<script>
import { createSetting, getSetting, updateSetting } from "@/helpers/campaign-settings";
import { notificationsMixin } from "@/mixins";
import MultiselectSearch from "@/components/multiselect-search.vue";
import urls from "@/constants/urls";
import axios from "axios";
import { isPlainObject } from "@/helpers/inspect";

export default {
  SETTING_META_NAME: "pinned_donations_for_teams",

  components: {
    MultiselectSearch,
  },

  mixins: [notificationsMixin],

  props: {
    campaignId: { type: [Number, String], required: true },
    orgId: { type: [Number, String], required: true },
    team: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      settingUserData: null,
      settingData: null,
      loading: false,
      optionSelected: null,
    };
  },

  computed: {
    settingId() {
      return (this.settingData?.id != "0" && this.settingData?.id) || null;
    },
    settingEnabled() {
      return this.settingUserData?.value || false;
    },
    settingDonations() {
      const donations = this.settingUserData?.donations;
      if (isPlainObject(donations)) {
        return [];
      }
      return donations || [];
    },
    teamSettingDonations() {
      return this.settingDonations.filter((v) => v.team_id === this.team.id);
    },
    foreignSettingDonations() {
      return this.settingDonations.filter((v) => v.team_id !== this.team.id);
    },
    getSearchUrl() {
      return urls.donationNewApi
        .replace(":orgId", this.orgId)
        .replace(":campaignId", this.campaignId);
    },
    searchOptions() {
      return {
        placeholder: this.$t("teams.pinned_donations_select", "Select donations to be pinned"),
        changeParams: ({ page, per_page: pp }) => ({
          page: page,
          limit: pp,
          team_id: this.team.id,
        }),
        customLabel: (e) => `[${e.id}] ${e.display_name}`,
        processResults: (data) =>
          data.map((el) => {
            return {
              id: el.id,
              display_name: el.attributes.display_name,
            };
          }),
      };
    },
  },

  mounted() {
    this.wrapGetSetting();
  },

  methods: {
    async save() {
      const $save = async () => {
        this.loading = true;

        if (this.settingId) {
          try {
            await this.wrapUpdateSetting();
            await this.wrapGetSetting();
          } finally {
            this.loading = false;
          }
        } else {
          try {
            await this.wrapAddSetting();
            await this.wrapGetSetting();
          } finally {
            this.loading = false;
          }
        }
      };

      await $save();
    },

    async disableOrEnableSetting(value) {
      this.settingUserData.value = value;
      await this.save();
    },

    async $saveSelectedPinnedDonations() {
      if (!this.settingEnabled) {
        return false;
      }

      const payload = this.optionSelected.map((v) => ({
        donation_id: v.id,
        team_id: this.team.id,
      }));
      this.settingUserData.donations = [...this.foreignSettingDonations, ...payload];
      await this.save();
    },

    async wrapGetSetting() {
      try {
        const setting = await getSetting(
          {
            cid: this.campaignId,
            oid: this.orgId,
            extraParams: {
              falback_to_meta_dir: 1,
            },
          },
          this.$options.SETTING_META_NAME
        );

        if (setting.id != "0") {
          this.settingData = setting;
        }
        if (setting.id === "0" && setting.attributes.default_meta_value_off) {
          this.settingUserData = setting.attributes.default_meta_value_off;
        }
        if (this.settingData) {
          this.settingUserData = setting.attributes.meta_data;
        }

        const donations = Promise.all(
          this.teamSettingDonations.map((v) =>
            axios
              .get(
                `${urls.putDonation
                  .replace(":orgId", this.orgId)
                  .replace(":campaignId", this.campaignId)}/${v.donation_id}`
              )
              .then(({ data }) => data.data)
          )
        );

        this.optionSelected = [];
        for (const donation of await donations) {
          const foundPinnedDonation = this.settingDonations.find(
            (v) => String(v.donation_id) === String(donation.id)
          );
          if (foundPinnedDonation) {
            this.optionSelected.push({
              id: foundPinnedDonation.donation_id,
              display_name: donation.attributes.display_name,
            });
          }
        }

        return setting;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        throw e;
      }
    },

    async wrapAddSetting() {
      try {
        const setting = await createSetting(
          { cid: this.campaignId, oid: this.orgId },
          this.$options.SETTING_META_NAME,
          this.settingUserData
        );

        this.$_notificationsMixin_makeToast(
          "Success",
          this.$t("edit_campaign.setting_saved", "Saved successfully"),
          "success"
        );

        this.settingData = setting;
        if (this.settingData) {
          this.settingUserData = setting.attributes.meta_data;
        }

        return setting;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        throw e;
      }
    },

    async wrapUpdateSetting() {
      try {
        const setting = await updateSetting(
          { cid: this.campaignId, oid: this.orgId },
          this.$options.SETTING_META_NAME,
          this.settingId,
          this.settingUserData
        );

        this.$_notificationsMixin_makeToast(
          "Success",
          this.$t("edit_campaign.setting_saved", "Saved successfully"),
          "success"
        );

        this.settingData = setting;
        if (this.settingData) {
          this.settingUserData = setting.attributes.meta_data;
        }

        return setting;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        throw e;
      }
    },
  },
};
</script>

<template>
  <div class="d-flex flex-column gap-3">
    <b-form-checkbox
      id="pinned_donations_enabled"
      name="pinned_donations_enabled"
      :checked="settingEnabled"
      :disabled="loading"
      @change="disableOrEnableSetting"
    >
      {{ $t("teams.pinned_donations_enabled", "Enabled pinned donations") }}
    </b-form-checkbox>
    <multiselect-search
      v-model="optionSelected"
      :disabled="!settingEnabled || loading"
      :open-prefetch="true"
      :render-prefetch="true"
      :allow-empty="true"
      :search-url="getSearchUrl"
      :search-options="searchOptions"
      :multiple="true"
      :close-on-select="false"
      skip-external-serching
      class="w-100"
    >
    </multiselect-search>
  </div>
</template>
