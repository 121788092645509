<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    current: {
      type: String,
      default: "",
    },
    template: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<template>
  <label
    class="template"
    :class="{
      'template--active': value === template.attributes.code,
      'not-set': value === template.attributes.code && value !== current,
      current: template.attributes.code === current,
    }"
  >
    <input type="button" class="template-rb" @click="$emit('click', template)" :value="value" />
    <span class="template__inner">
      <img :src="template.attributes.preview_img" alt="" />
      <span class="template__title">
        {{ template.attributes.name }}
      </span>
      <span class="template__description">
        {{ template.attributes.description }}
      </span>
    </span>
  </label>
</template>

<style lang="scss" scoped>
.template-rb {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.template {
  margin: 15px;
  min-height: 270px;
  border: 3px solid transparent;
  display: block;
  align-self: flex-start;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  width: 100%;
  @media only screen and (min-width: 568px) {
    width: 46%;
    margin: calc(8% / 2 / 2);
  }
  &:before {
    content: "";
    width: 100%;
    display: block;
    padding-top: 120%;
  }
  &:after {
    content: "";
    display: block;
    border-radius: 6px;
    border: 3px solid transparent;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    .template__inner {
      transform: scale(1.1);
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.2s ease-in-out;
    > img {
      margin-bottom: 5px;
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &__title {
    padding: 0 15px;
    text-align: center;
    font-family: $vito-bold;
    font-size: 18px;
    color: $bb-100;
    position: absolute;
    top: 5%;
    left: 5%;
  }
  &__description {
    width: 80%;
    height: 32px;
    overflow: hidden;
    font-family: $vito-light;
    font-size: 14px;
    line-height: 1.1;
    color: $fd;
    position: absolute;
    top: 12%;
    left: 8.5%;
  }
  &.current {
    border-color: $bb-100;
    &:hover {
      .template__inner {
        transform: scale(1);
      }
    }
  }
  &.not-set {
    &:hover {
      .template__inner {
        transform: scale(1);
      }
    }
  }
}

.template--active {
  background-color: #ffffff;
  border-color: $bb-100;
  &:after {
    opacity: 0;
  }
  &.not-set {
    border-color: $lr-50;
  }
}
</style>
