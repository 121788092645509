import { FAVORITE_CAMPAIGN_KEY, CAMPAIGN_LIST_LAYOUT_KEY } from "@/helpers/storage";

const state = {
  [FAVORITE_CAMPAIGN_KEY]: null,
  [CAMPAIGN_LIST_LAYOUT_KEY]: null,
};

const mutations = {
  [`SET_${FAVORITE_CAMPAIGN_KEY}`](state, value) {
    state[FAVORITE_CAMPAIGN_KEY] = value;
  },
  [`SET_${CAMPAIGN_LIST_LAYOUT_KEY}`](state, value) {
    state[CAMPAIGN_LIST_LAYOUT_KEY] = value;
  },
};

const getters = {};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
