<template>
  <div>
    <slot />
    <ModalWithSteps
      :id="id"
      :steps="CHARIDY_WIZARD_STEPS"
      :initialState="CHARIDY_WIZARD_INITIAL_STATE"
      :options="wizardOptions"
      showHidePopover
      :ssrStateSaved.sync="getCurrentlyEditedInWizard"
      @open="onModalOpen"
      @done="onModalDone"
      @save-draft="onModalSaveDraft"
      @hide="onModalHide"
    >
    </ModalWithSteps>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { nanoid } from "nanoid";
import store from "@/store/store";
import {
  OpenCrowdfundingFundraisingModal,
  CloseCrowdfundingFundraisingModal,
  ValidateShortlink,
  LinkValidationConfigWizard,
} from "@/constants/providers";
import { getGoogleTagManager, updateGoogleTagManager } from "@/helpers/google-tag-manager-meta";

import ModalWithSteps from "./modalWithSteps.vue";
import chooseTemplate from "./crowdfundingFundraising/chooseTemplate.vue";
import generalCampaignData from "./crowdfundingFundraising/generalCampaignData.vue";
import campaignInfo from "./crowdfundingFundraising/campaignInfo.vue";
import campaignMedia from "./crowdfundingFundraising/campaignMedia.vue";
import marketing from "./crowdfundingFundraising/marketing.vue";
import customization from "./crowdfundingFundraising/customization.vue";
import gatewayChoose from "./crowdfundingFundraising/gatewayChoose.vue";

const editCampaignAction = (state, moreData = {}) =>
  store.dispatch("fetchEditCampaign", {
    data: {
      template: state.campaignTemplate,
      title: state.campaignTitle,
      currency: state.campaignCurrency,
      primary_goal: state.campaignPrimaryGoal,
      primary_goal_multiplier: state.campaignPrimaryGoalMultiplier,
      start_date: state.campaignStartDate,
      end_date: state.campaignEndDate,
      short_link: state.campaignShortLink,
      ...moreData,
    },
    params: {
      update: true,
    },
  });

const campaignMediaAction = (state) => {
  const campaignVideo = {
    tag: "video",
    src: state.campaignVideoLink,
    lang: state.campaignLangImagesSelect,
  };

  const campaignCountdownVideo = {
    tag: "countdown_video",
    src: state.campaignCountdownVideoLink,
    lang: state.campaignLangImagesSelect,
  };

  const dispatchLoad = (videos) =>
    store
      .dispatch("fetchLoadWizardMedia", {
        videos,
      })
      .then(() => editCampaignAction(state));

  return dispatchLoad([campaignVideo, campaignCountdownVideo]);
};

export default {
  data() {
    return {
      id: nanoid(),
      wizardOptions: {
        hiddenSteps: {},
      },
    };
  },

  provide() {
    return {
      [OpenCrowdfundingFundraisingModal]: this.__open,
      [CloseCrowdfundingFundraisingModal]: this.__hide,
    };
  },

  inject: { ValidateShortlink, LinkValidationConfigWizard },

  components: {
    ModalWithSteps,
  },

  computed: {
    ...mapGetters({
      getCurrentlyEditedInWizardInternal: "getCurrentlyEditedInWizard",
    }),
    getCurrentlyEditedInWizard: {
      get() {
        return this.getCurrentlyEditedInWizardInternal;
      },
      set(newValue) {
        return this.setCurrentlyEditedInWizard(newValue);
      },
    },
    CHARIDY_WIZARD_STEPS() {
      return [
        {
          name: "chooseTemplate",
          component: chooseTemplate,
          hidden: this.wizardOptions.hiddenSteps.chooseTemplate || false,
          action: ({ state }) => {
            if (!store?.campaign?.data?.id) {
              return Promise.resolve();
            }

            return store.dispatch("fetchSaveTemplate", {
              templateCode: state.campaignTemplate,
            });
          },
        },
        {
          name: "generalCampaignData",
          component: generalCampaignData,
          action: {
            required: true,
            saveDraftBypassSubmit: true,
            submit: "GeneralCampaignDataForm",
            handler: async ({ state, setState }) => {
              if (!store.getters.getCurrentlyEditedInWizard) {
                await store.dispatch("fetchCreateCampaignWizard", {
                  title: state.campaignTitle,
                  shortLink: state.campaignShortLink,
                  primaryGoal: state.campaignPrimaryGoal,
                  primaryGoalMultiplier: state.campaignPrimaryGoalMultiplier,
                  currency: state.campaignCurrency,
                  startDate: state.campaignStartDate,
                  endDate: state.campaignEndDate,
                  templateCode: state.campaignTemplate,
                });
              } else {
                await editCampaignAction(state);
              }

              if (store.getters.getCurrentlyEditedInWizard) {
                const gtm = await getGoogleTagManager({
                  cid: store.getters.getCurrentlyEditedInWizard,
                });
                setState({ ...state, campaignGoogleTagManager: gtm });
              }

              return true;
            },
          },
        },
        {
          name: "campaignInfo",
          component: campaignInfo,
          action: async () => {
            return new Promise((resolve) => {
              this.$root.$emit("charidy::campaign-description::save-change", () => {
                resolve();
              });
            });
          },
        },
        {
          name: "campaignMedia",
          component: campaignMedia,
          action: ({ state }) => campaignMediaAction(state),
        },
        {
          name: "marketing",
          component: marketing,
          hidden: this.wizardOptions.hiddenSteps.marketing || false,
          action: {
            required: true,
            handler: async ({ state, setState }) => {
              await editCampaignAction(state, {
                facebook_pixel_id: state.campaignFacebookPixelId,
                google_analytics_number: state.campaignGoogleAnalyticsNumber,
                google_conversion_id: state.campaignGoogleConversionId,
                google_conversion_label: state.campaignGoogleConversionLabel,
              });

              const gtm = await updateGoogleTagManager(
                { cid: store.getters.getCurrentlyEditedInWizard },
                state.campaignGoogleTagManager
              );
              setState({ ...state, campaignGoogleTagManager: gtm });

              return true;
            },
          },
        },
        {
          name: "customization",
          component: customization,
          hidden: this.wizardOptions.hiddenSteps.customization || false,
          action: ({ state }) => editCampaignAction(state),
        },
        {
          name: "gatewayChoose",
          component: gatewayChoose,
          action: ({ state }) =>
            editCampaignAction(state, {
              gateway_id_list: state.campaignSelectedGateways.map((el) => Number(el.id)),
            }),
        },
      ].filter((v) => !v.hidden);
    },
    CHARIDY_WIZARD_INITIAL_STATE() {
      return {
        campaignTemplate: "standard-v2",
        campaignTitle: "",
        campaignCurrency: "usd",
        campaignPrimaryGoal: 1,
        campaignPrimaryGoalMultiplier: 1,
        campaignStartDate: new Date().getTime() / 1000,
        campaignStartDateString: new Date().toISOString(),
        campaignEndDate: (new Date().getTime() + 24 * 60 * 60 * 1000 + 24 * 60 * 60 * 1000) / 1000,
        campaignEndDateString: new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000 + 24 * 60 * 60 * 1000
        ).toISOString(),
        campaignShortLink: "",
        campaignGoogleAnalyticsNumber: "",
        campaignFacebookPixelId: "",
        campaignGoogleConversionId: "",
        campaignGoogleConversionLabel: "",
        campaignGoogleTagManager: {
          id: "",
          inject_as_text: false,
          __data: null,
        },
        campaignVideoLink: "",
        campaignCountdownVideoLink: "",
        campaignLangImagesSelect: "",
        campaignTemplates: [],
        campaignDescription: [],
        campaignSelectedGateways: [],
      };
    },
  },

  methods: {
    ...mapActions({
      getCampaign: "getCampaign",
      fetchDeleteCampaign: "fetchDeleteCampaign",
    }),
    ...mapMutations({
      setCurrentlyEditedInWizard: "setCurrentlyEditedInWizard",
    }),
    async updateInitialStateWithCampaignData(state, setState, loadingAction, blockStepper) {
      if (!this.getCurrentlyEditedInWizard) {
        return Promise.resolve(state);
      }

      loadingAction(true);

      const props = `?${[
        "extend=campaign_stats",
        "extend=content",
        "extend=media",
        "extend=meta",
        "extend=matchers",
        "extend=donation_levels",
        "extend=donation_streams",
      ].join("&")}`;

      let campaign;
      try {
        campaign = await this.getCampaign({
          cid: this.getCurrentlyEditedInWizard,
          props,
        });
      } catch (e) {
        blockStepper(e?.response?.data?.error || "Internal fatal error");
        return;
      }
      const attributes = campaign.data.attributes;

      const rounds = attributes.rounds.find(({ round }) => attributes.primary_goal === round) || {
        goal: 1,
        multiplier: 1,
      };

      return Promise.resolve(
        setState({
          campaignTemplate: attributes.template,
          campaignTitle: attributes.title,
          campaignCurrency: attributes.currency,
          campaignPrimaryGoal: rounds.goal,
          campaignPrimaryGoalMultiplier: rounds.multiplier,
          campaignStartDate: attributes.start_date,
          campaignStartDateString: new Date(attributes.start_date * 1000).toISOString(),
          campaignEndDate: attributes.end_date,
          campaignEndDateString: new Date(attributes.end_date * 1000).toISOString(),
          campaignShortLink: attributes.short_link,
          campaignGoogleAnalyticsNumber: attributes.google_analytics_number,
          campaignFacebookPixelId: attributes.facebook_pixel_id,
          campaignGoogleConversionId: attributes.google_conversion_id,
          campaignGoogleConversionLabel: attributes.google_conversion_label,
        })
      );
    },
    async onModalOpen(state, setState, loadingAction, errorHandler, setStep, blockStepper) {
      try {
        const { campaignShortLink } = await this.updateInitialStateWithCampaignData(
          state,
          setState,
          loadingAction,
          blockStepper
        );

        if (this.getCurrentlyEditedInWizard) {
          const gtm = await getGoogleTagManager({
            cid: this.getCurrentlyEditedInWizard,
          });
          setState({ ...state, campaignGoogleTagManager: gtm });
        }

        if (campaignShortLink.length > 0) {
          const [valid] = await this.ValidateShortlink(
            campaignShortLink,
            this.LinkValidationConfigWizard()
          );

          !valid && setStep(2);
          errorHandler(!valid);
        } else {
          errorHandler(false);
        }
      } finally {
        loadingAction(false);
      }
    },
    onModalHide({ action, loadingAction, resetAll, errorHandler }) {
      if (action === "delete-state") {
        loadingAction(true);
        return this.fetchDeleteCampaign({
          cId: this.getCurrentlyEditedInWizard,
        })
          .then(() => {
            this.$root.$emit("campaignList::getCampaignsByPage");
            resetAll();
            this.setCurrentlyEditedInWizard(null);
            this.__hide();

            this.$_notificationsMixin_makeToastCompat({
              group: "Notification",
              title: this.$t("campaigns.success_delete"),
            });
          })
          .catch((e) => {
            errorHandler(true);
            this.$_notificationsMixin_handleCatch(e);
          })
          .finally(() => {
            loadingAction(false);
          });
      }

      this.__hide();
      return this.$root.$emit("campaignList::getCampaignsByPage");
    },
    onModalDone({ cId, resetAll }) {
      this.setCurrentlyEditedInWizard(null);
      resetAll();
      this.$router.push({ path: `/campaign/${cId}` });
    },
    onModalSaveDraft(_0, _1, executeAction) {
      executeAction()
        .then((produced) => {
          this.$root.$emit("campaignList::getCampaignsByPage");
          return produced;
        })
        .then((produced) => {
          if (produced?.cId) {
            this.setCurrentlyEditedInWizard(produced?.cId);
          }
          return produced;
        });
    },

    __open(options = {}) {
      this.wizardOptions.showOnlyHolidayTemplates = options?.showOnlyHolidayTemplates || false;
      this.wizardOptions.hiddenSteps = options?.hiddenSteps || {};

      this.$bvModal.show(this.id);
    },
    __hide() {
      this.$bvModal.hide(this.id);
    },
  },
};
</script>
