<template>
  <div class="chat">
    <h5 class="font-weight-bold">
      {{ $t("dashboard.org_ticket_correspondence_label", "Correspondence") }}
    </h5>

    <div ref="list" class="chat-list">
      <see-more-btn-wraper
        v-model="page"
        :perPage="per_page"
        :totalRecords="totalRecords"
        class="text-center w-100 mb-2"
      >
        <template #btn="{ loadMore, hasMore }">
          <button v-if="hasMore" type="button" class="btn btn--sm bttn--orange" @click="loadMore">
            {{ $t("campaigns.campaigns_see_more", "See More") }}
          </button>
        </template>
      </see-more-btn-wraper>

      <div
        v-for="el in list"
        :key="el.id"
        class="chat-list-item"
        :class="{ rigth: el.created_by === 'organization' }"
      >
        {{ el.message }}
      </div>
    </div>

    <div class="chat-input mt-2">
      <b-form-textarea
        v-model="message"
        placeholder="Ask a question or post an update..."
        rows="2"
      ></b-form-textarea>
      <button
        class="bttn bttn--lg bttn--orange ml-2"
        :disabled="!message || sending"
        @click="createOrgTicket"
      >
        <b-spinner v-if="sending" small class="mx-2"></b-spinner>
        {{ $t(`dashboard.org_tickets_view_modal_comment_btn`, "Comment") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { notificationsMixin } from "@/mixins";
import seeMoreBtnWraper from "@/components/see-more-btn-wraper.vue";

export default {
  components: { seeMoreBtnWraper },

  props: {
    item: {
      type: Object,
      default: () => null,
    },
  },

  mixins: [notificationsMixin],

  data() {
    return {
      page: 1,
      per_page: 10,

      list: [],
      totalRecords: 0,

      intervalID: 0,

      message: "",
      sending: false,
    };
  },

  computed: {
    ...mapState({
      UID: (s) => s.user.user.id,
    }),

    ids() {
      return this.list.map((e) => e.id);
    },
  },

  watch: {
    page() {
      this.getCorrespondence();
    },
  },

  mounted() {
    this.getCorrespondence({ scrollBottom: true });

    this.intervalID = setInterval(() => {
      this.getCorrespondence({ update: true });
    }, 10000);
  },

  beforeDestroy() {
    clearInterval(this.intervalID);
  },

  methods: {
    ...mapActions({
      fetchOrgTickets: "orgTickets/fetchOrgTickets",
      fetchCreateOrgTicket: "orgTickets/fetchCreateOrgTicket",
    }),

    scrollBottom() {
      this.$nextTick(() => {
        this.$refs.list.scrollTop = this.$refs.list.scrollHeight;
      });
    },

    getCorrespondence({ scrollBottom, update } = {}) {
      const params = {
        parent_id: this.item.id,
        page: update ? 1 : this.page,
        per_page: this.per_page,
        sort_by: "-created_at",
      };

      this.fetchOrgTickets({ params })
        .then(({ data, headers }) => {
          this.totalRecords = Number(headers["x-total-records"]) || 0;

          data.reverse();

          if (update) {
            this.list.push(...data.filter((el) => !this.ids.includes(el.id)));
          } else {
            if (this.page > 1) {
              this.list.unshift(...data);
            } else {
              this.list = data;
            }
          }

          if (scrollBottom) {
            this.scrollBottom();
          }
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    createOrgTicket() {
      this.sending = true;
      const orgId = localStorage.getItem("orgId");

      const payload = {
        charidy_login_id: Number(this.UID),
        customer_org_id: Number(orgId),
        parent_ticket_id: this.item.id,
        created_by: "organization",
        message: this.message,
      };

      this.fetchCreateOrgTicket({ payload })
        .then(() => {
          this.message = "";
          this.sending = false;
          this.getCorrespondence({ scrollBottom: true, update: true });
        })
        .catch((err) => {
          this.$_notificationsMixin_handleCatch(err);
          this.sending = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  &-list {
    display: flex;
    flex-direction: column;
    max-height: 310px;
    overflow: auto;
    &-item {
      width: 90%;
      padding: 10px;
      border: 1px solid #e3e3e3;
      border-radius: 10px;
      margin-bottom: 10px;
      &.rigth {
        align-self: flex-end;
        background-color: #f5faff;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &-input {
    display: flex;
    align-items: center;
  }
}
</style>
