<template>
  <div>
    <b-modal ref="modalEl" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
      <template #modal-header>
        <h2 v-if="item">
          {{ $t(`dashboard.org_tickets_view_modal_title`, "Ticket #{id}", { id: item.id }) }}
        </h2>
      </template>

      <div v-if="item">
        <h6 class="font-weight-bold">
          {{ $t("dashboard.org_ticket_subject_label", "Subject") }}
        </h6>
        <p>
          {{ item.subject }}
        </p>

        <h6 class="font-weight-bold">
          {{ $t("dashboard.org_ticket_message_label", "Message") }}
        </h6>
        <p>
          {{ item.message }}
        </p>
      </div>

      <org-ticket-chat v-if="item" :item="item" />

      <template #modal-footer>
        <button class="bttn bttn--lg bttn--orange" @click="close">
          {{ $t(`dashboard.org_tickets_view_modal_btn`, "Ok") }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import OrgTicketChat from "./OrgTicketChat.vue";

export default {
  components: { OrgTicketChat },

  data() {
    return {
      item: null,
    };
  },

  methods: {
    open(item) {
      this.item = item;
      this.$refs.modalEl?.show();
    },

    close() {
      this.item = null;
      this.$refs.modalEl?.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
