<template>
  <div v-if="activetab === 1">
    <section class="date">
      <div class="hover-date start-date">
        <div class="form-group form-unit form-unit--addon date-time-select">
          <div class="form-unit__addon-wrap">
            <datetime
              input-id="end-date"
              type="datetime"
              v-model="date"
              value-zone="UTC"
              input-class="form-control form-unit__date"
            ></datetime>
          </div>
          <label class="form-unit__label">Start Data</label>
        </div>
      </div>
      <div class="line-date">
        <div class="line"></div>
      </div>
      <div class="hover-date end-date">
        <div class="form-group form-unit form-unit--addon date-time-select">
          <div class="form-unit__addon-wrap">
            <datetime
              input-id="end-date"
              type="datetime"
              v-model="date"
              value-zone="UTC"
              input-class="form-control form-unit__date"
            ></datetime>
          </div>
          <label class="form-unit__label">End Data</label>
        </div>
      </div>
    </section>

    <section class="amount-received">
      <div class="donations">
        <div class="donations-column">
          <div class="amount">
            <h1>$5,000</h1>
            <p>Amount Received</p>
          </div>
          <div class="count">
            <h1>173</h1>
            <p>Donations Count</p>
          </div>
        </div>
        <div class="description">
          <p>Feb 5, 2018 - Mar 1, 2019</p>
        </div>
        <div class="generate-report">
          <button>Generate Report</button>
        </div>
      </div>
      <div class="stripe">446</div>
    </section>

    <section class="donations-paypal">
      <div class="donation offline">
        <h1>$11,200</h1>
        <div class="description">
          <p>Offline Donations</p>
          <p class="text">02.25.19 - Today</p>
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure iusto perferendis ex
          incidunt itaque dicta? Eum maxime, eligendi sit magni officia explicabo a impedit maiores
          voluptas aut qui vero architecto. Labore minima dicta nihil ducimus deserunt
          necessitatibus possimus numquam rem veniam, minus quam! Veniam, temporibus voluptate
          tempora voluptatem autem hic ad quasi vel dicta voluptatibus maiores voluptas dolores
          magni blanditiis.
        </p>
      </div>
      <div class="donation">
        <h1>$11,200</h1>
        <div class="description">
          <p>Offline Donations</p>
          <p class="text">02.25.19 - Today</p>
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure iusto perferendis ex
          incidunt itaque dicta? Eum maxime, eligendi sit magni officia explicabo a impedit maiores
          voluptas aut qui vero architecto. Labore minima dicta nihil ducimus deserunt
          necessitatibus possimus numquam rem veniam, minus quam! Veniam, temporibus voluptate
          tempora voluptatem autem hic ad quasi vel dicta voluptatibus maiores voluptas dolores
          magni blanditiis.
        </p>
      </div>
    </section>

    <section class="donors">
      <div class="donor total">
        <h1>$11,200</h1>
        <div class="description">
          <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              d="M19.0428 1.4668C20.8275 1.46452 22.2767 2.93758 22.2812 4.75848C22.2857 6.57938 20.8438 8.05987 19.0591 8.06677C17.2744 8.07367 15.8216 6.60437 15.8125 4.78349C15.8082 3.90611 16.1465 3.06308 16.7526 2.44072C17.3588 1.81835 18.1829 1.4679 19.0428 1.4668Z"
              fill="#FF5555"
            />
            <path
              opacity="0.2"
              d="M22.5632 11.7301C23.8914 14.5516 21.767 20.5332 21.767 20.5332H17.2137C17.8668 17.7638 18.4061 13.8744 17.1917 11.2873C17.0038 10.8888 16.7825 10.5136 16.5312 10.1674C17.2501 9.73928 18.0454 9.52192 18.8501 9.53365C19.1179 9.53365 21.4918 9.44684 22.5632 11.7301Z"
              fill="#FF5555"
            />
            <path
              opacity="0.49535"
              d="M3.95717 1.4668C2.17248 1.46452 0.723258 2.93758 0.71876 4.75848C0.714263 6.57938 2.15619 8.05987 3.94087 8.06677C5.72555 8.07367 7.17843 6.60437 7.18746 4.78349C7.19181 3.90611 6.85353 3.06308 6.24738 2.44072C5.64123 1.81835 4.81711 1.4679 3.95717 1.4668Z"
              fill="#FF5555"
            />
            <path
              opacity="0.49535"
              d="M4.1499 9.53365C3.88206 9.53365 1.50818 9.44684 0.436815 11.7301C-0.891385 14.5516 1.233 20.5332 1.233 20.5332H5.7863C5.13321 17.7638 4.59386 13.8744 5.80832 11.2873C5.99624 10.8888 6.21749 10.5136 6.46875 10.1674C5.74992 9.73928 4.95462 9.52192 4.1499 9.53365Z"
              fill="#FF5555"
            />
            <path
              opacity="0.9"
              d="M7.90625 4.03333C7.90625 1.80578 9.67612 0 11.8594 0C14.0426 0 15.8125 1.80578 15.8125 4.03333C15.8125 6.26088 14.0426 8.06667 11.8594 8.06667C10.8109 8.06667 9.80545 7.64173 9.06409 6.88533C8.32274 6.12893 7.90625 5.10304 7.90625 4.03333Z"
              fill="#FF5555"
            />
            <path
              opacity="0.9"
              d="M11.8594 9.53375C11.5129 9.53375 8.43286 9.43528 7.03561 12.0309C5.67261 14.5635 7.11175 19.3294 7.7704 21.2121C7.94355 21.6873 8.38366 22.0013 8.8745 21.9999H14.8442C15.3351 22.0013 15.7752 21.6873 15.9483 21.2121C16.607 19.3294 18.0461 14.5517 16.6831 12.0309C15.2859 9.43134 12.2058 9.53375 11.8594 9.53375Z"
              fill="#FF5555"
            />
          </svg>
          <div class="description-te">
            <p>Total Donors</p>
            <p class="text">02.25.19 - Today</p>
          </div>
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure iusto perferendis ex
          incidunt itaque dicta? Eum maxime, eligendi sit magni officia explicabo a impedit maiores
          voluptas aut qui vero architecto. Labore minima dicta nihil ducimus deserunt
          necessitatibus possimus numquam rem veniam, minus quam! Veniam, temporibus voluptate
          tempora voluptatem autem hic ad quasi vel dicta voluptatibus maiores voluptas dolores
          magni blanditiis.
        </p>
      </div>
      <div class="donor">
        <h1>$11,200</h1>
        <div class="description">
          <svg
            width="23"
            height="22"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              d="M19.0428 1.4668C20.8275 1.46452 22.2767 2.93758 22.2812 4.75848C22.2857 6.57938 20.8438 8.05987 19.0591 8.06677C17.2744 8.07367 15.8216 6.60437 15.8125 4.78349C15.8082 3.90611 16.1465 3.06308 16.7526 2.44072C17.3588 1.81835 18.1829 1.4679 19.0428 1.4668Z"
              fill="#FF5555"
            />
            <path
              opacity="0.2"
              d="M22.5632 11.7301C23.8914 14.5516 21.767 20.5332 21.767 20.5332H17.2137C17.8668 17.7638 18.4061 13.8744 17.1917 11.2873C17.0038 10.8888 16.7825 10.5136 16.5312 10.1674C17.2501 9.73928 18.0454 9.52192 18.8501 9.53365C19.1179 9.53365 21.4918 9.44684 22.5632 11.7301Z"
              fill="#FF5555"
            />
            <path
              opacity="0.49535"
              d="M3.95717 1.4668C2.17248 1.46452 0.723258 2.93758 0.71876 4.75848C0.714263 6.57938 2.15619 8.05987 3.94087 8.06677C5.72555 8.07367 7.17843 6.60437 7.18746 4.78349C7.19181 3.90611 6.85353 3.06308 6.24738 2.44072C5.64123 1.81835 4.81711 1.4679 3.95717 1.4668Z"
              fill="#FF5555"
            />
            <path
              opacity="0.49535"
              d="M4.1499 9.53365C3.88206 9.53365 1.50818 9.44684 0.436815 11.7301C-0.891385 14.5516 1.233 20.5332 1.233 20.5332H5.7863C5.13321 17.7638 4.59386 13.8744 5.80832 11.2873C5.99624 10.8888 6.21749 10.5136 6.46875 10.1674C5.74992 9.73928 4.95462 9.52192 4.1499 9.53365Z"
              fill="#FF5555"
            />
            <path
              opacity="0.9"
              d="M7.90625 4.03333C7.90625 1.80578 9.67612 0 11.8594 0C14.0426 0 15.8125 1.80578 15.8125 4.03333C15.8125 6.26088 14.0426 8.06667 11.8594 8.06667C10.8109 8.06667 9.80545 7.64173 9.06409 6.88533C8.32274 6.12893 7.90625 5.10304 7.90625 4.03333Z"
              fill="#FF5555"
            />
            <path
              opacity="0.9"
              d="M11.8594 9.53375C11.5129 9.53375 8.43286 9.43528 7.03561 12.0309C5.67261 14.5635 7.11175 19.3294 7.7704 21.2121C7.94355 21.6873 8.38366 22.0013 8.8745 21.9999H14.8442C15.3351 22.0013 15.7752 21.6873 15.9483 21.2121C16.607 19.3294 18.0461 14.5517 16.6831 12.0309C15.2859 9.43134 12.2058 9.53375 11.8594 9.53375Z"
              fill="#FF5555"
            />
          </svg>
          <div class="description-te">
            <p>Total Donors</p>
            <p class="text">02.25.19 - Today</p>
          </div>
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure iusto perferendis ex
          incidunt itaque dicta? Eum maxime, eligendi sit magni officia explicabo a impedit maiores
          voluptas aut qui vero architecto. Labore minima dicta nihil ducimus deserunt
          necessitatibus possimus numquam rem veniam, minus quam! Veniam, temporibus voluptate
          tempora voluptatem autem hic ad quasi vel dicta voluptatibus maiores voluptas dolores
          magni blanditiis.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: "",
    };
  },

  props: {
    activetab: Number,
  },
};
</script>

<style lang="scss" scoped>
.date {
  padding-bottom: 16px;
  margin-top: 50px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.start-date {
  margin-right: 32px;
}

.end-date {
  margin-left: 32px;
}

.hover-date {
  width: 235px;
  text-align: center;
  &:hover {
    background: #fff;
  }
  .form-unit__label {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #107598;
  }
}

.form-unit__addon-wrap {
  &:before {
    content: "";
    display: block;
    margin-left: 12em;
    margin-top: 22px;
    vertical-align: 0.255em;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: #107598;
    position: absolute;
  }
}

.line-date {
  padding-top: 6px;
}

.line {
  opacity: 0.14;
  border: 1px solid rgba(65, 67, 89, 0.292422);
  height: 94px;
}

.amount-received {
  display: flex;
  padding-bottom: 40px;
}

.donations {
  background: #fff;
  box-shadow: 0px 8px 32px rgba(35, 40, 71, 0.0795686);
  border-radius: 4px;
  padding: 40px;
  margin-right: 40px;
}
.donations-column {
  display: flex;
  margin-bottom: 30px;
}

.stripe {
  //max-width: 778px;
  width: 100%;
}

.amount {
  background: #fff5f5;
  border-radius: 4px;
  padding: 39px 14px 77px 15px;
  margin-right: 16px;
  h1 {
    font-size: 36px;
    line-height: 46px;
    color: #ff5555;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #a0a1ac;
  }
}

.count {
  background: #f0fcf9;
  border-radius: 4px;
  padding: 39px 16px 58px 17px;
  text-align: center;
  h1 {
    font-size: 36px;
    line-height: 46px;
    color: #00d39c;
  }
  p {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #a0a1ac;
    white-space: nowrap;
  }
}

.description {
  text-align: center;
  p {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #414359;
  }
}

.generate-report {
  text-align: center;
  button {
    background: rgba(16, 117, 152, 0.07);
    border-radius: 26px;
    border: none;
    font-size: 16px;
    line-height: 20px;
    color: #107598;
    padding: 13px 47px 9px 33px;
    &:active {
      border: none;
    }
  }
}

.stripe {
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(35, 40, 71, 0.0795686);
  border-radius: 4px;
  padding: 40px 45px 42px 41px;
}

.donations-paypal {
  display: flex;
  padding-bottom: 40px;
}

.donation {
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(35, 40, 71, 0.0795686);
  border-radius: 4px;
  padding: 40px 41px 42px 40px;
  // max-width: 580px;
  // width: 100%;
  // height: 391px;
  h1 {
    font-size: 30px;
    line-height: 38px;
    color: #00d39c;
    margin-bottom: 8px;
  }
  .description {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 20px;
      line-height: 25px;
      color: #414359;
      margin-bottom: 36px;
    }
    .text {
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      color: #414359;
      opacity: 0.6;
    }
  }
}

.offline {
  margin-right: 40px;
}

.donors {
  display: flex;
}

.total {
  margin-right: 40px;
}

.donor {
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(35, 40, 71, 0.0795686);
  border-radius: 4px;
  padding: 40px 41px 42px 40px;
  // max-width: 580px;
  // width: 100%;
  // height: 391px;
  h1 {
    font-size: 30px;
    line-height: 38px;
    color: #ff5555;
    margin-bottom: 8px;
  }
  .description {
    display: flex;
    .description-te {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-left: 18px;
    }

    p {
      font-size: 20px;
      line-height: 25px;
      color: #414359;
      margin-bottom: 36px;
    }
    .text {
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      color: #414359;
      opacity: 0.6;
    }
  }
}

@media only screen and (max-width: 992px) {
  .amount-received {
    flex-wrap: wrap;
    .donations {
      margin: 0 auto;
      margin-bottom: 16px;
    }
    .stripe {
      margin-bottom: -20px;
    }
  }

  .donations-paypal {
    flex-wrap: wrap;
    margin-bottom: -35px;
    .donation {
      margin: 0 auto;
      margin-bottom: 16px;
    }
  }

  .donors {
    flex-wrap: wrap;
    .donor {
      margin: 0 auto;
      margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .form-unit__addon-wrap:before {
    margin-left: 7.6em;
  }

  .hover-date .form-unit__label {
    font-size: 12px;
    line-height: 10px;
  }

  .amount {
    width: 148px;
    h1 {
      font-size: 28px;
      line-height: 36px;
    }
  }

  .count {
    width: 148px;
    p {
      white-space: normal;
    }
  }

  .donations {
    padding: 16px;
    h1 {
      font-size: 22px;
      line-height: 28px;
    }
  }

  .stripe {
    padding: 16px 15px 16px 16px;
  }

  .donation {
    h1 {
      font-size: 22px;
      line-height: 28px;
    }
    .description {
      p {
        font-size: 14px;
        line-height: 18px;
      }
      .text {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .donor {
    padding: 16px 16px 17px 17px;
    h1 {
      font-size: 22px;
      line-height: 28px;
    }
    .description {
      .description-te {
        margin-left: 7px;
        margin-top: 3px;
        p {
          font-size: 14px;
          line-height: 18px;
        }
        text {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
