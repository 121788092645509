<template>
  <div>
    <b-modal
      id="share-video-form-madal"
      size="lg"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @hide="clearData"
    >
      <div slot="modal-header">
        <h2 class="text-capitalize">
          {{ title }}
        </h2>
      </div>

      <div>
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <template #label>
                {{ $t("dashboard.share_video_custom_holder_name", "Video title:") }}
              </template>
              <b-form-input v-model="custom_holder_name"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <b-form-file
                v-model="file"
                accept="video/*"
                size="lg"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click="close">
          {{ $t(`dashboard.cancel_btn`) }}
        </button>
        <button
          class="bttn bttn--lg bttn--orange text-capitalize"
          form="utm-form"
          :disabled="disabledButton"
          @click="submit"
        >
          <b-spinner v-if="loading" small class="mr-1"></b-spinner>
          {{ buttonText }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { notificationsMixin } from "@/mixins";
import { mapActions, mapState } from "vuex";

export default {
  mixins: [notificationsMixin],

  data: function () {
    return {
      file: null,
      custom_holder_name: "",
      originalData: null,
    };
  },

  computed: {
    ...mapState({
      cmpId: (state) => state.shareVideo.campaign.id,
      loading: (state) => state.shareVideo.createShareVideoLoading,
    }),
    title() {
      if (this.originalData) {
        return this.$t("dashboard.share_video_update_video_modal_title", `Update video`);
      }
      return this.$t("dashboard.share_video_add_new_video_modal_title", `Add video`);
    },
    buttonText() {
      if (this.originalData) {
        return this.$t("dashboard.share_video_update_modal_btn", `Update video`);
      }
      return this.$t("dashboard.share_video_add_modal_btn", `Add video`);
    },
    disabledButton() {
      if (this.originalData) {
        return this.loading;
      }
      return !this.file || this.loading;
    },
  },

  methods: {
    ...mapActions({
      fetchCreateShareVideo: "shareVideo/fetchCreateShareVideo",
      fetchPatchShareVideo: "shareVideo/fetchPatchShareVideo",
    }),

    open({ originalData } = {}) {
      if (originalData) {
        this.originalData = originalData;
        this.custom_holder_name = this.originalData?.name || "";
      }
      this.$bvModal.show("share-video-form-madal");
    },

    close() {
      this.$bvModal.hide("share-video-form-madal");
    },

    clearData() {
      this.file = null;
      this.custom_holder_name = "";
      this.originalData = null;
    },

    submit() {
      const payload = {
        cID: this.cmpId,
        form: {
          file: !this.originalData?.video || this.file !== null ? this.file : null,
          custom_holder_name: this.custom_holder_name,
        },
      };
      const fn = !this.originalData
        ? this.fetchCreateShareVideo.bind(null, payload)
        : this.fetchPatchShareVideo.bind(null, {
            ...payload,
            id: this.originalData.module_data_id,
          });
      fn()
        .then(() => {
          this.close();
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
  },
};
</script>

<style lang="scss" scoped></style>
