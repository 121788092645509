<template>
  <div class="tabs">
    <div class="tab">
      <div
        role="button"
        class="tab-btn"
        :class="{ active: value === 'to_do' }"
        @click="onSelectTab('to_do')"
      >
        {{
          $t("dashboard.org_tickets_to_do_status", "TO DO ({count})", {
            count: orgToDoCount,
          })
        }}
      </div>
    </div>
    <div class="tab">
      <div
        role="button"
        class="tab-btn"
        :class="{ active: value === 'in_process' }"
        @click="onSelectTab('in_process')"
      >
        {{
          $t("dashboard.org_tickets_in_process_status", "IN PROCESS ({count})", {
            count: orgInProcessCount,
          })
        }}
      </div>
    </div>
    <div class="tab">
      <div
        role="button"
        class="tab-btn"
        :class="{ active: value === 'completed' }"
        @click="onSelectTab('completed')"
      >
        {{
          $t("dashboard.org_tickets_completed_status", "COMPLETED ({count})", {
            count: orgCompletedCount,
          })
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    value: {
      type: String,
      default: "to_do",
    },
  },

  computed: {
    ...mapState({
      orgToDoCount: (state) => state.orgTickets.countByStatus.to_do,
      orgInProcessCount: (state) => state.orgTickets.countByStatus.in_process,
      orgCompletedCount: (state) => state.orgTickets.countByStatus.completed,
    }),
  },

  methods: {
    onSelectTab(tab) {
      this.$emit("input", tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  color: #212529;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dee2e6;
  box-sizing: border-box;
  .tab {
    color: #212529;
    box-sizing: border-box;
    margin-bottom: -1px;
    .tab-btn {
      padding: 12px;
      border: 1px solid transparent;
      &.active {
        color: #495057;
        font-weight: 700;
        background-color: #e9ecef;
        border-color: #dee2e6 #dee2e6 #e9ecef;
      }
      &:hover {
        border-color: #dee2e6 #dee2e6 #e9ecef;
      }
    }
  }
}
</style>
