<template>
  <SeferTorahPublicProvider
    v-slot="{
      list,
      loading,
      loadingSort,
      page,
      perPage,
      rows,
      updatePage,
      remove,
      changeItemSort,
      sortChange,
      searchChange,
      onRemoveMultiple,
      onAfterModalSubmit,
      getPages,
      currentFetchParams,
    }"
  >
    <SeferTorahAddModal
      :list="list"
      :currentFetchParams="currentFetchParams"
      @submit="onSubmit({ onAfterModalSubmit }, $event)"
      v-slot="{ open: openAddModal }"
    >
      <category-title :title="$t('dashboard.sefer_torah_title', 'Sefer Torah')" withActions>
        <b-button class="bttn bttn--lg bttn--orange mx-1" @click="openAddModal({})">
          {{ $t("dashboard.sefer_torah_addnew_modal_open", "Add Sefer Torah Page") }}
        </b-button>

        <b-button class="bttn bttn--lg bttn--orange mx-1" @click="openImport">
          {{ $t("dashboard.sefer_torah_import", "Import") }}
        </b-button>

        <b-button
          class="bttn bttn--lg bttn--orange mx-1"
          :disabled="exportLoading"
          @click="exportToFile"
        >
          <b-spinner v-if="exportLoading" variant="light" small class="mr-2" />
          {{ $t("dashboard.sefer_torah_export", "Export") }}
        </b-button>
      </category-title>
      <SeferTorahPublicTable
        :page="page"
        @update:page="updatePage"
        :perPage="perPage"
        :rows="rows"
        :openAddModal="openAddModal"
        :list="list"
        :loading="loading"
        :loadingSort="loadingSort"
        :remove="remove"
        @change-order="changeItemSort"
        @sort-change="sortChange"
        @search-change="searchChange"
        @remove-multiple="onRemoveMultiple"
      />
      <import-modal ref="import" @success-upload="onSuccessUpload({ getPages })" />
    </SeferTorahAddModal>
  </SeferTorahPublicProvider>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { notificationsMixin } from "@/mixins";
import SeferTorahPublicProvider from "./SeferTorahPublicProvider.vue";
import SeferTorahPublicTable from "./SeferTorahPublicTable.vue";
import ImportModal from "./ImportModal.vue";
import categoryTitle from "@/common-components/ui-elements/category-title.vue";
import SeferTorahAddModal from "./SeferTorahAddModal.vue";

export default {
  components: {
    ImportModal,
    categoryTitle,
    SeferTorahPublicProvider,
    SeferTorahPublicTable,
    SeferTorahAddModal,
  },

  mixins: [notificationsMixin],

  data() {
    return {
      exportLoading: false,
    };
  },

  computed: {
    ...mapState({
      cmpId: (state) => state.campaign.campaign.data.id,
    }),
  },

  methods: {
    ...mapActions({
      seferTorahFetchOrgExport: "seferTorahPages/fetchOrgExport",
    }),

    openImport() {
      this.$refs.import.showModal();
    },

    onSuccessUpload(ctx) {
      ctx.getPages();
    },

    onSubmit(ctx, { data, count }) {
      console.log("SeferTorahPage.vue emit submit event");
      ctx.onAfterModalSubmit(data, count);
    },

    exportToFile() {
      this.exportLoading = true;
      this.seferTorahFetchOrgExport({ cId: this.cmpId })
        .then(({ data }) => {
          const l = data.data.link;
          const a = document.createElement("a");
          a.href = l;
          a.target = "_blank";

          a.click();
          a.remove();
          this.exportLoading = false;
        })
        .catch(this.$_notificationsMixin_handleCatch)
        .finally(() => {
          this.exportLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
