const notificationsMixin = {
  methods: {
    /** Shows toast from parent.
     * @param {string} title Title of toast
     * @param {string} body Body of toast
     * @param {string} variant Variant of toast
     */
    $_notificationsMixin_makeToast(title, body, variant = null) {
      this.$root.$bvToast.toast(body, {
        title: title,
        variant: variant,
      });
    },
    /** Shows toast from parent
     * @param {Object} object
     */
    $_notificationsMixin_makeToastCompat({ title, group, type = "success" }) {
      this.$root.$bvToast.toast(title, {
        title: group,
        variant: type,
      });
    },
    /** Shows props from charidy error object with styling
     * @param {string} title Charidy error object prop
     * @param {string} detail Charidy error object prop
     * @param {string} status Charidy error object prop
     */
    $_notificationsMixin_makeErrorToast(title, detail, status) {
      const h = this.$createElement;
      const vNodesTitle = h(
        "div",
        { class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"] },
        [
          h("strong", { class: "mr-2" }, title),
          h("small", { class: "ml-auto text-italics" }, status),
        ]
      );
      this.$_notificationsMixin_makeToast([vNodesTitle], detail, "danger");
    },
    $_notificationsMixin_fillWithErrors(errors) {
      errors.forEach((e) => {
        if (e instanceof Error) {
          this.$_notificationsMixin_makeErrorToast(e.title || "Generic Error", e.message);
        } else if (e?.title && e?.detail && e?.status) {
          const { title, status, meta } = e;
          let detail = e.detail;

          if (meta && meta?.error_translation_key) {
            detail = this.$t(meta.error_translation_key, meta?.error_translation_default || "");
          }

          this.$_notificationsMixin_makeErrorToast(title, detail, status);
        } else {
          this.$_notificationsMixin_makeErrorToast("Error", e);
        }
      });
    },
    $_notificationsMixin_handleCatch(error) {
      const res = error && error.response;

      if (res && res.data.errors) {
        this.$_notificationsMixin_fillWithErrors(res.data.errors);
        return res.data.errors;
      }

      if (res && res.data.error) {
        this.$_notificationsMixin_makeToast("General Error", res.data.error, "danger");
        return res.data.error;
      }

      return error;
    },
    $_notificationsMixin_extractCatch(error) {
      const res = error && error.response;

      if (res && res.data.errors) {
        return res.data.errors;
      }

      if (res && res.data.error) {
        return res.data.error;
      }

      return error;
    },
  },
};

export default notificationsMixin;
