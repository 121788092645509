<template>
  <div
    v-bind:class="{ alert: rich, 'alert-danger': rich }"
    role="alert"
    v-if="errorDisplay.length > 0"
  >
    <ul v-if="errorDisplay.length > 1 && !isTk" class="error">
      <li v-for="error in errorDisplay" v-bind:key="error">{{ error }}</li>
    </ul>
    <div v-else class="error">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["errors", "rich"],

  data() {
    return {
      // errorDisplay: "",
    };
  },

  computed: {
    isTk() {
      return this.errorDisplay?.[0]?.includes("httperror");
    },

    errorText() {
      return this.$t(...this.errorDisplay);
    },

    errorDisplay: function () {
      var ed = [];

      if (this.errors != null && this.errors !== "undefined") {
        if (this.errors.response != null && this.errors.response !== "undefined") {
          if (this.errors.response.data != null && this.errors.response.data !== "undefined") {
            if (
              this.errors.response.data.error != null &&
              this.errors.response.data.error !== "undefined"
            ) {
              ed.push(this.errors.response.data.error);
              return ed;
            } else {
              ed.push(this.errors.response.data);
              return ed;
            }
          } else {
            // ed.push(this.errors.response)
          }
        } else {
          // ed.push(this.errors)
        }
      }

      // TODO:  add processing of network errors
      // console.log(this.errors)

      return this.errors;
    },
  },

  methods: {},
};
</script>

<style>
.error {
  color: #e8410d;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
</style>
