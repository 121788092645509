import { render, staticRenderFns } from "./AllowConditionalDonations.vue?vue&type=template&id=5d882a40&scoped=true&"
import script from "./AllowConditionalDonations.vue?vue&type=script&lang=js&"
export * from "./AllowConditionalDonations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d882a40",
  null
  
)

export default component.exports