<template>
  <div>
    <b-modal ref="modal" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
      <div slot="modal-header">
        <h2>
          {{ $t(`dashboard.report_ignore_list_title`, "Exeption list") }}
        </h2>
      </div>

      <div>
        <label for="tags-basic">
          {{
            $t(
              `dashboard.report_ignore_list`,
              "Email list that will be excluded from outgoing communication"
            )
          }}
        </label>
        <b-form-tags
          v-model="ignoreList"
          add-on-change
          tag-variant="primary"
          tag-pills
          size="lg"
          :placeholder="$t('dashboard.report_ignore_list_placeholder', 'Add email')"
        ></b-form-tags>
      </div>

      <div slot="modal-footer" class="d-flex flex-row">
        <button class="mx-2 bttn bttn--lg bttn--blue" @click="close">
          {{ $t(`dashboard.cancel_btn`, "Close") }}
        </button>

        <button class="bttn bttn--lg bttn--orange" @click="save">
          {{ $t(`dashboard.save_btn`, "Save") }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ignoreList: [],
    };
  },

  mounted() {
    const ril = localStorage.getItem("ReportIgnoreList");
    if (ril) {
      try {
        this.ignoreList = JSON.parse(ril);
      } catch {
        this.ignoreList = [];
      }
    }
  },

  computed: {
    listLength() {
      return this.ignoreList.length;
    },
  },

  methods: {
    open() {
      this.$refs.modal.show();
    },

    close() {
      this.$emit("close");
      this.$refs.modal.hide();
    },

    addToList(items = []) {
      const ril = localStorage.getItem("ReportIgnoreList");
      if (ril) {
        try {
          this.ignoreList = JSON.parse(ril);
        } catch {
          this.ignoreList = [];
        }
      }
      items.forEach((e) => {
        if (!this.ignoreList.includes(e) && e) {
          this.ignoreList.push(e);
        }
      });
      localStorage.setItem("ReportIgnoreList", JSON.stringify(this.ignoreList));
      this.$emit("close");
    },

    save() {
      localStorage.setItem("ReportIgnoreList", JSON.stringify(this.ignoreList));
      this.$emit("close");
      this.$refs.modal.hide();
    },

    getListLength() {
      return this.listLength;
    },

    getList() {
      return this.ignoreList;
    },
  },
};
</script>
