import urls from "@/constants/urls";
import axios from "axios";

function normalize(data, included) {
  const newArr = [];
  data?.forEach((e) => {
    if (e.relationships?.stripe_payouts.data.length > 0) {
      e.relationships?.stripe_payouts.data.forEach(({ id }) => {
        newArr.push({
          account: e.attributes.account,
          skipped: e.attributes.skipped,
          ...included.find((ipl) => ipl.id === id)?.attributes,
        });
      });
    } else {
      newArr.push({
        account: e.attributes.account,
        skipped: e.attributes.skipped,
      });
    }
  });
  return newArr;
}

const state = {
  fundTransferStatus: [],
  loading: {
    fundTransferStatus: false,
  },
};

const getters = {};

const mutations = {
  SET_FUND_TRANSFER_STATUS(state, data) {
    state.fundTransferStatus = data;
  },
  SET_LOADING(state, [k, v]) {
    state.loading[k] = v;
  },
};

const actions = {
  fetchFundTransferStatus({ commit }, [cId, showAll = 0]) {
    const orgId = localStorage.getItem("orgId");
    const url = urls["fundTransferStatus"].replace(":oid", orgId).replace(":cid", cId);
    const params = {};
    showAll && (params.show_all = showAll);

    commit("SET_LOADING", ["fundTransferStatus", true]);
    return axios
      .get(url, { params })
      .then(({ data: { data, included } }) => {
        commit("SET_FUND_TRANSFER_STATUS", normalize(data, included));
        return normalize(data, included);
      })
      .finally(() => commit("SET_LOADING", ["fundTransferStatus", false]));
  },
};

export default { namespaced: true, state, getters, mutations, actions };
