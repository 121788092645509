<template>
  <div v-if="team" class="donation-unit">
    <div class="donation-unit__top_row">
      <div class="donation-unit__cell donation-unit__id">
        {{ team.id }}
      </div>
      <div class="donation-unit__cell donation-unit__id">
        <b-container fluid class="p-0">
          <b-row>
            <b-col cols="12">
              <p>{{ teamAttr.name }}</p>
            </b-col>
            <b-col cols="12">
              <p class="text-black-50">
                {{ `${$t("dashboard.team_shortlink", "Shortlink:")} ${teamAttr.slug}` }}
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="donation-unit__cell donation-unit__id">
        {{ teamAttr.leader_name }}
      </div>
      <div class="donation-unit__cell donation-unit__id">
        <b-container fluid class="p-0">
          <b-row>
            <b-col cols="12">
              <p>{{ `${teamAttr.goal} ${selectedCmpCurrency}` }}</p>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="donation-unit__cell donation-unit__id">
        {{ `${totalAmount} ${selectedCmpCurrency}` }}
      </div>
      <div class="donation-unit__cell donation-unit__id">
        {{ totalCount }}
      </div>
      <div class="donation-unit__cell donation-unit__id">
        {{ percentFormated }}
      </div>
      <div class="donation-unit__cell donation-unit__id">
        {{ team.attributes.hidden ? "Hidden" : "Shown" }}
      </div>
      <div class="donation-unit__cell p-0">
        <b-container fluid>
          <b-row>
            <b-col>
              <p>{{ diffGoalRaised }}</p>
            </b-col>
            <b-col>
              <icon-info no-style type="bootstrap" :tooltipMsg="tooltipTkey(diffGoalRaisedPercent)">
                <b-icon
                  icon="exclamation-circle-fill"
                  :class="diffIconClass(diffGoalRaisedPercent)"
                ></b-icon>
              </icon-info>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <div class="donation-unit__action">
        <a v-if="waHref" aria-label="Chat on WhatsApp" target="_blank" :href="waHref">
          <img
            alt="Chat on WhatsApp"
            src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1708037021_6239643632643266623030613862613461356162333164303139396234326136_31373038303337303231.svg"
          />
        </a>
        <button v-if="hasAccessEdit" class="bttn-round" @click="editTeam">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M3 17.25V21h3.75L17.81
              9.94l-3.75-3.75L3 17.25zM20.71
              7.04c.39-.39.39-1.02
              0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41
              0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import iconInfo from "@/components/icon-info.vue";
import { mapState } from "vuex";

export default {
  components: { iconInfo },
  props: {
    team: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    ...mapState({
      hasAccessEdit: (state) => state.account.access.CanEditCampaignTeams,
      selectedCmpCurrency: (state) =>
        state.dashboard.campaignDashboard.data.attributes.currency_code,
      lang: (state) => state.i18n.locale,
      campaignId: (state) => state.dashboard.selectedCampaign,
      selectedCampaign(state) {
        return state.campaigns.campaigns.find((cmp) => cmp.id === this.campaignId);
      },
    }),

    teamAttr() {
      return this.team.attributes;
    },

    totalAmount() {
      return this.teamAttr.extend?.stats?.total_amount || 0;
    },

    totalCount() {
      return this.teamAttr.extend?.stats?.total_count || 0;
    },

    diffGoalRaised() {
      const diff = this.teamAttr.goal - this.totalAmount;

      let formattedDiff = 0;
      try {
        formattedDiff = new Intl.NumberFormat(this.lang).format(diff);
      } catch {
        formattedDiff = new Intl.NumberFormat("en").format(diff);
      }

      return formattedDiff < 0 ? 0 : formattedDiff;
    },

    diffInternalGoalRaised() {
      return (this.teamAttr.internal_goal && this.teamAttr.internal_goal - this.totalAmount) || 0;
    },

    diffGoalRaisedPercent() {
      if (this.teamAttr.goal) {
        return (this.totalAmount / this.teamAttr.goal) * 100;
      } else {
        return 0;
      }
    },

    diffInternalGoalRaisedPercent() {
      if (this.teamAttr.internal_goal) {
        return (this.totalAmount / this.teamAttr.internal_goal) * 100;
      } else {
        return 0;
      }
    },

    percent() {
      if (this.teamAttr.goal === 0) {
        return 0;
      }
      return (this.totalAmount / (this.teamAttr.goal || 1)) * 100;
    },

    percentFormated() {
      const value = this.percent > 100 ? 100 : this.percent;

      return `${value.toFixed(2).replace(".00", "")}%`;
    },

    childrenTotalGoal() {
      return (
        (this.teamAttr.extend &&
          this.teamAttr.extend.stats &&
          this.teamAttr.extend.stats.children_total_goal) ||
        0
      );
    },

    childrenTotalInternalGoal() {
      return (
        (this.teamAttr.extend &&
          this.teamAttr.extend.stats &&
          this.teamAttr.extend.stats.children_total_internal_goal) ||
        0
      );
    },

    waHref() {
      let waPhone = this.teamAttr.phone;
      if (!waPhone) {
        return false;
      }

      waPhone = waPhone.replace(/\D/g, "");
      const domain = this.selectedCampaign.attributes.short_url || "www.charidy.com";
      const text = `https://${domain}/${this.selectedCampaign.attributes.short_link}/${this.teamAttr.slug}`;

      return `https://wa.me/${waPhone}?text=${encodeURI(text)}`;
    },
  },

  methods: {
    editTeam() {
      this.$emit("edit-team", this.team);
    },

    tooltipTkey(value) {
      let prefix = "_60";
      let fallback = "";

      if (value <= 60) {
        prefix = "_60";
        fallback = "Indicator for the team that is not yet reached the goal";
      }
      if (value > 60 && value <= 80) {
        prefix = "_80";
        fallback = "Indicator for the team that is not yet reached the goal";
      }
      if (value > 80 && value <= 100) {
        prefix = "_100";
        fallback = "Indicator for the team that is not yet reached the goal";
      }
      if (value > 100) {
        prefix = "_100_and_more";
        fallback = "Indicator for the team that reached the goal";
      }

      return this.$t(`dashboard.team_diff_tooltip_${prefix}`, fallback);
    },
    diffIconClass(value) {
      return {
        diff__60: value <= 60,
        diff__80: value > 60 && value <= 80,
        diff__100: value > 80 && value <= 100,
        diff__100_and_more: value > 100,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-box {
  display: inherit;
  margin-right: 10px;
}

.donation-comment {
  font-weight: bold;
  color: #414359;
  line-height: 1;
  font-size: 14px;
  padding-left: calc(10% + 40px);
  span {
    color: #bbbcc4;
    @media (max-width: 768px) {
      display: block;
      padding-bottom: 10px;
    }
  }
  @media (max-width: 768px) {
    padding-left: 0;
    text-align: center;
    padding-top: 20px;
  }
}
.bttn-round--gray {
  &:hover {
    background-color: darken(#bbbcc4, 15%);
  }
}
.diff {
  &__60 {
    color: var(--danger);
  }
  &__80 {
    color: var(--warning);
  }
  &__100 {
    color: var(--success);
  }
  &__100_and_more {
    color: darken($color: #28a745, $amount: 10);
  }
}
</style>
