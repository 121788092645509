<template>
  <div class="teams-statistics">
    <b-card
      header-bg-variant="white"
      border-variant="Light"
      bg-variant="light"
      :title="$t('dashboard.team_statistics_title', 'Statistics')"
    >
      <b-overlay :show="loading" rounded="sm">
        <b-card-body>
          <div class="d-flex flex-column flex-lg-row justify-content-around flex-wrap">
            <div class="flex-fill elm-hover shadow bg-white mr-3 mt-3 p-2">
              <div>
                <b-media vertical-align="center">
                  <template #aside>
                    <span style="font-size: 2rem">
                      <b-icon
                        icon="clipboard-data"
                        class="border border-info rounded p-1"
                        variant="info"
                      ></b-icon>
                    </span>
                  </template>
                  <div>
                    <p style="font-size: 2.5vh" class="mt-3 mb-1 statistics-media">
                      {{ campaignCurrencySign }} {{ totalGoals.toLocaleString() }}
                    </p>
                    <p class="mb-0 text-capitalize text-secondary">
                      {{ $t("dashboard.team_statistics_total_goals", "total goals") }}
                    </p>
                  </div>
                </b-media>
              </div>
            </div>

            <div class="flex-fill elm-hover shadow bg-white mr-3 mt-3 p-2">
              <div>
                <b-media vertical-align="center">
                  <template #aside>
                    <span style="font-size: 2rem">
                      <b-icon
                        icon="credit-card-2-front"
                        class="border border-success rounded p-1"
                        variant="success"
                      ></b-icon>
                    </span>
                  </template>
                  <div>
                    <p style="font-size: 2.5vh" class="mt-3 mb-1 statistics-media">
                      {{ campaignCurrencySign }} {{ totalRaised.toLocaleString() }}
                    </p>
                    <p class="mb-0 text-capitalize text-secondary">
                      {{ $t("dashboard.team_statistics_total_raised", "total raised") }}
                    </p>
                  </div>
                </b-media>
              </div>
            </div>

            <div class="flex-fill elm-hover shadow bg-white mr-3 mt-3 p-2">
              <div>
                <b-media vertical-align="center">
                  <template #aside>
                    <span style="font-size: 2rem">
                      <b-icon
                        icon="arrow-down-up"
                        class="border border-warning rounded p-1"
                        variant="warning"
                      ></b-icon>
                    </span>
                  </template>
                  <div>
                    <p style="font-size: 2.5vh" class="mt-3 mb-1 statistics-media">
                      {{ campaignCurrencySign }} {{ diff.toLocaleString() }}
                    </p>
                    <p class="mb-0 text-capitalize text-secondary">
                      {{ $t("dashboard.team_statistics_diff", "Diff (goal-raised)") }}
                    </p>
                  </div>
                </b-media>
              </div>
            </div>

            <div class="flex-fill elm-hover shadow bg-white mr-3 mt-3 p-2">
              <div>
                <b-media vertical-align="center">
                  <template #aside>
                    <span style="font-size: 2rem">
                      <b-icon
                        icon="people"
                        class="border border-primary rounded p-1"
                        variant="primary"
                      ></b-icon>
                    </span>
                  </template>
                  <div>
                    <p style="font-size: 2.5vh" class="mt-3 mb-1 statistics-media">
                      {{ totalDonors.toLocaleString() }}
                    </p>
                    <p class="mb-0 text-capitalize text-secondary">
                      {{ $t("dashboard.team_statistics_total_donors", "total donors") }}
                    </p>
                  </div>
                </b-media>
              </div>
            </div>

            <div class="flex-fill elm-hover shadow bg-white mr-3 mt-3 p-2">
              <div>
                <b-media vertical-align="center">
                  <template #aside>
                    <span style="font-size: 2rem">
                      <b-icon
                        icon="trophy"
                        class="border border-secondary rounded p-1"
                        variant="secondary"
                      ></b-icon>
                    </span>
                  </template>
                  <div>
                    <p style="font-size: 2.5vh" class="mt-3 mb-1 statistics-media">
                      {{ campaignCurrencySign }} {{ averageDonation.toLocaleString() }}
                    </p>
                    <p class="mb-0 text-capitalize text-secondary">
                      {{ $t("dashboard.team_statistics_average_donation", "average donation") }}
                    </p>
                  </div>
                </b-media>
              </div>
            </div>

            <div class="flex-fill elm-hover shadow bg-white mr-3 mt-3 p-2">
              <div>
                <b-media vertical-align="center">
                  <template #aside>
                    <span style="font-size: 2rem">
                      <b-icon
                        icon="list-ol"
                        class="border border-c-secondary rounded p-1"
                        variant="c-secondary"
                      ></b-icon>
                    </span>
                  </template>
                  <div>
                    <p style="font-size: 2.5vh" class="mt-3 mb-1 statistics-media">
                      {{ teams.length }}
                    </p>
                    <p class="mb-0 text-capitalize text-secondary">
                      {{ $t("dashboard.team_statistics_teams_number", "Number of teams") }}
                    </p>
                  </div>
                </b-media>
              </div>
            </div>
          </div>

          <b-row class="mt-3">
            <b-col class="col elm-hover shadow bg-white mr-3 mt-3 pb-3">
              <h5 class="mt-3">
                {{ $t("dashboard.team_statistics_progress", "Progress") }}
              </h5>
              <b-progress>
                <b-progress-bar
                  :value="progress"
                  showValue
                  variant="success"
                  striped
                  :label="`${progress}%`"
                ></b-progress-bar>
              </b-progress>
            </b-col>
          </b-row>
        </b-card-body>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  props: ["collapse", "teams"],
  data() {
    return {
      loading: false,
      totalDonors: 0,
      totalGoals: 0,
      totalRaised: 0,
      diff: 0,
      averageDonation: 0,
      progress: 0,
    };
  },
  created() {},
  watch: {
    collapse(val) {
      if (val) {
        this.getStatistics(this.teams);
      }
    },
    campaignId() {
      this.$emit("close-collapse");
    },
    teams() {
      this.getStatistics(this.teams);
    },
  },
  computed: {
    ...mapState({
      campaignCurrencySign: (state) => state.campaign.campaign.data.attributes.currency_sign,
      campaignId: (state) => state.dashboard.selectedCampaign,
      teamsCount: (state) => state.teams.teamsCount,
      totalTeamsGoal: (state) => state.teams.totalTeamsGoal,
    }),
  },
  methods: {
    getStatistics(teams) {
      this.loading = true;
      this.resetStatistics();
      let total_count = null;
      let total_amount = null;
      let total_goal = null;

      if (teams.length > 0) {
        teams.forEach((element) => {
          total_count += element.attributes.extend.stats.total_count;
          total_amount += element.attributes.extend.stats.total_amount;
          total_goal += element.attributes.goal;
        });
        this.totalDonors = total_count;
        this.totalGoals = total_goal;
        this.totalRaised = total_amount;
        this.diff = this.totalRaised - this.totalGoals;
        this.averageDonation = Math.floor((this.totalRaised / this.totalDonors).toFixed(2));
        this.averageDonation = this.averageDonation > 0 ? this.averageDonation : 0;
        this.progress = Math.floor((this.totalRaised / this.totalGoals) * 100);
      }
      this.loading = false;
    },
    resetStatistics() {
      this.totalDonors = 0;
      this.totalGoals = 0;
      this.totalRaised = 0;
      this.diff = 0;
      this.averageDonation = 0;
      this.progress = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.elm-hover {
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.elm-hover:hover {
  transform: scale(1.05);
  box-shadow: 10px 10px 10px #888888;
}
</style>
