<template>
  <div class="top-stat-dropdown">
    <div class="inner-block">
      <div class="inner-block__total">
        <div class="total-row">
          <span></span>
          <span v-if="hasReal">
            {{ $t("dashboard.real_amount", "Real") }}
          </span>
          <span>
            {{ $t("dashboard.efective_amount", "Efective") }}
          </span>
        </div>
        <div class="total-row">
          <span>
            {{ $t("dashboard.total_online") }}
          </span>
          <span v-if="hasReal"> {{ currency }} {{ totals.onlineReal.toLocaleString() }} </span>
          <span> {{ currency }} {{ totals.online.toLocaleString() }} </span>
        </div>
        <div class="total-row">
          <span>
            {{ $t("dashboard.total_offline") }}
          </span>
          <span v-if="hasReal"> {{ currency }} {{ totals.offlineReal.toLocaleString() }} </span>
          <span> {{ currency }} {{ totals.offline.toLocaleString() }} </span>
        </div>
      </div>

      <div class="inner-block__gateways">
        <div class="total-row" v-for="(gateway, index) in gateways" :key="index">
          <span>
            {{ gateway[0] }}
          </span>
          <span v-if="getAmounts(gateway[1]).real">
            {{ currency }} {{ getAmounts(gateway[1]).real.toLocaleString() }}
          </span>
          <span> {{ currency }} {{ getAmounts(gateway[1]).total.toLocaleString() }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dashboardTopStatDropDown",
  props: {
    currency: {
      type: String,
      default: "$",
    },
    totals: {
      type: Object,
      default: () => {},
    },
    gateways: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    hasReal() {
      return (
        this.totals.offlineReal ||
        this.totals.onlineReal ||
        Number.isNaN(Number(this.gateways[1]?.[1]))
      );
    },
  },

  methods: {
    getAmounts(data) {
      return {
        real: data?.real || 0,
        total: Number.isNaN(Number(data)) ? data?.total || 0 : data,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.top-stat-dropdown {
  visibility: hidden;
  // visibility: visible;
  width: 100%;
  padding: 5px 0 0;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
}
.inner-block {
  padding: 15px;
  // border: 1px solid $bb-100;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  &__total {
    border-bottom: 1px solid #e6eaed;
    padding-bottom: 4px;
    margin-bottom: 6px;
  }
  &__gateways {
    padding-left: 10px;
    .total-row {
      color: lighten($fd, 10%);
      font-size: 13px;
      span {
        font-family: $vito-light;
      }
    }
  }
}
.total-row {
  display: flex;
  :nth-child(1) {
    flex: 2;
  }
  span {
    flex: 1;
  }
  align-items: center;
  justify-content: space-between;
  font-family: $vito-bold;
  font-size: 14px;
  line-height: 1.8;
  text-transform: uppercase;
  color: $fd;
}
</style>
