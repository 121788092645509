<template>
  <form
    id="newsletter"
    action="https://charidy.us7.list-manage.com/subscribe/post?u=ae65b2106f3047212cefe2102&id=a565dfb434"
    method="post"
    target="_blank"
  >
    <CdnInput
      id="mce-EMAIL"
      :hideTitle="hideTitle"
      :variant="variant"
      size="lg"
      type="email"
      :placeholder="$t('home_page_v2.newsletter_placeholder', 'Email Address')"
      name="EMAIL"
    >
      <template #default>
        {{ $t("home_page_v2.sign_up_to_our_newsletter", "Sign Up To Our Newsletter") }}
      </template>
      <template #after>
        <CdnButton type="submit">
          {{ $t("home_page_v2.newsletter_send", "Send") }}
        </CdnButton>
      </template>
    </CdnInput>
  </form>
</template>

<script>
import CdnInput from "./cdn-input.vue";
import CdnButton from "./cdn-button.vue";

export default {
  props: {
    hideTitle: { type: Boolean, default: false },
    variant: { type: String, default: "dark" },
  },
  components: { CdnInput, CdnButton },
};
</script>
