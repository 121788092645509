<template>
  <div>
    <div class="dashboard-content-wrapper">
      <div class="dashboard-block dashboard-donations">
        <div class="dashboard-donations__header">
          <h2 class="dashboard-block__title">{{ $t("dashboard.title_donation") }}</h2>
          <div class="btn-wrapper">
            <div v-if="!loadingCampaign">
              <donation-add-modal
                :campaignid="campaignid"
                :ccy="selectedCampaign.data.attributes.currency_code"
                ref="donationAddModal"
              ></donation-add-modal>
              <modal-import
                @get-donors-list="getNewPageDonation"
                :campaignid="campaignid"
                ref="importList"
              ></modal-import>
            </div>
            <b-dropdown
              :disabled="(mode !== 1 && mode !== 2) || !hasAccessImportDonation"
              id="import"
              right
              variant="-sm bttn--orange"
              :text="$t('dashboard.import_donation_button_title')"
            >
              <b-dropdown-item @click="openModalImportManual" class="manual-hidden">
                {{ $t("dashboard.import_manual_button_title") }}
              </b-dropdown-item>
              <b-dropdown-item @click="openModalImportList">
                {{ $t("dashboard.import_button_title") }}
              </b-dropdown-item>
            </b-dropdown>

            <b-dropdown
              id="export-action"
              right
              variant="-sm bttn--orange"
              :text="$t('dashboard.export_donation_button_title')"
            >
              <b-dropdown-item v-if="canExportAllOrgDonationList" @click="openModalExportDonations">
                {{ $t("dashboard.export_button_title") }}
              </b-dropdown-item>
              <b-dropdown-item @click="openModalExportTeams">
                {{ $t("dashboard.export_button_teams_title") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="canExportOrganizationReceiptList"
                @click="openModalExportReceipt"
              >
                {{ $t("dashboard.export_button_receipt_title") }}
              </b-dropdown-item>
            </b-dropdown>

            <export-teams ref="exportTeams"> </export-teams>

            <modal-export
              ref="exportDonations"
              :campaignid="campaignid"
              :category="category"
              :customLabel="customLabel"
              :gateways="gateways"
              :canSeeInputSearch="canSeeInputSearch"
              :multiselectTeam="multiselectTeam"
              :teamList="teamList"
              :filters="exportFilters"
              :isHasLinkedCampaigns="isHasLinkedCampaigns"
            >
            </modal-export>
            <export-receipts ref="exportReceipts" :campaignid="campaignid"></export-receipts>

            <modal-import-manual ref="importManual" :campaignid="campaignid"></modal-import-manual>
          </div>
        </div>

        <div
          v-if="showDoantionTabs"
          class="dashboard-donations__moderation"
          :class="moderation ? 'moderation' : ''"
        >
          <button
            class="bttn bttn--sm"
            :class="donationTab === 'all' ? 'bttn--blue' : 'bttn--inverted-blue'"
            @click="setDonationTab('all')"
          >
            {{ $t("dashboard.on_hold_all", "All") }}
          </button>

          <button
            v-if="donationsPremoderation.value"
            class="bttn bttn--sm"
            :class="moderation ? 'bttn--blue' : 'bttn--inverted-blue'"
            @click="setDonationTab('moderation')"
          >
            Pending Moderation ({{ pendingDonationCounter }})
          </button>

          <button
            v-if="unpublishedActivated"
            class="bttn bttn--sm"
            :class="donationTab === 'onhold' ? 'bttn--blue' : 'bttn--inverted-blue'"
            @click="setDonationTab('onhold')"
          >
            {{
              $t(
                "dashboard.donations_on_hold",
                "Donation On Hold ({unpublishedCount}) ::: Donations On Hold ({unpublishedCount})",
                { unpublishedCount },
                unpublishedCount
              )
            }}
          </button>

          <div
            class="ml-auto"
            v-if="donationTab === 'onhold' && dashboardData.attributes.total_real_payment_onhold"
          >
            <span>
              Total Amount: {{ selectedCampaignSign
              }}{{ dashboardData.attributes.total_real_payment_onhold }}
            </span>
          </div>
        </div>

        <div class="dashboard-donations__filters" v-if="donationTab === 'all'">
          <!-- search -->
          <div class="filter-block dashboard-donation__search" v-if="canSeeInputSearch">
            <div class="search">
              <svg viewBox="0 0 17 17">
                <g fill="#107598" fill-rule="nonzero">
                  <path
                    d="M11.22 3.29c.2.2.38.41.55.64l-.12-.16c.33.44.61.93.83 1.44l-.08-.19a6.76 6.76 0 0 1 .42 1.48 6.92 6.92 0 0 1 .03 1.52 6.76 6.76 0 0 1-.45 1.69l.08-.2c-.22.52-.5 1-.83 1.45l.12-.16c-.34.45-.74.85-1.18 1.2l.16-.12c-.43.34-.91.62-1.42.84l.2-.08a6.41 6.41 0 0 1-1.45.43 6.52 6.52 0 0 1-1.5.03 6.41 6.41 0 0 1-1.65-.46l.19.08c-.5-.22-.98-.5-1.42-.84l.16.12a6.67 6.67 0 0 1-1.18-1.2l.12.16a6.78 6.78 0 0 1-.83-1.45l.08.2a6.76 6.76 0 0 1-.42-1.48 6.92 6.92 0 0 1-.03-1.52c.08-.58.23-1.15.45-1.69l-.08.2c.22-.52.5-1 .83-1.45l-.12.16c.34-.45.74-.86 1.18-1.2l-.16.12c.44-.34.91-.62 1.42-.84l-.2.08a6.41 6.41 0 0 1 1.45-.43 6.52 6.52 0 0 1 1.5-.03c.57.08 1.12.23 1.65.46L9.33 2a6.43 6.43 0 0 1 1.89 1.28c.3.3.83.32 1.11 0a.83.83 0 0 0 0-1.14A7.13 7.13 0 0 0 .92 3.77a7.46 7.46 0 0 0 .85 8.43 7.16 7.16 0 0 0 9.02 1.58 7.53 7.53 0 0 0 2.48-2.38 7.46 7.46 0 0 0-.94-9.25c-.29-.3-.82-.32-1.11 0a.83.83 0 0 0 0 1.14z"
                  />
                  <path
                    d="M11.28 12.42l1.47 1.47 2.34 2.34.54.54c.3.3.85.32 1.14 0a.82.82 0 0 0 0-1.14l-1.47-1.47-2.34-2.34-.54-.54c-.3-.3-.85-.32-1.14 0a.82.82 0 0 0 0 1.14z"
                  />
                </g>
              </svg>
              <input
                class="search__input"
                type="text"
                :placeholder="$t('dashboard.select_filter_placeholder_input')"
                v-model="searchQ"
              />
            </div>
          </div>

          <!-- select team -->
          <div class="filter-block dashboard-donation__search" v-if="canSeeInputSearch">
            <div class="search">
              <svg viewBox="0 0 17 17">
                <g fill="#107598" fill-rule="nonzero">
                  <path
                    d="M11.22 3.29c.2.2.38.41.55.64l-.12-.16c.33.44.61.93.83 1.44l-.08-.19a6.76 6.76 0 0 1 .42 1.48 6.92 6.92 0 0 1 .03 1.52 6.76 6.76 0 0 1-.45 1.69l.08-.2c-.22.52-.5 1-.83 1.45l.12-.16c-.34.45-.74.85-1.18 1.2l.16-.12c-.43.34-.91.62-1.42.84l.2-.08a6.41 6.41 0 0 1-1.45.43 6.52 6.52 0 0 1-1.5.03 6.41 6.41 0 0 1-1.65-.46l.19.08c-.5-.22-.98-.5-1.42-.84l.16.12a6.67 6.67 0 0 1-1.18-1.2l.12.16a6.78 6.78 0 0 1-.83-1.45l.08.2a6.76 6.76 0 0 1-.42-1.48 6.92 6.92 0 0 1-.03-1.52c.08-.58.23-1.15.45-1.69l-.08.2c.22-.52.5-1 .83-1.45l-.12.16c.34-.45.74-.86 1.18-1.2l-.16.12c.44-.34.91-.62 1.42-.84l-.2.08a6.41 6.41 0 0 1 1.45-.43 6.52 6.52 0 0 1 1.5-.03c.57.08 1.12.23 1.65.46L9.33 2a6.43 6.43 0 0 1 1.89 1.28c.3.3.83.32 1.11 0a.83.83 0 0 0 0-1.14A7.13 7.13 0 0 0 .92 3.77a7.46 7.46 0 0 0 .85 8.43 7.16 7.16 0 0 0 9.02 1.58 7.53 7.53 0 0 0 2.48-2.38 7.46 7.46 0 0 0-.94-9.25c-.29-.3-.82-.32-1.11 0a.83.83 0 0 0 0 1.14z"
                  />
                  <path
                    d="M11.28 12.42l1.47 1.47 2.34 2.34.54.54c.3.3.85.32 1.14 0a.82.82 0 0 0 0-1.14l-1.47-1.47-2.34-2.34-.54-.54c-.3-.3-.85-.32-1.14 0a.82.82 0 0 0 0 1.14z"
                  />
                </g>
              </svg>
              <multiselect
                v-model="multiselectTeam"
                @search-change="multiselectTeamQueryChange"
                :options="teamListFilter"
                track-by="text"
                :placeholder="$t('dashboard.select_teams')"
                :customLabel="customLabel"
                :multiple="false"
                selectLabel=""
                selectGroupLabel=""
                selectedLabel=""
                deselectLabel=""
                deselectGroupLabel=""
                :internal-search="false"
                class="team-multiselect"
              >
                <template #option="{ option }">
                  <span class="team-multiselect__option">
                    {{ `${option.value > 0 ? `[${option.value}]` : ""}` }} {{ option.text }}
                  </span>
                </template>
              </multiselect>
            </div>
          </div>

          <!-- gateways -->
          <div class="filter-block dashboard-donation__status">
            <div class="select">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>
              <select class="select__select" v-model="gatewayFilter">
                <option value="">{{ $t("dashboard.select_filter_please_select_gateway") }}</option>
                <option
                  v-for="gateway in gateways"
                  :key="gateway.id"
                  :value="gateway.attributes.value"
                >
                  {{ $t("dashboard.select_filter_" + gateway.attributes.text) }}
                </option>
              </select>
            </div>
          </div>

          <!-- statuses -->
          <div class="filter-block dashboard-donation__type">
            <div class="select">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>
              <select class="select__select" v-model="filterStatus">
                <option value="">{{ $t("dashboard.select_filter_please_select_status") }}</option>
                <option value="authorized">{{ $t("dashboard.select_filter_authorized") }}</option>
                <option value="canceled">{{ $t("dashboard.select_filter_canceled") }}</option>
                <option value="processed">{{ $t("dashboard.select_filter_processed") }}</option>
                <option value="pending">{{ $t("dashboard.select_filter_pending") }}</option>
                <option value="failed">{{ $t("dashboard.select_filter_failed") }}</option>
                <option value="unpublished">
                  {{ $t("dashboard.select_filter_unpublished", "Unpublished") }}
                </option>
              </select>
            </div>
          </div>

          <!-- donation type -->
          <div class="filter-block dashboard-donation__recurring">
            <div class="select">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>
              <select class="select__select" v-model="recurringFilter">
                <option value="-1">
                  {{ $t("dashboard.recurring_filter_recurring_and_onetime") }}
                </option>
                <option value="1">{{ $t("dashboard.recurring_filter_recurring_only") }}</option>
                <option value="0">{{ $t("dashboard.recurring_filter_onetime_only") }}</option>
              </select>
            </div>
          </div>

          <!-- donation amount filter -->
          <div class="filter-block dashboard-donation__amount">
            <b-input-group>
              <template #prepend>
                <b-dropdown
                  :text="getAmountTk(amountFilterKey)"
                  class="h-100"
                  variant="custom"
                  toggle-class="h-100 donation-amount-filter-ddl"
                >
                  <b-dropdown-item @click="amountFilterKey = 'gt_real'">
                    {{ getAmountTk("gt_real") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="amountFilterKey = 'eq_real'">
                    {{ getAmountTk("eq_real") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="amountFilterKey = 'lt_real'">
                    {{ getAmountTk("lt_real") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="amountFilterKey = 'gt_total'">
                    {{ getAmountTk("gt_total") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="amountFilterKey = 'eq_total'">
                    {{ getAmountTk("eq_total") }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="amountFilterKey = 'lt_total'">
                    {{ getAmountTk("lt_total") }}
                  </b-dropdown-item>
                </b-dropdown>
              </template>

              <b-form-input
                v-model.number="amountFilterValue"
                class="h-100 donation-amount-filter-input"
                debounce="500"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                :placeholder="$t('dashboard.amount_filter_input_placeholder', 'Enter Amount')"
              ></b-form-input>
            </b-input-group>
          </div>

          <!-- payment city -->
          <div class="filter-block dashboard-donation__search" v-if="canSeeInputSearch">
            <div class="search">
              <svg viewBox="0 0 17 17">
                <g fill="#107598" fill-rule="nonzero">
                  <path
                    d="M11.22 3.29c.2.2.38.41.55.64l-.12-.16c.33.44.61.93.83 1.44l-.08-.19a6.76 6.76 0 0 1 .42 1.48 6.92 6.92 0 0 1 .03 1.52 6.76 6.76 0 0 1-.45 1.69l.08-.2c-.22.52-.5 1-.83 1.45l.12-.16c-.34.45-.74.85-1.18 1.2l.16-.12c-.43.34-.91.62-1.42.84l.2-.08a6.41 6.41 0 0 1-1.45.43 6.52 6.52 0 0 1-1.5.03 6.41 6.41 0 0 1-1.65-.46l.19.08c-.5-.22-.98-.5-1.42-.84l.16.12a6.67 6.67 0 0 1-1.18-1.2l.12.16a6.78 6.78 0 0 1-.83-1.45l.08.2a6.76 6.76 0 0 1-.42-1.48 6.92 6.92 0 0 1-.03-1.52c.08-.58.23-1.15.45-1.69l-.08.2c.22-.52.5-1 .83-1.45l-.12.16c.34-.45.74-.86 1.18-1.2l-.16.12c.44-.34.91-.62 1.42-.84l-.2.08a6.41 6.41 0 0 1 1.45-.43 6.52 6.52 0 0 1 1.5-.03c.57.08 1.12.23 1.65.46L9.33 2a6.43 6.43 0 0 1 1.89 1.28c.3.3.83.32 1.11 0a.83.83 0 0 0 0-1.14A7.13 7.13 0 0 0 .92 3.77a7.46 7.46 0 0 0 .85 8.43 7.16 7.16 0 0 0 9.02 1.58 7.53 7.53 0 0 0 2.48-2.38 7.46 7.46 0 0 0-.94-9.25c-.29-.3-.82-.32-1.11 0a.83.83 0 0 0 0 1.14z"
                  />
                  <path
                    d="M11.28 12.42l1.47 1.47 2.34 2.34.54.54c.3.3.85.32 1.14 0a.82.82 0 0 0 0-1.14l-1.47-1.47-2.34-2.34-.54-.54c-.3-.3-.85-.32-1.14 0a.82.82 0 0 0 0 1.14z"
                  />
                </g>
              </svg>
              <input
                class="search__input"
                type="text"
                :placeholder="$t('dashboard.search_payment_city')"
                v-model="paymentCityQ"
              />
            </div>
          </div>

          <!-- payment postcode -->
          <div class="filter-block dashboard-donation__search" v-if="canSeeInputSearch">
            <div class="search">
              <svg viewBox="0 0 17 17">
                <g fill="#107598" fill-rule="nonzero">
                  <path
                    d="M11.22 3.29c.2.2.38.41.55.64l-.12-.16c.33.44.61.93.83 1.44l-.08-.19a6.76 6.76 0 0 1 .42 1.48 6.92 6.92 0 0 1 .03 1.52 6.76 6.76 0 0 1-.45 1.69l.08-.2c-.22.52-.5 1-.83 1.45l.12-.16c-.34.45-.74.85-1.18 1.2l.16-.12c-.43.34-.91.62-1.42.84l.2-.08a6.41 6.41 0 0 1-1.45.43 6.52 6.52 0 0 1-1.5.03 6.41 6.41 0 0 1-1.65-.46l.19.08c-.5-.22-.98-.5-1.42-.84l.16.12a6.67 6.67 0 0 1-1.18-1.2l.12.16a6.78 6.78 0 0 1-.83-1.45l.08.2a6.76 6.76 0 0 1-.42-1.48 6.92 6.92 0 0 1-.03-1.52c.08-.58.23-1.15.45-1.69l-.08.2c.22-.52.5-1 .83-1.45l-.12.16c.34-.45.74-.86 1.18-1.2l-.16.12c.44-.34.91-.62 1.42-.84l-.2.08a6.41 6.41 0 0 1 1.45-.43 6.52 6.52 0 0 1 1.5-.03c.57.08 1.12.23 1.65.46L9.33 2a6.43 6.43 0 0 1 1.89 1.28c.3.3.83.32 1.11 0a.83.83 0 0 0 0-1.14A7.13 7.13 0 0 0 .92 3.77a7.46 7.46 0 0 0 .85 8.43 7.16 7.16 0 0 0 9.02 1.58 7.53 7.53 0 0 0 2.48-2.38 7.46 7.46 0 0 0-.94-9.25c-.29-.3-.82-.32-1.11 0a.83.83 0 0 0 0 1.14z"
                  />
                  <path
                    d="M11.28 12.42l1.47 1.47 2.34 2.34.54.54c.3.3.85.32 1.14 0a.82.82 0 0 0 0-1.14l-1.47-1.47-2.34-2.34-.54-.54c-.3-.3-.85-.32-1.14 0a.82.82 0 0 0 0 1.14z"
                  />
                </g>
              </svg>
              <input
                class="search__input"
                type="text"
                :placeholder="$t('dashboard.search_payment_postcode')"
                v-model="paymentPostcodeQ"
              />
            </div>
          </div>

          <div
            class="filter-block dashboard-donation__matchedStatus"
            v-if="allowConditionalDonations"
          >
            <div class="select">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>
              <select class="select__select" v-model="matchedStatusFilter">
                <option value="-1">
                  {{ $t("dashboard.matched_status_filter_all", "All (no matched donations)") }}
                </option>
                <option value="0">
                  {{ $t("dashboard.matched_status_filter_to_be_matched", "To be matched") }}
                </option>
                <option value="1">
                  {{ $t("dashboard.matched_status_filter_matched", "Matched") }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div
          class="dashboard-donations__bulk"
          v-if="showBulk('all') && onHoldBulkActionsConditions.unpublishDonations"
        >
          <b-form-group
            label-cols="5"
            :label="$t('dashboard.donations_bulk_actions', 'Bulk Actions:')"
          >
            <button class="bttn bttn--orange" @click="publishSelectedBulk({ publish: false })">
              {{
                $t("dashboard.donations_bulk_actions_unpublish", "Unpublish Selected ({count})", {
                  count: bulkPublishedDonations.length,
                })
              }}
            </button>
          </b-form-group>
        </div>

        <div class="dashboard-donations__bulk" v-if="showBulk('onhold')">
          <b-form-group
            label-cols="5"
            :label="$t('dashboard.donations_bulk_actions', 'Bulk Actions:')"
            label-for="input-default"
          >
            <div class="d-flex">
              <button
                class="bttn bttn--orange"
                @click="publishSelectedBulk"
                :disabled="!onHoldBulkActionsConditions.publishDonations"
              >
                {{
                  $t(
                    "dashboard.donations_bulk_actions_publish_onhold",
                    "Publish Selected ({count})",
                    { count: bulkUnpublishedDonations.length }
                  )
                }}
              </button>

              <button
                v-if="unpublishedDonations.length"
                class="bttn bttn--orange"
                @click="publishAll"
              >
                {{
                  $t("dashboard.donations_bulk_actions_publish_onhold_all", "Publish All Donations")
                }}
              </button>
            </div>
          </b-form-group>
        </div>

        <div v-if="pdfLinkMeta && multiselectTeam" class="pdf-button-donations">
          <a
            :href="`${apiUrl}/api/v1/campaign/${campaignId}/pdfreport/team_donor_list?team_id=${multiselectTeam.value}`"
            target="_blank"
          >
            {{ $t("dashboard.donations_pdf_link_open") }}
          </a>
        </div>

        <div class="dashboard-donations__th" v-if="donationTab === 'all'">
          <div class="th-item th-item--5"></div>
          <div class="th-item">#</div>
          <div class="th-item clickable th-item--name" @click="setSort('billing_name')">
            {{ $t("dashboard.donation_name", "Billing Name") }}
            <i :class="classSortBy('billing_name')"></i>
          </div>
          <div class="th-item clickable th-item--name" @click="setSort('display_name')">
            {{ $t("dashboard.donation_display_name", "Display Name") }}
            <i :class="classSortBy('display_name')"></i>
          </div>
          <div class="th-item clickable" @click="setSort('bank_name')">
            {{ $t("dashboard.donation_methods", "Gateway") }}
            <i :class="classSortBy('bank_name')"></i>
          </div>
          <div class="th-item clickable" @click="setSort('charged_amount')">
            {{ $t("dashboard.donation_amount_charged", "Amount charged") }}
            <i :class="classSortBy('charged_amount')"></i>
          </div>
          <div class="th-item clickable" @click="setSort('effective_amount')">
            {{ $t("dashboard.donation_amount_effective", "Amount effective") }}
            <i :class="classSortBy('effective_amount')"></i>
          </div>
          <div class="th-item clickable th-item--15" @click="setSort('email')">
            {{ $t("dashboard.donation_email_phone", "Email / Phone") }}
            <i :class="classSortBy('email')"></i>
          </div>
          <div class="th-item clickable" @click="setSort('date')">
            {{ $t("dashboard.donation_date", "Date") }}
            <i :class="classSortBy('date')"></i>
          </div>
          <div class="th-item">
            {{ $t("dashboard.donation_status", "Status") }}
          </div>
        </div>

        <div class="dashboard-donations__th dashboard-donations__th--modeartion" v-if="moderation">
          <div class="th-item">#</div>
          <!--<div class="th-item th-item&#45;&#45;name">{{ $t('dashboard.donation_name') }}</div>-->
          <div class="th-item th-item--20">
            {{ $t("dashboard.donation_display_name") }}
          </div>
          <!--<div class="th-item">{{ $t('dashboard.donation_methods') }}</div>-->
          <!--<div class="th-item">{{ $t('dashboard.donation_amount_charged') }}</div>-->
          <!--<div class="th-item">{{ $t('dashboard.donation_amount_effective') }}</div>-->
          <div class="th-item th-item--40">
            {{ $t("dashboard.donation_dedication") }}
          </div>
          <!--<div class="th-item">{{ $t('dashboard.donation_date') }}</div>-->
          <!--<div class="th-item">{{ $t('dashboard.donation_status') }}</div>-->
        </div>

        <div
          v-if="donationTab === 'onhold'"
          class="dashboard-donations__th dashboard-donations__th--modeartion"
        >
          <div class="th-item th-item--5"></div>
          <div class="th-item">#</div>
          <!--<div class="th-item th-item&#45;&#45;name">{{ $t('dashboard.donation_name') }}</div>-->
          <div class="th-item">
            {{ $t("dashboard.donation_display_name") }}
          </div>
          <!--<div class="th-item">{{ $t('dashboard.donation_amount_charged') }}</div>-->
          <div class="th-item th-item--10">
            {{ $t("dashboard.donation_dedication") }}
          </div>
          <div class="th-item">
            {{ $t("dashboard.donation_methods") }}
          </div>
          <div class="th-item">
            {{ $t("dashboard.donation_amount") }}
          </div>
          <div class="th-item">
            {{ $t("dashboard.donation_amount_effective") }}
          </div>
          <div class="th-item th-item--10">
            {{ $t("dashboard.donation_email_phone") }}
          </div>
          <div class="th-item">
            {{ $t("dashboard.donation_date") }}
          </div>
          <div class="th-item"></div>
          <!--<div class="th-item">{{ $t('dashboard.donation_status') }}</div>-->
        </div>
      </div>

      <b-overlay :show="loadingDobnations" rounded="sm">
        <div>
          <div v-if="donationTab === 'all'">
            <dashboard-donation-unit
              @edit-donate="editDonate"
              @edit-receipt="editReceipt"
              @request-receipt="receiptInit"
              v-for="donation in donations"
              :key="donation.id"
              :donation="donation"
              :premoderation="donationsPremoderation.value"
              @match-donation="matchDonation"
            ></dashboard-donation-unit>

            <div v-if="donations.length == 0" class="empty">
              <p>
                {{ $t("dashboard.donations_empty") }}
              </p>
            </div>
            <div class="pagination">
              <b-pagination
                size="md"
                v-if="donorsCount > 50"
                :total-rows="donorsCount"
                v-model="currentPage"
                :per-page="50"
              >
              </b-pagination>
            </div>
          </div>

          <!-- Moderation list -->
          <div v-if="moderation">
            <dashboard-moderation-unit
              v-for="donation in pendingDonations"
              :key="donation.id"
              :campaignid="campaignid"
              @edit-donate="editDonate"
              :donation="donation"
              :premoderation="donationsPremoderation.value"
            ></dashboard-moderation-unit>

            <div class="empty" v-if="pendingDonations.length === 0">
              <p>
                {{ $t("dashboard.donations_empty") }}
              </p>
            </div>
            <div class="pagination">
              <b-pagination
                size="md"
                v-if="pendingDonationCounter > 50"
                :total-rows="pendingDonationCounter"
                v-model="currentPagePendingDonations"
                :per-page="50"
              ></b-pagination>
            </div>
          </div>

          <!-- Unpublished list -->
          <div v-if="donationTab === 'onhold'">
            <dashboard-unpublished-unit
              v-for="donation in unpublishedDonations"
              :key="donation.id"
              :donation="donation"
              :premoderation="donationsPremoderation.value"
              @edit-donate="editDonate"
            ></dashboard-unpublished-unit>

            <div class="empty" v-if="unpublishedDonations.length === 0">
              <p>
                {{ $t("dashboard.donations_empty") }}
              </p>
            </div>
            <div class="pagination">
              <b-pagination
                v-if="unpublishedCount > 50"
                v-model="currentPageUnpublishedDonations"
                size="md"
                :total-rows="unpublishedCount"
                :per-page="50"
              ></b-pagination>
            </div>
          </div>
        </div>
      </b-overlay>
    </div>

    <donation-edit
      :campaignid="campaignid"
      :currentpage="currentPage"
      ref="editDonateModal"
      @hide="onDonationEditHide"
    ></donation-edit>

    <modal-receipt
      :campaignid="campaignid"
      :currentpage="currentPage"
      ref="editReceiptModal"
    ></modal-receipt>

    <requestReceiptModal ref="receiptInitModal" @update="getNewPageDonationWatch" />

    <details-modal />
  </div>
</template>

<script>
import dashboardDonationUnit from "./dashboardDonationUnit.vue";
import dashboardModerationUnit from "./dashboardModerationUnit.vue";
import dashboardUnpublishedUnit from "./dashboardUnpublishedUnit.vue";
import donationAddModal from "./donationAddModal.vue";
import donationUnitModal from "./donationUnitModal.vue";
import dashboardExportDonors from "./dashboardExportDonors.vue";
import dashboardImportDonors from "./dashboardImportDonors.vue";
import donationAddManualModal from "./donationAddManualModal.vue";
import donationReceiptModal from "./donationReceiptModal.vue";
import requestReceiptModal from "./requestReceiptModal.vue";
import exportTeams from "./export-teams-modal.vue";
import exportReceipts from "./export-receipts.vue";
import debounce from "lodash/debounce";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import qs from "qs";
import { publicApiHost } from "@/constants/urls.js";
import DetailsModal from "@/components/details-modal.vue";

function includes(str, query) {
  if (str === undefined) str = "undefined";
  if (str === null) str = "null";
  if (str === false) str = "false";
  const text = str.toString().toLowerCase();
  query = query.toLowerCase();
  return text.indexOf(query.trim()) !== -1;
}

export default {
  props: ["campaignid", "category"],

  data: function () {
    return {
      apiUrl: publicApiHost,

      currentPage: 1,
      currentPagePendingDonations: 1,
      currentPageUnpublishedDonations: 1,

      searchQ: "",
      filterStatus: "",
      gatewayFilter: "",
      recurringFilter: "-1",
      sortBy: "",
      amountFilterKey: "gt_real",
      amountFilterValue: "",

      setIntervalData: null,

      donationTab: "all",

      mode: null,

      multiselectTeam: null,
      multiselectTeamQuerySlug: "",
      multiselectTeamQuery: "",

      paymentCityQ: "",
      paymentPostcodeQ: "",

      matchedStatusFilter: "-1",
    };
  },

  directives: {
    debounce,
  },

  watch: {
    unpublishedActivated: {
      handler() {
        this.getNewPageUnpublishedDonations();
      },
      immediate: true,
    },

    currentPage: function () {
      this.getNewPageDonation();
    },

    loadingCampaign: function () {
      if (!this.loadingCampaign && this.selectedCampaign.data) {
        this.mode = this.selectedCampaign.data.attributes.mode;
        // this.startUpdateDonationProcess();
      }
    },

    currentPagePendingDonations: function () {
      this.getNewPagePendingDonations();
    },

    currentPageUnpublishedDonations() {
      this.getNewPageUnpublishedDonations();
    },

    searchQ: debounce(function () {
      this.getNewPageDonationWatch();
    }, 500),

    paymentCityQ: debounce(function () {
      this.getNewPageDonationWatch();
    }, 500),

    paymentPostcodeQ: debounce(function () {
      this.getNewPageDonationWatch();
    }, 500),

    filterStatus: function () {
      this.getNewPageDonationWatch();
    },

    amountFilterOpt: {
      handler(val) {
        if (val.value) {
          this.getNewPageDonationWatch();
        }
      },
      deep: true,
    },

    recurringFilter: function () {
      this.getNewPageDonationWatch();
    },

    matchedStatusFilter: function () {
      this.getNewPageDonationWatch();
    },

    sortBy: function () {
      this.getNewPageDonationWatch();
    },

    bluredTab: function () {
      if (!this.bluredTab) {
        this.updateCampaignDonation();
      }
    },

    gatewayFilter: function () {
      this.getNewPageDonationWatch();
    },

    multiselectTeam: function () {
      this.getNewPageDonationWatch();
    },
  },

  computed: {
    ...mapState({
      dashboardData: (state) => {
        return state.dashboard.campaignDashboard.data;
      },

      hasAccessImportDonation: (state) => {
        return state.account.access.CanImportOfflineCampaignDonation;
      },
      canExportOrganizationReceiptList: (state) => {
        return state.account.access.CanExportOrganizationReceiptList;
      },
      pendingDonationCounter: (state) => {
        return parseInt(state.donation.pendingDonationCounter);
      },

      unpublishedActivated: (state) => state.donation.unpublishedActivated,

      unpublishedDonations: (state) => state.donation.unpublished,

      unpublishedCount: (state) => {
        return parseInt(state.donation.unpublishedCount);
      },

      canSeeInputSearch: (state) => {
        return state.account.access.CanPerformDonationSearch;
      },

      pendingDonations: (state) => {
        return state.donation.pendingDonations;
      },

      teamListFilter() {
        return [
          {
            value: -1,
            text: "Without teams",
            slug: "without_teams",
          },
          ...this.teamList,
        ];
      },

      teamListMapped() {
        return this.$store.getters.getAllTeamsList.map((element) => {
          return {
            value: element.id,
            text: element.attributes.name,
            slug: element.attributes.slug,
          };
        });
      },

      teamList() {
        const teams = this.teamListMapped;

        const search = (teams, query, queryBy) => {
          const filtered = teams.filter((team) => includes(team[queryBy], query));

          return filtered.length > 0 ? filtered : teams;
        };

        if (this.multiselectTeamQuerySlug.length > 0) {
          return search(teams, this.multiselectTeamQuerySlug, "slug");
        }

        if (this.multiselectTeamQuery.length > 0) {
          return search(teams, this.multiselectTeamQuery, "text");
        }

        return teams;
      },

      donationsPremoderation: function () {
        return this.$store.getters.getDonationsPremoderationCampaigns(this.campaignid);
      },

      integrations: (state) => {
        return state.integrations.integrations.filter(function (i) {
          return i.attributes.status === 1;
        });
      },

      donations: (state) => {
        return state.donation.donations;
      },

      loadingCampaign: (state) => {
        return state.dashboard.loadingCampaignDashboard;
      },

      loadingDobnations: (state) => {
        return state.donation.loadingDonation;
      },

      selectedCampaign: (state) => {
        return state.dashboard.campaignDashboard;
      },

      selectedCampaignSign: (state) => {
        return state.dashboard.campaignDashboard.data.attributes.currency_sign;
      },

      campaignId: (state) => state.dashboard.selectedCampaign,

      donorsCount: (state) => {
        return parseInt(state.donation.donationTotal);
      },

      gateways: (state) => {
        return state.donation.gateways;
      },

      bluredTab: (state) => {
        return state.account.blured;
      },

      showDoantionTabs() {
        return this.donationsPremoderation.value || this.unpublishedActivated;
      },

      moderation() {
        return this.donationTab === "moderation";
      },

      amountFilterOpt() {
        return {
          key: this.amountFilterKey,
          value: this.amountFilterValue || undefined,
        };
      },

      bulkUnpublishedDonations: (state) => state.donation.bulkUnpublishedDonations,
      bulkPublishedDonations: (state) => state.donation.bulkPublishedDonations,
    }),

    ...mapGetters({
      getMetasInSelectedCampaign: "getMetasInSelectedCampaign",
      hasAcceess: "hasAcceess",
    }),

    canExportAllOrgDonationList() {
      return (
        this.hasAcceess("CanExportAllOrgDonationList") ||
        this.hasAcceess("CanExportCampaignDonationList", this.campaignId)
      );
    },

    allowConditionalDonations() {
      const meta = this.getMetasInSelectedCampaign?.allow_conditional_donations || {};
      return meta.value || false;
    },

    pdfLinkMeta() {
      const { value } = this.getMetasInSelectedCampaign.orgarea_show_pdf_link_team_donors || {};

      return value || false;
    },

    isHasLinkedCampaigns() {
      const { value, camapaign_id_list } = this.getMetasInSelectedCampaign.link_campaign || {};
      return value && camapaign_id_list && camapaign_id_list.length > 0;
    },

    exportFilters: {
      get() {
        const { searchQ, filterStatus, recurringFilter, gatewayFilter, matchedStatusFilter } = this;
        return { searchQ, filterStatus, recurringFilter, gatewayFilter, matchedStatusFilter };
      },
    },

    onHoldBulkActionsConditions() {
      return {
        publishDonations: this.bulkUnpublishedDonations.length > 0,
        unpublishDonations: this.bulkPublishedDonations.length > 0,
      };
    },
  },

  methods: {
    ...mapActions({
      publishDonations: "publishDonations",
      getUnpublishedDonations: "getUnpublishedDonations",
    }),
    ...mapMutations({
      uncheckBulkUnpublishedDonations: "uncheck_bulkUnpublishedDonations",
      uncheckBulkPublishedDonations: "uncheck_bulkPublishedDonations",
    }),
    getNewPageDonationWatch() {
      if (this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.getNewPageDonation();
      }
    },
    multiselectTeamQueryChange(searchQuery) {
      if (searchQuery.includes("/")) {
        searchQuery = searchQuery.replace("/", "");
        if (searchQuery.length > 0) {
          this.multiselectTeamQuerySlug = searchQuery;
        } else {
          this.multiselectTeamQuerySlug = "";
        }
      } else {
        this.multiselectTeamQuerySlug = "";
        this.multiselectTeamQuery = searchQuery;
      }
    },
    getAmountTk(key) {
      switch (key) {
        case "gt_real":
          return this.$t("dashboard.amount_filter_gt_real", "Amount >");
        case "eq_real":
          return this.$t("dashboard.amount_filter_eq_real", "Amount =");
        case "lt_real":
          return this.$t("dashboard.amount_filter_lt_real", "Amount <");
        case "gt_total":
          return this.$t("dashboard.amount_filter_gt_total", "Amount effective >");
        case "eq_total":
          return this.$t("dashboard.amount_filter_eq_total", "Amount effective =");
        case "lt_total":
          return this.$t("dashboard.amount_filter_lt_total", "Amount effective <");
      }
    },

    setSort: function (sortBy) {
      if (this.sortBy != sortBy && this.sortBy != `-${sortBy}`) {
        this.sortBy = sortBy;
      } else if (this.sortBy == sortBy) {
        this.sortBy = `-${sortBy}`;
      } else {
        this.sortBy = "";
      }
    },

    classSortBy: function (sortBy) {
      if (this.sortBy == sortBy) {
        return "fa fa-angle-up";
      } else if (this.sortBy == "-" + sortBy) {
        return "fa fa-angle-down";
      }
    },

    customLabel({ value, text }) {
      return `${value > 0 ? `[${value}]` : ""} ${text}`;
    },

    getNewPageDonation: function () {
      const paramsObj = {
        extend: "donation_receipt_id",
        page: this.currentPage,
        limit: 50,
        gateway: this.gatewayFilter || undefined,
        status: this.filterStatus || undefined,
        recurring: this.recurringFilter || undefined,
        matched_status: this.matchedStatusFilter || undefined,
        q: this.searchQ || undefined,
        sort: this.sortBy || undefined,
        team_id: this.multiselectTeam?.value,
        [this.amountFilterOpt.key]: this.amountFilterOpt.value,
        payment_city: this.paymentCityQ || undefined,
        payment_postcode: this.paymentPostcodeQ || undefined,
      };

      const paramsStr = qs.stringify(paramsObj);

      this.$emit("load-doination", `?${paramsStr}`);
    },

    getNewPagePendingDonations: function () {
      let setting = `?moderation_status=0&extend=donation_receipt_id&page=${
        this.currentPagePendingDonations
      }&limit=50${this.gatewayFilter.length > 0 ? "&gateway=" + this.gatewayFilter : ""}${
        this.filterStatus.length > 0 ? "&status=" + this.filterStatus : ""
      }${"&recurring=" + this.recurringFilter}${"&matched_status=" + this.matchedStatusFilter}${
        this.searchQ.length > 0 ? "&q=" + this.searchQ : ""
      }${this.sortBy.length > 0 ? "&sort=" + this.sortBy : ""}${
        this.multiselectTeam ? "&team_id=" + this.multiselectTeam.value : ""
      }${this.paymentCityQ ? "&payment_city=" + this.paymentCityQ : ""}${
        this.paymentPostcodeQ ? "&payment_postcode=" + this.paymentPostcodeQ : ""
      }`;
      this.$emit("load-pending-donations", setting);
    },

    getNewPageUnpublishedDonations(options) {
      const force = options?.force || false;

      const params = {
        page: this.currentPageUnpublishedDonations,
        limit: 50,
        gateway: this.gatewayFilter || null,
        recurring: this.recurringFilter,
        matched_status: this.matchedStatusFilter,
        sort: this.sortBy || null,
        team_id: this.multiselectTeam && this.multiselectTeam.value,
        extend: ["donation_receipt_id"],
      };

      if (this.unpublishedActivated || force) {
        this.$store.dispatch("getUnpublishedDonations", [this.campaignid, params]);
      }
    },

    updateCampaignDonation: function () {
      this.getNewPageDonation();
    },

    editDonate: function (donate, premoderation) {
      this.$refs.editDonateModal.openEditModal(donate, premoderation);
    },

    matchDonation(matchDonationParams) {
      this.$refs.donationAddModal.openModal({ matchDonationParams });
    },

    // checkIntegration: function() {
    //  this.$store.dispatch("getIntegrationsList");
    // },

    editReceipt(donate) {
      this.$refs.editReceiptModal.openEditModal(donate);
    },

    receiptInit(donate) {
      this.$refs.receiptInitModal.openModal(donate);
    },

    openModalImportManual() {
      this.$refs.importManual.openModal();
    },

    openModalExportTeams() {
      this.$refs.exportTeams.openModal(this.campaignid);
    },

    openModalImportList() {
      this.$refs.importList.openModal();
    },

    openModalExportReceipt() {
      this.$refs.exportReceipts.openModal();
    },

    setDonationTab(tab) {
      this.currentPage = 1;
      this.donationTab = tab;

      if (tab === "all") {
        this.getNewPageDonation();
      }
      if (tab === "moderation") {
        this.getNewPagePendingDonations();
      }
      if (tab === "onhold") {
        this.getNewPageUnpublishedDonations();
      }
    },

    openModalExportDonations() {
      this.$refs.exportDonations.openModal();
    },

    startUpdateDonationProcess() {
      if (this.mode == 1 || this.mode == 2) {
        this.setIntervalData = setInterval(() => {
          if (navigator.onLine && !this.bluredTab) {
            this.updateCampaignDonation();
          }
        }, 30000);
      }
    },

    publishSelectedBulk({ publish = true }) {
      const params = {
        true: {
          okText: this.$t("dashboard.donation_publish"),
          message: this.$t(
            "donors.donation_approved_confirm_msg_bulk",
            "Please confirm publishing of {counter} donations",
            { counter: this.bulkUnpublishedDonations.length }
          ),
          donationIds: this.bulkUnpublishedDonations,
          uncheck: this.uncheckBulkUnpublishedDonations,
          newPage: this.getNewPageUnpublishedDonations,
        },
        false: {
          okText: this.$t("dashboard.donation_unpublish", "Unpublish"),
          message: this.$t(
            "donors.donation_approved_confirm_msg_bulk",
            "Please confirm unpublishing of {counter} donations",
            { counter: this.bulkPublishedDonations.length }
          ),
          donationIds: this.bulkPublishedDonations,
          uncheck: this.uncheckBulkPublishedDonations,
          newPage: (opt) => {
            this.getNewPageDonation(opt);
            this.getNewPageUnpublishedDonations(opt);
          },
        },
      };

      const { okText, donationIds, uncheck, newPage, message } = params[publish];

      const options = {
        okText,
        cancelText: this.$t("donors.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.publishDonations([this.campaignId, donationIds, publish]).then(() => {
          uncheck(null);
          newPage({ force: true });
        });
      });
    },

    publishAll() {
      const message = this.$t(
        "donors.donation_approved_confirm_publis_all",
        "Please confirm publishing of all donations"
      );

      const options = {
        okText: this.$t("dashboard.donation_publish"),
        cancelText: this.$t("donors.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.publishDonations([this.campaignId, [], true]).then(() => {
          this.uncheckBulkUnpublishedDonations(null);
          this.getUnpublishedDonations([this.campaignId]);
        });
      });
    },

    showBulk(tab) {
      return this.unpublishedActivated && this.donationTab === tab;
    },

    onDonationEditHide() {
      if (this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.getNewPageDonation();
      }
    },
  },

  components: {
    exportTeams,
    dashboardDonationUnit,
    donationAddModal,
    exportReceipts,
    Multiselect,
    "donation-edit": donationUnitModal,
    "modal-export": dashboardExportDonors,
    "modal-import": dashboardImportDonors,
    "modal-import-manual": donationAddManualModal,
    "modal-receipt": donationReceiptModal,
    "dashboard-moderation-unit": dashboardModerationUnit,
    requestReceiptModal,
    dashboardUnpublishedUnit,
    DetailsModal,
  },

  destroyed: function () {
    if (this.mode == 1 || this.mode == 2) {
      clearInterval(this.setIntervalData);
    }
  },

  created() {
    if (this.campaignid) {
      this.$store.dispatch("getTeamsByCampaignId", [this.campaignid]);
    }
  },

  mounted() {
    if (!this.loadingCampaign && this.selectedCampaign.data) {
      this.mode = this.selectedCampaign.data.attributes.mode;
      // this.startUpdateDonationProcess();
      this.getNewPageUnpublishedDonations();
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .donation-amount-filter-ddl {
    border-top-left-radius: 50rem;
    border-bottom-left-radius: 50rem;
    background-color: #107598;
    color: #fff;
    &:hover {
      color: #fff;
      background-color: lighten(#107598, 5);
    }
    @media only screen and (max-width: 1440px) {
      white-space: break-spaces;
      max-width: 150px;
    }
  }
  .donation-amount-filter-input {
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem;
    border-color: #88bacc;
    &:focus {
      border-color: #88bacc;
      box-shadow: unset;
    }
  }

  // -- multiselect --
  %multiselect_shared_style {
    min-height: 48px;
    line-height: 24px;
    margin: 0;
    padding: 0;
  }

  .multiselect__tags {
    min-height: 48px;
    width: 100%;
    border: 1px solid #88bacc;
    border-radius: 24px;
    padding-top: 0;
    padding-left: 25px;
  }
  .multiselect__placeholder {
    line-height: 48px;
  }
  .multiselect__select {
    min-height: 48px;
  }
  .multiselect__single {
    display: inline-flex;
    align-items: center;
    min-height: 32px;
    margin: 8px 0;
    padding: 0;
  }
  .multiselect__input {
    @extend %multiselect_shared_style;
  }
  .multiselect__placeholder {
    @extend %multiselect_shared_style;
    background: transparent;
    color: initial;
    font-family: inherit;
    font-size: 16px;
  }
}

.search {
  width: 100%;
  display: inline-flex;
  align-items: center;
}

#import {
  @media only screen and (max-width: 1169px) {
    display: none;
  }
  ::v-deep .btn.disabled {
    opacity: initial;
  }
}

.donation-loading {
  display: flex;
  min-height: 100px;
  align-items: center;
  justify-content: center;
}
.pagination {
  margin-top: 20px;
}

.clickable {
  cursor: pointer;
  i {
    padding-left: 3px;
  }
}

.pdf-button-donations {
  background-color: #fff;
  padding-bottom: 20px;
  a {
    display: block;
    width: 100%;
    padding: 10px 25px;
    background-color: #b4c5cd52;
    color: #127598;
    font-family: "VitoExtended-Bold";
  }
}
.th-item {
  &--5 {
    width: 5% !important;
    min-width: 5% !important;
  }
  &--10 {
    width: 10% !important;
    min-width: 10% !important;
  }
  &--30 {
    width: 30% !important;
    min-width: 30% !important;
  }
}
.team-multiselect {
  ::v-deep {
    .multiselect {
      &__content-wrapper {
        overflow-x: hidden;
        @media only screen and (max-width: 1080px) {
          max-width: 500px;
        }
      }
      &__element {
        @media only screen and (max-width: 1080px) {
          width: 339px;
        }
      }
      &__single {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  &__option {
    word-break: break-all;
    white-space: normal;
  }
}

.dashboard-donation {
  &__amount {
    direction: ltr;
  }
}
</style>
