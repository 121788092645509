<template>
  <div>
    <error v-if="errors" :errors="errors"></error>

    <b-tabs class="mb-3" content-class="d-none" id="tab1" v-model="currentTab">
      <b-tab
        :key="tag.id"
        v-for="tag in descriptionTagsTabs"
        no-body
        :disabled="load"
        @click="changeDescriptionTag(tag.tag)"
        :active="descriptionTag === tag.tag"
        :id="tag.id"
        :title-link-class="{
          'language-selected__tab--active': descriptionTag === tag.tag,
          'text-dark': descriptionTag !== tag.tag,
        }"
        :title-item-class="{
          'language-selected__tab--load': load,
        }"
      >
        <template #title>
          <b-skeleton width="100%" v-if="load"></b-skeleton>
          <template v-else>
            {{ tag.title }}
          </template>
        </template>
      </b-tab>
    </b-tabs>
    <p>
      {{
        $t(
          "edit_campaign.description_tag_info",
          "Default campaign description is shown if custom description is not defined for campaign status"
        )
      }}
    </p>

    <ul class="language-selected">
      <template v-if="!load">
        <li
          v-for="lang in languagesAdded[descriptionTag]"
          :key="lang.id"
          @click="isSelected(lang.attributes.language)"
          class="language-selected__unit"
          :class="{ active: languageSelected === lang.attributes.language }"
          v-text="setFullName(lang.attributes.language)"
        ></li>
      </template>
      <template v-else>
        <b-skeleton
          class="language-selected__unit"
          v-for="(_, i) in new Array(
            languagesAdded[descriptionTag].length > 0 ? languagesAdded[descriptionTag].length : 1
          ).fill(0)"
          :key="i"
        />
      </template>

      <template v-if="!load">
        <b-dropdown
          id="ddown1"
          text="+ Add language"
          class="dropdown"
          variant="-sm bttn--bordered-blue"
          :disabled="!hasAccess"
        >
          <b-dropdown-item
            v-for="(lang, index) in languageSet"
            :key="index"
            :disabled="!hasAccess"
            @click="addLanguage(lang.short)"
            >{{ lang.long }}</b-dropdown-item
          >
        </b-dropdown>
      </template>
      <template v-else>
        <b-skeleton class="language-selected__unit" />
      </template>
    </ul>

    <div v-if="descriptionTag === '' && selectedIndex >= 0">
      <div class="campaign-edit-title mb-2">
        <h2 class="h2-like">{{ $t("edit_campaign.generalinfo_name") }}</h2>
      </div>
      <div class="row">
        <div class="col-12">
          <div v-if="languagesAdded[descriptionTag][selectedIndex]" class="form-group form-unit">
            <input
              v-model="languagesAdded[descriptionTag][selectedIndex].attributes.title"
              type="text"
              class="form-control form-unit__input"
              :placeholder="$t('edit_campaign.generalinfo_name')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="campaign-edit-title mb-2">
      <h2 class="h2-like">{{ $t("edit_campaign.description_title") }}</h2>
    </div>
    <div
      v-for="(lang, index) in languagesAdded[descriptionTag]"
      :key="`lang-${index}`"
      class="description-area"
      v-show="
        lang.attributes.language === languageSelected && lang.attributes.tag === descriptionTag
      "
    >
      <form
        v-if="
          lang.attributes.language === languageSelected && lang.attributes.tag === descriptionTag
        "
        method="POST"
        @submit.prevent="saveChange"
        class="campaign-description"
      >
        <div class="description-area">
          <div class="editor-wrap">
            <QuillDescriptionEditor
              v-model="lang.attributes.content"
              :disabled="!hasAccess"
              :id="'quill-editor' + index"
              ref="myQuillEditor"
              :options="editorOption"
              :editor-direction="editorDirection"
            >
            </QuillDescriptionEditor>

            <div class="row">
              <div class="col-auto mr-auto">
                <b-overlay
                  :show="load"
                  rounded="pill"
                  opacity="0.6"
                  spinner-small
                  spinner-variant="secondary"
                  class="d-inline-block"
                >
                  <button
                    v-if="lang.id"
                    type="button"
                    class="bttn bttn--lg bttn--orange bttn--inverted-orange"
                    @click="deleteLang(lang)"
                    name="button"
                  >
                    {{
                      $t("edit_campaign.delete", {
                        langName: setFullName(lang.attributes.language),
                      })
                    }}
                  </button>
                </b-overlay>
              </div>
              <div class="col-auto">
                <b-overlay
                  :show="load"
                  rounded="pill"
                  opacity="0.6"
                  spinner-small
                  spinner-variant="secondary"
                  class="d-inline-block"
                >
                  <button
                    v-if="!enableRootEvents"
                    type="submit"
                    :disabled="!hasAccess || load"
                    class="bttn bttn--lg bttn--orange bttn--mw255"
                    value="Save"
                  >
                    {{ $t("dashboard.save_btn") }}
                  </button>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import error from "@/components/errors.vue";
import Vue from "vue";
import QuillDescriptionEditor from "@/components/vue-quill-editor/descriptionEditor.vue";
import { getDirection } from "@/store/modules/translation";

export default {
  components: {
    QuillDescriptionEditor,
    error: error,
  },

  props: {
    enableRootEvents: Boolean,
  },

  data() {
    return {
      languageSelected: "",
      languagesAdded: null,
      languageSet: null,
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ["bold", "underline", "strike", "italic"],
              ["blockquote"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ align: [] }],
              [{ color: [] }, { background: [] }],
              ["image"],
              ["video"],
              ["link"],
              ["clean"],
            ],
          },
          imageResize: {
            modules: ["Resize", "DisplaySize"],
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
          },
        },
      },
      load: false,
      errors: [],
      descriptionTag: "",
      changeTag: false,
      seteTimeoutFunc: null,
      currentTab: 0,
      listeners: [],
      editorDirection: "ltr",
    };
  },

  computed: {
    ...mapState({
      hasAccess: (state) => {
        return state.account.access.CanEditCampaignContent;
      },

      langsArr: function (state) {
        let included = state.campaign.campaign.included;

        let relationships = state.campaign.campaign.data.relationships.campaign_content.data;

        let arrDesc = [];

        for (let i = 0; i < relationships.length; i++) {
          let el = included.filter((el) => {
            return el.id == relationships[i].id && el.type == relationships[i].type;
          });

          arrDesc.push(el[0]);
        }

        let tagLangs = this.descriptionTags.reduce((acc, curr) => {
          acc[curr] = [];
          return acc;
        }, {});

        for (const el of arrDesc) {
          tagLangs[el.attributes.tag].push(el);
        }

        tagLangs = Object.entries(tagLangs);

        if (tagLangs.some(([, t]) => t.attributes?.created_at)) {
          tagLangs.sort(([, a], [, b]) => a.attributes.created_at - b.attributes.created_at);
        } else {
          tagLangs.sort(([, a], [, b]) => Number(a.id) - Number(b.id));
        }

        const sorted = Object.fromEntries(tagLangs);

        return Vue.observable(sorted);
      },

      campaignId: (state) => {
        return state.campaign.campaign.data.id;
      },

      accountId: (state) => {
        return state.account.account.data.id;
      },

      orgId: function () {
        return localStorage.getItem("orgId");
      },

      selectedIndex() {
        return this.languagesAdded[this.descriptionTag].findIndex(
          (lang) => lang.attributes.language === this.languageSelected
        );
      },

      locale: (state) => state.i18n.locale,
    }),
    ...mapGetters({
      getOldLangsVariant: "getOldLangsVariant",
    }),
    descriptionTags() {
      return ["", "countdown", "primary_goal", "bonus_goal", "completed"];
    },
    descriptionTagsTabs() {
      return this.descriptionTags.map(this.createDescriptionTag);
    },
    safeLocale() {
      if (this.getOldLangsVariant.map((lv) => lv.short).includes(this.locale)) {
        return this.locale;
      }
      return "en";
    },
  },

  methods: {
    addLanguage: function (val) {
      let newLang = {
        attributes: {
          campaign_id: parseInt(this.campaignId),
          content: "",
          title: "",
          language: val,
          tag: this.descriptionTag,
        },
      };
      this.languageSelected = val;

      this.languagesAdded[this.descriptionTag] = [
        ...this.languagesAdded[this.descriptionTag],
        newLang,
      ];

      this.editorDirection = getDirection(val);

      this.setLangForAdd();
    },

    createDescriptionTag(name) {
      const tag = name === "" ? "untagged" : name;
      const $t = this.$t.bind(this);
      return {
        tag: name,
        get id() {
          return `tab-tooltip-target-${tag}`;
        },
        get title() {
          return $t(`edit_campaign.${tag}`);
        },
        get usageDescription() {
          return $t(`edit_campaign.${tag}_usage_description`);
        },
      };
    },

    isSelected(lang) {
      this.languageSelected = lang;
      this.editorDirection = getDirection(lang);
    },

    addNewLang: function (lang) {
      this.load = true;

      this.errors = [];

      let descForAdd = {
        data: lang,
      };

      axios
        .post(
          urls.addDescription.replace(":orgId", this.orgId).replace(":campaignId", this.campaignId),
          descForAdd
        )
        .then(() => {
          this.$_notificationsMixin_makeToast("Success", this.$t("edit_campaign.success_edit"));

          let extend = "?extend=campaign_stats&extend=content&extend=media&extend=matchers";

          this.load = false;

          this.$store.dispatch("updateCampaign", extend);
        })
        .catch((e) => {
          this.load = false;
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },

    changeDescriptionTag: function (val) {
      this.descriptionTag = val;
      this.setAddedLang(true);
    },

    saveChange: async function () {
      this.load = true;
      this.unsavedChangesTooltipShow = false;
      this.unsavedChangesTooltipTarget = "";

      this.errors = [];

      const errorCatch = (e, lang) => {
        this.load = false;
        const getTag = (tag) => (tag === "" ? "untagged" : tag);
        const errorTemplate = (errorMessage) =>
          [
            `Language '${this.setFullName(lang.attributes.language)}'`,
            `with tag '${this.$t(`edit_campaign.${getTag(lang.attributes.tag)}`)}'`,
            `: ${errorMessage}`,
          ].join(" ");
        if (e.response.data) {
          if (e.response.data.error) {
            this.errors.push(errorTemplate(e.response.data.error));
          } else {
            this.errors.push(errorTemplate(this.$t("template.error_default")));
          }
        } else {
          this.errors.push(errorTemplate(this.$t("template.error_default")));
        }
      };

      const arrDescPromises = new Map(Object.entries(this.languagesAdded));
      for (const [, value] of arrDescPromises) {
        const valuePromises = value.map((lang) => {
          if (lang?.id) {
            return axios
              .put(
                urls.addDescription
                  .replace(":orgId", this.orgId)
                  .replace(":campaignId", this.campaignId) +
                  "/" +
                  lang.id,
                { data: lang }
              )
              .catch((e) => errorCatch(e, lang));
          } else {
            return axios
              .post(
                urls.addDescription
                  .replace(":orgId", this.orgId)
                  .replace(":campaignId", this.campaignId),
                { data: lang }
              )
              .catch((e) => errorCatch(e, lang));
          }
        });
        await Promise.allSettled(valuePromises);
      }

      this.$_notificationsMixin_makeToast("Success", this.$t("edit_campaign.success_edit"));

      let extend = "?extend=campaign_stats&extend=content&extend=media&extend=matchers";
      this.$store.dispatch("updateCampaign", extend);

      this.load = false;
    },

    deleteLang: function (lang) {
      this.load = true;

      this.errors = [];

      let descForDelete = {
        data: lang.attributes,
      };
      let message = this.$t("edit_campaign.confirm_delete_desc");

      let options = {
        okText: this.$t("edit_campaign.confirm_delete"),
        cancelText: this.$t("edit_campaign.confirm_cancel"),
      };

      this.$dialog
        .confirm(message, options)
        .then(() => {
          return axios
            .delete(
              urls.addDescription
                .replace(":orgId", this.orgId)
                .replace(":campaignId", this.campaignId) +
                "/" +
                lang.id,
              descForDelete
            )
            .then(() => {
              let extend = "?extend=campaign_stats&extend=content&extend=media&extend=matchers";

              this.load = false;

              this.$store.dispatch("updateCampaign", extend);

              this.$_notificationsMixin_makeToast("Success", this.$t("edit_campaign.success_edit"));
            })
            .catch((e) => {
              this.load = false;
              if (e.response.data) {
                if (e.response.data.error) {
                  this.errors.push(e.response.data.error);
                } else {
                  this.errors.push(this.$t("template.error_default"));
                }
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            });
        })
        .catch(() => {
          this.load = false;
        });
    },

    setLangForAdd: function () {
      let allLang = this.getOldLangsVariant;

      let addedLang = this.languagesAdded[this.descriptionTag];

      let languageAwailable = this.unique(allLang, addedLang);

      this.languageSet = languageAwailable;
    },

    setFullName: function (short) {
      let fullNameLang = this.getOldLangsVariant.filter((el) => {
        return el.short == short;
      });

      if (fullNameLang.length > 0) {
        return fullNameLang[0].long;
      }

      return "";
    },

    unique: function (arr, arr2) {
      let result = [];
      nextInput: for (let i = 0; i < arr.length; i++) {
        let str = arr[i];
        for (let j = 0; j < arr2.length; j++) {
          if (arr2[j].attributes.language == str.short) continue nextInput;
        }
        result.push(str);
      }
      return result;
    },

    setAddedLang: function (firstLoad) {
      this.languagesAdded = this.langsArr;

      if (this.languagesAdded[this.descriptionTag].length > 0 && firstLoad) {
        this.languageSelected = this.languagesAdded[this.descriptionTag][0].attributes.language;
        this.editorDirection = getDirection(this.languageSelected);
      }

      if (this.languagesAdded[this.descriptionTag].length == 0 && this.hasAccess) {
        this.addLanguage(this.safeLocale);
      }

      this.setLangForAdd();
    },

    createRootListenerSaveChange() {
      const event = "charidy::campaign-description::save-change";
      const callback = (cb) => {
        this.saveChange().then(() => cb());
      };

      this.$root.$on(event, callback);
      return () => {
        this.$root.$off(event, callback);
      };
    },

    createRootListenerUpdateTitle() {
      const event = "charidy::campaign-description::update-title";
      const callback = (newTitle) => {
        this.languagesAdded[this.descriptionTag][0].attributes.title = newTitle;
      };

      this.$root.$on(event, callback);
      return () => {
        this.$root.$off(event, callback);
      };
    },

    createRootListenerGetTitle() {
      const event = "charidy::campaign-description::get-title";
      const callback = (cb) => {
        cb(this.languagesAdded[this.descriptionTag][0].attributes.title);
      };

      this.$root.$on(event, callback);
      return () => {
        this.$root.$off(event, callback);
      };
    },
  },

  watch: {
    descriptionTag: function (newVal, oldVal) {
      clearTimeout(this.seteTimeoutFunc);

      if (newVal != oldVal) {
        this.changeTag = true;

        this.seteTimeoutFunc = setTimeout(() => {
          this.changeTag = false;
        }, 1000);
      }
    },

    langsArr: function (newVal, oldVal) {
      this.setAddedLang(false);

      if (newVal.length > 0 && !this.changeTag) {
        if (newVal.length > oldVal.length) {
          this.languageSelected = newVal[newVal.length - 1].attributes.language;
        }

        if (newVal.length < oldVal.length) {
          this.languageSelected = newVal[0].attributes.language;
        }
      } else if (newVal.length > 0 && this.changeTag) {
        this.languageSelected = newVal[0].attributes.language;
      }
    },
  },

  created: function () {
    this.setAddedLang(true);

    if (this.enableRootEvents) {
      this.listeners.push(this.createRootListenerSaveChange());
      this.listeners.push(this.createRootListenerUpdateTitle());
      this.listeners.push(this.createRootListenerGetTitle());
    }
  },

  beforeDestroy() {
    this.listeners?.length > 0 && this.listeners.forEach((unsubscribe) => unsubscribe());
  },
};
</script>

<style lang="scss">
.quill-editor {
  margin-bottom: 30px;
}
.quill-editor .ql-container {
  height: 400px;
  position: relative;
}

.language-selected .btn.btn-secondary.dropdown-toggle {
  background: transparent;
  border: 1px solid rgba(32, 67, 78, 0.15);
  color: #20434e;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  height: 42px;
  border-radius: 0;
}

.language-selected .dropdown-menu {
  color: #20434e;
}

.delete-description {
  background: transparent;
  border: 0;
  font-size: 16px;
  color: #cc6d4c;
  display: block;
  margin: 20px 0;
}

.loadin-image {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 140px;
  }
}

.alert-danger-editor li {
  display: block;
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px 20px;
  border-radius: 4px;
  border: 1px solid #f5c6cb;
  font-size: 14px;
}
.campaign-description {
  .ql-editor {
    font-family: "ProximaNova-Regular";
    font-size: 16px;
    strong {
      font-family: "ProximaNova-Bold";
    }
  }
}

.selected-tag-text {
  color: #107598;
  margin-bottom: 10px;
}
</style>
