<template>
  <div class="profile-settings" v-if="accountSettings">
    <h4>
      {{ $t("dashboard.donor_settings", "Donor settings") }}
    </h4>
    <p>
      {{ $t("dashboard.donor_settings_donation_visibility", "Donation visibility") }}
    </p>
    <div class="radio-form">
      <div class="swich-elem">
        <b-form-checkbox v-model="accountSettings.hide_avatar_on_donations">
          {{ $t("dashboard.donor_settings_hide_picture", "Hide picture") }}
        </b-form-checkbox>
      </div>

      <div class="swich-elem">
        <b-form-checkbox v-model="accountSettings.anonymous_donations">
          {{ $t("dashboard.donor_settings_make_donations_anonymous", "Make donations anonymous") }}
        </b-form-checkbox>
      </div>

      <div class="radio-form-footer">
        <button
          :disabled="load"
          class="bttn bttn--sm bttn--orange"
          type="submit"
          @click="updateSettings"
        >
          {{ $t("dashboard.donor_settings_save_btn", "Save") }}
        </button>
      </div>
    </div>

    <h4 class="mt-4">
      {{ $t("dashboard.donor_settings_account", "Donor account settings") }}
    </h4>
    <div class="buttons__container">
      <button class="bttn bttn--sm bttn--orange" :disabled="loading" @click="unsubscribe">
        {{ $t("dashboard.donor_settings_unsubscribe_all_btn", "Unsubscribe from mailing") }}
      </button>

      <button class="bttn bttn--sm bttn--orange" :disabled="loading" @click="downloadAllAccData">
        {{ $t("dashboard.donor_settings_download_all_acc_data_btn", "Donwload all customer data") }}
      </button>

      <button class="bttn bttn--sm bttn--orange" :disabled="loading" @click="delAcc">
        {{ $t("dashboard.donor_settings_del_acc_btn", "Delete account") }}
      </button>

      <button class="bttn bttn--sm bttn--orange" :disabled="loading" @click="delAllAccData">
        {{ $t("dashboard.donor_settings_del_all_acc_data_btn", "Delete all account data") }}
      </button>
    </div>

    <b-modal
      id="confirm-action-privacy-requirements-modal"
      centered
      :title="confirmPrivacyRequirementsData.message"
    >
      <b-form-checkbox class="mb-2" v-model="confirmPrivacyRequirementsData.ccpa">{{
        $t("dashboard.donor_data_california_resident_checkbox")
      }}</b-form-checkbox>

      <div slot="modal-footer">
        <button
          type="button"
          class="bttn bttn--lg bttn--blue"
          @click="$root.$emit('bv::hide::modal', 'confirm-action-privacy-requirements-modal')"
          name="button"
        >
          {{ $t("dashboard.donor_settings_close", "Close") }}
        </button>
        <button
          @click="onConfirmPrivacyRequirements"
          :disabled="privacyRequirementsOutstanding()"
          :class="
            privacyRequirementsOutstanding()
              ? 'disabled bttn bttn--lg bttn--orange'
              : 'bttn bttn--lg bttn--orange'
          "
        >
          {{ $t("dashboard.donor_settings_save", "Ok") }}
        </button>
      </div>

      <b-card v-if="confirmPrivacyRequirementsData.ccpa">
        <h4>{{ $t("dashboard.ccpa_declaration_title") }}</h4>
        <p>{{ $t("dashboard.ccpa_declaration_body") }}</p>

        <b-form id="ccpa-requirements-form">
          <b-form-group :label="$t('dashboard.donor_data_request_email')">
            <b-form-input
              v-model="confirmPrivacyRequirementsData.ccpaData.email"
              type="email"
              placeholder="Enter your email"
              :required="confirmPrivacyRequirementsData.ccpa"
            ></b-form-input>
          </b-form-group>
          <b-form-group :label="$t('dashboard.donor_data_request_current_date')">
            <b-form-input
              v-model="confirmPrivacyRequirementsData.ccpaData.currentDate"
              type="date"
              :required="confirmPrivacyRequirementsData.ccpa"
            ></b-form-input>
          </b-form-group>
          <b-form-group :label="$t('dashboard.donor_data_request_declarant')">
            <b-form-input
              v-model="confirmPrivacyRequirementsData.ccpaData.declarant"
              type="text"
              placeholder="Enter your full name"
              :required="confirmPrivacyRequirementsData.ccpa"
            ></b-form-input>
          </b-form-group>
        </b-form>

        <error-message ref="error" class="mb-2" :response.sync="errorResponse"></error-message>

        <button
          class="bttn bttn--lg bttn--orange"
          style="float: right"
          v-if="!confirmPrivacyRequirementsData.ccpaData.confirmed"
          @click="submitCCPAPrivacyRequirements()"
        >
          {{ $t("dashboard.donor_data_request_submit") }}
        </button>

        <b-icon
          icon="check-circle"
          variant="success"
          style="width: 80px; height: 80px; float: right"
          v-else
        ></b-icon>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { notificationsMixin } from "@/mixins";
import axios from "axios";
import urls from "@/constants/urls";

import errorList from "@/components/error-message.vue";

export default {
  data() {
    return {
      load: false,
      accountSettings: {
        hide_avatar_on_donations: false,
        anonymous_donations: false,
      },
      confirmPrivacyRequirementsData: {
        message: "",
        actionType: "",
        action: null,
        ccpa: false,
        ccpaData: {
          email: "",
          currentDate: "",
          declarant: "",
          confirmed: false,
        },
      },
      loading: false,
      errorResponse: null,
    };
  },
  components: {
    errorMessage: errorList,
  },
  mixins: [notificationsMixin],
  computed: {
    ...mapState({
      donorAccount: (state) => {
        return state.donorAccount.account.attributes;
      },
      isLoading: (state) => {
        return state.donorAccount.loadingAccount;
      },
    }),

    ...mapGetters(["getIncludedByType"]),
  },
  watch: {
    donorAccount: function () {
      this.updateAccountForm();
    },
  },
  methods: {
    ...mapActions([
      "fetchUnsubscribe",
      "fetchDownloadData",
      "fetchDeleteAccount",
      "fetchDeleteAllData",
    ]),

    updateSettings() {
      this.load = true;

      let settings = { data: { attributes: null } };
      settings.data.attributes = this.accountSettings;

      axios
        .patch(urls.donorAccount, settings)
        .then((res) => {
          this.load = false;

          this.$store.commit("setDonorAccount", res.data);

          this.$_notificationsMixin_makeToast(
            "Success!",
            this.$t("account.success_edit", "Success!"),
            "success"
          );
        })
        .catch((e) => {
          this.load = false;

          console.log(e);
        });
    },

    updateAccountForm: function () {
      this.accountSettings = this.donorAccount;
    },

    confirm(msg) {
      return this.$bvModal.msgBoxConfirm(msg, {
        title: this.$t("dashboard.donor_settings_confirm_modal_title", "Confirm your action"),
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: this.$t("dashboard.donor_settings_confirm_yes_btn", "Yes"),
        cancelTitle: this.$t("dashboard.donor_settings_confirm_no_btn", "No"),
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      });
    },

    clearConfirmPrivacyRequirements() {
      this.confirmPrivacyRequirementsData = {
        message: "",
        actionType: "",
        action: null,
        ccpa: false,
        ccpaData: {
          email: "",
          currentDate: "",
          declarant: "",
          confirmed: false,
        },
      };
    },

    privacyRequirementsOutstanding() {
      return (
        this.confirmPrivacyRequirementsData.ccpa &&
        !this.confirmPrivacyRequirementsData.ccpaData.confirmed
      );
    },

    confirmPrivacyRequirements(msg, actionType, action) {
      this.clearConfirmPrivacyRequirements();
      this.confirmPrivacyRequirementsData.message = msg;
      this.confirmPrivacyRequirementsData.actionType = actionType;
      this.confirmPrivacyRequirementsData.action = action;
      this.$bvModal.show("confirm-action-privacy-requirements-modal");
    },

    onConfirmPrivacyRequirements() {
      if (this.confirmPrivacyRequirementsData.action != null) {
        this.confirmPrivacyRequirementsData.action();
      }
    },

    submitCCPAPrivacyRequirements() {
      this.loading = true;
      this.errorResponse = null;

      let payload = {
        data: {
          attributes: {
            email: this.confirmPrivacyRequirementsData.ccpaData.email,
            request_type: this.confirmPrivacyRequirementsData.actionType,
            date: this.confirmPrivacyRequirementsData.ccpaData.currentDate,
            declarant: this.confirmPrivacyRequirementsData.ccpaData.declarant,
          },
        },
      };

      axios
        .post(urls.donorDataRequestCCPA, payload)
        .then(() => {
          this.loading = false;
          this.confirmPrivacyRequirementsData.ccpaData.confirmed = true;
        })
        .catch((e) => {
          this.loading = false;
          this.errorResponse = e.response;
        });
    },

    successMsgPopup(msg) {
      const dmsg = this.$t(
        "dashboard.donor_settings_download_all_acc_data_success_msg",
        "to complete this action, we need to verify your identity, please follow the link sent on your email"
      );

      this.$bvModal.msgBoxOk(msg || dmsg, {
        title: this.$t("dashboard.donor_settings_msg_modal_title", "Success action"),
        okVariant: "success",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0",
        centered: true,
        okTitle: this.$t("dashboard.donor_settings_confirm_ok_btn", "Ok"),
      });
    },

    unsubscribe() {
      const msg = this.$t(
        "dashboard.donor_settings_unsubscribe_confirm_msg",
        "Unsubscribe from mailing list?"
      );
      this.confirm(msg).then((value) => {
        if (value) {
          this.loading = true;
          this.fetchUnsubscribe()
            .then(() => {
              this.loading = false;
              this.successMsgPopup();
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },

    downloadAllAccData() {
      this.loading = true;
      this.fetchDownloadData()
        .then(() => {
          this.loading = false;
          this.successMsgPopup();
        })
        .catch(() => {
          this.loading = false;
        });
    },

    delAcc() {
      const msg = this.$t("dashboard.donor_settings_del_acc_confirm_msg", "Delete account?");
      this.confirm(msg).then((value) => {
        if (value) {
          this.loading = true;
          this.fetchDeleteAccount()
            .then(() => {
              this.loading = false;
              this.successMsgPopup();
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },

    delAllAccData() {
      const msg = this.$t(
        "dashboard.donor_settings_del_all_acc_data_confirm_msg",
        "Delete all account data?"
      );
      this.confirmPrivacyRequirements(msg, "delete", () => {
        this.loading = true;
        this.fetchDeleteAllData()
          .then(() => {
            this.loading = false;
            this.successMsgPopup();
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
  },
  created: function () {
    this.updateAccountForm();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
.profile-settings {
  padding: 0 15px;
  font-size: 20px;
  color: #414359;
  h4 {
    font: 26px/1.2 "VitoExtended-Bold";
    margin-bottom: 15px;
  }
  .radio-form-footer {
    padding-top: 25px;
  }
}
.buttons {
  &__container {
    display: grid;
    grid-auto-flow: column;
    gap: 10px;
  }
}
.swich-elem {
  .custom-checkbox {
    display: block;
    padding-left: 0;
    font-size: 17px;
    margin-bottom: 10px;
    .custom-control-label {
      padding-left: 50px;
      position: relative;
      &:before {
        width: 38px;
        height: 20px;
        left: 0;
        background: #dee4eb;
        border-radius: 10px;
        box-shadow: 0 2px 10px 0 rgba(4, 19, 36, 0.05);
        outline: none !important;
        border: none !important;
      }
      &:after {
        width: 20px;
        height: 20px;
        left: 0px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 4px 12px 0 rgba(3, 12, 41, 0.08);
      }
    }
    .custom-control-input:focus + .custom-control-label {
      &:before {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
    .custom-control-input:checked + .custom-control-label {
      &:before {
        background: #00d39c;
      }
      &:after {
        width: 20px;
        height: 20px;
        left: 18px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 4px 12px 0 rgba(3, 12, 41, 0.08);
      }
    }
  }
}
.custom-radio {
  padding-left: 0;
  margin-bottom: 5px;
  font-size: 17px;
  label {
    padding-left: 26px;
    position: relative;
    &:before,
    &:after {
      display: block;
      content: "";
      position: absolute;
    }
    &:before {
      width: 16px;
      height: 16px;
      border: 1px solid $bb-100;
      border-radius: 8px;
      left: 0;
      top: 50%;
      margin-top: -8px;
    }
    &:after {
      opacity: 0;
      width: 10px;
      height: 10px;
      background: $bb-100;
      border-radius: 5px;
      left: 3px;
      top: 50%;
      margin-top: -5px;
    }
  }
  input[type="radio"] {
    opacity: 0;
    &:checked + label:after {
      opacity: 1;
    }
  }
}
</style>
