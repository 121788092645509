<template>
  <div>
    <ul class="cp-edit-nav">
      <li
        class="cp-edit-nav__unit"
        :class="{ active: campaignProfileStep === 'general' }"
        @click="updateTab('general')"
      >
        <svg viewBox="0 0 22 22">
          <g fill="none" fill-rule="nonzero">
            <path
              fill="#107598"
              d="M18.65 18.65a10.82 10.82 0 1 0-15.75-.47l.1.07a5.1 5.1 0 0 1-2.23 2.52c-.37.18-.27.73.13.8 1.23.2 2.98-.02 4.6-1.25l.03.03a10.85 10.85 0 0 0 13.12-1.7z"
            />
            <path
              fill="#FFF"
              d="M11.45 4.33a1.78 1.78 0 0 1 0 3.55 1.78 1.78 0 0 1 0-3.55zM8.63 15.7c.07-.63.17-1.27.3-1.9.17-1.1.37-2.2.57-3.3 0-.07.03-.15.03-.2 0-.45-.15-.63-.6-.68-.2-.02-.4-.04-.58-.1-.22-.07-.32-.24-.3-.45.02-.2.15-.32.4-.35.13-.02.28-.02.4-.02H12.2c.4 0 .65.2.68.6 0 .35-.06.7-.1 1.02l-.7 3.78c-.08.43-.15.83-.2 1.25-.03.2 0 .43.04.6.08.28.28.4.53.38.23-.03.45-.1.65-.18.18-.07.33-.17.5-.25.25-.1.47.07.4.35-.05.15-.13.3-.25.43a3.3 3.3 0 0 1-2.32 1.05c-.43 0-.86 0-1.28-.08-.75-.15-1.65-1.02-1.53-1.95z"
            />
          </g>
        </svg>
        <span>{{ $t("edit_campaign.general_info") }}</span>
      </li>

      <li
        class="cp-edit-nav__unit"
        :class="{ active: campaignProfileStep === 'description' }"
        @click="updateTab('description')"
      >
        <svg viewBox="0 0 18 22">
          <path
            fill="#107598"
            fill-rule="nonzero"
            d="M10.8 0H2.16C.96 0 0 .98 0 2.2v17.6C0 21.02.97 22 2.16 22h12.96c1.2 0 2.16-.98 2.16-2.2V6.6L10.8 0zm2.16 17.6H4.32v-2.2h8.64v2.2zm0-4.4H4.32V11h8.64v2.2zm-2.88-5.87V2.25l4.99 5.08h-4.99z"
          />
        </svg>
        <span>{{ $t("edit_campaign.description") }}</span>
      </li>

      <li
        class="cp-edit-nav__unit"
        :class="{ active: campaignProfileStep === 'images' }"
        @click="updateTab('images')"
      >
        <svg viewBox="0 0 21 20">
          <g fill="#107598" fill-rule="nonzero">
            <path
              d="M18.24.02h-16C1.12.02.2.93.2 2.05v12.88l7.92-7.88 5.75 5.72 2.05-2.04 4.32 4.3V2.04c0-1.12-.92-2.03-2.01-2.03zm-2.51 6.43c-.87 0-1.58-.7-1.58-1.56a1.57 1.57 0 0 1 3.15 0c0 .86-.7 1.56-1.57 1.56z"
            />
            <path
              d="M13.88 14.95l-5.75-5.7-7.33 7.3c-.39.38-.6.88-.6 1.43 0 1.11.92 2.02 2.04 2.02h16c1.12 0 2.03-.9 2.03-2.02v-.75l-4.34-4.3-2.05 2.02z"
            />
          </g>
        </svg>
        <span>{{ $t("edit_campaign.images") }}</span>
      </li>

      <li
        class="cp-edit-nav__unit"
        :class="{ active: campaignProfileStep === 'teams' }"
        @click="updateTab('teams')"
      >
        <svg viewBox="0 0 22 21">
          <path
            fill="#107598"
            fill-rule="nonzero"
            d="M2.32 7.64A2.48 2.48 0 0 1 4.8 5.16c0 1.65.65 3.16 1.7 4.27-.44.42-1.04.69-1.7.69a2.48 2.48 0 0 1-2.48-2.48zM11 10.12a4.96 4.96 0 1 0-.01-9.93 4.96 4.96 0 0 0 .01 9.93zm6.2 0a2.48 2.48 0 0 0 0-4.96c0 1.66-.65 3.16-1.7 4.27.44.43 1.04.69 1.7.69zm0 2.48c-.34 0-.68.04-1 .1a9.46 9.46 0 0 1 3.48 3.62H22a4.97 4.97 0 0 0-4.8-3.72zm-11.22.15A4.97 4.97 0 0 0 0 16.32h2.4a9.9 9.9 0 0 1 3.58-3.57zm5.2-.15a8.7 8.7 0 0 0-8.77 7.44H19.6a8.34 8.34 0 0 0-8.42-7.44z"
          />
        </svg>
        <span>{{ $t("edit_campaign.teams") }}</span>
      </li>
    </ul>

    <div class="campaign-profile">
      <div class="campaign-profile__body">
        <general-info v-if="isActive('general')" />
        <description v-if="isActive('description')" />
        <layout v-if="isActive('images')" />
        <campaign-teams v-if="isActive('teams')" />
      </div>
    </div>
  </div>
</template>

<script>
import generalInfo from "./expressCampaignEdit/generalInfo.vue";
import description from "./expressCampaignEdit/description.vue";
import layout from "./expressCampaignEdit/layout.vue";
import CampaignTeams from "@/views/campaigns/campaign-edit-vue/subcomponents/campaignTeams.vue";

export default {
  components: {
    generalInfo,
    description,
    layout,
    CampaignTeams,
  },

  data() {
    return {
      campaignProfileStep: "general",
    };
  },

  methods: {
    isActive(name) {
      return this.campaignProfileStep === name;
    },

    updateTab(val) {
      location.hash = val;
      this.campaignProfileStep = val;
    },

    checkHash: function () {
      const hash = location.hash;

      if (hash.length > 0) {
        this.campaignProfileStep = hash.replace("#", "");
      }
    },
  },

  created() {
    this.checkHash();
  },
};
</script>

<style></style>
