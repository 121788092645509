export default {
  title: "AllowTeamUpdateByPublicToken",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
  },
};
