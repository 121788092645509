<template>
  <div>
    <div class="d-flex flex-column flex-lg-row justify-content-around flex-wrap">
      <b-button
        class="m-3 bttn bttn--sm bttn--orange"
        variant="outline-info"
        @click="importTeams"
        :disabled="!hasAccessAdd"
        >{{ $t("edit_campaign.import_teams", "Import teams") }}
      </b-button>
      <b-button
        class="m-3 bttn bttn--sm bttn--orange"
        variant="outline-info"
        @click="exportTeams"
        :disabled="!hasAccessEdit"
      >
        {{ $t("edit_campaign.export_button_teams_title", "Export teams") }}
      </b-button>
      <b-button
        :disabled="!hasAccessAdd"
        class="m-3 bttn bttn--sm bttn--orange"
        variant="outline-info"
        @click="addTeam"
      >
        {{ $t("teams.add_new_team", "Add new team") }}
      </b-button>
      <b-button class="m-3 bttn bttn--sm bttn--orange" variant="outline-info" @click="sendEmail">
        {{ $t("edit_campaign.teams_button_send_email", "Send email") }}
      </b-button>
      <b-button
        :disabled="!teamSMSMessaging"
        class="m-3 bttn bttn--sm bttn--orange"
        variant="outline-info"
        @click="sendSMS"
      >
        {{ $t("edit_campaign.teams_button_send_sms", "Send SMS") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      teamSMSMessaging: (state) => state.account.teamSMSMessaging,
      hasAccessAdd: (state) => state.account.access.CanAddCampaignTeams,
      hasAccessEdit: (state) => state.account.access.CanEditCampaignTeams,
    }),
  },
  methods: {
    addTeam() {
      this.$emit("add-team");
    },
    importTeams() {
      this.$emit("import-teams");
    },
    exportTeams() {
      this.$emit("export-teams");
    },
    sendEmail() {
      this.$emit("send-email");
    },
    sendSMS() {
      this.$emit("send-sms");
    },
  },
};
</script>

<style lang="scss" scoped></style>
