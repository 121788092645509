<template>
  <div>
    <div class="d-flex justify-content-center">
      <button v-if="!success" class="bttn bttn--lg bttn--orange" @click="send">
        {{ btnTitle }}
      </button>
      <b-alert v-else variant="success" show>
        {{ $t("dashboard.pledge_notification_to_pay_sent_success", "Sent successfully") }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls";
import { notificationsMixin } from "@/mixins";

export default {
  props: {
    donationID: {
      type: [Number, String],
      default: 0,
    },
    donationAttributes: {
      type: Object,
      default: () => {},
    },
  },

  mixins: [notificationsMixin],

  data() {
    return {
      success: false,
    };
  },

  computed: {
    orgId() {
      return localStorage.getItem("orgId");
    },
    sent() {
      return this.donationAttributes.pledge_notification_to_pay_sent;
    },
    btnTitle() {
      if (this.sent) {
        return this.$t("dashboard.pledge_notification_to_pay_resend", "Re-send");
      }
      return this.$t("dashboard.pledge_notification_to_pay_send", "Send");
    },
  },

  methods: {
    send() {
      axios
        .post(
          urls.donationNotification
            .replace(":oid", this.orgId)
            .replace(":cid", this.donationAttributes.campaign_id)
            .replace(":did", this.donationID)
            .replace(":type", "pledge_to_pay")
        )
        .then(() => {
          this.$_notificationsMixin_makeToast("Sent", "", "success");
          this.success = true;
        })
        .catch((e) => {
          this.$_notificationsMixin_handleCatch(e);
        });
    },
  },
};
</script>

<style></style>
