<template>
  <div class="card">
    <div class="card-header" role="tab">
      <h2 class="mb-0">
        <b-button block v-b-toggle.accordion-jle-config variant="link">
          {{ $t("edit_campaign.donation_level_jle_config", "Journal config") }}
        </b-button>
      </h2>
    </div>
    <b-collapse id="accordion-jle-config" accordion="accordion-jle-config" role="tabpanel">
      <div class="card-body">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Icon">
              <image-upload v-model="value.icon" label="Upload icon" class="m-auto" />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Background image">
              <image-upload
                v-model="value.bg_url"
                label="Upload background image"
                type="campaign"
                class="m-auto"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Row">
              <b-form-input
                v-model="value.row"
                placeholder="Enter row number"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Adds">
              <b-form-input v-model="value.adds" placeholder="Enter adds"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Request" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group v-model="value.request" :aria-describedby="ariaDescribedby">
                <b-form-checkbox value="image"> Image </b-form-checkbox>
                <b-form-checkbox value="name"> Name </b-form-checkbox>
                <b-form-checkbox value="description"> Dedication </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-col>

          <b-col v-if="requestDescription">
            <b-form-group label="Dedication label">
              <b-form-input
                v-model="value.dedication_label"
                placeholder="Enter dedication label"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col v-if="requestDescription">
            <b-form-group label="Dedication max symbols">
              <b-form-input
                v-model="value.max_symbols"
                placeholder="Enter dedication max symbols"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import imageUpload from "@/components/image-upload.vue";

export const defaultFormData = {
  row: 0,
  adds: "",
  icon: "",
  bg_url: "",
  show_textarea: true,
  dedication_label: "Your AD",
  max_symbols: 100,
  request: ["name", "description", "image"],
};

export default {
  components: {
    imageUpload,
  },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    requestDescription() {
      if (Array.isArray(this.value.request)) {
        return this.value.request.includes("description");
      }

      return false;
    },
  },

  mounted() {
    if (!Array.isArray(this.value.request)) {
      this.$set(this.value, "request", []);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .matcher-img {
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
