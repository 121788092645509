<template>
  <div>
    <slot :afterProceedLogin="afterProceedLogin" />
    <b-modal
      :ref="$options.MODAL_REF"
      size="xl"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      header-class="p-3"
      footer-class="p-3"
      body-class="p-0 w-100 h-100"
      content-class="h-100"
      :title="title"
      @hidden="handleHidden"
      @ok="handleOk"
    >
      <div class="d-flex w-100 h-100">
        <object class="w-100 h-100" :data="$options.DOCUMENT_URL" type="application/pdf">
          <iframe
            class="w-100 h-100"
            :src="`https://docs.google.com/viewer?url=${$options.DOCUMENT_URL}&embedded=true`"
          />
        </object>
      </div>
      <template #modal-footer="{ ok }">
        <button class="bttn bttn--lg bttn--orange" @click="ok">
          {{
            $t(
              "template.israel_legal_document_agreement_ok",
              "I agree with content of this document."
            )
          }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { getCookieJSON, setCookie } from "@/helpers/cookie";
import { routeUserAfterLogin } from "@/helpers/userRoute";
import { defineComponent } from "vue";
import { mapState } from "vuex";

const ISRAEL_LEGAL_DOCUMENT_AGREEMENT_COOKIE = "ISRAEL_LEGAL_DOCUMENT_AGREEMENT";

export default defineComponent({
  name: "LegalAgreementDocument",
  MODAL_REF: "LegalAgreementDocumentModal",
  DOCUMENT_URL:
    "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1656606658_a7b9700ebd67c3b1d4af539528c803aa_1656606658.pdf",

  data() {
    return {
      user: null,
      listeners: [],
    };
  },
  computed: {
    ...mapState({
      showIsraelLegalDocumentByIP: (state) => state.featureHide.showIsraelLegalDocument,
    }),
    title() {
      return this.$t("template.israel_legal_document_agreement_title", "Legal Agreement");
    },
  },
  mounted() {
    this.listeners.push(this.registerRootEvent());
  },
  beforeDestroy() {
    this.listeners.forEach((unsub) => unsub());
  },
  methods: {
    show() {
      this.$refs[this.$options.MODAL_REF].show();
    },
    hide() {
      this.$refs[this.$options.MODAL_REF].hide();
    },
    setILDACookie(orgId) {
      const cookieLocal = getCookieJSON(ISRAEL_LEGAL_DOCUMENT_AGREEMENT_COOKIE);
      cookieLocal[orgId] = "1";
      return new Promise((resolve) => {
        this.$nextTick(() => {
          setCookie(
            ISRAEL_LEGAL_DOCUMENT_AGREEMENT_COOKIE,
            [
              `${ISRAEL_LEGAL_DOCUMENT_AGREEMENT_COOKIE}=${JSON.stringify(cookieLocal)}`,
              process.env.NODE_ENV !== "development" ? `domain=.charidy.com` : null,
              "path=/",
            ],
            { reset: true, filterFn: Boolean }
          );
          resolve();
        });
      });
    },
    isCookieSet(orgId) {
      const cookie = getCookieJSON(ISRAEL_LEGAL_DOCUMENT_AGREEMENT_COOKIE);
      return cookie[orgId] && cookie[orgId] === "1";
    },
    registerRootEvent() {
      const eventName = "charidy::legal-agreement-document::open";
      const cb = (user) => {
        this.afterProceedLogin(user);
      };
      this.$root.$on(eventName, cb);
      return () => {
        this.$root.$off(eventName, cb);
      };
    },
    afterProceedLogin(data) {
      if (!data) {
        return;
      }

      const user = data.user;

      if (!this.showIsraelLegalDocumentByIP) {
        return routeUserAfterLogin(user);
      }

      if (this.isCookieSet(user.id)) {
        return routeUserAfterLogin(user);
      }

      this.user = user;
      this.show();
      return;
    },
    handleOk(bvEvent) {
      if (!this.user) {
        bvEvent.preventDefault();
        return;
      }

      this.setILDACookie(this.user.id).then(() => {
        this.hide();
        return routeUserAfterLogin(this.user);
      });
    },
    handleHidden() {
      this.user = null;
    },
  },
});
</script>

<style lang="scss" scoped></style>
