import urls from "../../constants/urls.js";
import axios from "axios";
import store from "../store.js";
import { mapIncludedToData } from "@/helpers/jsonapi.js";

const state = {
  donations: [],
  unpublished: [],
  pendingDonations: [],
  loadingDonation: true,
  donationTotal: 0,
  pendingDonationCounter: 0,
  unpublishedCount: 0,
  unpublishedActivated: false,
  gateways: [],
  included: [],
  bulkUnpublishedDonations: [],
  bulkPublishedDonations: [],
};

const generateBulkMutations = (stateField) => {
  return {
    [`check_${stateField}`](state, id) {
      state[stateField].push(id);
    },
    [`uncheck_${stateField}`](state, id) {
      if (id) {
        state[stateField] = state[stateField].filter((v) => id !== v);
      } else {
        state[stateField] = [];
      }
    },
  };
};

const mutations = {
  setDonation(state, data) {
    state.donations = data;
  },

  setIncluded(state, value) {
    state.included = value;
  },

  setPendingDonation(state, data) {
    state.pendingDonations = data;
  },

  setLoadingDonation(state, value) {
    state.loadingDonation = value;
  },

  setDonationTotal(state, value) {
    state.donationTotal = value;
  },

  setPendingDonationCounter(state, value) {
    state.pendingDonationCounter = value;
  },

  setUnpublishedList(state, value) {
    state.unpublished = value;
  },

  setUnpublishedCount(state, value) {
    state.unpublishedCount = value;
  },

  setGatewaysForFilter(state, value) {
    state.gateways = value;
  },

  setUnpublishedDonationsActivated(state, value) {
    state.unpublishedActivated = value;
  },

  ...generateBulkMutations("bulkUnpublishedDonations"),
  ...generateBulkMutations("bulkPublishedDonations"),
};

const getters = {};

const actions = {
  getCampaignDonation({ commit }, props) {
    commit("setLoadingDonation", true);
    let orgId = localStorage.getItem("orgId");
    axios
      .get(
        urls.donationNewApi.replace(":orgId", orgId).replace(":campaignId", props.id) +
          props.setting +
          `&loc=${store.state.translation.locate}`
      )
      .then((response) => {
        let donation = response.data.data;
        const { included } = response.data;
        commit("setIncluded", included);
        commit("setDonation", mapIncludedToData(donation, included));
        commit("setDonationTotal", response.headers["x-search-donations"]);
      })
      .finally(() => {
        commit("setLoadingDonation", false);
      });
  },

  getPendingCampaignDonation({ commit }, props) {
    commit("setLoadingDonation", true);
    let orgId = localStorage.getItem("orgId");
    axios
      .get(
        urls.donationNewApi.replace(":orgId", orgId).replace(":campaignId", props.id) +
          props.setting +
          `&loc=${store.state.translation.locate}`
      )
      .then((response) => {
        let donation = response.data.data;
        const { included } = response.data;
        commit("setIncluded", included);
        commit("setPendingDonation", donation);
        commit("setPendingDonationCounter", response.headers["x-search-donations"]);
      })
      .finally(() => {
        commit("setLoadingDonation", false);
      });
  },

  getUnpublishedDonationsActivated({ commit }, cmpId) {
    const orgId = localStorage.getItem("orgId");
    const params = {
      module_name: "unpublishable_donations",
    };
    axios
      .get(urls.getModule.replace(":oid", orgId).replace(":cid", cmpId), {
        params,
      })
      .then(({ data }) => {
        const { active } = data.data.attributes;
        commit("setUnpublishedDonationsActivated", active);
      })
      .catch(() => {
        commit("setUnpublishedDonationsActivated", false);
      });
  },

  getUnpublishedDonations({ commit, rootState }, [cId, getParams = {}]) {
    commit("setLoadingDonation", true);
    const orgId = localStorage.getItem("orgId");
    const params = {
      status: "unpublished",
      loc: rootState.translation.locate,
      ...getParams,
    };

    axios
      .get(urls.donationNewApi.replace(":orgId", orgId).replace(":campaignId", cId), { params })
      .then(({ data, headers }) => {
        const { included } = data;
        commit("setIncluded", included);
        commit("setUnpublishedCount", headers["x-search-donations"]);
        commit("setUnpublishedList", mapIncludedToData(data.data, included));
      })
      .finally(() => {
        commit("setLoadingDonation", false);
      });
  },

  publishDonations({ state, commit }, [cId, ids, publish]) {
    const orgId = localStorage.getItem("orgId");
    const data = {
      data: {
        attributes: {
          donation_ids: ids,
          publish,
          all: publish && ids.length === 0,
        },
      },
    };

    return axios
      .post(urls.unpublishableDonations.replace(":oid", orgId).replace(":cmpid", cId), data)
      .then((res) => {
        const cnt = +state.unpublishedCount + (publish ? -1 : 1);
        commit("setUnpublishedCount", cnt);
        return res;
      });
  },

  updateCampaignDonation({ commit }, props) {
    let orgId = localStorage.getItem("orgId");
    axios
      .get(
        urls.donationNewApi.replace(":orgId", orgId).replace(":campaignId", props.id) +
          props.setting +
          `&loc=${store.state.translation.locate}`
      )
      .then((response) => {
        let donation = response.data.data;
        const { included } = response.data;
        commit("setIncluded", included);
        commit("setDonation", donation);
        commit("setDonationTotal", response.headers["x-search-donations"]);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  getOrganizationDonotion({ commit }, props) {
    commit("setLoadingDonation", true);

    let orgId = localStorage.orgId;

    axios
      .get(
        urls.orgDonationsApi.replace(":orgId", orgId) +
          props +
          `&loc=${store.state.translation.locate}`
      )
      .then((response) => {
        let donation = response.data.data;
        const { included } = response.data;
        commit("setIncluded", included);
        commit("setDonation", donation);
        commit("setDonationTotal", response.headers["x-search-donations"]);
      })
      .finally(() => {
        commit("setLoadingDonation", false);
      });
  },

  updateOrganizationDonotion({ commit }, props) {
    let orgId = localStorage.orgId;

    axios
      .get(
        urls.orgDonationsApi.replace(":orgId", orgId) +
          props +
          `&loc=${store.state.translation.locate}`
      )
      .then((response) => {
        let donation = response.data.data;
        const { included } = response.data;
        commit("setIncluded", included);
        commit("setDonation", donation);
        commit("setDonationTotal", response.headers["x-search-donations"]);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  getGatewaysByOrg({ commit }) {
    let orgId = localStorage.orgId;

    axios.get(urls.gatewaysForFilterByOrg.replace(":orgId", orgId)).then((response) => {
      let gateways = response.data.data;
      commit("setGatewaysForFilter", gateways);
    });
  },

  gatewaysForFilterByCampaign({ commit }, campaignId) {
    let orgId = localStorage.getItem("orgId");
    axios
      .get(
        urls.gatewaysForFilterByCampaign.replace(":orgId", orgId).replace(":campaignId", campaignId)
      )
      .then((response) => {
        let gateways = response.data.data;
        commit("setGatewaysForFilter", gateways);
      });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
