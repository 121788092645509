<template>
  <div class="row">
    <div class="col-12 col-lg-5">
      <account-detail></account-detail>
      <org-notification-from-email />
      <org-notification-emails />
      <sub-account v-if="showSubAccounts"></sub-account>
    </div>

    <div class="col-12 col-lg-7">
      <account-settings></account-settings>
    </div>
  </div>
</template>

<script>
import accountDetail from "./subcomponents/accountDetail.vue";
import accountSettings from "./subcomponents/accountSettings.vue";
import subAccount from "./subcomponents/subAccount.vue";
import OrgNotificationEmails from "./subcomponents/orgNotificationEmails.vue";
import OrgNotificationFromEmail from "./subcomponents/orgNotificationFromEmail.vue";
import { mapState } from "vuex";

export default {
  components: {
    accountDetail,
    accountSettings,
    subAccount,
    OrgNotificationEmails,
    OrgNotificationFromEmail,
  },

  created() {
    this.$store.dispatch("getEntities");
    this.$store.dispatch("getContacts");
    this.$store.dispatch("initData");
  },

  computed: {
    ...mapState({
      orgSettingsLoaded: (state) => state.account.orgSettingsLoaded,
      acc: (state) => state.account,
      showSubAccounts: (state) => state.featureHide.showSubAccounts,
    }),
  },
};
</script>

<style lang="scss">
#account-settings {
  .multiselect {
    min-height: 50px;

    &__tags {
      padding: 7px 40px 0px 8px;
      min-height: 50px;
      max-height: 50px;
      border: 1px solid rgba(32, 67, 78, 0.15);
      transition: all 0.5s;
      &:hover,
      &:focus {
        border-color: #0084af;
      }
    }
    &__select {
      top: 0;
      padding: 0;
      height: 50px;
      &:before {
        top: 61%;
      }
    }
    &__single {
      margin-bottom: 0;
      height: 34px;
      line-height: 34px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__input {
      height: 34px;
    }
  }
}
</style>
