<template>
  <div>
    <div class="category-title">
      <h1 class="h1-like">
        {{ $t("dashboard.media_storage_title", "Media Storage") }}
      </h1>
      <div class="action-block">
        <media-storage-batch-actions :items="selectedItems" />
        <div class="item">
          <b-button class="bttn bttn--lg bttn--orange" @click="openAddEdit()">
            {{ $t("dashboard.media_storage_add_new_media", "Add new media") }}
          </b-button>
        </div>
      </div>
    </div>

    <b-table
      ref="MediaStorageTable"
      id="media-storage-table"
      head-variant="light"
      :busy="loading"
      :items="items"
      :fields="fields"
      @row-selected="onRowSelected"
      selectable
      select-mode="range"
    >
      <!-- Select One -->
      <template v-slot:cell(select)="{ rowSelected }">
        <template v-if="rowSelected">
          <b-form-checkbox :checked="true" :disabled="true" />
        </template>
        <template v-else>
          <b-form-checkbox :checked="false" :disabled="true" />
        </template>
      </template>
      <!-- Select All -->
      <template v-slot:head(select)>
        <b-form-checkbox v-model="selectAll"></b-form-checkbox>
      </template>

      <template v-slot:cell(order)="{ item }">
        <div class="d-flex flex-row align-items-center">
          <span class="mx-4">
            {{ item.order }}
          </span>
          <div class="d-flex flex-column">
            <button
              type="button"
              :disabled="loadingChangeOrder"
              class="bttn-round my-1"
              @click="changeOrderUp(item)"
              :title="$t('edit_campaign.image_change_order_up')"
            >
              <b-icon-arrow-up-short />
            </button>
            <button
              type="button"
              :disabled="loadingChangeOrder || item.order === 0"
              class="bttn-round my-1"
              @click="changeOrderDown(item)"
              :title="$t('edit_campaign.image_change_order_down')"
            >
              <b-icon-arrow-down-short />
            </button>
          </div>
        </div>
      </template>

      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>

      <template v-slot:cell(preview)="{ index, item }">
        <b-img
          v-if="!item.tag.includes('video')"
          :id="`media-storage-preview-${index}`"
          :src="item.src"
          fluid
          :alt="item.tag"
          style="max-width: 250px"
        ></b-img>

        <span v-else> No preview </span>

        <b-popover
          :target="`media-storage-preview-${index}`"
          triggers="hover"
          placement="right"
          custom-class="media-storage-preview"
        >
          <div>
            <img :src="item.src" :alt="item.tag" width="500px" />
          </div>
        </b-popover>
      </template>

      <template v-slot:cell(src)="{ index, item }">
        <a
          class="copy-link"
          v-b-popover.hover.top="
            copied !== index ? item.src : $t('dashboard.utm_link_copied_to_clipboard')
          "
          @click="copy(index, item.src)"
          role="button"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.40156 19.4896L16.6016 19.4896C16.8137 19.4896 17.0172 19.4054 17.1672 19.2554C17.3173 19.1055 17.4016 18.9021 17.4016 18.6901L17.4016 4.29948C17.4016 4.08744 17.3173 3.88409 17.1672 3.73416C17.0172 3.58423 16.8137 3.5 16.6016 3.5H7.80156C7.69702 3.50126 7.59374 3.52299 7.49756 3.56396C7.39865 3.60059 7.30866 3.65782 7.23356 3.73185L4.83356 6.13029C4.7616 6.20396 4.70456 6.29083 4.66556 6.38612C4.62526 6.48525 4.60356 6.59094 4.60156 6.69792V18.6901C4.60156 18.9021 4.68585 19.1055 4.83588 19.2554C4.98591 19.4054 5.18939 19.4896 5.40156 19.4896ZM8.60156 5.09896L15.8016 5.09896L15.8016 17.8906L6.20156 17.8906L6.20156 7.4974H7.80156C8.01374 7.4974 8.21722 7.41317 8.36725 7.26324C8.51728 7.1133 8.60156 6.90995 8.60156 6.69792V5.09896ZM18.5859 21.5L9.97426 21.4805C9.97426 21.4805 9.17188 21.4805 9.17188 20.6841C9.17188 19.8878 9.97426 19.8878 9.97426 19.8878H17.804L17.804 8.29687C17.804 8.29687 17.8016 7.49739 18.6016 7.49739C19.4016 7.49739 19.3984 8.29687 19.3984 8.29687L19.3984 20.6738C19.3984 20.8858 19.3258 21.0924 19.1758 21.2423C19.0258 21.3923 18.7981 21.5 18.5859 21.5Z"
              fill="#107598"
            />
          </svg>
          {{ $t("dashboard.utm_copy_url") }}
        </a>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="link" no-caret>
          <template v-slot:button-content>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 18.5C14 17.3954 13.1046 16.5 12 16.5C10.8954 16.5 10 17.3954 10 18.5C10 19.6046 10.8954 20.5 12 20.5C13.1046 20.5 14 19.6046 14 18.5Z"
                fill="#107598"
              />
              <path
                d="M14 12.5C14 11.3954 13.1046 10.5 12 10.5C10.8954 10.5 10 11.3954 10 12.5C10 13.6046 10.8954 14.5 12 14.5C13.1046 14.5 14 13.6046 14 12.5Z"
                fill="#107598"
              />
              <path
                d="M14 6.5C14 5.39543 13.1046 4.5 12 4.5C10.8954 4.5 10 5.39543 10 6.5C10 7.60457 10.8954 8.5 12 8.5C13.1046 8.5 14 7.60457 14 6.5Z"
                fill="#107598"
              />
            </svg>
          </template>
          <b-dropdown-item-button @click="openAddEdit(item)">
            {{ $t("dashboard.utm_actions_edit") }}
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="remove(item.id)">
            <span class="text-danger">
              {{ $t("dashboard.utm_actions_delete") }}
            </span>
          </b-dropdown-item-button>
        </b-dropdown>
      </template>
    </b-table>

    <MediaStorageAddEditModal ref="modal" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { notificationsMixin } from "@/mixins";

import MediaStorageBatchActions from "./MediaStorageBatchActions.vue";
import MediaStorageAddEditModal from "./MediaStorageAddEditModal.vue";

export default {
  components: {
    MediaStorageAddEditModal,
    MediaStorageBatchActions,
  },

  mixins: [notificationsMixin],

  data() {
    return {
      fields: [
        "select",
        {
          key: "preview",
          label: this.$t("dashboard.media_storage_th_preview", "Preview"),
        },
        {
          key: "campaign_id",
          label: this.$t("dashboard.media_storage_th_cid", "CID"),
        },
        {
          key: "tag",
          label: this.$t("dashboard.media_storage_th_tag", "Tag"),
        },
        {
          key: "lang",
          label: this.$t("dashboard.media_storage_th_lang", "Lang"),
        },
        {
          key: "team_id",
          label: this.$t("dashboard.media_storage_th_tid", "TID"),
        },
        {
          key: "order",
          label: this.$t("dashboard.media_storage_th_order", "Order"),
        },
        {
          key: "src",
          label: this.$t("dashboard.media_storage_th_src", "Src"),
        },
        {
          key: "actions",
          label: "",
        },
      ],
      page: 1,
      per_page: 20,
      copied: null,
      selectedItems: [],
      selectAll: false,
      loadingChangeOrder: false,
    };
  },

  computed: {
    ...mapState({
      cmpId: (state) => state.mediaStorage.campaign.id,
      loading: (state) => state.mediaStorage.mediaListLoading,
      items: (state) => state.mediaStorage.mediaListGrouped,
    }),
  },

  watch: {
    selectAll(val) {
      if (val) {
        this.selectAllRows();
      } else {
        this.clearSelected();
      }
    },
  },

  methods: {
    ...mapActions({
      fetchRemoveMedia: "mediaStorage/fetchRemoveMedia",
      fetchCreateEditMedia: "mediaStorage/fetchCreateEditMedia",
    }),

    openAddEdit(item = null) {
      if (item) {
        this.$refs.modal.open("edit", item);
      }

      this.$refs.modal.open("add");
    },

    remove(mID) {
      const message = this.$t("edit_campaign.confirm_delete_image");

      const options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.fetchRemoveMedia({ cID: this.cmpId, mID }).then(() => {
          this.$_notificationsMixin_makeToast(
            "Success!",
            this.$t("dashboard.media_storage_success_delete", "Deleted successfully"),
            "success"
          );
        });
      });
    },

    async changeOrder(item, order) {
      const form = structuredClone(item);
      form.order = order;

      this.loadingChangeOrder = true;
      try {
        await this.fetchCreateEditMedia({
          mID: form.id,
          form,
          localLoading: true,
        });
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
      } finally {
        this.loadingChangeOrder = false;
      }
    },

    async changeOrderUp(item) {
      await this.changeOrder(item, item.order + 1);
    },

    async changeOrderDown(item) {
      await this.changeOrder(item, item.order - 1);
    },

    copy(i, text) {
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
      this.copied = i;
    },

    onRowSelected(items) {
      this.selectedItems = items;
    },
    selectAllRows() {
      this.$refs.MediaStorageTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.MediaStorageTable.clearSelected();
    },
  },
};
</script>

<style lang="scss">
.media-storage-preview {
  max-width: unset !important;
  width: auto;
}
</style>

<style lang="scss" scoped>
::v-deep {
  #media-storage-table {
    td {
      vertical-align: middle;
    }
  }
}
</style>
