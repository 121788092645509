<template>
  <div>
    <b-modal
      id="vetting-form-madal"
      size="xl"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @hide="clearData"
    >
      <div slot="modal-header">
        <h2>{{ $t(`dashboard.vetting_${type}_modal_title`) }}</h2>
      </div>

      <ValidationObserver ref="observer">
        <form id="vetting-form" @submit.prevent="presubmit('submitted')">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_head_of_org") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.head_of_org"> </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_position_at_org") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.position_at_org"> </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Email  -->
          <!-- Phone Number -->
          <b-row>
            <b-col md="6" sm="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_org_phone") }}
                  </label>
                </template>
                <b-row>
                  <b-col cols="4">
                    <b-form-input v-model="form.meta.org_phone_area_code" type="tel">
                    </b-form-input>
                    <small class="form-text text-muted">
                      {{ $t("dashboard.vetting_form_org_phone_area_code") }}
                    </small>
                  </b-col>
                  <b-col cols="8">
                    <b-form-input v-model="form.meta.org_phone_num" type="tel"> </b-form-input>
                    <small class="form-text text-muted">
                      {{ $t("dashboard.vetting_form_org_phone_num") }}
                    </small>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_org_email") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.org_email" type="email"> </b-form-input>
                <small class="form-text text-muted"> example@example.com </small>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Address -->
          <b-row>
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_address") }}
                  </label>
                </template>
                <b-row>
                  <b-col cols="6" sm="3">
                    <b-form-input v-model="form.meta.address_street"> </b-form-input>
                    <small class="form-text text-muted">
                      {{ $t("dashboard.vetting_form_address_street") }}
                    </small>
                  </b-col>
                  <b-col cols="6" sm="3">
                    <b-form-input v-model="form.meta.address_street_2"> </b-form-input>
                    <small class="form-text text-muted">
                      {{ $t("dashboard.vetting_form_address_street_2") }}
                    </small>
                  </b-col>
                  <b-col md="2">
                    <b-form-input v-model="form.meta.address_city"> </b-form-input>
                    <small class="form-text text-muted">
                      {{ $t("dashboard.vetting_form_address_city") }}
                    </small>
                  </b-col>
                  <b-col cols="6" sm="2">
                    <b-form-input v-model="form.meta.address_state"> </b-form-input>
                    <small class="form-text text-muted">
                      {{ $t("dashboard.vetting_form_address_state") }}
                    </small>
                  </b-col>
                  <b-col cols="6" sm="2">
                    <b-form-input v-model="form.meta.address_zip"> </b-form-input>
                    <small class="form-text text-muted">
                      {{ $t("dashboard.vetting_form_address_zip") }}
                    </small>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- What your organization does? -->
          <b-row>
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_what_org_does") }}
                  </label>
                </template>
                <b-form-textarea v-model="form.meta.what_org_does" rows="2"> </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Number of Alumni? -->
          <!-- How many people does the organization serve annually? -->
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_num_of_alumni") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.num_of_alumni" type="tel"> </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_how_many_people_does_org_serve_annually") }}
                  </label>
                </template>
                <b-form-input
                  v-model="form.meta.how_many_people_does_org_serve_annually"
                  type="tel"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Where does your funding come from -->
          <b-row>
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_where_does_funding_come_from") }}
                  </label>
                </template>
                <b-row>
                  <b-col
                    cols="6"
                    v-for="opt in options.where_does_funding_come_from"
                    :key="opt.value"
                  >
                    <div class="m-0 form-checkbox form-checkbox--orange">
                      <input
                        v-model="form.meta.where_does_funding_come_from"
                        :id="`where_does_funding_come_from_${opt.value}`"
                        :value="opt.value"
                        class="form-checkbox__input"
                        type="checkbox"
                      />
                      <label
                        class="form-checkbox__label"
                        :for="`where_does_funding_come_from_${opt.value}`"
                      >
                        {{ $t(`dashboard.vetting_form_funding_come_from_${opt.value}`) }}
                      </label>
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- How far back does your donor records go? -->
          <!-- How much did it raise? -->
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_how_far_back_does_donor_records") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.how_far_back_does_donor_records"> </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_how_much_did_it_raise") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.how_much_did_it_raise"> </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- From how many donors? -->
          <!-- Areas of service -->
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_from_how_many_donors") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.from_how_many_donors"> </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_areas_of_service") }}
                  </label>
                </template>
                <b-form-select
                  v-model="form.meta.areas_of_service"
                  :options="options.areas_of_service"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- What is the name of your organization -->
          <!-- Name of individual completing this form -->
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required text-break">
                    {{ $t("dashboard.vetting_form_org_name") }}
                  </label>
                </template>
                <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                  <b-form-input
                    v-model="form.meta.org_name"
                    :class="{ 'has-error': errors.length }"
                  >
                  </b-form-input>
                  <div
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    class="invalid-feedback d-block is-danger"
                  >
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required text-break">
                    {{ $t("dashboard.vetting_form_name_of_individual_completing_form") }}
                  </label>
                </template>
                <b-row>
                  <b-col cols="6">
                    <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                      <b-form-input
                        v-model="form.meta.first_name_of_individual_completing_form"
                        :class="{ 'has-error': errors.length }"
                      >
                      </b-form-input>
                      <div
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        class="invalid-feedback d-block is-danger"
                      >
                        {{ errors[0] }}
                      </div>
                      <small class="form-text text-muted">
                        {{ $t("dashboard.vetting_form_first_name") }}
                      </small>
                    </ValidationProvider>
                  </b-col>
                  <b-col cols="6">
                    <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                      <b-form-input
                        v-model="form.meta.last_name_of_individual_completing_form"
                        :class="{ 'has-error': errors.length }"
                      >
                      </b-form-input>
                      <div
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                        class="invalid-feedback d-block is-danger"
                      >
                        {{ errors[0] }}
                      </div>
                      <small class="form-text text-muted">
                        {{ $t("dashboard.vetting_form_last_name") }}
                      </small>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- What is your email address -->
          <!-- What is your annual operating budget -->
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required text-break">
                    {{ $t("dashboard.vetting_form_email_address") }}
                  </label>
                </template>
                <ValidationProvider name=" " rules="required|email" v-slot="{ errors }">
                  <b-form-input
                    v-model="form.meta.email_address"
                    type="email"
                    :class="{ 'has-error': errors.length }"
                  >
                  </b-form-input>
                  <div
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    class="invalid-feedback d-block is-danger"
                  >
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required text-break">
                    {{ $t("dashboard.vetting_form_annual_operating_budget") }}
                  </label>
                </template>
                <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                  <b-form-input
                    v-model="form.meta.annual_operating_budget"
                    :class="{ 'has-error': errors.length }"
                  >
                  </b-form-input>
                  <div
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    class="invalid-feedback d-block is-danger"
                  >
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Please provide more detailed information about your latest fundraiser -->
          <b-row>
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required text-break">
                    {{ $t("dashboard.vetting_form_more_info_about_last_fundraiser") }}
                  </label>
                </template>
                <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                  <b-form-textarea
                    v-model="form.meta.more_info_about_last_fundraiser"
                    :class="{ 'has-error': errors.length }"
                    rows="2"
                  >
                  </b-form-textarea>
                  <div
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    class="invalid-feedback d-block is-danger"
                  >
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- What types of fundraisers do you hold? -->
          <b-row>
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required text-break">
                    {{ $t("dashboard.vetting_form_types_of_fundraisers") }}
                  </label>
                </template>
                <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                  <b-row>
                    <b-col
                      v-for="opt in options.types_of_fundraisers"
                      :key="opt.value"
                      :cols="opt.value === 'other' ? 12 : 6"
                    >
                      <b-row>
                        <b-col>
                          <div
                            class="m-0 form-checkbox form-checkbox--orange"
                            :class="{ 'has-error': errors.length }"
                          >
                            <input
                              v-model="form.meta.types_of_fundraisers"
                              :id="`types_of_fundraisers_${opt.value}`"
                              :value="opt.value"
                              class="form-checkbox__input"
                              type="checkbox"
                            />
                            <label
                              class="form-checkbox__label"
                              :for="`types_of_fundraisers_${opt.value}`"
                            >
                              {{ $t(`dashboard.vetting_form_types_of_fundraisers_${opt.value}`) }}
                            </label>
                          </div>
                        </b-col>
                        <b-col
                          v-if="
                            opt.value === 'other' &&
                            form.meta.types_of_fundraisers.includes('other')
                          "
                          cols="12"
                          sm="6"
                        >
                          <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                            <b-form-input
                              v-model="form.meta.other_type_of_fundraisers"
                              :class="{ 'has-error': errors.length }"
                              :placeholder="
                                $t(`dashboard.vetting_form_types_of_fundraisers_${opt.value}`)
                              "
                            >
                            </b-form-input>
                            <div
                              role="alert"
                              aria-live="assertive"
                              aria-atomic="true"
                              class="invalid-feedback d-block is-danger"
                            >
                              {{ errors[0] }}
                            </div>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-col>
                    <div
                      role="alert"
                      aria-live="assertive"
                      aria-atomic="true"
                      class="invalid-feedback d-block is-danger"
                    >
                      {{ errors[0] }}
                    </div>
                  </b-row>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Number of donors by criteria -->
          <b-row>
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required text-break">
                    {{ $t("dashboard.vetting_form_num_of_donors_by_criteria") }}
                  </label>
                </template>
                <b-row>
                  <b-col cols="4" sm="6" class="p-2">
                    {{ $t("dashboard.vetting_form_num_of_donors_by_amount") }}
                  </b-col>
                  <b-col cols="4" sm="3" class="bg-light border text-center p-2">
                    {{ $t("dashboard.vetting_form_num_of_donors_last_year") }}
                  </b-col>
                  <b-col cols="4" sm="3" class="bg-light border text-center p-2">
                    {{ $t("dashboard.vetting_form_num_of_donors_last_2_3_years") }}
                  </b-col>
                </b-row>
                <b-row
                  v-for="opt in options.num_of_donors_by_criteria"
                  :key="`num_of_donors_by_criteria_${opt.text}`"
                  align-v="center"
                  class="bg-light border"
                >
                  <b-col cols="4" sm="6">
                    {{
                      opt.key === "total"
                        ? $t("dashboard.vetting_form_num_of_donors_total")
                        : opt.text
                    }}
                  </b-col>
                  <b-col cols="4" sm="3" class="border-left text-center p-2">
                    <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                      <b-form-input
                        v-model="form.meta.num_of_donors_by_criteria[opt.key].last_year"
                        :class="{ 'has-error': errors.length }"
                        type="tel"
                      >
                      </b-form-input>
                    </ValidationProvider>
                  </b-col>
                  <b-col cols="4" sm="3" class="border-left text-center p-2">
                    <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                      <b-form-input
                        v-model="form.meta.num_of_donors_by_criteria[opt.key].last_2_3_years"
                        :class="{ 'has-error': errors.length }"
                        type="tel"
                      >
                      </b-form-input>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Please expand on this answer in regards to funds and budget allocation: -->
          <!-- How many individuals does your organization currently service -->
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{
                      $t("dashboard.vetting_form_expand_answer_in_regards_funds_budget_allocation")
                    }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.expand_answer_in_regards_funds_budget_allocation">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required text-break">
                    {{
                      $t(
                        "dashboard.vetting_form_how_many_individuals_organization_currently_service"
                      )
                    }}
                  </label>
                </template>
                <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                  <b-form-input
                    v-model="form.meta.how_many_individuals_organization_currently_service"
                    :class="{ 'has-error': errors.length }"
                  >
                  </b-form-input>
                  <div
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    class="invalid-feedback d-block is-danger"
                  >
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- How many staff members will you have working on your Charidy campaign? -->
          <!-- How many employees/staffers does your organization have? -->
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{
                      $t(
                        "dashboard.vetting_form_how_many_staff_members_will_working_on_charidy_campaign"
                      )
                    }}
                  </label>
                </template>
                <b-form-input
                  v-model="form.meta.how_many_staff_members_will_working_on_charidy_campaign"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required text-break">
                    {{ $t("dashboard.vetting_form_how_many_employees_staffers_does_org_have") }}
                  </label>
                </template>
                <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                  <b-form-input
                    v-model="form.meta.how_many_employees_staffers_does_org_have"
                    :class="{ 'has-error': errors.length }"
                  >
                  </b-form-input>
                  <div
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    class="invalid-feedback d-block is-danger"
                  >
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- How many volunteers do you have that you can rely upon for your Charidy campaign? -->
          <!-- Are there any particular blackout dates for which a campaign wouldn’t be possible because staff would not be available? (staff vacation days, etc.) -->
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required text-break">
                    {{ $t("dashboard.vetting_form_how_many_volunteers_do_u_have") }}
                  </label>
                </template>
                <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                  <b-form-input
                    v-model="form.meta.how_many_volunteers_do_u_have"
                    :class="{ 'has-error': errors.length }"
                  >
                  </b-form-input>
                  <div
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    class="invalid-feedback d-block is-danger"
                  >
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_any_particular_blackout_dates") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.any_particular_blackout_dates"> </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <h2 class="mb-3">{{ $t(`dashboard.vetting_marketing_social_media`) }}</h2>

          <!-- Do you have a website? -->
          <!-- What is the link to your website? -->
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_have_website") }}
                  </label>
                </template>
                <b-row>
                  <b-col>
                    <b-form-radio v-model="form.meta.have_website" name="have_website" value="yes">
                      {{ $t("dashboard.vetting_form_have_website_yes") }}
                    </b-form-radio>
                  </b-col>
                  <b-col>
                    <b-form-radio v-model="form.meta.have_website" name="have_website" value="no">
                      {{ $t("dashboard.vetting_form_have_website_no") }}
                    </b-form-radio>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_link_to_website") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.link_to_website"> </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Paste the link to your Facebook page: -->
          <!-- How many Facebook Followers do you have? -->
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_link_to_facebook") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.link_to_facebook"> </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_how_many_facebook_followers_do_u_have") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.how_many_facebook_followers_do_u_have">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Paste the link to your Instagram handle: -->
          <!-- How many Instagram Followers do you have? -->
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_link_to_instagram") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.link_to_instagram"> </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_how_many_instagram_followers_do_u_have") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.how_many_instagram_followers_do_u_have">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Paste the link to your Twitter  handle: -->
          <!-- How many Twitter  Followers do you have? -->
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_link_to_twitter") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.link_to_twitter"> </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_how_many_twitter_followers_do_u_have") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.how_many_twitter_followers_do_u_have">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- How many numbers are on your call list? -->
          <!-- What is the size of your email list? -->
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required text-break">
                    {{ $t("dashboard.vetting_form_how_many_nums_on_call_list") }}
                  </label>
                </template>
                <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                  <b-form-input
                    v-model="form.meta.how_many_nums_on_call_list"
                    type="tel"
                    :class="{ 'has-error': errors.length }"
                  >
                  </b-form-input>
                  <div
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    class="invalid-feedback d-block is-danger"
                  >
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required text-break">
                    {{ $t("dashboard.vetting_form_size_of_your_email_list") }}
                  </label>
                </template>
                <ValidationProvider name=" " rules="required" v-slot="{ errors }">
                  <b-form-input
                    v-model="form.meta.size_of_your_email_list"
                    type="tel"
                    :class="{ 'has-error': errors.length }"
                  >
                  </b-form-input>
                  <div
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                    class="invalid-feedback d-block is-danger"
                  >
                    {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- How many unique mailing addresses do you have? -->
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_how_many_unique_mailing_addresses") }}
                  </label>
                </template>
                <b-form-input v-model="form.meta.how_many_unique_mailing_addresses" type="tel">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Please provide any additional information that you believe can be helpful for us to assess an obtainable goal for your organization. -->
          <b-row>
            <b-col>
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label text-break">
                    {{ $t("dashboard.vetting_form_additional_information") }}
                  </label>
                </template>
                <b-form-textarea v-model="form.meta.additional_information" rows="2">
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>

          <error :response.sync="error" :alert="true"></error>
        </form>
      </ValidationObserver>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click="close">
          {{ $t(`dashboard.cancel_btn`) }}
        </button>
        <button type="submit" class="bttn bttn--lg bttn--orange" form="vetting-form">
          {{ $t(`dashboard.vetting_submit_modal_btn`) }}
        </button>
        <button class="bttn bttn--lg bttn--orange" @click="presubmit('draft')">
          {{ $t(`dashboard.vetting_${type}_modal_btn`) }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { notificationsMixin } from "@/mixins";

import error from "@/components/error-message.vue";

const defaultMetaForm = {
  head_of_org: "",
  position_at_org: "",
  // org phone / email
  org_phone_area_code: "",
  org_phone_num: "",
  org_email: "",
  // address
  address_street: "",
  address_street_2: "",
  address_city: "",
  address_state: "",
  address_zip: "",
  what_org_does: "",
  num_of_alumni: "",
  how_many_people_does_org_serve_annually: "",
  where_does_funding_come_from: [],
  how_far_back_does_donor_records: "",
  how_much_did_it_raise: "",
  areas_of_service: "",
  org_name: "",
  first_name_of_individual_completing_form: "",
  last_name_of_individual_completing_form: "",
  email_address: "",
  annual_operating_budget: "",
  more_info_about_last_fundraiser: "",
  types_of_fundraisers: [],
  other_type_of_fundraisers: "",
  num_of_donors_by_criteria: {
    "5_100": { last_year: "", last_2_3_years: "" },
    "101_500": { last_year: "", last_2_3_years: "" },
    "501_1000": { last_year: "", last_2_3_years: "" },
    "1001_5000": { last_year: "", last_2_3_years: "" },
    "5001_10000": { last_year: "", last_2_3_years: "" },
    "10001_20000": { last_year: "", last_2_3_years: "" },
    "20001_50000": { last_year: "", last_2_3_years: "" },
    "50001_100000": { last_year: "", last_2_3_years: "" },
    more_100001: { last_year: "", last_2_3_years: "" },
    total: { last_year: "", last_2_3_years: "" },
  },
  expand_answer_in_regards_funds_budget_allocation: "",
  how_many_individuals_organization_currently_service: "",
  how_many_staff_members_will_working_on_charidy_campaign: "",
  how_many_employees_staffers_does_org_have: "",
  how_many_volunteers_do_u_have: "",
  any_particular_blackout_dates: "",
  have_website: "",
  link_to_website: "",
  link_to_facebook: "",
  how_many_facebook_followers_do_u_have: "",
  link_to_instagram: "",
  how_many_instagram_followers_do_u_have: "",
  link_to_twitter: "",
  how_many_twitter_followers_do_u_have: "",
  how_many_nums_on_call_list: "",
  size_of_your_email_list: "",
  how_many_unique_mailing_addresses: "",
  additional_information: "",
};

export default {
  components: {
    error,
    ValidationObserver,
    ValidationProvider,
  },

  mixins: [notificationsMixin],

  data() {
    return {
      type: "add",
      id: 0,
      form: {
        meta: defaultMetaForm,
        status: "draft",
      },
      options: {
        where_does_funding_come_from: [
          { value: "federal" },
          { value: "state" },
          { value: "city" },
          { value: "foundations" },
          { value: "private_donors" },
          { value: "other" },
        ],
        areas_of_service: [
          {
            value: "",
            text: this.$t("dashboard.vetting_form_pls_select_opt"),
          },
          {
            value: "animal",
            text: this.$t(`dashboard.vetting_form_areas_of_animal`),
          },
          {
            value: "arts_and_culture",
            text: this.$t(`dashboard.vetting_form_areas_of_arts_and_culture`),
          },
          {
            value: "education",
            text: this.$t(`dashboard.vetting_form_areas_of_education`),
          },
          {
            value: "environment",
            text: this.$t(`dashboard.vetting_form_areas_of_environment`),
          },
          {
            value: "health",
            text: this.$t(`dashboard.vetting_form_areas_of_health`),
          },
          {
            value: "humanitarian",
            text: this.$t(`dashboard.vetting_form_areas_of_humanitarian`),
          },
          {
            value: "religion_faith",
            text: this.$t(`dashboard.vetting_form_areas_of_religion_faith`),
          },
          {
            value: "memorial",
            text: this.$t(`dashboard.vetting_form_areas_of_memorial`),
          },
          {
            value: "disaster",
            text: this.$t(`dashboard.vetting_form_areas_of_disaster`),
          },
          {
            value: "medical",
            text: this.$t(`dashboard.vetting_form_areas_of_medical`),
          },
          {
            value: "gift",
            text: this.$t(`dashboard.vetting_form_areas_of_gift`),
          },
          {
            value: "other",
            text: this.$t(`dashboard.vetting_form_areas_of_other`),
          },
        ],
        types_of_fundraisers: [
          { value: "dinner_gala" },
          { value: "auction" },
          { value: "raffle" },
          { value: "mailers" },
          { value: "email_solicitation_campaign" },
          { value: "telethon" },
          { value: "crowdfunding_campaigns" },
          { value: "phone-a-thon" },
          { value: "other" },
        ],
        num_of_donors_by_criteria: [
          { key: "5_100", text: "$5 - $100" },
          { key: "101_500", text: "$101 - $500" },
          { key: "501_1000", text: "$501 - $1K" },
          { key: "1001_5000", text: "$1,001 - $5K" },
          { key: "5001_10000", text: "$5,001 - $10K" },
          { key: "10001_20000", text: "$10,001 - $20K" },
          { key: "20001_50000", text: "$20,001 - $50K" },
          { key: "50001_100000", text: "$50,001 - $100K" },
          { key: "more_100001", text: "$100,001+" },
          { key: "total" },
        ],
      },
      submiting: false,
      error: null,
    };
  },

  computed: {},

  watch: {
    "form.meta.num_of_donors_by_criteria": {
      handler(data) {
        const { total_last_year, total_last_2_3_years } = Object.entries(data).reduce(
          ({ total_last_year, total_last_2_3_years }, [key, value]) => {
            let _total_last_year = total_last_year;
            let _total_last_2_3_years = total_last_2_3_years;
            if (key !== "total") {
              _total_last_year += Number(value.last_year);
              _total_last_2_3_years += Number(value.last_2_3_years);
            }
            return {
              total_last_year: _total_last_year,
              total_last_2_3_years: _total_last_2_3_years,
            };
          },
          { total_last_year: 0, total_last_2_3_years: 0 }
        );
        data.total.last_year = total_last_year;
        data.total.last_2_3_years = total_last_2_3_years;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      fetchVettings: "tools/vetting/fetchVettings",
      fetchCreateVetting: "tools/vetting/fetchCreateVetting",
      fetchUpdateVetting: "tools/vetting/fetchUpdateVetting",
    }),

    open(type, item) {
      if (type === "edit") {
        this.type = type;
        this.id = item.id;
        this.form = item.attributes;
      }
      this.$bvModal.show("vetting-form-madal");
    },

    close() {
      this.$bvModal.hide("vetting-form-madal");
    },

    presubmit(status) {
      if (status === "draft") {
        this.submit(status);
        return;
      }

      const message = this.$t("dashboard.vetting_confirm_submit_msg");

      const options = {
        okText: this.$t("dashboard.vetting_confirm_submit_ok_btn"),
        cancelText: this.$t("dashboard.cancel_btn"),
      };

      this.$refs.observer.validate().then((isValid) => {
        if (!isValid) {
          const array = Object.entries(this.$refs.observer.ctx.errors);
          for (let i = 0; i < array.length; i++) {
            const [key, val] = array[i];
            if (val.length) {
              const el = this.$refs.observer.refs[key].$el;
              el.scrollIntoView({
                block: "center",
                inline: "center",
              });
              const formEl = el.querySelector("input, select, textarea");
              if (formEl) formEl.focus();
              return;
            }
          }
        }

        this.$dialog.confirm(message, options).then(() => {
          this.submit(status);
        });
      });
    },

    submit(status) {
      this.submiting = true;
      this.error = null;

      const data = {
        meta: JSON.stringify(this.form.meta),
        status: status,
      };

      if (this.type === "add") {
        this.fetchCreateVetting([data]).then(this.successHandler).catch(this.errorHandler);
      }

      if (this.type === "edit") {
        this.fetchUpdateVetting([this.id, data]).then(this.successHandler).catch(this.errorHandler);
      }
    },

    successHandler() {
      this.submiting = false;
      this.close();
      this.fetchVettings();

      this.$_notificationsMixin_makeToast(
        "Success!",
        this.$t("dashboard.vetting_success_save", "Saved successfully"),
        "success"
      );
    },

    errorHandler(err) {
      this.submiting = false;
      this.error = err.response;
    },

    clearData() {
      this.$refs.observer.reset();
      this.type = "add";
      this.error = null;
      this.form = {
        meta: JSON.parse(JSON.stringify(defaultMetaForm)),
        status: "draft",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.is-danger {
  color: #f26e66;
}
.has-error {
  border-color: #f26e66;
  &::placeholder {
    color: #f26e66;
  }
}
.form-checkbox {
  &.has-error {
    label {
      color: #f26e66;
      &::before {
        border-color: #f26e66;
      }
    }
  }
}
</style>
