<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    iframeUrl: {
      type: String,
      default: "",
    },
  },
});
</script>

<template>
  <div class="post-campaign-report" v-if="iframeUrl">
    <iframe
      class="w-100 h-100"
      width="800"
      height="1000"
      :src="iframeUrl"
      frameborder="0"
      style="border: 0"
      allowfullscreen=""
    ></iframe>
  </div>
</template>

<style lang="scss" scoped>
.post-campaign-report {
  display: flex;
  width: 100%;
  --_padding-top: 60px;
  --_padding-bottom: 60px;
  --_filter-box: 256px;
  --_filter-box-padding-bottom: 41px;
  height: calc(
    100vh - var(--_padding-top) - var(--_padding-bottom) - var(--_filter-box) -
      var(--_filter-box-padding-bottom)
  );
}
</style>
