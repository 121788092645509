<template lang="html">
  <div class="scroll">
    <div class="updates-block">
      <div class="add-block">
        <form>
          <input required type="text" />
          <textarea required name="name" rows="8" cols="80"> </textarea>
          <button type="submit" class="bttn bttn--lg bttn--blue" name="button">
            {{ $t("dashboard.updates_add") }}
          </button>
        </form>
      </div>
      <div>
        <dashboardUpdateItem />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dashboardUpdateItem from "./dashboardUpdateItem";
import urls from "@/constants/urls.js";
import axios from "axios";

export default {
  data() {
    return {};
  },
  components: {
    dashboardUpdateItem,
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.updates-block {
  width: 100%;
  padding-left: 30px;
  position: relative;
  &:before {
    content: "";
    width: 1px;
    height: 100%;
    background: #3cc7a0;
    position: absolute;
    left: 7.5px;
    top: 0px;
    bottom: 0px;
  }
}
.add-block {
  padding-bottom: 20px;
  form {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 21px;
      height: 1px;
      top: 50%;
      left: -21px;
      background: #3cc7a0;
    }
    &:after {
      position: absolute;
      content: "";
      width: 17px;
      height: 17px;
      background: #3cc7a0;
      top: 50%;
      margin-top: -8px;
      left: -30px;
      border-radius: 50%;
    }
  }
  input,
  textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #3cc7a0;
    outline: none;
    margin-bottom: 15px;
  }
  input {
    height: 40px;
    padding: 10px 25px;
  }
  textarea {
    height: 90px;
    padding: 25px;
    resize: none;
  }
}
.scroll {
  overflow: auto;
  max-height: 400px;
}
</style>
