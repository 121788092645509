var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-table',{ref:"ShareVideoTable",attrs:{"id":"ShareVideoTable","head-variant":"light","busy":_vm.loading,"items":_vm.list,"fields":_vm.fields},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(video)",fn:function({ item }){return [_c('div',{staticStyle:{"max-width":"250px"}},[_c('b-embed',{staticClass:"svw-unidy-embed",attrs:{"type":"video","controls":""}},[_c('source',{attrs:{"src":item.video,"type":"video/mp4"}})])],1)]}},{key:"cell(name)",fn:function({ item }){return [_c('p',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"cell(actions)",fn:function({ item }){return [_c('TableOptionsDropdown',{attrs:{"options":[
          { text: _vm.$t('dashboard.utm_actions_edit'), action: () => _vm.openAddModal(item) },
          {
            text: _vm.$t('dashboard.utm_actions_delete'),
            action: () => _vm.remove(item),
            variant: 'danger',
          },
        ]}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }