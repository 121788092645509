<template>
  <div>
    <slot
      :exportDonors="exportDonors"
      :finished="finished"
      :regenerating="regenerating"
      :progress="progress"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { oldProcRequestInterval } from "@/helpers/proc-request";

export default {
  data() {
    return {
      finished: false,
      regenerating: false,
      progress: 0,
    };
  },
  methods: {
    ...mapActions({
      exportDonorsInternal: "donorTeams/exportDonors",
    }),
    async exportDonors(teamId) {
      this.regenerating = true;
      const procId = await this.exportDonorsInternal({ teamId });
      oldProcRequestInterval(procId).then(({ finished, progress, attrs }) => {
        this.progress = progress;
        this.finished = finished;
        if (finished) {
          this.progress = 0;
          this.regenerating = false;
          this.$emit("proc-request-finished", attrs);
        }
      });
    },
  },
};
</script>
