<template>
  <div>
    <div class="category-title">
      <h1 class="h1-like">{{ $t("users.section_title") }}</h1>
      <div class="action-block">
        <button class="bttn bttn--sm bttn--orange">
          {{ $t("users.create_new_user_button") }}
        </button>
      </div>
    </div>
    <div class="user-search-bar">
      <div class="search">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
          ></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
        <input
          type="text"
          value=""
          placeholder="search"
          class="search__input"
          v-model="searchValue"
        />
      </div>
    </div>
    <div class="users-section"></div>

    <div class="users-section-empty">
      {{ $t("account.sub_account_no_subaccounts") }}
    </div>

    <div class="users-section-empty">
      {{ $t("account.sub_account_no_subaccounts_by_search") }}
    </div>

    <user-details-modal ref="userDataModal"></user-details-modal>
  </div>
</template>

<script>
import urls from "@/constants/urls.js";
import errorList from "@/components/error-message.vue";

import { mapState } from "vuex";
import axios from "axios";

import userCard from "./userCard";
import userDetailsModal from "./userDetailsModal";

export default {
  components: {
    userCard,
    userDetailsModal,
    errorMessage: errorList,
  },

  data() {
    return {
      subAccList: [],
      searchValue: "",
    };
  },

  created() {},

  computed: mapState({}),

  watch: {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
