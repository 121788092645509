import Vue from "vue";
import DropdownIcon from "bootstrap-icons/icons/arrow-down-short.svg";
import { nanoid } from "nanoid";
import CustomChromePicker from "@/components/custom-chrome-picker.vue";

function toggleAriaAttribute(element, attribute) {
  element.setAttribute(attribute, !(element.getAttribute(attribute) === "true"));
}

function initCustomChromePicker(el, component, props = {}) {
  return new Vue({
    el,
    propsData: props,
    ...component,
  });
}

class VanilaColorful {
  constructor(select, label) {
    this.select = select;
    this.container = document.createElement("span");
    this.buildPicker();
    this.select.style.display = "none";
    this.select.parentNode.insertBefore(this.container, this.select);

    this.label.addEventListener("mousedown", () => {
      this.togglePicker();
    });
    this.label.addEventListener("keydown", (event) => {
      switch (event.key) {
        case "Enter":
          this.togglePicker();
          break;
        case "Escape":
          this.escape();
          event.preventDefault();
          break;
        default:
      }
    });
    this.select.addEventListener("change", this.update.bind(this));

    this.label.innerHTML = label;
    this.container.classList.add("ql-color-picker");
    Array.from(this.container.querySelectorAll(".ql-picker-item"))
      .slice(0, 7)
      .forEach((item) => {
        item.classList.add("ql-primary");
      });
  }

  togglePicker() {
    this.container.classList.toggle("ql-expanded");
    // Toggle aria-expanded and aria-hidden to make the picker accessible
    toggleAriaAttribute(this.label, "aria-expanded");
    toggleAriaAttribute(this.options, "aria-hidden");
  }

  buildItem(option) {
    const item = document.createElement("span");
    item.tabIndex = "0";
    item.setAttribute("role", "button");
    item.classList.add("ql-picker-item");
    if (option.hasAttribute("value")) {
      item.setAttribute("data-value", option.getAttribute("value"));
    }
    if (option.textContent) {
      item.setAttribute("data-label", option.textContent);
    }
    item.addEventListener("click", () => {
      this.selectItem(item, true);
    });
    item.addEventListener("keydown", (event) => {
      switch (event.key) {
        case "Enter":
          this.selectItem(item, true);
          event.preventDefault();
          break;
        case "Escape":
          this.escape();
          event.preventDefault();
          break;
        default:
      }
    });

    item.style.backgroundColor = option.getAttribute("value") || "";

    return item;
  }

  buildLabel() {
    const label = document.createElement("span");
    label.classList.add("ql-picker-label");
    label.innerHTML = DropdownIcon;
    label.tabIndex = "0";
    label.setAttribute("role", "button");
    label.setAttribute("aria-expanded", "false");
    this.container.appendChild(label);
    return label;
  }

  buildOptions() {
    const options = document.createElement("span");
    const app = document.createElement("div");
    options.classList.add("ql-picker-options", "charidy-picker-options");

    // Don't want screen readers to read this until options are visible
    options.setAttribute("aria-hidden", "true");
    options.tabIndex = "-1";

    // Need a unique id for aria-controls
    options.id = `ql-picker-options-${nanoid()}`;
    app.id = `charidy-picker-options-app-${nanoid()}`;
    this.label.setAttribute("aria-controls", options.id);

    this.options = options;
    options.append(app);
    this.container.appendChild(options);

    const observer = new MutationObserver(() => {
      if (this.container.contains(options)) {
        const hexColorPickerInstance = initCustomChromePicker(app, CustomChromePicker, {
          color: "#000",
        });

        hexColorPickerInstance.$on("input", (newColor) => {
          this.selectItemColor(newColor, { trigger: true });
        });

        observer.disconnect();
      }
    });

    observer.observe(this.container, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: true,
    });
  }

  buildPicker() {
    Array.from(this.select.attributes).forEach((item) => {
      this.container.setAttribute(item.name, item.value);
    });
    this.container.classList.add("ql-picker");
    this.label = this.buildLabel();
    this.buildOptions();
  }

  escape() {
    // Close menu and return focus to trigger label
    this.close();
    // Need setTimeout for accessibility to ensure that the browser executes
    // focus on the next process thread and after any DOM content changes
    setTimeout(() => this.label.focus(), 1);
  }

  close() {
    this.container.classList.remove("ql-expanded");
    this.label.setAttribute("aria-expanded", "false");
    this.options.setAttribute("aria-hidden", "true");
  }

  selectItemColor(hex, params) {
    const trigger = params?.trigger || false;
    const close = params?.close || false;

    this.select.selectedIndex = 1;
    this.select.options[this.select.selectedIndex].setAttribute("value", hex);

    if (trigger) {
      this.select.dispatchEvent(new Event("change"));
      close && this.close();
    }

    const colorLabel = this.label.querySelector(".ql-color-label");
    if (colorLabel) {
      if (colorLabel.tagName === "line") {
        colorLabel.style.stroke = hex;
      } else {
        colorLabel.style.fill = hex;
      }
    }
  }

  selectItem(item, trigger = false) {
    const selected = this.container.querySelector(".ql-selected");
    if (item === selected) return;
    if (selected != null) {
      selected.classList.remove("ql-selected");
    }
    if (item == null) return;
    item.classList.add("ql-selected");
    this.select.selectedIndex = Array.from(item.parentNode.children).indexOf(item);
    if (item.hasAttribute("data-value")) {
      this.label.setAttribute("data-value", item.getAttribute("data-value"));
    } else {
      this.label.removeAttribute("data-value");
    }
    if (item.hasAttribute("data-label")) {
      this.label.setAttribute("data-label", item.getAttribute("data-label"));
    } else {
      this.label.removeAttribute("data-label");
    }
    if (trigger) {
      this.select.dispatchEvent(new Event("change"));
      this.close();
    }
    const colorLabel = this.label.querySelector(".ql-color-label");
    const value = item ? item.getAttribute("data-value") || "" : "";
    if (colorLabel) {
      if (colorLabel.tagName === "line") {
        colorLabel.style.stroke = value;
      } else {
        colorLabel.style.fill = value;
      }
    }
  }

  update() {
    let option;
    if (this.select.selectedIndex > -1) {
      const item =
        this.container.querySelector(".ql-picker-options").children[this.select.selectedIndex];
      option = this.select.options[this.select.selectedIndex];
      this.selectItem(item);
    } else {
      this.selectItem(null);
    }
    const isActive = option != null && option !== this.select.querySelector("option[selected]");
    this.label.classList.toggle("ql-active", isActive);
  }
}

export default VanilaColorful;
