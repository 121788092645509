<script>
import { cdnBaseInputProps } from "./cdn-base-input-mixin.js";
import CdnBaseInput from "./cdn-base-input.vue";

export default {
  inheritAttrs: false,
  props: {
    ...cdnBaseInputProps,
    value: { type: [Number, String], default: "" },
  },
  components: { CdnBaseInput },
};
</script>

<template>
  <CdnBaseInput
    :form="form"
    :hideTitle="hideTitle"
    :variant="variant"
    :type="type"
    :placeholder="placeholder"
    :name="name"
    :size="size"
    :error="error"
  >
    <template #default>
      <slot />
    </template>
    <template #input>
      <input
        v-bind="$attrs"
        :type="type"
        :placeholder="placeholder"
        :name="name"
        :value="value"
        @input="$emit('input', $event.target.value)"
      />
      <slot name="after" />
    </template>
    <template #error>
      <slot name="error" />
    </template>
  </CdnBaseInput>
</template>
