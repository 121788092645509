<template lang="html">
  <div class="articles-wrap">
    <item v-for="article in articles" :key="article.id" :article="article" />
  </div>
</template>

<script>
import item from "./article-item.vue";

export default {
  components: {
    item,
  },

  data() {
    return {
      articles: [
        {
          id: 0,
          title: "Can Change Really Come to the Third Sector?",
          date: "July 26, 2018",
          creator: "Moshe Hecht",
          lead: "The verdict is in: the people of the nonprofit world are ready for a radical shift. I’m not talking about some nicely-folded policy changes…",
          link: "http://blog.charidy.com/academy/can-change-really-come-to-the-third-sector/",
          image:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/org_dashboard/CharidyAcademyEmpty.png",
        },
        {
          id: 1,
          title: "Say ‘No’ to Donor Extortion",
          date: "July 25, 2018",
          creator: "Moshe Hecht",
          lead: "  Here’s a novel concept: Donor extortion isn’t fun. In fact, it’s pretty darn terrible. Duh. So, can someone please tell me why I…",
          link: "http://blog.charidy.com/academy/say-no-to-donor-extortion/",
          image:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/org_dashboard/articles/image1.png",
        },
        {
          id: 2,
          title: "Giving: The Science of Lasting Happiness",
          date: "July 23, 2018",
          creator: "Moshe Hecht",
          lead: "You know those deep, gaping, and painful existential feelings of inadequacy, loneliness, and meaninglessness you’ve been feeling all your life? That bottomless void that…",
          link: "http://blog.charidy.com/academy/giving-the-science-of-lasting-happiness/",
          image:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/org_dashboard/articles/image2.png",
        },
        {
          id: 3,
          title: "How This Crowdfunding Campaign Went From $1,500 to $15M",
          date: "July 20, 2018",
          creator: "Moshe Hecht",
          lead: "It began as a simple $1,500 Facebook fundraiser, sparked by a couple that wanted to make any difference in any way that they could…",
          link: "http://blog.charidy.com/academy/how-this-crowdfunding-campaign-went-from-1500-to-15m/",
          image:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/org_dashboard/articles/image3.png",
        },
        {
          id: 4,
          title: "The Giving Pledge, Compounded",
          date: "July 19, 2018",
          creator: "Moshe Hecht",
          lead: "Could the Giving Pledge be more giving? The gutsiest and most awe-inspiring trend in charitable giving is the commitment from Warren Buffett, Bill and…",
          link: "http://blog.charidy.com/academy/the-giving-pledge-compounded/",
          image:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/org_dashboard/articles/image4.png",
        },
        {
          id: 5,
          title: "The Business of Non-Profits: For-profit thinking in the social impact space",
          date: "July 13, 2018",
          creator: "Josh Bobrowsky",
          lead: "If you want to make real social impact you have to build a real social impact business that focuses on supporting your cause through…",
          link: "http://blog.charidy.com/academy/the-business-of-non-profits-for-profit-thinking-in-the-social-impact-space/",
          image:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/org_dashboard/CharidyAcademyEmpty.png",
        },
        {
          id: 6,
          title: "What the Heck is a “Culture of Philanthropy?”",
          date: "July 11, 2018",
          creator: "Moshe Hecht",
          lead: "  You know when you’re at a meeting, and the person leading said meeting keeps throwing around a term over and over again, and…",
          link: "http://blog.charidy.com/academy/what-the-heck-is-a-culture-of-philanthropy/",
          image:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/org_dashboard/articles/image5.png",
        },
        {
          id: 7,
          title: "6 Impact Entrepreneurs Changing the World",
          date: "June 18, 2018",
          creator: "Moshe Hecht",
          lead: "When we think about the characteristics of a true entrepreneur, we think about their exceptional talent, skills, vision and willingness to take extreme risk;…",
          link: "http://blog.charidy.com/academy/6-impact-entrepreneurs-changing-the-world/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2018/06/download-1-640x340.png",
        },
        {
          id: 8,
          title: "The Golden Age of Philanthropy",
          date: "June 1, 2018",
          creator: "Moshe Hecht",
          lead: "Ahh, the golden age … a time of prosperity and abundance. That perfectly in between era, when the waves are high and the taps…",
          link: "http://blog.charidy.com/academy/the-golden-age-of-philanthropy/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2018/06/terapia-sistemica-600x480.jpg",
        },
        {
          id: 9,
          title: "Don’t Be Afraid of Your Mega Donors",
          date: "May 25, 2018",
          creator: "Moshe Hecht",
          lead: "In my years working with nonprofit and development directors, I’ve noticed a certain underpinning fear of major donors. This is not a monster-under-your-bed fear…",
          link: "http://blog.charidy.com/academy/dont-be-afraid-of-your-mega-donors/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2018/05/5-ways-to-donate-to-charity-effectively_0-640x480.jpg",
        },
        {
          id: 10,
          title: "Nonprofit Terms We Need to Change [Part 3]",
          date: "May 25, 2018",
          creator: "Moshe Hecht",
          lead: "Change. We all want it. We all think it. We all talk it. And some even try to walk it – hoping that some…",
          link: "http://blog.charidy.com/academy/nonprofit-terms-we-need-to-change-part-3/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2016/08/Untitled-design.jpg",
        },
        {
          id: 11,
          title: "Nonprofit Terms we Need to Change [Part 2]",
          date: "May 25, 2018",
          creator: "Moshe Hecht",
          lead: "4. Nonprofit vs. For Purpose A novel concept with regards to the use of the term “Nonprofit,” as originated by Adam Braun of Pencils of Promise,…",
          link: "http://blog.charidy.com/academy/nonprofit-terms-we-need-to-change-part-2/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2016/08/Untitled-design.jpg",
        },
        {
          id: 12,
          title: "Nonprofit Terms we Need to Change [Part 1]",
          date: "May 25, 2018",
          creator: "Moshe Hecht",
          lead: "Confession. I’m no veteran of the nonprofit space. My background is in marketing, branding, technology, music … anything but nonprofit. Before you all run screaming…",
          link: "http://blog.charidy.com/academy/nonprofit-terms-we-need-to-change-part-1/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2016/08/boardmember-e1472229411645.jpg",
        },
        {
          id: 13,
          title: "The Time Traveler’s Guide to Charitable Giving",
          date: "July 5, 2017",
          creator: "Moshe Hecht",
          lead: "We live our lives as limited beings within the borders of time and space. And as much as we seek a certain transcendence of…",
          link: "http://blog.charidy.com/academy/time-travelers-guide-charitable-giving/",
          image: "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2017/07/download.png",
        },
        {
          id: 14,
          title: "Goodbye Millennials, Hello Generation Z",
          date: "July 5, 2017",
          creator: "Moshe Hecht",
          lead: "Dear nonprofit leaders—I’ve got good news and bad news. The good news is you’ve finally mastered the art of Millennial engagement. You’ve read the…",
          link: "http://blog.charidy.com/academy/goodbye-millennials-hello-generation-z/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2017/07/Screen-Shot-2017-07-04-at-10.56.10-PM.png",
        },
        {
          id: 15,
          title: "How “Trickle-up Charity” Can Save the World",
          date: "July 5, 2017",
          creator: "Moshe Hecht",
          lead: "Today’s mega-rich philanthropists – Warren Buffet, Bill and Melinda Gates, Mark Zuckerberg – are putting billions towards advancing our world and solving some of…",
          link: "http://blog.charidy.com/academy/trickle-charity-can-save-world/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2017/07/Trickle-up-Charity-Article-Cover-e1489250246275.png",
        },
        {
          id: 16,
          title: "The Magical Matching Tour",
          date: "July 5, 2017",
          creator: "Moshe Hecht",
          lead: "Matching campaigns are key to creating unity and financial sustainability for your nonprofit. At their essence, these campaigns nurture a community of interdependent givers,…",
          link: "http://blog.charidy.com/academy/magical-matching-tour/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2017/07/Matching-Tour-Article-Cover-1-e1488656126136.png",
        },
        {
          id: 17,
          title: "Engagement Marketing is the New Black",
          date: "May 19, 2017",
          creator: "roxannaayers",
          lead: "Life never goes as planned. You may set out on one specific path and quickly find yourself on another, drastically different path—maybe even going…",
          link: "http://blog.charidy.com/academy/engagement-marketing-new-black/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2017/05/OG-image-marketing-engagement-Facebook.jpg",
        },
        {
          id: 18,
          title:
            "[AUDIO] How One Nonprofit Raised 80% of Their Budget for 2017 in a Single Campaign",
          date: "February 13, 2017",
          creator: "Moshe Hecht",
          lead: "Our Chief Innovation Officer, Moshe Hecht, interviews Rabbi Eliyahu Rivkin of Chabad of Northridge to discuss the dream, the challenges, and the incredible results…",
          link: "http://blog.charidy.com/academy/one-nonprofit-raised-80-budget-2017-single-campaign/",
          image: "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2017/02/download.jpeg",
        },
        {
          id: 19,
          title: "The Perfect Storm for Online Fundraising",
          date: "February 13, 2017",
          creator: "Moshe Hecht",
          lead: "Originally posted on Guidestar. So you want to be successful in online fundraising? Ready to take 2017 by the reins and make an exponential…",
          link: "http://blog.charidy.com/academy/perfect-storm-online-fundraising/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2017/02/Screen-Shot-2017-02-12-at-10.35.20-PM.png",
        },
        {
          id: 20,
          title: "Millennials Shmillenials: Working Together for One Cause",
          date: "January 10, 2017",
          creator: "Moshe Hecht",
          lead: "There have always been Millennials. We’ve known them by other names of course. Explorers. Inventors. Witches. Heretics. Revolutionaries. As generations rise and ebb, the…",
          link: "http://blog.charidy.com/academy/millennials-shmillenials-working-together-one-cause/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2017/01/shutterstock_287569700.jpg",
        },
        {
          id: 21,
          title: "Here’s Why Your Major Donor Is About to Give You the Boot",
          date: "January 10, 2017",
          creator: "Moshe Hecht",
          lead: "Ever wonder why two organizations with the same mission and passion—both possessing teams of equally amazing and dedicated individuals, both sources of goodness in…",
          link: "http://blog.charidy.com/academy/heres-major-donor-give-boot/",
          image: "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2017/01/boot.jpg",
        },
        {
          id: 22,
          title: "The Modern Art of Fundraising: Interview with Alan Secter of YU",
          date: "November 25, 2016",
          creator: "Ariella Shapiro",
          lead: "Fundraising is an art. The great masters are not merely inspirational, they enable us to see the world, and ourselves, in whole new ways.…",
          link: "http://blog.charidy.com/academy/modern-art-fundraising-interview-alan-secter/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2016/11/modernfund.jpg",
        },
        {
          id: 23,
          title: "Non Profits are Thinking About Crowdfunding All Wrong",
          date: "September 29, 2016",
          creator: "Moshe Hecht",
          lead: "Originally featured on Crowdfund Insider. We don’t do crowdfunding. I can’t tell you how often I hear these words, spoken with such confidence, by…",
          link: "http://blog.charidy.com/academy/non-profits-thinking-crowdfunding-wrong/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2016/09/Screenshot-2016-09-29-17.19.20.png",
        },
        {
          id: 24,
          title: "Is snail mail really dead?",
          date: "September 13, 2016",
          creator: "Natalie Katz",
          lead: "We are constantly changing. To be a living, breathing individual on this earth means that we must embrace the change. What we did yesterday…",
          link: "http://blog.charidy.com/academy/snail-mail-really-dead/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2016/09/Real-Snail-Mail-Delivery-1.jpg",
        },
        {
          id: 25,
          title: "Donors are not “ATM” machines.",
          date: "September 8, 2016",
          creator: "Peretz Shapiro",
          lead: "What really irks me as a donor, is that the only time that I hear from the organisations I give to, is when they…",
          link: "http://blog.charidy.com/academy/donors-not-atm-machines/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2016/09/atm-malware-threat-grows-worldwide-showcase_image-2-a-8788-e1473358833128.jpg",
        },
        {
          id: 26,
          title: "When your Board seems bored.",
          date: "September 7, 2016",
          creator: "Natalie Katz",
          lead: "According to a study by Nonprofit Governance Index, the boards of nonprofit organizations hold the gold medal for inefficiency. While we all generally strive…",
          link: "http://blog.charidy.com/academy/board-seems-bored/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2016/08/Untitled-design.jpg",
        },
        {
          id: 27,
          title: "Ask Not. The Power of An Ask-Less Event",
          date: "August 29, 2016",
          creator: "Natalie Katz",
          lead: "Ask Not. The Power of An Ask-Less Event Over the last year or so, I've had the privilege of packing boxes at my local…",
          link: "http://blog.charidy.com/academy/ask-not-power-ask-less-event/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2016/08/party_ribbons.jpg",
        },
        {
          id: 28,
          title: "The 5 most important Social Influencers.",
          date: "August 15, 2016",
          creator: "Natalie Katz",
          lead: "Effective Communication is a sophisticated art that is ever evolving. Between hieroglyphics, courier pigeon, pony express, tin cans, and telephone calls, we’ve been doing it since…",
          link: "http://blog.charidy.com/academy/know-guy-knows-guy/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2016/08/AdobeStock_95547793-1200x675.png",
        },
        {
          id: 29,
          title: "It’s not you, It’s me",
          date: "August 2, 2016",
          creator: "Natalie Katz",
          lead: "  Are you tired? Drained? Feeling like an automated fundraising machine? Hopeless? Discouraged? Uninspired? (Cue the infomercial music)   Yes, I know, because I…",
          link: "http://blog.charidy.com/academy/its-not-you-its-me/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2016/08/imageedit_1_4162564937.jpg",
        },
        {
          id: 30,
          title: "Fundraisers: Listen up!",
          date: "July 8, 2016",
          creator: "Natalie Katz",
          lead: "You are in an office waiting room, ready to pitch your heart out.  You have planned a presentation about all the work you do…",
          link: "http://blog.charidy.com/academy/listen-up/",
          image: "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2016/07/0eca5f1.jpg",
        },
        {
          id: 31,
          title: "SOLICITATIONS: BRING A WINGMAN!",
          date: "June 30, 2016",
          creator: "Jonah Halper",
          lead: "SOLICITATIONS: BRING A WINGMAN! By Jonah Halper, Author of Date Your Donors and Founder and Partner at ALTRUICITY You have spent a lot of…",
          link: "http://blog.charidy.com/academy/solicitations-bring-wingman/",
          image: "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2016/06/wingman.png",
        },
        {
          id: 32,
          title: "MISSION IMPOSSIBlE : Creating and using your mission statement",
          date: "June 30, 2016",
          creator: "Natalie Katz",
          lead: "Who are you?   Seems like the most obvious question, with the most obvious answer.  As I am sure you can guess, I am…",
          link: "http://blog.charidy.com/academy/mission-impossible-creating-using-mission-statement/",
          image:
            "http://blog.charidy.com/academy/wp-content/uploads/sites/2/2016/06/1-e1471530252108.jpg",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.articles-wrap {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  ::v-deep .item:first-child {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    .item-image {
      width: 50%;
      height: 100%;
    }
    .item-body {
      width: 50%;
    }
    @media (max-width: 768px) {
      display: block;
      .item-image {
        width: 100%;
        height: 160px;
      }
      .item-body {
        width: 100%;
      }
    }
  }
}
</style>
