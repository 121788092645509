<script>
import { mapActions, mapState } from "vuex";
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],
  data() {
    return {
      loading: false,
      dateFrom: "",
      dateTo: "",
      donorEmail: "",
      email: "",
    };
  },
  computed: {
    ...mapState({
      cId: (state) => state.campaign.campaign.data.id,
    }),
  },
  methods: {
    ...mapActions({
      fetchReceiptBulk: "fetchReceiptBulk",
    }),
    async download() {
      this.loading = true;
      try {
        const dateFrom = !this.dateFrom ? "" : new Date(this.dateFrom).getTime() / 1000;
        const dateTo = !this.dateTo ? "" : new Date(this.dateTo).getTime() / 1000;

        await this.fetchReceiptBulk([
          this.cId,
          {
            date_from: dateFrom,
            date_to: dateTo,
            donor_email: this.donorEmail,
            email: this.email,
          },
        ]);

        this.$_notificationsMixin_makeToast(
          "Success",
          "The process has been initiated. You will receive an email upon completion.",
          "success"
        );
      } catch (e) {
        console.error(e);
        this.$_notificationsMixin_handleCatch(e);
        return;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="campaign-edit-title">
      <h2 class="h2-like">{{ $t("edit_campaign.download_receipt_title", "Download receipt") }}</h2>
    </div>

    <b-list-group class="mb-5">
      <b-list-group-item>
        <h5>
          {{
            $t(
              "edit_campaign.campaign_generate_archive_of_all_campaign_receipts",
              "This will generate archive of all campaign receipts. Receipts generation may take several minutes to complete. Once the receipts have been exported you will be notified via email with direct link to download the receipt archive."
            )
          }}
        </h5>
      </b-list-group-item>
      <b-list-group-item>
        <h5>
          {{
            $t(
              "edit_campaign.campaign_please_do_not_run_multiple_times",
              "Please do not run this process multiple times."
            )
          }}
        </h5>
      </b-list-group-item>
    </b-list-group>

    <section>
      <b-form class="campaign-download-receipt-form" inline @submit.prevent="download">
        <label class="mr-sm-2" for="download-receipt-input-email">
          {{ $t("edit_campaign.download_receipt_donor_email", "Email to send receipts to") }}
        </label>
        <b-form-input
          id="download-receipt-input-email"
          class="mb-2 mr-sm-2 mb-sm-0"
          type="email"
          required
          v-model="email"
        ></b-form-input>

        <label class="mr-sm-2" for="download-receipt-input-date-from">
          {{ $t("edit_campaign.download_receipt_date_from", "Date from") }}
        </label>
        <b-form-input
          id="download-receipt-input-date-from"
          class="mb-2 mr-sm-2 mb-sm-0"
          type="date"
          v-model="dateFrom"
        ></b-form-input>

        <label class="mr-sm-2" for="download-receipt-input-date-to">
          {{ $t("edit_campaign.download_receipt_date_to", "Date To") }}
        </label>
        <b-form-input
          id="download-receipt-input-date-to"
          class="mb-2 mr-sm-2 mb-sm-0"
          type="date"
          v-model="dateTo"
        ></b-form-input>

        <label class="mr-sm-2" for="download-receipt-input-donor-email">
          {{ $t("edit_campaign.download_receipt_donor_email", "Donor Email") }}
        </label>
        <b-form-input
          id="download-receipt-input-donor-email"
          class="mb-2 mr-sm-2 mb-sm-0"
          type="email"
          v-model="donorEmail"
        ></b-form-input>

        <button :disabled="loading" class="bttn bttn--sm bttn--orange" type="submit">
          {{ $t("edit_campaign.download_receipt_btn", "Download") }}
        </button>
      </b-form>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.campaign-download-receipt-form {
  gap: 20px;
}
</style>
