import urls from "../../constants/urls.js";
import axios from "axios";

const getUrl = (id = "") => {
  const orgId = localStorage.getItem("orgId");
  const posturl = String(id) && `/${id}`;

  return urls.campaignExpress.replace(":oid", orgId) + posturl;
};

const getData = (attr, cId = 0) => {
  const cIdO = cId || cId === 0 ? { campaign_id: Number(cId) } : {};
  return {
    ...cIdO,
    ...attr,
  };
};

const state = {
  loading: false,
  error: null,
};

const mutations = {
  setLoading(state, value) {
    state.loading = value;
  },

  setError(state, val) {
    state.error = val;
  },
};

const actions = {
  fetchCreateExpressCampaign(_, [attr, getParams] = []) {
    const url = getUrl();
    const params = {
      ...getParams,
    };
    const data = getData(attr);

    return axios.post(url, data, { params });
  },

  fetchUpdateExpressCampaign(_, [cId, attr, getParams] = []) {
    const url = getUrl();
    const params = {
      ...getParams,
    };
    const data = getData(attr, cId);

    return axios.put(url, data, { params });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
