<template>
  <div class="desktop-media">
    <SliderLaptopScreen1 v-if="screen === 't0'" />
    <SliderLaptopScreen2 v-if="screen === 't1'" />
    <SliderLaptopScreen3 v-if="screen === 't2'" />
    <SliderLaptopScreen4 v-if="screen === 't3'" />

    <div
      class="desktop-media__container"
      :class="[{ t1: screen === 't1' }, { t3: screen === 't3' }]"
    >
      <div v-if="imageset == 0" class="media-placeholder">
        <div
          class="media-placeholder__unit"
          :class="[
            { 'media-placeholder__unit--t0': screen === 't0' },
            { 'media-placeholder__unit--t1': screen === 't1' },
            { 'media-placeholder__unit--t0': screen === 't2' },
            { 'media-placeholder__unit--t3': screen === 't3' },
          ]"
          style="
            background-image: url('http://hdimages.org/wp-content/uploads/2017/03/placeholder-image10.jpg');
          "
        ></div>
      </div>

      <swiper ref="mySwiper" :options="swiperOptions" class="swiper-box">
        <swiper-slide
          class="swiper-item"
          v-for="(image, i) in imageset"
          :key="`slpb-${i}`"
          :class="`${tag}`"
          :style="`background-image:url('${image.attributes.src}')`"
        >
          <div class="swiper-item__control">
            <div class="swiper-item__control-buttons">
              <button
                type="button"
                :disabled="!hasAccess || load"
                class="bttn-round"
                @click="changeOrderUp(image)"
                :title="$t('edit_campaign.image_change_order_up')"
              >
                <b-icon-arrow-up-short />
              </button>
              <button
                type="button"
                :disabled="!hasAccess"
                class="bttn-round"
                @click="delFile(image)"
                :title="$t('edit_campaign.image_delete')"
              >
                <IconDeleteFile />
              </button>
              <input
                type="file"
                :disabled="!hasAccess"
                :id="`update_file_${image.id}`"
                @change="updFile($event, image)"
              />
              <label
                class="bttn-round"
                :class="{
                  disabled: !hasAccess,
                }"
                :for="`update_file_${image.id}`"
              >
                <IconUpdateFile />
              </label>
              <input
                v-if="!isOnlyOne"
                type="file"
                :disabled="!hasAccess"
                :id="`load_file_${image.id}`"
                @change="uplFile($event)"
              />
              <label
                v-if="!isOnlyOne"
                class="bttn-round"
                :class="{
                  disabled: !hasAccess,
                }"
                :for="`load_file_${image.id}`"
              >
                <IconUploadFile />
              </label>

              <button
                type="button"
                :disabled="!hasAccess || load || currentImageOrder === 0"
                class="bttn-round"
                @click="changeOrderDown(image)"
                :title="$t('edit_campaign.image_change_order_down')"
              >
                <b-icon-arrow-down-short />
              </button>
            </div>
            <p class="swiper-item__control-order">Order: {{ currentImageOrder }}</p>
          </div>
        </swiper-slide>

        <div v-show="imageset.length > 1" class="swiper-pagination" slot="pagination" />

        <div v-show="imageset.length > 1" class="bttn-round slide-controls prev" slot="button-prev">
          <IconSlidePrev />
        </div>

        <div v-show="imageset.length > 1" class="bttn-round slide-controls next" slot="button-next">
          <IconSlideNext />
        </div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IconSlidePrev from "@/views/campaigns/campaign-images/iconSlidePrev.vue";
import IconSlideNext from "@/views/campaigns/campaign-images/iconSlideNext.vue";
import IconDeleteFile from "@/views/campaigns/campaign-images/iconDeleteFile.vue";
import IconUpdateFile from "@/views/campaigns/campaign-images/iconUpdateFile.vue";
import IconUploadFile from "@/views/campaigns/campaign-images/iconUploadFile.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import SliderLaptopScreen1 from "./sliderLaptopScreen1.vue";
import SliderLaptopScreen2 from "./sliderLaptopScreen2.vue";
import SliderLaptopScreen3 from "./sliderLaptopScreen3.vue";
import SliderLaptopScreen4 from "./sliderLaptopScreen4.vue";

export default {
  // screen - 't0' -> countdown laptop
  // screen - 't1' -> standard laptop
  // screen - 't2' -> select laptop
  // screen - 't3' -> smm laptop
  props: ["imageset", "screen", "tag", "onlyOne", "uploadImmediately", "load"],

  data() {
    return {
      currentSlide: 0,
    };
  },

  components: {
    swiper,
    swiperSlide,
    IconSlidePrev,
    IconSlideNext,
    IconDeleteFile,
    IconUpdateFile,
    IconUploadFile,
    SliderLaptopScreen1,
    SliderLaptopScreen2,
    SliderLaptopScreen3,
    SliderLaptopScreen4,
  },

  computed: {
    ...mapState({
      hasAccess: (state) => {
        return state.account.access.CanEditCampaignMedia;
      },

      isOnlyOne() {
        return typeof this.onlyOne !== "undefined";
      },
    }),
    swiperOptions() {
      return {
        slidePerView: 1,
        effect: "fade",
        allowTouchMove: false,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".next",
          prevEl: ".prev",
          disabledClass: "disabled",
        },
      };
    },
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    currentImageOrder() {
      return this.imageset[this.currentSlide]?.attributes.order || 0;
    },
  },

  watch: {
    imageset: function (n, o) {
      if (n.length !== o.length) {
        setTimeout(() => {
          this.slideTo(n.length);
        }, 500);
      }
    },
  },

  mounted() {
    this.slideTo(0);
    this.currentSlide = this.swiper.activeIndex;
    this.swiper.on("slideChange", () => {
      this.currentSlide = this.swiper.activeIndex;
    });
  },

  methods: {
    updFile(e, image) {
      if (e.target.files[0]) {
        this.$emit("cUpdFile", e.target.files[0], image);
        e.target.type = "text";
        e.target.type = "file";
      }
    },

    uplFile(e) {
      this.$emit("cUplFile", e, this.tag, this.uploadImmediately);
    },

    delFile: function (image) {
      let message = this.$t("edit_campaign.confirm_delete_image");

      let options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.$emit("cDelFile", image);
      });
    },

    changeOrderUp(image) {
      this.$emit("changeOrderUp", image);
    },

    changeOrderDown(image) {
      this.$emit("changeOrderDown", image);
    },

    slideTo(n) {
      this.swiper.slideTo(n, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.desktop-media {
  width: 280px;
  height: 210px;
  display: flex;
  flex-shrink: 0;
  position: relative;
  @media only screen and (min-width: 470px) {
    width: 400px;
    height: 300px;
  }
  @media only screen and (min-width: 1360px) {
    width: 100%;
    height: 450px;
  }
  &::v-deep > svg {
    display: block;
    width: 360px;
    height: auto;
    transform: translateX(-50%) translateY(-50%) translateZ(0px);
    position: absolute;
    top: 50%;
    left: 50%;
    @media only screen and (min-width: 470px) {
      width: initial;
    }
    @media only screen and (min-width: 1360px) {
      width: 650px;
    }
    @media only screen and (min-width: 1600px) {
      width: 800px;
    }
  }
  &__container {
    width: 100%;
    max-width: 226px;
    height: 173px;
    display: flex;
    background-color: transparent;
    transform: translateX(-50%) translateZ(0px);
    position: absolute;
    top: 36px;
    left: 50%;
    @media only screen and (min-width: 470px) {
      max-width: 302px;
      height: 270px;
      top: 56px;
    }
    @media only screen and (min-width: 1360px) {
      max-width: 410px;
      height: 330px;
      top: 98px;
    }
    @media only screen and (min-width: 1600px) {
      max-width: 505px;
      height: 390px;
      top: 72px;
    }
    &.t1 {
      max-width: 202px;
      @media only screen and (min-width: 470px) {
        max-width: 268px;
      }
      @media only screen and (min-width: 1360px) {
        max-width: 370px;
        top: 103px;
      }
      @media only screen and (min-width: 1600px) {
        max-width: 450px;
        top: 74px;
      }
      ::v-deep .swiper-item {
        width: 140px !important;
        height: 72px;
        @media only screen and (min-width: 470px) {
          width: 180px !important;
          height: 95px;
        }
        @media only screen and (min-width: 1360px) {
          width: 250px !important;
          height: 126px;
        }
        @media only screen and (min-width: 1600px) {
          width: 300px !important;
          height: 156px;
        }
        &__control {
          left: 47%;
          @media only screen and (min-width: 470px) {
            left: 55%;
          }
          @media only screen and (min-width: 1360px) {
            left: 60%;
          }
          @media only screen and (min-width: 1600px) {
            left: 64%;
          }
        }
      }
      .prev {
        left: -12px;
        @media only screen and (min-width: 470px) {
          left: -17px;
        }
      }
      .next {
        right: -12px;
        @media only screen and (min-width: 470px) {
          right: -17px;
        }
      }
    }
    &.t3 {
      max-width: 202px;
      @media only screen and (min-width: 470px) {
        max-width: 268px;
      }
      @media only screen and (min-width: 1360px) {
        max-width: 365px;
      }
      @media only screen and (min-width: 1600px) {
        max-width: 450px;
      }
      ::v-deep .swiper-item {
        &.dinner_top_right {
          background-size: contain;
          background-position: right top;
          @media only screen and (min-width: 470px) {
            height: 115px;
          }
          @media only screen and (min-width: 1360px) {
            height: 157px;
          }
          @media only screen and (min-width: 1600px) {
            height: 200px;
          }
        }
        width: 202px !important;
        height: 104px;
        @media only screen and (min-width: 470px) {
          width: 268px !important;
          height: 140px;
        }
        @media only screen and (min-width: 1360px) {
          width: 365px !important;
          height: 192px;
        }
        @media only screen and (min-width: 1600px) {
          width: 450px !important;
          height: 235px;
        }
      }
      .prev {
        left: -12px;
        @media only screen and (min-width: 470px) {
          left: -17px;
        }
      }
      .next {
        right: -12px;
        @media only screen and (min-width: 470px) {
          right: -17px;
        }
      }
    }
  }

  ::v-deep .swiper-box {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: inline-flex;
    overflow: hidden;
  }
  ::v-deep .swiper-wrapper {
    display: flex;
  }
  ::v-deep .swiper-item {
    height: 55px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    @media only screen and (min-width: 470px) {
      height: 70px;
    }
    @media only screen and (min-width: 1360px) {
      height: 95px;
    }
    @media only screen and (min-width: 1600px) {
      height: 118px;
    }
    &.swiper-slide-active {
      z-index: 99;
      .swiper-item__control {
        display: flex;
        flex-direction: column;
        z-index: 100;
        gap: 10px;
      }
      .swiper-item__control-buttons {
        display: inline-flex;
      }
    }
    &__control {
      position: absolute;
      top: 142px;
      display: none;
      @media only screen and (min-width: 470px) {
        top: 200px;
      }
      @media only screen and (min-width: 1360px) {
        top: 265px;
      }
      @media only screen and (min-width: 1600px) {
        top: 325px;
      }
      .bttn-round {
        box-shadow: none;
        flex-shrink: 0;
        margin-bottom: 0;
      }
      .bttn-round:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

::v-deep .swiper-pagination {
  transform: translate3d(-50%, 0, 0);
  position: absolute;
  top: 115px;
  left: 50%;
  z-index: 120;
  @media only screen and (min-width: 470px) {
    top: 160px;
  }
  @media only screen and (min-width: 1360px) {
    top: 225px;
  }
  @media only screen and (min-width: 1600px) {
    top: 270px;
  }
}
.slide-controls {
  background-color: transparent;
  border: 1px solid #107598;
  position: absolute;
  top: 142px;
  z-index: 1001;
  @media only screen and (min-width: 470px) {
    top: 200px;
  }
  @media only screen and (min-width: 1360px) {
    top: 265px;
  }
  @media only screen and (min-width: 1600px) {
    top: 325px;
  }
  > svg {
    fill: #107598;
  }
  &:hover {
    border-color: lighten(#107598, 5%);
    background-color: transparent;
    svg {
      fill: lighten(#107598, 5%);
    }
  }
  &.prev {
    left: 0;
  }
  &.next {
    right: 0;
  }
  &.disabled {
    border-color: #bbbcc4;
    svg {
      fill: #bbbcc4;
    }
  }
}

.media-placeholder {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &__unit {
    width: 100%;
    background-position: center center;
    background-size: cover;
    opacity: 0.5;
    &--t0 {
      height: 55px;
      @media only screen and(min-width: 470px) {
        height: 70px;
      }
      @media only screen and(min-width: 1360px) {
        height: 95px;
      }
      @media only screen and(min-width: 1600px) {
        height: 118px;
      }
    }
    &--t1 {
      width: 140px;
      height: 72px;
      @media only screen and(min-width: 470px) {
        width: 185px;
        height: 95px;
      }
      @media only screen and(min-width: 1360px) {
        width: 250px;
        height: 125px;
      }
      @media only screen and(min-width: 1600px) {
        width: 300px;
        height: 155px;
      }
    }
    &--t3 {
      height: 104px;
      @media only screen and(min-width: 470px) {
        height: 140px;
      }
      @media only screen and(min-width: 1360px) {
        width: 365px;
        height: 192px;
      }
      @media only screen and(min-width: 1600px) {
        width: 450px;
        height: 235px;
      }
    }
  }
}

.bttn-round {
  svg {
    overflow: visible;
  }
}

input[type="file"] {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
