<template>
  <b-card class="account-card" v-if="accountFrom">
    <h2 slot="header" class="account-card__title">
      {{ $t("account.your_contact_details") }}
    </h2>

    <div slot="footer" class="account-card__footer text-right">
      <button
        class="bttn bttn--sm bttn--orange"
        form="contact-details"
        :disabled="load || !hasAccess"
        type="submit"
      >
        {{ $t("account.save_change") }}
      </button>
    </div>

    <form id="contact-details" class="card-text" @submit.prevent="updateDetails">
      <!-- hide option https://app.asana.com/0/863708115264164/1202187387833026/f -->
      <!-- <div class="col-12 form-unit switch-public">
        <label class="form-unit__label--upper-sm">
          {{ $t("account.switch_public_account_contacts") }}
        </label>
        <div class="switch-public--switch">
          <input
            type="checkbox"
            :disabled="!hasAccess"
            v-model="isPublicContacts"
            id="switch_public_account_contacts"
          />
          <label for="switch_public_account_contacts"></label>
        </div>
      </div> -->

      <error-message ref="error"></error-message>

      <b-form-group
        :label="$t('account.full_name')"
        label-for="full-name"
        label-class="form-unit__label--upper-sm"
        class="account-card__inputs"
        :class="{ 'has-error': vErrors.has('fullName') }"
      >
        <b-form-input
          id="full-name"
          :disabled="!hasAccess"
          name="fullName"
          v-validate="'required|alpha_h|min:1|max:50'"
          v-model="accountFrom.data.attributes.full_name"
        ></b-form-input>
        <span v-show="vErrors.has('fullName')" class="v-help is-danger">{{
          $t("template.field_is_required")
        }}</span>
      </b-form-group>

      <b-form-group
        :label="$t('account.email')"
        label-for="account-email"
        label-class="form-unit__label--upper-sm"
        class="account-card__inputs"
        :class="{ 'has-error': vErrors.has('contactEmail') }"
      >
        <b-form-input
          id="account-email"
          :disabled="!hasAccess"
          name="contactEmail"
          v-validate="'required|email'"
          v-model="accountFrom.data.attributes.email"
        ></b-form-input>
        <span v-show="vErrors.has('contactEmail')" class="v-help is-danger">{{
          $t("template.field_is_required")
        }}</span>
      </b-form-group>

      <b-form-group
        :label="$t('account.phone')"
        label-for="account-phone"
        label-class="form-unit__label--upper-sm"
        class="account-card__inputs"
      >
        <b-form-input
          id="account-phone"
          :disabled="!hasAccess"
          v-model="accountFrom.data.attributes.phone"
        ></b-form-input>
      </b-form-group>
    </form>
  </b-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import errorList from "@/components/error-message.vue";
import { notificationsMixin } from "@/mixins";

export default {
  data() {
    return {
      accountFrom: null,
      load: false,
      errors: [],
      isPublicContacts: null,
    };
  },

  mixins: [notificationsMixin],

  watch: {
    isPublicContacts(newVal, oldVal) {
      if (oldVal !== null) {
        this.changePublicContacts();
      }
    },
    accountData() {
      this.setPublicContacts();
    },
  },

  components: {
    errorMessage: errorList,
  },

  computed: mapState({
    loading: (state) => {
      return state.account.loadingAccount;
    },

    accountData: (state) => {
      return state.account.account;
    },

    orgId: function () {
      return localStorage.getItem("orgId");
    },

    hasAccess: (state) => {
      return state.account.access.CanEditOrganizationDetail;
    },
  }),

  created: function () {
    this.createAccountForm();
    this.setPublicContacts();
  },

  methods: {
    setPublicContacts() {
      const { included = [] } = this.accountData;
      const value = included.filter((el) => el.attributes.type === "org_contact_is_public");
      if (value.length > 0) {
        this.isPublicContacts = value[0].attributes.val_bool;
      }
    },

    changePublicContacts() {
      const data = {
        data: {
          type: "customer_setting",
          attributes: {
            val_bool: this.isPublicContacts,
            type: "org_contact_is_public",
          },
        },
      };
      axios.post(urls.organizationAccountSettings.replace(":orgId", this.orgId), data).then(() => {
        this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));

        this.$store.dispatch("updateAccountOrg");
      });
    },

    updateDetails() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.load = true;
          this.errors = [];

          axios
            .patch(urls.accountContacts.replace(":orgId", this.orgId), this.accountFrom)
            .then(() => {
              this.load = false;
              this.$store.dispatch("updateAccountOrg");

              this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
            })
            .catch((e) => {
              this.load = false;
              this.$refs.error.setErrorResponse(e.response);
            });
        }
      });
    },

    createAccountForm: function () {
      this.accountFrom = this.accountData;
    },
  },
};
</script>

<style lang="scss" scoped>
.switch-public {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  &--switch {
    input {
      display: block;
      opacity: 0;
    }
    label {
      width: 60px;
      height: 30px;
      cursor: pointer;
      display: inline-block;
      position: relative;
      background: rgb(189, 189, 189);
      border-radius: 30px;
      transition: background-color 0.4s;
      -moz-transition: background-color 0.4s;
      -webkit-transition: background-color 0.4s;
    }
    label:after {
      left: 0;
      width: 20px;
      height: 20px;
      margin: 5px;
      content: "";
      position: absolute;
      background: #fff;
      border-radius: 10px;
    }
    input:checked + label {
      background: #3cc7a0;
    }
    input:checked + label:after {
      left: auto;
      right: 0;
    }
  }
}
::v-deep.form-group {
  position: relative;
}
::v-deep .v-help {
  position: absolute;
  top: 5px;
  right: 0;
}

.is-danger {
  color: #f26e66;
}
.has-error {
  .form-control {
    border-color: #f26e66;
  }
}
</style>
