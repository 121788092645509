<template>
  <div class="plate-campaign">
    <div class="plate-campaign__top">
      <!-- <div class="actions-btn">
        <b-dropdown right variant="link" size="lg" no-caret>
          <template slot="button-content"> <i class="fa fa-ellipsis-v"></i>
          </template>
          <b-dropdown-item @click.prevent="editCampaign">
            {{ $t('campaigns.edit') }}
          </b-dropdown-item>
          <b-dropdown-item :class='!canCreateCampaign ? "disabled" : ""' @click="canCreateCampaign ? cloneCampaign() : false">  {{ $t('campaigns.clone') }} </b-dropdown-item>
          <b-dropdown-item :class='!canEditCampaignDetails ? "disabled" : ""' @click="canEditCampaignDetails ? deleteCampaign() : false">  {{ $t('campaigns.delete') }} </b-dropdown-item>
        </b-dropdown>
      </div> -->

      <router-link class="edit" :to="`/packages-page/${campaigndata.id}`">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            fill="#ffffff"
          />
          <path d="M0 0h24v24H0z" fill="none" /></svg
      ></router-link>
    </div>

    <div class="plate-campaign__body">
      <h2 class="plate-campaign__title">
        {{ campaigndata.attributes.title }}
        <a :href="'https://charidy.com'" class="open-page" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
              fill="#cc6d4c"
            />
          </svg>
        </a>
      </h2>

      <div class="campaign-status">
        <div class="campaign-status__title">Short description with some text about the pacakge</div>
        <div class="campaign-status__price">Price: $2000</div>
        <div class="campaign-status__data">
          <div class="campaign-status__datetime">Available: Date until Date</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["campaigndata"],

  data: function () {
    return {
      errors: [],
    };
  },

  computed: mapState({}),

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/_vars.scss";

.actions-btn {
  display: flex;
  justify-content: flex-end;
  i {
    color: #fff;
  }
}
.dropdown-item:focus {
  outline: none;
}

.plate-campaign {
  width: 100%;
  margin: 0 15px 20px 15px;
  overflow: hidden;
  color: $fd;
  border-radius: 0 0 4px 4px;
  box-shadow: $shadow-main;
  background-color: #ffffff;
  @media only screen and (min-width: 624px) {
    width: calc(50% - 30px);
  }
  @media only screen and (min-width: 1024px) {
    width: calc(33% - 30px);
  }
  @media only screen and (min-width: 1360px) {
    width: calc(33.333% - 30px);
  }
  @media only screen and (min-width: 1500px) {
    width: calc(33% - 30px);
  }

  &__top {
    display: block;
    width: 100%;
    height: 160px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-color: $fl;
    //background-image: url("https://www.charidy.com/new/img/campaign_no_image.jpg");
    .actions-btn {
      .dropdown-item {
        text-transform: initial !important;
      }
    }
    .edit {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $bb-100;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      transition: background-color 0.2s ease-in-out;
      position: absolute;
      right: 30px;
      bottom: -28px;
      &:hover,
      &:focus {
        background-color: $bb-90;
      }
    }
  }

  &__body {
    padding: 40px 30px 40px;
  }
  &__header {
    display: flex;
    width: 100%;
    align-items: center;
  }
  &__title {
    min-height: 72px;
    display: inline-block;
    margin: 0 0 30px;
    padding: 0;
    color: $fd;
    font-size: 24px;
    line-height: 1;
    .open-page {
      position: relative;
      bottom: 1px;
    }
  }
}
.campaign-progress {
  margin-bottom: 30px;
  &__collected {
    width: 100%;
    margin: 0 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $fd;
    .collected-amount {
      font-family: $vito-bold;
      font-size: 24px;
      text-transform: uppercase;
    }
    .collected-percent {
      font-size: 14px;
    }
  }
  &__line {
    width: 100%;
    margin: 0 0 10px;
    height: 8px;
    border-radius: 4px;
    background-color: $bg-100;
    position: relative;
    overflow: hidden;
    > div {
      background-color: $bb-100;
      border-radius: 4px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
  &__goal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $vito-bold;
    font-size: 12px;
    letter-spacing: 1px;
    color: $fl;
    span {
      font-family: $vito-light;
      font-size: 16px;
      color: $fd;
      text-transform: uppercase;
    }
  }
}
.campaign-status {
  &__title {
    width: 100%;
    font-family: $vito-bold;
    font-size: 12px;
    letter-spacing: 1px;
    color: $fl;
  }
  &__data {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: $fd;
    line-height: 1.2;
    @media only screen and (min-width: 468px) {
      font-size: 16px;
    }
  }
  &__type {
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-size: 12px;
    text-transform: capitalize;
    @media only screen and (min-width: 468px) {
      font-size: 14px;
    }
  }
  &__type--active {
    color: #ffffff;
    background-color: $lr-100;
  }
  &__type--success {
    color: #ffffff;
    background-color: #ffbe55;
  }
  &__description {
    font-size: 14px;
    color: $fl;
  }
}

.plate-campaign__title {
  margin: 0 0 15px;
}

.campaign-status__title {
  font-size: 10px;
  line-height: 12px;
  color: #040404;
  font-family: $vito-light;
  margin-bottom: 16px;
}

.campaign-status__price {
  font-size: 10px;
  line-height: 12px;
  color: #040404;
  font-family: $vito-light;
  margin-bottom: 24px;
}

.campaign-status__datetime {
  font-size: 13px;
  line-height: 16px;
  color: #040404;
}

.plate-campaign__title {
  min-height: 40px;
  font-size: 15px;
}
</style>
