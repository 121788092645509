<template>
  <div class="matcher-plate">
    <div class="matcher-plate__header">
      <div class="matcher-plate__image">
        <img
          v-if="matcher.attributes.image"
          :src="matcher.attributes.image"
          :alt="matcher.attributes.name"
        />
      </div>

      <div class="matcher-plate__data">
        <h2 class="matcher-plate__title">
          {{ matcher.attributes.name }}
        </h2>

        <div class="matcher-plate__row--data">
          <div class="matcher-plate__type">
            {{ $t("edit_campaign.matcher_type") }}
            <span>{{ $t("edit_campaign.matcher_type_" + matcher.attributes.goal) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="matcher-plate__body">
      <div class="matcher-plate__description">
        {{ matcher.attributes.description }}
      </div>

      <div class="matcher-plate__action">
        <button :disabled="!hasAccess" class="bttn bttn-round" @click="editMatcher()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          <!--{{ $t('edit_campaign.edit') }}-->
        </button>

        <button :disabled="!hasAccess" class="bttn bttn-round" @click="deleteMatcher()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          <!--{{ $t('edit_campaign.delete') }}-->
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["matcher"],

  computed: mapState({
    hasAccess: (state) => {
      return state.account.access.CanEditCampaignMatchers;
    },
  }),

  methods: {
    editMatcher: function () {
      this.$emit("edit-matcher", this.matcher);
    },

    deleteMatcher: function () {
      this.$emit("delete-matcher", this.matcher);
    },
  },

  filters: {
    shorten(value, stop) {
      return value.length > stop ? value.slice(0, stop) + " ..." : value;
    },
  },
};
</script>
