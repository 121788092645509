<script>
import { nanoid } from "nanoid";
import { mapActions, mapState } from "vuex";
import ModalWithSteps from "./modalWithSteps.vue";
import { notificationsMixin } from "@/mixins";
import StepStatus from "./stepStatus.vue";
import StepMainForm from "./stepMainForm.vue";
import StepDocumentFields from "./stepDocumentFields.vue";
import StepDocumentFieldsPreview from "./stepDocumentFieldsPreview.vue";

export default {
  mixins: [notificationsMixin],
  components: {
    ModalWithSteps,
  },
  data() {
    return {
      id: `foundationRequestModal-${nanoid()}`,
      wizardOptions: {
        title: this.$t("account.request_foundation_modal_title", "Request Charidy Foundation"),
        description: this.$t(
          "account.request_foundation_modal_subtitle",
          "Use Charidy Foundation to accept your donations"
        ),
        cancelActionText: this.$t("account.request_foundation_modal_action_cancel", "Cancel"),
        backActionText: this.$t("account.request_foundation_modal_action_back", "Back"),
        doneActionText: this.$t("account.request_foundation_modal_action_close", "Close"),
        continueActionText: this.$t("account.request_foundation_modal_action_next", "Next"),
      },
    };
  },
  computed: {
    ...mapState({
      hasAccess: (state) => {
        return state.account.access.CanEditOrganizationDetail;
      },
      orgName: (state) => {
        return state.account.account?.data?.attributes?.name || "";
      },
      orgFullName: (state) => {
        return state.account.account?.data?.attributes?.full_name || "";
      },
      orgEmail: (state) => {
        return state.account.account?.data?.attributes?.email || "";
      },
    }),
    MODAL_STEPS() {
      return [
        {
          name: "step-main-form",
          component: StepMainForm,
          action: {
            required: true,
            submit: "FormMainData",
            handler: async () => {
              return true;
            },
          },
        },
        {
          name: "step-document-fields",
          component: StepDocumentFields,
          action: {
            required: true,
            submit: "document-fields-accordion-form",
            continueActionText: this.$t(
              "account.request_foundation_modal_step_document_fields_action_save",
              "Save"
            ),
            handler: async ({ state, setState }) => {
              const setAlert = (variant, message) => {
                setState({
                  ...state,
                  alertVariant: variant,
                  alertMessage: message,
                });
              };

              setState({
                ...state,
                embedDocumentId: "",
              });
              setAlert(
                "info",
                this.$t(
                  "account.request_foundation_modal_step_document_fields_alert_creating",
                  "Creating document..."
                )
              );

              let documentId;
              try {
                documentId = await this.pandadocCreateDocument({
                  template_id: state.templateId,
                  email: state.signingDocumentFields.email,
                  fname: state.signingDocumentFields.fname,
                  lname: state.signingDocumentFields.lname,
                  "Client.Company": state.signingDocumentFields.client.company,
                  "Client.NonProfitType": state.signingDocumentFields.client.non_profit_type,
                  "Client.Providing": state.signingDocumentFields.client.providing,
                  "Client.CampaignShortlink": state.signingDocumentFields.client.campaign_shortlink,
                  "Client.Position": state.signingDocumentFields.client.position,
                });
                await this.pandadocPollDocumentStatus({ documentId, status: "document.draft" });
              } catch (e) {
                setAlert(
                  "danger",
                  this.$t(
                    "account.request_foundation_modal_step_document_fields_alert_creating",
                    `Creating document... ${e}`
                  )
                );
                throw e;
              }

              setAlert(
                "info",
                this.$t(
                  "account.request_foundation_modal_step_document_fields_alert_sending",
                  "Sending document..."
                )
              );

              try {
                await this.pandadocSendDocument({
                  documentId,
                  subject: "Charidy Foundation - Request for approval",
                  message: "Please approve the Charidy Foundation request",
                });
                await this.pandadocPollDocumentStatus({ documentId, status: "document.sent" });
              } catch (e) {
                setAlert(
                  "danger",
                  this.$t(
                    "account.request_foundation_modal_step_document_fields_alert_sending",
                    `Sending document... ${e}`
                  )
                );
                throw e;
              }

              let embedDocumentId;
              try {
                embedDocumentId = await this.pandadocCreateSigningSession({
                  documentId,
                  recipient: state.signingDocumentFields.email,
                });
              } catch (e) {
                setAlert(
                  "danger",
                  this.$t(
                    "account.request_foundation_modal_step_document_fields_alert_preview",
                    `Document preview. Please sign... ${e}`
                  )
                );
                throw e;
              }

              setState({
                ...state,
                embedDocumentId,
                alertVariant: "light",
                alertMessage: this.$t(
                  "account.request_foundation_modal_step_document_fields_alert_preview",
                  "Document preview. Please sign."
                ),
              });

              return true;
            },
          },
        },
        {
          name: "step-document-fields-preview",
          component: StepDocumentFieldsPreview,
          action: {
            required: true,
            handler: async ({ state, setState }) => {
              const status = await this.getStatus();
              if (status) {
                setState("statusRequestData", status);
                return true;
              }

              const data = await this.create({
                data: {
                  ...{ ...state.formData, campaign_id: Number(state.formData.campaign_id) },
                  document_ref: state.documentRef,
                },
                params: {
                  newRequest: true,
                },
              });
              setState("statusRequestData", data);
              return true;
            },
          },
        },
        {
          name: "step-status",
          component: StepStatus,
          action: {
            required: true,
            handler: async () => {
              return true;
            },
          },
        },
      ];
    },
    INITIAL_STATE() {
      return {
        statusRequestData: null,
        formData: {
          organization_name: "",
          ngo_id: "",
          bank_account_name: "",
          bank_account_address: "",
          bank_account_zip_code: "",
          bank_account_number: "",
          bank_account_swift: "",
          appeal_reason: "",
          campaign_id: "",
        },
        signingDocumentFields: {
          email: "",
          fname: "",
          lname: "",
          client: {
            company: "",
            non_profit_type: "",
            providing: "",
            campaign_shortlink: "",
            position: "",
          },
        },
        documentRef: "",
        embedDocumentId: "",
        templateId: "3dKTMvLFVtwfRdKTLm4TYZ",
        alertVariant: "warning",
        alertMessage: "Please fill remaining fields",
      };
    },
  },
  methods: {
    ...mapActions({
      getStatus: "foundationRequest/getStatus",
      create: "foundationRequest/create",
      pandadocCreateDocument: "foundationRequest/pandadocCreateDocument",
      pandadocPollDocumentStatus: "foundationRequest/pandadocPollDocumentStatus",
      pandadocSendDocument: "foundationRequest/pandadocSendDocument",
      pandadocCreateSigningSession: "foundationRequest/pandadocCreateSigningSession",
    }),
    async onModalOpen(state, setState, loadingAction, errorHandler, setStep, blockStepper) {
      loadingAction(true);
      try {
        const data = await this.getStatus();

        const formData = this.INITIAL_STATE.formData;
        const signingDocumentFields = this.INITIAL_STATE.signingDocumentFields;
        const signingDocumentFieldsClient = signingDocumentFields.client;
        setState({
          statusRequestData: data,
          formData: {
            ...formData,
            organization_name: this.orgName,
          },
          signingDocumentFields: {
            ...signingDocumentFields,
            email: this.orgEmail,
            fname: this.orgFullName,
            client: {
              ...signingDocumentFieldsClient,
              company: this.orgName,
            },
          },
        });
        if (data) {
          setStep(this.MODAL_STEPS.length);
          return;
        }
      } catch (e) {
        console.error(e);
        blockStepper(this.$_notificationsMixin_extractCatch(e));
        return;
      } finally {
        loadingAction(false);
      }
    },
    __open() {
      this.$bvModal.show(this.id);
    },
  },
};
</script>

<template>
  <div class="foundation-request-modal">
    <button
      type="button"
      :disabled="!hasAccess"
      class="mx-2 bttn-bordered bttn-bordered--sm"
      @click="__open"
    >
      {{ $t("account.request_foundation", "Request Charidy Foundation") }}
    </button>
    <ModalWithSteps
      :id="id"
      :steps="MODAL_STEPS"
      :initialState="INITIAL_STATE"
      :options="wizardOptions"
      @open="onModalOpen"
    >
    </ModalWithSteps>
  </div>
</template>
