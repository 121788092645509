import urls from "@/constants/urls.js";
import axios from "axios";

const state = {
  publicKeyTest: "pk_test_PMsOfZJpfPpg6khrhCGjNHz6",
  publicKeyLive: "",
  secretKey: "",
  cards: [],
};

const mutations = {
  setPublicKeyLive(state, data) {
    state.publicKeyLive = data;
  },
  setCards(state, data) {
    state.cards = data;
  },
};

const actions = {
  getPublicKeyLive({ commit }) {
    const params = { stripe_prod_public_key: 1 };
    axios.get(urls.initPage, { params }).then(({ data }) => {
      const { public_key } = data.included[0].attributes;
      commit("setPublicKeyLive", public_key);
    });
  },

  getCards({ commit }) {
    axios
      .get(urls.donorCardsStripe.replace("/:cardId", ""))
      .then((response) => {
        let cards = response.data.data ? response.data.data : [];
        commit("setCards", cards);
      })
      .catch((e) => {
        console.log(e);
      });
  },
};

export default {
  state,
  actions,
  mutations,
};
