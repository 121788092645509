import axios from "axios";
import urls from "../../constants/urls.js";

const state = {
  campaign: null,
  shareVideoList: [],

  loading: false,
  shareVideoListLoading: false,
  createShareVideoLoading: false,
};

const mutations = {
  setShareVideoList(state, data) {
    state.shareVideoList = data;
  },

  setCampaign(state, data) {
    state.campaign = data;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  setShareVideoListLoading(state, value) {
    state.shareVideoListLoading = value;
  },

  setCreateShareVideoLoading(state, value) {
    state.createShareVideoLoading = value;
  },
};

const getters = {};

const actions = {
  selectCampaign({ commit, dispatch }, cmp) {
    commit("setCampaign", cmp);
    dispatch("fetchShareVideoList", { cID: cmp.id });
  },

  fetchShareVideoList({ commit }, { cID }) {
    commit("setShareVideoListLoading", true);

    return axios
      .get(urls.shareVideoPublic.replace(":cid", cID))
      .then(({ data }) => {
        commit("setShareVideoList", data || []);
        return data;
      })
      .finally(() => {
        commit("setShareVideoListLoading", false);
      });
  },

  fetchCreateShareVideo({ commit, dispatch }, { cID, form }) {
    commit("setCreateShareVideoLoading", true);

    const orgId = localStorage.getItem("orgId");

    const formData = new FormData();

    if (form.file) {
      formData.append("file", form.file);
    }

    if (form.custom_holder_name) {
      formData.append("custom_holder_name", form.custom_holder_name);
    }

    return axios
      .post(urls.shareVideoProtectedCreate.replace(":oid", orgId).replace(":cid", cID), formData)
      .then(() => {
        dispatch("fetchShareVideoList", { cID });
      })
      .finally(() => {
        commit("setCreateShareVideoLoading", false);
      });
  },

  fetchPatchShareVideo({ commit, dispatch }, { cID, id, form }) {
    commit("setCreateShareVideoLoading", true);

    const orgId = localStorage.getItem("orgId");
    const formData = new FormData();

    if (form.file) {
      formData.append("file", form.file);
    }
    if (form.custom_holder_name) {
      formData.append("custom_holder_name", form.custom_holder_name);
    }

    return axios
      .patch(
        urls.shareVideoProtectedRemove
          .replace(":oid", orgId)
          .replace(":cid", cID)
          .replace(":id", id),
        formData
      )
      .then(() => {
        dispatch("fetchShareVideoList", { cID });
      })
      .finally(() => {
        commit("setCreateShareVideoLoading", false);
      });
  },

  fetchRemoveShareVideo({ dispatch }, { cID, id }) {
    const orgId = localStorage.getItem("orgId");

    return axios
      .delete(
        urls.shareVideoProtectedRemove
          .replace(":oid", orgId)
          .replace(":cid", cID)
          .replace(":id", id)
      )
      .then(() => {
        dispatch("fetchShareVideoList", { cID });
      });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
