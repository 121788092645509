<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-3">
        <image-upload v-model="form.donor_image" label="Upload donor image" />
      </div>

      <div class="col-12 col-md-9">
        <div class="form-group">
          <label class="entities-data__label entities-data">
            {{ $t("dashboard.journal_ad_donor_name") }}
          </label>
          <b-form-input v-model="form.donor_name"></b-form-input>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col-12 col-md-3">
        <image-upload
          v-model="form.icon"
          label="Upload Icon"
        />
      </div> -->

      <div class="col-12 col-md-9 ml-auto">
        <div class="form-group">
          <label class="entities-data__label entities-data">
            {{ $t("dashboard.journal_ad_donor_dedication") }}
          </label>
          <b-form-input v-model="form.donor_dedication"></b-form-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import urls from "@/constants/urls.js";

import imageUpload from "@/components/image-upload";

export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    moduleId: {
      type: Number,
      default: 0,
    },
  },

  components: {
    imageUpload,
  },

  data() {
    return {
      donationId: "",
      form: {
        donor_name: "",
        donor_dedication: "",
        donor_image: "",
        icon: "",
      },
    };
  },

  computed: {
    ...mapState({
      campaignId: (state) => state.dashboard.selectedCampaign,
    }),

    orgId() {
      return localStorage.getItem("orgId");
    },
  },

  mounted() {
    if (this.edit && this.moduleId > 0) {
      axios
        .get(
          `${urls.moduleData.replace(":oid", this.orgId).replace(":cmpid", this.campaignId)}/${
            this.moduleId
          }`
        )
        .then(({ data }) => {
          this.donationId = data.data.attributes.donation_id;
          this.form = JSON.parse(data.data.attributes.data);
        })
        .catch((err) => {
          this.$emit("error", err.response.data);
        });
    }
  },

  methods: {
    getMediaUrl(key) {
      const { orgId } = this;
      const mediaUrl = this.form[key];

      return new Promise(function (resolve) {
        if (mediaUrl instanceof File) {
          const formData = new FormData();

          formData.append("file", mediaUrl);

          axios.post(urls.loadImageQuill.replace(":OrgId", orgId), formData).then((response) => {
            resolve(response.data.src);
          });
        } else {
          resolve(mediaUrl);
        }
      });
    },

    async submitForm() {
      const res = this.$validator.validate();
      if (!res) return;

      await this.prepareMedia();

      if (this.edit) {
        return this.editJournal();
      } else {
        return this.addJournal();
      }
    },

    addJournal() {
      const data = {
        type: "module_data",
        attributes: {
          module: "richcontent",
          campaign_id: Number(this.campaignId),
          data: JSON.stringify(this.form),
        },
      };

      return axios.post(
        urls.moduleData.replace(":oid", this.orgId).replace(":cmpid", this.campaignId),
        { data }
      );
    },

    editJournal() {
      const data = {
        type: "module_data",
        id: String(this.moduleId),
        attributes: {
          module: "richcontent",
          campaign_id: Number(this.campaignId),
          donation_id: this.donationId,
          data: JSON.stringify(this.form),
        },
      };

      return axios.put(
        urls.moduleData.replace(":oid", this.orgId).replace(":cmpid", this.campaignId),
        { data }
      );
    },

    async prepareMedia() {
      this.form.donor_image = await this.getMediaUrl("donor_image");
      // this.form.icon = await this.getMediaUrl("icon");
    },

    resetForm() {
      this.form = {
        donor_name: "",
        donor_dedication: "",
        donor_image: "",
        icon: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.entities-data {
  &__label {
    font-family: $vito-light;
    text-transform: uppercase;
    font-size: 14px;
    color: #414359;
    line-height: 1;
    &--required {
      &:after {
        content: "*";
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        color: $lr-100;
      }
    }
  }
}
.v-help {
  font-size: 12px;
  position: absolute;
  right: 0;
  bottom: -18px;
  &.is-danger {
    color: $lr-100;
  }
}
.form-group {
  position: relative;
  &.has-error {
    .form-control {
      border-color: $lr-100;
    }
  }
}
::v-deep .dropdown-toggle {
  background: transparent !important;
  border: 0;
  text-transform: uppercase;
  font-family: $vito-bold;
  font-size: 14px;
  color: #414359 !important;
  &:focus,
  &:active,
  &:hover {
    background: transparent !important;
    color: #414359 !important;
  }
  + .dropdown-menu {
    .dropdown-item {
      font-family: $vito-light;
    }
  }
}

.form-unit__addon-icon {
  padding: 0;
}

.wrapper-options {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eaf4ff;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
  }
  .show-hide-bttn {
    width: auto;
    position: relative;
    z-index: 2;
    &:focus {
      outline: 0;
    }
  }
}

.convert-amount {
  padding: 0 15px;
  display: flex;
  align-items: center;
  label {
    white-space: nowrap;
    margin: 0;
  }
}
.checkbox-block {
  padding-top: 12px;
  .form-checkbox {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
}
.form-checkbox {
  margin: 0;
  &.no-height {
    height: initial;
    > label {
      margin-bottom: 0;
    }
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>
