<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      notificationsFromEmail: (s) => s.account.account.data.attributes.notifications_from_email,
    }),
    emailToUse() {
      return this.notificationsFromEmail || "support@charidy.com";
    },
  },
};
</script>

<template>
  <div>
    <slot :notificationsFromEmail="emailToUse">
      <p>
        {{
          $t(
            "account.org_notifications_from_email_note",
            "The receipt will be sent from email: {email}",
            { email: emailToUse }
          )
        }}
        <router-link to="/account">
          {{ $t("account.org_notifications_from_email_click_here", "click here to change") }}
        </router-link>
      </p>
    </slot>
  </div>
</template>
