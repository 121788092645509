<template>
  <div class="email-tempaltes">
    <div class="inner-tabs justify-content-around">
      <div
        class="inner-tabs__unit"
        :class="{ active: currentEmailType == 'confirmation_email' }"
        @click="changeEmailType('confirmation_email')"
      >
        {{ $t("edit_campaign.email_template_confirmation", "Donation confirmation") }}
      </div>
      <div
        class="inner-tabs__unit"
        :class="{ active: currentEmailType == 'receipt_email' }"
        @click="changeEmailType('receipt_email')"
      >
        {{ $t("edit_campaign.email_template_receipt", "Receipt email") }}
      </div>
      <div
        class="inner-tabs__unit"
        :class="{ active: currentEmailType == 'team_confirmation_email' }"
        @click="changeEmailType('team_confirmation_email')"
      >
        {{ $t("edit_campaign.email_template_team_confirmation_email", "Team confirmation email") }}
      </div>
    </div>

    <div class="row">
      <div class="col-12" :class="{ 'col-xl-6': attachments.length }">
        <CampaignEmailToggle
          v-if="showReceiptToggles"
          class="mb-4"
          :email-type="currentEmailType"
        />

        <div class="campaign-edit-title justify-content-center flex-column">
          <OrgNotificationFromEmailLink class="mb-4" />
          <h2 class="h2-like">
            {{ $t("edit_campaign.email_template_title", "Email templates and settings") }}
          </h2>
        </div>

        <div class="content" v-show="!(loadingEmailTemplates || updateEmail)">
          <div class="right-column">
            <div class="template-container" v-show="emailTemplates">
              <div
                class="template-view"
                @mouseover="showBtns = true"
                @mouseleave="showBtns = false"
              >
                <div class="template">
                  <transition name="fade">
                    <div class="template-btn-group" v-show="showBtns">
                      <div class="btn-wrap" v-show="currentEmailType !== 'team_confirmation_email'">
                        <button
                          class="bttn bttn--lg bttn--blue"
                          :title="$t('dashboard.email_template_change_image')"
                          @click="onClickImage"
                        >
                          <input type="file" ref="emailTemplateFile" style="display: none" />
                          {{ $t("edit_campaign.email_template_update_image") }}
                        </button>
                      </div>
                      <div class="btn-wrap">
                        <button class="bttn bttn--lg bttn--blue" @click="showEditModal">
                          {{ $t("edit_campaign.email_template_edit_content") }}
                        </button>
                      </div>
                      <div class="btn-wrap">
                        <button
                          class="bttn bttn--lg bttn--orange el-right"
                          @click="resetAllTemplateToDefault()"
                        >
                          {{ $t("edit_campaign.email_template_reset_all") }}
                        </button>
                      </div>
                    </div>
                  </transition>
                  <div
                    class="body"
                    v-if="emailTemplate"
                    v-html="emailTemplate.attributes.preview_html_body"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="attachments.length" class="col-xl-6 col-12">
        <div class="campaign-edit-title justify-content-center">
          <h2 class="h2-like">{{ $t("edit_campaign.receipt_preview", "Receipts preview") }}</h2>
        </div>

        <div class="content" v-if="!(loadingEmailTemplates || updateEmail)">
          <div class="left-column">
            <div v-for="atch in attachments" :key="atch.id">
              <object :data="atch.src" type="application/pdf">
                <iframe
                  :src="`https://docs.google.com/viewer?url=${atch.src}&embedded=true`"
                ></iframe>
              </object>
            </div>
          </div>
        </div>
      </div>
    </div>

    <compaign-email-modal ref="emailModal" @save-email-template="updateContentTemplate" />

    <div class="justify-content-center d-flex" v-if="loadingEmailTemplates || updateEmail">
      <LdsSpinner />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";
import router from "@/router";
import urls from "@/constants/urls.js";
import compaignEmailModal from "./campaign-email-modal.vue";
import OrgNotificationFromEmailLink from "@/views/account/subcomponents/orgNotificationFromEmailLink.vue";
import CampaignEmailToggle from "./campaign-email-toggle.vue";

export default {
  name: "campaign-email-template",
  data() {
    return {
      showBtns: false,
      updateEmail: false,
      currentEmailType: "receipt_email",
      emailTemplateData: {
        data: {
          attributes: {
            custom_subject: "",
            custom_body: "",
          },
          relationships: {
            custom_content: [],
          },
        },
        included: [],
      },
      templateType: "",
      errors: [],
    };
  },

  components: {
    LdsSpinner,
    compaignEmailModal,
    OrgNotificationFromEmailLink,
    CampaignEmailToggle,
  },

  created: function () {
    this.$store.dispatch("getEmailTemplates");
  },

  mounted() {
    this.$refs.emailTemplateFile.addEventListener("change", this.onChangeImage);
  },

  methods: {
    changeEmailType(val) {
      this.currentEmailType = val;
    },

    showEditModal() {
      this.$refs.emailModal.openModal(this.emailTemplateData);
    },

    updateContentTemplate(template) {
      this.emailTemplateData.data.attributes = template.attributes;
      this.emailTemplateData.data.relationships = template.relationships;
      this.emailTemplateData.included = template.included;

      this.$refs.emailModal.setErrors([]);
      this.updateTemplate();
    },

    showError(e_1) {
      if (e_1.response.data) {
        if (e_1.response.data.error) {
          this.errors.push(e_1.response.data.error);
        } else {
          this.errors.push(this.$t("template.error_default"));
        }
      } else {
        this.errors.push(this.$t("template.error_default"));
      }
    },

    onClickImage() {
      this.$refs.emailTemplateFile.click();
    },

    onChangeImage(e) {
      this.processFile(e);
    },

    async processFile(e) {
      this.errors = [];

      let fileInput = e.target.files[0];

      if (fileInput != null) {
        this.load = true;
        this.loadingImage = true;
        this.updateEmail = true;

        let form_data = new FormData();

        form_data.append("file", fileInput);

        try {
          const response = await axios.post(urls.loadImageQuill, form_data);

          const [included] = this.emailTemplateData.included;
          included.attributes.src = response.data.src;

          this.updateTemplate();
        } catch (e_1) {
          this.showError(e_1);
        } finally {
          this.updateEmail = false;
          this.loadingImage = false;
          this.load = false;
        }
      }
    },

    resetAllTemplateToDefault() {
      let orgId = localStorage.getItem("orgId");
      const templateType = this.emailTemplateData.data.attributes.title;

      this.updateEmail = true;
      axios
        .delete(
          urls.resetAllEmailTemplateById
            .replace(":orgId", orgId)
            .replace(":campaignId", router.currentRoute.params.id)
            .replace(":templateType", templateType)
        )
        .then(() => {
          this.load = false;
          this.updateEmail = false;
          this.$store.dispatch("getEmailTemplates");
        })
        .catch((e) => {
          this.updateEmail = false;
          this.showError(e);
        });
    },

    resetTemplateToDefault(section) {
      let orgId = localStorage.getItem("orgId");
      const templateType = this.emailTemplateData.data.attributes.title;

      axios
        .delete(
          urls.resetEmailTemplateById
            .replace(":orgId", orgId)
            .replace(":campaignId", router.currentRoute.params.id)
            .replace(":templateType", templateType)
            .replace(":section", section)
        )
        .then(() => {
          this.load = false;
          this.$store.dispatch("getEmailTemplates");
        })
        .catch((e) => {
          this.load = false;
          this.showError(e);
        });
    },

    updateTemplate() {
      this.updateEmail = true;

      let orgId = localStorage.getItem("orgId");

      axios
        .post(
          urls.emailTemplateById
            .replace(":orgId", orgId)
            .replace(":campaignId", router.currentRoute.params.id)
            .replace(":templateType", this.templateType),
          this.emailTemplateData
        )
        .then(() => {
          this.$refs.emailModal.hideModal();
          this.$store.dispatch("getEmailTemplates");

          this.load = false;
          this.updateEmail = false;
        })
        .catch((e) => {
          this.load = false;
          this.updateEmail = false;
          this.showError(e);
          this.$refs.emailModal.setErrors(this.errors);
        });
    },
  },

  computed: {
    ...mapState({
      emailTemplate() {
        const template = this.getTemplateBytype(this.currentEmailType);

        if (template) {
          this.templateType = template.attributes.title;
          return template;
        }
      },
      emailTemplates: (state) => state.campaign.emailTemplates,
      loadingEmailTemplates: (state) => state.campaign.loadingEmailTemplates,
      type: (state) => state.campaign.campaign.data.attributes.type,
      showReceiptToggles: (state) => state.featureHide.showReceiptToggles,
    }),
    ...mapGetters(["getTemplateBytype"]),

    includedMap() {
      return this.emailTemplateData.included.reduce((res, el) => {
        res[`${el.id}_${el.type}`] = el.attributes;
        return res;
      }, {});
    },

    attachments() {
      return (this.emailTemplateData.data.relationships?.attachments?.data || []).map((el) => ({
        ...this.includedMap[`${el.id}_${el.type}`],
        id: el.id,
      }));
    },
  },

  watch: {
    emailTemplates({ included }) {
      this.emailTemplateData.included = [...included];
    },
    emailTemplate(templete) {
      this.emailTemplateData.data = Object.assign(this.emailTemplateData.data, templete);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_vars.scss";

.inner-tabs {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: 769px) {
    padding-left: 25px;
    padding-right: 25px;
    align-items: initial;
    flex-direction: row;
  }
  &__unit {
    width: 33%;
    @media only screen and (min-width: 769px) {
      max-width: 40%;
    }
    padding: 0 25px;
    height: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 19px;
    border: 1px solid $bb-100;
    cursor: pointer;
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
    &:hover {
      background-color: #eaf4ff;
    }
    &.active {
      color: #ffffff;
      background-color: $bb-100;
    }
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.content {
  display: flex;
  height: 100%;
  justify-content: center;
  @media only screen and (min-width: 769px) {
    width: 100%;
  }
  .left-column {
    display: flex;
    @media only screen and (min-width: 769px) {
      width: 100%;
      & > div {
        width: 100%;
      }
    }
    * object,
    * iframe {
      min-width: 600px;
      min-height: 800px;
      height: 94%;
      @media only screen and (min-width: 769px) {
        min-width: unset;
        width: 100%;
      }
    }
  }
  .right-column {
    display: flex;
    justify-content: center;
    //flex-grow: 3;
  }
}

// .description {
// }

.quill-editor .ql-container {
  height: 400px;
  position: relative;
}

.template-container {
  display: flex;
}

// .template-view {
//   width: 50%;
// }

.template {
  max-width: 600px;
  min-width: 500px;
  min-height: 200px;
  position: relative;
  img {
    max-width: 100% !important;
  }
  @media only screen and (min-width: 769px) {
    max-width: unset;
    min-width: unset;
    width: 100%;
  }
  @media only screen and (min-width: 769px) {
    * table {
      width: 100% !important;
    }
  }
}

.image-controll {
  position: relative;
  height: 30px;
  width: 30px;
  label {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #0084af;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 18px;
    &:hover {
      background-color: lighten(#0084af, 10%);
    }
  }

  .upload_new_file {
    background-image: url("../../../../assets/img/cloud_white.png");
    position: absolute;
    left: 0;
    top: 0;
  }
  .email-template-editor {
    .quill-editor .ql-container {
      height: 350px;
      position: relative;
    }
  }
  .email-template-editor .quill-editor .ql-container {
    height: 350px;
    position: relative;
  }
}

.email-type-btn {
  margin-bottom: 24px;
}

.reset-btn {
  margin-bottom: 24px;
}

.template-btn-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  .btn-wrap {
    margin: 5px 0;
  }
  label {
    margin: 0;
    cursor: pointer;
  }
}

.loadin-image {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 140px;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ql-align-center {
  text-align: center;
}

.ql-align-left {
  text-align: left;
}

.ql-align-right {
  text-align: right;
}
</style>
