<template lang="html">
  <b-list-group class="alert-danger" v-bind="$attrs">
    <b-list-group-item
      variant="danger"
      v-for="(error, index) in errors"
      :key="index"
      :error="error"
    >
      <span v-if="error.error">{{ error.error }}</span>
      <span v-else>{{ error }}</span>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  props: ["errors"],
};
</script>

<style lang="scss" scoped>
.alert-danger {
  margin: 0;
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
}
</style>
