import _Quill from "quill";
import quillEditor from "./editor.vue";
import CharidyTheme from "./theme/charidy";
import { LinkWithTarget } from "./modules/link-with-target";
import ImageResize from "./image-resize.js";
import CustomVideo from "./formats/custom-video";
import { SizeStyle } from "./attributors/size";

const Quill = window.Quill || _Quill;
const install = (Vue, globalOptions) => {
  if (globalOptions) {
    quillEditor.props.globalOptions.default = () => globalOptions;
  }
  Vue.component(quillEditor.name, quillEditor);
};

const DirectionAttribute = Quill.import("attributors/attribute/direction");
const AlignClass = Quill.import("attributors/class/align");
const BackgroundClass = Quill.import("attributors/class/background");
const ColorClass = Quill.import("attributors/class/color");
const DirectionClass = Quill.import("attributors/class/direction");
const FontClass = Quill.import("attributors/class/font");
const SizeClass = Quill.import("attributors/class/size");
const AlignStyle = Quill.import("attributors/style/align");
const BackgroundStyle = Quill.import("attributors/style/background");
const ColorStyle = Quill.import("attributors/style/color");
const DirectionStyle = Quill.import("attributors/style/direction");
const FontStyle = Quill.import("attributors/style/font");

Quill.register(DirectionAttribute, true);
Quill.register(AlignClass, true);
Quill.register(BackgroundClass, true);
Quill.register(ColorClass, true);
Quill.register(DirectionClass, true);
Quill.register(FontClass, true);
Quill.register(SizeClass, true);
Quill.register(AlignStyle, true);
Quill.register(BackgroundStyle, true);
Quill.register(ColorStyle, true);
Quill.register(DirectionStyle, true);
Quill.register(FontStyle, true);
Quill.register(SizeStyle, true);

Quill.register(
  {
    LinkWithTarget,
    "modules/imageResize": ImageResize,
    "themes/charidy": CharidyTheme,
    [`formats/${CustomVideo.blotName}`]: CustomVideo,
  },
  true
);

const VueQuillEditor = { Quill, quillEditor, install };

export default VueQuillEditor;
export { Quill, quillEditor, install };
