import axios from "axios";
import urls from "@/constants/urls";

const state = {
  share: [],
  loading: {
    share: false,
  },
};

const mutations = {
  setCampaignShare(state, data) {
    state.share = data;
  },

  setCampaignLoading(state, [k, v]) {
    state.loading[k] = v;
  },
};

const actions = {
  fetchShare({ commit }, [cId, getParams = {}, internalLoading = true]) {
    const orgId = localStorage.getItem("orgId");
    const params = {
      ...getParams,
    };
    internalLoading && commit("setCampaignLoading", ["share", true]);
    return axios
      .get(urls.share.replace(":oid", orgId).replace(":cid", cId), { params })
      .then(({ data }) => {
        commit("setCampaignShare", data);
        return data;
      })
      .finally(() => {
        internalLoading && commit("setCampaignLoading", ["share", false]);
      });
  },

  createShare({ commit }, [cId, social, metadata, options = {}]) {
    const noLoading = options.noLoading || false;
    const orgId = localStorage.getItem("orgId");
    const body = {
      data: {
        type: "campaign_share",
        attributes: {
          ...metadata,
          campaign_id: Number(cId),
          social,
        },
      },
    };
    if (!noLoading) {
      commit("setCampaignLoading", ["share", true]);
    }
    return axios
      .post(urls.share.replace(":oid", orgId).replace(":cid", cId), body)
      .then(({ data }) => {
        return data;
      })
      .finally(() => {
        if (!noLoading) {
          commit("setCampaignLoading", ["share", false]);
        }
      });
  },

  async refreshImages({ commit }, [cId, options = {}]) {
    const noLoading = options.noLoading || false;
    const orgId = localStorage.getItem("orgId");

    if (!noLoading) {
      commit("setCampaignLoading", ["share", true]);
    }

    try {
      const { data } = await axios.post(
        urls.shareRefreshImages.replace(":oid", orgId).replace(":cid", cId)
      );
      return data;
    } finally {
      if (!noLoading) {
        commit("setCampaignLoading", ["share", false]);
      }
    }
  },

  editShare({ commit }, [cId, shareId, social, metadata, options = {}]) {
    const noLoading = options.noLoading || false;
    const orgId = localStorage.getItem("orgId");
    const body = {
      data: {
        id: shareId,
        type: "campaign_share",
        attributes: {
          ...metadata,
          campaign_id: Number(cId),
          social,
        },
      },
    };
    const url = `${urls.share.replace(":oid", orgId).replace(":cid", cId)}/${social}`;
    if (!noLoading) {
      commit("setCampaignLoading", ["share", true]);
    }
    return axios
      .put(url, body)
      .then(({ data }) => {
        return data;
      })
      .finally(() => {
        if (!noLoading) {
          commit("setCampaignLoading", ["share", false]);
        }
      });
  },

  deleteShare({ commit }, [cId, social, options = {}]) {
    const noLoading = options.noLoading || false;
    const orgId = localStorage.getItem("orgId");
    const url = `${urls.share.replace(":oid", orgId).replace(":cid", cId)}/${social}`;
    if (!noLoading) {
      commit("setCampaignLoading", ["share", true]);
    }
    return axios
      .delete(url)
      .then(({ data }) => {
        return data;
      })
      .finally(() => {
        if (!noLoading) {
          commit("setCampaignLoading", ["share", false]);
        }
      });
  },
};

export default { namespaced: true, state, mutations, actions };
