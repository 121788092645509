export const currencies = [
  {
    code: "usd",
    sign: "$",
  },
  {
    code: "ils",
    sign: "₪",
  },
  {
    code: "gbp",
    sign: "£",
  },
  {
    code: "eur",
    sign: "€",
  },
  {
    code: "aud",
    sign: "$",
  },
  {
    code: "brl",
    sign: "R$",
  },
  {
    code: "cad",
    sign: "$",
  },
  {
    code: "nzd",
    sign: "$",
  },
  {
    code: "sek",
    sign: "kr",
  },
  {
    code: "zar",
    sign: "R",
  },
  {
    code: "mxn",
    sign: "$",
  },
  {
    code: "ars",
    sign: "$",
  },
  {
    code: "chf",
    sign: "CHF",
  },
  {
    code: "rub",
    sign: "₽",
  },
  {
    code: "sgd",
    sign: "S$",
  },
  {
    code: "hkd",
    sign: "HK$",
  },
  {
    code: "uah",
    sign: "₴",
  },
  {
    code: "clp",
    sign: "$",
  },
  {
    code: "uyu",
    sign: "$U",
  },
];

export const currenciesMap = currencies.reduce((res, el) => {
  res[el.code] = el;
  return res;
}, {});

export const getFormatedAmountStringRaw = (amt, currCode, params = { includeCode: false }) => {
  const totalFmt = amt?.toLocaleString() || 0;
  const curSign = currenciesMap[currCode]?.sign || "";
  const currCodeFmt = params?.includeCode ? currCode.toUpperCase() : "";

  if (amt && currCode === "eur") {
    return {
      formatted: [currCodeFmt, totalFmt, curSign],
      currCodeFmt,
      totalFmt,
      curSign,
    };
  }

  return {
    formatted: [curSign, totalFmt, currCodeFmt],
    curSign,
    totalFmt,
    currCodeFmt,
  };
};

export const getFormatedAmountString = (amt, currCode, params) => {
  const data = getFormatedAmountStringRaw(amt, currCode, params);

  if (!data) {
    return "";
  }

  return data.formatted.join("");
};

export default { currencies, currenciesMap, getFormatedAmountString };
