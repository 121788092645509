<template>
  <svg
    width="116"
    height="123"
    viewBox="0 0 116 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="uniqueTitleID"
  >
    <title id="uniqueTitleID">
      {{ $t("home_page_v2.logo_title", "Charidy") }}
    </title>
    <path
      d="M38.603 67.7282C39.16 67.7282 39.6941 67.507 40.088 67.1132C40.4818 66.7193 40.703 66.1852 40.703 65.6282V59.6042C40.7709 58.1399 40.2647 56.707 39.2921 55.6102C38.8185 55.1072 38.2425 54.7117 37.603 54.4503C36.9635 54.189 36.2753 54.0679 35.585 54.0952C34.8251 54.0716 34.0688 54.2071 33.3644 54.4932C32.66 54.7792 32.0233 55.2095 31.4951 55.7562V50.8402C31.4951 50.5645 31.4407 50.2914 31.3351 50.0366C31.2296 49.7818 31.0749 49.5503 30.8799 49.3553C30.6849 49.1603 30.4535 49.0056 30.1987 48.9001C29.9439 48.7946 29.6708 48.7402 29.395 48.7402H29.369C29.0932 48.7402 28.8202 48.7946 28.5654 48.9001C28.3106 49.0056 28.0791 49.1603 27.8841 49.3553C27.6891 49.5503 27.5344 49.7818 27.4289 50.0366C27.3234 50.2914 27.269 50.5645 27.269 50.8402V65.6292C27.269 66.1862 27.4903 66.7203 27.8841 67.1142C28.2779 67.508 28.8121 67.7292 29.369 67.7292H29.395C29.952 67.7292 30.4861 67.508 30.8799 67.1142C31.2738 66.7203 31.4951 66.1862 31.4951 65.6292V61.5832C31.4243 60.6057 31.6796 59.6323 32.221 58.8152C32.4558 58.5142 32.759 58.2735 33.1055 58.1132C33.4519 57.9528 33.8316 57.8774 34.213 57.8932C34.5268 57.8821 34.839 57.9414 35.1268 58.0669C35.4146 58.1924 35.6707 58.3808 35.876 58.6182C36.3138 59.1461 36.5395 59.8181 36.509 60.5032V65.6312C36.509 65.907 36.5633 66.1801 36.6689 66.4349C36.7744 66.6897 36.9291 66.9211 37.1241 67.1161C37.3191 67.3112 37.5506 67.4658 37.8054 67.5714C38.0602 67.6769 38.3332 67.7312 38.609 67.7312"
      :fill="fillColor"
    />
    <path
      d="M56.4799 53.9268V53.9408C55.9592 53.9402 55.4567 54.1322 55.069 54.4798C54.6814 54.8274 54.4359 55.3061 54.3799 55.8238L54.3669 55.5478C54.3669 55.5478 53.1829 54.1708 50.2509 54.0978C49.3901 54.0757 48.5348 54.241 47.7443 54.5824C46.9538 54.9238 46.247 55.433 45.6729 56.0748C44.4562 57.4726 43.7861 59.2632 43.7861 61.1163C43.7861 62.9694 44.4562 64.76 45.6729 66.1578C46.2552 66.7883 46.9638 67.2891 47.7526 67.6275C48.5414 67.9659 49.3926 68.1343 50.2509 68.1218C51.7784 68.0912 53.2565 67.5749 54.4709 66.6478C54.6271 67.1245 54.9481 67.5301 55.3762 67.7917C55.8043 68.0533 56.3115 68.1538 56.8071 68.0753C57.3026 67.9969 57.754 67.7445 58.0803 67.3634C58.4066 66.9823 58.5866 66.4975 58.5879 65.9958V56.0348C58.5879 55.7577 58.5333 55.4834 58.4272 55.2275C58.3212 54.9715 58.1657 54.739 57.9698 54.5432C57.7738 54.3473 57.5413 54.192 57.2853 54.086C57.0293 53.9801 56.7549 53.9257 56.4778 53.9258L56.4799 53.9268ZM51.1219 64.5358C50.6931 64.5501 50.2662 64.4729 49.8696 64.3092C49.473 64.1456 49.1158 63.8993 48.8218 63.5868C48.2423 62.8938 47.9249 62.0191 47.9249 61.1158C47.9249 60.2124 48.2423 59.3378 48.8218 58.6448C49.1138 58.3289 49.4703 58.0795 49.8672 57.9135C50.264 57.7476 50.692 57.6689 51.1219 57.6828C51.5489 57.6793 51.972 57.7642 52.3647 57.932C52.7574 58.0998 53.1112 58.3468 53.4039 58.6578C54.0231 59.3265 54.367 60.2044 54.367 61.1158C54.367 62.0272 54.0231 62.905 53.4039 63.5738C53.1092 63.8813 52.7548 64.1253 52.3623 64.2907C51.9699 64.4562 51.5478 64.5396 51.1219 64.5358Z"
      :fill="fillColor"
    />
    <path
      d="M77.6129 54.1094C77.3358 54.1092 77.0615 54.1637 76.8054 54.2696C76.5494 54.3755 76.3167 54.5308 76.1207 54.7267C75.9246 54.9225 75.7691 55.155 75.663 55.411C75.5568 55.6669 75.5021 55.9413 75.502 56.2184V65.9974C75.502 66.5572 75.7243 67.0942 76.1202 67.4901C76.5161 67.8859 77.053 68.1084 77.6129 68.1084C78.1728 68.1084 78.7098 67.8859 79.1057 67.4901C79.5015 67.0942 79.7239 66.5572 79.7239 65.9974V56.2184C79.7238 55.9413 79.6691 55.6669 79.5629 55.411C79.4568 55.155 79.3012 54.9225 79.1052 54.7267C78.9092 54.5308 78.6765 54.3755 78.4205 54.2696C78.1644 54.1637 77.89 54.1092 77.6129 54.1094Z"
      :fill="fillColor"
    />
    <path
      d="M93.7591 50.859V55.5511C92.622 54.561 91.1498 54.0423 89.6431 54.101C88.7838 54.0891 87.9316 54.259 87.1426 54.5998C86.3536 54.9405 85.6456 55.4443 85.0651 56.078C83.8487 57.4759 83.1787 59.2665 83.1787 61.1195C83.1787 62.9726 83.8487 64.7632 85.0651 66.161C85.6475 66.7915 86.3561 67.2923 87.1449 67.6307C87.9337 67.9691 88.7849 68.1375 89.6431 68.125C91.1498 68.1838 92.622 67.6651 93.7591 66.675L93.8591 66.5941C94.0096 67.0758 94.3277 67.4876 94.7557 67.755C95.1837 68.0224 95.6933 68.1276 96.1923 68.0516C96.6912 67.9755 97.1463 67.7232 97.4752 67.3405C97.8041 66.9577 97.985 66.4697 97.9851 65.9651V50.859C97.9851 50.2992 97.7627 49.7622 97.3668 49.3663C96.9709 48.9704 96.434 48.748 95.8741 48.748C95.3143 48.748 94.7773 48.9704 94.3814 49.3663C93.9855 49.7622 93.7631 50.2992 93.7631 50.859H93.7591ZM92.8001 63.578C92.5055 63.8856 92.1511 64.1296 91.7586 64.2951C91.3662 64.4605 90.944 64.5439 90.5181 64.54C90.0893 64.5544 89.6624 64.4771 89.2658 64.3135C88.8692 64.1499 88.5121 63.9036 88.2181 63.591C87.6386 62.898 87.3211 62.0234 87.3211 61.1201C87.3211 60.2167 87.6386 59.3421 88.2181 58.649C88.5101 58.3332 88.8666 58.0838 89.2634 57.9178C89.6603 57.7518 90.0882 57.6731 90.5181 57.687C90.9451 57.6836 91.3682 57.7685 91.7609 57.9362C92.1536 58.104 92.5074 58.3511 92.8001 58.662C93.4191 59.3309 93.7629 60.2087 93.7629 61.1201C93.7629 62.0314 93.4191 62.9092 92.8001 63.578Z"
      :fill="fillColor"
    />
    <path
      d="M113.339 54.1092H113.313C112.909 54.1095 112.514 54.2278 112.177 54.4495C111.839 54.6712 111.574 54.9867 111.413 55.3572L108.432 62.1922L105.626 55.4012C105.524 55.146 105.372 54.9136 105.179 54.7176C104.987 54.5216 104.757 54.3658 104.504 54.2594C104.25 54.153 103.978 54.098 103.703 54.0977C103.429 54.0973 103.156 54.1516 102.903 54.2574C102.649 54.3633 102.419 54.5184 102.226 54.714C102.033 54.9095 101.881 55.1415 101.778 55.3965C101.675 55.6514 101.624 55.9242 101.628 56.199C101.632 56.4738 101.69 56.7452 101.8 56.9972L106.243 67.2812L104.685 70.7632C104.548 71.0783 104.492 71.4224 104.522 71.7645C104.552 72.1066 104.666 72.436 104.854 72.7231C105.043 73.0101 105.299 73.2459 105.601 73.4091C105.903 73.5723 106.241 73.6579 106.585 73.6582H106.627C107.031 73.6574 107.426 73.5384 107.764 73.316C108.101 73.0936 108.367 72.7773 108.527 72.4062L115.24 57.0062C115.375 56.6913 115.431 56.3476 115.401 56.006C115.371 55.6645 115.256 55.3357 115.068 55.0492C114.88 54.7627 114.623 54.5274 114.322 54.3644C114.02 54.2013 113.683 54.1157 113.34 54.1152"
      :fill="fillColor"
    />
    <path
      d="M103.714 96.4839C103.289 96.4817 102.869 96.572 102.483 96.7487C102.097 96.9254 101.754 97.1841 101.478 97.5069C96.1984 103.738 89.6239 108.743 82.2125 112.173C74.8011 115.604 66.7309 117.378 58.564 117.371H58.348C48.9076 117.327 39.6304 114.905 31.3728 110.33C23.1152 105.754 16.1432 99.1721 11.1005 91.1912C6.05776 83.2103 3.10672 74.0877 2.51981 64.6654C1.93289 55.2432 3.72898 45.8249 7.74231 37.2799C11.7556 28.7349 17.8569 21.3386 25.4828 15.7737C33.1088 10.2088 42.0138 6.65458 51.3757 5.43918C60.7377 4.22377 70.2549 5.38632 79.0491 8.81952C87.8432 12.2527 95.6308 17.8459 101.693 25.0829C101.892 25.3225 102.142 25.5156 102.424 25.6483C102.706 25.781 103.014 25.8501 103.326 25.8509C103.816 25.8506 104.295 25.7101 104.707 25.446C105.119 25.1818 105.447 24.805 105.652 24.3603C105.857 23.9155 105.93 23.4214 105.862 22.9365C105.795 22.4515 105.59 21.996 105.272 21.6239C99.5253 14.8432 92.37 9.39639 84.304 5.66245C76.2381 1.9285 67.4553 -0.00283807 58.567 0.00286891C50.731 -0.0756126 42.9624 1.45687 35.7433 4.50525C28.5241 7.55363 22.0082 12.0529 16.6 17.7239C5.89501 29.0019 0 44.2899 0 60.7739C0 77.3079 5.90001 92.7359 16.6 104.216C21.9569 109.98 28.4543 114.568 35.6792 117.687C42.9042 120.805 50.6988 122.387 58.568 122.332C67.5301 122.341 76.3846 120.379 84.5041 116.585C92.6236 112.791 99.8096 107.259 105.553 100.379C105.844 100.035 106.029 99.6144 106.086 99.1678C106.144 98.7211 106.072 98.2674 105.878 97.8609C105.688 97.4479 105.383 97.0984 105 96.8542C104.616 96.61 104.171 96.4814 103.716 96.4839"
      :fill="fillColor"
    />
    <path
      d="M17.6222 57.8858C18.0409 57.8844 18.4557 57.9676 18.8415 58.1305C19.2272 58.2934 19.5761 58.5326 19.8672 58.8337C20.0492 59.0234 20.267 59.1752 20.508 59.2805C20.7489 59.3857 21.0083 59.4422 21.2711 59.4469C21.534 59.4515 21.7952 59.4042 22.0397 59.3075C22.2842 59.2109 22.5072 59.0668 22.6959 58.8837C22.8845 58.7006 23.0351 58.482 23.139 58.2405C23.2429 57.9989 23.2981 57.7393 23.3012 57.4764C23.3044 57.2135 23.2556 56.9525 23.1576 56.7086C23.0596 56.4646 22.9143 56.2424 22.7302 56.0548C21.7316 55.0271 20.4459 54.325 19.0416 54.0403C17.6372 53.7556 16.1797 53.9016 14.8597 54.4592C13.5398 55.0169 12.4189 55.9601 11.6441 57.1654C10.8692 58.3707 10.4764 59.782 10.517 61.2143C10.5577 62.6466 11.0299 64.0333 11.8719 65.1927C12.7139 66.3522 13.8864 67.2303 15.2359 67.7121C16.5854 68.194 18.0489 68.2571 19.4348 67.8932C20.8207 67.5293 22.0645 66.7554 23.0032 65.6728C23.3076 65.2681 23.4472 64.7629 23.3937 64.2593C23.3403 63.7556 23.0977 63.2911 22.7151 62.9593C22.3324 62.6275 21.8381 62.4533 21.332 62.4717C20.8259 62.4902 20.3456 62.7 19.9882 63.0588C19.6455 63.4553 19.2087 63.7594 18.7179 63.9432C18.2271 64.1269 17.698 64.1844 17.1792 64.1104C16.6604 64.0363 16.1685 63.8331 15.7487 63.5193C15.3289 63.2056 14.9946 62.7914 14.7766 62.3148C14.5587 61.8382 14.4639 61.3145 14.5011 60.7917C14.5383 60.2689 14.7063 59.7639 14.9896 59.323C15.2728 58.8821 15.6624 58.5194 16.1224 58.2683C16.5824 58.0171 17.0981 57.8856 17.6222 57.8858Z"
      :fill="fillColor"
    />
    <path
      d="M64.9751 54.0676C64.677 54.0673 64.3818 54.1258 64.1064 54.2396C63.8309 54.3535 63.5806 54.5205 63.3697 54.7311C63.1588 54.9417 62.9915 55.1918 62.8773 55.4671C62.763 55.7424 62.7042 56.0376 62.7041 56.3356V65.9586C62.7042 66.2408 62.7599 66.5202 62.868 66.7809C62.9762 67.0416 63.1346 67.2784 63.3342 67.4779C63.5339 67.6773 63.7708 67.8355 64.0316 67.9434C64.2924 68.0513 64.5719 68.1067 64.8541 68.1066C65.126 68.1069 65.3953 68.0535 65.6466 67.9497C65.8979 67.8458 66.1263 67.6935 66.3187 67.5014C66.5111 67.3092 66.6638 67.0811 66.768 66.8299C66.8722 66.5787 66.926 66.3095 66.9261 66.0376V63.2136C66.9261 60.5036 67.9491 58.8546 69.9971 58.3666L70.8391 58.2026C71.3844 58.1638 71.8947 57.9197 72.267 57.5193C72.6393 57.119 72.846 56.5923 72.8451 56.0456C72.8451 55.9743 72.8418 55.9036 72.8351 55.8336C72.7978 55.5463 72.7022 55.2696 72.5541 55.0206C72.4061 54.7716 72.2087 54.5554 71.9742 54.3854C71.7396 54.2153 71.4728 54.095 71.1901 54.0318C70.9074 53.9685 70.6147 53.9637 70.3301 54.0176C70.0991 54.0564 69.8722 54.1163 69.6521 54.1966C68.4923 54.6554 67.5287 55.5041 66.9271 56.5966V56.0156C66.9265 55.4985 66.7207 55.0028 66.3549 54.6373C65.9891 54.2719 65.4932 54.0666 64.9761 54.0666"
      :fill="fillColor"
    />
    <path
      d="M77.6089 47.4453C77.1556 47.4457 76.7127 47.5805 76.336 47.8326C75.9594 48.0846 75.6659 48.4427 75.4928 48.8616C75.3196 49.2804 75.2745 49.7412 75.3631 50.1857C75.4518 50.6301 75.6703 51.0384 75.9909 51.3587C76.3115 51.679 76.7199 51.8971 77.1645 51.9854C77.609 52.0737 78.0697 52.0282 78.4884 51.8546C78.9071 51.6811 79.265 51.3873 79.5168 51.0105C79.7685 50.6336 79.9029 50.1905 79.9029 49.7373C79.9023 49.1292 79.6604 48.5463 79.2303 48.1165C78.8001 47.6867 78.2169 47.4453 77.6089 47.4453Z"
      :fill="fillColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: { type: String, default: "#0A8EFF" },
  },
};
</script>
