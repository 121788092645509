<template>
  <div class="category-header" :class="{ 'm-0': card || list }">
    <h1
      class="category-header__title"
      :class="{
        'm-0 category-header__title--card': card,
        'category-header__title--list': list,
      }"
    >
      {{ title }}
    </h1>
    <div v-if="withActions" class="action-block" :class="actionsClass">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryTitle",
  props: {
    title: {
      type: String,
      default: "",
    },
    withActions: {
      type: Boolean,
      default: false,
    },
    actionsClass: {
      type: [String, Array, Object],
      default: "",
    },
    card: Boolean,
    list: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.category-header {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  &__title {
    width: 100%;
    margin: 0 0 10px;
    font-family: $vito-light;
    font-size: 26px;
    line-height: 1;
    @media only screen and (min-width: 468px) {
      font-size: 32px;
    }
    @media only screen and (min-width: 992px) {
      width: initial;
    }
    &--card {
      font-size: 18px;
      line-height: 1;
      @media only screen and (min-width: 468px) {
        font-size: 26px;
      }
    }
    &--list {
      margin: 0 0 10px auto;
    }
  }
}
.action-block {
  display: flex;
  margin: 0 0 10px auto;
}
</style>
