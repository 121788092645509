<template>
  <div
    class="text-3-rows"
    :class="{
      'text-3-rows--one-row': loading,
      active,
    }"
    :role="btn ? 'button' : 'text'"
  >
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-skeleton type="text" width="92px" height="86px"></b-skeleton>
      </template>
      <span class="text-3-rows__top">
        <slot name="top" />
      </span>
      <span>
        <slot name="middle" />
      </span>
      <div class="text-3-rows__bottom">
        <slot name="bottom" />
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    active: Boolean,
    btn: Boolean,
  },

  computed: {
    ...mapState({
      loading: (s) => s.rds.loading,
    }),
  },
};
</script>

<style lang="scss" scoped>
.text-3-rows {
  &.active {
    margin: 0;
    border: 2px solid #107598;
    border-radius: 0.5rem;
    background: #10769815;
  }
  margin: 2px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 0.5fr);
  justify-items: center;
  align-items: center;
  text-align: center;
  &__top {
    color: #107598;
    font-size: 32px;
  }
  &__bottom {
    direction: ltr;
    display: inline-flex;
    gap: 5px;
    font-size: 13px;
    font-weight: bold;
  }
  &--one-row {
    grid-template-rows: 1fr;
  }
}
</style>
