<template>
  <div>
    <b-row>
      <b-col v-if="!multiple">
        <b-form-group class="m-0">
          <DatePicker
            v-model="date"
            :is-range="range"
            :min-date="availableDates.start"
            :max-date="availableDates.end"
            :disabled-dates="getDisabledDates"
          >
            <template #default="{ inputValue, inputEvents, togglePopover }">
              <b-row>
                <b-col>
                  <b-form-group :label="startDateLabel">
                    <b-input-group>
                      <b-form-input
                        :value="range ? inputValue.start : inputValue"
                        readonly
                        class="bg-white"
                        v-on="range ? inputEvents.start : inputEvents"
                      />

                      <template #prepend>
                        <b-input-group-text @click="togglePopover">
                          <b-icon-calendar font-scale="1.4" />
                        </b-input-group-text>
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col v-if="range" cols="12" sm="6">
                  <b-form-group :label="$t('dashboard_calendar_module.end_date_label', 'End day:')">
                    <b-input-group>
                      <b-form-input
                        :value="inputValue.end"
                        readonly
                        class="bg-white"
                        v-on="inputEvents.end"
                      />

                      <template #prepend>
                        <b-input-group-text @click="togglePopover">
                          <b-icon-calendar font-scale="1.4" />
                        </b-input-group-text>
                      </template>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </DatePicker>
        </b-form-group>
      </b-col>

      <b-col v-if="multiple">
        <b-form-group class="m-0">
          <DatePicker
            v-model="selectedModel"
            :min-date="availableDates.start"
            :max-date="availableDates.end"
            :disabled-dates="getDisabledDatesExt"
          >
            <template #default="{ togglePopover, hidePopover }">
              <b-row>
                <b-col>
                  <div class="bg-white form-control h-auto">
                    <div class="d-flex flex-wrap m-n1">
                      <div
                        v-for="(d, i) in dates"
                        :key="i"
                        ref="button"
                        class="badge-primary m-1 p-1 rounded d-flex align-items-center"
                        role="button"
                        @click.stop="dateSelected($event, i, togglePopover)"
                      >
                        {{ new Date(d.start).toLocaleDateString() }}
                        <b-icon-x
                          v-if="dates.length > 1"
                          font-scale="1.3"
                          @click.stop="removeDate(i, hidePopover)"
                        />
                      </div>

                      <b-button
                        variant="outline-primary"
                        size="sm"
                        class="m-1"
                        @click.stop="addDate"
                      >
                        + Add date
                      </b-button>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </template>
          </DatePicker>
        </b-form-group>
      </b-col>

      <!-- hide for now as we have only one value in this ddl -->
      <!-- <b-col
        v-if="forPeriod"
        cols="12"
        md="4"
      >
        <b-form-group :label="$t('dashboard_calendar_module.period_label', 'Period:')">
          <b-form-select
            v-model="period"
            :options="periods"
          />
        </b-form-group>
      </b-col> -->

      <b-col v-if="forPeriod" cols="12" md="6">
        <b-form-group :label="$t('dashboard_calendar_module.interval_label', 'Interval:')">
          <b-form-input v-model="interval" type="number" min="1" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col v-if="isOutAvailableDatesRange" cols="12" class="text-danger mb-2">
        {{
          $t(
            "dashboard_calendar_module.date_is_out_avail_dates_range_err_msg",
            "The period is out of availble date range"
          )
        }}
      </b-col>
      <b-col v-if="!forPeriodMetaInfo.valid" cols="12" class="text-danger mb-2">
        {{
          $t(
            "dashboard_calendar_module.period_goes_through_reserved_date_err_msg",
            "The period goes through a reserved date"
          )
        }}
      </b-col>
    </b-row>

    <b-row v-if="forPeriod">
      <b-col>
        <b-form-group :label="$t('dashboard_calendar_module.preview_label', 'Preview:')">
          <v-calendar
            ref="calendar"
            :attributes="forPeriodMetaInfo.attributes"
            is-expanded
            :min-date="availableDates.start"
            :max-date="availableDates.end"
            :disabled-dates="getDisabledDates"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import DatePicker from "v-calendar/lib/components/date-picker.umd.js";
import VCalendar from "v-calendar/lib/components/calendar.umd.js";

import { isDateDisabled, isOutAvailableDatesRangeHelper } from "@/helpers/calendarModule";
import { getEndDateOfInterval } from "@/helpers/time";

const now = new Date();

export const defaultFormData = {
  dates: [
    {
      start: now,
      end: now,
    },
  ],
  period: "week",
  interval: 0,
};

export default {
  components: {
    DatePicker,
    VCalendar,
  },

  props: {
    value: {
      type: Object,
      default: () => defaultFormData,
    },
    range: Boolean,
    forPeriod: Boolean,
    multiple: Boolean,
  },

  data() {
    return {
      periods: [
        {
          text: this.$t("dashboard_calendar_module.period_week", "week"),
          value: "week",
        },
      ],
      selected: {},
      selectedIdx: -1,
    };
  },

  computed: {
    ...mapState({
      moduleConfig: (state) => state.calendar.config,
    }),
    ...mapGetters({
      getDisabledDates: "calendar/getDisabledDates",
    }),
    getDisabledDatesExt() {
      const dates = [...this.dates];
      if (this.selectedIdx >= 0) {
        dates.splice(this.selectedIdx, 1);
      }
      return [...this.getDisabledDates, ...dates];
    },
    availableDates() {
      return this.moduleConfig.available_dates;
    },
    date: {
      get() {
        if (this.range) {
          return this.value.dates[0];
        }
        return this.value.dates[0].start;
      },
      set(value) {
        const item = {};
        if (this.range) {
          this.$set(item, "start", value.start);
          this.$set(item, "end", value.end);
        } else {
          this.$set(item, "start", value);
          this.$set(item, "end", value);
        }
        this.$set(this.value, "dates", [item]);
        this.$emit("input", this.value);
      },
    },
    selectedModel: {
      get() {
        return this.selected.start;
      },
      set(value) {
        this.$set(this.selected, "start", value);
        this.$set(this.selected, "end", value);
      },
    },
    dates: {
      get() {
        return this.value.dates;
      },
      set(value) {
        this.$set(this.value, "dates", value);
        this.$emit("input", this.value);
      },
    },
    period: {
      get() {
        return this.value.period;
      },
      set(value) {
        this.$set(this.value, "period", value);
        this.$emit("input", this.value);
      },
    },
    interval: {
      get() {
        return Number(this.value.interval);
      },
      set(value) {
        this.$set(this.value, "interval", Number(value));
        this.$emit("input", this.value);
      },
    },
    startDateLabel() {
      if (this.range || this.forPeriod) {
        return this.$t("dashboard_calendar_module.start_date_label", "Start day:");
      }
      return this.$t("dashboard_calendar_module.selected_date_label", "Selected day:");
    },
    forPeriodMetaInfo() {
      const res = {
        valid: true,
        disabledDates: [],
        attributes: [],
      };

      if (this.forPeriod) {
        const list = Array(this.interval).fill(this.date);

        const attrs = list.map((d, i) => {
          const nd = getEndDateOfInterval(d, this.period, i);
          const disabled = isDateDisabled(this.getDisabledDates, nd);

          if (disabled) {
            res.valid = false;
            res.disabledDates.push(nd);
          }

          return {
            dates: nd,
            highlight: disabled ? "red" : true,
          };
        });

        res.attributes = attrs;
      }

      return res;
    },
    isOutAvailableDatesRange() {
      return isOutAvailableDatesRangeHelper(this.moduleConfig, this.value);
    },
  },

  watch: {
    "value.start": function (val) {
      if (this.forPeriod) {
        this.calendarMove(val);
      }
    },
  },

  mounted() {
    // this.dates = [];
    this.selected = {};
    this.selectedIdx = -1;

    const { start, end } = this.value.dates[0] || {};

    if (start !== end && this.range) {
      this.date = {
        start: start,
        end: start,
      };
    }

    if (start !== end && !this.range) {
      this.date = start;
    }

    if (this.multiple && this.value.dates.length === 0) {
      this.addDate();
    }

    if (this.forPeriod) {
      this.period = "week";
      this.interval = 2;

      this.calendarMove(this.date);
    } else {
      this.period = "";
      this.interval = 0;
    }
  },

  methods: {
    calendarMove(val) {
      const { calendar } = this.$refs;

      calendar.move(val);
    },
    addDate() {
      const dates = [...this.dates];
      const nd = new Date(this.date);
      nd.setDate(nd.getDate() + dates.length);

      dates.push({ start: nd, end: nd });

      this.dates = dates;

      this.$nextTick(() => {
        const btn = this.$refs.button[dates.length - 1];
        btn?.click();
      });
    },
    removeDate(i, hide) {
      const dates = [...this.dates];
      dates.splice(i, 1);
      this.dates = dates;
      hide();
    },
    dateSelected(e, i, toggle) {
      this.selected = this.dates[i];
      this.selectedIdx = i;
      toggle({ ref: e.target });
    },
  },
};
</script>

<style lang="scss" scoped>
[dir="rtl"] * {
  ::v-deep {
    .vc-nav-header {
      .vc-nav-title {
        direction: rtl;
      }
    }
  }
}

::v-deep {
  .vc-day {
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: 50% 50%;
  }

  .vc-nav-header {
    direction: ltr;
  }
}
</style>
