<template>
  <div>
    <div class="dashboard-content-wrapper">
      <div class="dashboard-block dashboard-donations">
        <div class="dashboard-donations__header">
          <h2 class="dashboard-block__title">{{ $t("dashboard.title_donation") }}</h2>
          <div class="btn-wrapper">
            <div v-if="!loadingCampaign">
              <donation-add-modal
                :campaignid="campaignid"
                :ccy="selectedCampaign.data.attributes.currency_code"
              ></donation-add-modal>
              <modal-import
                @get-donors-list="getNewPageDonation"
                :campaignid="campaignid"
                ref="importList"
              ></modal-import>
            </div>
            <b-dropdown
              id="import"
              right
              variant="-sm bttn--orange"
              :text="$t('dashboard.import_donation_button_title')"
            >
              <b-dropdown-item @click="openModalImportManual" class="manual-hidden">
                {{ $t("dashboard.import_manual_button_title") }}
              </b-dropdown-item>
              <b-dropdown-item @click="openModalImportList">
                {{ $t("dashboard.import_button_title") }}
              </b-dropdown-item>
            </b-dropdown>

            <b-dropdown
              id="export-action"
              right
              variant="-sm bttn--orange"
              :text="$t('dashboard.export_donation_button_title')"
            >
              <b-dropdown-item @click="openModalExportDonations">
                {{ $t("dashboard.export_button_title") }}
              </b-dropdown-item>
              <b-dropdown-item @click="openModalExportTeams">
                {{ $t("dashboard.export_button_teams_title") }}
              </b-dropdown-item>
              <b-dropdown-item @click="openModalExportReceipt">
                {{ $t("dashboard.export_button_receipt_title") }}
              </b-dropdown-item>
            </b-dropdown>

            <export-teams ref="exportTeams"> </export-teams>

            <modal-export
              ref="exportDonations"
              :campaignid="campaignid"
              :customLabel="customLabel"
              :gateways="gateways"
              :canSeeInputSearch="canSeeInputSearch"
              :multiselectTeam="multiselectTeam"
              :teamList="teamList"
              :filters="{ searchQ, filterStatus, recurringFilter, gatewayFilter }"
            >
            </modal-export>
            <export-receipts ref="exportReceipts" :campaignid="campaignid"></export-receipts>

            <modal-import-manual ref="importManual" :campaignid="campaignid"></modal-import-manual>
            <div class="btn-item">
              <modal-integration-export :location="'donation'"></modal-integration-export>
            </div>
          </div>
        </div>

        <div
          v-if="donationsPremoderation.value"
          class="dashboard-donations__moderation"
          :class="!moderation ? '' : 'moderation'"
        >
          <button
            class="bttn bttn--sm"
            :class="moderation ? 'bttn--inverted-blue' : 'bttn--blue'"
            @click="moderationOff"
          >
            All
          </button>

          <button
            class="bttn bttn--sm"
            :class="!moderation ? 'bttn--inverted-blue' : 'bttn--blue'"
            @click="moderationOn"
          >
            Pending Moderation ({{ pendingDonationCounter }})
          </button>
        </div>

        <div class="dashboard-donations__filters" v-if="!moderation">
          <div class="filter-block dashboard-donation__search" v-if="canSeeInputSearch">
            <div class="search">
              <svg viewBox="0 0 17 17">
                <g fill="#107598" fill-rule="nonzero">
                  <path
                    d="M11.22 3.29c.2.2.38.41.55.64l-.12-.16c.33.44.61.93.83 1.44l-.08-.19a6.76 6.76 0 0 1 .42 1.48 6.92 6.92 0 0 1 .03 1.52 6.76 6.76 0 0 1-.45 1.69l.08-.2c-.22.52-.5 1-.83 1.45l.12-.16c-.34.45-.74.85-1.18 1.2l.16-.12c-.43.34-.91.62-1.42.84l.2-.08a6.41 6.41 0 0 1-1.45.43 6.52 6.52 0 0 1-1.5.03 6.41 6.41 0 0 1-1.65-.46l.19.08c-.5-.22-.98-.5-1.42-.84l.16.12a6.67 6.67 0 0 1-1.18-1.2l.12.16a6.78 6.78 0 0 1-.83-1.45l.08.2a6.76 6.76 0 0 1-.42-1.48 6.92 6.92 0 0 1-.03-1.52c.08-.58.23-1.15.45-1.69l-.08.2c.22-.52.5-1 .83-1.45l-.12.16c.34-.45.74-.86 1.18-1.2l-.16.12c.44-.34.91-.62 1.42-.84l-.2.08a6.41 6.41 0 0 1 1.45-.43 6.52 6.52 0 0 1 1.5-.03c.57.08 1.12.23 1.65.46L9.33 2a6.43 6.43 0 0 1 1.89 1.28c.3.3.83.32 1.11 0a.83.83 0 0 0 0-1.14A7.13 7.13 0 0 0 .92 3.77a7.46 7.46 0 0 0 .85 8.43 7.16 7.16 0 0 0 9.02 1.58 7.53 7.53 0 0 0 2.48-2.38 7.46 7.46 0 0 0-.94-9.25c-.29-.3-.82-.32-1.11 0a.83.83 0 0 0 0 1.14z"
                  />
                  <path
                    d="M11.28 12.42l1.47 1.47 2.34 2.34.54.54c.3.3.85.32 1.14 0a.82.82 0 0 0 0-1.14l-1.47-1.47-2.34-2.34-.54-.54c-.3-.3-.85-.32-1.14 0a.82.82 0 0 0 0 1.14z"
                  />
                </g>
              </svg>
              <input
                class="search__input"
                type="text"
                :placeholder="$t('dashboard.select_filter_placeholder_input')"
                v-model="searchQ"
                value=""
              />
            </div>
          </div>

          <div class="filter-block dashboard-donation__search" v-if="canSeeInputSearch">
            <div class="search">
              <svg viewBox="0 0 17 17">
                <g fill="#107598" fill-rule="nonzero">
                  <path
                    d="M11.22 3.29c.2.2.38.41.55.64l-.12-.16c.33.44.61.93.83 1.44l-.08-.19a6.76 6.76 0 0 1 .42 1.48 6.92 6.92 0 0 1 .03 1.52 6.76 6.76 0 0 1-.45 1.69l.08-.2c-.22.52-.5 1-.83 1.45l.12-.16c-.34.45-.74.85-1.18 1.2l.16-.12c-.43.34-.91.62-1.42.84l.2-.08a6.41 6.41 0 0 1-1.45.43 6.52 6.52 0 0 1-1.5.03 6.41 6.41 0 0 1-1.65-.46l.19.08c-.5-.22-.98-.5-1.42-.84l.16.12a6.67 6.67 0 0 1-1.18-1.2l.12.16a6.78 6.78 0 0 1-.83-1.45l.08.2a6.76 6.76 0 0 1-.42-1.48 6.92 6.92 0 0 1-.03-1.52c.08-.58.23-1.15.45-1.69l-.08.2c.22-.52.5-1 .83-1.45l-.12.16c.34-.45.74-.86 1.18-1.2l-.16.12c.44-.34.91-.62 1.42-.84l-.2.08a6.41 6.41 0 0 1 1.45-.43 6.52 6.52 0 0 1 1.5-.03c.57.08 1.12.23 1.65.46L9.33 2a6.43 6.43 0 0 1 1.89 1.28c.3.3.83.32 1.11 0a.83.83 0 0 0 0-1.14A7.13 7.13 0 0 0 .92 3.77a7.46 7.46 0 0 0 .85 8.43 7.16 7.16 0 0 0 9.02 1.58 7.53 7.53 0 0 0 2.48-2.38 7.46 7.46 0 0 0-.94-9.25c-.29-.3-.82-.32-1.11 0a.83.83 0 0 0 0 1.14z"
                  />
                  <path
                    d="M11.28 12.42l1.47 1.47 2.34 2.34.54.54c.3.3.85.32 1.14 0a.82.82 0 0 0 0-1.14l-1.47-1.47-2.34-2.34-.54-.54c-.3-.3-.85-.32-1.14 0a.82.82 0 0 0 0 1.14z"
                  />
                </g>
              </svg>
              <multiselect
                v-model="multiselectTeam"
                :options="teamList"
                :custom-label="customLabel"
                track-by="text"
                :placeholder="$t('dashboard.select_teams')"
                :multiple="false"
              >
              </multiselect>
            </div>
          </div>

          <div class="filter-block dashboard-donation__status">
            <div class="select">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>
              <select class="select__select" v-model="gatewayFilter">
                <option value="">{{ $t("dashboard.select_filter_please_select_gateway") }}</option>
                <option
                  v-for="gateway in gateways"
                  :key="gateway.id"
                  :value="gateway.attributes.value"
                >
                  {{ $t("dashboard.select_filter_" + gateway.attributes.text) }}
                </option>
              </select>
            </div>
          </div>

          <div class="filter-block dashboard-donation__type">
            <div class="select">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>
              <select class="select__select" v-model="filterStatus">
                <option value="">{{ $t("dashboard.select_filter_please_select_status") }}</option>
                <option value="authorized">{{ $t("dashboard.select_filter_authorized") }}</option>
                <option value="canceled">{{ $t("dashboard.select_filter_canceled") }}</option>
                <option value="processed">{{ $t("dashboard.select_filter_processed") }}</option>
                <option value="pending">{{ $t("dashboard.select_filter_pending") }}</option>
              </select>
            </div>
          </div>

          <div class="filter-block dashboard-donation__recurring">
            <div class="select">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>
              <select class="select__select" v-model="recurringFilter">
                <option value="-1">
                  {{ $t("dashboard.recurring_filter_recurring_and_onetime") }}
                </option>
                <option value="1">{{ $t("dashboard.recurring_filter_recurring_only") }}</option>
                <option value="0">{{ $t("dashboard.recurring_filter_onetime_only") }}</option>
              </select>
            </div>
          </div>
        </div>

        <div v-if="pdfLinkMeta && multiselectTeam" class="pdf-button-donations">
          <a
            :href="`${apiUrl}/api/v1/campaign/${campaignId}/pdfreport/team_donor_list?team_id=${multiselectTeam.value}`"
            target="_blank"
          >
            {{ $t("dashboard.donations_pdf_link_open") }}
          </a>
        </div>

        <div class="dashboard-donations__th" v-if="!moderation">
          <div class="th-item">#</div>
          <div class="th-item clickable th-item--name" @click="setSort('billing_name')">
            {{ $t("dashboard.donation_name") }} <i :class="classSortBy('billing_name')"></i>
          </div>
          <div class="th-item clickable th-item--name" @click="setSort('display_name')">
            {{ $t("dashboard.donation_display_name") }} <i :class="classSortBy('display_name')"></i>
          </div>
          <div class="th-item clickable" @click="setSort('bank_name')">
            {{ $t("dashboard.donation_methods") }} <i :class="classSortBy('bank_name')"></i>
          </div>
          <div class="th-item clickable" @click="setSort('charged_amount')">
            {{ $t("dashboard.donation_amount_charged") }}
            <i :class="classSortBy('charged_amount')"></i>
          </div>
          <div class="th-item clickable" @click="setSort('effective_amount')">
            {{ $t("dashboard.donation_amount_effective") }}
            <i :class="classSortBy('effective_amount')"></i>
          </div>
          <div class="th-item clickable th-item--15" @click="setSort('email')">
            {{ $t("dashboard.donation_email_phone") }} <i :class="classSortBy('email')"></i>
          </div>
          <div class="th-item clickable" @click="setSort('date')">
            {{ $t("dashboard.donation_date") }} <i :class="classSortBy('date')"></i>
          </div>
          <div class="th-item">{{ $t("dashboard.donation_status") }}</div>
        </div>

        <div class="dashboard-donations__th dashboard-donations__th--modeartion" v-if="moderation">
          <div class="th-item">#</div>
          <!--<div class="th-item th-item&#45;&#45;name">{{ $t('dashboard.donation_name') }}</div>-->
          <div class="th-item th-item--20">{{ $t("dashboard.donation_display_name") }}</div>
          <!--<div class="th-item">{{ $t('dashboard.donation_methods') }}</div>-->
          <!--<div class="th-item">{{ $t('dashboard.donation_amount_charged') }}</div>-->
          <!--<div class="th-item">{{ $t('dashboard.donation_amount_effective') }}</div>-->
          <div class="th-item th-item--40">{{ $t("dashboard.donation_dedication") }}</div>
          <!--<div class="th-item">{{ $t('dashboard.donation_date') }}</div>-->
          <!--<div class="th-item">{{ $t('dashboard.donation_status') }}</div>-->
        </div>
      </div>

      <div v-if="!loadingDobnations">
        <div v-if="!moderation">
          <dashboard-donation-unit
            @edit-donate="editDonate"
            @edit-receipt="editReceipt"
            v-for="donation in donations"
            :key="donation.id"
            :donation="donation"
            :premoderation="donationsPremoderation.value"
          ></dashboard-donation-unit>

          <div v-if="donations.length == 0" class="empty">
            <p>
              {{ $t("dashboard.donations_empty") }}
            </p>
          </div>
          <div class="pagination">
            <b-pagination
              size="md"
              v-if="donorsCount > 50"
              :total-rows="donorsCount"
              v-model="currentPage"
              :per-page="50"
            >
            </b-pagination>
          </div>
        </div>
        <div v-if="moderation">
          <dashboard-moderation-unit
            v-for="donation in pendingDonations"
            :key="donation.id"
            :campaignid="campaignid"
            @edit-donate="editDonate"
            :donation="donation"
            :premoderation="donationsPremoderation.value"
          ></dashboard-moderation-unit>

          <div class="empty" v-if="pendingDonations.length === 0">
            <p>
              {{ $t("dashboard.donations_empty") }}
            </p>
          </div>
          <div class="pagination">
            <b-pagination
              size="md"
              v-if="donorsCount > 50"
              :total-rows="donorsCount"
              v-model="currentPagePendingDonations"
              :per-page="50"
            >
            </b-pagination>
          </div>
        </div>
      </div>

      <div v-if="loadingDobnations">
        <div class="loadings">
          <LdsSpinner />
        </div>
      </div>
    </div>

    <donation-edit
      :campaignid="campaignid"
      :currentpage="currentPage"
      ref="editDonateModal"
    ></donation-edit>

    <modal-receipt
      :campaignid="campaignid"
      :currentpage="currentPage"
      ref="editReceiptModal"
    ></modal-receipt>
  </div>
</template>

<script>
import dashboardDonationUnit from "./dashboardDonationUnit.vue";
import dashboardModerationUnit from "./dashboardModerationUnit.vue";
import donationAddModal from "./donationAddModal.vue";
import donationUnitModal from "./donationUnitModal.vue";
import dashboardExportDonors from "./dashboardExportDonors.vue";
import dashboardImportDonors from "./dashboardImportDonors.vue";
import donationAddManualModal from "./donationAddManualModal.vue";
import donationReceiptModal from "./donationReceiptModal.vue";
import integrationExportModal from "../../../integration/subcomponents/integration-export-btn.vue";
import exportTeams from "./export-teams-modal.vue";
import exportReceipts from "./export-receipts.vue";
import debounce from "lodash/debounce";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";
import { publicApiHost } from "@/constants/urls.js";

export default {
  props: ["campaignid"],

  data: function () {
    return {
      apiUrl: publicApiHost,

      currentPage: 1,

      currentPagePendingDonations: 1,

      searchQ: "",

      filterStatus: "",

      gatewayFilter: "",

      recurringFilter: "-1",

      sortBy: "",

      setIntervalData: null,

      moderation: false,

      mode: null,

      multiselectTeam: null,
    };
  },

  directives: {
    debounce,
  },

  watch: {
    currentPage: function () {
      this.getNewPageDonation();
    },

    loadingCampaign: function () {
      if (!this.loadingCampaign && this.selectedCampaign.data) {
        this.mode = this.selectedCampaign.data.attributes.mode;
        this.startUpdateDonationProcess();
      }
    },

    currentPagePendingDonations: function () {
      this.getNewPagePendingDonations();
    },

    searchQ: debounce(function () {
      if (this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.getNewPageDonation();
      }
    }, 500),

    filterStatus: function () {
      if (this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.getNewPageDonation();
      }
    },

    recurringFilter: function () {
      if (this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.getNewPageDonation();
      }
    },

    sortBy: function () {
      if (this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.getNewPageDonation();
      }
    },

    bluredTab: function () {
      if (!this.bluredTab) {
        this.updateCampaignDonation();
      }
    },

    gatewayFilter: function () {
      if (this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.getNewPageDonation();
      }
    },

    multiselectTeam: function () {
      if (this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.getNewPageDonation();
      }
    },
  },

  computed: mapState({
    pendingDonationCounter: (state) => {
      return parseInt(state.donation.pendingDonationCounter);
    },

    canSeeInputSearch: (state) => {
      return state.account.access.CanPerformDonationSearch;
    },

    pendingDonations: (state) => {
      return state.donation.pendingDonations;
    },

    teamList() {
      let resultArr = [];

      this.$store.getters.getAllTeamsList.forEach((element) => {
        resultArr.push({
          value: element.id,
          text: element.attributes.name,
        });
      });

      return resultArr;
    },

    donationsPremoderation: function () {
      return this.$store.getters.getDonationsPremoderationCampaigns(this.campaignid);
    },

    integrations: (state) => {
      return state.integrations.integrations.filter(function (i) {
        return i.attributes.status === 1;
      });
    },

    donations: (state) => {
      return state.donation.donations;
    },

    loadingCampaign: (state) => {
      return state.dashboard.loadingCampaignDashboard;
    },

    loadingDobnations: (state) => {
      return state.donation.loadingDonation;
    },

    selectedCampaign: (state) => {
      return state.dashboard.campaignDashboard;
    },

    campaignId: (state) => state.dashboard.selectedCampaign,

    donorsCount: (state) => {
      return parseInt(state.donation.donationTotal);
    },

    gateways: (state) => {
      return state.donation.gateways;
    },

    bluredTab: (state) => {
      return state.account.blured;
    },

    pdfLinkMeta(state) {
      const { campaigns } = state.campaigns;
      const { includedMedia } = state.campaigns;
      const campaign = campaigns.filter((el) => el.id === this.campaignid)[0];
      const { data } = campaign.relationships.campaign_meta;
      const metas = [];
      data.forEach((el) => {
        const meta = includedMedia.filter(
          (metaEl) => el.id === metaEl.id && el.type === metaEl.type
        );
        if (meta.length > 0) {
          metas.push(meta[0]);
        }
      });
      const pdfLinkMeta = metas.filter(
        (el) => el.attributes.name === "orgarea_show_pdf_link_team_donors"
      );
      const pdfLinkData =
        pdfLinkMeta.length > 0 ? JSON.parse(pdfLinkMeta[0].attributes.data) : false;
      if (pdfLinkData) {
        const value = pdfLinkData.value;
        return value;
      }
      return false;
    },
  }),

  methods: {
    setSort: function (sortBy) {
      if (this.sortBy != sortBy && this.sortBy != `-${sortBy}`) {
        this.sortBy = sortBy;
      } else if (this.sortBy == sortBy) {
        this.sortBy = `-${sortBy}`;
      } else {
        this.sortBy = "";
      }
    },

    classSortBy: function (sortBy) {
      if (this.sortBy == sortBy) {
        return "fa fa-angle-up";
      } else if (this.sortBy == "-" + sortBy) {
        return "fa fa-angle-down";
      }
    },

    customLabel({ value, text }) {
      return `[${value}] ${text}`;
    },

    getNewPageDonation: function () {
      // let recurringFilterValue = this.recurringFilter !== "" ? this.recurringFilter : -1;
      let setting = `?extend=donation_receipt_id&page=${this.currentPage}&limit=50${
        this.gatewayFilter.length > 0 ? "&gateway=" + this.gatewayFilter : ""
      }${this.filterStatus.length > 0 ? "&status=" + this.filterStatus : ""}${
        "&recurring=" + this.recurringFilter
      }${this.searchQ.length > 0 ? "&q=" + this.searchQ : ""}${
        this.sortBy.length > 0 ? "&sort=" + this.sortBy : ""
      }${this.multiselectTeam ? "&team_id=" + this.multiselectTeam.value : ""}`;
      this.$emit("load-doination", setting);
    },

    getNewPagePendingDonations: function () {
      let setting = `?moderation_status=0&extend=donation_receipt_id&page=${
        this.currentPagePendingDonations
      }&limit=50${this.gatewayFilter.length > 0 ? "&gateway=" + this.gatewayFilter : ""}${
        this.filterStatus.length > 0 ? "&status=" + this.filterStatus : ""
      }${"&recurring=" + this.recurringFilter}${
        this.searchQ.length > 0 ? "&q=" + this.searchQ : ""
      }${this.sortBy.length > 0 ? "&sort=" + this.sortBy : ""}${
        this.multiselectTeam ? "&team_id=" + this.multiselectTeam.value : ""
      }`;
      this.$emit("load-pending-donations", setting);
    },

    updateCampaignDonation: function () {
      let setting = `?extend=donation_receipt_id&page=${this.currentPage}&limit=50${
        this.gatewayFilter.length > 0 ? "&gateway=" + this.gatewayFilter : ""
      }${this.filterStatus.length > 0 ? "&status=" + this.filterStatus : ""}${
        "&recurring=" + this.recurringFilter
      }${this.searchQ.length > 0 ? "&q=" + this.searchQ : ""}${
        this.sortBy.length > 0 ? "&sort=" + this.sortBy : ""
      }${this.multiselectTeam ? "&team_id=" + this.multiselectTeam.value : ""}`;

      let params = {
        id: this.campaignid,
        setting: setting,
      };
      this.$store.dispatch("updateCampaignDonation", params);
    },

    editDonate: function (donate, premoderation) {
      this.$refs.editDonateModal.openEditModal(donate, premoderation);
    },

    // checkIntegration: function() {
    //  this.$store.dispatch("getIntegrationsList");
    // },

    editReceipt(donate) {
      this.$refs.editReceiptModal.openEditModal(donate);
    },

    openModalImportManual() {
      this.$refs.importManual.openModal();
    },

    openModalExportTeams() {
      this.$refs.exportTeams.openModal(this.campaignid);
    },

    openModalImportList() {
      this.$refs.importList.openModal();
    },

    openModalExportReceipt() {
      this.$refs.exportReceipts.openModal();
    },

    moderationOn() {
      this.currentPage = 1;
      this.moderation = true;
      this.getNewPagePendingDonations();
    },

    moderationOff() {
      this.currentPage = 1;
      this.moderation = false;
      this.getNewPageDonation();
    },

    openModalExportDonations() {
      this.$refs.exportDonations.openModal();
    },

    startUpdateDonationProcess() {
      if (this.mode == 1 || this.mode == 2) {
        this.setIntervalData = setInterval(() => {
          if (navigator.onLine && !this.bluredTab) {
            this.updateCampaignDonation();
          }
        }, 30000);
      }
    },
  },

  components: {
    exportTeams,
    dashboardDonationUnit,
    donationAddModal,
    exportReceipts,
    Multiselect,
    "donation-edit": donationUnitModal,
    "modal-export": dashboardExportDonors,
    "modal-import": dashboardImportDonors,
    "modal-import-manual": donationAddManualModal,
    "modal-integration-export": integrationExportModal,
    "modal-receipt": donationReceiptModal,
    "dashboard-moderation-unit": dashboardModerationUnit,
    LdsSpinner,
  },

  destroyed: function () {
    if (this.mode == 1 || this.mode == 2) {
      clearInterval(this.setIntervalData);
    }
  },

  created() {
    this.$store.dispatch("getTeamsByCampaignId", [this.campaignid]);
  },
};
</script>

<style lang="scss" scoped>
#import {
  @media only screen and (max-width: 1359px) {
    display: none;
  }
}
::v-deep .multiselect__tags {
  height: 48px;
  width: 100%;
  padding-left: 45px;
  border: 1px solid #88bacc;
  border-radius: 24px;
  padding: 0;
  padding-left: 30px;
}
::v-deep .multiselect__single,
::v-deep .multiselect__placeholder {
  margin: 0;
  line-height: 48px;
  background: #0000;
}
::v-deep .multiselect__select {
  height: 48px;
}
::v-deep .multiselect__input,
::v-deep .multiselect__single,
::v-deep .multiselect__placeholder {
  height: 48px;
  margin: 0;
  line-height: 48px;
  background: transparent;
  color: initial;
  padding: 0;
  font-family: inherit;
  font-size: 16px;
}
.donation-loading {
  display: flex;
  min-height: 100px;
  align-items: center;
  justify-content: center;
}
/*.btn-item {*/
/*margin-left: 10px;*/
/*}*/
.pagination {
  margin-top: 20px;
}

.clickable {
  cursor: pointer;
  i {
    padding-left: 3px;
  }
}

.pdf-button-donations {
  background-color: #fff;
  padding-bottom: 20px;
  a {
    display: block;
    width: 100%;
    padding: 10px 25px;
    background-color: #b4c5cd52;
    color: #127598;
    font-family: "VitoExtended-Bold";
  }
}
</style>
