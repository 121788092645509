<template>
  <div class="form-group form-unit form-unit--addon">
    <label class="form-unit__label form-unit__label--upper-sm">
      {{ $t("edit_campaign.generalinfo_short_link") }}

      <Tooltip :title="urlHtml" v-if="disabled">
        <template #icon>
          <CampaignCopyButton
            :id="id"
            :value="url"
            @copy-link="
              $_notificationsMixin_makeToast(
                'Action',
                $t('edit_campaign.link_copied_to_clipboard', 'Campaign shortlink copied')
              )
            "
            size="small"
          />
        </template>
      </Tooltip>
    </label>
    <div class="form-unit__addon-wrap">
      <div
        class="form-unit__addon-icon form-unit__addon-icon--wide"
        :class="{ disabled: disabled }"
      >
        charidy.com/
      </div>
      <input
        name="shortLink"
        type="text"
        @input="onInput($event.target.value)"
        :required="this.notValidLink"
        class="form-control form-unit__input"
        :placeholder="$t('edit_campaign.generalinfo_short_link')"
        :value="value"
        :disabled="disabled"
      />
      <template v-if="valid !== null">
        <div class="link-error" v-if="this.validationResult && this.unexpectedError">
          {{ this.unexpectedError }}
        </div>
        <div class="link-error" v-else-if="this.notValidLink">
          {{ $t("edit_campaign.generalinfo_short_link_allowe") }}
        </div>
        <div class="link-success" v-else>
          {{ $t("edit_campaign.generalinfo_short_link_awailable") }}
        </div>
      </template>
      <div class="link-validate" v-if="loading">
        {{ $t("edit_campaign.generalinfo_short_link_validating") }}
        <b-spinner
          small
          variant="warning"
          :label="$t('edit_campaign.generalinfo_short_link_validating')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { nanoid } from "nanoid";
import { appHost } from "@/constants/urls.js";
import Tooltip from "@/common-components/tooltip.vue";
import CampaignCopyButton from "@/views/campaigns/campaign-edit-vue/subcomponents/campaignCopyButton.vue";
import { notificationsMixin } from "@/mixins";
import { ValidateShortlink } from "@/constants/providers";

const orgId = localStorage.getItem("orgId");

export default {
  components: { Tooltip, CampaignCopyButton },
  mixins: [notificationsMixin],
  inject: { ValidateShortlink },
  props: {
    value: {
      type: String,
      default: "",
    },

    config: { type: Object, default: () => ({ type: "org", orgId }) },

    valid: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      validationResult: true,
      unexpectedError: null,
      loading: false,
      id: `id${nanoid()}`,
    };
  },
  computed: {
    url() {
      return `${appHost}/${this.value}`;
    },
    urlHtml() {
      return `<a href="${this.url}" target="_blank">${this.url}</a>`;
    },
    notValidLink() {
      if (this.valid === null) {
        return !this.validationResult;
      }
      return !this.validationResult || !this.valid;
    },
  },
  methods: {
    onInput(e) {
      this.loading = true;
      this.$emit("input", e);
      this.$emit("validation", true);
      this.validate(e);
    },
    validate: debounce(function (e) {
      this.ValidateShortlink(e, this.config)
        .then(([valid]) => {
          this.validationResult = valid;
          this.$emit("validation-result", valid);
        })
        .catch((e) => {
          this.unexpectedError = this.$t(
            "edit_campaign.generalinfo_short_link_unexpectederror",
            "Something wrong happend with validation your shortlink. Please try later"
          );
          this.validationResult = true;
          this.$emit("validation-result", true);
          this.$_notificationsMixin_handleCatch(e);
        })
        .finally(() => {
          this.loading = false;
          this.$emit("validation", false);
        });
    }, 1000),
  },
};
</script>
