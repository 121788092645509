<template>
  <div class="action-block">
    <!--<b-btn v-b-modal.exportDonation variant="bttn bttn&#45;&#45;sm">{{ $t('dashboard.export_button_title') }}</b-btn>-->
    <button
      style="display: none"
      :disabled="!hasAccess"
      class="bttn bttn--sm bttn--orange"
      @click="openModal"
    >
      {{ $t("dashboard.export_button_title") }}
    </button>

    <!-- Modal Component -->
    <b-modal id="exportDonation" size="lg" @hidden="clearData">
      <div slot="modal-header">
        <h2 class="h1-like">{{ $t("dashboard.export_modal_title") }}</h2>
        <div class="modal-header__hide" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button
          :class="
            !exportLink && success
              ? 'bttn bttn--lg bttn--orange el-right'
              : 'bttn bttn--lg bttn--blue'
          "
          @click.prevent="hideModal"
        >
          {{ $t("dashboard.hide_modal") }}
        </button>
        <button
          @click="exportList"
          :class="
            loadingExportLink ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'
          "
          type="button"
          v-if="!success"
          :disabled="loadingExportLink"
        >
          {{ $t("dashboard.export_export_in_modal") }}
        </button>
        <a :href="exportLink" v-if="exportLink && success" class="bttn bttn--lg bttn--orange">{{
          $t("dashboard.export_dowload_export_file")
        }}</a>
      </div>

      <form id="export-donation">
        <div class="info-content success-desc" v-if="exportLink && success">
          <p>
            {{ $t("dashboard.export_success_have_button") }}
          </p>
        </div>

        <div class="error info-content" v-if="!exportLink && success">
          <p>
            {{ $t("dashboard.export_check_email") }}
          </p>
        </div>

        <div class="loading-link-wrap" v-if="!success">
          <div v-if="!loadingExportLink">
            <div class="info-content">
              <p>
                {{ $t("dashboard.export_export_description") }}
              </p>
            </div>

            <div class="error info-content" v-if="needEmail">
              <p>
                {{ $t("dashboard.export_please_enter_email") }}
              </p>
            </div>

            <!--<h4>{{ $t('dashboard.export_email') }}</h4>-->

            <div class="form-group">
              <label>{{ $t("dashboard.export_email") }}</label>
              <input type="email" name="email" value="" v-model="email" class="form-control" />
            </div>
          </div>

          <div class="info-content loading-link" v-if="loadingExportLink">
            <p>
              {{ $t("dashboard.export_loading_link") }}
            </p>
          </div>
        </div>
      </form>

      <div class="error-box">
        <error-message :response.sync="errorResponse"></error-message>
      </div>
    </b-modal>
  </div>
</template>

<script>
import errorList from "@/components/error-message.vue";
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";

export default {
  props: ["campaignid", "filters"],

  data() {
    return {
      errorResponse: null,
      email: "",
      exportLink: "",
      loadingExportLink: false,
      needEmail: false,
      success: false,
    };
  },

  components: {
    errorMessage: errorList,
  },

  computed: mapState({
    hasAccess: (state) => {
      return state.account.access.CanExportAllOrgDonationList;
    },
  }),

  methods: {
    clearData: function () {
      this.email = "";
      (this.errorResponse = null), (this.exportLink = "");
      this.loadingExportLink = false;
      this.needEmail = false;
      this.success = false;
    },

    openModal() {
      this.$root.$emit("bv::show::modal", "exportDonation");
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "exportDonation");
    },

    exportList: function () {
      this.loadingExportLink = true;

      const setting = {
        campaign_id: this.filters.campaignId?.id || undefined,
        gateway: this.filters.gatewayFilter?.id || undefined,
        status: this.filters.filterStatus?.id || undefined,
        q: this.filters.searchQ || undefined,
        format: "xlsx",
        email: this.email,
      };

      let orgId = localStorage.getItem("orgId");

      axios
        .post(urls.exportDonorsOrg.replace(":orgId", orgId), null, { params: setting })
        .then((response) => {
          this.loadingExportLink = false;
          this.exportLink = response.data.link;
          this.success = true;
        })
        .catch((e) => {
          console.log(e.response);
          this.errorResponse = e.response;
          this.needEmail = true;
          this.loadingExportLink = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#export-donation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.loading-link-wrap {
  width: 100%;
  .form-group {
    @media only screen and (min-width: 768px) {
      width: 50%;
    }
  }
}

.loading-link,
.success-desc {
  text-align: center;
  width: 100%;
  p {
    width: 100%;
    text-align: center;
  }
}

.export-list {
  margin-top: 20px;
}

/*.entities-data {*/
/*&__labels {*/
/*color: gray;*/
/*text-transform: capitalize;*/
/*}*/
/*&__label {*/
/*color: gray;*/
/*text-transform: capitalize;*/
/*line-height: 1;*/
/*&--required {*/
/*padding-right: 10px;*/
/*position: relative;*/
/*&:after {*/
/*content: '*';*/
/*font-size: 16px;*/
/*font-weight: 600;*/
/*line-height: 1;*/
/*color: red;*/
/*position: absolute;*/
/*top: 0;*/
/*right: 0;*/
/*}*/
/*}*/
/*}*/
/*}*/
/*.entities-add {*/
/*display: inline-flex;*/
/*align-items: center;*/
/*height: 24px;*/
/*padding: 0 15px;*/
/*border-radius: 12px;*/
/*font-size: 12px;*/
/*font-weight: 400;*/
/*text-transform: uppercase;*/
/*line-height: 1;*/
/*color: #20434e;*/
/*background-color: #ffffff;*/
/*}*/
/*.btn-oval {*/
/*width: auto;*/
/*height: 45px;*/
/*padding: 0 23px;*/
/*display: inline-flex;*/
/*align-items: center;*/
/*justify-content: center;*/
/*border-radius: 23px;*/
/*border: none;*/
/*font-family: 'ProximaNova-Bold', sans-serif;*/
/*font-size: 16px;*/
/*color: #ffffff;*/
/*background-color: #cc6d4c;*/
/*transition: all .2s ease-in-out;*/
/*cursor: pointer;*/
/*&:hover,*/
/*&:active {*/
/*background-color: darken(#cc6d4c, 5%);*/
/*box-shadow: none;*/
/*outline: 0;*/
/*}*/
/*&:focus {*/
/*background-color: #cc6d4c;*/
/*box-shadow: none;*/
/*outline: 0;*/
/*}*/
/*&--cancel {*/
/*background-color: gray;*/
/*&:hover,*/
/*&:active {*/
/*background-color: darken(gray, 5%);*/
/*}*/
/*&:focus {*/
/*background-color: gray;*/
/*}*/
/*}*/
/*}*/
</style>
