<template>
  <MediaStorageCropperProvider>
    <template #default="{ cropperProps }">
      <img
        class="preview-image cropperPreviewImage"
        :style="{ ...cropperProps(tag).previewStyles }"
        :src="image"
        alt="previewImageSrc"
      />
    </template>
  </MediaStorageCropperProvider>
</template>

<script>
import MediaStorageCropperProvider from "./MediaStorageCropperProvider.vue";
export default {
  props: {
    image: {
      type: String,
      default: "",
    },
    tag: {
      type: String,
      default: "",
    },
  },
  components: {
    MediaStorageCropperProvider,
  },
  computed: {},
};
</script>

<style lang="scss">
.preview-image {
  width: 100%;
}
.cropperPreviewImage {
  --border-radius: unset;
  border-radius: var(--border-radius);
}
</style>
