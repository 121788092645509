<template>
  <b-overlay
    overlay-tag="b-container"
    fluid
    class="shadow-sm bg-white rounded border border-light py-4 mb-4"
    :show="loading"
  >
    <b-row>
      <b-col class="d-flex flex-column align-items-center stats-col">
        <span class="text">
          {{ $t("donor_dashboard.teams_stats_raised", "Total raised") }}
        </span>
        <span class="num">{{ currencySign }}{{ raised }}</span>
      </b-col>
      <b-col class="d-flex flex-column align-items-center stats-col">
        <span class="text">
          {{ $t("donor_dashboard.teams_stats_goal", "Total goal") }}
        </span>
        <span class="num">{{ currencySign }}{{ goal }}</span>
      </b-col>
      <b-col class="d-flex flex-column align-items-center stats-col">
        <span class="text">
          {{ $t("donor_dashboard.teams_stats_remaining", "Total remaining") }}
        </span>
        <span class="num">{{ currencySign }}{{ remaining }}</span>
      </b-col>
      <b-col class="d-flex flex-column align-items-center stats-col">
        <span class="text">
          {{ $t("donor_dashboard.teams_stats_donors", "Total donors") }}
        </span>
        <span class="num">{{ donors }}</span>
      </b-col>
      <b-col class="d-flex flex-column align-items-center stats-col">
        <span class="text">
          {{ $t("donor_dashboard.teams_stats_progress", "Progress") }}
        </span>
        <span class="num">{{ progress }}%</span>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { currenciesMap } from "@/constants/currencies";
import { mapActions, mapState } from "vuex";

export default {
  mounted() {
    this.getDonorTeamsStats();
  },
  computed: {
    ...mapState({
      stats: (state) => state.donorTeams.stats,
      loading: (state) => state.donorTeams.loading.getDonorTeamsStats,
    }),

    currencySign() {
      return currenciesMap[this.stats.currency_code]?.sign || "";
    },

    goal() {
      return (this.stats.total_goal || 0).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    raised() {
      return (this.stats.total_amount || 0).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    remaining() {
      const goal = this.stats.total_goal || 0;
      const raised = this.stats.total_amount || 0;

      return (goal - raised).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    donors() {
      return this.stats.total_count;
    },

    progress() {
      if (this.goal === 0) {
        return 0;
      }
      const goal = this.stats.total_goal || 0;
      const raised = this.stats.total_amount || 0;

      return ((raised / goal) * 100).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
  methods: {
    ...mapActions({
      getDonorTeamsStats: "donorTeams/getDonorTeamsStats",
    }),
  },
};
</script>

<style lang="scss" scoped>
.stats-col {
  * {
    color: #107598;
  }
  .text {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
  }
  .num {
    font-size: 18px;
  }
}
</style>
