import { render, staticRenderFns } from "./sliderLaptopBig.vue?vue&type=template&id=da06e69c&scoped=true&"
import script from "./sliderLaptopBig.vue?vue&type=script&lang=js&"
export * from "./sliderLaptopBig.vue?vue&type=script&lang=js&"
import style0 from "./sliderLaptopBig.vue?vue&type=style&index=0&id=da06e69c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da06e69c",
  null
  
)

export default component.exports