<template>
  <div class="card">
    <slot name="body"></slot>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  props: {
    card: {
      type: Object,
      default: () => {
        return {
          id: 0,
          name: "No name",
          ending: "0000",
          expire: "1/2020",
          img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/bank-card.png",
          isDefault: false,
          isBankAccount: false,
          state: "Verified",
        };
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";

.card {
  border: 1px solid #e6eaedcc;
  max-width: calc(33.33% - 20px);
  min-width: calc(33.33% - 20px);
  background-color: #fbfdff;
  border-radius: 4px;
  overflow: hidden;
  color: $fd;
  line-height: 1.2;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-top: 10px;
  margin-bottom: 13px;
  h3 {
    font: 18px/1.2 "VitoExtended-Bold";
    text-transform: uppercase;
    margin-bottom: 30px;
  }
}
.card-body {
  padding: 30px 6.5%;
  i {
    margin-right: 25px;
  }
  p {
    margin-bottom: 14px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.card-footer {
  background: #fff;
  text-align: right;
  padding: 10px;
  & > * {
    display: inline-block;
    vertical-align: middle;
  }
  .highlight {
    padding: 6px 16px;
    border-radius: 19px;
    cursor: pointer;
    &.default {
      background: #def9f2;
      color: #33dbb1;
    }
  }
  .btn-del {
    background: none;
    border: none;
    cursor: pointer;
    font-family: "VitoExtended-Light";
    color: #414359;
    & > * {
      display: inline-block;
      vertical-align: middle;
    }
    i {
      margin-right: 3px;
      width: 12px;
      height: 15px;
      background: url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/trash-icon.png")
        center no-repeat;
    }
    &:focus {
      outline: none;
      color: #414359;
      background: none;
    }
  }
}
</style>
