<template>
  <div>
    <div class="dashboard-content-wrapper">
      <div class="dashboard-block dashboard-donations">
        <div class="dashboard-donations__header">
          <div class="row col-12 row-cols-1 row-cols-md-3">
            <div class="col">
              <h2 class="dashboard-block__title">
                {{ $t("dashboard.title_teams", "Team") }}
              </h2>
            </div>

            <div class="col">
              <!-- search -->
              <div class="filter-block dashboard-donation__search" v-if="canSeeInputSearch">
                <div class="search">
                  <svg viewBox="0 0 17 17">
                    <g fill="#107598" fill-rule="nonzero">
                      <path
                        d="M11.22 3.29c.2.2.38.41.55.64l-.12-.16c.33.44.61.93.83 1.44l-.08-.19a6.76 6.76 0 0 1 .42 1.48 6.92 6.92 0 0 1 .03 1.52 6.76 6.76 0 0 1-.45 1.69l.08-.2c-.22.52-.5 1-.83 1.45l.12-.16c-.34.45-.74.85-1.18 1.2l.16-.12c-.43.34-.91.62-1.42.84l.2-.08a6.41 6.41 0 0 1-1.45.43 6.52 6.52 0 0 1-1.5.03 6.41 6.41 0 0 1-1.65-.46l.19.08c-.5-.22-.98-.5-1.42-.84l.16.12a6.67 6.67 0 0 1-1.18-1.2l.12.16a6.78 6.78 0 0 1-.83-1.45l.08.2a6.76 6.76 0 0 1-.42-1.48 6.92 6.92 0 0 1-.03-1.52c.08-.58.23-1.15.45-1.69l-.08.2c.22-.52.5-1 .83-1.45l-.12.16c.34-.45.74-.86 1.18-1.2l-.16.12c.44-.34.91-.62 1.42-.84l-.2.08a6.41 6.41 0 0 1 1.45-.43 6.52 6.52 0 0 1 1.5-.03c.57.08 1.12.23 1.65.46L9.33 2a6.43 6.43 0 0 1 1.89 1.28c.3.3.83.32 1.11 0a.83.83 0 0 0 0-1.14A7.13 7.13 0 0 0 .92 3.77a7.46 7.46 0 0 0 .85 8.43 7.16 7.16 0 0 0 9.02 1.58 7.53 7.53 0 0 0 2.48-2.38 7.46 7.46 0 0 0-.94-9.25c-.29-.3-.82-.32-1.11 0a.83.83 0 0 0 0 1.14z"
                      />
                      <path
                        d="M11.28 12.42l1.47 1.47 2.34 2.34.54.54c.3.3.85.32 1.14 0a.82.82 0 0 0 0-1.14l-1.47-1.47-2.34-2.34-.54-.54c-.3-.3-.85-.32-1.14 0a.82.82 0 0 0 0 1.14z"
                      />
                    </g>
                  </svg>
                  <input
                    v-model="searchQ"
                    class="search__input"
                    type="text"
                    :placeholder="$t('dashboard.select_filter_placeholder_input')"
                    value
                  />
                </div>
              </div>
            </div>
            <div class="col">
              <div class="float-right float-he">
                <b-icon
                  v-b-tooltip.hover.top="
                    this.$t('dashboard.teams_tooltip_refresh_data', 'Refresh data')
                  "
                  @click="getTeams"
                  class="h3"
                  icon="arrow-clockwise"
                  :animation="loading === true ? 'spin' : ''"
                ></b-icon>
              </div>
            </div>
          </div>

          <b-row class="row flex-column col-12">
            <b-col @click="collapseVisible = !collapseVisible">
              <b-icon
                :class="collapseVisible ? 'rotate-180' : ''"
                id="collapse-icon"
                v-b-tooltip.hover.top="this.$t('dashboard.teams_tooltip_statistics', 'Statistics')"
                icon="chevron-down"
              ></b-icon>
              {{ $t("dashboard.team_statistics", "Statistics") }}
            </b-col>
            <b-col>
              <b-collapse v-model="collapseVisible" class="mt-2">
                <teamsOverview
                  :teams="allTeams"
                  :collapse="collapseVisible"
                  @close-collapse="collapseVisible = false"
                ></teamsOverview>
              </b-collapse>
            </b-col>
          </b-row>

          <b-row class="row flex-column col-12">
            <b-col @click="actionsCollapse = !actionsCollapse">
              <div class="">
                <b-icon
                  :class="actionsCollapse ? 'rotate-180' : ''"
                  id="collapse-icon"
                  v-b-tooltip.hover.top="this.$t('dashboard.teams_tooltip_actions', 'Actions')"
                  icon="chevron-down"
                ></b-icon>
                {{ $t("dashboard.team_actions", "Actions") }}
              </div>
            </b-col>

            <b-col>
              <b-collapse v-model="actionsCollapse" class="mt-2">
                <teamsActions
                  @add-team="addTeam"
                  @import-teams="importTeams"
                  @export-teams="exportTeams"
                  @send-email="sendEmail"
                  @send-sms="sendSMS"
                ></teamsActions>
              </b-collapse>
            </b-col>
          </b-row>
        </div>

        <b-row>
          <b-col>
            <div class="pb-1">
              <b-form-checkbox v-model="sortByShown" name="check-hidden" switch>
                {{ $t("dashboard.team_table_hidden_filter", "Show hidden teams") }}
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>

        <b-table
          hover
          small
          head-variant="light"
          :items="teams"
          :fields="fields"
          :sort-by.sync="sort"
          :sort-desc.sync="sortDesc"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          class="text-center team-table"
          primary-key="id"
          style="min-height: 200px"
        >
          <template #cell(id)="data">
            {{ data.item.id }}
          </template>

          <template #cell(name)="data">
            <a
              target="_blank"
              :href="`https://www.charidy.com/${campaignShortLink}/${data.item.attributes.slug}`"
            >
              {{ data.item.attributes.name }}</a
            >
          </template>

          <template #cell(goal)="data">
            {{ campaignCurrencySign }} {{ data.item.attributes.goal }}
          </template>

          <template #cell(total_amount)="data">
            {{ campaignCurrencySign }} {{ data.item.attributes.extend.stats.total_amount }}
          </template>

          <template #cell(total_count)="data">
            {{ data.item.attributes.extend.stats.total_count }}
          </template>

          <template #cell(progress)="data">
            <div class="" v-if="data.item.attributes.goal !== 0">
              <span :class="`text-${data.item.attributes.variant}`">
                {{
                  Math.floor(
                    (data.item.attributes.extend.stats.total_amount / data.item.attributes.goal) *
                      100
                  )
                }}%
              </span>
              <b-progress
                :value="
                  Math.floor(
                    (data.item.attributes.extend.stats.total_amount / data.item.attributes.goal) *
                      100
                  )
                "
                height="8px"
                :variant="data.item.attributes.variant"
                striped
              />
            </div>
          </template>

          <template #cell(diff)="data">
            {{ campaignCurrencySign }}
            {{
              Math.trunc(data.item.attributes.extend.stats.total_amount - data.item.attributes.goal)
            }}
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <button :disabled="!hasAccessEdit" class="bttn-round" @click="editTeam(data.item)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    d="M3 17.25V21h3.75L17.81
              9.94l-3.75-3.75L3 17.25zM20.71
              7.04c.39-.39.39-1.02
              0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41
              0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                  />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </button>

              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                class="dir-he"
                no-caret
              >
                <template v-slot:button-content>
                  <b-icon icon="three-dots-vertical" />
                </template>

                <b-dropdown-item
                  v-if="data.item.attributes.extend.stats.parent_level !== 0"
                  @click="childrenTeams(data.item)"
                >
                  <b-icon-people class="m-1" />
                  {{ $t("dashboard.team_show__children_teams_btn", "Show children teams") }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="data.item.attributes.extend.stats.total_count !== 0"
                  @click="$emit('open-donation-modal', data.item.id)"
                >
                  <b-icon-cash class="m-1" />
                  {{ $t("dashboard.teams_donations_btn", "Show donations") }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="teamSMSMessaging"
                  :disabled="!team_sms_messaging"
                  @click="sendSMS(data.item)"
                >
                  <b-icon-chat-text class="m-1" />
                  {{ $t("edit_campaign.teams_button_send_sms", "Send SMS") }}
                </b-dropdown-item>
                <b-dropdown-item @click="sendEmail(data.item)">
                  <b-icon-envelope class="m-1" />
                  {{ $t("edit_campaign.teams_button_send_email", "Send email") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="enableChargeTeamLinkedCc && data.item.attributes.can_charge_credit_card"
                  @click="openChargeLinkedCcModal(data.item)"
                >
                  <b-icon-credit-card class="m-1" />
                  {{ $t("edit_campaign.teams_button_charge_linked_cc", "Charge linked CC") }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>

        <div v-if="loading">
          <div class="loadings">
            <LdsSpinner />
          </div>
        </div>

        <b-row v-if="teams.length > 0">
          <b-col>
            <b-pagination
              v-model="page"
              :total-rows="teamsCount"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </b-col>
          <b-col>
            <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="sm"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <div v-if="teams.length <= 0" class="no-teams">There are no teams to show</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { BProgress } from "bootstrap-vue";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";
import teamsOverview from "./teamsOverview.vue";
import teamsActions from "./teamsActions.vue";
export default {
  components: {
    BProgress,
    LdsSpinner,
    teamsOverview,
    teamsActions,
  },
  props: {},
  data() {
    return {
      page: 1,
      perPage: 10,
      searchQ: "",
      sortBy: "-percent",
      sort: "",
      sortDesc: "",
      currentPage: 1,
      sortByShown: false,
      progressVariant: "",
      collapseVisible: false,
      actionsCollapse: false,
      fields: [
        {
          key: "id",
          label: this.$t("dashboard.team_id", "ID"),
        },
        { key: "name", label: this.$t("dashboard.team_name", "Name"), sortable: true },
        { key: "goal", label: this.$t("dashboard.team_goal", "Goal"), sortable: true },
        { key: "total_amount", label: this.$t("dashboard.team_raised", "Raised"), sortable: true },
        {
          key: "total_count",
          label: this.$t("dashboard.team_number_of_donors", "Number of donors"),
          sortable: true,
        },
        { key: "progress", label: this.$t("dashboard.team_percent", "Progress"), sortable: true },
        { key: "diff", label: this.$t("dashboard.diff_goal_raised", "Diff (goal-raised)") },
        { key: "actions", label: this.$t("dashboard.team_actions", "Actions") },
      ],
      pageOptions: [
        5,
        10,
        15,
        { value: 100, text: this.$t("dashboard.team_show_a_lot", "Show a lot") },
      ],
    };
  },
  created() {
    this.getTeams();
  },
  computed: {
    ...mapState({
      teams: (state) => state.teams.teams,
      campaignCurrencySign: (state) => state.campaign.campaign.data.attributes.currency_sign,
      campaignShortLink: (state) => state.campaign.campaign.data.attributes.short_link,
      campaignId: (state) => state.dashboard.selectedCampaign,
      loading: (state) => state.teams.loadingTeam,
      teamsCount: (state) => state.teams.teamsCount,
      canSeeInputSearch: (state) => state.account.access.CanPerformDonationSearch,
      hasAccessEdit: (state) => state.account.access.CanEditCampaignTeams,
      team_sms_messaging: (state) => state.messaging.team_sms_messaging,
      teamSMSMessaging: (state) => state.account.teamSMSMessaging,
      enableChargeTeamLinkedCc: (state) => state.account.enableChargeTeamLinkedCc,
    }),
    ...mapGetters({
      allTeams: "allTeamsWithStats",
    }),
    rows() {
      return this.teams.length;
    },
  },
  watch: {
    collapseVisible(val) {
      if (val) {
        this.$store.dispatch("getAllTeams", this.campaignId);
      }
    },
    teams() {
      this.addVariantTeams();
    },
    searchQ() {
      this.page = 1;
      this.getTeams();
    },
    perPage() {
      this.getTeams();
    },
    page() {
      this.getTeams();
    },
    campaignId() {
      this.page = 1;
      this.getTeams();
    },
    sort(value) {
      this.filterSortBy(value);
    },
    sortBy() {
      this.page = 1;
      this.getTeams();
    },
    sortDesc() {
      this.filterSortBy(this.sort);
    },
    sortByShown() {
      this.page = 1;
      this.getTeams();
    },
  },
  methods: {
    getTeams() {
      const {
        page,
        perPage,
        sortBy,
        sortByShown,
        searchQ,
        parent_team_id,
        including_grand_children,
      } = this;
      const params = {
        page,
        limit: perPage,
        q: searchQ ? searchQ : undefined,
        sort: sortBy ? sortBy : undefined,
        hidden: sortByShown,
        extend: ["stats"],
        parent_team_id,
        including_grand_children,
      };
      this.$store.dispatch("getTeamsByCampaignId", [this.campaignId, params]);
    },
    addVariantTeams() {
      for (let i = 0; i < this.teams.length; i++) {
        //Check if extend equals to null
        if (typeof this.teams[i].attributes.extend?.stats?.total_amount === "undefined") {
          this.teams[i].attributes.extend.stats = {
            parent_level: 0,
            total_amount: 0,
            total_count: 0,
          };
        }
        this.teams[i].attributes.variant = this.rowClass(this.teams[i]);
      }
    },
    rowClass(item) {
      let progress = Math.floor(
        (item.attributes.extend.stats.total_amount / item.attributes.goal) * 100
      );
      if (this.between(progress, 0, 20)) {
        return "danger";
      } else if (this.between(progress, 21, 50)) {
        return "secondary";
      } else if (this.between(progress, 51, 70)) {
        return "warning";
      } else if (this.between(progress, 71, 90)) {
        return "info";
      } else if (this.between(progress, 91, 100)) {
        return "success";
      } else if (this.between(progress, 101, 100000)) {
        return "primary";
      }
      return;
    },
    between(x, min, max) {
      return x >= min && x <= max;
    },
    editTeam(team) {
      this.$emit("edit-team", team);
    },
    filterSortBy(sortBy) {
      let sorting = "";
      //Sort By name
      if (sortBy === "name" && this.sortDesc) {
        sorting = "-name";
      } else if (sortBy === "name" && !this.sortDesc) {
        sorting = "name";
      }
      //Sort By goal
      if (sortBy === "goal" && this.sortDesc) {
        sorting = "-goal";
      } else if (sortBy === "goal" && !this.sortDesc) {
        sorting = "goal";
      }
      //Sort By raised
      if (sortBy === "total_amount" && this.sortDesc) {
        sorting = "-raised";
      } else if (sortBy === "total_amount" && !this.sortDesc) {
        sorting = "raised";
      }
      //Sort By progress
      if (sortBy === "progress" && this.sortDesc) {
        sorting = "-percent";
      } else if (sortBy === "progress" && !this.sortDesc) {
        sorting = "percent";
      }
      //Sort By donors
      if (sortBy === "total_count" && this.sortDesc) {
        sorting = "-donors";
      } else if (sortBy === "total_count" && !this.sortDesc) {
        sorting = "donors";
      }
      this.sortBy = sorting;
    },
    addTeam() {
      this.$emit("add-team");
    },
    childrenTeams(item) {
      this.$emit("children-teams", item);
    },
    importTeams() {
      this.$emit("import-teams");
    },
    exportTeams() {
      this.$emit("export-teams");
    },
    sendEmail(team) {
      this.$emit("send-email", { team });
    },
    sendSMS(team) {
      this.$emit("send-sms", { team });
    },
    openChargeLinkedCcModal(team) {
      this.$emit("open-charge-linked-cc-modal", { team });
    },
  },
};
</script>
<style scoped>
.dashboard-content-wrapper {
  margin-bottom: 20px;
}
.no-teams {
  text-align: center;
  font-size: larger;
  height: 200px;
  padding-top: 65px;
}
#collapse-icon {
  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.rotate-180 {
  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  stroke: #107598;
  transform: rotate(180deg);
}
</style>
