<template>
  <div>
    <b-modal id="editReceipt" size="lg">
      <div slot="modal-header">
        <div class="modal-header__close" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
        <h2 class="h1-like">{{ $t("dashboard.receipt_receipt") }}</h2>
      </div>

      <div slot="modal-footer">
        <button form="receipt-edit" class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
          {{ $t("dashboard.receipt_cancel") }}
        </button>
      </div>

      <form id="receipt-edit" @submit.prevent="formSend" class="receipt-data entities-data">
        <div class="regenerate-block" v-if="!hideRegenarate">
          <!--
          <a
            href="#"
            v-b-tooltip.hover
            :title="$t('dashboard.regenerate_receipt_tooltip')"
            @click.prevent="regenerate"
            v-if='!regenarateStatus.loading && !regenarateStatus.success'
          >
            {{ $t('dashboard.regenerate_receipt') }}
          </a>
          -->
          <a
            v-if="!regenerating"
            @click.prevent="regenerate"
            href="#"
            v-b-tooltip.hover
            :title="$t('dashboard.regenerate_receipt_tooltip')"
            class="btn bttn bttn--sm bttn--orange"
          >
            {{ $t("dashboard.regenerate_receipt") }}
          </a>
          <a v-if="regenerating">
            {{ $t("dashboard.regenerate_receipt_loading") }}
          </a>
          <!--
          <a
            href="#"
            @click.prevent="loadingReceipt"
            v-else-if='regenarateStatus.success'
          >
            {{ $t('dashboard.regenerate_receipt_success') }}
          </a>
          -->
        </div>

        <div class="row align-items-start">
          <div class="col-12 receipt-data__receipt">
            <div v-if="loaderFlag" class="spinner-wrapper">
              <LdsSpinner />
            </div>
            <div v-else v-html="receiptHtml" class="receipt-data__receipt-content"></div>
            <a :href="receiptDownload">
              <svg xmlns="http://www.w3.org/2000/svg" width="144" height="144" viewBox="0 0 24 24">
                <path
                  d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z"
                ></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
              Download receipt
            </a>
          </div>

          <div class="col-12">
            <div class="receipt-data__send">
              <div class="form-group">
                <label for="receipt-email" class="entities-data__label entities-data">
                  {{ $t("dashboard.receipt_email") }}
                </label>

                <b-form-input id="receipt-email" v-model="initialEmail"></b-form-input>
              </div>

              <button
                v-if="sendFlag === 0"
                form="receipt-edit"
                class="bttn bttn--lg bttn--orange"
                :disabled="sendFlag === 2"
              >
                <span>{{ $t("dashboard.receipt_resend") }}</span>
              </button>
              <span class="receipt-send-message" v-if="sendFlag === 1">{{
                $t("dashboard.receipt_sending")
              }}</span>
              <span class="receipt-send-message" v-if="sendFlag === 2">{{
                $t("dashboard.receipt_sent")
              }}</span>
            </div>
          </div>
        </div>
      </form>
      <div v-if="regenerating" class="process-overlay">
        <LdsSpinner />
        <span>{{ progress }}</span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";
import axios from "axios";
import urls from "@/constants/urls.js";

export default {
  props: ["campaignid", "currentpage"],
  components: { LdsSpinner },

  data() {
    return {
      receipt: {
        data: {
          attributes: {
            email: "",
          },
        },
      },
      donationId: 0,
      orgId: "",
      campaignId: "",
      initialEmail: "",
      sendFlag: 0, // 0 - initial open, 1 - sending, 2 - email sent, 3 - error (3 not styled)
      loaderFlag: true,
      receiptHtml: "",
      receiptDownload: "",
      regenerating: false,
      procId: null,
      progress: 0,
      regenarateStatus: {
        loading: false,
        success: false,
      },
      hideRegenarate: false,
    };
  },

  methods: {
    regenerate() {
      this.regenerating = true;
      this.regenarateStatus.loading = true;
      axios
        .post(
          urls.getReceipt
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignId)
            .replace(":donationId", this.donationId),
          {
            force: true,
            generateReceipt: true,
            generateEmail: true,
            sendEmail: false,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.procId = res.data.proc;
            this.procCheck();
            this.sendFlag = 0;
          }
        })
        .catch((e) => {
          console.log(e);
          this.hideRegenarate = true;
        });
    },

    procCheck() {
      let token = localStorage.getItem("jwt_token");

      let interval = setInterval(() => {
        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .get(urls.oldProcProgressTracker.replace(":id", this.procId))
          .then((res) => {
            let data = res.data.data;
            this.progress = data.attributes.progress;
            if (data.attributes.finished) {
              clearInterval(interval);
              this.progress = 0;
              this.regenerating = false;
              this.loadingReceipt();
            }
          })
          .catch((err) => console.log(err));
      }, 5000);
    },

    formSend() {
      this.sendFlag = 1;

      const searchParams = new URLSearchParams();
      searchParams.append("email", this.initialEmail);
      axios
        .post(
          urls.postReceipt
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignId)
            .replace(":donationId", this.donationId)
            + "?" +
            searchParams.toString()
        )
        .then((res) => {
          if (res.status === 200) {
            this.sendFlag = 2;
          }
        })

        .catch((err) => {
          this.sendFlag = 3;
        });
    },

    openEditModal: function (donate) {
      this.orgId = localStorage.getItem("orgId");
      this.donationId = donate.id;
      this.campaignId = donate.attributes.campaign_id;
      this.initialEmail = donate.attributes.email;
      this.receipt.data.attributes = donate.attributes;
      this.$root.$emit("bv::show::modal", "editReceipt");
      this.loadingReceipt();
    },

    loadingReceipt() {
      axios
        .get(
          urls.getReceipt
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignId)
            .replace(":donationId", this.donationId)
        )
        .then((res) => {
          this.receiptHtml = res.data.data.attributes.content;
          this.receiptDownload = res.data.data.attributes.download_link;
          this.loaderFlag = false;
        })
        .catch((err) => console.log(err));
    },

    hideModal() {
      this.sendFlag = 0;
      this.regenerating = false;
      this.procId = null;
      this.progress = 0;
      this.$root.$emit("bv::hide::modal", "editReceipt");
    },
  },
};
</script>

<style lang="scss" scoped>
#editReceipt {
  .modal-header {
    position: relative;

    &__close {
      display: inline-flex;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .modal-content {
    overflow: hidden;
  }

  .spinner-wrapper {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed rgba(0, 0, 0, 0.1);
  }
}

.receipt-data {
  &__receipt {
    //text-align: center;

    &-content {
      width: 100%;
      max-height: 400px;
      margin-bottom: 5px;
      overflow-x: auto;
      overflow-y: auto;
      table {
        margin: 0 auto !important;
      }
    }

    a {
      margin: 0 auto 20px;
      display: inline-flex;
      width: 100%;
      //flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      text-transform: uppercase;
      color: #20434e;
      transition: all 0.2s ease-in-out;
      svg {
        display: block;
        margin-right: 10px;
        width: 24px;
        height: auto;
        fill: #c1c1c1;
      }
      &:hover,
      &:focus,
      &:active {
        color: #cc6d4c;
      }
    }
  }

  .form-group {
    margin-bottom: 10px;
    max-width: 310px;
    width: 100%;
    @media only screen and (min-width: 768px) {
      max-width: 290px;
      margin-right: 10px;
      margin-bottom: 0;
    }
    .form-control {
      height: 45px;
      width: 100%;
    }
  }

  .receipt-send {
    span {
      font-family: inherit;
      font-size: inherit;
      color: inherit;
    }
    &--success {
      color: #666666;
      background-color: transparent;
    }
  }

  &__send {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    @media only screen and (min-width: 768px) {
      flex-wrap: nowrap;
    }
    button {
      margin: 0;
      align-self: flex-end;
    }
  }
}

.modal-footer {
  .bttn--blue {
    margin-left: auto;
  }
}

.receipt-send-message {
  height: 46px;
  display: flex;
  align-items: center;
  align-self: flex-end;
}

.regenerate-block {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  > .btn {
    display: inline-flex;
  }
}
.process-overlay {
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -72px;
  left: 0;
  bottom: -87px;
  > span {
    padding-top: 20px;
  }
}
</style>
