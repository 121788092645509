import { getFavoriteCampaign } from "@/helpers/storage.js";
import axios from "axios";
import urls from "../../constants/urls.js";
import utm from "./utm";
import vetting from "./vetting";
import { includedArrToMap, mapIncludedToRelationships } from "@/helpers/jsonapi.js";

const state = {
  campaigns: [],
  campaign: null,
  loading: false,
};

const mutations = {
  setCampaigns(state, data) {
    state.campaigns = data;
  },

  setCampaign(state, data) {
    state.campaign = data;
  },

  setLoading(state, value) {
    state.loading = value;
  },
};

function mapCampaignIncludedToRelationships(campaignData, campaignIncluded) {
  const newCampaignData = structuredClone(campaignData);
  const newCampaignIncluded = structuredClone(campaignIncluded);

  const includedMap = includedArrToMap(newCampaignIncluded);
  const relationships = mapIncludedToRelationships(newCampaignData.relationships, includedMap);

  newCampaignData.relationships = relationships;

  return newCampaignData;
}

const actions = {
  async fetchCampaigns({ commit }) {
    commit("setLoading", true);

    const orgId = localStorage.getItem("orgId");
    const extendParams = [
      "campaign_stats",
      "media",
      "meta",
      "donation_levels",
      "donation_streams",
      "url_alias",
    ];

    try {
      const { data } = await axios.get(urls.getCampaignList.replace(":orgId", orgId), {
        params: { extend: extendParams },
      });

      commit(
        "setCampaigns",
        data.data.map((campaign) => mapCampaignIncludedToRelationships(campaign, data.included))
      );
      if (!getFavoriteCampaign()) {
        commit("setCampaign", mapCampaignIncludedToRelationships(data.data[0], data.included));
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit("setLoading", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  modules: {
    utm,
    vetting,
  },
};
