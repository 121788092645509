<template>
  <div>
    <div v-if="!loadTranslation">
      <div class="dashboard-content-wrapper">
        <category-title :title="$t('dashboard.title_donation')" withActions>
          <!-- hide export donations on the Donors tab : https://app.asana.com/0/1192055722440618/1199702804132629 -->
          <donation-export
            v-if="allowAllDonorExport"
            :filters="{ searchQ, filterStatus, gatewayFilter, campaignId: selectedCampaignFilter }"
          />
        </category-title>

        <div class="c-dashboard-donations">
          <div class="c-dashboard-donations__filters" v-if="canSeeInputSearch">
            <app-search-input
              class="filters"
              v-model="searchQ"
              value=""
              :placeholder="$t('dashboard.select_filter_placeholder_input')"
            />

            <appSelect
              class="filters"
              v-model="gatewayFilter"
              :searchable="false"
              track-by="id"
              label="title"
              :allow-empty="false"
              :select-label="$t('dashboard.donation_gateway_select_label', 'Press enter to select')"
              :deselect-label="
                $t('dashboard.donation_gateway_deselect_label', 'Press enter to remove')
              "
              :selected-label="$t('dashboard.donation_gateway_selected_label', 'Selected')"
              :options="gatewaysList"
            />

            <appSelectSearch
              class="filters"
              v-model="selectedCampaignFilter"
              :searchUrl="getSearchUrl"
              :searchOptions="searchOptions"
            />

            <appSelect
              class="filters"
              v-model="filterStatus"
              :searchable="false"
              track-by="id"
              label="title"
              :allow-empty="false"
              :select-label="$t('dashboard.donation_status_select_label', 'Press enter to select')"
              :deselect-label="
                $t('dashboard.donation_status_deselect_label', 'Press enter to remove')
              "
              :selected-label="$t('dashboard.donation_status_selected_label', 'Selected')"
              :options="donationStatuses"
            />
          </div>

          <div class="c-dashboard-donations__filters" v-if="canSeeInputSearch">
            <b-form-checkbox v-model="searchInArchivedCampaigns">
              {{ $t("dashboard.search_in_archived_campaigns", "Search in archived campaigns") }}
            </b-form-checkbox>
          </div>

          <div class="c-dashboard-donations__th">
            <div class="c-th-item">#</div>
            <div class="c-th-item clickable c-th-item--name" @click="setSort('billing_name')">
              {{ $t("dashboard.donation_name") }} <i :class="classSortBy('billing_name')"></i>
            </div>
            <div class="c-th-item clickable c-th-item--name" @click="setSort('display_name')">
              {{ $t("dashboard.donation_display_name") }}
              <i :class="classSortBy('display_name')"></i>
            </div>
            <div class="c-th-item clickable" @click="setSort('bank_name')">
              {{ $t("dashboard.donation_methods") }} <i :class="classSortBy('bank_name')"></i>
            </div>
            <div class="c-th-item clickable" @click="setSort('charged_amount')">
              {{ $t("dashboard.donation_amount_charged") }}
              <i :class="classSortBy('charged_amount')"></i>
            </div>
            <div class="c-th-item clickable" @click="setSort('effective_amount')">
              {{ $t("dashboard.donation_amount_effective") }}
              <i :class="classSortBy('effective_amount')"></i>
            </div>
            <div class="c-th-item clickable c-th-item--15" @click="setSort('email')">
              {{ $t("dashboard.donation_email_phone") }} <i :class="classSortBy('email')"></i>
            </div>
            <div class="c-th-item clickable" @click="setSort('date')">
              {{ $t("dashboard.donation_date") }} <i :class="classSortBy('date')"></i>
            </div>
            <div class="c-th-item">
              {{ $t("dashboard.donation_status") }}
            </div>
          </div>
        </div>

        <div v-if="!loadingDonations">
          <template v-if="donations.length > 0">
            <donation-row
              @edit-receipt="editReceipt"
              @preview-donate="preview"
              v-for="(donation, idx) in donations"
              :donation="donation"
              :key="idx"
            />
          </template>

          <div v-if="donations.length == 0" class="empty-result-message">
            {{ $t("dashboard.donations_empty") }}
          </div>
        </div>

        <div v-if="loadingDonations">
          <div class="loadings">
            <app-spinner />
          </div>
        </div>
      </div>

      <b-pagination
        v-model="currentPage"
        size="md"
        v-if="donorsCount > 50"
        :total-rows="donorsCount"
        :per-page="50"
      />

      <modal-receipt
        :campaignid="selectedCampaignFilter"
        :currentpage="currentPage"
        ref="editReceiptModal"
      />
      <preview-donate ref="previewDonate" name="donation" />
    </div>
    <div v-else class="c-loading">
      <app-spinner />
    </div>
  </div>
</template>

<script>
import categoryTitle from "@/common-components/ui-elements/category-title.vue";
import donationRow from "./donation-row.vue";
import donationExport from "./donation-export-modal.vue";
import donationReceiptModal from "../dashboard/subcomponents/donationReceiptModal.vue";
import previewDonate from "@/components/view-modal-donation.vue";
import appSearchInput from "@/common-components/ui-elements/app-search-input.vue";
import appSpinner from "@/common-components/ui-elements/app-spinner.vue";
import appSelect from "@/common-components/ui-elements/app-select.vue";
import appSelectSearch from "@/common-components/ui-elements/app-select-search.vue";
import { mapState } from "vuex";
import debounce from "lodash/debounce";
import qs from "qs";
import urls from "@/constants/urls";

export default {
  components: {
    categoryTitle,
    appSearchInput,
    "donation-row": donationRow,
    "modal-receipt": donationReceiptModal,
    "preview-donate": previewDonate,
    appSpinner,
    appSelect,
    appSelectSearch,
    donationExport,
  },

  data: function () {
    return {
      currentPage: 1,

      selectedCampaignFilter: null,

      searchQ: "",

      sortBy: "",

      filterStatus: {
        id: "",
        title: this.$t("dashboard.select_filter_please_select_status"),
      },

      gatewayFilter: {
        id: "",
        title: this.$t("dashboard.select_filter_please_select_gateway", "Please select gateway"),
      },

      searchInArchivedCampaigns: false,

      donationStatuses: [
        {
          id: "",
          title: this.$t("dashboard.select_filter_please_select_status"),
        },
        {
          id: "authorized",
          title: this.$t("dashboard.select_filter_authorized"),
        },
        {
          id: "canceled",
          title: this.$t("dashboard.select_filter_canceled"),
        },
        {
          id: "processed",
          title: this.$t("dashboard.select_filter_processed"),
        },
        {
          id: "pending",
          title: this.$t("dashboard.select_filter_pending"),
        },
      ],
      setIntervalData: null,
    };
  },

  created: function () {
    let setting = `?extend=donation_receipt_id&page=1&limit=50`;

    this.$store.dispatch("getCountries");

    this.$store.dispatch("getGatewaysByOrg");

    this.$store.dispatch("getOrganizationDonotion", setting);

    this.setIntervalData = setInterval(() => {
      if (navigator.onLine && !this.bluredTab && this.currentPage == 1) {
        this.updatePageDonations();
      }
    }, 30000);
  },

  watch: {
    currentPage: function () {
      this.getNewPageDonation();
    },

    bluredTab: function () {
      if (!this.bluredTab && this.currentPage == 1) {
        this.updatePageDonations();
      }
    },

    sortBy: debounce(function () {
      if (this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.getNewPageDonation();
      }
    }, 500),

    searchQ: debounce(function () {
      this.currentPage = 1;

      this.getNewPageDonation();
    }, 500),

    filterStatus: {
      deep: true,
      handler: function () {
        this.getNewPageDonation();
      },
    },

    gatewayFilter: {
      deep: true,
      handler: function () {
        this.getNewPageDonation();
      },
    },

    selectedCampaignFilter: {
      deep: true,
      handler: function () {
        this.getNewPageDonation();
      },
    },

    searchInArchivedCampaigns: {
      deep: true,
      handler: function () {
        this.getNewPageDonation();
      },
    },
  },

  directives: {
    debounce,
  },

  computed: {
    ...mapState({
      canSeeInputSearch: (state) => {
        return state.account.access.CanPerformDonationSearch;
      },

      bluredTab: (state) => {
        return state.account.blured;
      },

      gateways: function (state) {
        let gateways = state.donation.gateways;

        let filtredGateways = this.unique(gateways);

        return filtredGateways;
      },

      donations: (state) => {
        return state.donation.donations;
      },

      loadingDonations: (state) => {
        return state.donation.loadingDonation;
      },

      donorsCount: (state) => {
        return parseInt(state.donation.donationTotal);
      },

      allCampaigns: (state) => {
        return state.campaigns.campaigns;
      },

      loadTranslation: (state) => {
        return state.translation.loadTranslation;
      },
      allowAllDonorExport: (state) => state.account.allowAllDonorExport,
    }),
    gatewaysList() {
      const all = {
        id: "",
        title: "All Gateways",
      };
      const arr = this.gateways.map((item) => ({
        id: item.attributes.gateway,
        title: this.$t(
          `dashboard.select_filter_${item.attributes.gateway}`,
          item.attributes.gateway
        ),
      }));
      return [all, ...arr];
    },
    getSearchUrl() {
      const orgID = localStorage.getItem("orgId");

      return urls.getCampaignList.replace(":orgId", orgID);
    },

    searchOptions() {
      return {
        placeholder: this.$t("dashboard.select_filter_please_select_campaign", "Select campaign"),
        changeParams: ({ page, per_page: pp, query }) => ({
          page: page,
          perPage: pp,
          search: query,
          extend: ["campaign_stats", "media", "meta", "donation_levels", "donation_streams"],
        }),
        customLabel: (e) => `[${e.id}] ${e.attributes.title}`,
      };
    },
  },

  methods: {
    preview: function (donate) {
      this.$refs.previewDonate.openModal(donate.attributes);
    },

    setSort: function (sortBy) {
      if (this.sortBy != sortBy && this.sortBy != `-${sortBy}`) {
        this.sortBy = sortBy;
      } else if (this.sortBy == sortBy) {
        this.sortBy = `-${sortBy}`;
      } else {
        this.sortBy = "";
      }
    },

    classSortBy: function (sortBy) {
      if (this.sortBy == sortBy) {
        return "fa fa-angle-up";
      } else if (this.sortBy == "-" + sortBy) {
        return "fa fa-angle-down";
      }
    },

    unique: function (arr) {
      var result = [];

      nextInput: for (var i = 0; i < arr.length; i++) {
        var str = arr[i]; // для каждого элемента
        for (var j = 0; j < result.length; j++) {
          // ищем, был ли он уже?
          if (result[j].attributes.gateway == str.attributes.gateway) continue nextInput; // если да, то следующий
        }
        result.push(str);
      }
      return result;
    },

    getNewPageDonation: function () {
      const paramsObj = {
        extend: "donation_receipt_id",
        page: this.currentPage,
        limit: 50,
        campaign_id: this.selectedCampaignFilter?.id || undefined,
        gateway: this.gatewayFilter?.id || undefined,
        status: this.filterStatus?.id || undefined,
        q: this.searchQ || undefined,
        sort: this.sortBy || undefined,
        searchInArchivedCampaigns: Number(this.searchInArchivedCampaigns) || undefined,
      };

      const paramsStr = qs.stringify(paramsObj);

      this.$store.dispatch("updateOrganizationDonotion", `?${paramsStr}`);
    },

    updatePageDonations: function () {
      const searchParams = new URLSearchParams();
      searchParams.set("extend", "donation_receipt_id");
      searchParams.set("page", this.currentPage);
      searchParams.set("limit", 50);

      if (this.selectedCampaignFilter?.id) {
        searchParams.set("campaign_id", this.selectedCampaignFilter.id);
      }
      if (this.gatewayFilter) {
        searchParams.set("gateway", this.gatewayFilter.id);
      }
      if (this.filterStatus.id.length > 0) {
        searchParams.set("status", this.filterStatus.id);
      }
      if (this.searchQ.length > 0) {
        searchParams.set("q", this.searchQ);
      }
      if (this.sortBy.length > 0) {
        searchParams.set("sort", this.sortBy);
      }
      if (this.searchInArchivedCampaigns) {
        searchParams.set("searchInArchivedCampaigns", 1);
      }

      this.$store.dispatch("updateOrganizationDonotion", `?${searchParams.toString()}`);
    },

    editReceipt(donate) {
      this.$refs.editReceiptModal.openEditModal(donate);
    },

    campaignCustomLabel({ id, title }) {
      return id !== 0 ? `[${id}] ${title}` : `${title}`;
    },
  },

  destroyed: function () {
    clearInterval(this.setIntervalData);
  },
};
</script>

<style lang="scss" scoped>
.c-loadings {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-dashboard-donations {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: $shadow-th;
  position: relative;
  z-index: 1;
  @media only screen and (min-width: 468px) {
    padding: 20px 40px;
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: -5px;
    margin-left: -5px;
    @media only screen and (min-width: 1360px) {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $bg-100;
    }
    @media only screen and (min-width: 1460px) {
      flex-wrap: nowrap;
    }
    .filters {
      margin: 5px;
      @media only screen and (min-width: 660px) {
        width: 48%;
      }
      @media only screen and (min-width: 1024px) {
        width: 48%;
      }
      @media only screen and (min-width: 1180px) {
        width: 24%;
      }
      @media only screen and (min-width: 1360px) {
        width: 48%;
      }
      @media only screen and (min-width: 1460px) {
        width: 24%;
      }
    }
  }

  &__th {
    display: none;
    @media only screen and (min-width: 1360px) {
      display: flex;
    }
    .c-th-item {
      display: flex;
      align-items: center;
      width: 10%;
      min-width: 10%;
      padding: 0 5px;
      color: $fd;
      font-size: 14px;
      &--15 {
        width: 15%;
        min-width: 15%;
      }
      &--20 {
        width: 20%;
        min-width: 20%;
      }
    }
  }
}

.clickable {
  cursor: pointer;
  i {
    padding-left: 3px;
  }
}

#app.he {
  .c-dashboard-donations {
    &__th {
      .c-th-item {
        text-align: right;
      }
    }
  }
}
</style>
