<template>
  <div class="gateway-block">
    <div class="category-title">
      <h1 class="h1-like">{{ $t("gateways.title") }}</h1>
      <gateway-add ref="gatewayModal"></gateway-add>
    </div>

    <gatewayPlate> </gatewayPlate>
  </div>
</template>

<script>
import gatewayPlate from "./subcomponents/gatewayPlate.vue";
import gatewayAdd from "./subcomponents/gatewayAdd.vue";
import entitiesModal from "@/views/account/subcomponents/entitiesModal.vue";
import store from "../../../store/store.js";
import { mapState } from "vuex";

export default {
  created: function () {
    store.dispatch("getGateways");
    store.dispatch("getEntities");
  },

  components: {
    gatewayPlate,
    gatewayAdd,
    entitiesModal,
  },

  computed: mapState({}),

  methods: {},
};
</script>

<style lang="scss" scoped>
.entities-add {
  display: none !important;
}
.gateway-block {
  .entities-modal > div {
    > button {
      display: none;
    }
  }
}
</style>
