import urls from "../../../constants/urls.js";
import axios from "axios";
import updates from "./updates";

const state = {
  campaignDashboard: [],
  loadingCampaignDashboard: true,
  selectedCampaign: 0,
};

const mutations = {
  setCampaignDashboard(state, data) {
    state.campaignDashboard = data;
  },

  setLoadingCampaignDashboard(state, value) {
    state.loadingCampaignDashboard = value;
  },

  setSelectedCampaign(state, value) {
    state.selectedCampaign = value;
  },
};

const getters = {
  getSelectedCampaign(state, getters, rootState) {
    const id = state.selectedCampaign;
    return rootState.campaigns.campaigns.find((el) => el.id === id);
  },

  getMetasInSelectedCampaign(state, getters, rootState) {
    const id = state.selectedCampaign;
    const selectedCampaignMetas = getters.getMetasByCampaignId(id);
    const campaignMetas = rootState.campaign.metas;

    if (Object.keys(selectedCampaignMetas).length > 0) {
      return selectedCampaignMetas;
    }

    if (Object.keys(campaignMetas).length > 0) {
      return campaignMetas;
    }

    return {};
  },

  getMetasByCampaignId(state, getters, rootState) {
    return (campaignId) => {
      const campaigns = [
        ...rootState.campaigns.campaigns,
        ...rootState.campaigns.current,
        ...rootState.campaigns.past,
      ];
      const campaign = campaigns.find((el) => el.id === campaignId);
      const campaignMetaIds = campaign?.relationships?.campaign_meta?.data || [];
      const included = rootState.campaigns.includedMedia;
      const allMeta = included.reduce((res, el) => {
        if (el.type === "meta") {
          res[el.id] = el.attributes;
        }
        return res;
      }, {});
      return campaignMetaIds.reduce((res, el) => {
        const meta = allMeta[el.id];
        if (meta) {
          try {
            res[meta.name] = JSON.parse(meta.data);
          } catch {
            console.table({
              error: "Invalid meta data",
              meta: meta.name,
              data: meta.data,
            });
          }
        }
        return res;
      }, {});
    };
  },
};

const actions = {
  getCampaignByIdDashboard({ commit }, props) {
    commit("setLoadingCampaignDashboard", true);

    let orgId = localStorage.getItem("orgId");
    axios
      .get(
        urls.getDashboardInfo.replace(":orgId", orgId).replace(":campaignId", props.id) +
          props.setting
      )
      .then((response) => {
        let campaign = response.data;
        commit("setCampaignDashboard", campaign);
        commit("setLoadingCampaignDashboard", false);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  updateCampaignByIdDashboard({ commit }, props) {
    let orgId = localStorage.getItem("orgId");
    axios
      .get(
        urls.getDashboardInfo.replace(":orgId", orgId).replace(":campaignId", props.id) +
          props.setting
      )
      .then((response) => {
        let campaign = response.data;
        commit("setCampaignDashboard", campaign);
        commit("setLoadingCampaignDashboard", false);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // eslint-disable-next-line no-unused-vars
  cancelRecurringPlan(_, jwtAuthCode) {
    return axios.get(urls.cancelRecurringPlan, { params: { auth_code: jwtAuthCode } });
  },

  postDonorTeamsPublicReply(_, { comment, jwtAuthCode } = {}) {
    return axios.post(
      urls.donorTeamsPublicReply,
      { data: { attributes: { comment } } },
      {
        params: { auth_code: jwtAuthCode },
        headers: { "Content-Type": "application/json", Authorization: "" },
      }
    );
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
  modules: {
    updates,
  },
};
