<template>
  <div class="design-templates-wrapper">
    <div v-if="activetab === 2">
      <div class="desc">
        <p>
          {{ canva.description }}
        </p>
        <div class="list-row">
          <strong>Availability:</strong>
          <span>Raising $75K-$250K on day of campaign</span>
        </div>
        <div class="list-row">
          <strong>Deliverables:</strong>
          <span
            >Eight email headers, eight social media posts, web/mobile countdown banner, sliders,
            flyer 8.5x11, social media share image</span
          >
        </div>
        <div class="list-row premium">
          <i class="premium-icon">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1561113597489940819.png"
              alt
            />
          </i>
          <strong>Additional premium deliverables:</strong>
          <span>Flyer 11x17, testimonial posts, advertisements, profile picture overlay</span>
        </div>
        <div class="list-row">
          <strong>Customizable:</strong>
          <span>fonts, colors, pictures and logo</span>
        </div>
        <div class="list-row">
          <strong>Rounds of edits:</strong>
          <span>Three</span>
        </div>
      </div>

      <div class="filter-box">
        <b-dropdown variant="-lg bttn--bordered">
          <template slot="button-content">
            <span>Categories: {{ startCategories }}</span>
          </template>
          <b-dropdown-item
            placeholder="Select a subject"
            v-for="categorie in canva.categories"
            :key="categorie.i"
            @click="startCategories = categorie"
          >
            {{ categorie }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <!--
      <div v-show="showStaffStaffPicks">
        <div class="slider-box">
          <div class="swiper-box">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide
                class="swiper-item"
                v-for="(template, i) in canva.templatesStaffPicks"
                :key="i"
              >
                <div class="slider-title">
                  <h4>{{ template.title }}</h4>
                </div>
                <img :src="template.small_preview_img">
                <div class="slider-btn-wrap">
                  <a :href="template.link"  class="bttn bttn--lg bttn--blue" target="_blank">
                    <i></i>Choose Template #{{ template.id }}
                  </a>
                </div>
              </swiper-slide>
            </swiper>
            <div class="bttn-round slide-controls prev">
              <span>
                <svg viewBox="0 0 477.175 477.175">
                  <path
                    d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"
                  ></path>
                </svg>
              </span>
              <p>view previous template</p>
            </div>

            <div class="bttn-round slide-controls next">
              <span>
                <svg viewBox="0 0 129 129">
                  <path
                    d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"
                  ></path>
                </svg>
              </span>
              <p>view next template</p>
            </div>
          </div>
        </div>

        <div class="content-box">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide
              class="swiper-item"
              v-for="(template, i) in canva.templatesStaffPicks"
              :key="i"
            >
              <img :src="template.large_preview_image">
              <div class="slider-btn-wrap">
                <a :href="template.link" class="bttn bttn--lg bttn--blue" target="_blank">
                  <i></i>Choose Template #{{ template.id }}
                </a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <div v-show="showClassic">
        <div class="slider-box">
          <div class="swiper-box">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide
                class="swiper-item"
                v-for="(template, i) in canva.templatesClassic"
                :key="i"
              >
                <div class="slider-title">
                  <h4>{{ template.title }}</h4>
                </div>
                <img :src="template.small_preview_img">
                <div class="slider-btn-wrap">
                  <a :href="template.link" class="bttn bttn--lg bttn--blue" target="_blank">
                    <i></i>Choose Template #{{ template.id }}
                  </a>
                </div>
              </swiper-slide>
            </swiper>
            <div class="bttn-round slide-controls prev">
              <span>
                <svg viewBox="0 0 477.175 477.175">
                  <path
                    d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"
                  ></path>
                </svg>
              </span>
              <p>view previous template</p>
            </div>

            <div class="bttn-round slide-controls next">
              <span>
                <svg viewBox="0 0 129 129">
                  <path
                    d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"
                  ></path>
                </svg>
              </span>
              <p>view next template</p>
            </div>
          </div>
        </div>

        <div class="content-box">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide
              class="swiper-item"
              v-for="(template, i) in canva.templatesClassic"
              :key="i"
            >
              <img :src="template.large_preview_image">
              <div class="slider-btn-wrap">
                <a :href="template.link" class="bttn bttn--lg bttn--blue" target="_blank">
                  <i></i>Choose Template #{{ template.id }}
                </a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <div v-show="showElegant">
        <div class="slider-box">
          <div class="swiper-box">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide
                class="swiper-item"
                v-for="(template, i) in canva.templatesElegant"
                :key="i"
              >
                <div class="slider-title">
                  <h4>{{ template.title }}</h4>
                </div>
                <img :src="template.small_preview_img">
                <div class="slider-btn-wrap">
                  <a :href="template.link" class="bttn bttn--lg bttn--blue" target="_blank">
                    <i></i>Choose Template #{{ template.id }}
                  </a>
                </div>
              </swiper-slide>
            </swiper>
            <div class="bttn-round slide-controls prev">
              <span>
                <svg viewBox="0 0 477.175 477.175">
                  <path
                    d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"
                  ></path>
                </svg>
              </span>
              <p>view previous template</p>
            </div>

            <div class="bttn-round slide-controls next">
              <span>
                <svg viewBox="0 0 129 129">
                  <path
                    d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"
                  ></path>
                </svg>
              </span>
              <p>view next template</p>
            </div>
          </div>
        </div>

        <div class="content-box">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide
              class="swiper-item"
              v-for="(template, i) in canva.templatesElegant"
              :key="i"
            >
              <img :src="template.large_preview_image">
              <div class="slider-btn-wrap">
                <a :href="template.link" class="bttn bttn--lg bttn--blue" target="_blank">
                  <i></i>Choose Template #{{ template.id }}
                </a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <div v-show="showModern">
        <div class="slider-box">
          <div class="swiper-box">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide
                class="swiper-item"
                v-for="(template, i) in canva.templatesModern"
                :key="i"
              >
                <div class="slider-title">
                  <h4>{{ template.title }}</h4>
                </div>
                <img :src="template.small_preview_img">
                <div class="slider-btn-wrap">
                  <a :href="template.link" class="bttn bttn--lg bttn--blue" target="_blank">
                    <i></i>Choose Template #{{ template.id }}
                  </a>
                </div>
              </swiper-slide>
            </swiper>
            <div class="bttn-round slide-controls prev">
              <span>
                <svg viewBox="0 0 477.175 477.175">
                  <path
                    d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"
                  ></path>
                </svg>
              </span>
              <p>view previous template</p>
            </div>

            <div class="bttn-round slide-controls next">
              <span>
                <svg viewBox="0 0 129 129">
                  <path
                    d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"
                  ></path>
                </svg>
              </span>
              <p>view next template</p>
            </div>
          </div>
        </div>

        <div class="content-box">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide
              class="swiper-item"
              v-for="(template, i) in canva.templatesModern"
              :key="i"
            >
              <img :src="template.large_preview_image">
              <div class="slider-btn-wrap">
                <a :href="template.link" class="bttn bttn--lg bttn--blue" target="_blank">
                  <i></i>Choose Template #{{ template.id }}
                </a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <div v-show="showFlashy">
        <div class="slider-box">
          <div class="swiper-box">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide
                class="swiper-item"
                v-for="(template, i) in canva.templatesFlashy"
                :key="i"
              >
                <div class="slider-title">
                  <h4>{{ template.title }}</h4>
                </div>
                <img :src="template.small_preview_img">
                <div class="slider-btn-wrap">
                  <a :href="template.link" class="bttn bttn--lg bttn--blue" target="_blank">
                    <i></i>Choose Template #{{ template.id }}
                  </a>
                </div>
              </swiper-slide>
            </swiper>
            <div class="bttn-round slide-controls prev">
              <span>
                <svg viewBox="0 0 477.175 477.175">
                  <path
                    d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"
                  ></path>
                </svg>
              </span>
              <p>view previous template</p>
            </div>

            <div class="bttn-round slide-controls next">
              <span>
                <svg viewBox="0 0 129 129">
                  <path
                    d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"
                  ></path>
                </svg>
              </span>
              <p>view next template</p>
            </div>
          </div>
        </div>

        <div class="content-box">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide
              class="swiper-item"
              v-for="(template, i) in canva.templatesFlashy"
              :key="i"
            >
              <img :src="template.large_preview_image">
              <div class="slider-btn-wrap">
                <a :href="template.link" class="bttn bttn--lg bttn--blue" target="_blank">
                  <i></i>Choose Template #{{ template.id }}
                </a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
-->
      <div>
        <div class="slider-box">
          <div class="swiper-box">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide class="swiper-item" v-for="(template, i) in selectedTemplates" :key="i">
                <div class="slider-title">
                  <h4>{{ template.title }}</h4>
                </div>
                <img :src="template.small_preview_img" />
                <div class="slider-btn-wrap">
                  <a
                    v-if="hasAccess"
                    :href="template.link"
                    class="bttn bttn--lg bttn--blue"
                    target="_blank"
                  >
                    <i></i>Choose Template #{{ template.id }}
                  </a>
                </div>
              </swiper-slide>
            </swiper>
            <div class="bttn-round slide-controls prev">
              <span>
                <svg viewBox="0 0 477.175 477.175">
                  <path
                    d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"
                  ></path>
                </svg>
              </span>
              <p>view previous template</p>
            </div>

            <div class="bttn-round slide-controls next">
              <span>
                <svg viewBox="0 0 129 129">
                  <path
                    d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"
                  ></path>
                </svg>
              </span>
              <p>view next template</p>
            </div>
          </div>
        </div>

        <div class="content-box">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide class="swiper-item" v-for="(template, i) in selectedTemplates" :key="i">
              <img :src="template.large_preview_image" />
              <div class="slider-btn-wrap">
                <a
                  v-if="hasAccess"
                  :href="template.link"
                  class="bttn bttn--lg bttn--blue"
                  target="_blank"
                >
                  <i></i>Choose Template #{{ template.id }}
                </a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  data: function () {
    return {
      swiperOptions: {
        slidesPerView: 1,
        navigation: {
          nextEl: ".next",
          prevEl: ".prev",
          disabledClass: "disabled",
        },
      },

      startCategories: "Staff Picks",

      canva: {
        description:
          "Our Premium Templates are designed to provide organizations with superior design, while offering greater customization and increased deliverable options to be shared on social media, email, and in print.",
        categories: ["Staff Picks", "Classic", "Elegant", "Modern", "Flashy", "See All"],
        templatesStaffPicks: [
          {
            id: "1",
            title: "STAFF PICKS TEMPLATE #1",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566308333293941651.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566308418451733149.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=1",
          },
          {
            id: "4",
            title: "STAFF PICKS TEMPLATE #4",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566308979495391711.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566309042591151759.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=4",
          },
          {
            id: "9",
            title: "STAFF PICKS TEMPLATE #9",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566309385422507762.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566309607748743491.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=9",
          },
          {
            id: "18",
            title: "STAFF PICKS TEMPLATE #18",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566309897001935371.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566310063251361691.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=18",
          },
          {
            id: "23",
            title: "STAFF PICKS TEMPLATE #23",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566310256218614237.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566310415345736083.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=23",
          },
        ],
        templatesClassic: [
          {
            id: "6",
            title: "CLASSIC TEMPLATE #6",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566387950730482122.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388033821532008.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=6",
          },
          {
            id: "15",
            title: "CLASSIC TEMPLATE #15",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388240368016463.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388283387105036.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=15",
          },
          {
            id: "18",
            title: "CLASSIC TEMPLATE #18",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388480487993780.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388524659524533.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=18",
          },
          {
            id: "20",
            title: "CLASSIC TEMPLATE #20",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388652796299562.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388688118502023.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=20",
          },
          {
            id: "22",
            title: "CLASSIC TEMPLATE #22",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388825104937309.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388849344384896.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=22",
          },
        ],
        templatesElegant: [
          {
            id: "1",
            title: "ELEGANT TEMPLATE #1",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389046815558654.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389081263857731.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=1",
          },
          {
            id: "3",
            title: "ELEGANT TEMPLATE #3",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389475652074546.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389516045913315.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=3",
          },
          {
            id: "8",
            title: "ELEGANT TEMPLATE #8",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389639070177355.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389673820545391.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=8",
          },
          {
            id: "9",
            title: "ELEGANT TEMPLATE #9",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389796306047127.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389832031260459.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=9",
          },
          {
            id: "13",
            title: "ELEGANT TEMPLATE #13",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389958209174666.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389990545253661.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=13",
          },
          {
            id: "19",
            title: "ELEGANT TEMPLATE #19",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390102855419233.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390141654826459.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=19",
          },
        ],
        templatesModern: [
          {
            id: "2",
            title: "MODERN TEMPLATE #2",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566311153489211394.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566312172332019424.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=2",
          },
          {
            id: "4",
            title: "MODERN TEMPLATE #4",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566312473732974836.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566312623298312434.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=4",
          },
          {
            id: "7",
            title: "MODERN TEMPLATE #7",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566313108798699303.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566313315151656544.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=7",
          },
          {
            id: "11",
            title: "MODERN TEMPLATE #11",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566313470357599203.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566313596204912780.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=11",
          },
          {
            id: "12",
            title: "MODERN TEMPLATE #12",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566313857827592192.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566313962219630758.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=12",
          },
          {
            id: "16",
            title: "MODERN TEMPLATE #16",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566314112706797770.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566314227650828480.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=16",
          },
          {
            id: "17",
            title: "MODERN TEMPLATE #17",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566314404274804784.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566314502745206830.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=17",
          },
          {
            id: "21",
            title: "MODERN TEMPLATE #21",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566314753849467424.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566314798314934106.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=21",
          },
          {
            id: "23",
            title: "MODERN TEMPLATE #23",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566314977272060827.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566315022358613141.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=23",
          },
        ],
        templatesFlashy: [
          {
            id: "4",
            title: "FLASHY TEMPLATE #4",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390351168803581.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390386792313687.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=4",
          },
          {
            id: "5",
            title: "FLASHY TEMPLATE #5",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390502212219988.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390525183749196.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=5",
          },
          {
            id: "10",
            title: "FLASHY TEMPLATE #10",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390727681152853.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390748093847266.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=10",
          },
          {
            id: "14",
            title: "FLASHY TEMPLATE #14",
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390823330395486.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390847590613918.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=14",
          },
        ],
        templatesSeeAll: [
          {
            id: "1",
            title: "PREMIUM TEMPLATE #1",
            categories: ["Staff Picks", "Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566308333293941651.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566308418451733149.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=1",
          },
          {
            id: "2",
            title: "PREMIUM TEMPLATE #2",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566311153489211394.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566312172332019424.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=2",
          },
          {
            id: "3",
            title: "PREMIUM TEMPLATE #3",
            categories: ["Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389475652074546.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389516045913315.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=3",
          },
          {
            id: "4",
            title: "PREMIUM TEMPLATE #4",
            categories: ["Staff Picks", "Modern", "Flashy"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566308979495391711.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566309042591151759.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=4",
          },
          {
            id: "5",
            title: "PREMIUM TEMPLATE #5",
            categories: ["Flashy"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390502212219988.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390525183749196.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=5",
          },
          {
            id: "6",
            title: "PREMIUM TEMPLATE #6",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566387950730482122.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388033821532008.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=6",
          },
          {
            id: "7",
            title: "PREMIUM TEMPLATE #7",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566313108798699303.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566313315151656544.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=7",
          },
          {
            id: "8",
            title: "PREMIUM TEMPLATE #8",
            categories: ["Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389639070177355.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389673820545391.jpg",
            link: "	https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=8",
          },
          {
            id: "9",
            title: "PREMIUM TEMPLATE #9",
            categories: ["Staff Picks", "Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389796306047127.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389832031260459.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=9",
          },
          {
            id: "10",
            title: "PREMIUM TEMPLATE #10",
            categories: ["Flashy"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390727681152853.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390748093847266.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=10",
          },
          {
            id: "11",
            title: "PREMIUM TEMPLATE #11",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566313470357599203.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566313596204912780.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=11",
          },
          {
            id: "12",
            title: "PREMIUM TEMPLATE #12",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566313857827592192.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566313962219630758.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=12",
          },
          {
            id: "13",
            title: "PREMIUM TEMPLATE #13",
            categories: ["Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389958209174666.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566389990545253661.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=13",
          },
          {
            id: "14",
            title: "PREMIUM TEMPLATE #14",
            categories: ["Flashy"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390823330395486.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390847590613918.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=14",
          },
          {
            id: "15",
            title: "PREMIUM TEMPLATE #15",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388240368016463.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388283387105036.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=15",
          },
          {
            id: "16",
            title: "PREMIUM TEMPLATE #16",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566314112706797770.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566314227650828480.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=16",
          },
          {
            id: "17",
            title: "PREMIUM TEMPLATE #17",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566314404274804784.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566314502745206830.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=17",
          },
          {
            id: "18",
            title: "PREMIUM TEMPLATE #18",
            categories: ["Staff Picks", "Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388480487993780.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388524659524533.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=18",
          },
          {
            id: "19",
            title: "PREMIUM TEMPLATE #19",
            categories: ["Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390102855419233.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566390141654826459.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=19",
          },
          {
            id: "20",
            title: "PREMIUM TEMPLATE #20",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388652796299562.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388688118502023.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=20",
          },
          {
            id: "21",
            title: "PREMIUM TEMPLATE #21",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566314753849467424.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566314798314934106.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=21",
          },
          {
            id: "22",
            title: "PREMIUM TEMPLATE #22",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388825104937309.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566388849344384896.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=22",
          },
          {
            id: "23",
            title: "PREMIUM TEMPLATE #23",
            categories: ["Staff Picks", "Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566314977272060827.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566315022358613141.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Premium&prefill_Design%20Number=23",
          },
        ],
      },
    };
  },

  props: {
    activetab: Number,
  },

  computed: {
    ...mapState({
      hasAccess: (state) => {
        return (
          state.account.submitDesignTemplatesRequestEnabled &&
          state.account.access.CanSubmitDesignTemplatesRequest
        );
      },
    }),
    swiper() {
      return this.$refs.mySwiper.swiper;
    },

    selectedTemplates() {
      return this.startCategories === "See All"
        ? this.canva.templatesSeeAll
        : this.canva.templatesSeeAll.filter((el) => el.categories.includes(this.startCategories));
    },
  },
  methods: {
    chooseTemplate: function () {
      window.open("https://airtable.com/shrKI29a3vqPLAAAR", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
.design-templates-wrapper {
  padding: 0 90px;
}
::v-deep .swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

::v-deep .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  align-items: flex-end;
}
::v-deep .swiper-item {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  img {
    width: 100%;
  }
}
.swiper-box {
  position: relative;
  //margin-bottom: 40px;
  //height: 800px;
  .slide-controls.bttn-round {
    position: absolute;
    top: 50%;
    margin-top: -40px;
  }
}
.h1-like {
  font-size: 50px;
}
.slide-controls.bttn-round {
  width: 80px;
  height: 180px;
  left: auto;
  background: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  > img,
  svg {
    fill: #ccc;
    width: 50px;
    cursor: pointer;
  }
  &.prev {
    left: -120px;
  }
  &.next {
    right: -120px;
  }
  &.disabled {
    cursor: initial;
  }
  p {
    margin-top: 30px;
    font-size: 18px;
    color: #ccc;
    pointer-events: none;
    user-select: none;
  }
}
.btns-wrap {
  padding: 20px 20px 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.bttn--xlg {
  height: 64px;
  min-width: 100px;
  width: 30%;
  padding: 0 56px;
  border-radius: 40px;
  font-size: 25px;
  font-family: $vito-light;
  line-height: 1;
  position: relative;
  i {
    position: absolute;
    display: block;
    left: 10px;
    width: 46px;
    height: 46px;
    top: 50%;
    margin-top: -23px;
    img {
      width: 100%;
    }
  }
  &.bttn--bordered {
    border: 1px solid $bb-100;
  }
}
.premium-icon {
  img {
    width: 100%;
  }
}
.desc {
  font-size: 20px;
  padding: 0 5%;
}

.list-row {
  margin-bottom: 30px;
  position: relative;
  strong {
    font-family: $vito-bold;
    font-weight: normal;
  }
  &.premium {
    strong {
      color: #e5b65b;
      i {
      }
    }
  }
  .premium-icon {
    position: absolute;
    width: 46px;
    left: -60px;
    height: 46px;
    top: 50%;
    margin-top: -23px;
  }
}
.filter-box {
  text-align: right;
  margin-bottom: 40px;
  .dropdown {
    text-align: left;
    font-family: $vito-light;
    border-radius: 10px;
    ::v-deep .btn {
      font: 18px/1 $vito-light;
      border: 1px solid #e9e9e8;
      border-radius: 10px;
      &:active,
      &:focus {
        border: 1px solid $bb-100;
      }
      &:after {
        border: none;
        margin-left: 1.25em;
        margin-top: -3px;
        border-right: 1px solid #bbb;
        border-bottom: 1px solid #bbb;
        position: relative;
        transform: rotate(45deg);
        width: 10px;
        height: 10px;
      }
    }
  }
}
.content-box,
.slider-box {
  border-radius: 5px;
  background-color: #ffffff;
  padding: 20px 20px 25px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 80px;
}
.slider-btn-wrap {
  text-align: center;
  margin-top: 50px;
  .bttn {
    font-family: $vito-light;
    font-size: 24px;
    height: 58px;
    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}
.slider-title {
  text-align: center;
  font-family: $vito-bold;
  color: $bb-100;
  //margin-bottom: -15px;
}
.content-box {
  padding: 35px 50px;
  .template-view img {
    width: 100%;
  }
  .flex-row {
    display: flex;
    margin: 0 -15px;
    justify-content: space-between;
    align-items: center;
    .col {
      width: 45%;
    }
  }
}
.content-title {
  text-align: center;
  h5 {
    color: $bb-100;
    text-transform: uppercase;
    margin-bottom: 40px;
    line-height: 80px;
  }
}
.img-container {
  margin-bottom: 40px;
  border: 1px solid #e4e5ea;
  img {
    width: 100%;
    display: block;
  }
}
@media all and (min-width: 1280px) {
  .design-templates-wrapper {
    padding: 0 50px;
  }
}
</style>
