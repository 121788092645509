export const countryList = [
  { name: "Afghanistan", iso_code: "AF", iso_639_1: null },
  { name: "Albania", iso_code: "AL", iso_639_1: null },
  { name: "Algeria", iso_code: "DZ", iso_639_1: null },
  { name: "American Samoa", iso_code: "AS", iso_639_1: null },
  { name: "Andorra", iso_code: "AD", iso_639_1: null },
  { name: "Angola", iso_code: "AO", iso_639_1: null },
  { name: "Anguilla", iso_code: "AI", iso_639_1: null },
  { name: "Antarctica", iso_code: "AQ", iso_639_1: null },
  { name: "Antigua and Barbuda", iso_code: "AG", iso_639_1: null },
  { name: "Argentina", iso_code: "AR", iso_639_1: null },
  { name: "Armenia", iso_code: "AM", iso_639_1: null },
  { name: "Aruba", iso_code: "AW", iso_639_1: null },
  { name: "Australia", iso_code: "AU", iso_639_1: null },
  { name: "Austria", iso_code: "AT", iso_639_1: null },
  { name: "Azerbaijan", iso_code: "AZ", iso_639_1: null },
  { name: "Bahamas", iso_code: "BS", iso_639_1: null },
  { name: "Bahrain", iso_code: "BH", iso_639_1: null },
  { name: "Bangladesh", iso_code: "BD", iso_639_1: null },
  { name: "Barbados", iso_code: "BB", iso_639_1: null },
  { name: "Belarus", iso_code: "BY", iso_639_1: null },
  { name: "Belgium", iso_code: "BE", iso_639_1: null },
  { name: "Belize", iso_code: "BZ", iso_639_1: null },
  { name: "Benin", iso_code: "BJ", iso_639_1: null },
  { name: "Bermuda", iso_code: "BM", iso_639_1: null },
  { name: "Bhutan", iso_code: "BT", iso_639_1: null },
  { name: "Bolivia", iso_code: "BO", iso_639_1: null },
  { name: "Bosnia and Herzegowina", iso_code: "BA", iso_639_1: null },
  { name: "Botswana", iso_code: "BW", iso_639_1: null },
  { name: "Bouvet Island", iso_code: "BV", iso_639_1: null },
  { name: "Brazil", iso_code: "BR", iso_639_1: null },
  { name: "British Indian Ocean Territory", iso_code: "IO", iso_639_1: null },
  { name: "Brunei Darussalam", iso_code: "BN", iso_639_1: null },
  { name: "Bulgaria", iso_code: "BG", iso_639_1: null },
  { name: "Burkina Faso", iso_code: "BF", iso_639_1: null },
  { name: "Burundi", iso_code: "BI", iso_639_1: null },
  { name: "Cambodia", iso_code: "KH", iso_639_1: null },
  { name: "Cameroon", iso_code: "CM", iso_639_1: null },
  { name: "Canada", iso_code: "CA", iso_639_1: null },
  { name: "Cape Verde", iso_code: "CV", iso_639_1: null },
  { name: "Cayman Islands", iso_code: "KY", iso_639_1: null },
  { name: "Central African Republic", iso_code: "CF", iso_639_1: null },
  { name: "Chad", iso_code: "TD", iso_639_1: null },
  { name: "Chile", iso_code: "CL", iso_639_1: null },
  { name: "China", iso_code: "CN", iso_639_1: null },
  { name: "Christmas Island", iso_code: "CX", iso_639_1: null },
  { name: "Cocos (Keeling) Islands", iso_code: "CC", iso_639_1: null },
  { name: "Colombia", iso_code: "CO", iso_639_1: null },
  { name: "Comoros", iso_code: "KM", iso_639_1: null },
  { name: "Congo", iso_code: "CG", iso_639_1: null },
  { name: "Cook Islands", iso_code: "CK", iso_639_1: null },
  { name: "Costa Rica", iso_code: "CR", iso_639_1: null },
  { name: "Cote D'Ivoire", iso_code: "CI", iso_639_1: null },
  { name: "Croatia", iso_code: "HR", iso_639_1: null },
  { name: "Cuba", iso_code: "CU", iso_639_1: null },
  { name: "Cyprus", iso_code: "CY", iso_639_1: null },
  { name: "Czech Republic", iso_code: "CZ", iso_639_1: null },
  { name: "Denmark", iso_code: "DK", iso_639_1: null },
  { name: "Djibouti", iso_code: "DJ", iso_639_1: null },
  { name: "Dominica", iso_code: "DM", iso_639_1: null },
  { name: "Dominican Republic", iso_code: "DO", iso_639_1: null },
  { name: "East Timor", iso_code: "TP", iso_639_1: null },
  { name: "Ecuador", iso_code: "EC", iso_639_1: null },
  { name: "Egypt", iso_code: "EG", iso_639_1: null },
  { name: "El Salvador", iso_code: "SV", iso_639_1: null },
  { name: "Equatorial Guinea", iso_code: "GQ", iso_639_1: null },
  { name: "Eritrea", iso_code: "ER", iso_639_1: null },
  { name: "Estonia", iso_code: "EE", iso_639_1: null },
  { name: "Ethiopia", iso_code: "ET", iso_639_1: null },
  { name: "Falkland Islands (Malvinas)", iso_code: "FK", iso_639_1: null },
  { name: "Faroe Islands", iso_code: "FO", iso_639_1: null },
  { name: "Fiji", iso_code: "FJ", iso_639_1: null },
  { name: "Finland", iso_code: "FI", iso_639_1: null },
  { name: "France", iso_code: "FR", iso_639_1: null },
  { name: "France, Metropolitan", iso_code: "FX", iso_639_1: null },
  { name: "French Guiana", iso_code: "GF", iso_639_1: null },
  { name: "French Polynesia", iso_code: "PF", iso_639_1: null },
  { name: "French Southern Territories", iso_code: "TF", iso_639_1: null },
  { name: "Gabon", iso_code: "GA", iso_639_1: null },
  { name: "Gambia", iso_code: "GM", iso_639_1: null },
  { name: "Georgia", iso_code: "GE", iso_639_1: null },
  { name: "Germany", iso_code: "DE", iso_639_1: null },
  { name: "Ghana", iso_code: "GH", iso_639_1: null },
  { name: "Gibraltar", iso_code: "GI", iso_639_1: null },
  { name: "Greece", iso_code: "GR", iso_639_1: null },
  { name: "Greenland", iso_code: "GL", iso_639_1: null },
  { name: "Grenada", iso_code: "GD", iso_639_1: null },
  { name: "Guadeloupe", iso_code: "GP", iso_639_1: null },
  { name: "Guam", iso_code: "GU", iso_639_1: null },
  { name: "Guatemala", iso_code: "GT", iso_639_1: null },
  { name: "Guinea", iso_code: "GN", iso_639_1: null },
  { name: "Guinea-bissau", iso_code: "GW", iso_639_1: null },
  { name: "Guyana", iso_code: "GY", iso_639_1: null },
  { name: "Haiti", iso_code: "HT", iso_639_1: null },
  { name: "Heard and Mc Donald Islands", iso_code: "HM", iso_639_1: null },
  { name: "Honduras", iso_code: "HN", iso_639_1: null },
  { name: "Hong Kong", iso_code: "HK", iso_639_1: null },
  { name: "Hungary", iso_code: "HU", iso_639_1: null },
  { name: "Iceland", iso_code: "IS", iso_639_1: null },
  { name: "India", iso_code: "IN", iso_639_1: null },
  { name: "Indonesia", iso_code: "ID", iso_639_1: null },
  { name: "Iran (Islamic Republic of)", iso_code: "IR", iso_639_1: null },
  { name: "Iraq", iso_code: "IQ", iso_639_1: null },
  { name: "Ireland", iso_code: "IE", iso_639_1: null },
  { name: "Israel", iso_code: "IL", iso_639_1: "HE" },
  { name: "Italy", iso_code: "IT", iso_639_1: null },
  { name: "Jamaica", iso_code: "JM", iso_639_1: null },
  { name: "Japan", iso_code: "JP", iso_639_1: null },
  { name: "Jordan", iso_code: "JO", iso_639_1: null },
  { name: "Kazakhstan", iso_code: "KZ", iso_639_1: null },
  { name: "Kenya", iso_code: "KE", iso_639_1: null },
  { name: "Kiribati", iso_code: "KI", iso_639_1: null },
  { name: "North Korea", iso_code: "KP", iso_639_1: null },
  { name: "Korea, Republic of", iso_code: "KR", iso_639_1: null },
  { name: "Kuwait", iso_code: "KW", iso_639_1: null },
  { name: "Kyrgyzstan", iso_code: "KG", iso_639_1: null },
  { name: "Lao People's Democratic Republic", iso_code: "LA", iso_639_1: null },
  { name: "Latvia", iso_code: "LV", iso_639_1: null },
  { name: "Lebanon", iso_code: "LB", iso_639_1: null },
  { name: "Lesotho", iso_code: "LS", iso_639_1: null },
  { name: "Liberia", iso_code: "LR", iso_639_1: null },
  { name: "Libyan Arab Jamahiriya", iso_code: "LY", iso_639_1: null },
  { name: "Liechtenstein", iso_code: "LI", iso_639_1: null },
  { name: "Lithuania", iso_code: "LT", iso_639_1: null },
  { name: "Luxembourg", iso_code: "LU", iso_639_1: null },
  { name: "Macau", iso_code: "MO", iso_639_1: null },
  { name: "Macedonia", iso_code: "MK", iso_639_1: null },
  { name: "Madagascar", iso_code: "MG", iso_639_1: null },
  { name: "Malawi", iso_code: "MW", iso_639_1: null },
  { name: "Malaysia", iso_code: "MY", iso_639_1: null },
  { name: "Maldives", iso_code: "MV", iso_639_1: null },
  { name: "Mali", iso_code: "ML", iso_639_1: null },
  { name: "Malta", iso_code: "MT", iso_639_1: null },
  { name: "Marshall Islands", iso_code: "MH", iso_639_1: null },
  { name: "Martinique", iso_code: "MQ", iso_639_1: null },
  { name: "Mauritania", iso_code: "MR", iso_639_1: null },
  { name: "Mauritius", iso_code: "MU", iso_639_1: null },
  { name: "Mayotte", iso_code: "YT", iso_639_1: null },
  { name: "Mexico", iso_code: "MX", iso_639_1: null },
  { name: "Micronesia, Federated States of", iso_code: "FM", iso_639_1: null },
  { name: "Moldova, Republic of", iso_code: "MD", iso_639_1: null },
  { name: "Monaco", iso_code: "MC", iso_639_1: null },
  { name: "Mongolia", iso_code: "MN", iso_639_1: null },
  { name: "Montserrat", iso_code: "MS", iso_639_1: null },
  { name: "Morocco", iso_code: "MA", iso_639_1: null },
  { name: "Mozambique", iso_code: "MZ", iso_639_1: null },
  { name: "Myanmar", iso_code: "MM", iso_639_1: null },
  { name: "Namibia", iso_code: "NA", iso_639_1: null },
  { name: "Nauru", iso_code: "NR", iso_639_1: null },
  { name: "Nepal", iso_code: "NP", iso_639_1: null },
  { name: "Netherlands", iso_code: "NL", iso_639_1: null },
  { name: "Netherlands Antilles", iso_code: "AN", iso_639_1: null },
  { name: "New Caledonia", iso_code: "NC", iso_639_1: null },
  { name: "New Zealand", iso_code: "NZ", iso_639_1: null },
  { name: "Nicaragua", iso_code: "NI", iso_639_1: null },
  { name: "Niger", iso_code: "NE", iso_639_1: null },
  { name: "Nigeria", iso_code: "NG", iso_639_1: null },
  { name: "Niue", iso_code: "NU", iso_639_1: null },
  { name: "Norfolk Island", iso_code: "NF", iso_639_1: null },
  { name: "Northern Mariana Islands", iso_code: "MP", iso_639_1: null },
  { name: "Norway", iso_code: "NO", iso_639_1: null },
  { name: "Oman", iso_code: "OM", iso_639_1: null },
  { name: "Pakistan", iso_code: "PK", iso_639_1: null },
  { name: "Palau", iso_code: "PW", iso_639_1: null },
  { name: "Panama", iso_code: "PA", iso_639_1: null },
  { name: "Papua New Guinea", iso_code: "PG", iso_639_1: null },
  { name: "Paraguay", iso_code: "PY", iso_639_1: null },
  { name: "Peru", iso_code: "PE", iso_639_1: null },
  { name: "Philippines", iso_code: "PH", iso_639_1: null },
  { name: "Pitcairn", iso_code: "PN", iso_639_1: null },
  { name: "Poland", iso_code: "PL", iso_639_1: null },
  { name: "Portugal", iso_code: "PT", iso_639_1: null },
  { name: "Puerto Rico", iso_code: "PR", iso_639_1: null },
  { name: "Qatar", iso_code: "QA", iso_639_1: null },
  { name: "Reunion", iso_code: "RE", iso_639_1: null },
  { name: "Romania", iso_code: "RO", iso_639_1: null },
  { name: "Russian Federation", iso_code: "RU", iso_639_1: null },
  { name: "Rwanda", iso_code: "RW", iso_639_1: null },
  { name: "Saint Kitts and Nevis", iso_code: "KN", iso_639_1: null },
  { name: "Saint Lucia", iso_code: "LC", iso_639_1: null },
  { name: "Saint Vincent and the Grenadines", iso_code: "VC", iso_639_1: null },
  { name: "Samoa", iso_code: "WS", iso_639_1: null },
  { name: "San Marino", iso_code: "SM", iso_639_1: null },
  { name: "Sao Tome and Principe", iso_code: "ST", iso_639_1: null },
  { name: "Saudi Arabia", iso_code: "SA", iso_639_1: null },
  { name: "Senegal", iso_code: "SN", iso_639_1: null },
  { name: "Seychelles", iso_code: "SC", iso_639_1: null },
  { name: "Sierra Leone", iso_code: "SL", iso_639_1: null },
  { name: "Singapore", iso_code: "SG", iso_639_1: null },
  { name: "Slovak Republic", iso_code: "SK", iso_639_1: null },
  { name: "Slovenia", iso_code: "SI", iso_639_1: null },
  { name: "Solomon Islands", iso_code: "SB", iso_639_1: null },
  { name: "Somalia", iso_code: "SO", iso_639_1: null },
  { name: "South Africa", iso_code: "ZA", iso_639_1: null },
  { name: "South Georgia &amp; South Sandwich Islands", iso_code: "GS", iso_639_1: null },
  { name: "Spain", iso_code: "ES", iso_639_1: null },
  { name: "Sri Lanka", iso_code: "LK", iso_639_1: null },
  { name: "St. Helena", iso_code: "SH", iso_639_1: null },
  { name: "St. Pierre and Miquelon", iso_code: "PM", iso_639_1: null },
  { name: "Sudan", iso_code: "SD", iso_639_1: null },
  { name: "Suriname", iso_code: "SR", iso_639_1: null },
  { name: "Svalbard and Jan Mayen Islands", iso_code: "SJ", iso_639_1: null },
  { name: "Swaziland", iso_code: "SZ", iso_639_1: null },
  { name: "Sweden", iso_code: "SE", iso_639_1: null },
  { name: "Switzerland", iso_code: "CH", iso_639_1: null },
  { name: "Syrian Arab Republic", iso_code: "SY", iso_639_1: null },
  { name: "Taiwan", iso_code: "TW", iso_639_1: null },
  { name: "Tajikistan", iso_code: "TJ", iso_639_1: null },
  { name: "Tanzania, United Republic of", iso_code: "TZ", iso_639_1: null },
  { name: "Thailand", iso_code: "TH", iso_639_1: null },
  { name: "Togo", iso_code: "TG", iso_639_1: null },
  { name: "Tokelau", iso_code: "TK", iso_639_1: null },
  { name: "Tonga", iso_code: "TO", iso_639_1: null },
  { name: "Trinidad and Tobago", iso_code: "TT", iso_639_1: null },
  { name: "Tunisia", iso_code: "TN", iso_639_1: null },
  { name: "Turkey", iso_code: "TR", iso_639_1: null },
  { name: "Turkmenistan", iso_code: "TM", iso_639_1: null },
  { name: "Turks and Caicos Islands", iso_code: "TC", iso_639_1: null },
  { name: "Tuvalu", iso_code: "TV", iso_639_1: null },
  { name: "Uganda", iso_code: "UG", iso_639_1: null },
  { name: "Ukraine", iso_code: "UA", iso_639_1: null },
  { name: "United Arab Emirates", iso_code: "AE", iso_639_1: null },
  { name: "United Kingdom", iso_code: "GB", iso_639_1: null },
  { name: "United States", iso_code: "US", iso_639_1: null },
  { name: "United States Minor Outlying Islands", iso_code: "UM", iso_639_1: null },
  { name: "Uruguay", iso_code: "UY", iso_639_1: null },
  { name: "Uzbekistan", iso_code: "UZ", iso_639_1: null },
  { name: "Vanuatu", iso_code: "VU", iso_639_1: null },
  { name: "Vatican City State (Holy See)", iso_code: "VA", iso_639_1: null },
  { name: "Venezuela", iso_code: "VE", iso_639_1: null },
  { name: "Viet Nam", iso_code: "VN", iso_639_1: null },
  { name: "Virgin Islands (British)", iso_code: "VG", iso_639_1: null },
  { name: "Virgin Islands (U.S.)", iso_code: "VI", iso_639_1: null },
  { name: "Wallis and Futuna Islands", iso_code: "WF", iso_639_1: null },
  { name: "Western Sahara", iso_code: "EH", iso_639_1: null },
  { name: "Yemen", iso_code: "YE", iso_639_1: null },
  { name: "Yugoslavia", iso_code: "YU", iso_639_1: null },
  { name: "Democratic Republic of Congo", iso_code: "CD", iso_639_1: null },
  { name: "Zambia", iso_code: "ZM", iso_639_1: null },
  { name: "Zimbabwe", iso_code: "ZW", iso_639_1: null },
];

export const getLangCodeFromLocation = (location) => {
  const found = countryList.find(
    (v) => v.iso_code.toLocaleLowerCase() === location.toLocaleLowerCase()
  );
  const [userLanguage] = (window.navigator.language || window.navigator.userLanguage).split("-");
  return (
    (found?.iso_639_1 && found.iso_639_1.toLocaleLowerCase()) ||
    userLanguage.toLocaleLowerCase() ||
    "en"
  );
};

export const stripeSupportedTransferCountries = [
  "AU",
  "AT",
  "BE",
  "BR",
  "BG",
  "CA",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GI",
  "GR",
  "HK",
  "HU",
  "IN",
  "IE",
  "IT",
  "JP",
  "LV",
  "LI",
  "LT",
  "LU",
  "MY",
  "MT",
  "MX",
  "NL",
  "NZ",
  "NO",
  "PL",
  "PT",
  "RO",
  "SG",
  "SK",
  "SI",
  "ES",
  "SE",
  "CH",
  "AE",
  "GB",
  "US",
];

export default { countryList, stripeSupportedTransferCountries };
