<template>
  <div id="integration_export">
    <!-- Modal Component -->
    <b-modal id="integrationExport" size="lg" @hidden="clearData">
      <div slot="modal-header">
        <h2>{{ $t("dashboard.import_integration") }}</h2>
      </div>
      <div slot="modal-footer">
        <template v-if="integrationsList.length == 0">
          <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
            {{ $t("dashboard.integration_export_close") }}
          </button>
          <button
            :disabled="!selectedItem || exporting"
            class="bttn bttn--lg bttn--orange el-right"
            @click="exportList"
          >
            {{ $t("dashboard.mailchimp_export") }}
          </button>
        </template>
        <template v-else>
          <button
            :class="
              exportedList.length > 0 || (exportedList.length > 0 && !exportSuccess)
                ? 'bttn bttn--lg bttn--blue'
                : 'bttn bttn--lg bttn--orange el-right'
            "
            v-if="!exportSuccess"
            @click.prevent="hideModal"
          >
            {{ $t("dashboard.integration_export_close") }}
          </button>

          <button
            class="bttn bttn--lg bttn--orange el-right"
            v-if="exportSuccess"
            @click.prevent="hideModal"
          >
            {{ $t("dashboard.integration_export_close") }}
          </button>

          <button
            :disabled="!selectedItem || exporting"
            v-if="exportedList.length > 0 || (exportedList.length > 0 && !exportSuccess)"
            class="bttn bttn--lg bttn--orange el-right"
            @click="exportList"
          >
            {{ $t("dashboard.mailchimp_export") }}
          </button>
        </template>
      </div>

      <div v-if="errors.length > 0" class="col-12">
        <error :errors="errors"></error>
      </div>

      <div v-if="!exporting && !exportSuccess">
        <div v-if="integrationsList.length == 0" class="empty">
          {{ $t("integration.not_have_this_integration") }}
          <div class="add-integration-btn">
            <button class="bttn bttn--lg bttn--orange el-right" @click="goToIntegration">
              {{ $t("dashboard.integration_export_add_mailchimp") }}
            </button>
          </div>
        </div>
        <div v-else>
          <div class="integration-list" v-if="exportedList.length > 0">
            <div class="plate form-group" v-for="(item, index) in exportedList" :key="index">
              <div class="form-radio">
                <input
                  :id="'v-item-' + index"
                  type="radio"
                  class="form-radio__input"
                  :value="item.id"
                  v-model="selectedItem"
                />
                <label :for="'v-item-' + index" class="form-radio__label">{{
                  item.attributes.name
                }}</label>
              </div>
            </div>
          </div>
          <div
            v-if="integrationsList.length > 0 && exportedList.length == 0 && isloaded"
            class="empty"
          >
            {{ $t("integration.not_have_list_in_mailchimp") }}
          </div>
        </div>

        <div class="loading" v-if="loading">
          <LdsSpinner />
        </div>

        <div class="loading" v-if="loadingMailChimpList && !loading">
          <LdsSpinner />
        </div>
      </div>

      <div v-else>
        <div class="exporting" v-if="exporting">{{ $t("integration.exporting_process") }}</div>
        <div class="success_export" v-if="exportSuccess">
          {{ $t("integration.successfuly_exported") }}
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";
import error from "@/components/errors.vue";

export default {
  name: "integration-export",
  data() {
    return {
      errors: [],
      exportedList: [],
      selectedItem: "",
      location: "",
      exporting: false,
      exportSuccess: false,
      loadingMailChimpList: false,
      isloaded: false,
    };
  },
  computed: mapState({
    integrationsList: function () {
      return this.$store.getters.getintegratuimByType("mailchimp");
    },
    loading: (state) => {
      return state.integrations.loadingIntegrations;
    },

    selectedCampaignId: (state) => {
      return state.dashboard.selectedCampaign;
    },
  }),

  watch: {
    integrationsList: function () {
      if (this.integrationsList && this.integrationsList.length > 0) {
        this.getMailChimpIntegrationList(this.integrationsList[0].id);
      }
    },
    exportedList: function () {
      if (this.exportedList.length === 1) {
        this.selectedItem = this.exportedList[0].id;
      }
    },
  },

  components: {
    error,
    LdsSpinner,
  },

  methods: {
    openModal(location) {
      this.location = location;
      this.$root.$emit("bv::show::modal", "integrationExport");
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "integrationExport");
    },

    goToIntegration() {
      this.$router.push({ path: "/integration" });
    },

    exportList() {
      this.location === "donation"
        ? this.exportToMailChimpDonationList()
        : this.exportToMailChimpRemindnList();
    },

    getMailChimpIntegrationList(id) {
      this.errors = [];
      let orgId = localStorage.getItem("orgId");
      this.loadingMailChimpList = true;
      axios
        .get(urls.getMailChimpIntegrationList.replace(":orgId", orgId).replace(":id", id))
        .then((response) => {
          this.exportedList = response.data.data;
          this.loadingMailChimpList = false;
          this.isloaded = true;
        })
        .catch((e) => {
          this.loadingMailChimpList = false;
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },

    exportToMailChimpDonationList() {
      this.errors = [];
      let orgId = localStorage.getItem("orgId");
      this.exporting = true;
      this.exportedList = [];
      axios
        .post(
          urls.exportToMailChimpDonationList
            .replace(":orgId", orgId)
            .replace(":id", this.integrationsList[0].id)
            .replace(":campaignId", this.selectedCampaignId)
            .replace(":listId", this.selectedItem)
        )
        .then(() => {
          this.exporting = false;
          this.exportSuccess = true;
        })
        .catch((e) => {
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },

    exportToMailChimpRemindnList() {
      this.errors = [];
      let orgId = localStorage.getItem("orgId");
      this.exportedList = [];
      axios
        .post(
          urls.exportToMailChimpRemindnList
            .replace(":orgId", orgId)
            .replace(":id", this.integrationsList[0].id)
            .replace(":campaignId", this.selectedCampaignId)
            .replace(":listId", this.selectedItem)
        )
        .then(() => {
          this.hideModal();
        })
        .catch((e) => {
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },

    clearData: function () {
      this.exportedList = [];
      this.selectedItem = "";
      this.location = "";
      this.exportSuccess = false;
      this.isloaded = false;
      this.errors = [];
    },
  },
};
</script>

<style scoped>
.el-right {
  margin-left: auto;
}
.empty {
  min-height: 200px;
}

.form-radio {
  padding: 15px 50px;
}
.form-radio__label {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 15px 50px;
}
.form-radio__label:before {
  margin-left: 24px;
}
.form-radio__label:after {
  margin-left: 24px;
}
.plate {
  padding: 0;
}

.form-radio {
  width: 100%;
  position: relative;
  padding: 0;
}

.loading {
  top: 0;
  width: 100%;
  left: 0;
}
.add-integration-btn {
  margin-top: 40px;
}
</style>
