<template>
  <form class="gateway-form">
    <error :errors="errors"></error>

    <div class="form-block">
      <div class="row">
        <div class="col-12">
          <h3>{{ $t("gateways.stripe_acc_detalis") }}</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.legal_entity")
            }}</label>
            <b-form-select required>
              <option>{{ entity.attributes.name }}</option>
            </b-form-select>
          </div>
        </div>

        <div class="crate-legal-entity col-12">
          <p>
            {{ $t("gateways.create_entity_text") }}
          </p>
          <button type="button" class="bttn bttn--sm bttn--orange" name="button">
            {{ $t("gateways.create_entity_button") }}
          </button>

          <StripeSupportedTransferCountries />
        </div>

        <div class="loadings">
          <LdsSpinner />
        </div>

        <div class="col-12 col-lg-12">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.org_type")
            }}</label>
            <b-form-select required />
          </div>
        </div>
      </div>
    </div>

    <div class="form-block">
      <div class="row">
        <div class="col-12">
          <h3>{{ $t("gateways.bank_detalis") }}</h3>
        </div>
      </div>
      <div class="row align-items-end">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.bank_account_currency")
            }}</label>
            <b-form-select required />
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.business_name")
            }}</label>
            <b-form-input required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.iban_bank_account_number")
            }}</label>
            <b-form-input required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label for="">{{ $t("gateways.sort_code") }}</label>
            <b-form-input type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required">{{
              $t("gateways.bsb")
            }}</label>
            <b-form-input required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.iban")
            }}</label>
            <b-form-input required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.bank_account_number")
            }}</label>
            <b-form-input required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.bank_account_routing_number")
            }}</label>
            <b-form-input required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.bank_code")
            }}</label>
            <b-form-input required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.clearing_code")
            }}</label>
            <b-form-input required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.branch_code")
            }}</label>
            <b-form-input required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.transit_number")
            }}</label>
            <b-form-input required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.institution_number")
            }}</label>
            <b-form-input required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <!--
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{ $t('gateways.statement') }}</label>
            <b-form-input v-model="stripeData.data.attributes.statement" required type="text"></b-form-input>
          </div>-->

          <div class="form-group form-unit form-unit--addon">
            <label class="form-unit__label form-unit__label--upper-sm form-unit__label--required">
              {{ $t("gateways.statement") }}</label
            >

            <div class="form-unit__addon-wrap">
              <div class="form-unit__addon-icon form-unit__addon-icon--wide">CHARIDY</div>
              <input type="text" required class="form-control form-unit__input" />
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.legal_entity_dob")
            }}</label>
            <b-form-input required type="date"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.entity_country_full")
            }}</label>
            <b-form-input required readonly type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">
              <span> </span>
            </label>
            <b-form-input required type="text"></b-form-input>
          </div>
        </div>
      </div>
    </div>

    <div class="gateway-form__notice">
      By registering your account, you agree to our
      <a target="_blank" :href="`${appHost}/termsconditions`">Services Agreement</a> and the
      <a target="_blank" href="https://stripe.com/connect-account/legal"
        >Stripe Connected Account Agreement</a
      >.
    </div>

    <error :errors="errors"></error>

    <div class="gateway-form__action">
      <button type="button" name="button">{{ $t("gateways.close") }}</button>
      <button type="submit">{{ $t("gateways.save") }}</button>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";
import StripeSupportedTransferCountries from "@/views/gateways/subcomponents/stripeSupportedTransferCountries.vue";
import { appHost } from "@/constants/urls.js";
import error from "@/components/errors.vue";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";

export default {
  data() {
    return {
      appHost,
      stripeData: {
        data: {},
      },

      statementShortened: "",

      entities: [],

      legal_entity_id: 0,

      legal_entity_dob: null,

      selectOrganizationType: "company",

      listInputsStripe: null,

      loadingStripeData: false,

      loadingLegalEntiti: true,

      stripeApiData: null,

      stripeInputsApi: null,

      countryList: [],

      errors: [],

      sending: false,

      loadingEntity: true,

      bankCode: "",

      branchCode: "",

      entityCountryModel: "",

      organizationType: [
        { value: null, text: "Select type" },
        { value: "individual", text: this.$t("gateways.org_type_individual") },
        { value: "company", text: this.$t("gateways.org_type_company") },
      ],
    };
  },

  components: {
    error: error,
    Multiselect,
    Datepicker,
    StripeSupportedTransferCountries,
    LdsSpinner,
  },

  computed: mapState({}),

  methods: {},
};
</script>

<style lang="scss" scoped>
.loadings {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.alert-danger {
  width: auto;
  margin-left: 0;
  li {
    width: auto;
    margin-left: 0;
  }
}

.crate-legal-entity {
  button {
    display: block;
    margin: 20px auto;
  }
}
</style>
