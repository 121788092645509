export const blobToFile = (theBlob, fileName, type = "") => {
  var file = new File([theBlob], fileName, {
    type: type || theBlob.type,
    lastModified: Date.now(),
  });
  return file;
};

export const processImageFile = (file) => {
  if (!/image/i.test(file.type)) {
    return Promise.resolve(false);
  }

  return new Promise((resolve) => {
    const blob = new Blob([file], { type: file.type });

    if (file.type.includes("gif")) {
      const blobWebp = new Blob([file], { type: "image/webp" });

      return resolve(blobWebp);
    }

    window.URL = window.URL || window.webkitURL;

    const blobURL = window.URL.createObjectURL(blob); // and get it's URL

    // helper Image object
    const image = new Image();
    image.src = blobURL;

    image.onload = () => {
      resize(image).then((resized) => {
        window.URL.revokeObjectURL(blobURL);
        resolve(resized);
      });
    };
  });
};

export const resize = (img, max_width = 0, max_height = 0) => {
  const canvas = document.createElement("canvas");

  let width = img.width;
  let height = img.height;

  if (max_width && max_height) {
    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > max_width) {
        //height *= max_width / width;
        height = Math.round((height *= max_width / width));
        width = max_width;
      }
    } else {
      if (height > max_height) {
        //width *= max_height / height;
        width = Math.round((width *= max_height / height));
        height = max_height;
      }
    }
  }

  // resize the canvas and draw the image data into it
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, width, height);

  return new Promise((resolve) => {
    canvas.toBlob((b) => resolve(b), "image/webp", 0.8); // get the data from canvas
  });
};
