export const isArray = (val) => Array.isArray(val);
export const isPlainObject = (obj) => Object.prototype.toString.call(obj) === "[object Object]";
export const isFunction = (value) =>
  value &&
  (Object.prototype.toString.call(value) === "[object Function]" ||
    "function" === typeof value ||
    value instanceof Function);
export function unwrapValues(obj) {
  return Object.fromEntries(Object.entries(obj).map(([, v]) => v?.value || v));
}
