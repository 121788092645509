<template>
  <div>
    <b-skeleton-wrapper :loading="checkEmailSettingInOrgLoading">
      <template #loading>
        <b-skeleton type="button"></b-skeleton>
      </template>
      <button v-show="team_email_messaging" class="bttn bttn--sm bttn--orange" @click="open">
        {{ $t("dashboard.open_email_message", "Send email") }}
      </button>
    </b-skeleton-wrapper>
    <b-modal
      id="EmailMessageModal"
      ref="EmailMessageModal"
      size="lg"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <div slot="modal-header">
        <h2>{{ $t(`dashboard.email_message_modal_title`) }}</h2>
      </div>
      <error :response.sync="error" :alert="true"></error>
      <section v-if="step === 1">
        <form id="EmailMessageModalForm1" @submit.prevent="submit1">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required">
                    {{ $t("dashboard.email_message_which_team") }}
                  </label>
                </template>

                <div v-if="importGroup">
                  <b>
                    {{ $t("dashboard.message_to_imported_group", "The Imported group") }}
                  </b>
                </div>
                <div v-else>
                  <b-form-radio
                    v-model="selected"
                    name="target-radios"
                    value="target-bucket"
                    class="mb-2"
                  >
                    <div class="pl-1" :class="{ 'disabled-area': selected !== 'target-bucket' }">
                      <b-form-select
                        v-model="targetBucket"
                        :options="options"
                        :disabled="selected !== 'target-bucket'"
                        required
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled>
                            {{ $t("dashboard.sms_message_please_select_option") }}
                          </b-form-select-option>
                        </template>
                      </b-form-select>

                      <b-row
                        v-if="targetBucket === 'has_goal_not_reached_between_custom_pct_range'"
                        class="pt-2"
                      >
                        <b-col>
                          <b-form-group label="From">
                            <b-form-input
                              v-model.number="formPayload.custom_pct_range.from"
                              type="text"
                              inputmode="numeric"
                              pattern="[0-9]*"
                              min="0"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>

                        <b-col>
                          <b-form-group label="To">
                            <b-form-input
                              v-model.number="formPayload.custom_pct_range.to"
                              type="text"
                              inputmode="numeric"
                              pattern="[0-9]*"
                              min="0"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </b-form-radio>

                  <b-form-radio
                    v-model="selected"
                    name="target-radios"
                    value="individual-teams"
                    :disabled="selected === 'individual-teams'"
                  >
                    <div class="pl-1">
                      <multiselect-search
                        v-model="selectedTeams"
                        :disabled="selected !== 'individual-teams'"
                        :open-prefetch="true"
                        :search-url="getSearchUrl"
                        :search-options="searchOptions"
                        :multiselectClass="{ cms: true }"
                        :multiple="true"
                        :taggable="true"
                      >
                        <template #no-options>
                          {{
                            $t(
                              "dashboard.team_search_no_options",
                              "Please enter 1 or more characters"
                            )
                          }}
                        </template>
                        <template #no-more-infinite>
                          {{ $t("dashboard.team_search_no_more", "No more teams") }}
                        </template>
                      </multiselect-search>
                    </div>
                  </b-form-radio>

                  <div v-if="selected === 'individual-teams'" class="pl-4 mt-2">
                    <b-form-checkbox v-model="formPayload.include_children_teams">
                      {{ $t("dashboard.message_include_children_teams", "Include children teams") }}
                    </b-form-checkbox>

                    <b-form-checkbox v-model="formPayload.include_grandchildren_teams">
                      {{
                        $t(
                          "dashboard.message_include_grandchildren_teams",
                          "Include grandchildren teams"
                        )
                      }}
                    </b-form-checkbox>

                    <b-form-checkbox
                      v-if="
                        formPayload.include_grandchildren_teams ||
                        formPayload.include_children_teams
                      "
                      v-model="formPayload.exclude_parent_teams"
                    >
                      {{
                        $t(
                          "dashboard.message_exclude_parent_teams",
                          "do not send to the parent teams itself"
                        )
                      }}
                    </b-form-checkbox>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                :description="
                  $t(
                    'dashboard.email_message_replay_to_desc',
                    'Use this field to resend message to person'
                  )
                "
              >
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required">{{
                    $t("dashboard.email_message_replay_to", "Replay To")
                  }}</label>
                </template>
                <b-form-input type="text" v-model="formPayload.replay_to" required></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label">{{
                    $t("dashboard.email_message_subject")
                  }}</label>
                </template>
                <b-form-input
                  type="text"
                  :placeholder="defaultSubject"
                  v-model="formPayload.subject"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required">{{
                    $t("dashboard.email_message_data")
                  }}</label>
                </template>
                <quill-editor-variables
                  v-model="formPayload.message"
                  emitWithChangeSource
                  :tags="tags"
                  :editorOptions="editorOptions"
                >
                  <template #btn-content>
                    {{ $t("edit_campaign.email_template_edit_title") }}
                  </template>
                  <template #description>
                    {{ $t("edit_campaign.email_template_var_description") }}
                  </template>
                  <template #tags-full-text="{ tagsFull }">
                    {{
                      tagsFull
                        ? $t("edit_campaign.email_template_show_description")
                        : $t("edit_campaign.email_template_hide_description")
                    }}
                  </template>
                  <template #between-tags-and-editor="{ unwrappedValueInline }">
                    <OrgMessageTemplates
                      type="email"
                      :templateText="unwrappedValueInline"
                      @update:templateText="(v) => (formPayload.message = { value: v })"
                    />
                  </template>
                </quill-editor-variables>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </section>
      <section v-else-if="step === 2">
        <form id="EmailMessageModalForm2" @submit.prevent="submit2">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label">
                    <b>{{
                      $t(
                        "dashboard.email_message_step2_you_are_about",
                        "You are about to send emails to"
                      )
                    }}</b>
                    &nbsp;
                    <u>{{ targetTeamBucketText }}</u>
                  </label>
                </template>
              </b-form-group>
            </b-col>
            <OrgNotificationFromEmailLink v-slot="{ notificationsFromEmail }">
              <b-col cols="12">
                <b-form-group>
                  <template v-slot:label>
                    <label class="form-group__label">
                      {{
                        $t(
                          "account.org_notifications_from_email_note",
                          "The receipt will be sent from email: {email}",
                          { email: notificationsFromEmail }
                        )
                      }}
                    </label>
                  </template>
                </b-form-group>
              </b-col>
            </OrgNotificationFromEmailLink>
            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label">
                    <b>{{ $t("dashboard.email_message_step2_message") }}</b>
                  </label>
                </template>
                <quill-editor-variables v-model="formPayload.message" :disabled="true" />
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </section>
      <div slot="modal-footer" class="d-flex flex-row">
        <button class="mx-2 bttn bttn--lg bttn--blue" @click="close">
          {{ $t(`dashboard.cancel_btn`) }}
        </button>

        <button v-if="step === 2" class="mx-2 bttn bttn--lg bttn--blue" @click="back">
          {{ $t(`dashboard.sms_message_modal_back`) }}
        </button>

        <div class="ml-auto" v-if="step === 1">
          <button type="submit" class="bttn bttn--lg bttn--orange" form="EmailMessageModalForm1">
            {{ $t(`dashboard.sms_message_modal_save`) }}
          </button>
        </div>

        <b-overlay
          :show="loading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="ml-auto d-inline-block"
          v-else-if="step === 2"
        >
          <button
            type="submit"
            class="bttn bttn--lg bttn--orange"
            form="EmailMessageModalForm2"
            :disabled="loading"
          >
            {{ $t(`dashboard.sms_message_modal_send`) }}
          </button>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import error from "@/components/error-message.vue";
import quillEditorVariables from "@/common-components/ui-elements/quill-editor-variables.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { notificationsMixin } from "@/mixins";
import urls from "@/constants/urls";
import MultiselectSearch from "@/components/multiselect-search.vue";
import { unwrapContentInline, unwrapContentValue } from "@/components/vue-quill-editor/utils";
import { defineComponent } from "vue";
import OrgNotificationFromEmailLink from "@/views/account/subcomponents/orgNotificationFromEmailLink.vue";
import OrgMessageTemplates from "@/components/org-message-templates.vue";

export default defineComponent({
  mixins: [notificationsMixin],
  components: {
    error,
    quillEditorVariables,
    MultiselectSearch,
    OrgNotificationFromEmailLink,
    OrgMessageTemplates,
  },
  data() {
    return {
      step: 1,
      error: null,
      formPayload: {
        target_bucket: null,
        subject: "",
        message: "",
        group: "",
        cc_list: [],
        replay_to: "",
        exclude_parent_teams: false,
        include_children_teams: false,
        include_grandchildren_teams: false,
        custom_pct_range: {
          from: 0,
          to: 100,
        },
      },
      editorOptions: {
        modules: {
          toolbar: {
            container: [
              ["bold", "underline", "strike", "italic"],
              ["blockquote"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ align: [] }],
              [{ color: [] }, { background: [] }],
              ["image"],
              ["link"],
              ["clean"],
            ],
          },
          imageResize: {
            modules: ["Resize", "DisplaySize"],
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
          },
        },
      },
      previewEditorValue: "",
      importGroup: false,
      targetBucket: null,
      selected: "target-bucket",
      selectedTeams: [],
      options: [
        {
          text: this.$t("dashboard.sms_message_options_all_teams"),
          value: "all",
        },
        {
          text: this.$t("dashboard.sms_message_options_all_teams_no_goal_reach"),
          value: "has_goal_not_reached",
        },
        {
          text: this.$t("dashboard.sms_message_options_teams_no_goal"),
          value: "has_no_goal",
        },
        {
          text: this.$t(
            "dashboard.sms_message_options_teams_no_donations",
            "All teams without donations"
          ),
          value: "has_no_donations",
        },
        {
          text: this.$t(
            "dashboard.sms_message_options_teams_with_donations",
            "All teams with donations"
          ),
          value: "has_donations",
        },
        {
          label: this.$t("dashboard.sms_message_options_teams_goal_no_reach"),
          options: [
            {
              text: "<=25%",
              value: "has_goal_not_reached_below_25pct",
            },
            {
              text: "<=50%",
              value: "has_goal_not_reached_below_50pct",
            },
            {
              text: "<=75%",
              value: "has_goal_not_reached_below_75pct",
            },
            {
              text: "<=100%",
              value: "has_goal_not_reached_below_100pct",
            },
          ],
        },
        {
          text: this.$t(
            "dashboard.sms_message_options_between_custom_pct_range",
            "Custom percentage range"
          ),
          value: "has_goal_not_reached_between_custom_pct_range",
        },
        {
          text: this.$t("dashboard.email_message_options_hidden_teams", "Only hidden teams"),
          value: "only_hidden_teams",
        },
        {
          text: this.$t("dashboard.email_message_options_shown_teams", "Only shown teams"),
          value: "only_shown_teams",
        },
      ],
    };
  },
  mounted() {
    this.checkEmailSettingInOrg({ oId: this.oId });
  },
  computed: {
    ...mapState({
      cId: (state) => state.dashboard.selectedCampaign,
      loading: (state) => state.messaging.loading.saveMessaging_email,
      team_email_messaging: (state) => state.messaging.team_email_messaging,
      checkEmailSettingInOrgLoading: (state) => state.messaging.loading.checkEmailSettingInOrg,
      contacts: (state) => state.account.contacts,
      orgEmail: (state) => state.account.account.data.attributes.email,
    }),
    ...mapGetters({
      metas: "getMetasInSelectedCampaign",
      getCurrentOrganization: "getCurrentOrganization",
    }),
    oId() {
      return localStorage.getItem("orgId");
    },
    donorSupportContact() {
      return this.contacts.find((el) => el.attributes.type === "donor_support");
    },
    targetTeamBucketText() {
      if (this.importGroup) {
        return this.$t("dashboard.message_to_imported_group", "The Imported group");
      }

      if (this.selected === "individual-teams") {
        return this.selectedTeams.map((t) => this.searchOptions.customLabel(t)).join(", ");
      }

      let text = null;
      this.options.forEach((v) => {
        if (v.options) {
          return v.options.forEach((o) => {
            o.value === this.targetBucket && (text = `${v.label} ${o.text}`);
          });
        } else {
          if (v.value === this.targetBucket) {
            switch (this.targetBucket) {
              case "has_goal_not_reached_between_custom_pct_range": {
                const { from, to } = this.formPayload.custom_pct_range;
                text = `${this.$t(
                  "dashboard.sms_message_options_teams_goal_between",
                  "Teams with progress between below point:"
                )} ${from}% - ${to}%`;
                break;
              }

              default:
                text = v.text;
                break;
            }
          }
        }
      });
      return text;
    },
    getSearchUrl() {
      const cID = this.cId;
      const orgID = localStorage.getItem("orgId");

      if (Number(cID) === 0) {
        throw new Error("getSearchUrl email msg campaign id is 0");
      }

      return urls.apiTeams.replace(":orgId", orgID).replace(":campaignId", cID);
    },

    searchOptions() {
      return {
        placeholder: this.$t(
          "dashboard.email_team_search_label_placeholder",
          "Add individual teams"
        ),
        changeParams: ({ query, page, per_page: pp }) => ({
          q: query,
          page: page,
          limit: pp,
        }),
        customLabel: (e) => `[${e.id}] ${e.name}`,
        processResults: (data) =>
          data.map((el) => ({
            id: el.id,
            ...el.attributes,
          })),
      };
    },
    tags() {
      let options = [
        {
          value: "[TEAM_ID]",
          text: this.$t("dashboard.email_message_tag_team_id"),
        },
        {
          value: "[SHORTLINK]",
          text: this.$t("dashboard.email_message_tag_team_shortlink", "Team shortlink"),
        },
        {
          value: "[GOAL]",
          text: this.$t("dashboard.email_message_tag_goal"),
        },
        {
          value: "[LEADER_NAME]",
          text: this.$t("dashboard.email_message_tag_leader_name"),
        },
        {
          value: "[TEAM_NAME]",
          text: this.$t("dashboard.email_message_tag_team_name"),
        },
        {
          value: "[TEAM_FULL_LINK]",
          text: this.$t("dashboard.email_message_tag_team_full_link"),
        },
        {
          value: "[RAISED_AMOUNT]",
          text: this.$t("dashboard.email_message_tag_raised_amount"),
        },
        {
          value: "[DONOR_COUNT]",
          text: this.$t("dashboard.email_message_tag_donor_count"),
        },
        {
          value: "[DONOR_LIST_EXPORT_FILE_LINK]",
          text: this.$t("dashboard.email_message_tag_donor_list_export_file_link"),
        },
        {
          value: `[DONOR_LIST_EXPORT_FILE_LINK_FOR_CID_${this.cId}]`,
          text: this.$t("dashboard.email_message_tag_donor_list_export_file_link_for_cid"),
        },
        {
          value: `[LOGIN_TO_TEAM_ACCESS_TOKEN]`,
          text: this.$t("dashboard.email_message_tag_login_to_team_access_token"),
        },
      ];

      const allowTeamUpdateByPublicToken =
        this.metas["allow_team_update_by_public_token"]?.value || false;
      if (allowTeamUpdateByPublicToken) {
        options = [
          ...options,
          {
            value: "[TOKEN]",
            text: this.$t("dashboard.email_message_tag_token"),
          },
        ];
      }

      return options;
    },
    defaultSubject() {
      return this.$t("dashboard.email_message_subject_defaults", "New message from {orgname}", {
        orgname: this.getCurrentOrganization?.attributes?.name || "",
      });
    },
  },
  methods: {
    ...mapActions({
      saveMessage: "messaging/save",
      checkEmailSettingInOrg: "messaging/checkEmailSettingInOrg",
    }),
    close() {
      this.step = 1;
      this.targetBucket = null;
      this.selectedTeams = [];
      this.selected = "target-bucket";
      this.formPayload = {
        target_bucket: null,
        subject: "",
        message: "",
        group: "",
        cc_list: [],
        replay_to: "",
        include_children_teams: false,
        include_grandchildren_teams: false,
        exclude_parent_teams: false,
        custom_pct_range: {
          from: 0,
          to: 100,
        },
      };
      this.importGroup = false;

      this.$refs.EmailMessageModal.hide();
    },
    open({ team, importGroup } = {}) {
      if (this.donorSupportContact) {
        this.formPayload.replay_to = this.donorSupportContact.attributes.email;
      } else {
        this.formPayload.replay_to = this.orgEmail;
      }

      if (importGroup) {
        this.selected = "";
        this.importGroup = importGroup;
      }

      if (team) {
        this.selected = "individual-teams";
        this.selectedTeams = [
          {
            id: team.id,
            ...team.attributes,
          },
        ];
      }
      this.$refs.EmailMessageModal.show();
    },
    back() {
      if (this.step === 2) {
        this.step = 1;
      }
    },
    submit1() {
      this.step = 2;
    },
    submit2() {
      const data = {
        campaign_id: Number(this.cId),
        ...{
          ...this.formPayload,
          message:
            unwrapContentInline(this.formPayload.message) ||
            unwrapContentValue(this.formPayload.message),
        },
      };

      if (data.subject.length === 0) {
        data.subject = this.defaultSubject;
      }

      if (this.importGroup) {
        data.group = this.importGroup;
      }

      if (this.selected === "target-bucket") {
        data.target_bucket = this.targetBucket;
      }

      if (this.selected === "individual-teams") {
        data.team_ids = this.selectedTeams.map((t) => Number(t.id));
      }

      this.saveMessage({
        oId: this.oId,
        cId: this.cId,
        delivery: "email",
        audience: "teams",
        data,
      })
        .then((data) => {
          this.close();
          this.$_notificationsMixin_makeToast(
            `Success`,
            `Message id: ${data.sqs_message_id}`,
            "success"
          );
        })
        .catch((e) => (this.error = e.response));
    },
  },
});
</script>

<style lang="scss" scoped>
::v-deep {
  .custom-control {
    z-index: unset;
  }
  .multiselect.cms {
    width: 100%;
    min-height: 50px;
    cursor: pointer;
    .multiselect__select {
      height: 50px;
      &:before {
        top: 60%;
      }
    }
    .multiselect__tags {
      &-wrap {
        min-height: 50px;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
      }
      min-height: 50px;
      padding: 0px 40px 0 8px;
    }
    .multiselect__single,
    .multiselect__placeholder {
      font-size: 16px;
      font-weight: 600;
      min-height: 50px;
      line-height: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
    .multiselect__input {
      min-height: 50px;
      font-size: 16px;
      font-weight: 600;
      line-height: 50px;
      margin-bottom: 0;
    }
  }
  input[type="radio"][name="target-radios"] {
    &:disabled {
      ~ label {
        &::before {
          background-color: #007bff !important;
        }
      }
    }
    ~ label {
      width: 100%;
      &::after {
        background-image: unset !important;
        background: unset;
      }
    }
  }
}

.disabled-area {
  pointer-events: none;
}
</style>
