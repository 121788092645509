import { render, staticRenderFns } from "./cdn-footer.vue?vue&type=template&id=4ecd1404&scoped=true&"
import script from "./cdn-footer.vue?vue&type=script&lang=js&"
export * from "./cdn-footer.vue?vue&type=script&lang=js&"
import style0 from "./cdn-footer.vue?vue&type=style&index=0&id=4ecd1404&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ecd1404",
  null
  
)

export default component.exports