<template>
  <div class="upload-bar__checkbox flex">
    <div>
      <input
        class="upload-bar__checkbox-input mx-1"
        type="checkbox"
        :id="id"
        @input="onInput"
        :checked="checked"
        style="cursor: pointer"
      />

      <span :for="id">
        {{ $t("edit_campaign.upload_immediately") }}
      </span>
    </div>
    <div class="mx-1">
      <b-icon-question-circle-fill
        v-b-tooltip.hover.right="$t('edit_campaign.upload_immediately_tooltip')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onInput(event) {
      this.$emit("input", event.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
input[type="checkbox"] {
  transform: scale(1.2);
}
.flex {
  display: flex;
  align-items: center;
}
</style>
