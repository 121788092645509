<template>
  <svg
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 800 800"
    width="134.67"
    height="143"
    fill="none"
    aria-labelledby="uniqueTitleID"
  >
    <title id="uniqueTitleID">
      {{ $t("home_page_v2.logo_title", "Charidy") }}
    </title>
    <g>
      <path
        :fill="fillColor"
        d="m666.6 611.7c-5.2 0-10 2.2-13.3 6.1-64.1 75.4-157.4 118.6-256.3 118.6q-0.6 0-1.2 0c-87.5-0.3-170.9-35.5-234.7-99-63.8-63.6-99.3-146.7-99.9-234.1-0.7-90.1 34-175 97.5-238.9 63.5-63.9 148.2-99.1 238.4-99.1 99.5 0 193.4 43.7 257.5 120.1 2.4 2.9 6 4.6 9.7 4.6 6 0 11.3-3.4 13.9-8.9 2.5-5.5 1.6-11.8-2.3-16.4-69.6-82-171.3-129.1-278.8-129.1-96.9 0-185.8 37.6-250.6 105.8-63.9 67.4-99.1 158.7-99.1 257.1 0 98.7 35.2 190.8 99.1 259.4 65 69.7 154 108.1 250.6 108.1 108.5 0 210.8-47.8 280.5-131.1 3.5-4.2 4.3-10 1.9-15-2.3-5.1-7.3-8.2-12.9-8.2z"
      />
      <g>
        <path
          :fill="fillColor"
          d="m277.9 440c6.9 0 12.5-5.6 12.5-12.5v-36q0-14.8-8.4-23.8-8.5-9.1-22.2-9.1-14.8 0-24.4 9.9v-29.3c0-6.9-5.6-12.5-12.5-12.5h-0.2c-6.9 0-12.5 5.6-12.5 12.5v88.3c0 6.9 5.6 12.5 12.5 12.5h0.2c6.9 0 12.5-5.6 12.5-12.5v-24.2q0-11 4.3-16.5 4.4-5.5 11.9-5.5 6.2 0 10 4.3 3.7 4.3 3.7 11.3v30.6c0 6.9 5.7 12.5 12.6 12.5z"
        />
        <path
          fill-rule="evenodd"
          :fill="fillColor"
          d="m401.2 369.2v59.5c0 6.9-5.6 12.6-12.6 12.6-5.6 0-10.3-3.7-12-8.7-6.5 5-16 8.8-25.2 8.8q-16.1 0-27.3-11.8-11.3-11.7-11.3-30.1 0-18.3 11.3-30.1c7.5-7.8 16.6-12 27.3-11.8 17.5 0.5 24.6 8.7 24.6 8.7l0.1 1.6c0.6-6.3 6-11.2 12.5-11.2v-0.1c7 0 12.6 5.6 12.6 12.6zm-31 45q5.8-5.7 5.8-14.7 0-8.8-5.8-14.7-5.7-5.8-13.6-5.8-8.3 0-13.7 5.8-5.3 5.7-5.3 14.7 0 9.1 5.3 14.8 5.4 5.6 13.7 5.6 7.9 0 13.6-5.7z"
        />
        <path
          :fill="fillColor"
          d="m510.8 358.7c-7 0-12.6 5.6-12.6 12.6v58.4c0 6.9 5.6 12.6 12.6 12.6 6.9 0 12.6-5.7 12.6-12.6v-58.4c0-7-5.7-12.6-12.6-12.6z"
        />
        <path
          fill-rule="evenodd"
          :fill="fillColor"
          d="m619.8 326.7c6.9 0 12.6 5.6 12.6 12.6v90.2c0 6.9-5.7 12.6-12.7 12.6-5.6-0.1-10.4-3.8-11.9-8.9l-0.6 0.5q-9.6 8.7-24.6 8.7-16.1 0-27.3-11.8-11.3-11.7-11.3-30.1 0-18.3 11.3-30.1 11.2-11.8 27.3-11.8 15 0 24.6 8.7v-28c0-7 5.6-12.6 12.6-12.6zm-12.6 73.8q0-8.8-5.8-14.7-5.7-5.8-13.6-5.8-8.3 0-13.7 5.8-5.4 5.7-5.4 14.7 0 9.1 5.4 14.8 5.4 5.6 13.7 5.6 7.9 0 13.6-5.7 5.8-5.7 5.8-14.7z"
        />
        <path
          :fill="fillColor"
          d="m724.1 358.7h-0.2c-4.9 0-9.4 2.9-11.3 7.4l-17.8 40.8-16.8-40.5c-1.9-4.7-6.4-7.7-11.5-7.7-8.8 0-14.8 9.1-11.3 17.2l26.5 61.4-9.3 20.8c-3.5 8.2 2.4 17.3 11.4 17.3h0.2c5 0 9.4-2.9 11.4-7.5l40.1-92c3.5-8.1-2.5-17.2-11.4-17.2z"
        />
        <path
          :fill="fillColor"
          d="m152.6 381.2c5.1 0 9.8 2 13.4 5.7 4.6 4.7 12.1 4.8 16.8 0.2 4.8-4.6 4.9-12.1 0.3-16.8-8.1-8.3-18.9-12.9-30.5-12.9-23.5 0-42.5 19.1-42.5 42.5 0 23.4 19 42.5 42.5 42.5 12.3 0 24-5.4 32.1-14.7 4.3-4.9 3.8-12.5-1.2-16.8-4.9-4.3-12.5-3.8-16.8 1.2-3.5 4.1-8.7 6.5-14.1 6.5-10.3 0-18.7-8.4-18.7-18.7 0-10.3 8.4-18.7 18.7-18.7z"
        />
        <path
          :fill="fillColor"
          d="m435.3 358.4c-7.5 0-13.5 6.1-13.5 13.6v57.4c0 7.1 5.7 12.9 12.8 12.9 6.8 0 12.4-5.6 12.4-12.4v-16.9c0-16.1 6.1-26 18.3-28.9l5-1c7.1-0.8 12-6 12-12.9q0-0.6 0-1.2c-0.8-7.2-7.9-12.1-15-10.9-1.3 0.2-2.7 0.6-4.1 1.1q-9.8 3.6-16.2 14.3v-3.4c0-6.5-5.2-11.7-11.7-11.7z"
        />
      </g>
      <path
        :fill="fillColor"
        d="m510.8 318.9c-7.6 0-13.7 6.1-13.7 13.7 0 7.5 6.1 13.7 13.7 13.7 7.5 0 13.7-6.2 13.7-13.7 0-7.6-6.2-13.7-13.7-13.7z"
      />
      <g>
        <path
          :fill="fillColor"
          d="m299.3 495.2q0 1.9-0.3 3.2-0.4 1.3-1.2 2.2-0.8 0.8-2 1.2-1.3 0.3-3.1 0.3-3.9 0-5.4-1.5-1.5-1.6-1.5-5.5v-18.2q0-2.1 0.4-3.5 0.3-1.5 1.1-2.4 0.8-0.9 2.1-1.3 1.3-0.5 3.2-0.5 3.7 0 5.2 1.9 1.5 1.8 1.5 5.8z"
        />
        <path
          :fill="fillColor"
          d="m357.6 476.9q0 6.9-6.5 6.9h-2.5v32.4q0 4.4-1.4 6.4-1.5 2.2-5.2 2.2-3.8 0-5.4-2.1-1.3-1.9-1.3-6.4v-32.5h-21.3q-6.4 0-6.4-6.5 0-6.8 6.3-6.8h37.2q6.5 0 6.5 6.4z"
        />
        <path
          :fill="fillColor"
          d="m379.3 495.2q0 1.9-0.4 3.2-0.3 1.3-1.1 2.2-0.8 0.8-2.1 1.2-1.2 0.3-3.1 0.3-3.8 0-5.3-1.5-1.5-1.6-1.5-5.5v-18.2q0-2.1 0.3-3.5 0.4-1.5 1.2-2.4 0.8-0.9 2.1-1.3 1.3-0.5 3.1-0.5 3.8 0 5.3 1.9 1.5 1.8 1.5 5.8z"
        />
        <path
          :fill="fillColor"
          d="m430.2 517.8q0 3.7-1.6 5.4-1.5 1.6-5 1.6-3.9 0-5.5-1.4-1.5-1.5-1.5-5.5v-25.3q0-8.7-8.7-8.7h-13.4q-7 0-7-6.4 0.1-7 7.2-7h14.9q9.9 0 15.2 5.4 5.4 5.4 5.4 15.3z"
        />
        <path
          :fill="fillColor"
          d="m455.7 466.6q0 1.2-1.2 3.5l-6.6 13.4q-1.6 3.4-5.6 3.4-2.2 0-3.3-1.6-1-1.7-0.7-3.2l3.8-15.7q0.7-2.3 2.6-3.6 1.9-1.3 4.3-1.3h0.7q2.4 0 4.2 1.3 1.8 1.4 1.8 3.8z"
        />
        <path
          :fill="fillColor"
          d="m506.7 513.3q0 4.6-3.4 7.7-3.4 3.1-8.5 3.1h-26.5q-3.9 0-5.4-1.5-1.4-1.6-1.4-5 0-3.5 1.6-5 1.6-1.6 5.2-1.6h22.6q0.4 0 0.5-0.3 0.1-0.3-0.4-0.8l-27.7-27.4q-3-3-3-5.8 0-2.8 2.8-5.2 2.2-2 4.4-2 3 0 6.1 3.2l12.2 12.6 8.8-11.6q3.1-4 6.5-4 2.1 0 4.2 1.7 2.8 2.3 2.8 5.2 0 2.9-2.6 5.9l-11.1 12.7 8.1 8.5q4.2 4.5 4.2 9.6z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fillColor: {
      type: String,
      default: "#598edc",
    },
  },
};
</script>
