<template>
  <div>
    <b-table head-variant="light" :busy="loading" :items="items" :fields="fields">
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #cell(subject)="{ value, item }">
        <b-button variant="link" @click="$emit('view', item)" class="button-truncate">
          {{ value }}
        </b-button>
      </template>
      <template #cell(actions)="{ item }">
        <b-dropdown v-if="item.created_by !== 'charidy'" variant="link" no-caret>
          <template #button-content>
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 18.5C14 17.3954 13.1046 16.5 12 16.5C10.8954 16.5 10 17.3954 10 18.5C10 19.6046 10.8954 20.5 12 20.5C13.1046 20.5 14 19.6046 14 18.5Z"
                fill="#107598"
              />
              <path
                d="M14 12.5C14 11.3954 13.1046 10.5 12 10.5C10.8954 10.5 10 11.3954 10 12.5C10 13.6046 10.8954 14.5 12 14.5C13.1046 14.5 14 13.6046 14 12.5Z"
                fill="#107598"
              />
              <path
                d="M14 6.5C14 5.39543 13.1046 4.5 12 4.5C10.8954 4.5 10 5.39543 10 6.5C10 7.60457 10.8954 8.5 12 8.5C13.1046 8.5 14 7.60457 14 6.5Z"
                fill="#107598"
              />
            </svg>
          </template>
          <b-dropdown-item-button @click="$emit('edit', item)">
            {{ $t("dashboard.utm_actions_edit") }}
          </b-dropdown-item-button>
          <b-dropdown-item-button @click="$emit('remove', item)">
            <span class="text-danger">
              {{ $t("dashboard.utm_actions_delete") }}
            </span>
          </b-dropdown-item-button>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("dashboard.org_ticket_id", "ID"),
        },
        {
          key: "subject",
          label: this.$t("dashboard.org_ticket_subject", "Subject"),
          tdClass: ["w-25"],
        },
        {
          key: "message",
          label: this.$t("dashboard.org_ticket_message", "Message"),
        },
        {
          key: "actions",
          label: this.$t("dashboard.org_ticket_actions", "Actions"),
        },
      ],
    };
  },

  computed: {
    ...mapState({
      items: (s) => s.orgTickets.list,
      loading: (s) => s.orgTickets.loading,
      count: (s) => s.orgTickets.count,
    }),
  },
};
</script>

<style lang="scss" scoped>
.button-truncate {
  overflow-y: auto;
  text-overflow: ellipsis;
  height: auto;
  max-height: auto;
}
</style>
