<script>
export default {
  props: {
    campaignAttrs: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formatProgressAmount() {
      if (this.campaignAttrs) {
        return this.campaignAttrs?.campaign_stats?.total?.toLocaleString();
      }

      return "0";
    },
    formatGoal() {
      if (this.campaignAttrs) {
        return this.campaignAttrs?.rounds[0].goal.toLocaleString();
      }

      return "0";
    },
    percentCount() {
      if (!this.campaignAttrs) {
        return 0;
      }
      if (!Array.isArray(this.campaignAttrs?.rounds) || this.campaignAttrs?.rounds?.length === 0) {
        return 0;
      }
      if (!this.campaignAttrs?.campaign_stats) {
        return 0;
      }

      const firstRound = this.campaignAttrs?.rounds[0];
      if (!firstRound) {
        return 0;
      }

      const percent = Math.floor(
        (this.campaignAttrs?.campaign_stats?.total / firstRound.goal) * 100
      );
      if (Number.isNaN(percent)) {
        return 0;
      }

      return percent;
    },
  },
};
</script>

<template>
  <div class="campaign-progress" v-if="campaignAttrs">
    <div class="campaign-progress__collected">
      <span class="collected-amount">{{ formatProgressAmount }} {{ campaignAttrs.currency }}</span>
      <span class="collected-percent">{{ percentCount }}%</span>
    </div>

    <div class="campaign-progress__line">
      <div v-bind:style="{ width: percentCount + '%' }"></div>
    </div>

    <div class="campaign-progress__goal">
      {{ $t("campaigns.goal") }}
      <span>{{ formatGoal }} {{ campaignAttrs.currency }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.campaign-progress {
  margin-bottom: 30px;
  &__collected {
    width: 100%;
    margin: 0 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $fd;
    .collected-amount {
      font-family: $vito-bold;
      font-size: 24px;
      text-transform: uppercase;
    }
    .collected-percent {
      font-size: 14px;
    }
  }
  &__line {
    width: 100%;
    margin: 0 0 10px;
    height: 8px;
    border-radius: 4px;
    background-color: $bg-100;
    position: relative;
    overflow: hidden;
    > div {
      background-color: $bb-100;
      border-radius: 4px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
  &__goal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $vito-bold;
    font-size: 12px;
    letter-spacing: 1px;
    color: $fl;
    span {
      font-family: $vito-light;
      font-size: 16px;
      color: $fd;
      text-transform: uppercase;
    }
  }
}
</style>
