<template>
  <div v-if="!hide" class="request">
    <div v-if="!submited" class="request__text">
      <span v-if="!editsRequired" v-html="$t('dashboard.send_live_req_decription')"> </span>
      <div v-else>
        <span v-html="$t('dashboard.send_live_req_edits_required_decription')"> </span>
        <br />
        <ul>
          <li v-for="(note, i) in notes" :key="i">{{ note }}</li>
        </ul>
      </div>
    </div>
    <div v-if="!submited" class="request__btn">
      <span v-if="editsRequired" class="mb-2">
        {{ $t("dashboard.send_live_req_edits_required_btn_msg") }}
      </span>
      <button
        :disabled="submitting"
        class="bttn bttn--sm bttn--orange bttn--mw255"
        @click="createLiveRequest"
      >
        {{ $t("dashboard.send_live_req_btn") }}
      </button>
    </div>

    <div v-if="submited" class="request__text m-auto">
      <span>
        {{ $t("dashboard.send_live_req_submited") }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      submitting: false,
      submited: false,
    };
  },

  mounted() {
    this.fetchLiveRequest([this.cId]);
  },

  computed: {
    ...mapState({
      campaignMode: (state) => state.campaign.campaign.data.attributes.mode,
      request: (state) => state.campaign.liveRequest,
    }),

    cId() {
      return this.$route.params.id;
    },

    notes() {
      if (this.request && this.request.attributes.meta.length) {
        const metaData = JSON.parse(this.request.attributes.meta);
        return metaData.notes;
      }
      return [];
    },

    editsRequired() {
      return this.request && this.request.attributes.status === "rejected";
    },

    hide() {
      // return this.request && this.campaignMode !== 5;
      return true;
    },
  },

  methods: {
    ...mapActions({
      fetchLiveRequest: "fetchLiveRequest",
      fetchCreateLiveRequest: "fetchCreateLiveRequest",
    }),

    createLiveRequest() {
      this.submitting = true;
      const data = {
        status: "new",
      };

      this.fetchCreateLiveRequest([this.cId, this.request.id, data]).then((data) => {
        this.submitting = false;
        this.submited = true;
        console.log(data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.request {
  display: flex;
  padding: 15px;
  background-color: $bg-20;
  border: 1px solid #e4e8ec;
  border-radius: 8px;
  margin-bottom: 40px;
  justify-content: space-between;
  align-items: center;
  &__text {
    font-family: $vito-light;
    font-size: 16px;
    line-height: 1.125;
    color: $bb-100;
    ul {
      li {
        margin: 5px 0;
        &::before {
          content: "\002D \0020";
        }
      }
    }
  }
  &__btn {
    display: flex;
    flex-direction: column;
  }
}
</style>
