<template>
  <div>
    <b-modal ref="SMSMessageModal" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
      <div slot="modal-header">
        <h2>{{ $t(`leads.sms_message_modal_title`, "Send SMS notification") }}</h2>
      </div>

      <error :response.sync="error" :alert="true"></error>

      <section v-if="step === 1">
        <form id="SMSMessageModalForm1" @submit.prevent="submit1">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <template #label>
                  <label class="form-group__label form-group__label--required">
                    {{ $t("leads.sms_message_to", "Who do you want to send the SMS messages") }}
                  </label>
                </template>

                <div v-if="importGroup">
                  <b>
                    {{ $t("leads.message_to_imported_group", "The Imported group") }}
                  </b>
                </div>
                <div v-else>
                  <div class="pl-1">
                    <multiselect-search
                      v-model="selectedCampaign"
                      :open-prefetch="true"
                      :search-url="getSearchUrl"
                      :search-options="searchOptions"
                      :multiselectClass="{ cms: true }"
                    >
                      <template #no-options>
                        {{
                          $t("leads.leads_search_no_options", "Please enter 1 or more characters")
                        }}
                      </template>
                      <template #no-more-infinite>
                        {{ $t("leads.campaigns_search_no_more", "No more campaigns") }}
                      </template>
                    </multiselect-search>
                  </div>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label form-group__label--required">
                    {{ $t("leads.sms_message_data", "SMS Message to be sent:") }}
                  </label>
                </template>

                <quill-editor-variables simple v-model="formPayload.message" :tags="tags">
                  <template #btn-content>
                    {{ $t("edit_campaign.sms_template_edit_title", "Description") }}
                  </template>
                  <template #description>
                    {{
                      $t(
                        "edit_campaign.sms_template_var_description",
                        "You can use these placeholders (variables) which will be replaced with actual lead-related values: [NAME] = The lead Name"
                      )
                    }}
                  </template>
                  <template #tags-full-text="{ tagsFull }">
                    {{
                      tagsFull
                        ? $t("edit_campaign.sms_template_show_description", "Show")
                        : $t("edit_campaign.sms_template_hide_description", "Hide")
                    }}
                  </template>
                </quill-editor-variables>
                <template #description>
                  <span :class="{ 'text-danger': charactersLeft < 0 }">
                    {{ `${charactersLeftText} characters left` }}
                  </span>
                </template>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </section>

      <section v-else-if="step === 2">
        <form id="SMSMessageModalForm2" @submit.prevent="submit2">
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label">
                    <b>
                      {{
                        $t(
                          "leads.sms_message_step2_you_are_about",
                          "You are about to send SMS Messages to"
                        )
                      }}
                    </b>
                    &nbsp;
                    <u>{{ targetTeamBucketText }}</u>
                  </label>
                </template>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <template v-slot:label>
                  <label class="form-group__label">
                    <b>
                      {{ $t("leads.sms_message_step2_message", "SMS Message") }}
                    </b>
                  </label>
                </template>
                <quill-editor-variables simple v-model="formPayload.message" :disabled="true" />
                <template #description>
                  <span :class="{ 'text-danger': charactersLeft < 0 }">
                    {{ `${charactersLeftText} characters left` }}
                  </span>
                </template>
              </b-form-group>
            </b-col>
          </b-row>
        </form>
      </section>

      <div slot="modal-footer" class="d-flex flex-row">
        <button class="mx-2 bttn bttn--lg bttn--blue" @click="close" type="button">
          {{ $t(`dashboard.cancel_btn`) }}
        </button>

        <button class="mx-2 bttn bttn--lg bttn--blue" @click="back" type="button" v-if="step === 2">
          {{ $t(`dashboard.back_btn`, "Back") }}
        </button>

        <div class="ml-auto" v-if="step === 1">
          <button id="submit-btn" class="bttn bttn--lg bttn--orange" form="SMSMessageModalForm1">
            {{ $t(`dashboard.sms_message_modal_save`) }}
          </button>

          <b-tooltip v-if="charactersLeft < 0" target="submit-btn" variant="danger">
            {{ $t(`dashboard.sms_message_too_long`, "Message too long") }}
          </b-tooltip>
        </div>

        <b-overlay
          :show="loading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="ml-auto d-inline-block"
          v-else-if="step === 2"
        >
          <button
            type="submit"
            class="bttn bttn--lg bttn--orange"
            form="SMSMessageModalForm2"
            :disabled="loading"
          >
            {{ $t(`dashboard.sms_message_modal_send`) }}
          </button>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import urls from "@/constants/urls";
import { notificationsMixin } from "@/mixins";
import error from "@/components/error-message.vue";
import quillEditorVariables from "@/common-components/ui-elements/quill-editor-variables.vue";
import MultiselectSearch from "@/components/multiselect-search.vue";

const extendParams = ["campaign_stats", "media", "meta", "donation_levels", "donation_streams"];

export default {
  mixins: [notificationsMixin],
  components: {
    error,
    quillEditorVariables,
    MultiselectSearch,
  },
  data() {
    return {
      step: 1,
      error: null,
      formPayload: {
        message: "",
        group: "",
      },
      importGroup: false,
      selectedCampaign: null,
      tags: [
        {
          value: "[LEAD_NAME]",
          text: this.$t("leads.email_message_tag_lead_name", "The lead Name"),
        },
        {
          value: "[LEAD_AMOUNT]",
          text: this.$t("leads.email_message_tag_lead_amount", "The lead Amount"),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.messaging.loading.saveMessaging_sms,
    }),
    oId() {
      return localStorage.getItem("orgId");
    },
    charactersLeft() {
      return 160 - this.formPayload.message.length;
    },
    charactersLeftText() {
      const l = this.formPayload.message.length;
      return `(${l} / 160) ${this.charactersLeft}`;
    },
    targetTeamBucketText() {
      if (this.importGroup) {
        return this.$t("leads.message_to_imported_group", "The Imported group");
      }

      return this.searchOptions.customLabel(this.selectedCampaign);
    },
    getSearchUrl() {
      const orgID = localStorage.getItem("orgId");

      return urls.getCampaignList.replace(":orgId", orgID);
    },

    searchOptions() {
      return {
        placeholder: "Select campaign",
        changeParams: ({ page, per_page: pp }) => ({
          page: page,
          perPage: pp,
          extend: extendParams,
        }),
        customLabel: (e) => `[${e.id}] ${e.attributes.title}`,
      };
    },
  },
  methods: {
    ...mapActions({
      saveSmsMessage: "messaging/save",
    }),
    close() {
      this.step = 1;
      this.selectedCampaign = null;
      this.formPayload = {
        message: "",
        group: "",
      };
      this.importGroup = false;

      this.$refs.SMSMessageModal.hide();
    },
    open({ importGroup } = {}) {
      if (importGroup) {
        this.importGroup = importGroup;
      }

      this.$refs.SMSMessageModal.show();
    },
    back() {
      if (this.step === 2) {
        this.step = 1;
      }
    },
    submit1() {
      if (this.formPayload.message.length <= 160) {
        this.step = 2;
      }
    },
    submit2() {
      const data = {
        campaign_id: Number(this.selectedCampaign.id),
        ...this.formPayload,
      };

      if (this.importGroup) {
        data.group = this.importGroup;
      }

      this.saveSmsMessage({
        oId: this.oId,
        cId: this.selectedCampaign.id,
        delivery: "sms",
        audience: "remind_me",
        data,
      })
        .then((data) => {
          this.close();
          this.$_notificationsMixin_makeToast(
            `Success`,
            `Message id: ${data.sqs_message_id}`,
            "success"
          );
        })
        .catch((e) => (this.error = e.response));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .multiselect.cms {
    width: 100%;
    min-height: 50px;
    cursor: pointer;
    .multiselect__select {
      height: 50px;
      &:before {
        top: 60%;
      }
    }
    .multiselect__tags {
      &-wrap {
        min-height: 50px;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
      }
      min-height: 50px;
      padding: 0px 40px 0 8px;
    }
    .multiselect__single,
    .multiselect__placeholder {
      font-size: 16px;
      font-weight: 600;
      min-height: 50px;
      line-height: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
    .multiselect__input {
      min-height: 50px;
      font-size: 16px;
      font-weight: 600;
      line-height: 50px;
      margin-bottom: 0;
    }
  }
  input[type="radio"][name="target-radios"] {
    &:disabled {
      ~ label {
        &::before {
          background-color: #007bff !important;
        }
      }
    }
    ~ label {
      width: 100%;
      &::after {
        background-image: unset !important;
        background: unset;
      }
    }
  }
}

.disabled-area {
  pointer-events: none;
}
</style>
