<template>
  <div v-if="lead" class="donation-unit">
    <div class="donation-unit__top_row">
      <div class="donation-unit__cell donation-unit__id">
        {{ lead.id }}
      </div>

      <div class="donation-unit__cell donation-unit__name">
        {{ `${leadAttr.billing_name} ${leadAttr.billing_last_name}` }}
      </div>

      <div class="donation-unit__cell donation-unit__charged">
        {{ leadAttr.charged_amount }} {{ leadAttr.currency_code.toUpperCase() }}
      </div>

      <div class="donation-unit__cell donation-unit__name donation-unit__contact" :class="{ blur }">
        {{ leadAttr.email }}
      </div>

      <div class="donation-unit__cell donation-unit__name donation-unit__contact" :class="{ blur }">
        {{ leadAttr.phone }}
      </div>

      <div class="donation-unit__cell donation-unit__status">
        {{ leadAttr.lead_status && $t(`dashboard.lead_status_${leadAttr.lead_status}`) }}
      </div>

      <div class="donation-unit__action w-25 d-flex">
        <div v-if="!blur" class="messsaging-btns">
          <button
            v-if="leadAttr.phone"
            class="bttn bttn-round edit"
            @click="$emit('send-sms', { lead })"
          >
            <b-icon-chat-text-fill />
          </button>

          <button
            v-if="leadAttr.email"
            class="bttn bttn-round edit"
            @click="$emit('send-email', { lead })"
          >
            <b-icon-envelope-fill />
          </button>
        </div>

        <div class="d-flex flex-column">
          <div class="select">
            <svg viewBox="0 0 12 6">
              <path
                fill="#A0A1AC"
                fill-rule="nonzero"
                d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
              />
            </svg>
            <select class="select__select" v-model="status">
              <option value="">
                {{ $t("dashboard.lead_status_no_set") }}
              </option>
              <option value="pending">
                {{ $t("dashboard.lead_status_pending") }}
              </option>
              <option value="processed_donated">
                {{ $t("dashboard.lead_status_processed_donated") }}
              </option>
              <option value="processed_not_available">
                {{ $t("dashboard.lead_status_processed_not_available") }}
              </option>
              <option value="processed_refused">
                {{ $t("dashboard.lead_status_processed_refused") }}
              </option>
              <option value="callback">
                {{ $t("dashboard.lead_status_callback") }}
              </option>
            </select>
          </div>
          <button v-if="confirm" class="bttn bttn--sm bttn--orange mt-1" @click="updateStatus">
            {{ $t("dashboard.lead_status_chnage_confirm") }}
          </button>
        </div>
        <button v-if="blur" class="bttn bttn--lg bttn--orange" @click="checkout">
          {{ $t("dashboard.lead_checkout") }}
        </button>
        <a v-if="!blur" :href="donateHref" target="_blank" class="bttn bttn--lg bttn--orange">
          {{ $t("dashboard.lead_donate") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import urls, { donateHost } from "../../../constants/urls";

export default {
  props: {
    lead: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      status: "",
      confirm: false,
      blur: true,
    };
  },

  computed: {
    ...mapState({
      campaignId: (state) => state.dashboard.selectedCampaign,
      hasAccessEdit: (state) => state.account.access.CanEditCampaignTeams,
      selectedCampaignCurrency: (state) =>
        state.dashboard.campaignDashboard.data.attributes.currency_code,
    }),

    leadAttr() {
      return this.lead.attributes;
    },

    donateHref() {
      if (!this.blur) {
        const cid = this.leadAttr.campaign_id;
        const params = Object.entries({
          amount: this.leadAttr.charged_amount,
          currency: this.leadAttr.currency_code,
          email: this.leadAttr.email,
          phone: this.leadAttr.phone,
          firstname: this.leadAttr.billing_name,
          lastname: this.leadAttr.billing_last_name,
          fullname: `${this.leadAttr.billing_name} ${this.leadAttr.billing_last_name}`,
          displayname: `${this.leadAttr.billing_name} ${this.leadAttr.billing_last_name}`,
          countrycode: this.leadAttr.payment_country,
          state: this.leadAttr.payment_state,
          city: this.leadAttr.payment_city,
          zip: this.leadAttr.payment_postcode,
          address: this.leadAttr.payment_address,
        })
          .map(([k, v]) => `${k}=${v}`)
          .join("&");

        return `${donateHost}/${cid}?custom_view=1&${params}`;
      }

      return "";
    },
  },

  watch: {
    leadAttr: {
      handler(val) {
        this.status = val.lead_status;
      },
      immediate: true,
    },
    status(newVal) {
      if (newVal !== this.leadAttr.lead_status) {
        this.confirm = true;
      } else {
        this.confirm = false;
      }
    },
  },

  methods: {
    ...mapActions({
      fetchDonations: "fetchDonations",
    }),

    checkout() {
      const params = {
        email: this.leadAttr.email,
        first_name: this.leadAttr.billing_name,
        last_name: this.leadAttr.billing_last_name,
      };

      this.fetchDonations([this.campaignId, params]).then(({ data }) => {
        if (data.data.length !== 0) {
          this.$emit("open-modal-checkout", data.data);
        }
        this.blur = false;
      });
    },

    updateStatus() {
      const orgId = localStorage.getItem("orgId");
      const data = {
        data: {
          attributes: {
            ...this.leadAttr,
            lead_status: this.status,
          },
        },
      };

      delete data.data.attributes.team_id_list;

      axios
        .patch(
          `${urls.putDonation.replace(":orgId", orgId).replace(":campaignId", this.campaignId)}/${
            this.lead.id
          }`,
          data
        )
        .then(() => {
          this.$emit("update-list");
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-box {
  display: inherit;
  margin-right: 10px;
}

.blur {
  filter: blur(2.5px);
}

.donation-comment {
  font-weight: bold;
  color: #414359;
  line-height: 1;
  font-size: 14px;
  padding-left: calc(10% + 40px);
  span {
    color: #bbbcc4;
    @media (max-width: 768px) {
      display: block;
      padding-bottom: 10px;
    }
  }
  @media (max-width: 768px) {
    padding-left: 0;
    text-align: center;
    padding-top: 20px;
  }
}
.bttn-round--gray {
  &:hover {
    background-color: darken(#bbbcc4, 15%);
  }
}
.messsaging-btns {
  display: flex;
  @media screen and (max-width: 1440px) {
    flex-direction: column;
    .bttn.bttn-round {
      margin: 2px 0;
    }
  }
}
</style>
