<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      smsLimit: 0,
      smsCount: 0,
    };
  },
  mounted() {
    this.getAll();
  },
  methods: {
    ...mapActions({
      getOrgSMSLimit: "getOrgSMSLimit",
      getOrgSMSCount: "getOrgSMSCount",
    }),
    async getAll() {
      this.loading = true;
      await Promise.all([
        this.getOrgSMSLimit().then((data) => (this.smsLimit = data)),
        this.getOrgSMSCount().then((data) => (this.smsCount = data)),
      ]);
      this.loading = false;
    },
  },
  computed: {
    smsCountLimit() {
      return this.smsLimit - this.smsCount;
    },
  },
};
</script>

<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <b-skeleton width="75%" height="24px" type="text"></b-skeleton>
    </template>
    <p class="m-0" v-if="smsLimit > 0">
      {{
        $t(
          "dashboard.sms_message_limit_count_info",
          "Your organization has {count} message left of {limit} available ::: Your organization has {count} messages left of {limit} available",
          {
            count: smsCountLimit,
            limit: smsLimit,
          },
          smsCountLimit
        )
      }}
    </p>
  </b-skeleton-wrapper>
</template>
