<template>
  <div>
    <payoutsListItem v-for="(payout, index) in payouts" :key="index" :payout="payout" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import payoutsListItem from "./payoutsListItem.vue";

export default {
  components: {
    payoutsListItem,
  },

  computed: {
    ...mapState({
      payouts: (state) => state.payouts.payouts,
    }),
  },
};
</script>

<style lang="scss" scoped></style>
