<template>
  <div id="updates-container">
    <div class="updates-block">
      <div class="add-block">
        <form @submit.prevent="sendUpdate">
          <b-form-group
            id="title-group"
            :label="$t('dashboard.updates_label_title', 'Title')"
            label-for="title-input"
          >
            <b-input id="title-input" v-model="update.title" required type="text" />
          </b-form-group>
          <b-form-group :description="`${this.charactersLeft} characters left`">
            <quill-editor
              class="textarea"
              :class="{ 'textarea--danger': editorDisabled }"
              ref="messageTextArea"
              v-model="update.message"
              :options="editorOptions"
              @change="onEditorChange"
              emitWithChangeSource
            />
          </b-form-group>
          <button type="submit" class="bttn bttn--lg bttn--blue" :disabled="loading" name="button">
            {{ !editingNow ? $t("dashboard.updates_add") : $t("dashboard.updates_patch", "Edit") }}
          </button>
        </form>
      </div>
      <div>
        <dashboardUpdateItem
          v-for="update in updates"
          :update="update"
          :key="update.id"
          @edit="onEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import dashboardUpdateItem from "./dashboardUpdateItem";
import { unwrapContentValue } from "@/components/vue-quill-editor/utils";
import CustomVideo from "@/components/vue-quill-editor/formats/custom-video";
// import DOMPurify from "dompurify";

export default {
  data() {
    return {
      update: {
        title: "",
        message: "",
      },
      editorDisabled: false,
      charactersLeft: 100,
      editingNow: null,
    };
  },
  props: {
    selectedCampaign: {
      type: [Number, String],
      default: 0,
    },
  },
  components: {
    dashboardUpdateItem,
  },
  computed: {
    ...mapState({
      updates: (state) =>
        state.dashboard.updates.collection.sort((x, y) => {
          return y.attributes.created_at - x.attributes.created_at;
        }),
      loading: (state) => state.dashboard.updates.loading,
      campaignId: (state) => state.dashboard.selectedCampaign,
    }),
    editorOptions() {
      return {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
              ["image"],
              [CustomVideo.blotName],
            ],
          },
          imageResize: {
            modules: ["Resize", "DisplaySize"],
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
          },
        },
        placeholder: this.$t("dashboard.updates_label_message", "Message"),
      };
    },
  },
  watch: {
    selectedCampaign: {
      handler(val) {
        if (val) {
          this.fetchUpdates(val);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      postUpdate: "updates/postUpdate",
      fetchUpdates: "updates/fetchUpdates",
      editUpdate: "updates/editUpdate",
    }),
    sendUpdate(event) {
      if (this.editorDisabled) {
        return;
      }

      event.preventDefault();
      this.update.campaign_id = parseInt(this.selectedCampaign || this.campaignId);

      /* DOMPurify.addHook("uponSanitizeElement", (node, data) => {
        if (data.tagName === "iframe") {
          const src = node.getAttribute("src") || "";
          if (
            src.toLowerCase().indexOf("youtu") !== -1 ||
            src.toLowerCase().indexOf("vimeo.com") !== -1
          ) {
            return node.parentNode.removeChild(node);
          }
        }
      });

      let clean = DOMPurify.sanitize(this.update.message.value, {
        ADD_ATTR: [
          "allow",
          "allowfullscreen",
          "frameborder",
          "scrolling",
          "style",
          "class"
        ],
        ADD_TAGS: ["iframe"],
        USE_PROFILES: { html: true }
      }); */
      const clean = unwrapContentValue(this.update.message);

      const cleanUp = () => {
        this.update.title = "";
        this.update.message = "";
        this.$refs.messageTextArea.innerHTML = "";
        this.editingNow = null;
      };

      if (this.editingNow) {
        return this.editUpdate({
          uid: this.editingNow,
          update: { ...this.update, message: clean },
          selectedCampaign: this.selectedCampaign,
        }).then(cleanUp);
      }

      return this.postUpdate({
        update: { ...this.update, message: clean },
        selectedCampaign: this.selectedCampaign,
      }).then(cleanUp);
    },
    onEditorChange({ text }) {
      this.charactersLeft = 100 - text.length;
      this.editorDisabled = Boolean(text.length > 100);
    },
    onEdit({ update, uid }) {
      this.editingNow = uid;
      this.update.title = update.title;
      this.update.message = unwrapContentValue(update.message);
      document.getElementById("updates-container").scrollIntoView();
    },
  },
  created() {
    if (!this.selectedCampaign) {
      this.fetchUpdates(this.campaignId);
    }
  },
};
</script>

<style lang="scss" scoped>
.updates-block {
  width: 100%;
  padding-left: 30px;
  position: relative;
  &:before {
    content: "";
    width: 1px;
    height: 100%;
    background: $bb-100;
    position: absolute;
    left: 7.5px;
    top: 0px;
    bottom: 0px;
  }
}
.add-block {
  padding-bottom: 20px;
  form {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 21px;
      height: 1px;
      top: 50%;
      left: -21px;
      background: $bb-100;
    }
    &:after {
      position: absolute;
      content: "";
      width: 17px;
      height: 17px;
      background: $bb-100;
      top: 50%;
      margin-top: -8px;
      left: -30px;
      border-radius: 50%;
    }
  }
  input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid $bb-100;
    outline: none;
    margin-bottom: 15px;
  }
  input {
    height: 40px;
    padding: 10px 25px;
  }
  .textarea {
    width: 100%;
    outline: none;
    margin-bottom: 15px;
    min-height: 90px;
    resize: none;
    &--danger {
      ::v-deep .ql-toolbar.ql-snow {
        border-color: var(--danger) !important;
      }
      ::v-deep .ql-container.ql-snow {
        border-color: var(--danger) !important;
      }
    }
    &[contenteditable][placeholder]:empty:before {
      content: attr(placeholder);
      position: absolute;
      color: gray;
      background-color: transparent;
    }
    ::v-deep .ql-toolbar.ql-snow {
      border: 1px solid $bb-100;
      border-radius: 5px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    ::v-deep .ql-container.ql-snow {
      border: 1px solid $bb-100;
      border-radius: 5px;
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}
.scroll {
  overflow: auto;
  max-height: 400px;
}
</style>
