<template>
  <div>
    <div class="campaign-edit-title">
      <h2 class="h2-like">{{ $t("edit_campaign.matchers_title") }}</h2>
      <div class="btn-wrap">
        <matcher-form ref="modalMatcher"></matcher-form>
      </div>
    </div>

    <section class="matchers_page">
      <div class="row">
        <template v-if="mathcersList.length > 0">
          <div class="col-12 col-lg-6" v-for="matcher in mathcersList" :key="matcher.id">
            <matcher-plate
              :matcher="matcher"
              @edit-matcher="editMatcher"
              @delete-matcher="deleteMatcher"
            ></matcher-plate>
          </div>
        </template>
        <div v-if="mathcersList.length == 0" class="empty-result-message">
          {{ $t("edit_campaign.matcher_empty") }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import campaignMatcherPlate from "./campaignMatcherPlate.vue";
import matcherForm from "./matcher-form.vue";
import { notificationsMixin } from "@/mixins";

export default {
  data() {
    return {
      mathcersList: [],
      load: false,
      errors: [],
    };
  },
  mixins: [notificationsMixin],
  components: {
    "matcher-plate": campaignMatcherPlate,
    "matcher-form": matcherForm,
  },

  computed: mapState({
    matchers: (state) => {
      let included = state.campaign.campaign.included;

      let relationships = state.campaign.campaign.data.relationships.campaign_matcher.data;

      let arrMatchers = [];

      for (let i = 0; i < relationships.length; i++) {
        let matcher = included.filter((el) => {
          return el.id == relationships[i].id && el.type == relationships[i].type;
        });

        arrMatchers.push(matcher[0]);
      }

      return arrMatchers;
    },

    campaignId: (state) => {
      return state.campaign.campaign.data.id;
    },

    orgId: function () {
      return localStorage.getItem("orgId");
    },
  }),

  methods: {
    editMatcher: function (matcher) {
      this.$refs.modalMatcher.openModalEdit(matcher);
    },

    deleteMatcher: function (matcher) {
      this.load = true;

      this.errors = [];

      let message = this.$t("edit_campaign.confirm_delete_matcher");

      let options = {
        okText: this.$t("edit_campaign.confirm_delete"),
        cancelText: this.$t("edit_campaign.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        axios
          .delete(
            urls.apiPutMacther
              .replace(":orgId", this.orgId)
              .replace(":campaignId", this.campaignId) +
              "/" +
              matcher.id
          )
          .then(() => {
            this.load = false;

            let extend = "?extend=campaign_stats&extend=content&extend=media&extend=matchers";

            this.$store.dispatch("updateCampaign", extend);

            this.$_notificationsMixin_makeToast(
              "Success",
              this.$t("edit_campaign.success_delete_matcher")
            );
          })

          .catch((e) => {
            this.load = false;
            if (e.response.data) {
              if (e.response.data.error) {
                this.errors.push(e.response.data.error);
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          });
      });
    },

    deleteImage: function (matcher) {
      matcher.attributes.image = "";

      this.saveMatchers();
    },

    changeImage: function (matcher) {
      this.load = true;

      this.errors = [];

      let fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file");
      fileInput.setAttribute("accept", "image/png, image/gif, image/jpeg, image/bmp, image/x-icon");
      fileInput.classList.add("load-image-input");

      fileInput.addEventListener("change", () => {
        if (fileInput.files != null && fileInput.files[0] != null) {
          let form_data = new FormData();

          form_data.append("file", fileInput.files[0]);

          let orgId = localStorage.getItem("orgId");

          axios
            .post(urls.loadImageQuill.replace(":OrgId", orgId), form_data)
            .then((response) => {
              this.load = false;

              matcher.attributes.image = response.data.src;

              fileInput.remove();

              this.saveMatchers();
            })
            .catch((e) => {
              this.load = false;
              if (e.response.data) {
                if (e.response.data.error) {
                  this.errors.push(e.response.data.error);
                } else {
                  this.errors.push(this.$t("template.error_default"));
                }
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            });
        }
      });

      document.querySelector("body").appendChild(fileInput);

      fileInput.click();
    },
  },

  watch: {
    matchers: function () {
      this.mathcersList = this.matchers;
    },
  },

  mounted: function () {
    this.mathcersList = this.matchers;
  },
};
</script>

<style lang="scss" scoped>
.matchers_page {
  padding: 0px;
  &_search {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    position: relative;
    > svg {
      fill: gray;
      position: absolute;
      left: 0;
      top: calc(50% - 12px);
    }
    > input {
      width: 100%;
      border-left: none;
      border-right: none;
      border-top: none;
      border-radius: 0;
      padding-left: 30px;
    }
  }
}
</style>
