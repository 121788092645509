<template>
  <b-col cols="12" md="6">
    <b-form-group>
      <template #label>
        {{ $t("dashboard.add_donate_kids", "Kids") }}
      </template>
      <b-form-tags
        v-model="kids"
        :placeholder="$t('template.add_kid_name')"
        tag-variant="blue"
        size="md"
        tag-pills
        add-on-change
      />
    </b-form-group>
  </b-col>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    dataID: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      kids: [],
    };
  },

  computed: {
    ...mapState({
      cID: (state) => state.dashboard.selectedCampaign,
    }),
  },

  mounted() {
    if (this.dataID) {
      this.fetchTicket([this.cID, this.dataID, {}]).then(({ attributes }) => {
        if (attributes.data?.kids) {
          this.kids = attributes.data?.kids || [];
        }
      });
    }
  },

  methods: {
    ...mapActions({
      fetchTicket: "ticket/fetchTicket",
      fetchCreateRichcontent: "fetchCreateRichcontent",
      fetchUpdateRichcontent: "fetchUpdateRichcontent",
    }),

    submit() {
      const payload = { kids: this.kids };

      if (!this.dataID) {
        return this.fetchCreateRichcontent([this.cID, payload]);
      }

      if (this.dataID) {
        return this.fetchUpdateRichcontent([this.cID, this.dataID, payload]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .badge-blue {
    background: $bb-100;
    color: #fff;
  }
  .b-form-tags-button {
    align-self: center;
  }
}
</style>
