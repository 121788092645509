import { render, staticRenderFns } from "./OrgTicketsForm.vue?vue&type=template&id=fc22705a&scoped=true&"
import script from "./OrgTicketsForm.vue?vue&type=script&lang=js&"
export * from "./OrgTicketsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc22705a",
  null
  
)

export default component.exports