<template>
  <div class="gateway-block">
    <category-title :title="$t('gateways.title')" withActions>
      <gateway-add ref="gatewayModal" @create-entity="createEntity" />
    </category-title>

    <b-alert :show="$route.query.i === '1'" variant="info">
      <div>
        {{
          $t(
            "gateways.create_instruction",
            'To create gateway click "Add new gateway" and select some gateway'
          )
        }}
      </div>
      <div v-if="hasGateways">
        {{ $t("gateways.create_instruction_has_disabled", "Or activate some from exists") }}
      </div>
    </b-alert>

    <div v-if="!loading && hasAccess">
      <div
        v-for="[id, data] in Object.entries(gateways.groups)"
        :key="id"
        class="border p-2 mb-2 mx-n2"
        :class="{ 'mt-5': data.gtw === 'stripe-element' }"
      >
        <div v-if="data.gtw === 'stripe-element'" class="group-section mb-2 shift-up">
          <div class="bg-white border p-2 d-flex align-items-center">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/stripe.png"
              height="25px"
              class="mx-2"
            />
            <div class="d-flex flex-column">
              <span class="text-uppercase">{{ data.entityName }}</span>
              <small class="text-grey">{{ data.accountNumber }}</small>
            </div>
          </div>
          <div>
            <button
              class="bttn bttn--sm bttn--orange"
              @click="openMorePaymentMethodsModal(data.list)"
            >
              {{ $t("gateways.more_payment_methods", "More payment methods") }}
            </button>
          </div>
        </div>
        <gatewayPlate
          v-for="item in data.list"
          :key="item.id"
          :gatewaydata="item"
          @view-data="openModals"
        />
      </div>

      <gatewayPlate
        v-for="item in gateways.rest"
        :key="item.id"
        :gatewaydata="item"
        @view-data="openModals"
      />
    </div>

    <div class="loading" v-if="loading && hasAccess">
      <app-spinner />
    </div>

    <div v-if="!hasGateways && !loading && hasAccess" class="empty">
      {{ $t("gateways.not_have_gateways") }}
    </div>

    <div v-if="!hasAccess" class="empty">{{ $t("gateways.not_have_gateways_access") }}</div>

    <div class="entities-modal">
      <entities-modal ref="entitiesModal" :gateway-modal-ref="$refs.gatewayModal" />
    </div>

    <more-payment-methods-modal ref="morePaymentMethodsModal" />
  </div>
</template>

<script>
import categoryTitle from "@/common-components/ui-elements/category-title.vue";
import gatewayPlate from "./subcomponents/gatewayPlate.vue";
import gatewayAdd from "./subcomponents/gatewayAdd.vue";
import entitiesModal from "@/views/account/subcomponents/entitiesModal.vue";
import appSpinner from "@/common-components/ui-elements/app-spinner.vue";
import store from "../../store/store.js";
import { mapState } from "vuex";
import MorePaymentMethodsModal from "./subcomponents/morePaymentMethodsModal.vue";

function getStripeElGroupID(attrs) {
  return attrs.gateway + attrs.gateway_data.account_number;
}

export default {
  created() {
    store.dispatch("getGateways");
    store.dispatch("getEntities");
  },

  components: {
    categoryTitle,
    gatewayPlate,
    gatewayAdd,
    entitiesModal,
    appSpinner,
    MorePaymentMethodsModal,
  },

  computed: mapState({
    hasGateways(state) {
      return state.gateways.gateways.length;
    },

    gateways: (state) => {
      const buildGroup = (res, gtw, gID) => {
        const attrs = gtw.attributes;
        const data = res.groups[gID] || {};
        const list = data.list || [];
        list.push(gtw);
        data.list = list;
        res.groups[gID] = {
          list,
          gtw: gtw.attributes.gateway,
          entityName: attrs.entity_name,
          accountNumber: attrs.gateway_data.account_number,
        };
        return res;
      };

      return state.gateways.gateways.reduce(
        (res, gtw) => {
          switch (gtw.attributes.gateway) {
            case "stripe": {
              const gID = gtw.attributes.gateway;
              res = buildGroup(res, gtw, gID);
              break;
            }

            case "stripe-element": {
              const gID = getStripeElGroupID(gtw.attributes);
              res = buildGroup(res, gtw, gID);
              break;
            }

            default:
              res.rest.push(gtw);
              break;
          }

          return res;
        },
        {
          groups: {},
          rest: [],
        }
      );
    },

    loading: (state) => {
      return state.gateways.loadingGateways;
    },

    hasAccess: (state) => {
      return state.account.access.CanSeeOrganizationGatewayList;
    },
  }),

  methods: {
    openModals(getawaysIngo) {
      this.$refs.gatewayModal.openModalView(getawaysIngo);
    },

    openMorePaymentMethodsModal(list) {
      this.$refs.morePaymentMethodsModal.open(list);
    },

    createEntity: function (gatewayStep) {
      this.$refs.entitiesModal.openModal(gatewayStep);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .gateway-plate {
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

// Don't touch below styles, it hide button from Entity modal
.gateway-block {
  ::v-deep .entities-modal > div {
    > button {
      display: none;
    }
  }
}

.group-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-grey {
  color: #bbbcc4;
}

.shift-up {
  margin-top: -2.5rem;
}
</style>
