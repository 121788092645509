<template>
  <div>
    <journal-ad-tab-form
      v-if="showForm"
      ref="journalAdForm"
      :module-id="Number(mID)"
      :edit="Boolean(mID)"
      @error="errorHandler"
    />

    <div class="text-center">
      <div v-if="remove" class="text-danger mb-2">
        {{
          $t(
            "dashboard.journal_ad_remove_info_msg",
            'Ad will be deleted after clicking the "Save" button.'
          )
        }}
      </div>

      <button v-if="(showForm && !mID) || remove" class="bttn bttn--lg bttn--blue" @click="cancel">
        {{ $t("dashboard.donation_add_cancel") }}
      </button>

      <button v-if="!mID && !showForm" class="bttn bttn--lg bttn--orange" @click="triggerAddEdit">
        {{ $t("dashboard.journal_ad_add_btn") }}
      </button>

      <button
        v-if="mID && showForm && !remove"
        class="bttn bttn--lg bttn--orange"
        @click="triggerRemove"
      >
        {{ $t("dashboard.journal_ad_remove_btn", "Remove Ad") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";

import journalAdTabForm from "../journalAdTabForm.vue";

export default {
  components: {
    journalAdTabForm,
  },

  props: {
    moduleId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      mID: 0,
      showForm: false,
      addEdit: false,
      remove: false,
    };
  },

  computed: {
    ...mapState({
      campaignId: (state) => state.dashboard.selectedCampaign,
    }),
  },

  mounted() {
    this.mID = this.moduleId;

    if (this.moduleId) {
      this.showForm = true;
      this.addEdit = true;
    }
  },

  methods: {
    cancel() {
      if (this.remove) {
        this.remove = false;
      } else {
        this.showForm = false;
        this.addEdit = false;
      }
    },

    triggerAddEdit() {
      this.showForm = true;
      this.addEdit = true;
    },

    triggerRemove() {
      this.remove = true;
    },

    errorHandler() {
      this.mID = 0;
      this.showForm = false;
      this.addEdit = false;
    },

    removeUnit() {
      const orgId = localStorage.getItem("orgId");

      return axios.delete(
        `${urls.moduleData.replace(":oid", orgId).replace(":cmpid", this.campaignId)}/${
          this.moduleId
        }`
      );
    },

    submit() {
      if (this.remove) {
        return this.removeUnit().then(() => 0);
      }

      if (this.addEdit) {
        return this.$refs.journalAdForm
          .submitForm()
          .then(({ data }) => data.data.id || data.data.attributes.id);
      }

      return Promise.resolve(0);
    },
  },
};
</script>

<style lang="scss" scoped></style>
