<template>
  <b-modal :id="id" size="xl" centered @hidden="resetAll">
    <div :id="`modal-header-${id}`" slot="modal-header">
      <button class="modal-header__hide" :id="`modal-header-hide-${id}`" @click="onHideModal">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </button>
      <h2 class="h1-like">
        {{ options.title }}
      </h2>
      <div class="modal-header__description">
        {{ options.description }}
      </div>
    </div>

    <div slot="modal-footer" class="d-flex flex-column">
      <div
        class="w-100 d-flex align-items-center"
        :class="{ 'justify-content-end': step === 0 || prevStepHidden }"
      >
        <div class="mr-auto">
          <button
            type="button"
            v-if="step < steps.length - 1"
            class="mx-2 bttn bttn--lg bttn--blue"
            @click.prevent="hideModal"
          >
            {{ options.cancelActionText }}
          </button>
          <button
            type="button"
            class="mx-2 bttn bttn--lg bttn--blue"
            v-if="(step > 0 && step < steps.length - 1 && !prevStepHidden) || error"
            @click="backStep"
          >
            {{ options.backActionText }}
          </button>
        </div>
        <button
          class="mx-2 bttn bttn--lg bttn--orange"
          v-if="step < steps.length - 1 && !nextStepGet.hidden"
          :disabled="loading || error"
          v-bind="continueBtnAttrs"
          v-on="continueBtnListeners"
        >
          {{ continueBtnText }}
        </button>
        <button
          type="button"
          class="mx-2 bttn bttn--lg bttn--orange"
          v-else
          @click="done"
          :disabled="loading || error"
        >
          {{ options.doneActionText }}
        </button>
      </div>
    </div>

    <div class="d-stepper" v-if="!stepHidden">
      <transition :name="effect" mode="out-in">
        <keep-alive>
          <component
            ref="step"
            :is="stepComponent"
            :state="store.state"
            :setState="setState"
            :options="options"
            :submitByFormId="getActionHandler(activeStep.action).submit"
            :loading="loading"
            :error="error"
            @loading="loadingAction"
            @error="errorHandler"
            @fatal-error="blockStepper"
            @can-continue="nextStepAction"
            @set-step="setStep"
          />
        </keep-alive>
      </transition>
    </div>
  </b-modal>
</template>

<script>
import { notificationsMixin } from "@/mixins";
import { isFunction, isPlainObject } from "@/helpers/inspect";

export default {
  mixins: [notificationsMixin],
  props: {
    id: { type: String, required: true },
    steps: { type: Array, default: () => [] },
    initialState: { type: Object, default: () => ({}) },
    options: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      store: {
        state: this.getInitialState(),
        setState: this.setState,
        resetState: this.resetState,
      },
      step: 0,
      loading: false,
      error: null,
      fatalError: false,
      fatalErrorMsg: "",
      effect: "in-out-translate-fade",
      shake: false,
    };
  },
  computed: {
    activeStep() {
      return this.steps[this.step];
    },
    prevStep() {
      const prev = this.steps[this.step - 1];
      if (!prev) {
        return this.activeStep;
      }
      return prev;
    },
    nextStepGet() {
      const next = this.steps[this.step + 1];
      if (!next) {
        return this.activeStep;
      }
      return next;
    },
    stepComponent() {
      return this.steps[this.step].component;
    },
    stepHidden() {
      return this.steps[this.step].hidden;
    },
    prevStepHidden() {
      return this.prevStep.hidden;
    },
    continueBtnAttrs() {
      const { submit } = this.getActionHandler(this.activeStep.action);

      return {
        ...(submit.length > 0 && {
          type: "submit",
          form: submit,
          "data-context": "continue",
        }),
      };
    },
    continueBtnListeners() {
      const { submit } = this.getActionHandler(this.activeStep.action);

      return {
        ...(submit.length === 0 && {
          click: this.nextStep.bind(this, { continue: true }),
        }),
      };
    },
    continueBtnText() {
      if (this.activeStep?.action?.continueActionText) {
        return this.activeStep.action.continueActionText;
      }

      return this.options.continueActionText;
    },
  },
  mounted() {
    this.$root.$on("bv::modal::show", this.onShowModal);
  },
  beforeDestroy() {
    this.$root.$off("bv::modal::show", this.onShowModal);
  },
  watch: {
    activeStep: {
      handler(v) {
        if (v.hidden) {
          this.nextStep({
            continue: true,
          });
        }
      },
    },
  },
  methods: {
    setStep(step) {
      if (step >= 1 && step <= this.steps.length) this.step = step - 1;
    },
    resetState() {
      this.store.state = {
        ...this.getInitialState(),
      };
    },
    setState(key, value) {
      if (isPlainObject(key)) {
        this.store.state = {
          ...this.store.state,
          ...key,
        };

        return this.store.state;
      }

      this.store.state = {
        ...this.store.state,
        [key]: value,
      };

      return this.store.state;
    },
    errorHandler(payload) {
      this.error = payload;
      this.shake = payload;
      setTimeout(() => {
        this.shake = !payload;
      }, 750);
    },
    blockStepper(msg) {
      this.resetParams();
      this.fatalErrorMsg = msg;
      this.fatalError = true;
    },
    resetParams() {
      this.error = false;
      this.loading = false;
      this.fatalErrorMsg = "";
      this.fatalError = false;
    },
    stepNumberClasses(i) {
      return {
        "c-bg-secondary text-white": this.step === i && !this.fatalError,
        "bg-success text-white": this.step > i && !this.fatalerror,
        "bg-danger text-white": this.fatalError && this.step === i,
        "c-text-secondary": this.step < i,
      };
    },
    nextStep(context) {
      if (!this.$refs.step?.nextStep) return this.nextStepAction(context);

      if (this.$refs.step.nextStep()) {
        if (!this.loading) {
          this.nextStepAction(context);
        }
      }
    },
    nextStepAction(context) {
      this.effect = "in-out-translate-fade";
      this.resetParams();
      const { required } = this.getActionHandler(this.activeStep.action);
      if (this.step < this.steps.length - 1) {
        if (required && context?.continue) {
          this.executeAction().then(() => {
            this.step++;
          });
        } else {
          this.step++;
        }
      }
    },
    backStep() {
      this.effect = "out-in-translate-fade";
      this.resetParams();
      if (this.step > 0) this.step--;
    },
    loadingAction(status) {
      this.loading = status;
    },
    onShowModal(bvEvent, modalId) {
      if (modalId === this.id) {
        this.resetState();
        if (this.activeStep.name === this.prevStep.name && this.prevStep.hidden) {
          this.nextStep({
            continue: true,
          });
        }
        this.$emit(
          "open",
          this.store.state,
          this.store.setState,
          this.loadingAction,
          this.errorHandler,
          this.setStep,
          this.blockStepper
        );
      }
    },
    onHideModal() {
      this.hideModal();
    },
    resetAll() {
      this.loading = false;
      this.error = false;
      this.fatalError = false;
      this.shake = false;
      this.step = 0;
      this.resetState();
    },
    hideModal() {
      this.$bvModal.hide(this.id);
      this.$emit("hide", { resetAll: this.resetAll });
    },
    getActionHandler(action) {
      const delegatedAction =
        this.steps.find((s) => {
          const name = s?.name ? s.name : "";
          return name === action?.delegate;
        })?.action || null;
      const actual = !delegatedAction ? action : delegatedAction;

      if (isFunction(actual)) {
        return {
          handler: actual,
          required: true, // shorthand for always execute actions, but not validate output
          submit: "",
          delegated: !!delegatedAction,
        };
      }

      return {
        handler: actual.handler,
        required: actual.required || false,
        submit: actual.submit || "",
        delegated: !!delegatedAction,
      };
    },
    getComponentActions() {
      const { handler } = this.getActionHandler(this.activeStep.action);
      const { handler: prevHandler } = this.getActionHandler(this.prevStep.action);

      if (!handler) {
        throw new Error("Not existent action called! Check action handlers");
      }

      if (this.activeStep.name !== this.prevStep.name && this.prevStep.hidden) {
        return prevHandler({
          state: this.store.state,
          setState: this.store.setState,
        }).then(() =>
          handler({
            state: this.store.state,
            setState: this.store.setState,
          })
        );
      }

      return handler({
        state: this.store.state,
        setState: this.store.setState,
      });
    },
    async executeAction() {
      this.error = null;
      this.loadingAction(true);
      await this.$nextTick();

      try {
        return await this.getComponentActions();
      } catch (e) {
        console.error(e);
        this.errorHandler(e);
        this.$_notificationsMixin_handleCatch(e);
      } finally {
        this.loadingAction(false);
      }
    },
    async done() {
      const produced = await this.executeAction();
      this.hideModal();
      this.$emit("done", { ...produced, resetAll: this.resetAll });
    },
    getInitialState() {
      return this.initialState;
    },
  },
};
</script>

<style lang="scss" scoped>
.d-stepper .d-stepper-header {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.d-stepper .d-stepper-header::before {
  position: absolute;
  width: 100%;
  height: 1px;
  background: #ddd;
  top: 20px;
  left: 0;
  content: " ";
}

.d-stepper .step-number {
  background: #e9e9e9;
  border-radius: 50%;
  text-align: center;
  height: 40px;
  width: 40px;
  display: flex;
}
.d-stepper .step-number-content {
  transition: transform 0.2s;
  z-index: 2;
  width: 68px;
}

.d-stepper .step-number-content div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.d-stepper .step-number-content.active {
  transform: scale(1.25);
}

.in-out-translate-fade-enter-active,
.in-out-translate-fade-leave-active {
  transition: all 0.15s;
}
.in-out-translate-fade-enter,
.in-out-translate-fade-leave-active {
  opacity: 0;
}
.in-out-translate-fade-enter {
  transform: translateX(100px);
}
.in-out-translate-fade-leave-active {
  transform: translateX(-100px);
}

.out-in-translate-fade-enter-active,
.out-in-translate-fade-leave-active {
  transition: all 0.15s;
}
.out-in-translate-fade-enter,
.out-in-translate-fade-leave-active {
  opacity: 0;
}
.out-in-translate-fade-enter {
  transform: translateX(-100px);
}
.out-in-translate-fade-leave-active {
  transform: translateX(100px);
}
</style>
