<template>
  <div>
    <ValidationObserver ref="observer">
      <b-row>
        <b-col cols="12">
          <ValidationProvider rules="required" name="subject" v-slot="{ valid, errors }">
            <b-form-group>
              <template #label>
                <label class="form-group__label form-group__label--required">
                  {{ $t("dashboard.org_ticket_form_subject_label", "Subject") }}
                </label>
              </template>
              <b-form-input v-model="value.subject" :state="valid"> </b-form-input>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <ValidationProvider rules="required" name="message" v-slot="{ valid, errors }">
            <b-form-group>
              <template #label>
                <label class="form-group__label form-group__label--required">
                  {{ $t("dashboard.org_ticket_form_message_label", "Message") }}
                </label>
              </template>
              <b-form-textarea v-model="value.message" :state="valid" rows="3"> </b-form-textarea>
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: { ValidationObserver, ValidationProvider },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    validate() {
      return this.$refs.observer.validate();
    },
  },
};
</script>

<style lang="scss" scoped></style>
