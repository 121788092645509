var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.errors)?_c('error',{attrs:{"errors":_vm.errors}}):_vm._e(),_c('b-tabs',{staticClass:"mb-3",attrs:{"content-class":"d-none","id":"tab1"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.descriptionTagsTabs),function(tag){return _c('b-tab',{key:tag.id,attrs:{"no-body":"","disabled":_vm.load,"active":_vm.descriptionTag === tag.tag,"id":tag.id,"title-link-class":{
          'language-selected__tab--active': _vm.descriptionTag === tag.tag,
          'text-dark': _vm.descriptionTag !== tag.tag,
        },"title-item-class":{
          'language-selected__tab--load': _vm.load,
        }},on:{"click":function($event){return _vm.changeDescriptionTag(tag.tag)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.load)?_c('b-skeleton',{attrs:{"width":"100%"}}):[_vm._v(" "+_vm._s(tag.title)+" ")]]},proxy:true}],null,true)})}),1),_c('p',[_vm._v(" "+_vm._s(_vm.$t( "edit_campaign.description_tag_info", "Default campaign description is shown if custom description is not defined for campaign status" ))+" ")]),_c('ul',{staticClass:"language-selected"},[(!_vm.load)?_vm._l((_vm.languagesAdded[_vm.descriptionTag]),function(lang){return _c('li',{key:lang.id,staticClass:"language-selected__unit",class:{ active: _vm.languageSelected === lang.attributes.language },domProps:{"textContent":_vm._s(_vm.setFullName(lang.attributes.language))},on:{"click":function($event){return _vm.isSelected(lang.attributes.language)}}})}):_vm._l((new Array(
            _vm.languagesAdded[_vm.descriptionTag].length > 0 ? _vm.languagesAdded[_vm.descriptionTag].length : 1
          ).fill(0)),function(_,i){return _c('b-skeleton',{key:i,staticClass:"language-selected__unit"})}),(!_vm.load)?[_c('b-dropdown',{staticClass:"dropdown",attrs:{"id":"ddown1","text":"+ Add language","variant":"-sm bttn--bordered-blue","disabled":!_vm.hasAccess}},_vm._l((_vm.languageSet),function(lang,index){return _c('b-dropdown-item',{key:index,attrs:{"disabled":!_vm.hasAccess},on:{"click":function($event){return _vm.addLanguage(lang.short)}}},[_vm._v(_vm._s(lang.long))])}),1)]:[_c('b-skeleton',{staticClass:"language-selected__unit"})]],2),(_vm.descriptionTag === '' && _vm.selectedIndex >= 0)?_c('div',[_c('div',{staticClass:"campaign-edit-title mb-2"},[_c('h2',{staticClass:"h2-like"},[_vm._v(_vm._s(_vm.$t("edit_campaign.generalinfo_name")))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.languagesAdded[_vm.descriptionTag][_vm.selectedIndex])?_c('div',{staticClass:"form-group form-unit"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.languagesAdded[_vm.descriptionTag][_vm.selectedIndex].attributes.title),expression:"languagesAdded[descriptionTag][selectedIndex].attributes.title"}],staticClass:"form-control form-unit__input",attrs:{"type":"text","placeholder":_vm.$t('edit_campaign.generalinfo_name')},domProps:{"value":(_vm.languagesAdded[_vm.descriptionTag][_vm.selectedIndex].attributes.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.languagesAdded[_vm.descriptionTag][_vm.selectedIndex].attributes, "title", $event.target.value)}}})]):_vm._e()])])]):_vm._e(),_c('div',{staticClass:"campaign-edit-title mb-2"},[_c('h2',{staticClass:"h2-like"},[_vm._v(_vm._s(_vm.$t("edit_campaign.description_title")))])]),_vm._l((_vm.languagesAdded[_vm.descriptionTag]),function(lang,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
        lang.attributes.language === _vm.languageSelected && lang.attributes.tag === _vm.descriptionTag
      ),expression:"\n        lang.attributes.language === languageSelected && lang.attributes.tag === descriptionTag\n      "}],key:`lang-${index}`,staticClass:"description-area"},[(
          lang.attributes.language === _vm.languageSelected && lang.attributes.tag === _vm.descriptionTag
        )?_c('form',{staticClass:"campaign-description",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.saveChange.apply(null, arguments)}}},[_c('div',{staticClass:"description-area"},[_c('div',{staticClass:"editor-wrap"},[_c('QuillDescriptionEditor',{ref:"myQuillEditor",refInFor:true,attrs:{"disabled":!_vm.hasAccess,"id":'quill-editor' + index,"options":_vm.editorOption,"editor-direction":_vm.editorDirection},model:{value:(lang.attributes.content),callback:function ($$v) {_vm.$set(lang.attributes, "content", $$v)},expression:"lang.attributes.content"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto mr-auto"},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.load,"rounded":"pill","opacity":"0.6","spinner-small":"","spinner-variant":"secondary"}},[(lang.id)?_c('button',{staticClass:"bttn bttn--lg bttn--orange bttn--inverted-orange",attrs:{"type":"button","name":"button"},on:{"click":function($event){return _vm.deleteLang(lang)}}},[_vm._v(" "+_vm._s(_vm.$t("edit_campaign.delete", { langName: _vm.setFullName(lang.attributes.language), }))+" ")]):_vm._e()])],1),_c('div',{staticClass:"col-auto"},[_c('b-overlay',{staticClass:"d-inline-block",attrs:{"show":_vm.load,"rounded":"pill","opacity":"0.6","spinner-small":"","spinner-variant":"secondary"}},[(!_vm.enableRootEvents)?_c('button',{staticClass:"bttn bttn--lg bttn--orange bttn--mw255",attrs:{"type":"submit","disabled":!_vm.hasAccess || _vm.load,"value":"Save"}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.save_btn"))+" ")]):_vm._e()])],1)])],1)])]):_vm._e()])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }