<template>
  <div>
    <slot />
    <b-modal
      id="media-storage-cropper-modal"
      ref="modal"
      size="xl"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @hide="clearData"
      @ok="ok"
    >
      <div slot="modal-header">
        <h2 class="text-capitalize">
          {{ $t(`dashboard.media_storage_cropper_preview_modal_title`, `Preview media`) }}
        </h2>
      </div>
      <div>
        <b-row>
          <b-col cols="12">
            <legend
              tabindex="-1"
              class="bv-no-focus-ring col-form-label pt-0"
              v-if="legendTranslationKey"
            >
              {{ legendTranslationKey }}
            </legend>
            <MediaStorageCropperPreview :image="srcImage" :tag="tag" />
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <button class="bttn bttn--lg bttn--blue" @click="cancel">
          {{ $t(`dashboard.cancel_btn`) }}
        </button>

        <button class="bttn bttn--lg bttn--orange" @click="ok">Ok</button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mediaTagAspectRatio } from "@/constants/media-tags";
import { mapActions } from "vuex";
import { nanoid } from "nanoid";
import MediaStorageCropperPreview from "./MediaStorageCropperPreview.vue";
import { OpenMediaStoragePreview, OnOkMediaStoragePreview } from "@/constants/providers";

export default {
  props: {
    campaignTemplate: {
      type: String,
      default: "",
    },
  },
  provide() {
    return {
      [OpenMediaStoragePreview]: this.__open,
      [OnOkMediaStoragePreview]: this.__onOK,
    };
  },
  components: {
    MediaStorageCropperPreview,
  },
  data() {
    return {
      id: nanoid(),
      src: "",
      tag: "",
      file: null,
      customAspectRatio: null,
      previewImageSrc: "",
      cropperCanvas: null,
      callback: () => null,
      legendTranslationKey: null,
      options: {
        tags: [
          {
            text: "Countdown page image",
            value: "countdown_hero",
          },
          {
            text: "Countdown page video",
            value: "countdown_video",
          },
          {
            text: "Camapign image in the live list",
            value: "charidy_homepage",
          },
          {
            text: "Countdown page mobile image",
            value: "countdown_hero_mobile",
          },
          {
            text: "Regular campaign slider",
            value: "slider",
          },
          {
            text: "Regular campaign video",
            value: "video",
          },
          {
            text: "Unidy/Select campaign slider",
            value: "campaign_hero",
          },
          {
            text: "Campaign page slider mobile",
            value: "campaign_hero_mobile",
          },
          {
            text: "Campaign page share image (fb, whatsapp)",
            value: "shared_image",
          },
          {
            text: "Projector Mode Banner",
            value: "projector_banner",
          },
          {
            text: "Team page slider",
            value: "team_default",
          },
          {
            text: "Team page default avatar",
            value: "team_default_avatar",
          },
        ],
      },
    };
  },
  computed: {
    fileUrl() {
      return this.file && URL.createObjectURL(this.file);
    },
    srcImage() {
      return this.fileUrl || this.src;
    },
    aspectRatio() {
      if (this.customAspectRatio) {
        return this.customAspectRatio?.value || 1;
      }
      const mtar = mediaTagAspectRatio[this.tag];
      if (mtar && (mtar.value?.height || mtar.value?.width)) {
        return mtar.value.height / mtar.value.width;
      }
      return (mtar && mtar.value) || 1;
    },

    aspectRatioInfo() {
      const tag = this.options.tags.find((el) => el.value === this.tag)?.text || "";
      const fallback = `For "{tmpl}" template, "{tag}" image, the recomended aspect ratio is <b>{aspectRatio}</b>, use the croping tool bolow to crop the original image according to the guideline`;

      return this.$t("dashboard.media_storage_aspect_ratio_info", fallback, {
        tmpl: this.campaignTemplate.replace(/\b[a-z]/g, (x) => x.toUpperCase()),
        tag,
        aspectRatio: this.aspectRatioText,
      });
    },

    aspectRatioText() {
      if (this.customAspectRatio) {
        return this.customAspectRatio?.text || "";
      }
      const mtar = mediaTagAspectRatio[this.tag];
      return (mtar && mtar.text) || "";
    },
  },
  methods: {
    ...mapActions({
      fetchCreateFileUrl: "mediaStorage/fetchCreateFileUrl",
    }),
    __open(file, legendTranslationKey) {
      this.file = file;
      this.tag = null;
      this.customAspectRatio = null;
      this.legendTranslationKey = legendTranslationKey;
      this.$refs.modal.show();
    },
    close() {
      this.$refs.modal.hide();
    },
    __onOK(cb) {
      this.callback = cb;
    },
    ok() {
      return this.callback();
    },
    clearData() {
      this.$emit("hide");
      this.fileUrl && URL.revokeObjectURL(this.fileUrl);
      this.file = "";
      this.previewImageSrc = "";
      this.customAspectRatio = null;
      this.callback = () => null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
