<template>
  <b-modal
    ref="delpaymentMethod"
    body-class="delete-payment-method-popup"
    id="delete-payment-method"
    title
    centered
    hide-footer
  >
    <div v-if="isLoading" class="d-flex justify-content-center">
      <b-spinner label="Loading..." variant="info"></b-spinner>
    </div>

    <div v-else>
      <p>Are you sure you want to delete the card?</p>
      <div class="btn-wrap">
        <button class="mx-2 add bttn bttn--lg bttn--orange" @click="hideModal">Cancel</button>
        <button class="mx-2 add bttn bttn--lg bttn--green" @click="deleteCard">Delete</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls.js";

export default {
  name: "delete-payment-method",
  data() {
    return {
      isLoading: false,
      cardId: "",
    };
  },
  methods: {
    deleteCard: function () {
      this.isLoading = true;

      axios
        .delete(urls.donorCardsStripe.replace(":cardId", this.cardId))
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch("getCards");
          this.hideModal();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    openModal(cardId) {
      this.cardId = cardId;
      this.$root.$emit("bv::show::modal", "delete-payment-method");
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "delete-payment-method");
    },
  },
};
</script>

<style lang="scss">
.delete-payment-method-popup {
  background: #1c273999;
  color: #414359;
  .close {
    font-size: 2.5rem;
    line-height: 0.5;
    color: #107598;
    opacity: 1;
    width: 36px;
    height: 36px;
    padding: 5px;
    border-radius: 18px;
    margin-top: -5px;
    outline: none;
    &:hover {
      background: #10759824;
    }
  }
  .modal-dialog {
    max-width: 800px;
  }
  .modal-body {
    p {
      font: 30px/1.4 "VitoExtended-Bold";
      margin-bottom: 60px;
      text-align: center;
    }
  }
  .modal-content {
    background: url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco1.svg")
        top -40px left 5% no-repeat,
      url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco2.svg")
        top -50px right 20% no-repeat,
      #fff;
    .modal-header {
      background: none;
    }
    .modal-body {
      background: none;
    }
  }
  .input-wrap {
    flex-grow: 2;
  }
  .bttn--green {
    background: #00d39a;
    width: 180px;
    color: #fff;
  }
  .bttn--orange {
    background-color: #ff5555;
    &:hover {
      opacity: 0.85;
    }
  }
  .btn-wrap {
    text-align: center;
    .bttn {
      margin: 0 10px;
    }
  }
}
</style>
