<template>
  <div v-bind="{}" class="gateway-block d-flex justify-content-center align-items-center w-100">
    <div v-if="!loadingGateways && gateways.length > 0" class="w-100">
      <ValidationObserver v-slot="{ validate, errors: observerErrors }">
        <form :id="submitByFormId" @submit.prevent="handleSubmit($event, validate)">
          <ErrorsView
            v-if="$options.filterErrors(errors, observerErrors)"
            :errors="$options.filterErrors(errors, observerErrors)"
          />
          <div class="row">
            <div class="col-12">
              <div class="form-group form-unit form-unit--multiselect">
                <label class="form-unit__label">
                  {{ $t("add_campaign.gateway") }}
                </label>
                <div class="d-flex align-items-center">
                  <Multiselect
                    :value="state.campaignSelectedGateways"
                    @input="(v) => setState('campaignSelectedGateways', v)"
                    track-by="id"
                    :custom-label="customLabel"
                    :options="gateways"
                    :multiple="true"
                  ></Multiselect>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div v-else-if="!loadingGateways && gateways.length === 0" class="w-100">
      <AccStripe externalClose @create-entity="createEntity" @submit="onAccStripeSubmit" />
      <div class="entities-modal">
        <EntitiesModal ref="entitiesModal" @submit="onSubmitEntity" />
      </div>
    </div>
    <div v-else>
      <LdsSpinner />
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ErrorsView from "@/components/errors.vue";
import { filterErrors } from "@/helpers/filters";
import Multiselect from "vue-multiselect";
import { mapActions, mapState } from "vuex";
import AccStripe from "@/views/gateways/subcomponents/accStripe.vue";
import EntitiesModal from "@/views/account/subcomponents/entitiesModal.vue";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";

export default {
  filterErrors,
  props: {
    state: { type: Object, default: () => ({}) },
    setState: { type: Function, default: () => {} },
    submitByFormId: { type: String, default: "" },
  },
  data() {
    return {
      errors: [],
    };
  },
  components: {
    Multiselect,
    AccStripe,
    EntitiesModal,
    ValidationObserver,
    ErrorsView,
    LdsSpinner,
  },
  mounted() {
    this.getGateways();
  },
  computed: {
    ...mapState({
      loadingGateways: (state) => state.gateways.loadingGateways,
      gateways: (state) => state.gateways.gateways.filter((v) => v.attributes.active),
    }),
  },
  methods: {
    ...mapActions(["getGateways"]),
    customLabel({ attributes }) {
      return `${attributes.gateway}`;
    },
    createEntity() {
      this.$refs.entitiesModal.openModal();
    },
    onSubmitEntity() {},
    onAccStripeSubmit(createdGateway) {
      this.setState("campaignSelectedGateways", [createdGateway]);
      this.$emit("can-continue", {
        continue: true,
      });
    },
    handleSubmit(event, validate) {
      const { submitter } = event;
      const context = {
        continue: submitter.dataset.context === "continue",
        saveDraft: submitter.dataset.context === "saveDraft",
      };

      return validate().then(() => this.$emit("can-continue", context));
    },
  },
};
</script>

<style lang="scss" scoped>
.gateway-block {
  ::v-deep .entities-modal > div {
    > button {
      display: none;
    }
  }
}
</style>
