<template>
  <div class="wizard">
    <div class="main">
      <div class="taxgift-intro">
        <p>
          <b>{{ $t("integration.jl_title", "JL") }}</b>
        </p>
        <p>
          {{ $t("integration.jl_description", "Please whitelist IP on JL side: 18.224.225.210") }}
        </p>
      </div>
      <b-form-group
        id="reports-fieldset-select-key"
        :label="
          $t(
            'integration.jl_api_url',
            'API Url: (https://example.myschools.info:7192/API/GetInfo.aspx)'
          )
        "
        label-for="reports-input-select-key"
      >
        <b-form-input
          id="reports-input-select-key"
          v-model="apiURL"
          type="text"
          placeholder="Please enter your API Url"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="reports-fieldset-select-entity"
        label="Key:"
        label-for="reports-input-select-entity"
      >
        <b-form-input
          id="reports-input-select-entity"
          v-model="key"
          type="text"
          placeholder="Please enter your Key"
          required
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="footer">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="closeModal">
        {{ $t("integration.close") }}
      </button>
      <button
        class="bttn bttn--lg bttn--orange el-right"
        :disabled="!(apiURL && key)"
        @click.prevent="addIntegration"
      >
        {{ $t("integration.add_taxgift", "Add Integration") }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      apiURL: "",
      key: "",
    };
  },

  computed: {
    ...mapState({
      orgId: () => {
        return localStorage.getItem("orgId");
      },
    }),
  },

  methods: {
    closeModal() {
      this.$store.dispatch("getIntegrationsList");
      this.$emit("close-modal");
    },
    addIntegration() {
      const payload = {
        api_url: this.apiURL,
        key: this.key,
        status: true,
      };
      // Please fill in API Call here
      axios
        .post(urls.addIntegration(this.orgId, "jl"), payload)
        .then(() => {
          this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
          this.$emit("close-modal");
          this.$store.dispatch("getIntegrationsList");
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: space-between;
}
.el-right {
  margin-left: auto;
}
</style>
