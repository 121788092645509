<template>
  <div>
    <button class="bttn bttn--sm bttn--orange" :disabled="!hasAccess" @click="openModal">
      {{ $t("edit_campaign.add_new_donation_stream") }}
    </button>
    <!-- Modal Component -->

    <b-modal id="stream-modal-form" @hidden="clearData" size="lg">
      <div slot="modal-header">
        <h2>{{ $t("edit_campaign.donation_stream_form") }}</h2>
        <div class="modal-header__hide" @click="modalClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button type="button" class="bttn bttn--lg bttn--blue" @click="modalClose" name="button">
          {{ $t("edit_campaign.matcher_form_close") }}
        </button>
        <button
          type="submit"
          form="matcher-form"
          :disabled="sending"
          :class="sending ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
        >
          {{ $t("edit_campaign.matcher_form_save") }}
        </button>
      </div>

      <form id="matcher-form" class="gateway-form" @submit.prevent="saveForm">
        <error v-if="errors" :errors="errors"></error>

        <div class="form-block">
          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="matcher-card__img">
                <div class="matcher-img">
                  <label v-if="!streamData.data.attributes.image" @click="changeImage()">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                      />
                    </svg>
                    {{ $t("edit_campaign.upload_donation_stream_image") }}
                  </label>

                  <div v-if="streamData.data.attributes.image" class="matcher-img__loaded">
                    <div class="image-wrapper">
                      <img :src="streamData.data.attributes.image" alt />
                    </div>
                    <div class="matcher-img__action matcher-img__delete" @click="deleteImage()">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                        />
                        <path d="M0 0h24v24H0z" fill="none" />
                      </svg>
                    </div>
                    <label class="matcher-img__action matcher-img__change" @click="changeImage()">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                        />
                      </svg>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-9">
              <div class="row">
                <div class="col-12">
                  <div class="form-group form-unit">
                    <label
                      class="form-unit__label form-unit__label--upper-sm form-group__label--required"
                      >{{ $t("edit_campaign.donation_stream_title") }}</label
                    >
                    <input
                      type="text"
                      required
                      class="form-control form-unit__input"
                      v-model="streamData.data.attributes.title"
                    />
                  </div>
                  <div class="form-group form-unit">
                    <label class="form-unit__label form-unit__label--upper-sm">{{
                      $t("edit_campaign.donation_stream_description")
                    }}</label>
                    <input
                      type="text"
                      class="form-control form-unit__input"
                      v-model="streamData.data.attributes.description"
                    />
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <div class="form-group form-unit">
                        <label
                          class="form-unit__label form-unit__label--upper-sm form-group__label--required"
                          >{{ $t("edit_campaign.donation_stream_goal") }}</label
                        >
                        <input
                          type="text"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          required
                          class="form-control form-unit__input"
                          v-model.number="streamData.data.attributes.goal"
                        />
                      </div>
                    </div>
                    <div class="col-6 align-self-end">
                      <div class="form-group form-unit">
                        <div class="form-checkbox form-checkbox--orange no-margin">
                          <input
                            type="checkbox"
                            v-model="streamData.data.attributes.default"
                            id="v1"
                            class="form-checkbox__input"
                          />
                          <label for="v1" class="form-checkbox__label">{{
                            $t("edit_campaign.donation_stream_default")
                          }}</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <div class="form-group form-unit">
                        <label
                          class="form-unit__label form-unit__label--upper-sm form-group__label--required"
                          >{{ $t("edit_campaign.donation_stream_order") }}</label
                        >
                        <input
                          type="text"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          required
                          class="form-control form-unit__input"
                          v-model.number="streamData.data.attributes.order"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row align-items-center justify-content-center">
                    <div class="col-4">
                      <b-form-input
                        v-model="streamData.data.attributes.color"
                        :value="streamData.data.attributes.color"
                        type="color"
                      ></b-form-input>
                    </div>
                    <div class="col-8">
                      <div class="row align-items-center justify-content-center">
                        <div class="col-12">
                          <AppSwitchToggle
                            class="m-0"
                            :label="
                              $t(
                                'edit_campaign.donation_stream_show_on_campaign_page',
                                'Show on campaign page'
                              )
                            "
                            v-model="streamData.data.attributes.meta.show_on_campaign_page"
                            :disabled="sending"
                          />
                        </div>
                        <div class="col-12">
                          <AppSwitchToggle
                            class="m-0"
                            :label="
                              $t(
                                'edit_campaign.donation_stream_show_on_donation_form',
                                'Show on donation form'
                              )
                            "
                            v-model="streamData.data.attributes.meta.show_on_donation_form"
                            :disabled="sending"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="gateway-form__action"></div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import AppSwitchToggle from "@/common-components/ui-elements/app-switch-toggle.vue";
import error from "@/components/errors.vue";

const extend = `?${[
  "extend=campaign_stats",
  "extend=content",
  "extend=media",
  "extend=matchers",
  "extend=donation_levels",
  "extend=donation_streams",
].join("&")}`;

export default Vue.extend({
  data() {
    return {
      edit: false,
      errors: [],
      sending: false,
      streamId: 0,
      streamData: {
        data: {
          attributes: {
            title: "",
            description: "",
            color: "#cccccc",
            goal: 0,
            image: "",
            order: 1,
            default: false,
            meta: {
              show_on_campaign_page: true,
              show_on_donation_form: true,
            },
          },
        },
      },
    };
  },

  components: {
    error,
    AppSwitchToggle,
  },

  computed: mapState({
    campaignId: (state) => state.campaign.campaign.data.id,
    hasAccess: (state) => state.account.access.CanEditCampaignDonation,
    orgId: () => localStorage.getItem("orgId"),
  }),

  methods: {
    openModal() {
      this.$root.$emit("bv::show::modal", "stream-modal-form");
    },

    modalClose() {
      this.$root.$emit("bv::hide::modal", "stream-modal-form");
    },

    deleteImage() {
      this.streamData.data.attributes.image = "";
    },

    saveForm() {
      if (this.edit) this.editStream();
      else this.addStream();
    },

    addStream() {
      this.sending = true;
      this.errors = [];
      const newData = JSON.parse(JSON.stringify(this.streamData));

      newData.data.attributes.campaign_id = parseInt(this.campaignId);
      newData.data.attributes.meta = JSON.stringify(this.streamData.data.attributes.meta);

      axios
        .post(
          urls.apiDonationStreams
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignId),
          newData
        )
        .then(() => {
          this.sending = false;

          this.$store.dispatch("updateCampaign", extend);

          this.modalClose();

          this.$_notificationsMixin_makeToastCompat({
            group: "Notification",
            title: this.$t("edit_campaign.success_save_donation_streams"),
          });
        })
        .catch((e) => {
          this.sending = false;
          this.renderError(e);
        });
    },

    editStream() {
      this.sending = true;
      this.errors = [];
      const newData = JSON.parse(JSON.stringify(this.streamData));

      newData.data.attributes.meta = JSON.stringify(this.streamData.data.attributes.meta);

      axios
        .put(
          urls.apiPutDonationStream
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignId)
            .replace(":donationstreamId", this.streamId),
          newData
        )
        .then(() => {
          this.sending = false;
          this.$store.dispatch("updateCampaign", extend);

          this.modalClose();

          this.$_notificationsMixin_makeToastCompat({
            group: "Notification",
            title: this.$t("edit_campaign.success_save_donation_streams"),
          });
        })
        .catch((e) => {
          this.sending = false;
          this.renderError(e);
        });
    },

    changeImage() {
      this.errors = [];

      let fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file");
      fileInput.setAttribute("accept", "image/png, image/gif, image/jpeg, image/bmp, image/x-icon");
      fileInput.classList.add("sending-image-input");

      fileInput.addEventListener("change", () => {
        this.sending = true;

        if (fileInput.files != null && fileInput.files[0] != null) {
          let form_data = new FormData();

          form_data.append("file", fileInput.files[0]);

          axios
            .post(urls.loadImageQuill.replace(":OrgId", this.orgId), form_data)
            .then((response) => {
              this.sending = false;

              this.streamData.data.attributes.image = response.data.src;
            })
            .catch((e) => {
              this.sending = false;

              this.renderError(e);
            });
        }
      });

      document.querySelector("body").appendChild(fileInput);

      fileInput.click();
      fileInput.remove();
    },

    renderError(e) {
      if (e.response) {
        if (e.response.data.error) {
          this.errors.push(e.response.data.error);
        } else {
          this.errors.push(this.$t("template.error_default"));
        }
      } else {
        this.errors.push(this.$t("template.error_default"));
      }
    },

    openModalEdit(stream) {
      this.edit = true;

      this.streamData.data.attributes = Object.assign({}, stream.attributes);
      this.streamId = stream.id;

      this.openModal();
    },

    clearData() {
      this.edit = false;

      this.streamId = 0;
      this.errors = [];

      this.streamData.data.attributes = {
        title: "",
        description: "",
        color: "#cccccc",
        goal: 0,
        image: "",
        order: 1,
        default: false,
        meta: {
          show_on_campaign_page: true,
          show_on_donation_form: true,
        },
      };
    },
  },
});
</script>

<style></style>
