import moment from "moment";
import { mapGetters } from "vuex";

export const campaignStatusMixinProps = {
  campaignAttrs: {
    type: Object,
    required: true,
  },
  campaignId: {
    type: String,
    default: "-1",
  },
  isWizardMode: Boolean,
};

export const campaignStatusMixin = {
  computed: {
    ...mapGetters({
      metasByCampaignId: "getMetasByCampaignId",
    }),
    endDate() {
      if (this.campaignAttrs.end_date) {
        let endCampaign = new Date(this.campaignAttrs.end_date * 1000);

        return moment(endCampaign).format("MMMM Do YYYY, h:mm a");
      } else {
        return "Not set";
      }
    },
    displayCampaignEndDate() {
      const metas = this.metasByCampaignId(this.campaignId);
      const meta = metas?.display_campaign_end_date;
      const enabled = meta && meta?.value;

      if (enabled) {
        let date = new Date(meta.date);

        return moment(date).format("MMMM Do YYYY, h:mm a");
      }

      return "Not set";
    },
    campaignState() {
      if (this.isWizardMode) {
        return "wizard";
      }

      switch (this.campaignAttrs.mode) {
        case 0:
          return "pending";
        case 1:
          return "countdown";
        case 2:
          return "live";
        case 3:
          return "success";
        case 4:
          return "failed";
        case 5:
          return "demo";
        default:
          throw new Error("[CampaignStatus.vue]: Cannot resolve state status");
      }
    },
    stateClass() {
      switch (this.campaignState) {
        case "pending":
          return "campaign-status__type--success";
        case "countdown":
          return "campaign-status__type--success";
        case "live":
          return "campaign-status__type--active";
        case "success":
          return "campaign-status__type--success";
        case "failed":
          return "campaign-status__type--success";
        case "demo":
          return "campaign-status__type--active";
        case "wizard":
          return "campaign-status__type--wizard";
        default:
          throw new Error("[CampaignStatus.vue]: Bad campaign state");
      }
    },
  },
};
