<template>
  <div class="topstat-block">
    <div class="count-block">
      <div class="count-block__data">
        <div class="count-block__title">Profile Visits</div>
        <div class="count-block__description">
          <div class="count-block__moment">0 in last hour</div>
          <div class="count-block__total">0</div>
        </div>
      </div>
    </div>
    <div class="count-block">
      <div class="count-block__data">
        <div class="count-block__title">Profile Visits</div>
        <div class="count-block__description">
          <div class="count-block__moment">0 in last hour</div>
          <div class="count-block__total">0</div>
        </div>
      </div>
    </div>
    <div class="count-block">
      <div class="count-block__data">
        <div class="count-block__title">Profile Visits</div>
        <div class="count-block__description">
          <div class="count-block__moment">0 in last hour</div>
          <div class="count-block__total">0</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState({}),
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
.topstat-block {
  .count-block {
    margin-right: 10px;
    &:last-child {
      margin-right: 0px;
    }
  }
}

.count-block__description {
  text-align: center;
}

.count-block__title {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  font-family: $vito-light;
}
</style>
