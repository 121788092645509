<template>
  <div>
    <CategoryTitle :title="$t('video_tutorials.title')" withActions>
      <LanguageDropdown
        :dropdown-title="$t('video_tutorials.languages')"
        :dropdown-options="getOldLangsVariant"
        @onClick="onLangClick"
      />
    </CategoryTitle>
    <video-tutorials-lang-content v-model="currentLang" />
  </div>
</template>

<script>
import CategoryTitle from "@/common-components/ui-elements/category-title";
import LanguageDropdown from "./language-dropdown.vue";
import VideoTutorialsLangContent from "./lang-content.vue";
import { mapGetters } from "vuex";

export default {
  name: "VideoTutorials",
  data() {
    return {
      currentLang: "en",
    };
  },
  computed: {
    ...mapGetters({
      getOldLangsVariant: "getOldLangsVariant",
    }),
  },
  components: {
    VideoTutorialsLangContent,
    CategoryTitle,
    LanguageDropdown,
  },
  methods: {
    onLangClick(lang) {
      this.currentLang = lang;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .category-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .action-block {
    margin: 0 auto 10px 0;
  }
}
</style>
