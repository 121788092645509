<template>
  <div class="py-2">
    <h4 class="h4-like">
      {{ $t("dashboard.update_payment_details", "Update payment details") }}
    </h4>

    <div v-if="!success">
      <b-overlay :show="loading" rounded="sm">
        <b-form-group
          label="Enter your card"
          class="m-0"
          :invalid-feedback="cardFieldError"
          :state="!cardFieldError"
        >
          <div class="form-control d-flex flex-column justify-content-center">
            <div ref="card"></div>
          </div>
        </b-form-group>
      </b-overlay>
    </div>

    <div v-if="success" class="text-center">
      <b-iconstack font-scale="5" variant="success">
        <b-icon stacked icon="circle"></b-icon>
        <b-icon stacked icon="check"></b-icon>
      </b-iconstack>
    </div>

    <div class="d-flex justify-content-center py-2">
      <b-button
        v-if="!success"
        class="bttn bttn--sm bttn--orange text-nowrap"
        :disabled="loading || !!cardFieldError || !cardFeildComplete"
        @click="save"
      >
        {{ $t("dashboard.update", "Update") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls.js";
import { notificationsMixin } from "@/mixins";

const elementStyles = {
  base: {
    color: "#555",
    ":-webkit-autofill": {
      color: "#e39f48",
    },
  },
  invalid: {
    color: "#E25950",
    "::placeholder": {
      color: "#FFCCA5",
    },
  },
};

export default {
  props: {
    donationID: {
      type: [String, Number],
      default: 0,
    },
    campaignID: {
      type: [String, Number],
      default: 0,
    },
    donationAttrs: {
      type: Object,
      default: null,
    },
  },

  mixins: [notificationsMixin],

  data() {
    return {
      loading: false,
      success: false,

      stripe: null,
      cardFieldError: "",
      cardFeildComplete: false,
    };
  },

  computed: {
    orgID() {
      return localStorage.getItem("orgId");
    },
    updatePyamentDatailsURL() {
      return urls.paymentDetails(this.orgID, this.campaignID, this.donationID);
    },
  },

  mounted() {
    this.getDetails();
  },

  methods: {
    stripeInit({ stripe_public_key, stripe_account_id }) {
      this.stripe = Stripe(stripe_public_key, { stripeAccount: stripe_account_id });
    },

    cardFieldMount() {
      const elements = this.stripe.elements();
      this.card = elements.create("card", { style: elementStyles, hidePostalCode: true });
      this.card.mount(this.$refs.card);

      this.card.addEventListener("change", (event) => {
        this.cardFieldError = event?.error?.message || "";
        this.cardFeildComplete = event.complete;
      });
    },

    getDetails() {
      this.loading = true;

      axios
        .get(this.updatePyamentDatailsURL)
        .then(({ data }) => {
          this.stripeInit(data.data);
          this.cardFieldMount();
        })
        .catch(this.$_notificationsMixin_handleCatch)
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      this.loading = true;
      this.createPaymentMethod().then((pm) => {
        this.updDetails(pm.id);
      });
    },

    createPaymentMethod() {
      const { billing_last_name, billing_name, email, phone } = this.donationAttrs;

      return this.stripe
        .createPaymentMethod({
          type: "card",
          card: this.card,
          billing_details: {
            name: `${billing_name} ${billing_last_name}`,
            email,
            phone,
          },
        })
        .then((result) => {
          if (result.error) {
            console.log(result.error);
            this.$_notificationsMixin_makeErrorToast(
              "Error",
              result.error.message || "Something went wrong"
            );
          }
          return result.paymentMethod;
        });
    },

    updDetails(payment_method_id) {
      this.loading = true;

      const payload = {
        stripe: {
          payment_method_id,
        },
      };

      axios
        .put(this.updatePyamentDatailsURL, payload)
        .then(({ data }) => {
          this.success = data.status === "ok";
        })
        .catch(this.$_notificationsMixin_handleCatch)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
