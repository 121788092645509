<template>
  <div class="col-md-6 col-sm-12 contact-form">
    <h4 class="mb-4">{{ $t("account.your_contact_details") }}</h4>

    <b-form @submit.prevent="onSubmit">
      <!-- hide option https://app.asana.com/0/1192055722440618/1199112663491804 -->
      <!-- <div class="col-12 form-unit switch-public">
        <label class="form-unit__label--upper-sm">
          {{ $t('account.switch_public_account_contacts') }}
        </label>
        <div class="switch-public--switch">
          <input
            type="checkbox"
            v-model="isPublicContacts"
            @change="changePublicContacts"
            id="switch_public_account_contacts"
          />
          <label for="switch_public_account_contacts"></label>
        </div>
      </div> -->

      <b-form-group
        :label="$t('account.first_name')"
        label-for="account-first-name"
        label-class="form-unit__label--upper-sm"
        class="account-card__inputs"
      >
        <b-form-input
          required
          v-model="accountDetails.data.attributes.first_name"
          id="account-first-name"
          type="text"
          name="firstName"
          class="form-control form-unit__input"
          placeholder="Enter your first name"
          autocomplete="nope"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :label="$t('account.last_name')"
        label-for="account-last-name"
        label-class="form-unit__label--upper-sm"
        class="account-card__inputs"
      >
        <b-form-input
          required
          v-model="accountDetails.data.attributes.last_name"
          id="account-last-name"
          type="text"
          name="lastName"
          class="form-control form-unit__input"
          placeholder="Enter your last name"
          autocomplete="nope"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :label="$t('account.email')"
        label-for="account-email"
        label-class="form-unit__label--upper-sm"
        class="account-card__inputs"
      >
        <b-form-input
          required
          v-model="accountDetails.data.attributes.email"
          id="account-email"
          type="email"
          class="form-control form-unit__input"
          placeholder="Enter your email"
          autocomplete="nope"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('template.phone_number')"
        label-for="phone"
        label-class="form-unit__label--upper-sm"
        class="account-card__inputs"
      >
        <VueTelInput
          name="phone"
          v-model="accountDetails.data.attributes.phone"
          v-validate="{
            twilio_lookup: twilioLookup,
          }"
          mode="international"
          data-vv-validate-on="blur"
          valid-characters-only
          tabindex="4"
          @validate="validate"
          :defaultCountry="defaultCountry"
          :autoDefaultCountry="autoDefaultCountry"
        />
        <b-form-invalid-feedback force-show v-if="twilioLookupErrorMsg">
          {{ twilioLookupErrorMsg }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-button
        type="submit"
        :disabled="isLoading || load || twilioLookupErrorMsg.length > 0"
        class="bttn bttn--sm bttn--orange"
        >{{ $t("account.save_change") }}</b-button
      >
    </b-form>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import { VueTelInput } from "vue-tel-input";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    VueTelInput,
  },

  data() {
    return {
      load: false,
      isPublicContacts: null,
      accountDetails: {
        data: {
          attributes: {
            first_name: "",
            last_name: "",
            email: "",
          },
        },
      },
      phoneObject: null,
    };
  },

  mounted() {
    this.updateAccountForm();
  },

  computed: {
    ...mapState({
      donorAccount: (state) => state.donorAccount.account.attributes,
      isLoading: (state) => state.donorAccount.loadingAccount,
      defaultCountry: (s) => s.account.userCountry,
    }),
    ...mapGetters(["getIncludedByType"]),
    autoDefaultCountry() {
      return this.defaultCountry === "N/A" || this.defaultCountry.length === 0;
    },
    twilioLookupErrorMsg() {
      const exist = this.vErrorsHas("phone", "twilio_lookup");

      if (exist) {
        return this.$t("template.field_error_twilio_lookup", "Twilio lookup error");
      }

      return "";
    },
    twilioLookup() {
      return {
        valid: this.phoneObject?.valid || false,
      };
    },
  },

  watch: {
    donorAccount() {
      this.updateAccountForm();
    },
  },

  methods: {
    onSubmit() {
      if (this.twilioLookupErrorMsg.length > 0) {
        return;
      }

      this.load = true;
      this.$parent.$refs.error.clearErrors();

      axios
        .patch(urls.donorAccount, this.accountDetails)
        .then((res) => {
          this.load = false;

          this.$store.commit("setDonorAccount", res.data);

          this.$_notificationsMixin_makeToastCompat({
            group: "donor",
            title: this.$t("account.success_edit"),
          });
        })
        .catch((e) => {
          this.load = false;

          this.$parent.$refs.error.setErrorResponse(e.response);
          console.log(e);
        });
    },

    changePublicContacts() {
      const data = {
        data: {
          type: "customer_setting",
          attributes: {
            val_bool: this.isPublicContacts,
            type: "donor_contact_is_public",
          },
        },
      };

      axios.post(urls.donorAccountSettings, data).then((res) => {
        this.$store.dispatch("updateDonorAccountInclude", res.data.data);

        this.$_notificationsMixin_makeToastCompat({
          group: "donor",
          title: this.$t("account.success_edit"),
        });
      });
    },

    updateAccountForm() {
      const donorContactIsPublic = this.getIncludedByType("donor_contact_is_public");

      this.isPublicContacts = donorContactIsPublic
        ? donorContactIsPublic.attributes.val_bool
        : false;

      if (this.donorAccount) {
        const { first_name, last_name, email, phone } = this.donorAccount;

        this.accountDetails.data.attributes = { first_name, last_name, email, phone };
      }
    },

    vErrorsHas(field, rule) {
      return this.vErrors.items.find((el) => el.field === field && el.rule === rule);
    },

    validate(phoneObject) {
      this.phoneObject = phoneObject;
    },
  },
});
</script>

<style lang="scss" scoped>
.form-control {
  border-color: #e6eaed;
  background: #f7fbff;
}

.switch-public {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  &--switch {
    input {
      display: block;
      opacity: 0;
    }
    label {
      width: 60px;
      height: 30px;
      cursor: pointer;
      display: inline-block;
      position: relative;
      background: rgb(189, 189, 189);
      border-radius: 30px;
      transition: background-color 0.4s;
      -moz-transition: background-color 0.4s;
      -webkit-transition: background-color 0.4s;
    }
    label:after {
      left: 0;
      width: 20px;
      height: 20px;
      margin: 5px;
      content: "";
      position: absolute;
      background: #fff;
      border-radius: 10px;
    }
    input:checked + label {
      background: #3cc7a0;
    }
    input:checked + label:after {
      left: auto;
      right: 0;
    }
  }
}

@media (max-width: 768px) {
  .contact-form {
    margin-bottom: 25px;
  }
}
</style>
