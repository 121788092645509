<template>
  <div
    v-if="type !== 'bootstrap'"
    class="icon icon__tooltip"
    :class="{ [lang]: true }"
    :data-message="tooltipMsg"
    :style="{ ...placementStyles }"
  >
    <slot>
      {{ sign }}
    </slot>
  </div>
  <div
    v-else
    :class="{ icon: !noStyle }"
    v-b-popover.hover.focus.auto="{ customClass: 'my-popover-class', content: tooltipMsg }"
  >
    <slot>
      {{ sign }}
    </slot>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "IconInfo",
  props: {
    tooltipMsg: {
      type: String,
      default: "",
    },
    sign: {
      type: String,
      default: "i",
    },
    placement: {
      type: String,
      default: "top",
    },
    type: {
      type: String,
      default: "default",
    },
    noStyle: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      lang: (state) => state.i18n.locale,
    }),
    placementCache() {
      return (
        {
          top: "translateY(-100%)",
          bottom: "translateY(100%)",
        }[this.placement] || ""
      );
    },
    placementStyles() {
      return {
        "--icon-info-transform": this.placementCache,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
.icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  text-align: center;
  line-height: 16px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  border: 1px solid #e8ecf3;
  background-color: $bg-50;
  font-size: 12px;
  &__tooltip {
    &:after {
      display: inline-block;
      padding: 3px 6px;
      text-align: initial;
      transform: var(--icon-info-transform);
      content: attr(data-message);
      background-color: $bg-50;
      border: 1px solid #e8ecf3;
      border-radius: 3px;
      position: absolute;
      top: -5px;
      left: 0;
      visibility: hidden;
      transition: visibility 0.2s ease-in-out;
      z-index: 1;
    }
    &:hover {
      &:after {
        visibility: visible;
      }
    }
  }
}
.he {
  &.icon {
    &__tooltip {
      &:after {
        left: initial;
        right: 0;
      }
    }
  }
}
.my-popover-class {
  max-width: 360px;
}
</style>
