import urls from "@/constants/urls";
import axios from "axios";

const state = {
  collection: [],
  loading: {
    getTeamSubAccounts: false,
    createTeamSubAccount: false,
    editTeamSubAccount: false,
    getLoginsToTeamsUsers: false,
  },
};

const getters = {};

const mutations = {
  SET_COLLECTION(state, collection) {
    state.collection = collection;
  },
  SET_LOADING(state, [k, v]) {
    state.loading[k] = v;
  },
};

const actions = {
  getLoginsToTeamsUsers({ commit }, { oid, cid }) {
    const url = urls["getLoginsToTeamsUsers"].replace(":oid", oid).replace(":cid", cid);
    commit("SET_LOADING", ["getLoginsToTeamsUsers", true]);
    return axios
      .get(url)
      .then(({ data }) => {
        return data;
      })
      .finally(() => commit("SET_LOADING", ["getLoginsToTeamsUsers", false]));
  },
  getTeamSubAccounts({ commit }, { oid, cid, id }) {
    const url = urls["getTeamSubAccounts"]
      .replace(":oid", oid)
      .replace(":cid", cid)
      .replace(":id", id);
    commit("SET_LOADING", ["getTeamSubAccounts", true]);
    return axios
      .get(url)
      .then(({ data }) => {
        commit("SET_COLLECTION", data);
        return data;
      })
      .finally(() => commit("SET_LOADING", ["getTeamSubAccounts", false]));
  },
  createTeamSubAccount({ commit, state }, { oid, cid, id, data }) {
    const url = urls["createTeamSubAccount"]
      .replace(":oid", oid)
      .replace(":cid", cid)
      .replace(":id", id);
    commit("SET_LOADING", ["createTeamSubAccount", true]);
    return axios
      .post(url, data)
      .then(({ data }) => {
        commit("SET_COLLECTION", [...state.collection, data]);
        return data;
      })
      .finally(() => commit("SET_LOADING", ["createTeamSubAccount", false]));
  },
  editTeamSubAccount({ commit, state }, { oid, cid, id, data }) {
    const url = urls["editTeamSubAccount"]
      .replace(":oid", oid)
      .replace(":cid", cid)
      .replace(":id", id);
    commit("SET_LOADING", ["editTeamSubAccount", true]);
    return axios
      .patch(url, data)
      .then(({ data }) => {
        const copy = [...state.collection];
        const index = copy.findIndex((c) => c.id === data.id);
        copy[index] = data;
        commit("SET_COLLECTION", copy);
        return data;
      })
      .finally(() => commit("SET_LOADING", ["editTeamSubAccount", false]));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
