<template>
  <div>
    <button class="bttn bttn--sm bttn--orange" :disabled="!hasAccess" @click="openModal">
      {{ $t("edit_campaign.add_new_donation_level") }}
    </button>
    <!-- Modal Component -->

    <b-modal id="level-modal-form" @hidden="clearData" size="lg">
      <div slot="modal-header">
        <h2>{{ $t("edit_campaign.donation_level_form") }}</h2>
        <div class="modal-header__hide" @click="modalClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button type="button" class="bttn bttn--lg bttn--blue" @click="modalClose" name="button">
          {{ $t("edit_campaign.matcher_form_close") }}
        </button>
        <button
          type="submit"
          form="matcher-form"
          :disabled="sending"
          :class="sending ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
        >
          {{ $t("edit_campaign.matcher_form_save") }}
        </button>
      </div>

      <form id="matcher-form" class="gateway-form" @submit.prevent="saveForm">
        <error v-if="errors" :errors="errors"></error>

        <div class="form-block">
          <div class="row">
            <div class="col-12 col-lg-3">
              <div class="matcher-card__img">
                <div class="matcher-img">
                  <label v-if="!levelData.data.attributes.image">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                      />
                    </svg>
                    {{ $t("edit_campaign.upload_donation_level_image") }}
                  </label>

                  <div v-if="levelData.data.attributes.image" class="matcher-img__loaded">
                    <div class="image-wrapper">
                      <img :src="levelData.data.attributes.image" alt />
                    </div>
                    <div class="matcher-img__action matcher-img__delete">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                        />
                        <path d="M0 0h24v24H0z" fill="none" />
                      </svg>
                    </div>
                    <label class="matcher-img__action matcher-img__change">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                        />
                      </svg>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-9">
              <div class="row">
                <div class="col-12">
                  <div class="form-group form-unit">
                    <label
                      class="form-unit__label form-unit__label--upper-sm form-group__label--required"
                    >
                      {{ $t("edit_campaign.donation_level_title") }}
                    </label>
                    <input
                      type="text"
                      required
                      class="form-control form-unit__input"
                      v-model="levelData.data.attributes.title"
                    />
                  </div>
                  <div class="form-group form-unit">
                    <label class="form-unit__label form-unit__label--upper-sm">
                      {{ $t("edit_campaign.donation_level_subtitle") }}
                    </label>
                    <input
                      type="text"
                      class="form-control form-unit__input"
                      v-model="levelData.data.attributes.subtitle"
                    />
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <div class="form-group form-unit">
                        <label
                          class="form-unit__label form-unit__label--upper-sm form-group__label--required"
                        >
                          {{ $t("edit_campaign.donation_level_amount") }}
                        </label>
                        <input
                          type="number"
                          required
                          class="form-control form-unit__input"
                          v-model.number="levelData.data.attributes.amount"
                        />
                      </div>
                    </div>
                    <div class="col-6">
                      <!-- <div class="form-group form-unit">
                        <label
                          class="form-unit__label form-unit__label--upper-sm form-group__label--required"
                        >{{ $t('edit_campaign.matcher_order') }}</label>
                        <input
                          type="number"
                          required
                          class="form-control form-unit__input"
                          v-model.number="levelData.data.attributes.order"
                        />
                      </div> -->
                    </div>
                  </div>

                  <div>
                    <b-form-input
                      v-model="levelData.data.attributes.color"
                      :value="levelData.data.attributes.color"
                      type="color"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="gateway-form__action"></div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";

import error from "@/components/errors.vue";

export default {
  data: function () {
    return {
      edit: false,
      errors: [],
      sending: false,
      levelId: 0,
      levelData: {
        data: {
          attributes: {
            title: "",
            subtitle: "",
            color: "#cccccc",
            amount: 0,
            image: "",
          },
        },
      },
    };
  },

  components: {
    error,
  },

  computed: mapState({}),

  methods: {},
};
</script>

<style></style>
