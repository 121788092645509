<template>
  <b-modal
    ref="downloadPopup"
    class="download-popup"
    id="download-popup"
    title=""
    centered
    hide-footer
    visible
    hide-header
  >
    <div class="text-center">
      <img
        src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/download-popup-img.png"
        alt=""
      />
      <h5>Download the <a href="">Charidy App.</a></h5>
      <p>
        Enjoy your donor dashboard, rapid donations, recurring gifts, receipt storing and many more
        features to come
      </p>
    </div>
    <div class="text-center btns-wrap">
      <a href="" class="app-store"></a>
      <a href="" class="google-play"></a>
    </div>
  </b-modal>
</template>

<script>
export default {};
</script>

<style lang="scss">
.download-popup {
  background: #1c273999;
  a {
    color: #107598;
    text-decoration: none;
  }
  .close {
    font-size: 2.5rem;
    line-height: 0.5;
    color: #107598;
    opacity: 1;
    width: 36px;
    height: 36px;
    padding: 5px;
    border-radius: 18px;
    margin-top: -5px;
    outline: none;
    &:hover {
      background: #10759824;
    }
  }
  .modal-dialog {
    max-width: 375px;
  }
  .modal-content {
    background: url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco1.svg")
        top 20px left 40% no-repeat,
      //url('https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco2.svg') top -50px right 20% no-repeat,
      #fff;
    .modal-header {
      background: none;
    }
    .modal-body {
      padding-top: 150px;
      background: none;
      padding-bottom: 110px;
      img {
        margin-bottom: 42px;
      }
      h5 {
        font: 18px/1.4 "VitoExtended-Bold";
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 50px;
        color: #414359;
        margin-bottom: 25px;
        font-size: 14px;
      }
    }
  }
  .btns-wrap {
    max-width: 150px;
    text-align: center;
    margin: 0 auto;
    .google-play {
      background: url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/google-play-btn.png")
        center no-repeat;
    }
    .app-store {
      background: url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/app-store-btn.png")
        center no-repeat;
    }
    a {
      display: inline-block;
      vertical-align: middle;
      width: 145px;
      height: 42px;
      margin-bottom: 15px;
      //box-shadow: 0 14px 44px 0 rgba(50, 54, 64, 0.25);
      background-size: 100% !important;
    }
  }
}
</style>
