<template>
  <div>
    <div class="form-group form-unit">
      <label for="email">{{ $t("template.sign_up_email_address", "Email address") }}</label>
      <input
        type="email"
        name="email"
        class="form-control"
        id="email"
        :placeholder="$t('template.sign_up_enter_email_placeholder', 'Enter email')"
        v-model="registerData.email"
        v-validate="'required|email'"
      />
      <span v-show="vErrors.has('email')" class="v-help is-danger">{{
        $t("template.email_is_required")
      }}</span>
    </div>
    <div class="form-group form-unit">
      <label for="name">{{ $t("template.sign_up_name", "name") }}</label>
      <input
        type="text"
        name="firstname"
        class="form-control"
        id="name"
        :placeholder="$t('template.sign_up_enter_your_name_placeholder', 'Enter your name')"
        v-model="registerData.first_name"
        v-validate="'required|min:3'"
      />
      <span v-show="vErrors.has('firstname')" class="v-help is-danger">{{
        $t("template.first_name_is_required")
      }}</span>
    </div>
    <div class="form-group form-unit">
      <password-hint
        ref="passwordHint"
        :target="'password'"
        :password="registerData.password"
      ></password-hint>

      <label for="password">{{ $t("template.sign_up_password", "Password") }}</label>
      <input
        type="password"
        name="password"
        class="form-control"
        id="password"
        :placeholder="$t('template.sign_up_password_placeholder', 'Password')"
        v-model="registerData.password"
        v-validate="'required'"
      />
      <span v-show="vErrors.has('password')" class="v-help is-danger">{{
        $t("template.password_is_required")
      }}</span>
    </div>
    <div class="form-group form-unit">
      <div class="terms-acknowledge">
        <label class="terms-acknowledge__checkbox" :class="{ checked: acknowledge }" tabindex="7">
          <input type="checkbox" name="terms" v-model="acknowledge" v-validate="'required'" />
        </label>
        <p class="terms-acknowledge__text">
          {{ $t("template.by_clicked_i_aggree") }}
          <a :href="`${appHost}/terms`">{{ $t("template.terms_of_service") }}</a>
          {{ $t("template.and") }}
          <a :href="`${appHost}/terms`">{{ $t("template.private_policy") }}</a
          >.
        </p>
        <span v-show="vErrors.has('terms')" class="v-help is-danger">{{
          $t("template.terms_is_required")
        }}</span>
      </div>
    </div>
    <div class="form-group form-unit">
      <div class="subscribe-newsletter">
        <label
          class="subscribe-newsletter__checkbox"
          :class="{ checked: registerData.subscribe_newsletter }"
          tabindex="7"
        >
          <input type="checkbox" name="subscribe" v-model="registerData.subscribe_newsletter" />
        </label>
        <p class="terms-acknowledge__text">
          {{ $t("template.subscribe_newsletter") }}
        </p>
      </div>
    </div>

    <error class="error" :errors="errors"></error>
  </div>
</template>

<script>
import axios from "axios";
import urls, { appHost } from "@/constants/urls.js";
import errorDisplay from "@/views/Error.vue";
import passwordHint from "@/components/password-hint-tooltip";
import { mapActions } from "vuex";
import { load } from "recaptcha-v3";

export default {
  data() {
    return {
      appHost,
      acknowledge: false,
      subscribe: false,
      errors: [],
      registerData: {
        email: "",
        first_name: "",
        password: "",
        subscribe_newsletter: false,
      },
      recaptcha: null,
    };
  },
  components: {
    error: errorDisplay,
    passwordHint,
  },
  mounted() {
    this.initData().then(async ({ data }) => {
      if (data.data.attributes.captcha_site_key_v3) {
        this.recaptcha = await load(data.data.attributes.captcha_site_key_v3);
      }
    });
    if (this.$route.query.ref) {
      this.registerData.ref = this.$route.query.ref;
    }
  },
  methods: {
    ...mapActions({
      initData: "initData",
    }),
    registerUser() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          const token = await this.recaptcha.execute("donor_signup");

          axios
            .post(urls.registerUser, {
              ...this.registerData,
              captcha_token: token,
            })
            .then((response) => {
              if (response.data.Result == "ok") {
                this.$emit("register-success", true);
              }
            })
            .catch((e) => {
              this.$emit("register-success", false);

              if (e.response.data) {
                if (e.response.data.error) {
                  this.errors.push(e.response.data.error);
                } else {
                  this.errors.push(this.$t("template.error_default"));
                }
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            });
        } else {
          this.$emit("register-success", false);
        }
      });
    },
    openHint: function () {
      this.$refs.passwordHint.openHint();
    },
    closeHint: function () {
      this.$refs.passwordHint.closeHint();
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-acknowledge,
.subscribe-newsletter {
  padding-top: 10px;
  display: flex;
  align-items: flex-start;
  text-align: center !important;
  &__checkbox {
    margin-right: 10px;
    display: inline-flex;
    width: 16px;
    height: 16px;
    border: 1px solid #a1a1a1;
    border-radius: 3px;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
    > input {
      width: 1px;
      height: 1px;
      overflow: hidden;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    &.checked:after {
      content: "\02716";
      font-size: 14px;
      color: #414359;
      line-height: 1;
      position: absolute;
      top: 1px;
      left: 2px;
    }
  }
  &__text {
    margin-bottom: 15px;
    font-size: 12px;
  }
}
</style>
