<template>
  <div class="video-block">
    <div class="video-list-wrapper">
      <div class="video-list">
        <video-list-item
          v-for="(video, index) in sortedVideoTutorials"
          :video-item="video"
          :key="index"
          @setVideo="setActive"
        />
      </div>
    </div>
    <div class="video-wrapper">
      <div v-if="selectedTutorial !== null" class="video">
        <youtube-media
          class="youtube-video"
          :video-id="selectedTutorial.youtubeId"
          host="https://www.youtube-nocookie.com"
        />
      </div>
      <div v-if="selectedTutorial !== null" class="video-title">
        {{ selectedTutorial.title }}
      </div>
    </div>
  </div>
</template>

<script>
import VideoListItem from "./video-list-item.vue";

/**
 * Youtube thumbnail images instruction:
 * - high quality
 * https://img.youtube.com/vi/<insert-youtube-video-id-here>/hqdefault.jpg
 * - medium quality
 * https://img.youtube.com/vi/<insert-youtube-video-id-here>/mqdefault.jpg
 * - standard definition
 * https://img.youtube.com/vi/<insert-youtube-video-id-here>/sddefault.jpg
 * */
export default {
  props: {
    value: {
      type: String,
      default: "en",
    },
  },
  components: {
    VideoListItem,
  },
  data() {
    return {
      videoTutorials: [
        {
          order: 10,
          title: "Tuto 1: Create account beta version",
          youtubeId: "V8vnJo3R3CE",
        },
        {
          order: 20,
          title: "Tuto 2: Account settings",
          youtubeId: "2Aj_jRMK9RU",
        },
        {
          order: 30,
          title: "Tuto 3: Account settings",
          youtubeId: "_qoMXmZZcg8",
        },
        {
          order: 40,
          title: "Tuto 4: Account settings",
          youtubeId: "m_hwauPht9o",
        },
        {
          order: 50,
          title: "Tuto 5: Account settings",
          youtubeId: "V2VD1vEjwAY",
        },
        {
          order: 60,
          title: "Tuto 6: create Campaign beta version",
          youtubeId: "88lGue_u6hw",
        },
        {
          order: 70,
          title: "Tuto 7: middle Campaign beta version",
          youtubeId: "HPBJHVcsJuA",
        },
        {
          order: 80,
          title: "Tuto 8: middle Campaign beta version",
          youtubeId: "Tf2VFObazac",
        },
        {
          order: 90,
          title: "Tuto 9: after Campaign beta version",
          youtubeId: "wNMgFqJ1fzg",
        },
        {
          order: 100,
          title: "Tuto 10: after Campaign beta version",
          youtubeId: "exY4njDYLLQ",
        },
      ],
      selectedTutorial: null,
    };
  },
  computed: {
    initialVideo() {
      return this.sortedVideoTutorials.length ? this.sortedVideoTutorials[0] : null;
    },
    sortedVideoTutorials() {
      return this.videoTutorials.slice().sort((a, b) => a.order - b.order);
    },
  },
  created() {
    this.selectedTutorial = this.initialVideo;
  },
  methods: {
    setActive(v) {
      this.selectedTutorial = v;
    },
  },
};
</script>

<style lang="scss" scoped>
.video-block {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
}
.video-list-wrapper {
  display: flex;
  order: 2;
  @media only screen and (min-width: 768px) {
    max-width: 310px;
    min-width: 310px;
    padding-right: 5px;
    order: initial;
  }
}
.video-list {
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  @media only screen and (min-width: 768px) {
    flex-direction: column;
    max-height: 370px;
    overflow-x: visible;
    overflow-y: auto;
    padding-bottom: initial;
    padding-right: 10px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #107598;
  }
}
.video-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 20px;
  @media only screen and (min-width: 768px) {
    margin-bottom: initial;
  }
}
.video {
  width: 100%;
  background-color: gray;
  padding-bottom: 56.25%;
  position: relative;
  order: 2;
  @media only screen and (min-width: 768px) {
    margin-bottom: 40px;
    order: initial;
  }
}
.video-title {
  margin-bottom: 20px;
  font-family: $vito-bold;
  font-size: 24px;
  color: $fd;
  @media only screen and (min-width: 768px) {
    margin-bottom: initial;
  }
}
.youtube-video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  > ::v-deep iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
