import { mapState } from "vuex";
import { appHost } from "@/constants/urls.js";

export const cdnNavMixin = {
  computed: {
    ...mapState({
      $_communities_lang: (state) => state?.i18n?.locale,
    }),
    $_communities_appHost() {
      return appHost;
    },
    navsData() {
      return [
        {
          to: `${this.$_communities_appHost}/products`,
          label: this.$t("home_page_v2.header_nav_products_label", "Products"),
        },
        {
          to: `${this.$_communities_appHost}/about`,
          label: this.$t("home_page_v2.header_nav_about_label", "About"),
        },
        {
          to: `${this.$_communities_appHost}/all-campaigns`,
          label: this.$t("home_page_v2.header_nav_live_label", "Live Campaigns"),
        },
        // { to: '/charidy-v2/knowledgebase', label: this.$t('home_page_v2.header_nav_knowledgebase_label', 'Knowledgebase') },
        // { to: '/charidy-v2/foundation', label: this.$t('home_page_v2.header_nav_foundation_label', 'Charidy Foundation') },
        {
          to: `${this.$_communities_appHost}/contact`,
          label: this.$t("home_page_v2.header_nav_contact_label", "Contact"),
        },
      ];
    },
    dropNav() {
      return Object.values(this.communitiesData).map((v) => ({
        to: v.to,
        label: v.title,
        color: v.color,
        bannerImage: v.bannerImage?.[this.$_communities_lang] || "",
      }));
    },
    communitiesSlider() {
      return Object.values(this.communitiesData).map((v) => ({
        title: v.title,
        color: v.color,
        coverImage: v.coverImage,
        targets: v.targets,
        to: v.to,
        bannerImage: v.bannerImage?.[this.$_communities_lang] || "",
      }));
    },
    communitiesData() {
      return {
        "saving-lives": {
          to: `${this.$_communities_appHost}/community/saving-lives`,
          title: this.communitiesTranslate("saving_lives", "title", "saving lives"),
          color: "#FA6080",
          bannerImage: {
            he: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1697752938_3232373966633538663665656438346130373764326661343938333635663531_31363937373532393338.svg",
          },
          coverImage:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665143301_e50bb24c01f212d8b94e55a2c2b57d7f_1665143301.jpg",
          contentMain: {
            text: [
              {
                accent: true,
                data: this.communitiesTranslate(
                  "saving_lives",
                  "content_main_text_1",
                  "Some communities are born out of necessity, a heart-wrenching story and a deep belief in our ability to affect a positive change, often in the face of insurmountable odds."
                ),
              },
              {
                data: this.communitiesTranslate(
                  "saving_lives",
                  "content_main_text_2",
                  "Charidy Saving Lives is a collection of all those communities dedicated to improving medical outcomes through institutional but also through personal effort. In many cases these communities rally around a cause that has the potential to save lives and many continue to work together, growing their foundation and supporting greater causes."
                ),
              },
            ],
            mediaSrc:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665143604_0cea2ec6723076426965b6b7d6a92fd6_1665143604.jpg",
          },
          contentMagic: {
            text: [
              {
                data: this.communitiesTranslate(
                  "saving_lives",
                  "content_magic_text_1",
                  "Charidy Saving Lives excels at identifying potential audiences, characterizing them, them targeting them with specific communications. Charidy’s fundraising experts are trained to handle sensitive situations, they know how to appeal to the heart without losing the connection to the human that needs our help. Most importantly, Charidy Saving Lives can work on large scale, extended, campaigns designed to fundraise for a new hospital wing as well as on extremely personal, time-sensitive, emergency campaigns that can mean life or death for a sick patient."
                ),
              },
            ],
          },
          targets: {
            title: this.communitiesTranslate("saving_lives", "targets_title", "Raising Money For"),
            data: [
              this.communitiesTranslate("saving_lives", "target_data_1", "Hospitals"),
              this.communitiesTranslate("saving_lives", "target_data_2", "Emergency Services"),
              this.communitiesTranslate("saving_lives", "target_data_3", "Medical Equipment"),
              this.communitiesTranslate(
                "saving_lives",
                "target_data_4",
                "Research And Development"
              ),
            ],
          },
          figures: {
            data: [
              {
                label: this.communitiesTranslate(
                  "saving_lives",
                  "figure_data_label_1",
                  "Infrastructure & Medical services"
                ),
                amount: this.communitiesTranslate(
                  "saving_lives",
                  "figure_data_amount_1",
                  "$1MM-$10MM"
                ),
              },
              {
                label: this.communitiesTranslate("saving_lives", "figure_data_label_2", "Medium"),
                amount: this.communitiesTranslate(
                  "saving_lives",
                  "figure_data_amount_2",
                  "$100K-$1MM"
                ),
              },
              {
                label: this.communitiesTranslate("saving_lives", "figure_data_label_3", "Small"),
                amount: this.communitiesTranslate("saving_lives", "figure_data_amount_3", "$50K"),
              },
            ],
          },
        },
        mehadrin: {
          to: `${this.$_communities_appHost}/mehadrin`,
          title: this.communitiesTranslate("mehadrin", "title", "mehadrin"),
          color: "#2AA3EF",
          bannerImage: {
            he: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1697753613_3230363936333162353238653161343433313638393662336436336434663438_31363937373533363133.svg",
          },
          coverImage:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665146647_10de674b692216fb0034fc2db8996112_1665146647.jpg",
          contentMain: {
            text: [
              {
                accent: true,
                data: this.communitiesTranslate(
                  "mehadrin",
                  "content_main_text_1",
                  "You might be a community organizer raising money toward helping members in need, a dedicated parent committed to the memory of a child by raising money to support children in need, or a passionate student crowdfunding for a new yeshiva building or a major renovation to your neighborhood synagogue."
                ),
              },
              {
                data: this.communitiesTranslate(
                  "mehadrin",
                  "content_main_text_2",
                  "Whatever the cause, you are surrounded by a tight-knit community that’s ready to spring into action. The only thing missing for most hasidic communities is a method for organizing efficient peer-to-peer and ambassador-led crowdfunding campaigns that work around some obvious online restrictions. The good news is Charidy Hasidic communities have been able to set and reach outstanding goals."
                ),
              },
            ],
            mediaSrc:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665146662_10a720f386fe20f9edaf3ccdb31a8f91_1665146662.jpg",
          },
          contentMagic: {
            text: [
              {
                data: this.communitiesTranslate(
                  "mehadrin",
                  "content_magic_text_1",
                  "The majority of our campaign managers hail from Hasidic communities around the world and specialize in medium size campaigns as well as massive Giving Days that involve dozens of small groups working together. Because we speak the language of the community (often literally), we live its customs and are familiar with all the intricacies of Hasidic life, we proudly tout an unmatched talent for creating high-utilization campaigns for this community. Charidy can help you improve the way you plan your campaign and mobilize hundreds or thousands of followers in high-yield campaigns that use proprietary fundraising tools and tactics."
                ),
              },
            ],
          },
          targets: {
            title: this.communitiesTranslate("mehadrin", "targets_title", "Raising Money For"),
            data: [
              this.communitiesTranslate(
                "mehadrin",
                "target_data_1",
                "Synagogue construction and renovation"
              ),
              this.communitiesTranslate("mehadrin", "target_data_2", "Special meetings"),
              this.communitiesTranslate(
                "mehadrin",
                "target_data_3",
                "Major constructions of yeshivas and community institutions"
              ),
              this.communitiesTranslate(
                "mehadrin",
                "target_data_4",
                "Ongoing activities and community building initiatives"
              ),
              this.communitiesTranslate("mehadrin", "target_data_5", "Educational activities"),
              this.communitiesTranslate(
                "mehadrin",
                "target_data_6",
                "Community members assistance activities"
              ),
              this.communitiesTranslate("mehadrin", "target_data_7", "Dedicated Torah scrolls"),
            ],
          },
          figures: {
            data: [
              {
                label: this.communitiesTranslate("mehadrin", "figure_data_label_1", "Large Scale"),
                amount: this.communitiesTranslate("mehadrin", "figure_data_amount_1", "1MM-$50MM"),
              },
              {
                label: this.communitiesTranslate("mehadrin", "figure_data_label_2", "Ongoing"),
                amount: this.communitiesTranslate("mehadrin", "figure_data_amount_2", "$500K-1MM"),
              },
              {
                label: this.communitiesTranslate(
                  "mehadrin",
                  "figure_data_label_3",
                  "Local campaign"
                ),
                amount: this.communitiesTranslate("mehadrin", "figure_data_amount_3", "$100K-500K"),
              },
            ],
          },
        },
        "pet-power": {
          to: `${this.$_communities_appHost}/community/pet-power`,
          title: this.communitiesTranslate("pet_power", "title", "pet power"),
          color: "#E09B3D",
          bannerImage: {
            he: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1697753692_6231366265373266623131353636396335653430623437393135373961313433_31363937373533363932.svg",
          },
          coverImage:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665147263_ca426cbf68d39a3cf1eb180d6f0272d7_1665147263.jpg",
          contentMain: {
            text: [
              {
                accent: true,
                data: this.communitiesTranslate(
                  "pet_power",
                  "content_main_text_1",
                  "4-legged, 2-legged, 8-legged! Charidy Pet Power helps those communities that care deeply about our animal companions."
                ),
              },
              {
                data: this.communitiesTranslate(
                  "pet_power",
                  "content_main_text_2",
                  "Whether your community is made up of cat people or dog people the common thread that runs through everything they root for is characterized by passion, compassion, and a great deal of respect for animals in general and pets in particular."
                ),
              },
            ],
            mediaSrc:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665147280_074687ca04cf0d5a1a33b1effe756eaf_1665147280.jpg",
          },
          contentMagic: {
            text: [
              {
                data: this.communitiesTranslate(
                  "pet_power",
                  "content_magic_text_1",
                  "Charidy has practically perfected its method for turning a loosely organized group of individuals with a shared interest into a tight-knit community of donors. Charidy Pet Power will help you collect relevant data about the people who support your cause, make sense of the information, mobilize them and even turn some of them into avid ambassadors for your campaign."
                ),
              },
            ],
          },
          targets: {
            title: this.communitiesTranslate("pet_power", "targets_title", "Raising Money For"),
            data: [
              this.communitiesTranslate("pet_power", "target_data_1", "Animal shelters"),
              this.communitiesTranslate(
                "pet_power",
                "target_data_2",
                "Assistance to animals at risk"
              ),
              this.communitiesTranslate(
                "pet_power",
                "target_data_3",
                "Supporting the struggle for animal-worthy living conditions"
              ),
            ],
          },
          figures: {
            data: [
              {
                label: this.communitiesTranslate("pet_power", "figure_data_label_1", "Large"),
                amount: this.communitiesTranslate(
                  "pet_power",
                  "figure_data_amount_1",
                  "$250K-300K"
                ),
              },
              {
                label: this.communitiesTranslate("pet_power", "figure_data_label_2", "Medium"),
                amount: this.communitiesTranslate(
                  "pet_power",
                  "figure_data_amount_2",
                  "$100K-$200K"
                ),
              },
              {
                label: this.communitiesTranslate("pet_power", "figure_data_label_3", "Small"),
                amount: this.communitiesTranslate("pet_power", "figure_data_amount_3", "$50K"),
              },
            ],
          },
        },
        "next-gen": {
          to: `${this.$_communities_appHost}/community/next-gen`,
          title: this.communitiesTranslate("next_gen", "title", "next gen"),
          color: "#4FC6B7",
          bannerImage: {
            he: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1697753737_6231356266633039643239383761616262323236346133636463316163373165_31363937373533373337.svg",
          },
          coverImage:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665149306_d11003f5276751bfd87594ea4c93e4ed_1665149306.jpg",
          contentMain: {
            text: [
              {
                accent: true,
                data: this.communitiesTranslate(
                  "next_gen",
                  "content_main_text_1",
                  "From pen and paper for school children in need to entire computer labs and from funding of special activities to full scholarships NextGen communities care about the education and enrichment of the next generation of kids."
                ),
              },
              {
                data: this.communitiesTranslate(
                  "next_gen",
                  "content_main_text_2",
                  "If your charitable organization is motivated by seeing happy students, if you get excited by unwrapping laptop boxes, if you tear up when you see at-risk youth smiling for the first time because they got the attention they need, then you definitely belong to a Charidy NextGen community."
                ),
              },
            ],
            mediaSrc:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665149321_7c6a0b415d16402d42b727bc36773e70_1665149321.jpg",
          },
          contentMagic: {
            text: [
              {
                data: this.communitiesTranslate(
                  "next_gen",
                  "content_magic_text_1",
                  "We honed our community crowdfunding skills in fundraising for educational projects in hasidic communities. We specialize in helping charitable organizations dedicated to children education find their unique voice, pinpoint the message that will resonate with their community and actually grow the community around your cause from campaign to campaign. We help you."
                ),
              },
              {
                data: this.communitiesTranslate(
                  "next_gen",
                  "content_magic_text_2",
                  "Charidy NextGen communities who are consistently exposed to unique communications and are shown to foster tighter bonds to foundations that work with Charidy platform in spite of a highly saturated market."
                ),
              },
            ],
          },
          targets: {
            title: this.communitiesTranslate("next_gen", "targets_title", "Raising Money For"),
            data: [
              this.communitiesTranslate("next_gen", "target_data_1", "Educational activities"),
              this.communitiesTranslate("next_gen", "target_data_2", "Educational buildings"),
              this.communitiesTranslate(
                "next_gen",
                "target_data_3",
                "Equipment for the benefit of education or basic conditions"
              ),
              this.communitiesTranslate("next_gen", "target_data_4", "Parent education"),
              this.communitiesTranslate("next_gen", "target_data_5", "Scholarships"),
              this.communitiesTranslate("next_gen", "target_data_6", "Enrichment classes"),
              this.communitiesTranslate(
                "next_gen",
                "target_data_7",
                "Outstanding programs, programs for at-risk youth"
              ),
            ],
          },
          figures: {
            data: [
              {
                label: this.communitiesTranslate(
                  "next_gen",
                  "figure_data_label_1",
                  "International campaigns"
                ),
                amount: this.communitiesTranslate("next_gen", "figure_data_amount_1", "$1MM-$10MM"),
              },
              {
                label: this.communitiesTranslate("next_gen", "figure_data_label_2", "Ongoing"),
                amount: this.communitiesTranslate("next_gen", "figure_data_amount_2", "$300-1MMK"),
              },
              {
                label: this.communitiesTranslate(
                  "next_gen",
                  "figure_data_label_3",
                  "Local campaign"
                ),
                amount: this.communitiesTranslate("next_gen", "figure_data_amount_3", "$100-$300K"),
              },
            ],
          },
        },
        "special-needs": {
          to: `${this.$_communities_appHost}/community/special-needs`,
          title: this.communitiesTranslate("special_needs", "title", "special needs"),
          color: "#FC2C58",
          bannerImage: {
            he: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1697753777_3832643465346261356134366139353839373933336335343962636462333663_31363937373533373737.svg",
          },
          coverImage:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665175381_0b6e8a0ecbf8d06688413747aeda0380_1665175381.jpg",
          contentMain: {
            text: [
              {
                accent: true,
                data: this.communitiesTranslate(
                  "special_needs",
                  "content_main_text_1",
                  "Your charity organizes field activities for children with special needs, you are raising money for a highly specialized facility for the blind, or on purchasing medical equipment for patients with special needs."
                ),
              },
              {
                data: this.communitiesTranslate(
                  "special_needs",
                  "content_main_text_2",
                  "If your community cares about improving the lives of children or adults with special needs through dedicated counselors, specially designed vacations, and suitable opportunities for work, then you belong with the Charidy Special Needs community."
                ),
              },
            ],
            mediaSrc:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665175397_48b023ad7a158539d41a8e088f576e2c_1665175397.jpg",
          },
          contentMagic: {
            text: [
              {
                data: this.communitiesTranslate(
                  "special_needs",
                  "content_magic_text_1",
                  "Charidy Special Needs has accumulated years of experience approaching diverse populations. We help directors make contact and create dialogue with potential donors, even those who come from the immediate families and close circles of friends of those in need. It’s an artful science and it requires every bit of emotional intelligence and technical support to execute perfectly. Our work has helped flageling organizations solidify their community of donors and engage them year-round for maximum impact beyond the campaign day."
                ),
              },
            ],
          },
          targets: {
            title: this.communitiesTranslate("special_needs", "targets_title", "Raising Money For"),
            data: [
              this.communitiesTranslate(
                "special_needs",
                "target_data_1",
                "Organizations in the field working on ongoing activities"
              ),
              this.communitiesTranslate(
                "special_needs",
                "target_data_2",
                "Renovation or construction of buildings"
              ),
              this.communitiesTranslate(
                "special_needs",
                "target_data_3",
                "Medical equipment for patients with special needs"
              ),
              this.communitiesTranslate(
                "special_needs",
                "target_data_4",
                "Pay for counselors, workers"
              ),
              this.communitiesTranslate(
                "special_needs",
                "target_data_5",
                "Pay for special activities, vacations"
              ),
            ],
          },
          figures: {
            data: [
              {
                label: this.communitiesTranslate(
                  "special_needs",
                  "figure_data_label_1",
                  "Capital improvement"
                ),
                amount: this.communitiesTranslate(
                  "special_needs",
                  "figure_data_amount_1",
                  "$1MM-$10MM"
                ),
              },
              {
                label: this.communitiesTranslate(
                  "special_needs",
                  "figure_data_label_2",
                  "Complex activities"
                ),
                amount: this.communitiesTranslate(
                  "special_needs",
                  "figure_data_amount_2",
                  "$100K-$1MM"
                ),
              },
              {
                label: this.communitiesTranslate(
                  "special_needs",
                  "figure_data_label_3",
                  "Activities"
                ),
                amount: this.communitiesTranslate("special_needs", "figure_data_amount_3", "$75K"),
              },
            ],
          },
        },
        "eco-friendly": {
          to: `${this.$_communities_appHost}/community/eco-friendly`,
          title: this.communitiesTranslate("eco_friendly", "title", "eco friendly"),
          color: "#B4D81C",
          bannerImage: {
            he: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1697753816_6135383631356138346261353261376339363536363931373535623836396437_31363937373533383136.svg",
          },
          coverImage:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665175828_02ea3acdbea8e5b1596fb9bc30e0394f_1665175828.jpg",
          contentMain: {
            text: [
              {
                accent: true,
                data: this.communitiesTranslate(
                  "eco_friendly",
                  "content_main_text_1",
                  "The unique challenge of rallying people who are not activists around an ecological issue is that it doesn’t always feel imminent."
                ),
              },
              {
                data: this.communitiesTranslate(
                  "eco_friendly",
                  "content_main_text_2",
                  "It’s this sense of “we’ll get to it later” that eco-friendly communities must overcome to engage donors and create successful fundraising campaigns. If you sense the urgency of ecological issues, if you’re passionate about educating people about the effects of climate change and the value of sustainability, localization, and green construction, you are definitely part of the Charidy Eco-Friends Community."
                ),
              },
            ],
            mediaSrc:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665175847_6c8720977fe40abd193ed1eff83088a9_1665175847.jpg",
          },
          contentMagic: {
            text: [
              {
                data: this.communitiesTranslate(
                  "eco_friendly",
                  "content_magic_text_1",
                  "Charidy’s experience and infectious positive attitude have proven repeatedly our ability to infuse ecological fundraising campaigns with a much needed urgency. Through tried and true methods we will give you the tools to mobilize your community around long-term issues that do not always feel immediate. And when a local oil spill or forest fire require immediate funds to mitigate a looming disaster we will be there with you to help you through the twists and turns that will get your community behind what matters right now."
                ),
              },
            ],
          },
          targets: {
            title: this.communitiesTranslate("eco_friendly", "targets_title", "Raising Money For"),
            data: [
              this.communitiesTranslate(
                "eco_friendly",
                "target_data_1",
                "Dissemination of information"
              ),
              this.communitiesTranslate(
                "eco_friendly",
                "target_data_2",
                "Lobbying and promotion of laws"
              ),
              this.communitiesTranslate("eco_friendly", "target_data_3", "Educational initiatives"),
              this.communitiesTranslate(
                "eco_friendly",
                "target_data_4",
                "Projects that require equipment or construction"
              ),
              this.communitiesTranslate(
                "eco_friendly",
                "target_data_5",
                "Rehabilitation after disasters (Fires, oil leaks, etc.)"
              ),
            ],
          },
          figures: {
            data: [
              {
                label: this.communitiesTranslate(
                  "eco_friendly",
                  "figure_data_label_1",
                  "International campaigns"
                ),
                amount: this.communitiesTranslate(
                  "eco_friendly",
                  "figure_data_amount_1",
                  "$500K-$1MM"
                ),
              },
              {
                label: this.communitiesTranslate("eco_friendly", "figure_data_label_2", "Ongoing"),
                amount: this.communitiesTranslate(
                  "eco_friendly",
                  "figure_data_amount_2",
                  "$250K-$500K"
                ),
              },
              {
                label: this.communitiesTranslate(
                  "eco_friendly",
                  "figure_data_label_3",
                  "Local campaign"
                ),
                amount: this.communitiesTranslate(
                  "eco_friendly",
                  "figure_data_amount_3",
                  "$100-250K"
                ),
              },
            ],
          },
        },
        humanitarian: {
          to: `${this.$_communities_appHost}/community/humanitarian`,
          title: this.communitiesTranslate("humanitarian", "title", "human-itarian"),
          color: "#9B592C",
          bannerImage: {
            he: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1697753861_6634626230383832356264663462363038646561636138323632396166633832_31363937373533383631.svg",
          },
          coverImage:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665176088_da1b343d34be7516371984ef67fedbe7_1665176088.jpg",
          contentMain: {
            text: [
              {
                accent: true,
                data: this.communitiesTranslate(
                  "humanitarian",
                  "content_main_text_1",
                  "Charidy Humanitarian works with charitable communities who fundraise for the benefit of other communities."
                ),
              },
              {
                data: this.communitiesTranslate(
                  "humanitarian",
                  "content_main_text_2",
                  "These communities are typically in need of food or medicine, and basic needs in the days and weeks following a natural disaster or an armed conflict. If you are actively crowdfunding to support the struggle for human rights anywhere around the world, if you can’t keep silent while entire populations are struggling with the effects of a humanitarian crisis, Charidy Humanitarian will help you tap the power of your humanitarian community."
                ),
              },
            ],
            mediaSrc:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665176124_e8bbd98a043af4c1005c6d4eb7345680_1665176124.jpg",
          },
          contentMagic: {
            text: [
              {
                data: this.communitiesTranslate(
                  "humanitarian",
                  "content_magic_text_1",
                  "Charidy Humanitarian solves the basic operational issues of managing the donor database, targeting potential supporters, and rallying them around the current campaign. But this goes beyond logistics. Charidy will help you activate your audience, inform them and excite them about your cause, mobilize them during a campaign and turn them from a group of unrelated philanthropists to a strong community that can have serious impact during humanitarian crises."
                ),
              },
            ],
          },
          targets: {
            title: this.communitiesTranslate("humanitarian", "targets_title", "Raising Money For"),
            data: [
              this.communitiesTranslate("humanitarian", "target_data_1", "Homeless care"),
              this.communitiesTranslate("humanitarian", "target_data_2", "Food for the needy"),
              this.communitiesTranslate("humanitarian", "target_data_3", "Medicine for the needy"),
              this.communitiesTranslate(
                "humanitarian",
                "target_data_4",
                "Rehabilitation after disasters"
              ),
              this.communitiesTranslate("humanitarian", "target_data_5", "Human rights campaigns"),
            ],
          },
          figures: {
            data: [
              {
                label: this.communitiesTranslate(
                  "humanitarian",
                  "figure_data_label_1",
                  "International campaigns"
                ),
                amount: this.communitiesTranslate(
                  "humanitarian",
                  "figure_data_amount_1",
                  "$1MM-$5MM"
                ),
              },
              {
                label: this.communitiesTranslate("humanitarian", "figure_data_label_2", "Ongoing"),
                amount: this.communitiesTranslate(
                  "humanitarian",
                  "figure_data_amount_2",
                  "$100K-$1MM"
                ),
              },
              {
                label: this.communitiesTranslate(
                  "humanitarian",
                  "figure_data_label_3",
                  "Local campaign"
                ),
                amount: this.communitiesTranslate(
                  "humanitarian",
                  "figure_data_amount_3",
                  "$50-100K"
                ),
              },
            ],
          },
        },
        "faith-based": {
          to: `${this.$_communities_appHost}/community/faith-based`,
          title: this.communitiesTranslate("faith_based", "title", "faith based"),
          color: "#8952FF",
          bannerImage: {
            he: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1697753907_3439393265313734643463636231653330353966323630303436633032323430_31363937373533393037.svg",
          },
          coverImage:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665177044_b911e2eafedd7e3072577c475da7dceb_1665177044.jpg",
          contentMain: {
            text: [
              {
                accent: true,
                data: this.communitiesTranslate(
                  "faith_based",
                  "content_main_text_1",
                  "Christian, Jewish, Muslim, Hindu… Charidy Faith Based communities are made up of people united around a shared belief."
                ),
              },
              {
                data: this.communitiesTranslate(
                  "faith_based",
                  "content_main_text_2",
                  "They may belong to the same church, fundraise for renovations to their mosque, or champion local causes in their community characterized by strong social and moral values. If you care about the social fabric of your religious community, if you’re concerned about education, if you have big plans for community outreach programs around a place of worship, and if you are looking for innovative attractive messaging that will make community members perk up with excitement, you belong to the Charidy Faith Based community."
                ),
              },
            ],
            mediaSrc:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665176895_47878f66b39c26da3927ea2b7a161613_1665176895.jpg",
          },
          contentMagic: {
            text: [
              {
                data: this.communitiesTranslate(
                  "faith_based",
                  "content_magic_text_1",
                  "Charidy’s fundraising experts practically grew up in the Hasidic community where they learned how to elevate the science of charitable crowdfunding into an artform. We are familiar with the dynamics of faith-based communities and know how to harness their zest for rallying behind important causes. We’ve learned how to  identify internal tensions early on and our campaign managers are trained to help you work through them in order to bring about the success smaller fundraising events and capital campaigns. The Charidy Giving Day is an especially powerful tool for exponentially multiplying the raising power of many small entities under one powerful cause or one umbrella organization."
                ),
              },
            ],
          },
          targets: {
            title: this.communitiesTranslate(
              "faith_based",
              "targets_title",
              "We’ve helped faith based communities raise money for:"
            ),
            data: [
              this.communitiesTranslate(
                "faith_based",
                "target_data_1",
                "Construction of community buildings"
              ),
              this.communitiesTranslate(
                "faith_based",
                "target_data_2",
                "Maintenance of important structures"
              ),
              this.communitiesTranslate(
                "faith_based",
                "target_data_3",
                "Faith-based educational activities"
              ),
              this.communitiesTranslate(
                "faith_based",
                "target_data_4",
                "Faith-based activities for young adults"
              ),
              this.communitiesTranslate(
                "faith_based",
                "target_data_5",
                "Special publications of books and brochures"
              ),
            ],
          },
          figures: {
            data: [
              {
                label: this.communitiesTranslate(
                  "faith_based",
                  "figure_data_label_1",
                  "Structures"
                ),
                amount: this.communitiesTranslate(
                  "faith_based",
                  "figure_data_amount_1",
                  "$1MM-$20MM"
                ),
              },
              {
                label: this.communitiesTranslate(
                  "faith_based",
                  "figure_data_label_2",
                  "Adult  Education"
                ),
                amount: this.communitiesTranslate(
                  "faith_based",
                  "figure_data_amount_2",
                  "$150K-$1MM"
                ),
              },
              {
                label: this.communitiesTranslate("faith_based", "figure_data_label_3", "Events"),
                amount: this.communitiesTranslate(
                  "faith_based",
                  "figure_data_amount_3",
                  "$25K-$150"
                ),
              },
            ],
          },
        },
        artsy: {
          to: `${this.$_communities_appHost}/community/artsy`,
          title: this.communitiesTranslate("artsy", "title", "artsy"),
          color: "#72768D",
          bannerImage: {
            he: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1697753933_3561333532306666376231313336366532366537613434343739383065633433_31363937373533393333.svg",
          },
          coverImage:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665177331_68b653c2df0891efd26d51cc9af184f6_1665177331.jpg",
          contentMain: {
            text: [
              {
                accent: true,
                data: this.communitiesTranslate(
                  "artsy",
                  "content_main_text_1",
                  "If you ask your donors to skip a meal to help a starving artist they will do it in a heartbeat, and if you ask them they’ll quickly explain the  profound impact an arts center can have on a neighborhood."
                ),
              },
              {
                data: this.communitiesTranslate(
                  "artsy",
                  "content_main_text_2",
                  "At a time when so many people suffer from a spiritual crisis, when young people have their basic needs met but are deprived of deeper emotional stimulation, art, craftsmanship, and creativity hold the key to fostering a resilient social fabric, to nurturing richer relationships, and to coming up with great ideas that can change the world. Charidy Artsy works with communities who consider the arts their number one priority in life."
                ),
              },
            ],
            mediaSrc:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665177348_8e8e07105e56145935f821a2697ebffb_1665177348.jpg",
          },
          contentMagic: {
            text: [
              {
                data: this.communitiesTranslate(
                  "artsy",
                  "content_magic_text_1",
                  "Because Charidy is an expert when it comes to engaging and mobilizing the human capital, Charidy Artsy can help fundraisers tap their community of patrons in new and exciting ways without taking away from the allure of being a benefactor of the arts. After all, art today is as important as it was during the times of the Medicis but we have the advantage of new technology, matching campaigns, and group psychology that work together to create an environment conducive to giving, where institutional art and independent craftsmanship are taking center stage."
                ),
              },
            ],
          },
          targets: {
            title: this.communitiesTranslate("artsy", "targets_title", "Raising Money For"),
            data: [
              this.communitiesTranslate("artsy", "target_data_1", "A budget for adult classes"),
              this.communitiesTranslate(
                "artsy",
                "target_data_2",
                "Educational frameworks or institutions"
              ),
              this.communitiesTranslate(
                "artsy",
                "target_data_3",
                "Renovate or build an arts gallery"
              ),
            ],
          },
          figures: {
            data: [
              {
                label: this.communitiesTranslate(
                  "artsy",
                  "figure_data_label_1",
                  "Capital improvements, museum"
                ),
                amount: this.communitiesTranslate("artsy", "figure_data_amount_1", "1MM-10MM"),
              },
              {
                label: this.communitiesTranslate(
                  "artsy",
                  "figure_data_label_2",
                  "Medium campaigns"
                ),
                amount: this.communitiesTranslate("artsy", "figure_data_amount_2", "$100K-$1MM"),
              },
              {
                label: this.communitiesTranslate("artsy", "figure_data_label_3", "Small"),
                amount: this.communitiesTranslate("artsy", "figure_data_amount_3", "$50-100K"),
              },
            ],
          },
        },
        "dream-it": {
          to: `${this.$_communities_appHost}/community/dream-it`,
          title: this.communitiesTranslate("dream_it", "title", "dream it"),
          color: "#8DA2A8",
          bannerImage: {
            he: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1697753960_3939313566323732373832656436346161373664346363346234623334323330_31363937373533393630.svg",
          },
          coverImage:
            "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665177569_15171351e99360585558032937ebee60_1665177569.jpg",
          contentMain: {
            text: [
              {
                accent: true,
                data: this.communitiesTranslate(
                  "dream_it",
                  "content_main_text_1",
                  "Maybe you’ve got a dream that’s just out of reach, or maybe a close friend has a personal wish that’s a few sizes too big on their current paycheck, or maybe you know someone that’s fallen on hard time because of Covid, because of recent changes at work, or at home, or just because."
                ),
              },
              {
                data: this.communitiesTranslate(
                  "dream_it",
                  "content_main_text_2",
                  "That’s why there’s a family, a circle of friends, a supportive community that will gladly lend a shoulder if you knew how to ask and had the right tools to help them give. That’s what Charidy Dream It community is all about."
                ),
              },
            ],
            mediaSrc:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1665177584_6a25ce1d98bc1fb8d02393bd4ae46281_1665177584.jpg",
          },
          contentMagic: {
            text: [
              {
                data: this.communitiesTranslate(
                  "dream_it",
                  "content_magic_text_1",
                  "Tech innovators, startup founders, and weekend tinkerers have plenty of options when it comes to raising money. We help you run a crowdfunding campaign with family and friends especially when circumstances are more sensitive, mentally or emotionally. When you’re not trying to start a business but simply need someone to help you articulating the ‘ask’, Charidy Dream It has done it a thousand times and helped thousands more find a giving place inside of them."
                ),
              },
            ],
          },
          targets: {
            title: this.communitiesTranslate("dream_it", "targets_title", "Raising Money For"),
            data: [
              this.communitiesTranslate("dream_it", "target_data_1", "A sudden loss in a family"),
              this.communitiesTranslate(
                "dream_it",
                "target_data_2",
                "Families who suffer from a sudden financial crisis"
              ),
              this.communitiesTranslate(
                "dream_it",
                "target_data_3",
                "Honoring the memory of a loved one"
              ),
              this.communitiesTranslate("dream_it", "target_data_4", "Fulfilling a dying wish"),
            ],
          },
          figures: {
            data: [
              {
                label: this.communitiesTranslate("dream_it", "figure_data_label_1", "Large Scale"),
                amount: this.communitiesTranslate("dream_it", "figure_data_amount_1", "$100-$250K"),
              },
              {
                label: this.communitiesTranslate(
                  "dream_it",
                  "figure_data_label_2",
                  "Medium campaigns"
                ),
                amount: this.communitiesTranslate("dream_it", "figure_data_amount_2", "$50-100K"),
              },
              {
                label: this.communitiesTranslate("dream_it", "figure_data_label_3", "Small"),
                amount: this.communitiesTranslate("dream_it", "figure_data_amount_3", "$10-50K"),
              },
            ],
          },
        },
      };
    },
  },
  methods: {
    communitiesFindByKey(key) {
      const found = this.communitiesData[key];
      if (!found) {
        throw new Error(`Cannot find category by this ${key} key`);
      }

      if (key === "dream-it" && this.$_communities_lang !== "he") {
        found.targets.data.push(
          this.communitiesTranslate("dream_it", "target_data_5", "Gifting a special bucket list")
        );
      }

      return { ...found, bannerImage: found.bannerImage?.[this.$_communities_lang] || "" };
    },
    communitiesTranslate(communityKey, key, defaultName) {
      const data = this.$t(`home_page_v2_page_${communityKey}.${key}`, defaultName);
      if (data.trim().length === 0) {
        return defaultName;
      }
      return data;
    },
  },
};
