<template>
  <div class="reminders-block-header">
    <div class="th-item th-item--20">{{ $t("leads.campaigns") }}</div>
    <div class="th-item th-item--15">{{ $t("leads.name") }}</div>
    <div class="th-item">{{ $t("leads.amount") }}</div>
    <div class="th-item th-item--15">{{ $t("leads.phone") }}</div>
    <div class="th-item th-item--25">{{ $t("leads.email") }}</div>
    <div class="th-item th-item--15">{{ $t("leads.date") }}</div>
  </div>
</template>

<script>
export default {
  name: "ReminderBlockTitle",
};
</script>

<style lang="scss" scoped>
.reminders-block-header {
  display: none;
  @media only screen and (min-width: 1024px) {
    display: flex;
  }
  .th-item {
    display: flex;
    align-items: center;
    width: 10%;
    min-width: 10%;
    padding: 0 5px;
    color: $fd;
    font-size: 14px;
    &--15 {
      width: 15%;
      min-width: 15%;
    }
    &--20 {
      width: 20%;
      min-width: 20%;
    }
    &--25 {
      width: 25%;
      min-width: 25%;
    }
    &--30 {
      width: 30%;
      min-width: 30%;
    }
  }
}
</style>
