<template>
  <b-card v-if="donorAccount" class="account-card">
    <h2 slot="header" class="account-card__title">
      {{ $t("account.donor_profile") }}
    </h2>
    <div slot="footer" class="account-card__footer text-right">
      <button class="bttn bttn--sm bttn--orange" form="edit-contact" :disabled="load" type="submit">
        {{ $t("account.save_change") }}
      </button>
    </div>

    <form id="edit-contact" class="card-text" @submit.prevent="onSubmit">
      <!-- <div class="col-12 form-unit switch-public">
        <label class="form-unit__label--upper-sm">
          {{ $t('account.switch_public_account_contacts') }}
        </label>
        <div class="switch-public--switch">
          <input
            type="checkbox"
            v-model="isPublicContacts"
            @change="changePublicContacts"
            id="switch_public_account_contacts"
          />
          <label for="switch_public_account_contacts"></label>
        </div>
      </div> -->

      <b-form-group
        :label="$t('account.first_name')"
        label-for="account-first-name"
        label-class="form-unit__label--upper-sm"
        class="account-card__inputs"
      >
        <b-form-input
          required
          v-model="accountDetails.data.attributes.first_name"
          id="account-first-name"
          type="text"
          name="firstName"
          class="form-control form-unit__input"
          placeholder="Enter your first name"
          autocomplete="nope"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :label="$t('account.last_name')"
        label-for="account-last-name"
        label-class="form-unit__label--upper-sm"
        class="account-card__inputs"
      >
        <b-form-input
          required
          v-model="accountDetails.data.attributes.last_name"
          id="account-last-name"
          type="text"
          name="lastName"
          class="form-control form-unit__input"
          placeholder="Enter your last name"
          autocomplete="nope"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :label="$t('account.contact_email')"
        label-for="account-email"
        label-class="form-unit__label--upper-sm"
        class="account-card__inputs"
      >
        <b-form-input
          required
          v-model="accountDetails.data.attributes.email"
          id="account-email"
          type="email"
          class="form-control form-unit__input"
          placeholder="Enter your email"
          autocomplete="nope"
        ></b-form-input>
      </b-form-group>
    </form>
  </b-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import { notificationsMixin } from "@/mixins";

export default {
  data() {
    return {
      load: false,
      isPublicContacts: null,
      accountDetails: {
        data: {
          attributes: {
            first_name: "",
            last_name: "",
            email: "",
          },
        },
      },
    };
  },
  mixins: [notificationsMixin],

  mounted() {
    // this.$store.dispatch("getDonorAccountInfo", false);

    this.updateAccountForm();
  },

  computed: {
    ...mapState({
      donorAccount: (state) => state.donorAccount.account.attributes,
      isLoading: (state) => state.donorAccount.loadingAccount,
    }),
    ...mapGetters(["getIncludedByType"]),
  },

  watch: {
    donorAccount() {
      this.updateAccountForm();
    },
  },

  methods: {
    onSubmit() {
      this.load = true;

      axios
        .patch(urls.donorAccount, this.accountDetails)
        .then((res) => {
          this.load = false;

          this.$store.commit("setDonorAccount", res.data);

          this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
        })
        .catch((e) => {
          this.load = false;

          // this.$parent.$refs.error.setErrorResponse(e.response);
          console.log(e);
        });
    },

    changePublicContacts() {
      const data = {
        data: {
          type: "customer_setting",
          attributes: {
            val_bool: this.isPublicContacts,
            type: "donor_contact_is_public",
          },
        },
      };

      axios.post(urls.donorAccountSettings, data).then(() => {
        this.$store.dispatch("getDonorAccountInfo", false);

        this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
      });
    },

    updateAccountForm() {
      const donorContactIsPublic = this.getIncludedByType("donor_contact_is_public");

      this.isPublicContacts = donorContactIsPublic
        ? donorContactIsPublic.attributes.val_bool
        : false;

      if (this.donorAccount) {
        const { first_name, last_name, email } = this.donorAccount;

        this.accountDetails.data.attributes = { first_name, last_name, email };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  border-color: #e6eaed;
}

.switch-public {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  &--switch {
    input {
      display: block;
      opacity: 0;
    }
    label {
      width: 60px;
      height: 30px;
      cursor: pointer;
      display: inline-block;
      position: relative;
      background: rgb(189, 189, 189);
      border-radius: 30px;
      transition: background-color 0.4s;
      -moz-transition: background-color 0.4s;
      -webkit-transition: background-color 0.4s;
    }
    label:after {
      left: 0;
      width: 20px;
      height: 20px;
      margin: 5px;
      content: "";
      position: absolute;
      background: #fff;
      border-radius: 10px;
    }
    input:checked + label {
      background: #3cc7a0;
    }
    input:checked + label:after {
      left: auto;
      right: 0;
    }
  }
}

@media (max-width: 768px) {
  .contact-form {
    margin-bottom: 25px;
  }
}
</style>
