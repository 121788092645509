<template lang="html">
  <div class="dg-view-wrapper">
    <div class="dg-content-body">
      <div class="dg-content">{{ options.message }}</div>
      <div class="checkbox-confirm">
        <input type="checkbox" v-model="checkboxVal" /><label>{{
          options.confirmDeleteText
        }}</label>
      </div>
    </div>

    <div class="dg-content-footer">
      <button type="button" class="dg-btn dg-btn--cancel" name="button" @click="hendleCancel">
        {{ options.cancelText }}
      </button>
      <button
        type="button"
        :disabled="!checkboxVal"
        class="dg-btn dg-btn--ok dg-pull-right"
        name="button"
        @click="hendleConfirm"
      >
        {{ options.okText }}
      </button>
    </div>
  </div>
</template>

<script>
import DialogMixin from "vuejs-dialog/dist/vuejs-dialog-mixin.min.js";

export default {
  props: ["options"],

  data: function () {
    return {
      checkboxVal: false,
    };
  },

  mixins: [DialogMixin],

  methods: {
    hendleConfirm() {
      this.proceed();
    },
    hendleCancel() {
      this.cancel();
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 10px 0;
  input {
    margin-right: 5px;
  }
  label {
    margin: 0;
  }
}
</style>
