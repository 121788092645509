<template>
  <b-table
    ref="ShareVideoTable"
    id="ShareVideoTable"
    head-variant="light"
    :busy="loading"
    :items="list"
    :fields="fields"
  >
    <template v-slot:table-busy>
      <div class="text-center my-2">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </template>

    <template v-slot:cell(video)="{ item }">
      <div style="max-width: 250px">
        <b-embed type="video" class="svw-unidy-embed" controls>
          <source :src="item.video" type="video/mp4" />
        </b-embed>
      </div>
    </template>

    <template v-slot:cell(name)="{ item }">
      <p>
        {{ item.name }}
      </p>
    </template>

    <template v-slot:cell(actions)="{ item }">
      <TableOptionsDropdown
        :options="[
          { text: $t('dashboard.utm_actions_edit'), action: () => openAddModal(item) },
          {
            text: $t('dashboard.utm_actions_delete'),
            action: () => remove(item),
            variant: 'danger',
          },
        ]"
      />
    </template>
  </b-table>
</template>

<script>
import TableOptionsDropdown from "@/common-components/ui-elements/TableOptionsDropdown.vue";

export default {
  components: {
    TableOptionsDropdown,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    remove: {
      type: Function,
      default: () => {},
    },
    openAddModal: {
      type: Function,
      default: () => {},
    },
    loading: Boolean,
  },
  data() {
    return {
      fields: [
        {
          key: "video",
          label: this.$t("dashboard.share_video_th_video", "Video"),
        },
        {
          key: "name",
          label: this.$t("dashboard.share_video_th_name", "Title"),
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
