<template>
  <div>
    <div>
      <CampaignLevels />
    </div>
    <div class="mt-4">
      <CampaignStreams />
    </div>
  </div>
</template>

<script>
import CampaignLevels from "@/views/campaigns/campaign-edit-vue/subcomponents/campaignLevels.vue";
import CampaignStreams from "@/views/campaigns/campaign-edit-vue/subcomponents/campaign-streams.vue";

export default {
  components: {
    CampaignLevels,
    CampaignStreams,
  },
};
</script>

<style lang="scss" scoped></style>
