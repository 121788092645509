export default {
  title: "UnidyMediaStripe",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    hide_on_team_page: {
      title: "Hide on team page",
      type: "boolean",
      default: false,
    },
    medias: {
      title: "Medias",
      type: "list",
      default: [],
      expanded: true,
      layout: "expanded",
      items: {
        type: "object",
        validation: "unidy_media_stripe",
        properties: {
          type: {
            title: "Type",
            type: "enum",
            default: "auto",
            items: {
              enum: [
                {
                  text: "Auto",
                  value: "auto",
                },
                {
                  text: "Image",
                  value: "image",
                },
                {
                  text: "Video",
                  value: "video",
                },
              ],
            },
          },
          src: {
            title: "Image/Video File",
            type: "image/video",
            inputType: "text/file",
            required: true,
          },
          style: {
            title: "Video Style",
            type: "style",
            properties: {
              width: {
                title: "Width",
                type: "number",
                default: 480,
                cssValue: "px",
              },
              height: {
                title: "Height",
                type: "number",
                default: 270,
                cssValue: "px",
              },
            },
          },
        },
      },
    },
  },
};
