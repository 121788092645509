<script>
export default {
  props: {
    fields: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <div>
    <b-row v-for="{ attributes } in fields" :key="attributes.key">
      <b-col cols="12">
        <b-form-group
          :id="`input-group-${attributes.key}`"
          :label="attributes.key"
          :label-for="attributes.key"
        >
          <b-form-input
            :id="attributes.key"
            :value="attributes.val_str"
            type="text"
            readonly
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
