<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { ValidateShortlink, LinkValidationConfigWizard } from "@/constants/providers";
import { shortlinkValidation } from "@/resources";

export default {
  provide() {
    return {
      [ValidateShortlink]: this.validate,
      [LinkValidationConfigWizard]: this.getLinkValidationConfigWizard,
    };
  },

  computed: {
    ...mapState({
      orgId: (state) => state.account.orgId || localStorage.getItem("orgId"),
    }),
    ...mapGetters({
      getCurrentlyEditedInWizard: "getCurrentlyEditedInWizard",
    }),
  },

  methods: {
    validate(shortLink, config) {
      return this.getValidationPromise(shortLink, config).then(
        ({
          data: {
            data: { attributes },
          },
        }) => [attributes.available, attributes.shortlink]
      );
    },
    getValidationPromise(shortLink, config) {
      if (config?.type === "campaign") {
        return shortlinkValidation.validateByCampaign({
          oid: config.orgId,
          cid: config.campaignId,
          shortLink,
        });
      }
      if (config?.type === "org") {
        return shortlinkValidation.validateByOrg({
          oid: config.orgId,
          shortLink,
        });
      }
      return Promise.reject();
    },
    getLinkValidationConfigWizard() {
      if (this.getCurrentlyEditedInWizard) {
        return {
          type: "campaign",
          orgId: this.orgId,
          campaignId: this.getCurrentlyEditedInWizard,
        };
      }

      return { type: "org", orgId: this.orgId };
    },
  },
};
</script>
