<template>
  <div class="level-plate">
    <div class="level-plate__header">
      <div class="level-plate__image">
        <b-img
          v-if="level.attributes.image == ''"
          v-bind="{ blank: true, blankColor: '#777', width: 75, height: 75, class: 'm1' }"
          rounded="circle"
          alt="Circle image"
        ></b-img>
        <img
          v-if="level.attributes.image"
          :src="level.attributes.image"
          :alt="level.attributes.name"
        />
      </div>

      <div class="level-plate__data">
        <div class="d-flex">
          <h2 class="level-plate__title col-8">{{ level.attributes.title }}</h2>
          <h2 class="level-plate__title text-right col-4">
            {{ currencySign }}{{ level.attributes.amount }}
          </h2>
        </div>
        <p>{{ level.attributes.subtitle }}</p>

        <div class="level-plate__row--data">
          <div class="level-plate__type">{{ $t("edit_campaign.donation_level_color") }}</div>
          <span class="level-plate__color" :style="{ background: level.attributes.color }"></span>
        </div>

        <div v-if="meta.hidden_on_campaign_page" class="level-plate__row--data text-warning">
          {{
            $t(
              "edit_campaign.donation_level_hidden_from_campaign_page",
              "The level hidden from the campaign page"
            )
          }}
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="level-plate__action mr-auto p-2">
        <button
          :disabled="!hasAccess || loading || !hasUpperBoundOrder(level)"
          class="bttn bttn-round"
          @click="changeLevelOrder('increase')"
        >
          <b-icon-arrow-up-short />
        </button>
        <div class="matcher-plate__type">{{ level.attributes.order }}</div>
        <button
          :disabled="
            !hasAccess || loading || level.attributes.order === 0 || !hasLowerBoundOrder(level)
          "
          class="bttn bttn-round"
          @click="changeLevelOrder('decrease')"
        >
          <b-icon-arrow-down-short />
        </button>
      </div>
      <div class="level-plate__action ml-auto p-2">
        <button :disabled="!hasAccess || loading" class="bttn bttn-round" @click="editLevel()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
        <button :disabled="!hasAccess || loading" class="bttn bttn-round" @click="deleteLevel()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { currenciesMap } from "@/constants/currencies";
import debounce from "lodash/debounce";
import { mapState } from "vuex";

export default {
  props: ["level", "loading"],

  computed: {
    ...mapState({
      campaignCurrencySign: (state) => state.campaign.campaign.data.attributes.currency_sign,
      hasAccess: (state) => state.account.access.CanEditCampaignDonationLevels,
      levels: (state) => {
        let included = state.campaign.campaign.included;

        let relationships = state.campaign.campaign.data.relationships.donation_level.data;

        let arrLevels = [];

        relationships.forEach((relationship) => {
          let level = included.find(
            (el) => el.id == relationship.id && el.type == relationship.type
          );

          arrLevels.push(level);
        });

        return arrLevels;
      },
    }),

    meta() {
      try {
        return JSON.parse(this.level.attributes.meta);
      } catch {
        return {};
      }
    },

    currencySign() {
      const levelCurrency = this.level.attributes?.currency || null;
      const levelCurrencyMap = levelCurrency && currenciesMap?.[levelCurrency];
      if (levelCurrencyMap) {
        return levelCurrencyMap.sign;
      }

      return this.campaignCurrencySign;
    },
  },

  methods: {
    editLevel() {
      this.$emit("edit-level", this.level);
    },
    deleteLevel() {
      this.$emit("delete-level", this.level);
    },
    changeLevelOrder: debounce(function (direction) {
      this.$emit("change-level-order", this.level, direction);
    }, 300),
    hasUpperBoundOrder(level) {
      // Determine the maximum order value in the list
      const maxOrder = Math.max(...this.levels.map((item) => item.attributes.order));

      // Check if the item's order is less than the maximum order
      return level.attributes.order < maxOrder;
    },
    hasLowerBoundOrder(level) {
      // Determine the minimum order value in the list
      const minOrder = Math.min(...this.levels.map((item) => item.attributes.order));

      // Check if the item's order is greater than the minimum order
      return level.attributes.order > minOrder;
    },
  },
};
</script>

<style lang="scss" scoped>
.level-plate {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #e6eaed;
  background-color: #ffffff;
  &__image {
    margin-bottom: 15px;
    width: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    @media only screen and (min-width: 568px) {
      margin-bottom: 0;
      margin-right: 20px;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  &__header {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (min-width: 568px) {
      flex-direction: row;
    }
  }
  &__data {
    flex-grow: 1;
  }
  &__title {
    margin: 0 0 10px;
    padding: 0;
    color: $fd;
    font-size: 24px;
    text-transform: uppercase;
    line-height: 1.125;
  }
  &__type {
    font-size: 14px;
    color: $fd;
    span {
      padding-left: 5px;
      color: $bb-100;
      font-family: $vito-light;
    }
  }
  &__row {
    display: flex;
    &--data {
      display: flex;
      align-items: center;
    }
  }
  &__description {
    display: none;
    min-height: 82px;
    margin-right: -20px;
    margin-left: -20px;
    padding: 20px;
    background-color: $bg-50;
    font-size: 14px;
    color: $fd;
  }
  &__action {
    padding: 10px 0 5px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
  }
  &__color {
    margin-left: 20px;
    width: 3rem;
    height: 20px;
  }
}
</style>
