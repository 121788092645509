import urls from "../../constants/urls.js";
import axios from "axios";

const state = {
  gateways: [],
  loadingGateways: true,
};

const mutations = {
  setGateways(state, data) {
    state.gateways = data;
  },

  setLoadingGateways(state, value) {
    state.loadingGateways = value;
  },
};

const actions = {
  getGateways({ commit }) {
    commit("setLoadingGateways", true);
    let orgId = localStorage.getItem("orgId");
    axios.get(urls.gateways.replace(":orgId", orgId)).then((response) => {
      let gateways = response.data.data;
      commit("setGateways", gateways);
      commit("setLoadingGateways", false);
    });
  },

  getGatewaysAndReturn() {
    let orgId = localStorage.getItem("orgId");
    return axios.get(urls.gateways.replace(":orgId", orgId)).then((response) => {
      return response.data.data;
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
