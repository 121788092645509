<template>
  <div class="rds-total-stats">
    <b-form-group
      :label="$t('dashboard.rds_total_amount_to_be_charged', 'Total amount to be charged')"
      label-class="text-uppercase font-weight-bold"
    >
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-skeleton type="text" width="96px" height="53px"></b-skeleton>
        </template>
        <div class="rds-total-stats__text">
          <span>{{ currencySign }}</span>
          <span>{{ stats.total_to_be_charged.toLocaleString() }}</span>
        </div>
      </b-skeleton-wrapper>
    </b-form-group>
    <b-form-group
      :label="$t('dashboard.rds_total_charged_as_of_today', 'Total charged as of today')"
      label-class="text-uppercase font-weight-bold"
    >
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-skeleton type="text" width="96px" height="53px"></b-skeleton>
        </template>
        <div class="rds-total-stats__text">
          <span>{{ currencySign }}</span>
          <span>{{ stats.total_charged.toLocaleString() }}</span>
        </div>
      </b-skeleton-wrapper>
    </b-form-group>
    <b-form-group
      :label="$t('dashboard.rds_total_recurring_funds_progress', 'Total recurring funds progress')"
      label-class="text-uppercase font-weight-bold"
    >
      <b-skeleton-wrapper :loading="loading">
        <template #loading>
          <b-skeleton type="text" width="96px" height="53px"></b-skeleton>
        </template>
        <div class="rds-total-stats__text">
          <span>{{ stats.total_progress.toLocaleString() }}</span>
          <span>%</span>
        </div>
      </b-skeleton-wrapper>
    </b-form-group>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      filters: (s) => s.rds.ui.filters,
      stats: (s) => s.rds.stats,
      campaigns: (s) => s.campaigns.campaigns,
      loading: (s) => s.rds.loading,
    }),
    campaign() {
      return this.campaigns.find((v) => v.id === this.filters.cid);
    },
    currencySign() {
      return this.campaign?.attributes.currency_sign;
    },
  },
};
</script>

<style lang="scss" scoped>
.rds-total-stats {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    text-align: center;
    ::v-deep .form-group {
      width: 100%;
    }
  }
  justify-content: space-between;
  border-top: var(--border-darker);
  border-bottom: var(--border-darker);
  padding: 20px 0px;
  &__text {
    direction: ltr;
    display: inline-flex;
    gap: 5px;
    color: #107598;
    font-size: 32px;
  }
  ::v-deep .form-group {
    margin-bottom: 0px;
  }
}
</style>
