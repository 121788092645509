<template>
  <div class="fts-page__outer-block">
    <category-title :title="$t('dashboard.title_fund_transfer_status', 'Fund transfer status')">
    </category-title>
    <div class="dashboard-block">
      <div class="fts-page">
        <select-campaign
          id="select-campaign"
          default-styles
          @input="selectedCampaignId = $event"
          :value="cidInDashboard"
        />
      </div>
    </div>
    <div class="fts-page__table-block">
      <fts-table />
    </div>
  </div>
</template>

<script>
import ftsTable from "./fts-table.vue";
import categoryTitle from "@/common-components/ui-elements/category-title.vue";
import { mapActions, mapState } from "vuex";
import { notificationsMixin } from "@/mixins";
import selectCampaign from "@/views/dashboard/subcomponents/selectCampaign.vue";

export default {
  mixins: [notificationsMixin],
  components: {
    ftsTable,
    categoryTitle,
    selectCampaign,
  },
  data() {
    return {
      selectedCampaignId: null,
    };
  },
  mounted() {
    this.$store.dispatch("getCampaigns");
    if (this.cid) {
      this.fetchFundTransferStatus([this.cid]).catch(this.$_notificationsMixin_handleCatch);
    }
  },
  watch: {
    selectedCampaignId: function () {
      this.$store.commit("setSelectedCampaign", this.selectedCampaignId);
      this.fetchFundTransferStatus([this.cid]).catch(this.$_notificationsMixin_handleCatch);
    },
  },
  computed: {
    ...mapState({
      cidInDashboard: (state) => state.dashboard.selectedCampaign,
    }),
    cid() {
      return this.selectedCampaignId || this.cidInDashboard;
    },
  },
  methods: {
    ...mapActions({
      fetchFundTransferStatus: "fundTransferStatus/fetchFundTransferStatus",
    }),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dashboard-block {
  padding-bottom: 0px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
}
.fts-page {
  display: grid;
  grid-auto-flow: row;
  gap: 20px;
  border-bottom: 1px solid #e6eaed;
  &__block {
    display: grid;
    gap: 60px;
    grid-template-columns: 0.7fr 1fr;
  }
  &__outer-block {
    display: grid;
    grid-auto-flow: row;
  }
  &__table-block {
    background: #fff;
  }
  &:last-child {
    padding-bottom: 20px;
  }
}
</style>
