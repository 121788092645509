<template>
  <div v-bind="{}">
    <ValidationObserver v-slot="{ validate, errors: observerErrors }">
      <form :id="submitByFormId" @submit.prevent="handleSubmit($event, validate)">
        <ErrorsView
          v-if="$options.filterErrors(errors, observerErrors)"
          :errors="$options.filterErrors(errors, observerErrors)"
        />
        <CampaignGeneralFields
          :campaignTitle="state.campaignTitle"
          :campaignCurrency="state.campaignCurrency"
          :campaignPrimaryGoal="state.campaignPrimaryGoal"
          :campaignPrimaryGoalMultiplier="state.campaignPrimaryGoalMultiplier"
          :campaignStartDate="state.campaignStartDate"
          :campaignStartDateString="state.campaignStartDateString"
          :campaignEndDate="state.campaignEndDate"
          :campaignEndDateString="state.campaignEndDateString"
          :campaignShortLink="state.campaignShortLink"
          @update:campaignTitle="(...args) => onUpdateCampaignTitle(...args)"
          @update:campaignCurrency="(...args) => setStateWithError('campaignCurrency', ...args)"
          @update:campaignPrimaryGoal="
            (...args) => setStateWithError('campaignPrimaryGoal', ...args)
          "
          @update:campaignPrimaryGoalMultiplier="
            (...args) => setStateWithError('campaignPrimaryGoalMultiplier', ...args)
          "
          @update:campaignStartDate="(...args) => setStateWithError('campaignStartDate', ...args)"
          @update:campaignStartDateString="
            (...args) => setStateWithError('campaignStartDateString', ...args)
          "
          @update:campaignEndDate="(...args) => setStateWithError('campaignEndDate', ...args)"
          @update:campaignEndDateString="
            (...args) => setStateWithError('campaignEndDateString', ...args)
          "
          @update:campaignShortLink="(...args) => setStateWithError('campaignShortLink', ...args)"
          @campaignShortLinkValidationResult="onCampaignShortLinkValidationResult($event, validate)"
          @campaignShortLinkValidation="$emit('loading', $event)"
          :linkValidation="LinkValidationConfigWizard()"
          :containerClass="null"
          shortLinkClass="col-12"
        />
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import ErrorsView from "@/components/errors.vue";
import { filterErrors } from "@/helpers/filters";
import { LinkValidationConfigWizard } from "@/constants/providers";
import CampaignGeneralFields from "@/views/campaigns/campaign-edit-vue/subcomponents/campaignGeneralFields.vue";

export default {
  filterErrors,

  props: {
    state: { type: Object, default: () => ({}) },
    setState: { type: Function, default: () => {} },
    submitByFormId: { type: String, default: "" },
  },
  data() {
    return {
      errors: [],
    };
  },
  inject: { LinkValidationConfigWizard },
  components: {
    CampaignGeneralFields,
    ValidationObserver,
    ErrorsView,
  },
  computed: {
    ...mapGetters({
      getCurrentlyEditedInWizard: "getCurrentlyEditedInWizard",
    }),
    orgId: function () {
      return localStorage.getItem("orgId");
    },
  },
  mounted() {
    // to prevent early submit
    this.$emit("loading", true);
  },
  methods: {
    onCampaignShortLinkValidationResult(v, validate) {
      return validate()
        .then(() => this.$emit("error", !v))
        .finally(() => this.$emit("loading", false));
    },
    handleSubmit(event, validate) {
      const { submitter } = event;
      const context = {
        continue: submitter.dataset.context === "continue",
        saveDraft: submitter.dataset.context === "saveDraft",
      };

      return validate()
        .then(() => this.$emit("can-continue", context))
        .finally(() => this.$emit("loading", false));
    },
    setStateWithError(state, ...args) {
      const [v, failedRules] = args;
      const valid = Object.entries(failedRules || {}).length === 0;

      this.setState(state, v);
      this.$emit("error", !valid);
    },
    onUpdateCampaignTitle(...args) {
      const [v] = args;
      const campaignDescriptions = this.state.campaignDescription;
      const campaignDescription = campaignDescriptions[0];
      if (campaignDescription) {
        const newDescription = {
          ...campaignDescription,
          attributes: { ...campaignDescription.attributes, title: v },
        };
        campaignDescriptions[0] = newDescription;
        this.setState("campaignDescription", campaignDescriptions);
      }
      this.setStateWithError("campaignTitle", ...args);
    },
  },
};
</script>

<style lang="scss"></style>
