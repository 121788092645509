<template>
  <div>
    <teamManagement
      ref="teamManagement"
      v-if="hasAccessSee"
      @edit-team="editTeam"
      @add-team="addTeam"
      @import-teams="openModalImportTeams"
      @export-teams="openModalExportTeams"
      @send-email="sendEmail"
      @send-sms="sendSMS"
      @children-teams="getChildrenTeams"
      @open-donation-modal="openDonationModal"
      @open-charge-linked-cc-modal="openChargeLinkedCcModal"
    ></teamManagement>

    <team-form
      ref="teamform"
      :dashboard="true"
      :shortlink="selectedCampaign.attributes.short_link"
      :ccy="selectedCampaign.attributes.currency"
    />

    <import-teams
      ref="ImportTeamsModal"
      :campaignId="campaignId"
      @send-email="sendEmail"
      @send-sms="sendSMS"
      @update-team-list="updateList"
    />
    <email-message v-show="false" ref="emailMessage" />
    <sms-message v-show="false" ref="smsMessage" />

    <export-teams ref="ExportTeamsModal" />

    <childrenTeams
      ref="childrenTeams"
      @edit-children-team="editTeam"
      @send-email="sendEmail"
      @send-sms="sendSMS"
      @open-donation-modal="openDonationModal"
    ></childrenTeams>

    <TeamsDonationModal ref="TeamsDonationModal" />

    <TeamChargeLinkedCCModal ref="TeamChargeLinkedCcModal" />

    <section class="teams-page" v-if="!hasAccessSee">
      <div class="empty-result-message">
        {{ $t("teams.teams_access_error") }}
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import teamManagement from "./teamManagement.vue";
import teamForm from "@/views/campaigns/campaign-edit-vue/subcomponents/team-form.vue";
import importTeams from "@/views/campaigns/campaign-edit-vue/subcomponents/campaign-import-teams.vue";
import exportTeams from "@/views/dashboard/subcomponents/export-teams-modal.vue";
import SmsMessage from "../messaging/smsMessage.vue";
import EmailMessage from "../messaging/emailMessage.vue";
import childrenTeams from "./childrenTeams.vue";
import TeamsDonationModal from "./teamsDonation.vue";
import TeamChargeLinkedCCModal from "./teamChargeLinkedCCModal.vue";

export default {
  components: {
    teamManagement,
    teamForm,
    importTeams,
    SmsMessage,
    EmailMessage,
    exportTeams,
    childrenTeams,
    TeamsDonationModal,
    TeamChargeLinkedCCModal,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      campaignId: (state) => state.dashboard.selectedCampaign,
      selectedCampaign(state) {
        return state.campaigns.campaigns.find((cmp) => cmp.id === this.campaignId);
      },
      hasAccessSee: (state) => {
        return state.account.access.CanSeeCampaignTeams;
      },
    }),
  },
  methods: {
    addTeam() {
      this.$refs.teamform.openModal();
    },
    editTeam(team) {
      this.$refs.teamform.openEditModal(team);
    },
    openModalImportTeams() {
      this.$refs.ImportTeamsModal.openModal();
    },
    openModalExportTeams() {
      const params = {
        parent_team_id: this.parent_team_id,
      };
      this.$refs.ExportTeamsModal.openModal(this.campaignId, { params });
    },
    sendEmail({ team, importGroup } = {}) {
      this.$refs.emailMessage.open({ team, importGroup });
    },
    sendSMS({ team, importGroup } = {}) {
      this.$refs.smsMessage.open({ team, importGroup });
    },
    getChildrenTeams(item) {
      this.$refs.childrenTeams.openModal(item);
    },
    updateList() {
      this.$refs.teamManagement.getTeams();
    },
    openDonationModal(id) {
      this.$refs.TeamsDonationModal.show(id);
    },
    openChargeLinkedCcModal({ team }) {
      this.$refs.TeamChargeLinkedCcModal.show(team);
    },
  },
};
</script>

<style lang="scss" scoped></style>
