<script>
export default {
  data() {
    return {
      currentSelectedInput: Object.entries(this.field.properties)[0],
    };
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    field: {
      type: Object,
      default: () => null,
    },
    fieldKey: {
      type: String,
      default: "",
    },
  },
  computed: {
    valueModel: {
      get() {
        return this.value;
      },
      set(data) {
        this.$set(this, "value", { ...this.value, ...data });
      },
    },
    currentSelectedInputKey() {
      return this.currentSelectedInput[0];
    },
    currentSelectedInputValue() {
      return this.currentSelectedInput[1];
    },
    inputValue() {
      return this.value[this.currentSelectedInputKey];
    },
    formatter() {
      return {
        lazy: this.currentSelectedInputValue?.formatter?.lazy || false,
        handler:
          this.currentSelectedInputValue?.formatter?.handler ||
          this.currentSelectedInputValue?.formatter ||
          (() => {}),
      };
    },
    formTitle() {
      if (this.field?.title) {
        return this.field.title;
      }

      return this.currentSelectedInputValue.title;
    },
  },
  methods: {
    updateValue(key, value) {
      if (this.field.properties[key].type === "number") {
        value = Number(value);
        if (!Number.isNaN(value)) {
          this.$set(this.value, key, value);
        } else {
          this.$set(this.value, key, 0);
        }
      } else {
        this.$set(this.value, key, value);
      }
      this.$emit("input", this.value);
    },
    onDropdownClick(prop) {
      this.currentSelectedInput = prop;
    },
    typeAttributes(type) {
      if (type === "number") {
        return "text";
      }

      return type || "text";
    },
    numberAttributes() {
      if (this.currentSelectedInputValue.type === "number") {
        return {
          inputmode: "numeric",
          pattern: Object.hasOwn(this.currentSelectedInputValue, "pattern")
            ? this.currentSelectedInputValue.pattern
            : "[0-9]*",
        };
      }

      return {};
    },
  },
};
</script>

<template>
  <div class="w-100 mb-4">
    <b-form-group :label="formTitle">
      <b-input-group>
        <b-form-input
          :value="inputValue"
          @update="updateValue(currentSelectedInputKey, $event)"
          :type="typeAttributes(currentSelectedInputValue.type)"
          :lazy-formatter="formatter.lazy"
          :formatter="formatter.handler"
          v-bind="numberAttributes()"
        />

        <template #append>
          <b-dropdown
            toggle-class="h-100"
            :text="currentSelectedInputValue.title"
            variant="primary"
          >
            <b-dropdown-item
              @click="onDropdownClick([k, v])"
              v-for="[k, v] of Object.entries(field.properties)"
              :key="k"
            >
              {{ v.title }}
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-input-group>
    </b-form-group>
  </div>
</template>
