<template>
  <div>
    <campaignDescription enableRootEvents />
  </div>
</template>

<script>
import campaignDescription from "@/views/campaigns/campaign-edit-vue/subcomponents/campaignDescription.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    campaignDescription,
  },
  props: {
    state: { type: Object, default: () => ({}) },
    setState: { type: Function, default: () => {} },
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {},
  activated() {
    this.$root.$emit("charidy::campaign-description::update-title", this.state.campaignTitle);
  },
  deactivated() {
    this.$root.$emit("charidy::campaign-description::get-title", (newTitle) => {
      this.setState("campaignTitle", newTitle);
    });
  },
};
</script>
