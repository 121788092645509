<template>
  <b-container fluid>
    <b-form-row>
      <b-col cols="12">
        <div class="d-flex align-items-center">
          <div class="w-100">
            <b-form-select v-model="login" :options="loginsOptions"></b-form-select>
          </div>
          <div class="mx-1">
            <b-button
              type="button"
              class="bttn bttn--sm bttn--orange"
              :disabled="login === null"
              @click="addNewByLogin"
            >
              {{ $t("teams.show-sao-add", "Add") }}
            </b-button>
          </div>
          <span class="mx-3">OR</span>
          <div class="mx-1">
            <button
              @click="showAddNew = !showAddNew"
              type="button"
              class="bttn bttn--sm bttn--orange text-nowrap"
            >
              {{ $t("teams.show-sao-add-new", "Create new") }}
            </button>
          </div>
        </div>
      </b-col>
      <b-col class="py-2" cols="12">
        <b-collapse v-model="showAddNew" id="sao-collapse-add-new">
          <b-card>
            <form id="SaoAddNewForm" @submit.prevent="addNew">
              <b-form-group
                id="EmailGroup"
                :label="$t('teams.sub_account_email', 'Email')"
                label-for="EmailInput"
              >
                <b-form-input
                  id="EmailInput"
                  @input="checkEmailInput"
                  v-model="newSao.email"
                  type="email"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-row>
                <b-col cols="6">
                  <b-form-group
                    id="FirstNameGroup"
                    :label="$t('teams.sub_account_first_name', 'FirstName')"
                    label-for="FirstNameInput"
                  >
                    <b-form-input id="FirstNameInput" v-model="newSao.firstname"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    id="LastNameGroup"
                    :label="$t('teams.sub_account_last_name', 'Last Name')"
                    label-for="LastNameInput"
                  >
                    <b-form-input id="LastNameInput" v-model="newSao.lastname"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row class="py-3 align-items-center">
                <b-col cols="6" v-for="(v, k) of newSaoPermissions" :key="`sao-meta-add-new-${k}`">
                  <b-form-checkbox v-model="newSaoPermissions[k]">{{
                    $t(`teams.${k}`)
                  }}</b-form-checkbox>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col cols="12" class="text-right">
                  <button
                    form="SaoAddNewForm"
                    type="submit"
                    class="ml-2 bttn bttn--sm bttn--orange"
                  >
                    {{ $t("teams.sao-add-new", "Add new") }}
                  </button>
                </b-col>
              </b-form-row>
            </form>
          </b-card>
        </b-collapse>
      </b-col>
    </b-form-row>
    <b-form-row class="py-3" v-for="(sao, i) in subAccountsLocal" :key="sao.id">
      <b-col cols="12">
        <b-form-row class>
          <b-col cols="9" class="justify-content-start">
            <p>
              <span v-if="sao.firstname || sao.lastname">
                {{ `${sao.firstname} ${sao.lastname} <${sao.email}>` }}
              </span>
              <span v-else>
                {{ sao.email }}
              </span>
              <b-badge
                :id="`sao-hover-tooltip-badge-${sao.id}`"
                class="pl-1"
                variant="success"
                v-show="!sao.id"
                >New</b-badge
              >
            </p>
            <b-tooltip :target="`sao-hover-tooltip-badge-${sao.id}`" triggers="hover"
              >This item will be added only on save!</b-tooltip
            >
          </b-col>
          <b-col>
            <div class="d-flex align-items-center justify-content-end">
              <b-checkbox
                class="sao__switch"
                v-show="sao.id"
                switch
                v-model="sao.active"
              ></b-checkbox>
              <button
                v-if="sao.id"
                type="button"
                class="ml-2 sao__link-circle"
                aria-label="Edit sub-account"
                @click="setActivateEditMode(sao.id)"
              >
                <b-icon-pencil />
              </button>
              <button
                v-else
                type="button"
                class="ml-2 sao__link-circle sao__link-circle--danger"
                aria-label="Delete sub-account"
                @click="deleteNew(i)"
              >
                <b-icon-trash />
              </button>
            </div>
          </b-col>
        </b-form-row>
      </b-col>
      <b-col class="pt-2" cols="12">
        <b-collapse :id="`sao-collapse-${sao.id}`" v-model="activateEditMode[sao.id]">
          <b-card>
            <b-container>
              <b-form-row v-for="(v, k) of sao.permissions" :key="`sao-meta-${k}`">
                <b-col cols="12">
                  <b-form-checkbox v-model="sao.permissions[k]">{{
                    $t(`teams.${k}`)
                  }}</b-form-checkbox>
                </b-col>
              </b-form-row>
            </b-container>
          </b-card>
        </b-collapse>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { notificationsMixin } from "@/mixins";
import { cloneDeep } from "@/helpers/cloneDeep.js";

export default {
  mixins: [notificationsMixin],
  props: {
    teamId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      showAddNew: false,
      subAccountsLocal: [],
      activateEditMode: {},
      newSao: {
        email: "",
        firstname: "",
        lastname: "",
      },
      newSaoPermissions: {},
      login: null,
      logins: [],
    };
  },
  mounted() {
    if (this.teamId !== 0) {
      this.invalidateState();
    }
  },
  computed: {
    ...mapState({
      cidInDashboard: (state) => state.dashboard.selectedCampaign,
      subAccounts: (state) => state.subAccounts.collection,
      loadingGetTeamSubAccounts: (state) => state.subAccounts.loading.getTeamSubAccounts,
      loadingCreateTeamSubAccount: (state) => state.subAccounts.loading.createTeamSubAccount,
      loadingEditTeamSubAccount: (state) => state.subAccounts.loading.editTeamSubAccount,
    }),
    oid() {
      return localStorage.getItem("orgId");
    },
    cid() {
      return this.$route.params.id ? this.$route.params.id : this.cidInDashboard;
    },
    usedUserIdOREmail() {
      return this.subAccountsLocal.reduce(
        (res, acc) => {
          if (acc.user_id) {
            res.uidList.push(acc.user_id);
          }
          if (acc.email) {
            res.emailList.push(acc.email);
          }
          return res;
        },
        { uidList: [], emailList: [] }
      );
    },
    loginsOptions() {
      const list = this.logins.filter(
        (l) =>
          !(
            this.usedUserIdOREmail.uidList.includes(l.user_id) ||
            this.usedUserIdOREmail.emailList.includes(l.email)
          )
      );
      return [
        {
          value: null,
          text: this.$t("teams.subaccount_select_existing_user", "Please select existing user"),
        },
        ...list.map((l) => ({
          value: l,
          text: `${l.firstname} ${l.lastname} <${l.email}>`,
        })),
      ];
    },
  },
  methods: {
    ...mapActions({
      getLoginsToTeamsUsers: "subAccounts/getLoginsToTeamsUsers",
      getTeamSubAccounts: "subAccounts/getTeamSubAccounts",
      createTeamSubAccount: "subAccounts/createTeamSubAccount",
      editTeamSubAccount: "subAccounts/editTeamSubAccount",
    }),
    mapActivateEditMode(data) {
      for (const o of data) {
        !this.activateEditMode[o.id] && this.$set(this.activateEditMode, o.id, false);
      }
    },
    setActivateEditMode(id) {
      this.$set(this.activateEditMode, id, !this.activateEditMode[id]);
    },
    checkEmailInput(data) {
      const form = document.getElementById("SaoAddNewForm");
      const emailInput = document.getElementById("EmailInput");

      const v = [ValidationRegex.email.test(data), ValidationRegex.email.test(data)].every(
        (v) => v
      );

      if (!v) {
        emailInput.setCustomValidity(`${data} is not a valid email address`);
      } else {
        emailInput.setCustomValidity("");
      }

      form.reportValidity();
    },
    addNew() {
      this.showAddNew = false;
      this.subAccountsLocal.push({
        email: this.newSao.email,
        firstname: this.newSao.firstname,
        lastname: this.newSao.lastname,
        permissions: cloneDeep(this.newSaoPermissions),
      });
      this.newSao = {
        email: "",
        firstname: "",
        lastname: "",
      };
      Object.keys(this.newSaoPermissions).forEach((k) => {
        this.newSaoPermissions[k] = false;
      });
    },
    addNewByLogin() {
      this.subAccountsLocal.push({
        ...this.login,
        permissions: cloneDeep(this.newSaoPermissions),
      });
      Object.keys(this.newSaoPermissions).forEach((k) => {
        this.newSaoPermissions[k] = false;
      });
      this.login = null;
    },
    deleteNew(index) {
      this.subAccountsLocal = this.subAccountsLocal.filter((v, i) => i !== index);
    },
    async checkState(errors) {
      const gotError = errors.some((c) => c?.status === "400");
      if (!gotError) {
        await this.invalidateState();
      }
      return errors;
    },
    async $createAndEdit() {
      const created = await this.create();
      const edited = await this.edit();
      const editedAndCreated = [...edited, ...created];
      return this.checkState(editedAndCreated);
    },
    create() {
      const readyToSend = [];
      this.subAccountsLocal.forEach((v) => {
        if (!v.id) {
          const data = {
            email: v.email,
            firstname: v.firstname,
            lastname: v.lastname,
            user_id: v.user_id,
            permissions: cloneDeep(v.permissions),
          };
          readyToSend.push(
            this.createTeamSubAccount({
              oid: this.oid,
              cid: this.cid,
              id: this.teamId,
              data,
            })
          );
        }
      });
      return Promise.all(readyToSend).catch(this.$_notificationsMixin_handleCatch);
    },
    edit() {
      const readyToSend = [];
      this.subAccountsLocal.forEach((v) => {
        if (v.id) {
          const data = {
            active: v.active,
            permissions: cloneDeep(v.permissions),
          };
          readyToSend.push(
            this.editTeamSubAccount({
              oid: this.oid,
              cid: this.cid,
              id: v.id,
              data,
            })
          );
        }
      });
      return Promise.all(readyToSend).catch(this.$_notificationsMixin_handleCatch);
    },
    invalidateState() {
      this.getLoginsToTeamsUsers({
        oid: this.oid,
        cid: this.cid,
      }).then(({ data }) => {
        this.logins = data;
      });
      return this.getTeamSubAccounts({
        oid: this.oid,
        cid: this.cid,
        id: this.teamId,
      })
        .then((data) => {
          this.subAccountsLocal = data;
          this.subAccountsLocal.forEach((v, i) => {
            this.$set(this.subAccountsLocal, i, cloneDeep({ ...v, permissions: v.permissions }));
          });
          Object.keys(data[0].permissions).forEach((k) => {
            this.newSaoPermissions[k] = false;
          });
          this.mapActivateEditMode(data);
          return data;
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
  },
};
</script>

<style lang="scss" scoped>
.sao {
  &__link-circle {
    display: flex;
    width: 30px;
    height: 30px;
    background-color: $bb-100;
    border-radius: 50%;
    border: 2px solid $bb-100;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: ease-in 0.3s, ease-out 0.3s;
    svg {
      display: block;
      color: white;
    }
    &:focus {
      outline: none;
    }
    &--danger {
      border: 2px solid var(--danger);
      background-color: var(--danger);
    }
  }
  &__switch {
    ::v-deep .custom-control-label {
      cursor: pointer;
    }
  }
}

.text-nowrap {
  white-space: nowrap;
}
</style>
