<template>
  <div class="row">
    <div class="col-12" v-if="valueItems.length > 0">
      <b-table small :items="valueItems" :fields="fields()">
        <template #cell(actions)="{ index }" v-if="!fieldsIncludesPlaceholder">
          <b-row>
            <b-col style="align-self: center">
              <b-icon-trash-fill
                variant="danger"
                role="button"
                font-scale="1.2"
                @click="remove(index)"
              />
            </b-col>
          </b-row>
        </template>
      </b-table>
    </div>
    <div class="col-12">
      <slot name="form" :value="item" :submit="submit"></slot>
      <b-col>
        <b-button variant="outline-dark" block :form="form" type="submit" :disabled="loading">
          <b-spinner v-if="loading" small></b-spinner>
          Add
        </b-button>
      </b-col>
    </div>
  </div>
</template>

<script>
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],
  components: {},

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    scheme: {
      type: Object,
      default: () => null,
    },
    form: {
      type: String,
      default: "",
    },
    schemeName: {
      type: String,
      default: "",
    },
    layout: {
      type: String,
      default: "column",
    },
  },

  data() {
    return {
      item: {},
      valueItems: [],
      loading: false,
    };
  },

  watch: {
    value: {
      handler() {
        switch (this.schemeName) {
          case "show_billing_address":
            this.valueItems = Object.entries(this.value).map(([key, value]) => ({
              gateway: key,
              address_request: value.address_request,
              address_mandatory: value.address_mandatory,
            }));
            break;
          case "maximum_donation_amount":
            this.valueItems = Object.entries(this.value).map(([key, value]) => ({
              currency: key,
              amount: value,
            }));
            break;
        }
      },
      immediate: true,
      deep: true,
    },
  },

  computed: {
    fieldsIncludesPlaceholder() {
      return this.fields().includes("placeholder");
    },
  },

  methods: {
    async add() {
      this.onInput(this.item);
      return Promise.resolve();
    },

    onInput(item) {
      if (this.schemeName === "show_billing_address") {
        this.$emit("input", { ...structuredClone(this.value), ...this.item.gateway });
      } else {
        const newValue = {
          ...structuredClone(this.value),
          ...Object.fromEntries([Object.values(item)]),
        };

        this.$emit("input", newValue);
      }

      this.item = {};
      this.loading = false;
    },

    submit() {
      this.loading = true;
      this.add()
        .catch((e) => {
          this.$_notificationsMixin_makeErrorToast("Error", e?.message || "Something went wrong");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    remove(i) {
      const newList = Object.entries(this.value).filter((item, index) => index !== i);
      this.$emit("input", Object.fromEntries(newList));
    },

    fields() {
      const keys = Object.keys(this.valueItems[0] || {});
      return [
        ...keys,
        ...((keys.length === 0 && ["placeholder"]) || []),
        { key: "actions", tdClass: "align-middle text-center" },
      ];
    },
  },
};
</script>
