<template>
  <div class="row row-align-stretch">
    <div class="col-12 col-lg-6">
      <password-change />
    </div>
    <div class="col-12 col-lg-6">
      <profile-edit-contact-form />
    </div>
    <div class="col-12 col-lg-6">
      <TwoFa />
    </div>
  </div>
</template>

<script>
import TwoFa from "./subcomponents/2fa.vue";
import passwordChange from "./subcomponents/passwordChange.vue";
import profileEditContactForm from "./subcomponents/profile-edit-contact-form.vue";

export default {
  components: {
    passwordChange,
    profileEditContactForm,
    TwoFa,
  },
};
</script>

<style lang="scss">
.row-align-stretch {
  align-items: stretch;
}
</style>
