<template>
  <div>
    <b-row class="table-filters">
      <b-col cols="12" md="4">
        <b-input-group class="border-bottom">
          <template #prepend>
            <div class="align-self-center pr-1">
              <b-icon-search />
            </div>
          </template>
          <b-form-input
            v-model="query"
            debounce="250"
            type="search"
            class="border-0 shadow-none"
            :placeholder="$t('dashboard.rds_search_palceholder', 'Search by name / email')"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-table :items="records" :fields="fields" :busy="loading" stacked="lg" fixed>
      <template #head(charge_id)>
        <span>{{ $t("dashboard.rds_charge_id", "Charge ID") }}</span>
      </template>
      <template #head(created_at)>
        <span>{{ $t("dashboard.rds_date", "Date") }}</span>
      </template>
      <template #head(donor_name)>
        <span>{{ $t("dashboard.rds_donor_name", "Donor Name") }}</span>
      </template>
      <template #head(donor_email)>
        <span>{{ $t("dashboard.rds_donor_email", "Donor Email") }}</span>
      </template>
      <template #head(amount)>
        <span>{{ $t("dashboard.rds_amount", "Amount") }}</span>
      </template>
      <template #head(status)>
        <span>{{ $t("dashboard.rds_status", "Status") }}</span>
      </template>

      <template #cell(created_at)="{ value }">
        {{ formatDate(value) }}
      </template>
      <template #cell(amount)="{ item, value }">
        {{ `${value.toLocaleString()} ${item.currency}` }}
      </template>
      <template #cell(status)="{ value }">
        {{ $_rdsStatusMixin_getTranslationStatus(value) }}
      </template>
    </b-table>
    <b-pagination
      v-if="total > perPage"
      v-model="page"
      class="m-0"
      :total-rows="total"
      :per-page="perPage"
      aria-controls="TranslationList"
    ></b-pagination>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { rdsStatusMixin } from "@/mixins";

export default {
  mixins: [rdsStatusMixin],
  data() {
    return {
      page: 1,
      perPage: 20,
      fields: ["charge_id", "donor_name", "donor_email", "amount", "created_at", "status"],
    };
  },

  computed: {
    ...mapState({
      filters: (s) => s.rds.ui.filters,
      records: (s) => s.rds.records,
      loading: (s) => s.rds.loading,
      total: (s) => s.rds.total,
    }),
    ...mapGetters({
      getFiltersIfPeriodPresent: "rds/ui/getFiltersIfPeriodPresent",
    }),

    oid: () => localStorage.getItem("orgId"),

    query: {
      get() {
        return this.filters.q;
      },
      set(val) {
        this.setFilter({ key: "q", value: val });
      },
    },
  },

  watch: {
    getFiltersIfPeriodPresent: {
      handler(val) {
        if (val) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    page: {
      handler(page) {
        this.getData(page, this.perPage);
      },
    },
  },

  methods: {
    ...mapActions({
      fetchData: "rds/fetchData",
      setFilter: "rds/ui/setFilter",
    }),

    getData(page = 1, perPage = 20) {
      this.fetchData({
        oid: this.oid,
        cid: this.getFiltersIfPeriodPresent.cid,
        year: this.getFiltersIfPeriodPresent.year,
        month: this.getFiltersIfPeriodPresent.month,
        page,
        perPage,
        status: this.filters.status,
        q: this.filters.q,
      });
    },

    formatDate(val) {
      return new Date(val * 1000).toISOString().split("T")[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.table-filters {
  padding: 15px 35px;
}
::v-deep .table {
  margin-bottom: 0px;
}
::v-deep .table thead,
::v-deep .table thead tr,
::v-deep .table thead th,
::v-deep .table tbody[role="rowgroup"] td {
  padding: 20px 40px;
}
::v-deep .table thead th {
  border-top: none;
  border-bottom: none;
  span {
    font-weight: 400;
  }
}
::v-deep {
  .table tbody[role="rowgroup"] td {
    border-top: var(--border);
    border-bottom: var(--border);
    border-radius: 4px;
    background-color: #ffffff;
    &:first-of-type {
      border-left: var(--border);
    }
    &:last-of-type {
      border-right: var(--border);
    }
  }
  .table.b-table.b-table-stacked-lg > tbody > tr > [data-label] > div {
    word-wrap: break-word;
  }
}
</style>
