<template>
  <div class="w-100 mb-4">
    <div class="d-flex justify-content-between mb-2">
      <span>
        {{ field.title }}
      </span>
    </div>
    <b-form-select
      v-model="valueModel"
      :required="field.required"
      :options="currencyListWithDisabledState"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},

  props: {
    form: {
      type: Object,
      default: () => null,
    },
    field: {
      type: Object,
      default: () => null,
    },
    fieldKey: {
      type: String,
      default: "",
    },
    parentValue: {
      type: Object,
      default: () => ({}),
    },
    parentFormKey: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      localValue: [],
    };
  },

  watch: {
    value(val, old) {
      const same = old === val;
      if (!same) {
        this.localValue = val;
      }
    },
  },

  computed: {
    ...mapState({
      cID: (state) => state.campaign.campaign.data.id,
      currencyList: (state) => {
        let arr = state.account.currencyList;
        return arr;
      },
    }),

    componentAttrs() {
      return this.field?.componentAttrs || {};
    },

    value() {
      return this.form[this.fieldKey];
    },

    currencyListWithDisabledState() {
      let resultArr = [];

      for (var i = 0; i < this.currencyList.length; i++) {
        const value = this.currencyList[i].attributes.code;
        const disabled = (parentFormKey, parentValue, value) => {
          if (parentFormKey === "currency_to_amount") {
            return !!parentValue[value];
          }

          return false;
        };

        let obj = {
          text: value,
          value: value,
          disabled: disabled(this.parentFormKey, this.parentValue, value),
        };

        resultArr.push(obj);
      }

      return resultArr;
    },

    valueModel: {
      get() {
        return this.localValue;
      },
      set(val) {
        this.localValue = val;
        this.$emit("input", val);
      },
    },
  },
};
</script>
