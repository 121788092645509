import Quill from "quill";
import { camelize, match } from "../utils";
const Parchment = Quill.import("parchment");
import invert from "lodash/invert";
import { DEFAULT_INLINE_STYLES } from "quill-delta-to-html/dist/commonjs/OpToHtmlConverter";

const fontSize = Object.fromEntries(
  Object.entries(DEFAULT_INLINE_STYLES.size).map(([k, v]) => [k, v.replace("font-size: ", "")])
);

const fontSizeInvert = invert(fontSize);

class SizeAttributor extends Parchment.Attributor.Style {
  add(node, value) {
    this.remove(node);
    node.style[camelize(this.keyName)] = fontSize[value];
    node.classList.add(`ql-size-${value}`);
    return true;
  }

  remove(node) {
    let matches = match(node, "ql-size");
    matches.forEach(function (name) {
      node.classList.remove(name);
    });
    if (node.classList.length === 0) {
      node.removeAttribute("class");
    }
    node.style[camelize(this.keyName)] = "";
    if (!node.getAttribute("style")) {
      node.removeAttribute("style");
    }
  }

  value(node) {
    let value = node.style[camelize(this.keyName)];
    return fontSizeInvert[value] || "";
  }
}

export const SizeStyle = new SizeAttributor("size", "font-size", {
  scope: Parchment.Scope.INLINE,
});
