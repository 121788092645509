<script>
import axios from "axios";
import urls from "@/constants/urls";

export default {
  TITLE_SHORTEN_LENGTH: 39,

  props: {
    campaign: {
      type: Object,
      default: () => null,
    },
    campaignTitle: {
      type: String,
      default: "",
    },
    campaignShortlink: {
      type: String,
      required: true,
    },
    isFavoriteCampaign: Boolean,
  },
  computed: {
    cmpID() {
      return this.campaign.id;
    },
    cmpAttrs() {
      return this.campaign.attributes;
    },
  },
  methods: {
    shorten(value) {
      return value.length > this.$options.TITLE_SHORTEN_LENGTH
        ? value.slice(0, this.$options.TITLE_SHORTEN_LENGTH) + " ..."
        : value;
    },

    getPreviewToken() {
      const orgID = localStorage.getItem("orgId");

      return axios
        .get(urls.getPreviewToken(orgID, this.cmpID))
        .then((resp) => resp.data.data.token);
    },

    preview() {
      const shortLinkURL = new URL(this.campaignShortlink, "https://charidy.com");

      this.getPreviewToken().then((token) => {
        if (token) {
          shortLinkURL.searchParams.set("token", token);
        }
        window.open(decodeURI(shortLinkURL.toString()), "_blank").focus();
      });
    },
  },
};
</script>

<template>
  <div class="plate-campaign__title">
    <slot :campaignTitle="campaignTitle">
      <h2>{{ shorten(campaignTitle) }}</h2>
    </slot>
    <div class="plate-campaign__title-icons">
      <a v-if="campaignShortlink" @click="preview" class="open-page">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
            fill="#cc6d4c"
          />
        </svg>
      </a>
      <b-icon
        v-if="isFavoriteCampaign"
        icon="star-fill"
        :style="{ width: '16px', height: '16px' }"
        variant="warning"
      ></b-icon>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.plate-campaign {
  &__title {
    display: flex;
    flex-flow: row;
    min-height: 72px;
    align-items: baseline;
    vertical-align: baseline;
    gap: 0.5em;
    flex-wrap: wrap;
    margin: 0 0 30px;

    h2 {
      margin: 0;
      padding: 0;
      color: $fd;
      font-size: 24px;
      line-height: 1;
    }
  }
  &__title-icons {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
  }
}
.open-page {
  cursor: pointer;
}
</style>
