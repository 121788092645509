<template>
  <div>
    <b-modal ref="modal" modal-class="app-modal" size="xl" centered>
      <div slot="modal-header">
        <div class="modal-header__close" @click.prevent="hide">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
        <h2 class="h1-like">
          {{ $t("dashboard.update_public_data", "Edit") }}
        </h2>
      </div>

      <b-card no-body>
        <b-tabs card>
          <b-tab title="Donation data" active class="p-0">
            <edit-donation-form v-model="form" />
          </b-tab>
          <b-tab title="Donation to the teams" class="p-0">
            <edit-donation-teams
              ref="donationTeams"
              :donation="donation"
              @onValidate="onValidate('teams', $event)"
            />
          </b-tab>
        </b-tabs>
      </b-card>

      <b-alert v-if="!validState.teams" show class="mt-4" variant="warning">
        {{
          $t(
            "dashboard.donations_to_teams_error",
            "Validation error: Check please tab Donation to the teams"
          )
        }}
      </b-alert>

      <div slot="modal-footer">
        <button form="editReceipt" class="bttn bttn--blue" @click.prevent="hide">
          {{ $t("dashboard.cancel", "Cancel") }}
        </button>

        <b-button
          class="bttn bttn--sm bttn--orange text-nowrap"
          :disabled="loading || !valid"
          @click="save"
        >
          {{ $t("donor_dashboard.save", "Save") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls.js";
import { notificationsMixin } from "@/mixins";
import EditDonationForm from "./edit-donation-form.vue";
import EditDonationTeams from "./edit-donation-teams.vue";

export default {
  components: { EditDonationForm, EditDonationTeams },

  mixins: [notificationsMixin],

  data() {
    return {
      loading: false,
      success: false,

      donation: null,

      form: {
        display_name: "",
        dedication: "",
      },

      validState: {
        teams: true,
      },
    };
  },

  computed: {
    valid() {
      return !Object.values(this.validState).includes(false);
    },
  },

  methods: {
    show(donation) {
      this.setData(donation);

      this.donation = donation;

      this.$refs.modal.show();
    },

    hide() {
      this.form = {
        display_name: "",
        dedication: "",
      };
      this.success = false;
      this.$refs.modal.hide();
    },

    onValidate(key, valid) {
      this.validState[key] = valid;
    },

    setData(donation) {
      this.form.display_name = donation.display_name || "";
      this.form.dedication = donation.dedication || "";
    },

    save() {
      this.loading = true;

      const requests = [this.updDonation()];

      if (this.$refs.donationTeams) {
        requests.push(this.$refs.donationTeams.save());
      }

      Promise.all(requests)
        .then(() => {
          this.success = true;
          this.$emit("update-list");
          this.$_notificationsMixin_makeToast("Donation", "saved successfully", "success");
          this.hide();
        })
        .catch(this.$_notificationsMixin_handleCatch)
        .finally(() => {
          this.loading = false;
        });
    },

    updDonation() {
      const payload = this.form;

      return axios
        .put(urls.donorDonationsEdit.replace(":did", this.donation.id), payload)
        .then(() => true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.app-modal {
  .modal-header {
    position: relative;

    &__close {
      display: inline-flex;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .modal-content {
    overflow: hidden;
  }
}
</style>
