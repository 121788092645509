<template>
  <div class="donation-unit donation-unit--moderation">
    <div class="donation-unit__cell donation-unit__bulkselect">
      <b-form-checkbox
        :id="`checkbox-bulk-unpublished-${donation.id}`"
        :checked="checked"
        :name="`checkbox-bulk-unpublished-${donation.id}`"
        @change="onChange"
      >
      </b-form-checkbox>
    </div>

    <div class="donation-unit__cell donation-unit__id">
      {{ donation.id }}
    </div>

    <div class="donation-unit__cell donation-unit__dname">
      <div class="donation-unit__label">
        {{ $t("dashboard.donation_display_name") }}
      </div>
      {{ donation.attributes.display_name }}
    </div>

    <div class="donation-unit__cell donation-unit__dedication">
      <div class="donation-unit__label">
        {{ $t("dashboard.donation_dedication") }}
      </div>
      {{ donation.attributes.dedication }}
    </div>

    <div class="donation-unit__method donation-unit__cell">
      <div class="donation-unit__label">{{ $t("dashboard.donation_via") }}</div>
      {{ donation.attributes.bank_name }}
    </div>

    <div class="donation-unit__cell donation-unit__amount">
      <div class="donation-unit__label">
        {{ $t("dashboard.donation_amount") }}
      </div>
      {{ donation.attributes.charged_amount }} {{ donation.attributes.currency_code.toUpperCase() }}
    </div>

    <div class="donation-unit__effective donation-unit__cell">
      <div class="donation-unit__label">
        {{ $t("dashboard.donation_amount_effective") }}
      </div>
      {{ donation.attributes.effective_amount }}
      {{ donation.attributes.currency_code.toUpperCase() }}

      <!-- recuring icon -->
      <recuring-icon
        v-if="
          donation.attributes.recurring_period !== 0 || donation.attributes.installments_n !== 0
        "
        v-b-tooltip.hover
        :title="isRecurring ? $t('dashboard.donation_recurring_period') : installmentMessage"
        :isRecurring="isRecurring"
        :period="
          isRecurring ? donation.attributes.recurring_period : donation.attributes.installments_n
        "
      />
      <!--/ ^^ recuring icon -->
    </div>

    <div class="donation-unit__contact donation-unit__cell">
      <div class="donation-unit__label">{{ $t("dashboard.donation_email") }}</div>
      <span>{{ $_emailGettersMixin_getStaticEmail(donation)[0] }}</span>
      <span>/ {{ donation.attributes.phone }}</span>
    </div>

    <div class="donation-unit__cell donation-unit__date">
      {{ getDateFormat(donation.attributes.date) }}
    </div>

    <div class="donation-unit__action">
      <button class="bttn-round" @click="editDonate">
        <b-icon-pencil-fill />
      </button>

      <button
        v-if="!statusChanged"
        class="bttn-round bttn-round--auto"
        type="button"
        @click="publish"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
        </svg>
        {{ $t("dashboard.donation_publish") }}
      </button>

      <p v-else class="approved-text">{{ $t("dashboard.donation_published") }}</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import { emailGettersMixin } from "@/mixins";
import { mapState, mapActions, mapMutations } from "vuex";

import recuringIcon from "./donation/recuring-icon.vue";

export default {
  components: { recuringIcon },

  props: ["donation", "premoderation"],

  mixins: [emailGettersMixin],

  data() {
    return {
      statusChanged: false,
    };
  },

  computed: {
    ...mapState({
      campaignId: (state) => state.dashboard.selectedCampaign,
      selectedCampaignSign: (state) => {
        return state.dashboard.campaignDashboard.data.attributes.currency_sign;
      },
      bulkUnpublishedDonations: (state) => state.donation.bulkUnpublishedDonations,
    }),

    isRecurring() {
      return this.donation.attributes.recurring_period > 0;
    },
    installmentMessage() {
      if (this.donation.attributes.installments_n !== 0) {
        let currency = this.donation.attributes.currency_sign;
        let monthlyPayment =
          Math.round(
            (this.donation.attributes.charged_amount / this.donation.attributes.installments_n) * 10
          ) / 10;

        return `${currency}${monthlyPayment}/month`;
      }
      return "";
    },

    checked() {
      return Boolean(this.bulkUnpublishedDonations.find((v) => v === this.donation.id));
    },
  },

  methods: {
    ...mapActions({
      publishDonations: "publishDonations",
    }),
    ...mapMutations({
      checkBulkUnpublishedDonations: "check_bulkUnpublishedDonations",
      uncheckBulkUnpublishedDonations: "uncheck_bulkUnpublishedDonations",
    }),

    getDateFormat(date) {
      let donateDate = moment.unix(date).format("MMMM D, YYYY");

      let thisDate = moment(new Date()).format("MMMM D, YYYY");

      if (donateDate == thisDate) {
        return moment.unix(date).format("h:mm a");
      } else {
        return moment.unix(date).format("MMMM D, YYYY h:mm a");
      }
    },

    showDetails() {
      const $vm = this;

      const opt = {
        data: {
          id: this.donation.id,
          get name() {
            return `${this.billing_name} ${this.billing_last_name}`;
          },
          get amount() {
            return `${this.currency_sign}${
              this.charged_amount
            } ${this.currency_code.toUpperCase()}`;
          },
          get amount_effective() {
            return `${this.currency_sign}${
              this.effective_amount
            } ${this.currency_code.toUpperCase()}`;
          },
          ...this.donation.attributes,
        },
        valueFormatters: {
          date(v) {
            return $vm.getDateFormat(v);
          },
          effective_date(v) {
            return $vm.getDateFormat(v);
          },
        },
        modalTitle: this.$t("dashboard.donation_details", "Donation details"),
        translationKey(k) {
          return `dashboard.donation_details_${k}`;
        },
        excludedFields: [
          "team_id",
          "charged_amount",
          "effective_amount",
          "currency_sign",
          "currency_code",
          "processing_charged_amount",
          "processing_charged_currency",
          "billing_name",
          "billing_last_name",
          "account",
          "bank",
          "branch",
          "campaign_id",
          "campaign_name",
          "category",
          "change_request_note",
          "change_request_type",
          "check_n",
          "donation_receipt_id",
          "donation_type",
          "send_confirmation_email",
          "send_receipt",
          "module_data_id",
          "moderation_status",
          "legal_entity_id",
          "lead_status",
          "get_data",
          "failure_reason",
          "offline_donation_source",
          "offline_donation_note",
          "referrer",
        ],
      };

      this.$root.$emit("cahridy::details::show::modal", opt);
    },

    editDonate() {
      this.$emit("edit-donate", this.donation, this.premoderation);
      this.$store.dispatch("getTeamsByCampaignId", [this.campaignId]);
    },

    publish() {
      const message = this.$t("donors.donation_approved_confirm_msg");

      const options = {
        okText: this.$t("dashboard.donation_publish"),
        cancelText: this.$t("donors.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        const ids = [this.donation.id];

        this.publishDonations([this.campaignId, ids, true]).then(() => {
          this.statusChanged = true;
        });
      });
    },

    onChange(checked) {
      if (checked) {
        this.checkBulkUnpublishedDonations(this.donation.id);
      } else {
        this.uncheckBulkUnpublishedDonations(this.donation.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.approved-text {
  margin: 0;
  margin-right: 10px;
  font-weight: bold;
}
.donation-unit--moderation {
  .donation-unit__id {
    width: 100%;
    @media only screen and (min-width: 1024px) {
      width: 10%;
    }
  }

  .donation-unit__bulkselect {
    width: 5%;
  }

  .donation-unit__dname {
    display: flex;
    width: 100%;
    order: 2;
    position: relative;
    font-size: 16px;
    @media only screen and (min-width: 568px) {
      width: 50%;
    }
    @media only screen and (min-width: 768px) {
      width: 40%;
    }
    @media only screen and (min-width: 1024px) {
      width: 10%;
      min-width: 10%;
      order: initial;
      font-size: 14px;
    }
    .donation-unit__label {
      font-size: 14px;
      position: absolute;
      top: 2px;
      left: 5px;
    }
  }

  .donation-unit__dedication,
  .donation-unit__contact {
    width: 100%;
    order: 3;
    font-size: 16px;
    @media only screen and (min-width: 568px) {
      width: 50%;
    }
    @media only screen and (min-width: 768px) {
      width: 60%;
    }
    @media only screen and (min-width: 1024px) {
      width: 10%;
      min-width: 10%;
      order: initial;
      font-size: 14px;
    }
    .donation-unit__label {
      font-size: 14px;
      position: absolute;
      top: 2px;
      left: 5px;
    }
  }

  .donation-unit__amount {
    display: flex;
    width: 100%;
    order: 4;
    position: relative;
    font-size: 16px;
    @media only screen and (min-width: 568px) {
      width: 50%;
    }
    @media only screen and (min-width: 768px) {
      width: 40%;
    }
    @media only screen and (min-width: 1024px) {
      width: 10%;
      min-width: 10%;
      order: initial;
      font-size: 14px;
    }
    .donation-unit__label {
      font-size: 14px;
      position: absolute;
      top: 2px;
      left: 5px;
    }
  }

  .donation-unit__action {
    @media only screen and (max-width: 1023px) {
      padding-top: 10px;
      width: 100%;
      border-left: none;
    }
  }

  .bttn-round.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  .bttn-round:not(:last-of-type) {
    margin-right: 10px;
    @media only screen and (min-width: 1024px) {
      margin-bottom: 0;
    }
  }
}
</style>
