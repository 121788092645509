export const rdsStatuses = {
  undefined: {
    number: 0,
    translation: ["dashboard.rds_status_undefined", "undefined"],
  },
  authorized: {
    number: 1,
    code: "pending",
    translation: ["dashboard.rds_status_authorized", "authorized"],
  },
  "authorized and canceled": {
    number: 2,
    code: "canceled",
    translation: ["dashboard.rds_status_authorized_and_canceled", "authorized and canceled"],
  },
  "charge failed": {
    number: 3,
    code: "failed",
    translation: ["dashboard.rds_status_charge_failed", "charge failed"],
  },
  "charge successful": {
    number: 4,
    code: "successful",
    translation: ["dashboard.rds_status_charge_successful", "charge successful"],
  },
  "charge successful and disputed": {
    number: 5,
    translation: [
      "dashboard.rds_status_charge_successful_and_disputed",
      "charge successful and disputed",
    ],
  },
  "charge successful and refunded": {
    number: 6,
    translation: [
      "dashboard.rds_status_charge_successful_and_refunded",
      "charge successful and refunded",
    ],
  },
};
