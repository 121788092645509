<template>
  <div class="plate plate--wm">
    <div class="plate__media">
      <div v-if="logos[integratiodata.attributes.service]" class="plate__logo">
        <img
          :src="logos[integratiodata.attributes.service]"
          :alt="integratiodata.attributes.service"
        />
      </div>
      <div class="plate__title">
        {{ integratiodata.attributes.service }} {{ getSandboxPrefix(integratiodata) }}
      </div>
    </div>

    <div class="plate__data">
      <div class="plate__date">{{ getDateFormat(integratiodata.attributes.created_at) }}</div>

      <div class="plate__action" @click.prevent="gatewayOverview">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 480.606 480.606"
          style="enable-background: new 0 0 480.606 480.606"
          xml:space="preserve"
        >
          <g>
            <rect x="85.285" y="192.5" width="200" height="30" />
            <path
              d="M439.108,480.606l21.213-21.213l-71.349-71.349c12.528-16.886,19.949-37.777,19.949-60.371
                    c0-40.664-24.032-75.814-58.637-92.012V108.787L241.499,0H20.285v445h330v-25.313c6.188-2.897,12.04-6.396,17.475-10.429
                    L439.108,480.606z M250.285,51.213L299.072,100h-48.787V51.213z M50.285,30h170v100h100v96.957
                    c-4.224-0.538-8.529-0.815-12.896-0.815c-31.197,0-59.148,14.147-77.788,36.358H85.285v30h126.856
                    c-4.062,10.965-6.285,22.814-6.285,35.174c0,1.618,0.042,3.226,0.117,4.826H85.285v30H212.01
                    c8.095,22.101,23.669,40.624,43.636,52.5H50.285V30z M307.389,399.208c-39.443,0-71.533-32.09-71.533-71.533
                    s32.089-71.533,71.533-71.533s71.533,32.089,71.533,71.533S346.832,399.208,307.389,399.208z"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import moment from "moment";

export default {
  props: ["integratiodata"],

  methods: {
    gatewayOverview() {
      this.$emit("view-data", this.integratiodata);
    },

    getDateFormat: function (date) {
      return moment.unix(date).format("MMMM D, YYYY h:mm a");
    },

    getSandboxPrefix(s) {
      switch (s.attributes.service) {
        case "donorfuse":
          if (s.attributes.sandbox) {
            return "(Sandbox)";
          }
      }
      return "";
    },
  },

  computed: mapState({
    logos: function () {
      let logos = {
        salesforce:
          "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/services/salesforce-logo.png",
        mailchimp:
          "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/services/mailchimp-logo.png",
        overpass:
          "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/services/overpass.svg",
        zapier:
          "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/images/281/zapier_logo_1657439051.png",
        tax_gift:
          "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/images/7943/taxgift_1660867465.png",
        comeunity:
          "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/images/281/file_1662029944.png",
      };
      return logos;
    },
  }),
};
</script>

<style lang="scss" scoped>
.gateway-plate {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
  &__media {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-width: 180px;
    margin-right: 20px;
    margin-bottom: 20px;
    @media only screen and (min-width: 992px) {
      margin-bottom: initial;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    height: 20px;
    min-width: 80px;
    max-width: 80px;
    overflow: hidden;
    img {
      height: 20px;
      width: auto;
    }
  }
  &__title {
    font-size: 16px;
    padding: 0 0 0 10px;
    text-transform: capitalize;
  }
  &__owner {
    padding-right: 10px;
    font-size: 16px;
    color: #20434e;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 20px;
    @media only screen and (min-width: 992px) {
      margin-right: 20px;
      margin-bottom: initial;
    }
  }
  &__data {
    margin-left: auto;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    @media only screen and(min-width: 992px) {
      width: auto;
      justify-content: initial;
    }
  }
  &__ccy {
    margin-right: 15px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__verification {
    margin-right: 15px;
    min-width: 90px;
    font-size: 16px;
    text-transform: capitalize;
  }
  &__status {
    margin-right: 15px;
    min-width: 65px;
    font-size: 16px;
    color: gray;
    text-transform: capitalize;
    &.enabled {
      color: #65bd6a;
    }
  }
  &__action {
    min-height: 25px;
    min-width: 25px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    svg {
      width: 22px;
      height: auto;
      fill: #cc6d4c;
    }
  }
}
</style>
