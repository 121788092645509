<template>
  <div class="help-tooltip">
    <svg
      v-if="icon == 'warn'"
      data-name="Layer 1"
      id="Layer_1"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#FFDE03"
        d="M30.16,11.51,6.84,51.9a2.13,2.13,0,0,0,1.84,3.19H55.32a2.13,2.13,0,0,0,1.84-3.19L33.84,11.51A2.13,2.13,0,0,0,30.16,11.51Z"
      />
      <path
        fill="#F26E66"
        d="M29,46a3,3,0,1,1,3,3A2.88,2.88,0,0,1,29,46Zm1.09-4.66-.76-15h5.26l-.73,15Z"
      />
    </svg>

    <svg
      v-else-if="icon === 'info'"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="#107598"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
      />
    </svg>

    <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="#107598"
        d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
      />
    </svg>

    <slot class="help-tooltip__message"></slot>
  </div>
</template>

<script>
export default {
  props: ["icon"],
  name: "tooltip",
};
</script>

<style lang="scss" scoped>
.help-tooltip {
  display: inline-block;
  position: relative;
  transition: all 0.2s ease-in-out;
  > svg {
    display: block;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  &__message {
    width: 180px;
    padding: 10px;
    border: 1px solid #414359;
    border-radius: 4px;
    font-size: 14px;
    text-transform: initial;
    background-color: #eaf4ff;
    position: absolute;
    display: none;
    opacity: 0;
    z-index: 1;
    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 7px 0 7px;
      border-color: #414359 transparent transparent transparent;
      position: absolute;
      bottom: -5px;
      right: 4px;
    }
  }
  &:hover {
    .help-tooltip__message {
      display: block;
      opacity: 1;
      right: -4px;
      bottom: 22px;
      pointer-events: none;
    }
  }
}
</style>
