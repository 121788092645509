<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <b-alert :show="notEmpty" variant="info" v-html="translated" />
</template>

<script>
export default {
  props: {
    tKey: {
      type: String,
      default: "",
    },
  },
  computed: {
    translated() {
      return this.$t(this.tKey);
    },
    notEmpty() {
      const original = this.tKey;
      const translated = this.translated;
      if (original === translated) {
        return false;
      }

      return translated.trim().length !== 0;
    },
  },
};
</script>

<style lang="scss" scoped></style>
