<template>
  <CdnLogoHe v-if="lang === 'he'" />
  <CdnLogo v-else />
</template>

<script>
import { mapState } from "vuex";
import CdnLogoHe from "./cdn-logo-he.vue";
import CdnLogo from "./cdn-logo.vue";

export default {
  components: { CdnLogoHe, CdnLogo },
  computed: {
    ...mapState({
      lang: (state) => state.translation.locate,
    }),
  },
};
</script>
