<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="team-plate">
    <div class="team-plate__header">
      <div class="team-plate__image" v-if="team.attributes.image">
        <img :src="team.attributes.image" :alt="team.attributes.name" />
      </div>

      <div class="team-plate__data">
        <div class="team-plate__title-block">
          <h2 class="team-plate__title">
            {{ team.attributes.name | shorten(26) }}
          </h2>
          <div class="team-color" :style="{ backgroundColor: team.attributes.color }"></div>
          <div v-if="parentLevelTag" class="team-plate__parent_level">
            <b-badge pill variant="warning">
              {{ $t(`teams.team_parent_level_${parentLevel}`, parentLevelTag) }}
            </b-badge>
          </div>
        </div>

        <div class="team-data">
          <div class="team-data__item">
            {{ $t("teams.team_id", "ID") }}: <span>{{ team.id }}</span>
          </div>

          <div class="team-data__item">
            {{ $t("teams.shortlink", "Slug") }}: <span>{{ team.attributes.slug }}</span>
          </div>

          <div class="team-data__item">
            {{ $t("teams.goal", "Goal") }}:
            <span class="text-uppercase"> {{ displayGoalCurrencyAmount }} {{ goalCurrency }} </span>
            <span v-if="goalCurrency !== campaignCurrrency" class="text-uppercase">
              ({{ goalInCampaignCurrency }} {{ campaignCurrrency }})
            </span>

            <div v-if="team.attributes.bonus_goal">
              {{ $t("teams.bonus_goal", "Bonus goal") }}:
              <span class="text-uppercase">
                {{ displayBonusGoalCurrencyAmount }} {{ goalCurrency }}
              </span>
              <span v-if="goalCurrency !== campaignCurrrency" class="text-uppercase">
                ({{ bonusGoalInCampaignCurrency }} {{ campaignCurrrency }})
              </span>
            </div>
          </div>

          <div v-if="team.attributes.donor_goal > 0" class="team-data__item">
            {{ $t("teams.donor_goal", "Donor goal") }}:
            <span>{{ team.attributes.donor_goal }}</span>
          </div>

          <div v-if="team.attributes.sort > 0" class="team-data__item">
            {{ $t("teams.order", "Order") }}:
            <span>{{ team.attributes.sort }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="team-plate__body">
      <div class="team-plate__description" v-html="team.attributes.description"></div>

      <div class="team-plate__action">
        <div class="d-flex flex-column">
          <AppSwitchToggle
            :label="$t('teams.show_hide_switch_label', 'Visibility')"
            v-model="team.attributes.hidden"
            @change="changeShowHide"
            :true-value="false"
            :false-value="true"
            plate
          />
          <AppSwitchToggle
            class="my-1"
            :label="$t('teams.unsubscribe_from_email', 'Unsubscribe From Email')"
            v-model="team.attributes.unsubscribe_from_email"
            @change="changeUnsubscribe"
            plate
          />
          <div v-if="teamDonationNotificationBySms" class="my-1">
            <AppSwitchToggle
              :label="$t('teams.unsubscribe_from_sms', 'Unsubscribe From Sms')"
              v-model="team.attributes.unsubscribe_from_sms"
              @change="changeUnsubscribe"
              plate
            />
          </div>
          <div v-if="whatsappMeta.value == true" class="my-1">
            <AppSwitchToggle
              :label="$t('teams.unsubscribe_from_whatsapp', 'Unsubscribe From Whatsapp')"
              v-model="team.attributes.unsubscribe_from_whatsapp"
              @change="changeUnsubscribe"
              plate
            />
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center">
          <a
            class="mx-2"
            v-if="waHref"
            aria-label="Chat on WhatsApp"
            target="_blank"
            :href="waHref"
          >
            <img
              alt="Chat on WhatsApp"
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1708037021_6239643632643266623030613862613461356162333164303139396234326136_31373038303337303231.svg"
            />
          </a>

          <button
            v-if="team_sms_messaging"
            class="bttn bttn-round edit mx-2"
            @click="$emit('send-sms', { team })"
          >
            <b-icon-chat-text-fill />
          </button>

          <button
            v-if="whatsappMeta.value == true"
            class="bttn bttn-round edit mx-2"
            @click="$emit('send-whatsapp', { team })"
          >
            <b-icon-whatsapp />
          </button>

          <button
            v-if="team_email_messaging"
            class="bttn bttn-round edit mx-2"
            @click="$emit('send-email', { team })"
          >
            <b-icon-envelope-fill />
          </button>

          <CampaignCopyButton
            :id="team.attributes.slug"
            :value="`https://${mainShortUrl}/${mainShortLink}/${team.attributes.slug}`"
            @copy-link="$emit('copy-link')"
            class="mx-2"
          />

          <button :disabled="!hasAccessEdit" class="bttn bttn-round edit mx-2" @click="editTeam()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </button>

          <button
            :disabled="!hasAccessEdit"
            class="bttn bttn-round delete mx-2"
            @click="deleteTeam()"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import CampaignCopyButton from "@/views/campaigns/campaign-edit-vue/subcomponents/campaignCopyButton.vue";
import AppSwitchToggle from "@/common-components/ui-elements/app-switch-toggle.vue";
import { notificationsMixin } from "@/mixins";

export default {
  props: ["team"],

  mixins: [notificationsMixin],

  components: {
    CampaignCopyButton,
    AppSwitchToggle,
  },

  computed: {
    ...mapState({
      team_sms_messaging: (state) => state.messaging.team_sms_messaging,
      team_email_messaging: (state) => state.messaging.team_email_messaging,
      metas: (s) => s.campaign.metas,

      hasAccessEdit: (state) => {
        return state.account.access.CanEditCampaignTeams;
      },
      mainShortLink: (state) => {
        return state.campaign.campaign.data.attributes.short_link;
      },
      mainShortUrl: (state) => {
        return state.campaign.campaign.data.attributes.short_url || "charidy.com";
      },
      orgId() {
        return localStorage.getItem("orgId");
      },
    }),

    goalCurrency() {
      return this.team.attributes.display_goal_currency;
    },

    campaignCurrrency() {
      return this.team.attributes.campaign_currrency;
    },

    goalInCampaignCurrency() {
      return this.team.attributes.goal;
    },

    bonusGoalInCampaignCurrency() {
      return this.team.attributes.bonus_goal;
    },

    internalGoalInCampaignCurrency() {
      return this.team.attributes.internal_goal;
    },

    displayGoalCurrencyAmount() {
      if (this.goalCurrency === this.campaignCurrrency) {
        return this.goalInCampaignCurrency;
      }
      return this.team.attributes.display_goal_currency_amount;
    },

    displayBonusGoalCurrencyAmount() {
      if (this.goalCurrency === this.campaignCurrrency) {
        return this.bonusGoalInCampaignCurrency;
      }
      return this.team.attributes.display_bonus_goal_currency_amount;
    },

    displayInternalGoalCurrencyAmount() {
      if (this.goalCurrency === this.campaignCurrrency) {
        return this.internalGoalInCampaignCurrency;
      }
      return this.team.attributes.display_internal_goal_currency_amount;
    },

    teamDonationNotificationBySms() {
      return this.metas?.team_donation_notification_by_sms?.value;
    },

    parentLevel() {
      return this.team.attributes.extend?.stats?.parent_level;
    },

    parentLevelTag() {
      switch (this.parentLevel) {
        case 1:
          return "parent";

        case 2:
          return "grandparent";

        default:
          return "";
      }
    },
    whatsappMeta() {
      return this.metas?.campaign_whatsapp_settings || {};
    },

    waHref() {
      let waPhone = this.team.attributes.phone;
      if (!waPhone) {
        return false;
      }

      waPhone = waPhone.replace(/\D/g, "");
      const text = `https://${this.mainShortUrl}/${this.mainShortLink}/${this.team.attributes.slug}`;

      return `https://wa.me/${waPhone}?text=${encodeURI(text)}`;
    },
  },

  created() {
    this.teamHidden = this.team.attributes.hidden;
  },

  methods: {
    editTeam: function () {
      this.$emit("edit-team", this.team);
    },

    deleteTeam: function () {
      this.$emit("delete-team", this.team);
    },

    changeShowHide() {
      const payload = {
        data: {
          attributes: this.team.attributes,
        },
      };

      this.putTeam(payload)
        .then(() => {
          this.$_notificationsMixin_makeToast("Success", this.$t("edit_campaign.success_edit"));
          this.$store.dispatch("getTeamsByCampaignId", [this.team.attributes.campaign_id]);
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    changeUnsubscribe() {
      const payload = {
        data: {
          attributes: this.team.attributes,
        },
      };

      this.putTeam(payload)
        .then(() => {
          this.$_notificationsMixin_makeToast("Success", this.$t("edit_campaign.success_edit"));
          this.$store.dispatch("getTeamsByCampaignId", [this.team.attributes.campaign_id]);
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    putTeam(payload) {
      return axios.put(
        urls.editTeams
          .replace(":orgId", this.orgId)
          .replace(":campaignId", this.team.attributes.campaign_id) +
          "/" +
          this.team.id,
        payload
      );
    },
  },

  filters: {
    shorten(value, stop) {
      return value.length > stop ? value.slice(0, stop) + " ..." : value;
    },
  },
};
</script>

<style lang="scss" scope>
.team-data {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
  align-items: center;

  &__item {
    padding-bottom: 8px;
    width: 50%;
    font-size: 14px;
    color: #414359;

    @media only screen and (max-width: 568px) {
      width: 100%;
    }

    span {
      font-family: "VitoExtended-Light", sans-serif;
      color: #107598;
    }
  }
}
</style>

<style lang="scss">
.team-plate__title-block {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.team-plate__title {
  min-height: 54px;
  margin: 0;
  padding: 0;
  color: #414359;
  font-size: 24px;
  text-transform: uppercase;
  text-align: center;
  line-height: 54px;
}

.team-plate__image {
  display: flex;
  align-items: center;
}

.team-plate__parent_level {
  align-self: flex-start;
  margin-left: auto;
  font-size: 18px;
}

.team-color {
  align-self: flex-start;
  background-color: rgb(37, 51, 109);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: 12px;
  flex-shrink: 0;
}
</style>
