<script>
import Vue from "vue";
import { mapGetters } from "vuex";

const VueComponent = Vue.extend({
  props: {
    internalValue: {
      type: Boolean,
      default: false,
    },
    internalLockedType: {
      type: String,
      default: "",
    },
    internalValueInt: {
      type: [Number, Object],
      default: undefined,
    },
    onLockedChange: {
      type: Function,
      required: true,
    },
    onLockedVarIntInput: {
      type: Function,
      default: () => {},
    },
    hasThresholdsError: {
      type: String,
      default: "",
    },
    thresholdChangeRateLoading: Boolean,

    donationSelectedTeam: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      getMetasInSelectedCampaign: "getMetasInSelectedCampaign",
    }),

    allowConditionalDonations() {
      const data = this.getMetasInSelectedCampaign?.allow_conditional_donations;

      if (data?.value) {
        return data;
      }

      return {};
    },
    show() {
      return (
        Object.keys(this.donationSelectedTeam || {}).length > 0 &&
        this.allowConditionalDonations?.value &&
        this.allowConditionalDonations?.types_supported.includes("team_reach_x")
      );
    },
    thresholdsDisabled() {
      return !!this.hasThresholdsError || this.thresholdChangeRateLoading;
    },
  },
  methods: {
    vErrorsHas(field, rule) {
      return this.vErrors.items.find((el) => el.field === field && el.rule === rule);
    },
    onChange(event) {
      this.onLockedChange("team_reach_x", event.target.checked);
    },
  },
});

export default class TeamReachXControl extends VueComponent {}
</script>

<template>
  <b-list-group-item v-if="show" class="extra-panel">
    <div class="extra-panel__container">
      <div class="extra-panel__input">
        <input
          id="team_reach_x"
          :checked="internalValue && internalLockedType === 'team_reach_x'"
          type="checkbox"
          name="allow_conditional_donations"
          :disabled="thresholdsDisabled"
          @change="onChange"
        />
      </div>
      <div>
        <h4 class="extra-panel__title">
          {{
            $t(
              "donation.team_reach_x_label",
              "Donate on condition my donation gets matched when selected team reaches X goal!"
            )
          }}
        </h4>
        <p class="extra-panel__description">
          {{
            $t(
              "donation.team_reach_x_description",
              `Your donation will be showed on the campaign page, inspiring others to match it.
              The donation amount will contribute to the campaign goal once team reaches X goal!`
            )
          }}
        </p>
        <p v-if="thresholdChangeRateLoading" class="extra-panel__description">
          <span>
            {{ $t("donation.peer_match_loading", "Loading...") }}
          </span>
        </p>
        <p
          v-else-if="hasThresholdsError && !thresholdChangeRateLoading"
          class="extra-panel__description extra-panel__description--error"
        >
          <span>
            {{ hasThresholdsError }}
          </span>
        </p>
      </div>
      <div class="extra-panel__img">
        <img
          width="100"
          height="100"
          :src="
            [
              'https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com',
              'public_upload',
              '1717202103_6434396435656537323565313730396238356463666536353333363438366338_31373137323032313033.png',
            ].join('/')
          "
        />
      </div>
    </div>
    <div v-if="internalValue && internalLockedType === 'team_reach_x'" class="form-group">
      <label class="extra-panel__form-group-label">
        {{ $t("donation.team_reach_x_input_label", "Desired goal to reach") }}
        <b-form-input
          v-validate.immediate.persist="{ required: true, numeric: true, min_value: 1 }"
          :value="internalValueInt"
          inputmode="numeric"
          name="team_reach_x_control_value_int"
          type="text"
          :placeholder="$t('donation.team_reach_x_input_placeholder', 'Enter your goal')"
          @input="onLockedVarIntInput"
        />
      </label>
      <span v-show="vErrors.has('team_reach_x_control_value_int')" class="validation-error">
        {{
          vErrorsHas("team_reach_x_control_value_int", "required") &&
          $t("donation.team_reach_x_input_validation_required", "This field is required")
        }}
        {{
          vErrorsHas("team_reach_x_control_value_int", "numeric") &&
          $t("donation.team_reach_x_input_validation_numeric", "This field must be numeric")
        }}
        {{
          vErrorsHas("team_reach_x_control_value_int", "min_value") &&
          $t(
            "donation.team_reach_x_input_validation_min_value",
            "This field must be greater than or equal to 1"
          )
        }}
      </span>
    </div>
  </b-list-group-item>
</template>

<style lang="scss" scoped>
.validation-error {
  color: red;
  pointer-events: none;
}
.extra-panel {
  padding-inline: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  &__form-group-label {
    width: 100%;
  }

  &__title {
    margin: 0px;
    font-size: 14px;
    font-weight: bold;
  }

  &__description {
    margin: 0;
    font-size: 14px;
    font-weight: 400;

    &--error {
      color: red;
    }
  }

  &__input {
    input {
      width: 30px;
      height: 30px;
    }
  }

  &__img {
    width: 150px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
