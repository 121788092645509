<template>
  <div class="share-video" v-if="!loading">
    <div v-if="selectedCmp">
      <ShareVideoProvider v-slot="{ list, remove, loading, viewState, openAddModal }">
        <ShareVideoGrid
          v-if="viewState === 'grid'"
          :list="list"
          :remove="remove"
          :openAddModal="openAddModal"
        />
        <ShareVideoTable
          v-else-if="viewState === 'table'"
          :list="list"
          :remove="remove"
          :loading="loading"
          :openAddModal="openAddModal"
        />
      </ShareVideoProvider>
    </div>
  </div>
  <div v-else>
    <div class="text-center">
      <b-spinner class="align-middle"></b-spinner>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ShareVideoProvider from "./ShareVideoProvider.vue";
import ShareVideoGrid from "./ShareVideoGrid.vue";
import ShareVideoTable from "./ShareVideoTable.vue";

export default {
  components: {
    ShareVideoGrid,
    ShareVideoProvider,
    ShareVideoTable,
  },

  computed: {
    ...mapState({
      campaign: (state) => state.campaign.campaign.data,
      loading: (state) => state.shareVideo.loading,
    }),

    selectedCmp: {
      get() {
        return this.$store.state.shareVideo.campaign;
      },
      set(val) {
        this.selectCampaign(val);
      },
    },
  },

  mounted() {
    this.selectedCmp = this.campaign;
  },

  methods: {
    ...mapActions({
      selectCampaign: "shareVideo/selectCampaign",
    }),
  },
};
</script>
