<template>
  <div>
    <b-modal id="request-receipt" size="md" hide-header hide-footer @hide="resetData" centered>
      <div class="dialog-message">
        <p v-if="!regenerating && !generated">Please, confirm receipt generating</p>
        <p v-if="!regenerating && generated">Precess completed</p>
        <div v-if="regenerating" class="process-overlay">
          <LdsSpinner />
          <span>Progress ... {{ progress }}%</span>
        </div>
      </div>

      <div
        class="dialog-action"
        :style="{ justifyContent: generated ? 'center' : 'space-between' }"
      >
        <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
          {{ $t("dashboard.receipt_cancel") }}
        </button>
        <button
          v-if="!generated"
          class="bttn bttn--lg bttn--orange"
          @click.prevent="regenerate"
          :disabled="regenerating"
        >
          {{ $t("dashboard.receipt_generate") }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";

export default {
  name: "requestReceiptModal",
  components: {
    LdsSpinner,
  },
  data() {
    return {
      orgId: "",
      donationId: null,
      campaignId: "",
      initialEmail: "",
      //
      regenerating: false,
      progress: 0,
      procId: null,
      generated: false,
    };
  },
  methods: {
    openModal(donate) {
      this.orgId = localStorage.getItem("orgId");
      this.donationId = donate.id;
      this.campaignId = donate.attributes.campaign_id;
      // init flags
      this.regenerating = false;
      this.progress = 0;
      this.procId = null;
      this.generated = false;

      this.$bvModal.show("request-receipt");
    },
    hideModal() {
      this.resetData();
      this.$bvModal.hide("request-receipt");
    },
    resetData() {
      this.orgId = "";
      this.donationId = null;
      this.campaignId = "";
      this.initialEmail = "";
    },
    regenerate() {
      this.regenerating = true;
      axios
        .post(
          urls.getReceipt
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignId)
            .replace(":donationId", this.donationId),
          {
            force: true,
            generateReceipt: true,
            generateEmail: true,
            sendEmail: false,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.procId = res.data.proc;
            this.procCheck();
          }
        })
        .catch((err) => {
          this.regenerating = false;
          console.log(err);
        });
    },
    procCheck() {
      let token = localStorage.getItem("jwt_token");

      let interval = setInterval(() => {
        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .get(urls.oldProcProgressTracker.replace(":id", this.procId))
          .then((res) => {
            let data = res.data.data;
            this.progress = data.attributes.progress;

            if (data.attributes.finished) {
              clearInterval(interval);
              this.progress = 0;
              this.regenerating = false;
              this.generated = true;
              this.$emit("update");
            }
          })
          .catch((err) => {
            this.regenerating = false;
            console.log(err);
          });
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-message {
  padding: 10px 0;
  margin-bottom: 15px;
  font-family: "VitoExtended-Light";
  font-size: 18px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  p {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.process-overlay {
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: rgba(0,0,0,0.1);
  position: absolute;
  top: 0px;
  left: 0;
  > span {
    padding-top: 5px;
  }
}
::v-deep .modal-body {
  padding: 20px !important;
}
.dialog-action {
  display: flex;
  justify-content: space-between;
}
</style>
