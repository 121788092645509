import urls from "@/constants/urls";
import axios from "axios";

const orgId = localStorage.getItem("orgId");

export const shortlinkValidationSetup = () => {
  const createUrlCampaign = ({ oid, cid, shortLink }) =>
    urls.validationShortLink
      .replace(":orgId", oid || orgId)
      .replace(":campaignId", cid)
      .replace(":exclude_campaign_id", cid)
      .replace(":shortlink", shortLink);

  const createUrlOrg = ({ oid }) => urls.validationShortLinkOrg.replace(":orgId", oid || orgId);

  const validateByCampaign = ({ oid, cid, shortLink }) =>
    axios.get(createUrlCampaign({ oid, cid, shortLink }));

  const validateByOrg = ({ oid, shortLink }) =>
    axios.get(createUrlOrg({ oid }), {
      params: { q: shortLink, exclude_org_id: oid },
    });

  return { validateByOrg, validateByCampaign };
};
