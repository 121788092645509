<template>
  <div>
    <b-modal id="video-restricted" size="lg" centered>
      <div slot="modal-header">
        <div class="modal-header__hide" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue el-right" @click.prevent="hideModal">
          {{ $t("training.restricted_video_close") }}
        </button>
      </div>

      <div class="restricted-message">
        {{ $t("training.restricted_video_message") }}
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  methods: {
    showModal() {
      this.$root.$emit("bv::show::modal", "video-restricted");
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "video-restricted");
    },
  },
};
</script>

<style lang="scss">
#video-restricted {
  .modal-header {
    height: 65px;
  }
  .modal-body {
    &.no-footer {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  .restricted-message {
    font-size: 16px;
    text-align: center;
  }
  .el-right {
    margin-left: auto;
  }
}
</style>
