import urls from "../../constants/urls.js";
import axios from "axios";

const state = {
  list: [],
  listPublic: [],
  count: 0,
  loading: false,
  active: false,
  error: null,
};

const mutations = {
  setAtive(state, data) {
    state.active = data;
  },

  setList(state, data) {
    state.list = data;
  },

  setListPublic(state, data) {
    state.listPublic = data;
  },

  setCount(state, val) {
    state.count = Number(val) || 0;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  setError(state, val) {
    state.error = val;
  },
};

const actions = {
  fetchIsActive({ commit }, { cId }) {
    const url = urls.getSeferTorahTeamPagesPublic.replace(":cid", cId);

    const params = {
      page: 1,
      per_page: 1,
    };

    axios.get(url, { params }).then(({ data }) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      commit("setAtive", Boolean([...data?.data].length));
    });
  },

  fetchPages({ commit }, { cId, tId, params }) {
    commit("setLoading", true);

    const url = urls.getSeferTorahTeamPages.replace(":cid", cId).replace(":tid", tId);

    axios
      .get(url, {
        params,
      })
      .then(({ data, headers }) => {
        commit("setCount", headers["x-total-records"]);
        // eslint-disable-next-line no-unsafe-optional-chaining
        commit("setList", [...data?.data]);
        commit("setLoading", false);
      })
      .catch((e) => {
        commit("setError", e.response);
        commit("setLoading", false);
      });
  },

  async fetchPagesPublic({ commit }, { cId, params = { extend: "parent" } }) {
    const url = urls.getSeferTorahTeamPagesPublic.replace(":cid", cId);
    const { data, headers } = await axios.get(url, {
      params,
    });
    commit("setCount", headers["x-total-records"]);
    commit("setListPublic", [...(data?.data || [])]);

    return { data: data?.data || [], count: Number(headers["x-total-records"]) };
  },

  fetchCreatePage(_, { tId, id, data, params }) {
    const url = urls.editSeferTorahTeamPages.replace(":tid", tId).replace(":id", id);

    return axios.post(url, data, { params });
  },

  fetchOrgCreatePage(_, { cId, tId, id, data, params }) {
    const orgId = localStorage.getItem("orgId");

    const url = urls.orgEditSeferTorahTeamPages
      .replace(":oid", orgId)
      .replace(":cid", cId)
      .replace(":tid", tId)
      .replace(":id", id);

    return axios.post(url, data, { params });
  },

  async fetchDeletePage(_, { tId, id, params = {} } = {}) {
    const url = urls.editSeferTorahTeamPages.replace(":tid", tId).replace(":id", id);

    const { data, headers } = await axios.delete(url, { params });

    return { data: data?.data || [], count: Number(headers["x-total-records"]) };
  },

  fetchOrgDeletePage(_, { cId, tId, id }) {
    const orgId = localStorage.getItem("orgId");

    const url = urls.orgEditSeferTorahTeamPages
      .replace(":oid", orgId)
      .replace(":cid", cId)
      .replace(":tid", tId)
      .replace(":id", id);

    return axios.delete(url);
  },

  fetchOrgImport(_, { cId, file }) {
    const orgId = localStorage.getItem("orgId");

    let data = new FormData();
    data.append("file", file);

    const url = urls.seferTorahImport.replace(":oid", orgId).replace(":cid", cId);

    return axios.post(url, data);
  },

  fetchOrgExport(_, { cId }) {
    const orgId = localStorage.getItem("orgId");

    const url = urls.seferTorahExport.replace(":oid", orgId).replace(":cid", cId);

    return axios.get(url);
  },

  async fetchOrgCreatePageNoTeam(_, { cId, data, params }) {
    const orgId = localStorage.getItem("orgId");

    const url = urls.orgSaveSeferTorah.replace(":oid", orgId).replace(":cid", cId);

    const { data: data_1, headers } = await axios.post(
      url,
      { data: { attributes: { ...data.attributes, campaign_id: Number(cId) } } },
      { params }
    );
    return { data: data_1?.data || [], count: Number(headers["x-total-records"]) };
  },

  async fetchOrgEditPageNoTeam(_, { cId, id, data, params }) {
    const orgId = localStorage.getItem("orgId");

    const url = urls.orgEditSeferTorah
      .replace(":oid", orgId)
      .replace(":cid", cId)
      .replace(":id", id);

    const { data: data_1, headers } = await axios.patch(url, { data }, { params });
    return { data: data_1?.data || [], count: Number(headers["x-total-records"]) };
  },

  async fetchOrgDeletePageNoTeam(_, { cId, id, params }) {
    const orgId = localStorage.getItem("orgId");

    const url = urls.orgDeleteSeferTorah
      .replace(":oid", orgId)
      .replace(":cid", cId)
      .replace(":id", id);

    const { data, headers } = await axios.delete(url, { params });

    return { data: data?.data || [], count: Number(headers["x-total-records"]) };
  },

  fetchOrgImportTemplate(_, { cId, params }) {
    const orgId = localStorage.getItem("orgId");

    const url = urls.seferTorahImportTemplate.replace(":oid", orgId).replace(":cid", cId);

    return axios.get(url, { params }).then(({ data }) => data);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
