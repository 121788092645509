<script>
import { confirmMixin, notificationsMixin } from "@/mixins";
import { mapState, mapActions } from "vuex";

export default {
  mixins: [notificationsMixin, confirmMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    templateText: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      templateId: 0,
      templateName: "",
      loading: false,
      messageTemplatesList: [],
    };
  },
  computed: {
    ...mapState({}),

    currentTemplate() {
      return this.messageTemplatesList.find((t) => t.id === this.templateId);
    },
  },
  mounted() {
    this.getMessageTemplates();
  },
  methods: {
    ...mapActions({
      fetchMessageTemplates: "getMessageTemplates",
      saveMessageTemplates: "saveMessageTemplates",
      deleteMessageTemplates: "deleteMessageTemplates",
    }),
    async getMessageTemplates() {
      this.loading = true;

      try {
        const data = await this.fetchMessageTemplates({
          type: this.type,
        });
        this.messageTemplatesList = data;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async saveNewTemplate() {
      this.loading = true;
      try {
        let payload;
        if (this.currentTemplate) {
          payload = this.messageTemplatesList.map((t) => {
            if (t.id === this.currentTemplate.id) {
              return {
                ...t,
                name: this.templateName,
                text: this.templateText,
              };
            }

            return t;
          });
        } else {
          payload = [
            ...this.messageTemplatesList,
            {
              id: 0,
              type: this.type,
              name: this.templateName,
              text: this.templateText,
            },
          ];
        }

        const newItem = await this.saveMessageTemplates({
          type: this.type,
          payload,
        });

        if (newItem) {
          this.chooseTemplate(newItem);
        }

        await this.getMessageTemplates();
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    chooseTemplate(template) {
      this.templateId = template.id;
      this.templateName = template.name;
      this.$emit("update:templateText", template.text);
    },
    async deleteTemplate() {
      if (!this.currentTemplate) {
        return;
      }

      this.loading = true;
      try {
        const ok = await this.$_confirmMixin_deleteConfirm({
          warnItem: `"${this.currentTemplate.name}"`,
        });
        if (ok) {
          await this.deleteMessageTemplates({
            type: this.type,
            id: this.currentTemplate.id,
          });
          await this.getMessageTemplates();
          this.templateName = "";
          this.$emit("update:templateText", "");
        }
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    onTemplateNameInput(value) {
      this.templateName = value;
    },
    onDropdownAddNewTemplate() {
      this.templateId = 0;
      this.templateName = "";
      this.$emit("update:templateText", "");
    },
  },
};
</script>

<template>
  <form @submit.prevent="saveNewTemplate" class="org-message-templates">
    <b-input-group class="org-message-templates__input-group">
      <b-form-input
        name="template-name"
        id="template-name"
        autocomplete="on"
        :placeholder="
          $t('account.message_template_input_placeholder', 'Type to enter template name...')
        "
        required
        @input="onTemplateNameInput"
        :value="templateName"
      />
      <b-input-group-append class="org-message-templates__group-append">
        <b-button
          class="flex flex-row align-center justify-center"
          variant="c-primary"
          type="submit"
          :disabled="loading"
        >
          <b-icon-save class="mx-1" />
          <span class="mx-1" v-if="currentTemplate">
            {{ $t("account.message_template_save", "Save template") }}
          </span>
          <span class="mx-1" v-else>
            {{ $t("account.message_template_save", "Add new template") }}
          </span>
        </b-button>
        <b-button
          v-if="currentTemplate"
          class="flex flex-row align-center justify-center"
          variant="c-secondary"
          type="button"
          @click="deleteTemplate"
          :disabled="loading"
          :aria-label="$t('account.delete_changes', 'Delete')"
        >
          <b-icon-trash class="mx-1" />
          <span class="mx-1">
            {{ $t("account.message_template_delete", "Delete") }}
          </span>
        </b-button>
        <b-dropdown
          :disabled="loading"
          class="org-message-templates__dropdown"
          text=""
          variant="c-secondary"
          menu-class="org-message-templates__dropdown-menu"
        >
          <template v-if="messageTemplatesList.length > 0">
            <b-dropdown-item-button @click="onDropdownAddNewTemplate">
              {{ $t("account.message_template_save", "Add new template") }}
            </b-dropdown-item-button>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-group :header="$t('account.message_template_dropdown_group', 'Templates')">
              <b-dropdown-item
                @click="chooseTemplate(t)"
                v-for="(t, i) in messageTemplatesList"
                :key="i"
                :active="t.id === templateId"
              >
                {{ t.name }}
              </b-dropdown-item>
            </b-dropdown-group>
          </template>
          <template v-else>
            <b-dropdown-item disabled>
              {{ $t("account.no_templates", "No templates") }}
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </b-input-group-append>
    </b-input-group>
  </form>
</template>

<style lang="scss" scoped>
.org-message-templates {
  &__group-append {
    position: initial;
  }
  &__dropdown {
    position: initial;
  }
  ::v-deep &__dropdown-menu {
    --_offset: 4%;
    --_width: 100%;
    transform: translate3d(calc(var(--_offset) / -2), 50px, 0px) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    width: calc(var(--_width) + var(--_offset));
  }
  &__input-group {
    position: relative;
  }
}
</style>
