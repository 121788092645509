<template>
  <div>
    <div class="campaign-edit-title">
      <h2 class="h2-like">{{ $t("edit_campaign.streams_title") }}</h2>
      <div class="btn-wrap">
        <stream-modal-form ref="modalStream"></stream-modal-form>
      </div>
    </div>

    <section class="matchers_page">
      <div class="row" v-if="streamsList.length > 0">
        <div class="col-12 col-lg-6" v-for="stream in streamsList" :key="stream.id">
          <stream-plate
            :loading="load"
            :stream="stream"
            @edit-stream="editStream"
            @delete-stream="deleteStream"
          />
        </div>
        <div v-if="streamsList.length == 0" class="empty-result-message">
          {{ $t("edit_campaign.donation_stream_empty") }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import streamPlate from "./campaign-stream-plate.vue";
import streamModalForm from "./stream-modal-form.vue";
import { notificationsMixin } from "@/mixins";

export default Vue.extend({
  components: {
    streamPlate,
    streamModalForm,
  },
  mixins: [notificationsMixin],
  data() {
    return {
      streamsList: [],
      load: false,
      errors: [],
      extend: `?${[
        "extend=campaign_stats",
        "extend=content",
        "extend=media",
        "extend=matchers",
        "extend=donation_levels",
        "extend=donation_streams",
      ].join("&")}`,
    };
  },

  computed: mapState({
    streams: (state) => {
      let included = state.campaign.campaign.included;

      let relationships = state.campaign.campaign.data.relationships.donation_stream.data;

      let arrStreams = [];

      relationships.forEach((relationship) => {
        let stream = included.find(
          (el) => el.id == relationship.id && el.type == relationship.type
        );

        try {
          stream.attributes.meta = JSON.parse(stream.attributes.meta || "{}");
        } catch {
          stream.attributes.meta = {};
        }

        arrStreams.push(stream);
      });
      return arrStreams;
    },

    campaignId: (state) => state.campaign.campaign.data.id,
    orgId: () => localStorage.getItem("orgId"),
  }),

  methods: {
    editStream(stream) {
      this.$refs.modalStream.openModalEdit(stream);
    },

    deleteStream(stream) {
      this.errors = [];

      let message = this.$t("edit_campaign.confirm_delete_donation_stream");

      let options = {
        okText: this.$t("edit_campaign.confirm_delete"),
        cancelText: this.$t("edit_campaign.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.load = true;

        axios
          .delete(
            urls.apiPutDonationStream
              .replace(":orgId", this.orgId)
              .replace(":campaignId", this.campaignId)
              .replace(":donationstreamId", stream.id)
          )
          .then(() => {
            this.load = false;

            this.$store.dispatch("updateCampaign", this.extend);

            this.$_notificationsMixin_makeToast(
              "Success",
              this.$t("edit_campaign.success_delete_donation_streams")
            );
          })
          .catch((e) => {
            this.load = false;

            if (e.response.data) {
              if (e.response.data.error) {
                this.errors.push(e.response.data.error);
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          });
      });
    },
  },

  watch: {
    streams() {
      this.streamsList = this.streams;
    },
  },

  mounted() {
    this.$store.dispatch("updateCampaign", this.extend);
    this.streamsList = this.streams;
  },
});
</script>

<style lang="scss" scoped>
.matchers_page {
  padding: 0px;
  &_search {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    position: relative;
    > svg {
      fill: gray;
      position: absolute;
      left: 0;
      top: calc(50% - 12px);
    }
    > input {
      width: 100%;
      border-left: none;
      border-right: none;
      border-top: none;
      border-radius: 0;
      padding-left: 30px;
    }
  }
}
</style>
