<template>
  <div class="matcher-plate">
    <div class="matcher-plate__header">
      <div class="matcher-plate__image">
        <b-img rounded="circle" alt="Circle image"></b-img>
        <img />
      </div>

      <div class="matcher-plate__data">
        <div class="d-flex">
          <h2 class="matcher-plate__title col-8">{{}}</h2>
          <h2 class="matcher-plate__title text-right col-4">${{}}</h2>
        </div>
        <p>{{}}</p>

        <div class="matcher-plate__row--data">
          <div class="matcher-plate__type">
            {{ $t("edit_campaign.donation_level_color") }}
          </div>
          <span class="level-plate__color" :style="{}"></span>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="align-items-end d-flex p-2">
        <!-- <div class="matcher-plate__type">Order: {{level.order}}</div> -->
      </div>
      <div class="matcher-plate__action ml-auto p-2">
        <button :disabled="!hasAccess" class="bttn bttn-round">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          <!-- {{ $t('edit_campaign.edit') }} -->
        </button>

        <button :disabled="!hasAccess" class="bttn bttn-round">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          <!-- {{ $t('edit_campaign.delete') }} -->
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["level"],

  computed: mapState({
    hasAccess: (state) => state.account.access.CanEditCampaignDonation,
  }),

  methods: {},
};
</script>

<style lang="scss" scoped>
.level-plate {
  &__color {
    margin-left: 20px;
    width: 3rem;
    height: 20px;
  }
}
</style>
