<template>
  <div id="reminder">
    <category-title :title="$t('leads.reminder_list')" withActions>
      <button
        v-if="teamSMSMessaging"
        class="button-export bttn bttn--sm bttn--orange mx-2"
        type="button"
        @click="sendSMS"
        name="button"
      >
        {{ $t("leads.send_sms", "Send SMS") }}
      </button>

      <button
        class="button-export bttn bttn--sm bttn--orange mx-2"
        type="button"
        @click="importList"
        name="button"
      >
        {{ $t("leads.import_list", "Import leads") }}
      </button>

      <button
        class="button-export bttn bttn--sm bttn--orange"
        type="button"
        @click="exportList"
        name="button"
      >
        {{ $t("leads.export_list") }}
      </button>
    </category-title>

    <section class="donors_page leads" v-if="!loading && !loadingCampaigns">
      <div class="reminder-list-wrapper">
        <div class="reminders-block">
          <div class="reminder-filter-block">
            <app-search-input
              :placeholder="$t('leads.search_remind')"
              v-model="searchValue"
              value=""
            />
            <app-select
              v-model.number="campaignId"
              :searchable="false"
              track-by="id"
              label="title"
              :allow-empty="false"
              :select-label="$t('leads.search_campaign_select_label', 'Press enter to select')"
              :deselect-label="$t('leads.search_campaign_deselect_label', 'Press enter to remove')"
              :selected-label="$t('leads.search_campaign_selected_label', 'Selected')"
              :options="selectOptions"
            />
            <!--
            <div class="select">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>

              <select class="select__select" v-model.number='campaignId'>
                <option :value="0">{{ $t('leads.all') }}</option>
                <option v-for='(campaign, idx) in campaigns' :value="campaign.id" :key="idx">
                  {{ campaign.attributes.title }}
                </option>
              </select>
            </div>
            -->
          </div>
          <reminder-block-header />
        </div>

        <div v-if="reminder.length > 0">
          <reminder-unit
            v-for="remind in reminder"
            @viewReminderDetails="viewReminder"
            :remind="remind"
            :campaigns="campaigns"
            :key="remind.id"
          />
        </div>

        <div class="result-empty" v-if="reminder.length == 0">
          <div class="result-empty__message">{{ $t("leads.no_leads_found") }}</div>
        </div>
      </div>

      <b-pagination
        v-if="reminderCount > 50"
        v-model="currentPage"
        size="md"
        :total-rows="reminderCount"
        :per-page="50"
      />

      <modal-view ref="modalView" />
      <import-modal ref="importModal" />
      <sms-message-modal ref="smsMessageModal" />
    </section>

    <div v-else class="loading">
      <app-spinner />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import debounce from "lodash/debounce";
import { mapState } from "vuex";

import urls from "../../constants/urls.js";

import viewReminder from "./viewReminder.vue";
import reminderUnit from "./reminder-unit.vue";
import importModal from "./import-modal.vue";
import smsMessageModal from "./smsMessageModal.vue";

import appSpinner from "@/common-components/ui-elements/app-spinner.vue";
import appSearchInput from "@/common-components/ui-elements/app-search-input.vue";
import categoryTitle from "@/common-components/ui-elements/category-title.vue";
import reminderBlockHeader from "./reminder-block-header.vue";
import appSelect from "@/common-components/ui-elements/app-select.vue";

export default {
  data: function () {
    return {
      currentPage: 1,
      searchValue: "",
      campaignId: { id: 0, title: this.$t("leads.reminder_list_all", "All") },
    };
  },

  created() {
    let props = `?limit=50&page=1&q=`;
    this.$store.dispatch("getReminder", props);
    this.$store.dispatch("getCampaigns");
  },

  components: {
    "modal-view": viewReminder,
    categoryTitle,
    appSpinner,
    reminderBlockHeader,
    reminderUnit,
    appSearchInput,
    appSelect,
    importModal,
    smsMessageModal,
  },

  computed: {
    ...mapState({
      loading: (state) => state.reminder.loadingReminder,
      loadingCampaigns: (state) => state.campaigns.loadingCampaigns,
      campaigns: (state) => state.campaigns.campaigns,
      reminder: (state) => state.reminder.reminder,
      reminderCount: (state) => state.reminder.reminderCount,
      teamSMSMessaging: (state) => state.account.teamSMSMessaging,
    }),
    selectOptions() {
      const all = {
        id: 0,
        title: this.$t("leads.reminder_list_all", "All"),
      };
      const arr = this.campaigns.map((item) => ({
        id: parseInt(item.id),
        title: item.attributes.title,
      }));
      return [all, ...arr];
    },
  },

  watch: {
    currentPage: function () {
      this.loadNewPageReminder();
    },
    searchValue: function () {
      this.changeSearchQ();
    },
    campaignId: {
      deep: true,
      handler: function () {
        this.loadNewPageReminder();
      },
    },
  },

  methods: {
    changeSearchQ: debounce(function () {
      this.loadNewPageReminder();

      this.currentPage = 1;
    }, 600),

    importList() {
      this.$refs.importModal.openModal();
    },

    sendSMS() {
      this.$refs.smsMessageModal.open();
    },

    exportList() {
      let orgId = localStorage.getItem("orgId");

      let params =
        this.campaignId > 0 ? `?format=xlsx&campaign_id=${this.campaignId}` : `?format=xlsx`;

      axios
        .get(urls.getExportReminderList.replace(":orgId", orgId) + params)
        .then((response) => {
          document.location.href = `${response.data.link}`;
        })
        .catch((e) => {
          console.log(e.response);
        });
    },

    viewReminder(item) {
      this.$refs.modalView.openEditModal(item);
    },

    loadNewPageReminder: function () {
      let props;

      if (this.campaignId.id > 0) {
        props = `?limit=50&page=${this.currentPage}&campaign_id=${this.campaignId.id}&q=${this.searchValue}`;
      } else {
        props = `?limit=50&page=${this.currentPage}&q=${this.searchValue}`;
      }

      this.$store.dispatch("getReminder", props);
    },
  },
};
</script>

<style lang="scss" scoped>
.reminders-block {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: $shadow-th;
  position: relative;
  z-index: 1;
  @media only screen and (min-width: 468px) {
    padding: 20px 40px;
  }
}

.reminder-filter-block {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  @media only screen and (min-width: 670px) {
    flex-wrap: nowrap;
  }
  @media only screen and (min-width: 1024px) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $bg-100;
  }

  ::v-deep .app-search {
    margin-bottom: 10px;
    @media only screen and (min-width: 670px) {
      max-width: 290px;
      margin-right: 10px;
      margin-bottom: initial;
    }
  }

  ::v-deep .app-select {
    @media only screen and (min-width: 670px) {
      max-width: 290px;
    }
  }
}
#app.he {
  .reminder-filter-block {
    ::v-deep .app-search {
      @media only screen and (min-width: 670px) {
        margin-right: initial;
        margin-left: 10px;
      }
    }
  }
}
</style>
