<template>
  <div class="account-card__add-language">
    <form>
      <ul>
        <li v-for="(orgLang, orgLangIndex) in orgLanguages" :key="orgLangIndex" class="li">
          <div class="account-card__add-language-li">
            <div class="account-card__add-language-li__input">
              <b-form-input
                :class="{ 'error-input': orgLang.error }"
                id="input-1"
                v-model="orgLang.value"
                type="text"
                :placeholder="$t('account.org_name', 'Organization name')"
                :disabled="!hasAccess"
                @input="(e) => handleInputChange(e, orgLang, orgLangIndex)"
              ></b-form-input>
            </div>
            <b-dropdown
              id="ddown1"
              :text="orgLang.name"
              class="dropdown account-card__add-language-li__dropdown"
              variant="-sm bttn--bordered-blue"
              :disabled="!hasAccess"
            >
              <b-dropdown-item
                v-for="(lang, index) in languages"
                :key="index"
                :disabled="!hasAccess"
                @click="(event) => handleLangChange(lang, orgLangIndex)"
                >{{ lang.attributes.name }}</b-dropdown-item
              >
            </b-dropdown>

            <div class="account-card__add-language-li__button">
              <button
                type="button"
                :disabled="!hasAccess"
                class="bttn-round btn-remove-contact"
                @click="deleteLang(orgLangIndex)"
                name="button"
              >
                <b-icon-x variant="danger" font-scale="1.5" role="button" />
              </button>
            </div>
          </div>
          <div v-show="orgLang.error" class="error-message">
            {{ orgLang.error }}
          </div>
        </li>
      </ul>
    </form>
    <div class="account-card__add-language-buttons">
      <button
        @click="addLanguage()"
        :disabled="!hasAccess"
        type="button"
        class="bttn-bordered bttn-bordered--sm add-lang"
      >
        {{ $t("account.add_language", "Add organization language") }}
      </button>
      <button
        class="bttn bttn--sm bttn--orange"
        :disabled="!hasAccess"
        @click="onSave"
        type="button"
        v-if="dirty"
      >
        {{ $t("account.save_changes", "Save") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getCookie } from "@/helpers/cookie.js";
import { cloneDeep } from "@/helpers/cloneDeep";
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],
  props: {
    hasAccess: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orgLanguages: [],
      userLang: "",
      dirty: false,
    };
  },
  mounted() {
    this.userLang = getCookie("language");
  },
  computed: {
    ...mapState({
      languages: (state) => state.account.languages,
      orgLanguagesList: (state) => state.account.account.data.attributes.org_name_languages,
    }),
    initialLang: () => (this.userLang != "" ? this.userLang : "en"),
  },
  watch: {
    orgLanguagesList: {
      handler(val) {
        this.orgLanguages = cloneDeep(val);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      saveOrgLanguages: "saveOrgNameLanguages",
    }),
    handleLangChange(lang, orgLangIndex) {
      // validate that langName does not exist in this.orgLanguages
      let duplicate = false;
      for (let ol of this.orgLanguages) {
        if (ol.name === lang.attributes.name) {
          duplicate = true;
          break;
        }
      }

      if (duplicate) {
        this.$_notificationsMixin_makeErrorToast(
          this.$t("account.error", "Error"),
          this.$t("account.language_already_exists", "This language already exists")
        );
        return;
      }

      this.orgLanguages[orgLangIndex].name = lang.attributes.name;
      this.orgLanguages[orgLangIndex].code = lang.attributes.code;
    },
    validateInputs() {
      let valid = true;
      for (let i = 0; i < this.orgLanguages.length; i++) {
        if (this.orgLanguages[i].value?.trim() === "") {
          this.orgLanguages[i].error = this.$t(
            "account.empty_input_error",
            "Field cannot be empty"
          );

          valid = false;
        } else if (this.orgLanguages[i].value.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g)) {
          this.orgLanguages[i].error = this.$t(
            "account.emoji_error",
            "Field cannot contain emojis"
          );

          valid = false;
        } else if (this.orgLanguages[i].value.length > 50) {
          this.orgLanguages[i].error = this.$t(
            "account.org_name_length_error",
            "Organization name cannot be larger than 50 symbols"
          );

          valid = false;
        } else {
          this.orgLanguages[i].error = "";
        }
      }

      return valid;
    },
    addLanguage() {
      // get the lang based on getCookie code if it does not exist or if it was already picked
      // then get first language that is not in the orgLanguages
      let lang = this.languages.find((l) => l.attributes.code === this.userLang);

      let duplicate = false;
      if (lang) {
        for (let ol of this.orgLanguages) {
          console.log(this.orgLanguages);
          if (ol.name === lang.attributes.name) {
            duplicate = true;
            break;
          }
        }
      }

      if (duplicate || !lang) {
        lang = this.languages.find(
          (l) => !this.orgLanguages.find((ol) => ol.name === l.attributes.name)
        );
      }

      if (!lang) {
        this.$_notificationsMixin_makeErrorToast(
          this.$t("account.error", "Error"),
          this.$t("account.all_languages_added", "All languages has been already added")
        );
        return;
      }

      this.orgLanguages = [
        ...this.orgLanguages,
        {
          code: lang.attributes.code,
          name: lang.attributes.name,
          value: "",
          error: null,
        },
      ];
      this.dirty = true;
    },
    deleteLang(langIndex) {
      this.orgLanguages.splice(langIndex, 1);
      this.dirty = true;
    },
    handleInputChange(value) {
      if (this.orgLang.value !== value) {
        this.dirty = true;
      }
      this.validateInputs();
    },
    onSave() {
      const valid = this.validateInputs();
      if (!valid) {
        return;
      }

      const orgLangs = this.orgLanguages.map((orgLang) => {
        return {
          code: orgLang.code,
          name: orgLang.name,
          value: orgLang.value,
        };
      });

      const payload = {
        org_name_languages_list: orgLangs,
      };

      this.saveOrgLanguages({ payload })
        .then(() => {
          this.$_notificationsMixin_makeToast(
            "Success!",
            this.$t("account.org_languages_save", "Saved successfully"),
            "success"
          );
          this.dirty = false;
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-lang {
  margin-left: auto;
  margin-right: 10px;
}
.save-btn {
  margin-right: 10px;
}
.li {
  margin-bottom: 0.8rem;
}
.account-card__add-language {
  width: 100%;
}
.account-card__add-language-li {
  display: flex;
  align-items: center;

  .account-card__add-language-li__input {
    flex: 1;
    margin-right: 1rem;
  }
  .account-card__add-language-li__dropdown {
    flex: 0;
    margin-right: 1rem;
    margin-left: 0.5rem;
  }
  .account-card__add-language-li__button {
    flex: 0;
  }
}
.error-message {
  color: red;
  font-size: 0.8rem;
  height: 0.8rem;
}
.error-input {
  border-color: red;
}
.account-card__add-language-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
</style>
