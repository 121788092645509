import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import axios from "axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import InfiniteLoading from "vue-infinite-loading";
import VeeValidate from "vee-validate";
import Datetime from "vue-datetime";
import "@/helpers/validator";
import "./styles/dashboard.scss";
import "vue-datetime/dist/vue-datetime.css";
import "vue-advanced-cropper/dist/style.css";
import "flag-icon-css/sass/flag-icon.scss";
import "vue-tel-input/dist/vue-tel-input.css";
import PortalVue from "portal-vue";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

Vue.config.productionTip = false;

import VueQuillEditor from "./components/vue-quill-editor";
import VueYouTubeEmbed from "vue-youtube-embed";
import { notificationsMixin } from "./mixins";
import veeValidateDictionary from "./constants/vee-validate-dictionary";

if (process.env.NODE_ENV != "development") {
  Bugsnag.start({
    apiKey: "c9f13cc9551a13c5e6b11b4874b107d2",
    plugins: [new BugsnagPluginVue()],
    onError(event) {
      if (event?.originalError?.isAxiosError && event?.originalError?.response?.status === 404) {
        console.log("ignored");
        return false;
      }
    },
  });

  const bugsnagVue = Bugsnag.getPlugin("vue");
  bugsnagVue.installVueErrorHandler(Vue);
}

Vue.use(VueQuillEditor);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(InfiniteLoading);
Vue.use(VeeValidate, {
  events: "input|change",
  errorBagName: "vErrors",
  fieldsBagName: "vFields",
  locale: "en",
  dictionary: veeValidateDictionary,
});
Vue.use(Datetime);
Vue.use(PortalVue);

// stk
Vue.prototype.$t = function (...props) {
  const result = this.$i18n.translate(...props);
  const queryParams = new URLSearchParams(location.search);
  const stc = queryParams.get("stk") === "1";
  const logtk = queryParams.get("logtk") === "1";

  if (logtk) {
    let logtkSession;
    try {
      logtkSession = JSON.parse(sessionStorage.getItem("__logtk") || "[]");
    } catch {
      logtkSession = [];
    }

    const [group, ...restProps] = props[0].split(".");
    const key = restProps.join(".");
    const tkObj = {
      group,
      key,
      original_translation_key: props[0],
      english: result,
    };
    // eslint-disable-next-line no-console
    console.groupCollapsed(`${group} | ${key} | ${result}`);
    // eslint-disable-next-line no-console
    console.table(tkObj);
    // eslint-disable-next-line no-console
    console.groupEnd();

    try {
      logtkSession = [
        ...new Map(
          [...logtkSession, tkObj].map((item) => [item.original_translation_key, item])
        ).values(),
      ];
      sessionStorage.setItem("__logtk", JSON.stringify(logtkSession));
      window.__LOGTK = logtkSession;
    } catch {
      // eslint-disable-next-line no-underscore-dangle
      window.__LOGTK = logtkSession;
    }
  }

  if (result === "" || stc) {
    return props[0];
  }
  return result;
};

Vue.use(VueYouTubeEmbed, { global: true, componentId: "youtube-media" });

if (localStorage.getItem("jwt_token")) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("jwt_token");
}

Vue.mixin(notificationsMixin);

new Vue({
  router,
  store,
  render: (h) => h(App),

  created: function () {
    // keep axios header up to date to the Token
    store.subscribe((mutation) => {
      if (mutation.type == "setUser") {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("jwt_token");
      }
    });
  },
}).$mount("#app");
