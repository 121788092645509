<template>
  <div>
    <b-modal
      id="details-modal"
      hide-footer
      centered
      scrollable
      size="lg"
      content-class="modal-content-dashboard"
    >
      <template #modal-header>
        <h2>
          {{ modalTitle }}
        </h2>
        <div class="modal-header__hide" @click.prevent="close">
          <b-icon-x scale="2" />
        </div>
      </template>

      <div class="py-3">
        <b-list-group>
          <b-row>
            <b-col v-for="[k, v] in dataEntries" :key="k" cols="12" md="6">
              <slot :name="`item(${k})`" :field="k" :value="v">
                <b-list-group-item class="h-100">
                  {{ $t(getTranslationKey(k), `${k}: `) }} {{ getValue(k, v) }}
                </b-list-group-item>
              </slot>
            </b-col>
          </b-row>
        </b-list-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: null,
    };
  },

  computed: {
    valueFormatters() {
      return this.options?.valueFormatters || {};
    },

    modalTitle() {
      return this.options?.modalTitle || "";
    },

    translationKeyPrefix() {
      return this.options?.translationKey || "";
    },

    excludedFields() {
      return this.options?.excludedFields || [];
    },

    dataEntries() {
      return Object.entries(this.options?.data || {}).filter(
        ([k]) => !this.excludedFields.includes(k)
      );
    },
  },

  created() {
    this.$root.$on("cahridy::details::show::modal", this.open);
    this.$root.$on("cahridy::details::hide::modal", this.close);
  },

  beforeDestroy() {
    this.$root.$off("cahridy::details::show::modal", this.open);
    this.$root.$off("cahridy::details::hide::modal", this.close);
  },

  methods: {
    getValue(k, v) {
      const formatHandler = this.valueFormatters?.[k];

      if (formatHandler) {
        return formatHandler(v);
      }

      return v;
    },

    getTranslationKey(k) {
      if (typeof this.translationKey === "function") {
        return this.translationKey(k);
      }

      return `${this.translationKey}_${k}`;
    },

    open(opt) {
      this.options = opt;
      this.$bvModal.show("details-modal");
    },

    close() {
      this.options = null;
      this.$bvModal.hide("details-modal");
    },
  },
};
</script>
