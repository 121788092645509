<template>
  <div>
    <slot name="btn" :loadMore="loadMore" :hasMore="hasMore"></slot>
  </div>
</template>

<script>
export default {
  props: {
    totalRecords: {
      type: Number,
      default: 0,
    },
    value: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },

  computed: {
    hasMore() {
      return this.value * this.perPage < this.totalRecords;
    },
  },

  methods: {
    loadMore() {
      if (this.hasMore) {
        this.$emit("input", this.value + 1);
      }
    },
  },
};
</script>
