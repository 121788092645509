import urls from "../../constants/urls.js";
import axios from "axios";

const state = {
  video: [],
  loadingVideo: true,
};

const mutations = {
  setVideo(state, data) {
    state.video = data;
  },

  setLoadingVideo(state, value) {
    state.loadingVideo = value;
  },
};

const getters = {};

const actions = {
  getVideo({ commit }) {
    const orgId = localStorage.getItem("orgId");

    const params = {
      org_id: orgId,
    };

    axios
      .get(urls.getVideo.replace(":oid", orgId), { params })
      .then((response) => {
        let video = response.data;
        commit("setVideo", video);
        commit("setLoadingVideo", false);
      })
      .catch(() => {
        commit("setLoadingVideo", false);
      });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
