<template>
  <div class="dashboard-block chart">
    <h2 class="dashboard-block__title">Traffic</h2>

    <div class="dashboard-block__subtitle">Numbers of page views</div>

    <div class="line-chart">
      <canvas id="lineCahrt"></canvas>
    </div>
  </div>
</template>

<script type="text/javascript">
import { mapState } from "vuex";

export default {
  data() {
    return {
      chart: null,
    };
  },

  computed: mapState({}),

  methods: {},
};
</script>
