<template>
  <div class="design-templates-wrapper">
    <div v-if="activetab === 1">
      <div class="desc">
        <p>
          {{ canva.description }}
        </p>
        <div class="list-row">
          <strong>Availability:</strong>
          <span>Raising $25K-$75K on day of campaign</span>
        </div>
        <div class="list-row">
          <strong>Deliverables:</strong>
          <span
            >Eight email headers, eight social media posts, campaign page sliders, campaign page
            countdown banner (web/mobile), ﬂyer 8.5x11, social media share image</span
          >
        </div>
        <div class="list-row premium" v-if="false">
          <i class="premium-icon">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1561113597489940819.png"
              alt
            />
          </i>
          <strong>Additional premium deliverables:</strong>
          <span>Flyer 11x17, testimonial posts, advertisements, profile picture overlay</span>
        </div>
        <div class="list-row">
          <strong>Customizable:</strong>
          <span>Pictures and logo</span>
        </div>
        <div class="list-row">
          <strong>Rounds of edits:</strong>
          <span>One</span>
        </div>
      </div>

      <div class="filter-box">
        <b-dropdown variant="-lg bttn--bordered">
          <template slot="button-content">
            <span>Categories: {{ startCategories }}</span>
          </template>
          <b-dropdown-item
            placeholder="Select a subject"
            v-for="categorie in canva.categories"
            :key="categorie.i"
            @click="startCategories = categorie"
          >
            {{ categorie }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div>
        <div class="slider-box">
          <div class="swiper-box">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide class="swiper-item" v-for="(template, i) in selectedTemplates" :key="i">
                <div class="slider-title">
                  <h4>{{ template.title }}</h4>
                </div>
                <img :src="template.small_preview_img" />
                <div class="slider-btn-wrap">
                  <a
                    v-if="hasAccess"
                    :href="template.link"
                    target="_blank"
                    class="bttn bttn--lg bttn--blue"
                  >
                    <i></i>Choose Template #{{ template.id }}
                  </a>
                </div>
              </swiper-slide>
            </swiper>
            <div class="bttn-round slide-controls prev">
              <span>
                <svg viewBox="0 0 477.175 477.175">
                  <path
                    d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"
                  ></path>
                </svg>
              </span>
              <p>view previous template</p>
            </div>

            <div class="bttn-round slide-controls next">
              <span>
                <svg viewBox="0 0 129 129">
                  <path
                    d="m40.4,121.3c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2c-1.6-1.6-1.6-4.2 0-5.8l51-51-51-51c-1.6-1.6-1.6-4.2 0-5.8 1.6-1.6 4.2-1.6 5.8,0l53.9,53.9c1.6,1.6 1.6,4.2 0,5.8l-53.9,53.9z"
                  ></path>
                </svg>
              </span>
              <p>view next template</p>
            </div>
          </div>
        </div>

        <div class="content-box">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide class="swiper-item" v-for="(template, i) in selectedTemplates" :key="i">
              <img :src="template.large_preview_image" />
              <div class="slider-btn-wrap">
                <a
                  v-if="hasAccess"
                  :href="template.link"
                  target="_blank"
                  class="bttn bttn--lg bttn--blue"
                >
                  <i></i>Choose Template #{{ template.id }}
                </a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    activetab: Number,
  },
  data: function () {
    return {
      swiperOptions: {
        slidesPerView: 1,
        navigation: {
          nextEl: ".next",
          prevEl: ".prev",
          disabledClass: "disabled",
        },
      },

      startCategories: "See All",

      canva: {
        description:
          "Using the revolutionary Canva.com platform, your marketing material is designed and personalized by our in-house designers, giving you the flexibility for continuous editing throughout the campaign process.",
        categories: ["Staff Picks", "Classic", "Elegant", "Modern", "Flashy", "See All"],
        templatesStaffPicks: [
          {
            id: "3",
            title: "STAFF PICKS TEMPLATE #3",
            categories: ["Staff Picks", "Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487851143085366.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487895860699025.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=3",
          },
          {
            id: "6",
            title: "STAFF PICKS TEMPLATE #6",
            categories: ["Staff Picks", "Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490055002802597.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490081036022369.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=6",
          },
          {
            id: "11",
            title: "STAFF PICKS TEMPLATE #11",
            categories: ["Staff Picks", "Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490519477369053.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490540535794407.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=11",
          },
          {
            id: "12",
            title: "STAFF PICKS TEMPLATE #12",
            categories: ["Staff Picks", "Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490583089117868.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490615808677304.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=12",
          },
          {
            id: "18",
            title: "STAFF PICKS TEMPLATE #18",
            categories: ["Classic", "Staff Picks"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491057244226029.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491075254692610.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=18",
          },
        ],
        templatesClassic: [
          {
            id: "1",
            title: "CLASSIC TEMPLATE #1",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487331042957673.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487626704298052.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=1",
          },
          {
            id: "4",
            title: "CLASSIC TEMPLATE #4",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487975170401428.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566488047809567653.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=4",
          },
          {
            id: "8",
            title: "CLASSIC TEMPLATE #8",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490282762753063.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490310356768251.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=8",
          },
          {
            id: "10",
            title: "CLASSIC TEMPLATE #10",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490452017226744.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490475309998513.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=10",
          },
          {
            id: "13",
            title: "CLASSIC TEMPLATE #13",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490672545652758.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490698208771764.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=13",
          },
          {
            id: "15",
            title: "CLASSIC TEMPLATE #15",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490792329877358.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490812312793873.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=15",
          },
          {
            id: "18",
            title: "CLASSIC TEMPLATE #18",
            categories: ["Classic", "Staff Picks"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491057244226029.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491075254692610.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=18",
          },
          {
            id: "19",
            title: "CLASSIC TEMPLATE #19",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491116306335936.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491135481829666.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=19",
          },
          {
            id: "23",
            title: "CLASSIC TEMPLATE #23",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491387756292769.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491408730309956.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=23",
          },
        ],
        templatesElegant: [
          {
            id: "11",
            title: "ELEGANT TEMPLATE #11",
            categories: ["Staff Picks", "Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490519477369053.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490540535794407.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=11",
          },
          {
            id: "12",
            title: "ELEGANT TEMPLATE #12",
            categories: ["Staff Picks", "Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490583089117868.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490615808677304.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=12",
          },
          {
            id: "14",
            title: "ELEGANT TEMPLATE #14",
            categories: ["Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490735700353465.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490752827254588.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=14",
          },
          {
            id: "17",
            title: "ELEGANT TEMPLATE #17",
            categories: ["Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490964822594066.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491002701390302.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=17",
          },
        ],
        templatesModern: [
          {
            id: "2",
            title: "MODERN TEMPLATE #2",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487734324391766.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487793735039894.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=2",
          },
          {
            id: "3",
            title: "MODERN TEMPLATE #3",
            categories: ["Staff Picks", "Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487851143085366.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487895860699025.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=3",
          },
          {
            id: "6",
            title: "MODERN TEMPLATE #6",
            categories: ["Staff Picks", "Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490055002802597.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490081036022369.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=6",
          },
          {
            id: "7",
            title: "MODERN TEMPLATE #7",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490122317549014.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491830351703789.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=7",
          },
          {
            id: "16",
            title: "MODERN TEMPLATE #16",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490855535777091.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490873449459944.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=16",
          },
          {
            id: "20",
            title: "MODERN TEMPLATE #20",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491172606400577.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491188708723156.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=20",
          },
          {
            id: "22",
            title: "MODERN TEMPLATE #22",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491294711481788.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491311439896136.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=22",
          },
        ],
        templatesFlashy: [
          {
            id: "5",
            title: "FLASHY TEMPLATE #5",
            categories: ["Flashy"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566488149138837894.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566488229118598447.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=5",
          },
          {
            id: "9",
            title: "FLASHY TEMPLATE #9",
            categories: ["Flashy"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490385525910127.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490408112687689.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=9",
          },
          {
            id: "21",
            title: "FLASHY TEMPLATE #21",
            categories: ["Flashy"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491241779854728.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491262495382455.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=21",
          },
        ],
        templatesSeeAll: [
          {
            id: "1",
            title: "CANVA TEMPLATE #1",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487331042957673.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487626704298052.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=1",
          },
          {
            id: "2",
            title: "CANVA TEMPLATE #2",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487734324391766.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487793735039894.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=2",
          },
          {
            id: "3",
            title: "CANVA TEMPLATE #3",
            categories: ["Staff Picks", "Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487851143085366.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487895860699025.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=3",
          },
          {
            id: "4",
            title: "CANVA TEMPLATE #4",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566487975170401428.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566488047809567653.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=4",
          },
          {
            id: "5",
            title: "CANVA TEMPLATE #5",
            categories: ["Flashy"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566488149138837894.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566488229118598447.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=5",
          },
          {
            id: "6",
            title: "CANVA TEMPLATE #6",
            categories: ["Staff Picks", "Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490055002802597.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490081036022369.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=6",
          },
          {
            id: "7",
            title: "CANVA TEMPLATE #7",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490122317549014.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491830351703789.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=7",
          },
          {
            id: "8",
            title: "CANVA TEMPLATE #8",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490282762753063.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490310356768251.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=8",
          },
          {
            id: "9",
            title: "CANVA TEMPLATE #9",
            categories: ["Flashy"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490385525910127.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490408112687689.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=9",
          },
          {
            id: "10",
            title: "CANVA TEMPLATE #10",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490452017226744.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490475309998513.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=10",
          },
          {
            id: "11",
            title: "CANVA TEMPLATE #11",
            categories: ["Staff Picks", "Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490519477369053.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490540535794407.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=11",
          },
          {
            id: "12",
            title: "CANVA TEMPLATE #12",
            categories: ["Staff Picks", "Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490583089117868.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490615808677304.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=12",
          },
          {
            id: "13",
            title: "CANVA TEMPLATE #13",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490672545652758.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490698208771764.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=13",
          },
          {
            id: "14",
            title: "CANVA TEMPLATE #14",
            categories: ["Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490735700353465.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490752827254588.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=14",
          },
          {
            id: "15",
            title: "CANVA TEMPLATE #15",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490792329877358.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490812312793873.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=15",
          },
          {
            id: "16",
            title: "CANVA TEMPLATE #16",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490855535777091.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490873449459944.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=16",
          },
          {
            id: "17",
            title: "CANVA TEMPLATE #17",
            categories: ["Elegant"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566490964822594066.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491002701390302.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=17",
          },
          {
            id: "18",
            title: "CANVA TEMPLATE #18",
            categories: ["Classic", "Staff Picks"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491057244226029.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491075254692610.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=18",
          },
          {
            id: "19",
            title: "CANVA TEMPLATE #19",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491116306335936.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491135481829666.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=19",
          },
          {
            id: "20",
            title: "CANVA TEMPLATE #20",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491172606400577.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491188708723156.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=20",
          },
          {
            id: "21",
            title: "CANVA TEMPLATE #21",
            categories: ["Flashy"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491241779854728.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491262495382455.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=21",
          },
          {
            id: "22",
            title: "CANVA TEMPLATE #22",
            categories: ["Modern"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491294711481788.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491311439896136.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=22",
          },
          {
            id: "23",
            title: "CANVA TEMPLATE #23",
            categories: ["Classic"],
            small_preview_img:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491387756292769.jpg",
            large_preview_image:
              "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1566491408730309956.jpg",
            link: "https://airtable.com/shrKI29a3vqPLAAAR?prefill_Design%20Template=Canva&prefill_Design%20Number=23",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      hasAccess: (state) => {
        return (
          state.account.canSubmitDesignTemplatesRequest &&
          state.account.access.CanSubmitDesignTemplatesRequest
        );
      },
    }),
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    selectedTemplates() {
      return this.startCategories === "See All"
        ? this.canva.templatesSeeAll
        : this.canva.templatesSeeAll.filter((el) => el.categories.includes(this.startCategories));
    },
  },
  methods: {
    chooseTemplate: function () {
      window.open("https://airtable.com/shrKI29a3vqPLAAAR", "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
.design-templates-wrapper {
  padding: 0 90px;
}
::v-deep .swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

::v-deep .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  align-items: flex-end;
}
::v-deep .swiper-item {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  img {
    width: 100%;
  }
}
.swiper-box {
  position: relative;
  //margin-bottom: 40px;
  //height: 800px;
  .slide-controls.bttn-round {
    position: absolute;
    top: 50%;
    margin-top: -40px;
  }
}
.h1-like {
  font-size: 50px;
}
.slide-controls.bttn-round {
  width: 80px;
  height: 180px;
  left: auto;
  background: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  > img,
  svg {
    fill: #ccc;
    width: 50px;
    cursor: pointer;
  }
  &.prev {
    left: -120px;
  }
  &.next {
    right: -120px;
  }
  &.disabled {
    cursor: initial;
  }
  p {
    margin-top: 30px;
    font-size: 18px;
    color: #ccc;
    pointer-events: none;
    user-select: none;
  }
}
.btns-wrap {
  padding: 20px 20px 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.bttn--xlg {
  height: 64px;
  min-width: 100px;
  width: 30%;
  padding: 0 56px;
  border-radius: 40px;
  font-size: 25px;
  font-family: $vito-light;
  line-height: 1;
  position: relative;
  i {
    position: absolute;
    display: block;
    left: 10px;
    width: 46px;
    height: 46px;
    top: 50%;
    margin-top: -23px;
    img {
      width: 100%;
    }
  }
  &.bttn--bordered {
    border: 1px solid $bb-100;
  }
}
.premium-icon {
  img {
    width: 100%;
  }
}
.desc {
  font-size: 20px;
  padding: 0 5%;
}

.list-row {
  margin-bottom: 30px;
  position: relative;
  strong {
    font-family: $vito-bold;
    font-weight: normal;
  }
  &.premium {
    strong {
      color: #e5b65b;
      i {
      }
    }
  }
  .premium-icon {
    position: absolute;
    width: 46px;
    left: -60px;
    height: 46px;
    top: 50%;
    margin-top: -23px;
  }
}
.filter-box {
  text-align: right;
  margin-bottom: 40px;
  .dropdown {
    text-align: left;
    font-family: $vito-light;
    border-radius: 10px;
    ::v-deep .btn {
      font: 18px/1 $vito-light;
      border: 1px solid #e9e9e8;
      border-radius: 10px;
      &:active,
      &:focus {
        border: 1px solid $bb-100;
      }
      &:after {
        border: none;
        margin-left: 1.25em;
        margin-top: -3px;
        border-right: 1px solid #bbb;
        border-bottom: 1px solid #bbb;
        position: relative;
        transform: rotate(45deg);
        width: 10px;
        height: 10px;
      }
    }
  }
}
.content-box,
.slider-box {
  border-radius: 5px;
  background-color: #ffffff;
  padding: 20px 20px 25px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 80px;
}
.slider-btn-wrap {
  text-align: center;
  margin-top: 50px;
  .bttn {
    font-family: $vito-light;
    font-size: 24px;
    height: 58px;
    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}
.slider-title {
  text-align: center;
  font-family: $vito-bold;
  color: $bb-100;
  //margin-bottom: -15px;
}
.content-box {
  padding: 35px 50px;
  .template-view img {
    width: 100%;
  }
  .flex-row {
    display: flex;
    margin: 0 -15px;
    justify-content: space-between;
    align-items: center;
    .col {
      width: 45%;
    }
  }
}
.content-title {
  text-align: center;
  h5 {
    color: $bb-100;
    text-transform: uppercase;
    margin-bottom: 40px;
    line-height: 80px;
  }
}
.img-container {
  margin-bottom: 40px;
  border: 1px solid #e4e5ea;
  img {
    width: 100%;
    display: block;
  }
}
@media all and (min-width: 1280px) {
  .design-templates-wrapper {
    padding: 0 50px;
  }
}
</style>
