import { render, staticRenderFns } from "./teamsOverview.vue?vue&type=template&id=350ca900&scoped=true&"
import script from "./teamsOverview.vue?vue&type=script&lang=js&"
export * from "./teamsOverview.vue?vue&type=script&lang=js&"
import style0 from "./teamsOverview.vue?vue&type=style&index=0&id=350ca900&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "350ca900",
  null
  
)

export default component.exports