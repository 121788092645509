<template>
  <div v-if="hasAccessAdd && withCID" class="add-cerfa-wrap">
    <button class="add-cerfa-btn" @click="open">
      {{ $t("dashboard.add_button_with_receipt_title", "Add offline donation with receipt") }}
    </button>

    <donation-add-modal
      v-if="cmp"
      ref="addDonation"
      :campaignid="cmp.id"
      :ccy="cmp.attributes.currency"
      hideAddBtn
      withReceipt
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

import donationAddModal from "@/views/dashboard/subcomponents/donationAddModal.vue";
import urls from "@/constants/urls";

export default {
  components: { donationAddModal },

  data() {
    return {
      cmp: null,
    };
  },

  computed: {
    ...mapState({
      hasAccessAdd: (state) => state.account.showCreateOfflineDonationWithReceiptBtn,
      withCID: (state) => state.account.showCreateOfflineDonationWithReceiptCID,
    }),
  },

  methods: {
    open() {
      // this.$router.push("/dashboard");

      const orgId = localStorage.getItem("orgId");

      axios
        .get(urls.getCampaignData.replace(":orgId", orgId).replace(":campaignId", this.withCID))
        .then(({ data }) => {
          this.cmp = data.data;

          this.$store.commit("setSelectedCampaign", this.withCID);

          this.getCampaignData();

          this.$store.dispatch("getTeamsByCampaignId", [this.withCID]);

          this.$nextTick(() => {
            this.$refs.addDonation.openModal();
          });
        });
    },

    getCampaignData() {
      let params = {
        id: this.withCID,
        setting: `?extend=campaign_stats&extend=content&extend=media&extend=meta&extend=matchers`,
      };

      this.$store.dispatch("getCampaignByIdDashboard", params);

      this.$store.dispatch("gatewaysForFilterByCampaign", this.withCID);
    },
  },
};
</script>

<style lang="scss" scoped>
// .add-cerfa-wrap {
//   position: fixed;
//   width: 370px;
//   @media screen and (max-width: 1024px) {
//     display: none;
//     &.open {
//       display: unset;
//     }
//   }
// }
.add-cerfa-btn {
  // position: absolute;
  width: 100%;
  padding: 15px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  background-color: $bc-90;
  border-color: $bc-100;
  color: #ffffff;
  outline: none;
  font-size: 16px;
  &:hover {
    color: #fff;
    font-family: $vito-bold;
    text-decoration: none;
  }
}
</style>
