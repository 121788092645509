const onlyPositive = {
  lazy: true,
  handler: (value) => Math.abs(value),
};

export default {
  title: "MaximumDonationAmount",
  type: "object",
  noValidateChild: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
    },
    currency_to_amount: {
      title: "Currency to amount",
      type: "list-object",
      default: {},
      expanded: true,
      items: {
        type: "object",
        properties: {
          currency: {
            title: "Currency",
            type: "object",
            component: "CurrenciesSelect",
            required: true,
          },
          amount: {
            title: "Amount",
            type: "number",
            default: 1,
            min: 1,
            formatter: onlyPositive,
            required: true,
          },
        },
      },
    },
  },
};
