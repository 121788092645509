import urls from "@/constants/urls";
import axios from "axios";

const state = {
  collection: [],
  loading: {
    fetchRoles: false,
  },
};

const getters = {};

const mutations = {
  SET_COLLECTION(state, data) {
    state.collection = data;
  },
  SET_LOADING(state, [k, v]) {
    state.loading[k] = v;
  },
};

const actions = {
  fetchRoles({ commit }) {
    commit("SET_LOADING", ["fetchRoles", true]);
    return axios
      .get(urls.organizationRoles)
      .then(({ data: { data } }) => {
        commit("SET_COLLECTION", data);
        return data;
      })
      .finally(() => commit("SET_LOADING", ["fetchRoles", false]));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
