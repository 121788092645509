<template>
  <div>
    <div class="campaign-edit-title">
      <h2 class="h2-like">{{ $t("edit_campaign.generalinfo_title") }}</h2>
    </div>

    <ValidationObserver ref="observer" v-slot="{ validate }">
      <b-form id="ExpressCampaignEdit" @submit.prevent="validate().then(onSave)">
        <ValidationProvider rules="no_only_spaces" v-slot="{ valid, errors }">
          <b-form-group :label="$t('edit_campaign.generalinfo_name')">
            <b-form-input
              v-model="form.title"
              required
              :state="valid"
              :placeholder="$t('edit_campaign.generalinfo_name')"
            ></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
              {{ errors[0] ? $t("edit_campaign.generalinfo_title_not_valid") : "" }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <b-row>
          <b-col cols="12" sm="6">
            <ValidationProvider rules="short_link" v-slot="{ valid, errors }">
              <b-form-group :label="$t('edit_campaign.generalinfo_short_link')">
                <b-input-group prepend="CHARIDY.COM/">
                  <b-form-input
                    required
                    v-model="form.short_link"
                    aria-describedby="input-live-feedback"
                    debounce="200"
                    :state="valid && shortLinkValid"
                    :placeholder="$t('edit_campaign.generalinfo_short_link')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback" class="text-right">
                    {{ errors[0] ? $t("edit_campaign.generalinfo_short_link_allowe") : "" }}
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="12" sm="6">
            <b-form-group :label="$t('edit_campaign.generalinfo_currency')">
              <b-form-select v-model="form.currency" :options="currencies"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>

    <b-row>
      <b-btn
        class="bttn bttn--lg bttn--orange bttn--mw255 ml-auto"
        :disabled="sending"
        type="submit"
        form="ExpressCampaignEdit"
      >
        {{ $t("edit_campaign.generalinfo_save") }}
      </b-btn>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      form: {
        title: "",
        short_link: "",
        currency: "usd",
      },
      shortLinkValid: true,
      sending: false,
    };
  },

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    ...mapState({
      cId: (state) => state.campaign.campaign.data.id,
      attrs: (state) => state.campaign.campaign.data.attributes,
      currencies: (state) =>
        state.account.currencyList.map((el) => ({
          value: el.attributes.code.toLowerCase(),
          text: `${el.attributes.title}(${el.attributes.code})`,
        })),
    }),
  },

  watch: {
    "form.short_link"(val) {
      val && this.validateShortLink();
    },
  },

  created() {
    this.presetForm();
  },

  methods: {
    ...mapActions({
      fetchUpdateExpressCampaign: "expressCampaign/fetchUpdateExpressCampaign",
      featchValidationShortLink: "featchValidationShortLink",
    }),

    validateShortLink() {
      const {
        cId,
        form: { short_link: shortLink },
      } = this;

      this.featchValidationShortLink({ cId, shortLink }).then(({ data }) => {
        this.shortLinkValid = data.attributes.available;
      });
    },

    presetForm() {
      Object.entries(this.form).forEach(([key]) => {
        this.form[key] = this.attrs[key];
      });
    },

    updateCampaign() {
      let extend = `?${[
        "extend=campaign_stats",
        "extend=content",
        "extend=media",
        "extend=matchers",
        "extend=donation_levels",
        "extend=donation_streams",
      ].join("&")}`;

      this.$store.dispatch("updateCampaign", extend);
    },

    onSave() {
      if (!this.shortLinkValid) return;

      this.sending = true;
      this.fetchUpdateExpressCampaign([this.cId, this.form])
        .then(() => {
          this.sending = false;
          this.$_notificationsMixin_makeToastCompat({
            group: "express-campaign-edit",
            title: this.$t("edit_campaign.success_edit"),
          });

          this.updateCampaign();
        })
        .catch(() => {
          this.sending = false;
        });
    },
  },
};
</script>
