<template>
  <form action="" class="gateway-form">
    <error :errors="errors"></error>

    <div class="form-block">
      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.legal_entity")
            }}</label>
            <b-form-select required :options="entities" />
          </div>
        </div>

        <div class="crate-legal-entity col-12">
          <p>
            {{ $t("gateways.create_entity_text") }}
          </p>
          <button type="button" class="bttn bttn--sm bttn--orange" name="button">
            {{ $t("gateways.create_entity_button") }}
          </button>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group currency-select">
                <label class="form-group__label form-group__label--required" for="">{{
                  $t("gateways.currency")
                }}</label>
                <b-form-select required>
                  <option value="usd">USD</option>
                  <option value="eur">EUR</option>
                  <option value="gbp">gbp</option>
                  <option value="cad">cad</option>
                  <option value="ils">ils</option>
                  <option value="brl">brl</option>
                  <option value="aud">aud</option>
                  <option value="zar">zar</option>
                  <option value="nzd">nzd</option>
                  <option value="mxn">mxn</option>
                  <option value="chf">chf</option>
                </b-form-select>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label class="form-group__label form-group__label--required" for="">{{
                  $t("gateways.merch_id")
                }}</label>
                <b-form-input required type="text"></b-form-input>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label class="form-group__label form-group__label--required" for="">{{
                  $t("gateways.priv_key")
                }}</label>
                <b-form-input required type="text"></b-form-input>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label class="form-group__label form-group__label--required" for="">{{
                  $t("gateways.pub_key")
                }}</label>
                <b-form-input required type="text"></b-form-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="gateway-form__action">
      <button type="button" :class="entities.length > 0 ? 'bttn bttn--lg bttn--blue' : 'bttn bttn--lg bttn--blue el-right'" @click="$emit('close-modal')" name="button">{{ $t('gateways.close') }}</button>
      <button type="submit" :disabled='sending' v-if='entities.length > 0' :class="sending ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'">{{ $t('gateways.save') }}</button>
    </div> -->
  </form>
</template>

<script>
import { mapState } from "vuex";

import error from "@/components/errors.vue";

export default {
  props: ["entities", "editdata"],

  data() {
    return {
      braintreeData: {
        data: {
          attributes: {},
        },
      },
      errors: [],
      sending: false,
    };
  },

  components: {
    error: error,
  },

  computed: mapState({}),

  methods: {},
};
</script>

<style lang="scss" scoped>
.crate-legal-entity {
  button {
    display: block;
    margin: 20px auto;
  }
}

.alert-danger {
  width: auto;
  margin-left: 0;
  li {
    width: auto;
    margin-left: 0;
  }
}

.currency-select select {
  text-transform: uppercase;
}
</style>
