<template>
  <div class="donor-temporary-page donor-wrapper">
    <download-popup></download-popup>
  </div>
</template>

<script>
import downloadPopup from "./modals/download-popup.vue";

export default {
  components: {
    downloadPopup,
  },
};
</script>

<style lang="scss">
@import "@/styles/_vars.scss";
.donor-wrapper {
  padding-top: 40px;
  min-height: 100vh;
}
</style>
