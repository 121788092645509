import urls from "@/constants/urls";
import axios from "axios";

const orgID = localStorage.getItem("orgId");

export const campaignSettingSetup = () => {
  const createURL = ({ oid, cid }) =>
    urls.campaignSettings.replace(":orgId", oid || orgID).replace(":campaignId", cid);

  const createPayload = ({ id, cid, data, name }) => ({
    data: {
      type: "campaign_meta",
      id: String(id),
      attributes: {
        campaign_id: Number(cid),
        meta_name: name,
        meta_data: JSON.stringify(data),
      },
    },
  });

  const get = ({ oid, cid, params }) => {
    const requestURL = createURL({ oid, cid });

    return axios.get(requestURL, { params }).then((resp) => {
      resp.data.data = resp.data.data.map((el) => {
        try {
          el.attributes.default_meta_value_off = JSON.parse(
            el.attributes.default_meta_value_off || "{}"
          );
        } catch {
          el.attributes.default_meta_value_off = {};
        }
        try {
          el.attributes.default_meta_value_on = JSON.parse(
            el.attributes.default_meta_value_on || "{}"
          );
        } catch {
          el.attributes.default_meta_value_on = {};
        }
        try {
          el.attributes.meta_data = JSON.parse(el.attributes.meta_data || "{}");
        } catch {
          el.attributes.meta_data = {};
        }

        return el;
      });

      return resp;
    });
  };

  const post = ({ oid, cid, name, data }) => {
    const requestURL = createURL({ oid, cid });

    const payload = createPayload({ id: 0, cid, data, name });

    return axios.post(requestURL, payload).then((resp) => {
      resp.data.data.attributes.meta_data = JSON.parse(resp.data.data.attributes.meta_data);

      return resp;
    });
  };

  const put = ({ oid, cid, id, name, data }) => {
    const requestURL = createURL({ oid, cid });

    const payload = createPayload({ id, cid, data, name });

    return axios.put(requestURL, payload).then((resp) => {
      resp.data.data.attributes.meta_data = JSON.parse(resp.data.data.attributes.meta_data);

      return resp;
    });
  };

  return { get, post, put };
};
