<template>
  <div class="wizard">
    <div class="main">
      <div class="first-step" v-if="step === 'first'">
        <p>{{ $t("integration.mailchimp_step_1") }}</p>
      </div>

      <div class="second-step" v-if="step === 'second'">
        <p>{{ $t("integration.mailchimp_step_2") }}</p>
      </div>
      <div class="second-step" v-if="step === 'success'">
        <p>{{ $t("integration.mailchimp_step_success") }}</p>
      </div>
      <div class="second-step" v-if="step === 'fail'">
        <p>{{ $t("integration.mailchimp_step_fail") }}</p>
      </div>
    </div>
    <div class="footer">
      <button
        :class="
          step === 'success' ? 'bttn bttn--lg bttn--blue el-right' : 'bttn bttn--lg bttn--blue'
        "
        @click.prevent="closeModal"
      >
        {{ $t("integration.close") }}
      </button>
      <button
        class="bttn bttn--lg bttn--orange el-right"
        v-if="step === 'first'"
        @click.prevent="nextStep('first')"
      >
        {{ $t("integration.next") }}
      </button>
      <button
        class="bttn bttn--lg bttn--orange el-right"
        v-if="step === 'second'"
        @click.prevent="nextStep('second')"
      >
        {{ $t("integration.next") }}
      </button>
      <button
        class="bttn bttn--lg bttn--orange el-right"
        v-if="step === 'fail'"
        @click.prevent="nextStep('first')"
      >
        {{ $t("integration.try_again") }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import urls from "@/constants/urls.js";

import store from "@/store/store.js";

import { mapState } from "vuex";

export default {
  data() {
    return {
      redirectUri: "",
      client_id: "",
      step: "first",
      integrationStatus: false,
      currentIntegrationListCache: [],
      unsubscribe: "",
    };
  },

  computed: mapState({
    currentIntegrationList: (state) => {
      return state.integrations.integrations;
    },
  }),

  methods: {
    checkIntegrationList() {
      let orgId = localStorage.getItem("orgId");
      axios.get(urls.getIntegrationsList.replace(":orgId", orgId)).then((response) => {
        if (response.data.data.length > 0) {
          this.integrationStatus = true;
        }
      });
    },

    checkIntegration(mutation, state) {
      if (mutation.type === "setIntegrations") {
        const self = this;
        const newIntegration = mutation.payload.filter(function (o1) {
          return self.currentIntegrationListCache.every(function (o2) {
            return (
              o1.id != o2.id || (o1.id === o2.id && o1.attributes.status != o2.attributes.status)
            );
          });
        });

        if (newIntegration.length > 0 && newIntegration[0].attributes.status === 1) {
          this.step = "success";
        } else {
          this.step = "fail";
        }
      }
      this.unsubscribe();
    },

    getMailChimpIntegrationKey() {
      let orgId = localStorage.getItem("orgId");
      axios.post(urls.getMailChimpIntegrationKey.replace(":orgId", orgId)).then((response) => {
        this.redirectUri = response.data.callback_url;
        this.client_id = response.data.client_id;
        document.cookie =
          "mailchimp_integration_key=" +
          response.data.mailchimp_integration_key +
          ";domain=.charidy.com;path=/";
      });
    },

    nextStep(step) {
      if (step === "first") {
        this.step = "second";
        window.open(
          `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=${this.client_id}&redirect_uri=${this.redirectUri}`,
          "_blank"
        );
      }
      if (step === "second") {
        this.currentIntegrationListCache = this.currentIntegrationList;
        store.dispatch("getIntegrationsList");
        this.unsubscribe = store.subscribe((mutation, state) => {
          this.checkIntegration(mutation, state);
        });
      }
    },

    closeModal() {
      this.$store.dispatch("getIntegrationsList");
      this.$emit("close-modal");
    },
  },

  created: function () {
    this.getMailChimpIntegrationKey();
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  display: flex;
  //justify-content: space-between;
}

.el-right {
  margin-left: auto;
}

.account-link {
  font-size: 16px;
  color: #cc6d4c;
}
</style>
