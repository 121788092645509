<template lang="html">
  <div class="campaigns-page-wrapper">
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.campaigns-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
}

h4 {
  padding-top: 50px;
  padding-bottom: 30px;
}

.top-campaigns-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
