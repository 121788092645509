<template>
  <div v-if="!loading && !loadingAccount">
    <div class="category-title">
      <h1 class="h1-like">
        {{ title }}
      </h1>

      <div class="btn-group">
        <b-dropdown
          v-if="campaignShortLink"
          variant="-sm bttn--bordered-blue"
          right
          :text="$t('edit_campaign.open_campaign_page')"
        >
          <b-dropdown-item v-for="(pd, i) in previewDropdown" :key="i" @click="preview(pd.params)">
            {{ pd.text }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div class="campaign-sub">
      {{ $t("edit_campaign.campaign_mode_" + campaignMode) }}
    </div>
    <MediaStorageCropperPreviewModal>
      <express-campaign-edit v-if="yearRoundCampaign" />
      <campaign-edit v-else />
    </MediaStorageCropperPreviewModal>
  </div>
  <div class="loading" v-else>
    <app-spinner />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import urls from "@/constants/urls.js";
import MediaStorageCropperPreviewModal from "@/views/media-storage/MediaStorageCropperPreviewModal.vue";
import campaignEdit from "./campaign-edit-vue/campaignEdit.vue";
import expressCampaignEdit from "./campaign-wizards/v1/expressCampaignEdit.vue";
import appSpinner from "@/common-components/ui-elements/app-spinner.vue";
import axios from "axios";

export default {
  components: {
    campaignEdit,
    expressCampaignEdit,
    appSpinner,
    MediaStorageCropperPreviewModal,
  },

  data() {
    return {
      appHost: urls.appHost,
    };
  },

  computed: {
    ...mapState({
      loading: (state) => state.campaign.loadingCampaign,
      loadingAccount: (state) => state.account.loadingAccount,
      cmpID: (state) => state.campaign.campaign.data.id,
      title: (state) => state.campaign.campaign.data.attributes.title,
      campaignMode: (state) => state.campaign.campaign.data.attributes.mode,
      campaignShortLink: (state) => state.campaign.campaign.data.attributes.short_link,
    }),
    ...mapGetters({
      yearRoundCampaign: "yearRoundCampaign",
    }),
    expressCampaignDropdown() {
      return [
        {
          href: `${this.appHost}/${this.campaignShortLink}?viewmode=2`,
          text: this.$t("edit_campaign.campaign_in_live_mode"),
        },
      ];
    },
    defaultCampaignDropdown() {
      return [
        {
          params: { viewmode: 1 },
          text: this.$t("edit_campaign.campaign_in_countdown_mode"),
        },
        {
          params: { viewmode: 2 },
          text: this.$t("edit_campaign.campaign_in_live_mode"),
        },
        {
          params: { viewmode: 3 },
          text: this.$t("edit_campaign.campaign_in_success_mode"),
        },
        {
          params: { viewmode: 2, viewround: 2 },
          text: this.$t("edit_campaign.campaign_in_bonus_round"),
        },
      ];
    },
    previewDropdown() {
      if (this.yearRoundCampaign) {
        return this.expressCampaignDropdown;
      }
      return this.defaultCampaignDropdown;
    },
  },

  created() {
    let cid = this.$route.params.id;
    let props = `?${[
      "extend=campaign_stats",
      "extend=content",
      "extend=media",
      "extend=meta",
      "extend=matchers",
      "extend=donation_levels",
      "extend=donation_streams",
      "extend=campaign_roles",
      "extend=url_alias",
    ].join("&")}`;

    this.$store.dispatch("getCampaign", { cid, props });

    let teamsParams = "?limit=50&page=1&extend=stats";

    this.$store.dispatch("getTeams", teamsParams);
  },

  methods: {
    getPreviewToken() {
      const orgID = localStorage.getItem("orgId");

      return axios
        .get(urls.getPreviewToken(orgID, this.cmpID))
        .then((resp) => resp.data.data.token);
    },

    preview(params = {}) {
      const shortLinkURL = new URL(this.campaignShortLink, "https://charidy.com");

      Object.entries(params).map(([k, v]) => shortLinkURL.searchParams.set(k, v));

      this.getPreviewToken().then((token) => {
        if (token) {
          shortLinkURL.searchParams.set("token", token);
        }
        window.open(decodeURI(shortLinkURL.toString()), "_blank").focus();
      });
    },
  },
};
</script>
