<script>
import urls from "@/constants/urls.js";
import notificationsMixin from "@/mixins/notifications";
import axios from "axios";

export default {
  mixins: [notificationsMixin],
  props: {
    cId: {
      type: String,
      required: true,
    },
    dId: {
      type: String,
      required: true,
    },
    dAttrs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async sendConfirmationEmail() {
      this.loading = true;
      const orgID = localStorage.getItem("orgId");
      const campaignID = this.cId;
      const donationID = this.dId;
      const url = new URL(
        urls.postConfirmationEmail
          .replace(":orgId", orgID)
          .replace(":campaignId", campaignID)
          .replace(":donationId", donationID)
      );

      try {
        await axios.post(url.toString());
        this.$_notificationsMixin_makeToast(
          "Donation",
          this.$t("dashboard.edit_success_send_confirmation_email", "Sent confirmation email"),
          "success"
        );
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
      } finally {
        this.loading = false;
      }
    },
    async generateReceiptAndSendEmail() {
      this.loading = true;
      const orgID = localStorage.getItem("orgId");
      const campaignID = this.cId;
      const donationID = this.dId;
      const url = new URL(
        urls.postReceipt
          .replace(":orgId", orgID)
          .replace(":campaignId", campaignID)
          .replace(":donationId", donationID)
      );
      const searchParams = new URLSearchParams();
      searchParams.append("regenerate_and_send_receipt_email", "1");
      url.search = searchParams.toString();

      try {
        await axios.post(url.toString());
        this.$_notificationsMixin_makeToast(
          "Donation",
          this.$t(
            "dashboard.edit_success_send_generate_receipt_email",
            "Generated and sent receipt email"
          ),
          "success"
        );
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
      } finally {
        this.loading = false;
      }
    },
    async sendReceiptEmail() {
      this.loading = true;
      const orgID = localStorage.getItem("orgId");
      const campaignID = this.cId;
      const donationID = this.dId;
      const url = new URL(
        urls.postReceipt
          .replace(":orgId", orgID)
          .replace(":campaignId", campaignID)
          .replace(":donationId", donationID)
      );

      try {
        await axios.post(url.toString());
        this.$_notificationsMixin_makeToast(
          "Donation",
          this.$t("dashboard.edit_success_send_receipt_email", "Sent receipt email"),
          "success"
        );
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <div class="d-flex">
    <template v-if="dAttrs.can_generate_receipt">
      <button
        v-if="!dAttrs.donation_receipt_id"
        class="mx-1 bttn bttn--lg bttn--orange"
        :disabled="loading"
        @click="generateReceiptAndSendEmail"
        type="button"
      >
        {{
          $t(
            "dashboard.donation_edit_emails_generate_receipt_and_send_email",
            "Generate and send receipt email"
          )
        }}
      </button>
      <button
        v-else
        class="mx-1 bttn bttn--lg bttn--orange"
        :disabled="loading"
        @click="sendReceiptEmail"
        type="button"
      >
        {{ $t("dashboard.donation_edit_emails_resend_receipt_email", "Resend receipt email") }}
      </button>
    </template>
    <button
      class="mx-1 bttn bttn--lg bttn--orange"
      :disabled="loading"
      @click="sendConfirmationEmail"
      type="button"
    >
      {{ $t("dashboard.donation_edit_emails_send_confirmation_email", "Send confirmation email") }}
    </button>
  </div>
</template>
