<template>
  <div class="rds-charges-stats">
    <span class="rds-charges-stats__title">{{
      $t("dashboard.rds_charges_stats_title", "Recurring charges")
    }}</span>
    <div class="rds-charges-stats__bottom">
      <text-3-rows
        btn
        :active="status === $_rdsStatusMixin_getStatusCode('charge successful')"
        @click.native="setStatus($_rdsStatusMixin_getStatusCode('charge successful'))"
      >
        <template #top>
          {{ stats.successful_count }}
        </template>
        <template #middle>{{
          $t("dashboard.rds_charges_stats_successful", "Successful")
        }}</template>
        <template #bottom>
          <span>{{ currencySign }}</span>
          <span>{{ (stats.successful_total || 0).toLocaleString() }}</span>
        </template>
      </text-3-rows>
      <text-3-rows
        btn
        :active="status === $_rdsStatusMixin_getStatusCode('charge failed')"
        @click.native="setStatus($_rdsStatusMixin_getStatusCode('charge failed'))"
      >
        <template #top>
          {{ stats.failed_count }}
        </template>
        <template #middle>{{ $t("dashboard.rds_charges_stats_failed", "Failed") }}</template>
        <template #bottom>
          <span>{{ currencySign }}</span>
          <span>{{ (stats.failed_total || 0).toLocaleString() }}</span>
        </template>
      </text-3-rows>
      <text-3-rows
        btn
        :active="status === $_rdsStatusMixin_getStatusCode('authorized')"
        @click.native="setStatus($_rdsStatusMixin_getStatusCode('authorized'))"
      >
        <template #top>
          {{ stats.pending_count || 0 }}
        </template>
        <template #middle>{{ $t("dashboard.rds_charges_stats_pending", "Pending") }}</template>
        <template #bottom>
          <span>{{ currencySign }}</span>
          <span>{{ (stats.pending_total || 0).toLocaleString() }}</span>
        </template>
      </text-3-rows>
      <text-3-rows
        btn
        :active="status === $_rdsStatusMixin_getStatusCode('authorized and canceled')"
        @click.native="setStatus($_rdsStatusMixin_getStatusCode('authorized and canceled'))"
      >
        <template #top>
          {{ stats.pending_count || 0 }}
        </template>
        <template #middle>{{ $t("dashboard.rds_charges_stats_canceled", "Canceled") }}</template>
        <template #bottom>
          <span>{{ currencySign }}</span>
          <span>{{ (stats.canceled_total || 0).toLocaleString() }}</span>
        </template>
      </text-3-rows>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import text3Rows from "./text-3-rows.vue";
import { rdsStatusMixin } from "@/mixins";

export default {
  mixins: [rdsStatusMixin],
  components: {
    text3Rows,
  },

  computed: {
    ...mapState({
      stats: (s) => s.rds.recordsStats,
      filters: (s) => s.rds.ui.filters,
      campaigns: (s) => s.campaigns.campaigns,
    }),

    status: {
      get() {
        return this.filters.status;
      },
      set(status) {
        this.setFilter({ key: "status", value: status });
      },
    },

    campaign() {
      return this.campaigns.find((v) => v.id === this.filters.cid);
    },
    currencySign() {
      return this.campaign?.attributes.currency_sign;
    },
  },

  methods: {
    ...mapActions({
      setFilter: "rds/ui/setFilter",
    }),

    setStatus(status) {
      if (this.status !== status) {
        this.status = status;
      } else {
        this.status = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rds-charges-stats {
  display: grid;
  flex: 1;
  margin-left: 60px;
  @media screen and (max-width: 425px) {
    margin-left: 0;
  }
  &__title {
    text-transform: uppercase;
    font-weight: 700;
  }
  &__bottom {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
