<template>
  <div>
    <b-modal id="editor" size="lg" @hidden="clearData">
      <div slot="modal-header">
        <h2>{{ $t("edit_campaign.email_template_edit_content_modal") }}</h2>
      </div>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
          {{ $t("dashboard.integration_export_close") }}
        </button>

        <button
          :disabled="selectedBodyLoading || !selectedBodyValid"
          class="bttn bttn--lg bttn--orange el-right"
          @click="saveEmailTemplate"
        >
          {{ $t("dashboard.email_template_save") }}
        </button>
      </div>

      <ul class="language-selected">
        <li
          @click="getLocalization('default')"
          class="language-selected__unit"
          :class="{ active: languageSelected === 'default' }"
        >
          {{ $t("edit_campaign.email_template_language_default", "Default") }}
        </li>
        <li
          v-for="item in customContent"
          :key="item.id"
          @click="getLocalization(item.attributes.lang)"
          class="language-selected__unit"
          :class="{ active: languageSelected === item.attributes.lang }"
        >
          {{ getLanguageLong(item.attributes.lang) }}
          <div class="pl-2">
            <b-button-close v-on:click.stop="deleteLocalization(item.attributes.lang)" />
          </div>
        </li>

        <b-dropdown
          id="ddown1"
          :text="$t('edit_campaign.email_template_add_language', '+ Add language')"
          class="dropdown"
          variant="-sm bttn--bordered-blue"
        >
          <b-dropdown-item
            v-for="(lang, index) in availableLanguages"
            :key="index"
            @click="createLocalization(lang)"
            >{{ getLanguageLong(lang) }}</b-dropdown-item
          >
        </b-dropdown>
      </ul>

      <b-form-group :label="$t('dashboard.email_template_subject')">
        <b-form-input v-model="selectedSubject" />
      </b-form-group>

      <QuillEditorVariables
        v-model="selectedBody"
        class="email-template-editor"
        :id="'quill-email-editor'"
        ref="myQuillEditor"
        emitWithChangeSource
        :editorOptions="editorOptions"
        :tags="editorTags"
        :valid.sync="selectedBodyValid"
        :loading.sync="selectedBodyLoading"
        :editor-direction="editorDirection"
      />

      <error :errors="errors" />
    </b-modal>
  </div>
</template>

<script>
import error from "@/components/errors.vue";
import { mapGetters, mapState } from "vuex";
import { unwrapContentInline } from "@/components/vue-quill-editor/utils";
import QuillEditorVariables from "@/common-components/ui-elements/quill-editor-variables.vue";
import { getDirection } from "@/store/modules/translation";

export default {
  data() {
    return {
      emailTemplate: {
        attributes: {
          custom_body: "",
          custom_subject: "",
        },
        relationships: {
          custom_content: {
            data: [],
          },
        },
        included: [],
      },
      customContentID: 0,
      editorOptions: {
        modules: {
          toolbar: {
            container: [
              ["bold", "underline", "strike", "italic"],
              ["blockquote"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ align: [] }],
              [{ color: [] }, { background: [] }],
              ["image"],
              ["link"],
              ["clean"],
            ],
          },
          imageResize: {
            modules: ["Resize", "DisplaySize"],
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
          },
        },
      },
      errors: [],
      tags: {
        confirmation: [
          { value: "[FIRST_NAME]", text: "The donor’s First Name" },
          { value: "[FULL_NAME]", text: "The donor’s Full Name" },
          { value: "[ORG_NAME]", text: "The organisation’s Name" },
          { value: "[GOAL]", text: "Campaign primary goal" },
          {
            value: "[DATE_DONATED]",
            text: "Full date and time of the donation",
          },
          {
            value: "[BILL_ADDRESS]",
            text: "The donor’s full billing address as entered on the donation form",
          },
          {
            value: "[TEAM_PARAGRAPH]",
            text: "A custom paragraph associated with a team",
          },
          {
            value: "[DONATED]",
            text: "The full amount and currency of the donation. E.g 5.00 EUR",
          },
          {
            value: "[CUSTOM_DONATION_FORM_TEXT_INPUT]",
            text: "Information entered by the donor on a custom field on the donation form",
          },
          {
            value: "[DONATED_CURRENCY]",
            text: "The donation currency. E.g EUR",
          },
          { value: "[DONATED_AMOUNT]", text: "The donation amount. E.g 5.00" },
        ],
        receipt: [
          { value: "[FIRST_NAME]", text: "The donor’s First Name" },
          { value: "[FULL_NAME]", text: "The donor’s Full Name" },
          { value: "[ORG_NAME]", text: "The organisation’s Name" },
          { value: "[GOAL]", text: "Campaign primary goal" },
          {
            value: "[DATE_DONATED]",
            text: "Full date and time of the donation",
          },
          {
            value: "[DONATED]",
            text: "The full amount and currency of the donation. E.g 5.00 EUR",
          },
          {
            value: "[DONATED_CURRENCY]",
            text: "The donation currency. E.g EUR",
          },
          { value: "[DONATED_AMOUNT]", text: "The donation amount. E.g 5.00" },
          {
            value: "[CUSTOM_DONATION_FORM_TEXT_INPUT]",
            text: "Information entered by the donor on a custom field on the donation form",
          },
          {
            value: "[BILL_ADDRESS]",
            text: "The donor’s full billing address as entered on the donation form",
          },
          {
            value: "[TEAM_PARAGRAPH]",
            text: "A custom paragraph associated with a team",
          },
          {
            value: "[STREAM]",
            text: "The fundraising stream that the donor donated to",
          },
        ],
        team_confirmation: [
          {
            value: "[TEAM_NAME]",
            text: "The name of the team",
          },
          {
            value: "[DESCRIPTION]",
            text: "A description of the team",
          },
          {
            value: "[CAMPAIGN_TITLE]",
            text: "The title of the campaign the team belongs to",
          },
          {
            value: "[GOAL]",
            text: "The individual goal for the team",
          },
          {
            value: "[CURRENCY]",
            text: "The currency of the campaign the team belongs to",
          },
          {
            value: "[LEADER_NAME]",
            text: "The full name of the team leader",
          },
          {
            value: "[LEADER_EMAIL]",
            text: "The email address of the team leader",
          },
          {
            value: "[FULL_LINK]",
            text: "The link to the team page",
          },
          {
            value: "[TEAM_ID]",
            text: "Unique ID of the team",
          },
          {
            value: "[TOKEN]",
            text: "URL with token to edit the team",
          },
        ],
      },
      languageSet: [],
      languageSelected: "",
      selectedSubject: "",
      selectedBody: "",
      selectedBodyLoading: false,
      selectedBodyValid: true,
      editorDirection: "ltr",
    };
  },

  components: {
    error,
    QuillEditorVariables,
  },

  computed: {
    ...mapState({
      locale: (state) => state.i18n.locale,
    }),
    ...mapGetters({
      getOldLangsVariant: "getOldLangsVariant",
    }),

    editorTags() {
      if (this.isConfirmationEmail) {
        return this.tags.confirmation;
      } else if (this.isTeamConfirmationEmail) {
        return this.tags.team_confirmation;
      }

      return this.tags.receipt;
    },

    isConfirmationEmail() {
      return this.emailTemplate.attributes.type === "confirmation_email";
    },

    isTeamConfirmationEmail() {
      return this.emailTemplate.attributes.type === "team_confirmation_email";
    },

    customContent() {
      let result = [];
      for (let i = 0; i < this.emailTemplate.included.length; i++) {
        let found = false;
        for (let j = 0; j < this.emailTemplate.relationships.custom_content.data.length; j++) {
          if (
            this.emailTemplate.relationships.custom_content.data[j].id ===
            this.emailTemplate.included[i].id
          ) {
            found = true;
            break;
          }
        }
        if (!found) {
          continue;
        }
        if (this.emailTemplate.included[i].type === "email_template_custom_content") {
          result.push(this.emailTemplate.included[i]);
        }
      }
      return result;
    },

    previewHtmlCustomContent() {
      const temp = {};
      for (const incl of this.emailTemplate.included) {
        if (incl.type === "default_preview_custom_content") {
          temp[incl.attributes.lang] = incl.attributes.body;
        }
      }
      return temp;
    },

    availableLanguages() {
      let result = [];
      for (let i = 0; i < this.languageSet.length; i++) {
        let used = false;
        for (let j = 0; j < this.customContent.length; j++) {
          if (this.languageSet[i].short === this.customContent[j].attributes.lang) {
            used = true;
            break;
          }
        }
        if (!used) {
          result.push(this.languageSet[i].short);
        }
      }
      return result;
    },
  },

  methods: {
    clearData() {
      this.selectedSubject = "";
      this.selectedBody = "";
      this.previewValue = "";
      this.emailTemplate.attributes.custom_subject = "";
      this.emailTemplate.attributes.custom_body = "";
      this.emailTemplate.relationships = {};
      this.emailTemplate.relationships.custom_content = [];
      this.emailTemplate.included = [];
      this.customContentID = 0;
      this.errors = [];
      this.languageSelected = "";
    },

    openModal(template) {
      const tmpAttrs = template.data.attributes;
      if (!tmpAttrs.custom_body && tmpAttrs.preview_html_content) {
        tmpAttrs.custom_body = tmpAttrs.preview_html_content;
      }

      this.clearData();
      this.initializeLanguages();
      this.emailTemplate.attributes = { ...tmpAttrs };
      this.emailTemplate.relationships = Object.assign({}, template.data.relationships);
      this.emailTemplate.included = Object.assign([], template.included);
      this.customContentID = this.emailTemplate.included.length;
      for (let i = 0; i < this.emailTemplate.included; i++) {
        this.customContentID = Math.max(
          parseInt(this.customContentID),
          parseInt(this.emailTemplate.included[i].id)
        ).toString();
      }
      this.getLocalization("default");

      this.$root.$emit("bv::show::modal", "editor");
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "editor");
    },

    saveEmailTemplate() {
      this.saveLocalization();
      this.$emit("save-email-template", this.emailTemplate);
    },

    setErrors(errors) {
      this.errors = errors;
    },

    initializeLanguages() {
      this.languageSet = this.getOldLangsVariant;
    },

    createLocalization(languageCode) {
      this.customContentID++;
      this.emailTemplate.relationships.custom_content.data.push({
        id: this.customContentID.toString(),
        type: "email_template_custom_content",
      });
      this.emailTemplate.included.push({
        id: this.customContentID.toString(),
        type: "email_template_custom_content",
        attributes: {
          lang: languageCode,
          subject: "",
          body: "",
        },
      });
      this.getLocalization(languageCode);
    },

    getLocalization(languageCode) {
      if (languageCode !== "") {
        this.saveLocalization();
      }
      this.languageSelected = languageCode;
      this.editorDirection = getDirection(languageCode);
      if (languageCode === "default") {
        this.selectedSubject = this.emailTemplate.attributes.custom_subject;
        this.selectedBody = this.emailTemplate.attributes.custom_body;
        this.previewValue = this.emailTemplate.attributes.custom_body;
        this.editorDirection = getDirection(this.locale);
        return;
      }
      for (let i = 0; i < this.customContent.length; i++) {
        if (this.customContent[i].attributes.lang === languageCode) {
          this.selectedSubject = this.customContent[i].attributes.subject;
          if (this.previewHtmlCustomContent[languageCode]) {
            this.selectedBody = this.previewHtmlCustomContent[languageCode];
            this.previewValue = this.previewHtmlCustomContent[languageCode];
          } else {
            this.selectedBody = this.customContent[i].attributes.body;
            this.previewValue = this.customContent[i].attributes.body;
          }
          break;
        }
      }
    },

    saveLocalization() {
      if (this.languageSelected === "default") {
        this.emailTemplate.attributes.custom_subject = this.selectedSubject;
        this.emailTemplate.attributes.custom_body = unwrapContentInline(this.selectedBody);
        return;
      }
      for (let i = 0; i < this.customContent.length; i++) {
        if (this.customContent[i].attributes.lang === this.languageSelected) {
          this.customContent[i].attributes.subject = this.selectedSubject;
          this.customContent[i].attributes.body = unwrapContentInline(this.selectedBody);
          break;
        }
      }
    },

    deleteLocalization(languageCode) {
      let index = -1;
      for (let i = 0; i < this.emailTemplate.included.length; i++) {
        if (this.emailTemplate.included[i].attributes.lang === languageCode) {
          index = i;
          break;
        }
      }
      let id = "";
      if (index >= 0) {
        id = this.emailTemplate.included[index].id;
        this.emailTemplate.included.splice(index, 1);
      } else {
        return;
      }
      index = -1;
      for (let i = 0; i < this.emailTemplate.relationships.length; i++) {
        if (this.emailTemplate.relationships[i].id === id) {
          index = i;
          break;
        }
      }
      if (index >= 0) {
        this.emailTemplate.relationships.splice(index, 1);
      }
      this.getLocalization("default");
    },

    getLanguageLong(languageCode) {
      for (let i = 0; i < this.languageSet.length; i++) {
        if (this.languageSet[i].short == languageCode) {
          return this.languageSet[i].long;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
