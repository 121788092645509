<template>
  <div>
    <div class="category-title">
      <h1 class="h1-like">
        {{ $t("dashboard.vetting_title") }}
      </h1>
      <div class="action-block">
        <div class="item">
          <b-button class="bttn bttn--lg bttn--orange" @click="openAddModal">
            {{ $t("dashboard.vetting_add_new_btn") }}
          </b-button>
        </div>
      </div>
    </div>

    <b-table head-variant="light" :busy="loading" :fixed="true" :items="items" :fields="fields">
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template v-slot:cell(actions)="{ item }">
        <button
          v-if="item.attributes.status === 'draft'"
          class="bttn-round ml-auto"
          @click="openEditModal(item)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              data-v-364ddc4c=""
              d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            ></path>
            <path data-v-364ddc4c="" d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
      </template>
    </b-table>

    <vetting-form-modal ref="vettingFormModal" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import VettingFormModal from "./VettingFormModal.vue";

export default {
  components: {
    VettingFormModal,
  },

  data() {
    return {
      fields: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "attributes.status",
          label: this.$t("dashboard.vetting_th_status"),
        },
        {
          key: "attributes.updated_at",
          label: this.$t("dashboard.vetting_th_date"),
          formatter(value) {
            return new Date(value * 1000).toDateString();
          },
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      items: (state) => state.tools.vetting.list,
      count: (state) => state.tools.vetting.count,
      loading: (state) => state.tools.vetting.loading,
    }),
  },

  mounted() {
    this.getVettings();
  },

  methods: {
    ...mapActions({
      fetchVettings: "tools/vetting/fetchVettings",
    }),

    getVettings() {
      this.fetchVettings();
    },

    openAddModal() {
      this.$refs.vettingFormModal.open();
    },

    openEditModal(item) {
      this.$refs.vettingFormModal.open("edit", item);
    },
  },
};
</script>

<style></style>
