<template>
  <b-pagination
    :value="value"
    @input="$emit('input', $event)"
    :total-rows="total"
    :per-page="perPage"
    aria-controls="donors-table"
    v-bind="$attrs"
  ></b-pagination>
</template>

<script>
export default {
  props: {
    value: {
      type: [Boolean, Number, String, Object],
      default: null,
    },
    total: {
      type: [Number, String],
      default: 0,
    },
    perPage: {
      type: [Number, String],
      default: 20,
    },
  },
};
</script>
