<template lang="html">
  <div>
    <div class="campaign-edit-title">
      <h2 class="h2-like">Reviews</h2>
    </div>

    <section class="reviews-page">
      <h2 class="h2-like">Not Sure what to include</h2>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: function () {
    return {};
  },

  components: {},

  computed: mapState({}),

  watch: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.reviews-page {
  text-align: center;
  height: 400px;
  h2 {
    line-height: 400px;
  }
}
</style>
