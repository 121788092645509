<template>
  <div class="wizard">
    <div class="main">
      <div class="taxgift-intro">
        <p>
          <b>{{ $t("integration.taxgift_title", "What is TaxGift?") }}</b>
        </p>
        <p>
          {{
            $t(
              "integration.taxgift_description",
              "TaxGift is a digital solution that automates the process of gifting donation tax rebates back to New Zealand schools and charities."
            )
          }}
        </p>
      </div>
      <b-form-group
        id="reports-fieldset-select-entities"
        :label="
          $t(
            'integration.filters_entities_title',
            'Pick a legal entity that you want to connect with TaxGift'
          )
        "
        label-for="reports-input-select-entities"
        description="Only New Zealand legal entities will be shown in this dropdown."
      >
        <multiselect
          id="reports-input-select-entities"
          v-model="legal"
          :options="entitiesList"
          :allow-empty="false"
          :custom-label="customLabel"
          :loading="loadingEntities"
          :placeholder="$t('reports.filters_select_entities', 'Select legal entity')"
          :select-label="$t('reports.select_entities_select_label', 'Press enter to select')"
          :deselect-label="$t('reports.select_entities_deselect_label', 'Press enter to remove')"
          :selected-label="$t('reports.select_entities_selected_label', 'Selected')"
        />
      </b-form-group>
      <b-form-group
        id="reports-fieldset-select-key"
        label="API Key:"
        label-for="reports-input-select-key"
        description="To get your API Key, please contact your campaign manager. Each API Key is unique to one legal entity."
      >
        <b-form-input
          id="reports-input-select-key"
          v-model="apiKey"
          type="text"
          placeholder="Please enter your API Key"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="reports-fieldset-select-entity"
        label="Entity ID:"
        label-for="reports-input-select-entity"
        description="To get your Entity ID, please contact your campaign manager. Each Entity ID is unique to one legal entity."
      >
        <b-form-input
          id="reports-input-select-entity"
          v-model="entityId"
          type="text"
          placeholder="Please enter your Entity ID"
          required
        ></b-form-input>
      </b-form-group>
    </div>
    <div class="footer">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="closeModal">
        {{ $t("integration.close") }}
      </button>
      <button
        class="bttn bttn--lg bttn--orange el-right"
        :disabled="!(legal && apiKey && entityId)"
        @click.prevent="addIntegration"
      >
        {{ $t("integration.add_taxgift", "Add Integration") }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls.js";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      legal: null,
      apiKey: "",
      entityId: "",
    };
  },

  created() {
    this.$store.dispatch("getEntities");
  },

  computed: mapState({
    loadingEntities: (state) => {
      return state.account.loadingEntities;
    },

    entitiesList: (state) => {
      const integrations = state.integrations.integrations.filter(function (i) {
        return i.attributes.status === 1;
      });
      /*
        Two steps of filtering:
        1. Filter out entities that already has a taxgift integration
        2. Filter out non NZ entities
      */
      const filteredList = state.account.entities
        .filter((entity) => {
          let exist = false;
          for (let i = 0; i < integrations.length; i++) {
            if (
              parseInt(entity.id) === integrations[i].attributes.customer_id &&
              integrations[i].attributes.service === "tax_gift"
            ) {
              exist = true;
            }
          }
          return !exist;
        })
        .filter((entity) => entity.attributes.country === "NZ");
      return filteredList;
    },

    orgId: () => {
      return localStorage.getItem("orgId");
    },
  }),

  methods: {
    closeModal() {
      this.$store.dispatch("getIntegrationsList");
      this.$emit("close-modal");
    },
    customLabel(entity) {
      return `[${entity.id}] ${entity.attributes.name}`;
    },
    addIntegration() {
      const payload = {
        api_key: this.apiKey,
        entity_id: this.entityId,
        charidy_legal_entity_id: parseInt(this.legal.id),
        status: true,
      };
      // Please fill in API Call here
      axios
        .post(urls.addIntegration(this.orgId, "taxgift"), payload)
        .then(() => {
          this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
          this.$emit("close-modal");
          this.$store.dispatch("getIntegrationsList");
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: space-between;
}
.el-right {
  margin-left: auto;
}
</style>
