<template>
  <div class="updates__item">
    <div class="updates__item--top">
      <h4>
        {{ update.attributes.title }}
      </h4>
      <span>{{ ago }}</span>
    </div>
    <p>
      {{ update.attributes.message }}
    </p>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    update: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ago() {
      return moment(this.update.attributes.created_at * 1000).fromNow();
    },
  },
};
</script>

<style lang="scss" scoped>
.updates {
  &__item {
    width: 100%;
    padding: 20px 30px;
    background: rgba(0, 211, 156, 0.07);
    border: 0;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 21px;
      height: 1px;
      top: 50%;
      left: -21px;
      background: #3cc7a0;
    }
    &:after {
      position: absolute;
      content: "";
      width: 17px;
      height: 17px;
      background: #3cc7a0;
      top: 50%;
      margin-top: -8px;
      left: -30px;
      border-radius: 50%;
    }
    &--top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 15px;
    }
    h4,
    p,
    span {
      font-size: 20px;
      margin: 0;
    }
    span {
      color: #a0a1ac;
      font-size: 16px;
    }
    p {
      font-size: 16px;
      color: #a0a1ac;
    }
  }
}
</style>
