<script>
import { cdnBaseInputProps } from "./cdn-base-input-mixin.js";

export default {
  inheritAttrs: false,
  props: {
    ...cdnBaseInputProps,
  },
};
</script>

<template>
  <div
    class="cnd-input"
    :data-name="name"
    :class="{ 'cnd-input--form': form, 'cnd-input--error': error }"
  >
    <label
      v-if="!hideTitle"
      class="cnd-input__label"
      :class="{
        'cnd-input__label--size-md': size === 'md',
        'cnd-input__label--size-lg': size === 'lg',
        'cnd-input__label--light': variant === 'light',
        'cnd-input__label--dark': variant === 'dark',
      }"
    >
      <slot />
    </label>
    <div
      class="cnd-input__group"
      :class="{
        'cnd-input__group--light': variant === 'light',
        'cnd-input__group--dark': variant === 'dark',
        'cnd-input__group--white': variant === 'white',
      }"
    >
      <slot name="input" />
    </div>
    <span v-if="error" class="cnd-input__label cnd-input__label--size-sm">
      <slot name="error" />
    </span>
  </div>
</template>

<style lang="scss" scoped>
.cnd-input {
  &--form {
    margin-bottom: 28px;
  }

  &--error {
    color: red;
  }

  &__label {
    &--size-sm {
      font-size: 14px;
      font-weight: 400;
    }

    &--size-md {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 400;
    }

    &--size-lg {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 700;
    }

    &--light {
      color: #313131;
    }

    &--dark {
      color: #fafafa;
    }
    &--white {
      color: #fafafa;
    }
  }

  &__group {
    display: flex;
    align-items: center;

    &--light {
      input,
      textarea {
        color: #313131;
      }
    }

    &--dark {
      input,
      textarea {
        color: #fafafa;
      }
    }

    input,
    textarea {
      background: transparent;
      border: 1px solid #707070;
      height: 42px;
      flex-grow: 1;
      padding: 0 15px;
      font-family: var(--font-secondary);
      border-radius: 0px;
    }

    textarea {
      height: 125px;
    }
    &.cnd-input__group--white {
      input,
      textarea {
        color: #fafafa;
        border: 1px solid #fafafa;
        &::placeholder {
          color: #fafafa;
        }
      }
    }
  }
}
</style>
