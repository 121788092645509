<template>
  <div class="design-templates-wrapper">
    <div class="category-title">
      <h1 class="h1-like">Charidy Graphics</h1>
    </div>
    <div class="btns-wrap">
      <button
        class="bttn bttn--xlg bttn--bordered canva-btn"
        :class="{ 'bttn--blue': activetab == 1 }"
        v-on:click="activetab = 1"
      >
        <i>
          <img
            src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1561113519677205059.png"
            alt
          /> </i
        >Canva Templates
      </button>
      <button
        class="bttn bttn--xlg bttn--bordered premium-btn"
        :class="{ 'bttn--blue': activetab == 2 }"
        v-on:click="activetab = 2"
      >
        <i>
          <img
            src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1561113597489940819.png"
            alt
          /> </i
        >Premium Templates
      </button>
    </div>

    <canva-tab :activetab="activetab" />
    <premium-tab :activetab="activetab" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import canvaTab from "./tabs/canvaTab";
import premiumTab from "./tabs/premiumTab";

export default {
  components: {
    canvaTab,
    premiumTab,
  },
  mounted() {
    this.updateOrgSettingsAccess();
  },
  data: function () {
    return {
      activetab: 1,
    };
  },
  methods: {
    ...mapActions(["updateOrgSettingsAccess"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
.design-templates-wrapper {
  padding: 0 90px;
}

.h1-like {
  font-size: 50px;
}

.btns-wrap {
  padding: 20px 20px 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.bttn--xlg {
  height: 64px;
  min-width: 100px;
  width: 30%;
  padding: 0 56px;
  border-radius: 40px;
  font-size: 25px;
  font-family: $vito-light;
  line-height: 1;
  position: relative;
  &.active {
    color: #ffffff;
    background-color: $bb-100;
  }
  i {
    position: absolute;
    display: block;
    left: 10px;
    width: 46px;
    height: 46px;
    top: 50%;
    margin-top: -23px;
    img {
      width: 100%;
    }
  }
  &.bttn--bordered {
    border: 1px solid $bb-100;
  }
}

@media all and (min-width: 1280px) {
  .design-templates-wrapper {
    padding: 0 50px;
  }
}
</style>
