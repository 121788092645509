import axios from "axios";
import urls from "@/constants/urls.js";

const state = {
  account: {},
  included: [],
  loadingAccount: true,
  avatarSrc:
    "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/default-user-picture.png",
};

const mutations = {
  setDonorAccount(state, { data }) {
    state.account = data;
  },

  setDonorAccountDataIncluded(state, { data, included }) {
    state.account = data;
    state.included = included;
  },

  setDonorAccountIncluded(state, included) {
    state.included = included;
  },

  setDonorLoadingAccount(state, value) {
    state.loadingAccount = value;
  },

  SET_AVATAR_SRC(state, avatarSrc) {
    state.avatarSrc = avatarSrc;
  },
};

const getters = {
  getIncludedByType: (state) => (type) => {
    const [included] = state.included.filter((el) => el.attributes.type === type);

    return included;
  },
};

const actions = {
  updateDonorAccountInclude({ state, commit }, data) {
    const { included } = state;
    const index = included.findIndex((el) => el.attributes.type === data.attributes.type);
    included[index] = data;
    commit("setDonorAccountIncluded", state.included);
  },

  getDonorAccountInfo({ commit }, changeOrgId = true) {
    commit("setDonorLoadingAccount", true);

    let extend = `?${["extend=settings"].join("&")}`;

    axios
      .get(urls.donorAccount + extend)
      .then((response) => {
        if (changeOrgId) {
          commit("setOrgId", response.data.data.id, { root: true });
          localStorage.setItem("orgId", response.data.data.id);
        }
        commit("setDonorAccountDataIncluded", response.data);
        commit("setDonorLoadingAccount", false);
      })
      .catch((e) => {
        console.log(e);
        commit("setDonorLoadingAccount", false);
      });
  },
  deleteDonorAccountAvatarFile({ commit }) {
    commit("SET_AVATAR_SRC", "data:");
    return Promise.resolve();
  },
  uploadDonorAccountAvatarFile({ commit }, fileInputEvent) {
    let fileInput = fileInputEvent.target.files[0];

    let formData = new FormData();
    formData.append("file", fileInput);

    return axios.post(urls.loadImageQuill, formData).then((response) => {
      commit("SET_AVATAR_SRC", response.data.src);
      return response;
    });
  },
  updateUserWithAvatar({ commit, state }) {
    const accountDataCopy = { ...state.account };
    const attributesCopy = {
      ...accountDataCopy.attributes,
      avatar: state.avatarSrc,
    };
    accountDataCopy.attributes = attributesCopy;
    return axios.patch(urls.donorAccount, { data: accountDataCopy }).then((response) => {
      commit("setDonorAccount", { data: accountDataCopy });
      return response;
    });
  },

  fetchUnsubscribe() {
    return axios.post(urls.donorUnsubscribe).then((response) => {
      return response.data;
    });
  },

  fetchDeleteAccount() {
    return axios.post(urls.donorDeleteAccount).then((response) => {
      return response.data;
    });
  },

  fetchDeleteAllData() {
    return axios.post(urls.donorDeleteAllData).then((response) => {
      return response.data;
    });
  },

  fetchDownloadData() {
    return axios.post(urls.donorDownloadData).then((response) => {
      return response.data;
    });
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
