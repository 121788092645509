import urls from "../../constants/urls.js";
import axios from "axios";

const getUrl = (id = "") => {
  const orgId = localStorage.getItem("orgId");

  return urls.getOrgTicket.replace(":oid", orgId).replace("/:id", id ? `/${id}` : "");
};

const state = {
  list: [],
  count: 0,
  countByStatus: {
    to_do: 0,
    in_process: 0,
    completed: 0,
  },
  loading: false,
};

const mutations = {
  setList(state, data) {
    state.list = data;
  },

  setCount(state, val) {
    state.count = Number(val) || 0;
  },

  setCountByStatus(state, { status, val }) {
    state.countByStatus[status] = Number(val) || 0;
  },

  setLoading(state, value) {
    state.loading = value;
  },
};

const actions = {
  fetchOrgToDoTicketsCount({ commit }, { status } = {}) {
    const orgId = localStorage.getItem("orgId");
    const url = urls.getOrgTicketList.replace(":oid", orgId);
    const paramsLoc = {
      parent_id: 0,
      page: 1,
      per_page: 1,
    };

    switch (status) {
      case "to_do":
        paramsLoc.status = "to_do";
        paramsLoc.assigned_on = "organization";
        break;

      case "in_process":
        paramsLoc.status = "to_do";
        paramsLoc.assigned_on = "charidy";
        break;

      case "completed":
        paramsLoc.status = "completed";
        break;
    }

    axios.get(url, { params: paramsLoc }).then(({ headers }) => {
      commit("setCountByStatus", {
        status,
        val: headers["x-total-records"],
      });
    });
  },

  fetchOrgTickets(_, { params } = {}) {
    const orgId = localStorage.getItem("orgId");
    const url = urls.getOrgTicketList.replace(":oid", orgId);
    const paramsLoc = {
      ...params,
    };

    return axios.get(url, { params: paramsLoc });
  },

  fetchOrgTicketsAndStore({ commit, dispatch }, { params } = {}) {
    commit("setLoading", true);

    const paramsLoc = {
      ...params,
    };

    return dispatch("fetchOrgTickets", { params: paramsLoc })
      .then(({ data, headers }) => {
        commit("setCount", headers["x-total-records"]);
        commit("setList", data);
        commit("setLoading", false);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },

  fetchOrgTicket(_, { id, params } = {}) {
    const url = getUrl(id);
    const paramsLoc = {
      ...params,
    };

    return axios.get(url, { params: paramsLoc });
  },

  fetchCreateOrgTicket(_, { params, payload } = {}) {
    const url = getUrl();
    const paramsLoc = {
      ...params,
    };

    return axios.post(url, payload, { params: paramsLoc });
  },

  fetchUpdateOrgTicket(_, { id, params, payload } = {}) {
    const url = getUrl(id);
    const paramsLoc = {
      ...params,
    };

    return axios.patch(url, payload, { params: paramsLoc });
  },

  fetchDeleteOrgTicket(_, { id } = {}) {
    const url = getUrl(id);

    return axios.delete(url);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
