<script>
export default {
  props: {
    gatewayKey: {
      type: String,
      required: true,
    },
    gateway: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <a
    href="#"
    class="gateways-add-select__item"
    :class="{ 'gateways-add-select__item--hk': !!gateway.title }"
    :key="gatewayKey"
    @click.prevent="$emit('click', gatewayKey)"
  >
    <img
      v-if="gateway.img"
      :src="gateway.img"
      :alt="!!gateway.title ? gateway.title : gatewayKey"
    />
    <p v-if="gateway.title">{{ gateway.title }}</p>
  </a>
</template>

<style scoped lang="scss">
.gateways-add-select {
  &__item {
    margin: 10px;
    flex-grow: 1;
    min-width: 120px;
    max-width: 170px;
    background: #fff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(32, 67, 78, 0.15);
    @media only screen and (min-width: 460px) {
      min-width: 170px;
    }

    img {
      max-width: 80px;
      height: auto;
    }
    &--hk {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: auto;
        height: 48px;
        object-fit: contain;
        margin-bottom: 2px;
      }
      p {
        margin-bottom: 0;
        font-family: $vito-bold;
        font-size: 12px;
        color: $fd;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}
</style>
