<template>
  <MediaStorageCropperProvider>
    <template #default="{ cropperProps }">
      <cropper
        class="cropper"
        v-bind="{ ...cropperProps(tag).props, ...$attrs }"
        v-on="$listeners"
        :debounce="300"
        ref="cropper"
      ></cropper>
    </template>
  </MediaStorageCropperProvider>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import MediaStorageCropperProvider from "./MediaStorageCropperProvider.vue";
export default {
  props: {
    tag: {
      type: String,
      default: "",
    },
  },
  components: {
    Cropper,
    MediaStorageCropperProvider,
  },
  watch: {
    cropperProps: {
      handler() {
        const cropperRef = this.$refs.cropper;
        if (cropperRef) {
          cropperRef.update();
        }
      },
      deep: true,
    },
  },
};
</script>
