<template>
  <div id="disabled_import_button">
    <!-- <b-btn v-b-modal.importDonors :disabled='disabledButton'>{{ $t('dashboard.import_button_title') }}</b-btn> -->

    <b-tooltip
      target="disabled_import_button"
      v-if="disabledButton || loading"
      :title="$t('dashboard.disabled_import_button')"
    ></b-tooltip>

    <!-- Modal Component -->
    <b-modal id="importDonors" size="lg" @hide="clearData">
      <div slot="modal-header">
        <h2>{{ $t("dashboard.import_modal_title") }}</h2>
        <div class="modal-header__hide" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button
          :class="
            successUpload || procLoading
              ? 'bttn bttn--lg bttn--orange el-right'
              : 'bttn bttn--lg bttn--blue'
          "
          @click.prevent="hideModal"
        >
          {{ $t("dashboard.hide_modal") }}
        </button>
        <button
          form="import-donation"
          v-if="!successUpload && !procLoading"
          @click.prevent="uploadFile"
          :disabled="loading || procLoading"
          class="bttn bttn--lg bttn--orange"
        >
          {{ $t("dashboard.import_donations") }}
        </button>
      </div>

      <form id="import-donation" @submit.prevent="uploadFile" class="entities-data">
        <!-- <errors-hendler v-if='errors' :errors='errors'></errors-hendler> -->

        <div v-if="!procLoading && !successUpload">
          <p>
            {{ $t("dashboard.import_donation_description") }}
            <a href="#" @click="dowloadSample">{{ $t("dashboard.impoprt_donation_dowloadfile") }}</a
            >. {{ $t("dashboard.import_donation_mandatory") }}
          </p>

          <label for="massUpload" class="upload-file"
            >{{ $t("dashboard.impoprt_donation_upload_file") }}<br />{{ fileName }}</label
          >
          <input
            ref="massUpload"
            type="file"
            id="massUpload"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            @change="fileLoad"
          />
        </div>

        <div v-if="procLoading">Uploading... {{ progress }}%</div>

        <div v-if="successUpload && uploadResult">
          <p class="success">{{ $t("dashboard.impoprt_donation_successful_import") }}</p>
          <p>
            {{ $t("dashboard.impoprt_donation_imported_number") }}:
            {{ uploadResult.imported_number }}
          </p>
          <p>
            {{ $t("dashboard.impoprt_donation_imported_effective_amount_sum") }}:
            {{ uploadResult.imported_effective_amount_sum }}
          </p>
          <p>
            {{ $t("dashboard.impoprt_donation_imported_charge_amount_sum") }}:
            {{ uploadResult.imported_charge_amount_sum }}
          </p>
        </div>

        <div class="loading" v-if="loadingTemplate">
          <LdsSpinner />
        </div>
      </form>

      <div class="error-box">
        <error-message :response.sync="errorResponse"></error-message>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";
import errorList from "@/components/error-message.vue";

export default {
  props: {
    campaignid: {
      default: 0,
    },
    type: {
      default: null,
    },
  },

  data() {
    return {
      file: undefined,
      fileName: "",
      errorResponse: null,
      errors: [],
      loading: false, // loading the API call
      procLoading: false, // the process of file uploading
      uploadResult: null, // json with result report
      successUpload: false, // if the import is comlpeted
      progress: 0,
      procID: 0,
      interval: null,
      loadingTemplate: false,
    };
  },

  components: {
    LdsSpinner,
    errorMessage: errorList,
  },

  computed: mapState({
    disabledButton: (state) => {
      if (state.dashboard.campaignDashboard.data) {
        return (
          state.dashboard.campaignDashboard.data.attributes.mode != 1 &&
          state.dashboard.campaignDashboard.data.attributes.mode != 2
        );
      } else {
        return false;
      }
    },
  }),

  watch: {
    procID: function () {
      if (this.procID > 0 && this.interval == null) {
        this.initProcTracking();
      }
    },
  },

  methods: {
    clearData: function () {
      this.file = undefined;
      this.fileName = "";
      this.errors = [];
      (this.errorResponse = null), (this.loading = false); // loading the API call
      this.procLoading = false; // the process of file uploading
      this.uploadResult = null; // json with result report
      this.successUpload = false; // if the import is comlpeted
      this.progress = 0;
      this.procID = 0;
      this.interval = null;
      const input = this.$refs.massUpload;
      if (input) {
        input.type = "text";
        input.type = "file";
      }
    },

    initProcTracking: function () {
      this.interval = setInterval(this.checkProcProgress, 2000);
    },

    checkProcProgress: function () {
      if (this.procID == 0) {
        return;
      }
      let orgId = localStorage.getItem("orgId");
      // do axios request
      axios
        .get(urls.procProgressTracker.replace(":oid", orgId).replace(":id", this.procID))
        .then((response) => {
          this.progress = response.data.data.attributes.progress;

          if (response.data.data.attributes.finished) {
            clearInterval(this.interval);

            this.$emit("get-donors-list");

            this.successUpload = true;
            this.procLoading = false;
            if (response.data.data.attributes.meta != null) {
              this.uploadResult = JSON.parse(response.data.data.attributes.meta);
            }
          } else {
            this.procLoading = true;
          }
        })
        .catch((e) => {
          this.errorResponse = e.response;
          this.errors.push(e.response);
        });
    },

    openModal() {
      this.$root.$emit("bv::show::modal", "importDonors");
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "importDonors");
    },

    dowloadSample: function (e) {
      e.preventDefault();
      this.loadingTemplate = true;
      this.errors = [];
      let orgId = localStorage.getItem("orgId");
      axios
        .get(urls.massUpload.replace(":campaignId", this.campaignid).replace(":orgId", orgId), {
          params: {
            type: this.type,
          },
        })
        .then((response) => {
          this.loadingTemplate = false;
          let linkButton = document.createElement("a");
          linkButton.setAttribute("target", "_parent");
          linkButton.setAttribute("href", response.data.link);
          linkButton.classList.add("link");
          document.querySelector("body").appendChild(linkButton);
          linkButton.click();
          linkButton.remove();
        })
        .catch((e) => {
          this.errorResponse = e.response;
          this.errors.push(e.response);
        });
    },

    uploadFile: function () {
      this.errors = [];
      this.errorResponse = null;

      if (!this.file) {
        this.errors = [];
        this.errors.push("Please upload your file");
      } else {
        this.loading = true;
        let data = new FormData();
        data.append("file", this.file);
        let orgId = localStorage.getItem("orgId");
        axios
          .post(
            urls.massUpload.replace(":orgId", orgId).replace(":campaignId", this.campaignid),
            data,
            {
              params: {
                type: this.type,
              },
            }
          )
          .then((response) => {
            this.file = undefined;
            this.fileName = "";
            this.loading = false; // api request is over
            this.procID = parseInt(response.data.data.id); // set process id to track progress
            this.procLoading = true;
          })
          .catch((e) => {
            this.errors = [];
            this.errorResponse = e.response;
            if (e.response.data) {
              if (e.response.data.error) {
                this.errors.push(e.response.data.error);
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
            this.loading = false;
          });
      }
    },

    fileLoad: function (e) {
      this.fileName = e.target.files[0] ? e.target.files[0].name : "";
      this.file = e.target.files[0];
    },
  },
};
</script>

<style lang="scss" scoped>
#import-donation {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.upload-file {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  cursor: pointer;
  border: 1px dashed #cecece;
  text-align: center;
}

#massUpload {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
}

.export-list {
  margin-top: 20px;
}

.loading {
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
