<template>
  <div>
    <div class="d-flex justify-content-center">
      <button v-if="!success" class="bttn bttn--lg bttn--orange" @click="send" :disabled="loading">
        <b-spinner v-if="loading" small></b-spinner>
        {{ btnTitle }}
      </button>
      <b-alert v-else variant="success" show>
        {{
          $t(
            "dashboard.resubscribe_success",
            "New subscription(ID: {new_subscription_id}) created for {remaining} {period} ",
            result
          )
        }}
      </b-alert>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls";
import { notificationsMixin } from "@/mixins";

export default {
  props: {
    donationID: {
      type: [Number, String],
      default: 0,
    },
    donationAttributes: {
      type: Object,
      default: () => {},
    },
  },

  mixins: [notificationsMixin],

  data() {
    return {
      success: false,
      loading: false,
      result: {
        duration: 0,
        success_charges: 0,
        remaining: 0,
        new_subscription_id: "",
        period: "",
      },
    };
  },

  computed: {
    orgId() {
      return localStorage.getItem("orgId");
    },
    btnTitle() {
      return this.$t("dashboard.resubscribe", "Re-subscribe");
    },
  },

  methods: {
    send() {
      this.loading = true;
      axios
        .post(
          urls.recurringDonationResubscribe
            .replace(":oid", this.orgId)
            .replace(":cid", this.donationAttributes.campaign_id)
            .replace(":did", this.donationID)
        )
        .then(({ data }) => {
          this.$_notificationsMixin_makeToast("Success", "", "success");
          this.success = true;
          this.result = data.data;
        })
        .catch((e) => {
          this.$_notificationsMixin_handleCatch(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
