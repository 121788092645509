<template>
  <div class="chooseaccount donor-wrapper">
    <header class="main-header-component">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-lg justify-content-between align-items-center">
          <a :href="`${appHost}`" class="navbar-brand">
            <CdnLogoByLang />
          </a>
        </nav>
      </div>
    </header>

    <div class="container-fluid main">
      <div class="chooseaccount-body">
        <h2>{{ $t("account.choose_account", "Choose an Account") }}</h2>

        <div
          class="chooseAccount-container d-flex align-items-center"
          v-for="account in accounts"
          :key="account.id"
        >
          <img :src="account.img" />
          <div class="chooseAccount-text">
            <p>{{ account.organizationName }}</p>
            <p>
              <router-link :to="account.logInUrl">{{ account.organizationUrl }}</router-link>
            </p>
          </div>
          <b-button class="bttn bttn--lg bttn--orange continue-btn" :to="account.logInUrl">
            {{ $t("account.continue", "Continue") }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CdnLogoByLang from "@/common-components/cdn-v2/cdn-logo-by-lang.vue";
import { dashboardHost, appHost } from "@/constants/urls.js";

export default {
  data() {
    return {
      appHost,
    };
  },
  computed: {
    accounts() {
      return [
        {
          id: 0,
          img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/organization.svg",
          organizationName: this.$t(
            "template.choose_account_organization_account",
            "Organization account"
          ),
          organizationUrl: `${dashboardHost}/dashboard`,
          logInUrl: "/dashboard",
        },
        {
          id: 1,
          img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/donor.svg",
          organizationName: this.$t(
            "template.choose_account_donor_account",
            "Donor (personal) account"
          ),
          organizationUrl: `${dashboardHost}/donor-log-in`,
          logInUrl: "/donor-log-in",
        },
      ];
    },
  },
  components: { CdnLogoByLang },
};
</script>

<style lang="scss">
@import "@/styles/_vars.scss";
.chooseaccount.donor-wrapper {
  min-width: initial;
}
.donor-wrapper {
  min-height: 100vh;
  padding-top: 40px;
  background: url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco1.svg")
      top 400% left 20% no-repeat,
    url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco2.svg")
      top 100% right 20% no-repeat,
    #f8fbfd;
  display: flex;
  flex-direction: column;
  .main-header-component {
    padding: 16px 0;
    margin-bottom: 83px;
    .container-fluid {
      max-width: 1770px;
      margin: 0 auto;
      &:after {
        display: block;
        content: "";
        clear: both;
      }
    }
    .navbar-brand {
      display: inline-flex;
      width: 160ppx;
      height: 120px;
      margin: 14px 1rem 14px 0;
      background-image: none;
      svg {
        width: 100%;
        height: auto;
      }
    }
    .bttn--lg {
      font-size: 16px;
      padding: 15px 33px;
      height: 46px;
      border: none !important;
    }
  }
}
.chooseaccount-body {
  margin: 0 auto;
  @media only screen and (min-width: 768px) {
    max-width: 800px;
  }
  h2 {
    text-align: center;
    margin-bottom: 50px;
  }
  .chooseAccount-container {
    margin-bottom: 30px;
    background: #fff;
    padding: 20px 30px;
    justify-content: space-between;
    gap: 10px;
    img {
      margin-right: 25px;
    }
    .chooseAccount-text {
      flex: 2;
      p {
        margin-bottom: 6px;
      }
    }
    .continue-btn {
      max-width: 150px;
      width: 100%;
      &:active {
        background-color: #f26e66;
        border-color: transparent;
        opacity: 0.8;
      }
      &:hover {
        border-color: transparent;
      }
    }
  }
}
@media all and (max-width: 767px) {
  .chooseaccount .main-header-component {
    margin-bottom: 0px;
  }
  .chooseaccount-body {
    h2 {
      font-size: 26px;
      margin-bottom: 20px;
    }
    .chooseAccount-container {
      text-align: center;
      padding: 20px;
      &.d-flex {
        display: block !important;
      }
      img {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    .bttn {
      margin-top: 12px;
    }
  }
  .chooseAccount-text {
    p {
      flex: initial;
      a {
        font-size: 14px;
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
