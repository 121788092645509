<template>
  <div class="action-block">
    <button class="bttn bttn--sm bttn--orange" :disabled="!hasAccess" @click="openModal">
      {{ $t("gateways.add_gateways", "Add new") }}
    </button>

    <b-modal
      id="gatewayadd"
      size="lg"
      :no-close-on-backdrop="true"
      :hide-footer="!gatewayStep"
      footer-class="gateway-footer"
      @hidden="clearData"
    >
      <div slot="modal-header">
        <h2>
          <span v-if="gatewayStep" @click="backToList">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              enable-background="new 0 0 24 24"
              xml:space="preserve"
            >
              <path d="M15.41,16.59L10.83,12l4.58-4.59L14,6l-6,6l6,6L15.41,16.59z" />
              <path fill="none" d="M0,0h24v24H0V0z" />
            </svg>
          </span>
          {{ formTitle }}
        </h2>
        <div class="modal-header__hide" @click="modalClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>
      <div slot="modal-footer">
        <portal-target slim name="gateway-add-footer" />
      </div>

      <div v-if="gatewayDataView">
        <AccGatewayView
          ref="modalViewData"
          :gateway="gatewayDataView"
          @update-step="modalClose"
        ></AccGatewayView>
      </div>

      <div v-else>
        <div v-if="!gatewayStep">
          <ViewByOrgSetting
            class="w-100"
            org-setting="dashboardGatewaysTabList"
            v-slot="{ meta: dashboardGatewaysTabList }"
          >
            <div
              class="w-100"
              v-if="
                dashboardGatewaysTabList.value &&
                dashboardGatewaysTabList.meta &&
                Object.keys(dashboardGatewaysTabList.meta).length > 0
              "
            >
              <b-tabs class="w-100 mb-3" v-model="currentTab">
                <b-tab
                  v-for="(gatewayCategory, i) in Object.keys(dashboardGatewaysTabList.meta)"
                  no-body
                  :key="gatewayCategory"
                  :title-link-class="{
                    'tab--active': currentTab === i,
                    'text-dark': currentTab !== i,
                  }"
                >
                  <template #title>
                    {{ $t(`gateways.tab_${gatewayCategory}`) }}
                  </template>
                  <div
                    :key="i"
                    v-for="(categoryGateways, i) in getGatewaysFromCategory(
                      dashboardGatewaysTabList.meta,
                      gatewayCategory
                    )"
                  >
                    <b-card
                      v-if="i === 0 && categoryGateways.length > 0"
                      :title="$t(`gateways.tab_category_preferred`, 'Preferred gateways')"
                      class="gateways-add-select-preferred mb-2"
                    >
                      <div class="gateways-add-select">
                        <GatewayAddItem
                          v-for="[key, gateway] in categoryGateways"
                          :key="key"
                          :gateway-key="key"
                          :gateway="gateway"
                          @click="gatewaysHandle"
                        />
                      </div>
                    </b-card>
                    <div class="gateways-add-select" v-else-if="i === 1">
                      <GatewayAddItem
                        v-for="[key, gateway] in categoryGateways"
                        :key="key"
                        :gateway-key="key"
                        :gateway="gateway"
                        @click="gatewaysHandle"
                      />
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
            <div class="gateways-add-select" v-else>
              <GatewayAddItem
                v-for="[key, gateway] in Object.entries(gatewayListFiltered)"
                :key="key"
                :gateway-key="key"
                :gateway="gateway"
                @click="gatewaysHandle"
              />
            </div>
          </ViewByOrgSetting>
        </div>
        <div v-else>
          <component
            v-if="currentComponent"
            :is="currentComponent.component"
            :entities="entities"
            :entitiesFullList="entitiesFullList"
            :editdata="editGatewaData"
            :stripeAccounts="stripeAccounts"
            :gatewayName="currentComponent.gatewayName"
            :isBit="currentComponent.isBit"
            :isGooglePay="currentComponent.isGooglePay"
            :isAch="currentComponent.isAch"
            @close-modal="modalClose()"
            @back-to-list="backToList"
            @create-entity="createEntity"
            @create-stripe-acc="gatewayStep = 'stripe'"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ViewByOrgSetting from "@/views/campaigns/campaign-wizards/viewByOrgSetting.vue";
import GatewayAddItem from "./gatewayAddItem.vue";
import AccGatewayView from "./accGatewayView.vue";

export default {
  GATEWAY_LIST_CONFIG: {
    stripe: {
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/stripe.png",
      component: () => import("./accStripe.vue"),
    },
    "stripe-element": {
      title: "Element",
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/stripe.png",
      gatewayName: "stripe-element",
      component: () => import("./accFromStripe.vue"),
    },
    "stripe-ideal": {
      title: "Stripe iDeal",
      gatewayName: "stripe-ideal",
      component: () => import("./accFromStripe.vue"),
    },
    stripe_becs: {
      title: "Stripe BECS",
      component: () => import("./accStripeBecs.vue"),
    },
    stripe_apple_google_pay: {
      title: "Stripe Apple/Google Pay",
      component: () => import("./accStripeAppleGooglePay.vue"),
    },
    yaadpay: {
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/yaadpay.png",
      component: () => import("./accYaadpay.vue"),
    },
    paypal: {
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/paypal.png",
      component: () => import("./accPaypal.vue"),
    },
    israeltoremet: {
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/israeltoremet.png",
      component: () => import("./accIsraeltoremet.vue"),
    },
    payfast: {
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/payfast.png",
      component: () => import("./accFastPay.vue"),
    },
    payme: {
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/payme_01.png",
      component: () => import("./accPayme.vue"),
    },
    broom: {
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/broom.jpg",
      component: () => import("./accBroomFoundation.vue"),
    },
    asser: {
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/asseb.png",
      component: () => import("./accAsser.vue"),
    },
    achisomoch: {
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1708023150_6431333764373962656534623831383666616564336630383931616431636637_31373038303233313530.png",
      component: () => import("./accAchisomoch.vue"),
    },
    braintree: {
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1560882051285651734.png",
      component: () => import("./accBraintree.vue"),
    },
    mercadopago: {
      img: "https://http2.mlstatic.com/ui/navigation/4.1.4/mercadopago/logo__large@2x.png",
      component: () => import("./accMercadoPago.vue"),
    },
    "hk-bank-transfer": {
      title: "HK Bank Transfer",
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/business-and-finance-glyph-10-512.png",
      component: () => import("./accHkBankTransfer.vue"),
    },
    pledge: {
      title: "Pledge",
      component: () => import("./accPledge.vue"),
    },
    ojc: {
      title: "OJC",
      component: () => import("./accOjc.vue"),
    },
    paygate: {
      title: "PayGate",
      component: () => import("./accPayGate.vue"),
    },
    paytrail: {
      title: "Paytrail",
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1633978140_8f30fd61588aea0ab2a016859da18522_1633978140.jpg",
      component: () => import("./accCheckoutFI.vue"),
    },
    meshulam: {
      title: "Meshulam",
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1603128033630529584.png",
      component: () => import("./accMeshulam.vue"),
    },
    "meshulam-bit": {
      title: "Meshulam-Bit",
      isBit: true,
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1603128111820534712.png",
      component: () => import("./accMeshulamBit.vue"),
    },
    "meshulam-google-pay": {
      title: "Meshulam Google Pay",
      isGooglePay: true,
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/images/281/Google_Pay_Logo.svg_1699261306.png",
      component: () => import("./accMeshulam.vue"),
    },
    mhtrust: {
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1654181780_8aa03b8a611b307207537b5b703dadea_1654181780.png",
      component: () => import("./accMhTrust.vue"),
    },
    zoreyatzedokos: {
      title: "Zoreya Tzedokos",
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1625604140_335bf636f6a7b54521e0a8fb73a41d80_1625604140.png",
      component: () => import("./accZoreyaTzedokos.vue"),
    },
    threepillars: {
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1625604113_5adf8a08f84b634255601c00731b1ff6_1625604113.png",
      component: () => import("./accThreepillars.vue"),
    },
    kolyom: {
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1618239698909704069.jpg",
      component: () => import("./accKolYom.vue"),
    },
    kcbobov: {
      img: "https://online.kcbobov.co.uk/assets/images/logo.png",
      component: () => import("./accKcbobov.vue"),
    },
    yadshlomo: {
      img: "https://yadshlomo.org/assets/images/logo.png",
      component: () => import("./accYadShlomo.vue"),
    },
    belzvouchers: {
      img: "https://belzvouchers.com/assets/images/logo.png",
      component: () => import("./accBelzVouchers.vue"),
    },
    uta: {
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1708023171_3761373764663633633838326462383338363065363231333932356236323265_31373038303233313731.png",
      component: () => import("./accUTA.vue"),
    },
    cmz: {
      img: "https://online.cmz.org.uk/assets/images/logo.png",
      component: () => import("./accCMZ.vue"),
    },
    tevini: {
      img: "https://www.tevini.co.uk/assets/image/logo/logo.png",
      component: () => import("./accTevini.vue"),
    },
    dlocal: {
      title: "DLocal",
      component: () => import("./accDLocal.vue"),
    },
    nedarimplus: {
      title: "NedarimPlus",
      component: () => import("./accNedarimPlus.vue"),
    },
    nedarimplusbit: {
      title: "NedarimPlusBit",
      isBit: true,
      component: () => import("./accNedarimPlus.vue"),
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1603128111820534712.png",
    },
    tranzila: {
      title: "Tranzila",
      component: () => import("./accTranzila.vue"),
    },
    "tranzila-bit": {
      title: "Tranzila-Bit",
      isBit: true,
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1603128111820534712.png",
      component: () => import("./accTranzilaBit.vue"),
    },
    funds: {
      title: "Funds",
      component: () => import("./accFunds.vue"),
    },
    pelecard: {
      title: "Pelecard",
      component: () => import("./accPelecard.vue"),
    },
    walletdoc: {
      title: "Walletdoc",
      component: () => import("./accWalletdoc.vue"),
    },
    coinbase: {
      title: "Coinbase",
      component: () => import("./accCoinbase.vue"),
    },
    cardcom: {
      title: "CardCom",
      component: () => import("./accCardCom.vue"),
    },
    "cardcom-bit": {
      title: "CardCom Bit",
      component: () => import("./accCardCom.vue"),
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1603128111820534712.png",
      isBit: true,
    },
    "icredit-rivhit": {
      title: "iCredit",
      component: () => import("./accICreditRivhit.vue"),
    },
    jaffa: {
      title: "Jaffa",
      component: () => import("./accJaffa.vue"),
    },
    "jaffa-bit": {
      title: "Jaffa Bit",
      isBit: true,
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1603128111820534712.png",
      component: () => import("./accJaffaBit.vue"),
    },
    "blink-fidelipay": {
      title: "Blink Fidelipay",
      component: () => import("./accBlinkFidelipay.vue"),
    },
    aminut: {
      title: "Aminut",
      component: () => import("./accAminut.vue"),
    },
    cardknox: {
      title: "Cardknox",
      component: () => import("./accCardknox.vue"),
    },
    kehilot: {
      title: "Kehilot",
      component: () => import("./accKehilot.vue"),
    },
    "kehilot-bit": {
      title: "Kehilot Bit",
      isBit: true,
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1603128111820534712.png",
      component: () => import("./accKehilot.vue"),
    },
    donorsfund: {
      title: "The Donors Fund",
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/images/281/Stacked_1667904124.png",
      component: () => import("./accDonorsFund.vue"),
    },
    pledger: {
      title: "Pledger",
      component: () => import("./accPledger.vue"),
    },
    mancal: {
      title: "Mancal",
      component: () => import("./accMancal.vue"),
    },
    "mancal-bit": {
      title: "Mancal Bit",
      isBit: true,
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1603128111820534712.png",
      component: () => import("./accMancal.vue"),
    },
    usaepay: {
      title: "USAePay",
      component: () => import("./accUsaepay.vue"),
    },
    banquest: {
      title: "Banquest",
      component: () => import("./accBanquest.vue"),
    },
    "banquest-ach": {
      title: "Banquest ACH",
      isAch: true,
      component: () => import("./accBanquest.vue"),
    },
    gama: {
      title: "Gama",
      component: () => import("./accGama.vue"),
    },
    "gama-bit": {
      title: "Gama Bit",
      isBit: true,
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1603128111820534712.png",
      component: () => import("./accGama.vue"),
    },
    donary: {
      title: "Donary",
      component: () => import("./accDonary.vue"),
    },
    authorize: {
      title: "Authorize",
      component: () => import("./accAuthorize.vue"),
    },
    payarc: {
      title: "Payarc",
      img: "https://payarc.com/wp-content/uploads/2021/11/logo.svg",
      component: () => import("./accPayarc.vue"),
    },
    matbia: {
      title: "Matbia",
      component: () => import("./accMatbia.vue"),
    },
    peach: {
      title: "Peach",
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/images/281/logo-PEACH_1675767208.png",
      component: () => import("./accPeach.vue"),
    },
    "peach-bit": {
      title: "Peach Bit",
      isBit: true,
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1603128111820534712.png",
      component: () => import("./accPeach.vue"),
    },
    "powerdoc-direct-debit": {
      title: "Powerdoc Direct Debit",
      isBit: true,
      component: () => import("./accPowerdocDirectDebit.vue"),
    },
    sumit: {
      title: "Sumit",
      component: () => import("./accSumit.vue"),
    },
    chariot: {
      title: "Chariot",
      img: "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1718029313_3237646230636631376635313234346337326330633030643162356332333230_31373138303239333133.svg",
      component: () => import("./accChariot.vue"),
    },
  },

  components: {
    ViewByOrgSetting,
    GatewayAddItem,
    AccGatewayView,
  },

  data() {
    return {
      gatewayStep: "",
      gatewayDataView: false,
      formTitle: "Add Payment Gateway",
      editGatewaData: null,
      currentTab: 0,
    };
  },

  computed: {
    ...mapState({
      hasAccess: (state) => {
        return state.account.access.CanEditOrganizationGatewayList;
      },

      stripeAccounts: (state) => {
        return state.gateways.gateways.filter(
          (g) => g.attributes.gateway === "stripe" && g.attributes.active
        );
      },

      entities: function (state) {
        let entitiesList = state.account.entities;

        let resultArr = [];

        if (entitiesList.length > 1) {
          let defaultObj = {
            text: this.$t("gateways.select_entities"),
            value: 0,
            disabled: true,
          };

          resultArr.push(defaultObj);
        }

        for (var i = 0; i < entitiesList.length; i++) {
          let obj = {
            text: entitiesList[i].attributes.name,
            value: parseInt(entitiesList[i].id),
          };

          resultArr.push(obj);
        }

        return resultArr;
      },

      entitiesFullList(state) {
        let entitiesList = state.account.entities;

        let resultArr = [];

        if (entitiesList.length > 1) {
          let defaultObj = {
            text: this.$t("gateways.select_entities"),
            value: null,
            disabled: true,
          };

          resultArr.push(defaultObj);
        }

        for (var i = 0; i < entitiesList.length; i++) {
          let obj = {
            text: entitiesList[i].attributes.name,
            value: entitiesList[i],
          };

          resultArr.push(obj);
        }

        return resultArr;
      },

      useStripeOnboarding: (state) => state.account.useStripeOnboarding,
    }),

    gatewayListFiltered() {
      const list = this.$options.GATEWAY_LIST_CONFIG;

      if (list?.stripe && this.useStripeOnboarding) {
        list.stripe = {
          ...list.stripe,
          component: () => import("./accStripeOnboarding.vue"),
        };
      }

      return list;
    },

    currentComponent() {
      const gateway = this.gatewayListFiltered[this.gatewayStep];
      if (gateway) {
        return gateway;
      }

      return null;
    },
  },

  methods: {
    backToList: function () {
      this.gatewayStep = "";
      this.formTitle = this.$t("gateways.add_gateways");
    },

    openModal(gatewayStep) {
      this.gatewayStep = "";
      if (typeof gatewayStep === "string") {
        this.gatewaysHandle(gatewayStep);
      }
      this.formTitle = this.$t("gateways.add_gateways");
      this.$root.$emit("bv::show::modal", "gatewayadd");
    },

    modalClose() {
      this.$root.$emit("bv::hide::modal", "gatewayadd");
    },

    createEntity: function () {
      this.modalClose();
      this.$emit("create-entity", this.gatewayStep);
    },

    clearData: function () {
      this.formTitle = "";

      if (this.gatewayDataView) {
        this.gatewayDataView = false;
      }

      this.gatewayStep = "";
      this.editGatewaData = null;
    },

    openModalView(getawaysIngo) {
      this.formTitle = this.$t("gateways.view_data_" + getawaysIngo.attributes.gateway);
      this.gatewayDataView = getawaysIngo;
      this.$root.$emit("bv::show::modal", "gatewayadd");
    },

    openEdit(obj) {
      this.$refs.modalViewData.clearDataModa();
      this.gatewayDataView = false;
      this.gatewayStep = obj.getwayname;
      this.editGatewaData = obj.data;
    },

    gatewaysHandle(props) {
      this.gatewayStep = props;
      this.formTitle = this.$t("gateways.add_" + props);
    },

    getGatewaysFromCategory(items, categoryItem) {
      const list = this.$options.GATEWAY_LIST_CONFIG;
      const categoryGateways = {};
      const categoryGatewaysPreferred = {};

      for (const item of items[categoryItem]) {
        const keyOrDefault = item?.key || item;
        const gateway = list[keyOrDefault];
        if (!gateway) {
          continue;
        }

        const orPreferred = item.preferred ? categoryGatewaysPreferred : categoryGateways;

        orPreferred[keyOrDefault] = {
          ...gateway,
          title: item?.label || gateway?.title,
        };

        if (orPreferred?.stripe && this.useStripeOnboarding) {
          orPreferred.stripe = {
            ...orPreferred.stripe,
            component: () => import("./accStripeOnboarding.vue"),
          };
        }
      }

      if (items[categoryItem].includes("$rest")) {
        for (const [key, gateway] of Object.entries(list)) {
          if (categoryGateways[key]) {
            continue;
          }
          categoryGateways[key] = { ...gateway };
        }
      }

      return [Object.entries(categoryGatewaysPreferred), Object.entries(categoryGateways)];
    },
  },
};
</script>

<style lang="scss" scoped>
.gateways-add-select-preferred {
  border-radius: 0;

  :deep(.card-body) {
    padding: 10px;
  }

  :deep(.card-title) {
    text-align: center;
    margin-bottom: 0;
    font-family: $vito-bold;
    font-size: 12px;
    color: $fd;
  }
}
.modal-header {
  position: relative;
  h2 {
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;
    span {
      display: inline-block;
      cursor: pointer;
      margin-right: 10px;
      position: absolute;
      top: 0;
      left: -30px;
      svg {
        fill: gray;
      }
    }
  }
}
.gateways-add-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#gatewayadd {
  .gateway-form {
    .form-block {
      margin-bottom: 20px;

      h3 {
        font-size: 24px;
        color: #20434e;
        padding: 10px 0;
      }

      label,
      .b-skeleton {
        margin-bottom: 10px;
        padding: 0;
        font-family: $vito-light;
        text-transform: uppercase;
        font-size: 14px;
        color: $fd;
      }
    }

    &__action {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    &__notice {
      margin-bottom: 20px;
      font-size: 16px;
      color: #20434e;

      a {
        font-size: 16px;
        font-weight: 500;
        color: #cc6d4c;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
  .gateway-footer {
    display: flex;
    width: 100%;
    ::v-deep .vue-portal-target {
      display: flex;
      width: 100%;
    }
  }
  .tab {
    &--active {
      color: #ffffff !important;
      background-color: $bb-100 !important;
    }
  }
}

:deep(.gateway-form) {
  .gateway-form {
    &__action {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
