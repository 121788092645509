<template>
  <div>
    <b-modal ref="modal" modal-class="app-modal" size="md" centered>
      <div slot="modal-header">
        <div class="modal-header__close" @click.prevent="hide">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
        <h2 class="h1-like">
          {{ $t("donor_dashboard.org_contacts", "Organization contacts") }}
        </h2>
      </div>

      <OrgContacts :org-attrs="orgAttrs" />

      <div slot="modal-footer" class="justify-content-center">
        <button class="bttn bttn--blue" @click.prevent="hide">
          {{ $t("dashboard.close", "Close") }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import OrgContacts from "./org-contacts.vue";

export default {
  components: {
    OrgContacts,
  },

  data() {
    return {
      orgAttrs: {},
    };
  },

  methods: {
    show(orgAttrs) {
      this.orgAttrs = orgAttrs;
      this.$refs.modal.show();
    },

    hide() {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.app-modal {
  .modal-header {
    position: relative;

    &__close {
      display: inline-flex;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .modal-content {
    overflow: hidden;
  }
}
</style>
