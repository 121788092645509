<template>
  <div>
    <b-modal centered hide-header hide-footer size="md" id="alert">
      <b-form class="info-modal">
        <b-row>
          <b-col cols="12">
            <p class="info-modal__message">{{ message }}</p>
          </b-col>
          <b-col cols="12">
            <div class="info-modal__action">
              <button @click="closeModal" type="button" class="bttn bttn--sm bttn--orange">
                OK
              </button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "InfoModal",
  props: {
    message: {
      type: String,
      default: "Done!",
    },
  },
  methods: {
    openModal() {
      this.$bvModal.show("alert");
    },
    closeModal() {
      this.$bvModal.hide("alert");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-body {
  border-radius: 8px;
}
.info-modal {
  &__message {
    font-size: 18px;
    text-align: center;
    margin-bottom: 25px;
  }
  &__action {
    width: 100%;
    display: flex;
  }
}
.bttn {
  margin: 0 auto;
}
</style>
