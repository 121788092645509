<template>
  <div class="reminder-unit" @click="reminderDetail">
    <div class="reminder-unit__cell reminder-unit__cell--campaign">
      <div class="cell-label">{{ $t("leads.campaigns") }}</div>
      {{ returnCampaign(remind.attributes.campaign_id) }}
    </div>

    <div class="reminder-unit__cell reminder-unit__cell--name">
      <div class="cell-label">{{ $t("leads.name") }}</div>
      {{ remind.attributes.name }}
    </div>

    <div class="reminder-unit__cell reminder-unit__cell--amount">
      <div class="cell-label">{{ $t("leads.amount") }}</div>
      {{ remind.attributes.amount }}
    </div>

    <div class="reminder-unit__cell reminder-unit__cell--phone">
      <div class="cell-label">{{ $t("leads.phone") }}</div>
      {{ remind.attributes.phone }}
    </div>

    <div class="reminder-unit__cell reminder-unit__cell--email">
      <div class="cell-label">{{ $t("leads.email") }}</div>
      {{ remind.attributes.email }}
    </div>

    <div class="reminder-unit__cell reminder-unit__cell--date">
      <div class="cell-label">{{ $t("leads.date") }}</div>
      {{ returnDate(remind.attributes.created_at) }}
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "ReminderUnit",
  props: {
    remind: {
      type: Object,
      default: () => {},
    },
    campaigns: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    returnCampaign(campaignId) {
      let campaign = this.campaigns.filter((el) => {
        return el.id == campaignId;
      });
      if (campaign.length > 0) {
        return campaign[0].attributes.title;
      }
      return "";
    },
    returnDate(date) {
      if (date) {
        return moment.unix(date).format("YYYY-MM-DD");
      }
      return "";
    },
    reminderDetail() {
      this.$emit("viewReminderDetails", this.remind);
    },
  },
};
</script>

<style lang="scss" scoped>
.reminder-unit {
  margin-top: -1px;
  min-height: 90px;
  padding: 15px 20px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  border: 1px solid #e6eaed;
  border-radius: 4px;
  background-color: #ffffff;
  transition: all 0.5s;
  cursor: pointer;
  @media only screen and (min-width: 468px) {
    padding: 15px 40px;
  }
  @media only screen and (min-width: 1024px) {
    flex-wrap: nowrap;
    align-items: center;
  }
  &:hover {
    background: $bg-20;
  }

  &__cell {
    padding: 15px 0 5px;
    margin-bottom: 10px;
    font-size: 16px;
    color: $fd;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    @media only screen and (min-width: 1024px) {
      margin-bottom: initial;
      font-size: 14px;
      padding: 0 5px;
      margin-bottom: initial;
      position: static;
    }
    .cell-label {
      display: inline-block;
      font-family: $vito-bold;
      font-size: 12px;
      letter-spacing: 1px;
      color: $fl;
      text-transform: uppercase;
      position: absolute;
      top: 0;
      @media only screen and (min-width: 1024px) {
        display: none;
        position: static;
      }
    }
    &--campaign {
      width: 100%;
      font-family: $vito-bold;
      color: $bb-100;
      @media only screen and (min-width: 1024px) {
        min-width: 20%;
        max-width: 20%;
        order: initial;
      }
    }
    &--name {
      width: 50%;
      order: 3;
      @media only screen and (min-width: 1024px) {
        min-width: 15%;
        max-width: 15%;
        order: initial;
      }
    }
    &--amount {
      width: 50%;
      order: 2;
      text-align: right;
      color: $lr-100;
      .cell-label {
        right: 0;
      }
      @media only screen and (min-width: 1024px) {
        min-width: 10%;
        max-width: 10%;
        order: initial;
        text-align: initial;
      }
    }
    &--phone {
      width: 50%;
      order: 4;
      text-align: right;
      .cell-label {
        right: 0;
      }
      @media only screen and (min-width: 1024px) {
        min-width: 15%;
        max-width: 15%;
        order: initial;
        text-align: initial;
      }
    }
    &--email {
      width: 50%;
      order: 5;
      @media only screen and (min-width: 1024px) {
        min-width: 25%;
        max-width: 25%;
        order: initial;
      }
    }
    &--date {
      width: 50%;
      order: 1;
      @media only screen and (min-width: 1024px) {
        min-width: 15%;
        max-width: 15%;
        order: initial;
      }
    }
  }
}
// rtl styles
#app.he {
  .reminder-unit {
    &__cell {
      &--campaign {
        text-align: right;
      }
      &--name {
        text-align: right;
      }
      &--email {
        text-align: right;
      }
      &--date {
        text-align: right;
      }
    }
  }
}
</style>
