import urls from "../../constants/urls.js";
import axios from "axios";

const getUrl = (cId, id = "") => {
  const orgId = localStorage.getItem("orgId");
  const posturl = String(id) && `/${id}`;

  return urls.addDescription.replace(":orgId", orgId).replace(":campaignId", cId) + posturl;
};

const getData = (attr, cId) => {
  return {
    data: {
      attributes: {
        campaign_id: Number(cId),
        ...attr,
      },
    },
  };
};

const state = {
  loading: false,
  error: null,
};

const mutations = {
  setLoading(state, value) {
    state.loading = value;
  },

  setError(state, val) {
    state.error = val;
  },
};

const actions = {
  fetchCreateContent(_, { cId, data, queryParams = {} }) {
    const url = getUrl(cId);
    const params = {
      ...queryParams,
    };
    const payload = getData(data, cId);

    return axios.post(url, payload, { params });
  },

  fetchUpdateContent(_, { cId, id, data, queryParams = {} }) {
    const url = getUrl(cId, id);
    const params = {
      ...queryParams,
    };
    const payload = getData(data, cId, id);

    return axios.put(url, payload, { params });
  },

  fetchDeleteContent(_, { cId, id }) {
    const url = getUrl(cId, id);

    return axios.delete(url);
  },
};

export default {
  state,
  actions,
  mutations,
};
