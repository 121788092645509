import axios from "axios";
import urls from "@/constants/urls.js";

const currentYear = new Date().getFullYear();

const state = {
  donations: [],
  included: [],
  donationsTotal: 0,
  subscriptionsTotal: 0,
  donationsSearch: 0,
  downloadRecepitsLink: "",
  loadingDonation: true,
  years: Array(10)
    .fill(0)
    .map((_, i) => currentYear - i),
};

const mutations = {
  setDonorDonations(state, data) {
    state.donations = data;
  },
  setIncluded(state, value) {
    state.included = value;
  },
  setDonorLoadingDonations(state, value) {
    state.loadingDonation = value;
  },
  setDonorDonationsTotal(state, value) {
    state.donationsTotal = value;
  },
  setDonorSubscriptionsTotal(state, value) {
    state.subscriptionsTotal = value;
  },
  setDonorDonationsSearch(state, value) {
    state.donationsSearch = value;
  },
  setDownloadRecepitsLink(state, value) {
    state.downloadRecepitsLink = value;
  },
  setDonorDonationsYears(state, value) {
    state.years = value;
  },
};

const actions = {
  async getDonorDonationsInfo({ commit }, props) {
    commit("setDonorLoadingDonations", true);
    try {
      const response = await axios.get(urls.donorDonationsList + props.setting);
      let donations = response.data.data ? response.data.data : [];

      const { included } = response.data;

      commit("setIncluded", included);

      commit("setDonorDonations", donations);

      commit("setDonorDonationsTotal", response.headers["x-total-donations"]);
      commit("setDonorSubscriptionsTotal", response.headers["x-total-subscriptions"] || 0);
      commit("setDonorDonationsSearch", response.headers["x-search-donations"]);
      commit("setDonorLoadingDonations", false);
      return donations;
    } catch (e) {
      console.log(e);
      commit("setDonorLoadingDonations", false);
    }
  },
};

export default {
  state,
  actions,
  mutations,
  //getters
};
