<template>
  <div class="action-block">
    <b-dropdown
      variant="-sm bttn--orange"
      :disabled="!canCreateCampaign"
      :text="$t('add_campaign.create_new_campaign_button')"
    >
      <b-dropdown-item @click="openModal('charidyToGo')">
        {{ $t("add_campaign.create_charidy_to_go", "Charidy To-Go") }}
      </b-dropdown-item>
      <b-dropdown-item @click="openModal('crowdfundingFundraising')">
        {{ $t("add_campaign.create_crowdfunding_fundraising", "Crowdfunding/Fundraising") }}
      </b-dropdown-item>
      <b-dropdown-item @click="openModal('crowdfundingFundraising_holidayTemplates')">
        {{ $t("add_campaign.create_holiday", "Holiday") }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { OpenCrowdfundingFundraisingModal, OpenCharidyToGoModal } from "@/constants/providers";

export default {
  inject: {
    OpenCrowdfundingFundraisingModal,
    OpenCharidyToGoModal,
  },

  props: {
    crowdfundingFundraisingOptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      newCampaign: {
        campaignCurrency: "USD",
        goalAmount: "",
        startDate: "",
        endDate: "",
        campaignTitle: "",
      },
      errors: [],
      validation: [],
      sending: false,
    };
  },

  components: {},

  created() {},

  computed: {
    ...mapState({
      canCreateCampaign: (state) => state.account.access.CanCreateCampaign,
    }),
    crowdfundingFundraisingOptionsAsKeys() {
      return this.crowdfundingFundraisingOptions.reduce((acc, curr) => {
        acc[curr.key] = curr.disabled;
        return acc;
      }, {});
    },
  },
  methods: {
    openModal(type) {
      if (type === "charidyToGo") {
        this.$store.dispatch("getGateways");
        this.OpenCharidyToGoModal();
      } else if (type === "crowdfundingFundraising") {
        this.OpenCrowdfundingFundraisingModal({
          hiddenSteps: {
            ...this.crowdfundingFundraisingOptionsAsKeys,
          },
        });
      } else if (type === "crowdfundingFundraising_holidayTemplates") {
        this.OpenCrowdfundingFundraisingModal({
          showOnlyHolidayTemplates: true,
          hiddenSteps: {
            ...this.crowdfundingFundraisingOptionsAsKeys,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.goal-input {
  .dropdown {
    display: flex;
    &.open {
      .btn {
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
      }
    }
    .btn {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top: 1px solid #e6eaed !important;
      border-bottom: 1px solid #e6eaed !important;
      border-left: 1px solid #e6eaed !important;
      background-color: #ffffff;
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      position: relative;
      color: #20434e;
      &:active,
      &:focus,
      &:hover {
        color: #20434e;
        outline: 0;
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
        box-shadow: none;
      }
      span {
        position: absolute;
        top: 22px;
        right: 5px;
      }
    }
    .dropdown-toggle {
      min-width: 65px;
      font-size: 15px;
      position: relative;
      &:after {
        position: absolute;
        right: 6px;
        top: calc(50% - 1px);
      }
    }
    &-menu {
      min-width: initial;
      padding: 0;
      width: 100%;
      border-radius: 0;
    }
    &-item {
      height: 45px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: gray;
      text-transform: uppercase;
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(32, 67, 78, 0.15);
      }
    }
  }
}

.description-set {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
  h3 {
    margin: 0 0 20px;
    padding: 0;
    font-family: "ProximaNova-Bold", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #20434e;
  }
  &__unit {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (min-width: 992px) {
      width: 46%;
    }
    textarea {
      width: 100%;
      resize: none;
    }
  }
  &__unit:not(:last-of-type) {
    margin-bottom: 20px;
    @media only screen and (min-width: 992px) {
      margin-bottom: 0;
    }
  }
}

#campaign-add {
  .dropdown-toggle {
    text-transform: uppercase; // !important;
  }
  .dropdown-item {
    text-transform: uppercase;
  }

  .date-time-select {
    .form-unit__addon-icon {
      display: none;
      @media only screen and (min-width: 992px) {
        display: flex;
      }
    }
    .form-unit__input {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      @media only screen and (min-width: 992px) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.alert-danger li {
  width: 100%;
  margin-left: 0;
}

.vdatetime {
  width: 100%;
}

.vdatetime-popup {
  backface-visibility: hidden;
  transform: translate3d(-50%, calc(-50% - 0.5px), 0px);
  &__header {
    background-color: $bb-100;
  }
}

.vdatetime-calendar {
  &__month__day {
    &--selected {
      &:hover {
        > span > span {
          background-color: $bb-80;
        }
      }
      > span > span {
        background-color: $bb-100;
      }
    }
  }
}

.vdatetime-year-picker__item {
  &--selected {
    color: $bb-100;
  }
}

.vdatetime-time-picker__item {
  &--selected {
    color: $bb-100;
  }
}

.language-selected__unit {
  &.add-modal {
    padding: 0px 10px 0 20px;
  }
}
</style>
