import axios from "axios";
import urls from "../../constants/urls.js";

const state = {
  campaign: null,
  campaigns: [],
  mediaList: [],
  mediaListGrouped: [],

  loading: false,
  mediaListLoading: false,
  createFileLoading: false,
};

const mutations = {
  setCampaigns(state, data) {
    state.campaigns = data;
  },

  setMediaList(state, data) {
    state.mediaList = data;
  },

  setMediaListGrouped(state, data) {
    state.mediaListGrouped = data;
  },

  setCampaign(state, data) {
    state.campaign = data;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  setMediaListLoading(state, value) {
    state.mediaListLoading = value;
  },

  setCreateFileLoading(state, value) {
    state.createFileLoading = value;
  },

  setCreateEditMediaLoading(state, value) {
    state.createEditMediaLoading = value;
  },
};

const getters = {
  getMediaList(state) {
    const arr = state.mediaList.map((el) => ({
      ...el.attributes,
      id: el.id,
      type: el.type,
      _attributes: el.attributes,
    }));

    return arr;
  },
};

function groupMediaList(mediaList) {
  const items = mediaList.map((el) => ({
    ...el.attributes,
    id: el.id,
    type: el.type,
    _attributes: el.attributes,
  }));

  items.sort((a, b) => b.order - a.order);

  const groupedByTag = {};
  for (const item of items) {
    const tag = item.tag.length === 0 ? "no_tag" : item.tag;
    if (!groupedByTag[tag]) {
      groupedByTag[tag] = [];
    }
    groupedByTag[tag].push(item);
  }

  const result = [];
  for (const tag in groupedByTag) {
    const cloned = groupedByTag[tag];
    result.push(...cloned);
  }

  return result;
}

const actions = {
  selectCampaign({ commit, dispatch }, cmp) {
    commit("setCampaign", cmp);
    dispatch("fetchCampaignMediaList", { cID: cmp.id });
  },

  fetchCampaigns({ commit, dispatch }) {
    commit("setLoading", true);
    const orgId = localStorage.getItem("orgId");
    const extendParams = ["campaign_stats", "media", "meta", "donation_levels", "donation_streams"];
    axios
      .get(urls.getCampaignList.replace(":orgId", orgId), { params: { extend: extendParams } })
      .then(({ data }) => {
        commit("setCampaigns", data.data);
        dispatch("selectCampaign", data.data[0]);
        commit("setLoading", false);
      });
  },

  fetchCreateFileUrl({ commit }, { file }) {
    commit("setCreateFileLoading", true);

    return new Promise((resolve) => {
      if (file instanceof File || file instanceof Blob) {
        const formData = new FormData();

        formData.append("file", file);

        axios.post(urls.loadImageQuill, formData).then((response) => {
          resolve(response.data.src);
          commit("setCreateFileLoading", false);
        });
      } else {
        resolve(file);
      }
    });
  },

  async fetchCampaignMediaList({ commit }, { cID, localLoading = false } = {}) {
    !localLoading && commit("setMediaListLoading", true);

    const orgId = localStorage.getItem("orgId");
    const params = {
      extend: "media",
    };

    try {
      const { data } = await axios.get(
        urls.getCampaignData.replace(":orgId", orgId).replace(":campaignId", cID),
        { params }
      );
      commit("setMediaList", data.included || []);
      commit("setMediaListGrouped", groupMediaList(data.included || []));
    } finally {
      !localLoading && commit("setMediaListLoading", false);
    }
  },

  async fetchCreateEditMedia({ commit, dispatch }, { mID, form, localLoading = false } = {}) {
    !localLoading && commit("setCreateEditMediaLoading", true);

    const orgId = localStorage.getItem("orgId");

    let payload = {
      data: {
        attributes: form,
      },
    };

    try {
      await axios[mID ? "put" : "post"](
        `${urls.loadImage.replace(":orgId", orgId).replace(":campaignId", form.campaign_id)}${
          mID ? `/${mID}` : ""
        }`,
        payload
      );
      await dispatch("fetchCampaignMediaList", { cID: form.campaign_id, localLoading });
    } finally {
      !localLoading && commit("setCreateEditMediaLoading", false);
    }
  },

  fetchRemoveMedia({ dispatch }, { cID, mID }) {
    const orgId = localStorage.getItem("orgId");

    return axios
      .delete(urls.deleteImage.replace(":oid", orgId).replace(":cid", cID).replace(":mid", mID))
      .then(() => {
        dispatch("fetchCampaignMediaList", { cID });
      });
  },

  batchDeleteMedia({ commit, dispatch }, { cID, items }) {
    commit("setLoading", true);
    const orgId = localStorage.getItem("orgId");
    const deletePromises = items.map((item) => {
      const newUrl = urls.deleteImage
        .replace(":oid", orgId)
        .replace(":cid", cID)
        .replace(":mid", item.id);
      return axios.delete(newUrl);
    });
    return Promise.all(deletePromises)
      .then((response) => {
        dispatch("fetchCampaignMediaList", { cID });
        return response;
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
