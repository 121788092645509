<template>
  <ul v-if="errors.length > 0" :class="{ 'alert-danger': alert }">
    <li class="error-item" v-for="(error, i) in errors" :key="`error-${i}`">
      {{ getText(error) }}
    </li>
  </ul>
</template>

<script>
const DEFAULTMESSAGE = [
  { code: 404, message: "The resource is not found" },
  { code: 500, message: "Some error happened, please contact support." },
  { code: 400, message: "Some error happened, please contact support." },
];

export default {
  props: ["response", "customMessage", "alert"],
  data() {
    return {
      errors: [],
    };
  },

  methods: {
    getText(err) {
      if (Array.isArray(err) && err.length > 1) {
        return this.$t(...err);
      }

      return err;
    },

    setErrorResponse(response) {
      if (response && response.data) {
        if (response.data.error) {
          const data = response.data;

          this.errors.push([data.error_translation_key, data.error]);
        } else if (response.data.errors) {
          this.errors = response.data.errors.map((e) => e.detail);
        } else {
          this.errors.push(response.data);
        }
      } else if (response) {
        if (this.customMessage) {
          this.errors.push(this.$t(this.customMessage));
        } else {
          DEFAULTMESSAGE.map((i) => {
            if (i.code === response.status) {
              this.errors.push(i.message);
            }
          });
        }
      }
    },

    clearErrors() {
      this.errors = [];
    },
  },

  watch: {
    response: function () {
      if (this.response) {
        this.setErrorResponse(this.response);
      } else {
        this.errors = [];
      }
    },
  },
};
</script>

<style scoped>
ul {
  margin: 0;
}
.alert-danger li {
  margin: 0;
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 5px;
}

.error-item {
  color: red;
}
</style>
