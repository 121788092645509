<template>
  <div id="disabled_import_button">
    <!-- Modal Component -->
    <b-modal id="importTeams" size="lg" @hidden="clearData">
      <div slot="modal-header">
        <h2>{{ $t("edit_campaign.import_teams_modal_title") }}</h2>
        <div class="modal-header__hide" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button
          :class="
            successUpload || procLoading
              ? 'bttn bttn--lg bttn--orange el-right'
              : 'bttn bttn--lg bttn--blue'
          "
          @click.prevent="hideModal"
        >
          {{ $t("edit_campaign.hide_modal") }}
        </button>
        <button
          form="import-donation"
          v-if="!successUpload && !procLoading"
          @click.prevent="uploadFile"
          :disabled="loading || procLoading"
          class="bttn bttn--lg bttn--orange"
        >
          {{ $t("edit_campaign.import_teams") }}
        </button>
      </div>

      <form id="import-donation" @submit.prevent="uploadFile" class="entities-data">
        <div v-if="!procLoading && !successUpload">
          <p>
            {{ $t("edit_campaign.import_teams_description") }}
            <a href="#" @click="dowloadSample">
              {{ $t("edit_campaign.import_teams_dowloadfile") }}
            </a>
            {{ $t("edit_campaign.import_teams_mandatory") }}
          </p>

          <label for="massUpload" class="upload-file">
            {{ $t("edit_campaign.import_teams_upload_file") }}
            <br />
            {{ fileName }}
          </label>
          <input
            type="file"
            id="massUpload"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            @change="fileLoad"
          />

          <b-form-group
            :label="$t('edit_campaign.import_teams_after_send_label', 'After import send')"
            class="mt-2"
          >
            <b-form-select v-model="afterSend" :options="afterSendOptions"></b-form-select>
          </b-form-group>
        </div>

        <div v-if="procLoading">
          {{ $t("edit_campaign.import_teams_uploading") }}... {{ progress }}%
        </div>

        <div v-if="successUpload && uploadResult">
          <p class="success">{{ $t("edit_campaign.import_teams_successful_import") }}</p>
          <p>
            {{ $t("edit_campaign.import_teams_imported_number") }}:
            {{ uploadResult.imported_number }}
          </p>
        </div>

        <div class="loading" v-if="loadingTemplate">
          <LdsSpinner />
        </div>
      </form>

      <div class="error-box">
        <error-message :response.sync="errorResponse"></error-message>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import errorList from "@/components/error-message.vue";
import urls from "@/constants/urls.js";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";

export default {
  props: ["campaignId"],

  name: "campaign-import-teams",
  data() {
    return {
      file: undefined,
      fileName: "",
      errors: [],
      errorResponse: null,
      loading: false, // loading the API call
      procLoading: false, // the process of file uploading
      uploadResult: null, // json with result report
      successUpload: false, // if the import is comlpeted
      progress: 0,
      procID: 0,
      importGroup: null,
      interval: null,
      loadingTemplate: false,
      afterSend: null,
      afterSendOptions: [
        {
          text: this.$t(
            "edit_campaign.import_teams_afret_send_nothing",
            "Please select an option or leave empty"
          ),
          value: null,
        },
        {
          text: this.$t("edit_campaign.import_teams_afret_send_email", "Email"),
          value: "email",
        },
        {
          text: this.$t("edit_campaign.import_teams_afret_send_sms", "SMS notification"),
          value: "sms",
        },
      ],
    };
  },
  components: {
    LdsSpinner,
    errorMessage: errorList,
  },

  watch: {
    procID: function () {
      if (this.procID > 0 && this.interval == null) {
        this.initProcTracking();
      }
    },
  },

  methods: {
    openModal() {
      this.$root.$emit("bv::show::modal", "importTeams");
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "importTeams");
    },

    initProcTracking: function () {
      this.interval = setInterval(this.checkProcProgress, 2000);
    },

    checkProcProgress: function () {
      if (this.procID == 0) {
        return;
      }
      let orgId = localStorage.getItem("orgId");
      // do axios request
      axios
        .get(urls.procProgressTracker.replace(":oid", orgId).replace(":id", this.procID))
        .then((response) => {
          const attr = response.data.data.attributes;
          this.progress = response.data.data.attributes.progress;

          if (response.data.data.attributes.finished) {
            clearInterval(this.interval);

            this.$emit("update-team-list");
            this.$parent?.loadNewPageTeams?.();
            this.successUpload = true;
            this.procLoading = false;

            if (this.afterSend === "sms") {
              this.$emit("send-sms", { importGroup: this.importGroup });
              this.hideModal();
            }

            if (this.afterSend === "email") {
              this.$emit("send-email", { importGroup: this.importGroup });
              this.hideModal();
            }

            if (attr.meta != null && attr.meta != "") {
              this.uploadResult = JSON.parse(attr.meta);
            } else {
              this.uploadResult = { imported_number: 0 };
            }
          } else {
            this.procLoading = true;
          }
        })
        .catch((e) => {
          this.errorResponse = e.response;
          this.errors.push(e.response);
        });
    },

    dowloadSample: function (e) {
      let orgId = localStorage.getItem("orgId");
      e.preventDefault();
      this.loadingTemplate = true;
      this.errors = [];

      axios
        .get(
          urls.importTeams
            .replace(":orgId", orgId)
            .replace(":campaignId", this.$route.params.id ? this.$route.params.id : this.campaignId)
        )
        .then((response) => {
          this.loadingTemplate = false;
          let linkButton = document.createElement("a");
          linkButton.setAttribute("target", "_parent");
          linkButton.setAttribute("href", response.data.link);
          linkButton.classList.add("link");
          document.querySelector("body").appendChild(linkButton);
          linkButton.click();
          linkButton.remove();
        })
        .catch((e) => {
          this.errors.push(e.response);
        });
    },

    uploadFile: function () {
      this.errors = [];
      let orgId = localStorage.getItem("orgId");
      if (!this.file) {
        this.errors = [];
        this.errors.push("Please upload your file");
      } else {
        this.loading = true;
        let data = new FormData();
        data.append("file", this.file);
        axios
          .post(
            urls.importTeams
              .replace(":orgId", orgId)
              .replace(
                ":campaignId",
                this.$route.params.id ? this.$route.params.id : this.campaignId
              ),
            data
          )
          .then((response) => {
            const attrs = response.data.data.attributes;

            this.file = undefined;
            this.fileName = "";

            this.loading = false; // api request is over
            this.importGroup = attrs.group;
            this.procID = attrs.proc_session.ID; // set process id to track progress
            this.procLoading = true;
          })
          .catch((e) => {
            this.errors = [];
            if (e.response?.data) {
              if (e.response.data.error) {
                this.errors.push(e.response.data.error);
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
            this.errorResponse = e.response;
            this.loading = false;
          });
      }
    },

    fileLoad: function (e) {
      if (e.target.files.length > 0) {
        this.fileName = e.target.files[0].name;
        this.file = e.target.files[0];
      } else {
        this.fileName = "";
        this.file = null;
      }
    },

    clearData: function () {
      this.file = undefined;
      this.fileName = "";
      this.errors = [];
      this.errorResponse = null;
      this.loading = false; // loading the API call
      this.procLoading = false; // the process of file uploading
      this.uploadResult = null; // json with result report
      this.successUpload = false; // if the import is comlpeted
      this.progress = 0;
      this.procID = 0;
      this.interval = null;
      this.afterSend = null;
    },
  },
};
</script>

<style scoped>
.error-box {
  margin-bottom: -30px;
}
#import-donation {
  /* // display: flex;
  // align-items: center;
  // justify-content: space-between; */
  padding-top: 20px;
  padding-bottom: 20px;
}

.upload-file {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  cursor: pointer;
  border: 1px dashed #cecece;
  text-align: center;
}

#massUpload {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
}

.export-list {
  margin-top: 20px;
}

.loading {
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
