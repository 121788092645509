<script>
import Vue from "vue";
import { mapGetters } from "vuex";

const VueComponent = Vue.extend({
  props: {
    internalValue: {
      type: Boolean,
      default: false,
    },
    internalLockedType: {
      type: String,
      default: "",
    },
    onLockedChange: {
      type: Function,
      required: true,
    },
    hasThresholdsError: {
      type: String,
      default: "",
    },
    thresholdChangeRateLoading: Boolean,
  },
  computed: {
    ...mapGetters({
      getMetasInSelectedCampaign: "getMetasInSelectedCampaign",
    }),

    allowConditionalDonations() {
      const data = this.getMetasInSelectedCampaign?.allow_conditional_donations;

      if (data?.value) {
        return data;
      }

      return {};
    },
    thresholdsDisabled() {
      return !!this.hasThresholdsError || this.thresholdChangeRateLoading;
    },
    show() {
      return (
        this.allowConditionalDonations?.value &&
        this.allowConditionalDonations?.types_supported.includes("peer_match")
      );
    },
  },
  methods: {
    onChange(event) {
      this.onLockedChange("peer_match", event.target.checked);
    },
  },
});

export default VueComponent;
</script>

<template>
  <b-list-group-item v-if="show" class="extra-panel">
    <div class="extra-panel__input">
      <input
        id="peer_match"
        :checked="internalValue && internalLockedType === 'peer_match'"
        type="checkbox"
        name="allow_conditional_donations"
        :disabled="thresholdsDisabled"
        @change="onChange"
      />
    </div>
    <div>
      <h4 class="extra-panel__title">
        {{
          $t(
            "donation.peer_match_label",
            "Donate on condition my donation gets matched by a new donor!"
          )
        }}
      </h4>
      <p class="extra-panel__description">
        {{
          $t(
            "donation.peer_match_description",
            `Your donation will be showed on the campaign page, inspiring others to match it.
            The donation amount will contribute to the campaign goal once a matching donor is found!`
          )
        }}
      </p>
      <p v-if="thresholdChangeRateLoading" class="extra-panel__description">
        <span>
          {{ $t("donation.peer_match_loading", "Loading...") }}
        </span>
      </p>
      <p
        v-else-if="hasThresholdsError && !thresholdChangeRateLoading"
        class="extra-panel__description extra-panel__description--error"
      >
        <span>
          {{ hasThresholdsError }}
        </span>
      </p>
    </div>
    <div class="extra-panel__img">
      <img
        width="100"
        height="100"
        :src="
          [
            'https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com',
            'public_upload',
            '1717202103_6434396435656537323565313730396238356463666536353333363438366338_31373137323032313033.png',
          ].join('/')
        "
      />
    </div>
  </b-list-group-item>
</template>

<style lang="scss" scoped>
.extra-panel {
  padding-inline: 20px;
  gap: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  &__title {
    margin: 0px;
    font-size: 14px;
    font-weight: bold;
  }

  &__description {
    margin: 0;
    font-size: 14px;
    font-weight: 400;

    &--error {
      color: red;
    }
  }

  &__input {
    input {
      width: 30px;
      height: 30px;
    }
  }

  &__img {
    width: 150px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
