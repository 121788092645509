import urls from "../../constants/urls.js";
import Vue from "vue";
import axios from "axios";

export function getDirection(lang) {
  switch (lang) {
    case "he":
    case "ar":
      return "rtl";
    default:
      return "ltr";
  }
}

const state = {
  translation: {},
  locate: "en",
  loadTranslation: true,
};

const mutations = {
  setTranslation(state, data) {
    state.translation = data;
  },

  setLoadingTranslation(state, value) {
    state.loadTranslation = value;
  },

  setLocate(state, value) {
    const html = document.querySelector("html");
    html.setAttribute("lang", value);
    html.classList.value = "";
    html.classList.add(value);
    document.dir = getDirection(value);
    state.locate = value;
  },
};

const getters = {
  loadingTranslation: (state) => () => state.loadTranslation,
  changeTranslation: (state) => () => state.translation,
  selectedLang: (state) => () => state.locate,
};

const actions = {
  getTranslation({ state, commit }) {
    const groups = `group=${[
      "template",
      "donors",
      "header",
      "donation",
      "dashboard",
      "training",
      "campaigns",
      "add_campaign",
      "account",
      "gateways",
      "edit_campaign",
      "teams",
      "leads",
      "integration",
      "email_confirm",
      "users",
      "payouts",
      "reports",
      "video_tutorials",
      "donor_dashboard",
      "dashboard_calendar_module",
    ].join("&group=")}`;

    axios
      .get(
        urls.getTransition
          .replace(":group", groups)
          .replace(":location", `loc=${state.locate}&loc=en`)
      )
      .then((response) => {
        let transitionData = response.data;
        commit("setTranslation", null);
        commit("setTranslation", transitionData);
        commit("setLoadingTranslation", false);
      });
  },
  setTranslationPermanent({ dispatch }, newLang) {
    const success = dispatch("setTranslation", newLang);

    if (success) {
      if (process.env.NODE_ENV === "development") {
        document.cookie = "language=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";
        document.cookie = `language=${newLang}; path=/`;
      }

      document.cookie =
        "language=; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.charidy.com; path=/";
      document.cookie = `language=${newLang}; domain=.charidy.com; path=/`;
    }
  },
  setTranslation({ state, commit }, newLang) {
    newLang = newLang || "en";
    if (state.locate === newLang) {
      return false;
    }

    commit("setLocate", newLang);
    Vue.i18n.set(state.locate || "en");
    return true;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
