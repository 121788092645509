import urls from "@/constants/urls";
import axios from "axios";

export function oldProcRequestInterval(procId) {
  const token = localStorage.getItem("jwt_token");
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .get(urls.procDonorAccountProgressTracker.replace(":id", procId))
        .then((res) => {
          const data = res.data.data;
          let attrs;

          try {
            attrs = Object.fromEntries(
              Object.entries(data.attributes).map(([k, v]) => {
                if (k === "meta") {
                  return [k, JSON.parse(v)];
                }

                return [k, v];
              })
            );
          } catch (e) {
            clearInterval(interval);
            reject(e);
          }

          if (attrs.finished) {
            clearInterval(interval);
            resolve({
              finished: attrs.finished,
              progress: attrs.progress,
              attrs,
            });
            return;
          }

          resolve({
            finished: attrs.finished,
            progress: attrs.progress,
            attrs,
          });
        })
        .catch(reject);
    }, 5000);
  });
}
