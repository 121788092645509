<script>
import { mapState } from "vuex";
import { campaignStatusMixin, campaignStatusMixinProps } from "./campaign-status-mixin";
import { getFavoriteCampaign } from "@/helpers/storage";

export default {
  getFavoriteCampaign,

  props: {
    styledDropdownBtn: Boolean,
    ...campaignStatusMixinProps,
  },
  mixins: [campaignStatusMixin],
  computed: {
    ...mapState({
      canEditCampaignDetails: (state) => {
        return state.account.access.CanEditCampaignDetails;
      },
      canCreateCampaign: (state) => {
        return state.account.access.CanCreateCampaign;
      },
    }),
  },
  methods: {
    editAction() {
      this.$emit("edit", this.campaignState);
    },
    cloneAction() {
      if (this.canCreateCampaign) {
        this.$emit("clone", this.campaignState);
      }
    },
    deleteAction() {
      if (this.canEditCampaignDetails) {
        this.$emit("delete", this.campaignState);
      }
    },
  },
};
</script>

<template>
  <div class="actions-btn" :class="{ 'styled-dropdown-btn': styledDropdownBtn }">
    <b-dropdown right :variant="styledDropdownBtn ? 'primary' : 'link'" size="lg" no-caret>
      <template #button-content>
        <slot name="button-content">
          <i class="fa fa-ellipsis-v" />
        </slot>
      </template>
      <b-dropdown-item @click.prevent="editAction">
        {{ $t("campaigns.edit") }}
      </b-dropdown-item>
      <b-dropdown-item :class="{ disabled: !canCreateCampaign }" @click="cloneAction">
        {{ $t("campaigns.clone") }}
      </b-dropdown-item>
      <b-dropdown-item :class="{ disabled: !canEditCampaignDetails }" @click="deleteAction">
        {{ $t("campaigns.delete") }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="$options.getFavoriteCampaign() === campaignId"
        @click="$emit('unfavorite')"
      >
        {{ $t("campaigns.favorite", "Unfavorite") }}
      </b-dropdown-item>
      <b-dropdown-item v-else @click="$emit('favorite')">
        {{ $t("campaigns.favorite", "Favorite") }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<style lang="scss" scoped>
.actions-btn {
  display: flex;
  justify-content: flex-end;
  i {
    color: #fff;
  }
  &.styled-dropdown-btn {
    ::v-deep .btn.dropdown-toggle {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $bb-100;
      border-color: $bb-100;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      transition: background-color 0.2s ease-in-out;
      &:hover,
      &:not(:disabled):not(.disabled):active,
      &:focus {
        background-color: $bb-90;
        border-color: $bb-90;
      }
    }
  }
  ::v-deep .dropdown-item {
    &:focus {
      outline: none;
    }
  }
}
</style>
