<script>
import { notificationsMixin } from "@/mixins";
import {
  REQUEST_UNDER_REVIEW,
  REQUEST_DECLINED,
  REQUEST_ACCEPTED,
} from "@/store/modules/foundationRequest";
import { mapActions } from "vuex";

export default {
  REQUEST_UNDER_REVIEW,
  REQUEST_DECLINED,
  REQUEST_ACCEPTED,

  mixins: [notificationsMixin],
  props: {
    state: { type: Object, default: () => ({}) },
    setState: { type: Function, default: () => {} },
    options: { type: Object, default: () => ({}) },
    error: { type: [Error, Object, Boolean], default: () => ({}) },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      pandadocDownloadDocument: "foundationRequest/pandadocDownloadDocument",
    }),
    async download() {
      this.loading = true;
      try {
        const link = await this.pandadocDownloadDocument({
          documentId: this.state.statusRequestData.document_ref,
        });

        const a = document.createElement("a");
        a.href = link;
        a.target = "_blank";
        a.click();
        a.remove();
      } catch (e) {
        console.error(e);

        if (e instanceof Error) {
          this.$_notificationsMixin_makeToastCompat({
            group: "Notification",
            title: this.$t("account.request_foundation_modal_status_error", "Error"),
            type: "danger",
            body: e.message,
          });
        } else {
          this.$_notificationsMixin_handleCatch(e);
        }
      } finally {
        this.loading = false;
      }
    },
    formatRequestStatus(requestData) {
      if (!requestData) {
        return this.$t("account.request_foundation_modal_status_undefined", "Unknown status");
      }

      if (requestData.status === this.$options.REQUEST_UNDER_REVIEW) {
        return this.$t(
          "account.request_foundation_modal_status_under_review",
          "Your application is under review"
        );
      } else if (requestData.status === this.$options.REQUEST_DECLINED) {
        return this.$t(
          "account.request_foundation_modal_status_declined",
          "Your application was declined"
        );
      } else if (requestData.status === this.$options.REQUEST_ACCEPTED) {
        return this.$t(
          "account.request_foundation_modal_status_accepted",
          "Your application is already accepted"
        );
      } else {
        return this.$t("account.request_foundation_modal_status_undefined", "Unknown status");
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="font-weight-bold">
          {{ formatRequestStatus(state.statusRequestData) }}
        </h4>
        <template v-if="state.statusRequestData">
          <span v-if="state.statusRequestData.status === $options.REQUEST_UNDER_REVIEW">
            {{
              $t(
                "account.request_foundation_modal_status_under_review_description",
                "You will be notified on update"
              )
            }}
          </span>
          <span v-else-if="state.statusRequestData.status === $options.REQUEST_ACCEPTED">
            {{
              $t(
                "account.request_foundation_modal_status_accepted_description",
                "The Charidy Foundation Legal Entity is added to your account as well as the payment methods"
              )
            }}
          </span>
          <span v-else-if="state.statusRequestData.status === $options.REQUEST_DECLINED">
            {{
              $t(
                "account.request_foundation_modal_status_declined_description",
                "Please contact your Campaign Manager for details"
              )
            }}
          </span>
          <b-alert v-else show variant="danger">
            {{
              $t(
                "account.request_foundation_modal_status_undefined_description",
                "Internal server error (status field contains unexptected value)"
              )
            }}
          </b-alert>
        </template>
        <b-alert v-else show variant="danger">
          {{
            $t(
              "account.request_foundation_modal_status_undefined_description",
              "Internal server error (status field contains unexptected value)"
            )
          }}
        </b-alert>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 text-center">
        <button
          type="button"
          class="mx-2 bttn bttn--lg bttn--orange"
          :disabled="loading"
          @click="download"
        >
          <b-spinner v-if="loading" small class="mx-2"></b-spinner>
          {{ $t("account.request_foundation_modal_status_download_pandadoc", "Download document") }}
        </button>
      </div>
    </div>
  </div>
</template>
