<script>
import { mapState } from "vuex";
import Dashboard from "./dashboard.vue";

export default {
  components: {
    Dashboard,
  },
  computed: {
    ...mapState({
      CanSeeCampaignDashboard: (state) => state.account.access.CanSeeCampaignDashboard,
    }),
  },
};
</script>

<template>
  <Dashboard v-if="CanSeeCampaignDashboard" />
  <div v-else>
    <b-alert show variant="warning">
      {{
        $t("template.no_access", "You don`t have access to this page. Failed ACL: {acl}", {
          acl: "SeeCampaignDashboard",
        })
      }}
    </b-alert>
  </div>
</template>
