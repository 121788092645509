import moment from "moment";

export const timeZoneUser = () => moment.tz.guess();

export const getDate = (seconds, format = "MMM D") => {
  const milliseconds = seconds * 1000;

  if (timeZoneUser()) {
    return moment(new Date(milliseconds)).tz(timeZoneUser()).format(format);
  }
  return moment(new Date(milliseconds)).format(format);
};

export const getTime = (seconds, format = "h a") => {
  const milliseconds = seconds * 1000;

  if (timeZoneUser()) {
    return moment(new Date(milliseconds)).tz(timeZoneUser()).format(format);
  }
  return moment(new Date(milliseconds)).format(format);
};

export const stringToDate = (str) => {
  if (typeof str === "string") {
    return new Date(str);
  }
  return str;
};

export const getOverlapedDateRange = (firstDateRange, secondDateRange) => {
  let { start: fSart, end: fEnd } = firstDateRange;
  let { start: sSart, end: sEnd } = secondDateRange;

  const overlappedRange = { start: null, end: null };

  fSart = stringToDate(fSart);
  fEnd = stringToDate(fEnd);
  sSart = stringToDate(sSart);
  sEnd = stringToDate(sEnd);

  // f-start -----------f-end
  //          s-start -------------- s-end
  const overlappedEnd = fSart <= sSart && fEnd >= sSart && fEnd <= sEnd;
  if (overlappedEnd) {
    overlappedRange.start = sSart;
    overlappedRange.end = fEnd;
  }

  // f-start ----------------------- f-end
  //         s-start --------- s-end
  const overlappedBetween = fSart <= sSart && fEnd >= sEnd;
  if (overlappedBetween) {
    overlappedRange.start = sSart;
    overlappedRange.end = sEnd;
  }

  //            f-start -----------f-end
  // s-start -------------- s-end
  const overlappedStart = fSart >= sSart && fSart <= sEnd && fEnd >= sEnd;
  if (overlappedStart) {
    overlappedRange.start = fSart;
    overlappedRange.end = sEnd;
  }

  return {
    firstDateRange,
    secondDateRange,
    overlappedRange,
    overlapped: overlappedEnd || overlappedBetween || overlappedStart,
    overlappedEnd,
    overlappedBetween,
    overlappedStart,
  };
};

export const diffDays = (a, b) => moment(b, "M/D/YYYY").diff(moment(a, "M/D/YYYY"), "days");

export const getEndDateOfInterval = (start, period, interval) => {
  const nd = new Date(start);

  if (period === "week") {
    const i = interval;

    if (i >= 0) {
      nd.setDate(nd.getDate() + 7 * i);
    }
  }

  return nd;
};
