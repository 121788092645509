<template>
  <div>
    <div class="campaign-edit-title">
      <h2 class="h2-like">{{ $t("edit_campaign.share_title") }}</h2>
    </div>
    <section class="matchers_page">
      <b-card
        class="mb-4"
        no-body
        :key="`edit-campaign-share_${item.name}`"
        v-for="(item, key) in shareItems"
      >
        <component :is="loadingComponent.is" v-bind="loadingComponent.attrs">
          <template #loading>
            <b-card-header
              class="p-0 campaing-share-list__header"
              :class="{ 'campaing-share-list__header--no-body': typeOfModel(key) === 'no_body' }"
            >
              <b-skeleton class="ml-3" width="70%" height="24px"></b-skeleton>
              <b-skeleton width="36px" height="24px"></b-skeleton>
            </b-card-header>
            <b-card-body v-if="typeOfModel(key) === 'normal_object'">
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card-body>
            <b-card-body v-else-if="typeOfModel(key) === 'translation_key'">
              <b-skeleton width="85%"></b-skeleton>
            </b-card-body>
            <template v-else-if="typeOfModel(key) === 'no_body'"> </template>
            <template v-else></template>
            <b-card-footer class="text-right" v-show="typeOfModel(key) === 'normal_object'">
              <div class="d-flex flex-row justify-content-end">
                <b-skeleton class="mr-2" width="158px" height="38px"></b-skeleton>
                <b-skeleton width="100px" height="38px"></b-skeleton>
              </div>
            </b-card-footer>
          </template>
          <b-card-header
            class="p-0 campaing-share-list__header"
            :class="{ 'campaing-share-list__header--no-body': typeOfModel(key) === 'no_body' }"
          >
            <span class="pl-3">
              <i v-if="item.iconClass" class="mr-2" :class="item.iconClass" aria-hidden="true"></i>
              <b-icon
                :icon="item.bootstrapIcon"
                v-else-if="item.bootstrapIcon"
                class="mr-2"
                aria-hidden="true"
              ></b-icon>
              {{ item.name }}
            </span>
            <b-form-checkbox
              :checked="item.active"
              name="check-button"
              switch
              @change="(active) => onClickCheckbox(key, active)"
            ></b-form-checkbox>
          </b-card-header>
          <b-card-body v-if="typeOfModel(key) === 'normal_object'">
            <b-form-group
              :id="`edit-campaign-share_${item.name}_${model.name}_fieldset`"
              :label="model.name"
              :label-for="`edit-campaign-share_${item.name}_${model.name}`"
              v-for="model in item.model"
              :key="`edit-campaign-share_${item.name}_${model.name}`"
              label-cols="2"
              :description="model.characters && description(model.value, model.characters)"
              v-show="!model.hidden"
            >
              <template v-if="!model.hidden">
                <component
                  :is="model.component"
                  :id="`edit-campaign-share_${item.name}_${model.name}`"
                  v-model="model.value"
                  rows="4"
                  no-resize
                  :state="model.characters && charactersState(model.value, model.characters)"
                >
                </component>
                <b-form-text class="font-weight-bold" v-if="model.helpText">
                  <b-icon-question-circle-fill />
                  {{ model.helpText }}
                </b-form-text>
              </template>
            </b-form-group>
          </b-card-body>
          <b-card-body v-else-if="typeOfModel(key) === 'translation_key'">
            <p>{{ item.model }}</p>
          </b-card-body>
          <template v-else-if="typeOfModel(key) === 'no_body'"> </template>
          <template v-else></template>
          <b-card-footer class="text-right" v-show="typeOfModel(key) === 'normal_object'">
            <button
              v-if="item.resolveAs !== 'meta'"
              class="bttn bttn--sm bttn--orange mr-2"
              @click="restore(key)"
              :disabled="loadingCampaignShare || !item.data"
            >
              {{ $t("edit_campaign.restore_btn") }}
            </button>
            <button
              class="bttn bttn--sm bttn--orange"
              @click="onClickSave(key, item)"
              :disabled="loadingCampaignShare || charactersStateBtnDisable(item.model)"
            >
              {{ $t("edit_campaign.save_btn") }}
            </button>
          </b-card-footer>
        </component>
      </b-card>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { notificationsMixin } from "@/mixins";
import { createSetting, getSetting, updateSetting } from "@/helpers/campaign-settings";

export default {
  mixins: [notificationsMixin],
  data() {
    return {
      firstLoad: false,

      shareItems: {
        facebook: {
          name: this.$t("edit_campaign.share_item_facebook", "Facebook"),
          active: false,
          iconClass: "fa fa-facebook",
          data: null,
          mapModelToItems: ["whatsapp"],
          model: {
            title: {
              name: "Message",
              value: "",
              component: "b-form-input",
            },
            quote: {
              name: "Description",
              value: "",
              component: "b-form-textarea",
              characters: 280,
            },
            url_for_share: {
              name: "Share URL",
              value: "",
              component: "b-form-input",
            },
          },
        },
        twitter: {
          name: this.$t("edit_campaign.share_item_twitter", "Twitter"),
          active: false,
          iconClass: "fa fa-twitter",
          data: null,
          model: {
            url_for_share: {
              name: "Share URL",
              value: "",
              component: "b-form-input",
            },
            description: {
              name: "Description",
              value: "",
              component: "b-form-textarea",
              characters: 280,
            },
          },
        },
        email: {
          name: this.$t("edit_campaign.share_item_email", "Email"),
          active: false,
          iconClass: "fa fa-envelope",
          data: null,
          model: {
            title: {
              name: "Subject",
              value: "",
              component: "b-form-input",
            },
            description: {
              hidden: true,
              value: "&nbsp;",
            },
            url_for_share: {
              name: "Share URL",
              helpText: "This field can be used for any links that will appear in email",
              value: "",
              component: "b-form-input",
              valueMod: {
                get(currVal) {
                  return currVal;
                },
                set(currVal) {
                  return currVal;
                },
              },
            },
          },
        },
        linkedin: {
          name: this.$t("edit_campaign.share_item_linkedin", "LinkedIn"),
          active: false,
          iconClass: "fa fa-linkedin",
          data: null,
          model: {
            url_for_share: {
              name: "Share URL",
              value: "",
              component: "b-form-input",
            },
          },
        },
        copy_link: {
          name: this.$t("edit_campaign.share_item_copy_link", "Copy Link"),
          active: false,
          iconClass: "fa fa-link",
          data: null,
          model: "",
        },
        whatsapp: {
          name: this.$t("edit_campaign.share_item_whatsapp", "WhatsApp"),
          active: false,
          iconClass: "fa fa-whatsapp",
          data: null,
          model: this.$t(
            "edit_campaign.whatsapp_please_use_facebook",
            "For whatsapp please use facebook"
          ),
        },
      },
    };
  },
  computed: {
    ...mapState({
      loadingCampaignShare: (state) => state.campaignShare.loading.share,
      oId: () => localStorage.getItem("orgId"),
      cId: (state) => state.campaign.campaign.data.id,
    }),
    charactersStateBtnDisable() {
      return (model) => {
        const found = this.extractCharactesData(model);
        if (found && !this.charactersState(found.value, found.characters)) {
          return true;
        }
        return false;
      };
    },
    loadingComponent() {
      if (this.firstLoad) {
        return {
          is: "b-skeleton-wrapper",
          attrs: {
            loading: this.firstLoad,
          },
        };
      }

      return {
        is: "b-overlay",
        attrs: {
          show: this.loadingCampaignShare,
          "spinner-small": true,
          rounded: true,
        },
      };
    },
  },
  mounted() {
    this.invalidateState();
  },
  methods: {
    ...mapActions({
      fetchShare: "campaignShare/fetchShare",
      createShare: "campaignShare/createShare",
      editShare: "campaignShare/editShare",
      deleteShare: "campaignShare/deleteShare",
    }),
    clearState() {
      const entries = Object.entries(this.shareItems);
      for (const [k, v] of entries) {
        const originalModel = this.shareItems[k].model;
        let model = originalModel;
        if (typeof originalModel !== "string") {
          model = this.clearModel(originalModel);
        }
        this.shareItems[k] = { ...v, active: false, data: null, model };
      }
    },
    mapStateToTrueState(data) {
      for (const item of data) {
        const attr = item.attributes;
        if (Object.prototype.hasOwnProperty.call(this.shareItems, attr.social)) {
          if (!this.shareItems[attr.social].data) {
            this.shareItems[attr.social].active = attr.active;
            this.shareItems[attr.social].data = item;
            for (const p in attr) {
              const model = this.shareItems[attr.social].model;
              if (typeof model !== "string") {
                const prop = model[p];
                if (prop && !prop?.valueMod?.set && prop.value.length === 0) {
                  prop.value = attr[p];
                } else if (prop && prop?.valueMod?.set && prop?.valueMod?.get) {
                  prop.value = prop.valueMod.get(attr[p]);
                }
              }
            }
          }
        }
      }
    },
    mapMetaState({ metaName, metaData, __data }) {
      Object.keys(metaData).forEach((k) => {
        const item = this.shareItems[metaName];

        if (k === "value") {
          const internalKey = k === "value" ? "active" : k;
          item[internalKey] = metaData[k];
        } else {
          item.model[k].value = metaData[k];
        }

        item.data = __data;
      });
    },
    getMetaShareItem() {
      return Promise.all(
        Object.entries(this.shareItems).map(([k, v]) => {
          if (v?.resolveAs === "meta") {
            return getSetting({ cid: this.cId, oid: this.oId }, k)
              .then((data) => {
                return {
                  metaName: data?.attributes?.meta_name || "",
                  metaData: data?.attributes?.meta_data || {},
                  __data: data,
                };
              })
              .catch(this.$_notificationsMixin_handleCatch);
          }

          return null;
        })
      );
    },
    invalidateState(firstLoad) {
      new Promise((resolve) => {
        firstLoad && (this.firstLoad = firstLoad);
        this.clearState();

        this.fetchShare([this.cId, {}, !firstLoad])
          .then(({ data }) => {
            resolve(true);
            this.mapStateToTrueState(data);
          })
          .catch((...args) => {
            resolve(false);
            this.$_notificationsMixin_handleCatch(...args);
          });

        this.getMetaShareItem()
          .then((promises) => {
            resolve(true);
            promises.forEach((data) => data !== null && this.mapMetaState(data));
          })
          .catch((e) => {
            resolve(false);
            throw e;
          });
      }).finally(() => {
        firstLoad && (this.firstLoad = false);
      });
    },
    onClickSave(key, item) {
      if (item?.resolveAs === "meta") {
        return this.saveMeta({
          metaName: key,
          metaValue: item.active,
          metaDataModel: item.model,
          metaData: item.data,
        });
      }

      return this.save(key);
    },
    save(key) {
      const item = this.shareItems[key];
      const options = {
        noLoading: true,
      };
      const internalSave = (id, key, attributes, model, active, invalidate = true) => {
        const metadata = {
          ...item.data.attributes,
          ...this.modelToMetadata(model),
          active,
        };
        if (id === "0") {
          this.createShare([this.cId, key, metadata, options])
            .then(() => invalidate && this.invalidateState())
            .catch(this.$_notificationsMixin_handleCatch);
        } else {
          this.editShare([this.cId, id, key, metadata, options])
            .then(() => invalidate && this.invalidateState())
            .catch(this.$_notificationsMixin_handleCatch);
        }
      };
      if (item.mapModelToItems && item.mapModelToItems.length !== 0) {
        item.mapModelToItems.forEach((mapModelKey) => {
          if (mapModelKey !== key) {
            const mapped = this.shareItems[mapModelKey];
            internalSave(
              mapped.data.id,
              mapModelKey,
              mapped.data.attributes,
              item.model,
              mapped.active,
              false
            );
          }
        });
      }
      internalSave(item.data.id, key, item.data.attributes, item.model, item.active);
    },
    saveMeta({ metaName, metaValue, metaDataModel, metaData }) {
      const data = {
        value: metaValue,
        ...this.modelToMetadata(metaDataModel),
      };

      if (!metaData?.id) {
        return createSetting({ cid: this.cId, oid: this.oId }, metaName, data)
          .then(() => this.invalidateState())
          .catch(this.$_notificationsMixin_handleCatch);
      }

      return updateSetting({ cid: this.cId, oid: this.oId }, metaName, metaData.id, data)
        .then(() => this.invalidateState())
        .catch(this.$_notificationsMixin_handleCatch);
    },
    restore(key) {
      const item = this.shareItems[key];
      const options = {
        noLoading: true,
      };
      const internalDelete = (social, invalidate = true) => {
        this.deleteShare([this.cId, social, options])
          .then(() => invalidate && this.invalidateState())
          .catch(this.$_notificationsMixin_handleCatch);
      };
      if (item.mapModelToItems && item.mapModelToItems.length !== 0) {
        item.mapModelToItems.forEach((mapModelKey) => {
          if (mapModelKey !== key) {
            const mapped = this.shareItems[mapModelKey];
            internalDelete(mapped.data.attributes.social, false);
          }
        });
      }
      internalDelete(item.data.attributes.social);
    },
    onClickCheckbox(key, active) {
      const item = this.shareItems[key];
      const options = {
        noLoading: true,
      };

      if (item?.resolveAs === "meta") {
        return this.saveMeta({
          metaName: key,
          metaValue: active,
          metaDataModel: item.model,
          metaData: item.data,
        });
      }

      const editShare = ({ attributes, id, metadata }) => {
        this.editShare([this.cId, id, attributes.social, metadata, options])
          .then(this.invalidateState)
          .catch(this.$_notificationsMixin_handleCatch);
      };

      const activateItem = ({ attributes, id }) => {
        if (item.active) {
          const metadata = { ...attributes, active: false };
          editShare({ attributes, id, metadata });
        } else {
          const metadata = { ...attributes, active: true };
          editShare({ attributes, id, metadata });
        }
      };

      if (!item.data || item.data.id === "0") {
        this.createShare([this.cId, key, options])
          .then(({ data }) => {
            this.invalidateState();
            return data;
          })
          .then(activateItem)
          .catch(this.$_notificationsMixin_handleCatch);
      } else {
        activateItem({ attributes: item.data.attributes, id: item.data.id });
      }
    },
    typeOfModel(key) {
      const item = this.shareItems[key];

      if (typeof item?.model === "undefined" || item?.model === null) {
        throw new Error("Cannot find model in item definition!");
      }

      if (Object.keys(item.model).length !== 0 && item.model.constructor === Object) {
        return "normal_object";
      }
      if (typeof item.model === "string" && item.model.trim().length !== 0) {
        return "translation_key";
      }
      if (typeof item.model === "string" && item.model.trim().length === 0) {
        return "no_body";
      }
      return "unhandled";
    },
    modelToMetadata(model) {
      const metadata = {};
      if (typeof model !== "string") {
        for (const [k, v] of Object.entries(model)) {
          metadata[k] = v?.valueMod?.set ? v.valueMod?.set(v.value) : v.value;
        }
      }
      return metadata;
    },
    clearModel(model) {
      const t = {};
      for (const [k, v] of Object.entries(model)) {
        if (!v?.valueMod?.set && v.value.length === 0) {
          t[k] = { ...v, value: "" };
        } else {
          t[k] = v;
        }
      }
      return t;
    },
    charactersLeft(value, characters) {
      return characters - value.length;
    },
    description(value, characters) {
      return `${this.charactersLeft(value, characters)} characters left`;
    },
    charactersState(value, characters) {
      return value.length <= characters;
    },
    extractCharactesData(model) {
      let found = null;
      Object.values(model).forEach((v) => {
        if (v.characters) {
          found = v;
        }
      });
      if (!found) {
        return false;
      }
      return found;
    },
  },
};
</script>

<style lang="scss" scoped>
.matchers_page {
  padding: 0px;
  &_search {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    position: relative;
    > svg {
      fill: gray;
      position: absolute;
      left: 0;
      top: calc(50% - 12px);
    }
    > input {
      width: 100%;
      border-left: none;
      border-right: none;
      border-top: none;
      border-radius: 0;
      padding-left: 30px;
    }
  }
  .custom-control {
    z-index: unset;
  }
}
.campaing-share-list {
  &__header {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 0.9fr 0.1fr;
    align-items: center;
    div:last-child {
      justify-self: end;
      margin: 10px;
      ::v-deep .custom-control-label {
        cursor: pointer;
      }
    }
    &--no-body {
      border-width: 0px;
      background-color: inherit;
    }
  }
}
</style>
