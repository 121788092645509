export function teamStatsNotEmpty(teamStats) {
  return Object.keys(teamStats).length !== 0;
}

export function donationAmount(teamStats) {
  return getTeamStats(teamStats, "total_amount");
}

export function donationAmountRank(teamStats) {
  return getTeamStats(teamStats, "total_amount_rank");
}

export function donorsCount(teamStats) {
  return getTeamStats(teamStats, "total_count");
}

export function donorsCountRank(teamStats) {
  return getTeamStats(teamStats, "total_count_rank");
}

export function progress(teamStats, goal) {
  if (teamStatsNotEmpty(teamStats)) {
    const donation = donationAmount(teamStats);
    return (donation / (goal || 1)) * 100;
  }
  return NaN;
}

export function getTeamStats(teamStats, key) {
  if (teamStatsNotEmpty(teamStats)) {
    return teamStats[key];
  }
  return "";
}
