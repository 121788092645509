import urls from "@/constants/urls";
import axios from "axios";

const state = {
  collection: [],
  stats: {},
  loading: {
    getDonorTeams: false,
    getDonorTeamsStats: false,
    editDonorTeams: false,
  },
};

const getters = {};

const mutations = {
  SET_COLLECTION(state, data) {
    state.collection = data;
  },

  SET_STATS(state, data) {
    state.stats = data;
  },

  SET_LOADING(state, [k, v]) {
    state.loading[k] = v;
  },
};

const actions = {
  getDonorTeams({ commit }, { extend, sortBy } = {}) {
    const url = urls["getDonorTeams"];
    const params = {
      extend,
      sort_by: sortBy,
    };
    commit("SET_LOADING", ["getDonorTeams", true]);
    return axios
      .get(url, { params })
      .then(({ data }) => {
        commit("SET_COLLECTION", data);
        return data;
      })
      .finally(() => commit("SET_LOADING", ["getDonorTeams", false]));
  },
  getDonorTeamsStats({ commit }) {
    const url = urls["getDonorTeamsStats"];
    const params = {};
    commit("SET_LOADING", ["getDonorTeamsStats", true]);
    return axios
      .get(url, { params })
      .then(({ data }) => {
        commit("SET_STATS", data);
        return data;
      })
      .finally(() => commit("SET_LOADING", ["getDonorTeamsStats", false]));
  },
  getDonorTeam(_, { id, params }) {
    const url = urls["editDonorTeams"].replace(":id", id);
    return axios.get(url, { params }).then(({ data }) => {
      return data;
    });
  },
  getDonorTeamDonors(_, { id, params }) {
    const url = urls["getDonorTeamDonors"].replace(":id", id);
    return axios.get(url, { params }).then(({ data, headers }) => {
      return {
        data,
        total: Number(headers["x-total-records"]),
      };
    });
  },
  editDonorTeams({ commit, state }, { id, data }) {
    const url = urls["editDonorTeams"].replace(":id", id);
    const body = {
      data: {
        attributes: {
          ...data,
        },
      },
    };
    commit("SET_LOADING", ["editDonorTeams", true]);
    return axios
      .patch(url, body)
      .then(({ data }) => {
        const copy = [...state.collection];
        const index = copy.findIndex((c) => c.id === data.id);
        copy[index] = data;
        commit("SET_COLLECTION", copy);
        return data;
      })
      .finally(() => commit("SET_LOADING", ["editDonorTeams", false]));
  },
  sendEmail(_, { payload } = {}) {
    if (payload) {
      payload.message_type = "email";
      const url = urls.donorAccountMessaging;
      return axios.post(url, payload).then(({ data }) => {
        return data;
      });
    }
  },
  exportDonors(_, { teamId } = {}) {
    return axios.get(urls.exportDonors.replace(":id", teamId)).then(({ data }) => {
      return data.proc_id;
    });
  },
  exportTeams() {
    return axios.get(urls.getDonorTeamsExport).then(({ data }) => {
      return data.proc_id;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
