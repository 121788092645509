<template>
  <div>
    <b-modal
      id="lead-checkout-modal"
      size="lg"
      scrollable
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @hide="clearData"
    >
      <div slot="modal-header">
        <h2>{{ $t(`dashboard.lead_checkout_modal_title`) }}</h2>
      </div>

      <div>
        <p>{{ $t(`dashboard.lead_checkout_modal_description`) }}</p>

        <b-table :fields="fields" :items="items"></b-table>
      </div>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue m-auto" @click="close">
          {{ $t(`dashboard.close_btn`) }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      fields: [
        {
          key: "attributes.billing_name",
          label: this.$t("dashboard.lead_name"),
          formatter: (value, key, item) => {
            const attr = item.attributes;
            return `${attr.billing_name} ${attr.billing_last_name}`;
          },
        },
        {
          key: "attributes.email",
          label: this.$t("dashboard.lead_email"),
        },
        {
          key: "attributes.charged_amount",
          label: this.$t("dashboard.lead_amount"),
          formatter: (value, key, item) => {
            const attr = item.attributes;
            return `${attr.charged_amount} ${attr.currency_code.toUpperCase()}`;
          },
        },
        {
          key: "attributes.status",
          label: this.$t("dashboard.lead_status", "Status"),
        },
        {
          key: "attributes.date",
          label: this.$t("dashboard.lead_date", "Date"),
          formatter: (value, key, item) => {
            const { date } = item.attributes;

            let donateDate = moment.unix(date).format("MMMM D, YYYY");

            let thisDate = moment(new Date()).format("MMMM D, YYYY");

            if (donateDate == thisDate) {
              return moment.unix(date).format("h:mm a");
            } else {
              return moment.unix(date).format("MMMM D, YYYY h:mm a");
            }
          },
        },
      ],
      items: [],
    };
  },

  methods: {
    open(items) {
      this.items = items;
      this.$bvModal.show("lead-checkout-modal");
    },

    close() {
      this.$bvModal.hide("lead-checkout-modal");
    },

    clearData() {
      this.items = [];
    },
  },
};
</script>
