<template>
  <b-overlay
    :show="loading"
    rounded
    opacity="0.9"
    spinner-small
    spinner-variant="primary"
    class="d-inline-flex w-100"
  >
    <div :class="{ 'toggle-switch': !plate, 'toggle-plate': plate }">
      <template v-if="!plate"> {{ label }}</template>
      <div>
        <input
          :checked="trueValue === value"
          @input="$emit('input', trueValue === $event.target.checked)"
          @change="$emit('change', trueValue === $event)"
          type="checkbox"
          :true-value="trueValue"
          :false-value="falseValue"
          :id="idGen"
          :disabled="disabled"
          v-bind="$attrs"
        />
        <label :for="idGen"></label>
      </div>
      <template v-if="plate"> {{ label }}</template>
    </div>
  </b-overlay>
</template>

<script>
import { nanoid } from "nanoid";

export default {
  props: {
    value: {
      type: [Boolean, String, Number, Object, Array],
      default: false,
    },
    trueValue: {
      type: [Boolean, String, Number, Object, Array],
      default: true,
    },
    falseValue: {
      type: [Boolean, String, Number, Object, Array],
      default: false,
    },
    disabled: Boolean,
    loading: Boolean,
    plate: Boolean,
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      idGen: null,
    };
  },
  mounted() {
    if (this.id.length) {
      this.idGen = this.id;
    } else {
      this.idGen = nanoid();
    }
  },
};
</script>

<style lang="scss" scoped>
.toggle-switch {
  width: 100%;
  margin-bottom: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  > div {
    padding-left: 10px;
  }
  input {
    display: block;
    opacity: 0;
  }
  label {
    width: 60px;
    height: 30px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    background: rgb(189, 189, 189);
    border-radius: 30px;

    transition: background-color 0.4s;
    -moz-transition: background-color 0.4s;
    -webkit-transition: background-color 0.4s;
  }
  label:after {
    left: 0;
    width: 20px;
    height: 20px;
    margin: 5px;
    content: "";
    position: absolute;
    background: #fff;
    border-radius: 10px;
  }
  input:checked + label {
    background: #3cc7a0;
  }
  input:checked + label:after {
    left: auto;
    right: 0;
  }
}
.toggle-plate {
  display: inline-flex;
  align-items: center;
  margin-right: auto;
  font-size: 12px;
  > div {
    height: 30px;
    margin-right: 5px;
    position: relative;
  }
  input {
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  label {
    margin: 0;
    width: 60px;
    height: 30px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    background: rgb(189, 189, 189);
    border-radius: 30px;
    transition: background-color 0.4s;
    -moz-transition: background-color 0.4s;
    -webkit-transition: background-color 0.4s;
  }
  label:after {
    left: 0;
    width: 20px;
    height: 20px;
    margin: 5px;
    content: "";
    position: absolute;
    background: #fff;
    border-radius: 10px;
  }
  input:checked + label {
    background: #3cc7a0;
  }
  input:checked + label:after {
    left: auto;
    right: 0;
  }
}
.he {
  .toggle-plate {
    margin-right: initial;
    margin-left: auto;
    > div {
      margin-right: initial;
      margin-left: 5px;
    }
  }
}
</style>
