<template>
  <div>
    <b-form-group label="Enter your name">
      <b-form-input v-model="displayNameModel"></b-form-input>
    </b-form-group>
    <b-form-group label="Enter your dedication">
      <b-form-input v-model="dedicationModel"></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
const useModel = (key) => ({
  get() {
    return this.value[key];
  },
  set(val) {
    this.$set(this.value, key, val);
  },
});

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    displayNameModel: useModel("display_name"),
    dedicationModel: useModel("dedication"),
  },
};
</script>

<style lang="scss" scoped></style>
