<template>
  <div class="login-form-org">
    <div class="form-group form-unit" v-if="steps === 'login'">
      <label class="form-unit__label form-unit__label--upper-sm">{{
        $t("template.email_address")
      }}</label>
      <input
        type="email"
        v-on:keyup.enter="$emit('login-user')"
        v-model="email"
        class="form-control form-unit__input"
        tabindex="1"
      />
    </div>

    <div
      class="form-group form-unit"
      v-if="steps === 'resetPassword' || steps === 'resetPasswordPhone'"
    >
      <div class="action-title">
        <p v-if="steps === 'resetPassword'" class="sign-form__description">
          {{ $t("template.reset_by_email") }}
        </p>
        <p v-else-if="steps === 'resetPasswordPhone'" class="sign-form__description">
          {{ $t("template.reset_by_sms") }}
        </p>
      </div>
      <label class="form-unit__label form-unit__label--upper-sm form-unit__label--w-link"
        >{{ $t("template.email_address") }}
        <a
          v-if="steps === 'resetPassword'"
          href="#"
          @click.prevent="$emit('steps-set', 'resetPasswordPhone')"
          tabindex="3"
          >{{ $t("template.send_sms") }}</a
        >
        <a
          v-else-if="steps === 'resetPasswordPhone'"
          href="#"
          @click.prevent="$emit('steps-set', 'resetPassword')"
          tabindex="3"
          >{{ $t("template.send_email") }}</a
        >
      </label>
      <input
        type="email"
        v-on:keyup.enter="$emit('login-user')"
        v-model="email"
        class="form-control form-unit__input"
        tabindex="1"
        @input="emailChange"
      />
      <span v-show="this.loginLocalErrors.email" class="v-help is-danger">{{
        $t("template.valid_email_is_required")
      }}</span>
    </div>

    <div class="form-group form-unit" v-if="steps === 'resetPasswordPhone'">
      <label class="form-unit__label form-unit__label--upper-sm form-unit__label--w-link"
        >{{ $t("template.phone_number") }}
      </label>
      <VueTelInput
        name="phone"
        v-model="phone"
        v-validate="{
          required: true,
          twilio_lookup: twilioLookup,
        }"
        :inputOptions="{
          styleClasses: 'form-control form-unit__input',
        }"
        mode="international"
        data-vv-validate-on="blur"
        valid-characters-only
        tabindex="4"
        @validate="validate"
        :defaultCountry="defaultCountry"
        :autoDefaultCountry="autoDefaultCountry"
      />
      <span
        v-if="vErrorsHas('phone', 'required') || vErrorsHas('phone', 'decimal')"
        class="v-help is-danger"
      >
        {{ $t("template.field_is_required") }}
      </span>
      <span v-if="twilioLookupErrorMsg" class="v-help is-danger">
        {{ twilioLookupErrorMsg }}
      </span>
    </div>

    <div class="form-group form-unit" v-if="steps === 'login'">
      <label class="form-unit__label form-unit__label--upper-sm form-unit__label--w-link">
        {{ $t("template.password") }}
        <a href="#" @click.prevent="$emit('steps-set', 'resetPassword')" tabindex="3">{{
          $t("template.forgot_password")
        }}</a>
      </label>
      <input
        type="password"
        v-on:keyup.enter="$emit('login-user')"
        v-model="password"
        class="form-control form-unit__input"
        tabindex="2"
      />
    </div>

    <div class="form-group form-unit" v-if="steps === '2facode'">
      <label class="form-unit__label form-unit__label--upper-sm">
        {{ $t("template.two_fa_vode", "Code from your Googe Authenticator") }}
      </label>
      <input
        v-on:keyup.enter="$emit('login-user')"
        v-model="twoFACode"
        class="form-control form-unit__input"
        tabindex="1"
      />
    </div>

    <div class="login-message" v-if="steps === 'backToLogin' && prevstep === 'resetPassword'">
      {{ $t("template.password_reset_please_check_email") }}
    </div>
    <div
      class="login-message"
      v-else-if="steps === 'backToLogin' && prevstep === 'resetPasswordPhone'"
    >
      {{ $t("template.password_reset_please_check_phone") }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { VueTelInput } from "vue-tel-input";

export default defineComponent({
  props: [
    "login",
    "steps",
    "prevstep",
    "successresetpassword",
    "stepsset",
    "stepto",
    "proceedresetpassword",
    "loginuser",
    "loginLocalErrors",
  ],

  components: {
    VueTelInput,
  },

  data() {
    return {
      phoneObject: null,
    };
  },

  computed: {
    phone: {
      get() {
        return this.login.phone || "";
      },
      set(val) {
        this.$set(this.login, "phone", val);
      },
    },
    email: {
      get() {
        return this.login.email || "";
      },
      set(val) {
        this.$set(this.login, "email", val);
      },
    },
    password: {
      get() {
        return this.login.password || "";
      },
      set(val) {
        this.$set(this.login, "password", val);
      },
    },
    twoFACode: {
      get() {
        return this.login["2fa_code"] || "";
      },
      set(val) {
        this.$set(this.login, "2fa_code", val);
      },
    },
    ...mapState({
      defaultCountry: (s) => s.account.userCountry,
    }),
    autoDefaultCountry() {
      return this.defaultCountry === "N/A" || this.defaultCountry.length === 0;
    },
    twilioLookupErrorMsg() {
      const exist = this.vErrors.items.find(
        (el) => el.field === "phone" && el.rule === "twilio_lookup"
      );

      if (exist) {
        return this.$t("template.field_error_twilio_lookup", "Invalid or incorrect phone number");
      }

      return "";
    },
    twilioLookup() {
      return {
        valid: this.phoneObject?.valid || false,
      };
    },
  },
  methods: {
    validate(phoneObject) {
      this.phoneObject = phoneObject;

      if (!this.phoneObject?.valid) {
        this.$set(this.loginLocalErrors, "phone", true);
        return;
      }

      this.$set(this.loginLocalErrors, "phone", false);
    },
    vErrorsHas(field, rule) {
      return this.vErrors.items.find((el) => el.field === field && el.rule === rule);
    },
    emailChange() {
      if (this.login.email === "" || !this.validEmail(this.login.email)) {
        this.$set(this.loginLocalErrors, "email", true);
        return;
      }

      this.$set(this.loginLocalErrors, "email", false);
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
});
</script>

<style lang="scss" scoped></style>
