<template>
  <div class="sign-form__data">
    <div class="form-unit" v-if="!registerData.ref">
      <label class="form-unit__label form-unit__label--upper-sm">
        {{ $t("template.customer_exist", "ARE YOU CURRENTLY WORKING WITH US?") }}
      </label>

      <b-form-radio-group
        v-model="registerData.customer_exist"
        v-validate="'required'"
        :options="options_2"
        :state="isExist"
        name="exist"
        @change="clearCustomerExistInfo"
        class="mb-3"
      />
      <span v-show="vErrors.has('exist')" class="v-help is-danger">{{
        $t("template.field_is_required", "This field is required")
      }}</span>
    </div>

    <div v-if="isExist && !registerData.ref" class="form-unit mt-3">
      <b-input-group class="input-group-with-block-dropdown__input-group">
        <label class="form-unit__label form-unit__label--upper-sm">
          {{ $t("template.charidy_contact", "PLEASE NAME YOUR CONTACT PERSON AT CHARIDY") }}
        </label>
        <input
          v-model="registerData.sales_contact_name"
          type="text"
          name="contact"
          class="form-control form-unit__input"
          tabindex="1"
          v-validate="'required'"
        />
        <span v-show="vErrors.has('contact')" class="v-help is-danger">
          {{ $t("template.contact_person_is_required", "Contact person field is required") }}
        </span>
        <b-input-group-append class="input-group-with-block-dropdown__group-append">
          <b-dropdown
            :disabled="loading"
            class="input-group-with-block-dropdown__dropdown"
            text=""
            variant="c-secondary"
            menu-class="input-group-with-block-dropdown__dropdown-menu"
          >
            <template v-if="salesRepList.length > 0">
              <b-dropdown-group :header="$t('template.contact_person_list', 'Sales Rep')">
                <b-dropdown-item
                  @click="onSalesRepSelect(l)"
                  v-for="(l, i) in salesRepList"
                  :key="i"
                  :active="l.id === salesRepListTempID"
                >
                  {{ l.name }}
                </b-dropdown-item>
              </b-dropdown-group>
            </template>
            <template v-else>
              <b-dropdown-item disabled>
                {{
                  $t(
                    "template.contact_person_list_empty",
                    "No sales rep available. Please fill the input field"
                  )
                }}
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div class="form-group form-unit">
      <label class="form-unit__label form-unit__label--upper-sm">{{
        $t("template.work_email_address", "WORK EMAIL ADDRESS")
      }}</label>
      <input
        type="email"
        name="email"
        v-model="emailModel"
        v-validate="'required|email'"
        class="form-control form-unit__input"
        tabindex="1"
      />
      <span v-show="vErrors.has('email')" class="v-help is-danger">{{
        $t("template.email_is_required", "Email field is required")
      }}</span>
    </div>
    <div class="my-2">
      <b-form-checkbox
        v-model="useSameEmailForContactsModel"
        name="use_same_email_for_contacts"
        :value="true"
        :unchecked-value="false"
      >
        <label class="form-unit__label">
          {{
            $t(
              "template.use_same_email_for_contacts",
              "Use the same email for Donor Support and Payment Resolution Contact Details"
            )
          }}
        </label>
      </b-form-checkbox>
    </div>
    <div class="form-group form-unit" v-if="!useSameEmailForContactsModel">
      <label class="form-unit__label form-unit__label--upper-sm">{{
        $t("template.donor_email_address", "DONOR EMAIL ADDRESS")
      }}</label>
      <input
        type="email"
        name="email"
        v-model="registerData.donor_support_email"
        v-validate="'required|email'"
        class="form-control form-unit__input"
        tabindex="1"
      />
      <span v-show="vErrors.has('email')" class="v-help is-danger">{{
        $t("template.email_is_required", "Donor Support Email field is required")
      }}</span>
    </div>
    <div class="sign-form__row">
      <div class="form-group form-unit">
        <label class="form-unit__label form-unit__label--upper-sm">{{
          $t("template.firs_name", "FIRST NAME")
        }}</label>
        <input
          type="text"
          name="firstname"
          v-model="registerData.first_name"
          v-validate="'required|min:3|max:32'"
          class="form-control form-unit__input"
          tabindex="2"
        />
        <span v-show="vErrors.has('firstname')" class="v-help is-danger">{{
          $t("template.first_name_is_required", "First name field is required")
        }}</span>
      </div>

      <div class="form-group form-unit">
        <label class="form-unit__label form-unit__label--upper-sm">{{
          $t("template.last_name", "LAST NAME")
        }}</label>
        <input
          type="text"
          name="lastname"
          v-model="registerData.last_name"
          v-validate="'required|min:3|max:32'"
          class="form-control form-unit__input"
          tabindex="3"
        />
        <span v-show="vErrors.has('lastname')" class="v-help is-danger">{{
          $t("template.last_name_is_required", "Last name field is required")
        }}</span>
      </div>
    </div>

    <div class="form-group form-unit">
      <label class="form-unit__label form-unit__label--upper-sm">
        {{ $t("template.phone_number", "PHONE") }}</label
      >
      <VueTelInput
        name="phone"
        v-model="registerData.phone"
        v-validate="phoneValidation"
        :inputOptions="{
          styleClasses: 'form-control form-unit__input',
        }"
        mode="international"
        data-vv-validate-on="blur"
        valid-characters-only
        tabindex="4"
        @validate="validate"
        :defaultCountry="defaultCountry"
        :autoDefaultCountry="autoDefaultCountry"
      />
      <span
        v-if="vErrorsHas('phone', 'required') || vErrorsHas('phone', 'decimal')"
        class="v-help is-danger"
      >
        {{ $t("template.field_is_required", "This field is required") }}
      </span>
      <span v-if="twilioLookupErrorMsg" class="v-help is-danger">
        {{ twilioLookupErrorMsg }}
      </span>
    </div>

    <div class="form-group form-unit">
      <label class="form-unit__label form-unit__label--upper-sm">
        {{ $t("template.organization_name", "ORGANIZATION NAME") }}</label
      >
      <input
        type="text"
        name="organization"
        v-model="registerData.organization_name"
        v-validate="'required|min:3'"
        class="form-control form-unit__input"
        tabindex="5"
      />
      <span v-show="vErrors.has('organization')" class="v-help is-danger">{{
        $t("template.organization_name_is_required", "Organization name field is required")
      }}</span>
    </div>

    <div class="my-2" v-if="this.multiselectCountry.iso_code !== 'IL'">
      <b-form-checkbox
        v-model="registerData.org_501c3"
        name="org_501c3"
        :value="true"
        :unchecked-value="false"
      >
        <label class="form-unit__label">
          {{ $t("template.is_org_501c3", "My organization has the 501c3 requirments (optional).") }}
          <button id="show-requirements" class="q-mark" tabindex="0" type="button">?</button>
        </label>
        <b-tooltip target="show-requirements" placement="top">
          {{
            $t(
              "template.the_501c3_requirements",
              "To run a Charidy campaign, a tax id/non profit 501c3 number is required."
            )
          }}
        </b-tooltip>
      </b-form-checkbox>
    </div>

    <div class="form-group form-unit">
      <label class="form-unit__label form-unit__label--upper-sm">
        {{ $t("template.tax_id1", "EIN:") }}</label
      >
      <input
        type="text"
        name="tax_id1"
        v-model="registerData.tax_id1"
        class="form-control form-unit__input"
        tabindex="6"
      />
      <span v-show="vErrors.has('tax_id1')" class="v-help is-danger">
        {{ $t("template.tax_id1_is_required", "EIN field is required") }}
      </span>
    </div>

    <div class="form-group form-unit">
      <password-hint
        ref="passwordHint"
        :target="'tooltip-pass'"
        :password="registerData.password"
      ></password-hint>

      <label class="form-unit__label form-unit__label--upper-sm">
        {{ $t("template.password", "PASSWORD") }}
      </label>

      <input
        type="password"
        name="password"
        id="tooltip-pass"
        @focus="openHint"
        @blur="closeHint"
        v-validate="'required'"
        v-model="registerData.password"
        class="form-control form-unit__input"
        tabindex="7"
      />
      <span v-show="vErrors.has('password')" class="v-help is-danger">{{
        $t("template.password_is_required", "Password field is required")
      }}</span>
    </div>

    <div class="form-group form-unit">
      <label class="form-unit__label form-unit__label--upper-sm">
        {{ $t("template.country", "COUNTRY") }}
      </label>

      <multiselect
        v-model="multiselectCountry"
        placeholder="Choose country"
        label="name"
        track-by="iso_code"
        :options="countries"
        :show-labels="false"
      >
      </multiselect>
    </div>

    <div class="form-group form-unit">
      <div class="terms-acknowledge">
        <label class="terms-acknowledge__checkbox" :class="{ checked: acknowledge }" tabindex="7">
          <input type="checkbox" name="terms" v-model="acknowledge" v-validate="'required'" />
        </label>
        <p class="terms-acknowledge__text">
          {{
            $t(
              "template.by_clicked_i_aggree",
              "I acknowledge that I have read and agree to the Charidy"
            )
          }}
          <a :href="`${appHost}/terms`" target="_blank">
            {{ $t("template.terms_of_service", "Terms of Service") }}
          </a>
          {{ $t("template.and") }}
          <a :href="`${appHost}/terms`" target="_blank">
            {{ $t("template.private_policy", "Private Policy") }}
          </a>
          .
        </p>
        <span v-show="vErrors.has('terms')" class="v-help is-danger">{{
          $t("template.terms_is_required", "Terms field is required")
        }}</span>
      </div>
    </div>

    <error :errors="errors"></error>
  </div>
</template>

<script>
import axios from "axios";
import { load } from "recaptcha-v3";
import { countryList } from "@/constants/countries";
import multiselect from "vue-multiselect";
import passwordHint from "./password-hint-tooltip";
import urls, { appHost } from "@/constants/urls.js";
import { VueTelInput } from "vue-tel-input";
import ErrorDisplay from "@/views/Error.vue";
import { mapActions, mapState } from "vuex";
import { nanoid } from "nanoid";

export default {
  name: "signup-form",
  inject: ["$validator"],

  data: function () {
    return {
      appHost,
      registerData: {
        org_account: "1",
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        organization_name: "",
        password: "",
        ref: "",
        country: "",
        tax_id1: "",
        org_501c3: false,
        customer_exist: true,
        donor_support_email: "",
        sales_contact_name: "",
      },

      multiselectCountry: {},
      countries: [...countryList],
      acknowledge: false,
      errors: [],
      phoneObject: null,
      options_2: [
        { text: this.$t("template.yes", "Yes"), value: true },
        { text: this.$t("template.no", "No"), value: false },
      ],
      use_same_email_for_contacts: true,
      recaptcha: null,
      validatePhoneWithTwilio: true,
      salesRepList: [],
      loading: false,
      salesRepListTempID: null,
    };
  },

  components: {
    passwordHint,
    error: ErrorDisplay,
    multiselect,
    VueTelInput,
  },

  mounted() {
    this.loading = true;
    this.initData({ page: "dashboard_signup" })
      .then(({ data, headers }) => {
        let pageMetaData = (data?.included || []).filter((v) => v.type === "page_meta_data");
        pageMetaData = pageMetaData.reduce((res, v) => {
          try {
            res[v.attributes.name] = JSON.parse(v.attributes.content);
          } catch {
            res[v.attributes.name] = v.attributes.content;
          }
          return res;
        }, {});
        this.salesRepList = (pageMetaData?.sales_rep_list || []).map((v) => {
          return {
            id: nanoid(),
            name: v.name,
          };
        });

        return { data, headers };
      })
      .then(async ({ data, headers }) => {
        const country = headers["x-geo-ip-country"];
        const countryFromList = this.countries.find((c) => c.iso_code === country);
        this.multiselectCountry = countryFromList || {};
        this.validatePhoneWithTwilio = data.data.attributes.validate_phone_with_twilio;
        if (data.data.attributes.captcha_site_key_v3) {
          this.recaptcha = await load(data.data.attributes.captcha_site_key_v3);
        }
      })
      .finally(() => {
        this.loading = false;
      });
    if (this.$route.query.ref) {
      this.registerData.ref = this.$route.query.ref;
    }
  },
  computed: {
    ...mapState({
      defaultCountry: (s) => s.account.userCountry,
    }),
    emailModel: {
      get() {
        return this.registerData.email;
      },
      set(val) {
        if (
          !this.registerData.use_same_email_for_contacts &&
          (this.registerData.donor_support_email === "" ||
            this.registerData.donor_support_email !== val)
        ) {
          this.registerData.donor_support_email = val;
        }

        this.registerData.email = val;
      },
    },
    useSameEmailForContactsModel: {
      get() {
        return this.use_same_email_for_contacts;
      },
      set(val) {
        if (val) {
          this.registerData.donor_support_email = this.registerData.email;
        }
        this.use_same_email_for_contacts = val;
      },
    },
    autoDefaultCountry() {
      return this.defaultCountry === "N/A" || this.defaultCountry.length === 0;
    },
    phoneValidation() {
      if (!this.validatePhoneWithTwilio) {
        return {
          required: true,
        };
      }

      return {
        required: true,
        twilio_lookup: this.twilioLookup,
      };
    },
    twilioLookupErrorMsg() {
      const exist = this.vErrors.items.find(
        (el) => el.field === "phone" && el.rule === "twilio_lookup"
      );

      if (exist) {
        return this.$t("template.field_error_twilio_lookup", "Twilio lookup error");
      }

      return "";
    },
    twilioLookup() {
      return {
        valid: this.phoneObject?.valid || false,
      };
    },

    isExist() {
      return this.registerData.customer_exist;
    },
  },
  methods: {
    ...mapActions({
      initData: "initData",
    }),
    registerUser() {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.errors = [];
          if (!this.recaptcha) {
            return;
          }
          const token = await this.recaptcha.execute("signup");
          axios
            .post(urls.registerUser, {
              ...this.registerData,
              country: this.multiselectCountry.iso_code,
              timezone: timezone || "",
              captcha_token: token,
            })
            .then((response) => {
              if (response.data.Result == "ok") {
                this.$emit("register-success", true);
              }
            })
            .catch((e) => {
              this.$emit("register-success", false);
              if (e.response.data) {
                if (e.response.data.error) {
                  this.errors.push(e.response.data.error);
                } else {
                  this.errors.push(this.$t("template.error_default"));
                }
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            });
        } else {
          this.$emit("register-success", false);
        }
      });
    },
    openHint: function () {
      this.$refs.passwordHint.openHint();
    },

    closeHint: function () {
      this.$refs.passwordHint.closeHint();
    },

    vErrorsHas(field, rule) {
      return this.vErrors.items.find((el) => el.field === field && el.rule === rule);
    },

    validate(phoneObject) {
      this.phoneObject = phoneObject;
    },
    handleCauseChange(value) {
      if (value === "donor") {
        this.registerData.customer_exist = false;
        this.registerData.sales_contact_name = "";
      }
      this.clearOrgInfo();
    },

    clearOrgInfo() {
      this.registerData.org_501c3 = false;
      this.registerData.tax_id1 = "";
      this.registerData.org_name = "";
    },
    clearCustomerExistInfo() {
      this.registerData.sales_contact_name = "";
    },

    onSalesRepSelect(l) {
      this.registerData.sales_contact_name = l.name;
      this.salesRepListTempID = l.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-acknowledge {
  padding-top: 10px;
  display: flex;
  align-items: flex-start;
  &__checkbox {
    margin-right: 10px;
    display: inline-flex;
    width: 16px;
    height: 16px;
    border: 1px solid #a1a1a1;
    border-radius: 3px;
    flex-shrink: 0;
    position: relative;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
    > input {
      width: 1px;
      height: 1px;
      overflow: hidden;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
    &.checked:after {
      content: "\02716";
      font-size: 14px;
      color: #414359;
      line-height: 1;
      position: absolute;
      top: 1px;
      left: 2px;
    }
  }
  &__text {
    margin: 0;
    font-size: 12px;
  }
}

::v-deep {
  .vue-tel-input {
    direction: ltr;
    border-radius: 4px;
    border: 1px solid #e6eaed;

    &:hover {
      border: 1px solid #2883a2;
    }

    &:focus,
    &:focus-within {
      border: 2px solid #2883a2;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-color: #2883a2;
    }

    .vti__dropdown {
      background-color: #fff;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .vti__dropdown-list {
      &.below {
        z-index: 2;
      }
    }
    .form-control.form-unit__input {
      border: none;
      border-left: 1px solid #e6eaed;
      border-radius: 0px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
.q-mark {
  background: transparent;
  border: 2px solid black;
  border-radius: 50%;
  font-size: 0.7rem;
  outline: none;
  cursor: pointer;
  padding: 1px 3px;
  font-weight: 600;
  margin: 0;
  width: auto;
  height: auto;
  display: inline-block;
}
.input-group-with-block-dropdown {
  &__group-append {
    position: initial;
  }
  &__dropdown {
    position: initial;
    ::v-deep .dropdown-toggle {
      height: 50px;
    }
  }
  ::v-deep &__dropdown-menu {
    --_offset: 4%;
    --_width: 100%;
    transform: translate3d(calc(var(--_offset) / -2), 50px, 0px) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    width: calc(var(--_width) + var(--_offset));

    .dropdown-item {
      padding: 0;
      padding-inline-start: 1rem;
    }
  }
  &__input-group {
    position: relative;
    .form-control {
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
  }
}
</style>
