export const restrictedACLitemsToModelArr = (items) => {
  if (Array.isArray(items.data)) {
    return [];
  }
  const res = items.reduce((obj, el) => {
    const attrs = el.attributes;
    const item = {
      action: attrs.action,
      campaign_ids: [],
    };
    if (attrs.campaign_id) {
      item.campaign_ids = [...(obj[attrs.action]?.campaign_ids || []), attrs.campaign_id];
    }
    obj[attrs.action] = item;
    return obj;
  }, {});

  return Object.values(res);
};

export const restrictedACLModelArrToJSONApiRel = (list) => {
  const relationships = { restricted_acl_items: { data: [] } };
  const included = [];

  let id = 0;

  const addItem = (acl, cid) => {
    const rel = {
      id: String(id),
      type: "restricted_acl_item",
    };

    const inc = {
      ...rel,
      attributes: {
        action: acl.action,
        campaign_id: cid,
      },
    };

    relationships.restricted_acl_items.data.push(rel);
    included.push(inc);

    id += 1;
  };

  for (let i = 0; i < list.length; i++) {
    const acl = list[i];

    for (let j = 0; j < acl.campaign_ids.length; j++) {
      const cid = acl.campaign_ids[j];

      addItem(acl, cid);
    }

    if (acl.campaign_ids.length === 0) {
      addItem(acl, 0);
    }
  }

  return { relationships, included };
};
