<template>
  <div>
    <campaign-images
      disableTabs
      disableVideoLinksButtons
      layoutStep="1"
      :campaignVideoLink="state.campaignVideoLink"
      :campaignCountdownVideoLink="state.campaignCountdownVideoLink"
      :campaignLangImagesSelect="state.campaignLangImagesSelect"
      @update:campaignVideoLink="(v) => setState('campaignVideoLink', v)"
      @update:campaignCountdownVideoLink="(v) => setState('campaignCountdownVideoLink', v)"
      @update:campaignLangImagesSelect="(v) => setState('campaignLangImagesSelect', v)"
    />
  </div>
</template>

<script>
import campaignImages from "@/views/campaigns/campaign-edit-vue/subcomponents/campaignImages.vue";
export default {
  components: {
    campaignImages,
  },
  props: {
    state: { type: Object, default: () => ({}) },
    setState: { type: Function, default: () => {} },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
