<template>
  <div>
    <!-- Modal Component -->
    <b-modal id="childrenTeams" size="xl">
      <div slot="modal-header">
        <h2>{{ $t("dashboard.team_children_teams", "Children teams") }}</h2>
        <div class="modal-header__hide" @click="modalClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button type="button" class="bttn bttn--lg bttn--blue" @click="modalClose" name="button">
          {{ $t("teams.close") }}
        </button>
      </div>

      <b-row class="row flex-column col-12 mb-2">
        <b-col @click="collapseVisible = !collapseVisible">
          <b-icon
            :class="collapseVisible ? 'rotate-180' : ''"
            id="collapse-icon"
            v-b-tooltip.hover.top="this.$t('dashboard.teams_tooltip_statistics', 'Statistics')"
            icon="chevron-down"
          ></b-icon>

          {{ $t("dashboard.team_statistics", "Statistics") }}
        </b-col>
      </b-row>

      <b-row class="row col-12 mb-2">
        <b-col>
          <b-collapse v-model="collapseVisible" class="mt-2">
            <teamsOverview
              :teams="statisticsChildrenTeams"
              :collapse="collapseVisible"
              @close-collapse="collapseVisible = false"
            ></teamsOverview>
          </b-collapse>
        </b-col>
      </b-row>

      <div v-if="childrenTeamsList.length">
        <b-table
          hover
          small
          head-variant="light"
          :items="childrenTeamsList"
          :fields="fields"
          responsive
          class="text-center team-table"
          primary-key="id"
          style="min-height: 200px"
        >
          <template #cell(id)="data">
            {{ data.item.id }}
          </template>

          <template #cell(name)="data">
            <a
              target="_blank"
              :href="`https://www.charidy.com/${campaignShortLink}/${data.item.attributes.slug}`"
            >
              {{ data.item.attributes.name }}</a
            >
          </template>

          <template #cell(goal)="data">
            {{ campaignCurrencySign }} {{ data.item.attributes.goal }}
          </template>

          <template #cell(total_amount)="data">
            {{ campaignCurrencySign }} {{ data.item.attributes.extend.stats.total_amount }}
          </template>

          <template #cell(total_count)="data">
            {{ data.item.attributes.extend.stats.total_count }}
          </template>

          <template #cell(progress)="data">
            <div class="" v-if="data.item.attributes.goal !== 0">
              <span :class="`text-${data.item.attributes.variant}`">
                {{
                  Math.floor(
                    (data.item.attributes.extend.stats.total_amount / data.item.attributes.goal) *
                      100
                  )
                }}%
              </span>
              <b-progress
                :value="
                  Math.floor(
                    (data.item.attributes.extend.stats.total_amount / data.item.attributes.goal) *
                      100
                  )
                "
                height="8px"
                :variant="data.item.attributes.variant"
                striped
              />
            </div>
          </template>

          <template #cell(diff)="data">
            {{ campaignCurrencySign }}
            {{
              Math.trunc(data.item.attributes.extend.stats.total_amount - data.item.attributes.goal)
            }}
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-center">
              <button :disabled="!hasAccessEdit" class="bttn-round" @click="editTeam(data.item)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    d="M3 17.25V21h3.75L17.81
              9.94l-3.75-3.75L3 17.25zM20.71
              7.04c.39-.39.39-1.02
              0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41
              0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                  />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </button>

              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <b-icon icon="three-dots-vertical" />
                </template>
                <b-dropdown-item
                  v-if="data.item.attributes.extend.stats.total_count !== 0"
                  @click="$emit('open-donation-modal', data.item.id)"
                >
                  <b-icon-cash class="m-1" />
                  {{ $t("dashboard.teams_donations_btn", "Show donations") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="teamSMSMessaging"
                  :disabled="!team_sms_messaging"
                  @click="sendSMS(data.item)"
                >
                  <b-icon-chat-text class="m-1" />
                  {{ $t("edit_campaign.teams_button_send_sms", "Send SMS") }}
                </b-dropdown-item>
                <b-dropdown-item @click="sendEmail(data.item)">
                  <b-icon-envelope class="m-1" />
                  {{ $t("edit_campaign.teams_button_send_email", "Send email") }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </div>

      <div v-if="loading">
        <div class="loadings">
          <LdsSpinner />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BProgress } from "bootstrap-vue";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";
import teamsOverview from "./teamsOverview.vue";

export default {
  components: {
    BProgress,
    LdsSpinner,
    teamsOverview,
  },
  data() {
    return {
      team: null,
      childrenTeamsList: [],
      collapseVisible: false,
      statisticsChildrenTeams: [],
      fields: [
        {
          key: "id",
          label: this.$t("dashboard.team_id", "ID"),
        },
        { key: "name", label: this.$t("dashboard.team_name", "Name") },
        { key: "goal", label: this.$t("dashboard.team_goal", "Goal") },
        { key: "total_amount", label: this.$t("dashboard.team_raised", "Raised") },
        {
          key: "total_count",
          label: this.$t("dashboard.team_number_of_donors", "Number of donors"),
        },
        { key: "progress", label: this.$t("dashboard.team_percent", "Progress") },
        { key: "diff", label: this.$t("dashboard.diff_goal_raised", "Diff (goal-raised)") },
        { key: "actions", label: this.$t("dashboard.team_actions", "Actions") },
      ],
    };
  },
  created() {},
  watch: {
    childrenTeamsList() {
      this.addVariantTeams();
    },
    collapseVisible(val) {
      if (val) {
        this.statisticsChildrenTeams = this.childrenTeamsList;
      }
    },
  },
  computed: {
    ...mapState({
      campaignId: (state) => state.dashboard.selectedCampaign,
      campaignCurrencySign: (state) => state.campaign.campaign.data.attributes.currency_sign,
      campaignShortLink: (state) => state.campaign.campaign.data.attributes.short_link,
      teamsCount: (state) => state.teams.teamsCount,
      loading: (state) => state.teams.loadingChildrenTeam,
      hasAccessEdit: (state) => state.account.access.CanEditCampaignTeams,
      team_sms_messaging: (state) => state.messaging.team_sms_messaging,
      teamSMSMessaging: (state) => state.account.teamSMSMessaging,
    }),
  },
  methods: {
    getChildrenTeams(id) {
      const params = {
        parent_team_id: id,
        extend: ["stats"],
      };
      this.$store
        .dispatch("getTeamsByParentId", [this.campaignId, params])
        .then((response) => {
          this.childrenTeamsList = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addVariantTeams() {
      for (let i = 0; i < this.childrenTeamsList.length; i++) {
        this.childrenTeamsList[i].attributes.variant = this.rowClass(this.childrenTeamsList[i]);
      }
    },
    rowClass(item) {
      let progress = Math.floor(
        (item.attributes.extend.stats.total_amount / item.attributes.goal) * 100
      );
      if (this.between(progress, 0, 20)) {
        return "danger";
      } else if (this.between(progress, 21, 50)) {
        return "secondary";
      } else if (this.between(progress, 51, 70)) {
        return "warning";
      } else if (this.between(progress, 71, 90)) {
        return "info";
      } else if (this.between(progress, 91, 100)) {
        return "success";
      } else if (this.between(progress, 101, 100000)) {
        return "primary";
      }
      return;
    },
    between(x, min, max) {
      return x >= min && x <= max;
    },
    editTeam(team) {
      this.$emit("edit-children-team", team);
    },
    openModal: function (item) {
      this.team = item;
      this.getChildrenTeams(this.team.id);
      this.$root.$emit("bv::show::modal", "childrenTeams");
    },
    modalClose: function () {
      this.$root.$emit("bv::hide::modal", "childrenTeams");
    },
    sendEmail(team) {
      this.$emit("send-email", { team });
    },
    sendSMS(team) {
      this.$emit("send-sms", { team });
    },
  },
};
</script>

<style scoped>
#collapse-icon {
  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.rotate-180 {
  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  stroke: #107598;
  transform: rotate(180deg);
}
</style>
