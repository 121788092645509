<template>
  <div class="action-block">
    <!--<b-btn v-b-modal.gatewayadd>Add Gateway</b-btn>-->
    <button class="bttn bttn--sm bttn--orange">{{ $t("gateways.add_gateways") }}</button>

    <!-- Modal Component -->
    <b-modal id="gatewayadd" size="lg">
      <div slot="modal-header">
        <h2>
          <span>
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              enable-background="new 0 0 24 24"
              xml:space="preserve"
            >
              <path d="M15.41,16.59L10.83,12l4.58-4.59L14,6l-6,6l6,6L15.41,16.59z" />
              <path fill="none" d="M0,0h24v24H0V0z" />
            </svg>
          </span>
          кеконлне
        </h2>
        <div class="modal-header__hide">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>

      <div>
        <acc-gateway-view></acc-gateway-view>
      </div>

      <div>
        <div class="gateways-add-select">
          <a href="#" class="gateways-add-select__item">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/stripe.png"
              alt=""
            />
          </a>

          <a href="#" class="gateways-add-select__item">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/yaadpay.png"
              alt=""
            />
          </a>

          <a href="#" class="gateways-add-select__item">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/paypal.png"
              alt=""
            />
          </a>

          <a href="#" class="gateways-add-select__item">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/israeltoremet.png"
              alt=""
            />
          </a>

          <a href="#" class="gateways-add-select__item">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/payfast.png"
              alt=""
            />
          </a>

          <a href="#" class="gateways-add-select__item">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/payme_01.png"
              alt=""
            />
          </a>

          <a href="#" class="gateways-add-select__item">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/broom.jpg"
              alt=""
            />
          </a>

          <a href="#" class="gateways-add-select__item">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/asseb.png"
              alt=""
            />
          </a>

          <a href="#" class="gateways-add-select__item">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/gateways/achisomoch.png"
              alt=""
            />
          </a>

          <a href="#" class="gateways-add-select__item">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1560882051285651734.png"
              alt=""
            />
          </a>

          <a href="#" class="gateways-add-select__item">
            <img
              src="https://http2.mlstatic.com/ui/navigation/4.1.4/mercadopago/logo__large@2x.png"
              alt=""
            />
          </a>

          <a href="#" class="gateways-add-select__item gateways-add-select__item--hk">
            <img
              src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/business-and-finance-glyph-10-512.png"
              alt=""
            />
            <p>HK Bank Transfer</p>
          </a>
        </div>

        <acc-stripe></acc-stripe>
        <acc-paypal></acc-paypal>
        <acc-yaadpay></acc-yaadpay>
        <acc-israeltoremet></acc-israeltoremet>
        <acc-payme></acc-payme>
        <acc-fastpay></acc-fastpay>
        <acc-broom></acc-broom>
        <acc-asser></acc-asser>
        <acc-achisomoch></acc-achisomoch>
        <acc-braintree></acc-braintree>
        <acc-marcado-pago />
        <acc-hk-bank-tranfer />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

import accStripe from "./accStripe.vue";
import accPaypal from "./accPaypal.vue";
import accYaadpay from "./accYaadpay.vue";
import accIsraeltoremet from "./accIsraeltoremet.vue";
import accGatewayView from "./accGatewayView.vue";
import accFastpay from "./accFastPay.vue";
import accPayme from "./accPayme.vue";
import accBroom from "./accBroomFoundation.vue";
import accAsser from "./accAsser.vue";
import accAchisomoch from "./accAchisomoch.vue";
import accBraintree from "./accBraintree.vue";
import accMarcadoPago from "./accMercadoPago.vue";
import accHkBankTranfer from "./accHkBankTransfer.vue";

export default {
  components: {
    accStripe,
    accPaypal,
    accYaadpay,
    accIsraeltoremet,
    accGatewayView,
    accFastpay,
    accPayme,
    accBroom,
    accAsser,
    accAchisomoch,
    accBraintree,
    accMarcadoPago,
    accHkBankTranfer,
  },

  data() {
    return {};
  },

  computed: mapState({}),

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/_vars.scss";

.gateways-add {
  margin-left: auto;
  margin-bottom: 10px;
  .modal-header {
    position: relative;
    h2 {
      padding: 0;
      display: flex;
      align-items: center;
      position: relative;
      span {
        display: inline-block;
        cursor: pointer;
        margin-right: 10px;
        position: absolute;
        top: calc(50% - 12px);
        left: -30px;
        svg {
          fill: gray;
        }
      }
    }
    &__hide {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
      svg {
        fill: gray;
      }
    }
  }
  .modal-body {
    background-color: #f8f8f8;
    @media only screen and (min-width: 460px) {
      padding: 20px 40px !important;
    }
  }
  .modal-footer {
    display: none !important;
  }
}

.gateways-add-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &__item {
    margin: 10px;
    flex-grow: 1;
    min-width: 120px;
    max-width: 170px;
    background: #fff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(32, 67, 78, 0.15);
    @media only screen and (min-width: 460px) {
      min-width: 170px;
    }

    img {
      max-width: 80px;
      height: auto;
    }
    &--hk {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 48px;
        height: auto;
        margin-bottom: 2px;
      }
      p {
        margin-bottom: 0;
        font-family: $vito-bold;
        font-size: 12px;
        color: $fd;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}
#gatewayadd {
  .gateway-form {
    .form-block {
      margin-bottom: 20px;
      h3 {
        font-size: 24px;
        color: #20434e;
        padding: 10px 0;
      }
      label {
        margin-bottom: 10px;
        padding: 0;
        font-family: $vito-light;
        text-transform: uppercase;
        font-size: 14px;
        color: $fd;
      }
    }
    &__action {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }
    &__notice {
      margin-bottom: 20px;
      font-size: 16px;
      color: #20434e;
      a {
        font-size: 16px;
        font-weight: 500;
        color: #cc6d4c;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }
}
.data-view {
  margin-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 992px) {
    flex-wrap: nowrap;
  }
  &__title {
    min-width: 100%;
    padding: 10px 0;
    font-family: "ProximaNova-Bold", sans-serif;
    font-size: 16px;
    @media only screen and (min-width: 992px) {
      min-width: 30%;
      max-width: 30%;
      margin-right: 20px;
    }
  }
  &__data {
    flex-grow: 1;
    padding: 10px 0;
    //font-family: 'ProximaNova', sans-serif;
    font-size: 16px;
    word-wrap: break-word;
    max-width: 65%;
  }
}

@media (max-width: 768px) {
  .data-view__data {
    max-width: 100%;
  }
}
</style>
