const state = {
  filters: {
    cid: 0,
    year: 0,
    month: 0,
    status: "",
    q: "",
  },
};

const getters = {
  getFiltersIfPeriodPresent(state) {
    const { cid, year, month } = state.filters;

    if (cid && year && month) {
      return state.filters;
    }

    return false;
  },
};

const mutations = {
  SET_FILTER(state, { key, value }) {
    state.filters[key] = value;
  },
};

const actions = {
  setFilter({ commit }, { key, value }) {
    commit("SET_FILTER", { key, value });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
