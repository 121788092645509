<template>
  <div>
    <b-modal id="exportReceipts" size="lg" @hidden="clearData">
      <div slot="modal-header">
        <h2>{{ $t("dashboard.export_receipts_modal_title") }}</h2>
        <div class="modal-header__hide" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button
          :class="
            !exportLink.file && success ? 'bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--blue'
          "
          @click.prevent="hideModal"
        >
          {{ $t("dashboard.hide_modal") }}
        </button>
        <button
          @click="exportList"
          :class="isLoading ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
          type="button"
          v-if="!isLoading"
        >
          {{ $t("dashboard.export_export_in_modal") }}
        </button>
        <a
          :href="exportLink.file"
          v-if="exportLink.file && success"
          class="bttn bttn--lg bttn--orange"
          >{{ $t("dashboard.export_dowload_export_file") }}</a
        >
      </div>

      <form id="export-donation" class="entities-data">
        <div class="info-content success-desc" v-if="exportLink.file && success">
          <p>
            {{ $t("dashboard.export_success_have_button") }}
          </p>
        </div>

        <div class="error info-content" v-if="!exportLink.file && success">
          <p>
            {{ $t("dashboard.export_check_email") }}
          </p>
        </div>

        <div class="loading-link-wrap" v-if="!success">
          <div v-if="!isLoading">
            <div class="info-content">
              <p>
                {{ $t("dashboard.export_export_description") }}
              </p>
            </div>

            <div class="error info-content">
              <error-message :response.sync="errorResponse"></error-message>
            </div>

            <div class="form-group">
              <label>{{ $t("dashboard.export_email") }}</label>
              <input type="email" name="email" v-model="email" class="form-control" />
            </div>

            <div class="form-group">
              <label>{{ $t("dashboard.entitis") }}</label>
              <select v-model="entity">
                <option value="0">{{ $t("dashboard.select_entiti") }}</option>
                <option v-for="item in entitiesLists" :key="item.id" :value="item.id">
                  {{ item.attributes.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="info-content loading-link" v-if="isLoading">
            <p>
              {{ $t("dashboard.export_is_sent_to_email", "Check your email for donations report") }}
            </p>
          </div>
          <error-message ref="error" :response.sync="errorResponse"></error-message>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

import axios from "axios";

import urls from "@/constants/urls.js";

import errorList from "@/components/error-message.vue";

export default {
  props: ["campaignid"],

  data() {
    return {
      email: "",
      exportLink: "",
      isLoading: false,
      needEmail: false,
      success: false,
      errorResponse: null,
      procLoading: false, // the process of file uploading
      uploadResult: null, // json with result report
      successUpload: false, // if the import is comlpeted
      entity: 0,
    };
  },

  components: {
    errorMessage: errorList,
  },

  computed: mapState({
    orgId() {
      return localStorage.getItem("orgId");
    },

    loadingEntities: (state) => state.account.loadingEntities,
    entitiesLists: (state) => state.account.entities,
  }),

  methods: {
    clearData: function () {
      this.email = "";
      this.exportLink = "";
      this.isLoading = false;
      this.needEmail = false;
      this.success = false;
      this.procLoading = false; // the process of file uploading
      this.uploadResult = null; // json with result report
      this.successUpload = false; // if the import is comlpeted
    },

    openModal() {
      this.$store.dispatch("getEntities");

      this.$root.$emit("bv::show::modal", "exportReceipts");
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "exportReceipts");
    },

    exportList() {
      this.$refs.error.clearErrors();
      this.isLoading = true;
      axios
        .post(
          urls.exportReceipts
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignid)
            .replace(":email", this.email)
            .replace(":legal_entity", this.entity)
        )
        .catch((e) => {
          this.isLoading = false;
          this.needEmail = true;
          this.errorResponse = e.response;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#export-donation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.loading-link-wrap {
  width: 100%;
  .form-group {
    @media only screen and (min-width: 768px) {
      width: 50%;
    }
  }
}

.loading-link,
.success-desc {
  text-align: center;
  width: 100%;
  p {
    width: 100%;
    text-align: center;
  }
}

.export-list {
  margin-top: 20px;
}
</style>
