<template>
  <div>
    <b-card no-body class="mb-3" v-if="tags.length > 0">
      <b-card-header header-tag="header" class="p-0 info-collapse" role="tab">
        <b-button block href="#" v-b-toggle.infoline>
          <slot name="btn-content">
            {{ $t("template.quill_editor_variables_edit_title") }}
          </slot>
        </b-button>
      </b-card-header>
      <slot name="tags" :tags="tags" :tagsFull="tagsFull">
        <b-collapse id="infoline" visible accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <div class="tags-list" ref="tagsListEl" :class="{ expanded: tagsFull && !simple }">
              <div class="tags-list__intro">
                <slot name="description">
                  {{ $t("template.quill_editor_variables_var_description") }}
                </slot>
              </div>
              <div v-for="(tag, index) in tags" :key="index" class="tag">
                <p>
                  <span @click="addTag(tag.value)" class="tag__label">
                    {{ tag.value }}
                  </span>
                  {{ tag.text }}
                </p>
              </div>
            </div>

            <div @click="tagsFull = !tagsFull" class="tag-list-all" v-if="!simple && !hideMoreBtn">
              <slot name="tags-full-text" :tagsFull="tagsFull">
                {{
                  tagsFull
                    ? $t("template.quill_editor_variables_show_description", "Show")
                    : $t("template.quill_editor_variables_hide_description", "Hide")
                }}
              </slot>
            </div>
          </b-card-body>
        </b-collapse>
      </slot>
    </b-card>
    <b-alert v-if="failErrors.length > 0" variant="danger" show>
      {{
        $t(
          "template.quill_editor_variables_fail_errors",
          "You have {count} incorrect variable: {variables} ::: You have {count} incorrect variables: {variables}",
          {
            count: failErrors.length,
            variables: new Intl.ListFormat(lang, { style: "long", type: "conjunction" }).format(
              failErrors
            ),
          },
          failErrors.length
        )
      }}
    </b-alert>
    <div class="mb-3">
      <slot
        name="between-tags-and-editor"
        :value="value"
        :unwrappedValue="unwrappedValue"
        :unwrappedValueInline="unwrappedValueInline"
      />
    </div>
    <quill-editor
      v-if="!simple"
      :value="value"
      @input="onQuillEditorInput"
      class="quill-editor-variables"
      :id="'quill-editor-variables'"
      ref="QuillEditorVariables"
      :options="editorOptionsValue"
      :disabled="disabled"
      :emitWithChangeSource="emitWithChangeSource"
      :editor-direction="editorDirection"
    ></quill-editor>
    <b-textarea
      v-else
      :value="value"
      @input="onTextAreaChange($event)"
      ref="TextAreaVariables"
      rows="10"
      class="textarea-editor"
      :style="{ '--editor-direction': editorDirection }"
      :readonly="disabled"
    ></b-textarea>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CustomVideo from "@/components/vue-quill-editor/formats/custom-video";
import { unwrapContentInline, unwrapContentValue } from "@/components/vue-quill-editor/utils";

export default {
  data() {
    return {
      tagsFull: true,
      hideMoreBtn: false,
      failErrors: [],
    };
  },
  props: {
    value: {
      type: [String, Object],
      default: "",
    },
    editorOptions: {
      type: Object,
      default: () => {
        return {
          modules: {
            toolbar: {
              container: [
                ["bold", "underline", "strike", "italic"],
                ["blockquote"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ size: ["small", false, "large", "huge"] }],
                [{ align: [] }],
                [{ color: [] }, { background: [] }],
                ["image"],
                [CustomVideo.blotName],
                ["link"],
                ["clean"],
              ],
            },
            imageResize: {
              modules: ["Resize", "DisplaySize"],
              displayStyles: {
                backgroundColor: "black",
                border: "none",
                color: "white",
              },
            },
          },
        };
      },
    },
    editorOptionsDisabled: {
      type: Object,
      default: () => {
        return {
          modules: {
            toolbar: false,
          },
        };
      },
    },
    tags: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    emitWithChangeSource: Boolean,
    editorDirection: {
      type: String,
      default: "ltr",
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.translation.locate,
    }),
    editorOptionsValue() {
      if (this.disabled) {
        return this.editorOptionsDisabled;
      }
      return this.editorOptions;
    },
    unwrappedValueInline() {
      return unwrapContentInline(this.value);
    },
    unwrappedValue() {
      return unwrapContentValue(this.value);
    },
  },
  watch: {
    tags: {
      handler(val) {
        if (val.length) {
          this.hideMoreBtn = val.length <= 3;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.validateAndFillErrors(this.unwrappedValue);
  },
  methods: {
    tokenize(input) {
      const tokens = [];
      let currentToken = "";
      let insideTag = false;
      let bracketStack = [];

      for (let i = 0; i < input.length; i++) {
        const char = input[i];

        if (char === "<" && bracketStack.length === 0) {
          if (currentToken) {
            processCurrentToken();
          }
          insideTag = true;
          currentToken += char;
        } else if (char === ">" && insideTag) {
          currentToken += char;
          tokens.push({ type: "WORD", value: currentToken.trim() });
          currentToken = "";
          insideTag = false;
        } else if (char === "[" && !insideTag) {
          if (currentToken) {
            processCurrentToken();
          }
          bracketStack.push(i);
          currentToken += char;
        } else if (char === "]" && bracketStack.length > 0) {
          currentToken += char;
          bracketStack.pop();
          if (bracketStack.length === 0) {
            tokens.push({ type: "VARIABLE", value: currentToken.slice(1, -1).trim() });
            currentToken = "";
          }
        } else {
          currentToken += char;
        }
      }

      if (currentToken) {
        processCurrentToken();
      }

      // Add empty error tokens if needed
      while (tokens.filter((t) => t.type === "ERROR").length < 3) {
        tokens.push({ type: "ERROR", value: "" });
      }

      return tokens;

      function processCurrentToken() {
        if (bracketStack.length > 0) {
          // This is an error token
          tokens.push({ type: "ERROR", value: currentToken.trim() });
        } else if (currentToken.includes("[")) {
          // This token contains an unclosed bracket, so it's an error
          const parts = currentToken.split("[");
          if (parts[0].trim()) {
            tokens.push({ type: "WORD", value: parts[0].trim() });
          }
          tokens.push({ type: "ERROR", value: "[" + parts[1].trim() });
        } else {
          tokens.push({ type: "WORD", value: currentToken.trim() });
        }
        currentToken = "";
      }
    },
    addTag(value) {
      if (!this.simple) {
        const quill = this.$refs.QuillEditorVariables.quill;
        const idx = quill.getSelection(true);
        quill.insertText(idx.index, value, "user");
        quill.setSelection(quill.getLength(), 0);
      } else {
        this.insertAtCursor(this.$refs.TextAreaVariables, value);
      }
    },
    onTextAreaChange(event) {
      this.$emit("input", event);
    },
    variablesValidation(value) {
      return new Promise((resolve) => {
        const tokens = this.tokenize(value);
        const failErrors = tokens
          .filter((token) => token.type === "ERROR" && Boolean(token.value))
          .map((token) => token.value);

        resolve(failErrors);
      });
    },
    validateAndFillErrors(value) {
      this.$emit("update:loading", true);
      this.variablesValidation(value)
        .then((failErrors) => {
          this.failErrors = failErrors;
          this.$emit("update:valid", failErrors.length === 0);
          return failErrors;
        })
        .finally(() => {
          this.$emit("update:loading", false);
        });
    },
    onQuillEditorInput(event) {
      this.validateAndFillErrors(event.value);
      this.$emit("input", event);
    },
    insertAtCursor(myField, myValue) {
      //IE support
      if (document.selection) {
        myField.focus();
        const sel = document.selection.createRange();
        sel.text = myValue;
      }
      //MOZILLA and others
      else if (myField.selectionStart || myField.selectionStart == "0") {
        const startPos = myField.selectionStart;
        const endPos = myField.selectionEnd;
        this.onTextAreaChange(
          myField.value.substring(0, startPos) +
            myValue +
            myField.value.substring(endPos, myField.value.length)
        );
      } else {
        this.onTextAreaChange(myValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.info-collapse {
  background-color: #ffffff;
  // border: none;
  > a.btn {
    color: lighten(#107598, 10%);
    font-family: "VitoExtended-Light";
    background-color: #ffffff;
    border: 1px solid transparent;
    &:hover,
    &:focus,
    &:active {
      color: darken(#107598, 10%);
      background-color: #ffffff;
      border: 1px solid transparent;
    }
  }
}

.tags-list {
  margin-bottom: 20px;
  &.expanded {
    max-height: 270px;
    overflow: hidden;
  }
  &__intro {
    line-height: 1.2;
    margin-bottom: 20px;
  }
}

.tag {
  display: flex;
  flex-direction: row;
  > p {
    margin-bottom: 16px;
  }

  &__label {
    margin-right: 15px;
    padding: 5px 10px;
    border-radius: 3px;
    background-color: rgba(136, 186, 204, 0.4);
    border: 1px solid #2883a2;
    font-size: 14px;
    align-self: flex-start;
    // text-transform: uppercase;
    cursor: pointer;
  }
}
.tag-list-all {
  margin: 0 auto;
  height: 32px;
  width: 140px;
  border: 1px solid #2883a2;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textarea-editor {
  direction: var(--editor-direction, ltr);
}
</style>
