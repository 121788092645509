import urls from "../../constants/urls.js";
import axios from "axios";

const getUrl = (id = "") => {
  const orgId = localStorage.getItem("orgId");
  const posturl = String(id) && `/${id}`;

  return urls.vetting.replace(":oid", orgId) + posturl;
};

const getData = (attr, id) => {
  const orgId = localStorage.getItem("orgId");
  const ido = id || id === 0 ? { id: String(id) } : {};
  return {
    data: {
      ...ido,
      type: "vetting_list",
      attributes: {
        org_customer_id: Number(orgId),
        ...attr,
      },
    },
  };
};

const state = {
  list: [],
  count: 0,
  loading: false,
  error: null,
};

const mutations = {
  setList(state, data) {
    state.list = data;
  },

  setCount(state, val) {
    state.count = val || 0;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  setError(state, val) {
    state.error = val;
  },
};

const actions = {
  fetchVettings({ commit }, [getParams] = []) {
    commit("setLoading", true);

    const url = getUrl();
    const params = {
      ...getParams,
    };

    axios
      .get(url, {
        params,
      })
      .then(({ data, headers }) => {
        commit("setCount", headers["x-total-records"]);
        const list = data.data.map((el) => {
          el.attributes.meta = JSON.parse(el.attributes.meta);
          return el;
        });
        commit("setList", list);
        commit("setLoading", false);
      })
      .catch((e) => {
        commit("setError", e.response);
        commit("setLoading", false);
      });
  },

  fetchVetting(_, [id, getParams] = []) {
    const url = getUrl(id);
    const params = {
      ...getParams,
    };

    return axios.get(url, { params });
  },

  fetchCreateVetting(_, [attr, getParams] = []) {
    const url = getUrl();
    const params = {
      ...getParams,
    };
    const data = getData(attr);

    return axios.post(url, data, { params });
  },

  fetchUpdateVetting(_, [id, attr, getParams] = []) {
    const url = getUrl(id);
    const params = {
      ...getParams,
    };
    const data = getData(attr, id);

    return axios.put(url, data, { params });
  },

  fetchDeleteUTM(_, [id] = []) {
    const url = getUrl(id);

    return axios.delete(url);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
