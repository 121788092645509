<script>
import { mapState } from "vuex";
import SelectCampaign from "@/views/dashboard/subcomponents/selectCampaign.vue";

export default {
  components: {
    SelectCampaign,
  },
  props: {
    state: { type: Object, default: () => ({}) },
    setState: { type: Function, default: () => {} },
    submitByFormId: { type: String, default: "" },
    options: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      showAdditionalBankAccountFields: false,
    };
  },
  computed: {
    ...mapState({
      frabaf: (state) => state.featureHide.foundationRequestAdditionalBankAccountFields,
    }),
    foundationRequestAdditionalBankAccountFields() {
      if (!this.frabaf && this.showAdditionalBankAccountFields) {
        return true;
      }

      return this.frabaf;
    },
  },
  methods: {
    handleSubmit(event, validate) {
      const { submitter } = event;
      const context = {
        continue: submitter.dataset.context === "continue",
      };

      return validate()
        .then(() => this.$emit("can-continue", context))
        .finally(() => this.$emit("loading", false));
    },
    validate() {
      return Promise.resolve();
    },
    setStateFormData(key, value) {
      const formData = {
        ...this.state.formData,
        [key]: value,
      };
      this.setState({
        ...this.state,
        formData: formData,
      });
    },
  },
};
</script>

<template>
  <div v-bind="{}">
    <form :id="submitByFormId" @submit.prevent="handleSubmit($event, validate)">
      <div class="row">
        <div class="col-12">
          <p>
            {{ $t("account.request_foundation_modal_form_data", "Please fill the form below") }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-form-group>
            <label for="organization_name">
              {{ $t("account.organization_name", "Select campaign") }}
            </label>
            <SelectCampaign
              required
              defaultStyles
              :value="state.formData.campaign_id"
              @input="setStateFormData('campaign_id', $event)"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-form-group>
            <label for="organization_name">
              {{ $t("account.organization_name", "Organization name") }}
            </label>
            <b-form-input
              required
              :value="state.formData.organization_name"
              @input="setStateFormData('organization_name', $event)"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-form-group>
            <label for="ngo_id">{{ $t("account.ngo_id", "NGO ID") }}</label>
            <b-form-input
              :value="state.formData.ngo_id"
              @input="setStateFormData('ngo_id', $event)"
              type="text"
              required
            />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-form-group>
            <label for="bank_account_name">
              {{ $t("account.bank_account_name", "Account name") }}
            </label>
            <b-form-input
              required
              :value="state.formData.bank_account_name"
              @input="setStateFormData('bank_account_name', $event)"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row" v-if="!frabaf">
        <div class="col-12">
          <b-form-group>
            <b-form-checkbox
              id="foundation_request_us_based_toggle"
              v-model="showAdditionalBankAccountFields"
              name="foundation_request_us_based_toggle"
            >
              {{ $t("account.foundation_request_us_based_toggle", "US based?") }}
            </b-form-checkbox>
          </b-form-group>
        </div>
      </div>
      <div class="row" v-if="foundationRequestAdditionalBankAccountFields">
        <div class="col-12">
          <b-form-group>
            <label for="bank_account_number">
              {{ $t("account.bank_account_number", "Account number or IBAN#") }}
            </label>
            <b-form-input
              required
              :value="state.formData.bank_account_number"
              @input="setStateFormData('bank_account_number', $event)"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row" v-if="foundationRequestAdditionalBankAccountFields">
        <div class="col-12">
          <b-form-group>
            <label for="bank_account_swift">
              {{ $t("account.bank_account_swift", "ABA# or SWIFT Code") }}
            </label>
            <b-form-input
              required
              :value="state.formData.bank_account_swift"
              @input="setStateFormData('bank_account_swift', $event)"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <b-form-group>
            <label for="bank_address">
              {{ $t("account.bank_address", "Bank address") }}
            </label>
            <b-form-input
              required
              :value="state.formData.bank_address"
              @input="setStateFormData('bank_address', $event)"
            />
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <b-form-group>
            <label for="bank_address">
              {{ $t("account.bank_address_zip_code", "Bank ZIP code") }}
            </label>
            <b-form-input
              required
              :value="state.formData.bank_address_zip_code"
              @input="setStateFormData('bank_address_zip_code', $event)"
            />
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-form-group>
            <label for="appeal_reason">
              {{ $t("account.appeal_reason", "Appeal reason") }}
            </label>
            <b-form-input
              required
              :value="state.formData.appeal_reason"
              @input="setStateFormData('appeal_reason', $event)"
            />
          </b-form-group>
        </div>
      </div>
    </form>
  </div>
</template>
