<template>
  <div class="col-md-6 col-sm-12">
    <h4 class="mb-4">{{ $t("account.change_password") }}</h4>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <b-form @submit.prevent="onSubmit">
        <b-form-group
          :label="$t('account.email')"
          label-for="sign-in-email"
          label-class="form-unit__label--upper-sm"
          class="account-card__inputs"
        >
          <b-form-input
            required
            v-model="changePassword.data.attributes.email"
            id="sign-in-email"
            type="email"
            class="form-control form-unit__input"
            placeholder="Enter your email"
            autocomplete="nope"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          :label="$t('account.current_password')"
          label-for="pass-current"
          label-class="form-unit__label--upper-sm"
          class="account-card__inputs"
        >
          <b-form-input
            required
            v-model="changePassword.data.attributes.current_password"
            id="pass-current"
            type="password"
            placeholder="Enter your current password"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          :label="$t('account.new_password')"
          label-for="pass-new"
          label-class="form-unit__label--upper-sm"
          class="account-card__inputs"
        >
          <password-hint
            ref="passwordHint"
            :target="'pass-new'"
            :password="changePassword.data.attributes.new_password"
          ></password-hint>
          <ValidationProvider vid="new_password">
            <b-form-input
              required
              v-model="changePassword.data.attributes.new_password"
              id="pass-new"
              @focus="$refs.passwordHint.openHint()"
              @blur="$refs.passwordHint.closeHint()"
              type="password"
              placeholder="Enter your new password"
            ></b-form-input>
          </ValidationProvider>
        </b-form-group>

        <b-form-group
          :label="$t('account.confirm_new_password')"
          label-for="pass-confirm-new"
          label-class="form-unit__label--upper-sm"
          class="account-card__inputs"
        >
          <ValidationProvider name=" " rules="confirmed:new_password" v-slot="{ errors }">
            <b-form-input
              required
              v-model="changePassword.data.attributes.new_password2"
              id="pass-confirm-new"
              type="password"
              placeholder="Enter your new password one more time"
            ></b-form-input>
            <div
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              class="invalid-feedback d-block is-danger"
            >
              {{ errors[0] }}
            </div>
          </ValidationProvider>
        </b-form-group>

        <b-button
          type="submit"
          :disabled="isLoading || load || invalid"
          class="bttn bttn--sm bttn--orange"
          >{{ $t("account.save_change") }}</b-button
        >
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import errorMessage from "@/components/error-message.vue";
import passwordHint from "@/components/password-hint-tooltip.vue";

export default {
  data() {
    return {
      load: false,
      changePassword: {
        data: {
          attributes: {
            email: "",
            current_password: "",
            new_password: "",
            new_password2: "",
          },
        },
      },
    };
  },

  mounted() {
    this.changePassword.data.attributes.email = this.donorEmail;
  },

  components: {
    passwordHint,
    errorMessage,
    ValidationProvider,
    ValidationObserver,
  },

  computed: {
    ...mapState({
      donorEmail: (state) => state.donorAccount.account.attributes.email,
      isLoading: (state) => state.donorAccount.loadingAccount,
    }),
  },

  watch: {
    donorEmail() {
      this.changePassword.data.attributes.email = this.donorEmail;
    },
  },

  methods: {
    onSubmit() {
      this.load = true;

      this.$refs.observer.validate().then((isValid) => {
        if (isValid) {
          this.$parent.$refs.error.clearErrors();

          axios
            .post(urls.changePassword, this.changePassword)
            .then(() => {
              this.load = false;

              this.$_notificationsMixin_makeToastCompat({
                group: "donor",
                title: this.$t("account.success_edit"),
              });
            })
            .catch((e) => {
              this.load = false;

              console.log(e);
              this.$parent.$refs.error.setErrorResponse(e.response);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  border-color: #e6eaed;
  background: #f7fbff;
}
</style>
