import { render, staticRenderFns } from "./dashboardTeams.vue?vue&type=template&id=01fcc9ef&scoped=true&"
import script from "./dashboardTeams.vue?vue&type=script&lang=js&"
export * from "./dashboardTeams.vue?vue&type=script&lang=js&"
import style0 from "./dashboardTeams.vue?vue&type=style&index=0&id=01fcc9ef&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01fcc9ef",
  null
  
)

export default component.exports