import urls from "../../constants/urls.js";
import axios from "axios";

const state = {
  list: [],
  count: 0,
  progress: 0,
  loading: false,
  error: null,
};

const mutations = {
  setList(state, data) {
    state.list = data;
  },

  setCount(state, val) {
    state.count = val || 0;
  },

  setProgress(state, val) {
    state.progress = val || 0;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  setError(state, val) {
    state.error = val;
  },
};

const actions = {
  fetchLeads({ commit, rootState }, [cId, getParams]) {
    commit("setLoading", true);

    const orgId = localStorage.getItem("orgId");

    const params = {
      gateway: "pledge",
      status: "pending",
      loc: rootState.translation.locate,
      ...getParams,
    };

    axios
      .get(urls.donationNewApi.replace(":orgId", orgId).replace(":campaignId", cId), { params })
      .then(({ data, headers }) => {
        commit("setCount", headers["x-search-donations"]);
        commit("setList", data.data);
        commit("setLoading", false);
      })
      .catch((e) => {
        commit("setError", e.response);
        commit("setLoading", false);
      });
  },

  fetchDonations({ rootState }, [cId, getParams]) {
    const orgId = localStorage.getItem("orgId");

    if (getParams.first_name && !getParams.last_name) {
      getParams.first_name = "";
    }

    const params = {
      loc: rootState.translation.locate,
      ...getParams,
    };

    return axios.get(urls.donationNewApi.replace(":orgId", orgId).replace(":campaignId", cId), {
      params,
    });
  },

  fetchExportLeads({ commit }, [cId, data = {}, getParams = {}]) {
    const orgId = localStorage.getItem("orgId");

    const params = {
      format: "xlsx",
      bank_name: "pledge",
      only_lead_export: true,
      ...getParams,
    };

    return axios
      .post(urls.donationsExport.replace(":oid", orgId).replace(":cid", cId), data, { params })
      .then(async ({ data }) => {
        const procID = parseInt(data.proc_session_id);
        const procProgressTracker = () =>
          new Promise((resolve, reject) => {
            const interval = setInterval(() => {
              axios
                .get(urls.procProgressTracker.replace(":oid", orgId).replace(":id", procID))
                .then(({ data }) => {
                  commit("setProgress", data.data.attributes.progress);
                  if (data.data.attributes.finished) {
                    commit("setProgress", 0);
                    clearInterval(interval);

                    if (![null, ""].includes(data.data.attributes.meta)) {
                      resolve(JSON.parse(data.data.attributes.meta));
                    }
                  }
                })
                .catch((e) => {
                  reject(e);
                });
            }, 2000);
          });

        const fileData = await procProgressTracker();

        return fileData;
      });
  },
};

export default {
  state,
  actions,
  mutations,
};
