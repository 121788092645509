<template>
  <div class="rds-filters">
    <div class="rds-filters__bottom">
      <b-form-group
        id="filters-fieldset-1"
        :label="$t('dashboard.rds_filters_label', 'Filters')"
        label-for="select-campaign"
        label-class="font-weight-bold"
      >
        <select-campaign v-model="cid" id="select-campaign" default-styles preselect-first />
      </b-form-group>
      <text-3-rows>
        <template #top>
          {{ stats.plan_count }}
        </template>
        <template #middle>{{ $t("dashboard.rds_charges_stats_total", "Total") }}</template>
      </text-3-rows>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import selectCampaign from "@/views/dashboard/subcomponents/selectCampaign.vue";
import text3Rows from "./text-3-rows.vue";

export default {
  components: {
    selectCampaign,
    text3Rows,
  },

  computed: {
    ...mapState({
      filters: (s) => s.rds.ui.filters,
      stats: (s) => s.rds.stats,
    }),

    oid: () => localStorage.getItem("orgId"),

    cid: {
      get() {
        return this.filters.cid;
      },
      set(id) {
        this.setFilter({ key: "cid", value: id });
        this.getStats({ cid: id });
      },
    },
  },

  mounted() {
    this.$store.dispatch("getCampaigns");
  },

  methods: {
    ...mapActions({
      setFilter: "rds/ui/setFilter",
      fetchStats: "rds/fetchStats",
    }),

    getStats({ cid }) {
      this.fetchStats({ oid: this.oid, cid });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .form-group input {
  height: auto;
  border: none;
  padding: 0px 5px;
}
.rds-filters {
  flex: 0.7;
  align-self: center;
  @media screen and (max-width: 768px) {
    flex: 1;
  }
  &__bottom {
    display: grid;
    grid-auto-flow: column;
    align-self: end;
    ::v-deep .text-3-rows {
      justify-items: center;
      border-right: var(--border-darker);
    }
  }
}
</style>
