<template>
  <div v-if="!loading">
    <b-alert v-if="allCampaignsEmpty" show variant="warning">
      {{ $t("template.campaign_list_empty", "Cannot find any campaigns for this query") }}
    </b-alert>
    <template v-else>
      <CampaignList
        v-if="total($options.MODE_TYPE) !== 0"
        :layout="layout"
        :type="$options.MODE_TYPE"
        :page.sync="page"
        external-fetch
        @update-list="updateList"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CampaignList from "./campaign-list.vue";

export default {
  MODE_TYPE: "current",

  components: {
    CampaignList,
  },

  props: {
    searchQuery: {
      type: String,
      default: "",
    },
    layout: {
      type: String,
      default: "grid",
    },
  },

  data() {
    return {
      page: 1,
      perPage: 10,
    };
  },

  computed: {
    ...mapState({
      campaigns: (state) => state.campaigns,
      loading: (state) => state.campaigns.loadingCampaigns,
    }),
    allCampaignsEmpty() {
      return this.campaigns[this.$options.MODE_TYPE].length === 0;
    },
  },

  watch: {
    page(page) {
      if (this.searchQueryNullable(this.searchQuery)) {
        return;
      }

      this.getCampaigns({ action: page > 1 ? "see-more" : "" });
    },
    searchQuery(search) {
      this.page = 1;
      this.getCampaigns(
        { action: this.page > 1 ? "see-more" : "" },
        { search: this.searchQueryNullable(search) }
      );
    },
  },

  created() {
    this.getCampaigns({}, { search: this.searchQueryNullable(this.searchQuery) });
    this.$root.$on("campaignList::getCampaignsByPage", this.getCampaigns);
  },

  beforeDestroy() {
    this.$root.$off("campaignList::getCampaignsByPage");
  },

  methods: {
    total(type) {
      return this.campaigns.total[type];
    },

    searchQueryNullable(searchQuery) {
      if (!searchQuery || searchQuery.length === 0) {
        return null;
      }

      return searchQuery;
    },

    updateList() {
      this.page = 1;
      this.$nextTick(() => {
        this.getCampaigns();
      });
    },

    getCampaigns(extendPayload = {}, extendParams = {}) {
      const extend = ["campaign_stats", "media", "meta", "donation_levels", "donation_streams"];
      return this.$store.dispatch("getCampaigns", {
        ...extendPayload,
        type: this.$options.MODE_TYPE,
        params: {
          page: this.page,
          perPage: this.perPage,
          sort_by: ["-startdate", "campaign_mode_dashboard", "hide_directdonation"],
          extend,
          ...extendParams,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.current-campaigns {
  margin-bottom: 20px;
  .h2-subcat {
    margin-bottom: 20px;
  }
}
</style>
