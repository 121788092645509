<template>
  <div>
    <slot :open="open" :close="close" :sending="sending" :sendErrors="sendErrors" />
    <b-modal ref="EmailMessageModal" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
      <div slot="modal-header">
        <h2 v-if="delivery === 'email'">
          {{ $t(`dashboard.email_message_modal_title`, "Email message") }}
        </h2>
        <h2 v-if="delivery === 'sms'">
          {{ $t(`dashboard.sms_message_modal_title`, "SMS message") }}
        </h2>
        <b-form-checkbox
          id="test-mode"
          v-model="form.test_mode"
          name="test-mode"
          :disabled="step === 1"
        >
          <span class="text-danger font-weight-bold" v-if="delivery === 'email'">
            {{
              $t(`dashboard.org_message_test_mode`, "Enable test mode (send message to your email)")
            }}
          </span>
        </b-form-checkbox>
      </div>

      <b-alert v-for="(err, i) in sendErrors" :key="i" show variant="danger">
        {{ err.detail }}
      </b-alert>

      <b-alert v-if="sended" show variant="success">
        <span v-if="delivery === 'sms'">
          {{ $t(`dashboard.sms_message_success`, "SMS sent successfully") }}
        </span>
        <span v-if="delivery === 'email'">
          {{ $t(`dashboard.email_message_success`, "Email sent successfully") }}
        </span>
      </b-alert>

      <div>
        <email-msg-form
          v-if="step === 0"
          v-model="form"
          ref="form"
          :tags="tags"
          :hide-subject="delivery === 'sms'"
          :simple="delivery === 'sms'"
          :max-length="delivery === 'sms' ? maxLength : 0"
          :show-reply-to="delivery === 'email'"
        />
        <email-msg-preview
          v-if="step === 1"
          :recipient="recipient"
          :subject="form.subject"
          :message="form.message"
        />
      </div>

      <div slot="modal-footer" class="d-flex flex-row">
        <button class="mx-2 bttn bttn--lg bttn--blue" @click="close">
          {{ $t(`dashboard.cancel_btn`, "Close") }}
        </button>

        <button v-if="step === 1" class="mx-2 bttn bttn--lg bttn--blue" @click="prevStep">
          {{ $t(`dashboard.message_modal_back`, "Back") }}
        </button>

        <div class="ml-auto" v-if="step === 0" @click="next">
          <button class="bttn bttn--lg bttn--orange">
            {{ $t(`dashboard.message_modal_next`, "Next") }}
          </button>
        </div>

        <b-overlay
          :show="sending"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="ml-auto d-inline-block"
          v-else-if="step === 1"
        >
          <button class="bttn bttn--lg bttn--orange" :disabled="sending" @click="onSendClick">
            {{ $t(`dashboard.sms_message_modal_send`, "Send") }}
          </button>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { unwrapContentValue } from "@/components/vue-quill-editor/utils";

import emailMsgForm from "@/common-components/messaging/Form.vue";
import EmailMsgPreview from "@/common-components/messaging/Preview.vue";

export default {
  components: { emailMsgForm, EmailMsgPreview },

  data() {
    return {
      delivery: "",
      audience: "donors",
      audienceParams: {},
      step: 0,
      form: {},
      donor: null,
      donors: null,
      team: null,
      sending: false,
      sended: false,
      sendErrors: [],
    };
  },

  computed: {
    ...mapState({
      cId: (state) => state.dashboard.selectedCampaign,
      contacts: (state) => state.account.contacts,
      orgEmail: (state) => state.account.account.data.attributes.email,
    }),
    ...mapGetters({
      getMetasByCampaignId: "getMetasByCampaignId",
      getMetasInSelectedCampaign: "getMetasInSelectedCampaign",
    }),
    metas() {
      if (this.audienceParams.campaign_id) {
        return this.getMetasByCampaignId(String(this.audienceParams.campaign_id));
      }
      return this.getMetasInSelectedCampaign;
    },
    donorSupportContact() {
      return this.contacts.find((el) => el.attributes.type === "donor_support");
    },
    oId() {
      return localStorage.getItem("orgId");
    },
    tags() {
      let options = [
        {
          value: "{{.Name}}",
          text: this.$t("dashboard.lead_message_tag_donor_name", "Name"),
        },
        {
          value: "{{.AmountWithCurrency}}",
          text: this.$t("dashboard.lead_message_tag_amount_with_currency", "Amount with currency"),
        },
        {
          value: "{{.Amount(x2)WithCurrency}}",
          text: this.$t(
            "dashboard.lead_message_tag_amount_with_currency_mul",
            "Amount multiplied to 2 with currency (x2 - can be arbitrary)"
          ),
        },
        {
          value: "{{.TeamNameListFmt}}",
          text: this.$t(
            "dashboard.lead_message_tag_team_name_list_fmt",
            "Team name list joined by comma which donation was made"
          ),
        },
      ];
      return options;
    },
    recipient() {
      if (this.form.test_mode) {
        return this.form.test_mode_recepient_email;
      }

      switch (this.audience) {
        case "donors": {
          const { billing_name, billing_last_name, email, phone } = this.donor?.attributes || {};

          switch (this.delivery) {
            case "email":
              return `${billing_name} ${billing_last_name} (${email})`;

            case "sms":
              return `${billing_name} ${billing_last_name} (${phone})`;

            default:
              return "undefined";
          }
        }

        case "donors_outreach": {
          switch (this.delivery) {
            case "email":
              return `All the donors who haven't yet donated in a current campaign`;

            case "sms":
              return `All the donors who haven't yet donated in a current campaign`;

            default:
              return "undefined";
          }
        }

        default:
          return "undefined";
      }
    },
    maxLength() {
      const meta = this.metas["team_sms_message"] || {};
      return meta.max_length || 160;
    },
  },

  methods: {
    ...mapActions({
      saveMessage: "messaging/save",
    }),

    open({ donor, donors, delivery, audience, audienceParams } = {}) {
      if (delivery) {
        this.delivery = delivery;

        if (delivery === "email") {
          if (this.donorSupportContact) {
            this.form.replay_to = this.donorSupportContact.attributes.email;
          } else {
            this.form.replay_to = this.orgEmail;
          }
        }
      }
      if (audience) {
        this.audience = audience;
      }
      if (audienceParams) {
        this.audienceParams = audienceParams;
      }
      if (donor) {
        this.donor = donor;
      }
      if (donors) {
        this.donors = donors;
      }
      this.$refs.EmailMessageModal.show();
    },

    close() {
      this.delivery = "";
      this.step = 0;
      this.form = {};
      this.donor = null;
      this.team = null;
      this.sended = false;
      this.sendErrors = [];
      this.$emit("close");
      this.$refs.EmailMessageModal.hide();
    },

    prevStep() {
      if (this.step > 0) {
        this.step -= 1;
      }
    },

    nextStep() {
      this.step += 1;
    },

    next() {
      this.$refs.form.validate().then(() => {
        this.nextStep();
      });
    },

    onSendClick() {
      this.sending = true;

      let data = {};
      const message = unwrapContentValue(this.form.message);

      let cID = Number(this.cId);

      switch (this.audience) {
        case "donors": {
          let donor_ids = [];
          if (this.donor) {
            donor_ids = [Number(this.donor.id)];
          } else if (this.donors) {
            donor_ids = this.donors.map((el) => Number(el.id));
          }

          if (cID === 0) {
            if (this.donor?.attributes) {
              cID = this.donor?.attributes.campaign_id;
            } else if (this.donors) {
              cID = this.audienceParams.campaign_id;
            }
          }

          data = {
            ...this.audienceParams,
            campaign_id: cID,
            ...this.form,
            message,
            donor_ids,
          };
          break;
        }

        case "donors_outreach": {
          cID = this.audienceParams.campaign_id;

          let donor_ids = [];
          if (this.donor) {
            donor_ids = [Number(this.donor.id)];
          } else if (this.donors) {
            donor_ids = this.donors.map((el) => Number(el.id));
          }

          if (this.donor?.attributes) {
            cID = this.donor?.attributes.campaign_id;
          }

          data = {
            ...this.audienceParams,
            ...this.form,
            message,
            donor_ids,
          };
          break;
        }

        default:
          break;
      }

      this.saveMessage({
        oId: this.oId,
        cId: cID,
        delivery: this.delivery,
        audience: this.audience,
        data,
      })
        .then((data) => {
          this.sended = true;
          this.$_notificationsMixin_makeToast(
            `Success`,
            `Message id: ${data.sqs_message_id}`,
            "success"
          );
        })
        .catch((e) => (this.sendErrors = e.response?.data?.errors || []))
        .finally(() => {
          this.sending = false;
          if (this.sendErrors.length === 0) {
            this.close();
          }
        });
    },
  },
};
</script>
