<template>
  <b-card class="account-card">
    <h2 slot="header" class="account-card__title">
      {{ $t("account.sub_account") }}
    </h2>

    <div class="account-card__action-top">
      <sub-account-add ref="subaccmodal"></sub-account-add>
    </div>

    <div class="subaccount-wrap" v-if="subAccList.length">
      <div class="subaccount-row" v-for="(subaccount, index) in subAccList" :key="index">
        <div class="subaccount-row__name">
          {{ subaccount.attributes.email }}
        </div>

        <div class="subaccount-row__status">
          <div>
            <input
              type="checkbox"
              v-model="subaccount.attributes.active"
              @change="updateSubAccount($event, subaccount.id)"
              :id="'checkbox' + index"
            />
            <label :for="'checkbox' + index"></label>
          </div>
        </div>

        <error-message ref="error"></error-message>

        <div class="subaccount-row__controls">
          <button
            type="button"
            class="bttn-round btn-edit-subaccount"
            name="button"
            @click="editSubAccount(subaccount)"
          >
            <i class="fa fa-pencil" aria-hidden="true"></i>
          </button>

          <button
            type="button"
            class="bttn-round btn-remove-subaccount"
            name="button"
            @click="deleteSubAccount(subaccount)"
          >
            <b-icon-trash />
          </button>
        </div>
      </div>
    </div>
    <div v-else class="subaccount-empty">
      {{ $t("account.sub_account_no_subaccounts") }}
    </div>
  </b-card>
</template>

<script>
import urls from "@/constants/urls.js";

import errorList from "@/components/error-message.vue";

import { mapState } from "vuex";
import axios from "axios";

import subAccountAdd from "./subAccountAdd";
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],

  data() {
    return {
      subAccList: [],
      patchModel: {
        data: {
          attributes: {
            active: "",
          },
        },
      },
    };
  },

  components: {
    subAccountAdd,
    errorMessage: errorList,
  },

  created() {
    if (this.hasAccess) {
      this.$store.dispatch("getSubaccountList");
    }
  },

  computed: mapState({
    loading: (state) => {
      return state.account.loadingAccount;
    },
    subAcc: (state) => {
      return state.account.subAccountList;
    },
    hasAccess: (state) => {
      return state.account.access.CanManageOtherAccountAccess;
    },
  }),
  watch: {
    subAcc: function () {
      this.setSubAccounts();
    },
  },
  methods: {
    setSubAccounts: function () {
      this.subAccList = this.subAcc;
    },

    editSubAccount(item) {
      this.$refs.subaccmodal.editMode(item);
    },

    deleteSubAccount(item) {
      let message = this.$t("account.confirm_delete_sub_account");

      let options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.$store
          .dispatch("deleteSubAccount", item)
          .then(() => {
            this.$_notificationsMixin_makeToast("Success", this.$t("users.success_edit"));
          })
          .catch(this.$_notificationsMixin_handleCatch);
      });
    },

    updateSubAccount(event, id) {
      let orgId = localStorage.getItem("orgId");
      this.patchModel.data.attributes = {
        active: event.target.checked,
      };
      axios
        .patch(
          urls.patchSubAccount.replace(":orgId", orgId).replace(":sucAccId", id),
          this.patchModel
        )
        .then(() => {
          this.$store.dispatch("getSubaccountList");
          this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
        })
        .catch((e) => {
          console.log(e);
          this.$refs.error.setErrorResponse(e.response);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  display: block;
  opacity: 0;
}
label {
  width: 60px;
  height: 30px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: rgb(189, 189, 189);
  border-radius: 30px;

  transition: background-color 0.4s;
  -moz-transition: background-color 0.4s;
  -webkit-transition: background-color 0.4s;
}
label:after {
  left: 0;
  width: 20px;
  height: 20px;
  margin: 5px;
  content: "";
  position: absolute;
  background: #fff;
  border-radius: 10px;
}
input:checked + label {
  background: #3cc7a0;
}
input:checked + label:after {
  left: auto;
  right: 0;
}
</style>
