<template>
  <div>
    <CampaignMarketing
      :googleAnalyticsNumber="state.campaignGoogleAnalyticsNumber"
      :facebookPixelId="state.campaignFacebookPixelId"
      :googleConversionId="state.campaignGoogleConversionId"
      :googleConversionLabel="state.campaignGoogleConversionLabel"
      :googleTagManager="state.campaignGoogleTagManager"
      @update:googleAnalyticsNumber="(v) => setState('campaignGoogleAnalyticsNumber', v)"
      @update:facebookPixelId="(v) => setState('campaignFacebookPixelId', v)"
      @update:googleConversionId="(v) => setState('campaignGoogleConversionId', v)"
      @update:googleConversionLabel="(v) => setState('campaignGoogleConversionLabel', v)"
      @update:googleTagManager="(v) => setState('campaignGoogleTagManager', v)"
      :disabled="!hasAccess"
      containerClass="row w-100"
    >
      <template #footer>
        <CampaignShare />
      </template>
    </CampaignMarketing>
  </div>
</template>

<script>
import { mapState } from "vuex";

import CampaignMarketing from "@/views/campaigns/campaign-edit-vue/subcomponents/campaignMarketing.vue";
import CampaignShare from "@/views/campaigns/campaign-edit-vue/subcomponents/campaign-share.vue";

export default {
  components: {
    CampaignMarketing,
    CampaignShare,
  },
  props: {
    state: { type: Object, default: () => ({}) },
    setState: { type: Function, default: () => {} },
  },
  computed: {
    ...mapState({
      hasAccess: (state) => {
        return state.account.access.CanEditCampaignContent;
      },
    }),
  },
};
</script>

<style lang="scss" scoped></style>
