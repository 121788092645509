<script>
import { notificationsMixin } from "@/mixins";
import { mapActions } from "vuex";

export default {
  mixins: [notificationsMixin],
  props: {
    campaignId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: "",
    };
  },
  methods: {
    ...mapActions({
      refreshImages: "campaignShare/refreshImages",
    }),
    async _refreshImages() {
      this.error = "";
      this.loading = true;

      try {
        await this.refreshImages([this.campaignId]);
        this.$_notificationsMixin_makeToast(
          "Success",
          this.$t("edit_campaign.refresh_image_success", "Successfully refreshed share images"),
          "success"
        );
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        this.error = this.$t("edit_campaign.refresh_image_error", "Cannot refresh image");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <slot :refreshImages="_refreshImages" :loading="loading" :error="error" />
  </div>
</template>
