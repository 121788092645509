<template>
  <div class="desktop-media">
    <svg
      v-if="screen === 't0'"
      width="478px"
      height="332px"
      viewBox="0 0 478 332"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <filter
          x="-38.5%"
          y="-48.3%"
          width="176.8%"
          height="215.2%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          x="-44.2%"
          y="-58.3%"
          width="188.2%"
          height="235.2%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="25"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0.113819851   0 0 0 0 0.171881521   0 0 0 0 0.314918155  0 0 0 0.112601902 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <path
          d="M399.011558,222.99169 C399.011558,222.99169 385.836182,228.394532 352.90025,228.394532 C352.90025,228.394532 242.599027,229.362881 199.505779,229.362881 C156.412531,229.362881 46.1113077,228.394532 46.1113077,228.394532 C13.1753765,228.394532 0,222.99169 0,222.99169 L399.011558,222.99169 Z"
          id="path-3"
        ></path>
        <filter
          x="-10.7%"
          y="-651.4%"
          width="121.3%"
          height="1434.1%"
          filterUnits="objectBoundingBox"
          id="filter-4"
        >
          <feGaussianBlur stdDeviation="12.5 0" in="SourceGraphic"></feGaussianBlur>
        </filter>
        <filter
          x="-11.4%"
          y="-698.5%"
          width="122.8%"
          height="1528.3%"
          filterUnits="objectBoundingBox"
          id="filter-5"
        >
          <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="2.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="95.3611898%" id="linearGradient-6">
          <stop stop-color="#000000" offset="0%"></stop>
          <stop stop-color="#000000" stop-opacity="0" offset="100%"></stop>
        </linearGradient>
        <path
          d="M47.881387,0 L351.130171,0 C357.375583,0 362.621704,5.23832811 362.621704,11.468144 L362.621704,213.434903 C362.621704,219.664719 362.583389,224.903047 356.237519,224.903047 L43.4124575,224.903047 C36.9861479,224.903047 36.3898541,219.664719 36.3898541,213.434903 L36.3898541,11.468144 C36.3898541,5.23832811 41.6359747,0 47.881387,0 L47.881387,0 Z"
          id="path-7"
        ></path>
        <polygon
          id="path-8"
          points="48.5198055 16.565097 350.491753 16.565097 350.491753 204.515235 48.5198055 204.515235"
        ></polygon>
        <linearGradient x1="50%" y1="99.740912%" x2="50%" y2="0%" id="linearGradient-10">
          <stop stop-color="#D0D0D0" offset="0%"></stop>
          <stop stop-color="#E7E7E7" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="100%" y1="59.0212107%" x2="0%" y2="59.0212107%" id="linearGradient-11">
          <stop stop-color="#000000" offset="0%"></stop>
          <stop stop-color="#FFFFFF" stop-opacity="0.5" offset="49.648972%"></stop>
          <stop stop-color="#000000" offset="100%"></stop>
        </linearGradient>
        <polygon
          id="path-12"
          points="0.638418493 221.080332 399.649977 221.080332 399.649977 222.354571 0.638418493 222.354571"
        ></polygon>
        <filter
          x="-0.1%"
          y="-35.0%"
          width="100.3%"
          height="159.2%"
          filterUnits="objectBoundingBox"
          id="filter-13"
        >
          <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetInner1"></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.300000012 0"
            type="matrix"
            in="shadowInnerInner1"
          ></feColorMatrix>
        </filter>
        <linearGradient x1="50%" y1="2.0567602%" x2="50%" y2="100%" id="linearGradient-14">
          <stop stop-color="#000000" offset="0%"></stop>
          <stop stop-color="#999999" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="50%" x2="50%" y2="0%" id="linearGradient-15">
          <stop stop-color="#000000" stop-opacity="0" offset="0%"></stop>
          <stop stop-color="#000000" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="20.7927981%" x2="50%" y2="100%" id="linearGradient-16">
          <stop stop-color="#FFFFFF" stop-opacity="-0.0636196476" offset="0%"></stop>
          <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
          <stop stop-color="#FFFFFF" offset="100%"></stop>
        </linearGradient>
        <path
          d="M199.824988,224.265928 C185.321112,224.265928 165.310488,223.911973 161.519879,223.204063 L161.519879,221.080332 L238.130098,221.080332 L238.130098,223.204063 C234.339488,223.911973 214.328865,224.265928 199.824988,224.265928 Z"
          id="path-17"
        ></path>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="images_1" transform="translate(-983.000000, -262.000000)">
          <g
            id="laptop_main"
            filter="url(#filter-1)"
            transform="translate(1022.000000, 292.000000)"
          >
            <g id="Group-2" filter="url(#filter-2)" transform="translate(0.000000, -0.000000)">
              <g id="shadow" opacity="0.1" filter="url(#filter-4)" fill="black" fill-opacity="1">
                <use filter="url(#filter-5)" xlink:href="#path-3"></use>
              </g>
              <g id="top">
                <use fill="#FFFEFF" fill-rule="evenodd" xlink:href="#path-7"></use>
                <use
                  fill-opacity="0.1"
                  fill="url(#linearGradient-6)"
                  fill-rule="evenodd"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-7"
                ></use>
                <path
                  stroke-opacity="0.25"
                  stroke="#989898"
                  stroke-width="3.75"
                  d="M47.881387,1.875 C42.671719,1.875 38.2648541,6.27365513 38.2648541,11.468144 L38.2648541,213.434903 C38.2648541,220.714228 39.3200589,223.028047 43.4124575,223.028047 L356.237519,223.028047 C360.07073,223.028047 360.746704,221.269675 360.746704,213.434903 L360.746704,11.468144 C360.746704,6.27365513 356.339839,1.875 351.130171,1.875 L47.881387,1.875 Z"
                  stroke-linejoin="square"
                ></path>
              </g>
              <mask id="mask-9" fill="white">
                <use xlink:href="#path-8"></use>
              </mask>
              <use id="screen" stroke="#EAEAEA" fill="#F7F8FA" xlink:href="#path-8"></use>
              <path
                d="M399.649977,222.354571 C399.649977,222.354571 386.4746,228.837982 353.683845,228.470914 C353.538669,228.837982 243.237446,230 200.463407,230 C157.050949,230 46.7497262,228.837982 46.60455,228.470914 C13.813795,228.837982 0.638418493,222.354571 0.638418493,222.354571 L399.649977,222.354571 Z"
                id="bottom"
                fill="url(#linearGradient-10)"
              ></path>
              <g id="bottomfront">
                <use fill="#F2F2F2" fill-rule="evenodd" xlink:href="#path-12"></use>
                <use
                  fill-opacity="0.02"
                  fill="url(#linearGradient-11)"
                  fill-rule="evenodd"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-12"
                ></use>
                <use
                  fill="black"
                  fill-opacity="1"
                  filter="url(#filter-13)"
                  xlink:href="#path-12"
                ></use>
              </g>
              <g id="shadows">
                <use fill="#FAFAFA" xlink:href="#path-17"></use>
                <use
                  fill-opacity="0.03"
                  fill="url(#linearGradient-14)"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-17"
                ></use>
                <use
                  fill-opacity="0.04"
                  fill="url(#linearGradient-15)"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-17"
                ></use>
                <use
                  fill-opacity="0.3"
                  fill="url(#linearGradient-16)"
                  style="mix-blend-mode: soft-light"
                  xlink:href="#path-17"
                ></use>
              </g>
            </g>
            <g
              id="dummy-text-copy"
              transform="translate(76.000000, 138.000000)"
              fill="#E6EAED"
              opacity="0.800000012"
            >
              <rect id="Rectangle-3" x="25" y="0" width="190" height="59" rx="2"></rect>
            </g>
            <rect
              id="Header"
              fill="#E6EAED"
              opacity="0.800000012"
              x="65"
              y="27"
              width="268"
              height="10"
              rx="2"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="screen === 't1'"
      width="478px"
      height="332px"
      viewBox="0 0 478 332"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <filter
          x="-38.5%"
          y="-48.3%"
          width="176.8%"
          height="215.2%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          x="-44.2%"
          y="-58.3%"
          width="188.2%"
          height="235.2%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="25"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0.113819851   0 0 0 0 0.171881521   0 0 0 0 0.314918155  0 0 0 0.112601902 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <path
          d="M399.011558,222.99169 C399.011558,222.99169 385.836182,228.394532 352.90025,228.394532 C352.90025,228.394532 242.599027,229.362881 199.505779,229.362881 C156.412531,229.362881 46.1113077,228.394532 46.1113077,228.394532 C13.1753765,228.394532 0,222.99169 0,222.99169 L399.011558,222.99169 Z"
          id="path-3"
        ></path>
        <filter
          x="-10.7%"
          y="-651.4%"
          width="121.3%"
          height="1434.1%"
          filterUnits="objectBoundingBox"
          id="filter-4"
        >
          <feGaussianBlur stdDeviation="12.5 0" in="SourceGraphic"></feGaussianBlur>
        </filter>
        <filter
          x="-11.4%"
          y="-698.5%"
          width="122.8%"
          height="1528.3%"
          filterUnits="objectBoundingBox"
          id="filter-5"
        >
          <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="2.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="95.3611898%" id="linearGradient-6">
          <stop stop-color="#000000" offset="0%"></stop>
          <stop stop-color="#000000" stop-opacity="0" offset="100%"></stop>
        </linearGradient>
        <path
          d="M47.881387,0 L351.130171,0 C357.375583,0 362.621704,5.23832811 362.621704,11.468144 L362.621704,213.434903 C362.621704,219.664719 362.583389,224.903047 356.237519,224.903047 L43.4124575,224.903047 C36.9861479,224.903047 36.3898541,219.664719 36.3898541,213.434903 L36.3898541,11.468144 C36.3898541,5.23832811 41.6359747,0 47.881387,0 L47.881387,0 Z"
          id="path-7"
        ></path>
        <polygon
          id="path-8"
          points="48.5198055 16.565097 350.491753 16.565097 350.491753 204.515235 48.5198055 204.515235"
        ></polygon>
        <linearGradient x1="50%" y1="99.740912%" x2="50%" y2="0%" id="linearGradient-10">
          <stop stop-color="#D0D0D0" offset="0%"></stop>
          <stop stop-color="#E7E7E7" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="100%" y1="59.0212107%" x2="0%" y2="59.0212107%" id="linearGradient-11">
          <stop stop-color="#000000" offset="0%"></stop>
          <stop stop-color="#FFFFFF" stop-opacity="0.5" offset="49.648972%"></stop>
          <stop stop-color="#000000" offset="100%"></stop>
        </linearGradient>
        <polygon
          id="path-12"
          points="0.638418493 221.080332 399.649977 221.080332 399.649977 222.354571 0.638418493 222.354571"
        ></polygon>
        <filter
          x="-0.1%"
          y="-35.0%"
          width="100.3%"
          height="159.2%"
          filterUnits="objectBoundingBox"
          id="filter-13"
        >
          <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetInner1"></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.300000012 0"
            type="matrix"
            in="shadowInnerInner1"
          ></feColorMatrix>
        </filter>
        <linearGradient x1="50%" y1="2.0567602%" x2="50%" y2="100%" id="linearGradient-14">
          <stop stop-color="#000000" offset="0%"></stop>
          <stop stop-color="#999999" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="50%" x2="50%" y2="0%" id="linearGradient-15">
          <stop stop-color="#000000" stop-opacity="0" offset="0%"></stop>
          <stop stop-color="#000000" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="20.7927981%" x2="50%" y2="100%" id="linearGradient-16">
          <stop stop-color="#FFFFFF" stop-opacity="-0.0636196476" offset="0%"></stop>
          <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
          <stop stop-color="#FFFFFF" offset="100%"></stop>
        </linearGradient>
        <path
          d="M199.824988,224.265928 C185.321112,224.265928 165.310488,223.911973 161.519879,223.204063 L161.519879,221.080332 L238.130098,221.080332 L238.130098,223.204063 C234.339488,223.911973 214.328865,224.265928 199.824988,224.265928 Z"
          id="path-17"
        ></path>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="images_1" transform="translate(-983.000000, -852.000000)">
          <g
            id="laptop_main-copy"
            filter="url(#filter-1)"
            transform="translate(1022.000000, 882.000000)"
          >
            <g id="Group-2" filter="url(#filter-2)" transform="translate(0.000000, -0.000000)">
              <g id="shadow" opacity="0.1" filter="url(#filter-4)" fill="black" fill-opacity="1">
                <use filter="url(#filter-5)" xlink:href="#path-3"></use>
              </g>
              <g id="top">
                <use fill="#FFFEFF" fill-rule="evenodd" xlink:href="#path-7"></use>
                <use
                  fill-opacity="0.1"
                  fill="url(#linearGradient-6)"
                  fill-rule="evenodd"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-7"
                ></use>
                <path
                  stroke-opacity="0.25"
                  stroke="#989898"
                  stroke-width="3.75"
                  d="M47.881387,1.875 C42.671719,1.875 38.2648541,6.27365513 38.2648541,11.468144 L38.2648541,213.434903 C38.2648541,220.714228 39.3200589,223.028047 43.4124575,223.028047 L356.237519,223.028047 C360.07073,223.028047 360.746704,221.269675 360.746704,213.434903 L360.746704,11.468144 C360.746704,6.27365513 356.339839,1.875 351.130171,1.875 L47.881387,1.875 Z"
                  stroke-linejoin="square"
                ></path>
              </g>
              <mask id="mask-9" fill="white">
                <use xlink:href="#path-8"></use>
              </mask>
              <use id="screen" stroke="#EAEAEA" fill="#F7F8FA" xlink:href="#path-8"></use>
              <path
                d="M399.649977,222.354571 C399.649977,222.354571 386.4746,228.837982 353.683845,228.470914 C353.538669,228.837982 243.237446,230 200.463407,230 C157.050949,230 46.7497262,228.837982 46.60455,228.470914 C13.813795,228.837982 0.638418493,222.354571 0.638418493,222.354571 L399.649977,222.354571 Z"
                id="bottom"
                fill="url(#linearGradient-10)"
              ></path>
              <g id="bottomfront">
                <use fill="#F2F2F2" fill-rule="evenodd" xlink:href="#path-12"></use>
                <use
                  fill-opacity="0.02"
                  fill="url(#linearGradient-11)"
                  fill-rule="evenodd"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-12"
                ></use>
                <use
                  fill="black"
                  fill-opacity="1"
                  filter="url(#filter-13)"
                  xlink:href="#path-12"
                ></use>
              </g>
              <g id="shadows">
                <use fill="#FAFAFA" xlink:href="#path-17"></use>
                <use
                  fill-opacity="0.03"
                  fill="url(#linearGradient-14)"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-17"
                ></use>
                <use
                  fill-opacity="0.04"
                  fill="url(#linearGradient-15)"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-17"
                ></use>
                <use
                  fill-opacity="0.3"
                  fill="url(#linearGradient-16)"
                  style="mix-blend-mode: soft-light"
                  xlink:href="#path-17"
                ></use>
              </g>
            </g>
            <g
              id="dummy-text-copy"
              transform="translate(65.000000, 158.000000)"
              fill="#E6EAED"
              opacity="0.800000012"
            >
              <rect id="Rectangle-3" x="0" y="0" width="176" height="4" rx="2"></rect>
              <rect id="Rectangle-3" x="0" y="11" width="83" height="4" rx="2"></rect>
              <rect id="Rectangle-3" x="89" y="11" width="49" height="4" rx="2"></rect>
              <rect id="Rectangle-3" x="0" y="34" width="42" height="4" rx="2"></rect>
              <rect id="Rectangle-3" x="49" y="34" width="49" height="4" rx="2"></rect>
              <rect id="Rectangle-3" x="104" y="34" width="76" height="4" rx="2"></rect>
              <rect id="Rectangle-3" x="0" y="23" width="151" height="4" rx="2"></rect>
            </g>
            <g id="slider_dots" transform="translate(140.000000, 145.000000)">
              <circle
                id="Oval-3"
                fill="#E9ECF0"
                cx="2.01149425"
                cy="2.81609195"
                r="2.01149425"
              ></circle>
              <circle
                id="Oval-3"
                fill="#E9ECF0"
                cx="10.0574713"
                cy="2.81609195"
                r="2.01149425"
              ></circle>
              <circle
                id="Oval-3"
                fill="#E9ECF0"
                cx="27.7586207"
                cy="2.81609195"
                r="2.01149425"
              ></circle>
              <circle
                id="Oval-3"
                fill="#9B9B9B"
                cx="18.908046"
                cy="2.81609195"
                r="2.81609195"
              ></circle>
            </g>
            <rect
              id="form_bttn"
              stroke="#E6EAED"
              stroke-width="2"
              fill="#E6EAED"
              fill-rule="nonzero"
              x="274"
              y="180"
              width="42"
              height="8"
              rx="3"
            ></rect>
            <rect
              id="form_input"
              stroke="#E6EAED"
              stroke-width="2"
              fill-rule="nonzero"
              x="274"
              y="166"
              width="42"
              height="8"
              rx="3"
            ></rect>
            <rect
              id="form"
              stroke="#E6EAED"
              stroke-width="2"
              fill-rule="nonzero"
              x="259"
              y="159"
              width="72"
              height="36"
              rx="3"
            ></rect>
            <rect
              id="Rectangle"
              stroke="#E6EAED"
              stroke-width="2"
              fill-rule="nonzero"
              x="259"
              y="47"
              width="72"
              height="92"
              rx="3"
            ></rect>
            <image
              id="Group-9"
              x="267"
              y="61"
              width="57.1533341"
              height="62.4290265"
              xlink:href="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1715089457_6537653137363433626435653735306338623934643566666536633534326339_31373135303839343537.png"
            ></image>
            <rect
              id="Header"
              fill="#E6EAED"
              opacity="0.800000012"
              x="64"
              y="27"
              width="268"
              height="10"
              rx="2"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="screen === 't2'"
      width="478px"
      height="332px"
      viewBox="0 0 478 332"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <filter
          x="-38.5%"
          y="-48.3%"
          width="176.8%"
          height="215.2%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          x="-44.2%"
          y="-58.3%"
          width="188.2%"
          height="235.2%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="25"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0.113819851   0 0 0 0 0.171881521   0 0 0 0 0.314918155  0 0 0 0.112601902 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <path
          d="M399.011558,222.99169 C399.011558,222.99169 385.836182,228.394532 352.90025,228.394532 C352.90025,228.394532 242.599027,229.362881 199.505779,229.362881 C156.412531,229.362881 46.1113077,228.394532 46.1113077,228.394532 C13.1753765,228.394532 0,222.99169 0,222.99169 L399.011558,222.99169 Z"
          id="path-3"
        ></path>
        <filter
          x="-10.7%"
          y="-651.4%"
          width="121.3%"
          height="1434.1%"
          filterUnits="objectBoundingBox"
          id="filter-4"
        >
          <feGaussianBlur stdDeviation="12.5 0" in="SourceGraphic"></feGaussianBlur>
        </filter>
        <filter
          x="-11.4%"
          y="-698.5%"
          width="122.8%"
          height="1528.3%"
          filterUnits="objectBoundingBox"
          id="filter-5"
        >
          <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="2.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="95.3611898%" id="linearGradient-6">
          <stop stop-color="#000000" offset="0%"></stop>
          <stop stop-color="#000000" stop-opacity="0" offset="100%"></stop>
        </linearGradient>
        <path
          d="M47.881387,0 L351.130171,0 C357.375583,0 362.621704,5.23832811 362.621704,11.468144 L362.621704,213.434903 C362.621704,219.664719 362.583389,224.903047 356.237519,224.903047 L43.4124575,224.903047 C36.9861479,224.903047 36.3898541,219.664719 36.3898541,213.434903 L36.3898541,11.468144 C36.3898541,5.23832811 41.6359747,0 47.881387,0 L47.881387,0 Z"
          id="path-7"
        ></path>
        <polygon
          id="path-8"
          points="48.5198055 16.565097 350.491753 16.565097 350.491753 204.515235 48.5198055 204.515235"
        ></polygon>
        <linearGradient x1="50%" y1="99.740912%" x2="50%" y2="0%" id="linearGradient-10">
          <stop stop-color="#D0D0D0" offset="0%"></stop>
          <stop stop-color="#E7E7E7" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="100%" y1="59.0212107%" x2="0%" y2="59.0212107%" id="linearGradient-11">
          <stop stop-color="#000000" offset="0%"></stop>
          <stop stop-color="#FFFFFF" stop-opacity="0.5" offset="49.648972%"></stop>
          <stop stop-color="#000000" offset="100%"></stop>
        </linearGradient>
        <polygon
          id="path-12"
          points="0.638418493 221.080332 399.649977 221.080332 399.649977 222.354571 0.638418493 222.354571"
        ></polygon>
        <filter
          x="-0.1%"
          y="-35.0%"
          width="100.3%"
          height="159.2%"
          filterUnits="objectBoundingBox"
          id="filter-13"
        >
          <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetInner1"></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.300000012 0"
            type="matrix"
            in="shadowInnerInner1"
          ></feColorMatrix>
        </filter>
        <linearGradient x1="50%" y1="2.0567602%" x2="50%" y2="100%" id="linearGradient-14">
          <stop stop-color="#000000" offset="0%"></stop>
          <stop stop-color="#999999" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="50%" x2="50%" y2="0%" id="linearGradient-15">
          <stop stop-color="#000000" stop-opacity="0" offset="0%"></stop>
          <stop stop-color="#000000" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="20.7927981%" x2="50%" y2="100%" id="linearGradient-16">
          <stop stop-color="#FFFFFF" stop-opacity="-0.0636196476" offset="0%"></stop>
          <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
          <stop stop-color="#FFFFFF" offset="100%"></stop>
        </linearGradient>
        <path
          d="M199.824988,224.265928 C185.321112,224.265928 165.310488,223.911973 161.519879,223.204063 L161.519879,221.080332 L238.130098,221.080332 L238.130098,223.204063 C234.339488,223.911973 214.328865,224.265928 199.824988,224.265928 Z"
          id="path-17"
        ></path>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="images_1" transform="translate(-983.000000, -552.000000)">
          <g
            id="laptop_main-copy-3"
            filter="url(#filter-1)"
            transform="translate(1022.000000, 582.000000)"
          >
            <g id="Group-2" filter="url(#filter-2)" transform="translate(0.000000, -0.000000)">
              <g id="shadow" opacity="0.1" filter="url(#filter-4)" fill="black" fill-opacity="1">
                <use filter="url(#filter-5)" xlink:href="#path-3"></use>
              </g>
              <g id="top">
                <use fill="#FFFEFF" fill-rule="evenodd" xlink:href="#path-7"></use>
                <use
                  fill-opacity="0.1"
                  fill="url(#linearGradient-6)"
                  fill-rule="evenodd"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-7"
                ></use>
                <path
                  stroke-opacity="0.25"
                  stroke="#989898"
                  stroke-width="3.75"
                  d="M47.881387,1.875 C42.671719,1.875 38.2648541,6.27365513 38.2648541,11.468144 L38.2648541,213.434903 C38.2648541,220.714228 39.3200589,223.028047 43.4124575,223.028047 L356.237519,223.028047 C360.07073,223.028047 360.746704,221.269675 360.746704,213.434903 L360.746704,11.468144 C360.746704,6.27365513 356.339839,1.875 351.130171,1.875 L47.881387,1.875 Z"
                  stroke-linejoin="square"
                ></path>
              </g>
              <mask id="mask-9" fill="white">
                <use xlink:href="#path-8"></use>
              </mask>
              <use id="screen" stroke="#EAEAEA" fill="#F7F8FA" xlink:href="#path-8"></use>
              <path
                d="M399.649977,222.354571 C399.649977,222.354571 386.4746,228.837982 353.683845,228.470914 C353.538669,228.837982 243.237446,230 200.463407,230 C157.050949,230 46.7497262,228.837982 46.60455,228.470914 C13.813795,228.837982 0.638418493,222.354571 0.638418493,222.354571 L399.649977,222.354571 Z"
                id="bottom"
                fill="url(#linearGradient-10)"
              ></path>
              <g id="bottomfront">
                <use fill="#F2F2F2" fill-rule="evenodd" xlink:href="#path-12"></use>
                <use
                  fill-opacity="0.02"
                  fill="url(#linearGradient-11)"
                  fill-rule="evenodd"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-12"
                ></use>
                <use
                  fill="black"
                  fill-opacity="1"
                  filter="url(#filter-13)"
                  xlink:href="#path-12"
                ></use>
              </g>
              <g id="shadows">
                <use fill="#FAFAFA" xlink:href="#path-17"></use>
                <use
                  fill-opacity="0.03"
                  fill="url(#linearGradient-14)"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-17"
                ></use>
                <use
                  fill-opacity="0.04"
                  fill="url(#linearGradient-15)"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-17"
                ></use>
                <use
                  fill-opacity="0.3"
                  fill="url(#linearGradient-16)"
                  style="mix-blend-mode: soft-light"
                  xlink:href="#path-17"
                ></use>
              </g>
            </g>
            <g
              id="dummy-text-copy"
              transform="translate(101.000000, 136.000000)"
              fill="#E6EAED"
              opacity="0.800000012"
            >
              <path
                d="M36.2941176,15.1792541 L55.7058824,0.253809704 C56.4206038,-0.295729983 57,0.076654877 57,1.08555457 L57,1.99894181 C57,3.0078415 56.4206038,4.27120624 55.7058824,4.82074593 L36.2941176,19.7461903 C35.5793962,20.29573 35,19.9233451 35,18.9144454 L35,18.0010582 C35,16.9921585 35.5793962,15.7287938 36.2941176,15.1792541 Z"
                id="Rectangle-3"
              ></path>
              <path
                d="M1.72413793,19.8086914 L23.2758621,9.17344667 C24.2280772,8.70355199 25,9.22125641 25,10.3297718 L25,11.3333442 C25,12.4418597 24.2280772,13.7214139 23.2758621,14.1913086 L1.72413793,24.8265533 C0.771922845,25.296448 1.16612716e-16,24.7787436 0,23.6702282 L0,22.6666558 C-1.16612716e-16,21.5581403 0.771922845,20.2785861 1.72413793,19.8086914 Z"
                id="Rectangle-3"
              ></path>
              <path
                d="M23.8235294,9.04777549 L36.1764706,15.3509726 C36.6312933,15.5830499 37,16.595206 37,17.6116865 L37,18.5319369 C37,19.5484174 36.6312933,20.1843018 36.1764706,19.9522245 L23.8235294,13.6490274 C23.3687067,13.4169501 23,12.404794 23,11.3883135 L23,10.4680631 C23,9.45158261 23.3687067,8.81569818 23.8235294,9.04777549 Z"
                id="Rectangle-3"
              ></path>
            </g>
            <g
              id="dummy-text-copy"
              transform="translate(93.000000, 171.000000)"
              fill="#E6EAED"
              opacity="0.800000012"
            >
              <rect id="Rectangle-3" x="0" y="0" width="72" height="3" rx="1.5"></rect>
              <rect id="Rectangle-3" x="0" y="7" width="34" height="3" rx="1.5"></rect>
              <rect id="Rectangle-3" x="37" y="7" width="20" height="3" rx="1.5"></rect>
              <rect id="Rectangle-3" x="0" y="22" width="17" height="3" rx="1.5"></rect>
              <rect id="Rectangle-3" x="20" y="22" width="20" height="3" rx="1.5"></rect>
              <rect id="Rectangle-3" x="43" y="22" width="31" height="3" rx="1.5"></rect>
              <rect id="Rectangle-3" x="0" y="15" width="62" height="3" rx="1.5"></rect>
            </g>
            <g
              id="dummy-text-copy-2"
              transform="translate(227.000000, 171.000000)"
              fill="#E6EAED"
              opacity="0.800000012"
            >
              <rect id="Rectangle-3" x="0" y="0" width="72" height="3" rx="1.5"></rect>
              <rect id="Rectangle-3" x="0" y="7" width="34" height="3" rx="1.5"></rect>
              <rect id="Rectangle-3" x="37" y="7" width="20" height="3" rx="1.5"></rect>
              <rect id="Rectangle-3" x="0" y="22" width="17" height="3" rx="1.5"></rect>
              <rect id="Rectangle-3" x="20" y="22" width="20" height="3" rx="1.5"></rect>
              <rect id="Rectangle-3" x="43" y="22" width="31" height="3" rx="1.5"></rect>
              <rect id="Rectangle-3" x="0" y="15" width="62" height="3" rx="1.5"></rect>
            </g>
            <circle
              id="Oval"
              stroke="#E6EAED"
              stroke-width="2"
              fill-rule="nonzero"
              cx="263.5"
              cy="148.5"
              r="13.5"
            ></circle>
            <rect
              id="Header"
              fill="#E6EAED"
              opacity="0.800000012"
              x="65"
              y="27"
              width="268"
              height="10"
              rx="2"
            ></rect>
            <polyline
              id="Path-2"
              stroke="#E6EAED"
              stroke-width="2"
              stroke-linecap="round"
              fill-rule="nonzero"
              points="259 148.571429 262.965623 152 268.351283 146"
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
    <svg
      v-if="screen === 't3'"
      width="478px"
      height="332px"
      viewBox="0 0 478 332"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <filter
          x="-38.5%"
          y="-48.3%"
          width="176.8%"
          height="215.2%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="2"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          x="-44.2%"
          y="-58.3%"
          width="188.2%"
          height="235.2%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset dx="0" dy="20" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="25"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0.113819851   0 0 0 0 0.171881521   0 0 0 0 0.314918155  0 0 0 0.112601902 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <path
          d="M399.011558,222.99169 C399.011558,222.99169 385.836182,228.394532 352.90025,228.394532 C352.90025,228.394532 242.599027,229.362881 199.505779,229.362881 C156.412531,229.362881 46.1113077,228.394532 46.1113077,228.394532 C13.1753765,228.394532 0,222.99169 0,222.99169 L399.011558,222.99169 Z"
          id="path-3"
        ></path>
        <filter
          x="-10.7%"
          y="-651.4%"
          width="121.3%"
          height="1434.1%"
          filterUnits="objectBoundingBox"
          id="filter-4"
        >
          <feGaussianBlur stdDeviation="12.5 0" in="SourceGraphic"></feGaussianBlur>
        </filter>
        <filter
          x="-11.4%"
          y="-698.5%"
          width="122.8%"
          height="1528.3%"
          filterUnits="objectBoundingBox"
          id="filter-5"
        >
          <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="2.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0"
            type="matrix"
            in="shadowBlurOuter1"
          ></feColorMatrix>
        </filter>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="95.3611898%" id="linearGradient-6">
          <stop stop-color="#000000" offset="0%"></stop>
          <stop stop-color="#000000" stop-opacity="0" offset="100%"></stop>
        </linearGradient>
        <path
          d="M47.881387,0 L351.130171,0 C357.375583,0 362.621704,5.23832811 362.621704,11.468144 L362.621704,213.434903 C362.621704,219.664719 362.583389,224.903047 356.237519,224.903047 L43.4124575,224.903047 C36.9861479,224.903047 36.3898541,219.664719 36.3898541,213.434903 L36.3898541,11.468144 C36.3898541,5.23832811 41.6359747,0 47.881387,0 L47.881387,0 Z"
          id="path-7"
        ></path>
        <polygon
          id="path-8"
          points="48.5198055 16.565097 350.491753 16.565097 350.491753 204.515235 48.5198055 204.515235"
        ></polygon>
        <linearGradient x1="50%" y1="99.740912%" x2="50%" y2="0%" id="linearGradient-10">
          <stop stop-color="#D0D0D0" offset="0%"></stop>
          <stop stop-color="#E7E7E7" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="100%" y1="59.0212107%" x2="0%" y2="59.0212107%" id="linearGradient-11">
          <stop stop-color="#000000" offset="0%"></stop>
          <stop stop-color="#FFFFFF" stop-opacity="0.5" offset="49.648972%"></stop>
          <stop stop-color="#000000" offset="100%"></stop>
        </linearGradient>
        <polygon
          id="path-12"
          points="0.638418493 221.080332 399.649977 221.080332 399.649977 222.354571 0.638418493 222.354571"
        ></polygon>
        <filter
          x="-0.1%"
          y="-35.0%"
          width="100.3%"
          height="159.2%"
          filterUnits="objectBoundingBox"
          id="filter-13"
        >
          <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetInner1"></feOffset>
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2="-1"
            k3="1"
            result="shadowInnerInner1"
          ></feComposite>
          <feColorMatrix
            values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.300000012 0"
            type="matrix"
            in="shadowInnerInner1"
          ></feColorMatrix>
        </filter>
        <linearGradient x1="50%" y1="2.0567602%" x2="50%" y2="100%" id="linearGradient-14">
          <stop stop-color="#000000" offset="0%"></stop>
          <stop stop-color="#999999" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="50%" x2="50%" y2="0%" id="linearGradient-15">
          <stop stop-color="#000000" stop-opacity="0" offset="0%"></stop>
          <stop stop-color="#000000" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="50%" y1="20.7927981%" x2="50%" y2="100%" id="linearGradient-16">
          <stop stop-color="#FFFFFF" stop-opacity="-0.0636196476" offset="0%"></stop>
          <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%"></stop>
          <stop stop-color="#FFFFFF" offset="100%"></stop>
        </linearGradient>
        <path
          d="M199.824988,224.265928 C185.321112,224.265928 165.310488,223.911973 161.519879,223.204063 L161.519879,221.080332 L238.130098,221.080332 L238.130098,223.204063 C234.339488,223.911973 214.328865,224.265928 199.824988,224.265928 Z"
          id="path-17"
        ></path>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="images_1" transform="translate(-983.000000, -1142.000000)">
          <g
            id="laptop_main-copy-2"
            filter="url(#filter-1)"
            transform="translate(1022.000000, 1172.000000)"
          >
            <g id="Group-2" filter="url(#filter-2)" transform="translate(0.000000, -0.000000)">
              <g id="shadow" opacity="0.1" filter="url(#filter-4)" fill="black" fill-opacity="1">
                <use filter="url(#filter-5)" xlink:href="#path-3"></use>
              </g>
              <g id="top">
                <use fill="#FFFEFF" fill-rule="evenodd" xlink:href="#path-7"></use>
                <use
                  fill-opacity="0.1"
                  fill="url(#linearGradient-6)"
                  fill-rule="evenodd"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-7"
                ></use>
                <path
                  stroke-opacity="0.25"
                  stroke="#989898"
                  stroke-width="3.75"
                  d="M47.881387,1.875 C42.671719,1.875 38.2648541,6.27365513 38.2648541,11.468144 L38.2648541,213.434903 C38.2648541,220.714228 39.3200589,223.028047 43.4124575,223.028047 L356.237519,223.028047 C360.07073,223.028047 360.746704,221.269675 360.746704,213.434903 L360.746704,11.468144 C360.746704,6.27365513 356.339839,1.875 351.130171,1.875 L47.881387,1.875 Z"
                  stroke-linejoin="square"
                ></path>
              </g>
              <mask id="mask-9" fill="white">
                <use xlink:href="#path-8"></use>
              </mask>
              <use id="screen" stroke="#EAEAEA" fill="#F7F8FA" xlink:href="#path-8"></use>
              <path
                d="M399.649977,222.354571 C399.649977,222.354571 386.4746,228.837982 353.683845,228.470914 C353.538669,228.837982 243.237446,230 200.463407,230 C157.050949,230 46.7497262,228.837982 46.60455,228.470914 C13.813795,228.837982 0.638418493,222.354571 0.638418493,222.354571 L399.649977,222.354571 Z"
                id="bottom"
                fill="url(#linearGradient-10)"
              ></path>
              <g id="bottomfront">
                <use fill="#F2F2F2" fill-rule="evenodd" xlink:href="#path-12"></use>
                <use
                  fill-opacity="0.02"
                  fill="url(#linearGradient-11)"
                  fill-rule="evenodd"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-12"
                ></use>
                <use
                  fill="black"
                  fill-opacity="1"
                  filter="url(#filter-13)"
                  xlink:href="#path-12"
                ></use>
              </g>
              <g id="shadows">
                <use fill="#FAFAFA" xlink:href="#path-17"></use>
                <use
                  fill-opacity="0.03"
                  fill="url(#linearGradient-14)"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-17"
                ></use>
                <use
                  fill-opacity="0.04"
                  fill="url(#linearGradient-15)"
                  style="mix-blend-mode: multiply"
                  xlink:href="#path-17"
                ></use>
                <use
                  fill-opacity="0.3"
                  fill="url(#linearGradient-16)"
                  style="mix-blend-mode: soft-light"
                  xlink:href="#path-17"
                ></use>
              </g>
            </g>
            <g id="slider_dots" transform="translate(177.000000, 188.000000)">
              <circle
                id="Oval-3"
                fill="#E9ECF0"
                cx="2.3467433"
                cy="3.28544061"
                r="2.3467433"
              ></circle>
              <circle
                id="Oval-3"
                fill="#E9ECF0"
                cx="11.7337165"
                cy="3.28544061"
                r="2.3467433"
              ></circle>
              <circle
                id="Oval-3"
                fill="#E9ECF0"
                cx="32.3850575"
                cy="3.28544061"
                r="2.3467433"
              ></circle>
              <circle
                id="Oval-3"
                fill="#9B9B9B"
                cx="22.059387"
                cy="3.28544061"
                r="3.28544061"
              ></circle>
            </g>
            <rect
              id="Header"
              fill="#E6EAED"
              opacity="0.800000012"
              x="64"
              y="27"
              width="268"
              height="10"
              rx="2"
            ></rect>
          </g>
        </g>
      </g>
    </svg>

    <div
      class="desktop-media__container"
      :class="[{ t1: screen === 't1' }, { t3: screen === 't3' }]"
    >
      <div v-if="imageset == 0" class="media-placeholder">
        <div
          class="media-placeholder__unit"
          :class="[
            { 'media-placeholder__unit--t0': screen === 't0' },
            { 'media-placeholder__unit--t1': screen === 't1' },
            { 'media-placeholder__unit--t0': screen === 't2' },
            { 'media-placeholder__unit--t3': screen === 't3' },
          ]"
          style="
            background-image: url('http://hdimages.org/wp-content/uploads/2017/03/placeholder-image10.jpg');
          "
        ></div>
      </div>

      <swiper ref="mySwiper" :options="swiperOptions" class="swiper-box">
        <swiper-slide
          class="swiper-item"
          v-for="image in imageset"
          :style="'background-image:url(' + image.attributes.src + ')'"
          :key="image.id"
        >
          <div class="swiper-item__control">
            <button
              :disabled="!hasAccess"
              class="bttn-round"
              @click="delFile(image)"
              :title="$t('edit_campaign.image_delete')"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                <path
                  d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                ></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
            </button>

            <input
              type="file"
              :disabled="!hasAccess"
              :id="'update_file_' + image.id"
              @change="updFile($event, image)"
            />
            <label
              :class="!hasAccess ? 'bttn-round disabled' : 'bttn-round'"
              :for="'update_file_' + image.id"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                ></path>
              </svg>
            </label>
            <input
              type="file"
              :disabled="!hasAccess"
              :id="'load_file_' + image.id"
              @change="uplFile($event)"
            />
            <label
              :class="!hasAccess ? 'bttn-round disabled' : 'bttn-round'"
              :for="'load_file_' + image.id"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                />
              </svg>
            </label>
          </div>
        </swiper-slide>

        <div v-show="imageset.length > 1" class="swiper-pagination" slot="pagination"></div>

        <div v-show="imageset.length > 1" class="bttn-round slide-controls prev" slot="button-prev">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>

        <div v-show="imageset.length > 1" class="bttn-round slide-controls next" slot="button-next">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </swiper>

      <!--@click="goPrev"-->
      <!--<div ref="prev" class="bttn-round slide-controls prev"-->
      <!--v-if="imageset.length > 1" slot="button-prev">-->
      <!--<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>-->
      <!--</div>-->

      <!--<div ref="next" class="bttn-round slide-controls next"-->
      <!--v-if="imageset.length > 1" slot="button-next">-->
      <!--<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/><path d="M0 0h24v24H0z" fill="none"/></svg>-->
      <!--</div>-->
      <!--@click="goNext"-->
    </div>

    <!-- <div class="item-slot item-slot--c-desktop">

      <div class="upload-bar">
        <input type="file" name="" :disabled='!hasAccess' id="countdown_hero" accept=".png, .jpg, .jpeg, .gif"  @change="processFile($event)">
        <label for="countdown_hero" class="upload-bar__label">

          <svg viewBox="0 0 46 39" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="images_1" transform="translate(-499.000000, -381.000000)" fill="#107598">
                <g id="Group-13" transform="translate(400.000000, 335.000000)">
                  <g id="noun_Plus_1894414" transform="translate(99.000000, 46.000000)">
                    <path d="M42.7142857,0 L3.28571429,0 C1.47106439,0 0,1.45502841 0,3.24989691 L0,29.2490722 C0,31.0439407 1.47106439,32.4989691 3.28571429,32.4989691 L17.2828571,32.4989691 C16.6955446,33.4498728 15.8332917,34.2046104 14.807619,34.665567 C13.8,35.2722144 12.8361905,35.8355299 12.6171429,36.832165 C12.5644467,37.5142715 12.8405443,38.180927 13.3619048,38.6304412 C13.5819253,38.8789153 13.9045347,39.0145296 14.2380952,38.9987629 L31.7619048,38.9987629 C32.0507949,38.9946929 32.3263571,38.8778822 32.5285714,38.6737732 C33.0711428,38.2193421 33.3569852,37.5327206 33.2952381,36.832165 C33.1638095,35.7921979 32.2,35.2288825 31.1047619,34.665567 C30.0790892,34.2046104 29.2168364,33.4498728 28.6295238,32.4989691 L42.7142857,32.4989691 C44.5289356,32.4989691 46,31.0439407 46,29.2490722 L46,3.24989691 C46,1.45502841 44.5289356,0 42.7142857,0 Z M43.8095238,25.9991753 L2.19047619,25.9991753 L2.19047619,3.24989691 C2.19047619,2.65160741 2.68083099,2.16659794 3.28571429,2.16659794 L42.7142857,2.16659794 C43.319169,2.16659794 43.8095238,2.65160741 43.8095238,3.24989691 L43.8095238,25.9991753 Z" id="Shape" fill-rule="nonzero"></path>
                    <path d="M22.992074,8 C23.5944518,8 24.0700132,8.47556143 24.0700132,9.07793923 L24.0700132,12.9299868 L27.9220608,12.9299868 C28.5085865,12.9299868 29,13.4055482 29,13.992074 C29,14.5944518 28.5085865,15.0700132 27.9220608,15.0700132 L24.0700132,15.0700132 L24.0700132,18.9220608 C24.0700132,19.5085865 23.5944518,20 22.992074,20 C22.4055482,20 21.9299868,19.5085865 21.9299868,18.9220608 L21.9299868,15.0700132 L18.0779392,15.0700132 C17.4755614,15.0700132 17,14.5944518 17,13.992074 C17,13.4055482 17.4755614,12.9299868 18.0779392,12.9299868 L21.9299868,12.9299868 L21.9299868,9.07793923 C21.9299868,8.47556143 22.4055482,8 22.992074,8 Z" id="Shape"></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>

          <span>{{ $t('edit_campaign.image_for_desktop') }}</span>

        </label>
      </div>

      <span class="img-cimages__size-text">{{ $t('edit_campaign.image_for_desktop_sizes') }}</span>

    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";

import { swiper, swiperSlide } from "vue-awesome-swiper";

export default {
  // screen - 't0' -> countdown laptop
  // screen - 't1' -> standard laptop
  // screen - 't2' -> select laptop
  // screen - 't3' -> smm laptop
  props: ["imageset", "screen", "tag"],

  data() {
    return {
      swiperOptions: {
        slidePerView: 1,
        //width: 302,
        //height: 70,
        effect: "fade",
        allowTouchMove: false,
        //watchOverflow: true,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".next",
          prevEl: ".prev",
          disabledClass: "disabled",
        },
      },
    };
  },

  components: {
    swiper,
    swiperSlide,
  },

  computed: mapState({
    hasAccess: (state) => {
      return state.account.access.CanEditCampaignMedia;
    },
  }),

  watch: {
    imageset: function (n, o) {
      if (n.length !== o.length) {
        setTimeout(() => {
          this.slideTo(n.length);
        }, 500);
      }
    },
  },

  mounted() {
    this.slideTo(0);
  },

  methods: {
    updFile(e, image) {
      if (e.target.files[0]) {
        this.$emit("cUpdFile", e.target.files[0], image);
        e.target.type = "text";
        e.target.type = "file";
      }
    },

    uplFile(e) {
      this.$emit("cUplFile", e, this.tag);
    },

    delFile: function (image) {
      let message = this.$t("edit_campaign.confirm_delete_image");

      let options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.$emit("cDelFile", image);
      });
    },

    slideTo(n) {
      this.$refs.mySwiper.swiper.slideTo(n, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.desktop-media {
  width: 280px;
  height: 210px;
  display: flex;
  flex-shrink: 0;
  //border: 1px solid cyan;
  position: relative;
  @media only screen and (min-width: 470px) {
    width: 400px;
    height: 300px;
  }
  @media only screen and (min-width: 1360px) {
    //width: 800px;
    width: 100%;
    height: 450px;
  }
  > svg {
    display: block;
    width: 360px;
    height: auto;
    transform: translateX(-50%) translateY(-50%) translateZ(0px);
    position: absolute;
    top: 50%;
    left: 50%;
    @media only screen and (min-width: 470px) {
      width: initial;
    }
    @media only screen and (min-width: 1360px) {
      width: 650px;
    }
    @media only screen and (min-width: 1600px) {
      width: 800px;
    }
  }
  &__container {
    width: 100%;
    max-width: 226px;
    height: 173px;
    display: flex;
    background-color: transparent;
    transform: translateX(-50%) translateZ(0px);
    position: absolute;
    top: 36px;
    left: 50%;
    @media only screen and (min-width: 470px) {
      max-width: 302px;
      height: 240px;
      top: 56px;
      //left: 50%;
    }
    @media only screen and (min-width: 1360px) {
      max-width: 410px;
      height: 300px;
      top: 98px;
    }
    @media only screen and (min-width: 1600px) {
      max-width: 505px;
      height: 360px;
      top: 72px;
    }
    &.t1 {
      max-width: 202px;
      @media only screen and (min-width: 470px) {
        max-width: 268px;
      }
      @media only screen and (min-width: 1360px) {
        max-width: 370px;
        top: 103px;
      }
      @media only screen and (min-width: 1600px) {
        max-width: 450px;
        top: 74px;
      }
      ::v-deep .swiper-item {
        width: 140px !important;
        height: 72px;
        @media only screen and (min-width: 470px) {
          width: 180px !important;
          height: 95px;
        }
        @media only screen and (min-width: 1360px) {
          width: 250px !important;
          height: 126px;
        }
        @media only screen and (min-width: 1600px) {
          width: 300px !important;
          height: 156px;
        }
        &__control {
          left: 47%;
          @media only screen and (min-width: 470px) {
            left: 55%;
          }
          @media only screen and (min-width: 1360px) {
            left: 60%;
          }
          @media only screen and (min-width: 1600px) {
            left: 64%;
          }
        }
      }
      .prev {
        left: -12px;
        @media only screen and (min-width: 470px) {
          left: -17px;
        }
      }
      .next {
        right: -12px;
        @media only screen and (min-width: 470px) {
          right: -17px;
        }
      }
    }
    &.t3 {
      max-width: 202px;
      @media only screen and (min-width: 470px) {
        max-width: 268px;
      }
      @media only screen and (min-width: 1360px) {
        max-width: 365px;
      }
      @media only screen and (min-width: 1600px) {
        max-width: 450px;
      }
      ::v-deep .swiper-item {
        width: 202px !important;
        height: 104px;
        @media only screen and (min-width: 470px) {
          width: 268px !important;
          height: 140px;
        }
        @media only screen and (min-width: 1360px) {
          width: 365px !important;
          height: 192px;
        }
        @media only screen and (min-width: 1600px) {
          width: 450px !important;
          height: 235px;
        }
      }
      .prev {
        left: -12px;
        @media only screen and (min-width: 470px) {
          left: -17px;
        }
      }
      .next {
        right: -12px;
        @media only screen and (min-width: 470px) {
          right: -17px;
        }
      }
    }
  }

  ::v-deep .swiper-box {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: inline-flex;
    overflow: hidden;
  }
  ::v-deep .swiper-wrapper {
    display: flex;
  }
  ::v-deep .swiper-item {
    height: 55px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    @media only screen and (min-width: 470px) {
      height: 70px;
    }
    @media only screen and (min-width: 1360px) {
      height: 95px;
    }
    @media only screen and (min-width: 1600px) {
      height: 118px;
    }
    &.swiper-slide-active {
      z-index: 99;
      .swiper-item__control {
        display: inline-flex;
        //opacity: 1;
        z-index: 100;
      }
    }
    &__control {
      //display: inline-flex;
      position: absolute;
      top: 142px;
      display: none;
      //opacity: 0;
      //transition: opacity 0.2s ease-in-out;
      @media only screen and (min-width: 470px) {
        top: 200px;
      }
      @media only screen and (min-width: 1360px) {
        top: 265px;
      }
      @media only screen and (min-width: 1600px) {
        top: 325px;
      }
      .bttn-round {
        box-shadow: none;
        flex-shrink: 0;
        margin-bottom: 0;
      }
      .bttn-round:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}

::v-deep .swiper-pagination {
  transform: translate3d(-50%, 0, 0);
  position: absolute;
  top: 115px;
  left: 50%;
  z-index: 120;
  @media only screen and (min-width: 470px) {
    top: 160px;
  }
  @media only screen and (min-width: 1360px) {
    top: 225px;
  }
  @media only screen and (min-width: 1600px) {
    top: 270px;
  }
}
.slide-controls {
  background-color: transparent;
  border: 1px solid #107598;
  position: absolute;
  top: 142px;
  z-index: 1001;
  @media only screen and (min-width: 470px) {
    top: 200px;
  }
  @media only screen and (min-width: 1360px) {
    top: 265px;
  }
  @media only screen and (min-width: 1600px) {
    top: 325px;
  }
  > svg {
    fill: #107598;
  }
  &:hover {
    border-color: lighten(#107598, 5%);
    background-color: transparent;
    svg {
      fill: lighten(#107598, 5%);
    }
  }
  &.prev {
    left: 0;
  }
  &.next {
    right: 0;
  }
  &.disabled {
    border-color: #bbbcc4;
    svg {
      fill: #bbbcc4;
    }
  }
}

.media-placeholder {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &__unit {
    width: 100%;
    background-position: center center;
    background-size: cover;
    opacity: 0.5;
    &--t0 {
      height: 55px;
      @media only screen and(min-width: 470px) {
        height: 70px;
      }
      @media only screen and(min-width: 1360px) {
        height: 95px;
      }
      @media only screen and(min-width: 1600px) {
        height: 118px;
      }
    }
    &--t1 {
      width: 140px;
      height: 72px;
      @media only screen and(min-width: 470px) {
        width: 185px;
        height: 95px;
      }
      @media only screen and(min-width: 1360px) {
        width: 250px;
        height: 125px;
      }
      @media only screen and(min-width: 1600px) {
        width: 300px;
        height: 155px;
      }
    }
    &--t3 {
      height: 104px;
      @media only screen and(min-width: 470px) {
        height: 140px;
      }
      @media only screen and(min-width: 1360px) {
        width: 365px;
        height: 192px;
      }
      @media only screen and(min-width: 1600px) {
        width: 450px;
        height: 235px;
      }
    }
  }
}

.bttn-round {
  svg {
    overflow: visible;
  }
}

input[type="file"] {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  //visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
