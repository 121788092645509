<template>
  <div class="cnd-footer-wrapper">
    <footer class="cnd-footer">
      <div class="cnd-footer-nav">
        <div
          :class="{
            'd-flex align-items-center justify-content-between': isMobile(),
            'mx-4': !isMobile(),
          }"
        >
          <a class="cnd-nav-block__link" :href="$options.appHost" target="_blank">
            <div v-if="isMobile()" class="px-4">
              <CdnLogoByLang />
            </div>
            <CdnLogoByLang v-else />
          </a>
          <template v-if="isMobile()">
            <div class="cnd-nav-block py-0 px-4">
              <h4 class="cnd-nav-block__title">
                {{ $t("home_page_v2.nav_products", "Products") }}
              </h4>
              <a
                v-for="(item, index) in navsData"
                :key="index"
                :href="item.to"
                target="_blank"
                class="cnd-nav-block__link"
              >
                {{ item.label }}
              </a>
            </div>
          </template>
        </div>
        <div class="cnd-nav-block cnd-nav-block--communities">
          <h4 class="cnd-nav-block__title">
            {{ $t("home_page_v2.nav_communities", "Communities") }}
          </h4>
          <div class="cnd-nav-block--communities-links">
            <a
              v-for="(dd, idx) in dropNav"
              :key="idx"
              class="cnd-nav-block__link"
              :href="dd.to"
              target="_blank"
            >
              {{ dd.label }}
            </a>
          </div>
        </div>
        <template v-if="!isMobile()">
          <div class="cnd-nav-block">
            <h4 class="cnd-nav-block__title">
              {{ $t("home_page_v2.nav_products", "Products") }}
            </h4>
            <a
              v-for="(item, index) in navsData"
              :key="index"
              :href="item.to"
              target="_blank"
              class="cnd-nav-block__link"
            >
              {{ item.label }}
            </a>
          </div>
        </template>
      </div>
      <div class="cnd-footer-social">
        <div class="cnd-footer-social__subscribe">
          <CdnSubscribe variant="dark" />
        </div>
        <div class="cnd-footer-social__links">
          <CdnSocialBar variant="inline" />
          <div class="cnd-nav-block">
            <a :href="`${$options.appHost}/terms`" target="_blank" class="cnd-nav-block__link">
              {{ $t("home_page_v2.nav_terms", "Terms & Conditions") }}
            </a>
            <a
              :href="`${$options.appHost}/privacypolicy`"
              target="_blank"
              class="cnd-nav-block__link"
            >
              {{ $t("home_page_v2.nav_privacy", "Privacy Policy") }}
            </a>
            <a href="https://articles.charidy.com" class="cnd-nav-block__link" target="_blank">
              {{ $t("home_page_v2.nav_help_center", "Help Center") }}
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import CdnLogoByLang from "./cdn-logo-by-lang.vue";
import CdnSubscribe from "./cdn-subscribe.vue";
import { cdnNavMixin } from "./cdn-nav-mixin";
import CdnSocialBar from "./cdn-social-bar.vue";
import MobileDetect from "mobile-detect";
import { appHost } from "@/constants/urls.js";

export default {
  appHost,
  mixins: [cdnNavMixin],
  components: {
    CdnLogoByLang,
    CdnSubscribe,
    CdnSocialBar,
  },
  methods: {
    isMobile() {
      const md = new MobileDetect(window.navigator.userAgent);
      return md.mobile();
    },
  },
};
</script>

<style lang="scss" scoped>
.cnd-footer-wrapper {
  background-color: #313131;
  width: 100%;
  padding-bottom: var(--app-footer-padding);
}

.cnd-footer {
  font-family: var(--font-secondary);
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-top: clamp(36px, 3.88vw, 56px);
  padding-right: clamp(35px, 4.86vw, 70px);
  padding-bottom: 16px;
  padding-left: clamp(35px, 4.86vw, 70px);
  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (max-width: 967px) {
    display: block;
  }

  @media only screen and (min-width: 968px) {
    grid-template-columns: 1.5fr 0.5fr;
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1.25fr 0.85fr;
  }
}

.cnd-footer-nav {
  display: flex;
  flex-direction: column;
  grid-row-gap: 30px;
  margin-bottom: 30px;

  @media only screen and (min-width: 968px) {
    display: grid;
    grid-row-gap: initial;
    grid-template-columns: repeat(4, 1fr);
  }

  svg {
    display: block;
    width: 95px;
    height: auto;
  }
}

.cnd-nav-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fafafa;
  padding-top: 5px;
  padding-bottom: 10px;

  [dir="ltr"] & {
    padding-left: 18px;
  }

  [dir="rtl"] & {
    padding-right: 18px;
  }

  &--communities {
    order: 2;
    @media only screen and (min-width: 968px) {
      order: initial;
    }
  }

  &--communities-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, auto);
  }

  @media only screen and (min-width: 968px) {
    [dir="ltr"] & {
      border-left: 1px solid #a3a3a3;
      padding-left: 38px;
    }
    [dir="rtl"] & {
      border-right: 1px solid #a3a3a3;
      padding-right: 38px;
    }
  }

  &--grid {
    display: grid;
    grid-template-columns: repeat(2, 155px);
    grid-template-rows: repeat(8, auto);
  }

  &:nth-of-type(1) {
    @media only screen and (max-width: 967px) {
      position: relative;

      &:after {
        content: "";
        display: block;
        background-color: #707070;
        height: 1px;
        width: 200%;
        position: absolute;
        right: 0;
        bottom: -14px;
      }
    }
  }

  &__title {
    font-weight: 700;
    font-size: 13px;
  }

  &__link {
    font-size: 13px;
    font-weight: 400;
    color: #fafafa;
    line-height: 2;
    transition: all 0.2s ease-in-out;
    text-transform: capitalize;
    width: 155px;

    @media only screen and (max-width: 967px) {
      width: auto;
    }

    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
  }
}

.cnd-footer-social {
  &__subscribe {
    margin-bottom: 62px;
  }

  &__links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 113px;
    @media only screen and (min-width: 320px) and (max-width: 479px) {
      gap: 22px;
    }

    [dir="ltr"] & {
      align-items: center;
    }

    [dir="rtl"] & {
      direction: ltr;
    }

    .cnd-nav-block {
      border: none;
      padding: 0px;
    }
  }
}
</style>
