export function getCookie(name) {
  var matches = document.cookie.match(
    new RegExp("(?:^|; )" + name.replace(/([.$?*|{}()[]\\\/\+^])/g, "\\$1") + "=([^;]*)")
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function getCookieJSON(name) {
  const cookie = getCookie(name);
  try {
    return JSON.parse(cookie);
  } catch {
    return {};
  }
}

export function setCookie(name, values, { reset = false, filterFn = () => {} } = {}) {
  if (reset) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
  document.cookie = values.filter(filterFn).join("; ");
}
