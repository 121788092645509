<template>
  <CampaignProviders>
    <CategoryTitle
      :title="$t('campaigns.campaigns')"
      withActions
      list
      :actionsClass="['w-100', 'align-items-center', 'gap-4']"
    >
      <div class="w-100">
        <b-input-group>
          <template #append>
            <b-input-group-text>
              <b-icon icon="search" />
            </b-input-group-text>
          </template>
          <b-form-input
            :value="searchQuery"
            @input="onDebounceInput"
            :placeholder="$t('campaigns.campaigns_search_placeholder', 'Search campaigns')"
          />
        </b-input-group>
      </div>
      <ViewByOrgSetting org-setting="campaignWizardVersion">
        <template #v1>
          <CampaignAddV1 />
        </template>
        <template #v2="{ meta }">
          <CampaignAdd :crowdfundingFundraisingOptions="meta" />
        </template>
      </ViewByOrgSetting>
    </CategoryTitle>
    <slot :searchQuery="searchQuery" />
  </CampaignProviders>
</template>

<script>
import CategoryTitle from "@/common-components/ui-elements/category-title.vue";
import ViewByOrgSetting from "@/views/campaigns/campaign-wizards/viewByOrgSetting.vue";
import CampaignAdd from "@/views/campaigns/campaign-wizards/v2/campaignAdd.vue";
import CampaignAddV1 from "@/views/campaigns/campaign-wizards/v1/campaign-add.vue";
import CampaignProviders from "@/views/campaigns/campaignProviders.vue";
import debounce from "lodash/debounce";

export default {
  components: {
    ViewByOrgSetting,
    CampaignAdd,
    CampaignProviders,
    CategoryTitle,
    CampaignAddV1,
  },
  data() {
    return {
      searchQuery: "",
    };
  },
  methods: {
    onDebounceInput: debounce(function onDebounceInput(e) {
      this.searchQuery = e;
    }, 1000),
  },
};
</script>
