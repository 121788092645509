<template>
  <div>
    <div v-if="!loadTranslation">
      <div class="dashboard-content-wrapper">
        <div class="category-title">
          <h1 class="h1-like">{{ $t("dashboard.title_donation") }}</h1>
          <modal-export
            :filters="{ searchQ, filterStatus, gatewayFilter, campaignId }"
          ></modal-export>
        </div>

        <div class="dashboard-donations">
          <div class="dashboard-donations__filters" v-if="canSeeInputSearch">
            <div class="search filters">
              <svg viewBox="0 0 17 17">
                <g fill="#107598" fill-rule="nonzero">
                  <path
                    d="M11.22 3.29c.2.2.38.41.55.64l-.12-.16c.33.44.61.93.83 1.44l-.08-.19a6.76 6.76 0 0 1 .42 1.48 6.92 6.92 0 0 1 .03 1.52 6.76 6.76 0 0 1-.45 1.69l.08-.2c-.22.52-.5 1-.83 1.45l.12-.16c-.34.45-.74.85-1.18 1.2l.16-.12c-.43.34-.91.62-1.42.84l.2-.08a6.41 6.41 0 0 1-1.45.43 6.52 6.52 0 0 1-1.5.03 6.41 6.41 0 0 1-1.65-.46l.19.08c-.5-.22-.98-.5-1.42-.84l.16.12a6.67 6.67 0 0 1-1.18-1.2l.12.16a6.78 6.78 0 0 1-.83-1.45l.08.2a6.76 6.76 0 0 1-.42-1.48 6.92 6.92 0 0 1-.03-1.52c.08-.58.23-1.15.45-1.69l-.08.2c.22-.52.5-1 .83-1.45l-.12.16c.34-.45.74-.86 1.18-1.2l-.16.12c.44-.34.91-.62 1.42-.84l-.2.08a6.41 6.41 0 0 1 1.45-.43 6.52 6.52 0 0 1 1.5-.03c.57.08 1.12.23 1.65.46L9.33 2a6.43 6.43 0 0 1 1.89 1.28c.3.3.83.32 1.11 0a.83.83 0 0 0 0-1.14A7.13 7.13 0 0 0 .92 3.77a7.46 7.46 0 0 0 .85 8.43 7.16 7.16 0 0 0 9.02 1.58 7.53 7.53 0 0 0 2.48-2.38 7.46 7.46 0 0 0-.94-9.25c-.29-.3-.82-.32-1.11 0a.83.83 0 0 0 0 1.14z"
                  />
                  <path
                    d="M11.28 12.42l1.47 1.47 2.34 2.34.54.54c.3.3.85.32 1.14 0a.82.82 0 0 0 0-1.14l-1.47-1.47-2.34-2.34-.54-.54c-.3-.3-.85-.32-1.14 0a.82.82 0 0 0 0 1.14z"
                  />
                </g>
              </svg>
              <input
                class="search__input"
                type="text"
                :placeholder="$t('dashboard.select_filter_placeholder_input')"
                v-model="searchQ"
                value=""
              />
            </div>

            <div class="select filters">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>
              <select class="select__select" v-model="gatewayFilter">
                <option value="">{{ $t("dashboard.select_filter_please_select_gateway") }}</option>
                <option
                  v-for="(gateway, i) in gateways"
                  :value="gateway.attributes.gateway"
                  :key="i"
                >
                  {{ $t("dashboard.select_filter_" + gateway.attributes.gateway) }}
                </option>
              </select>
            </div>

            <div class="select filters">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>
              <select class="select__select" v-model="campaignId">
                <option value="0">
                  {{ $t("dashboard.select_filter_please_select_campaign") }}
                </option>
                <option v-for="(campaign, i) in allCampaigns" :value="campaign.id" :key="i">
                  [{{ campaign.id }}] {{ campaign.attributes.title }}
                </option>
              </select>
            </div>

            <div class="select filters">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>
              <select class="select__select" v-model="filterStatus">
                <option value="">{{ $t("dashboard.select_filter_please_select_status") }}</option>
                <option value="authorized">{{ $t("dashboard.select_filter_authorized") }}</option>
                <option value="canceled">{{ $t("dashboard.select_filter_canceled") }}</option>
                <option value="processed">{{ $t("dashboard.select_filter_processed") }}</option>
                <option value="pending">{{ $t("dashboard.select_filter_pending") }}</option>
              </select>
            </div>
          </div>

          <div class="dashboard-donations__th">
            <div class="th-item">#</div>
            <div class="th-item clickable th-item--name" @click="setSort('billing_name')">
              {{ $t("dashboard.donation_name") }} <i :class="classSortBy('billing_name')"></i>
            </div>
            <div class="th-item clickable th-item--name" @click="setSort('display_name')">
              {{ $t("dashboard.donation_display_name") }}
              <i :class="classSortBy('display_name')"></i>
            </div>
            <div class="th-item clickable" @click="setSort('bank_name')">
              {{ $t("dashboard.donation_methods") }} <i :class="classSortBy('bank_name')"></i>
            </div>
            <div class="th-item clickable" @click="setSort('charged_amount')">
              {{ $t("dashboard.donation_amount_charged") }}
              <i :class="classSortBy('charged_amount')"></i>
            </div>
            <div class="th-item clickable" @click="setSort('effective_amount')">
              {{ $t("dashboard.donation_amount_effective") }}
              <i :class="classSortBy('effective_amount')"></i>
            </div>
            <div class="th-item clickable th-item--15" @click="setSort('email')">
              {{ $t("dashboard.donation_email_phone") }} <i :class="classSortBy('email')"></i>
            </div>
            <div class="th-item clickable" @click="setSort('date')">
              {{ $t("dashboard.donation_date") }} <i :class="classSortBy('date')"></i>
            </div>
            <div class="th-item">{{ $t("dashboard.donation_status") }}</div>
          </div>
        </div>

        <div v-if="!loadingDonations">
          <template v-if="donations.length > 0">
            <donation-row
              @edit-receipt="editReceipt"
              @preview-donate="preview"
              v-for="(donation, i) in donations"
              :donation="donation"
              :key="i"
            ></donation-row>
          </template>

          <div v-if="donations.length == 0" class="empty-result-message">
            {{ $t("dashboard.donations_empty") }}
          </div>
        </div>

        <div v-if="loadingDonations">
          <div class="loadings">
            <LdsSpinner />
          </div>
        </div>
      </div>

      <b-pagination
        size="md"
        v-if="donorsCount > 50"
        :total-rows="donorsCount"
        v-model="currentPage"
        :per-page="50"
      >
      </b-pagination>

      <modal-receipt
        :campaignid="campaignId"
        :currentpage="currentPage"
        ref="editReceiptModal"
      ></modal-receipt>
      <preview-donate ref="previewDonate" name="donation"></preview-donate>
    </div>

    <div v-else class="loading">
      <LdsSpinner />
    </div>
  </div>
</template>

<script>
import donationRow from "./donation-row.vue";
import donationExport from "./donation-export-modal.vue";
import donationReceiptModal from "../dashboard/subcomponents/donationReceiptModal.vue";
import previewDonate from "@/components/view-modal-donation.vue";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";
import { mapState } from "vuex";

import debounce from "lodash/debounce";

export default {
  data: function () {
    return {
      currentPage: 1,

      campaignId: 0,

      searchQ: "",

      sortBy: "",

      filterStatus: "",

      gatewayFilter: "",

      setIntervalData: null,
    };
  },

  created: function () {
    let setting = `?extend=donation_receipt_id&page=1&limit=50`;

    this.$store.dispatch("getCampaigns");

    this.$store.dispatch("getCountries");

    this.$store.dispatch("getGatewaysByOrg");

    this.$store.dispatch("getOrganizationDonotion", setting);

    this.setIntervalData = setInterval(() => {
      if (navigator.onLine && !this.bluredTab && this.currentPage == 1) {
        this.updatePageDonations();
      }
    }, 30000);
  },

  watch: {
    currentPage: function () {
      this.getNewPageDonation();
    },

    bluredTab: function () {
      if (!this.bluredTab && this.currentPage == 1) {
        this.updatePageDonations();
      }
    },

    sortBy: debounce(function () {
      if (this.currentPage != 1) {
        this.currentPage = 1;
      } else {
        this.getNewPageDonation();
      }
    }, 500),

    searchQ: function () {
      this.currentPage = 1;

      this.getNewPageDonation();
    },

    filterStatus: function () {
      this.getNewPageDonation();
    },

    gatewayFilter: function () {
      this.getNewPageDonation();
    },

    campaignId: function () {
      this.getNewPageDonation();
    },
  },

  directives: {
    debounce,
  },

  computed: mapState({
    canSeeInputSearch: (state) => {
      return state.account.access.CanPerformDonationSearch;
    },

    bluredTab: (state) => {
      return state.account.blured;
    },

    gateways: function (state) {
      let gateways = state.donation.gateways;

      let filtredGateways = this.unique(gateways);

      return filtredGateways;
    },

    donations: (state) => {
      return state.donation.donations;
    },

    loadingDonations: (state) => {
      return state.donation.loadingDonation;
    },

    donorsCount: (state) => {
      return parseInt(state.donation.donationTotal);
    },

    allCampaigns: (state) => {
      return state.campaigns.campaigns;
    },

    loadTranslation: (state) => {
      return state.translation.loadTranslation;
    },
  }),

  methods: {
    preview: function (donate) {
      this.$refs.previewDonate.openModal(donate.attributes);
    },

    setSort: function (sortBy) {
      if (this.sortBy != sortBy && this.sortBy != `-${sortBy}`) {
        this.sortBy = sortBy;
      } else if (this.sortBy == sortBy) {
        this.sortBy = `-${sortBy}`;
      } else {
        this.sortBy = "";
      }
    },

    classSortBy: function (sortBy) {
      if (this.sortBy == sortBy) {
        return "fa fa-angle-up";
      } else if (this.sortBy == "-" + sortBy) {
        return "fa fa-angle-down";
      }
    },

    unique: function (arr) {
      var result = [];

      nextInput: for (var i = 0; i < arr.length; i++) {
        var str = arr[i]; // для каждого элемента
        for (var j = 0; j < result.length; j++) {
          // ищем, был ли он уже?
          if (result[j].attributes.gateway == str.attributes.gateway) continue nextInput; // если да, то следующий
        }
        result.push(str);
      }

      return result;
    },

    getNewPageDonation: function () {
      let setting = `?extend=donation_receipt_id&page=${this.currentPage}&limit=50${
        this.campaignId ? "&campaign_id=" + this.campaignId : ""
      }${this.gatewayFilter.length > 0 ? "&gateway=" + this.gatewayFilter : ""}${
        this.filterStatus.length > 0 ? "&status=" + this.filterStatus : ""
      }${this.searchQ.length > 0 ? "&q=" + this.searchQ : ""}${
        this.sortBy.length > 0 ? "&sort=" + this.sortBy : ""
      }`;

      this.$store.dispatch("updateOrganizationDonotion", setting);
    },

    updatePageDonations: function () {
      let setting = `?extend=donation_receipt_id&page=${this.currentPage}&limit=50${
        this.campaignId ? "&campaign_id=" + this.campaignId : ""
      }${this.gatewayFilter.length > 0 ? "&gateway=" + this.gatewayFilter : ""}${
        this.filterStatus.length > 0 ? "&status=" + this.filterStatus : ""
      }${this.searchQ.length > 0 ? "&q=" + this.searchQ : ""}${
        this.sortBy.length > 0 ? "&sort=" + this.sortBy : ""
      }`;

      this.$store.dispatch("updateOrganizationDonotion", setting);
    },

    editReceipt(donate) {
      this.$refs.editReceiptModal.openEditModal(donate);
    },
  },

  components: {
    "donation-row": donationRow,
    "modal-export": donationExport,
    "modal-receipt": donationReceiptModal,
    "preview-donate": previewDonate,
    LdsSpinner,
  },

  destroyed: function () {
    clearInterval(this.setIntervalData);
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_vars.scss";

.loadings {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-donations {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: $shadow-th;
  position: relative;
  z-index: 1;
  @media only screen and (min-width: 468px) {
    padding: 20px 40px;
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: -5px;
    margin-left: -5px;
    @media only screen and (min-width: 1360px) {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $bg-100;
    }
    @media only screen and (min-width: 1460px) {
      flex-wrap: nowrap;
    }
    .filters {
      margin: 5px;
      @media only screen and (min-width: 660px) {
        width: 48%;
      }
      @media only screen and (min-width: 1024px) {
        width: 48%;
      }
      @media only screen and (min-width: 1180px) {
        width: 24%;
      }
      @media only screen and (min-width: 1360px) {
        width: 48%;
      }
      @media only screen and (min-width: 1460px) {
        width: 24%;
      }
    }
  }

  &__th {
    display: none;
    @media only screen and (min-width: 1360px) {
      display: flex;
    }
    .th-item {
      display: flex;
      align-items: center;
      width: 10%;
      min-width: 10%;
      padding: 0 5px;
      color: $fd;
      font-size: 14px;
      &--15 {
        width: 15%;
        min-width: 15%;
      }
      &--20 {
        width: 20%;
        min-width: 20%;
      }
    }
  }
}

.clickable {
  cursor: pointer;
  i {
    padding-left: 3px;
  }
}
</style>
