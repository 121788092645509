<template>
  <div>
    <b-modal ref="modal" modal-class="app-modal" size="md" centered>
      <div slot="modal-header">
        <div class="modal-header__close" @click.prevent="hide">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
        <h2 class="h1-like">
          {{ $t("dashboard.update_payment_details", "Update payment details") }}
        </h2>
      </div>

      <div v-if="!success">
        <b-overlay :show="loading" rounded="sm">
          <b-form-group
            label="Enter your card"
            class="m-0"
            :invalid-feedback="cardFieldError"
            :state="!cardFieldError"
          >
            <div class="form-control d-flex flex-column justify-content-center">
              <div ref="card"></div>
            </div>
          </b-form-group>
        </b-overlay>
      </div>

      <div v-if="success" class="text-center">
        <b-iconstack font-scale="5" variant="success">
          <b-icon stacked icon="circle"></b-icon>
          <b-icon stacked icon="check"></b-icon>
        </b-iconstack>
      </div>

      <div slot="modal-footer" :class="{ 'justify-content-center': success }">
        <button form="editReceipt" class="bttn bttn--blue" @click.prevent="hide">
          {{ success ? $t("dashboard.close", "Close") : $t("dashboard.cancel", "Cancel") }}
        </button>

        <b-button
          v-if="!success"
          class="bttn bttn--sm bttn--orange text-nowrap"
          :disabled="loading || !!cardFieldError || !cardFeildComplete"
          @click="save"
        >
          {{ $t("donor_dashboard.save", "Save") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls.js";
import { notificationsMixin } from "@/mixins";

const elementStyles = {
  base: {
    color: "#555",
    ":-webkit-autofill": {
      color: "#e39f48",
    },
  },
  invalid: {
    color: "#E25950",
    "::placeholder": {
      color: "#FFCCA5",
    },
  },
};

export default {
  mixins: [notificationsMixin],

  data() {
    return {
      loading: false,
      success: false,

      donation: null,

      stripe: null,
      cardFieldError: "",
      cardFeildComplete: false,
    };
  },

  methods: {
    show(donation) {
      this.getDetails(donation.id);

      this.donation = donation;

      this.$refs.modal.show();
    },

    hide() {
      this.$refs.modal.hide();
    },

    stripeInit({ stripe_public_key, stripe_account_id }) {
      this.stripe = Stripe(stripe_public_key, { stripeAccount: stripe_account_id });
    },

    cardFieldMount() {
      const elements = this.stripe.elements();
      this.card = elements.create("card", { style: elementStyles, hidePostalCode: true });
      this.card.mount(this.$refs.card);

      this.card.addEventListener("change", (event) => {
        this.cardFieldError = event?.error?.message || "";
        this.cardFeildComplete = event.complete;
      });
    },

    getDetails(id) {
      this.loading = true;

      axios
        .get(urls.subscriptionsPaymentDetails.replace(":did", id))
        .then(({ data }) => {
          this.stripeInit(data.data);
          this.cardFieldMount();
        })
        .catch(this.$_notificationsMixin_handleCatch)
        .finally(() => {
          this.loading = false;
        });
    },

    save() {
      this.loading = true;
      this.createPaymentMethod().then((pm) => {
        this.updDetails(pm.id);
      });
    },

    createPaymentMethod() {
      const { billing_last_name, billing_name, email, phone } = this.donation;

      return this.stripe
        .createPaymentMethod({
          type: "card",
          card: this.card,
          billing_details: {
            name: `${billing_name} ${billing_last_name}`,
            email,
            phone,
          },
        })
        .then((result) => {
          if (result.error) {
            console.log(result.error);
            this.$_notificationsMixin_makeErrorToast(
              "Error",
              result.error.message || "Something went wrong"
            );
          }
          return result.paymentMethod;
        });
    },

    updDetails(payment_method_id) {
      this.loading = true;

      const payload = {
        stripe: {
          payment_method_id,
        },
      };

      axios
        .put(urls.subscriptionsPaymentDetails.replace(":did", this.donation.id), payload)
        .then(({ data }) => {
          this.success = data.status === "ok";
        })
        .catch(this.$_notificationsMixin_handleCatch)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.app-modal {
  .modal-header {
    position: relative;

    &__close {
      display: inline-flex;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .modal-content {
    overflow: hidden;
  }
}
</style>
