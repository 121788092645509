<template>
  <div>
    <b-container>
      <b-row>
        <b-col
          cols="4"
          class="col-auto"
        >
          <b-form-input
            v-model="color"
            lazy
            type="color"
          />
        </b-col>
        <b-col
          cols="8"
          class="col-auto"
        >
          <b-form-input
            v-model="color"
            lazy
            type="text"
          />
        </b-col>
      </b-row>
      <p
        v-if="error"
        class="color-error"
      >
        {{ $t("color_tool.color_error", 'Please enter a valid hexcode') }}
      </p>
    </b-container>
  </div>
</template>

<script>

export default {
  props: {
    setColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      color: '',
      error: false,
    };
  },
  created() {
    this.getColor();
  },
  watch: {
    $props: {
      handler() {
        this.getColor();
      },
      deep: true,
      immediate: true,
    },
    color(val) {
      this.$emit('changeColor', this.color);
      if (this.isValidColor(val)) {
        this.error = false;
      } else if (!val) {
        this.error = false;
      } else {
        this.error = true;
      }
    },
  },
  methods: {
    getColor() {
      this.color = this.setColor;
    },
    isValidColor(color) {
      if (color) {
        return color.match(/^#[a-f0-9]{6}$/i) !== null;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
