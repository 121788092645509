<template>
  <div class="wizard">
    <div class="main">
      <div class="first-step" v-if="step === 'first'">
        <p>{{ $t("integration.zapier_generate_key_title") }}</p>
      </div>
      <b-form-group
        id="reports-fieldset-select-campaign"
        :label="$t('integration.filters_campaign_title', 'Pick campaign to generate API key for')"
        label-for="reports-input-select-campaign"
      >
        <multiselect
          id="reports-input-select-campaign"
          v-model="campaign"
          :options="campaigns"
          :custom-label="customLabel"
          :loading="loadingCampaigns"
          :allow-empty="false"
          track-by="id"
          :placeholder="$t('reports.filters_select_campaign', 'Select campaign')"
          :select-label="$t('reports.select_campaign_select_label', 'Press enter to select')"
          :deselect-label="$t('reports.select_campaign_deselect_label', 'Press enter to remove')"
          :selected-label="$t('reports.select_campaign_selected_label', 'Selected')"
        />
      </b-form-group>
      <div class="api-key" v-if="apiKey">
        <div class="api-key-title">
          {{ $t("integration.api_key_title", "API Key:") }}
        </div>

        <div class="api-key-content">
          <div class="text">
            <p>{{ apiKey }}</p>
          </div>
        </div>
        <a
          class="copy-link"
          v-b-popover.hover.top="
            copied === apiKey ? 'copied' : $t('dashboard.utm_link_copied_to_clipboard')
          "
          @click="copy(apiKey)"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.40156 19.4896L16.6016 19.4896C16.8137 19.4896 17.0172 19.4054 17.1672 19.2554C17.3173 19.1055 17.4016 18.9021 17.4016 18.6901L17.4016 4.29948C17.4016 4.08744 17.3173 3.88409 17.1672 3.73416C17.0172 3.58423 16.8137 3.5 16.6016 3.5H7.80156C7.69702 3.50126 7.59374 3.52299 7.49756 3.56396C7.39865 3.60059 7.30866 3.65782 7.23356 3.73185L4.83356 6.13029C4.7616 6.20396 4.70456 6.29083 4.66556 6.38612C4.62526 6.48525 4.60356 6.59094 4.60156 6.69792V18.6901C4.60156 18.9021 4.68585 19.1055 4.83588 19.2554C4.98591 19.4054 5.18939 19.4896 5.40156 19.4896ZM8.60156 5.09896L15.8016 5.09896L15.8016 17.8906L6.20156 17.8906L6.20156 7.4974H7.80156C8.01374 7.4974 8.21722 7.41317 8.36725 7.26324C8.51728 7.1133 8.60156 6.90995 8.60156 6.69792V5.09896ZM18.5859 21.5L9.97426 21.4805C9.97426 21.4805 9.17188 21.4805 9.17188 20.6841C9.17188 19.8878 9.97426 19.8878 9.97426 19.8878H17.804L17.804 8.29687C17.804 8.29687 17.8016 7.49739 18.6016 7.49739C19.4016 7.49739 19.3984 8.29687 19.3984 8.29687L19.3984 20.6738C19.3984 20.8858 19.3258 21.0924 19.1758 21.2423C19.0258 21.3923 18.7981 21.5 18.5859 21.5Z"
              fill="#107598"
            />
          </svg>
          {{ $t("dashboard.utm_copy_url", "copy") }}
        </a>
      </div>
    </div>
    <div class="footer">
      <button
        :class="
          step === 'success' ? 'bttn bttn--lg bttn--blue el-right' : 'bttn bttn--lg bttn--blue'
        "
        @click.prevent="closeModal"
      >
        {{ $t("integration.close", "Close") }}
      </button>
      <button
        v-if="step === 'first'"
        @click.prevent="generateKey"
        class="bttn bttn--lg bttn--orange el-right"
      >
        {{ $t("integration.generate_key", "Generete key") }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls.js";
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      step: "first",
      copied: "",
      campaign: null,
      apiKey: "",
    };
  },

  created() {
    this.$store.dispatch("getCampaigns");
  },

  computed: mapState({
    campaigns: (state) => state.campaigns.campaigns,
    loadingCampaigns: (state) => state.campaigns.loadingCampaigns,
  }),

  watch: {
    loadingCampaigns() {
      this.setCampaign();
    },
  },

  methods: {
    generateKey() {
      if (this.campaign === null) return;
      axios.post(urls.generateZapierKey.replace(":cid", this.campaign.id)).then((response) => {
        let key = response.data.jwt_token;
        console.log(response);
        this.apiKey = key;

        this.step = "success";
      });
    },
    customLabel(cmp) {
      return cmp.id && `[${cmp.id}] ${cmp.attributes.title}`;
    },
    setCampaign() {
      if (this.campaigns.length > 0) {
        if (this.campaigns.length === 1) {
          this.campaign = this.campaigns[0];
        } else {
          this.campaign = this.campaigns.filter((el) => el.attributes.category === "regular")[0];
        }
      } else if (this.allCampaigns.length > 0) {
        if (this.allCampaigns.length === 1) {
          this.campaign = this.allCampaigns[0];
        } else {
          this.campaign = this.allCampaigns.filter((el) => el.attributes.category === "regular")[0];
        }
      }
    },
    copy(text) {
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();

      dummy.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(dummy.value);

      document.body.removeChild(dummy);
      this.copied = text;
    },

    closeModal() {
      this.$store.dispatch("getIntegrationsList");
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: space-between;
}
.el-right {
  margin-left: auto;
}
.account-link {
  font-size: 16px;
  color: #cc6d4c;
}
.copy-link {
  cursor: pointer;
}
.api-key {
  padding: 10px 18px;
  width: 90%;
  -webkit-box-shadow: 0px 3px 15px 2px rgba(176, 176, 176, 0.41);
  box-shadow: 0px 3px 15px 2px rgba(176, 176, 176, 0.41);
  margin: 0 auto;
  border-radius: 5px;
}
.api-key .text {
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}
.api-key-title {
  font-weight: 600;
}
.api-key-content {
  margin: 7px 0;
}
.api-key-content .text {
  padding: 5px 8px;
  background-color: rgb(239, 239, 239);
  border-radius: 5px;
}
.api-key-content .text p {
  margin: 0;
}
</style>
