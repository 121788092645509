<template>
  <div v-if="activetab === 3">
    <div class="payout">
      <div class="info">
        <h1><sup>$</sup>76,000 USD</h1>
        <p>Paid: 1.30.2019</p>
      </div>
      <div class="payout__bank">
        <svg
          width="69"
          height="51"
          viewBox="0 0 69 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M66.15 41H14.85C13.235 41 12 39.737 12 38.0853V2.91469C12 1.26303 13.235 0 14.85 0H66.15C67.765 0 69 1.26303 69 2.91469V38.0853C69 39.737 67.765 41 66.15 41Z"
            fill="#213B7F"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M54.15 51H2.85C1.235 51 0 49.737 0 48.0853V12.9147C0 11.263 1.235 10 2.85 10H54.15C55.765 10 57 11.263 57 12.9147V48.0853C57 49.737 55.765 51 54.15 51Z"
            fill="#FBBE7B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.2143 36H8.78571C7.20714 36 6 34.7 6 33V30C6 28.3 7.20714 27 8.78571 27H16.2143C17.7929 27 19 28.3 19 30V33C19 34.7 17.7929 36 16.2143 36Z"
            fill="white"
          />
          <rect y="18" width="57" height="5" fill="#FC5455" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M49.1354 43H31.8501C31.3778 43 31 42.5556 31 42C31 41.4444 31.3778 41 31.8501 41H49.1354C49.6077 41 49.9855 41.4444 49.9855 42C50.08 42.5556 49.7022 43 49.1354 43Z"
            fill="#FC5455"
          />
        </svg>
        <p>Citibank …. 1079 USD</p>
      </div>
    </div>

    <div class="payout">
      <div class="info">
        <h1><sup>$</sup>76,000 USD</h1>
        <div class="transit">
          <p>In Transit: EST Arrival</p>
          <p>1.30.2019</p>
        </div>
      </div>
      <div class="payout__bank">
        <svg
          width="69"
          height="51"
          viewBox="0 0 69 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M66.15 41H14.85C13.235 41 12 39.737 12 38.0853V2.91469C12 1.26303 13.235 0 14.85 0H66.15C67.765 0 69 1.26303 69 2.91469V38.0853C69 39.737 67.765 41 66.15 41Z"
            fill="#213B7F"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M54.15 51H2.85C1.235 51 0 49.737 0 48.0853V12.9147C0 11.263 1.235 10 2.85 10H54.15C55.765 10 57 11.263 57 12.9147V48.0853C57 49.737 55.765 51 54.15 51Z"
            fill="#FBBE7B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.2143 36H8.78571C7.20714 36 6 34.7 6 33V30C6 28.3 7.20714 27 8.78571 27H16.2143C17.7929 27 19 28.3 19 30V33C19 34.7 17.7929 36 16.2143 36Z"
            fill="white"
          />
          <rect y="18" width="57" height="5" fill="#FC5455" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M49.1354 43H31.8501C31.3778 43 31 42.5556 31 42C31 41.4444 31.3778 41 31.8501 41H49.1354C49.6077 41 49.9855 41.4444 49.9855 42C50.08 42.5556 49.7022 43 49.1354 43Z"
            fill="#FC5455"
          />
        </svg>
        <p>Citibank …. 1079 USD</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: "",
    };
  },

  props: {
    activetab: Number,
  },
};
</script>

<style lang="scss" scoped>
.payout {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(35, 40, 71, 0.0795686);
  border-radius: 4px;
  padding: 26px;
  margin-bottom: 16px;
}

.info {
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 15px;
  h1 {
    font-size: 40px;
    line-height: 51px;
    text-align: right;
    color: #00d39c;
    margin: 0;
    sup {
      margin-right: 42px;
      font-size: 21px;
      line-height: 27px;
      top: -1em;
    }
  }
  p {
    font-size: 16px;
    line-height: 25px;
    color: #414359;
    margin: 0;
  }
}

.payout__bank {
  display: flex;
  align-items: center;
  svg {
    margin-right: 26px;
  }
  p {
    font-size: 22px;
    line-height: 13px;
    color: #107598;
    margin: 0;
  }
}

.transit {
  text-align: right;
}

@media only screen and (max-width: 480px) {
  .info {
    h1 {
      font-size: 19px;
      line-height: 15px;
    }
    sup {
      margin-right: 0px !important;
      font-size: 14px !important;
      line-height: 18px !important;
      top: 0em !important;
    }
    p {
      font-size: 10px;
      line-height: 13px;
    }
  }

  .citibank p {
    font-size: 14px;
    line-height: 13px;
  }
}
</style>
