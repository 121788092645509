import { render, staticRenderFns } from "./sidebar.vue?vue&type=template&id=6ee321b2&scoped=true&lang=html&"
import script from "./sidebar.vue?vue&type=script&lang=js&"
export * from "./sidebar.vue?vue&type=script&lang=js&"
import style0 from "./sidebar.vue?vue&type=style&index=0&id=6ee321b2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ee321b2",
  null
  
)

export default component.exports