<template>
  <div :style="menuOpenStyle">
    <b-card no-body class="border-0">
      <b-tabs
        nav-class="settings-tabs"
        pills
        card
        vertical
        nav-wrapper-class="nav-wrapper-class"
        content-class="content-class"
        class="tabs-class"
      >
        <template #tabs-start>
          <b-nav-item role="presentation" @click.prevent="toggleMenu" href="#">
            <b-icon icon="justify" />
          </b-nav-item>
        </template>
        <b-tab
          @click="closeMenu"
          :title="$t('edit_campaign.brand_slider_setting_name', 'Brand Slider')"
        >
          <b-button
            v-if="!menuOpenState"
            variant="link"
            role="presentation"
            @click.prevent="toggleMenu"
            href="#"
          >
            <b-icon icon="justify" />
          </b-button>
          <BrandSlider />
        </b-tab>
        <b-tab @click="closeMenu" :key="s.name" :title="s.title" v-for="s in settings">
          <b-button
            v-if="!menuOpenState"
            variant="link"
            role="presentation"
            @click.prevent="toggleMenu"
            href="#"
          >
            <b-icon icon="justify" />
          </b-button>
          <b-card-text>
            <setting :title="s.title" :name="s.name" :scheme="s.scheme" :remap="s.remap">
              <template v-if="s.name === 'add_team_from_campaign'" #above-form>
                <copy-field-row
                  :url="addTeamFromCampaignFromLink"
                  :label="$t('edit_campaign.add_team_from_campaign_form_link', 'Form link')"
                />
              </template>
              <template
                v-if="s.name === 'donation_success_page_config'"
                #extra-buttons="{ isActive }"
              >
                <b-btn
                  v-if="isActive"
                  class="bttn bttn--sm bttn--orange mx-2"
                  v-b-modal.preview-modal
                >
                  {{ $t("edit_campaign.setting_preview", "Preview") }}
                </b-btn>
              </template>
            </setting>
          </b-card-text>
        </b-tab>
        <b-tab
          @click="closeMenu"
          :title="$t('edit_campaign.cover_cc_fee_setting_name', 'Cover CC Fee')"
        >
          <b-button
            v-if="!menuOpenState"
            variant="link"
            role="presentation"
            @click.prevent="toggleMenu"
            href="#"
          >
            <b-icon icon="justify" />
          </b-button>
          <CoverCCFee />
        </b-tab>
      </b-tabs>
    </b-card>

    <b-modal id="preview-modal" centered size="xl">
      <iframe :src="donateThankYouPreview" frameborder="0" class="w-100" height="600"></iframe>

      <template #modal-footer="{ hide }">
        <b-btn class="bttn bttn--sm bttn--orange mx-2" @click="hide"> Ok </b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import AddTeamFromCampaignShema from "./settings-schemas/add_team_from_campaign.schema.js";
import AllowTeamUpdateByPublicTokenShema from "./settings-schemas/allow_team_update_by_public_token.schema.js";
import BrandSlider from "./settings-schemas/brand_slider.schema.vue";
import DonationSuccessPageConfig from "./settings-schemas/donation_success_page_config.schema.js";
import UnidyMediaStripe from "./settings-schemas/unidy_media_stripe.schema";
import NotifyOrgAboutDonationsAbove from "./settings-schemas/notify_org_about_donations_above.schema";
import DonorsGoal from "./settings-schemas/donors_goal.schema";
import DonationUpsell from "./settings-schemas/donation_upsell.schema";
import RecurringPayments from "./settings-schemas/recurring_payments.schema";
import CoverCCFee from "./settings-schemas/cover_cc_fee.schema.vue";
import TeamPageHideDonorListSchema from "./settings-schemas/team_page_hide_donor_list.schema";
import MinDonationAmount from "./settings-schemas/min_donation_amount.schema";
import DonationFormCustomFields from "./settings-schemas/donation_form_custom_fields.schema";
import PhoneRequired from "./settings-schemas/phone_required.schema";
import ShowBillingAddress from "./settings-schemas/show_billing_address.schema";
import NotificationsFromEmail from "./settings-schemas/notifications_from_email.schema";
import AddSetCampaignModeSetting from "./settings-schemas/add_set_campaign_mode_setting.schema";
import ActivateConfettiNow from "./settings-schemas/activate_confetti_now.schema";
import CopyFieldRow from "@/common-components/copy-field-row.vue";
import Setting from "./setting.vue";
import { donateHost } from "@/constants/urls";
import CustomTabsUnidyClassic from "./settings-schemas/custom_tabs_unidy_classic.schema";
import MaximumDonationAmount from "./settings-schemas/maximum_donation_amount.schema";

export default {
  components: { Setting, CopyFieldRow, CoverCCFee, BrandSlider },

  data() {
    return {
      menuOpenState: true,
    };
  },

  computed: {
    ...mapState({
      cID: (state) => state.campaign.campaign.data.id,
      addSetCampaignModeSetting: (state) => state.account.addSetCampaignModeSetting,
      addSetCampaignModeSettingMeta: (s) =>
        s.campaign.metas?.add_set_campaign_mode_setting?.value || false,
    }),

    menuOpenStyle() {
      if (this.menuOpenState) {
        return { "--_menu-open": "block" };
      }

      return { "--_menu-open": "none" };
    },

    addTeamFromCampaignFromLink() {
      let env = "";

      if (process.env.NODE_ENV === "development") {
        env = "develop.";
      } else if (location.hostname.includes("develop.")) {
        env = "develop.";
      }

      const url = new URL(`https://view.${env}charidy.com/new_team_page/`);

      url.searchParams.append("campaign_id", this.cID);

      return url.toString();
    },

    donateThankYouPreview() {
      return `${donateHost}/${this.cID}?status=preview`;
    },

    settings() {
      return [
        {
          name: "add_team_from_campaign",
          title: this.$t(
            "edit_campaign.add_team_from_campaign_setting_name",
            `"New team page" settings`
          ),
          scheme: AddTeamFromCampaignShema,
        },
        {
          name: "allow_team_update_by_public_token",
          title: this.$t(
            "edit_campaign.allow_team_update_by_public_token_setting_name",
            "Allow team update by public token"
          ),
          scheme: AllowTeamUpdateByPublicTokenShema,
        },
        {
          name: "donation_success_page_config",
          title: this.$t(
            "edit_campaign.donation_success_page_config_setting_name",
            "Donation Success Page Config"
          ),
          scheme: DonationSuccessPageConfig,
        },
        {
          name: "unidy_media_stripe",
          title: this.$t("edit_campaign.unidy_media_stripe_settings_name", "Unidy media stripe"),
          scheme: UnidyMediaStripe,
        },
        {
          name: "notify_org_about_donations_above",
          title: this.$t(
            "edit_campaign.notify_org_about_donations_above_settings_name",
            "Notify about donations above"
          ),
          scheme: NotifyOrgAboutDonationsAbove,
        },
        {
          name: "donors_goal",
          title: this.$t("edit_campaign.donors_goal_settings_name", "Donors goal"),
          scheme: DonorsGoal,
        },
        {
          name: "donation_upsell",
          title: this.$t("edit_campaign.donation_upsell_settings_name", "Donation Upsell"),
          scheme: DonationUpsell,
        },
        {
          name: "recurring_payments",
          title: this.$t("edit_campaign.recurring_payments_settings_name", "Recurring Payments"),
          scheme: RecurringPayments,
        },
        {
          name: "team_page_hide_donor_list",
          title: this.$t(
            "edit_campaign.team_page_hide_donor_list_settings_name",
            "Team Page Hide Donor List"
          ),
          scheme: TeamPageHideDonorListSchema,
        },
        {
          name: "notifications_from_email",
          title: this.$t(
            "edit_campaign.notifications_from_email_setting_name",
            "Notifications From Email"
          ),
          scheme: NotificationsFromEmail,
        },
        ...(this.addSetCampaignModeSetting && this.addSetCampaignModeSettingMeta
          ? [
              {
                name: "add_set_campaign_mode_setting",
                title: this.$t(
                  "edit_campaign.add_set_campaign_mode_setting_setting_name",
                  "Set Campaign Mode"
                ),
                scheme: AddSetCampaignModeSetting,
              },
            ]
          : []),
        {
          name: "activate_confetti_now",
          title: this.$t("edit_campaign.activate_confetti_now_setting_name", "Confetti ON/OFF"),
          scheme: ActivateConfettiNow,
        },
        {
          name: "min_donation_amount",
          title: this.$t("edit_campaign.meta_min_donation_amount", "Min Donation Amount"),
          scheme: MinDonationAmount,
        },
        {
          name: "maximum_donation_amount",
          title: this.$t("edit_campaign.meta_maximum_donation_amount", "Maximum Donation Amount"),
          scheme: MaximumDonationAmount,
        },
        {
          name: "donation_form_custom_fields",
          title: this.$t(
            "edit_campaign.meta_donation_form_custom_fields",
            "Donation form custom fields"
          ),
          scheme: DonationFormCustomFields,
        },
        {
          name: "custom_tabs_unidy_classic",
          title: this.$t(
            "edit_campaign.meta_custom_tabs_unidy_classic",
            "Custom tabs (unidy classic)"
          ),
          scheme: CustomTabsUnidyClassic,
        },
        {
          name: "phone_required",
          title: this.$t("edit_campaign.meta_phone_required", "Donation form - Phone required"),
          scheme: PhoneRequired,
        },
        {
          name: "show_billing_address",
          title: this.$t(
            "edit_campaign.meta_show_billing_address",
            "Donation form - Show billing address"
          ),
          scheme: ShowBillingAddress,
        },
      ];
    },
  },

  mounted() {
    this.fetchTicketActivated(this.cID);
  },

  methods: {
    ...mapActions({
      fetchTicketActivated: "ticket/fetchTicketActivated",
    }),
    toggleMenu() {
      this.menuOpenState = !this.menuOpenState;
    },
    closeMenu() {
      this.menuOpenState = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .nav-wrapper-class {
  display: var(--_menu-open, block);
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
}
::v-deep .settings-tabs {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #fff !important;
  @media only screen and (max-width: 991px) {
    border-right: none;
    width: 100%;
  }
}
::v-deep .tabs-class {
  flex-wrap: nowrap;
  @media only screen and (max-width: 991px) {
    flex-wrap: wrap;
  }
}
</style>
