<template>
  <b-modal :id="$options.MODAL_ID" size="xl" :ref="$options.MODAL_ID">
    <template #modal-header>
      <div>
        <h2>{{ $t("dashboard.campaign_team_linked_cc", "Charge linked CC") }}</h2>
        <div class="modal-header__hide" @click="hide">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>
    </template>
    <b-container fluid v-if="modalData.team && !modalData.initialLoading">
      <b-list-group>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>
            {{ $t("dashboard.campaign_team_linked_cc_team_goal_amount_label", "Goal:") }}
          </span>
          <span>
            {{ formatNumber(modalData.team.attributes.goal) }}
          </span>
        </b-list-group-item>

        <b-list-group-item
          v-if="modalData.team.attributes.extend.stats"
          class="d-flex justify-content-between align-items-center"
        >
          <span>
            {{ $t("dashboard.campaign_team_linked_cc_team_raised_amount_label", "Raised:") }}
          </span>
          <span>
            {{ formatNumber(modalData.team.attributes.extend.stats.total_amount) }}
          </span>
        </b-list-group-item>
      </b-list-group>
      <b-form class="mt-3" @submit.prevent="onCharge" id="charge-form">
        <b-form-group>
          <template #label>
            <span>
              {{ $t("dashboard.campaign_team_linked_cc_team_amount_input_label", "Amount:") }}
            </span>
          </template>
          <template #description>
            <span>
              {{
                $t(
                  "dashboard.campaign_team_linked_cc_team_amount_input_description",
                  "Amount should be less than the max amount {maxAmountCharge}",
                  {
                    maxAmountCharge: formatNumber(
                      modalData.maxAmountCharge,
                      modalData.stripeCurrency
                    ),
                  }
                )
              }}
            </span>
          </template>
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <span class="text-uppercase">{{ modalData.stripeCurrency }}</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              min="1"
              type="text"
              inputmode="numeric"
              pattern="[0-9]*"
              :max="modalData.maxAmountCharge"
              v-model="modalData.chargeAmount"
              required
              @input="onChargeAmountInput"
              ref="chargeAmountEl"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group v-if="campaignCurrency !== modalData.stripeCurrency">
          <template #label>
            <span>
              {{
                $t(
                  "dashboard.campaign_team_linked_cc_team_original_amount_input_label",
                  "Amount in campaign currency:"
                )
              }}
            </span>
          </template>
          <b-input-group v-if="!modalData.formLoading">
            <b-input-group-prepend>
              <b-input-group-text>
                <span class="text-uppercase">{{ campaignCurrency }}</span>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input readonly :value="modalData.originalAmount"></b-form-input>
          </b-input-group>
          <b-skeleton v-else type="input" width="100%" height="50px"></b-skeleton>
        </b-form-group>
      </b-form>
    </b-container>
    <b-container fluid v-else>
      <b-list-group>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <b-skeleton width="10%"></b-skeleton>
          <b-skeleton width="10%"></b-skeleton>
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <b-skeleton width="10%"></b-skeleton>
          <b-skeleton width="10%"></b-skeleton>
        </b-list-group-item>
      </b-list-group>
      <b-form class="mt-3">
        <b-form-group>
          <b-skeleton type="input" width="100%" height="50px"></b-skeleton>
          <template #label>
            <b-skeleton width="50%"></b-skeleton>
          </template>
          <template #description>
            <b-skeleton width="100%"></b-skeleton>
          </template>
        </b-form-group>
      </b-form>
    </b-container>
    <template #modal-footer>
      <div class="d-flex justify-content-between">
        <button type="button" class="bttn bttn--lg bttn--blue" @click="hide">
          {{ $t("teams.close", "Close") }}
        </button>
        <button
          type="submit"
          :disabled="!modalData.formValid || modalData.initialLoading || modalData.formLoading"
          form="charge-form"
          class="bttn bttn--lg bttn--orange"
        >
          <b-spinner
            v-if="modalData.initialLoading || modalData.formLoading"
            class="mx-2"
            small
            label="Charge loading..."
          ></b-spinner>
          {{ $t("dashboard.campaign_team_linked_cc_team_charge_action", "Charge") }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import urls from "@/constants/urls";
import { notificationsMixin } from "@/mixins";
import axios from "axios";
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";

export default {
  MODAL_ID: "TeamChargeLinkedCCModal",
  DEFAULT_DATA: {
    team: null,
    maxAmountCharge: 0,
    initialLoading: false,
    stripeCurrency: "",
    formValid: false,
    chargeAmount: 1,
    formLoading: false,
    originalAmount: 0,
  },

  mixins: [notificationsMixin],
  data() {
    return {
      modalData: structuredClone(this.$options.DEFAULT_DATA),
    };
  },
  computed: {
    ...mapState({
      campaignCurrency: (state) =>
        (state.campaign.campaign.data.attributes.currency || "").toLowerCase(),
      campaignId: (state) => state.campaign.campaign.data.id,
    }),
  },
  methods: {
    ...mapActions(["getGatewaysAndReturn"]),

    formatNumber(number, currency = this.campaignCurrency) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
      }).format(number);
    },
    async show(team) {
      this.modalData.initialLoading = true;
      this.modalData.team = team;
      this.$refs[this.$options.MODAL_ID].show();

      try {
        const gateways = await this.getGatewaysAndReturn();
        const stripeGateway = gateways.find(
          (g) => g.attributes.gateway === "stripe" && g.attributes.active
        );
        if (!stripeGateway) throw new Error("No stripe gateway");

        const stripeCurrency = (stripeGateway.attributes.primary_currency || "").toLowerCase();
        if (!stripeCurrency) throw new Error("No stripe currency");
        this.modalData.stripeCurrency = stripeCurrency;

        const amount =
          this.modalData.team.attributes.goal -
          (this.modalData.team.attributes.extend.stats.total_amount || 0);
        if (isNaN(amount)) throw new Error("Invalid amount");

        const maxAmountCharge = await this.xchange({
          amt: amount,
          from: this.campaignCurrency,
          to: stripeCurrency,
        });
        this.modalData.maxAmountCharge = maxAmountCharge;

        this.modalData.initialLoading = false;
        await this.$nextTick();
        await this.$nextTick();
        this.onChargeAmountInput(this.modalData.chargeAmount);
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
      }
    },
    hide() {
      this.modal = structuredClone(this.$options.DEFAULT_DATA);
      this.$refs[this.$options.MODAL_ID].hide();
    },
    async xchange({ amt, from, to }) {
      const { data } = await axios.get(
        urls.getConversionAmount.replace(":amount", amt).replace(":from", from).replace(":to", to)
      );
      return Math.ceil(data.data.attributes.amount_out);
    },
    async setOriginalAmount(v) {
      try {
        const originalAmount = await this.xchange({
          amt: v,
          from: this.modalData.stripeCurrency,
          to: this.campaignCurrency,
        });
        this.modalData.originalAmount = originalAmount;
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
      } finally {
        this.modalData.formLoading = false;
      }
    },
    onOriginalAmountInput: debounce(function (v) {
      this.modalData.formLoading = true;
      this.setOriginalAmount(v);
    }, 500),
    onChargeAmountInput(v) {
      const el = this.$refs.chargeAmountEl.$el;
      if (v > this.modalData.maxAmountCharge) {
        this.modalData.formValid = false;
        el.setCustomValidity("Amount exceeds max amount");
      } else {
        this.modalData.formValid = true;
        el.setCustomValidity("");
      }

      if (this.campaignCurrency !== this.modalData.stripeCurrency) {
        this.onOriginalAmountInput(v);
      }
    },
    async onCharge() {
      this.modalData.formLoading = true;

      try {
        const orgId = localStorage.getItem("orgId");
        await axios.post(urls.stripeCharge(orgId, this.campaignId, this.modalData.team.id), {
          amount: Math.round((Math.abs(Number(this.modalData.chargeAmount)) / 100) * 10000),
          currency: this.modalData.stripeCurrency,
        });
        this.hide();
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
      } finally {
        this.modalData.formLoading = false;
      }
    },
  },
};
</script>
