<template>
  <b-overlay
    overlay-tag="b-container"
    fluid
    class="shadow-sm bg-white rounded border border-light pt-2 mb-4"
    :show="loading"
  >
    <b-container>
      <b-row>
        <b-col cols="4" class="d-flex flex-column">
          <b-form-group label="Sort Teams">
            <b-form-select :value="value" @change="onChange" :options="options" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    loading: Boolean,
    value: {
      type: String,
      default: "goal",
    },
  },
  mounted() {},
  computed: {
    ...mapState({}),
    options() {
      return [
        { value: "goal", text: this.$t("donor_dashboard.teams_sortby_goal", "Goal") },
        { value: "-goal", text: this.$t("donor_dashboard.teams_sortby_goal_desc", "Goal (DESC)") },
        { value: "total", text: this.$t("donor_dashboard.teams_sortby_total", "Raised Amount") },
        {
          value: "-total",
          text: this.$t("donor_dashboard.teams_sortby_total_desc", "Raised Amount (DESC)"),
        },
        { value: "donors", text: this.$t("donor_dashboard.teams_sortby_donors", "Donors") },
        {
          value: "-donors",
          text: this.$t("donor_dashboard.teams_sortby_donors_desc", "Donors (DESC)"),
        },
      ];
    },
  },
  methods: {
    ...mapActions({}),
    onChange(value) {
      this.$emit("change", value);
    },
  },
};
</script>
