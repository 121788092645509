<script>
import EditIcon from "./edit-icon.vue";

export default {
  components: {
    EditIcon,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    buttonClass: {
      type: [Object, Array, String],
      default: "",
    },
    loading: Boolean,
  },
};
</script>

<template>
  <div>
    <button :id="id" :disabled="loading" :class="buttonClass" @click="$emit('click', id)">
      <EditIcon v-if="!loading" />
      <b-spinner v-else small label="Loading Campaign"></b-spinner>
    </button>
    <b-tooltip :target="id" triggers="hover">
      {{
        $t(
          "campaigns.currentlyEditedInWizard",
          "You created your campaign in Wizard. Click 'Done' on last step to fully edit"
        )
      }}
    </b-tooltip>
  </div>
</template>
