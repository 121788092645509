var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('ValidationObserver',{ref:"observer"},[_c('b-row',[(!_vm.hideSubject)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"form-group__label"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.email_message_subject", "Subject"))+" ")])]},proxy:true}],null,false,2713737555)},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1)],1):_vm._e(),(_vm.showReplyTo)?_c('b-col',{attrs:{"cols":"12"}},[(_vm.value.test_mode)?_c('b-form-group',{attrs:{"description":_vm.$t(
                'dashboard.email_message_test_mode_recipient_desc',
                'Use this field to send message to yourself'
              )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"form-group__label form-group__label--required text-danger font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.email_message_test_mode_recipient", "Test mode recipient"))+" ")])]},proxy:true}],null,false,1921223973)},[_c('b-form-input',{attrs:{"type":"email","required":""},model:{value:(_vm.testModeRecepientEmail),callback:function ($$v) {_vm.testModeRecepientEmail=$$v},expression:"testModeRecepientEmail"}})],1):_c('b-form-group',{attrs:{"description":_vm.$t(
                'dashboard.email_message_replay_to_desc',
                'Use this field to resend message to person'
              )},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"form-group__label form-group__label--required"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.email_message_replay_to", "Replay To"))+" ")])]},proxy:true}],null,false,3306648711)},[_c('b-form-input',{attrs:{"type":"email","required":""},model:{value:(_vm.replayTo),callback:function ($$v) {_vm.replayTo=$$v},expression:"replayTo"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"message","rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"form-group__label form-group__label--required"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.email_message_data", "Message"))+" ")])]},proxy:true},{key:"description",fn:function(){return [(_vm.maxLength)?_c('span',{class:{ 'text-danger': _vm.charactersLeft < 0 }},[_vm._v(" "+_vm._s(`${_vm.charactersLeftText} characters left`)+" ")]):_vm._e()]},proxy:true}],null,true)},[_c('quill-editor-variables',{attrs:{"tags":_vm.tagsloc,"simple":_vm.simple},scopedSlots:_vm._u([{key:"btn-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("donor_dashboard.email_template_edit_title", "Email variables"))+" ")]},proxy:true},{key:"description",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("donor_dashboard.email_template_var_description", "variables"))+" ")]},proxy:true}],null,true),model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('b-form-invalid-feedback',{attrs:{"state":valid}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }