const edjsHTML = require("editorjs-html");

const embed = ({ data }) => {
  switch (data.service) {
    case "vimeo":
      return `
        <div class="resp-container">
          <iframe
            class="resp-iframe"
            src="${data.embed}"
            height="${data.height}"
            frameborder="0"
            gesture="media"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
      `;
    case "youtube":
      return `
        <div class="resp-container">
          <iframe
            class="resp-iframe"
            width="${data.width}"
            height="${data.height}"
            src="${data.embed}"
            title="YouTube video player"
            frameborder="0"
            gesture="media"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
      `;
    default:
      throw new Error('Unsupported embed service type. Supported are "youtube" and "vimeo"');
  }
};

export const edjsHtmlWithTransformers = edjsHTML.bind(null, { embed });
