import Vue from "vue";
import FormList from "./form-list.vue";
import FormListObject from "./form-list-object.vue";
import FormListCard from "./form-list-card.vue";
import Tooltip from "@/common-components/tooltip.vue";
import { nanoid } from "nanoid";
import { isPlainObject } from "@/helpers/inspect";

export const FormGeneratorOptions = {
  components: {
    FormList,
    FormListObject,
    FormListCard,
    Tooltip,
  },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    scheme: {
      type: Object,
      default: () => null,
    },
    components: {
      type: Object,
      default: () => null,
    },
    form: {
      type: [String, Object],
      default: null,
    },
    schemeName: {
      type: [String, Object],
      default: null,
    },
    submit: {
      type: Function,
      default: () => null,
    },
    as: {
      type: String,
      default: "form",
    },
  },

  render(h) {
    const self = this;

    const excractProperties = (obj) => {
      return Object.entries(obj.properties || {});
    };

    const getMd = (nested, expanded) => {
      if (expanded) {
        return 12;
      }
      if (!nested) {
        return 6;
      }
      return null;
    };

    const tryUpdateMagicIf = () => {
      for (const [, v] of excractProperties(self.scheme)) {
        if (Object.hasOwn(v, "$if")) {
          self.$forceUpdate();
        }
      }
    };

    const useFormGroup = (
      h,
      {
        field,
        label,
        nested,
        custom,
        expanded,
        showKey,
        key,
        type,
        tooltipText,
        cardFooterSlotId,
        tableListGroupSlotId,
      } = {}
    ) => {
      let component = null;
      let staticClass = [];
      const md = getMd(nested, expanded);

      switch (type) {
        case "list-card": {
          component = h("b-card", { staticClass: "w-100", props: { noBody: true } }, [
            h(
              "b-card-header",
              {
                staticClass: "d-flex flex-row align-items-baseline",
                attrs: { role: "tab" },
              },
              [
                h("strong", { staticClass: "mb-0" }, [label || ""]),
                tooltipText &&
                  h(Tooltip, {
                    staticClass: "mx-2",
                    props: { title: tooltipText, opacity: false },
                  }),
              ]
            ),
            h("b-list-group", { attrs: { flush: true } }, [
              h("portal-target", { props: { slim: true, name: tableListGroupSlotId } }),
            ]),
            field,
            h("b-card-footer", {}, [
              h("portal-target", { props: { slim: true, name: cardFooterSlotId } }),
            ]),
          ]);
          staticClass = ["d-flex"];
          if (md === 6) {
            staticClass.push("border-bottom", "py-3");
          } else if (md === 12) {
            staticClass.push("my-3");
          }

          break;
        }

        default: {
          const options = {
            staticClass: "form-group-custom align-self-end w-100",
            props: {
              label: label || "",
            },
            scopedSlots: {
              ...(showKey && {
                description: () =>
                  h(
                    "div",
                    {
                      staticClass: "d-flex gap-1 flex-row",
                    },
                    [h("b", "attribute:"), h("span", key)]
                  ),
              }),
            },
          };

          component = custom ? field : h("b-form-group", options, [field]);
          staticClass = ["d-flex"];

          break;
        }
      }

      return h(
        "b-col",
        {
          attrs: { cols: "12", md },
          staticClass: staticClass.join(" "),
        },
        [component]
      );
    };

    const typeAttributes = (type) => {
      if (type === "number") {
        return "text";
      }

      return type || "text";
    };

    const numberAttributes = (type) => {
      if (type === "number") {
        return {
          inputmode: "numeric",
          pattern: "[0-9]*",
        };
      }

      return {};
    };

    const useInput = (
      h,
      {
        form,
        key,
        type,
        placeholder,
        required,
        maxLength,
        min,
        max,
        step,
        formatter,
        schemeName,
      } = {}
    ) => {
      const formatterFn = isPlainObject(formatter) ? formatter.handler : formatter;
      const formatterOnMount = isPlainObject(formatter) ? formatter.onMount : false;

      if (formatterOnMount) {
        self.$set(form, key, formatterFn(form[key]));
      }

      const tree = h("b-form-input", {
        attrs: {
          type: typeAttributes(type),
          placeholder: placeholder || "",
          required: required || false,
          maxLength: maxLength || null,
          min: min || null,
          max: max || null,
          step: step || null,
          formatter: formatterFn,
          lazyFormatter: isPlainObject(formatter) ? formatter.lazy : false,
          name: `${schemeName}|${key}`,
          ...numberAttributes(type),
        },
        model: {
          value: form[key],
          callback($$v) {
            if (type === "number") {
              const number = Number($$v);
              if (Number.isNaN(number)) {
                self.$set(form, key, $$v);
              } else {
                self.$set(form, key, number);
              }
            } else {
              self.$set(form, key, $$v);
            }
          },
        },
      });

      return tree;
    };

    const useTagsInput = (h, { form, key, type, placeholder, required } = {}) => {
      const tree = h("b-form-tags", {
        attrs: {
          inputType: typeAttributes(type),
          placeholder: placeholder || "",
          required: required || false,
          separator: " ",
          tagVariant: "primary",
          removeOnDelete: true,
          addButtonVariant: "primary",
          inputClass: "h-auto",
        },
        model: {
          value: form[key],
          callback($$v) {
            if (type === "number") {
              self.$set(
                form,
                key,
                $$v.map((v) => {
                  const number = Number(v);
                  if (Number.isNaN(number)) {
                    return v;
                  }
                  return number;
                })
              );
            } else {
              self.$set(form, key, $$v);
            }
          },
        },
        on: {
          input(tags) {
            if (type === "number") {
              self.$nextTick(() => {
                if (tree.elm) {
                  const elm = tree.elm;
                  const input = elm.querySelector(".b-form-tags-input");

                  let notValid = false;
                  for (const tag of tags) {
                    if (Number.isNaN(Number(tag))) {
                      notValid = true;
                      break;
                    }
                  }

                  if (notValid) {
                    input.setCustomValidity(
                      self.$t(
                        "edit_campaign.setting_number_validation",
                        "All values should be a number!"
                      )
                    );
                  } else {
                    input.setCustomValidity("");
                  }
                }
              });
            }
          },
        },
      });

      self.$nextTick(() => {
        if (tree.elm) {
          const elm = tree.elm;
          const input = elm.querySelector(".b-form-tags-input");
          for (const [k, v] of Object.entries(numberAttributes(type))) {
            input.setAttribute(k, v);
          }
        }
      });

      return tree;
    };

    const useCheckbox = (h, { form, key, label, placeholder, required } = {}) => {
      return h(
        "b-form-checkbox",
        {
          attrs: {
            placeholder: placeholder || "",
            required: required || false,
          },
          props: {
            switch: true,
          },
          model: {
            value: form[key],
            callback($$v) {
              self.$set(form, key, $$v);
              tryUpdateMagicIf();
            },
          },
        },
        [label]
      );
    };

    const useFile = (h, { form, key, accept, placeholder, required, inputType } = {}) => {
      const resetFile = () => {
        self.$set(form, key, null);
      };

      let value = form[key];
      if (value?.url || value?.file_name || typeof value === "string") {
        const type = `image/${value?.file_name?.split(".")?.reverse()?.[0] || "png"}`;
        const file = new File([nanoid()], value?.file_name || "image", { type });
        value = file;
        placeholder = value?.name || "image";
      }

      return h("div", {}, [
        h(
          "b-input-group",
          {
            scopedSlots: {
              append: () => {
                if (value) {
                  return h("div", { staticClass: "btn h-100", on: { click: resetFile } }, [
                    h("b-icon-arrow-clockwise", {
                      attrs: { rotate: "45", scale: "1.5" },
                    }),
                  ]);
                }
                return h();
              },
            },
            class: "my-2",
          },
          [
            h("b-form-file", {
              attrs: {
                accept: accept,
                placeholder: placeholder || "Choose a file or drop it here...",
                "drop-placeholder": "Drop file here...",
                required: inputType === "text/file" ? false : required || false,
              },
              model: {
                value,
                callback($$v) {
                  self.$set(form, key, $$v);
                },
              },
            }),
          ]
        ),
        inputType === "text/file" && [
          h(
            "b-input-group",
            {
              scopedSlots: {
                append: () => {
                  if (value) {
                    return h("div", { staticClass: "btn h-100", on: { click: resetFile } }, [
                      h("b-icon-arrow-clockwise", {
                        attrs: { rotate: "45", scale: "1.5" },
                      }),
                    ]);
                  }
                  return h();
                },
              },
              class: "my-2",
            },
            [
              h("b-form-input", {
                attrs: {
                  placeholder: placeholder || "Enter video or image url...",
                },
                model: {
                  value: typeof form[key] === "string" ? form[key] : form[key]?.name,
                  callback($$v) {
                    self.$set(form, key, $$v);
                  },
                },
              }),
            ]
          ),
        ],
      ]);
    };

    const useSelect = (h, { form, key, options, placeholder, required } = {}) => {
      return h("b-form-select", {
        attrs: { placeholder: placeholder || "", required: required || false },
        props: {
          options: options || [],
        },
        model: {
          value: form[key],
          callback($$v) {
            self.$set(form, key, $$v);
          },
        },
      });
    };

    const useList = (
      h,
      {
        form,
        key,
        scheme,
        placeholder,
        formName,
        schemeName,
        layout,
        styleVariant,
        cardFooterSlotId,
        tableListGroupSlotId,
      } = {}
    ) => {
      const formId = `${formName}-${nanoid()}`;
      return h(styleVariant, {
        attrs: { placeholder: placeholder || "" },
        props: {
          scheme: scheme || {},
          form: formId,
          layout,
          schemeName,
          cardFooterSlotId,
          tableListGroupSlotId,
        },
        scopedSlots: {
          form: (props) =>
            h(
              "form",
              {
                attrs: {
                  id: formId,
                },
                on: {
                  submit: (e) => {
                    e.preventDefault();
                    props.submit();
                  },
                },
              },
              genereteForm(scheme, props.value, true, formName)
            ),
        },
        model: {
          value: form[key],
          callback($$v) {
            self.$set(form, key, $$v);
            self.$forceUpdate();
          },
        },
      });
    };

    const useListObject = (
      h,
      { form, key, scheme, placeholder, formName, schemeName, layout } = {}
    ) => {
      const formId = `${formName}-${nanoid()}`;
      return h("form-list-object", {
        attrs: { placeholder: placeholder || "" },
        props: {
          scheme: scheme || {},
          form: formId,
          layout,
          schemeName,
        },
        scopedSlots: {
          form: (props) =>
            h(
              "form",
              {
                attrs: {
                  id: formId,
                },
                on: {
                  submit: (e) => {
                    e.preventDefault();
                    props.submit();
                  },
                },
              },
              genereteForm(scheme, props.value, true, "", "", form, key)
            ),
        },
        model: {
          value: form[key],
          callback($$v) {
            self.$set(form, key, $$v);
            self.$forceUpdate();
          },
        },
      });
    };

    const useStyle = (h, { scheme, form, key }) => {
      const formId = `styleForm-${nanoid()}`;

      const convertFromCssStyle = (from) => {
        return from
          .split(";")
          .filter(Boolean)
          .reduce((acc, curr) => {
            const [cssKey, cssValue] = curr.split(":");
            acc[cssKey] = !cssValue ? 0 : parseInt(cssValue, 10);
            return acc;
          }, {});
      };
      if (typeof form[key] === "string" || !form[key]) {
        form[key] = convertFromCssStyle(form[key] || "");
      }

      return h(
        "form",
        {
          attrs: {
            id: formId,
          },
          on: {
            submit: (e) => {
              e.preventDefault();
            },
          },
        },
        genereteForm(scheme, form[key], true)
      );
    };

    const genereteForm = (
      scheme,
      form,
      nested = false,
      formName = "",
      schemeName = "",
      parentForm = null,
      parentFormKey = null
    ) => {
      const shemaProps = excractProperties(scheme);

      return shemaProps.map(([key, value]) => {
        const cardFooterSlotId = `${formName}-${nanoid()}-footer`;
        const tableListGroupSlotId = `${formName}-${nanoid()}-table-list-group`;

        if (Object.hasOwn(value, "$if") && !value.$if(form)) {
          return;
        }

        const formGroupOptions = {
          key,
          label: value.title,
          expanded: value.expanded,
          custom: false,
          nested,
          showKey: value.showKey,
          type: value.type,
          tooltipText: value.tooltipText,
          cardFooterSlotId,
          tableListGroupSlotId,
        };

        const setDefault = () => {
          const defaultValues = {
            object: {},
            boolean: false,
            string: "",
            number: 0,
            list: [],
            enum: [],
          };

          if (form[key] === undefined) {
            form[key] = value.default || defaultValues[value.type];
          }
        };

        setDefault();

        const customComponent = this.components?.[value?.component];

        if (customComponent) {
          const field = h(customComponent, {
            props: {
              fieldKey: key,
              field: value,
              form,
              parentValue: parentForm && parentFormKey ? parentForm[parentFormKey] : null,
              parentFormKey,
            },
            model: {
              value: form[key],
              callback($$v) {
                self.$set(form, key, $$v);
              },
            },
          });

          formGroupOptions.field = field;
          formGroupOptions.custom = true;

          if (value.type === "object") {
            genereteForm(value, form[key], true, "", true);
          }

          return useFormGroup(h, formGroupOptions);
        }

        if (value.type === "string") {
          formGroupOptions.field = useInput(h, {
            form,
            key,
            required: value.required,
            maxLength: value.maxLength,
            formatter: value.formatter,
            placeholder: value.placeholder,
          });
        }

        if (value.type === "number") {
          formGroupOptions.field = useInput(h, {
            form,
            key,
            type: "number",
            min: value.min,
            max: value.max,
            step: value.step,
            formatter: value.formatter,
            schemeName,
          });
        }

        if (value.type === "color") {
          formGroupOptions.field = useInput(h, { form, key, type: "color" });
        }

        if (value.type === "boolean") {
          formGroupOptions.label = "";
          formGroupOptions.field = useCheckbox(h, {
            form,
            key,
            label: value.title,
          });
        }

        if (value.type === "enum") {
          const options = value.items.enum;

          formGroupOptions.field = useSelect(h, { form, key, options });
        }

        if (value.type === "list") {
          const scheme = value.items;

          formGroupOptions.field = useList(h, {
            form,
            key,
            scheme,
            formName,
            schemeName,
            layout: value?.layout,
            styleVariant: "form-list",
          });
        }

        if (value.type === "list-card") {
          const scheme = value.items;

          formGroupOptions.field = useList(h, {
            form,
            key,
            scheme,
            formName,
            schemeName,
            layout: value?.layout,
            styleVariant: "form-list-card",
            cardFooterSlotId,
            tableListGroupSlotId,
          });
        }

        if (value.type === "list-object") {
          const scheme = value.items;

          formGroupOptions.field = useListObject(h, {
            form,
            key,
            scheme,
            formName,
            schemeName,
            layout: value?.layout,
          });
        }

        if (value.type === "image") {
          formGroupOptions.field = useFile(h, {
            form,
            key,
            required: value.required,
            accept: "image/*",
            preview: value.preview,
          });
        }

        if (value.type === "image/video") {
          formGroupOptions.field = useFile(h, {
            form,
            key,
            required: value.required,
            accept: "image/*,video/mp4,video/x-m4v,video/*",
            preview: value.preview,
            inputType: value?.inputType,
          });
        }

        if (value.type === "style") {
          formGroupOptions.field = useStyle(h, {
            scheme: value,
            form,
            key,
          });
        }

        if (value.type === "object") {
          const md = getMd(nested, value.expanded);

          if (scheme.layout === "accordion") {
            return h("b-card", { props: { noBody: true } }, [
              h("b-card-header", { attrs: { role: "tab" } }, [
                h("h2", { staticClass: "mb-0" }, [
                  h(
                    "b-button",
                    {
                      attrs: {
                        id: `${key}-object-layout-accordion-button`,
                      },
                      props: { variant: "link" },
                      directives: [
                        {
                          name: "b-toggle",
                          arg: `${key}-object-layout-accordion`,
                        },
                      ],
                    },
                    value.title
                  ),
                ]),
              ]),
              h(
                "b-collapse",
                {
                  attrs: { id: `${key}-object-layout-accordion`, role: "tabpanel" },
                  props: { accordion: "object-layout-accordion" },
                },
                [h("b-card-body", [...genereteForm(value, form[key], true, "", key)])]
              ),
            ]);
          }

          return h("b-col", { attrs: { cols: "12", md }, class: { "mb-4": md === 12 } }, [
            h("div", { staticClass: "mb-2 font-weight-bold" }, value.title),
            ...genereteForm(value, form[key], true),
          ]);
        }

        if (value.type === "array") {
          formGroupOptions.field = useTagsInput(h, {
            form,
            key,
            type: value.inputType,
            required: value.required,
            placeholder: value.placeholder,
          });
        }

        return useFormGroup(h, formGroupOptions);
      });
    };

    const layouts = {
      default: () =>
        h("b-row", genereteForm(this.scheme, this.value, false, this.form, this.schemeName)),
      accordion: () =>
        h(
          "div",
          {
            staticClass: "accordion",
            attrs: {
              role: "tablist",
              id: "object-layout-accordion",
            },
          },
          genereteForm(this.scheme, this.value, false, this.form, this.schemeName)
        ),
    };

    const renderLayout = layouts[this.scheme?.layout] || layouts.default;

    return h(
      this.as,
      {
        attrs: { id: this.form },
        on: {
          submit: (e) => {
            e.preventDefault();
            this.submit();
          },
        },
      },
      [renderLayout()]
    );
  },
};

export default Vue.extend(FormGeneratorOptions);
