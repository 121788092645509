var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.allowConditionalDonations.value)?_c('div',[(
        _vm.allowConditionalDonations.types_supported.includes('peer_match') &&
        _vm.donation.attributes.locked &&
        _vm.donation.attributes.locked_type === 'peer_match' &&
        _vm.donation.attributes.peer_matched_by_donation_id === 0
      )?_vm._t("match-donation"):_vm._e(),(_vm.donation.attributes.peer_matched_by_donation_id > 0)?_vm._t("donation-matched-by"):(
        _vm.donation.attributes.peer_matched_by_donation_id === 0 &&
        _vm.donation.attributes.peer_matched_donation_id > 0
      )?_vm._t("donation-matched"):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }