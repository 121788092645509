<template>
  <b-modal ref="modal" no-close-on-backdrop no-close-on-esc>
    <template #modal-header>
      <h2 class="text-capitalize">
        {{ modalTitle }}
      </h2>
    </template>

    <b-form ref="goalCategoriesForm" @submit.prevent="proccess">
      <div class="image">
        <image-upload v-model="form.image" label="Upload image" />
      </div>

      <b-form-group>
        <template #label>
          <label class="form-group__label form-group__label--required m-0"> Key: </label>
        </template>
        <b-form-input
          v-model="keyModel"
          placeholder="Enter key"
          pattern="^[A-Za-z0-9_\-]+$"
          required
          name="key"
          title="Alphabetic characters, numbers, dashes or underscores only."
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <template #label>
          <label class="form-group__label form-group__label--required m-0"> Title: </label>
        </template>
        <b-form-input
          v-model="form.title"
          name="title"
          required
          placeholder="Enter title"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Subtitle:">
        <b-form-input
          v-model="form.subtitle"
          name="subtitle"
          placeholder="Enter subtitle"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <template #label>
          <label class="form-group__label form-group__label--required m-0"> Price: </label>
        </template>
        <b-form-input
          v-model="form.price"
          type="text"
          inputmode="numeric"
          pattern="[0-9]*"
          name="price"
          required
          number
          title="Enter price (only numbers)"
          placeholder="Enter price"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Levels:">
        <multiselect
          v-model="levelsModel"
          :options="levels"
          :custom-label="(el) => `[${el.id}] ${el.title}`"
          track-by="id"
          :multiple="true"
          :tags="true"
          :searchable="true"
          :select-label="$t('dashboard.team_select_label', 'Press enter to select')"
          :deselect-label="$t('dashboard.team_deselect_label', 'Press enter to remove')"
          :selected-label="$t('dashboard.team_selected_label', 'Selected')"
        >
        </multiselect>
      </b-form-group>

      <b-form-group v-if="tickets.length > 0" label="Tickets:">
        <multiselect
          v-model="ticketsModel"
          :options="tickets"
          :custom-label="(el) => `[${el.key}] ${el.title}`"
          track-by="key"
          :multiple="true"
          :tags="true"
          :searchable="true"
          :select-label="$t('dashboard.team_select_label', 'Press enter to select')"
          :deselect-label="$t('dashboard.team_deselect_label', 'Press enter to remove')"
          :selected-label="$t('dashboard.team_selected_label', 'Selected')"
        >
        </multiselect>
      </b-form-group>
    </b-form>

    <template #modal-footer>
      <button class="bttn bttn--lg bttn--blue" @click="close">
        {{ $t(`dashboard.cancel_btn`) }}
      </button>
      <button
        class="bttn bttn--lg bttn--orange text-capitalize"
        :disabled="loading"
        @click="onModalOk"
      >
        <b-spinner v-if="loading" small class="mr-1"></b-spinner>
        {{ okBtnTitle }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import urls from "@/constants/urls";
import { cloneDeep } from "@/helpers/cloneDeep";
import Multiselect from "vue-multiselect";
import ImageUpload from "@/components/image-upload.vue";
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],

  components: {
    Multiselect,
    ImageUpload,
  },

  data() {
    return {
      edit: false,
      idx: -1,
      form: {},
      loading: false,
      defaultValue: null,
    };
  },

  computed: {
    ...mapState({
      ticketActivated: (state) => state.ticket.activated,
      ticketsConfig: (state) => state.ticket.config,
      levels: (state) => Object.values(state.campaign.levels),
    }),

    tickets() {
      if (this.ticketActivated) {
        return Object.entries(this.ticketsConfig?.list || {}).map(([key, data]) => ({
          key,
          title: data.title,
        }));
      }
      return [];
    },

    ticketsModel: {
      get() {
        return (
          this.tickets.filter((el) => this.form?.raffle_hot_button_keys?.includes(el.key)) || []
        );
      },
      set(list) {
        const keys = list.map((l) => l.key);
        this.$set(this.form, "raffle_hot_button_keys", keys);
      },
    },

    keyModel: {
      get() {
        return this.form.key || this.defaultValue;
      },
      set(val) {
        this.$set(this.form, "key", val);
      },
    },

    levelsModel: {
      get() {
        return this.levels?.filter((lvl) => this.form?.level_ids?.includes(Number(lvl.id))) || [];
      },
      set(list) {
        const ids = list.map((l) => Number(l.id));
        this.$set(this.form, "level_ids", ids);
      },
    },

    modalTitle() {
      if (this.edit) {
        return this.$t(
          "edit_campaign.setting_goal_categories_edit_modal_title",
          "Edit goal category"
        );
      }
      return this.$t(
        "edit_campaign.setting_goal_categories_add_modal_title",
        "Add new goal category"
      );
    },

    okBtnTitle() {
      if (this.edit) {
        return this.$t("edit_campaign.setting_goal_categories_modal_save_btn", "Save");
      }
      return this.$t("edit_campaign.setting_goal_categories_modal_add_btn", "Add");
    },
  },

  methods: {
    open(edit = false, idx = -1, item = {}) {
      if (edit) {
        this.edit = true;
        this.idx = idx;
        this.form = cloneDeep(item);
      }

      this.defaultValue = `goal_category_${Date.now()}`;

      this.$refs.modal.show();
    },

    close() {
      this.edit = false;
      this.idx = -1;
      this.form = {};

      this.$refs.modal.hide();
    },

    getMediaUrl(file) {
      if (!file) return Promise.resolve(null);

      return new Promise((resolve) => {
        if (file instanceof File || file instanceof Blob) {
          const formData = new FormData();

          formData.append("file", file);

          axios.post(urls.loadImageQuill, formData).then((response) => {
            resolve(response.data.src);
          });
        } else {
          resolve(file);
        }
      });
    },

    onModalOk() {
      this.$refs.goalCategoriesForm.requestSubmit();
    },

    proccess() {
      this.loading = true;
      this.getMediaUrl(this.form.image)
        .then((url) => {
          this.form.image = url;
          this.$emit("save", {
            index: this.idx,
            item: { ...this.form, key: this.form.key || this.defaultValue },
          });
          this.close();
        })
        .catch((e) => {
          console.error(e);
          this.$_notificationsMixin_handleCatch(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .image {
    .matcher-img {
      width: 200px;
      height: 200px;
      border-radius: 0;
    }
    .image-wrapper {
      border-radius: 0;
    }
  }
}
.image {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
