import { render, staticRenderFns } from "./organization-role.vue?vue&type=template&id=728e7b36&"
import script from "./organization-role.vue?vue&type=script&lang=js&"
export * from "./organization-role.vue?vue&type=script&lang=js&"
import style0 from "./organization-role.vue?vue&type=style&index=0&id=728e7b36&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports