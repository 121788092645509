<template>
  <b-modal id="exportTeams" size="lg" @hidden="clearData">
    <div slot="modal-header">
      <h2>{{ $t("dashboard.export_modal_teams_title") }}</h2>
      <div class="modal-header__hide" @click.prevent="hideModal">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
      </div>
    </div>

    <div v-if="!startExport">
      <div class="filter-block-wrapper">
        <h5 class="modal-subtitle mb-3">
          {{ $t("dashboard.export_modal_filter_label") }}
        </h5>
        <div class="filter-block dashboard-donation__type mb-2">
          <label class="pb-2 form-unit__label">{{ $t("dashboard.parent_team") }}</label>
          <multiselect-search
            v-model="parentTeam"
            :open-prefetch="true"
            :search-url="getSearchUrl"
            :search-options="searchOptions"
            :multiselectClass="{ cms: true }"
          >
            <template #no-options>
              {{ $t("dashboard.team_search_no_options", "Please enter 1 or more characters") }}
            </template>
            <template #no-more-infinite>
              {{ $t("dashboard.team_search_no_more", "No more teams") }}
            </template>
          </multiselect-search>
        </div>
      </div>

      <b-form-group>
        <b-form-checkbox v-model="includeChildren" :value="1" :unchecked-value="null">
          {{ $t("dashboard.team_export_include_children", "include children") }}
        </b-form-checkbox>
      </b-form-group>
      <b-form-group>
        <b-form-checkbox v-model="importFormat" :value="1" :unchecked-value="null">
          {{
            $t(
              "dashboard.team_aexport_in_import_format",
              "Make the export in the import format (for mass editing)"
            )
          }}
        </b-form-checkbox>
      </b-form-group>

      <b-form-group :label="$t('teams.export_subaccount_filter', 'Filter by sub-account')">
        <Multiselect
          v-model="login"
          :options="logins"
          track-by="user_id"
          :custom-label="(l) => `${l.firstname} ${l.lastname} <${l.email}>`"
          :multiple="false"
          :tags="false"
          :searchable="true"
          :select-label="$t('dashboard.logins_to_teams_select_label', 'Press enter to select')"
          :deselect-label="$t('dashboard.logins_to_teams_deselect_label', 'Press enter to remove')"
          :selected-label="$t('dashboard.logins_to_teams_selected_label', 'Selected')"
        >
        </Multiselect>
      </b-form-group>
    </div>

    <div v-else>
      <div v-if="loading">
        <p>{{ $t("dashboard.loading_tems_export") }}</p>
      </div>

      <div v-else-if="!loading && !error">
        <p>{{ $t("dashboard.team_export_text") }}</p>
      </div>
    </div>

    <div slot="modal-footer">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
        {{ $t("dashboard.hide_modal") }}
      </button>
      <button
        v-if="!startExport"
        class="bttn bttn--lg bttn--orange"
        type="button"
        name="button"
        @click="exportTeams"
      >
        {{ $t("dashboard.export_teams_btn", "Export") }}
      </button>
      <button
        v-else
        class="bttn bttn--lg bttn--orange"
        type="button"
        name="button"
        :disabled="loading || error"
        @click="download"
      >
        {{ $t("dashboard.export_teams") }}
      </button>
    </div>

    <error-message :response.sync="error"></error-message>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import urls from "@/constants/urls.js";
import Multiselect from "vue-multiselect";
import errorMessage from "@/components/error-message.vue";
import MultiselectSearch from "@/components/multiselect-search.vue";

export default {
  data: function () {
    return {
      cid: 0,
      options: null,
      startExport: false,
      parentTeam: null,
      loading: true,
      error: null,
      exportLink: null,
      importFormat: null,
      includeChildren: null,

      login: null,
      logins: [],
    };
  },

  components: {
    errorMessage,
    MultiselectSearch,
    Multiselect,
  },

  computed: {
    ...mapState({
      campaign: (state) => state.campaign.campaign.data,

      teamList: (state) => {
        let arrTeams = state.teams.allTeams;

        let resultArr = arrTeams.map((el) => ({
          id: el.id,
          text: `[${el.id}] ${el.attributes.name}`,
        }));

        return resultArr;
      },
    }),

    params() {
      return {
        ...(this.options && this.options.params),
        parent_team_id: this.parentTeam && this.parentTeam.id,
        import_format: this.importFormat,
        include_children: this.includeChildren,
        login_user_id: this.login?.user_id,
      };
    },

    orgId() {
      return localStorage.getItem("orgId");
    },

    getSearchUrl() {
      const cID = this.campaign?.id;
      const orgID = localStorage.getItem("orgId");

      if (Number(cID) === 0) {
        throw new Error("getSearchUrl export teams campaign id is 0");
      }

      return urls.apiTeams.replace(":orgId", orgID).replace(":campaignId", cID);
    },

    searchOptions() {
      return {
        placeholder: this.$t("dashboard.select_parent_team"),
        changeParams: ({ query, page, per_page: pp }) => ({
          q: query,
          page: page,
          limit: pp,
          with_children_only: 1,
        }),
        customLabel: (e) => `[${e.id}] ${e.name}`,
        processResults: (data) =>
          data.map((el) => ({
            id: el.id,
            ...el.attributes,
          })),
      };
    },
  },

  methods: {
    ...mapActions({
      getLoginsToTeamsUsers: "subAccounts/getLoginsToTeamsUsers",
    }),

    clearData() {
      this.loading = true;
      this.startExport = false;
      this.error = null;
      this.exportLink = null;
      this.importFormat = null;
      this.includeChildren = null;
      this.login = null;
      this.logins = [];
    },

    openModal(id, { params } = {}) {
      this.cid = id;
      this.options = { params };

      if (params && params.parent_team_id) {
        this.parentTeam = this.teamList.find((el) => Number(el.id) === params.parent_team_id);
      }

      this.getLoginsToTeamsUsers({
        oid: this.orgId,
        cid: this.cid,
      }).then(({ data }) => {
        this.logins = data;
      });

      this.$root.$emit("bv::show::modal", "exportTeams");
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "exportTeams");
    },

    download() {
      const teamsExportLink = document.createElement("a");
      teamsExportLink.href = `${this.exportLink}`;
      teamsExportLink.download = `teams.csv`;
      document.body.appendChild(teamsExportLink);
      teamsExportLink.click();
      teamsExportLink.remove();
      this.hideModal();
    },

    exportTeams() {
      this.startExport = true;

      this.loadingExportFileTeams(this.cid, this.params);
    },

    loadingExportFileTeams(id, params) {
      this.loading = true;
      this.error = null;

      axios
        .post(
          urls.exportTeams.replace(":orgId", this.orgId).replace(":campaignId", id),
          {},
          { params }
        )
        .then(({ data }) => {
          const procID = Number(data.proc_session_id);

          const interval = setInterval(() => {
            axios
              .get(urls.procProgressTracker.replace(":oid", this.orgId).replace(":id", procID))
              .then(({ data }) => {
                if (data.data.attributes.finished) {
                  clearInterval(interval);

                  if (![null, ""].includes(data.data.attributes.meta)) {
                    const meta = JSON.parse(data.data.attributes.meta);
                    this.exportLink = meta.file;
                    this.loading = false;
                  }
                }
              })
              .catch((e) => {
                this.error = e.response;
                clearInterval(interval);
              });
          }, 2000);
        })
        .catch((e) => {
          this.error = e.response;
        });
    },
  },
};
</script>

<style lang="css"></style>
