<template>
  <div class="donation-plate-wrap">
    <slot name="top-list" />
    <div class="donation-plate" v-if="!isLoading">
      <div class="donation-plate__cell">{{ donation.date }}</div>
      <div class="donation-plate__cell">
        {{ donation.organizationName }}
      </div>
      <div class="donation-plate__cell">
        <a :href="donation.campaignShortLink" target="_blank">
          {{ donation.campaign }}
        </a>
      </div>
      <div class="donation-plate__cell">
        {{ toCurrencySign(donation.chargedCurrency) }}
        {{ toCurrency(donation.amount) }}
      </div>
      <div class="donation-plate__cell">
        {{ donation.currencySign }}
        {{ toCurrency(donation.amountEffective) }}
      </div>
      <div class="donation-plate__cell">
        <b-dropdown
          v-if="hasActions"
          variant="link"
          toggle-class="text-decoration-none"
          class="receipt"
          no-caret
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical"></b-icon>
            <span class="sr-only">Action</span>
          </template>
          <b-dropdown-item @click="editPublicData">
            <b-icon-pencil />
            <span class="px-2">
              {{ $t("donor_dashboard.edit_public_data", "Edit") }}
            </span>
          </b-dropdown-item>
          <b-dropdown-item v-if="hasOrgContacts" @click="showOrgContacts">
            <b-icon-headset />
            <span class="px-2">
              {{ $t("donor_dashboard.org_contact_information", "Organization contacts") }}
            </span>
          </b-dropdown-item>
          <b-dropdown-item @click="downloadReceipt">
            <b-icon-card-heading />
            <span class="px-2">
              {{ $t("donor_dashboard.donation_receipt", "Receipt") }}
            </span>
          </b-dropdown-item>
          <b-dropdown-item v-if="isStripeSub" @click="updatePaymentDetails">
            <b-icon-credit-card />
            <span class="px-2">
              {{ $t("donor_dashboard.update_payment_details", "Update payment details") }}
            </span>
          </b-dropdown-item>
          <b-dropdown-item v-if="isStripeSub" @click="cancelSubscription">
            <b-iconstack font-scale="2">
              <b-icon stacked icon="currency-dollar" scale="0.6"></b-icon>
              <b-icon stacked icon="arrow-clockwise"></b-icon>
              <b-icon
                stacked
                icon="x"
                variant="danger"
                scale="0.8"
                shift-v="5.5"
                shift-h="5"
              ></b-icon>
            </b-iconstack>
            <span class="px-2">
              {{ $t("donor_dashboard.cancel_subscription", "Cancel subscription") }}
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <slot name="sub-list" />
  </div>
</template>

<script>
import { BIconCardHeading } from "bootstrap-vue";
import urls from "@/constants/urls";
import axios from "axios";
import { notificationsMixin } from "@/mixins";

export default {
  props: ["donation", "isLoading"],
  components: {
    BIconCardHeading,
  },
  mixins: [notificationsMixin],
  computed: {
    isStripeSub() {
      const { bank_name, recurring_period } = this.donation;
      return bank_name === "stripe" && recurring_period > 0;
    },
    hasActions() {
      const { donationReceiptId } = this.donation;
      return donationReceiptId >= 0 || this.isStripeSub || this.hasOrgContacts;
    },
    orgAttr() {
      return this.donation.organizationAttr;
    },
    hasOrgContacts() {
      const { public_email, public_phone } = this.orgAttr || {};
      return Boolean(public_email) || Boolean(public_phone);
    },
  },
  methods: {
    downloadReceipt() {
      this.$emit("download-receipt", this.donation);
    },
    updatePaymentDetails() {
      this.$emit("update-payment-details", this.donation);
    },
    cancelSubscription() {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to cancel the subscription?", {
          title: "Subscription cancel confirmation",
          size: "md",
          buttonSize: "md",
          okTitle: "Yes",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            axios
              .post(urls.subscriptionsCancel.replace(":did", this.donation.id))
              .then(({ data }) => {
                if (data.status === "ok") {
                  this.$_notificationsMixin_makeToast(
                    "Success",
                    this.$t("donor_dashboard.success_sub_cancel", "success")
                  );
                }
              })
              .catch(this.$_notificationsMixin_handleCatch);
          }
        });
    },
    editPublicData() {
      this.$emit("edit-public-data", this.donation);
    },
    showOrgContacts() {
      this.$emit("show-org-contacts", this.orgAttr);
    },
    toCurrencySign(value) {
      let sing = "";
      if (!value) {
        return sing;
      }
      switch (value.toUpperCase()) {
        case "USD":
          sing = "$";
          break;
        case "GBP":
          sing = "£";
          break;
        case "AUD":
          sing = "$";
          break;
        case "ILS":
          sing = "₪";
          break;
      }
      return sing;
    },
    toCurrency(value) {
      if (!value) {
        value = 0;
      }
      return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_vars.scss";
.donation-container {
  box-shadow: 0 8px 32px 0 rgba(35, 40, 71, 0.08);
  background-color: #ffffff;
  color: $fd;
  h4 {
    font: 26px/1.2 "VitoExtended-Bold";
    margin-bottom: 0;
  }
  .bttn--orange {
    background-color: #ff5555;
    &:hover {
      opacity: 0.85;
    }
  }
  .donation-title {
    padding-bottom: 20px;
    border-bottom: 1px solid #e6eaed;
  }
  .badge {
    font: 14px/1.2 "VitoExtended-Bold";
    padding: 10px 10px;
    text-align: center;
    min-width: 61px;
    color: #33dbb1;
    background: #def9f2;
    border-radius: 19px;
    margin-right: 12px;
    &.badge-empty {
      color: #ffbe55;
      background: #ffbe5529;
    }
  }
  .label {
    display: inline-block;
    padding: 10px 19px;
  }
}
.receipt {
  font-size: 14px;
  padding: 10px 10px 10px 10px;
  text-align: center;
  min-width: 61px;
  color: #107598;
  border-radius: 19px;
  text-transform: uppercase;
  border: none;
  margin-top: -10px;
  background: #eef5fa;
  outline: none;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
    background-color: #dee4eb;
    cursor: auto;
  }
  &:focus {
    outline: none;
  }
}
.donation-plate {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  align-items: center;
  .donation-plate__cell {
    padding: 20px 7px;
    font-size: 16px;
    vertical-align: middle;
    letter-spacing: -0.6px;
  }
  a {
    color: #107598;
  }
  .blue {
    color: #107598;
  }
}

.donation-plate-wrap {
  border-bottom: 1px solid #88bacc;
}

.donation-plate__wrapper:not(:last-child) .donation-plate {
  &:after {
    position: absolute;
    left: 20px;
    right: 20px;
    height: 1px;
    background: #e6eaed;
    content: "";
    bottom: 0;
  }
}
</style>
