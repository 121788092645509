<template>
  <div class="color-container">
    <div class="color-basic">
      <h4 class="color-basic__title">
        {{ $t("edit_campaign.color-tool_intro_title_1", "Please choose colors for your campaign") }}
      </h4>
      <campaign-color-basic />
    </div>
    <div v-if="permission" class="color-advance">
      <h4 class="color-advance__title">
        {{
          $t("edit_campaign.color-tool_intro_title_2", "or edit individual components on the page")
        }}
      </h4>
      <p class="color-advance__description">
        {{
          $t(
            "edit_campaign.color-tool_intro_desc",
            "With this feature, you can edit text(color, size, thickness), colors, and hide spefic components on the page. This feature is only available for the organization owner's account."
          )
        }}
      </p>
      <button class="bttn bttn--lg bttn--orange bttn--mw255" @click="openPage('live')">
        {{ $t("edit_campaign.color-tool_button_live", "Edit Live Mode") }}
      </button>
      <button class="bttn bttn--lg bttn--orange bttn--mw255" @click="openPage('countdown')">
        {{ $t("edit_campaign.color-tool_button_countdown", "Edit Countdown Mode") }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import urls from "@/constants/urls";
import CampaignColorBasic from "./color-tool/campaignColorBasic.vue";

export default {
  props: {},
  data() {
    return {
      permission: false,
    };
  },
  components: {
    CampaignColorBasic,
  },
  created() {
    this.checkPermission();
  },
  computed: {
    ...mapState({
      campaignShortLink: (state) => state.campaign.campaign.data.attributes.short_link,
      campaignId: (state) => state.campaign.campaign.data.id,
      orgId: (state) => state.account.orgId || localStorage.getItem("orgId"),
      campaignTemplate: (state) => state.campaign.campaign.data.attributes.template,
    }),
  },
  methods: {
    openPage(page) {
      let shortLinkURL;
      
      if (page === 'donation') {
        shortLinkURL = new URL(this.campaignId, "https://donate.charidy.com")
      } else {
        shortLinkURL = new URL(this.campaignShortLink, "https://charidy.com");
      }

      shortLinkURL.searchParams.set('custom', 'true');

      if (page === "live") {
        shortLinkURL.searchParams.set('viewmode', '2');
        shortLinkURL.searchParams.set('viewtemplate', this.campaignTemplate);
      } else if (page === "countdown") {
        shortLinkURL.searchParams.set('viewmode', '1');
        shortLinkURL.searchParams.set('viewtemplate', this.campaignTemplate);
      }

      this.getPreviewToken().then((token) => {
        if (token) {
          shortLinkURL.searchParams.set("token", token);
        }
        window.open(decodeURI(shortLinkURL.toString()), "_blank").focus();
      });
    },
    checkPermission() {
      let token = localStorage.getItem("jwt_token") || null;
      if (token) {
        axios
          .get(
            urls.checkColorToolToken
              .replace(":orgId", this.orgId)
              .replace(":campaignId", this.campaignId),
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then(({ data }) => {
            if (data.result === "ok") {
              this.permission = true;
            }
          })
          .catch(() => {
            this.permission = false;
          });
      }
    },
    getPreviewToken() {
      const orgID = localStorage.getItem("orgId");

      return axios
        .get(urls.getPreviewToken(orgID, this.campaignId))
        .then((resp) => resp.data.data.token);
    },
  },
};
</script>

<style lang="scss" scoped>
.color-container {
  margin: 0px 20px;
}
.color-basic,
.color-advance {
  margin-bottom: 40px;
  &__title {
    font-weight: bold;
  }
  &__description {
    margin-top: 20px;
    font-weight: bold;
  }
}
.color-guide {
  margin-bottom: 40px;
  &__title {
    font-weight: bold;
    margin-bottom: 20px;
  }
  &__options {
    margin: 5px;
    font-weight: bold;
  }
  &__carousel {
    margin-top: 20px;
  }
}
.color-examples {
  &__title {
    font-weight: bold;
  }
}
button {
  margin: 5px;
}
</style>
<style>
.color-guide__carousel .carousel-control-prev,
.color-guide__carousel .carousel-control-next {
  background: #107598;
  opacity: 0.03 !important;
}
.color-guide__carousel .carousel-control-prev:hover,
.color-guide__carousel .carousel-control-next:hover {
  opacity: 0.5 !important;
}
.color-guide__carousel .carousel-indicators > li {
  background-color: #107598 !important;
}
.color-guide__carousel .carousel-indicators {
  bottom: -50px;
}
</style>
