const params = {
  list: [],
  filterFunc: () => true,
  fallbackFilterFunc: () => true,
};

export const filterWithFallback = ({ list, filterFunc, fallbackFilterFunc } = params) => {
  let tempList = list.filter(filterFunc);

  if (tempList.length === 0 && fallbackFilterFunc) {
    tempList = list.filter(fallbackFilterFunc);
  }

  return tempList;
};

export function filterErrors(errors, observerErrors) {
  const normalizedObserverErrors = Object.values(observerErrors).flat();

  if (errors.length === 0 && observerErrors.length === 0) {
    return false;
  }

  return [...errors, ...normalizedObserverErrors];
}

export function singleLineString(strings, ...values) {
  // Interweave the strings with the
  // substitution vars first.
  let output = "";
  for (let i = 0; i < values.length; i++) {
    output += strings[i] + values[i];
  }
  output += strings[values.length];

  // Split on newlines.
  let lines = output.split(/(?:\r\n|\n|\r)/);

  // Rip out the leading whitespace.
  return lines
    .map((line) => {
      return line.replace(/^\s+/gm, "");
    })
    .join(" ")
    .trim();
}
