<template>
  <b-col cols="12">
    <label class="form-group__label"> Access control list </label>

    <b-table v-if="items.length" striped :fields="fields" :items="items" stacked="sm">
      <template #cell(campaign_ids)="{ value }">
        {{ value.length ? value : "All" }}
      </template>

      <template #cell(actions)="{ index }">
        <b-icon-trash-fill variant="danger" role="button" font-scale="1.2" @click="remove(index)" />
      </template>
    </b-table>

    <b-form v-if="aclItems.length" inline class="justify-content-between my-2 mx-n1">
      <b-col class="px-1 pb-2">
        <b-form-select v-model="form.action" :options="aclItems" class="w-100" />
      </b-col>

      <b-col v-if="aclConfig[form.action].campaign" cols="12" md="5" class="px-1 pb-2">
        <multiselect-search
          v-model="form.cmps"
          :open-prefetch="true"
          :search-url="getSearchUrl"
          :search-options="searchOptions"
          skip-external-serching
          multiple
          taggable
        />
      </b-col>

      <b-col cols="12" md="2" class="px-1 pb-2">
        <b-button variant="primary" block @click="add"> Add </b-button>
      </b-col>
    </b-form>
  </b-col>
</template>

<script>
import { aclItems, aclConfig } from "@/constants/acls.js";
import urls from "@/constants/urls";
import multiselectSearch from "./multiselect-search.vue";

const extendParams = ["campaign_stats", "media", "meta", "donation_levels", "donation_streams"];

export default {
  components: { multiselectSearch },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      form: {
        action: "",
        cmps: [],
      },
    };
  },

  computed: {
    aclConfig() {
      return aclConfig;
    },

    aclItems() {
      const list = aclItems.filter((el) => !this.usedACL.includes(el));

      if (list.length) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.$set(this.form, "action", list[0]);
      }

      return list;
    },

    usedACL() {
      const val = this.value || [];

      return val.map((el) => `${el.action}`);
    },

    fields() {
      return [
        {
          key: "action",
          label: "Action",
        },
        {
          key: "campaign_ids",
          label: "Campaigns",
        },
        {
          key: "actions",
          label: "",
        },
      ];
    },

    items() {
      const val = this.value || [];

      return val;
    },

    getSearchUrl() {
      const orgID = localStorage.getItem("orgId");

      return urls.getCampaignList.replace(":orgId", orgID);
    },

    searchOptions() {
      return {
        placeholder: "Select campaigns (All)",
        changeParams: ({ page, per_page: pp }) => ({
          page: page,
          perPage: pp,
          extend: extendParams,
        }),
        customLabel: (e) => `[${e.id}] ${e.attributes.title}`,
      };
    },
  },

  methods: {
    add() {
      const val = JSON.parse(JSON.stringify(this.value || []));

      val.push({
        action: this.form.action,
        campaign_ids: this.form.cmps.map((c) => Number(c.id)),
      });
      this.resetForm();

      this.$emit("input", val);
    },

    remove(i) {
      const val = JSON.parse(JSON.stringify(this.value || []));

      val.splice(i, 1);

      this.$emit("input", val);
    },

    resetForm() {
      this.form = {
        action: "",
        cmps: [],
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
