<script>
export default {
  props: {
    loading: Boolean,
  },
};
</script>

<template>
  <div class="upload-bar">
    <button id="refresh_image" :disabled="loading" @click="$emit('click', $event)" />
    <label for="refresh_image" class="upload-bar__label">
      <b-spinner v-if="loading" label="trying to refresh images..." />
      <b-icon-box-arrow-up v-else />
      <span class="mobile-desk">{{
        $t("edit_campaign.refresh_image_label", "Refresh share image")
      }}</span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.upload-bar {
  width: 100%;
  margin-bottom: 10px;
  max-width: 280px;
  height: 60px;
  display: flex;
  justify-content: center;
  border: 1px solid #bbbcc4;
  border-radius: 5px;
  background-color: #f5faff;
  position: relative;
  > input,
  > button {
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__label {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    svg {
      display: block;
      height: 24px;
      width: auto;
      margin-right: 10px;
    }
    span:not(.spinner-border) {
      width: 60%;
      text-transform: uppercase;
      line-height: 1.125;
    }
  }
}
</style>
