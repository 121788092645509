<template>
  <div>
    <slot v-if="isMetaFeatureNameIsArray" v-bind="metaByProp" />
    <slot v-else :meta="metaByProp" />
  </div>
</template>

<script>
import { isArray } from "@/helpers/inspect";

export default {
  props: {
    metaFeatureName: {
      type: [String, Array],
      required: true,
    },
    metas: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isMetaFeatureNameIsArray() {
      return isArray(this.metaFeatureName);
    },
    metaByProp() {
      if (this.isMetaFeatureNameIsArray) {
        return this.metaFeatureName.map((m) => {
          const meta = this.metas[m];
          if (meta) {
            return {
              [m]: {
                value: meta?.value || false,
                ...meta,
              },
            };
          }
          return {
            [m]: {
              value: false,
            },
          };
        });
      }

      const meta = this.metas[this.metaFeatureName] || {};
      return {
        value: meta?.value || false,
        ...meta,
      };
    },
  },
};
</script>
