<template lang="html">
  <div class="campaigns-list">
    <div class="category-title">
      <h1 class="h1-like">Packages</h1>
      <packages-add></packages-add>
    </div>

    <div class="current-campaigns">
      <div class="campaigns-wrapper">
        <packages-card
          v-for="(campaign, index) in currentCampaigns"
          :campaigndata="campaign"
          :key="index"
        >
        </packages-card>
      </div>
    </div>
    <div class="current-campaigns">
      <h2 class="h2-subcat">
        {{ $t("campaigns.past_campaigns") }}
      </h2>
      <div class="campaigns-wrapper">
        <packages-card
          v-for="(campaign, index) in pastCampaigns"
          :campaigndata="campaign"
          :key="index"
        >
        </packages-card>
      </div>
    </div>

    <div class="empty">
      {{ $t("gateways.not_have_gateways") }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import packagesCard from "./subcomponents/packagesPlate.vue";
import packagesAdd from "./subcomponents/packages-add.vue";

export default {
  data() {
    return {
      showModalCampaignAdd: false,
      setIntervalData: null,
    };
  },
  components: {
    "packages-card": packagesCard,
    "packages-add": packagesAdd,
  },

  watch: {
    bluredTab: function () {
      if (!this.bluredTab) {
        this.$store.dispatch("updateCampaigns");
      }
    },
  },

  computed: mapState({
    loading: (state) => {
      return state.campaigns.loadingCampaigns;
    },

    currentCampaigns: function () {
      return this.$store.getters.getCurrentCampaigns;
    },

    pastCampaigns: function () {
      return this.$store.getters.getPastCampaigns;
    },

    bluredTab: (state) => {
      return state.account.blured;
    },
  }),

  methods: {},
};
</script>

<style lang="scss" scoped>
.campaigns-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.top-campaigns-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.current-campaigns {
  margin-bottom: 20px;
  .h2-subcat {
    margin-bottom: 20px;
  }
}
</style>
