<script>
import { campaignStatusMixin, campaignStatusMixinProps } from "./campaign-status-mixin";

export default {
  props: {
    ...campaignStatusMixinProps,
  },
  mixins: [campaignStatusMixin],
};
</script>

<template>
  <div class="campaign-status">
    <div class="campaign-status__data">
      <div>
        <div class="campaign-status__title">
          {{ $t("edit_campaign.generalinfo_finish_date", "Accept donations until:") }}
        </div>
        <div class="campaign-status__datetime">
          {{ endDate }}
        </div>
      </div>
      <div>
        <div class="campaign-status__title">
          {{
            $t(
              "edit_campaign.generalinfo_display_campaign_end_date",
              "Display campaign end date (countdown):"
            )
          }}
        </div>
        <div class="campaign-status__datetime">
          {{ displayCampaignEndDate }}
        </div>
      </div>
      <div class="campaign-status__type" v-bind:class="stateClass">
        {{ campaignState }}
      </div>
    </div>

    <div v-if="campaignState === 'pending'" class="campaign-status__description">
      {{ $t("campaigns.pending") }}
    </div>

    <div v-if="campaignState === 'live'" class="campaign-status__description">
      {{ $t("campaigns.live") }}
    </div>

    <div v-if="campaignState === 'failed'" class="campaign-status__description">
      {{ $t("campaigns.failed") }}
    </div>

    <div v-if="campaignState === 'countdown'" class="campaign-status__description">
      {{ $t("campaigns.countdown") }}
    </div>

    <div v-if="campaignState === 'success'" class="campaign-status__description">
      {{ $t("campaigns.success") }}
    </div>

    <div v-if="campaignState === 'demo'" class="campaign-status__description">
      {{ $t("campaigns.demo") }}
    </div>

    <div v-if="campaignState === 'wizard'" class="campaign-status__description">
      {{ $t("campaigns.currentlyEditedInWizard", "Editing in Wizard") }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.campaign-status {
  &__title {
    width: 100%;
    font-family: $vito-bold;
    font-size: 12px;
    letter-spacing: 1px;
    color: $fl;
  }
  &__data {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: $fd;
    line-height: 1.2;
    @media only screen and (min-width: 468px) {
      font-size: 16px;
    }
  }
  &__type {
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-size: 12px;
    text-transform: capitalize;
    @media only screen and (min-width: 468px) {
      font-size: 14px;
    }
  }
  &__type--active {
    color: #ffffff;
    background-color: $lr-100;
  }
  &__type--success {
    color: #ffffff;
    background-color: #ffbe55;
  }
  &__type--wizard {
    color: #ffffff;
    background-color: var(--info);
  }
  &__description {
    font-size: 14px;
    color: $fl;
  }
}
</style>
