<template>
  <div class="form-group">
    <div class="float-label" :class="{ 'is-active': groupName.length }">
      <input
        type="text"
        class="form-control float-label__input"
        placeholder="Group"
        v-model="groupName"
      />

      <label class="float-label__label" for="">Group Name</label>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      groupName: "",
    };
  },
};
</script>

<style lang="scss" scoped></style>
