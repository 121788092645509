const onlyPositive = {
  lazy: true,
  handler: (value) => Math.abs(value),
};

export default {
  title: "RecurringPayments",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
      expanded: true,
    },
    installment_payments: {
      title: "Installment",
      type: "boolean",
      default: false,
      expanded: true,
      showKey: true,
    },
    installments_number: {
      $if(form) {
        return form?.installment_payments || false;
      },
      title: "Installments duration",
      type: "number",
      default: 0,
      expanded: true,
      showKey: true,
      formatter: onlyPositive,
    },
    min_amount: {
      title: "Min Amount",
      type: "number",
      default: 1,
      min: 1,
      expanded: true,
      showKey: true,
      formatter: onlyPositive,
    },
    available_duration: {
      title: "Available Duration",
      type: "array",
      inputType: "number",
      default: [],
      expanded: true,
      showKey: true,
    },
    duration: {
      title: "Duration",
      type: "string",
      default: "12M",
      expanded: true,
      formatter: {
        lazy: true,
        handler(value) {
          let num = parseInt(value, 10);

          if (isNaN(num) || num == 0) num = 1;

          num = String(Math.abs(num));

          return `${num}M`;
        },
      },
      showKey: true,
    },
    checked_by_default: {
      title: "Checked By Default",
      type: "boolean",
      default: false,
      expanded: true,
      showKey: true,
    },
  },
};
