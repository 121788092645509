export default {
  title: "NotifyOrgAboutDonationsAbove",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
    },
    amount: {
      title: "Amount",
      type: "number",
    },
    amount_threshold_per_currency: {
      title: "Amount Threshold Per Currency",
      type: "list",
      default: [],
      expanded: true,
      layout: "expanded",
      items: {
        type: "object",
        properties: {
          currency: {
            title: "Currency Code",
            type: "string",
            default: "usd",
          },
          amount_from: {
            title: "Amount From",
            type: "number",
            default: 0,
          },
          amount_to: {
            title: "Amount To",
            type: "number",
            default: 0,
          },
        },
      },
    },
  },
};
