import urls from "../../constants/urls.js";
import axios from "axios";

const state = {
  payouts: [],
  loadingPayouts: true,
};

const mutations = {
  setPayouts(state, data) {
    state.payouts = data;
  },

  setLoadingPayouts(state, value) {
    state.loadingPayouts = value;
  },
};

const actions = {
  getPayouts({ commit }) {
    let orgId = localStorage.getItem("orgId");
    axios.get(urls.payouts.replace(":orgId", orgId)).then((response) => {
      let data = response.data.data;
      let payouts = [];

      data.forEach((el) => {
        let payout = el.attributes.payout_list.map((p) => {
          p.gateway = el.attributes.gateway;
          return p;
        });
        payouts = payouts.concat(payout);
      });

      commit("setPayouts", payouts);
      commit("setLoadingPayouts", false);
    });
  },
};

export default {
  state,
  actions,
  mutations,
};
