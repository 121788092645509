import Quill from "quill";
const Link = Quill.import("formats/link");

export class LinkWithTarget extends Link {
  static create(value) {
    const node = super.create(value);
    value = this.sanitize(value);
    node.setAttribute("href", value);
    try {
      const { searchParams } = new URL(value);
      const target = searchParams.get("target");
      if (target) {
        node.setAttribute("target", target);
      }
    } catch {
      console.log(`invalid URL: ${value}`);
    }
    return node;
  }
}
