export const aclConfig = {
  CanSeeTrainingVideos: {
    campaign: true,
    team: true,
  },
  CanSeeDesignTemplates: {
    campaign: false,
    team: false,
  },
  CanSubmitDesignTemplatesRequest: {
    campaign: false,
    team: false,
  },
  CanEditCampaignContent: {
    campaign: false,
    team: false,
  },
  CanSeeCampaignDetails: {
    campaign: true,
    team: true,
  },
  CanEditCampaignDetails: {
    campaign: false,
    team: false,
  },
  CanCreateCampaign: {
    campaign: false,
    team: false,
  },
  CanEditCampaignMedia: {
    campaign: false,
    team: false,
  },
  CanEditCampaignMatchers: {
    campaign: false,
    team: false,
  },
  CanEditCampaignDonationLevels: {
    campaign: true,
    team: true,
  },
  CanSeeCampaignTeams: {
    campaign: true,
    team: true,
  },
  CanAddCampaignTeams: {
    campaign: true,
    team: false,
  },
  CanEditCampaignTeams: {
    campaign: true,
    team: true,
  },
  CanDeleteAllCampaignTeams: {
    campaign: false,
    team: false,
  },
  CanSeeCampaignRemindList: {
    campaign: true,
    team: true,
  },
  CanSeeCampaignList: {
    campaign: true,
    team: true,
  },
  CanSeeCampaignDashboard: {
    campaign: true,
    team: true,
  },
  CanSeeCampaignDonationList: {
    campaign: true,
    team: true,
  },
  CanExportCampaignDonationList: {
    campaign: true,
    team: false,
  },
  CreateOfflineCampaignDonation: {
    campaign: true,
    team: false,
  },
  CanImportOfflineCampaignDonation: {
    campaign: true,
    team: false,
  },
  CanSeeOrganizationContactList: {
    campaign: false,
    team: false,
  },
  CanEditOrganizationContactList: {
    campaign: false,
    team: false,
  },
  CanSeeOrganizationEntityList: {
    campaign: false,
    team: false,
  },
  CanEditOrganizationEntityList: {
    campaign: false,
    team: false,
  },
  CanSeeOrganizationGatewayList: {
    campaign: true,
    team: true,
  },
  CanEditOrganizationGatewayList: {
    campaign: false,
    team: false,
  },
  CanSeeAndEditOrganizationIntegrationList: {
    campaign: false,
    team: false,
  },
  CanEditOrganizationDetail: {
    campaign: false,
    team: false,
  },
  CanSeeOrganizationDetail: {
    campaign: true,
    team: true,
  },
  CanSeeAllOrgDonationList: {
    campaign: true,
    team: true,
  },
  CanExportAllOrgDonationList: {
    campaign: false,
    team: false,
  },
  CanManageOtherAccountAccess: {
    campaign: false,
    team: false,
  },
  CanPerformDonationSearch: {
    campaign: true,
    team: true,
  },
  CanExportOrganizationReceiptList: {
    campaign: false,
    team: false,
  },
  CanEditCampaignUpdates: {
    campaign: false,
    team: false,
  },
  CanSeeCampaignUpdates: {
    campaign: false,
    team: false,
  },
};

export const aclItems = Object.keys(aclConfig);
