<template>
  <div class="email-stat">
    <div v-if="step === 1" class="email-stat__intro">
      <h2 class="email-stat__title" v-html="templateData.attributes.title"></h2>
      <p class="email-stat__text" v-html="templateData.attributes.subtitle"></p>
      <p class="email-stat__text" v-html="templateData.attributes.description"></p>

      <b-form-select v-model="selected" class="select-campaign">
        <option :value="null">
          {{ $t("dashboard.emails_select_campaign", "Please select campaign") }}
        </option>
        <option v-for="campaign in campaignsList" :key="campaign.id" :value="campaign.id">
          [{{ campaign.id }}] {{ campaign.attributes.title }}
        </option>
      </b-form-select>

      <button @click.prevent="templateView" class="bttn bttn--sm bttn--blue">
        {{ $t("dashboard.emails_preview", "Preview") }}
      </button>
      <div class="error-message" v-show="selected === null && campaignNotSelected">
        {{ $t("dashboard.emails_select_to_continue", "Please, select campaign to continue.") }}
      </div>
    </div>
    <div v-if="step === 2" class="email-template">
      <div class="email-template__header">
        <div class="email-template__title">
          {{ emailSubject }}
        </div>

        <div class="switch" :class="isHtml ? 'on' : ''">
          <div class="switch__label">{{ $t("dashboard.emails_preview_email", "Preview") }}</div>
          <div @click.prevent="htmlView" class="switch__inner">
            <div class="switch__button"></div>
          </div>
          <div class="switch__label">
            {{ $t("dashboard.emails_preview_email_as_html", "HTML") }}
          </div>
        </div>
      </div>

      <div v-if="!isHtml" class="email-template__preview">
        <span v-if="emailMarkup" v-html="emailMarkup" />
        <span v-else>
          {{ $t("dashboard.emails_template_loaded_here", "template loaded here ....") }}
        </span>
      </div>
      <div v-else class="raw-view-wrapper">
        <textarea v-model="emailMarkup" cols="30" rows="10" class="raw-view" readonly> </textarea>
      </div>

      <button class="bttn bttn--sm bttn--blue">
        {{ $t("dashboard.emails_send_email", "Send Email") }}
      </button>
    </div>
  </div>
</template>

<script>
import store from "../../store/store";
import { mapState } from "vuex";
import urls from "@/constants/urls";
import axios from "axios";

export default {
  props: {
    orgId: {
      type: Number,
      default: null,
    },
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      step: 1,
      isHtml: false,
      selected: null,
      emailMarkup: null,
      emailSubject: "",
      campaignNotSelected: false,
    };
  },
  created() {
    store.dispatch("getCampaigns");
  },
  watch: {
    selected: function (value) {
      if (value !== null) {
        this.campaignNotSelected = false;
      }
    },
  },
  computed: {
    ...mapState({
      campaignsList: (state) => state.campaigns.campaigns,
    }),
  },
  methods: {
    templateView() {
      if (this.selected === null) {
        this.campaignNotSelected = true;
        return;
      }
      let data = {
        data: {
          attributes: {
            campaign_id: Number(this.selected),
            parent_level: 1,
          },
        },
      };

      axios
        .post(
          urls.previewTemplate
            .replace(":orgId", this.orgId)
            .replace(":templateId", this.templateData.id),
          data
        )
        .then((res) => {
          this.emailMarkup = res.data.data.attributes.body;
          this.emailSubject = res.data.data.attributes.subject;
          this.step = 2;
        })
        .catch((err) => console.log(err));
    },

    htmlView() {
      this.isHtml = !this.isHtml;
    },

    resetStep() {
      this.step = 1;
      this.selected = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.email-stat {
  width: 100%;
  padding: 10px 15px 10px 25px;
  display: flex;
  &__title {
    margin-bottom: 20px;
    font-family: $vito-bold;
    font-size: 18px;
    color: $fd;
  }
  &__intro {
    display: flex;
    flex-direction: column;
    //align-items: center;
  }
  &__text {
    margin-bottom: 15px;
    font-family: $vito-light;
    font-size: 16px;
    color: $fd;
    > b {
      font-family: $vito-bold;
    }
    > span {
      color: $bb-100;
    }
    &:last-of-type {
      margin-bottom: 30px;
    }
  }
  .bttn {
    align-self: center;
  }
}

.select-campaign {
  margin-bottom: 30px;
}

.email-template {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    font-family: $vito-bold;
    font-size: 18px;
    color: $fd;
  }
  &__preview {
    margin-bottom: 30px;
    overflow-x: scroll;
  }
  .bttn {
    align-self: center;
  }
}

.error-message {
  padding-top: 20px;
  align-self: center;
  color: $lr-100;
  font-size: 16px;
}

.switch {
  $this: &;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 20;
  &.on {
    #{$this}__inner {
      border-color: $bc-60;
      background-color: $bc-60;
    }
    #{$this}__button {
      left: 26px;
    }
  }
  &__label {
    padding: 0 5px;
    font-family: $vito-bold;
    font-size: 12px;
    line-height: 1;
  }
  &__inner {
    width: 52px;
    height: 26px;
    border-radius: 13px;
    background-color: $fl;
    border: 1px solid $fl;
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  &__button {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #ffffff;
    transition: all 0.2s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.raw-view-wrapper {
  margin-bottom: 30px;
  border: 1px solid $bb-100;
}
.raw-view {
  width: 100%;
  min-height: 540px;
  border: none;
}

.he {
  .email-stat {
    &__title,
    &__intro,
    &__text {
      text-align: right;
    }
  }
  .switch {
    flex-direction: row-reverse;
  }
}
</style>
