import { getEndDateOfInterval, getOverlapedDateRange } from "./time";

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const isOutAvailableDatesRangeHelper = (config, form) => {
  if (config.available_dates?.end && form.interval && form.period) {
    const i = form.interval - 1;
    const nd = getEndDateOfInterval(form.start, form.period, i);

    return nd > new Date(config.available_dates.end);
  }

  return false;
};

export const isDateDisabled = (disabledDates, date) =>
  disabledDates.some((el) => {
    const odr = getOverlapedDateRange(el, { start: date, end: date });

    return odr.overlapped;
  });

export const computeDatesForPeriodItem = (el) => {
  const item = deepCopy(el);

  if (el.period !== "" && el.interval > 0) {
    for (let i = 0; i < el.dates.length; i++) {
      const dr = el.dates[i];

      item.dates = [];

      const list = Array(el.interval)
        .fill(dr)
        .map((d, j) => {
          const nd = getEndDateOfInterval(d.start, el.period, j);

          return nd;
        });

      item.dates.push(...list);
    }
  }

  return item;
};

export const getMonthYearLists = (s, e, m = [], y = []) => {
  const sd = new Date(s);
  const ed = new Date(e);

  const months = [...m];
  const years = [...y];

  while (sd.getTime() <= ed.getTime()) {
    const mn = sd.getMonth() + 1;
    const y = sd.getFullYear();

    if (!months.includes(mn)) {
      months.push(mn);
    }
    if (!years.includes(y)) {
      years.push(y);
    }

    sd.setMonth(mn);
  }

  return { months, years };
};
