<template>
  <div :id="`disabled_add_button${withReceipt && '_with_receipt'}`">
    <div v-if="hideAddBtn ? false : hasAccessAdd">
      <b-dropdown
        @click="openModal"
        :disabled="disabledButton || !hasAccessAdd"
        split
        split-variant="orange bttn bttn--sm bttn--orange"
        variant="orange bttn bttn--orange"
      >
        <template #button-content>
          {{
            withReceipt
              ? $t("dashboard.add_button_with_receipt_title", "Add offline donation with receipt")
              : $t("dashboard.add_button_title")
          }}
        </template>
        <b-dropdown-item :href="donateFormUrl" target="_blank">
          {{ $t("dashboard.open_button_title", "Open regular donation form") }}
        </b-dropdown-item>
        <b-dropdown-item-button @click="openModal({ addOfllineDonation: false })" target="_blank">
          {{
            $t(
              "dashboard.add_donation_to_existing_donor_button_title",
              "Add donation to existing donor"
            )
          }}
        </b-dropdown-item-button>
      </b-dropdown>
    </div>

    <b-tooltip
      target="disabled_add_button"
      v-if="disabledButton && !hasAccessAdd"
      :title="$t('dashboard.disabled_add_button')"
    ></b-tooltip>

    <!-- Modal Component -->
    <b-modal
      ref="addDonation"
      size="xl"
      content-class="modal-content-dashboard"
      header-class="modal-header-dashboard"
      @hidden="clearData"
      no-close-on-backdrop
    >
      <template #modal-header>
        <div
          class="modal-header-dashboard__title-content"
          :class="{ 'align-items-center': inlineSelectCampaign }"
        >
          <h2 class="w-50" v-if="addOfllineDonation">{{ $t("dashboard.add_modal_title") }}</h2>
          <h2 class="w-50" v-else>
            {{
              $t(
                "dashboard.add_donation_to_existing_donor_modal_title",
                "Add donation to existing donor"
              )
            }}
          </h2>

          <div class="w-50" v-if="inlineSelectCampaign">
            <SelectCampaign defaultStyles v-model="selectedCampaignId" />
          </div>
        </div>
        <div class="modal-header__hide" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </template>

      <div slot="modal-footer" class="justify-content-center">
        <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
          {{ $t("dashboard.donation_add_cancel") }}
        </button>
        <button
          v-if="!addOfllineDonation && customer"
          :disabled="customerLoading"
          class="bttn bttn--orange"
          @click="onSelectCustomer"
        >
          {{ $t("dashboard.select_customer_button_title", "Next (select customer to donate to)") }}
        </button>
        <button
          v-else-if="!addOfllineDonation && !customer"
          class="bttn bttn--orange"
          @click="onSelectNoCustomer"
        >
          {{ $t("dashboard.select_no_customer_button_title", "Proceed without customer") }}
        </button>
        <button
          v-if="addOfllineDonation"
          @click.prevent="submitForm"
          class="bttn bttn--lg bttn--orange"
          v-show="!sending"
          :disabled="sending || !selectedCampaignId || loadingCampaignData"
        >
          {{ $t("dashboard.add_donors_button_form") }}
        </button>
      </div>

      <div v-if="!addOfllineDonation" class="w-50 mx-auto">
        <label class="m-0">
          {{ $t("dashboard.add_to_existing_donor", "Select customer to donate to") }}
        </label>
        <select-customer @loading="customerLoading = $event" v-model="customer" class="my-2" />
      </div>

      <div v-if="addOfllineDonation">
        <info-alert :tKey="'dashboard.donation_info_html'" />

        <b-tabs content-class="m-0 p-0 mt-3" v-model="tabs" v-if="selectedCampaignId">
          <b-tab :title="$t('dashboard.tab_default_form')" active>
            <form id="add-donation" ref="addDonate" class="entities-data">
              <error v-if="errors" :errors="errors"></error>

              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group" :class="{ 'has-error': vErrors.has('fullName') }">
                    <label class="entities-data__label entities-data__label--required">
                      {{ $t("dashboard.add_donate_display_name") }}
                    </label>
                    <b-form-input
                      name="fullName"
                      v-validate="'required|min:3'"
                      v-model="addDonation.data.attributes.display_name"
                    >
                    </b-form-input>
                    <div class="form-help-grid">
                      <span v-show="vErrors.has('fullName')" class="v-help is-danger">
                        {{ $t("template.first_name_is_required") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div
                    class="form-group form-unit form-unit--addon"
                    :class="{ 'has-error': vErrors.has('amount') }"
                  >
                    <label class="entities-data__label entities-data__label--required">
                      {{ $t("dashboard.add_donate_charged_amount") }}
                    </label>
                    <div class="form-unit__addon-wrap">
                      <div class="form-unit__addon-icon">
                        <b-dropdown
                          id="ddown1"
                          :text="addDonation.data.attributes.processing_charged_currency"
                          class="dropdown"
                          :disabled="chargedAmountReadonly"
                        >
                          <b-dropdown-item
                            v-for="c in currencies"
                            :key="c"
                            @click="updateCurrency(c)"
                          >
                            {{ $t(`dashboard.currency_code_${c}`, c) }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>

                      <b-form-input
                        type="text"
                        class="form-unit__input"
                        name="amount"
                        autocomplete="off"
                        inputmode="numeric"
                        pattern="[0-9]*"
                        v-model.number="chargedAmountUser"
                        v-validate="'required|min_value:1'"
                        :readonly="chargedAmountReadonly"
                      >
                      </b-form-input>

                      <template
                        v-if="currency && addDonation.data.attributes.processing_charged_currency"
                      >
                        <div
                          class="convert-amount"
                          v-if="
                            addDonation.data.attributes.processing_charged_currency.toLowerCase() !=
                            currency.toLowerCase()
                          "
                        >
                          <label class="entities-data__label entities-data">
                            {{ currency }} {{ addDonation.data.attributes.charged_amount }}
                          </label>
                        </div>
                      </template>
                    </div>
                    <div
                      class="form-help-grid"
                      v-if="currency && addDonation.data.attributes.processing_charged_currency"
                    >
                      <span class="v-help">
                        {{
                          this.$t(
                            `dashboard.currency_code_${addDonation.data.attributes.processing_charged_currency.toLowerCase()}_description`
                          )
                        }}
                      </span>
                      <span v-show="vErrors.has('amount')" class="v-help is-danger">
                        {{ $t("template.amount_is_required") }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-6">
                  <div
                    class="form-group"
                    :class="{ 'has-error': vErrors.has('offline_donation_source') }"
                  >
                    <label
                      class="entities-data__label entities-data"
                      :class="requiredType ? 'entities-data__label--required' : ''"
                    >
                      {{ $t("donors.offline_donation_source") }}
                    </label>
                    <b-form-select
                      v-model="addDonation.data.attributes.offline_donation_source"
                      v-validate="{ required: requiredType }"
                      name="offline_donation_source"
                      :options="offlineDonationSourceOptions"
                    >
                    </b-form-select>
                    <div class="form-help-grid">
                      <span
                        v-show="vErrors.has('offline_donation_source')"
                        class="v-help is-danger"
                      >
                        {{ $t("template.type_is_required", "Field is required") }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <div
                    class="form-group"
                    :class="{ 'has-error': vErrors.has('offline_donation_note') }"
                  >
                    <label
                      class="entities-data__label entities-data"
                      :class="{
                        'entities-data__label--required':
                          offlineDonationOptions.offline_donation_note_required,
                      }"
                    >
                      {{ $t("donors.offline_donation_note") }}
                    </label>
                    <b-form-input
                      v-validate="{
                        required: offlineDonationOptions.offline_donation_note_required,
                      }"
                      name="offline_donation_note"
                      v-model="addDonation.data.attributes.offline_donation_note"
                    >
                    </b-form-input>
                    <div class="form-help-grid">
                      <span v-show="vErrors.has('offline_donation_note')" class="v-help is-danger">
                        {{
                          $t(
                            "template.offline_donation_note_is_required",
                            "Offline donation note is required"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-show="bankBranchAccountCanBeShowed">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="entities-data__label entities-data">
                      {{ $t("dashboard.add_donate_bank") }}
                    </label>
                    <b-form-input v-model="addDonation.data.attributes.bank"></b-form-input>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="entities-data__label entities-data">
                      {{ $t("dashboard.add_donate_branch") }}
                    </label>
                    <b-form-input v-model="addDonation.data.attributes.branch"></b-form-input>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="entities-data__label entities-data">
                      {{ $t("dashboard.add_donate_account") }}
                    </label>
                    <b-form-input v-model="addDonation.data.attributes.account"></b-form-input>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="entities-data__label entities-data">
                      {{ $t("dashboard.add_donate_check_n") }}
                    </label>
                    <b-form-input v-model="addDonation.data.attributes.check_n"></b-form-input>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group" :class="{ 'has-error': vErrors.has('email') }">
                    <label
                      class="entities-data__label"
                      :class="{
                        'entities-data__label--required': !offlineDonationOptions.email_optional,
                      }"
                    >
                      {{ $t("dashboard.add_donate_email") }}
                    </label>
                    <b-form-input
                      name="email"
                      v-validate="{
                        required: !offlineDonationOptions.email_optional,
                        email: true,
                      }"
                      v-model="addDonation.data.attributes.email"
                    >
                    </b-form-input>
                    <div class="form-help-grid">
                      <span v-show="vErrors.has('email')" class="v-help is-danger">
                        {{ $t("template.email_is_required") }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group form-unit form-unit--addon date-time-select">
                    <label class="entities-data__label">
                      {{ $t("dashboard.offline_donate_effective_date", "Effective date") }}
                    </label>
                    <div class="form-unit__addon-wrap">
                      <div class="form-unit__addon-icon">
                        <svg viewBox="0 0 24 22">
                          <g fill="none" fill-rule="evenodd" opacity="1">
                            <path
                              stroke="#107598"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4.64 1v3.64M19.18 1v3.64M1 2.82V21h14.55l7.27-7.27V2.82zM21.91 15.09v5h-5"
                            />
                            <path
                              fill="#107598"
                              fill-rule="nonzero"
                              d="M1 2.82V21h14.55l7.27-7.27V2.82H1zm6.36 14.54H5.55v-1.81h1.81v1.81zm0-3.63H5.55V11.9h1.81v1.82zm0-3.64H5.55V8.27h1.81v1.82zM11 17.36H9.18v-1.81H11v1.81zm0-3.63H9.18V11.9H11v1.82zm0-3.64H9.18V8.27H11v1.82zm3.64 7.27h-1.82v-1.81h1.82v1.81zm0-3.63h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82zm3.63 3.64h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82z"
                            />
                          </g>
                        </svg>
                      </div>

                      <datetime
                        input-id="effective-date"
                        type="datetime"
                        value-zone="UTC"
                        :zone="timeZone"
                        v-model="effectiveDate"
                        input-class="form-control form-unit__input"
                        title="Select effective date"
                      ></datetime>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6" v-if="totalTeams > 0">
                  <div class="form-group form-unit--multiselect">
                    <label class="entities-data__label entities-data">
                      {{ $t("dashboard.edit_donate_team") }}
                    </label>
                    <multiselect
                      v-model="multiselectTeam"
                      :options="teamList"
                      :custom-label="customLabel"
                      track-by="text"
                      :multiple="false"
                      :searchable="true"
                      :internal-search="false"
                      :select-label="$t('dashboard.team_select_label', 'Press enter to select')"
                      :deselect-label="$t('dashboard.team_deselect_label', 'Press enter to remove')"
                      :selected-label="$t('dashboard.team_selected_label', 'Selected')"
                      @search-change="teamFind"
                      :disabled="moreThanOneTeamDonation"
                    >
                    </multiselect>
                    <div class="form-help-grid">
                      <span v-show="moreThanOneTeamDonation" class="v-help is-warning">
                        {{
                          $t(
                            "template.team_donations_disabled_multiselect",
                            "Multiple teams selected!"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="entities-data__label">
                      {{ $t("dashboard.add_donate_dedication") }}
                    </label>
                    <b-form-input
                      v-model="addDonation.data.attributes.dedication"
                      :rows="2"
                    ></b-form-input>
                  </div>
                </div>
              </div>

              <ViewByMeta
                metaFeatureName="allow_conditional_donations"
                :metas="getMetasInSelectedCampaign"
                v-slot="{ meta: allowConditionalDonations }"
              >
                <div
                  class="row"
                  v-if="
                    allowConditionalDonations.value &&
                    addDonation.data.attributes.matched_donation_id === 0
                  "
                >
                  <div class="col-12">
                    <AllowConditionalDonations
                      :donationAmount="chargedAmountUser || 0"
                      :donationCurrencyCode="
                        addDonation.data.attributes.processing_charged_currency
                      "
                      :campaignCurrencyCode="ccy"
                      v-slot="{
                        internalValue,
                        internalLockedType,
                        internalValueInt,
                        hasThresholdsError,
                        onLockedChange,
                        onLockedVarIntInput,
                        thresholdChangeRateLoading,
                      }"
                      @setLocked="onLockedChange"
                      @setLockedType="onLockedTypeChange"
                      @setLockedVarInt="onLockedVarIntChange"
                      @deleteLockedVarInt="onDeleteLockedVarInt"
                    >
                      <PeerMatchControl
                        :internal-value="internalValue"
                        :internal-locked-type="internalLockedType"
                        :internal-value-int="internalValueInt"
                        :has-thresholds-error="hasThresholdsError"
                        :on-locked-change="onLockedChange"
                        :on-locked-var-int-input="onLockedVarIntInput"
                        :threshold-change-rate-loading="thresholdChangeRateLoading"
                      />
                      <TeamReachXControl
                        :internal-value="internalValue"
                        :internal-locked-type="internalLockedType"
                        :internal-value-int="internalValueInt"
                        :has-thresholds-error="hasThresholdsError"
                        :on-locked-change="onLockedChange"
                        :on-locked-var-int-input="onLockedVarIntInput"
                        :threshold-change-rate-loading="thresholdChangeRateLoading"
                        :donation-selected-team="multiselectTeam"
                      />
                    </AllowConditionalDonations>
                  </div>
                </div>
              </ViewByMeta>

              <div class="wrapper-options">
                <button
                  type="button"
                  @click="showOptionaly = !showOptionaly"
                  class="show-hide-bttn"
                  name="button"
                >
                  {{
                    !showOptionaly
                      ? $t("dashboard.add_donation_show_optionaly")
                      : $t("dashboard.add_donation_hide_optionaly")
                  }}
                  <i
                    :class="!showOptionaly ? 'fa fa-angle-down' : 'fa fa-angle-up'"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>

              <div class="optionally-params" v-show="showOptionaly">
                <div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.add_donate_billing_name") }}
                        </label>
                        <b-form-input
                          v-model="addDonation.data.attributes.billing_name"
                        ></b-form-input>
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.add_donate_billing_last_name") }}
                        </label>
                        <b-form-input
                          v-model="addDonation.data.attributes.billing_last_name"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group" :class="{ 'has-error': vErrors.has('phone') }">
                        <label
                          class="entities-data__label entities-data"
                          :class="{
                            'entities-data__label--required': offlineDonationOptions.phone_required,
                          }"
                        >
                          {{ $t("dashboard.add_donate_phone") }}
                        </label>
                        <b-form-input
                          v-validate="{
                            required: offlineDonationOptions.phone_required,
                          }"
                          name="phone"
                          v-model="addDonation.data.attributes.phone"
                        ></b-form-input>
                        <div class="form-help-grid">
                          <span v-show="vErrors.has('phone')" class="v-help is-danger">
                            {{ $t("template.phone_is_required", "Phone is required") }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div
                        class="form-group"
                        :class="{ 'has-error': vErrors.has('payment_country') }"
                      >
                        <label
                          class="entities-data__label entities-data"
                          :class="{
                            'entities-data__label--required':
                              addDonation.data.attributes.uk_gift_aid,
                          }"
                        >
                          {{ $t("dashboard.add_donate_country") }}
                        </label>
                        <b-form-select
                          v-model="addDonation.data.attributes.payment_country"
                          name="payment_country"
                          :required="addDonation.data.attributes.uk_gift_aid"
                          v-validate="addDonation.data.attributes.uk_gift_aid && 'required'"
                        >
                          <option
                            v-for="country in countryList"
                            :key="country.id"
                            :value="country.attributes.iso_code_2"
                          >
                            {{ country.attributes.name }}
                          </option>
                        </b-form-select>
                        <div class="form-help-grid">
                          <span v-show="vErrors.has('payment_country')" class="v-help is-danger">
                            {{ $t("template.type_is_required", "Field is required") }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group" :class="{ 'has-error': vErrors.has('payment_city') }">
                        <label
                          class="entities-data__label entities-data"
                          :class="{
                            'entities-data__label--required':
                              addDonation.data.attributes.uk_gift_aid,
                          }"
                        >
                          {{ $t("dashboard.add_donate_city") }}
                        </label>
                        <b-form-input
                          v-model="addDonation.data.attributes.payment_city"
                          name="payment_city"
                          :required="addDonation.data.attributes.uk_gift_aid"
                          v-validate="addDonation.data.attributes.uk_gift_aid && 'required'"
                        ></b-form-input>
                        <div class="form-help-grid">
                          <span v-show="vErrors.has('payment_city')" class="v-help is-danger">
                            {{ $t("template.type_is_required", "Field is required") }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div
                        class="form-group"
                        :class="{ 'has-error': vErrors.has('payment_address') }"
                      >
                        <label
                          class="entities-data__label entities-data"
                          :class="{
                            'entities-data__label--required':
                              addDonation.data.attributes.uk_gift_aid,
                          }"
                        >
                          {{ $t("dashboard.add_donate_address") }}
                        </label>
                        <b-form-input
                          v-model="addDonation.data.attributes.payment_address"
                          name="payment_address"
                          :required="addDonation.data.attributes.uk_gift_aid"
                          v-validate="addDonation.data.attributes.uk_gift_aid && 'required'"
                        ></b-form-input>
                        <div class="form-help-grid">
                          <span v-show="vErrors.has('payment_address')" class="v-help is-danger">
                            {{ $t("template.type_is_required", "Field is required") }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.add_donate_payment_address_2") }}
                        </label>
                        <b-form-input
                          v-model="addDonation.data.attributes.payment_address_2"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.add_donate_referrer") }}
                        </label>
                        <b-form-input
                          readonly
                          v-model="addDonation.data.attributes.referrer"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="row">
                        <div class="col-12 col-md-7">
                          <div
                            class="form-group"
                            :class="{ 'has-error': vErrors.has('payment_state') }"
                          >
                            <label
                              class="entities-data__label entities-data"
                              :class="{
                                'entities-data__label--required':
                                  addDonation.data.attributes.uk_gift_aid,
                              }"
                            >
                              {{ $t("dashboard.add_donate_payment_state") }}
                            </label>
                            <b-form-input
                              v-model="addDonation.data.attributes.payment_state"
                              name="payment_state"
                              :required="addDonation.data.attributes.uk_gift_aid"
                              v-validate="addDonation.data.attributes.uk_gift_aid && 'required'"
                            ></b-form-input>
                            <div class="form-help-grid">
                              <span v-show="vErrors.has('payment_state')" class="v-help is-danger">
                                {{ $t("template.type_is_required", "Field is required") }}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div class="col-12 col-md-5">
                          <div
                            class="form-group"
                            :class="{ 'has-error': vErrors.has('payment_postcode') }"
                          >
                            <label
                              class="entities-data__label entities-data"
                              :class="{
                                'entities-data__label--required':
                                  addDonation.data.attributes.uk_gift_aid,
                              }"
                            >
                              {{ $t("dashboard.edit_donate_postcode") }}
                            </label>
                            <b-form-input
                              v-model="addDonation.data.attributes.payment_postcode"
                              name="payment_postcode"
                              :required="addDonation.data.attributes.uk_gift_aid"
                              v-validate="addDonation.data.attributes.uk_gift_aid && 'required'"
                            ></b-form-input>
                            <div class="form-help-grid">
                              <span
                                v-show="vErrors.has('payment_postcode')"
                                class="v-help is-danger"
                              >
                                {{ $t("template.type_is_required", "Field is required") }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-md-6" v-if="stream.length > 0">
                      <div class="form-group form-unit--multiselect">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.edit_donate_stream", "Stream") }}
                        </label>
                        <multiselect
                          v-model="multiselectStream"
                          :options="stream"
                          :custom-label="(el) => `[${el.id}] ${el.title}`"
                          track-by="id"
                          :multiple="false"
                          :searchable="true"
                          :select-label="$t('dashboard.team_select_label', 'Press enter to select')"
                          :deselect-label="
                            $t('dashboard.team_deselect_label', 'Press enter to remove')
                          "
                          :selected-label="$t('dashboard.team_selected_label', 'Selected')"
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div v-else class="col-12 col-md-6">
                      <div class="form-group">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.add_donate_category") }}
                        </label>
                        <b-form-input v-model="addDonation.data.attributes.category"></b-form-input>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div v-if="entities.length > 1" class="col-12 col-md-6">
                      <div class="form-group form-unit--multiselect">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.edit_donate_entities") }}
                        </label>
                        <multiselect
                          v-model="multiselectEntity"
                          :options="entities"
                          :allow-empty="false"
                          :custom-label="customLabelEntity"
                          track-by="id"
                          placeholder="Select entity"
                          deselect-label=""
                        />
                      </div>
                    </div>

                    <div class="col-12 col-md-6 align-self-center checkbox-block">
                      <!-- https://app.clickup.com/t/346uy23?comment=90050000202418 -->
                      <!-- <div class="form-checkbox form-checkbox--orange no-height">
                      <input
                        class="form-checkbox__input"
                        type="checkbox"
                        id="send_confirmation_email"
                        v-model="addDonation.data.attributes.send_confirmation_email"
                      />
                      <label class="form-checkbox__label" for="send_confirmation_email">
                        {{ $t("dashboard.send_confirmation_email") }}
                      </label>
                    </div> -->

                      <div
                        class="form-checkbox form-checkbox--orange no-height"
                        :class="{ disabled: !sendReceiptEnabled }"
                      >
                        <input
                          class="form-checkbox__input"
                          type="checkbox"
                          id="send-email"
                          v-model="addDonation.data.attributes.send_receipt"
                          :disabled="!sendReceiptEnabled"
                        />
                        <label
                          ref="sendReceiptEnabledBtn"
                          class="form-checkbox__label"
                          for="send-email"
                        >
                          {{ $t("dashboard.send_receipt") }}
                        </label>
                      </div>
                      <b-form-group
                        v-if="addDonation.data.attributes.send_receipt"
                        label=""
                        v-slot="{ ariaDescribedby }"
                        class="mt-1"
                      >
                        <b-form-radio-group
                          v-model="
                            addDonation.data.attributes.send_receipt_without_pdf_receipt_attached
                          "
                          :aria-describedby="ariaDescribedby"
                        >
                          <b-form-radio :value="false">
                            {{
                              $t(
                                "dashboard.send_receipt_without_pdf_receipt_attached_false",
                                "(with pdf receipts attached)"
                              )
                            }}
                          </b-form-radio>
                          <b-form-radio :value="true">
                            {{
                              $t(
                                "dashboard.send_receipt_without_pdf_receipt_attached_true",
                                "(without pdf receipts attached)"
                              )
                            }}
                          </b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>

                      <b-popover
                        :target="() => !sendReceiptEnabled && $refs['sendReceiptEnabledBtn']"
                        triggers="hover"
                        placement="top"
                        variant="danger"
                      >
                        {{
                          $t(
                            "dashboard.add_donate_external_send_receipt_disabled",
                            "This functionality was disabled by meta"
                          )
                        }}
                      </b-popover>
                    </div>

                    <div class="col-12 col-md-6 align-self-center">
                      <div class="form-group form-unit--multiselect">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.edit_donate_tag", "Tag") }}
                        </label>
                        <b-form-select
                          v-model="addDonation.data.attributes.tag"
                          :options="tagOptions"
                        ></b-form-select>
                      </div>
                    </div>

                    <div class="col-12 col-md-6 align-self-center">
                      <div class="form-group">
                        <b-form-checkbox v-model="addDonation.data.attributes.uk_gift_aid">
                          {{ $t("dashboard.edit_donate_gift_aid", "Gift Aid (UK)") }}
                        </b-form-checkbox>
                      </div>
                    </div>

                    <div
                      v-if="pledgeWithNotificationToPay"
                      class="col-12 col-md-6 align-self-center"
                    >
                      <div class="form-group">
                        <b-form-checkbox
                          v-model="addDonation.data.attributes.pledge_with_notification_to_pay"
                        >
                          {{
                            $t(
                              "dashboard.edit_donate_pledge_with_notification_to_pay",
                              "Add new offline donation and send the donor email notification to complete donation"
                            )
                          }}
                        </b-form-checkbox>
                      </div>
                    </div>

                    <div class="col-12 col-md-6" v-if="levels.length > 0">
                      <div class="form-group form-unit--multiselect">
                        <label class="entities-data__label entities-data">
                          {{ $t("dashboard.edit_donate_level", "Level") }}
                        </label>
                        <multiselect
                          v-model="multiselectLevel"
                          :options="levels"
                          :custom-label="(el) => `[${el.amount} ${currency}] ${el.title}`"
                          track-by="id"
                          :multiple="false"
                          :searchable="true"
                          :select-label="$t('dashboard.team_select_label', 'Press enter to select')"
                          :deselect-label="
                            $t('dashboard.team_deselect_label', 'Press enter to remove')
                          "
                          :selected-label="$t('dashboard.team_selected_label', 'Selected')"
                        >
                        </multiselect>
                      </div>
                    </div>

                    <div
                      v-if="(journalAdActivated || ticketActivated) && !lookUpName"
                      class="col-12 col-md-6"
                    >
                      <div class="form-group">
                        <label class="entities-data__label entities-data">
                          {{
                            ticketActivated
                              ? $t("dashboard.add_donate_external_resource_id_ticket", "Ticket Id")
                              : $t("dashboard.add_donate_external_resource_id")
                          }}
                        </label>
                        <b-form-input v-model="moduleDataId"></b-form-input>
                      </div>
                    </div>

                    <look-up-name v-if="journalAdActivated && lookUpName" ref="lookUpName" />
                  </div>
                </div>
              </div>

              <error v-if="errors" :errors="errors"></error>
            </form>
          </b-tab>
          <b-tab :title="$t('dashboard.campaign_team_donation', 'Donation to the teams')">
            <b-overlay :show="loadingCampaignTeamDonations" variant="info">
              <CampaignTeamDonation
                :c-id="selectedCampaignId"
                :d-attrs="addDonation.data.attributes"
                :d-amount="chargedAmountUser"
                :d-charged-amount="chargedAmountUser"
                :d-currency-code="addDonation.data.attributes.currency_code"
                ref="CampaignTeamDonation"
                :hide="hideModal"
                :isValid.sync="teamDonationIsValid"
                @localDonationsChanged="localDonationsChanged"
              />
            </b-overlay>
          </b-tab>
        </b-tabs>

        <b-alert v-if="!selectedCampaignId" show class="mt-4" variant="warning">
          {{
            $t("dashboard.no_selected_campaign_error", "Please select any campaign to add donation")
          }}
        </b-alert>

        <b-alert v-if="!teamDonationIsValid" show class="mt-4" variant="warning">
          {{
            $t(
              "dashboard.donations_to_teams_error",
              "Validation error: Check please tab Donation to the teams"
            )
          }}
        </b-alert>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import moment from "moment";
import urls, { donateHost } from "@/constants/urls.js";
import error from "@/components/errors.vue";
import Multiselect from "vue-multiselect";
import debounce from "lodash/debounce";
import infoAlert from "@/views/gateways/subcomponents/infoAlert.vue";
import LookUpName from "./modules/LookUpName.vue";
import CampaignTeamDonation from "./campaignTeamDonation.vue";
import SelectCampaign from "./selectCampaign.vue";
import { getFavoriteCampaign } from "@/helpers/storage";
import { getOfflineDonationSourceOptions } from "./shared";
import SelectCustomer from "./selectCustomer.vue";
import AllowConditionalDonations from "@/components/conditional-donations/AllowConditionalDonations.vue";
import TeamReachXControl from "@/components/conditional-donations/TeamReachXControl.vue";
import PeerMatchControl from "@/components/conditional-donations/PeerMatchControl.vue";
import ViewByMeta from "@/views/campaigns/campaign-wizards/viewByMeta.vue";

export default {
  props: {
    campaignid: [String, Number],
    ccy: String,
    withReceipt: Boolean,
    hideAddBtn: Boolean,
    inlineSelectCampaign: Boolean,
  },

  data() {
    return {
      addDonation: {
        data: {
          attributes: {
            campaign_id: 0,
            display_name: "",
            billing_name: "",
            billing_last_name: "",
            currency_code: "",
            processing_charged_currency: "",
            phone: "",
            email: "",
            payment_country: "",
            payment_city: "",
            payment_address: "",
            payment_postcode: "",
            dedication: "",
            category: "",
            charged_amount: "",
            team_id: 0,
            stream_id: 0,
            legal_entity_id: 0,
            offline_donation_source: "",
            offline_donation_note: "",
            change_request_type: "",
            change_request_note: "",
            send_receipt: false,
            send_receipt_without_pdf_receipt_attached: false,
            pledge_with_notification_to_pay: false,
            send_confirmation_email: false,
            module_data_id: 0,
            bank: "",
            branch: "",
            account: "",
            get_data: "",
            effective_date: null,
            tag: "",
            uk_gift_aid: false,
            locked: false,
            locked_type: "",
            locked_var_int: undefined,
            matched_donation_id: 0,
          },
        },
      },
      errors: [],
      moduleDataId: "",
      multiselectTeam: null,
      multiselectLevel: null,
      multiselectStream: null,
      multiselectEntity: null,
      showOptionaly: false,
      sending: false,
      chargedAmountUser: null,
      chargedAmountReadonly: false,
      customer: null,
      chargeRequestType: [
        {
          text: this.$t("dashboard.none"),
          value: "",
        },
        {
          text: this.$t("dashboard.cancel_donation"),
          value: "cancel",
        },
        {
          text: this.$t("dashboard.cancel_refund"),
          value: "cancel_refund",
        },
      ],
      tagOptions: [
        { value: "", text: this.$t("dashboard.donation_no_tag", "No tag") },
        {
          value: "featured",
          text: this.$t("dashboard.donation_featured_tag", "Featured"),
        },
      ],
      offlineDonationSourceOptions: getOfflineDonationSourceOptions(this),
      teamDonationIsValid: true,
      tabs: 0,
      moreThanOneTeamDonation: false,
      loadingCampaignData: false,
      addOfllineDonation: false,
      customerLoading: false,
    };
  },

  components: {
    error,
    Multiselect,
    infoAlert,
    LookUpName,
    CampaignTeamDonation,
    SelectCampaign,
    SelectCustomer,
    AllowConditionalDonations,
    TeamReachXControl,
    PeerMatchControl,
    ViewByMeta,
  },

  watch: {
    multiselectTeam: function () {
      const ctdRef = this.$refs.CampaignTeamDonation;
      const tid = Number(this.multiselectTeam?.value) || 0;
      this.addDonation.data.attributes.team_id = tid;

      if (ctdRef) {
        const ctds = ctdRef.getLocalState();
        if (!tid && ctds.length === 1) {
          ctdRef.deleteLocal(0);
          return;
        }
        if (ctds.length === 0) {
          ctdRef.addLocal();
        }
        if (tid) {
          ctdRef.changeTeam({
            i: 0,
            team_id: tid,
          });
        }
      }
    },

    multiselectEntity(value) {
      if (value) {
        this.addDonation.data.attributes.legal_entity_id = parseInt(value.id);
      } else {
        this.addDonation.data.attributes.legal_entity_id = 0;
      }
    },

    multiselectStream(value) {
      if (value) {
        this.addDonation.data.attributes.category = value.key;
      } else {
        this.addDonation.data.attributes.category = "";
      }
    },

    chargedAmountUser: debounce(function () {
      this.convertCurrency();
    }, 500),

    entities(list) {
      this.multiselectEntity = list.find((e) => e.attributes.primary);
    },

    selectedCampaignId: {
      handler(v) {
        if (this.inlineSelectCampaign && v) {
          this.$store.commit("setSelectedCampaign", v);
        }
      },
    },
  },

  computed: {
    ...mapState({
      hasAccessAdd(state) {
        if (this.withReceipt) {
          return state.account.showCreateOfflineDonationWithReceiptBtn;
        }
        return state.account.access.CreateOfflineCampaignDonation;
      },

      totalTeams: (state) => {
        return Number(state.teams.totalTeams);
      },

      teamList: (state) => {
        let arrTeams = state.teams.teams;

        let resultArr = [];

        for (var i = 0; i < arrTeams.length; i++) {
          let objTeam = {
            value: arrTeams[i].id,
            text: arrTeams[i].attributes.name,
          };

          resultArr.push(objTeam);
        }
        return resultArr;
      },

      countryList: (state) => {
        return state.account.countries;
      },

      sendReceiptEnabled(state) {
        const { campaigns, includedMedia } = state.campaigns;
        const campaign = campaigns.filter((el) => el.id === this.selectedCampaignId)[0];
        const { data } = campaign?.relationships?.campaign_meta || { data: [] };
        const metas = {};

        data.forEach((el) => {
          const meta = includedMedia.find(
            (metaEl) => el.id === metaEl.id && el.type === metaEl.type
          );

          if (meta) metas[meta.attributes.name] = JSON.parse(meta.attributes.data);
        });

        if (metas.send_receipt_to_check_donation) return metas.send_receipt_to_check_donation.value;

        return true;
      },

      disabledButton: (state) => {
        return (
          state.dashboard.campaignDashboard?.data?.attributes?.mode != 1 &&
          state.dashboard.campaignDashboard?.data?.attributes?.mode != 2
        );
      },

      orgId: function () {
        return localStorage.getItem("orgId");
      },

      entities(state) {
        return state.account.entities;
      },

      journalAdActivated: (state) => state.journalAd.activated,
      ticketActivated: (state) => state.ticket.activated,
      timeZone: (state) => state.account.account.data.attributes.timezone,
      currencies: (state) =>
        state.account.currencyList?.map((el) => el.attributes.code.toLowerCase()) || [],

      loadingCampaignTeamDonations: (state) =>
        state.campaignTeamDonation.loading.campaignTeamDonation,

      levels: (state) => Object.values(state.campaign.levels),
      stream: (state) => Object.values(state.campaign.streams),
    }),

    ...mapGetters({
      getSelectedCampaign: "getSelectedCampaign",
      getMetasInSelectedCampaign: "getMetasInSelectedCampaign",
    }),

    donateFormUrl() {
      const params = new URLSearchParams("");
      if (this.customer) {
        const fname = `${this.customer.payment_firstname} ${this.customer.payment_lastname}`;
        if (this.customer.payment_firstname) {
          params.set("firstname", this.customer.payment_firstname);
        }
        if (this.customer.payment_lastname) {
          params.set("lastname", this.customer.payment_lastname);
        }
        if (fname.trim()) {
          params.set("fullname", fname);
          params.set("displayname", fname);
        }
        if (this.customer.payment_country) {
          params.set("countrycode", this.customer.payment_country);
        }
        if (this.customer.payment_city) {
          params.set("city", this.customer.payment_city);
        }
        if (this.customer.payment_postcode) {
          params.set("zip", this.customer.payment_postcode);
        }
        if (this.customer.payment_zone) {
          params.set("state", this.customer.payment_zone);
        }
        if (this.customer.payment_address_1) {
          params.set("address", this.customer.payment_address_1);
        }
        if (this.customer.payment_address_2) {
          params.set("address_number", this.customer.payment_address_2);
        }
        if (this.customer.phone) {
          params.set("phone", this.customer.phone);
        }
        if (this.customer.email) {
          params.set("email", this.customer.email);
        }
      }
      return `${donateHost}/${this.campaignid}?${params.toString()}`;
    },

    effectiveDate: {
      get() {
        const ddate = moment().tz(this.timeZone).startOf("hour").toISOString();
        const date = this.addDonation.data.attributes.effective_date;
        const fdate = moment(date * 1000).toISOString();

        return (date && fdate) || ddate;
      },
      set(val) {
        this.addDonation.data.attributes.effective_date = new Date(val).getTime() / 1000;
      },
    },

    currency() {
      if (this.inlineSelectCampaign) {
        return this.getSelectedCampaign?.attributes?.currency;
      }
      return this.ccy;
    },

    selectedCampaignId: {
      get() {
        return this.campaignid || this.$store.state.dashboard.selectedCampaign;
      },
      set(value) {
        this.$store.commit("setSelectedCampaign", value);
      },
    },

    requiredType() {
      const { value } = this.getMetasInSelectedCampaign.offline_donation_type_mandatory || {};

      if (value) {
        return value;
      }

      return false;
    },

    offlineDonationOptions() {
      const { value, email_optional, phone_required, offline_donation_note_required } =
        this.getMetasInSelectedCampaign.offline_donation || {};

      if (value) {
        return {
          email_optional,
          phone_required,
          offline_donation_note_required,
        };
      }

      return {
        email_optional: false,
        phone_required: false,
        offline_donation_note_required: false,
      };
    },

    lookUpName() {
      return (
        this.getMetasInSelectedCampaign?.["custom_donate_button_preform"]?.form === "lookup_name"
      );
    },

    pledgeWithNotificationToPay() {
      const { value } = this.getMetasInSelectedCampaign.pledge_with_notification_to_pay || {};

      return value || false;
    },

    bankBranchAccountCanBeShowed() {
      const allowlist = [
        "credit_card",
        "check",
        "bank_transfer",
        "recurring_credit_card",
        "recurring_bank_transfer",
      ];
      return allowlist.some((v) => v === this.addDonation.data.attributes.offline_donation_source);
    },
  },

  methods: {
    onLockedChange(value) {
      this.addDonation.data.attributes.locked = value;
    },
    onLockedTypeChange(value) {
      this.addDonation.data.attributes.locked_type = value;
    },
    onLockedVarIntChange(value) {
      this.addDonation.data.attributes.locked_var_int = value;
    },
    onDeleteLockedVarInt() {
      delete this.addDonation.data.attributes.locked_var_int;
    },

    onSelectNoCustomer() {
      this.addOfllineDonation = true;
    },
    onSelectCustomer() {
      if (this.customer) {
        const data = {
          display_name: `${this.customer.payment_firstname} ${this.customer.payment_lastname}`,
          billing_name: this.customer.payment_firstname,
          billing_last_name: this.customer.payment_lastname,
          customer_id: this.customer.customer_id,
          email: this.customer.email,
          payment_address: this.customer.payment_address_1,
          payment_address_2: this.customer.payment_address_2,
          payment_country: this.customer.payment_country,
          payment_city: this.customer.payment_city,
          payment_postcode: this.customer.payment_postcode,
          payment_state: this.customer.payment_zone,
          phone: this.customer.phone,
        };
        this.addDonation.data.attributes = {
          ...this.addDonation.data.attributes,
          ...data,
        };
        this.addOfllineDonation = true;
      }
    },

    convertCurrency() {
      if (
        this.currency &&
        this.chargedAmountUser !== "" &&
        this.chargedAmountUser !== null &&
        this.addDonation.data.attributes.processing_charged_currency.toLowerCase() !=
          this.currency.toLowerCase()
      ) {
        axios
          .get(
            urls.getConversionAmount
              .replace(":amount", this.chargedAmountUser)
              .replace(":from", this.addDonation.data.attributes.processing_charged_currency)
              .replace(":to", this.currency.toLowerCase())
          )
          .then((response) => {
            this.addDonation.data.attributes.charged_amount = Math.round(
              response.data.data.attributes.amount_out
            );
          });
      } else {
        this.addDonation.data.attributes.charged_amount = 0;
      }
    },

    clearData() {
      this.sending = false;

      this.errors = [];

      this.addDonation.data.attributes = {
        campaign_id: 0,
        display_name: "",
        billing_name: "",
        currency_code: "",
        billing_last_name: "",
        processing_charged_currency: "",
        phone: "",
        email: "",
        payment_city: "",
        payment_country: "",
        payment_address: "",
        payment_postcode: "",
        payment_address_2: "",
        category: "",
        dedication: "",
        charged_amount: "",
        team_id: 0,
        legal_entity_id: 0,
        send_receipt: false,
        send_receipt_without_pdf_receipt_attached: false,
        pledge_with_notification_to_pay: false,
        send_confirmation_email: false,
        module_data_id: 0,
        tag: "",
        bank: "",
        branch: "",
        account: "",
        get_data: "",
        effective_date: 0,
        uk_gift_aid: false,
        locked: false,
        locked_type: "",
        locked_var_int: undefined,
        matched_donation_id: 0,
      };

      this.chargedAmountUser = null;
      this.moduleDataId = "";
      this.showOptionaly = false;
      this.multiselectTeam = null;
      this.multiselectEntity = null;
      this.moreThanOneTeamDonation = false;
      this.customer = null;
      this.addOfllineDonation = false;
    },

    selectFavorite(options) {
      const selectCampaignFavorite = getFavoriteCampaign() || null;
      const optionOrFirst = options.find((c) => c.id === selectCampaignFavorite) || options[0];
      return optionOrFirst.id;
    },

    openModal({ addOfllineDonation = true, matchDonationParams = null } = {}) {
      this.addOfllineDonation = addOfllineDonation;
      this.$store.dispatch("getEntities");
      this.getTeams("");

      if (this.withReceipt) {
        this.addDonation.data.attributes.send_receipt = true;
      }

      const show = (v) => {
        this.updateCurrency(v);
        this.$refs.addDonation.show();
      };

      if (matchDonationParams) {
        this.addDonation.data.attributes.matched_donation_id = matchDonationParams.id;
        this.chargedAmountUser = matchDonationParams.amount;
        this.chargedAmountReadonly = true;
        show(matchDonationParams.currency);
        return;
      }

      if (this.inlineSelectCampaign) {
        this.$store.dispatch("getCampaigns").then(({ data }) => {
          this.$store.commit("setSelectedCampaign", this.selectFavorite(data.data));
          show(this.currency);
        });
      } else {
        show(this.currency);
      }
    },

    teamFind: debounce(function (q) {
      this.getTeams(q);
    }, 500),

    getTeams(query) {
      const params = {
        q: query ? query : undefined,
      };
      this.$store.dispatch("getTeamsByCampaignId", [this.selectedCampaignId, params]);
    },

    setActiveTeam(teamId) {
      const selectedTeam = this.teamList.filter((el) => el.value == teamId);

      if (selectedTeam.length > 0) {
        this.multiselectTeam = selectedTeam[0];
      } else {
        this.multiselectTeam = null;
      }
    },

    hideModal() {
      this.$refs.addDonation.hide();
    },

    updateCurrency(val) {
      this.addDonation.data.attributes.processing_charged_currency = val;
      this.convertCurrency();
    },

    async submitForm() {
      if (this.sending) return;

      const result = await this.$validator.validateAll();
      if (!result) {
        if (this.vErrors.has("phone")) {
          this.showOptionaly = true;
          await this.$nextTick();
        }
        return;
      }

      this.addDonation.data.attributes.charged_amount = this.chargedAmountUser;
      if (this.addDonation.data.attributes.processing_charged_currency != this.currency) {
        this.sending = true;

        axios
          .get(
            urls.getConversionAmount
              .replace(":amount", this.chargedAmountUser)
              .replace(":from", this.addDonation.data.attributes.processing_charged_currency)
              .replace(":to", this.currency.toLowerCase())
          )
          .then((response) => {
            this.addDonation.data.attributes.charged_amount = Math.round(
              response.data.data.attributes.amount_out
            );
            this.addDonate();
          })
          .catch(() => {
            this.sending = false;
          });
      } else {
        this.addDonate();
      }
    },

    buildRelations() {
      const relationships = {};
      const included = [];

      if (this.multiselectLevel) {
        relationships["selected_levels"] = {
          data: [
            {
              id: String(this.multiselectLevel.id),
              type: "selected_level",
            },
          ],
        };

        included.push({
          type: "selected_level",
          id: String(this.multiselectLevel.id),
          attributes: {
            count: 1,
          },
        });
      }

      if (included.length && Object.keys(relationships).length) {
        this.addDonation.data.relationships = relationships;
        this.addDonation.included = included;
      }
    },

    addDonate() {
      this.sending = true;
      this.errors = [];

      this.buildRelations();

      this.addDonation.data.attributes.team_id = 0;
      this.addDonation.data.attributes.campaign_id = parseInt(this.selectedCampaignId);
      this.addDonation.data.attributes.currency_code = this.currency.toLowerCase();
      this.addDonation.data.attributes.processing_charged_amount = this.chargedAmountUser;
      this.addDonation.data.attributes.module_data_id = Number(this.moduleDataId);
      if (!this.addDonation.data.attributes.email && this.offlineDonationOptions.email_optional) {
        this.addDonation.data.attributes.email = "noemail@charidy.com";
      }

      const promises = [];

      if (this.$refs.lookUpName) {
        promises.push(
          this.$refs.lookUpName.submit().then(({ data }) => {
            return { moduleDataId: data.data.attributes.id };
          })
        );
      }

      return Promise.all(promises).then((result) => {
        for (let i = 0; i < result.length; i++) {
          const element = result[i];

          if (element.moduleDataId) {
            this.addDonation.data.attributes.module_data_id = Number(element.moduleDataId);
          }
        }

        return axios
          .post(
            urls.donationNewApi
              .replace(":orgId", this.orgId)
              .replace(":campaignId", this.selectedCampaignId),
            this.addDonation
          )
          .then(({ data: response }) => {
            const dId = response.data.id;
            const realPayment = response.data.attributes.effective_amount;

            this.$_notificationsMixin_makeToastCompat({
              group: "Notification",
              title: this.$t("dashboard.add_success_add"),
            });

            let params = {
              id: this.selectedCampaignId,
              setting: `?page=1&limit=50`,
            };

            this.$store.dispatch("getCampaignDonation", params);

            return { dId, customAmount: realPayment };
          })
          .then(({ dId, customAmount }) => {
            if (!this.teamDonationIsValid && !dId) {
              return;
            }
            return this.$refs.CampaignTeamDonation.save({ dId, customAmount });
          })
          .then(() => {
            this.hideModal();
          })
          .catch((e) => {
            this.sending = false;
            if (e.response) {
              if (e.response.data) {
                if (e.response.data.error) {
                  this.errors.push(e.response.data.error);
                } else {
                  this.errors.push(this.$t("template.error_default"));
                }
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          });
      });
    },

    customLabel({ value, text }) {
      return `[${value}] ${text}`;
    },

    customLabelEntity({ id, attributes }) {
      return `[${id}] ${attributes.name}`;
    },

    localDonationsChanged(donations) {
      this.moreThanOneTeamDonation = donations.length > 1;
      if (donations.length === 1) {
        this.setActiveTeam(donations[0].team_id);
      } else if (donations.length > 1) {
        this.setActiveTeam(null);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.entities-data {
  &__label {
    font-family: $vito-light;
    text-transform: uppercase;
    font-size: 14px;
    color: #414359;
    line-height: 1;
    &--required {
      &:after {
        content: "*";
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        color: $lr-100;
      }
    }
  }
}
.v-help {
  font-size: 12px;
  position: absolute;
  right: 0;
  bottom: -18px;
  &.is-danger {
    color: $lr-100;
  }

  &.is-warning {
    color: $warning;
  }
}
.form-group {
  position: relative;
  &.has-error {
    .form-control {
      border-color: $lr-100;
    }
    :deep(.custom-select) {
      border-color: $lr-100;
    }
  }
}

::v-deep {
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 4px solid white !important;
    z-index: 1;
    [dir="rtl"] * & {
      border-top-right-radius: 20px !important;
      border-bottom-right-radius: 20px !important;
      border-right: 0 !important;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-left: 4px solid white !important;
    }
  }
  .dropdown-toggle {
    text-transform: uppercase;
    font-family: $vito-bold;
    font-size: 14px;

    &-split {
      border-top-right-radius: 20px !important;
      border-bottom-right-radius: 20px !important;
      padding-right: 20px;
      padding-left: 20px;
      z-index: 1;
      &:hover {
        filter: brightness(85%);
      }
      [dir="rtl"] * & {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 20px !important;
        border-bottom-left-radius: 20px !important;
      }
    }
    + .dropdown-menu {
      max-height: 500px;
      overflow-y: auto;

      .dropdown-item {
        font-family: $vito-light;
      }
    }
  }
}

.form-unit__addon-icon {
  padding: 0;
  ::v-deep {
    .dropdown-toggle {
      background-color: transparent;
      border: none;
      color: #414359;
      &:hover,
      &:active {
        background-color: transparent;
        border: none;
        color: #414359;
      }
    }
    .show > .btn-secondary.dropdown-toggle {
      background-color: transparent;
      border: none;
      color: #414359;
    }
  }
}

.wrapper-options {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eaf4ff;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
  }
  .show-hide-bttn {
    width: auto;
    position: relative;
    z-index: 2;
    &:focus {
      outline: 0;
    }
  }
}

.convert-amount {
  padding: 0 15px;
  display: flex;
  align-items: center;
  label {
    white-space: nowrap;
    margin: 0;
  }
}
.checkbox-block {
  padding-top: 12px;
  .form-checkbox {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
}
.form-checkbox {
  margin: 0;
  &.no-height {
    height: initial;
    > label {
      margin-bottom: 0;
    }
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.form-help-grid {
  padding-top: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 5px 0px;
  .v-help {
    font-size: 14px;
    font-family: $vito-light;
    position: relative;
    top: unset;
    right: unset;
    bottom: unset;
    left: unset;
  }
}

.modal-header-dashboard {
  &__title-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 0 20px;
    justify-content: space-between;
    @media only screen and (max-width: 1280px) {
      flex-wrap: wrap;
      padding: 0 0px;
    }
  }
}
</style>
