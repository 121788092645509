<template>
  <div>
    <modal-import-teams
      ref="importTeams"
      @send-email="sendEmail"
      @send-sms="sendSMS"
    ></modal-import-teams>

    <div class="campaign-edit-title">
      <h2 class="h2-like">{{ $t("teams.teams_title", "Teams") }}</h2>

      <div class="btn-wrap d-flex flex-row justify-content-around">
        <div class="mx-1">
          <team-form
            ref="teamform"
            :currentpage="currentPage"
            :searchvalue="searchValue"
            :shortlink="campaignShortLink"
            :ccy="campaignCurrency"
          />
        </div>
        <div class="mx-1">
          <email-message ref="emailMessage" />
        </div>
        <div class="mx-1">
          <sms-message ref="smsMessage" />
        </div>
        <div class="mx-1">
          <whatsapp-message ref="whatsappMessage" />
        </div>
        <div class="btn-group delete-all-teams mx-1">
          <b-dropdown
            :disabled="!hasAccessEdit"
            variant="-sm bttn--bordered-blue"
            right
            :text="$t('edit_campaign.teams_options', 'Options')"
          >
            <b-dropdown-item
              :class="this.teams.length === 0 || !hasAccessDeleteAllTeams ? 'disabled' : ''"
              v-on:click="deleteAllTeams"
            >
              {{ $t("edit_campaign.delete_all_teams", "Delete all teams") }}
            </b-dropdown-item>
            <b-dropdown-item @click="openModalImportTeams" v-if="hasAccessAdd">{{
              $t("edit_campaign.import_teams", "Import")
            }}</b-dropdown-item>
            <b-dropdown-item @click="openModalExportTeams">{{
              $t("edit_campaign.export_button_teams_title", "Export")
            }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <export-teams ref="exportTeams"> </export-teams>
    <section class="teams-page" v-if="hasAccessSee">
      <div class="search">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
        <input
          type="text"
          v-model="searchValue"
          :placeholder="$t('teams.search_placeholder', 'Type to search')"
          class="search__input"
        />
      </div>
      <error v-if="errors" :errors="errors"></error>
      <div class="row" v-if="teams.length > 0">
        <div class="col-12 col-md-6" v-for="team in teams" :key="team.id">
          <team-plate
            :team="team"
            @copy-link="linkCopyAlert"
            @edit-team="editTeam"
            @delete-team="deleteTeam"
            @send-email="sendEmail"
            @send-sms="sendSMS"
            @send-whatsapp="sendWhatsapp"
          />
        </div>
      </div>

      <div v-else class="empty-result-message">
        {{ $t("teams.teams_empty") }}
      </div>

      <b-pagination
        v-if="teamsCount > 50"
        size="md"
        :total-rows="teamsCount"
        v-model="currentPage"
        :per-page="50"
      >
      </b-pagination>
    </section>
    <section class="teams-page" v-else>
      <div class="empty-result-message">
        {{ $t("teams.teams_access_error") }}
      </div>
    </section>

    <infoModal :message="$t('teams.link_copied_to_clipboard')" ref="copyAlert" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";

import teamForm from "./team-form.vue";
import campaignTeamPlate from "./campaignTeamPlate.vue";
import campaignImportTeams from "./campaign-import-teams.vue";
import error from "@/components/errors";

import { notificationsMixin } from "@/mixins";
import urls from "@/constants/urls.js";

import exportTeams from "@/views/dashboard/subcomponents/export-teams-modal.vue";
import infoModal from "@/components/info-modal.vue";
import SmsMessage from "@/views/dashboard/subcomponents/messaging/smsMessage.vue";
import EmailMessage from "@/views/dashboard/subcomponents/messaging/emailMessage.vue";
import WhatsappMessage from "@/views/dashboard/subcomponents/messaging/whatsappMessage.vue";

export default {
  mixins: [notificationsMixin],

  data: function () {
    return {
      currentPage: 1,
      searchValue: "",
      errors: [],
    };
  },

  components: {
    "team-form": teamForm,
    "team-plate": campaignTeamPlate,
    "modal-import-teams": campaignImportTeams,
    exportTeams,
    infoModal,
    error,
    SmsMessage,
    EmailMessage,
    WhatsappMessage,
  },

  computed: mapState({
    campaignId: (state) => {
      return state.campaign.campaign.data.id;
    },

    teams: (state) => {
      if (state.teams.teams) {
        return state.teams.teams.sort(function (a, b) {
          if (a.attributes.sort === b.attributes.sort) {
            return a.id - b.id;
          }
          return a.attributes.sort - b.attributes.sort;
        });
      }
      return [];
    },

    teamsCount: (state) => {
      return state.teams.teamsCount;
    },

    orgId: function () {
      return localStorage.getItem("orgId");
    },

    campaignShortLink: (state) => {
      return state.campaign.campaign.data.attributes.short_link;
    },

    campaignCurrency: (state) => {
      return state.campaign.campaign.data.attributes.currency;
    },

    hasAccessSee: (state) => {
      return state.account.access.CanSeeCampaignTeams;
    },
    hasAccessEdit: (state) => {
      return state.account.access.CanEditCampaignTeams;
    },
    hasAccessAdd: (state) => {
      return state.account.access.CanAddCampaignTeams;
    },
    hasAccessDeleteAllTeams: (state) => {
      return state.account.access.CanDeleteAllCampaignTeams;
    },
  }),

  watch: {
    currentPage: function () {
      this.loadNewPageTeams();
    },

    searchValue: function () {
      this.loadNewPageTeams();

      this.currentPage = 1;
    },
  },

  methods: {
    ...mapActions({
      seferTorahFetchIsActive: "seferTorahPages/fetchIsActive",
    }),

    openModalImportTeams: function () {
      this.$refs.importTeams.openModal();
    },

    openModalExportTeams() {
      this.$refs.exportTeams.openModal(this.campaignId);
    },

    sendEmail({ team, importGroup } = {}) {
      this.$refs.emailMessage.open({ team, importGroup });
    },

    sendSMS({ team, importGroup } = {}) {
      this.$refs.smsMessage.open({ team, importGroup });
    },

    sendWhatsapp({ team, importGroup } = {}) {
      this.$refs.whatsappMessage.open({ team, importGroup });
    },

    editTeam: function (team) {
      this.$refs.teamform.openEditModal(team);
    },

    loadNewPageTeams() {
      const params = ["extend=stats", "limit=50", `page=${this.currentPage}`];

      if (this.searchValue) {
        params.push(`q=${this.searchValue}`);
      }

      let teamsParams = `?${params.join("&")}`;

      this.$store.dispatch("getTeams", teamsParams);
    },

    deleteAllTeams: function () {
      if (!this.hasAccessDeleteAllTeams) {
        return false;
      }

      if (this.teamsCount === 0) {
        return 0;
      }

      let message = this.$t("teams.confirm_delete_all_teams", {
        count: this.teamsCount,
      });

      let options = {
        view: "delete-width-checkbox-confirm",
        okText: this.$t("teams.confirm_delete"),
        cancelText: this.$t("teams.confirm_cancel"),
        confirmDeleteText: this.$t("teams.confirm_delete_team_text", {
          count: this.teamsCount,
        }),
        count: this.teamsCount,
      };

      this.$dialog.alert(message, options).then(() => {
        axios
          .delete(
            urls.deleteAllTeams
              .replace(":orgId", this.orgId)
              .replace(":campaignId", this.campaignId)
          )
          .then(() => {
            let teamsParams = `?limit=50&page=1&q=${this.searchValue}`;

            this.$store.dispatch("getTeams", teamsParams);

            this.$_notificationsMixin_makeToastCompat({
              group: "Notification",
              title: this.$t("teams.success_delete_all_teams"),
            });
          })
          .catch((e) => {
            this.$_notificationsMixin_makeToastCompat({
              group: "Notification",
              type: "danger",
              title: e.response.data.error,
            });
          });
      });
    },

    deleteTeam: function (team) {
      let message = this.$t("teams.confirm_delete_team");

      let options = {
        okText: this.$t("teams.confirm_delete"),
        cancelText: this.$t("teams.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        axios
          .delete(
            urls.editTeams.replace(":orgId", this.orgId).replace(":campaignId", this.campaignId) +
              "/" +
              team.id
          )
          .then(() => {
            let teamsParams = `?limit=50&page=${this.currentPage}&q=${this.searchValue}`;

            this.$store.dispatch("getTeams", teamsParams);

            this.$_notificationsMixin_makeToast(
              "Success!",
              this.$t("teams.success_delete"),
              "success"
            );
          })
          .catch((e) => {
            if (e.response.data) {
              if (e.response.data.error) {
                this.errors.push(e.response.data.error);
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          });
      });
    },

    linkCopyAlert() {
      this.$refs.copyAlert.openModal();
    },
  },

  created() {
    this.$store.dispatch("getAllTeams", this.$route.params.id);
    const p = {
      cId: this.$route.params.id,
    };
    this.seferTorahFetchIsActive(p);
  },
};
</script>

<style lang="scss" scoped>
// .delete-all-teams {
//   margin-left: 20px;
// }

.disabled {
  ::v-deep a {
    cursor: not-allowed;
  }
}

.search {
  padding: 20px 0;
}
</style>
