<template>
  <b-card class="account-card" v-if="!isLoading">
    <error-message ref="error" :alert="true"></error-message>
    <div class="row">
      <profile-edit-contact-form />
      <profile-edit-signin-form />
    </div>
  </b-card>
</template>

<script>
import { mapState } from "vuex";

import errorMessage from "@/components/error-message.vue";

import profileEditSigninForm from "./profile-edit-signin-form.vue";
import profileEditContactForm from "./profile-edit-contact-form.vue";

export default {
  components: {
    profileEditSigninForm,
    profileEditContactForm,
    errorMessage,
  },

  computed: mapState({
    isLoading: (state) => state.donorAccount.loadingAccount,
  }),

  created: function () {
    // this.$store.dispatch("getDonorAccountInfo");
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.account-card {
  color: $fd;
  .form-control {
    border-color: #e6eaed;
    background: #f7fbff;
  }
}
</style>
