<template>
  <div class="data-view-wrapper clearfix" v-if="service">
    <div class="data-view">
      <div class="data-view__title">{{ $t("integration.service_title") }}</div>
      <div class="data-view__data">
        {{ service.attributes.service }}
      </div>
    </div>

    <div class="data-view">
      <div class="data-view__title">
        {{ $t("integration.service_integrated") }}
      </div>
      <div class="data-view__data">
        {{ getDateFormat(service.attributes.created_at) }}
      </div>
    </div>

    <div class="data-view" v-if="showMetaFields && service.attributes.api_key">
      <div class="data-view__title">
        {{ $t("integration.service_api_key", "Api key") }}
      </div>
      <div class="data-view__data">
        {{ service.attributes.api_key }}
      </div>
    </div>

    <div v-if="showMetaFields">
      <div class="data-view" v-for="(value, key, index) in getMetaParsed(service)" :key="index">
        <div class="data-view__title">
          {{ key }}
        </div>
        <div class="data-view__data">
          {{ value }}
        </div>
      </div>
    </div>

    <div v-if="showMeta2Fields">
      <div class="data-view" v-for="(value, key, index) in showMeta2Fields" :key="index">
        <div class="data-view__title">
          {{ key }}
        </div>
        <div class="data-view__data">
          {{ value }}
        </div>
      </div>
    </div>

    <div v-if="allowUpdateMeta2" class="data-view">
      <div class="data-view__title">
        {{
          $t("integration.service_autoexport_for_campaigns", "Limit Export for Campaign ID list")
        }}
      </div>
      <div class="data-view__data">
        <b-form-tags
          placeholder="Campaign ID List"
          input-type="number"
          v-model="payload.autoexport_for_campaigns"
        ></b-form-tags>
      </div>
    </div>

    <taxgift-subview
      v-if="service.attributes.service === 'tax_gift'"
      :meta="getMetaParsed(service)"
      @update-meta="updateIntegrationMeta"
    />

    <div class="d-flex justify-content-evenly">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="updateStep">
        {{ $t("gateways.close_view") }}
      </button>

      <button
        class="bttn bttn--lg bttn--orange el-right"
        v-if="service.attributes.service === 'overpass'"
        @click.prevent="toLoginOverpass"
      >
        {{ $t("integration.overpass_login") }}
      </button>

      <button
        class="bttn bttn--lg bttn--orange el-right"
        v-if="allowUpdateMeta2"
        @click.prevent="updateIntegration"
      >
        {{ $t("integration.save", "Save") }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";

import urls from "@/constants/urls.js";
import taxgiftSubview from "@/views/integration/subcomponents/taxgift-subview.vue";

import moment from "moment";

export default {
  props: ["service"],

  data() {
    return {
      editIntegration: {
        data: {
          attributes: {
            donation_sobject_type: "",
          },
        },
      },
      gatewayInfo: null,
      typeList: ["Account", "Contact", "Custom", "Lead"],
      errors: [],
      payload: {
        autoexport_for_campaigns: [],
      },
    };
  },

  components: {
    taxgiftSubview,
  },

  computed: {
    ...mapState({
      orgId: () => {
        return localStorage.getItem("orgId");
      },
    }),

    attrs() {
      return this.service?.attributes || {};
    },

    showMetaFields() {
      switch (this.service.attributes.service) {
        case "zapier":
        case "jl":
        case "donorfuse":
        case "whatsapp":
          return true;
      }
      return false;
    },

    showMeta2Fields() {
      switch (this.attrs.service) {
        case "donorfuse":
          return {
            campaign_name: this.payload.campaign_name,
          };
      }
      return false;
    },

    allowUpdateMeta2() {
      switch (this.attrs.service) {
        case "jl":
        case "donorfuse":
          return true;
      }
      return false;
    },
  },

  mounted() {
    this.payload = this.getMeta2Parsed();
  },

  methods: {
    toLoginOverpass() {
      window.open(`https://app.overpass.com/auth/login`, "_blank");
    },

    updateStep() {
      this.$emit("update-step");
    },

    getDateFormat(date) {
      return moment.unix(date).format("MMMM D, YYYY h:mm a");
    },

    clearDataModa() {
      this.gatewayInfo = null;
      this.errors = [];
    },

    getMetaParsed(service) {
      let meta = {};
      try {
        meta = JSON.parse(service.attributes.meta);
      } catch {
        meta = {};
      }
      return meta;
    },

    getMeta2Parsed() {
      let meta = {};
      try {
        meta = JSON.parse(this.attrs.meta_2);
      } catch {
        meta = {};
      }
      return meta;
    },

    updateIntegrationMeta(newMeta, serviceName) {
      if (serviceName === "taxgift") {
        let payload = {
          id: this.service.id,
          status: true,
          ...newMeta,
        };

        axios
          .put(urls.addIntegration(this.orgId, "taxgift"), payload)
          .then(() => {
            this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
            this.$root.$emit("bv::hide::modal", "integrationadd");
            this.$store.dispatch("getIntegrationsList");
          })
          .catch(this.$_notificationsMixin_handleCatch);
      }
    },

    updateIntegration() {
      let payload = {
        ...this.payload,
        autoexport_for_campaigns: this.payload.autoexport_for_campaigns.map((id) => Number(id)),
      };

      axios
        .put(urls.updateIntegrationByID(this.orgId, this.attrs.service, this.service.id), payload)
        .then(() => {
          this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
          // this.$root.$emit("bv::hide::modal", "integrationadd");
          this.$store.dispatch("getIntegrationsList");
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-right {
  float: right;
}
.card {
  width: 100%;
}
.select {
  width: 50%;
}
.card-body {
  display: flex;
  align-items: center;
}
.type-input {
  height: 50px;
  width: 100%;
  margin: 0;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
</style>
