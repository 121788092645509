<template>
  <div>
    <div class="campaign-edit-title">
      <h2 class="h2-like">{{ $t("edit_campaign.donation_levels_title") }}</h2>
      <div class="btn-wrap">
        <level-modal-form ref="modalLevel"></level-modal-form>
      </div>
    </div>

    <section class="matchers_page">
      <div class="row">
        <template>
          <div class="col-12 col-lg-6">
            <level-plate></level-plate>
          </div>
        </template>
        <div class="empty-result-message">{{ $t("edit_campaign.donation_level_empty") }}</div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import error from "@/components/errors.vue";
import campaignLevelPlate from "./campaignLevelPlate.vue";
import levelModalForm from "./level-modal-form.vue";

export default {
  components: {
    error,
    "level-plate": campaignLevelPlate,
    levelModalForm,
  },

  data() {
    return {
      levelsList: [],
      load: false,
      errors: [],
    };
  },

  computed: mapState({}),

  methods: {},

  watch: {},

  mounted() {},
};
</script>

<style></style>
