export default {
  title: "DonorsGoal",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
    },
    team: {
      title: "Active for teams",
      type: "boolean",
    },
    campaign: {
      title: "Active for campaign",
      type: "boolean",
    },
    team_with_donor_goal_only: {
      title: "Hide teams donors goal with 0",
      type: "boolean",
    },
    show_total: {
      title: "Show campaign money progress",
      type: "boolean",
    },
    hide_team_amount_goal: {
      title: "Hide team amount goal",
      type: "boolean",
    },
    amount: {
      title: "Campaign donors goal",
      type: "number",
    },
  },
};
