<script>
export default {
  props: {
    loadingReports: Boolean,
    reportsList: {
      type: [Array, Object],
      default: () => ({}),
    },
  },
  computed: {
    templateMessage() {
      const message = (this.reportsList?.messages || []).join(";");
      const email = this.reportsList?.email || "";

      return this.$t(
        "reports.stripe_report_template_message",
        `{message} May take longer if server is has many requests. A report will be sent to <a href="mailto:{email}">{email}</a>`,
        {
          message,
          email,
        }
      );
    },
  },
};
</script>

<template>
  <div v-if="!Array.isArray(reportsList)">
    <div v-if="!loadingReports">
      <p v-html="templateMessage" />
    </div>
    <div v-else>
      <b-skeleton />
    </div>
  </div>
</template>
