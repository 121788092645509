<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div class="matcher-plate">
    <div class="matcher-plate__header">
      <div class="matcher-plate__image">
        <b-img
          v-if="stream.attributes.image == ''"
          v-bind="{ blank: true, blankColor: '#777', width: 75, height: 75, class: 'm1' }"
          rounded="circle"
          alt="Circle image"
        ></b-img>
        <img
          v-if="stream.attributes.image"
          :src="stream.attributes.image"
          :alt="stream.attributes.name"
        />
      </div>

      <div class="matcher-plate__data">
        <div class="d-flex">
          <h2 class="matcher-plate__title col-8">{{ stream.attributes.title }}</h2>
          <h2 class="matcher-plate__title text-right col-4">${{ stream.attributes.goal }}</h2>
        </div>
        <p class="stream-order-text">{{ stream.attributes.description }}</p>
        <div class="d-flex">
          <div
            class="form-checkbox form-checkbox--orange form-checkbox--disabled no-padding-left col-6"
          >
            <input
              type="checkbox"
              v-model="stream.attributes.default"
              :id="stream.id"
              class="form-checkbox__input"
              disabled
            />
            <label :for="stream.id" class="form-checkbox__label">{{
              $t("edit_campaign.donation_stream_default")
            }}</label>
          </div>

          <div class="col-6 d-flex stream-order-wrapper">
            <h3 class="stream-order-text-title text-left">
              {{ $t("edit_campaign.donation_stream_order") }}:
            </h3>
            <h3 class="stream-order-text matcher-plate__title text-right">
              {{ stream.attributes.order }}
            </h3>
          </div>
        </div>
        <div class="matcher-plate__row--data">
          <div class="matcher-plate__type">{{ $t("edit_campaign.donation_level_color") }}</div>
          <span class="level-plate__color" :style="{ background: stream.attributes.color }"></span>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="align-items-end d-flex p-2">
        <AppSwitchToggle
          :label="$t('edit_campaign.donation_stream_visible', 'Visible')"
          :value="visibleMeta"
          @input="changeShowHide"
          :disabled="!hasAccess || loadingInternal || loading"
          :loading="loadingInternal || loading"
          plate
        />
      </div>
      <div class="matcher-plate__action ml-auto p-2">
        <button :disabled="!hasAccess" class="bttn bttn-round" @click="editStream()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          <!-- {{ $t('edit_campaign.edit') }} -->
        </button>

        <button :disabled="!hasAccess" class="bttn bttn-round" @click="deleteStream()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
          <!-- {{ $t('edit_campaign.delete') }} -->
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import AppSwitchToggle from "@/common-components/ui-elements/app-switch-toggle.vue";
import axios from "axios";
import urls from "@/constants/urls";
import { notificationsMixin } from "@/mixins";

const extend = `?${[
  "extend=campaign_stats",
  "extend=content",
  "extend=media",
  "extend=matchers",
  "extend=donation_levels",
  "extend=donation_streams",
].join("&")}`;

export default Vue.extend({
  props: ["stream", "loading"],

  data() {
    return {
      loadingInternal: false,
    };
  },

  components: {
    AppSwitchToggle,
  },

  mixins: [notificationsMixin],

  computed: mapState({
    hasAccess: (state) => state.account.access.CanEditCampaignDonationLevels,
    campaignId: (state) => state.campaign.campaign.data.id,
    orgId() {
      return localStorage.getItem("orgId");
    },
    visibleMeta() {
      return (
        this.stream.attributes.meta.show_on_campaign_page ||
        this.stream.attributes.meta.show_on_donation_form
      );
    },
  }),

  methods: {
    ...mapActions({
      updateCampaign: "updateCampaign",
    }),
    editStream() {
      this.$emit("edit-stream", this.stream);
    },

    deleteStream() {
      this.$emit("delete-stream", this.stream);
    },

    changeShowHide(v) {
      this.stream.attributes.meta.show_on_campaign_page = v;
      this.stream.attributes.meta.show_on_donation_form = v;

      const payload = {
        data: {
          attributes: {
            ...this.stream.attributes,
            meta: JSON.stringify(this.stream.attributes.meta),
          },
        },
      };
      this.loadingInternal = true;
      return axios
        .put(
          urls.apiPutDonationStream
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignId)
            .replace(":donationstreamId", this.stream.id),
          payload
        )
        .then(
          new Promise((resolve) => {
            const timeoutHandle = setTimeout(() => {
              this.updateCampaign(extend).then(() => {
                resolve(timeoutHandle);
              });
            }, 1);
          })
        )
        .then((timeoutHandle) => {
          this.$_notificationsMixin_makeToastCompat({
            group: "Notification",
            title: this.$t("edit_campaign.success_save_donation_streams"),
          });
          clearTimeout(timeoutHandle);
        })
        .catch(this.$_notificationsMixin_handleCatch)
        .finally(() => (this.loadingInternal = false));
    },
  },

  filters: {
    shorten: (value, stop) => (value.length > stop ? value.slice(0, stop) + " ..." : value),
  },
});
</script>

<style lang="scss" scoped>
.level-plate {
  &__color {
    margin-left: 20px;
    width: 3rem;
    height: 20px;
  }
}
.stream-order-wrapper {
  padding-top: 13px;
}
.stream-order-text,
.stream-order-text-title {
  font-size: 1rem;
  line-height: 1;
  color: #414359;
}
.stream-order-text-title {
  margin-left: 60px;
  margin-right: 5px;
}

.no-padding-left {
  padding-left: 0;
}
</style>
