<template>
  <div class="wizard">
    <div class="main">
      <div class="first-step" v-if="step === 'first'">
        <p>{{ $t("integration.sales_force_step_1") }}</p>

        <b-form-group>
          <b-form-checkbox v-model="sandbox" name="sf-sandbox" switch>
            {{ $t("integration.sales_force_sandbox_mode", `Use 'sandbox' mode`) }}
          </b-form-checkbox>
        </b-form-group>

        <ValidationObserver ref="observer">
          <b-form-group v-if="chabadone" label="Chabad One CRM Campaign ID">
            <ValidationProvider
              name="cid"
              :rules="{
                required: true,
                regex: /^(701)\w{12}(?:\w{3})?$/,
              }"
              v-slot="{ errors, valid }"
            >
              <b-form-input
                v-model="form.chabadone_campaign_id"
                placeholder="Enter Campaign ID"
              ></b-form-input>
              <b-form-invalid-feedback :state="valid">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
        </ValidationObserver>
      </div>

      <div class="second-step" v-if="step === 'second'">
        <p>{{ $t("integration.sales_force_step_2") }}</p>
      </div>
      <div class="second-step" v-if="step === 'success'">
        <p>{{ $t("integration.sales_force_step_success") }}</p>
      </div>
      <div class="second-step" v-if="step === 'fail'">
        <p>{{ $t("integration.sales_force_step_fail") }}</p>
      </div>
    </div>
    <div class="footer">
      <button
        :class="
          step === 'success' ? 'bttn bttn--lg bttn--blue el-right' : 'bttn bttn--lg bttn--blue'
        "
        @click.prevent="closeModal"
      >
        {{ $t("integration.close") }}
      </button>
      <button
        class="bttn bttn--lg bttn--orange el-right"
        v-if="step === 'first'"
        @click.prevent="nextStep('first')"
      >
        {{ $t("integration.next") }}
      </button>
      <button
        class="bttn bttn--lg bttn--orange el-right"
        v-if="step === 'second'"
        @click.prevent="nextStep('second')"
      >
        {{ $t("integration.next") }}
      </button>
      <button
        class="bttn bttn--lg bttn--orange el-right"
        v-if="step === 'fail'"
        @click.prevent="nextStep('first')"
      >
        {{ $t("integration.try_again") }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls.js";
import store from "@/store/store.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import { mapState } from "vuex";

export default {
  components: { ValidationObserver, ValidationProvider },

  props: {
    chabadone: Boolean,
  },

  data() {
    return {
      redirectUri: "",
      salesforceIntegrationKey: "",
      client_id: "",
      step: "first",
      integrationStatus: false,
      currentIntegrationListCache: [],
      unsubscribe: "",
      sandbox: false,
      form: {
        chabadone_campaign_id: "",
      },
    };
  },

  computed: mapState({
    currentIntegrationList: (state) => {
      return state.integrations.integrations;
    },
  }),

  methods: {
    checkIntegrationList() {
      let orgId = localStorage.getItem("orgId");
      axios.get(urls.getIntegrationsList.replace(":orgId", orgId)).then((response) => {
        if (response.data.data.length > 0) {
          this.integrationStatus = true;
        }
      });
    },

    checkIntegration(mutation) {
      if (mutation.type === "setIntegrations") {
        const self = this;
        const newIntegration = mutation.payload.filter(function (o1) {
          return self.currentIntegrationListCache.every(function (o2) {
            return (
              o1.id != o2.id || (o1.id === o2.id && o1.attributes.status != o2.attributes.status)
            );
          });
        });

        if (newIntegration.length > 0 && newIntegration[0].attributes.status === 1) {
          this.step = "success";
        } else {
          this.step = "fail";
        }
      }
      this.unsubscribe();
    },

    getSalesforceIntegrationKey() {
      let orgId = localStorage.getItem("orgId");

      const params = {
        sandbox: this.sandbox,
      };

      let data = {};

      if (this.chabadone) {
        params.chabadone = 1;
        data = this.form;
      }
      return new Promise((resolve) => {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            axios
              .post(urls.getSalesforceIntegrationKey.replace(":orgId", orgId), data, {
                params,
              })
              .then((response) => {
                this.redirectUri = response.data.callback_url;
                this.client_id = response.data.client_id;
                this.salesforceIntegrationKey = response.data.salesforce_integration_key;
                document.cookie =
                  "salesforce_integration_key=" +
                  response.data.salesforce_integration_key +
                  ";domain=.charidy.com;path=/";
                resolve();
              });
          }
        });
      });
    },

    nextStep(step) {
      if (step === "first") {
        this.getSalesforceIntegrationKey().then(() => {
          this.step = "second";

          let host = "login.salesforce.com";
          if (this.sandbox) {
            host = "test.salesforce.com";
          }
          window.open(
            `https://${host}/services/oauth2/authorize?response_type=code&client_id=${this.client_id}&redirect_uri=${this.redirectUri}&state=${this.salesforceIntegrationKey}`,
            "_blank"
          );
        });
      }
      if (step === "second") {
        this.currentIntegrationListCache = this.currentIntegrationList;
        store.dispatch("getIntegrationsList");
        this.unsubscribe = store.subscribe((mutation, state) => {
          this.checkIntegration(mutation, state);
        });
      }
    },

    closeModal() {
      this.$store.dispatch("getIntegrationsList");
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: space-between;
}
.el-right {
  margin-left: auto;
}
.account-link {
  font-size: 16px;
  color: #cc6d4c;
}
</style>
