<template>
  <div class="donation-unit">
    <div class="donation-unit__cell donation-unit__id">
      {{ donation.id }}
    </div>

    <div class="donation-unit__name donation-unit__cell">
      <div class="donation-unit__label">
        ({{ $t("dashboard.donation_name") }} {{ donation.attributes.billing_name }}
        {{ donation.attributes.billing_last_name }})
      </div>
      {{ donation.attributes.billing_name }} {{ donation.attributes.billing_last_name }}
    </div>

    <div class="donation-unit__dname donation-unit__cell">
      {{ donation.attributes.display_name }}
    </div>

    <div class="donation-unit__method donation-unit__cell">
      <div class="donation-unit__label">{{ $t("dashboard.donation_via") }}</div>
      {{ donation.attributes.bank_name }}
    </div>

    <div class="donation-unit__charged donation-unit__cell">
      <div class="donation-unit__label">{{ $t("dashboard.donation_amount_charged") }}</div>
      {{ donation.attributes.charged_amount }} {{ donation.attributes.currency_code.toUpperCase() }}
    </div>

    <div class="donation-unit__effective donation-unit__cell">
      <div class="donation-unit__label">{{ $t("dashboard.donation_amount_effective") }}</div>
      <span class="donation-unit__effective-amount"
        >{{ donation.attributes.effective_amount }}
        {{ donation.attributes.currency_code.toUpperCase() }}</span
      >
      <div
        class="donation-unit__recuring-icon-counter"
        v-b-tooltip.hover
        v-if="donation.attributes.recurring_period > 0"
        :title="$t('dashboard.donation_recurring_period')"
      >
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 489.711 489.711"
          style="enable-background: new 0 0 489.711 489.711"
          xml:space="preserve"
        >
          <g>
            <g>
              <path
                d="M112.156,97.111c72.3-65.4,180.5-66.4,253.8-6.7l-58.1,2.2c-7.5,0.3-13.3,6.5-13,14c0.3,7.3,6.3,13,13.5,13
                        c0.2,0,0.3,0,0.5,0l89.2-3.3c7.3-0.3,13-6.2,13-13.5v-1c0-0.2,0-0.3,0-0.5v-0.1l0,0l-3.3-88.2c-0.3-7.5-6.6-13.3-14-13
                        c-7.5,0.3-13.3,6.5-13,14l2.1,55.3c-36.3-29.7-81-46.9-128.8-49.3c-59.2-3-116.1,17.3-160,57.1c-60.4,54.7-86,137.9-66.8,217.1
                        c1.5,6.2,7,10.3,13.1,10.3c1.1,0,2.1-0.1,3.2-0.4c7.2-1.8,11.7-9.1,9.9-16.3C36.656,218.211,59.056,145.111,112.156,97.111z"
                fill="grey"
              />
              <path
                d="M462.456,195.511c-1.8-7.2-9.1-11.7-16.3-9.9c-7.2,1.8-11.7,9.1-9.9,16.3c16.9,69.6-5.6,142.7-58.7,190.7
                        c-37.3,33.7-84.1,50.3-130.7,50.3c-44.5,0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-0.7,12.9-7.2,12.2-14.7s-7.2-12.9-14.7-12.2l-88.9,8
                        c-7.4,0.7-12.9,7.2-12.2,14.7l8,88.9c0.6,7,6.5,12.3,13.4,12.3c0.4,0,0.8,0,1.2-0.1c7.4-0.7,12.9-7.2,12.2-14.7l-4.8-54.1
                        c36.3,29.4,80.8,46.5,128.3,48.9c3.8,0.2,7.6,0.3,11.3,0.3c55.1,0,107.5-20.2,148.7-57.4
                        C456.056,357.911,481.656,274.811,462.456,195.511z"
                fill="grey"
              />
            </g>
          </g>
        </svg>
        <div class="donation-unit__recurring_period">
          {{ donation.attributes.recurring_period }}
        </div>
      </div>
    </div>

    <div class="donation-unit__contact donation-unit__cell">
      <div class="donation-unit__label">{{ $t("dashboard.donation_email") }}</div>
      <span>{{ donation.attributes.email }}</span> <span>/ {{ donation.attributes.phone }}</span>
    </div>

    <div class="donation-unit__cell donation-unit__date">
      {{ getDateFormat(donation.attributes.date) }}
    </div>

    <div class="donation-unit__status donation-unit__cell">
      <div class="donation-unit__label">{{ $t("dashboard.donation_status") }}</div>
      {{ donation.attributes.status }}
    </div>
    <div class="donation-unit__phone donation-unit__cell">
      <div class="donation-unit__label">{{ $t("dashboard.danation_phone") }}</div>
      {{ donation.attributes.phone }}
    </div>

    <div class="donation-unit__action">
      <button
        class="bttn-round"
        v-if="donation.attributes.donation_receipt_id > 0"
        @click="editReceipt"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
        <!--<span>receipt</span>-->
      </button>

      <button class="bttn-round" @click="previewDonate">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 480.606 480.606"
          style="enable-background: new 0 0 480.606 480.606"
          xml:space="preserve"
        >
          <g>
            <rect x="85.285" y="192.5" width="200" height="30" />
            <path
              d="M439.108,480.606l21.213-21.213l-71.349-71.349c12.528-16.886,19.949-37.777,19.949-60.371
              c0-40.664-24.032-75.814-58.637-92.012V108.787L241.499,0H20.285v445h330v-25.313c6.188-2.897,12.04-6.396,17.475-10.429
              L439.108,480.606z M250.285,51.213L299.072,100h-48.787V51.213z M50.285,30h170v100h100v96.957
              c-4.224-0.538-8.529-0.815-12.896-0.815c-31.197,0-59.148,14.147-77.788,36.358H85.285v30h126.856
              c-4.062,10.965-6.285,22.814-6.285,35.174c0,1.618,0.042,3.226,0.117,4.826H85.285v30H212.01
              c8.095,22.101,23.669,40.624,43.636,52.5H50.285V30z M307.389,399.208c-39.443,0-71.533-32.09-71.533-71.533
              s32.089-71.533,71.533-71.533s71.533,32.089,71.533,71.533S346.832,399.208,307.389,399.208z"
            />
          </g>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["donation"],

  methods: {
    getDateFormat: function (date) {
      let donateDate = moment.unix(date).format("MMMM D, YYYY");

      let thisDate = moment(new Date()).format("MMMM D, YYYY");

      if (donateDate == thisDate) {
        return moment.unix(date).format("h:mm a");
      } else {
        return moment.unix(date).format("MMMM D, YYYY h:mm a");
      }
    },

    editReceipt() {
      this.$emit("edit-receipt", this.donation);
    },

    previewDonate() {
      this.$emit("preview-donate", this.donation);
    },
  },
};
</script>

<style media="screen" scoped lang="scss">
.button-preview {
  width: 35px;
}
</style>
