<template>
  <div>
    <b-modal ref="modal" size="lg" @hidden="clearData">
      <div slot="modal-header">
        <h2>
          {{ $t("edit_campaign.import_sefer_torah_modal_title", "Import from the file") }}
        </h2>
        <div class="modal-header__hide" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button
          :class="
            successUpload ? 'bttn bttn--lg bttn--orange el-right' : 'bttn bttn--lg bttn--blue'
          "
          @click.prevent="hideModal"
        >
          {{ $t("edit_campaign.hide_modal", "Cancel") }}
        </button>

        <button
          form="import-donation"
          v-if="!successUpload && !loading"
          @click.prevent="uploadFile"
          :disabled="!file || loading"
          class="bttn bttn--lg bttn--orange"
        >
          {{ $t("edit_campaign.import_sefer_torah", "Import") }}
        </button>
      </div>

      <form @submit.prevent="uploadFile" class="entities-data">
        <div v-if="!loading && !successUpload">
          <div class="mb-3">
            <p class="m-0">
              {{
                $t(
                  "edit_campaign.import_sefer_torah_description",
                  "To upload more than one at a time, you can upload a XLSX file. The file must be formatted exactly as indicated in the sample file that you can download here:"
                )
              }}
            </p>
            <p class="m-0">
              <b-link
                :disabled="!Boolean(importTemplateUrl)"
                :href="importTemplateUrl"
                target="_blank"
              >
                <b-spinner
                  v-if="!Boolean(importTemplateUrl)"
                  small
                  label="Loading import template url..."
                ></b-spinner>
                {{ $t("edit_campaign.import_teams_dowloadfile") }}
              </b-link>
            </p>
          </div>

          <label for="massUpload" class="upload-file">
            {{ $t("edit_campaign.import_upload_file", "Upload file") }}
            <br />
            {{ fileName }}
          </label>
          <input
            type="file"
            id="massUpload"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            @change="fileLoad"
          />
        </div>

        <div v-if="successUpload">
          <p class="success">
            {{ $t("edit_campaign.import_successful_import", "Successful") }}
          </p>
        </div>

        <div class="loading" v-if="loading">
          <LdsSpinner />
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { notificationsMixin } from "@/mixins";

import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";

export default {
  components: { LdsSpinner },

  mixins: [notificationsMixin],

  data() {
    return {
      file: undefined,
      fileName: "",
      importTemplateUrl: null,
      successUpload: false,
      loading: false,
    };
  },

  computed: {
    ...mapState({
      cmpId: (state) => state.campaign.campaign.data.id,
    }),
  },

  methods: {
    ...mapActions({
      seferTorahFetchOrgImport: "seferTorahPages/fetchOrgImport",
      fetchOrgImportTemplate: "seferTorahPages/fetchOrgImportTemplate",
    }),

    getImportTemplateUrl() {
      return this.fetchOrgImportTemplate({ cId: this.cmpId }).then((data) => {
        const l = data.data.link;
        this.importTemplateUrl = l;
      });
    },

    fileLoad(e) {
      if (e.target.files.length > 0) {
        this.fileName = e.target.files[0].name;
        this.file = e.target.files[0];
      } else {
        this.fileName = "";
        this.file = null;
      }
    },

    uploadFile() {
      this.loading = true;

      this.seferTorahFetchOrgImport({
        cId: this.cmpId,
        file: this.file,
      })
        .then(() => {
          this.successUpload = true;
          this.$emit("success-upload");
        })
        .catch(this.$_notificationsMixin_handleCatch)
        .finally(() => {
          this.loading = false;
        });
    },

    showModal() {
      this.$refs.modal.show();
      this.getImportTemplateUrl();
    },

    hideModal() {
      this.$refs.modal.hide();
    },

    clearData() {
      this.file = null;
      this.fileName = "";
      this.successUpload = false;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.entities-data {
  padding-top: 20px;
  padding-bottom: 20px;
}

.upload-file {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  cursor: pointer;
  border: 1px dashed #cecece;
  text-align: center;
}

#massUpload {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
}

.export-list {
  margin-top: 20px;
}

.loading {
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
</style>
