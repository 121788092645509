<template>
  <b-col v-if="goalCategoriesOptions.length">
    <b-form-group>
      <template #label>
        {{ $t("teams.goal_categories", "Goal categories") }}
      </template>

      <div>
        <b-row v-for="(v, i) in value" :key="i" class="border-top border-bottom py-2">
          <b-col>
            {{ v.title }}
          </b-col>
          <b-col cols="1" class="p-0"> x{{ v.count }} </b-col>
          <b-col cols="2" class="text-center">
            {{ v.price * v.count }}
          </b-col>
          <b-col cols="1">
            <b-icon-x role="button" font-scale="1.5" variant="danger" @click="remove(i)" />
          </b-col>
        </b-row>

        <b-row v-if="options.length > 1" class="mt-2">
          <b-col>
            <b-form-group>
              <template #label>
                {{ $t("teams.goal_category_title", "Title") }}
              </template>
              <b-form-select v-model="selected" :options="options" />
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group>
              <template #label>
                {{ $t("teams.goal_category_count", "Count") }}
              </template>
              <b-form-input
                v-model.number="count"
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                :formatter="countFilter"
                :disabled="!selected"
              />
              <template #description>
                <span v-if="selected">
                  {{ selected.price }} x {{ count }} = {{ count * selected.price }}
                </span>
              </template>
            </b-form-group>
          </b-col>

          <b-col cols="3" class="d-flex align-items-center">
            <b-btn class="bttn bttn--lg bttn--orange" :disabled="!selected || !count" @click="add">
              {{ $t("teams.goal_category_add", "Add") }}
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-form-group>
  </b-col>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selected: null,
      count: 0,
    };
  },

  computed: {
    ...mapState({
      metas: (s) => s.campaign.metas,
    }),

    valueData() {
      return this.value || [];
    },

    goalCategoriesOptions() {
      const { value, goal_categories } = this.metas.add_team_from_campaign || {};

      if (value && goal_categories?.value) {
        return goal_categories?.options || [];
      }

      return [];
    },

    options() {
      return [
        {
          value: null,
          text: this.$t("teams.please_selet_goal_category", "Pleease select goal category"),
        },
        ...this.goalCategoriesOptions.map((g) => ({
          value: g,
          text: this.$t(g.title, g.title),
        })),
      ].filter(({ text }) => {
        return !this.valueData.find((gc) => gc.title === text);
      });
    },

    totalGoal() {
      return this.valueData.reduce((sum, gc) => {
        sum += gc.price * gc.count;
        return sum;
      }, 0);
    },
  },

  watch: {
    totalGoal(value) {
      this.$emit("update:goal", value);
    },
  },

  methods: {
    countFilter(val) {
      return Number(val) > 0 ? val : 0;
    },

    add() {
      const val = [...this.valueData];
      const item = { ...this.selected, count: this.count };

      if (Array.isArray(this.selected.level_ids)) {
        item.levels = this.selected.level_ids.map((id) => ({ id }));
      }

      if (Array.isArray(this.selected.raffle_hot_button_keys)) {
        item.raffle_hot_button_keys = this.selected.raffle_hot_button_keys.map((id) => ({ id }));
      }

      val.push(item);

      this.$emit("input", val);

      this.selected = null;
      this.count = 0;
    },

    remove(i) {
      const val = [...this.valueData];

      val.splice(i, 1);

      this.$emit("input", val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
