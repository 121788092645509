<template>
  <div>
    <div class="category-title">
      <h1 class="h1-like">All-in For Sabra Day!</h1>

      <div class="btn-group">
        <b-dropdown
          variant="-sm bttn--bordered-blue"
          right
          :text="$t('edit_campaign.open_campaign_page')"
        >
          <b-dropdown-item target="_blank">{{
            $t("edit_campaign.campaign_in_countdown_mode")
          }}</b-dropdown-item>
          <b-dropdown-item target="_blank">{{
            $t("edit_campaign.campaign_in_live_mode")
          }}</b-dropdown-item>
          <b-dropdown-item target="_blank">{{
            $t("edit_campaign.campaign_in_success_mode")
          }}</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <!-- <div class="campaign-sub">
        {{ $t('edit_campaign.campaign_mode_'+campaignMode) }}
      </div> -->

    <ul class="cp-edit-nav">
      <li
        class="cp-edit-nav__unit"
        v-bind:class="{ active: campaignProfileStep === 'general' }"
        @click="updateTab('general')"
      >
        <svg viewBox="0 0 22 22">
          <g fill="none" fill-rule="nonzero">
            <path
              fill="#107598"
              d="M18.65 18.65a10.82 10.82 0 1 0-15.75-.47l.1.07a5.1 5.1 0 0 1-2.23 2.52c-.37.18-.27.73.13.8 1.23.2 2.98-.02 4.6-1.25l.03.03a10.85 10.85 0 0 0 13.12-1.7z"
            />
            <path
              fill="#FFF"
              d="M11.45 4.33a1.78 1.78 0 0 1 0 3.55 1.78 1.78 0 0 1 0-3.55zM8.63 15.7c.07-.63.17-1.27.3-1.9.17-1.1.37-2.2.57-3.3 0-.07.03-.15.03-.2 0-.45-.15-.63-.6-.68-.2-.02-.4-.04-.58-.1-.22-.07-.32-.24-.3-.45.02-.2.15-.32.4-.35.13-.02.28-.02.4-.02H12.2c.4 0 .65.2.68.6 0 .35-.06.7-.1 1.02l-.7 3.78c-.08.43-.15.83-.2 1.25-.03.2 0 .43.04.6.08.28.28.4.53.38.23-.03.45-.1.65-.18.18-.07.33-.17.5-.25.25-.1.47.07.4.35-.05.15-.13.3-.25.43a3.3 3.3 0 0 1-2.32 1.05c-.43 0-.86 0-1.28-.08-.75-.15-1.65-1.02-1.53-1.95z"
            />
          </g>
        </svg>
        <span>{{ $t("edit_campaign.general_info") }}</span>
      </li>

      <li
        class="cp-edit-nav__unit"
        v-bind:class="{ active: campaignProfileStep === 'description' }"
        @click="updateTab('description')"
      >
        <svg viewBox="0 0 18 22">
          <path
            fill="#107598"
            fill-rule="nonzero"
            d="M10.8 0H2.16C.96 0 0 .98 0 2.2v17.6C0 21.02.97 22 2.16 22h12.96c1.2 0 2.16-.98 2.16-2.2V6.6L10.8 0zm2.16 17.6H4.32v-2.2h8.64v2.2zm0-4.4H4.32V11h8.64v2.2zm-2.88-5.87V2.25l4.99 5.08h-4.99z"
          />
        </svg>
        <span>{{ $t("edit_campaign.description") }}</span>
      </li>

      <li
        class="cp-edit-nav__unit"
        v-bind:class="{ active: campaignProfileStep === 'views' }"
        @click="updateTab('views')"
      >
        <svg viewBox="0 0 21 20">
          <g fill="#107598" fill-rule="nonzero">
            <path
              d="M18.24.02h-16C1.12.02.2.93.2 2.05v12.88l7.92-7.88 5.75 5.72 2.05-2.04 4.32 4.3V2.04c0-1.12-.92-2.03-2.01-2.03zm-2.51 6.43c-.87 0-1.58-.7-1.58-1.56a1.57 1.57 0 0 1 3.15 0c0 .86-.7 1.56-1.57 1.56z"
            />
            <path
              d="M13.88 14.95l-5.75-5.7-7.33 7.3c-.39.38-.6.88-.6 1.43 0 1.11.92 2.02 2.04 2.02h16c1.12 0 2.03-.9 2.03-2.02v-.75l-4.34-4.3-2.05 2.02z"
            />
          </g>
        </svg>
        <span>Views</span>
      </li>

      <li
        class="cp-edit-nav__unit"
        v-bind:class="{ active: campaignProfileStep === 'reviews' }"
        @click="updateTab('reviews')"
      >
        <svg viewBox="0 0 22 21">
          <path
            fill="#107598"
            fill-rule="nonzero"
            d="M2.32 7.64A2.48 2.48 0 0 1 4.8 5.16c0 1.65.65 3.16 1.7 4.27-.44.42-1.04.69-1.7.69a2.48 2.48 0 0 1-2.48-2.48zM11 10.12a4.96 4.96 0 1 0-.01-9.93 4.96 4.96 0 0 0 .01 9.93zm6.2 0a2.48 2.48 0 0 0 0-4.96c0 1.66-.65 3.16-1.7 4.27.44.43 1.04.69 1.7.69zm0 2.48c-.34 0-.68.04-1 .1a9.46 9.46 0 0 1 3.48 3.62H22a4.97 4.97 0 0 0-4.8-3.72zm-11.22.15A4.97 4.97 0 0 0 0 16.32h2.4a9.9 9.9 0 0 1 3.58-3.57zm5.2-.15a8.7 8.7 0 0 0-8.77 7.44H19.6a8.34 8.34 0 0 0-8.42-7.44z"
          />
        </svg>
        <span>Reviews</span>
      </li>

      <li
        class="cp-edit-nav__unit"
        v-bind:class="{ active: campaignProfileStep === 'emailTemplate' }"
        @click="updateTab('emailTemplate')"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
          <path
            fill="#107598"
            d="M160 448c-25.6 0-51.2-22.4-64-32-64-44.8-83.2-60.8-96-70.4V480c0 17.67 14.33 32 32 32h256c17.67 0 32-14.33 32-32V345.6c-12.8 9.6-32 25.6-96 70.4-12.8 9.6-38.4 32-64 32zm128-192H32c-17.67 0-32 14.33-32 32v16c25.6 19.2 22.4 19.2 115.2 86.4 9.6 6.4 28.8 25.6 44.8 25.6s35.2-19.2 44.8-22.4c92.8-67.2 89.6-67.2 115.2-86.4V288c0-17.67-14.33-32-32-32zm256-96H224c-17.67 0-32 14.33-32 32v32h96c33.21 0 60.59 25.42 63.71 57.82l.29-.22V416h192c17.67 0 32-14.33 32-32V192c0-17.67-14.33-32-32-32zm-32 128h-64v-64h64v64zm-352-96c0-35.29 28.71-64 64-64h224V32c0-17.67-14.33-32-32-32H96C78.33 0 64 14.33 64 32v192h96v-32z"
          ></path>
        </svg>
        <span>{{ $t("edit_campaign.email_template") }}</span>
      </li>

      <li class="cp-edit-nav__more" :class="{ active: campaignProfileStep === 'levels' }">
        <b-dropdown
          right
          text="Right align"
          offset="10"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template slot="button-content">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
              <path
                fill="#107598"
                d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z"
              ></path>
            </svg>
          </template>
          <b-dropdown-item :style="{ 'background-color': '#EAF4FF' }" @click="updateTab('levels')">
            Levels
          </b-dropdown-item>
          <!-- <b-dropdown-item
              :style="{'background-color': '#EAF4FF', 'margin-top': '5px'}"
              @click="updateTab('streams')"
            >
              Streams
            </b-dropdown-item> -->
        </b-dropdown>
      </li>
    </ul>

    <div class="campaign-profile">
      <div class="campaign-profile__body">
        <!-- Tabs -->
        <app-packages-general-info
          v-if="campaignProfileStep === 'general'"
        ></app-packages-general-info>
        <app-packages-description
          v-if="campaignProfileStep === 'description'"
        ></app-packages-description>
        <app-packages-reviews v-if="campaignProfileStep === 'reviews'"></app-packages-reviews>
        <app-packages-emails v-if="campaignProfileStep === 'emailTemplate'"></app-packages-emails>
        <app-packages-levels v-if="campaignProfileStep === 'levels'"></app-packages-levels>
        <app-packages-views v-if="campaignProfileStep === 'views'"></app-packages-views>
      </div>
    </div>
  </div>
</template>

<script>
import urls from "@/constants/urls.js";
import store from "../../../store/store";
import packagesGeneralInfo from "./subcomponents/packagesGeneralInfo.vue";
import packagesDescription from "./subcomponents/packagesDescription.vue";
import campaignLevels from "./subcomponents/campaignLevels.vue";
import campaignGroups from "./subcomponents/campaignGroups.vue";
import packagesReviews from "./subcomponents/packagesReviews.vue";
import packagesEmails from "./subcomponents/packagesEmails.vue";
import packagesViews from "./subcomponents/packagesViews.vue";

import { mapState } from "vuex";

export default {
  components: {
    appPackagesGeneralInfo: packagesGeneralInfo,
    appPackagesDescription: packagesDescription,
    appCampaignLevels: campaignLevels,
    appCampaignGroups: campaignGroups,
    appPackagesReviews: packagesReviews,
    appPackagesEmails: packagesEmails,
    appPackagesViews: packagesViews,
  },

  data() {
    return {
      campaignProfileStep: "general",
    };
  },

  methods: {
    updateTab(val) {
      location.hash = val;
      this.campaignProfileStep = val;
    },

    checkHash: function () {
      const hash = location.hash;

      if (hash.length > 0) {
        this.campaignProfileStep = hash.replace("#", "");
      }
    },
  },

  computed: mapState({}),

  created: function () {
    let extend = `?${[
      "extend=campaign_stats",
      "extend=content",
      "extend=media",
      "extend=matchers",
      "extend=donation_levels",
    ].join("&")}`;

    store.dispatch("getCampaign", extend);

    let teamsParams = "?limit=50&page=1";

    store.dispatch("getTeams", teamsParams);

    this.checkHash();
  },
};
</script>

<style lang="scss">
@import "../../../styles/_vars.scss";

.category-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .dropdown-item {
    &:focus {
      outline: 0;
    }
  }
}

.campaign-sub {
  margin-bottom: 10px;
  text-transform: uppercase;
}

.campaign-profile {
  margin-bottom: 60px;
  //padding: 0 0 20px 0;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: $shadow-th;
  &__body {
    padding: 40px 20px;
    background-color: #ffffff;
  }
  &__title-wrapper {
    min-height: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .button-add-big {
      margin-left: auto;
      padding: 0 20px;
      margin-bottom: 20px;
      @media only screen and (max-width: 380px) {
        width: 125px;
        font-size: 12px;
      }
    }
    .button_wrap {
      margin-left: auto;
      //padding: 0 20px;
      margin-bottom: 20px;
    }
  }
}

// new campaign edit menu
.cp-edit-nav {
  display: flex;
  margin: 0;
  list-style: none;
  border-radius: 6px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  background-color: $bg-100;
  z-index: 1;
  // overflow: hidden;
  &__unit {
    width: 20%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    position: relative;
    z-index: 0;
    cursor: pointer;
    &:first-of-type {
      border-radius: 6px 0 0 6px;
    }
    &:last-of-type {
      border-radius: 0 6px 6px 0px;
    }
    &:not(:last-of-type) {
      &:after {
        content: "";
        display: block;
        width: 1px;
        height: 30px;
        background-color: #107598;
        opacity: 0.8;
        position: absolute;
        top: 16px;
        right: -1px;
      }
    }
    svg {
      width: auto;
      height: 22px;
      display: block;
      opacity: 0.6;
      @media only screen and (min-width: 992px) {
        margin-right: 10px;
      }
    }
    span {
      margin-top: 5px;
      display: none;
      @media only screen and (min-width: 992px) {
        display: inline-block;
      }
    }
    &.active {
      // z-index: 1;
      svg {
        opacity: 1;
      }
      span {
        font-family: $vito-bold;
        color: $bb-100;
      }
    }
    &:first-of-type.active {
      border-radius: 6px 6px 0 0;
      box-shadow: $shadow-menu-left;
      background-color: white;
      &:after {
        display: none;
      }
    }
    &:not(:first-of-type).active {
      border-radius: 6px 6px 0 0;
      box-shadow: $shadow-menu-center;
      background-color: white;
      &:after {
        display: none;
      }
    }
    &:last-of-type.active {
      border-top-left-radius: 6px;
      border-bottom-right-radius: 0;
      box-shadow: $shadow-menu-right;
      background-color: white;
      &:after {
        display: none;
      }
    }
  }
  &__more {
    width: 7%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    position: relative;
    z-index: 0;
    cursor: pointer;
    &:last-of-type {
      border-radius: 0 6px 6px 0px;
    }
    svg {
      width: auto;
      height: 22px;
      display: block;
      opacity: 0.6;
      @media only screen and (min-width: 992px) {
        margin-right: 10px;
      }
    }
    span {
      margin-top: 5px;
      display: none;
      @media only screen and (min-width: 992px) {
        display: inline-block;
      }
    }
    &.active {
      svg {
        opacity: 1;
      }
      span {
        font-family: $vito-bold;
        color: $bb-100;
      }
    }
    &:last-of-type.active {
      border-top-left-radius: 6px;
      border-bottom-right-radius: 0;
      box-shadow: $shadow-menu-right;
      background-color: white;
      &:after {
        display: none;
      }
    }
  }
}

// ??
.campaign-edit {
  .submit-form {
    margin-left: auto;
  }
}

.teams-page {
  > .search {
    display: block;
    margin-left: auto;
    margin-right: 0;
    width: 100%;
    max-width: 280px;
  }
}
// Goal Amount styling
.goal-amount {
  display: flex;
  margin-bottom: 15px;
  .dropdown {
    display: flex;
    &.open {
      .btn {
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
      }
    }
    .btn {
      min-width: 60px;
      max-width: 60px;
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-color: rgba(32, 67, 78, 0.15);
      background-color: #ffffff;
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      position: relative;
      color: #20434e;
      &:active,
      &:focus,
      &:hover {
        color: #20434e;
        outline: 0;
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
        box-shadow: none;
      }
      span {
        position: absolute;
        top: 22px;
        right: 5px;
      }
    }
    .dropdown-toggle {
      font-size: 15px;
      position: relative;
      &:after {
        position: absolute;
        right: 6px;
        top: calc(50% - 1px);
      }
    }
    &-menu {
      min-width: initial;
      padding: 0;
      width: 100%;
      border-radius: 0;
    }
    &-item {
      height: 45px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: gray;
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(32, 67, 78, 0.15);
      }
    }
  }
  .form-group {
    width: 100%;
    margin: 0 0 0 -1px;
    .form-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

// Form Short link validation messages styles
.form-link {
  position: relative;
  .link-error,
  .link-validate,
  .link-success {
    display: inline-flex;
    align-items: center;
    padding: 5px 0;
    color: #20434e;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    img {
      margin-left: 5px;
      width: 20px;
      height: auto;
    }
    position: absolute;
    top: 13px;
    right: 25px;
  }
  .link-error {
    color: #ff0000;
  }
}

// Groups
.campaign-groups {
  .btn-add {
    margin-bottom: 20px;
  }
  .submit-form {
    margin-right: 0;
    margin-left: auto;
  }
}

// Campaign description
.campaign-description {
  .submit-form {
    margin-right: 0;
    margin-left: auto;
  }
}

.language-selected {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 20px;
  list-style: none;
  &__unit {
    padding: 0 20px;
    height: 38px;
    margin: 0 5px 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $bb-100;
    color: $fd;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    border-radius: 19px;
    cursor: pointer;
    &.active {
      color: #ffffff;
      background-color: $bb-100;
    }
    .btn-group {
      display: flex;
      width: 100%;
      height: 100%;
    }
  }
  &__add {
    display: inline-flex;
    height: 40px;
    border: 1px solid rgba(32, 67, 78, 0.15);
  }
  .language-add {
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
  }
  .btn-group {
    display: flex;
    &.open {
      .dropdown-toggle {
        box-shadow: none;
      }
      .dropdown-menu {
        width: 100%;
        min-width: initial;
        padding: 0;
        li {
          padding: 10px;
          text-align: center;
          text-transform: uppercase;
          cursor: pointer;
          &:not(:last-of-type) {
            border-bottom: 1px solid lightgray;
          }
        }
      }
    }
  }
}

.description-area {
  margin-bottom: 20px;
  textarea {
    width: 100%;
    resize: none;
  }
}

// .campaign-images {
//   font-family: "ProximaNova-Regular", sans-serif;
// }

.img-cimages {
  width: 230px;
  min-width: 230px;
  height: 230px;
  margin: 15px;
  border: 1px solid $fl;
  border-radius: 5px;
  position: relative;
  > input.img-cimages__input {
    width: 1px;
    height: 1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
  > label.img-cimages__label {
    margin: 0;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $bg-50;
    border-radius: 5px;
    //opacity: initial;
    //visibility: initial;
    //pointer-events: initial;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    svg {
      margin-bottom: 10px;
      fill: $bb-100;
    }
    span {
      font-size: 12px;
      font-weight: normal;
      text-transform: uppercase;
      color: $fd;
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
      &:last-of-type {
        color: $fl;
      }
    }
    .img-cimages__size-text {
      text-align: center;
      text-transform: lowercase;
      color: $fd;
    }
  }
  &__loaded-wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    max-width: 100%;
    margin-right: 15px;
  }
  &__delete {
    width: 40px;
    height: 40px;
    background: #0084af;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg {
      width: 24px;
      height: 24px;
      fill: #ffffff;
    }
  }
  label.img-cimages__change {
    padding: 0;
    margin: 0;
    visibility: initial;
    opacity: initial;
    position: static !important;
    width: 40px;
    height: 40px;
    background: #0084af;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: initial;
    overflow: hidden;
    position: relative;
    svg {
      width: 24px;
      height: 24px;
      fill: #ffffff;
    }
    > input {
      width: 1px;
      height: 1px;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &--loaded {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .img-cimages__delete-change {
      display: flex;
      align-items: center;
      justify-content: space-around;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    &:hover,
    &:focus {
      .img-cimages__delete-change {
        visibility: visible;
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }
  &--dummy {
    display: none;
    border-color: transparent;
    @media only screen and (min-width: 600px) {
      display: block;
    }
  }
}

.images-section {
  margin-bottom: 40px;
  &__title {
    margin: 0 0 10px;
    font-size: 16px;
    font-weight: 400;
    color: $fd;
  }
  &__subtitle {
    font-size: 14px;
    margin-bottom: 20px;
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: -15px;
    margin-left: -15px;
    .img-cimages--desktop {
      order: 0;
    }
    .desktop-media {
      order: 1;
    }
    .img-cimages--mobile {
      order: 3;
    }
    .mobile-media {
      order: 4;
    }
    @media only screen and (min-width: 600px) {
      flex-wrap: wrap;
      flex-direction: row;
      max-width: 600px;
    }
    @media only screen and (min-width: 1140px) {
      flex-wrap: nowrap;
      flex-direction: row;
      max-width: initial;
      .img-cimages--desktop {
        order: 0;
      }
      .desktop-media {
        order: 2;
      }
      .img-cimages--mobile {
        order: 1;
      }
      .mobile-media {
        order: 3;
      }
    }
    @media only screen and (min-width: 1360px) {
      max-width: 600px;
      flex-wrap: wrap;
      .img-cimages--desktop {
        order: 0;
      }
      .desktop-media {
        order: 1;
      }
      .img-cimages--mobile {
        order: 3;
      }
      .mobile-media {
        order: 4;
      }
    }
    @media only screen and (min-width: 1560px) {
      max-width: 1170px;
      flex-wrap: nowrap;
      .desktop-media {
        order: 2;
        margin: 15px 0 15px auto;
      }
      .img-cimages--mobile {
        order: 1;
      }
      .mobile-media {
        order: 3;
      }
    }
    .img-upload {
      margin: 15px;
    }
  }
}

.img-upload {
  &.img-upload--500 {
    width: 100%;
    max-width: 500px;
  }
  &.img-upload--600 {
    width: 100%;
    max-width: 600px;
  }
  &__unit {
    position: relative;
    margin-bottom: 10px;
  }
  &__input {
    width: 1px;
    height: 1px;
    position: absolute;
    z-index: -1;
  }
  label.img-upload__empty,
  label.img-upload__label {
    pointer-events: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    visibility: visible;
    opacity: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &__empty {
    display: flex;
    align-items: center;
    color: #a0a4ae;
    svg {
      margin-right: 10px;
      fill: #a0a4ae;
    }
  }
  &__proceed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    img {
      width: 100%;
    }
    &--inner {
      display: inline-flex;
      position: absolute;
      right: 10px;
      bottom: 20px;
    }
  }
  div.img-upload__action,
  label.img-upload__action {
    position: static;
    pointer-events: initial;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #0084af;
    margin-right: 10px;
    visibility: visible;
    opacity: 1;
    cursor: pointer;
    svg {
      fill: #ffffff;
    }
  }
  &__description {
    font-size: 12px;
    color: $fd;
  }
}

.img-slider {
  width: 220px;
  height: 220px;
  border: 1px dashed lightgray;
  &--500 {
    width: 100%;
  }
  &--600 {
    width: 100%;
    height: 315px;
  }
}

.campaign-matchers {
  .btn-add {
    margin: 0 0 20px auto;
  }
  .submit-form {
    margin-right: 0;
    margin-left: auto;
  }
}

.matcher-card {
  margin-bottom: 40px;
  &__title {
    font-size: 16px;
    font-weight: 600;
    color: #20434e;
    position: relative;
    text-align: center;
    margin: 0 0 20px;
    > span {
      display: inline-block;
      padding: 0 20px;
      background-color: #ffffff;
      position: relative;
      z-index: 1;
    }
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: #dee3e5;
      transform: translateY(-50%);
      position: absolute;
      left: 0;
      top: 50%;
    }
  }
  &__delete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: white;
    position: absolute;
    top: calc(50% - 20px);
    right: 0;
    z-index: 1;
    cursor: pointer;
    svg {
      fill: #dee3e5;
    }
  }
  &__body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  &__img {
    min-width: 140px;
    padding: 0 20px;
  }
  &__data {
    flex-grow: 1;
    padding: 0 20px;
    label {
      position: static;
      color: #20434e;
      font-size: 16px;
      visibility: initial;
      opacity: initial;
    }
    textarea {
      width: 100%;
      resize: none;
      box-sizing: border-box;
      box-shadow: none;
    }
  }
}

.matcher-img {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  border: 1px dashed lightgray;
  border-radius: 50%;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (min-width: 992px) {
    margin-right: initial;
    margin-left: initial;
  }
  > input[type="file"] {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  > label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    pointer-events: initial;
    cursor: pointer;
    visibility: initial;
    opacity: initial;
    color: #a0a4ae;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    svg {
      fill: #a0a4ae;
    }
  }
  &__loaded {
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .image-wrapper {
      border-radius: 50%;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
    img {
      width: 100%;
    }
  }
  div.matcher-img__action,
  label.matcher-img__action {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #0084af;
    pointer-events: initial;
    visibility: initial;
    opacity: initial;
    position: absolute;
    cursor: pointer;
    svg {
      fill: #ffffff;
    }
  }
  &__delete {
    left: -10px;
    bottom: -10px;
  }
  label.matcher-img__change {
    top: initial;
    left: initial;
    right: -10px;
    bottom: -10px;
    margin: 0;
  }
}

.teams_page {
  //margin-top: -37px;
  padding: 0px;
  .top {
    p {
      font-size: 16px;
      padding: 20px 0;
    }
  }
  .button_wrap {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  /*&_search {*/
  /*margin-bottom: 20px;*/
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: flex-end;*/
  /*flex-wrap: nowrap;*/
  /*position: relative;*/
  /*> svg {*/
  /*//margin-right: 10px;*/
  /*fill: gray;*/
  /*position: absolute;*/
  /*left: 0;*/
  /*top: calc(50% - 12px);*/
  /*}*/
  /*> input {*/
  /*width: 100%;*/
  /*//max-width: 360px;*/
  /*border-left: none;*/
  /*border-right: none;*/
  /*border-top: none;*/
  /*border-radius: 0;*/
  /*padding-left: 30px;*/
  /*}*/
  /*}*/
  .top-table {
    > div {
      display: flex;
      &:first-child {
        width: 25%;
      }
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 15%;
      }
      &:nth-child(5) {
        width: 15%;
      }
    }
  }
  .body-table {
    box-shadow: none;
  }
  .body-row {
    > div {
      display: flex;
      &:first-child {
        width: 25%;
      }
      &:nth-child(2) {
        width: 25%;
      }
      &:nth-child(3) {
        width: 15%;
      }
      &:nth-child(4) {
        width: 15%;
      }
      &:nth-child(5) {
        width: 15%;
        button {
          background-position: center left;
          border: 0;
          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }
    .name-team {
      width: auto;
    }
    .image {
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      img {
        min-height: 100%;
        max-height: 100%;
      }
    }
  }
}

.team-plate {
  width: 100%;
  margin-bottom: 20px;
  //max-width: 560px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #e6eaed;
  //box-shadow: $shadow-th;
  background-color: #ffffff;
  &__image {
    //display: none;
    margin-bottom: 15px;
    width: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    @media only screen and (min-width: 568px) {
      margin-bottom: 0;
      margin-right: 20px;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  &__header {
    padding: 20px;
    min-height: 169px;
    display: flex;
    flex-direction: column;
    align-items: center;
    //background-color: $bg-20;
    //border-bottom: 1px solid #E6EAED;
    @media only screen and (min-width: 568px) {
      flex-direction: row;
    }
  }
  &__data {
    flex-grow: 1;
    width: 100%;
    @media only screen and (min-width: 568px) {
      width: initial;
    }
  }
  &__body {
    padding: 0 20px 5px 20px;
  }
  &__title {
    min-height: 54px;
    margin: 0 0 10px;
    padding: 0;
    color: $fd;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.125;
    @media only screen and (min-width: 568px) {
      text-align: initial;
    }
    .open-page {
      position: relative;
      bottom: 1px;
    }
  }
  &__team-id {
    margin: 0 0 0 auto;
    font-size: 14px;
    color: $fd;
    line-height: 1;
    span {
      color: $bb-100;
      font-family: $vito-light;
    }
  }
  &__short {
    font-size: 14px;
    color: $fd;
    span {
      color: $bb-100;
      font-family: $vito-light;
    }
  }
  &__row {
    display: flex;
    &--data {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
    }
  }
  &__goal {
    font-size: 14px;
    color: $fd;
    margin-right: 5px;
  }
  &__goal-value {
    font-size: 14px;
    font-family: $vito-light;
    color: $bb-100;
  }
  &__order {
    margin-left: auto;
    display: flex;
    font-size: 14px;
    color: $fd;
    margin-right: 5px;
    &-value {
      padding-left: 5px;
      color: $bb-100;
    }
  }
  &__description {
    display: none;
    min-height: 82px;
    margin-right: -20px;
    margin-left: -20px;
    padding: 20px;
    background-color: $bg-50;
    font-size: 14px;
    color: $fd;
  }
}

.matchers_page {
  padding: 0px;
  &_search {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    position: relative;
    > svg {
      fill: gray;
      position: absolute;
      left: 0;
      top: calc(50% - 12px);
    }
    > input {
      width: 100%;
      border-left: none;
      border-right: none;
      border-top: none;
      border-radius: 0;
      padding-left: 30px;
    }
  }
}

.matcher-plate {
  width: 100%;
  margin-bottom: 20px;
  //max-width: 560px;
  overflow: hidden;
  border-radius: 4px;
  //box-shadow: $shadow-th;
  border: 1px solid #e6eaed;
  background-color: #ffffff;
  &__image {
    margin-bottom: 15px;
    width: 100px;
    min-width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    @media only screen and (min-width: 568px) {
      margin-bottom: 0;
      margin-right: 20px;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  &__header {
    padding: 20px 20px 5px;
    //min-height: 169px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (min-width: 568px) {
      flex-direction: row;
    }
  }
  &__data {
    flex-grow: 1;
  }
  &__body {
    padding: 0 20px 5px 20px;
  }
  &__title {
    //min-height: 54px;
    margin: 0 0 10px;
    padding: 0;
    color: $fd;
    font-size: 24px;
    text-transform: uppercase;
    line-height: 1.125;
  }
  &__type {
    font-size: 14px;
    color: $fd;
    span {
      padding-left: 5px;
      color: $bb-100;
      font-family: $vito-light;
    }
  }
  &__row {
    display: flex;
    &--data {
      //margin-bottom: 20px;
      display: flex;
      align-items: center;
    }
  }
  &__description {
    display: none;
    min-height: 82px;
    margin-right: -20px;
    margin-left: -20px;
    padding: 20px;
    background-color: $bg-50;
    font-size: 14px;
    color: $fd;
  }
}

.modal-header {
  position: relative;
  &__hide {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>
