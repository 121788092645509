<template>
  <form action="" class="gateway-form">
    <error></error>

    <div class="form-block">
      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.legal_entity")
            }}</label>
            <b-form-select required />
          </div>
        </div>

        <div class="crate-legal-entity col-12">
          <p>
            {{ $t("gateways.create_entity_text") }}
          </p>
          <button type="button" class="bttn bttn--sm bttn--orange" name="button">
            {{ $t("gateways.create_entity_button") }}
          </button>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label class="form-group__label form-group__label--required" for="">{{
                  $t("gateways.terminal_id")
                }}</label>
                <b-form-input required type="text"></b-form-input>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label for="">{{ $t("gateways.max_payments") }}</label>
                <b-form-select />
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label for="">{{ $t("gateways.installments") }}</label>
                <b-form-select />
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label class="form-group__label form-group__label--required" for="">{{
                  $t("gateways.api_password")
                }}</label>
                <b-form-input required type="text"></b-form-input>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label class="form-group__label form-group__label--required" for="">{{
                  $t("gateways.signature_key")
                }}</label>
                <b-form-input required type="text"></b-form-input>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label class="form-group__label form-group__label--required" for="">{{
                  $t("gateways.page_lang")
                }}</label>
                <b-form-select required />
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label class="form-group__label form-group__label--required" for="">{{
                  $t("gateways.currency")
                }}</label>
                <b-form-select required />
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label class="form-group__label form-group__label--required" for="">{{
                  $t("gateways.status")
                }}</label>
                <b-form-select required :options="statusType" />
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label class="form-group__label form-group__label--required" for="">{{
                  $t("gateways.emv")
                }}</label>
                <b-form-select required :options="yaademvType" />
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="form-group">
                <label class="form-group__label" for="">{{ $t("gateways.zpass") }}</label>
                <b-form-input />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="gateway-form__action">
      <button type="button" name="button">{{ $t("gateways.close") }}</button>
      <button type="submit">{{ $t("gateways.save") }}</button>
    </div>
  </form>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";

import error from "@/components/errors.vue";

export default {
  props: ["entities"],

  data() {
    return {
      yaadpayData: {
        data: {
          attributes: {},
        },
      },

      statusType: [
        { value: false, text: "Disabled" },
        { value: true, text: "Enabled" },
      ],
      yaademvType: [
        { value: "0", text: "No" },
        { value: "1", text: "Yes" },
      ],
    };
  },

  components: {
    error: error,
    Multiselect,
  },

  computed: mapState({}),

  methods: {},
};
</script>

<style lang="scss">
.crate-legal-entity {
  button {
    display: block;
    margin: 20px auto;
  }
}

.alert-danger {
  width: auto;
  margin-left: 0;
  li {
    width: auto;
    margin-left: 0;
  }
}
</style>
