<template>
  <b-input-group>
    <template #prepend>
      <strong>{{ title }}</strong>
    </template>
    <b-form-input
      class="form-control form-unit__input"
      :disabled="disabled"
      :value="value"
      :placeholder="placeholder"
      @input="$emit('input', $event)"
    >
    </b-form-input>
    <template #append>
      <slot
        name="button"
        :loadVideo="loadVideo"
        :disabled="disabled"
        :loading="loading"
        v-if="!disableVideoLinksButtons"
      >
        <button
          :disabled="disabled || loading"
          class="bttn bttn--sm bttn--blue"
          type="button"
          @click="loadVideo(tag, value, originalValue)"
        >
          {{ $t("edit_campaign.save_link") }}
        </button>
      </slot>
    </template>
  </b-input-group>
</template>

<script>
import { notificationsMixin } from "@/mixins";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [notificationsMixin],
  props: {
    value: {
      type: String,
      default: "",
    },

    tag: {
      type: String,
      required: true,
    },
    campaignId: {
      type: [String, Number],
      required: true,
    },
    langImagesSelect: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    disableVideoLinksButtons: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getIncludeCampaignMedia: "getIncludeCampaignMedia",
      getOriginalCampaignMediaValue: "getOriginalCampaignMediaValue",
    }),
    orgId: function () {
      return localStorage.getItem("orgId");
    },
    originalValue() {
      return this.getOriginalCampaignMediaValue(this.tag, this.langImagesSelect);
    },
  },
  watch: {
    originalValue(newVal) {
      if (newVal.length) {
        this.$emit("input", newVal[0].attributes.src);
      } else {
        this.$emit("input", "");
      }
    },
  },
  mounted() {
    this.$emit("input", this.originalValue[0] ? this.originalValue[0].attributes.src : "");
  },
  methods: {
    ...mapActions({
      fetchCreateMediaFile: "fetchCreateMediaFile",
      fetchEditMediaFile: "fetchEditMediaFile",
      fetchDeleteMediaFile: "fetchDeleteMediaFile",
    }),
    loadVideo(tag, model, originalData) {
      if (originalData.length && model.trim() === "") {
        let [video] = originalData;

        return this.deleteVideo(video.id);
      }

      if (originalData.length) {
        let [video] = originalData;
        video.attributes.src = model.startsWith("/") ? model.substr(1) : model;

        return this.loadEditFile(video.attributes, video.id);
      }

      return this.addNewVideo(tag, model);
    },
    loadEditFile: function (data, id) {
      this.loading = true;

      let mediaTag = data.tag;

      return this.fetchEditMediaFile({ id, cId: this.campaignId, data })
        .then(() => {
          this.$_notificationsMixin_makeToast(
            "Success",
            mediaTag !== "video" || mediaTag !== "countdown_video"
              ? this.$t("edit_campaign.success_edit_image")
              : this.$t("edit_campaign.success_edit_video")
          );

          this.$store.dispatch(
            "updateCampaign",
            "?extend=campaign_stats&extend=content&extend=media&extend=matchers"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async addNewVideo(tag, model) {
      this.loading = true;

      try {
        await this.fetchCreateMediaFile({
          cId: this.campaignId,
          data: {
            tag,
            src: model.startsWith("/") ? model.substr(1) : model,
            lang: this.langImagesSelect,
          },
        });

        this.$_notificationsMixin_makeToast("Success", this.$t("edit_campaign.success_add_video"));

        this.$store.dispatch(
          "updateCampaign",
          `?${["extend=campaign_stats", "extend=content", "extend=media", "extend=matchers"].join(
            "&"
          )}`
        );
      } finally {
        this.loading = false;
      }
    },
    async deleteVideo(id) {
      this.loading = true;

      try {
        await this.fetchDeleteMediaFile({
          id,
          cId: this.campaignId,
        });

        this.$_notificationsMixin_makeToast(
          "Success",
          this.$t("edit_campaign.success_delete_video", "Successfully removed video")
        );

        this.$store.dispatch(
          "updateCampaign",
          `?${["extend=campaign_stats", "extend=content", "extend=media", "extend=matchers"].join(
            "&"
          )}`
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
