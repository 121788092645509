<template>
  <div>
    <div v-if="loading && viewState === 'grid'" class="d-flex justify-content-center mb-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <div v-else>
      <div class="category-title">
        <h1 class="h1-like">
          {{ $t("dashboard.share_video_title", "Share video") }}
        </h1>
        <div class="action-block">
          <div class="item d-flex flex-row justify-content-around align-items-baseline mx-2">
            <span class="mx-2 pointer-text"> Grid </span>
            <b-form-checkbox
              v-model="viewState"
              name="view-change-check"
              unchecked-value="grid"
              value="table"
              switch
              class="my-custom-control-label"
            >
              <span class="pointer-text"> Table </span>
            </b-form-checkbox>
          </div>
          <div class="item">
            <b-button class="bttn bttn--lg bttn--orange" @click="openAddModal(null)">
              {{ $t("dashboard.share_video_add_new_video", "Add new video") }}
            </b-button>
          </div>
        </div>
      </div>

      <slot
        :list="list"
        :remove="remove"
        :viewState="viewState"
        :loading="loading"
        :openAddModal="openAddModal"
      />
    </div>

    <share-video-add-modal ref="addModal" />
  </div>
</template>

<script>
import { notificationsMixin } from "@/mixins";
import { mapActions, mapState } from "vuex";
import ShareVideoAddModal from "./ShareVideoAddModal.vue";

export default {
  components: { ShareVideoAddModal },

  mixins: [notificationsMixin],

  data() {
    return {
      viewState: "table",
    };
  },

  computed: {
    ...mapState({
      cmpId: (state) => state.shareVideo.campaign.id,
      loading: (state) => state.shareVideo.shareVideoListLoading,
      list: (state) => state.shareVideo.shareVideoList,
    }),
  },

  methods: {
    ...mapActions({
      fetchRemoveShareVideo: "shareVideo/fetchRemoveShareVideo",
    }),

    openAddModal(originalData) {
      this.$refs.addModal.open({ originalData });
    },

    remove({ module_data_id }) {
      const message = this.$t(
        "dashboard.share_video_confirm_delete",
        "Please confirm video delete"
      );

      const options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.fetchRemoveShareVideo({
          cID: this.cmpId,
          id: module_data_id || 0,
        }).catch(this.$_notificationsMixin_handleCatch);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-custom-control-label {
  ::v-deep .custom-control-label {
    &::before {
      cursor: pointer;
    }
    &::after {
      cursor: pointer;
    }
  }
}
.pointer-text {
  cursor: text;
}
</style>
