<template>
  <b-card no-body>
    <b-tabs
      v-model="langTabIndex"
      card
      pills
      no-nav-style
      active-nav-item-class="p-0"
      content-class="p-0"
    >
      <b-tab v-for="(tab, i) in langTabs" :key="'dyn-tab-' + i" title-link-class="p-0">
        <template v-slot:title>
          <li
            class="language-selected__unit"
            :class="{ active: langTabIndex === i, 'add-modal': i > 0 }"
          >
            <span class="d-flex flex-column align-items-center" style="line-height: 1">
              {{ getLond(tab.short) }}
              <small class="text-lowercase">
                ({{ $t(`edit_campaign.${tab.tag === "default" ? "untagged" : tab.tag}`) }})
              </small>
            </span>
            <b-icon
              v-if="i > 0"
              icon="x"
              class="ml-2"
              font-scale="1.5"
              variant="danger"
              @click="removeLanTab(i)"
            ></b-icon>
          </li>
        </template>

        <div class="d-flex align-items-center justify-content-center mb-3">
          <p class="selected-tag-text m-0 mr-2">
            {{ $t("edit_campaign.select_description_tag") }}
          </p>
          <b-dropdown
            variant="-sm bttn--blue"
            right
            :text="
              tab.tag == 'default' ? $t('edit_campaign.untagged') : $t(`edit_campaign.${tab.tag}`)
            "
          >
            <b-dropdown-item
              v-for="(tag, i) in getUnusedTags(tab.short)"
              :key="`tag-${i}`"
              @click="onChangeTag(tab, tag, tab.short)"
            >
              {{ $t(`edit_campaign.${tag === "default" ? "untagged" : tag}`) }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <quill-editor
          :value="tab.content"
          @input="tab.content = $event.value"
          class="m-0"
          :id="'quill-edito-' + tab.short"
          :options="editorOption"
        >
        </quill-editor>
      </b-tab>

      <template v-slot:tabs-end>
        <b-dropdown text="+ Add language" class="dropdown" variant="-sm bttn--bordered-blue">
          <b-dropdown-item v-for="(lang, i) in langList" :key="i" @click="newLanTab(lang)">
            {{ lang.long }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import CustomVideo from "./vue-quill-editor/formats/custom-video";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      langTabIndex: 0,
      langTabs: [],
      tagsTabContent: new Map(),
      descriptionTags: ["countdown", "primary_goal", "bonus_goal", "completed", "default"],
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ["bold", "underline", "strike", "italic"],
              ["blockquote"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ align: [] }],
              [{ color: [] }, { background: [] }],
              ["image"],
              [CustomVideo.blotName],
              ["link"],
              ["clean"],
            ],
          },
          imageResize: {
            modules: ["Resize", "DisplaySize"],
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
          },
        },
      },
    };
  },

  computed: {
    ...mapState({
      locale: (state) => state.i18n.locale,
    }),
    ...mapGetters({
      getOldLangsVariant: "getOldLangsVariant",
    }),

    langList() {
      return this.getOldLangsVariant.filter((el) => this.getUnusedTags(el.short).length);
    },

    usedTagsByLang() {
      return this.langTabs.reduce((res, el) => {
        if (res.has(el.short)) {
          res.get(el.short).push(el);
        } else {
          res.set(el.short, [el]);
        }
        return res;
      }, new Map());
    },
  },

  watch: {
    langTabs: {
      deep: true,
      handler(val) {
        for (const value of this.tagsTabContent.values()) {
          const el = val.find((v) => v.short === value.short && v.tag === value.tag);
          if (el) {
            this.tagsTabContent.set(`${el.tag}-${el.short}`, el);
          }
        }

        this.$emit(
          "input",
          Array.from(this.tagsTabContent.values(), (el) => ({
            ...el,
            tag: el.tag === "default" ? "" : el.tag,
          }))
        );
      },
    },
  },

  created() {
    if (this.value.length) {
      this.langTabs = this.value;
    } else {
      const exists = this.getOldLangsVariant.map((lv) => lv.short).includes(this.locale);
      const defaultLocale = exists ? this.locale : "en";

      this.newLanTab(this.langList.find((e) => e.short === defaultLocale));
      this.onChangeTag(this.langTabs[0], "default");
    }
  },

  methods: {
    getLond(short) {
      return this.getOldLangsVariant.find((el) => el.short === short).long;
    },

    getUnusedTags(lang) {
      const usedTags = this.usedTagsByLang.get(lang);
      const contains = (l) => (usedTags ? usedTags.includes(l) : false);
      return this.descriptionTags.filter((v) => !contains(v)).reverse();
    },

    newLanTab(lang) {
      const tags = this.getUnusedTags(lang.short);
      this.langTabs.push({ ...lang, content: "", tag: tags[0] });
    },

    removeLanTab(i) {
      this.langTabs.splice(i, 1);
    },

    onChangeTag(tab, tag) {
      const getId = (tag, short) => `${tag}-${short}`;

      this.tagsTabContent.set(getId(tab.tag, tab.short), { ...tab });

      const foundTab = this.tagsTabContent.get(getId(tag, tab.short)) || null;

      if (!foundTab) {
        this.tagsTabContent.set(getId(tag, tab.short), {
          ...tab,
          tag: tag,
          content: "",
        });

        tab.tag = tag;
        tab.content = "";
      } else {
        tab.tag = foundTab.tag;
        tab.content = foundTab.content;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.language-selected__unit {
  &.add-modal {
    padding: 0px 10px 0 20px;
  }
}
</style>
