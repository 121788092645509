import { render, staticRenderFns } from "./dashboardTeamsUnit.vue?vue&type=template&id=5b3f3f29&scoped=true&"
import script from "./dashboardTeamsUnit.vue?vue&type=script&lang=js&"
export * from "./dashboardTeamsUnit.vue?vue&type=script&lang=js&"
import style0 from "./dashboardTeamsUnit.vue?vue&type=style&index=0&id=5b3f3f29&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b3f3f29",
  null
  
)

export default component.exports