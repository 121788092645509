<template>
  <div v-if="show" class="unpublished-error">
    <div class="notice">
      <div class="notice__content">
        <p class="notice__text">
          {{
            $t(
              "dashboard.legal_entity_not_linked_error_msg",
              "The campaign has gateways without a legal entity. Please contact your campaign manager to resolve issue."
            )
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

const exclude = ["ojc", "pledge", "pledgeonly"];

export default {
  data() {
    return {
      show: false,
    };
  },

  computed: {
    ...mapState({
      selectedCampaign: (state) => {
        const { campaigns } = state.campaigns;
        const campaign = campaigns.find((el) => el.id === state.dashboard.selectedCampaign);
        return campaign;
      },
    }),
  },

  watch: {
    selectedCampaign: {
      handler(v) {
        if (v) {
          this.runCheckTime(v);
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(["getGatewaysAndReturn"]),

    getGateways() {
      return this.getGatewaysAndReturn();
    },

    runCheckTime(selectedCampaign) {
      const start = selectedCampaign.attributes.start_date;

      const nowTime = new Date();
      const startTime = new Date(start * 1000);
      const difference = startTime - nowTime.setHours(nowTime.getHours() + 1);
      const resultInSeconds = Math.round(difference / 1000);

      if (resultInSeconds <= 3600) {
        this.getGateways().then((gtwList) => {
          this.show = gtwList.some((gtw) => {
            return (
              !exclude.includes(gtw.attributes.gateway) &&
              gtw.attributes.active &&
              gtw.attributes.entity_id === 0
            );
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
.unpublished-error {
  padding: 15px;
  background-color: #f6e3e2;
  border: 2px solid darken($color: #f6e3e2, $amount: 10%);
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  a {
    margin-left: auto;
  }
}
.notice {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 468px) {
    flex-direction: row;
    align-items: center;
  }
  &__warning {
    margin-bottom: 15px;
    width: 63px;
    min-width: 63px;
    height: auto;
    flex-shrink: 0;
    align-self: center;
    @media only screen and (min-width: 468px) {
      margin-right: 30px;
      margin-bottom: initial;
    }
  }
  &__content {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    justify-content: center;
  }
  &__title {
    margin-bottom: 5px;
    font-family: $vito-bold;
    font-size: 16px;
    color: $fd;
    line-height: 1.125;
  }
  &__text {
    margin-bottom: 0;
    font-family: $vito-bold;
    font-size: 14px;
    line-height: 1.125;
    color: $fd;
  }
  .entity-modal {
    margin-left: auto;
  }

  ::v-deep .bttn-bordered {
    border-color: $lr-100;
    color: #ffffff;
    background-color: $lr-100;
    font-family: $vito-bold;
    &--sm {
      min-width: 180px;
    }
  }
}
</style>
