<template lang="html">
  <div>
    <!-- Modal Component -->
    <b-modal id="changeTimezone" size="lg">
      <div slot="modal-header">
        <h2>
          {{ $t("edit_campaign.edit_time_zone_modal") }}
        </h2>
        <div class="modal-header__hide">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <div class="controls">
          <button type="button" class="bttn bttn--lg bttn--blue" name="button">
            {{ $t("edit_campaign.edit_time_zone_close") }}
          </button>
          <button
            form="change-timezone"
            :disabled="sending"
            :class="sending ? 'disabled bttn bttn-lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
          >
            {{ $t("edit_campaign.edit_time_zone_save") }}
          </button>
        </div>
      </div>

      <form id="change-timezone" @submit.prevent="saveFormTimeZone">
        <error :errors="errors"></error>

        <div class="form-block">
          <div class="timezone-edit">
            <div class="text-in-modal">
              <p>
                {{ $t("account.timezone_modal") }}
              </p>
            </div>

            <div class="multiselect-wrapper">
              <multiselect v-model="multiselectData" label="text" track-by="text"> </multiselect>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import error from "@/components/errors.vue";

import { mapState } from "vuex";

import Multiselect from "vue-multiselect";

export default {
  data: function () {
    return {
      errors: [],

      sending: false,

      multiselectData: null,
    };
  },

  components: {
    error: error,
    Multiselect,
  },

  computed: mapState({}),

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../../../styles/_vars.scss";

.timezone-edit {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    display: block;
  }
}

.multiselect-wrapper {
  width: calc(100% - 200px);
  max-width: 350px;
}

.text-in-modal {
  width: 200px;
  p {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 768px) {
    padding-bottom: 10px;
  }
}

.change-time-zone-button {
  color: $lr-100;
}

.modal-footer {
  .controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
</style>
