<template>
  <multiselect-search
    v-model="optionSelected"
    :allow-empty="true"
    :search-url="getSearchUrl"
    :search-options="searchOptions"
    :closeOnSelect="true"
    class="w-100"
    openPrefetch
    @loading="$emit('loading', $event)"
  >
  </multiselect-search>
</template>

<script>
import urls from "@/constants/urls";
import MultiselectSearch from "@/components/multiselect-search.vue";

export default {
  components: {
    MultiselectSearch,
  },
  props: {
    value: {
      type: [Object],
      default: null,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },

  data() {
    return {
      innerSelected: null,
    };
  },

  mounted() {},

  computed: {
    optionSelected: {
      set(val) {
        this.innerSelected = val;
        this.$emit("input", val);
      },
      get() {
        return this.innerSelected;
      },
    },

    getSearchUrl() {
      const orgID = localStorage.getItem("orgId");
      return urls.customersSearchByQuery(orgID);
    },

    searchOptions() {
      return {
        placeholder: "Start typyng name or email",
        changeParams: ({ page, query }) => ({
          query,
          page,
          per_page: this.perPage,
        }),
        customLabel: (e) => `[${e.email}] ${e.payment_firstname} ${e.payment_lastname}`,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
