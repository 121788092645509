<template>
  <div>
    <div class="text-right">
      <b-button v-b-toggle.add-form class="bttn bttn--lg bttn--orange">
        {{ $t("teams.team_pages_open_form", "Add page") }}
      </b-button>
    </div>

    <b-collapse id="add-form" role="tabpanel">
      <div class="py-4">
        <b-form-group>
          <template #label>
            {{ $t("teams.team_pages_book", "Book") }}
          </template>

          <multiselect-search
            v-model="book"
            :open-prefetch="true"
            :search-url="getSearchUrl"
            :search-options="getSearchOptions('book')"
            skipExternalSerching
            closeOnSelect
          >
            <template #no-options>
              {{ $t("teams.team_pages_no_options", "Please enter 1 or more characters") }}
            </template>
            <template #no-more-infinite>
              {{ $t("teams.team_pages_book_no_more", "No more books") }}
            </template>
          </multiselect-search>
        </b-form-group>

        <b-form-group v-if="book">
          <template #label>
            {{ $t("teams.team_pages_chapter", "Chapter") }}
          </template>

          <multiselect-search
            v-model="chapter"
            :open-prefetch="true"
            :search-url="getSearchUrl"
            :search-options="getSearchOptions('chapter')"
            skipExternalSerching
            closeOnSelect
          >
            <template #no-options>
              {{ $t("teams.team_pages_no_options", "Please enter 1 or more characters") }}
            </template>
            <template #no-more-infinite>
              {{ $t("teams.team_pages_chapter_no_more", "No more chapters") }}
            </template>
          </multiselect-search>
        </b-form-group>

        <b-form-group v-if="book && chapter">
          <template #label>
            {{ $t("teams.team_pages_page", "Page") }}
          </template>

          <multiselect-search
            v-model="page"
            :open-prefetch="true"
            :search-url="getSearchUrl"
            :search-options="getSearchOptions('page')"
            skipExternalSerching
            closeOnSelect
          >
            <template #no-options>
              {{ $t("teams.team_pages_no_options", "Please enter 1 or more characters") }}
            </template>
            <template #no-more-infinite>
              {{ $t("teams.team_pages_no_more", "No more pages") }}
            </template>
          </multiselect-search>
        </b-form-group>

        <div class="text-center">
          <b-button
            class="bttn bttn--lg bttn--orange"
            :disabled="!book || !chapter || !page"
            @click="submit"
          >
            {{ $t("teams.team_add_page", "Add") }}
          </b-button>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import multiselectSearch from "@/components/multiselect-search.vue";
import urls from "@/constants/urls";

export default {
  components: { multiselectSearch },

  props: {
    team: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      book: null,
      chapter: null,
      page: null,
    };
  },

  computed: {
    cId() {
      return this.team.campaign_id;
    },

    tId() {
      return this.team.id;
    },

    getSearchUrl() {
      return urls.getSeferTorahTeamPagesPublic.replace(":cid", this.cId);
    },
  },

  watch: {
    book(nv, ov) {
      if (ov && nv === null) {
        this.page = null;
        this.chapter = null;
      }
      if (nv && ov && nv.id !== ov.id) {
        this.page = null;
        this.chapter = null;
      }
    },

    chapter(nv, ov) {
      if (ov && nv === null) {
        this.page = null;
      }
      if (nv && ov && nv.id !== ov.id) {
        this.page = null;
      }
    },
  },

  methods: {
    getSearchOptions(type) {
      const extraParams = {};

      const getPID = () => {
        switch (type) {
          case "book":
            return 0;
          case "chapter":
            return this.book?.id;
          case "page":
            extraParams.status = 1; // show only available chapters/pages
            return this.chapter?.id;
          default:
            return 0;
        }
      };

      return {
        placeholder: this.$t("dashboard.team_pages_book_placeholder", "Select book"),
        changeParams: ({ page, per_page }) => {
          const pid = getPID();

          return {
            page,
            per_page,
            parent_id: pid,
            ...extraParams,
          };
        },
        customLabel: (e) => `[${e.id}] ${e.title}`,
        processResults: (data) =>
          data.map((el) => ({
            id: el.id,
            ...el.attributes,
          })),
      };
    },

    clear() {
      this.page = null;
    },

    submit() {
      const { book, chapter, page } = this;

      this.$emit("submit", { book, chapter, page });
    },
  },
};
</script>

<style></style>
