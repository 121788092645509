<template>
  <div v-if="list.length > 0" class="current-campaigns">
    <template v-if="layout === 'grid'">
      <h2 class="h2-subcat">
        {{ $t(`campaigns.${this.type}_campaigns`) }}
      </h2>
      <ViewByOrgSetting
        class="campaigns-wrapper"
        org-setting="campaignWizardVersion"
        v-slot="{ meta: { meta } }"
      >
        <campaign-card
          layout="grid"
          v-for="(campaign, index) in list"
          :campaigndata="campaign"
          :key="index"
          :crowdfundingFundraisingOptions="meta"
          @update-list="updateList"
        />
      </ViewByOrgSetting>
    </template>
    <template v-else-if="layout === 'list'">
      <b-list-group>
        <b-list-group-item
          class="campaigns-list__item flex-column align-items-start"
          v-for="(campaign, index) in list"
          :key="index"
        >
          <ViewByOrgSetting
            class="w-100"
            org-setting="campaignWizardVersion"
            v-slot="{ meta: { meta } }"
          >
            <campaign-card
              layout="list"
              :campaigndata="campaign"
              :crowdfundingFundraisingOptions="meta"
              @update-list="updateList"
            />
          </ViewByOrgSetting>
        </b-list-group-item>
      </b-list-group>
    </template>
    <see-more-btn-wraper
      :value="pageInternal"
      @input="onSeeMoreBtnInput"
      :perPage="perPage"
      :totalRecords="total"
      class="text-center w-100"
    >
      <template #btn="{ loadMore, hasMore }">
        <button
          v-if="hasMore"
          type="button"
          class="btn btn--sm bttn--orange m-2"
          :disabled="loading"
          @click="loadMore"
        >
          <b-spinner v-if="loading" variant="light" small class="mr-2" />
          {{ $t("campaigns.campaigns_see_more", "See More") }}
        </button>
      </template>
    </see-more-btn-wraper>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SeeMoreBtnWraper from "@/components/see-more-btn-wraper.vue";
import ViewByOrgSetting from "@/views/campaigns/campaign-wizards/viewByOrgSetting.vue";
import campaignCard from "./campaignPlate.vue";

export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    layout: {
      type: String,
      default: "grid",
    },
    page: {
      type: Number,
      default: 1,
    },
    externalFetch: Boolean,
  },

  components: {
    campaignCard,
    SeeMoreBtnWraper,
    ViewByOrgSetting,
  },

  data() {
    return {
      pageInternal: this.page,
      perPage: 10,
    };
  },

  computed: {
    ...mapState({
      campaigns: (state) => state.campaigns,
      loading: (state) => state.campaigns.loadingCampaigns,
    }),

    list() {
      return this.campaigns[this.type];
    },

    total() {
      return this.campaigns.total[this.type];
    },
  },

  watch: {
    pageInternal() {
      if (this.externalFetch) {
        return;
      }

      this.getCampaigns({ action: this.pageInternal > 1 ? "see-more" : "" });
    },
  },

  created() {
    if (!this.externalFetch) {
      this.getCampaigns();
      this.$root.$on("campaignList::getCampaignsByPage", this.getCampaigns);
    }
  },

  beforeDestroy() {
    if (!this.externalFetch) {
      this.$root.$off("campaignList::getCampaignsByPage");
    }
  },

  methods: {
    updateList() {
      if (this.externalFetch) {
        this.$emit("update-list");
        return;
      }

      this.pageInternal = 1;
      this.$nextTick(() => {
        this.getCampaigns();
      });
    },

    getCampaigns(extendPayload = {}) {
      const extend = ["campaign_stats", "media", "meta", "donation_levels", "donation_streams"];
      const params = {
        page: this.pageInternal,
        perPage: this.perPage,
        sort_by: ["-startdate", "campaign_mode_dashboard", "hide_directdonation"],
        extend,
      };

      const payload = {
        ...extendPayload,
        type: this.type,
        params,
      };

      this.$store.dispatch("getCampaigns", payload);
    },

    onSeeMoreBtnInput(value) {
      this.pageInternal = value;
      this.$emit("update:page", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.campaigns-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.current-campaigns {
  margin-bottom: 20px;
  .h2-subcat {
    margin-bottom: 20px;
  }
}
</style>
