<template>
  <div v-if="journalAd" class="donation-unit">
    <div class="donation-unit__top_row">
      <div class="donation-unit__cell donation-unit__id">
        {{ id }}
      </div>

      <div class="donation-unit__cell donation-unit__name">
        {{ data.donor_name }}
      </div>

      <div class="donation-unit__cell donation-unit__name cut-text">
        {{ data.donor_dedication }}
      </div>

      <div v-if="hasAccessEdit" class="donation-unit__action">
        <button class="bttn bttn-round edit" @click="edit">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
        <button class="bttn bttn-round delete" @click="remove">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    journalAd: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    ...mapState({
      hasAccessEdit: (state) => state.account.access.CanEditCampaignTeams,
    }),

    id() {
      return this.journalAd.id;
    },

    data() {
      return JSON.parse(this.journalAd.attributes.data);
    },
  },

  methods: {
    edit() {
      this.$emit("edit", this.id);
    },

    remove() {
      this.$emit("remove", this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.tooltip-box {
  display: inherit;
  margin-right: 10px;
}

.donation-comment {
  font-weight: bold;
  color: #414359;
  line-height: 1;
  font-size: 14px;
  padding-left: calc(10% + 40px);
  span {
    color: #bbbcc4;
    @media (max-width: 768px) {
      display: block;
      padding-bottom: 10px;
    }
  }
  @media (max-width: 768px) {
    padding-left: 0;
    text-align: center;
    padding-top: 20px;
  }
}
.bttn-round--gray {
  &:hover {
    background-color: darken(#bbbcc4, 15%);
  }
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 70%;
  white-space: nowrap;
}
</style>
