<template>
  <div>
    <b-modal id="viewReminder" size="lg">
      <div slot="modal-header">
        <h2>{{ $t("leads.modal_heder") }}</h2>
        <div class="modal-header__hide" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
          {{ $t("leads.cancel") }}
        </button>
      </div>

      <div v-if="reminder">
        <div class="data-view" v-for="(value, key) in reminder.attributes" :key="key">
          <div class="data-view__title">
            {{ $t("leads." + key) }}
          </div>
          <div class="data-view__data" v-if="key != 'created_at' && key != 'campaign_id'">
            {{ value }}
          </div>
          <div class="data-view__data" v-if="key == 'created_at'">
            {{ getDate(value) }}
          </div>
          <div class="data-view__data" v-if="key == 'campaign_id'">
            {{ returnCampaign(value) }}
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";

import { mapState } from "vuex";

export default {
  data: function () {
    return {
      reminder: null,
    };
  },

  computed: mapState({
    campaigns: (state) => {
      return state.campaigns.campaigns;
    },
  }),

  methods: {
    returnCampaign: function (campaignId) {
      let campaign = this.campaigns.filter((el) => {
        return el.id == campaignId;
      });

      if (campaign.length > 0) {
        return campaign[0].attributes.title;
      }

      return "";
    },

    openEditModal: function (reminder) {
      this.reminder = reminder;

      this.$root.$emit("bv::show::modal", "viewReminder");
    },

    hideModal() {
      this.reminder = null;

      this.$root.$emit("bv::hide::modal", "viewReminder");
    },

    getDate: function (date) {
      if (date) {
        return moment.unix(date).format("YYYY-MM-DD h:mm a");
      }

      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";

#viewReminder .modal-footer {
  > div {
    display: flex;
    justify-content: flex-end;
  }
}

.data-view {
  margin-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 992px) {
    flex-wrap: nowrap;
  }
  &__title {
    min-width: 100%;
    padding: 10px 0;
    font-family: "ProximaNova-Bold", sans-serif;
    font-size: 16px;
    @media only screen and (min-width: 992px) {
      min-width: 30%;
      max-width: 30%;
      margin-right: 20px;
    }
  }
  &__data {
    flex-grow: 1;
    padding: 10px 0;
    font-family: "ProximaNova", sans-serif;
    font-size: 16px;
    word-wrap: break-word;
    max-width: 65%;
  }
}

.btn-oval {
  width: auto;
  height: 45px;
  padding: 0 23px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  border: none;
  font-family: "ProximaNova-Bold", sans-serif;
  font-size: 16px;
  color: #ffffff;
  background-color: #cc6d4c;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover,
  &:active {
    background-color: darken(#cc6d4c, 5%);
    box-shadow: none;
    outline: 0;
  }
  &:focus {
    background-color: #cc6d4c;
    box-shadow: none;
    outline: 0;
  }
  &--cancel {
    background-color: gray;
    &:hover,
    &:active {
      background-color: darken(gray, 5%);
    }
    &:focus {
      background-color: gray;
    }
  }
}

@media (max-width: 768px) {
  .data-view__data {
    max-width: 100%;
  }
}
</style>
