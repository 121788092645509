<template>
  <div>
    <campaign-templates
      :value="state.campaignTemplate"
      @input="(v) => setState('campaignTemplate', v)"
      :data="state.campaignTemplates"
      :loading="loading"
    />
  </div>
</template>

<script>
import campaignTemplates from "@/views/campaigns/campaign-edit-vue/subcomponents/campaignTemplates.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { campaignTemplates },
  props: {
    state: { type: Object, default: () => ({}) },
    setState: { type: Function, default: () => {} },
    options: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.loading = true;
    this.getCampaignTemplates({
      cId: this.getCurrentlyEditedInWizard || "0",
    })
      .then((data) => {
        if (this.options?.showOnlyHolidayTemplates) {
          const holidayTemplates = data.filter((t) => t.attributes.type === "holiday");
          this.setState("campaignTemplate", holidayTemplates[0]?.attributes.code || "");
          return this.setState("campaignTemplates", holidayTemplates);
        }

        return this.setState(
          "campaignTemplates",
          data.filter((t) => t.attributes.type !== "holiday")
        );
      })
      .finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters({
      getCurrentlyEditedInWizard: "getCurrentlyEditedInWizard",
    }),
  },
  methods: {
    ...mapActions({
      getCampaignTemplates: "getCampaignTemplates",
    }),
  },
};
</script>
