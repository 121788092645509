<template>
  <div>
    <div class="category-title">
      <h1 class="h1-like">
        {{ $t("dashboard.org_tickets_page_title", "Tickets") }}
      </h1>

      <div class="action-block">
        <div class="item">
          <b-button class="bttn bttn--lg bttn--orange" @click="addTicket">
            {{ $t("dashboard.add_new_org_ticket", "+ Add ticket") }}
          </b-button>
        </div>
      </div>
    </div>

    <org-tickets-tabs v-model="tab" />

    <org-tickets-table @view="viewTicket" @edit="editTicket" @remove="deleteTicket" />

    <div class="pagination">
      <b-pagination
        v-show="count > per_page"
        v-model="page"
        :total-rows="count"
        :per-page="per_page"
        size="sm"
      >
      </b-pagination>
    </div>

    <org-tickets-modal ref="modal" />
    <org-tickets-view-modal ref="modalView" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { notificationsMixin } from "@/mixins";

import OrgTicketsModal from "./OrgTicketsModal.vue";
import OrgTicketsTable from "./OrgTicketsTable.vue";
import OrgTicketsTabs from "./OrgTicketsTabs.vue";
import OrgTicketsViewModal from "./OrgTicketsViewModal.vue";

export default {
  components: {
    OrgTicketsTable,
    OrgTicketsModal,
    OrgTicketsTabs,
    OrgTicketsViewModal,
  },

  mixins: [notificationsMixin],

  data() {
    return {
      tab: "to_do",
      page: 1,
      per_page: 15,
    };
  },

  computed: {
    ...mapState({
      count: (s) => s.orgTickets.count,
    }),
  },

  watch: {
    page() {
      this.getOrgTickets();
    },
    tab() {
      this.getOrgTickets();
    },
  },

  created() {
    this.$root.$on("updateOrgTicketList", ({ type } = {}) => {
      if (type === "add") {
        this.tab = "in_process";
      }
      this.page = 1;
      this.getOrgTickets();
    });
  },

  mounted() {
    this.getOrgTickets();
  },

  beforeDestroy() {
    this.$root.$off("updateOrgTicketList");
  },

  methods: {
    ...mapActions({
      fetchOrgTickets: "orgTickets/fetchOrgTicketsAndStore",
      fetchDeleteOrgTicket: "orgTickets/fetchDeleteOrgTicket",
      fetchOrgToDoTicketsCount: "orgTickets/fetchOrgToDoTicketsCount",
    }),

    getOrgTickets() {
      const params = {
        parent_id: 0,
        page: this.page,
        per_page: this.per_page,
        sort_by: "-created_at",
      };

      switch (this.tab) {
        case "to_do":
          params.status = "to_do";
          params.assigned_on = "organization";
          break;

        case "in_process":
          params.status = "to_do";
          params.assigned_on = "charidy";
          break;

        case "completed":
          params.status = "completed";
          break;
      }

      this.fetchOrgTickets({ params }).catch(this.$_notificationsMixin_handleCatch);
      // Fetch counts
      this.fetchOrgToDoTicketsCount({ status: "to_do" });
      this.fetchOrgToDoTicketsCount({ status: "in_process" });
      this.fetchOrgToDoTicketsCount({ status: "completed" });
    },

    addTicket() {
      this.$refs.modal.open("add");
    },

    editTicket(item) {
      this.$refs.modal.open("edit", item);
    },

    viewTicket(item) {
      this.$refs.modalView.open(item);
    },

    deleteTicket({ id }) {
      let message = this.$t("dashboard.confirm_delete_org_ticket", `Do you want to delete ${id}`);

      let options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.fetchDeleteOrgTicket({ id })
          .then(() => {
            this.$_notificationsMixin_makeToast(
              "Success!",
              this.$t("dashboard.org_tickets_success_delete", "Deleted successfully"),
              "success"
            );
            this.getOrgTickets();
          })
          .catch(this.$_notificationsMixin_handleCatch);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
