<template>
  <div class="rds-status">
    <div class="rds-status__bottom">
      <text-3-rows>
        <template #top>
          {{ stats.active_count }}
        </template>
        <template #middle>{{ $t("dashboard.rds_status_active", "Active") }}</template>
      </text-3-rows>
      <text-3-rows>
        <template #top>
          {{ stats.completed_count }}
        </template>
        <template #middle>{{ $t("dashboard.rds_status_completed", "Completed") }}</template>
      </text-3-rows>
      <text-3-rows>
        <template #top>
          {{ stats.canceled_count }}
        </template>
        <template #middle>{{ $t("dashboard.rds_status_canceled", "Canceled") }}</template>
      </text-3-rows>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import text3Rows from "./text-3-rows.vue";

export default {
  components: {
    text3Rows,
  },
  computed: {
    ...mapState({
      stats: (s) => s.rds.stats,
    }),
  },
};
</script>

<style lang="scss" scoped>
.rds-status {
  flex: 1;
  margin-left: 60px;
  @media screen and (max-width: 425px) {
    margin-left: 0;
  }
  &__title {
    text-transform: uppercase;
    font-weight: 700;
  }
  &__bottom {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
