import urls from "@/constants/urls";
import axios from "axios";

const state = {
  collection: [],
  loading: false,
};

const getters = {};

const mutations = {
  setUpdates(state, value) {
    state.collection = value;
  },
  setLoading(state, value) {
    state.loading = value;
  },
};

const actions = {
  fetchUpdates({ rootState, commit }, selectedCampaign) {
    let orgId = localStorage.getItem("orgId");
    const cid = selectedCampaign || rootState.dashboard.selectedCampaign;
    commit("setLoading", true);
    return axios
      .get(urls.updates.replace(":orgId", orgId).replace(":campaignId", cid))
      .then(({ data }) => {
        commit("setUpdates", data.data);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  postUpdate({ commit, rootState, dispatch }, { update, selectedCampaign }) {
    let orgId = localStorage.getItem("orgId");
    const cid = selectedCampaign || rootState.dashboard.selectedCampaign;
    commit("setLoading", true);
    return axios
      .post(urls.updates.replace(":orgId", orgId).replace(":campaignId", cid), {
        data: { attributes: { ...update } },
      })
      .then(({ data }) => {
        dispatch("fetchUpdates");
        return data;
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  editUpdate({ rootState, commit, dispatch }, { uid, update, selectedCampaign }) {
    let orgId = localStorage.getItem("orgId");
    const cid = selectedCampaign || rootState.dashboard.selectedCampaign;
    commit("setLoading", true);
    return axios
      .patch(urls.update.replace(":oid", orgId).replace(":cid", cid).replace(":uid", uid), {
        data: { attributes: { ...update } },
      })
      .then(({ data }) => {
        dispatch("fetchUpdates");
        return data;
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
  removeUpdate({ rootState, commit, dispatch }, { uid, selectedCampaign }) {
    let orgId = localStorage.getItem("orgId");
    const cid = selectedCampaign || rootState.dashboard.selectedCampaign;
    commit("setLoading", true);
    return axios
      .delete(urls.update.replace(":oid", orgId).replace(":cid", cid).replace(":uid", uid))
      .then(({ status }) => {
        dispatch("fetchUpdates");
        return status;
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
