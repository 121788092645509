import { render, staticRenderFns } from "./donation-row.vue?vue&type=template&id=47d7b68a&scoped=true&"
import script from "./donation-row.vue?vue&type=script&lang=js&"
export * from "./donation-row.vue?vue&type=script&lang=js&"
import style0 from "./donation-row.vue?vue&type=style&index=0&id=47d7b68a&prod&media=screen&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47d7b68a",
  null
  
)

export default component.exports