<template>
  <b-overlay :show="loading">
    <div class="quill-editor" :style="{ '--editor-direction': editorDirection }">
      <slot name="toolbar"></slot>
      <div ref="editor"></div>
    </div>
  </b-overlay>
</template>

<script>
import _Quill from "quill";
import defaultOptions from "./options";
const Quill = window.Quill || _Quill;
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import { unwrapContentValue } from "@/components/vue-quill-editor/utils";
import CustomVideo from "./formats/custom-video";

export default {
  name: "quill-editor",
  data() {
    return {
      optionsRaw: {},
      defaultOptions,
    };
  },
  props: {
    value: {
      type: [String, Object],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    globalOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    emitWithChangeSource: Boolean,
    loading: Boolean,
    editorDirection: {
      type: String,
      default: "ltr",
    },
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    this.quill = null;
    delete this.quill;
  },
  methods: {
    // Init Quill instance
    initialize() {
      if (this.$el) {
        // Options
        this.optionsRaw = Object.assign({}, this.defaultOptions, this.globalOptions, this.options);

        this.quill = new Quill(this.$refs.editor, this.optionsRaw);
        this.quill.enable(false);

        // Set editor content
        if (this.value?.originalHtml) {
          this.quill.pasteHTML(this.value.originalHtml);
        } else {
          this.quill.pasteHTML(this.value);
        }

        // Disabled editor
        if (!this.disabled) {
          this.quill.enable(true);
        }

        // Mark model as touched if editor lost focus
        this.quill.on("selection-change", (range) => {
          if (!range) {
            this.$emit("blur", this.quill);
          } else {
            this.$emit("focus", this.quill);
          }
        });

        // Update model if text changes
        this.quill.on("text-change", (value, oldValue, source) => {
          if (source === "silent") {
            return;
          }

          let html = this.quill.root.innerHTML;
          const root = this.quill.root;
          const delta = this.quill.getContents();

          const converter = new QuillDeltaToHtmlConverter(delta.ops, {
            inlineStyles: true,
          });

          converter.renderCustomWith((customOp) => {
            if (customOp.insert.type === CustomVideo.blotName) {
              const url = customOp.insert.value?.src;
              return CustomVideo.chooseElementBasedOnUrl(url, "html");
            } else {
              throw new Error("Unmanaged custom blot!");
            }
          });

          let inlineStylesHtmlRaw = converter.convert();

          const firstChildren = root.children?.length === 1 && root.children[0];
          const secondChildren = firstChildren.children?.length === 1 && firstChildren.children[0];
          if (firstChildren?.nodeName === "P" && secondChildren?.nodeName === "BR") {
            html = "";
            inlineStylesHtmlRaw = "";
            this.quill.pasteHTML("");
          }

          const inputValue = {
            source,
            originalHtml: html,
            inlineStylesHtml: inlineStylesHtmlRaw,
            get value() {
              return this.originalHtml;
            },
          };

          if (source === "api") {
            this.$emit("change", inputValue);
          } else {
            this.$emit("input", inputValue);
          }
        });

        this.$emit("ready", this.quill);
      }
    },
  },
  watch: {
    disabled(newVal) {
      if (this.quill) {
        this.quill.enable(!newVal);
      }
    },
    value: {
      handler(...values) {
        if (!this.emitWithChangeSource) {
          return;
        }
        if (values[0]?.source === "user" && values[1]?.source === "user") {
          return;
        }
        const newVal = unwrapContentValue(values[0]);
        const oldVal = unwrapContentValue(values[1]);
        if (newVal.length > 0 && newVal !== oldVal) {
          this.quill.pasteHTML(newVal);
          this.quill.setSelection(this.quill.getLength(), 0);
        } else if (newVal.length === 0) {
          this.quill.setText(newVal, "silent");
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.quill-editor {
  direction: var(--editor-direction, ltr);
}
</style>
