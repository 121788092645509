const onlyPositive = {
  lazy: true,
  handler: (value) => {
    const number = Number(value);
    if (Number.isNaN(number)) {
      return value;
    }
    return Math.abs(value);
  },
};

const onlyPositivePercent = {
  lazy: true,
  handler: (value) => {
    if (value > 100) {
      return 100;
    }
    const number = Number(value);
    if (Number.isNaN(number)) {
      return value;
    }
    return Math.abs(value);
  },
};

export default {
  title: "DonationUpsell",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    upsell_thresholds: {
      title: "Upsell Thresholds",
      type: "list",
      default: [],
      expanded: true,
      items: {
        type: "object",
        properties: {
          amount_from: {
            title: "Amount From",
            type: "number",
            default: 1,
            min: 1,
            formatter: onlyPositive,
          },
          amount_to: {
            title: "Amount To",
            type: "number",
            default: 1,
            min: 1,
            formatter: onlyPositive,
          },
          upsell_type: {
            title: "Upsell Type",
            type: "enum",
            default: "onetime",
            items: {
              enum: [
                {
                  text: "onetime",
                  value: "onetime",
                },
                {
                  text: "recurring",
                  value: "recurring",
                },
                {
                  text: "installments",
                  value: "installments",
                },
              ],
            },
          },
          upsell_duration: {
            title: "Upsell Duration",
            type: "number",
            default: 0,
            formatter: onlyPositive,
          },
          upsell_message: {
            title: "Upsell Message",
            type: "string",
            default: "",
          },
          $upsell_amount_enum: {
            type: "object",
            component: "UpsellAmountEnum",
            expanded: true,
            properties: {
              upsell_amount: {
                title: "Upsell Amount",
                type: "number",
                default: 1,
                min: 1,
                formatter: onlyPositive,
              },
              upsell_amount_percentage: {
                title: "Upsell Amount Percentage",
                type: "number",
                default: 0,
                min: 0,
                max: 100,
                formatter: onlyPositivePercent,
              },
            },
          },
        },
      },
    },
  },
};
