<template>
  <div>
    <div class="campaign-images">
      <error :errors="errors"></error>

      <div class="images-section">
        <h4 class="images-section__title">Images</h4>

        <div class="images-preview">
          <div class="images-preview__sliders">
            <div class="item-slot item-slot--s-desktop">
              <slider-laptop
                tag="countdown_hero"
                :imageset="countDownImage"
                @cUpdFile="changeFile"
                @cDelFile="removeFile"
                @cUplFile="processFile"
                screen="t0"
              >
              </slider-laptop>
              <error-message
                ref="countdown_hero"
                :customMessage="customErrorMessage"
              ></error-message>
            </div>

            <div class="item-slot item-slot--s-mobile">
              <slider-mobile
                tag="countdown_hero_mobile"
                :imageset="countDownMobileImage"
                @cUpdFile="changeFile"
                @cDelFile="removeFile"
                @cUplFile="processFile"
                screen="m0"
              >
              </slider-mobile>
              <error-message
                ref="countdown_hero_mobile"
                :customMessage="customErrorMessage"
              ></error-message>
            </div>

            <div class="item-slot item-slot--c-desktop">
              <div class="upload-bar">
                <input
                  type="file"
                  name=""
                  :disabled="!hasAccess"
                  id="countdown_hero"
                  accept=".png, .jpg, .jpeg, .gif"
                />
                <label for="countdown_hero" class="upload-bar__label">
                  <svg
                    viewBox="0 0 46 39"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g
                        id="images_1"
                        transform="translate(-499.000000, -381.000000)"
                        fill="#107598"
                      >
                        <g id="Group-13" transform="translate(400.000000, 335.000000)">
                          <g id="noun_Plus_1894414" transform="translate(99.000000, 46.000000)">
                            <path
                              d="M42.7142857,0 L3.28571429,0 C1.47106439,0 0,1.45502841 0,3.24989691 L0,29.2490722 C0,31.0439407 1.47106439,32.4989691 3.28571429,32.4989691 L17.2828571,32.4989691 C16.6955446,33.4498728 15.8332917,34.2046104 14.807619,34.665567 C13.8,35.2722144 12.8361905,35.8355299 12.6171429,36.832165 C12.5644467,37.5142715 12.8405443,38.180927 13.3619048,38.6304412 C13.5819253,38.8789153 13.9045347,39.0145296 14.2380952,38.9987629 L31.7619048,38.9987629 C32.0507949,38.9946929 32.3263571,38.8778822 32.5285714,38.6737732 C33.0711428,38.2193421 33.3569852,37.5327206 33.2952381,36.832165 C33.1638095,35.7921979 32.2,35.2288825 31.1047619,34.665567 C30.0790892,34.2046104 29.2168364,33.4498728 28.6295238,32.4989691 L42.7142857,32.4989691 C44.5289356,32.4989691 46,31.0439407 46,29.2490722 L46,3.24989691 C46,1.45502841 44.5289356,0 42.7142857,0 Z M43.8095238,25.9991753 L2.19047619,25.9991753 L2.19047619,3.24989691 C2.19047619,2.65160741 2.68083099,2.16659794 3.28571429,2.16659794 L42.7142857,2.16659794 C43.319169,2.16659794 43.8095238,2.65160741 43.8095238,3.24989691 L43.8095238,25.9991753 Z"
                              id="Shape"
                              fill-rule="nonzero"
                            ></path>
                            <path
                              d="M22.992074,8 C23.5944518,8 24.0700132,8.47556143 24.0700132,9.07793923 L24.0700132,12.9299868 L27.9220608,12.9299868 C28.5085865,12.9299868 29,13.4055482 29,13.992074 C29,14.5944518 28.5085865,15.0700132 27.9220608,15.0700132 L24.0700132,15.0700132 L24.0700132,18.9220608 C24.0700132,19.5085865 23.5944518,20 22.992074,20 C22.4055482,20 21.9299868,19.5085865 21.9299868,18.9220608 L21.9299868,15.0700132 L18.0779392,15.0700132 C17.4755614,15.0700132 17,14.5944518 17,13.992074 C17,13.4055482 17.4755614,12.9299868 18.0779392,12.9299868 L21.9299868,12.9299868 L21.9299868,9.07793923 C21.9299868,8.47556143 22.4055482,8 22.992074,8 Z"
                              id="Shape"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>

                  <span>{{ $t("edit_campaign.image_for_desktop") }}</span>
                </label>
              </div>

              <span class="img-cimages__size-text">{{
                $t("edit_campaign.image_for_desktop_sizes")
              }}</span>
            </div>

            <div class="item-slot item-slot--c-mobile">
              <div class="upload-bar">
                <input type="file" :disabled="!hasAccess" name="" id="countdown_hero_mobile" />
                <label for="countdown_hero_mobile" class="upload-bar__label">
                  <svg
                    width="22px"
                    height="42px"
                    viewBox="0 0 22 42"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g
                        id="images_1"
                        transform="translate(-517.000000, -636.000000)"
                        fill="#107598"
                      >
                        <g id="Group-16" transform="translate(401.000000, 591.000000)">
                          <g id="noun_Mobile_440898" transform="translate(116.000000, 45.000000)">
                            <path
                              d="M10.992074,14 C11.5944518,14 12.0700132,14.4755614 12.0700132,15.0779392 L12.0700132,18.9299868 L15.9220608,18.9299868 C16.5085865,18.9299868 17,19.4055482 17,19.992074 C17,20.5944518 16.5085865,21.0700132 15.9220608,21.0700132 L12.0700132,21.0700132 L12.0700132,24.9220608 C12.0700132,25.5085865 11.5944518,26 10.992074,26 C10.4055482,26 9.92998679,25.5085865 9.92998679,24.9220608 L9.92998679,21.0700132 L6.07793923,21.0700132 C5.47556143,21.0700132 5,20.5944518 5,19.992074 C5,19.4055482 5.47556143,18.9299868 6.07793923,18.9299868 L9.92998679,18.9299868 L9.92998679,15.0779392 C9.92998679,14.4755614 10.4055482,14 10.992074,14 Z"
                              id="Shape"
                            ></path>
                            <path
                              d="M21.9130435,39.2608696 L21.9130435,2.73913043 C21.9130435,1.2326087 20.6804348,0 19.173913,0 L2.73913043,0 C1.2326087,0 0,1.2326087 0,2.73913043 L0,39.2608696 C0,40.7673913 1.2326087,42 2.73913043,42 L19.173913,42 C20.6804348,42 21.9130435,40.7673913 21.9130435,39.2608696 Z M9.13043478,1.82608696 L12.7826087,1.82608696 L12.7826087,2.73913043 L9.13043478,2.73913043 L9.13043478,1.82608696 Z M10.9565217,40.173913 C9.95217391,40.173913 9.13043478,39.3521739 9.13043478,38.3478261 C9.13043478,37.3434783 9.95217391,36.5217391 10.9565217,36.5217391 C11.9608696,36.5217391 12.7826087,37.3434783 12.7826087,38.3478261 C12.7826087,39.3521739 11.9608696,40.173913 10.9565217,40.173913 Z M20.0869565,34.6956522 L1.82608696,34.6956522 L1.82608696,4.56521739 L20.0869565,4.56521739 L20.0869565,34.6956522 Z"
                              id="Shape"
                              fill-rule="nonzero"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>

                  <span>{{ $t("edit_campaign.image_for_mobile") }}</span>
                </label>
              </div>

              <span class="img-cimages__size-text">{{
                $t("edit_campaign.image_for_mobile_sizes")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <b-row class="campaign-video my-4">
        <div class="col-12 col-sm-10">
          <label class="form-unit__label form-unit__label--upper-sm" for=""> Videos </label>
          <div class="form-unit__addon-wrap">
            <div :class="!hasAccess ? 'form-unit__addon-icon disabled' : 'form-unit__addon-icon'">
              <svg viewBox="0 0 26 18">
                <path
                  fill="#107598"
                  fill-rule="nonzero"
                  d="M21.47.2H4.53A4.33 4.33 0 0 0 .2 4.53v9.06c0 2.4 1.94 4.33 4.33 4.33h16.94c2.4 0 4.33-1.94 4.33-4.33V4.53c0-2.4-1.94-4.33-4.33-4.33zm-7.39 10.83L10.24 13V5.12l3.84 1.97 3.84 1.97-3.84 1.97z"
                />
              </svg>
            </div>
            <input
              type="text"
              class="form-control form-unit__input"
              placeholder="Videos"
              v-model="campaign_video"
            />
          </div>
        </div>
      </b-row>

      <div class="description-area">
        <div class="editor-wrap">
          <div class="row">
            <div class="col-auto mr-auto">
              <button
                type="button"
                class="bttn bttn--lg bttn--orange bttn--inverted-orange"
                name="button"
              >
                Delete
              </button>
            </div>
            <div class="col-auto">
              <button
                type="submit"
                :disabled="!hasAccess"
                class="bttn bttn--lg bttn--orange bttn--mw255"
                value="Save"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="loading-image" v-if="loadingImage">
      <img src="https://editionsdelarose.com/wp-content/themes/edr/img/loading.gif" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Chrome from "vue-color/src/components/Chrome.vue";

import error from "@/components/errors.vue";

import ClickOutside from "vue-click-outside";
import sliderLaptop from "./sliderLaptopBig";
import sliderMobile from "./sliderMobileBig";
import errorList from "@/components/error-message.vue";

export default {
  data() {
    return {
      load: false,
      loadingImage: false,
      customErrorMessage: "edit_campaign.upload_file_too_big",
      errors: [],
      testBlock: false,
      colors: {
        primary: "",
        secondary: "",
      },
      primaryPicker: false,
      secondaryPicker: false,
      layoutStep: "1",
      selectedTemplate: null,
      langImagesSelect: "",
      campaign_video: "",
    };
  },

  components: {
    error: error,
    errorMessage: errorList,
    sliderLaptop: sliderLaptop,
    sliderMobile: sliderMobile,
    chromePicker: Chrome,
  },

  mounted() {},
  computed: mapState({}),

  watch: {},

  methods: {},

  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.bttn-round {
  margin-right: 10px;
}

.inner-tabs {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: 768px) {
    padding-left: 25px;
    padding-right: 25px;
    align-items: initial;
    flex-direction: row;
  }
  &__unit {
    width: 100%;
    max-width: 260px;
    padding: 0 25px;
    height: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 19px;
    border: 1px solid $bb-100;
    cursor: pointer;
    &:not(:last-of-type) {
      margin-bottom: 15px;
      @media only screen and (min-width: 768px) {
        margin-bottom: initial;
        margin-right: 15px;
      }
    }
    &.active {
      color: #ffffff;
      background-color: $bb-100;
    }
  }
}

.he {
  .inner-tabs {
    &__unit {
      &:not(:last-of-type) {
        @media only screen and (min-width: 768px) {
          margin-right: initial;
          margin-left: 15px;
        }
      }
    }
  }
}

.loading-image {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  img {
    width: 150px;
  }
}

.img-cimages > label.img-cimages__label .desktop-mobile svg {
  margin-left: 5px;
  margin-right: 5px;
}

.load-image-input {
  display: none !important;
}

.inner-image {
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  &__action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 160px;
    .bttn-round {
      &:nth-of-type(2) {
        margin-right: 10px;
      }
      &:nth-of-type(1) {
        margin-right: auto;
      }
      &:nth-of-type(4) {
        margin-left: auto;
      }
    }
  }
}

.images-preview {
  &__sliders {
    display: flex;
    flex-wrap: wrap;
    .item-slot {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media only screen and (min-width: 992px) {
        width: 50%;
      }
      &--s-desktop,
      &--s-mobile {
        margin-bottom: 20px;
      }
      &--c-desktop {
        flex-direction: column;
        align-items: center;
        text-align: center;
        > span {
          width: 100%;
          max-width: 280px;
          font-size: 12px;
        }
      }
      &--c-mobile {
        flex-direction: column;
        align-items: center;
        text-align: center;
        > span {
          width: 100%;
          max-width: 280px;
          font-size: 12px;
        }
      }
      &--c-common {
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        > span {
          width: 100%;
          max-width: 280px;
          font-size: 12px;
        }
      }
    }
    .item-slot--s-desktop {
      order: 0;
      @media only screen and (min-width: 1360px) {
        width: 60%;
      }
    }
    .item-slot--s-mobile {
      order: 2;
      @media only screen and (min-width: 992px) {
        order: initial;
      }
      @media only screen and (min-width: 1360px) {
        width: 40%;
      }
    }
    .item-slot--c-desktop {
      order: 1;
      margin-bottom: 15px;
      @media only screen and (min-width: 992px) {
        order: initial;
      }
      @media only screen and (min-width: 1360px) {
        width: 60%;
      }
    }
    .item-slot--c-mobile {
      order: 3;
      //marging-bottom: 15px;
      @media only screen and (min-width: 992px) {
        order: initial;
      }
      @media only screen and (min-width: 1360px) {
        width: 40%;
      }
    }
    .item-slot--c-common {
      order: 3;
      //marging-bottom: 15px;
      @media only screen and (min-width: 992px) {
        order: initial;
      }
    }
  }
}

.upload-bar {
  width: 100%;
  margin-bottom: 10px;
  max-width: 280px;
  height: 60px;
  display: flex;
  justify-content: center;
  border: 1px solid #bbbcc4;
  border-radius: 5px;
  background-color: #f5faff;
  position: relative;
  > input {
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__label {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    svg {
      display: block;
      height: 24px;
      width: auto;
      margin-right: 10px;
    }
    span {
      width: 60%;
      text-transform: uppercase;
      line-height: 1.125;
    }
  }
}

.templates-section {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  position: relative;
  &__controls {
    padding: 40px 15px 0 15px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
.template-rb {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.template {
  margin: 15px;
  min-height: 270px;
  //background-color: rgba(0,0,0,.2);
  border: 3px solid transparent;
  display: block;
  align-self: flex-start;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  width: 100%;
  @media only screen and (min-width: 568px) {
    width: 46%;
    margin: calc(8% / 2 / 2);
  }
  // @media only screen and (min-width: 992px) {
  //   width: 30%;
  //   margin: calc(10%/3/2);
  // }
  &:before {
    content: "";
    width: 100%;
    display: block;
    padding-top: 120%;
  }
  &:after {
    content: "";
    display: block;
    border-radius: 6px;
    border: 3px solid transparent;
    // background-color: rgba(255,255,255, .5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    .template__inner {
      transform: scale(1.1);
    }
    // &:after {
    //   background-color: rgba(255,255,255, 0);
    //   border: 3px solid $bb-50;
    // }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 0.2s ease-in-out;
    > img {
      margin-bottom: 5px;
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &__title {
    padding: 0 15px;
    text-align: center;
    font-family: $vito-bold;
    font-size: 18px;
    color: $bb-100;
    position: absolute;
    top: 5%;
    left: 5%;
  }
  &__description {
    width: 80%;
    height: 32px;
    overflow: hidden;
    // border: 1px solid red;
    // padding: 0 15px;
    // text-align: center;
    font-family: $vito-light;
    font-size: 14px;
    line-height: 1.1;
    color: $fd;
    position: absolute;
    top: 12%;
    left: 8.5%;
  }
  &.current {
    border-color: $bb-100;
    &:hover {
      .template__inner {
        transform: scale(1);
      }
    }
  }
  &.not-set {
    &:hover {
      .template__inner {
        transform: scale(1);
      }
    }
  }
}

.template--active {
  background-color: #ffffff;
  border-color: $bb-100;
  &:after {
    opacity: 0;
  }
  &.not-set {
    border-color: $lr-50;
  }
}

.template-preview {
  display: inline-flex;
  padding: 0;
  background: transparent !important;
  border: none !important;
  color: $bb-100;
  font-size: 14px;
  text-decoration: underline;
  position: absolute;
  top: 5.7%;
  right: 10%;
}
.color-section {
  margin-bottom: 40px;
  &__inner {
    display: flex;
  }
  &__unit {
    width: 300px;
  }
}
.picker-block {
  width: 225px;
  height: 36px;
  position: relative;
  border: 1px solid #333333;
  cursor: pointer;
  &.show {
    .picker {
      display: block;
    }
  }
}

.picker {
  display: none;
  position: absolute;
  top: 102%;
  left: 0;
  z-index: 2;
}

.drop-down-lang {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  p {
    margin: 0;
  }
}

.campaign-video {
  margin: 15px 0px 15px 0px;
  label {
    font-family: "VitoExtended-Bold";
    margin-top: 6px;
  }
}

.row {
  display: block;
  .images-section__title {
    margin: 0 14px 10px;
  }
}

.form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid #e6eaed;
}

.campaign-video {
  margin: 14px 0px 40px 0px !important;
  label {
    font-family: $vito-light;
    text-transform: none;
  }
}

.editor-wrap {
  .row {
    display: flex;
  }
}
</style>
