<template>
  <div class="data-view-wrapper clearfix">
    <div class="data-view">
      <div class="data-view__title">
        {{ $t("gateways.gateway_name") }}
      </div>
      <div class="data-view__data">{{}}</div>
    </div>

    <div class="data-view">
      <div class="data-view__title">
        {{ $t("gateways.legal_entity") }}
      </div>
      <div class="data-view__data">{{}}</div>
    </div>

    <div class="data-view">
      <div class="data-view__title">
        {{ $t("gateways.status") }}
      </div>
      <div class="data-view__data">{{}}</div>
    </div>

    <div class="data-view">
      <div class="data-view__title">
        {{ $t("gateways.primary_currency") }}
      </div>
      <div class="data-view__data">{{}}</div>
    </div>

    <!-- <div class="data-view">
      <div class="data-view__title">
        {{ $t('gateways.verified_info') }}
      </div>
      <div class="data-view__data">
        {{ gatewayInfo.attributes.verified ? $t('gateways.verified') : $t('gateways.not_verified') }}
      </div>
    </div> -->

    <div class="data-view">
      <div class="data-view__title">{{}}</div>
      <div class="data-view__data">{{}}</div>
    </div>

    <button class="bttn bttn--lg bttn--blue">{{ $t("gateways.close_view") }}</button>
  </div>
</template>

<script>
import error from "@/components/errors.vue";

import { mapState } from "vuex";

export default {
  props: ["gateway"],

  data: function () {
    return {
      gatewayInfo: null,
      errors: [],
    };
  },

  components: {
    error: error,
  },

  computed: mapState({}),

  methods: {},
};
</script>
<style lang="scss" scoped>
.data-view-wrapper {
  button {
    float: right;
  }
}
</style>
