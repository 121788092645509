export default {
  title: "CustomTabsUnidyClassic",
  type: "object",
  expanded: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    content: {
      title: "Content",
      type: "list",
      default: [],
      expanded: true,
      items: {
        type: "object",
        properties: {
          key: {
            title: "Key",
            type: "string",
            default: "",
            placeholder: "custom_component_2",
          },
          title: {
            title: "Title",
            type: "string",
            default: "",
            placeholder: "Pdf viewer",
          },
          content: {
            title: "Content",
            type: "string",
            default: "",
            placeholder:
              "https://s2.q4cdn.com/175719177/files/doc_presentations/Placeholder-PDF.pdf",
          },
          component: {
            title: "Type",
            type: "enum",
            default: "pdf",
            items: {
              enum: [
                {
                  text: "PDF Viewer",
                  value: "pdf_viewer",
                },
                {
                  text: "Team List",
                  value: "team_list",
                },
                {
                  text: "Taggbox",
                  value: "taggbox",
                },
                {
                  text: "Donors custom fields",
                  value: "donors_custom_fields",
                },
              ],
            },
          },
          settings: {
            title: "Settings",
            type: "object",
            properties: {
              filter_by_team: {
                title: "Filter by team",
                type: "boolean",
                default: true,
              },
              parent_teams_only: {
                title: "Parent teams only",
                type: "boolean",
                default: true,
              },
              granparent_teams_only: {
                title: "Grandparent teams only",
                type: "boolean",
                default: false,
              },
            },
          },
          wall_id: {
            title: "Wall ID",
            type: "string",
            default: "",
            placeholder: "57870",
          },
          lang: {
            title: "Language",
            type: "object",
            component: "LanguageSelect",
          },
        },
      },
    },
  },
};
