<script>
export default {
  props: {
    size: { type: String, default: "md" },
    disabled: Boolean,
  },
};
</script>

<template>
  <button
    v-bind="$attrs"
    class="cnd-button"
    :class="{
      'cnd-button--size-lg': size === 'lg',
    }"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
.cnd-button {
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fafafa;
  min-width: 138px;
  background-color: #0a8eff;
  font-size: 15px;
  font-weight: 700;
  font-family: var(--font-secondary);
  border: none;

  @media only screen and (max-width: 967px) {
    min-width: 80px;
  }

  &--size-lg {
    min-width: 205px;
    height: 46px;
  }
}
</style>
