export const ValidateShortlink = Symbol();
export const LinkValidationConfigWizard = Symbol();

export const OpenCharidyToGoModal = Symbol();
export const CloseCharidyToGoModal = Symbol();

export const OpenCrowdfundingFundraisingModal = Symbol();
export const CloseCrowdfundingFundraisingModal = Symbol();

export const OpenMediaStoragePreview = Symbol();
export const OnOkMediaStoragePreview = Symbol();
