<template>
  <div class="entity-modal">
    <button
      type="button"
      :disabled="!hasAccess"
      class="bttn-bordered bttn-bordered--sm"
      @click="openModal"
    >
      + {{ $t("account.add_entities") }}
    </button>

    <!-- Modal Component -->
    <b-modal
      id="entitiesModal"
      size="lg"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @hidden="clearData"
      @shown="shown"
    >
      <div slot="modal-header" v-if="edit">
        <h2>{{ $t("account.legal_entity_detalis_edit") }}</h2>
        <div class="modal-header__subtitle">{{ $t("account.legal_entity_subtitle_edit") }}</div>
      </div>

      <div slot="modal-header" v-if="!edit">
        <h2>{{ $t("account.legal_entity_detalis_add") }}</h2>
        <div class="modal-header__subtitle">{{ $t("account.legal_entity_subtitle_add") }}</div>
      </div>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
          {{ $t("account.cancel_add_entity", "Cancel") }}
        </button>
        <button
          v-if="!edit"
          form="entaties-add"
          :class="loadind ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
          :disabled="loadind"
        >
          {{ $t("account.add_entity") }}
        </button>
        <button
          v-if="edit"
          form="entaties-add"
          :class="loadind ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
          :disabled="loadind"
        >
          {{ $t("account.edit_entity") }}
        </button>
      </div>

      <form id="entaties-add" @submit.prevent="submitForm" class="entities-data">
        <error v-if="errors" :errors="errors"></error>

        <b-alert show variant="info">
          {{
            $t(
              "account.legal_entity_note",
              "NOTE: Address must match the address registered with the Charity Commission"
            )
          }}
        </b-alert>

        <div class="mb-3 row">
          <div class="col-12 col-md-6">
            <span class="custom-label">{{ $t("account.receipt_logo", "Receipt Logo") }}</span>
            <div class="upload-file-box">
              <label for="receipt_logo" class="upload-file">{{
                $t("account.upload_receipt_logo")
              }}</label>
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                id="receipt_logo"
                @change="onReceiptLogoChange($event)"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="img-thumbnail img-thumbnail-wrap w-auto">
              <div class="loading-image" v-if="loadImage">
                <img src="https://editionsdelarose.com/wp-content/themes/edr/img/loading.gif" />
              </div>
              <img
                v-if="entities.data.attributes.receipt_logo"
                :src="entities.data.attributes.receipt_logo"
                alt="receipt logo"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <b-form-group
              :label="$t('account.type')"
              label-for="entities-type"
              class="account-card__select entities-data__labels"
            >
              <b-form-select
                id="entities-type"
                required
                v-model="entities.data.attributes.type"
                :options="entitiesTypes"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group form-unit">
              <label for="legal-name" class="entities-data__label entities-data__label--required">
                {{ $t("account.legal_name") }}
              </label>
              <b-form-input
                id="legal-name"
                name="legalname"
                v-validate="'required'"
                v-model="entities.data.attributes.name"
              ></b-form-input>
              <span v-show="vErrors.has('legalname')" class="v-help is-danger">{{
                $t("template.field_is_required")
              }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group form-unit">
              <label
                for="legal-tax_id"
                class="entities-data__label"
                :class="
                  entities.data.attributes.type === 'nonprofit'
                    ? 'entities-data__label--required'
                    : ''
                "
              >
                {{ taxIDLabel }}
              </label>
              <b-form-input
                id="legal-tax_id"
                name="taxid"
                v-validate="{ required: entities.data.attributes.type === 'nonprofit' }"
                v-model="entities.data.attributes.tax_id"
              />
              <span v-show="vErrors.has('taxid')" class="v-help is-danger">
                {{ $t("template.field_is_required") }}
              </span>
            </div>
          </div>

          <!-- CITY -->
          <div class="col-12 col-md-6">
            <div class="form-group form-unit">
              <label for="city" class="entities-data__label entities-data__label--required">
                {{ $t("account.city") }}
              </label>
              <b-form-input
                id="city"
                name="city"
                v-validate="'required'"
                v-model="entities.data.attributes.city"
              ></b-form-input>
              <span v-show="vErrors.has('city')" class="v-help is-danger">{{
                $t("template.field_is_required")
              }}</span>
            </div>
          </div>

          <!-- COUNTRY -->
          <div class="col-12 col-md-6">
            <div class="form-group form-unit" :class="{ invalid: countryInvalid }">
              <label for="country" class="entities-data__label entities-data__label--required">
                {{ $t("account.country") }}
              </label>
              <multiselect
                :class="{ invalid: countryInvalid }"
                :allow-empty="false"
                v-model="multiselectCountry"
                :options="countryList"
                label="text"
                track-by="text"
                @close="onTouch"
                :multiple="false"
              />
              <input
                type="text"
                name="countrylist"
                v-validate="'required'"
                v-model="multiselectCountry.value"
                style="display: none"
              />
              <span v-show="vErrors.has('countrylist')" class="v-help is-danger">{{
                $t("template.field_is_required")
              }}</span>
            </div>
          </div>

          <!-- ZIP -->
          <div class="col-12 col-md-6">
            <div class="form-group form-unit">
              <label for="zip" class="entities-data__label entities-data__label--required">
                {{ zipLabel }}
              </label>
              <b-form-input
                id="zip"
                name="zip"
                v-validate="'required'"
                v-model="entities.data.attributes.zip"
              ></b-form-input>
              <span v-show="vErrors.has('zip')" class="v-help is-danger">{{
                $t("template.field_is_required")
              }}</span>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group form-unit">
              <label
                for="address-line-1"
                class="entities-data__label entities-data__label--required"
              >
                {{ $t("account.address_line_1") }}
              </label>
              <b-form-input
                id="address-line-1"
                name="address"
                v-validate="'required'"
                v-model="entities.data.attributes.address_line_1"
                ref="address"
              ></b-form-input>
              <span v-show="vErrors.has('address')" class="v-help is-danger">{{
                $t("template.field_is_required")
              }}</span>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group
              :label="$t('account.address_line_2')"
              label-for="address-line-2"
              class="entities-data__labels"
            >
              <b-form-input
                id="address-line-2"
                v-model="entities.data.attributes.address_line_2"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group form-unit">
              <label
                for="state"
                class="entities-data__label"
                :class="{
                  'entities-data__label--required':
                    multiselectCountry.value == 'CA' || multiselectCountry.value == 'US',
                }"
              >
                {{ stateLabel }}
              </label>

              <b-form-input
                v-if="multiselectCountry.value != 'CA' && multiselectCountry.value != 'US'"
                id="state"
                name="state"
                v-model="entities.data.attributes.state"
              ></b-form-input>

              <b-form-select
                name="canadastate"
                v-validate="'required'"
                v-model="entities.data.attributes.state"
                v-if="multiselectCountry.value == 'CA'"
              >
                <option value="">{{ $t("account.select_state") }}</option>
                <option
                  v-for="state in $options.CANADA_STATES"
                  :key="state.value"
                  :value="state.text"
                >
                  {{ state.text }}
                </option>
              </b-form-select>
              <span
                v-if="multiselectCountry.value == 'CA'"
                v-show="vErrors.has('canadastate')"
                class="v-help is-danger"
                >{{ $t("template.field_is_required") }}</span
              >

              <b-form-select
                name="usastate"
                v-validate="'required'"
                v-model="entities.data.attributes.state"
                v-if="multiselectCountry.value == 'US'"
              >
                <option value="">{{ $t("account.select_state") }}</option>
                <option v-for="state in $options.USA_STATES" :key="state.value" :value="state.text">
                  {{ state.text }}
                </option>
              </b-form-select>
              <span
                v-if="multiselectCountry.value == 'US'"
                v-show="vErrors.has('usastate')"
                class="v-help is-danger"
                >{{ $t("template.field_is_required") }}</span
              >
            </div>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group class="account-card__select entities-data__labels">
              <label class="entities-data__label entities-data__label--required">
                {{ $t("account.tax_deductible_receipt") }}
              </label>
              <b-form-select
                id="tax-deductible-receipt"
                required
                v-model="entities.data.attributes.tax_deductible_receipt"
                :options="taxDeductibleReceiptTypes"
              />
            </b-form-group>
          </div>
        </div>

        <div class="wrapper-options" v-if="multiselectCountry.value == 'FR'"></div>

        <template v-if="multiselectCountry.value == 'FR'">
          <div class="cerfa-options-wrapper">
            <!-- Options 1 -->
            <span class="custom-label">{{ $t("account.first_checkbox_group") }}</span>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input
                    type="checkbox"
                    :id="'is_200_du_cgi'"
                    v-model="CERFAReceipts.data.is_200_du_cgi"
                  />
                  <label :for="'is_200_du_cgi'">
                    {{ $t("account.is_200_du_cgi") }}
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input
                    type="checkbox"
                    :id="'is_238_bis_du_cgi'"
                    v-model="CERFAReceipts.data.is_238_bis_du_cgi"
                  />
                  <label :for="'is_238_bis_du_cgi'">
                    {{ $t("account.is_238_bis_du_cgi") }}
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input
                    type="checkbox"
                    :id="'is_885_0v_bis_du_cgi'"
                    v-model="CERFAReceipts.data.is_885_0v_bis_du_cgi"
                  />
                  <label :for="'is_885_0v_bis_du_cgi'">
                    {{ $t("account.is_885_0v_bis_du_cgi") }}
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input
                    type="checkbox"
                    :id="'is_978_du_cgi_impot_sur_la_fortune_immobiliere'"
                    v-model="CERFAReceipts.data.is_978_du_cgi_impot_sur_la_fortune_immobiliere"
                  />
                  <label :for="'is_978_du_cgi_impot_sur_la_fortune_immobiliere'">
                    {{
                      $t(
                        "account.is_978_du_cgi_impot_sur_la_fortune_immobiliere",
                        "978 du CGI Impôt sur la fortune immobilière (IFI)"
                      )
                    }}
                  </label>
                </div>
              </div>
            </div>

            <!-- Options 2 -->
            <span class="custom-label">{{ $t("account.second_checkbox_group") }}</span>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input
                    type="checkbox"
                    :id="'acte_sous_seing_prive'"
                    v-model="CERFAReceipts.data.acte_sous_seing_prive"
                  />
                  <label :for="'acte_sous_seing_prive'">
                    {{ $t("account.acte_sous_seing_prive") }}
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input
                    type="checkbox"
                    :id="'declaration_de_don_manuel'"
                    v-model="CERFAReceipts.data.declaration_de_don_manuel"
                  />
                  <label :for="'declaration_de_don_manuel'">
                    {{ $t("account.declaration_de_don_manuel") }}
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input type="checkbox" :id="'autres1'" v-model="CERFAReceipts.data.autres1" />
                  <label :for="'autres1'">
                    {{ $t("account.autres1") }}
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input
                    type="checkbox"
                    :id="'acte_authentique'"
                    v-model="CERFAReceipts.data.acte_authentique"
                  />
                  <label :for="'acte_authentique'">
                    {{ $t("account.acte_authentique") }}
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input
                    type="checkbox"
                    :id="'titres_de_societes_cotes'"
                    v-model="CERFAReceipts.data.titres_de_societes_cotes"
                  />
                  <label :for="'titres_de_societes_cotes'">
                    {{ $t("account.titres_de_societes_cotes") }}
                  </label>
                </div>
              </div>
            </div>

            <!-- Options 3 -->
            <span class="custom-label">{{ $t("account.third_checkbox_group") }}</span>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input type="checkbox" :id="'numeraire'" v-model="CERFAReceipts.data.numeraire" />
                  <label :for="'numeraire'">
                    {{ $t("account.numeraire") }}
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input type="checkbox" :id="'autres2'" v-model="CERFAReceipts.data.autres2" />
                  <label :for="'autres2'">
                    {{ $t("account.autres2") }}
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input
                    type="checkbox"
                    :id="'remise_d_especes'"
                    v-model="CERFAReceipts.data.remise_d_especes"
                  />
                  <label :for="'remise_d_especes'">
                    {{ $t("account.remise_d_especes") }}
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input type="checkbox" :id="'cheque'" v-model="CERFAReceipts.data.cheque" />
                  <label :for="'cheque'">
                    {{ $t("account.cheque") }}
                  </label>
                </div>
              </div>
            </div>

            <!-- Options 4 -->
            <span class="custom-label">{{ $t("account.fourth_checkbox_group") }}</span>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input type="checkbox" :id="'bsd'" v-model="CERFAReceipts.data.bsd" />
                  <label :for="'bsd'">
                    {{ $t("account.bsd") }}
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group custom-checkbox">
                  <input type="checkbox" :id="'oeuvre'" v-model="CERFAReceipts.data.is_oeuvre" />
                  <label :for="'oeuvre'">
                    {{ $t("account.is_oeuvre") }}
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <b-form-group
                  :label="$t('account.object')"
                  label-for="object"
                  class="entities-data__labels"
                >
                  <b-form-input id="object" v-model="CERFAReceipts.data.object"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-12 col-md-6">
                <b-form-group
                  :label="$t('account.receipt_prefix')"
                  label-for="receipt_prefix"
                  class="entities-data__labels"
                >
                  <b-form-input
                    id="receipt_prefix"
                    v-model="CERFAReceipts.data.receipt_prefix"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <span class="custom-label">{{ $t("account.signature") }}</span>
                <div class="upload-file-box">
                  <label for="signature" class="upload-file">{{
                    $t("account.upload_signature")
                  }}</label>
                  <input
                    ref="massUpload"
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    id="signature"
                    @change="onSignatureChange($event)"
                  />
                </div>
                <div class="img-thumbnail img-thumbnail-wrap">
                  <div class="loading-image" v-if="loadImage">
                    <img src="https://editionsdelarose.com/wp-content/themes/edr/img/loading.gif" />
                  </div>
                  <img
                    v-if="CERFAReceipts.data.signature"
                    :src="CERFAReceipts.data.signature"
                    alt="signature"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="multiselectCountry.value == 'CA'">
          <b-container>
            <b-row align-v="start">
              <b-col cols="4">
                <div class="form-group custom-checkbox">
                  <input type="checkbox" :id="'bh'" v-model="CERFAReceipts.data.bh" />
                  <label :for="'bh'">
                    {{ $t("account.bsd") }}
                  </label>
                </div>

                <b-form-group
                  :label="$t('account.full_name')"
                  label-for="full-name"
                  class="entities-data__labels"
                >
                  <b-form-input
                    id="full-name"
                    v-model="CERFAReceipts.data.full_name"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('account.canada_phone_number', 'Phone Number')"
                  label-for="canada-phone-number"
                  class="entities-data__labels"
                >
                  <b-form-input
                    id="canada-phone-number"
                    type="tel"
                    v-model="CERFAReceipts.data.tax_phone_number"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="8">
                <span class="custom-label">{{ $t("account.signature") }}</span>
                <div class="upload-file-box">
                  <label for="signature" class="upload-file">{{
                    $t("account.upload_signature")
                  }}</label>
                  <input
                    ref="massUpload"
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    id="signature"
                    @change="onSignatureChange($event)"
                  />
                </div>
                <div class="img-thumbnail img-thumbnail-wrap">
                  <div class="loading-image" v-if="loadImage">
                    <img src="https://editionsdelarose.com/wp-content/themes/edr/img/loading.gif" />
                  </div>
                  <img
                    v-if="CERFAReceipts.data.signature"
                    :src="CERFAReceipts.data.signature"
                    alt="signature"
                  />
                </div>
              </b-col>
            </b-row>
            <!-- TEXT EDITOR AREA -->
            <b-row class="mt-3">
              <div class="description-area">
                <div class="editor-wrap">
                  <quill-editor
                    v-model="CERFAReceipts.data.message"
                    :enable="hasAccess"
                    :id="'quill-editor'"
                    ref="myQuillEditor"
                    :options="canada.editorOption"
                  >
                  </quill-editor>
                </div>
              </div>
            </b-row>
          </b-container>
        </template>

        <template v-if="multiselectCountry.value == 'DE'">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group
                :label="$t('account.purpose_german', 'Purpose')"
                class="entities-data__labels"
              >
                <b-form-input v-model="CERFAReceipts.data.purpose" />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                :label="$t('account.tax_number_german', 'Tax Number')"
                class="entities-data__labels"
              >
                <b-form-input v-model="CERFAReceipts.data.tax_number" />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                :label="$t('account.assessment_period_german', 'Assessment Period')"
                class="entities-data__labels"
              >
                <b-form-input v-model="CERFAReceipts.data.assessment_period" />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                :label="$t('account.bank_german', 'Bank')"
                class="entities-data__labels"
              >
                <b-form-input v-model="CERFAReceipts.data.bank" />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                :label="$t('account.iban_german', 'IBAN')"
                class="entities-data__labels"
              >
                <b-form-input v-model="CERFAReceipts.data.iban" />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group :label="$t('account.bic_german', 'BIC')" class="entities-data__labels">
                <b-form-input v-model="CERFAReceipts.data.bic" />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <div class="form-group custom-checkbox">
                <input type="checkbox" id="de_term_1" v-model="CERFAReceipts.data.term_1" />
                <label for="de_term_1">
                  {{ $t("account.de_term_1", "Term 1") }}
                </label>
              </div>
            </b-col>

            <b-col cols="12" md="6">
              <div class="form-group custom-checkbox">
                <input type="checkbox" id="de_term_2" v-model="CERFAReceipts.data.term_2" />
                <label for="de_term_2">
                  {{ $t("account.de_term_2", "Term 2") }}
                </label>
              </div>
            </b-col>

            <b-col cols="12" md="6">
              <div class="form-group custom-checkbox">
                <input type="checkbox" id="de_term_3" v-model="CERFAReceipts.data.term_3" />
                <label for="de_term_3">
                  {{ $t("account.de_term_3", "Term 3") }}
                </label>
              </div>
            </b-col>

            <b-col cols="12" md="6">
              <div class="form-group custom-checkbox">
                <input type="checkbox" id="de_term_4" v-model="CERFAReceipts.data.term_4" />
                <label for="de_term_4">
                  {{ $t("account.de_term_4", "Term 4") }}
                </label>
              </div>
            </b-col>

            <b-col cols="12">
              <span class="custom-label">{{ $t("account.signature_german") }}</span>
              <div class="upload-file-box">
                <label for="signature" class="upload-file">
                  {{ $t("account.upload_signature") }}
                </label>
                <input
                  ref="massUpload"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  id="signature"
                  @change="onSignatureChange($event)"
                />
              </div>
              <div class="img-thumbnail img-thumbnail-wrap">
                <div class="loading-image" v-if="loadImage">
                  <img src="https://editionsdelarose.com/wp-content/themes/edr/img/loading.gif" />
                </div>
                <img
                  v-if="CERFAReceipts.data.signature"
                  :src="CERFAReceipts.data.signature"
                  alt="signature"
                />
              </div>
            </b-col>
          </b-row>
        </template>

        <template v-if="multiselectCountry.value == 'NZ'">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group
                :label="$t('account.nz_org_designation', 'Organization designation')"
                label-for="org_designation"
                class="entities-data__labels"
              >
                <b-form-input
                  id="org_designation"
                  v-model="CERFAReceipts.data.name_position"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                :label="$t('account.nz_org_ird_num', 'Organization’s IRD number')"
                label-for="org_ird_num"
                class="entities-data__labels"
              >
                <b-form-input id="org_ird_num" v-model="CERFAReceipts.data.ird"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                :label="$t('account.nzbn')"
                label-for="nzbn"
                class="entities-data__labels"
              >
                <b-form-input id="nzbn" v-model="CERFAReceipts.data.nzbn"></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <span class="custom-label">
                {{ $t("account.signature_german") }}
              </span>
              <div class="upload-file-box">
                <label for="signature" class="upload-file">
                  {{ $t("account.upload_signature") }}
                </label>
                <input
                  ref="massUpload"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  id="signature"
                  @change="onSignatureChange($event)"
                />
              </div>
              <div class="img-thumbnail img-thumbnail-wrap">
                <div class="loading-image" v-if="loadImage">
                  <img src="https://editionsdelarose.com/wp-content/themes/edr/img/loading.gif" />
                </div>
                <img
                  v-if="CERFAReceipts.data.signature"
                  :src="CERFAReceipts.data.signature"
                  alt="signature"
                />
              </div>
            </b-col>
          </b-row>
        </template>

        <template v-if="multiselectCountry.value == 'IL'">
          <b-row>
            <b-col cols="12">
              <div class="form-group custom-checkbox">
                <input type="checkbox" :id="'section_46'" v-model="CERFAReceipts.data.section_46" />
                <label :for="'section_46'">
                  {{ $t("account.section_46") }}
                </label>
              </div>
            </b-col>
          </b-row>
        </template>

        <template v-if="multiselectCountry.value == 'ZA'">
          <div class="cerfa-options-wrapper">
            <div class="row">
              <div class="col-12 col-md-6">
                <span class="custom-label">{{ $t("account.signature") }}</span>
                <div class="upload-file-box">
                  <label for="signature" class="upload-file">{{
                    $t("account.upload_signature")
                  }}</label>
                  <input
                    ref="massUpload"
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    id="signature"
                    @change="onSignatureChange($event)"
                  />
                </div>
                <div class="img-thumbnail img-thumbnail-wrap">
                  <div class="loading-image" v-if="loadImage">
                    <img src="https://editionsdelarose.com/wp-content/themes/edr/img/loading.gif" />
                  </div>
                  <img
                    v-if="CERFAReceipts.data.signature"
                    :src="CERFAReceipts.data.signature"
                    alt="signature"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>

        <b-row>
          <b-col cols="12">
            <div class="form-group custom-checkbox">
              <input
                type="checkbox"
                id="is_primary"
                v-model="entities.data.attributes.primary"
                :disabled="someEntityIsPrimary"
              />
              <label for="is_primary">
                {{ primaryLabel }}
              </label>
              <b-form-text v-if="someEntityIsPrimary">
                {{
                  $t(
                    "account.primary_entity_help",
                    "You cannot select multiple primary organizations. To change the primary organization, please first deselect the checkbox on the current primary organization."
                  )
                }}
              </b-form-text>
            </div>
          </b-col>
        </b-row>

        <b-row v-if="accountLegalEntitiesComplianceQuestionsByCountry.length">
          <b-col
            cols="12"
            v-for="(question, index) in accountLegalEntitiesComplianceQuestionsByCountry"
            :key="`account-legal-entity-complience-question-${index}`"
          >
            <div class="form-group custom-checkbox">
              <input
                type="checkbox"
                :id="`account-legal-entity-complience-question-${index}`"
                v-model="accountLegalEntitiesComplianceQuestionsModel[index]"
                required
              />
              <label :for="`account-legal-entity-complience-question-${index}`">
                {{ $t(question) }}
              </label>
            </div>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import axios from "axios";
import urls from "@/constants/urls.js";
import error from "@/components/errors.vue";
import { notificationsMixin } from "@/mixins";
import { unwrapContentValue } from "@/components/vue-quill-editor/utils";
import CustomVideo from "@/components/vue-quill-editor/formats/custom-video";

const CANADA_STATES = [
  { value: "ab", text: "Alberta" },
  { value: "bc", text: "British Columbia" },
  { value: "mb", text: "Manitoba" },
  { value: "nb", text: "New Brunswick" },
  { value: "nl", text: "Newfoundland and Labrador" },
  { value: "ns", text: "Nova Scotia" },
  { value: "nt", text: "Northwest Territories" },
  { value: "nu", text: "Nunavut" },
  { value: "on", text: "Ontario" },
  { value: "pe", text: "Prince Edward Island " },
  { value: "qc", text: "Quebec" },
  { value: "sk", text: "Saskatchewan" },
];

const USA_STATES = [
  { value: "AL", text: "Alabama" },
  { value: "AK", text: "Alaska" },
  { value: "AZ", text: "Arizona" },
  { value: "AR", text: "Arkansas" },
  { value: "CA", text: "California" },
  { value: "CO", text: "Colorado" },
  { value: "CT", text: "Connecticut" },
  { value: "DE", text: "Delaware" },
  { value: "DC", text: "District Of Columbia" },
  { value: "FL", text: "Florida" },
  { value: "GA", text: "Georgia" },
  { value: "HI", text: "Hawaii" },
  { value: "ID", text: "Idaho" },
  { value: "IL", text: "Illinois" },
  { value: "IN", text: "Indiana" },
  { value: "IA", text: "Iowa" },
  { value: "KS", text: "Kansas" },
  { value: "KY", text: "Kentucky" },
  { value: "LA", text: "Louisiana" },
  { value: "ME", text: "Maine" },
  { value: "MD", text: "Maryland" },
  { value: "MA", text: "Massachusetts" },
  { value: "MI", text: "Michigan" },
  { value: "MN", text: "Minnesota" },
  { value: "MS", text: "Mississippi" },
  { value: "MO", text: "Missouri" },
  { value: "MT", text: "Montana" },
  { value: "NE", text: "Nebraska" },
  { value: "NV", text: "Nevada" },
  { value: "NH", text: "New Hampshire" },
  { value: "NJ", text: "New Jersey" },
  { value: "NM", text: "New Mexico" },
  { value: "NY", text: "New York" },
  { value: "NC", text: "North Carolina" },
  { value: "ND", text: "North Dakota" },
  { value: "OH", text: "Ohio" },
  { value: "OK", text: "Oklahoma" },
  { value: "OR", text: "Oregon" },
  { value: "PA", text: "Pennsylvania" },
  { value: "RI", text: "Rhode Island" },
  { value: "SC", text: "South Carolina" },
  { value: "SD", text: "South Dakota" },
  { value: "TN", text: "Tennessee" },
  { value: "TX", text: "Texas" },
  { value: "UT", text: "Utah" },
  { value: "VT", text: "Vermont" },
  { value: "VA", text: "Virginia" },
  { value: "WA", text: "Washington" },
  { value: "WV", text: "West Virginia" },
  { value: "WI", text: "Wisconsin" },
  { value: "WY", text: "Wyoming" },
];

export default {
  CANADA_STATES,
  USA_STATES,

  props: {
    gatewayModalRef: {
      type: Object,
      default: null,
    },
  },
  mixins: [notificationsMixin],
  data() {
    return {
      countryfieldsITouched: false,
      loadImage: false,
      gatewayStep: "",
      canada: {
        editorOption: {
          modules: {
            toolbar: {
              container: [
                ["bold", "underline", "strike", "italic"],
                ["blockquote"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ size: ["small", false, "large", "huge"] }],
                [{ align: [] }],
                [{ color: [] }, { background: [] }],
                ["image"],
                [CustomVideo.blotName],
                ["link"],
                ["clean"],
              ],
            },
            imageResize: {
              modules: ["Resize", "DisplaySize"],
              displayStyles: {
                backgroundColor: "black",
                border: "none",
                color: "white",
              },
            },
          },
        },
      },
      defaultCERFAReceipts: {
        data: {
          is_200_du_cgi: true,
          is_238_bis_du_cgi: true,
          is_885_0v_bis_du_cgi: true,
          is_978_du_cgi_impot_sur_la_fortune_immobiliere: true,
          acte_authentique: false,
          acte_sous_seing_prive: false,
          declaration_de_don_manuel: true,
          autres1: false,
          autres2: false,
          numeraire: true,
          titres_de_societes_cotes: false,
          cheque: false,
          remise_d_especes: false,
          bsd: true,
          bh: true,
          signature: "",
          object: "",
          receipt_prefix: "",
          receipt_start_n: 0,
          purpose: "",
          tax_number: "",
          assessment_period: "",
          bank: "",
          iban: "",
          bic: "",
          term_1: false,
          term_2: false,
          term_3: false,
          term_4: false,
        },
      },
      CERFAReceipts: {
        data: {
          is_200_du_cgi: true,
          is_238_bis_du_cgi: true,
          is_885_0v_bis_du_cgi: true,
          is_978_du_cgi_impot_sur_la_fortune_immobiliere: true,
          acte_authentique: false,
          acte_sous_seing_prive: false,
          declaration_de_don_manuel: true,
          autres1: false,
          autres2: false,
          numeraire: true,
          titres_de_societes_cotes: false,
          cheque: false,
          remise_d_especes: false,
          bsd: true,
          bh: true,
          signature: "",
          message: "",
          object: "",
          receipt_prefix: "",
          receipt_start_n: 0,
          is_oeuvre: true,
          full_name: "",
          tax_phone_number: "",
          purpose: "",
          tax_number: "",
          assessment_period: "",
          bank: "",
          iban: "",
          bic: "",
          term_1: false,
          term_2: false,
          term_3: false,
          term_4: false,
        },
      },
      entities: {
        data: {
          type: "org_legal_entity",
          attributes: {
            type: "nonprofit",
            name: "",
            tax_id: "",
            address_line_1: "",
            address_line_2: "",
            country: "",
            city: "",
            tax_deductible_receipt: null,
            zip: "",
            receipt_config_fr: null,
            receipt_config_ca: null,
            receipt_config_ge: null,
            receipt_config_nz: null,
            receipt_config_il: null,
            receipt_config_za: null,
            state: "",
            primary: false,
          },
          id: "",
        },
        included: [],
      },
      multiselectCountry: { value: "" },
      entitiesTypes: [
        { value: "nonprofit", text: "Non-Profit" },
        { value: "regular", text: "Regular" },
      ],
      taxDeductibleReceiptTypes: [
        {
          value: null,
          text: this.$t("account.tax_deductible_receipt_null", "Please select"),
        },
        { value: false, text: "No" },
        { value: true, text: "Yes" },
      ],
      countryList: [],
      loadind: false,
      errors: [],
      edit: false,
      showOptionaly: false,
      countryInvalid: false,
      fileName: "",
      autocomplete: null,
      accountLegalEntitiesComplianceQuestionsModel: [],
    };
  },

  components: {
    error: error,
    Multiselect,
  },

  computed: {
    ...mapState({
      addressAutocompleteLegalEntity: (state) => state.account.addressAutocompleteLegalEntity,
      accountLegalEntitiesComplianceQuestions: (state) =>
        state.account.accountLegalEntitiesComplianceQuestions,

      entitiesLists: (state) => {
        return state.account.entities;
      },

      includedReceiptConfigFr: function () {
        if (this.entities.data.attributes.country === "FR") {
          return this.entities.data.attributes.receipt_config_fr;
        }

        return null;
      },

      includedReceiptConfigCa: function () {
        if (this.entities.data.attributes.country === "CA") {
          return this.entities.data.attributes.receipt_config_ca;
        }

        return null;
      },

      includedReceiptConfigDe: function () {
        if (this.entities.data.attributes.country === "DE") {
          return this.entities.data.attributes.receipt_config_ge;
        }

        return null;
      },

      includedReceiptConfigNz: function () {
        if (this.entities.data.attributes.country === "NZ") {
          return this.entities.data.attributes.receipt_config_nz;
        }

        return null;
      },

      includedReceiptConfigIl: function () {
        if (this.entities.data.attributes.country === "IL") {
          return this.entities.data.attributes.receipt_config_il;
        }

        return null;
      },

      includedReceiptConfigZa: function () {
        if (this.entities.data.attributes.country === "ZA") {
          return this.entities.data.attributes.receipt_config_za;
        }

        return null;
      },

      sendCERFAReceipts: function () {
        return (
          this.entities.data.attributes.country === "FR" ||
          this.entities.data.attributes.country === "CA" ||
          this.entities.data.attributes.country === "DE" ||
          this.entities.data.attributes.country === "NZ" ||
          this.entities.data.attributes.country === "IL" ||
          this.entities.data.attributes.country === "ZA"
        );
      },

      hasAccess: (state) => {
        return state.account.access.CanEditOrganizationDetail;
      },

      orgId: function () {
        return localStorage.getItem("orgId");
      },
      userCountry: (state) => state.account.userCountry,
      setInitialCountry: function () {
        if (this.countryList.length) {
          return this.userCountry.length
            ? this.countryList.filter((el) => el.value === this.userCountry)
            : null;
        }
        return null;
      },
    }),

    taxIDLabel() {
      const countryCode = this.multiselectCountry.value.toLowerCase();

      switch (countryCode) {
        case "ca":
          return this.$t("account.tax_id_canada");

        case "gb":
          return this.$t("account.tax_id_gb", "UK Registered Charity Number");

        case "za":
          return this.$t("account.tax_id_za", "PBO No.");

        default:
          return this.$t("account.tax_id");
      }
    },

    zipLabel() {
      const countryCode = this.multiselectCountry.value.toLowerCase();

      switch (countryCode) {
        case "gb":
          return this.$t("account.zip_uk", "Postal Code");

        default:
          return this.$t("account.zpi");
      }
    },

    stateLabel() {
      const countryCode = this.multiselectCountry.value.toLowerCase();

      switch (countryCode) {
        case "gb":
          return this.$t("account.state_uk", "County");

        default:
          return this.$t("account.state");
      }
    },

    primaryLabel() {
      const countryCode = this.multiselectCountry.value.toLowerCase();

      switch (countryCode) {
        case "nz":
        case "au":
        case "gb":
          return this.$t("account.primary_org_flag_v2", "Primary organisation");

        default:
          return this.$t("account.primary_org_flag", "Primary organisation");
      }
    },

    someEntityIsPrimary() {
      return (
        this.entitiesLists.findIndex(
          (el) => el.attributes.primary && Number(el.id) !== Number(this.entities.data.id)
        ) !== -1
      );
    },

    accountLegalEntitiesComplianceQuestionsByCountry() {
      const countryCode = this.multiselectCountry.value.toLowerCase();
      return this.accountLegalEntitiesComplianceQuestions[countryCode] || [];
    },
  },

  created() {
    if (this.hasAccess) {
      this.getCoutryList();
    }
    this.CERFAReceipts.data = Object.assign({}, this.defaultCERFAReceipts.data);
  },

  watch: {
    includedReceiptConfigFr: function () {
      if (this.includedReceiptConfigFr) {
        this.CERFAReceipts.data = this.includedReceiptConfigFr;
      }
    },

    includedReceiptConfigCa: function () {
      if (this.includedReceiptConfigCa) {
        this.CERFAReceipts.data = this.includedReceiptConfigCa;
      }
    },

    includedReceiptConfigDe: function () {
      if (this.includedReceiptConfigDe) {
        this.CERFAReceipts.data = this.includedReceiptConfigDe;
      }
    },

    includedReceiptConfigNz: function () {
      if (this.includedReceiptConfigNz) {
        this.CERFAReceipts.data = this.includedReceiptConfigNz;
      }
    },

    includedReceiptConfigIl: function () {
      if (this.includedReceiptConfigIl) {
        this.CERFAReceipts.data = this.includedReceiptConfigIl;
      }
    },

    includedReceiptConfigZa: function () {
      if (this.includedReceiptConfigZa) {
        this.CERFAReceipts.data = this.includedReceiptConfigZa;
      }
    },

    "multiselectCountry.value": function (newValue, oldValue) {
      if (newValue && oldValue && newValue !== oldValue) {
        this.entities.data.attributes.state = "";
      }
    },

    hasAccess: {
      immediate: false,
      handler: function (nV) {
        if (nV) {
          this.getCoutryList();
        }
      },
    },

    accountLegalEntitiesComplianceQuestions: {
      handler: function (v) {
        if (v.length) {
          for (const [i] of this.accountLegalEntitiesComplianceQuestions.entries()) {
            this.accountLegalEntitiesComplianceQuestionsModel[i] = false;
          }
        }
      },
    },
  },

  methods: {
    initAutocomplete() {
      const el = this.$refs.address.$el;
      this.autocomplete = new google.maps.places.Autocomplete(el, {
        types: ["geocode"],
      });
      this.autocomplete.setFields(["address_component"]);
      this.autocomplete.addListener("place_changed", this.fillInAddress);
    },

    fillInAddress() {
      const componentForm = {
        street_number: { name: "short_name", key: "address_line_1" },
        route: { name: "long_name", add: true, key: "address_line_1" },
        locality: { name: "long_name", key: "city" },
        administrative_area_level_1: { name: "short_name", key: "state" },
        country: { name: "short_name", key: "country" },
        postal_code: { name: "short_name", key: "zip" },
      };

      const place = this.autocomplete.getPlace();

      for (let i = 0; i < place.address_components.length; i += 1) {
        const addressType = place.address_components[i].types[0];
        const { name, add, key } = componentForm[addressType] || {};
        if (name) {
          const val = place.address_components[i][name];

          if (key === "country") {
            this.multiselectCountry = this.countryList.find((el) => el.value === val) || {
              value: "",
            };
          }

          if (add) {
            this.entities.data.attributes[key] += ` ${val}`;
          } else {
            this.entities.data.attributes[key] = val;
          }
        }
      }
    },

    onTouch() {
      this.countryfieldsITouched = true;
    },

    openModal: function (gatewayStep) {
      if (this.setInitialCountry && this.setInitialCountry[0]) {
        this.multiselectCountry = this.setInitialCountry[0];
      }
      this.$root.$emit("bv::show::modal", "entitiesModal");
      this.gatewayStep = gatewayStep;
    },

    shown() {
      if (this.addressAutocompleteLegalEntity) {
        this.initAutocomplete();
      }
    },

    submitForm: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const fn = () => {
            if (this.gatewayModalRef) {
              this.gatewayModalRef.openModal(this.gatewayStep);
            }
            this.$emit("submit");
          };
          this.edit ? this.editEntry().then(fn) : this.addEntry().then(fn);
        }
      });
    },

    getCoutryList() {
      axios
        .get(urls.getEntitiesCountry.replace(":orgId", this.orgId))

        .then((response) => {
          let apiResult = response.data.data;
          let resultArr = [];

          for (var i = 0; i < apiResult.length; i++) {
            resultArr.push(apiResult[i].attributes);
          }
          let us = resultArr.filter((el) => el.value === "US")[0];
          let filtered = resultArr.filter((el) => el.value !== "US");
          this.countryList = [us, ...filtered];
        })

        .catch((e) => {
          this.catchError(e);
        });
    },

    editEntities(item) {
      this.edit = true;

      this.entities.data = JSON.parse(JSON.stringify(item));

      if (
        this.entities.data.attributes.country &&
        typeof this.entities.data.attributes.country != "object"
      ) {
        let selectCiuntry = this.countryList.filter((el) => {
          return el.value.toLowerCase() == this.entities.data.attributes.country.toLowerCase();
        });

        if (selectCiuntry.length > 0) {
          this.multiselectCountry = selectCiuntry[0];
        }
      }

      this.$root.$emit("bv::show::modal", "entitiesModal");
    },

    editEntry() {
      this.countryInvalid = !this.multiselectCountry;

      if (this.multiselectCountry) {
        this.entities.data.attributes.country = this.multiselectCountry.value;
      }

      this.errors = [];

      this.loadind = true;

      if (this.multiselectCountry.value === "CA") {
        if (this.sendCERFAReceipts) {
          this.prepareReceipt();
        }
      }

      if (this.multiselectCountry.value === "FR") {
        if (this.sendCERFAReceipts) {
          this.prepareReceipt();
        }
      }

      if (this.multiselectCountry.value === "DE") {
        if (this.sendCERFAReceipts) {
          this.prepareReceipt();
        }
      }

      if (this.multiselectCountry.value === "NZ") {
        if (this.sendCERFAReceipts) {
          this.prepareReceipt();
        }
      }

      if (this.multiselectCountry.value === "IL") {
        if (this.sendCERFAReceipts) {
          this.prepareReceipt();
        }
      }

      if (this.multiselectCountry.value === "ZA") {
        if (this.sendCERFAReceipts) {
          this.prepareReceipt();
        }
      }

      return axios
        .put(
          `${urls.editEntitie.replace(":orgId", this.orgId)}/${this.entities.data.id}`,
          this.entities
        )
        .then(({ data }) => {
          this.$store.dispatch("getEntities");

          this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));

          this.entities = {
            data: {
              type: "org_legal_entity",
              attributes: {
                type: "nonprofit",
                name: "",
                tax_id: "",
                address1: "",
                address2: "",
                country: "",
                city: "",
                tax_deductible_receipt: null,
                receipt_config_fr: null,
                receipt_config_ca: null,
                receipt_config_ge: null,
                zip: "",
                primary: false,
              },
            },
            included: [],
          };

          this.edit = false;

          this.$root.$emit("bv::hide::modal", "entitiesModal");

          return data;
        })
        .catch((e) => {
          this.catchError(e);
        })
        .finally(() => {
          this.loadind = false;
        });
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "entitiesModal");
    },

    prepareReceipt() {
      if (this.multiselectCountry.value === "CA") {
        const bh = this.CERFAReceipts.data.bh;
        const signature = this.CERFAReceipts.data.signature;
        const message = unwrapContentValue(this.CERFAReceipts.data.message);
        const full_name = this.CERFAReceipts.data.full_name;
        const tax_phone_number = this.CERFAReceipts.data.tax_phone_number;

        const canadaAdditionalData = {
          bh,
          signature,
          message,
          full_name,
          tax_phone_number,
        };

        this.entities.data.attributes.receipt_config_ca = canadaAdditionalData;
      }
      if (this.multiselectCountry.value === "FR") {
        this.entities.data.attributes.receipt_config_fr = this.CERFAReceipts.data;
      }

      if (this.multiselectCountry.value === "DE") {
        const {
          signature,
          purpose,
          tax_number,
          bank,
          iban,
          bic,
          assessment_period,
          term_1,
          term_2,
          term_3,
          term_4,
        } = this.CERFAReceipts.data;

        const germanAdditionalData = {
          signature,
          purpose,
          tax_number,
          bank,
          iban,
          bic,
          assessment_period,
          term_1,
          term_2,
          term_3,
          term_4,
        };
        this.entities.data.attributes.receipt_config_ge = germanAdditionalData;
      }

      if (this.multiselectCountry.value === "NZ") {
        const { nzbn, name_position, signature, ird } = this.CERFAReceipts.data || {};
        const newZealandAdditionalData = {
          nzbn,
          name_position,
          signature,
          ird,
        };
        this.entities.data.attributes.receipt_config_nz = newZealandAdditionalData;
      }

      if (this.multiselectCountry.value === "IL") {
        const section_46 = this.CERFAReceipts.data.section_46;
        const israelAdditionalData = {
          section_46,
        };
        this.entities.data.attributes.receipt_config_il = israelAdditionalData;
      }

      if (this.multiselectCountry.value === "ZA") {
        const { signature } = this.CERFAReceipts.data;
        const southAfricaAdditionalData = {
          signature,
        };
        this.entities.data.attributes.receipt_config_za = southAfricaAdditionalData;
      }
    },

    clearData: function () {
      this.edit = false;
      this.countryfieldsITouched = false;
      this.showOptionaly = false;
      this.errors = [];

      this.$validator.reset();
      this.multiselectCountry = { value: "" };
      this.entities = {
        data: {
          type: "org_legal_entity",
          attributes: {
            type: "nonprofit",
            name: "",
            tax_id: "",
            address1: "",
            address2: "",
            country: "",
            city: "",
            tax_deductible_receipt: null,
            receipt_config_fr: null,
            receipt_config_ca: null,
            receipt_config_ge: null,
            receipt_config_nz: null,
            receipt_config_il: null,
            receipt_config_za: null,
            zip: "",
            state: "",
            receipt_options: "",
            primary: false,
          },
        },
        included: [],
      };

      this.CERFAReceipts.data = Object.assign({}, this.defaultCERFAReceipts.data);
    },

    addEntry() {
      this.$validator.validate();
      this.countryInvalid = !this.multiselectCountry;

      if (this.multiselectCountry) {
        this.entities.data.attributes.country = this.multiselectCountry.value;
      }

      if (this.entities.data.attributes.country) {
        if (
          this.multiselectCountry.value === "FR" ||
          this.multiselectCountry.value === "CA" ||
          this.multiselectCountry.value === "DE" ||
          this.multiselectCountry.value === "NZ" ||
          this.multiselectCountry.value === "IL" ||
          this.multiselectCountry.value === "ZA"
        ) {
          this.prepareReceipt();
        }
        return axios
          .post(urls.postEntity.replace(":orgId", this.orgId), this.entities)
          .then(({ data }) => {
            this.$store.dispatch("getEntities");

            this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));

            this.entities = {
              data: {
                type: "org_legal_entity",
                attributes: {
                  type: "nonprofit",
                  name: "",
                  tax_id: "",
                  address1: "",
                  address2: "",
                  country: "",
                  city: "",
                  tax_deductible_receipt: null,
                  zip: "",
                  primary: false,
                },
              },
              included: [],
            };

            this.edit = false;

            this.$root.$emit("bv::hide::modal", "entitiesModal");

            return data;
          })
          .catch((e) => {
            this.catchError(e);
          })
          .finally(() => {
            this.loadind = false;
          });
      }
    },

    onReceiptLogoChange(fileInputEvent) {
      return this.processFile(fileInputEvent.target.files[0]).then(({ src }) => {
        this.entities.data.attributes.receipt_logo = src;
      });
    },

    onSignatureChange(fileInputEvent) {
      return this.processFile(fileInputEvent.target.files[0]).then(({ fileName, src }) => {
        this.CERFAReceipts.data.signature = src;
        this.fileName = fileName;
      });
    },

    processFile(file) {
      let orgId = localStorage.getItem("orgId");
      let form_data = new FormData();
      form_data.append("file", file);

      this.loadImage = true;
      return axios
        .post(urls.loadImageQuill.replace(":OrgId", orgId), form_data)
        .then((response) => {
          return { fileName: file.name, src: response.data.src };
        })
        .catch((e) => {
          this.catchError(e);
        })
        .finally(() => {
          this.loadImage = false;
          this.load = false;
        });
    },

    catchError(e) {
      if (e.response.data) {
        if (e.response.data.error) {
          this.errors.push(e.response.data.error);
        } else {
          this.errors.push(this.$t("template.error_default"));
        }
      } else {
        this.errors.push(this.$t("template.error_default"));
      }
    },
  },
};
</script>

<style lang="scss">
.pac-container {
  z-index: 1051;
}
</style>

<style lang="scss" scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.entities-data {
  &__labels {
    color: gray;
    text-transform: capitalize;
  }
  &__label {
    color: gray;
    text-transform: capitalize;
    &--required {
      position: relative;
      &:after {
        content: "*";
        font-size: 13px;
        line-height: 1;
        color: red;
        display: inline;
        position: relative;
        top: -2px;
      }
    }
  }
}
.entities-add {
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0 15px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  color: #20434e;
  background-color: #ffffff;
}
.btn-oval {
  width: auto;
  height: 45px;
  padding: 0 23px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  border: none;
  font-family: "ProximaNova-Bold", sans-serif;
  font-size: 16px;
  color: #ffffff;
  background-color: #cc6d4c;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover,
  &:active {
    background-color: darken(#cc6d4c, 5%);
    box-shadow: none;
    outline: 0;
  }
  &:focus {
    background-color: #cc6d4c;
    box-shadow: none;
    outline: 0;
  }
  &--cancel {
    background-color: gray;
    &:hover,
    &:active {
      background-color: darken(gray, 5%);
    }
    &:focus {
      background-color: gray;
    }
  }
}
.invalid .multiselect .multiselect__tags {
  border: 1px solid red;
}

.invalid .multiselect__tags {
  border: 1px solid red;
}

.custom-checkbox {
  position: relative;
  align-self: flex-end;
  margin-bottom: 10px;
  input {
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  label {
    margin: 0;
    cursor: pointer;
    padding-left: 24px;
    opacity: initial;
    visibility: initial;
    pointer-events: initial;
    font-size: 14px;
    color: #20434e;
    line-height: 1;
    position: relative;
    top: initial;
    left: initial;
    &:before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      border: 1px solid #20434e;
      border-radius: 4px;
      transition: border-width 0.2s ease-in-out;
      transform: translateY(-50%) translateZ(0px);
      position: absolute;
      left: 0;
      top: 50%;
    }
  }
  input:checked + label:before {
    border-color: #cc6d4c;
    border-width: 6px;
  }
}

.upload-file-box {
  input {
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
    position: absolute;
  }
  label {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    cursor: pointer;
    border: 1px dashed #cecece;
    text-align: center;
  }
}

.wrapper-options {
  width: 100%;
  padding: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eaf4ff;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
  }
}

.custom-label {
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  display: inline-block;
}
.img-thumbnail-wrap {
  width: 200px;
  height: 140px;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .loading-image {
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 5px;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
  }
}
.quill-editor {
  margin-bottom: 30px;
}
.quill-editor .ql-container {
  height: 400px;
  position: relative;
}

.language-selected .btn.btn-secondary.dropdown-toggle {
  background: transparent;
  border: 1px solid rgba(32, 67, 78, 0.15);
  color: #20434e;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  height: 42px;
  border-radius: 0;
}

.language-selected .dropdown-menu {
  color: #20434e;
}

.delete-description {
  background: transparent;
  border: 0;
  font-size: 16px;
  color: #cc6d4c;
  display: block;
  margin: 20px 0;
}

.loadin-image {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 140px;
  }
}

.alert-danger-editor li {
  display: block;
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px 20px;
  border-radius: 4px;
  border: 1px solid #f5c6cb;
  font-size: 14px;
}
.campaign-description {
  .ql-editor {
    font-family: "ProximaNova-Regular";
    font-size: 16px;
    strong {
      font-family: "ProximaNova-Bold";
    }
  }
}

.selected-tag-text {
  color: #107598;
  margin-bottom: 10px;
}

.description-area {
  width: 100%;
}
</style>
