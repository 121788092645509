<template>
  <div>
    <b-modal
      ref="modalEl"
      size="lg"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @hidden="clearData"
    >
      <div slot="modal-header">
        <h2>
          {{
            $t(
              `dashboard.org_tickets_${type}_modal_title`,
              type === "add" ? "Add ticket" : "Edit ticket"
            )
          }}
        </h2>
      </div>

      <org-tickets-form v-model="form" ref="formEl" />

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click="close">
          {{ $t(`dashboard.cancel_btn`) }}
        </button>

        <button class="bttn bttn--lg bttn--orange" @click="submit">
          {{ $t(`dashboard.org_tickets_${type}_modal_btn`, type === "add" ? "Add" : "Save") }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { notificationsMixin } from "@/mixins";
import OrgTicketsForm from "./OrgTicketsForm.vue";

export default {
  components: { OrgTicketsForm },

  mixins: [notificationsMixin],

  data() {
    return {
      id: 0,
      type: "add",
      form: {
        subject: "",
        message: "",
      },
    };
  },

  computed: {
    ...mapState({
      UID: (s) => s.user.user.id,
    }),
  },

  methods: {
    ...mapActions({
      fetchOrgTickets: "orgTickets/fetchOrgTicketsAndStore",
      fetchCreateOrgTicket: "orgTickets/fetchCreateOrgTicket",
      fetchUpdateOrgTicket: "orgTickets/fetchUpdateOrgTicket",
    }),

    open(type, item) {
      if (type === "edit") {
        this.type = type;
        this.id = item.id;
        this.form = JSON.parse(JSON.stringify(item));
      }
      this.$refs.modalEl?.show();
    },

    close() {
      this.$refs.modalEl?.hide();
    },

    submit() {
      this.$refs.formEl.validate().then((isVal) => {
        if (isVal) {
          if (this.type === "edit") {
            this.updateOrgTicket();
          } else {
            this.createOrgTicket();
          }
        }
      });
    },

    createOrgTicket() {
      const orgId = localStorage.getItem("orgId");

      const payload = {
        charidy_login_id: Number(this.UID),
        customer_org_id: Number(orgId),
        status: "to_do",
        assigned_on: "charidy",
        created_by: "organization",
        ...this.form,
      };

      this.fetchCreateOrgTicket({ payload })
        .then(() => {
          this.$root.$emit("updateOrgTicketList", { type: this.type });
          this.close();

          this.$_notificationsMixin_makeToast(
            "Success!",
            this.$t("dashboard.org_tickets_success_save", "Saved successfully"),
            "success"
          );
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    updateOrgTicket() {
      const { id } = this;
      const orgId = localStorage.getItem("orgId");

      const payload = {
        charidy_login_id: Number(this.UID),
        customer_org_id: Number(orgId),
        ...this.form,
      };

      this.fetchUpdateOrgTicket({ id, payload })
        .then(() => {
          this.$root.$emit("updateOrgTicketList", { type: this.type });
          this.close();
          this.$_notificationsMixin_makeToast(
            "Success!",
            this.$t("dashboard.org_tickets_success_save", "Saved successfully"),
            "success"
          );
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    clearData() {
      this.type = "add";
      this.form = {
        subject: "",
        message: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
