<template>
  <div>
    <div class="campaign-edit-title">
      <h2 class="h2-like">{{ $t("edit_campaign.description_title") }}</h2>
    </div>

    <b-form-group>
      <campign-description-form v-model="form.descriptions" />
    </b-form-group>

    <b-row>
      <b-btn
        class="bttn bttn--lg bttn--orange bttn--mw255 ml-auto"
        :disabled="sending"
        @click="onSave"
      >
        {{ $t("edit_campaign.generalinfo_save") }}
      </b-btn>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import campignDescriptionForm from "@/components/campign-description-form.vue";

export default {
  components: {
    campignDescriptionForm,
  },

  data() {
    return {
      form: {
        descriptions: [],
      },
      sending: false,
    };
  },

  computed: {
    ...mapState({
      cId: (state) => state.campaign.campaign.data.id,
      attrs: (state) => state.campaign.campaign.data.attributes,
      included: (state) => state.campaign.campaign.included || [],
    }),

    data() {
      const res = {};
      ["title", "short_link", "currency"].forEach((key) => {
        res[key] = this.attrs[key];
      });
      return res;
    },
  },

  created() {
    this.presetForm();
  },

  methods: {
    ...mapActions({
      fetchUpdateExpressCampaign: "expressCampaign/fetchUpdateExpressCampaign",
    }),

    presetForm() {
      let content = this.included.filter((el) => el.type === "content");
      this.form.descriptions = content.map((el) => ({
        short: el.attributes.language,
        content: el.attributes.content,
        tag: el.attributes.tag,
      }));
    },

    updateCampaign() {
      let extend = `?${[
        "extend=campaign_stats",
        "extend=content",
        "extend=media",
        "extend=matchers",
        "extend=donation_levels",
        "extend=donation_streams",
      ].join("&")}`;

      this.$store.dispatch("updateCampaign", extend);
    },

    getDescriptionsData() {
      const data = JSON.parse(JSON.stringify(this.form.descriptions));

      return data.map((el) => ({
        language: el.short,
        content: el.content,
        tag: el.tag,
      }));
    },

    onSave() {
      this.sending = true;

      this.fetchUpdateExpressCampaign([
        this.cId,
        {
          ...this.data,
          descriptions: this.getDescriptionsData(),
        },
      ])
        .then(() => {
          this.sending = false;
          this.$_notificationsMixin_makeToastCompat({
            group: "express-campaign-edit",
            title: this.$t("edit_campaign.success_edit"),
          });

          this.updateCampaign();
        })
        .catch(() => {
          this.sending = false;
        });
    },
  },
};
</script>

<style></style>
