<template>
  <div class="data-view-wrapper clearfix" v-if="service && isLoad">
    <div class="data-view">
      <div class="data-view__title">{{ $t("integration.service_title") }}</div>
      <div class="data-view__data">
        {{ service.attributes.service }}
      </div>
    </div>

    <div class="data-view">
      <div class="data-view__title">
        {{ $t("integration.service_integrated") }}
      </div>
      <div class="data-view__data">
        {{ getDateFormat(service.attributes.created_at) }}
      </div>
    </div>

    <div v-if="salesForceApiData.custom_formatter" class="data-view">
      <div class="data-view__title">
        {{ $t("integration.service_custom_formatter", "Custom formatter") }}
      </div>
      <div class="data-view__data">
        {{ salesForceApiData.custom_formatter }}
      </div>
    </div>

    <div v-if="salesForceApiData.chabad_one_campaign_id" class="data-view">
      <div class="data-view__title">
        {{ $t("integration.service_chabad_one_campaign_id", "Chabad One Campaign ID") }}
      </div>
      <div class="data-view__data">
        {{ salesForceApiData.chabad_one_campaign_id }}
      </div>
    </div>

    <div v-if="isChabadOne" class="data-view">
      <div class="data-view__title">
        {{
          $t("integration.service_autoexport_for_campaigns", "Limit Export for Campaign ID list")
        }}
      </div>
      <div class="data-view__data">
        <b-form-tags
          placeholder="Campaign ID List"
          input-type="number"
          v-model="salesForceApiData.autoexport_for_campaigns"
        ></b-form-tags>
      </div>
    </div>

    <div v-if="!isChabadOne" class="wrapper-options">
      <button
        type="button"
        @click="showOptionaly = !showOptionaly"
        class="show-hide-bttn"
        name="button"
      >
        {{
          !showOptionaly
            ? $t("integration.map_donors_property_show")
            : $t("integration.map_donors_property_hide")
        }}
        <i :class="!showOptionaly ? 'fa fa-angle-down' : 'fa fa-angle-up'" aria-hidden="true"></i>
      </button>
    </div>

    <div class="optionally-params" v-if="showOptionaly">
      <div class="data-view multi-row">
        <div class="data-view-row row">
          <div class="col-12 col-md-5">
            <div class="data-view__title">
              {{ $t("integration.donor_Object_type") }}
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="data-view__data input">
              <b-form-select
                id="field-select-type"
                v-model="objectTypeDonor"
                :options="objectTypeList"
              ></b-form-select>
            </div>
          </div>
        </div>

        <div class="data-view-row row">
          <div class="col-12 col-md-5">
            <div class="data-view__title" v-if="objectTypeDonor === 'Custom'">
              {{ $t("integration.donor_custom_type") }}
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="data-view__data" v-if="objectTypeDonor === 'Custom'">
              <b-form-input v-model="objectTypeDonorValue"> </b-form-input>
            </div>
          </div>
        </div>
      </div>

      <div class="row import-manual__row" v-for="(item, index) in donorFields" :key="index">
        <div class="col-12 col-md-5">
          <div class="form-group">
            <p>
              {{
                item.charidy_field && item.charidy_field != "static"
                  ? $t("integration.charidy-field")
                  : $t("integration.static_value")
              }}
            </p>

            <b-form-select
              v-if="item.new"
              id="field-select"
              :class="item.new && item.charidy_field === 'static' ? 'margin-static-select' : ''"
              v-model="item.charidy_field"
              :options="donorFieldsName"
            ></b-form-select>

            <b-form-input
              v-if="item.charidy_field && !item.new"
              disabled
              v-model="item.charidy_field"
            >
            </b-form-input>
            <b-form-input
              v-if="
                (item.static_value && item.charidy_field != 'static') ||
                (item.new && item.charidy_field === 'static')
              "
              :disabled="!item.new"
              v-model="item.static_value"
            >
            </b-form-input>
          </div>
        </div>

        <div class="col-12 col-md-5">
          <div class="form-group">
            <p :class="item.new && item.charidy_field === 'static' ? 'margin-static-field' : ''">
              {{ $t("integration.salesforse-field") }}
            </p>
            <b-form-input v-model="item.salesforce_field"></b-form-input>
          </div>
        </div>
        <div class="col-12 col-md-2 btn-box">
          <a class="import-manual__delete" role="button" @click.prevent="deleteDonorFields(index)">
            {{ $t("dashboard.add_donate_delete_row") }}
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 btn-row">
          <span class="import-manual__action">
            <button class="import-manual__add-row" @click.prevent="addDonorRow">
              {{ $t("dashboard.add_donate_add_row") }}
            </button>
          </span>
        </div>
      </div>
    </div>

    <div class="wrapper-options">
      <button
        type="button"
        @click="showOptionalyDonation = !showOptionalyDonation"
        class="show-hide-bttn"
        name="button"
      >
        {{
          !showOptionalyDonation
            ? $t("integration.map_donation_property_show")
            : $t("integration.map_donation_property_hide")
        }}
        <i
          :class="!showOptionalyDonation ? 'fa fa-angle-down' : 'fa fa-angle-up'"
          aria-hidden="true"
        ></i>
      </button>
    </div>
    <div class="optionally-params" v-if="showOptionalyDonation">
      <div class="data-view multi-row">
        <div class="data-view-row row">
          <div class="col-12 col-md-5">
            <div class="data-view__title">
              {{ $t("integration.donation_Object_type", "Object type") }}
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="data-view__data">
              <b-form-select
                id="field-select-type"
                v-model="objectTypeDonation"
                :options="objectTypeList"
              ></b-form-select>
            </div>
          </div>
        </div>

        <div class="data-view-row row">
          <div class="col-12 col-md-5">
            <div class="data-view__title" v-if="objectTypeDonation === 'Custom'">
              {{ $t("integration.donation_custom_type", "Custom type") }}
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="data-view__data" v-if="objectTypeDonation === 'Custom'">
              <b-form-input v-model="objectTypeDonationValue"> </b-form-input>
            </div>
          </div>
        </div>
      </div>

      <div class="row import-manual__row" v-for="(item, index) in donationFields" :key="index">
        <div class="col-12 col-md-5">
          <div class="form-group">
            <p>
              {{
                item.charidy_field && item.charidy_field != "static"
                  ? $t("integration.charidy-field")
                  : $t("integration.static_value")
              }}
            </p>

            <b-form-select
              v-if="item.new"
              id="field-select"
              :class="item.new && item.charidy_field === 'static' ? 'margin-static-select' : ''"
              v-model="item.charidy_field"
              :options="donationFieldsName"
            ></b-form-select>

            <b-form-input
              v-if="item.charidy_field && !item.new"
              disabled
              v-model="item.charidy_field"
            >
            </b-form-input>
            <b-form-input
              v-if="
                (item.static_value && item.charidy_field != 'static') ||
                (item.new && item.charidy_field === 'static')
              "
              :disabled="!item.new"
              v-model="item.static_value"
            >
            </b-form-input>
          </div>
        </div>

        <div class="col-12 col-md-5">
          <div class="form-group">
            <p :class="item.new && item.charidy_field === 'static' ? 'margin-static-field' : ''">
              {{ $t("integration.salesforse-field") }}
            </p>
            <b-form-input v-model="item.salesforce_field"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-md-2 btn-box">
          <a
            class="import-manual__delete"
            role="button"
            @click.prevent="deleteDonationFields(index)"
          >
            {{ $t("dashboard.add_donate_delete_row") }}
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 btn-row">
          <span class="import-manual__action">
            <button class="import-manual__add-row" @click.prevent="addDonationRow">
              {{ $t("dashboard.add_donate_add_row") }}
            </button>
          </span>
        </div>
      </div>
    </div>

    <div class="footer">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="updateStep">
        {{ $t("gateways.close_view") }}
      </button>
      <button
        class="bttn bttn--lg bttn--orange el-right"
        @click.prevent="updateSalesForceIntegrationDataMap"
      >
        {{ $t("gateways.update_view") }}
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import urls from "@/constants/urls.js";
import moment from "moment";

export default {
  props: ["service"],

  data() {
    return {
      salesForceApiData: {},
      showOptionaly: false,
      showOptionalyDonation: false,
      donorApiData: null,
      isLoad: null,
      donorFields: [],
      donationFields: [],
      objectTypeList: ["Account", "Contact", "Lead", "Custom"],
      objectTypeDonor: null,
      objectTypeDonation: null,
      objectTypeDonorValue: null,
      objectTypeDonationValue: null,
      donorFieldsName: [],
      donationFieldsName: [],
      errors: [],
    };
  },

  computed: {
    isChabadOne() {
      return this.salesForceApiData.custom_formatter === "ChabadOne";
    },
  },

  methods: {
    fieldList(section) {
      const fieldsArr = this[section];

      const resultArr = fieldsArr
        .filter((field) => field.charidy_field)
        .map((field) => ({
          text: field.charidy_field,
          value: field.charidy_field,
        }));

      // Remove duplicate keys
      const uniqueResultArr = [...new Map(resultArr.map((item) => [item.text, item])).values()];

      uniqueResultArr.push({ value: "static", text: "Static field" });

      return uniqueResultArr;
    },

    addRow(fieldsArray) {
      const fieldObj = {
        new: true,
        charidy_field: "static",
        salesforce_field: "",
        static_value: "",
      };
      fieldsArray.push(fieldObj);
    },

    deleteFields(index, fieldsArray) {
      if (index !== -1) {
        fieldsArray.splice(index, 1);
      }
    },

    addDonorRow() {
      this.addRow(this.donorFields);
    },

    addDonationRow() {
      this.addRow(this.donationFields);
    },

    deleteDonorFields(id) {
      this.deleteFields(id, this.donorFields);
    },

    deleteDonationFields(id) {
      this.deleteFields(id, this.donationFields);
    },

    getSalesForceIntegrationDataMap() {
      const orgId = localStorage.getItem("orgId");
      const url = urls.getSalesforceIntegrationDataMapV2(orgId, this.service.id);

      axios.get(url).then(this.handleSalesforceIntegrationDataMapResponse);
    },

    getSalesForceIntegrationDefaultDataMap() {
      const orgId = localStorage.getItem("orgId");
      const url = urls.getSalesforceIntegrationDefaultDataMapV2(orgId);

      axios.get(url).then(this.handleSalesforceIntegrationDataMapResponse);
    },

    handleSalesforceIntegrationDataMapResponse(response) {
      this.salesForceApiData = response.data;
      this.isLoad = true;
      this.getDonorDataMap();
      this.getDonationDataMap();
    },

    updateSalesForceIntegrationDataMap() {
      const orgId = localStorage.getItem("orgId");

      const processFields = (fields) => {
        fields.forEach((item) => {
          if (item.new) {
            delete item.new;
          }
          if (item.charidy_field === "static") {
            item.charidy_field = "";
          }
        });

        return fields;
      };

      this.salesForceApiData.donor_data_mapping = processFields(this.donorFields);
      this.salesForceApiData.donation_data_mapping = processFields(this.donationFields);

      const donorSObjectType =
        this.objectTypeDonor !== "Custom" ? this.objectTypeDonor : this.objectTypeDonorValue;
      const donationSObjectType =
        this.objectTypeDonation !== "Custom"
          ? this.objectTypeDonation
          : this.objectTypeDonationValue;

      this.salesForceApiData.donor_sobject_type = donorSObjectType;
      this.salesForceApiData.donation_sobject_type = donationSObjectType;

      const url = urls.getSalesforceIntegrationDataMapV2(orgId, this.service.id);

      this.salesForceApiData.autoexport_for_campaigns =
        this.salesForceApiData.autoexport_for_campaigns.map((e) => Number(e));

      axios.post(url, this.salesForceApiData).then(this.handleSalesforceIntegrationDataMapUpdate);
    },

    handleSalesforceIntegrationDataMapUpdate() {
      this.$emit("update-step");
    },

    getDonorDataMap() {
      const donorSObjectType = this.salesForceApiData.donor_sobject_type;

      this.donorFields = (this.salesForceApiData.donor_data_mapping || []).filter((item) => {
        return (
          (item.charidy_field && item.salesforce_field) ||
          (item.static_value && item.salesforce_field) ||
          item.new ||
          this.isChabadOne
        );
      });

      this.donorFieldsName = this.fieldList("donorFields");

      this.objectTypeDonor = this.checkType(donorSObjectType);
      if (this.objectTypeDonor === "Custom") {
        this.objectTypeDonorValue = donorSObjectType;
      }
    },

    checkType(type) {
      return type !== "Account" && type !== "Contact" && type !== "Lead" ? "Custom" : type;
    },

    getDonationDataMap() {
      const donationSObjectType = this.salesForceApiData.donation_sobject_type;

      this.donationFields = (this.salesForceApiData.donation_data_mapping || []).filter((item) => {
        return (
          (item.charidy_field && item.salesforce_field) ||
          (item.static_value && item.salesforce_field) ||
          item.new ||
          this.isChabadOne
        );
      });

      this.donationFieldsName = this.fieldList("donationFields");

      this.objectTypeDonation = this.checkType(donationSObjectType);
      if (this.objectTypeDonation === "Custom") {
        this.objectTypeDonationValue = donationSObjectType;
      }
    },

    updateStep() {
      this.$emit("update-step");
    },

    getDateFormat(date) {
      return moment.unix(date).format("MMMM D, YYYY h:mm a");
    },

    clearDataModal() {
      this.gatewayInfo = null;
      this.errors = [];
    },
  },

  created() {
    this.getSalesForceIntegrationDataMap();
  },
};
</script>

<style lang="scss" scoped>
.el-right {
  margin-left: auto;
}
.card {
  width: 100%;
}
.select {
  width: 50%;
}
.card-body {
  display: flex;
  align-items: center;
}
.type-input {
  height: 50px;
  width: 100%;
  margin: 0;
}

.donation-show-hide-options {
  border: 0;
  background: transparent;
  width: 100%;
  text-align: center;
  outline: none;
  margin: 30px 0;
}
.margin-static-field {
  margin-bottom: 80px;
}
.margin-static-select {
  margin-bottom: 15px;
}

.form-group {
  input {
    width: 100%;
  }
}
.import-manual {
  &__row {
    &:hover {
      .import-manual__delete {
        opacity: 1;
      }
    }
  }
  &__add-row {
    cursor: pointer;
    margin: 0;
    padding: 0 13px;
    height: 26px;
    font-size: 12px;
    line-height: 26px;
    text-transform: uppercase;
    background-color: #f2f4f5;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 13px;
    &:hover,
    &:focus,
    &:active {
      background-color: #f2f4f5;
      outline: 0;
      box-shadow: none;
    }
  }
  &__delete {
    opacity: 0;
    font-size: 14px;
    font-family: "ProximaNova-Regular", sans-serif;
    color: #cc6d4c;
    text-transform: capitalize;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover,
    &:focus {
      color: darken(#cc6d4c, 5%);
      text-decoration: none;
    }
  }
}
.footer {
  display: flex;
}
.optionally-params {
  margin-bottom: 15px;
}
.custom-select {
  height: 50px;
  border-radius: 4px;
}
.btn-box {
  align-items: center;
  display: flex;
  .import-manual__delete {
    margin-top: 20px;
    color: #cc6d4c;
  }
}

.btn-row {
  text-align: center;
  padding: 15px 0;
}
.wrapper-options {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #999999;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    z-index: 0;
  }
  button {
    width: auto;
    position: relative;
    z-index: 2;
    &:focus {
      outline: 0;
    }
  }
}

.multi-row {
  flex-wrap: wrap;
  .data-view__title {
    min-width: 50%;
    max-width: 50%;
  }
  input {
    width: 100%;
  }
}

.data-view-row {
  width: 100%;
  display: flex;
  align-items: center;
}
.data-view__data {
  max-width: 100%;
  width: 100%;
  margin-left: 15px;
}
</style>
