<template>
  <div v-if="activetab === 2">
    <section class="people">
      <div class="person">
        <img src="https://via.placeholder.com/150" alt="image" />
        <div class="description">
          <h1>Campaign Name</h1>
          <h2>01/20/19 - 01/23/19</h2>
          <p>Campaign Duration</p>
          <button class="information">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                fill="#107598"
              />
              <g opacity="0.903344">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.3372 7.97056C6.56755 7.92088 6.79414 8.06838 6.84347 8.29981L7.15333 9.75321C8.13987 8.06155 9.95066 7 11.9353 7C14.9917 7 17.4783 9.49929 17.4783 12.5714C17.4783 12.8081 17.2874 13 17.0519 13C16.8164 13 16.6255 12.8081 16.6255 12.5714C16.6255 9.97195 14.5215 7.85714 11.9353 7.85714C10.2493 7.85714 8.71136 8.76239 7.87872 10.2038L9.30024 9.89755C9.53056 9.84794 9.75719 9.99541 9.80648 10.2268C9.8558 10.4583 9.70913 10.6861 9.47888 10.7357L7.03875 11.2613C6.99731 11.2703 6.95521 11.2728 6.9137 11.2693C6.84456 11.2635 6.77716 11.2407 6.7178 11.2021C6.62284 11.1403 6.55619 11.0432 6.53248 10.9321L6.00953 8.47939C5.96028 8.248 6.10695 8.02021 6.3372 7.97056Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.96719 12.0003C7.20364 12.0079 7.38906 12.2005 7.38124 12.4305C7.3397 13.6545 7.79053 14.8208 8.6508 15.7149C9.51102 16.6089 10.6776 17.1235 11.9357 17.1639C11.9881 17.1656 12.0403 17.1664 12.0925 17.1664C13.7341 17.1664 15.2463 16.337 16.1003 14.9932L14.6112 15.3816C14.3827 15.4412 14.1478 15.3093 14.0865 15.087C14.0252 14.8647 14.1608 14.6361 14.3893 14.5765L16.8112 13.9448C16.921 13.9162 17.0379 13.9311 17.1363 13.9863C17.2347 14.0415 17.3065 14.1326 17.336 14.2394L17.9853 16.5955C18.0466 16.8179 17.911 17.0464 17.6824 17.106C17.6452 17.1157 17.608 17.1204 17.5712 17.1204C17.3821 17.1204 17.209 16.9976 17.1577 16.8114L16.7927 15.4872C15.7774 17.0425 14.0101 18.0001 12.0927 18C12.0311 18 11.9692 17.999 11.9074 17.997C10.4206 17.9492 9.04185 17.3411 8.0252 16.2845C7.00855 15.228 6.47574 13.8495 6.52486 12.403C6.53264 12.173 6.73077 11.9921 6.96719 12.0003Z"
                  fill="white"
                />
              </g></svg
            >Still Processing
          </button>
        </div>
      </div>

      <div class="person">
        <img src="https://via.placeholder.com/150" alt="image" />
        <div class="description">
          <h1>Campaign Name</h1>
          <h2>01/20/19 - 01/23/19</h2>
          <p>Campaign Duration</p>
          <button class="information">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.53693 5.04887C8.51239 5.09398 8.48785 5.1391 8.46332 5.17293C8.43878 5.20677 8.40197 5.2406 8.37744 5.27444C8.34063 5.30827 8.30383 5.34211 8.25476 5.37594C8.21795 5.3985 8.16888 5.43233 8.10754 5.44361C8.05846 5.46617 8.00939 5.47744 7.94805 5.48872C7.88671 5.5 7.83763 5.5 7.78856 5.5C7.73949 5.5 7.67815 5.5 7.62907 5.48872C7.58 5.47744 7.53093 5.46617 7.49412 5.44361C7.43278 5.42105 7.38371 5.3985 7.33464 5.36466C7.29783 5.34211 7.26103 5.30827 7.22422 5.27444L7.21195 5.26316C7.17515 5.22932 7.15061 5.19549 7.12607 5.16165C7.10154 5.12782 7.077 5.09398 7.06473 5.04887C7.06473 5.03759 7.06473 5.03759 7.05247 5.02632C7.02793 4.9812 7.01566 4.92481 7.00339 4.8797C6.99112 4.83459 6.99112 4.7782 6.99112 4.73308C6.99112 4.68797 6.99112 4.63158 7.00339 4.58647C7.01566 4.54135 7.02793 4.49624 7.05247 4.43985C7.08927 4.3609 7.13834 4.29323 7.19968 4.23684C7.21195 4.22556 7.21195 4.22556 7.22422 4.21429C7.26103 4.18045 7.29783 4.15789 7.3469 4.12406C7.39598 4.1015 7.43278 4.07895 7.48185 4.05639C7.53093 4.03383 7.58 4.02256 7.62907 4.01128C7.69041 4 7.73949 4 7.78856 4C7.83763 4 7.89898 4 7.94805 4.01128C7.99712 4.02256 8.04619 4.03383 8.09527 4.05639C8.15661 4.07895 8.20568 4.1015 8.24249 4.12406C8.29156 4.15789 8.32836 4.18045 8.36517 4.21429C8.40197 4.24812 8.43878 4.28195 8.46332 4.31579C8.48785 4.34962 8.51239 4.39474 8.53693 4.43985C8.56146 4.48496 8.57373 4.53008 8.586 4.57519C8.59827 4.63158 8.59827 4.67669 8.59827 4.7218C8.59827 4.76692 8.59827 4.82331 8.586 4.86842C8.57373 4.95865 8.54919 5.00376 8.53693 5.04887Z"
                fill="#107598"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.1914 10.4444H6.98717V7.4127C6.98717 7.19048 7.15881 7 7.39286 7H8.59431C8.81276 7 9 7.1746 9 7.4127C9 7.63492 8.82836 7.8254 8.59431 7.8254H7.85275V10.4603H8.59431C8.81276 10.4603 9 10.6349 9 10.873C9 11.0952 8.82836 11.2857 8.59431 11.2857H6.1914C5.97295 11.2857 5.78571 11.1111 5.78571 10.873C5.78571 10.6349 5.97295 10.4444 6.1914 10.4444Z"
                fill="#107598"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 7.5C15 3.35923 11.6408 0 7.5 0C3.35923 0 0 3.35923 0 7.5C0 11.6408 3.35923 15 7.5 15C11.6408 15 15 11.6408 15 7.5ZM14.0128 7.5C14.0128 11.0923 11.0923 14.0128 7.5 14.0128C3.90768 14.0128 0.987204 11.0923 0.987204 7.5C0.987204 3.90768 3.90768 0.987203 7.5 0.987203C11.0923 0.987203 14.0128 3.90768 14.0128 7.5Z"
                fill="#107598"
              /></svg
            >Still Processing
          </button>
        </div>
      </div>

      <div class="person">
        <img src="https://via.placeholder.com/150" alt="image" />
        <div class="description">
          <h1>Campaign Name</h1>
          <h2>01/20/19 - 01/23/19</h2>
          <p>Campaign Duration</p>
          <button class="information">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.53693 5.04887C8.51239 5.09398 8.48785 5.1391 8.46332 5.17293C8.43878 5.20677 8.40197 5.2406 8.37744 5.27444C8.34063 5.30827 8.30383 5.34211 8.25476 5.37594C8.21795 5.3985 8.16888 5.43233 8.10754 5.44361C8.05846 5.46617 8.00939 5.47744 7.94805 5.48872C7.88671 5.5 7.83763 5.5 7.78856 5.5C7.73949 5.5 7.67815 5.5 7.62907 5.48872C7.58 5.47744 7.53093 5.46617 7.49412 5.44361C7.43278 5.42105 7.38371 5.3985 7.33464 5.36466C7.29783 5.34211 7.26103 5.30827 7.22422 5.27444L7.21195 5.26316C7.17515 5.22932 7.15061 5.19549 7.12607 5.16165C7.10154 5.12782 7.077 5.09398 7.06473 5.04887C7.06473 5.03759 7.06473 5.03759 7.05247 5.02632C7.02793 4.9812 7.01566 4.92481 7.00339 4.8797C6.99112 4.83459 6.99112 4.7782 6.99112 4.73308C6.99112 4.68797 6.99112 4.63158 7.00339 4.58647C7.01566 4.54135 7.02793 4.49624 7.05247 4.43985C7.08927 4.3609 7.13834 4.29323 7.19968 4.23684C7.21195 4.22556 7.21195 4.22556 7.22422 4.21429C7.26103 4.18045 7.29783 4.15789 7.3469 4.12406C7.39598 4.1015 7.43278 4.07895 7.48185 4.05639C7.53093 4.03383 7.58 4.02256 7.62907 4.01128C7.69041 4 7.73949 4 7.78856 4C7.83763 4 7.89898 4 7.94805 4.01128C7.99712 4.02256 8.04619 4.03383 8.09527 4.05639C8.15661 4.07895 8.20568 4.1015 8.24249 4.12406C8.29156 4.15789 8.32836 4.18045 8.36517 4.21429C8.40197 4.24812 8.43878 4.28195 8.46332 4.31579C8.48785 4.34962 8.51239 4.39474 8.53693 4.43985C8.56146 4.48496 8.57373 4.53008 8.586 4.57519C8.59827 4.63158 8.59827 4.67669 8.59827 4.7218C8.59827 4.76692 8.59827 4.82331 8.586 4.86842C8.57373 4.95865 8.54919 5.00376 8.53693 5.04887Z"
                fill="#107598"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.1914 10.4444H6.98717V7.4127C6.98717 7.19048 7.15881 7 7.39286 7H8.59431C8.81276 7 9 7.1746 9 7.4127C9 7.63492 8.82836 7.8254 8.59431 7.8254H7.85275V10.4603H8.59431C8.81276 10.4603 9 10.6349 9 10.873C9 11.0952 8.82836 11.2857 8.59431 11.2857H6.1914C5.97295 11.2857 5.78571 11.1111 5.78571 10.873C5.78571 10.6349 5.97295 10.4444 6.1914 10.4444Z"
                fill="#107598"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15 7.5C15 3.35923 11.6408 0 7.5 0C3.35923 0 0 3.35923 0 7.5C0 11.6408 3.35923 15 7.5 15C11.6408 15 15 11.6408 15 7.5ZM14.0128 7.5C14.0128 11.0923 11.0923 14.0128 7.5 14.0128C3.90768 14.0128 0.987204 11.0923 0.987204 7.5C0.987204 3.90768 3.90768 0.987203 7.5 0.987203C11.0923 0.987203 14.0128 3.90768 14.0128 7.5Z"
                fill="#107598"
              /></svg
            >Still Processing
          </button>
        </div>
      </div>

      <div class="person">
        <img src="https://via.placeholder.com/150" alt="image" />
        <div class="description">
          <h1>Campaign Name</h1>
          <h2>01/20/19 - 01/23/19</h2>
          <p>Campaign Duration</p>
        </div>
      </div>

      <div class="person">
        <img src="https://via.placeholder.com/150" alt="image" />
        <div class="description">
          <h1>Campaign Name</h1>
          <h2>01/20/19 - 01/23/19</h2>
          <p>Campaign Duration</p>
        </div>
      </div>

      <div class="person">
        <img src="https://via.placeholder.com/150" alt="image" />
        <div class="description">
          <h1>Campaign Name</h1>
          <h2>01/20/19 - 01/23/19</h2>
          <p>Campaign Duration</p>
        </div>
      </div>

      <div class="person">
        <img src="https://via.placeholder.com/150" alt="image" />
        <div class="description">
          <h1>Campaign Name</h1>
          <h2>01/20/19 - 01/23/19</h2>
          <p>Campaign Duration</p>
        </div>
      </div>

      <div class="person">
        <img src="https://via.placeholder.com/150" alt="image" />
        <div class="description">
          <h1>Campaign Name</h1>
          <h2>01/20/19 - 01/23/19</h2>
          <p>Campaign Duration</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: "",
    };
  },

  props: {
    activetab: Number,
  },
};
</script>

<style lang="scss" scoped>
.people {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  justify-content: space-around;
  margin-bottom: -40px;
}

.person {
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(35, 40, 71, 0.0795686);
  border-radius: 6px;
  padding: 40px 60px 36px 60px;
  margin-bottom: 40px;
  > img {
    border-radius: 50%;
  }
}

.description {
  text-align: center;
  h1 {
    font-size: 16px;
    line-height: 28px;
    color: #01d39c;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 14px;
    line-height: 18px;
    color: #414359;
    margin-bottom: 1px;
  }
  p {
    font-size: 12px;
    line-height: 18px;
    color: #414359;
    opacity: 0.6;
  }
}

.information {
  padding: 0;
  align-items: center;
  border: none;
  background: transparent;
  font-size: 16px;
  line-height: 20px;
  color: #107598;
  svg {
    margin-right: 10px;
  }
}
</style>
