<template>
  <div class="emails-block">
    <category-title :title="$t('template.emails_notice.title')" />
    <div class="emails-body">
      <div class="emails-body__aside">
        <button
          class="nav-element"
          :class="index === active ? 'active' : ''"
          v-for="(item, index) in templateList"
          :key="index"
          @click.prevent="selectTemplate(index)"
        >
          <div class="nav-element__title">
            {{ item.attributes.title }}
          </div>
          <div class="nav-element__description">
            {{ item.attributes.subtitle }}
          </div>
        </button>
      </div>
      <div class="emails-body__main" v-if="selectedTemplate">
        <emailStats ref="emailStat" :template-data="selectedTemplate" :org-id="orgId" />
      </div>
    </div>
  </div>
</template>

<script>
import urls from "@/constants/urls";
import axios from "axios";
import categoryTitle from "@/common-components/ui-elements/category-title";
import emailStats from "./email-stats";

export default {
  components: {
    categoryTitle,
    emailStats,
  },
  data() {
    return {
      active: null,
      templateList: [],
      // templateId: null,
      // templateDescription: '',
      orgId: null,
      selectedTemplate: null,
    };
  },
  created() {
    this.orgId = Number(localStorage.getItem("orgId"));
    axios
      .get(urls.getTemplates.replace(":orgId", this.orgId))
      .then((res) => {
        this.templateList = res.data.data;
        this.active = 0;
        this.selectedTemplate = this.templateList[0];
      })
      .catch((err) => console.log(err));
  },
  methods: {
    selectTemplate(idx) {
      this.active = idx;
      this.$refs.emailStat.resetStep();
      this.selectedTemplate = this.templateList[idx];
    },
  },
};
</script>

<style lang="scss" scoped>
.emails-body {
  min-height: 760px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
  &__aside {
    margin-bottom: 20px;
    width: 100%;
    flex-shrink: 0;
    border-bottom: 1px solid $fl;
    display: flex;
    overflow-x: auto;
    @media only screen and (min-width: 768px) {
      display: block;
      margin-bottom: initial;
      width: 30%;
      border-bottom: none;
      border-right: 1px solid $fl;
    }
  }
  &__main {
    padding: 15px;
    width: 100%;
    @media only screen and (min-width: 768px) {
      width: 70%;
    }
  }
}

.nav-element {
  width: 100%;
  max-width: 280px;
  min-width: 280px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  color: $fd;
  background-color: #ffffff;
  border: none;
  border-bottom: 1px solid rgba(216, 216, 216, 0.3);
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    max-width: initial;
  }
  &:hover,
  &:focus {
    background-color: rgba(216, 216, 216, 0.3);
    outline: 0px;
  }
  &:not(:first-of-type) {
    @media only screen and (min-width: 768px) {
    }
  }
  &.active {
    background-color: rgba(216, 216, 216, 0.3);
    border-bottom-color: $fl;
  }
  &__title {
    width: 100%;
    font-family: $vito-bold;
    font-size: 16px;
    text-align: left;
  }
  &__description {
    width: 100%;
    font-family: $vito-light;
    font-size: 14px;
    text-align: left;
  }
}

.he {
  .emails-body__aside {
    @media only screen and (min-width: 768px) {
      border-right: none;
      border-left: 1px solid $fl;
    }
  }
  .nav-element {
    &__title {
      text-align: right;
    }
    &__description {
      text-align: right;
    }
  }
}
</style>
