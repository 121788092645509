var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._b({},'div',{},false),[_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-2 d-flex justify-content-between align-items-center",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{class:_vm.visible ? null : 'collapsed',attrs:{"aria-expanded":_vm.visible ? 'true' : 'false',"aria-controls":"document-fields-collapse","variant":"link"},on:{"click":function($event){_vm.visible = !_vm.visible}}},[_c('b-icon',{staticClass:"mx-1",attrs:{"icon":"arrow-down-short"}}),_vm._v(" "+_vm._s(_vm.$t("account.request_foundation_modal_step_document_fields_title", "Fields"))+" ")],1)],1),_c('b-collapse',{attrs:{"id":"document-fields-collapse","accordion":"document-fields-accordion","role":"tabpanel","visible":""},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('b-card-body',[_c('form',{attrs:{"id":_vm.submitByFormId},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('account.request_foundation_modal_step_document_fields_email_label', 'Email')}},[_c('b-form-input',{attrs:{"required":"","type":"email","value":_vm.state.signingDocumentFields.email},on:{"input":function($event){return _vm.setStateSigningDocumentFields('email', $event)}}})],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t(
                        'account.request_foundation_modal_step_document_fields_first_name_label',
                        'First Name'
                      )}},[_c('b-form-input',{attrs:{"required":"","value":_vm.state.signingDocumentFields.fname},on:{"input":function($event){return _vm.setStateSigningDocumentFields('fname', $event)}}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t(
                        'account.request_foundation_modal_step_document_fields_last_name_label',
                        'Last Name'
                      )}},[_c('b-form-input',{attrs:{"required":"","value":_vm.state.signingDocumentFields.lname},on:{"input":function($event){return _vm.setStateSigningDocumentFields('lname', $event)}}})],1)],1)],1),_c('b-form-group',{attrs:{"label":_vm.$t(
                    'account.request_foundation_modal_step_document_fields_client_company_label',
                    'Company'
                  )}},[_c('b-form-input',{attrs:{"required":"","value":_vm.state.signingDocumentFields.client.company},on:{"input":function($event){return _vm.setStateSigningDocumentFieldsClient('company', $event)}}})],1),_c('b-form-group',{attrs:{"label":_vm.$t(
                    'account.request_foundation_modal_step_document_fields_client_non_profit_type_label',
                    'Non-profit type'
                  )}},[_c('b-form-input',{attrs:{"required":"","value":_vm.state.signingDocumentFields.client.non_profit_type},on:{"input":function($event){return _vm.setStateSigningDocumentFieldsClient('non_profit_type', $event)}}})],1),_c('b-form-group',{attrs:{"label":_vm.$t(
                    'account.request_foundation_modal_step_document_fields_client_providing_label',
                    'Providing'
                  )}},[_c('b-form-input',{attrs:{"required":"","value":_vm.state.signingDocumentFields.client.providing},on:{"input":function($event){return _vm.setStateSigningDocumentFieldsClient('providing', $event)}}})],1),_c('b-form-group',{attrs:{"label":_vm.$t(
                    'account.request_foundation_modal_step_document_fields_client_campaign_shortlink_label',
                    'Campaign shortlink'
                  )}},[_c('b-form-input',{attrs:{"required":"","value":_vm.state.signingDocumentFields.client.campaign_shortlink},on:{"input":function($event){return _vm.setStateSigningDocumentFieldsClient('campaign_shortlink', $event)}}})],1),_c('b-form-group',{attrs:{"label":_vm.$t(
                    'account.request_foundation_modal_step_document_fields_client_position_label',
                    'Position'
                  )}},[_c('b-form-input',{attrs:{"required":"","value":_vm.state.signingDocumentFields.client.position},on:{"input":function($event){return _vm.setStateSigningDocumentFieldsClient('position', $event)}}})],1)],1)])],1)],1)],1),_c('b-card',{ref:"embed-document-card",staticClass:"mt-3",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0 no-bottom-border",attrs:{"header-tag":"header","role":"tab"}},[(!_vm.state.embedDocumentId)?_c('b-alert',{staticClass:"mb-0 no-bottom-border no-border-radius",attrs:{"variant":_vm.state.alertVariant,"show":""}},[_vm._v(" "+_vm._s(_vm.state.alertMessage)+" ")]):_c('b-alert',{staticClass:"mb-0 no-bottom-border no-border-radius",attrs:{"variant":"info","show":""}},[_vm._v(" "+_vm._s(_vm.$t( "account.request_foundation_modal_step_document_fields_alert_preview_edit", "Change fields here to recreate document" ))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }