<script>
import { mapState } from "vuex";
import FormGeneretor from "@/common-components/form-generetor/form-generetor";
import HeadlessMultipleSetting from "@/views/campaigns/campaign-settings/headless-multiple-setting.vue";

const onlyPositive = {
  lazy: true,
  handler: (value) => Math.abs(value),
};

const only2Decimals = {
  lazy: true,
  onMount: true,
  handler: (value) => Number(parseFloat(onlyPositive.handler(value)).toFixed(2)),
};

const GATEWAY_FEE_DEFAULT_PROPERTIES = {
  type: "object",
  expanded: true,
  properties: {
    percentage: {
      title: "Percentage",
      type: "number",
      default: 0,
      step: "any",
      min: 0,
      formatter: only2Decimals,
    },
    fixed: {
      title: "Fixed",
      type: "number",
      default: 0,
      step: "any",
      min: 0,
      formatter: only2Decimals,
    },
  },
};

export default {
  components: {
    FormGeneretor,
    HeadlessMultipleSetting,
  },
  data() {
    return {
      gatewayFeeScheme: null,
    };
  },
  created() {
    this.loadGateways();
  },
  methods: {
    async loadGateways() {
      let gateways = await this.$store.dispatch("getGatewaysAndReturn");
      const uniqueGateways = new Set();
      gateways = gateways
        .filter((v) => v.attributes.active)
        .filter((obj) => {
          const gateway = obj.attributes?.gateway;
          if (!gateway || uniqueGateways.has(gateway)) {
            return false;
          }
          uniqueGateways.add(gateway);
          return true;
        });
      const properties = gateways.reduce((acc, gateway) => {
        acc[gateway.attributes.gateway] = {
          title: gateway.attributes.gateway,
          ...GATEWAY_FEE_DEFAULT_PROPERTIES,
        };
        return acc;
      }, {});
      this.gatewayFeeScheme = {
        type: "object",
        layout: "accordion",
        properties,
      };
    },
    async onSubmit(form, save) {
      const elements = form.target.elements;
      let someElementIsInvalid = false;

      for (const element of elements) {
        if (!element.checkValidity()) {
          someElementIsInvalid = true;
          const key = element.name.split("|")[0];
          const button = document.getElementById(`${key}-object-layout-accordion-button`);
          const body = document.getElementById(`${key}-object-layout-accordion`);
          if (!body.classList.contains("show")) {
            button.click();
          }
          button.focus();
          this.$nextTick(() => {
            element.reportValidity();
          });
          break;
        }
      }

      if (!someElementIsInvalid) {
        await save();
      }
    },
  },
  computed: {
    ...mapState({}),
    includeFeeToMyDonatonScheme() {
      return {
        type: "object",
        properties: {
          value: {
            title: "Active",
            type: "boolean",
            default: false,
            expanded: true,
          },
          show_on_confirmation_step: {
            title: "Show On Confirmation Step",
            type: "boolean",
            default: false,
            expanded: true,
          },
          limit: {
            title: "Limit",
            type: "number",
            default: 0,
            expanded: true,
            min: 0,
            formatter: onlyPositive,
          },
          checked: {
            title: "Checked",
            type: "boolean",
            default: false,
            expanded: true,
          },
        },
      };
    },
  },
};
</script>

<template>
  <HeadlessMultipleSetting
    class="card-body"
    :settingScheme="{
      gateway_fee: gatewayFeeScheme,
      include_fee_to_my_donaton: includeFeeToMyDonatonScheme,
    }"
    v-slot="{ formData, save, loading }"
  >
    <form id="CoverCCFeeForm" novalidate @submit.prevent="onSubmit($event, save)">
      <FormGeneretor
        :scheme="includeFeeToMyDonatonScheme"
        :value="formData('include_fee_to_my_donaton')"
        key="include_fee_to_my_donaton"
        as="div"
        class="my-4"
        :components="$options.formGeneretorComponents"
      />

      <FormGeneretor
        v-if="!!gatewayFeeScheme"
        :scheme="gatewayFeeScheme"
        :value="formData('gateway_fee')"
        key="gateway_fee"
        as="div"
        class="my-4"
        :components="$options.formGeneretorComponents"
      />
      <div v-else class="accordion my-4" id="loading-accordion">
        <b-card
          no-body
          v-for="i in Array.from(Array(10).keys())"
          :key="`loading-accordion-card-${i}`"
        >
          <b-card-header role="tab">
            <h2 class="mb-0">
              <b-skeleton type="button" class="w-100" />
            </h2>
          </b-card-header>
          <b-collapse accordion="loading-accordion" role="tabpanel">
            <b-card-body />
          </b-collapse>
        </b-card>
      </div>
    </form>
    <div class="d-flex justify-content-end">
      <b-btn
        class="bttn bttn--sm bttn--orange"
        form="CoverCCFeeForm"
        type="submit"
        :disabled="loading"
        :style="{ gap: '10px' }"
      >
        <b-spinner v-if="loading" small></b-spinner>
        {{ $t("edit_campaign.setting_save", "Save") }}
      </b-btn>
    </div>
  </HeadlessMultipleSetting>
</template>
