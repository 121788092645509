const state = {
  // Integrations
  showIntegration: true,
  showSalesforce: true,
  showChabadOneSalesforce: true,
  showTaxGift: false,
  showComeunity: true,
  showWhatsApp: true,
  showJL: true,
  showDonorFuse: true,

  // Main Menus
  showAcademy: true,
  showVettingApplications: true,

  // Features - Org Settings
  showSubAccounts: true,
  showRequestCharidyFoundation: true,

  // Features - Dashboard
  showTeamManagement: true,
  showTeamEvent: true,
  showLeads: true,

  // Features - Campaign Edit
  showColorTool: true,
  showReceiptToggles: true,
  showSalesManager: true,
  showSeferTorahMenu: true,

  // Features - Teams
  showPinnedDonations: true,

  // Features - Templates
  excludedTemplate: [],

  // Features - Donation Reports
  excludedReports: [],

  // Others
  showIsraelLegalDocument: false,

  foundationRequestAdditionalBankAccountFields: false,
};

const mutations = {
  SET_IP_BASED_FEATURE(state, value) {
    const countryCode = value.toLowerCase();

    // foundation request show "bank_account_number" and "bank_account_swift" outside US
    if (countryCode !== "us") {
      state.foundationRequestAdditionalBankAccountFields = true;
    }

    switch (countryCode) {
      case "nz":
        // Integrations
        state.showChabadOneSalesforce = false;
        state.showComeunity = false;
        state.showWhatsApp = false;
        state.showJL = false;
        state.showDonorFuse = false;

        // Main Menus
        state.showAcademy = false;
        state.showVettingApplications = false;

        // Features - Org Settings
        state.showSubAccounts = false;
        (state.showRequestCharidyFoundation = false),
          // Features - Dashboard
          (state.showTeamManagement = false),
          (state.showTeamEvent = false),
          (state.showLeads = false),
          // Features - Campaign Edit
          (state.showColorTool = false);
        state.showReceiptToggles = false;
        state.showSalesManager = false;
        state.showSeferTorahMenu = false;

        // Features - Teams
        state.showPinnedDonations = false;

        // Features - Templates
        state.excludedTemplate = ["mehadrin", "sport", "shuvu", "passover", "lag_baomer", "purim"];

        // Features - Donations Reports
        state.excludedReports = [
          "foundation_report",
          "pickup_report",
          "report_sms",
          "report_email",
        ];
        break;
      case "au":
        // Integrations
        state.showChabadOneSalesforce = false;
        state.showComeunity = false;
        state.showWhatsApp = false;
        state.showJL = false;
        state.showDonorFuse = false;

        // Main Menus
        state.showAcademy = false;
        state.showVettingApplications = false;

        // Features - Org Settings
        state.showSubAccounts = false;
        (state.showRequestCharidyFoundation = false),
          // Features - Dashboard
          (state.showTeamManagement = false),
          (state.showTeamEvent = false),
          (state.showLeads = false),
          // Features - Campaign Edit
          (state.showColorTool = false);
        state.showReceiptToggles = false;
        state.showSalesManager = false;
        state.showSeferTorahMenu = false;

        // Features - Teams
        state.showPinnedDonations = false;

        // Features - Templates
        state.excludedTemplate = ["mehadrin", "sport", "shuvu", "passover", "lag_baomer", "purim"];

        // Features - Donations Reports
        state.excludedReports = [
          "foundation_report",
          "pickup_report",
          "report_sms",
          "report_email",
        ];
        break;
      case "il":
        state.showIsraelLegalDocument = true;
        break;
      default:
        state.showSalesforce = true;
        state.showIntegration = true;
        state.showAcademy = true;
        state.showColorTool = true;
        break;
    }
  },
};

const actions = {
  setIPBasedFeature({ commit }, value) {
    commit("SET_IP_BASED_FEATURE", value);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
