<template>
  <div id="app" :class="langCode" v-if="!loadingLang">
    <login-modal ref="loginModal" />
    <LegalAgreementDocument />
    <router-view></router-view>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import LegalAgreementDocument from "@/components/LegalAgreementDocument.vue";
import { getCookie } from "@/helpers/cookie.js";
import { notificationsMixin } from "@/mixins";
import loginModal from "./views/LoginModal.vue";
import { getLangCodeFromLocation } from "./constants/countries";

export default {
  components: {
    loginModal,
    LegalAgreementDocument,
  },

  mixins: [notificationsMixin],

  computed: mapState({
    loadingLang: (state) => {
      return state.translation.loadTranslation;
    },

    langCode: (state) => {
      return state.translation.locate;
    },
  }),

  methods: {
    setLocale(countryCode, lang) {
      this.$store.dispatch("featureHide/setIPBasedFeature", countryCode);
      this.$store.dispatch("setTranslation", lang);
    },
    async onCreated() {
      let lang = getCookie("language") || null;

      await this.$store.dispatch("checkToken");

      this.$store
        .dispatch("initData")
        .then(({ headers }) => {
          const countryCode = headers["x-geo-ip-country"].toLocaleLowerCase();

          if (lang) return Promise.reject({ countryCode, lang });
          const firstLang = getLangCodeFromLocation(countryCode);

          // 2. set lang on first time
          this.setLocale(countryCode, firstLang);

          return { countryCode };
        })
        .then(
          ({ countryCode }) =>
            new Promise((resolve, reject) =>
              this.$store
                .dispatch("getLanguages")
                .then((languagesAvailable) => resolve({ countryCode, languagesAvailable }))
                .catch((err) => reject(err))
            )
        )
        .then(({ countryCode, languagesAvailable }) => {
          const detectedLang = getLangCodeFromLocation(countryCode);
          const lang = languagesAvailable.find(({ short }) => short === detectedLang)?.short;
          if (!lang) {
            console.warn(
              `Could not detect language. ${detectedLang.toUpperCase()} not defined for current organization`
            );
          }

          // 3. set lang again for org, if available
          this.setLocale(countryCode, detectedLang);
        })
        .catch((e) => {
          if (e instanceof Error) {
            console.error(e);
            return;
          }
          if (!e?.countryCode && !e?.lang) {
            console.warn("No lang set");
            return;
          }

          // 1. set lang from cookie
          const { countryCode, lang } = e;
          this.setLocale(countryCode, lang);
        });

      this.$store.dispatch("getTranslation");

      sessionStorage.setItem("show_access_toasts", this.$route.query.show_access_toasts || "0");
      const token =
        this.$route.currentRoute?.params?.token || this.$route.currentRoute?.query?.token;

      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          const { status, config, data } = error.response || {};

          if (status == 403 || status == 401) {
            const { forbidden } = data || {};

            if (forbidden) {
              const url = new URL(config.url);

              const action = forbidden.replace("Can", "");

              let msg = `${action}`;

              if (sessionStorage.getItem("show_access_toasts") === "1") {
                msg = `${action} - ${url.pathname}`;
              }

              const h = this.$createElement;

              const vNodesDetails = h(
                "div",
                {
                  class: ["text-break"],
                },
                msg
              );

              this.$_notificationsMixin_makeToast("Access forbidden", [vNodesDetails], "warning");
            }

            if (
              !token &&
              !forbidden &&
              this.$route.path.indexOf("login") == -1 &&
              this.$route.path.indexOf("log-in") == -1 &&
              this.$route.path.indexOf("register") == -1 &&
              this.$route.path.indexOf("sign-up") == -1 &&
              this.$route.path.indexOf("resetpassword") == -1 &&
              this.$route.name !== "DonorChooseAccount"
            ) {
              this.$refs.loginModal.openModal();
            }

            if (forbidden === "CanSeeOrganizationDetail") {
              localStorage.removeItem("orgId");
              this.$refs.loginModal.openModal();
            }
          }

          return Promise.reject(error);
        }
      );

      window.onblur = () => {
        this.$store.commit("setBlured", true);
      };
      window.onfocus = () => {
        this.$store.commit("setBlured", false);
      };
    },
  },

  created: function () {
    this.onCreated();
  },
};
</script>

<style lang="scss">
#app {
  font-family: $vito-light;
  background-color: $bg-20;
  min-width: 320px;
  overflow: auto;
}

#loginModalBox .login-inner {
  max-width: 100%;
}

#loginModalBox #login {
  height: 100%;
}

.load-image-input {
  display: none !important;
}
</style>
