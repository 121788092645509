<template>
  <section>
    <ValidationObserver ref="observer">
      <b-row>
        <b-col v-if="!hideSubject" cols="12">
          <b-form-group>
            <template #label>
              <label class="form-group__label">
                {{ $t("dashboard.email_message_subject", "Subject") }}
              </label>
            </template>
            <b-form-input type="text" v-model="subject" />
          </b-form-group>
        </b-col>

        <b-col v-if="showReplyTo" cols="12">
          <b-form-group
            v-if="value.test_mode"
            :description="
              $t(
                'dashboard.email_message_test_mode_recipient_desc',
                'Use this field to send message to yourself'
              )
            "
          >
            <template v-slot:label>
              <label
                class="form-group__label form-group__label--required text-danger font-weight-bold"
              >
                {{ $t("dashboard.email_message_test_mode_recipient", "Test mode recipient") }}
              </label>
            </template>
            <b-form-input type="email" v-model="testModeRecepientEmail" required></b-form-input>
          </b-form-group>
          <b-form-group
            v-else
            :description="
              $t(
                'dashboard.email_message_replay_to_desc',
                'Use this field to resend message to person'
              )
            "
          >
            <template v-slot:label>
              <label class="form-group__label form-group__label--required">
                {{ $t("dashboard.email_message_replay_to", "Replay To") }}
              </label>
            </template>
            <b-form-input type="email" v-model="replayTo" required></b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <ValidationProvider name="message" :rules="rules" v-slot="{ errors, valid }">
            <b-form-group>
              <template #label>
                <label class="form-group__label form-group__label--required">
                  {{ $t("dashboard.email_message_data", "Message") }}
                </label>
              </template>
              <quill-editor-variables v-model="message" :tags="tagsloc" :simple="simple">
                <template #btn-content>
                  {{ $t("donor_dashboard.email_template_edit_title", "Email variables") }}
                </template>
                <template #description>
                  {{ $t("donor_dashboard.email_template_var_description", "variables") }}
                </template>
              </quill-editor-variables>
              <template #description>
                <span v-if="maxLength" :class="{ 'text-danger': charactersLeft < 0 }">
                  {{ `${charactersLeftText} characters left` }}
                </span>
              </template>
              <b-form-invalid-feedback :state="valid">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </ValidationObserver>
  </section>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import quillEditorVariables from "@/common-components/ui-elements/quill-editor-variables.vue";

const buildGetSet = (key) => {
  return {
    get() {
      return this.value[key] || "";
    },
    set(v) {
      this.$set(this.value, key, v);
    },
  };
};

export default {
  components: { quillEditorVariables, ValidationObserver, ValidationProvider },

  props: {
    value: {
      type: Object,
      default: () => {},
    },
    tags: {
      type: Array,
      default: () => [],
    },
    maxLength: {
      type: Number,
      default: 0,
    },
    simple: Boolean,
    hideSubject: Boolean,
    showReplyTo: Boolean,
  },

  computed: {
    subject: buildGetSet("subject"),
    replayTo: buildGetSet("replay_to"),
    message: buildGetSet("message"),
    testModeRecepientEmail: buildGetSet("test_mode_recepient_email"),
    tagsloc() {
      return this.tags;
    },
    rules() {
      if (this.maxLength > 0) {
        return `required|max:${this.maxLength}`;
      }
      return "required";
    },
    msgLength() {
      return new Blob([this.message]).size;
    },
    charactersLeft() {
      return this.maxLength - this.msgLength;
    },
    charactersLeftText() {
      const l = this.msgLength;
      return `(${l} / ${this.maxLength}) ${this.charactersLeft}`;
    },
  },

  methods: {
    validate() {
      return new Promise((resolve) => {
        this.$refs.observer.validate().then((valid) => {
          if (valid) {
            resolve();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
