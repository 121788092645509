<template>
  <div class="donor-component">
    <div class="donor-wrapper">
      <main-header></main-header>
      <div v-if="haveAccount" class="d-flex align-items-start justify-content-between main">
        <short-profile></short-profile>
        <router-view class="right-panel"></router-view>
      </div>
      <div v-if="!haveAccount" class="d-flex justify-content-center p-3">
        <h2>Profile is empty</h2>
      </div>
      <CharidyV2Footer />
      <show-settings-confirm-msg />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getCookie } from "@/helpers/cookie.js";
import mainHeader from "../common-components/main-header.vue";
import CharidyV2Footer from "@/common-components/cdn-v2/cdn-footer.vue";
import shortProfile from "./short-profile.vue";
import showSettingsConfirmMsg from "../modals/show-settings-confirm-msg.vue";

export default {
  data() {
    return {};
  },
  components: {
    "main-header": mainHeader,
    CharidyV2Footer,
    "short-profile": shortProfile,
    showSettingsConfirmMsg,
  },

  computed: mapState({
    haveAccount: (state) => {
      return state.donorAccount.account.attributes;
    },
  }),

  created: function () {
    let lang = getCookie("language");
    this.$store.dispatch("setTranslationPermanent", lang);
    this.$store.dispatch("getTranslation");
    this.$store.dispatch("getDonorAccountInfo");
    this.$store.dispatch("getTimeZones");
  },
};
</script>

<style lang="scss">
.right-panel {
  width: 74%;
}
.donor-component {
  min-height: 100vh;
  width: 100%;
  overflow: auto;
  background: url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/main-bg.png")
    top center no-repeat;
  background-size: 100% auto;
}
.donor-wrapper {
  min-width: 1440px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
}
.container-fluid {
  max-width: 1500px;
  margin: 0 auto;
}
.panel {
  box-shadow: 0 8px 32px 0 rgba(35, 40, 71, 0.08);
  background-color: #ffffff;
}
.main {
  margin: 0 auto;
  margin-bottom: 70px;
  max-width: 1440px;
}
</style>
