<template>
  <div>
    <b-container class="shadow-sm bg-white rounded border border-light">
      <div class="d-flex flex-row text-center align-items-center">
        <div v-show="team.image" class="pr-4 py-2 d-flex justify-content-start">
          <b-avatar :alt="team.name" :src="team.image" size="3rem" />
        </div>
        <div class="d-flex flex-column flex-fill justify-content-center align-items-baseline">
          <p v-if="showDonorPageTeamLink" class="m-0 font-weight-bold text-break text-team">
            <router-link :to="`/donor-my-teams/${team.id}`">
              {{ team.name }}
            </router-link>
          </p>
          <p v-else class="m-0 font-weight-bold text-break text-team">
            {{ team.name }}
          </p>
          <p class="m-0 font-weight-bold text-break text-team">
            {{ campaignTitle }}
            <a :href="cmpLink" target="_blank" rel="noopener noreferrer">
              <b-icon-box-arrow-up-right />
            </a>
          </p>
        </div>
        <div
          class="px-3 d-flex flex-row justify-content-end align-self-center align-items-center"
          v-if="slotStats.teamStatsNotEmpty && isFinite(slotStats.progress)"
        >
          <div class="d-flex flex-column text-right pr-3">
            <span class="text-percent font-weight-bold">
              {{ Math.round(slotStats.progress) }}%
            </span>
            <span class="text-completed text-uppercase font-weight-bold text-black-50">
              Completed
            </span>
          </div>
          <vue-circle
            :size="40"
            :progress="slotStats.progress"
            line-cap="round"
            :fill="{ color: '#107598' }"
            empty-fill="rgba(0, 0, 0, .1)"
            :animation-start-value="0.0"
            insert-mode="append"
            :thickness="6"
            :start-angle="-Math.PI / 2"
            :show-percent="false"
          />
        </div>
        <div class="px-1 m-0 d-flex justify-content-end">
          <button
            class="link-circle"
            aria-label="Edit team"
            @click="$emit('click-edit-team', $event)"
          >
            <b-icon-pencil />
          </button>
        </div>
        <div v-if="showDonorPageTeamLink" class="px-1 m-0 d-flex justify-content-end">
          <router-link :to="`/donor-my-teams/${team.id}`" class="link-circle">
            <b-icon-info />
          </router-link>
        </div>
      </div>
      <b-row class="w-100 p-0 pt-2" v-if="slotStats.teamStatsNotEmpty">
        <b-col class="w-25 pl-4 pr-4 pb-3 justify-content-center align-items-baseline border-right">
          <b-badge pill variant="c-secondary">
            {{
              $t("donor_dashboard.amount_rank", "Rank: {rank}", {
                rank: slotStats.donationAmountRank,
              })
            }}
          </b-badge>
          <p class="p-0 m-0 text-title text-uppercase font-weight-bold">
            {{ $t("donor_dashboard.amount", "amount") }}
          </p>
          <p class="p-0 m-0 text-highlited text-uppercase font-weight-bold">
            {{ slotStats.donationAmount }} {{ currency }}
          </p>
          <p class="p-0 m-0 text-tooltip text-uppercase font-weight-bold">
            {{
              `${this.$t("donor_dashboard.of", "of")} ${Math.ceil(team.goal)} ${currency} ${$t(
                "donor_dashboard.raised",
                "raised"
              )}`
            }}
          </p>
        </b-col>
        <b-col class="w-25 pl-4 pr-4 pb-3 justify-content-center align-items-baseline">
          <b-badge pill variant="c-secondary">
            {{
              $t("donor_dashboard.donors_rank", "Rank: {rank}", { rank: slotStats.donorsCountRank })
            }}
          </b-badge>
          <p class="p-0 m-0 text-title text-uppercase font-weight-bold">
            {{ $t("donor_dashboard.count", "donors") }}
          </p>
          <p class="p-0 m-0 text-highlited text-uppercase font-weight-bold">
            {{ slotStats.donorsCount }}
          </p>
        </b-col>
      </b-row>
      <div
        v-if="!hideDescription && team.description"
        class="py-3 desc"
        v-html="team.description"
      />
    </b-container>
  </div>
</template>

<script>
import {
  teamStatsNotEmpty,
  donationAmount,
  donationAmountRank,
  donorsCount,
  donorsCountRank,
  progress,
} from "@/helpers/team-stats";
import VueCircle from "vue2-circle-progress/src/index.vue";

export default {
  components: {
    VueCircle,
  },
  props: {
    team: {
      type: Object,
      default: () => ({}),
    },
    teamStats: {
      type: Object,
      default: () => ({}),
    },
    campaign: {
      type: Object,
      default: () => ({}),
    },
    showDonorPageTeamLink: Boolean,
    hideDescription: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    slotStats() {
      return {
        teamStatsNotEmpty: teamStatsNotEmpty(this.teamStats),
        donationAmount: donationAmount(this.teamStats),
        donationAmountRank: donationAmountRank(this.teamStats),
        donorsCount: donorsCount(this.teamStats),
        donorsCountRank: donorsCountRank(this.teamStats),
        progress: progress(this.teamStats, this.team.goal),
      };
    },
    currency() {
      return this.campaign && this.campaign.currency;
    },
    campaignTitle() {
      return this.campaign && this.campaign.title;
    },
    cmpLink() {
      let base = "https://charidy.com/";
      if (location.host.includes("develop")) {
        base = "https://ssr.develop.charidy.com/";
      }
      return `${base}${this.campaign?.short_link || ""}`;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
$numbers-font-size: 26px;
$labels-font-size: 11px;

::v-deep .circle {
  width: 40px;
  height: 40px;
}

.text-title {
  font-size: $labels-font-size;
  color: #898989;
}

.text-tooltip {
  font-size: $labels-font-size;
  color: #cdcdcd;
}

.text-highlited {
  color: #000;
  font-size: $numbers-font-size;
}

.text-team {
  font-size: 15px;
}

.text-percent {
  font-size: $numbers-font-size;
  color: $bb-100;
}

.text-completed {
  font-size: $labels-font-size;
}

.link-circle {
  display: flex;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid $bb-100;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: ease-in 0.3s, ease-out 0.3s;
  svg {
    display: block;
    width: 20px;
    height: 20px;
    color: black;
  }
  &:hover:not(:disabled) {
    background-color: $bb-100;
    svg {
      color: white;
    }
  }
  &:focus {
    outline: none;
  }
}

::v-deep {
  .btn-outline-primary {
    color: $bb-100;
    border: 2px solid $bb-100;
    transition: all 0.2s ease-in-out;
    &:hover,
    &:active {
      color: #ffffff;
      background-color: $bb-100;
      border: 2px solid $bb-100;
    }
  }
}

.desc {
  width: 100%;
  position: relative;
  img {
    max-width: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  p {
    margin: 0 0 10px;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
