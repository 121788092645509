<template>
  <div class="media-storage" v-if="!loading">
    <div class="category-title">
      <multiselect-search
        v-model="selectedCmp"
        :open-prefetch="true"
        :allow-empty="false"
        :search-url="getSearchUrl"
        :search-options="searchOptions"
        skip-external-serching
        class="w-100"
      >
      </multiselect-search>
    </div>

    <div v-if="selectedCmp">
      <MediaStorageTable />
    </div>
  </div>
  <div v-else>
    <div class="text-center">
      <b-spinner class="align-middle"></b-spinner>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import urls from "@/constants/urls";
import MediaStorageTable from "./MediaStorageTable.vue";
import MultiselectSearch from "@/components/multiselect-search.vue";

const extendParams = ["campaign_stats", "media", "meta", "donation_levels", "donation_streams"];

export default {
  components: {
    MediaStorageTable,
    MultiselectSearch,
  },

  computed: {
    ...mapState({
      campaigns: (state) => state.mediaStorage.campaigns,
      loading: (state) => state.mediaStorage.loading,
    }),

    selectedCmp: {
      get() {
        return this.$store.state.mediaStorage.campaign;
      },
      set(val) {
        this.selectCampaign(val);
      },
    },

    getSearchUrl() {
      const orgID = localStorage.getItem("orgId");

      return urls.getCampaignList.replace(":orgId", orgID);
    },

    searchOptions() {
      return {
        placeholder: "Select campaign",
        changeParams: ({ page, per_page: pp }) => ({
          page: page,
          perPage: pp,
          extend: extendParams,
        }),
        customLabel: (e) => `[${e.id}] ${e.attributes.title}`,
      };
    },
  },

  created() {
    this.fetchCampaigns();
  },

  methods: {
    ...mapActions({
      fetchCampaigns: "mediaStorage/fetchCampaigns",
      selectCampaign: "mediaStorage/selectCampaign",
    }),
  },
};
</script>
