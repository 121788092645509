<template>
  <div class="stripe-gateways" :class="{}">
    <div class="notice notice--loading" v-if="loadingStripeOnBoarding">
      <div class="notice__warning d-flex align-items-center justify-content-center">
        <b-spinner />
      </div>
      <div class="notice__content w-100">
        <p class="notice__title">
          {{
            $t(
              "gateways.stripe_gateways_links_title_v2_loading",
              "Stripe verification status loading..."
            )
          }}
        </p>
        <p class="notice__text">
          <b-skeleton width="85%" />
          <b-skeleton width="55%" />
          <b-skeleton width="70%" />
        </p>
      </div>
      <b-skeleton type="button" />
    </div>
    <template v-else-if="!loadingStripeOnBoarding && stripeGatewaysPresent">
      <div class="notice notice--verified" v-if="this.everyGatewayVerifiedAndNoErrors">
        <div class="notice__warning d-flex align-items-center justify-content-center">
          <b-icon-check style="width: 63px; height: 63px" />
        </div>
        <div class="notice__content">
          <p class="notice__title">
            {{
              $t(
                "gateways.stripe_gateways_links_title_v2_verified",
                "Your Stripe accounts have been verified"
              )
            }}
          </p>
        </div>
      </div>
      <div class="notice notice--verified" v-else-if="this.someGatewayVerifiedAndNoErrors">
        <div class="notice__warning d-flex align-items-center justify-content-center">
          <b-icon-check style="width: 63px; height: 63px" />
        </div>
        <div class="notice__content">
          <p class="notice__title">
            {{
              $t(
                "gateways.stripe_gateways_links_title_v2_some_verified",
                "One Stripe account has been verified ::: Multiple Stripe accounts ({count}) have been verified",
                {
                  count: partiallyVerified.length,
                },
                partiallyVerified.length
              )
            }}
          </p>
        </div>
      </div>
      <div class="notice" v-for="(gl, i) in gatewayLinksFiltered" :key="i">
        <div class="notice__warning d-flex align-items-center justify-content-center">
          <component :is="noticeIconComponent(gl)" style="width: 63px; height: 63px" />
        </div>
        <div class="notice__content">
          <p class="notice__title">
            {{ noticeTitle(gl) }}
          </p>

          <p class="notice__text" v-html="getSanitizedNoticeTkey(gl)"></p>

          <div
            class="mt-2"
            v-for="(fr, index) in gl.requirementsErrors"
            :key="`${fr.code}-${fr.requirement}-${index}`"
          >
            <p class="notice__text">
              <strong class="notice__title">
                {{
                  $t(
                    "gateways.stripe_gateways_links_requirements_error_reason",
                    "Verification error:"
                  )
                }}
              </strong>
              <span>{{ fr.reason }}</span>
            </p>
            <p class="notice__text">
              <strong class="notice__title">
                {{ $t("gateways.stripe_gateways_links_requirements_error_requirement", "For:") }}
              </strong>
              <span>{{ fr.requirement }}</span>
            </p>
          </div>
          <div
            class="mt-2"
            v-for="(fr, index) in gl.futureRequirementsErrors"
            :key="`${fr.code}-${fr.requirement}-${index}`"
          >
            <p class="notice__text">
              <strong class="notice__title">
                {{
                  $t(
                    "gateways.stripe_gateways_links_future_requirements_error_reason",
                    "Future verification error:"
                  )
                }}
              </strong>
              <span>{{ fr.reason }}</span>
            </p>
            <p class="notice__text">
              <strong class="notice__title">
                {{
                  $t("gateways.stripe_gateways_links_future_requirements_error_requirement", "For:")
                }}
              </strong>
              <span>{{ fr.requirement }}</span>
            </p>
          </div>

          <p class="notice__text mt-2" v-html="getPaymentStatus(gl)"></p>

          <div v-if="getFutureRequirementFields(gl)" class="my-1">
            <b-button v-b-toggle.FutureRequirementFields variant="link">
              {{
                $t(
                  "gateways.stripe_gateways_links_view_future_requirements",
                  "View future requirements"
                )
              }}
            </b-button>
            <b-collapse id="FutureRequirementFields">
              <b-card>
                <div>
                  <p v-for="(f, i) in getFutureRequirementFields(gl)" :key="i">
                    {{ $t(`gateways.stripe_requirement_field_${f}`, f) }}
                  </p>
                </div>
              </b-card>
            </b-collapse>
          </div>
          <div v-if="getRequirementFields(gl)" class="my-1">
            <b-button v-b-toggle.RequirementFields variant="link">
              {{ $t("gateways.stripe_gateways_links_view_requirements", "View requirements") }}
            </b-button>
            <b-collapse id="RequirementFields">
              <b-card>
                <div>
                  <p v-for="(f, i) in getRequirementFields(gl)" :key="i">
                    {{ $t(`gateways.stripe_requirement_field_${f}`, f) }}
                  </p>
                </div>
              </b-card>
            </b-collapse>
          </div>
        </div>
        <a v-if="!buttonVerified(gl)" class="bttn-bordered bttn-bordered--sm" :href="gl.link">
          {{ $t("gateways.stripe_gateways_linkbtn") }}
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls.js";
import { mapActions } from "vuex";
import DOMPurify from "dompurify";

export default {
  data() {
    return {
      gatewayLinks: [],
      stripeGatewaysPresent: false,
      loadingStripeOnBoarding: false,
    };
  },
  mounted() {
    this.loadingStripeOnBoarding = true;
    this.getGatewaysAndReturn()
      .then(async (gateways) => {
        const gatewayLinks = await this.loadGatewayLinks(gateways);
        return { gatewayLinks, gateways };
      })
      .then(({ gatewayLinks, gateways }) => {
        this.gatewayLinks = gatewayLinks;
        this.stripeGatewaysPresent =
          gateways.some((gateway) => gateway.attributes.gateway === "stripe") &&
          gatewayLinks.length > 0;
      })
      .finally(() => {
        this.loadingStripeOnBoarding = false;
      });
  },
  computed: {
    everyGatewayVerifiedAndNoErrors() {
      return this.gatewayLinks.every((v) => !v.required && !v.error);
    },
    someGatewayVerifiedAndNoErrors() {
      return this.gatewayLinks.some((v) => !v.required && !v.error);
    },
    partiallyVerified() {
      return this.gatewayLinks.filter((v) => !v.required && !v.error);
    },
    gatewayLinksFiltered() {
      return this.gatewayLinks.filter(
        (gl) => gl.required || gl.error || gl.futureRequirementsDedline || gl.pending
      );
    },
  },
  watch: {
    gateways() {
      this.loadGatewayLinks();
    },
  },
  methods: {
    ...mapActions({
      getGatewaysAndReturn: "getGatewaysAndReturn",
    }),
    async loadGatewayLinks(gateways) {
      let gatewayLinks = [];

      const promiseResults = await Promise.allSettled(
        gateways
          .filter((g) => g.attributes.active && g.attributes.gateway === "stripe")
          .map((g) => this.getStripeOnBoarding(g))
      );
      gatewayLinks = promiseResults.map((r) => {
        const { data, gatewayData, error } = r.value;
        const {
          required,
          pending,
          link,
          future_requirements_dedline,
          requirement_fields,
          future_requirement_fields,
          payouts_enabled,
          charges_enabled,
          future_requirements_errors,
          requirement_errors,
        } = data?.data?.attributes || {};

        return {
          futureRequirementsDedline: future_requirements_dedline,
          required,
          pending,
          link,
          gatewayData,
          error,
          requirementFields: requirement_fields,
          futureRequirementFields: future_requirement_fields,
          payoutsEnabled: payouts_enabled,
          chargesEnabled: charges_enabled,
          futureRequirementsErrors: future_requirements_errors || [],
          requirementErrors: requirement_errors || [],
        };
      });

      return gatewayLinks;
    },
    buttonVerified(gl) {
      return gl.chargesEnabled && gl.payoutsEnabled && !gl.pending && !gl.required && !gl.error;
    },
    getStripeOnBoarding(gateway) {
      const orgId = localStorage.getItem("orgId");
      return axios
        .get(urls.stripeOnBoarding.replace(":oid", orgId).replace(":idGateway", gateway.id))
        .then(({ data }) => ({ data, gatewayData: gateway.attributes.gateway_data }))
        .catch((e) => ({
          data: {},
          gatewayData: gateway.attributes.gateway_data,
          error: e?.response?.data?.error || e?.message || e,
        }));
    },
    noticeTitle(gl) {
      const stdVariables = {
        legalName: gl.gatewayData.legal_name,
        country: gl.gatewayData.country,
        accountNumber: gl.gatewayData.account_number,
      };

      if (gl?.error) {
        return this.$t(
          "gateways.stripe_gateways_links_title_v2_error",
          "We encountered some service error"
        );
      }

      if (gl.required) {
        return this.$t("gateways.stripe_gateways_links_title_v2", "Stripe verification required.");
      }

      if (gl.pending) {
        return this.$t(
          "gateways.stripe_gateways_links_title_v2_one_pending",
          "Your Stripe account ({accountNumber}) pending verification",
          stdVariables
        );
      }

      return this.$t(
        "gateways.stripe_gateways_links_title_v2_one_verified",
        "Your Stripe account ({accountNumber}) has been verified",
        stdVariables
      );
    },
    getRequirementFields(gl) {
      return gl.requirementFields;
    },
    getFutureRequirementFields(gl) {
      return gl.futureRequirementFields;
    },
    getPaymentStatus(gl) {
      return this.$t(
        "gateways.stripe_gateways_links_payment_status",
        "Current Status: Charges <strong class='notice__title'>{chargesSataus}</strong>, Payouts <strong class='notice__title'>{payoutsSataus}</strong>",
        {
          chargesSataus: gl.chargesEnabled
            ? this.$t("gateways.stripe_payment_status_on", "Enabled")
            : this.$t("gateways.stripe_payment_status_off", "Disabled"),
          payoutsSataus: gl.payoutsEnabled
            ? this.$t("gateways.stripe_payment_status_on", "Enabled")
            : this.$t("gateways.stripe_payment_status_off", "Disabled"),
        }
      );
    },
    noticeIconComponent(gl) {
      if (gl?.error) {
        return "b-icon-x";
      }

      if (!gl.required) {
        return "b-icon-check";
      }

      return "b-icon-exclamation-triangle";
    },
    getSanitizedNoticeTkey(gl) {
      const stdVariables = {
        legalName: gl.gatewayData.legal_name,
        country: gl.gatewayData.country,
        accountNumber: gl.gatewayData.account_number,
      };
      const domSanitize = (html) => {
        return DOMPurify.sanitize(html, { ALLOWED_TAGS: ["b", "strong"], ALLOWED_ATTR: ["class"] });
      };

      if (gl?.error) {
        return domSanitize(
          this.$t(
            "gateways.stripe_gateways_links_note_v2_error",
            "The Stripe account for <strong class='notice__title'>{legalName} ({country} {accountNumber})</strong> had error: {error}",
            {
              ...stdVariables,
              error: gl.error,
            }
          )
        );
      }

      const errors = [];

      if (gl?.futureRequirementsDedline > 0 && !gl.pending) {
        errors.push(
          domSanitize(
            this.$t(
              "gateways.stripe_gateways_links_note_v2_future_req",
              "The Stripe account for <strong class='notice__title'>{legalName} ({country} {accountNumber})</strong> has a future verification requirement due by {dueBy}. Update it now to avoid account interruptions",
              {
                ...stdVariables,
                dueBy: new Date(gl?.futureRequirementsDedline * 1000).toLocaleDateString(),
              }
            )
          )
        );
      }

      if (gl?.required) {
        errors.push(
          domSanitize(
            this.$t(
              "gateways.stripe_gateways_links_note_v2",
              "The Stripe account for <strong class='notice__title'>{legalName} ({country} {accountNumber})</strong> requires more information to be verified.",
              stdVariables
            )
          )
        );
      }

      if (gl.pending) {
        errors.push(
          domSanitize(
            this.$t(
              "gateways.stripe_gateways_links_pending_note_v2",
              "The Stripe account for <strong class='notice__title'>{legalName} ({country} {accountNumber})</strong> pending to be verified.",
              stdVariables
            )
          )
        );
      }

      return errors.join("<br/>");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
.stripe-gateways {
  margin-bottom: 40px;
  position: relative;
  overflow: hidden;
  // overflow: auto;
  // max-height: 200px;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #fef5f5;
  border: 1px solid #e4e8ec;
  border-radius: 8px;

  @media only screen and (min-width: 468px) {
    a {
      margin-left: auto;
    }
  }
}
.notice {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  @media only screen and (min-width: 468px) {
    flex-direction: row;
    align-items: center;
    gap: initial;
  }
  &--loading {
    background-color: #f9f0ce;
  }

  &--verified {
    background-color: #f5fef8;
  }
  &__warning {
    margin-bottom: 15px;
    width: 63px;
    min-width: 63px;
    height: auto;
    flex-shrink: 0;
    align-self: center;
    @media only screen and (min-width: 468px) {
      margin-right: 30px;
      margin-bottom: initial;
    }
  }
  &__content {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    justify-content: center;
  }
  ::v-deep &__title {
    margin-bottom: 5px;
    font-family: $vito-bold;
    font-size: 16px;
    color: $fd;
    line-height: 1.125;
  }
  &__text {
    margin-bottom: 0;
    font-family: $vito-light;
    font-size: 14px;
    line-height: 1.125;
    color: $fd;
  }
  .entity-modal {
    margin-left: auto;
  }

  ::v-deep .bttn-bordered {
    border-color: $lr-100;
    color: #ffffff;
    background-color: $lr-100;
    font-family: $vito-bold;
    &--sm {
      min-width: 180px;
    }
  }
}
</style>
