import store from "@/store/store";

export const FAVORITE_CAMPAIGN_KEY = "FAVORITE_CAMPAIGN";
export function getFavoriteCampaign() {
  return store.state.storage[FAVORITE_CAMPAIGN_KEY] || localStorage.getItem(FAVORITE_CAMPAIGN_KEY);
}
export function setFavoriteCampaign(value) {
  store.commit(`storage/SET_${FAVORITE_CAMPAIGN_KEY}`, value);
  return localStorage.setItem(FAVORITE_CAMPAIGN_KEY, value);
}
export function deleteFavoriteCampaign() {
  store.commit(`storage/SET_${FAVORITE_CAMPAIGN_KEY}`, null);
  return localStorage.removeItem(FAVORITE_CAMPAIGN_KEY);
}

export const CAMPAIGN_LIST_LAYOUT_KEY = "CAMPAIGN_LIST_LAYOUT";
export function getCampaignListLayout() {
  const value =
    store.state.storage[CAMPAIGN_LIST_LAYOUT_KEY] || localStorage.getItem(CAMPAIGN_LIST_LAYOUT_KEY);

  if (!value) {
    return setCampaignListLayout("grid");
  }

  return value;
}
export function setCampaignListLayout(value) {
  store.commit(`storage/SET_${CAMPAIGN_LIST_LAYOUT_KEY}`, value);
  localStorage.setItem(CAMPAIGN_LIST_LAYOUT_KEY, value);
  return value;
}
