<template>
  <div>
    <slot :cropperProps="cropperProps" />
  </div>
</template>

<script>
import { CircleStencil } from "vue-advanced-cropper";
export default {
  props: {},
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CircleStencil,
  },
  methods: {
    cropperProps(tag) {
      const props = {};
      const previewStyles = {};

      if (tag === "team_default_avatar") {
        props["stencil-component"] = this.$options.components.CircleStencil;
        previewStyles["--border-radius"] = "50%";
      }

      return { props, previewStyles };
    },
  },
};
</script>
