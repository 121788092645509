<template>
  <div class="w-100 mb-4">
    <div class="d-flex justify-content-between mb-2">
      <span>
        {{ field.title }}
      </span>
    </div>
    <b-form-select v-model="valueModel" :options="languageList" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},

  props: {
    form: {
      type: Object,
      default: () => null,
    },
    field: {
      type: Object,
      default: () => null,
    },
    fieldKey: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      localValue: [],
    };
  },

  watch: {
    value(val, old) {
      const same = old === val;
      if (!same) {
        this.localValue = val;
      }
    },
  },

  computed: {
    ...mapState({
      cID: (state) => state.campaign.campaign.data.id,
      languageList: (state) => {
        let langArr = state.account.languages;

        let resultArr = [];

        for (var i = 0; i < langArr.length; i++) {
          let obj = {
            text: langArr[i].attributes.name,
            value: langArr[i].attributes.code,
          };

          resultArr.push(obj);
        }

        return resultArr;
      },
    }),

    componentAttrs() {
      return this.field?.componentAttrs || {};
    },

    value() {
      return this.form[this.fieldKey];
    },

    valueModel: {
      get() {
        return this.localValue;
      },
      set(val) {
        this.localValue = val;
        this.$emit("input", val);
      },
    },
  },
};
</script>
