<template>
  <div>
    <slot />
    <campaignExpressAdd :id="id" @submit="handleSubmit" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { nanoid } from "nanoid";
import { OpenCharidyToGoModal, CloseCharidyToGoModal } from "@/constants/providers";
import campaignExpressAdd from "@/views/campaigns/campaign-wizards/v2/campaignExpressAdd.vue";
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],
  components: {
    campaignExpressAdd,
  },
  data() {
    return {
      id: nanoid(),
    };
  },
  provide() {
    return {
      [OpenCharidyToGoModal]: this.__open,
      [CloseCharidyToGoModal]: this.__hide,
    };
  },
  methods: {
    ...mapActions({
      fetchCreateCampaign: "fetchCreateCampaign",
      fetchSaveTemplate: "fetchSaveTemplate",
      fetchEditCampaign: "fetchEditCampaign",
      fetchCreateMediaFile: "fetchCreateMediaFile",
      fetchAddNewDescriptionLang: "fetchAddNewDescriptionLang",
      updateCampaignData: "updateCampaignData",
      fetchDeleteCampaign: "fetchDeleteCampaign",
    }),
    async handleSubmit({ data, resolve }) {
      const today = new Date();

      const tomorrow = new Date(today);
      const tomorrowNext = new Date(today);

      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrowNext.setDate(tomorrowNext.getDate() + 2);

      const remappedDesc = ({ cId }) =>
        data.descriptions.map(({ language, content, tag }) => ({
          attributes: {
            campaign_id: parseInt(cId),
            content,
            title: "",
            language,
            tag,
          },
        }));

      const { cId } = await this.fetchCreateCampaign({
        title: data.title,
        shortLink: data.short_link,
        primaryGoal: 0,
        currency: data.currency,
        startDate: tomorrow.getTime() / 1000,
        endDate: tomorrowNext.getTime() / 1000,
        gatewayIdList: data.gateway_id_list,
      });
      await this.updateCampaignData({ cId });

      return Promise.all([
        this.fetchSaveTemplate({
          cId,
          templateCode: "standard-v2",
        }),
        this.fetchEditCampaign({
          cId,
          data: {
            primary_goal_multiplier: 1,
          },
        }),
        this.updateCampaignData({ cId }),
        ({ cId }) => {
          if (data.image === "") {
            return { cId };
          }
          return this.fetchCreateMediaFile({
            cId,
            data: { src: data.image, tag: "slider", lang: "" },
          });
        },
        this.fetchAddNewDescriptionLang({
          cId,
          arrDesc: remappedDesc({ cId }),
        }),
        this.updateCampaignData({ cId }),
      ])
        .then(() => {
          this.toCampaign(cId);
        })
        .catch((e) => {
          this.fetchDeleteCampaign({ cId });
          this.$_notificationsMixin_handleCatch(e);
        })
        .finally(() => resolve());
    },

    toCampaign(cId) {
      this.$router.push({ path: `/campaign/${cId}` });
    },

    __open() {
      this.$bvModal.show(this.id);
    },
    __hide() {
      this.$bvModal.hide(this.id);
    },
  },
};
</script>
