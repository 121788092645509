<template>
  <b-modal id="importManual" size="lg" @hidden="clearData">
    <!-- @hidden="clearData" -->

    <div slot="modal-header">
      <h2 class="h1-like">{{ $t("dashboard.donation_manual_import") }}</h2>

      <div class="modal-header__hide" @click.prevent="hideModal">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
      </div>
    </div>

    <div slot="modal-footer">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
        {{ $t("dashboard.donation_manual_close") }}
      </button>
      <button form="import-manual" class="bttn bttn--lg bttn--orange" :disabled="sending">
        {{ $t("dashboard.donation_manual_save") }}
      </button>
    </div>

    <form id="import-manual" @submit.prevent="sendDonationList" class="import-manual">
      <error v-if="errors" :errors="errors"></error>

      <div class="import-manual__row payment-referer">
        <div class="form-group form-group--optional">
          <label class="import-manual__label">
            {{ $t("dashboard.payment_referer_for_all") }}
          </label>
          <b-form-input
            class="import-manual__input"
            :readonly="sending"
            v-model="paymentrReferer"
          ></b-form-input>
        </div>
      </div>

      <import-row
        v-for="item in donorsFields"
        :key="item.id"
        :item="item"
        :issaving="sending || item.disabledAllFields"
        @add-row="addOneDonation"
        @remove-row="deleteFields"
      ></import-row>

      <error v-if="errors" :errors="errors"></error>

      <span class="import-manual__action">
        <button :disabled="sending" class="import-manual__add-row" @click.prevent="addField">
          {{ $t("dashboard.add_donate_add_row") }}
        </button>
      </span>
    </form>
  </b-modal>
</template>

<script>
import importManualRow from "./importManualRow.vue";

import { mapState } from "vuex";

import axios from "axios";

import urls from "@/constants/urls.js";

import error from "@/components/errors.vue";

export default {
  props: ["campaignid"],

  data() {
    return {
      donorsFields: [],
      donorsCount: 0,
      errors: [],
      sending: false,
      sendIndex: 0,
      paymentrReferer: "",
      donorsFieldsForSend: [],
    };
  },

  components: {
    "import-row": importManualRow,
    error: error,
  },

  computed: mapState({
    currency: (state) => {
      return state.dashboard.campaignDashboard.data.attributes.currency_code;
    },

    orgId: function () {
      return localStorage.getItem("orgId");
    },

    selectedCampaignId: (state) => {
      return state.dashboard.selectedCampaign;
    },
  }),

  watch: {
    paymentrReferer: function () {
      this.setPaymentReferer();
    },
  },

  methods: {
    setPaymentReferer: function () {
      for (var i = 0; i < this.donorsFields.length; i++) {
        this.donorsFields[i].data.referrer = this.paymentrReferer;
      }
    },

    openModal() {
      this.$store.dispatch("getTeamsByCampaignId", [this.selectedCampaignId]);

      this.createDonorsField();

      this.$root.$emit("bv::show::modal", "importManual");
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "importManual");
    },

    clearData() {
      this.donorsFields = [];

      this.donorsCount = 0;

      this.sendIndex = 0;

      this.paymentrReferer = "";
    },

    createDonorsField: function () {
      for (let i = 0; this.donorsFields.length < 5; i++) {
        let fieldObj = {
          id: this.donorsCount,
          disabledAllFields: false,
          success: false,
          validation: false,
          data: {
            campaign_id: 0,
            display_name: "",
            billing_name: "",
            billing_last_name: "",
            currency_code: "",
            phone: "",
            email: "",
            payment_country: "",
            payment_city: "",
            payment_address: "",
            payment_postcode: "",
            payment_state: "",
            referrer: "",
            dedication: "",
            category: "",
            charged_amount: "",
            team_id: 0,
            offline_donation_source: "cash",
            offline_donation_note: "",
          },
        };

        this.donorsFields.push(fieldObj);

        this.donorsCount++;
      }
    },

    addField: function () {
      let fieldObj = {
        id: this.donorsCount,
        disabledAllFields: false,
        success: false,
        validation: false,
        data: {
          campaign_id: 0,
          display_name: "",
          billing_name: "",
          billing_last_name: "",
          currency_code: "",
          phone: "",
          email: "",
          payment_country: "",
          payment_city: "",
          payment_address: "",
          payment_postcode: "",
          payment_state: "",
          referrer: this.paymentrReferer,
          dedication: "",
          category: "",
          charged_amount: "",
          team_id: 0,
          offline_donation_source: "",
        },
      };

      this.donorsFields.push(fieldObj);

      this.donorsCount++;
    },

    deleteFields: function (id) {
      for (var i = 0; i < this.donorsFields.length; i++) {
        if (this.donorsFields[i].id == id) {
          this.donorsFields.splice(i, 1);
        }
      }
    },

    addOneDonation: function (id) {
      for (let i = 0; i < this.donorsFields.length; i++) {
        if (this.donorsFields[i].id == id) {
          if (!this.donorsFields[i].success) {
            this.donorsFields[i].validation = true;

            if (
              this.donorsFields[i].data.display_name.length > 0 &&
              this.donorsFields[i].data.charged_amount > 0 &&
              this.donorsFields[i].data.email.length > 0
            ) {
              this.donorsFields[i].disabledAllFields = true;

              this.donorsFields[i].data.campaign_id = parseInt(this.campaignid);

              this.donorsFields[i].data.currency_code = this.currency;

              let objForSend = {
                data: {
                  attributes: this.donorsFields[i].data,
                },
              };

              axios
                .post(
                  urls.donationNewApi
                    .replace(":orgId", this.orgId)
                    .replace(":campaignId", this.campaignid),
                  objForSend
                )
                .then((response) => {
                  this.donorsFields[i].success = true;

                  let params = {
                    id: this.campaignid,
                    setting: `?page=1&limit=50`,
                  };

                  this.donorsFields[i].disabledAllFields = false;

                  this.$store.dispatch("getCampaignDonation", params);
                })
                .catch((e) => {
                  this.donorsFields[i].disabledAllFields = false;

                  this.sending = false;
                  if (e.response.data) {
                    if (e.response.data.error) {
                      this.errors.push(e.response.data.error);
                    } else {
                      this.errors.push(this.$t("template.error_default"));
                    }
                  } else {
                    this.errors.push(this.$t("template.error_default"));
                  }
                });
            }
          }
        }
      }
    },

    sendDonationList: function () {
      this.sendIndex = 0;

      this.validationListDonations();

      this.errors = [];
    },

    validationListDonations: function () {
      let addDonations = this.donorsFields;

      let notEmptyRows = [];

      let emptyFields = false;

      for (let i = 0; i < addDonations.length; i++) {
        if (
          addDonations[i].data.display_name.length > 0 ||
          addDonations[i].data.charged_amount > 0 ||
          addDonations[i].data.email.length > 0
        ) {
          addDonations[i].validation = true;

          notEmptyRows.push(addDonations[i]);

          if (
            addDonations[i].data.display_name.length == 0 ||
            addDonations[i].data.charged_amount == 0 ||
            addDonations[i].data.email.length == 0
          ) {
            emptyFields = true;
          }
        } else {
          addDonations[i].validation = false;
        }
      }

      if (!emptyFields && notEmptyRows.length > 0) {
        this.donorsFieldsForSend = notEmptyRows;
        this.addDonationsList();
      }
    },

    addDonationsList: function () {
      this.sending = true;

      if (this.sendIndex < this.donorsFieldsForSend.length) {
        this.donorsFieldsForSend[this.sendIndex].disabledAllFields = true;

        this.donorsFieldsForSend[this.sendIndex].data.campaign_id = parseInt(this.campaignid);

        this.donorsFieldsForSend[this.sendIndex].data.currency_code = this.currency;

        let objForSend = {
          data: {
            attributes: this.donorsFieldsForSend[this.sendIndex].data,
          },
        };

        if (!this.donorsFieldsForSend[this.sendIndex].success) {
          axios
            .post(
              urls.donationNewApi
                .replace(":orgId", this.orgId)
                .replace(":campaignId", this.campaignid),
              objForSend
            )
            .then((response) => {
              this.donorsFieldsForSend[this.sendIndex].success = true;

              if (this.sendIndex == this.donorsFieldsForSend.length - 1) {
                let params = {
                  id: this.campaignid,
                  setting: `?page=1&limit=50`,
                };

                this.sending = false;

                this.$_notificationsMixin_makeToastCompat({
                  group: "Notification",
                  title: this.$t("dashboard.add_success_add"),
                });

                this.$store.dispatch("getCampaignDonation", params);

                if (this.errors.length == 0) {
                  this.hideModal();
                }
              } else {
                this.sendIndex++;

                this.addDonationsList();
              }
            })
            .catch((e) => {
              if (e.response.data) {
                if (e.response.data.error) {
                  this.errors.push(e.response.data.error);
                } else {
                  this.errors.push(this.$t("template.error_default"));
                }
              } else {
                this.errors.push(this.$t("template.error_default"));
              }

              if (this.donorsFieldsForSend[this.sendIndex]) {
                this.donorsFieldsForSend[this.sendIndex].disabledAllFields = false;
              }

              if (this.sendIndex == this.donorsFieldsForSend.length - 1) {
                let params = {
                  id: this.campaignid,
                  setting: `?page=1&limit=50`,
                };

                this.sending = false;

                this.$store.dispatch("getCampaignDonation", params);
              } else {
                this.sendIndex++;

                this.addDonationsList();
              }
            });
        } else {
          this.sendIndex++;
          this.addDonationsList();
        }
      } else {
        let params = {
          id: this.campaignid,
          setting: `?page=1&limit=50`,
        };

        this.sending = false;

        this.$store.dispatch("getCampaignDonation", params);

        if (this.errors.length == 0) {
          this.$_notificationsMixin_makeToastCompat({
            group: "Notification",
            title: this.$t("dashboard.add_success_add"),
          });

          this.hideModal();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group--optional {
  .import-manual__input {
    width: 30%;
  }
}

.payment-referer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  .form-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.alert-danger {
  margin: 10px 0;
  padding: 0 20px;
}
</style>
