<template>
  <div class="sign-wrapper">
    <div class="container-fluid">
      <div class="sign">
        <div class="sign__image">
          <a href="https://charidy.com" class="sign__logo">
            <CdnLogoByLang class="logo-main" />
          </a>

          <a
            href="https://charidy.com/all-campaigns"
            class="bttn bttn--orange bttn--sm live-campaigns"
          >
            Live campaigns
          </a>
        </div>

        <div class="sign__mode">
          <p class="sign__helper">{{ $t("template.dont_have_account") }}</p>
          <a href="/register" class="bttn bttn--sm bttn--orange">{{
            $t("template.get_started")
          }}</a>
        </div>

        <div class="sign__deco">
          <h1 class="sign-content__title">{{ $t("template.org_admin_dashboard") }}</h1>

          <div class="sign-content__content">
            <div class="sign-content__content-block">
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="#50cdaa"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
                {{ $t("template.sign_int_tip_1") }}
              </p>
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="#50cdaa"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
                {{ $t("template.sign_int_tip_2") }}
              </p>
            </div>
            <div class="sign-content__content-block">
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="#50cdaa"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
                {{ $t("template.sign_int_tip_3") }}
              </p>
              <p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="#50cdaa"
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                  ></path>
                </svg>
                {{ $t("template.sign_int_tip_4") }}
              </p>
            </div>
          </div>

          <SignInSvgDeco />
        </div>

        <div class="sign-content">
          <form @submit.prevent="proceedLogin" class="sign-form">
            <h2 class="sign-form__title">{{ $t("template.sign_in_to_charidy") }}</h2>
            <p class="sign-form__description">{{ $t("template.enter_your_detalis") }}</p>

            <login-form ref="loginmode" @update-step="updateMode">
              <login-form-org
                slot="item"
                slot-scope="props"
                :login="props.login"
                :steps="props.steps"
                :prevstep="props.prevstep"
                :errors="props.errors"
                :successresetpassword="props.successResetPassword"
                @steps-set="props.stepsset"
                @step-to="props.stepto"
                :proceedresetpassword="props.proceedResetPassword"
                @login-user="props.loginuser"
                :loginLocalErrors="props.loginLocalErrors"
              >
              </login-form-org>
            </login-form>

            <div class="sign-form__action">
              <button
                v-if="mode === 'login'"
                class="bttn bttn--lg bttn--orange bttn--sign"
                type="submit"
                :disabled="!loading"
              >
                {{ $t("template.sign_in") }}
              </button>

              <button
                v-if="mode === 'resetPassword' || mode === 'resetPasswordPhone'"
                class="bttn bttn--lg bttn--blue el-left"
                @click.prevent="stepBack('login')"
              >
                {{ $t("template.back") }}
              </button>
              <button
                v-if="mode === 'resetPassword' || mode === 'resetPasswordPhone'"
                class="bttn bttn--lg bttn--orange bttn--sign el-right"
                @click.prevent="resetPassword"
              >
                {{ $t("template.resset_my_password") }}
              </button>

              <button
                v-if="mode === 'backToLogin'"
                class="bttn bttn--lg bttn--blue"
                @click.prevent="stepBack('login')"
              >
                {{ $t("template.back_to_login") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <show-settings-confirm-msg />
  </div>
</template>

<script>
import CdnLogoByLang from "@/common-components/cdn-v2/cdn-logo-by-lang.vue";
import loginForm from "../components/loginForm.vue";
import loginFormOrg from "../components/loginFormOrg.vue";
import showSettingsConfirmMsg from "./donor-dashboard/modals/show-settings-confirm-msg.vue";
import SignInSvgDeco from "@/svg-components/SignInSvgDeco.vue";
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      mode: "login",
      loading: true,
    };
  },
  components: {
    loginForm,
    loginFormOrg,
    showSettingsConfirmMsg,
    SignInSvgDeco,
    CdnLogoByLang,
  },
  methods: {
    proceedLogin() {
      return this.$refs.loginmode.loginUser({ noRouting: true }).then((data) => {
        this.$root.$emit("charidy::legal-agreement-document::open", data);
      });
    },

    updateMode(n) {
      this.mode = n;
      this.$refs.loginmode.stepTo(n);
    },

    stepBack(n) {
      this.mode = n;
      this.$refs.loginmode.stepTo(n);
    },

    resetPassword() {
      this.$refs.loginmode.proceedResetPassword();
    },
  },
});
</script>

<style lang="scss" scoped>
.container-fluid {
  max-width: 1500px;
}
.el-left {
  margin-right: auto;
}
.el-right {
  margin-left: auto;
}
.live-campaigns {
  margin-left: 10px;
}
</style>
