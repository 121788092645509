<template>
  <div>
    <b-alert v-for="(error, i) in errors" :key="`error-${i}`" variant="danger" show>
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="m-0">
          {{ error.title }}
        </h5>

        <div>
          <small>
            {{ error.status }}
          </small>

          <button type="button" aria-label="Close" class="ml-4 close" @click="errors.splice(i, 1)">
            ×
          </button>
        </div>
      </div>

      <hr class="my-1" />

      <div class="py-1">
        {{ getErrorMessage(error) }}
      </div>
    </b-alert>
  </div>
</template>

<script>
export default {
  props: {
    response: {
      typpe: Object,
      default: () => null,
    },
  },

  data() {
    return {
      errors: [],
    };
  },

  methods: {
    getErrorMessage({ detail }) {
      if (Array.isArray(detail) && detail.length > 1) {
        return this.$t(...detail);
      }

      return detail;
    },

    handleCatch({ response }) {
      if (response?.data) {
        if (response.data.error) {
          const data = response.data;

          this.errors.push({
            title: "Server Error",
            status: response.status,
            detail: [data.error_translation_key, data.error],
          });

          return;
        }

        if (response.data.errors) {
          this.errors = response.data.errors;

          return;
        }
      }

      this.errors.push({
        title: "Server Error",
        status: response.status,
        detail: JSON.stringify(response.data),
      });
    },

    clearErrors() {
      this.errors = [];
    },
  },

  watch: {
    response() {
      if (this.response) {
        this.handleCatch(this.response);
      } else {
        this.errors = [];
      }
    },
  },
};
</script>

<style scoped>
ul {
  margin: 0;
}
.alert-danger li {
  margin: 0;
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 5px;
}

.error-item {
  color: red;
}
</style>
