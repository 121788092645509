<template lang="html">
  <b-modal id="exportTeams" size="lg" @hidden="clearData">
    <div slot="modal-header">
      <h2>{{ $t("dashboard.export_modal_teams_title") }}</h2>
      <div class="modal-header__hide" @click.prevent="hideModal">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
      </div>
    </div>

    <div v-if="loading">
      <p>{{ $t("dashboard.loading_tems_export") }}</p>
    </div>

    <div v-else-if="!loading && !error">
      <p>{{ $t("dashboard.team_export_text") }}</p>
    </div>

    <div slot="modal-footer">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
        {{ $t("dashboard.hide_modal") }}
      </button>
      <button
        class="bttn bttn--lg bttn--orange"
        :disabled="loading || error"
        @click="exportTeams"
        type="button"
        name="button"
      >
        {{ $t("dashboard.export_teams") }}
      </button>
    </div>

    <error-message :response.sync="error"></error-message>
  </b-modal>
</template>

<script>
import axios from "axios";

import urls from "@/constants/urls.js";

import errorMessage from "@/components/error-message.vue";

export default {
  data: function () {
    return {
      loading: true,
      error: null,
      exportLink: null,
    };
  },

  components: {
    errorMessage,
  },

  computed: {
    orgId: function () {
      return localStorage.getItem("orgId");
    },
  },

  methods: {
    clearData: function () {
      this.loading = true;
      this.error = null;
      this.exportLink = null;
    },

    openModal: function (id) {
      this.loadingExportFileTeams(id);
      this.$root.$emit("bv::show::modal", "exportTeams");
    },

    hideModal: function () {
      this.$root.$emit("bv::hide::modal", "exportTeams");
    },

    exportTeams: function () {
      const teamsExportLink = document.createElement("a");
      teamsExportLink.href = `${this.exportLink}`;
      teamsExportLink.download = `teams.csv`;
      document.body.appendChild(teamsExportLink);
      teamsExportLink.click();
      teamsExportLink.remove();
      this.hideModal();
    },

    loadingExportFileTeams: function (id) {
      this.loading = true;
      this.error = null;

      axios
        .post(urls.exportTeams.replace(":orgId", this.orgId).replace(":campaignId", id))
        .then((response) => {
          this.exportLink = response.data.link;
          this.loading = false;
        })
        .catch((e) => {
          this.error = e.response;
        });
    },
  },
};
</script>

<style lang="css"></style>
