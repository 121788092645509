<template>
  <b-card class="account-card">
    <h2 slot="header" class="account-card__title">
      {{ $t("account.org_notify_emails", "Notification email list") }}
      <tooltip :title="$t('dashboard.notification_email_list_tooltip', 'Notification emails will go to the Account Owner\'s email address by default. If you want us to send notification emails to an additional email address(es) please add below.')" class="mx-1" />
    </h2>
    <div class="email-list">
      <div v-for="(email, i) in emails" :key="i" class="email-list-item">
        <div class="email-list-item-text">
          {{ email }}
        </div>

        <div class="email-list-item-actions">
          <b-icon-x @click="removeEmail(i)" variant="danger" font-scale="1.5" role="button" />
        </div>
      </div>

      <div class="email-list-add-form">
        <b-form-input v-model="email" placeholder="Enter email" class="mr-2" type="email" />

        <button class="bttn-bordered bttn-bordered--sm" @click="addEmail">
          {{ $t("account.org_notify_emails_add", "Add") }}
        </button>
      </div>

      <div class="email-list-actions">
        <button class="bttn bttn--sm bttn--orange" @click="save">
          {{ $t("account.save_change") }}
        </button>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { notificationsMixin } from "@/mixins";
import { cloneDeep } from "@/helpers/cloneDeep";
import Tooltip from "@/common-components/tooltip.vue";

export default {
  mixins: [notificationsMixin],

  components: {
    Tooltip,
  },

  data() {
    return {
      email: "",
      emails: [],
    };
  },

  computed: {
    ...mapState({
      extraEmails: (s) => s.account.account.data.attributes.extra_emails,
    }),
  },

  watch: {
    extraEmails: {
      handler(val) {
        this.emails = cloneDeep(val);
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      saveExtraEmails: "saveExtraEmails",
    }),

    addEmail() {
      const email = this.email.trim();

      if (email) {
        this.emails.push(email);
        this.email = "";
      }
    },

    removeEmail(i) {
      this.emails.splice(i, 1);
    },

    save() {
      const payload = {
        extra_emails_list: this.emails,
      };

      this.saveExtraEmails({ payload })
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch("updateAccountOrg");
          }, 300);

          this.$_notificationsMixin_makeToast(
            "Success!",
            this.$t("account.org_notify_emails_save", "Saved successfully"),
            "success"
          );
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
  },
};
</script>

<style lang="scss" scoped>
.email-list {
  &-item {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
    align-items: center;
  }
  &-add-form {
    display: flex;
    margin-bottom: 15px;
  }
  &-actions {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
