import urls from "../../constants/urls.js";
import axios from "axios";

const getUrl = (cId, id = "") => {
  const orgId = localStorage.getItem("orgId");
  const posturl = String(id) && `/${id}`;

  return urls.utm.replace(":oid", orgId).replace(":cid", cId) + posturl;
};

const getData = (attr, cId, id) => {
  const ido = id || id === 0 ? { id: String(id) } : {};
  return {
    data: {
      ...ido,
      type: "utm",
      attributes: {
        campaign_id: Number(cId),
        ...attr,
      },
    },
  };
};

const state = {
  list: [],
  count: 0,
  loading: false,
  error: null,
};

const mutations = {
  setList(state, data) {
    state.list = data;
  },

  setCount(state, val) {
    state.count = val || 0;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  setError(state, val) {
    state.error = val;
  },
};

const actions = {
  fetchUTMs({ commit }, [cId, getParams] = []) {
    commit("setLoading", true);

    const url = getUrl(cId);
    const params = {
      ...getParams,
    };

    axios
      .get(url, {
        params,
      })
      .then(({ data, headers }) => {
        commit("setCount", headers["x-total-records"]);
        commit("setList", data.data);
        commit("setLoading", false);
      })
      .catch((e) => {
        commit("setError", e.response);
        commit("setLoading", false);
      });
  },

  fetchUTM(_, [cId, id, getParams] = []) {
    const url = getUrl(cId, id);
    const params = {
      ...getParams,
    };

    return axios.get(url, { params });
  },

  fetchCreateUTM(_, [cId, attr, getParams] = []) {
    const url = getUrl(cId);
    const params = {
      ...getParams,
    };
    const data = getData(attr, cId);

    return axios.post(url, data, { params });
  },

  fetchUpdateUTM(_, [cId, id, attr, getParams] = []) {
    const url = getUrl(cId, id);
    const params = {
      ...getParams,
    };
    const data = getData(attr, cId, id);

    return axios.put(url, data, { params });
  },

  fetchDeleteUTM(_, [cId, id] = []) {
    const url = getUrl(cId, id);

    return axios.delete(url);
  },

  fetchExportUTM(_, [cId] = []) {
    const url = getUrl(cId, "export");

    // export all utm links, not using pagination
    return axios.get(url, { params: { page: 0, per_page: 0 } });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
