<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate, invalid, errors: observerErrors }"
    tag="div"
  >
    <b-modal :id="id" size="xl" centered @hidden="hidden">
      <div slot="modal-header">
        <div class="modal-header__hide" @click="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
        <h2 class="h1-like">{{ $t("add_campaign.add_new_campaign") }}</h2>
        <div class="modal-header__description">
          {{ $t("add_campaign.change_anytime") }}
        </div>
      </div>
      <div slot="modal-footer" class="d-flex flex-column">
        <b-alert
          v-if="!gateways.length"
          show
          variant="warning"
          class="w-100 d-flex flex-column align-items-center"
        >
          {{ $t("add_campaign.no_gateway_alert", "To create campaign please add gateway") }}
          <router-link to="/gateways?i=1">
            {{ $t("add_campaign.add_gateway", "Add gateway") }}
          </router-link>
        </b-alert>
        <div class="w-100 d-flex justify-content-between">
          <a href="#" class="bttn bttn--lg bttn--blue" @click="hideModal">{{
            $t("add_campaign.close")
          }}</a>
          <button
            form="campaign-add-express"
            :disabled="invalid || sending || !gateways.length"
            class="bttn bttn--lg bttn--orange"
            type="submit"
          >
            {{ $t("add_campaign.create_new_campaign") }}
          </button>
        </div>
      </div>
      <form id="campaign-add-express" @submit.prevent="validate().then(handleSubmit)">
        <error
          ref="errorsRef"
          v-if="$options.filterErrors(errors, observerErrors)"
          :errors="$options.filterErrors(errors, observerErrors)"
        />
        <div class="d-flex align-items-center justify-content-center">
          <image-upload v-model="newCampaign.image" type="campaign" label="Upload campaign image" />
        </div>
        <div class="row align-items-end">
          <div class="col-12 col-lg-6">
            <ValidationProvider rules="no_whitespace" v-slot="{}">
              <div class="form-group form-unit">
                <label class="form-unit__label">
                  {{ $t("add_campaign.express_title") }}
                </label>
                <input
                  type="text"
                  class="form-control form-unit__input"
                  v-model="newCampaign.title"
                  required
                />
              </div>
            </ValidationProvider>
          </div>
          <div class="col-12 col-lg-6">
            <ValidationProvider rules="short_link" v-slot="{ valid }">
              <ShortlinkInput
                :valid="valid"
                :config="{ type: 'org', orgId }"
                v-model="newCampaign.short_link"
                @validation-result="onShortlinkValidationResult"
              />
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="form-group form-unit form-unit--addon goal-input">
              <label class="form-unit__label">
                {{ $t("add_campaign.currency") }}
              </label>
              <b-form-select v-model="newCampaign.currency" :options="currencies"></b-form-select>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div
              v-if="!loadingGateways && gateways.length"
              class="form-group form-unit form-unit--multiselect"
            >
              <label class="form-unit__label">
                {{ $t("add_campaign.gateway") }}
              </label>
              <div class="d-flex align-items-center">
                <multiselect
                  v-model="selectedGateways"
                  track-by="id"
                  :custom-label="customLabel"
                  :options="gateways"
                  :multiple="true"
                ></multiselect>
              </div>
            </div>
            <div v-else class="col-12 h-100 d-flex align-items-center justify-content-center">
              <b-spinner v-if="loadingGateways" small class="p-2"></b-spinner>
              <b-alert
                v-else
                show
                variant="warning"
                class="w-100 d-flex flex-column align-items-center"
              >
                {{ $t("add_campaign.not_gateways", "No active gateways") }}
                <router-link to="/gateways?i=1">
                  {{ $t("add_campaign.add_gateway", "Add gateway") }}
                </router-link>
              </b-alert>
            </div>
          </div>
        </div>
        <div class="row align-items-end">
          <div class="col-12">
            <label class="form-unit__label">
              {{ $t("edit_campaign.description_title") }}
            </label>
            <campign-description-form v-model="newCampaign.descriptions" />
          </div>
        </div>
      </form>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import Multiselect from "vue-multiselect";
import error from "@/components/errors.vue";
import campignDescriptionForm from "@/components/campign-description-form.vue";
import imageUpload from "@/components/image-upload.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import ShortlinkInput from "@/views/campaigns/campaign-edit-vue/subcomponents/shortlink-input.vue";
import { filterErrors } from "@/helpers/filters";

export default {
  filterErrors,

  props: {
    id: { type: String, required: true },
  },

  data() {
    return {
      newCampaign: {
        image: "",
        title: "",
        currency: "usd",
        short_link: "",
        descriptions: [],
      },
      selectedGateways: [],
      errors: [],
      sending: false,
    };
  },

  components: {
    error: error,
    campignDescriptionForm,
    Multiselect,
    imageUpload,
    ValidationObserver,
    ValidationProvider,
    ShortlinkInput,
  },

  computed: {
    ...mapState({
      orgId: () => localStorage.getItem("orgId"),
      loadingGateways: (state) => state.gateways.loadingGateways,
      currencies: (state) =>
        state.account.currencyList.map((el) => ({
          value: el.attributes.code.toLowerCase(),
          text: `${el.attributes.title}(${el.attributes.code})`,
        })),
      gateways: (state) => state.gateways.gateways.filter((v) => v.attributes.active),
      locale: (state) => state.i18n.locale,
    }),
  },

  methods: {
    customLabel({ attributes }) {
      return `${attributes.gateway}`;
    },

    hidden() {
      this.newCampaign = {
        image: "",
        title: "",
        short_link: "",
        descriptions: [],
      };
    },

    getMediaUrl() {
      const { orgId } = this;
      const mediaUrl = this.newCampaign.image;

      return new Promise(function (resolve) {
        if (mediaUrl instanceof File) {
          const formData = new FormData();

          formData.append("file", mediaUrl);

          axios.post(urls.loadImageQuill.replace(":OrgId", orgId), formData).then((response) => {
            resolve(response.data.src);
          });
        } else {
          resolve(mediaUrl);
        }
      });
    },

    onShortlinkValidationResult(e) {
      const id = "ShortlinkValidation";
      const foundError = this.errors.find((e) => e?.id === id);

      if (!e && !foundError) {
        this.errors = [
          ...this.errors,
          {
            error: this.$t("edit_campaign.generalinfo_short_link_allowe"),
            id,
          },
        ];
        return;
      }

      if (foundError) {
        this.errors = this.errors.filter((e) => e?.id !== id);
      }
    },

    async handleSubmit() {
      if (this.errors.length > 0) {
        this.$refs.errorsRef.$el.scrollIntoView({
          behavior: "smooth",
        });
        return;
      }

      this.sending = true;
      const data = JSON.parse(JSON.stringify(this.newCampaign));

      data.image = await this.getMediaUrl();
      data.descriptions = data.descriptions.map((el) => ({
        language: el.short,
        content: el.content,
        tag: el.tag,
      }));
      data.gateway_id_list = this.selectedGateways.map((el) => Number(el.id));

      const resolve = () => {
        this.sending = false;
      };

      this.$emit("submit", {
        data,
        resolve: resolve.bind(this),
      });
    },

    hideModal() {
      this.$bvModal.hide(this.id);
    },
  },
};
</script>

<style lang="scss" scoped></style>
