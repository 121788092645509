<template>
  <div>
    <b-col cols="12" v-if="recipient">
      <b-form-group>
        <template #label>
          <label class="form-group__label">
            <b>{{ $t("dashboard.email_message_preview_recipient", "Recipient:") }}</b>
            <u class="mx-2">{{ recipient }}</u>
          </label>
        </template>
      </b-form-group>
    </b-col>

    <b-col cols="12" v-if="subject">
      <b-form-group>
        <template #label>
          <label class="form-group__label">
            <b>{{ $t("dashboard.email_message_preview_subject", "Subject:") }}</b>
            {{ subject }}
          </label>
        </template>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-form-group>
        <template #label>
          <label class="form-group__label">
            <b>{{ $t("dashboard.email_message_step2_message", "Message") }}</b>
          </label>
        </template>
        <quill-editor-variables :value="message" :disabled="true" />
      </b-form-group>
    </b-col>
  </div>
</template>

<script>
import quillEditorVariables from "@/common-components/ui-elements/quill-editor-variables.vue";
export default {
  components: { quillEditorVariables },
  props: {
    recipient: {
      type: String,
      default: "",
    },
    subject: {
      type: String,
      default: "",
    },
    message: {
      type: [String, Object],
      default: "",
    },
  },
};
</script>

<style></style>
