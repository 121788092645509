<template>
  <b-modal
    id="add-payment-method"
    body-class="add-payment-method-popup"
    ref="paymentMethod"
    title
    centered
    hide-footer
    @shown="shownModal"
    @hidden="resetPaymentMethodData"
  >
    <h3 class="none-margin">Add credit or debit card</h3>
    <div class>
      <p class="stripeError" v-if="stripeError">{{ stripeError }}</p>
      <p class="stripeError" v-else></p>
    </div>
    <div class="form-group">
      <label class="form-group__label form-group__label--required"
        >Card Number, Expiry, Card CVC and ZIP Code</label
      >
    </div>
    <div class="form-group">
      <div class="credit-card" ref="card"></div>
    </div>
    <div class="btn-wrap">
      <button class="mx-2 add bttn bttn--lg bttn--green" @click="addPaymentMethod">Add</button>
      <button class="mx-2 add bttn bttn--lg bttn--green" @click="resetPaymentMethodData">
        Reset
      </button>
    </div>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";

export default {
  name: "add-payment-method",

  data() {
    return {
      stripe: undefined,
      card: undefined,
      stripeError: "",
    };
  },

  computed: mapState({
    publicKeyLive: (state) => state.stripe.publicKeyLive,
  }),

  watch: {
    publicKeyLive(value) {
      this.stripe = Stripe(value);
    },
  },

  mounted() {
    this.getPublicKeyLive();
  },

  methods: {
    ...mapActions({
      getPublicKeyLive: "getPublicKeyLive",
    }),

    openModal() {
      this.$root.$emit("bv::show::modal", "add-payment-method");
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "add-payment-method");
    },
    shownModal() {
      const elements = this.stripe.elements();
      this.card = elements.create("card");
      this.card.mount(this.$refs.card);
    },
    addPaymentMethod() {
      this.stripe.createToken(this.card).then((result) => {
        if (result.error) {
          this.stripeError = result.error.message;
        } else {
          this.saveTokenId(result.token.id);
        }
      });
    },
    resetPaymentMethodData() {
      this.card.clear();
      this.stripeError = false;
    },
    saveTokenId(tokenId) {
      const body = {
        data: {
          attributes: {
            token: tokenId,
          },
        },
      };

      axios
        .post(urls.donorCardsStripe.replace("/:cardId", ""), body)
        .then(() => {
          this.$store.dispatch("getCards");

          this.hideModal();
        })
        .catch((e) => {
          console.log(e);
          this.stripeError = e.response.data.error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-payment-method-popup {
  background: #1c273999;
  color: #414359;
  .close {
    font-size: 2.5rem;
    line-height: 0.5;
    color: #107598;
    opacity: 1;
    width: 36px;
    height: 36px;
    padding: 5px;
    border-radius: 18px;
    margin-top: -5px;
    outline: none;
    &:hover {
      background: #10759824;
    }
  }
  .stripeError {
    height: 24px;
    color: red;
    text-align: center;
  }
  .modal-dialog {
    max-width: 800px;
  }
  .modal-body {
    h3 {
      font: 30px/1.4 "VitoExtended-Bold";
      margin-bottom: 30px;
      text-align: center;
    }
    .none-margin {
      margin-bottom: 0;
    }
  }
  .modal-content {
    background: url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco1.svg")
        top -40px left 5% no-repeat,
      url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco2.svg")
        top -50px right 20% no-repeat,
      #fff;
    .modal-header {
      background: none;
    }
    .modal-body {
      background: none;
    }
  }
  .input-wrap {
    flex-grow: 2;
  }
  .bttn--green {
    background: #00d39a;
    width: 180px;
    color: #fff;
  }
  .form-group {
    text-align: center;
    margin-bottom: 40px;
    .credit-card {
      width: 390px;
      margin: 5% auto;
    }
    label {
      display: block;
      color: #414359;
      font-size: 20px;
    }
    img {
      vertical-align: inherit;
    }
  }
  .btn-wrap {
    text-align: center;
  }
  .custom-dropdown {
    .dropdown-toggle {
      background: #f7fbff;
      min-width: 100px;
      height: 44px;
      border-radius: 19px;
      border: 1px solid #e6eaed;
      color: #107598;
      &:not(:disabled):hover,
      &:not(:disabled):focus,
      &:not(:disabled):active {
        color: #107598;
        background: #f7fbff;
        border: 1px solid #107598;
      }
    }
    &.show > .dropdown-toggle {
      color: #107598;
      background: #f7fbff;
      border: 1px solid #107598;
    }
    .dropdown-menu {
      min-width: 100%;
    }
  }
}
</style>
