import { render, staticRenderFns } from "./campaignMedia.vue?vue&type=template&id=447c88d4&scoped=true&"
import script from "./campaignMedia.vue?vue&type=script&lang=js&"
export * from "./campaignMedia.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "447c88d4",
  null
  
)

export default component.exports