<template lang="html">
  <div class="action-block">
    <button class="bttn bttn--sm bttn--orange" @click.stop="">
      {{ $t("add_campaign.create_new_campaign_button") }}
    </button>
    <!--<b-btn v-b-modal.modalPrevent variant="primary">{{ $t('add_campaign.create_new_campaign_button') }}</b-btn>-->

    <!-- Modal Component -->
    <b-modal id="modalPrevent" ref="modal" size="lg" centered>
      <div slot="modal-header">
        <div class="modal-header__hide" @click.stop="">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
        <h2 class="h1-like">{{ $t("add_campaign.add_new_campaign") }}</h2>
        <div class="modal-header__description">
          {{ $t("add_campaign.change_anytime") }}
        </div>
      </div>

      <div slot="modal-footer">
        <a href="#" class="bttn bttn--lg bttn--blue" @click.stop="">{{
          $t("add_campaign.close")
        }}</a>

        <button
          form="campaign-add"
          :disabled="sending"
          class="bttn bttn--lg bttn--orange"
          type="submit"
        >
          {{ $t("add_campaign.create_new_campaign") }}
        </button>
      </div>

      <form id="campaign-add" @submit.stop.prevent="handleSubmit">
        <error v-if="errors" :errors="errors"></error>

        <ul v-if="validation" class="alert-danger">
          <li v-for="errorText in validation">{{ $t(errorText) }}</li>
        </ul>

        <div class="row align-items-end">
          <div class="col-12 col-md-6">
            <div class="form-group form-unit form-unit--addon goal-input">
              <label class="form-unit__label">{{ $t("add_campaign.goal_in_mind") }}</label>

              <div class="form-unit__addon-wrap">
                <b-dropdown id="ddown1" :text="newCampaign.campaignCurrency" class="dropdown">
                  <b-dropdown-item data-ccy="usd" @click.stop="">USD</b-dropdown-item>
                  <b-dropdown-item data-ccy="eur" @click.stop="">EUR</b-dropdown-item>
                  <b-dropdown-item data-ccy="gbp" @click.stop="">gbp</b-dropdown-item>
                  <b-dropdown-item data-ccy="cad" @click.stop="">cad</b-dropdown-item>
                  <b-dropdown-item data-ccy="ils" @click.stop="">ils</b-dropdown-item>
                  <b-dropdown-item data-ccy="brl" @click.stop="">brl</b-dropdown-item>
                  <b-dropdown-item data-ccy="aud" @click.stop="">aud</b-dropdown-item>
                  <b-dropdown-item data-ccy="zar" @click.stop="">zar</b-dropdown-item>
                  <b-dropdown-item data-ccy="nzd" @click.stop="">nzd</b-dropdown-item>
                  <b-dropdown-item data-ccy="mxn" @click.stop="">mxn</b-dropdown-item>
                  <b-dropdown-item data-ccy="chf" @click.stop="">chf</b-dropdown-item>
                </b-dropdown>

                <input type="number" min="0" required class="form-control form-unit__input" />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group form-unit">
              <label class="form-unit__label">{{ $t("add_campaign.title") }}</label>
              <input type="text" class="form-control form-unit__input" required />
            </div>
          </div>
        </div>

        <div class="row align-items-end">
          <div class="col-12 col-md-6">
            <div class="form-group form-unit form-unit--addon date-time-select">
              <label class="form-unit__label">{{ $t("add_campaign.date_in_mind") }}</label>

              <div class="form-unit__addon-wrap">
                <div class="form-unit__addon-icon">
                  <svg viewBox="0 0 24 22">
                    <g fill="none" fill-rule="evenodd" opacity="1">
                      <path
                        stroke="#107598"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4.64 1v3.64M19.18 1v3.64M1 2.82V21h14.55l7.27-7.27V2.82zM21.91 15.09v5h-5"
                      />
                      <path
                        fill="#107598"
                        fill-rule="nonzero"
                        d="M1 2.82V21h14.55l7.27-7.27V2.82H1zm6.36 14.54H5.55v-1.81h1.81v1.81zm0-3.63H5.55V11.9h1.81v1.82zm0-3.64H5.55V8.27h1.81v1.82zM11 17.36H9.18v-1.81H11v1.81zm0-3.63H9.18V11.9H11v1.82zm0-3.64H9.18V8.27H11v1.82zm3.64 7.27h-1.82v-1.81h1.82v1.81zm0-3.63h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82zm3.63 3.64h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82z"
                      />
                    </g>
                  </svg>
                </div>

                <datetime
                  input-id="start-date"
                  type="datetime"
                  value-zone="UTC"
                  input-class="form-control form-unit__input"
                  title="Select start date"
                ></datetime>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group form-unit form-unit--addon date-time-select">
              <label class="form-unit__label">{{ $t("add_campaign.end_date") }}</label>

              <div class="form-unit__addon-wrap">
                <div class="form-unit__addon-icon">
                  <svg viewBox="0 0 24 22">
                    <g fill="none" fill-rule="evenodd" opacity="1">
                      <path
                        stroke="#107598"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4.64 1v3.64M19.18 1v3.64M1 2.82V21h14.55l7.27-7.27V2.82zM21.91 15.09v5h-5"
                      />
                      <path
                        fill="#107598"
                        fill-rule="nonzero"
                        d="M1 2.82V21h14.55l7.27-7.27V2.82H1zm6.36 14.54H5.55v-1.81h1.81v1.81zm0-3.63H5.55V11.9h1.81v1.82zm0-3.64H5.55V8.27h1.81v1.82zM11 17.36H9.18v-1.81H11v1.81zm0-3.63H9.18V11.9H11v1.82zm0-3.64H9.18V8.27H11v1.82zm3.64 7.27h-1.82v-1.81h1.82v1.81zm0-3.63h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82zm3.63 3.64h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82z"
                      />
                    </g>
                  </svg>
                </div>

                <datetime
                  input-id="end-date"
                  type="datetime"
                  value-zone="UTC"
                  input-class="form-control form-unit__input"
                  :placeholder="$t('edit_campaign.generalinfo_end_date')"
                ></datetime>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import error from "@/components/errors.vue";

export default {
  data() {
    return {
      newCampaign: {},
      errors: [],
      validation: [],
      sending: false,
    };
  },

  components: {
    error: error,
  },

  computed: mapState({}),

  methods: {},
};
</script>

<style lang="scss">
@import "../../../../styles/_vars.scss";

.goal-input {
  .dropdown {
    display: flex;
    &.open {
      .btn {
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
      }
    }
    .btn {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top: 1px solid #e6eaed !important;
      border-bottom: 1px solid #e6eaed !important;
      border-left: 1px solid #e6eaed !important;
      background-color: #ffffff;
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      position: relative;
      color: #20434e;
      &:active,
      &:focus,
      &:hover {
        color: #20434e;
        outline: 0;
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
        box-shadow: none;
      }
      span {
        position: absolute;
        top: 22px;
        right: 5px;
      }
    }
    .dropdown-toggle {
      min-width: 65px;
      font-size: 15px;
      position: relative;
      &:after {
        position: absolute;
        right: 6px;
        top: calc(50% - 1px);
      }
    }
    &-menu {
      min-width: initial;
      padding: 0;
      width: 100%;
      border-radius: 0;
    }
    &-item {
      height: 45px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: gray;
      text-transform: uppercase;
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(32, 67, 78, 0.15);
      }
    }
  }
}

.description-set {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
  h3 {
    margin: 0 0 20px;
    padding: 0;
    font-family: "ProximaNova-Bold", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #20434e;
  }
  &__unit {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (min-width: 992px) {
      width: 46%;
    }
    textarea {
      width: 100%;
      resize: none;
    }
  }
  &__unit:not(:last-of-type) {
    margin-bottom: 20px;
    @media only screen and (min-width: 992px) {
      margin-bottom: 0;
    }
  }
}

#campaign-add {
  .dropdown-toggle {
    text-transform: uppercase; // !important;
  }
  .dropdown-item {
    text-transform: uppercase;
  }

  .date-time-select {
    .form-unit__addon-icon {
      display: none;
      @media only screen and (min-width: 992px) {
        display: flex;
      }
    }
    .form-unit__input {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      @media only screen and (min-width: 992px) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.alert-danger li {
  width: 100%;
  margin-left: 0;
}

.vdatetime {
  width: 100%;
}

.vdatetime-popup {
  &__header {
    background-color: $bb-100;
  }
}

.vdatetime-calendar {
  &__month__day {
    &--selected {
      &:hover {
        > span > span {
          background-color: $bb-80;
        }
      }
      > span > span {
        background-color: $bb-100;
      }
    }
  }
}

.vdatetime-year-picker__item {
  &--selected {
    color: $bb-100;
  }
}

.vdatetime-time-picker__item {
  &--selected {
    color: $bb-100;
  }
}
</style>
