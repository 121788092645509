<template>
  <div>
    <b-modal id="userModal" size="lg" @hidden="clearData">
      <div slot="modal-header">
        <h2>{{ $t("users.user_account_modal_header") }}</h2>
      </div>

      <div slot="modal-footer">
        <!-- Cancel modal for both modes-->
        <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
          {{ $t("account.cancel") }}
        </button>

        <!-- Save data :: Add news user mode -->
        <button
          v-if="!editMode"
          form="subAccountAdd"
          :disabled="isLoading"
          class="bttn bttn--lg bttn--orange"
        >
          {{ $t("account.send_invitation") }}
        </button>

        <!-- Save data :: Edit mode -->
        <button v-if="editMode" @click="updateSubAccount()" class="bttn bttn--lg bttn--orange">
          {{ $t("account.save") }}
        </button>
      </div>

      <form id="subAccountAdd" @submit.prevent="addSubAccount">
        <error v-if="errors" :errors="errors"></error>

        <div class="row">
          <div class="col-12 col-md-6">
            <!-- upload avatar -->
            <div class="avatar-upload">
              <label class="avatar-upload__label" :class="{ disabled: !hasAccess || editMode }">
                {{ $t("user.avatar") }}

                <div class="avatar-upload__file fileUpload" style="display: none">
                  <input
                    type="file"
                    :disabled="!hasAccess || editMode"
                    @change="uploadAvatar"
                    id="userAvatar"
                  />
                </div>

                <div class="avatar-upload__thumbnail">
                  <div class="loading-image" v-if="subaccount.data.attributes.avatar">
                    <img :src="subaccount.data.attributes.avatar" />
                  </div>

                  <img
                    v-if="subaccount.data.attributes.logo"
                    :src="subaccount.data.attributes.logo"
                    :alt="subaccount.data.attributes.name"
                  />
                </div>
              </label>

              <!-- <div class="avatar-upload__control">

                <button
                  v-if='subaccount.data.attributes.avatar'
                  :disabled='!hasAccess'
                  class="avatar-upload__remove"
                  @click.prevent='deleteAvatar'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
                    <path d="M0 0h24v24H0z" fill="none"/>
                  </svg>
                </button>

                <button
                  :disabled='!hasAccess'
                  class="avatar-upload__upload-new"
                  for='userAvatar'
                  @click.prevent="">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                    <path d="M0 0h24v24H0z" fill="none"/>
                  </svg>
                </button>

              </div> -->
            </div>
            <!--/ end of upload avatar -->
          </div>
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-12">
                <div class="form-group form-unit">
                  <label class="form-unit__label">
                    {{ $t("account.sub_account_modal_fname_label") }}
                  </label>
                  <input
                    type="text"
                    :disabled="editMode"
                    v-model="subaccount.data.attributes.first_name"
                    class="form-control form-unit__input"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group form-unit">
                  <label class="form-unit__label">
                    {{ $t("account.sub_account_modal_lname_label") }}
                  </label>
                  <input
                    type="text"
                    :disabled="editMode"
                    v-model="subaccount.data.attributes.last_name"
                    class="form-control form-unit__input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group form-unit" :class="{ 'has-error': vErrors.has('userEmail') }">
              <label class="form-unit__label form-group__label--required">
                {{ $t("account.sub_account_modal_email_label") }}
              </label>
              <input
                name="userEmail"
                v-validate="'required|email'"
                v-model="subaccount.data.attributes.email"
                :disabled="editMode"
                type="text"
                class="form-control form-unit__input"
              />
              <span v-show="vErrors.has('userEmail')" class="v-help is-danger">{{
                $t("template.field_is_required")
              }}</span>
            </div>
          </div>

          <RoleSelect
            id="subaccount-role"
            required
            v-model="subaccount.data.attributes.access"
            :label="$t('account.sub_account_role')"
          />

          <RestrictedAclList
            v-if="subaccount.data.attributes.access === 'restricted'"
            v-model="restrictedACL"
          />
        </div>
        <div class="row align-items-center">
          <error-message ref="error"></error-message>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import {
  restrictedACLitemsToModelArr,
  restrictedACLModelArrToJSONApiRel,
} from "@/helpers/subAccounts";

import error from "@/components/errors.vue";
import errorList from "@/components/error-message.vue";
import RoleSelect from "@/components/role-select.vue";
import RestrictedAclList from "@/components/restricted-acl-list.vue";

export default {
  components: {
    error,
    errorMessage: errorList,
    RoleSelect,
    RestrictedAclList,
  },

  data() {
    return {
      subAccId: "",
      subaccount: {
        data: {
          attributes: {
            active: "",
            access: null,
            email: "",
            first_name: "",
            last_name: "",
            login_id: "",
          },
        },
      },

      patchModel: {
        data: {
          attributes: {
            access: "",
            active: "",
          },
        },
      },

      restrictedACL: [],

      isLoading: false,
      errors: [],
      editMode: false,
      loadImage: false,
    };
  },

  computed: mapState({
    hasAccess: (state) => {
      return state.account.access.CanManageOtherAccountAccess;
    },
  }),

  methods: {
    addUser() {
      this.editMode = false;
      this.$root.$emit("bv::show::modal", "userModal");
    },

    editUser(item) {
      this.editMode = true;
      this.subAccId = item.id;
      this.subaccount.data = JSON.parse(JSON.stringify(item));

      this.restrictedACL = restrictedACLitemsToModelArr(
        item.relationships.restricted_acl_items.data
      );

      this.$root.$emit("bv::show::modal", "userModal");
    },

    hideModal() {
      this.clearData();
      this.$root.$emit("bv::hide::modal", "userModal");
    },

    clearData() {
      this.edit = false;
      this.subaccount.data.attributes = {
        access: null,
        email: "",
        first_name: "",
        last_name: "",
      };
    },

    uploadAvatar(e) {
      this.isLoading = true;

      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post(urls.loadImageQuill.replace(":OrgId", localStorage.getItem("orgId")), formData)
        .then((response) => {
          this.isLoading = false;
          this.subaccount.data.attributes.avatar = response.data.src;
        })
        .catch((e) => {
          this.load = false;
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },

    addSubAccount() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let orgId = localStorage.getItem("orgId");
          this.isLoading = true;

          const { relationships, included } = restrictedACLModelArrToJSONApiRel(this.restrictedACL);
          this.subaccount.data.relationships = relationships;
          this.subaccount.included = included;

          axios
            .post(urls.subAccount.replace(":orgId", orgId), this.subaccount)
            .then(() => {
              this.isLoading = false;
              this.$store.dispatch("getSubaccountList");
              this.$_notificationsMixin_makeToastCompat({
                group: "Notification",
                title: this.$t("users.success_edit"),
              });
              this.$root.$emit("bv::hide::modal", "userModal");
            })
            .catch((e) => {
              console.log(e);
              this.isLoading = false;
              this.$refs.error.setErrorResponse(e.response);
            });
        }
      });
    },

    updateSubAccount() {
      let orgId = localStorage.getItem("orgId");
      this.patchModel.data.attributes = {
        access: this.subaccount.data.attributes.access,
        active: this.subaccount.data.attributes.active,
      };

      const { relationships, included } = restrictedACLModelArrToJSONApiRel(this.restrictedACL);
      this.patchModel.data.relationships = relationships;
      this.patchModel.included = included;

      axios
        .patch(
          urls.patchSubAccount.replace(":orgId", orgId).replace(":sucAccId", this.subAccId),
          this.patchModel
        )
        .then(() => {
          this.$store.dispatch("getSubaccountList");
          this.$_notificationsMixin_makeToastCompat({
            group: "Notification",
            title: this.$t("users.success_edit"),
          });
          this.$root.$emit("bv::hide::modal", "userModal");
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.form-group {
  position: relative;
}
::v-deep .v-help {
  position: absolute;
  top: 5px;
  right: 0;
}

.is-danger {
  color: #f26e66;
}
.has-error {
  .form-control.form-unit__input {
    border-color: #f26e66;
  }
}
</style>
