import axios from "axios";
import urls from "@/constants/urls";
import { buildUrl } from "@/helpers/urls";

import recurringDonationsSummaryUI from "./recurringDonationsSummaryUI.js";

const state = {
  stats: {
    active_count: 0,
    canceled_count: 0,
    completed_count: 0,
    plan_count: 0,
    total_charged: 0,
    total_progress: 0,
    total_to_be_charged: 0,
  },

  records: [],
  recordsStats: {
    canceled_count: 0,
    charge_count: 0,
    charge_total: 0,
    failed_count: 0,
    refunded_count: 0,
    successful_count: 0,
  },

  total: 0,
  loading: false,
};

const getters = {};

const mutations = {
  SET_STATS(state, data) {
    state.stats = data;
  },

  SET_RECORDS(state, data) {
    state.records = data;
  },

  SET_RECORDS_STATS(state, data) {
    state.recordsStats = data;
  },

  SET_LOADING(state, data) {
    state.loading = data;
  },

  SET_TOTAL(state, data) {
    state.total = data;
  },
};

const actions = {
  fetchData({ commit }, { oid, cid, year, month, ...rest }) {
    const url = buildUrl(urls.recurringDonationsSummaryMonth, {
      oid,
      cid,
      year,
      month,
    });
    const params = {
      page: rest.page || 1,
      per_page: rest.perPage || 20,
      status: rest.status || null,
      q: rest.q || null,
    };

    commit("SET_LOADING", true);

    return axios
      .get(url, { params })
      .then(({ data, headers }) => {
        const records = (data.included || [])
          .map((el) => ({
            ...el.attributes,
            id: el.id,
            type: el.type,
          }))
          .sort((a, b) => b.created_at - a.created_at);
        const total = Number(headers["x-total-records"]) || 0;

        commit("SET_TOTAL", total);
        commit("SET_RECORDS", records);
        commit("SET_RECORDS_STATS", data.data.attributes);

        return {
          records,
          recordsStats: data.data.attributes,
        };
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },

  fetchStats({ commit }, { oid, cid }) {
    const url = buildUrl(urls.recurringDonationsSummaryStats, {
      oid,
      cid,
    });

    return axios.get(url).then(({ data }) => {
      commit("SET_STATS", data.data.attributes);

      return {
        stats: data.data.attributes,
      };
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    ui: recurringDonationsSummaryUI,
  },
};
