<template>
  <div>
    <slot
      :open="open"
      :close="close"
      :sending="sending"
      :sendOne="sendOne"
      :sendErrors="sendErrors"
    />
    <b-modal ref="EmailMessageModal" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true">
      <div slot="modal-header">
        <h2>{{ $t(`dashboard.email_message_modal_title`, "Email message") }}</h2>
      </div>

      <b-alert v-for="(err, i) in sendErrors" :key="i" show variant="danger">
        {{ err.detail }}
      </b-alert>

      <b-alert v-if="sended" show variant="success">
        {{ $t(`dashboard.sms_message_success`, "Email sent successfully") }}
      </b-alert>

      <div>
        <email-msg-form v-if="step === 0" v-model="form" ref="form" :tags="tags" />
        <email-msg-preview
          v-if="step === 1"
          :recipient="recipient"
          :subject="form.subject"
          :message="form.message"
        />
      </div>

      <div slot="modal-footer" class="d-flex flex-row">
        <button class="mx-2 bttn bttn--lg bttn--blue" @click="close">
          {{ $t(`dashboard.cancel_btn`, "Close") }}
        </button>

        <button v-if="step === 1" class="mx-2 bttn bttn--lg bttn--blue" @click="prevStep">
          {{ $t(`dashboard.message_modal_back`, "Back") }}
        </button>

        <div class="ml-auto" v-if="step === 0" @click="next">
          <button class="bttn bttn--lg bttn--orange">
            {{ $t(`dashboard.message_modal_next`, "Next") }}
          </button>
        </div>

        <b-overlay
          :show="sending"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="ml-auto d-inline-block"
          v-else-if="step === 1"
        >
          <button class="bttn bttn--lg bttn--orange" :disabled="sending" @click="onSendClick">
            {{ $t(`dashboard.sms_message_modal_send`, "Send") }}
          </button>
        </b-overlay>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import emailMsgForm from "@/common-components/messaging/Form.vue";
import EmailMsgPreview from "@/common-components/messaging/Preview.vue";
import isArray from "lodash/isArray";
import { isPlainObject } from "@/helpers/inspect";
import { unwrapContentValue } from "@/components/vue-quill-editor/utils";

export default {
  components: { emailMsgForm, EmailMsgPreview },

  data() {
    return {
      step: 0,
      form: {},
      donorsOrDonor: null,
      team: null,
      sending: false,
      sended: false,
      sendErrors: [],
    };
  },

  computed: {
    tags() {
      let options = [
        {
          value: "{{.TeamName}}",
          text: this.$t("dashboard.email_message_tag_team_name", "Team name"),
        },
        {
          value: "{{.DonorName}}",
          text: this.$t("dashboard.email_message_tag_donor_name", "Donor name"),
        },
      ];
      return options;
    },
    recipient() {
      if (isPlainObject(this.donorsOrDonor) && this.donorsOrDonor?.id === -1) {
        return this.$t("dashboard.email_message_modal_recepient_all", "All donors");
      }

      if (isArray(this.donorsOrDonor)) {
        return this.$t(
          "dashboard.email_message_modal_recepient",
          "{donors} donor ::: All {donors} donors",
          {
            donors: this.donorsOrDonor.length,
          },
          this.donorsOrDonor.length
        );
      }

      const { billing_name, billing_last_name } = this.donorsOrDonor;
      return `${billing_name} ${billing_last_name}`;
    },
  },

  methods: {
    ...mapActions({
      sendEmail: "donorTeams/sendEmail",
    }),

    open({ donor, team } = {}) {
      if (donor || donor?.length !== 0) {
        this.donorsOrDonor = donor;
      }
      if (team) {
        this.team = team;
      }
      this.$refs.EmailMessageModal.show();
    },

    close() {
      this.step = 0;
      this.form = {};
      this.donorsOrDonor = null;
      this.team = null;
      this.sended = false;
      this.sendErrors = [];
      this.$emit("close");
      this.$refs.EmailMessageModal.hide();
    },

    prevStep() {
      if (this.step > 0) {
        this.step -= 1;
      }
    },

    nextStep() {
      this.step += 1;
    },

    next() {
      this.$refs.form.validate().then(() => {
        this.nextStep();
      });
    },

    internalSend(donorId) {
      this.sendErrors = [];

      const payload = {
        subject: this.form.subject,
        message: unwrapContentValue(this.form.message),
        from_team_to_donor: {
          [this.team.id]: donorId,
        },
      };

      return this.sendEmail({ payload }).catch((err) => {
        const resp = err?.response;
        this.sendErrors = resp?.data?.errors || [];
        return resp;
      });
    },

    sendOne() {
      this.sending = true;
      return this.internalSend(this.donorsOrDonor.id)
        .then(() => {
          this.sended = true;
          this.$emit("update:sended", this.sended);
        })
        .finally(() => {
          this.sending = false;
          this.close();
        });
    },

    sendMultiple() {
      this.sending = true;
      return Promise.allSettled(this.donorsOrDonor.map((d) => this.internalSend(d.id)))
        .then(() => {
          this.sended = true;
          this.$emit("update:sended", this.sended);
        })
        .finally(() => {
          this.sending = false;
          this.close();
        });
    },

    onSendClick() {
      if (isArray(this.donorsOrDonor)) {
        return this.sendMultiple();
      }

      return this.sendOne();
    },
  },
};
</script>
