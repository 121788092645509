<template>
  <b-card class="account-card">
    <h2 slot="header" class="account-card__title">
      {{ $t("account.change_password") }}
    </h2>

    <div slot="footer" class="account-card__footer text-right">
      <button
        class="bttn bttn--sm bttn--orange"
        form="password-change"
        :disabled="loading"
        type="submit"
      >
        {{ $t("account.save_change") }}
      </button>
    </div>

    <form id="password-change" class="card-text" @submit.prevent="updateDetails">
      <b-alert v-model="showVerifyEmailAlert" id="verifyEmailAlert" dismissible>{{
        $t("account.verify_email_alert")
      }}</b-alert>

      <error-message ref="error" :alert="true"></error-message>

      <div class="form-group form-unit">
        <label class="form-unit__label form-unit__label--upper-sm">
          {{ $t("account.email_label") }}
        </label>
        <input
          type="email"
          autocomplete="nope"
          v-model="email"
          class="form-control form-unit__input"
        />
      </div>

      <b-form-group
        :label="$t('account.current_password')"
        label-for="pass-current"
        label-class="form-unit__label--upper-sm"
        class="account-card__inputs"
      >
        <b-form-input
          required
          id="pass-current"
          type="password"
          autocomplete="new-password"
          v-model="password.current"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :label="$t('account.new_password')"
        label-for="pass-new"
        label-class="form-unit__label--upper-sm"
        class="account-card__inputs"
      >
        <password-hint ref="passwordHint" :target="'pass-new'" :password="password.new" />
        <b-form-input
          required
          id="pass-new"
          @focus="openHint"
          @blur="closeHint"
          type="password"
          name="password"
          ref="password"
          v-model="password.new"
        />
      </b-form-group>

      <b-form-group
        :label="$t('account.confirm_new_password')"
        label-for="pass-confirm-new"
        label-class="form-unit__label--upper-sm"
        class="account-card__inputs"
      >
        <b-form-input
          id="pass-confirm-new"
          type="password"
          v-model="password.confirm"
          v-validate="'required|confirmed:password'"
          name="account_password_confirmed"
          @paste.prevent
        />
        <span v-show="vErrors.has('account_password_confirmed')" class="v-help is-danger">
          {{ $t("template.account_password_not_match") }}
        </span>
      </b-form-group>
    </form>
  </b-card>
</template>

<script>
import axios from "axios";

import urls from "@/constants/urls.js";

import errorList from "@/components/error-message.vue";

import passwordHint from "@/components/password-hint-tooltip.vue";
import { notificationsMixin } from "@/mixins";

export default {
  data() {
    return {
      email: "",
      oldEmail: "",
      password: {
        current: "",
        new: "",
        confirm: "",
      },
      loading: false,
      showVerifyEmailAlert: false,
      errors: [],
    };
  },
  mixins: [notificationsMixin],

  components: {
    passwordHint,
    errorMessage: errorList,
  },

  methods: {
    openHint: function () {
      this.$refs.passwordHint.openHint();
    },

    closeHint: function () {
      this.$refs.passwordHint.closeHint();
    },

    getEmail(update = false) {
      axios
        .get(urls.signin)
        .then((response) => {
          if (update && this.oldEmail !== response.data.data.attributes.email) {
            this.showVerifyEmailAlert = true;
            this.$nextTick(() => (window.location.hash = "#verifyEmailAlert"));
          }

          this.oldEmail = response.data.data.attributes.email;
          this.email = response.data.data.attributes.email;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },
    updateDetails() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.errors = [];
          this.loading = true;
          this.$refs.error.clearErrors();

          let changePassword = {
            data: {
              attributes: {
                email: this.email,
                current_password: this.password.current,
                new_password: this.password.new,
                new_password2: this.password.confirm,
              },
            },
          };

          axios
            .post(urls.changePassword, changePassword)
            .then(() => {
              this.$store.dispatch("updateAccountOrg");
              this.getEmail(true);

              this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));

              this.loading = false;
            })
            .catch((e) => {
              this.loading = false;
              // console.error('this happen')
              this.$refs.error.setErrorResponse(e.response);
              if (e.response.data) {
                if (e.response.data.error) {
                  this.errors.push(e.response.data.error);
                } else {
                  this.errors.push(this.$t("template.error_default"));
                }
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            });
        }
      });
    },
    clearData: function () {
      this.password = {
        current: "",
        new: "",
        confirm: "",
      };
    },
  },
  created: function () {
    this.getEmail();
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
}
.v-help {
  font-size: 14px;
  position: absolute;
  bottom: -20px;
  right: 0;
}

.is-danger {
  color: #f26e66;
}
</style>
