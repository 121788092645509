<template>
  <div class="data-view-wrapper clearfix" v-if="service && isLoad">
    <div class="data-view">
      <div class="data-view__title">{{ $t("integration.service_title") }}</div>
      <div class="data-view__data">
        {{ service.attributes.service }}
      </div>
    </div>

    <div class="data-view">
      <div class="data-view__title">
        {{ $t("integration.service_integrated") }}
      </div>
      <div class="data-view__data">
        {{ getDateFormat(service.attributes.created_at) }}
      </div>
    </div>

    <div class="wrapper-options">
      <button
        type="button"
        @click="showOptionaly = !showOptionaly"
        class="show-hide-bttn"
        name="button"
      >
        {{
          !showOptionaly
            ? $t("integration.map_donors_property_show")
            : $t("integration.map_donors_property_hide")
        }}
        <i :class="!showOptionaly ? 'fa fa-angle-down' : 'fa fa-angle-up'" aria-hidden="true"></i>
      </button>
    </div>

    <div class="optionally-params" v-if="showOptionaly">
      <div class="row import-manual__row" v-for="item in donorFieldsOptional" :key="item.id">
        <div class="col-12 col-md-5">
          <div class="form-group">
            <p>
              {{
                item.attributes.charidy_field && item.attributes.charidy_field != "static"
                  ? $t("integration.charidy-field")
                  : $t("integration.static_value")
              }}
            </p>

            <b-form-select
              v-if="item.new"
              id="field-select"
              :class="
                item.new && item.attributes.charidy_field === 'static' ? 'margin-static-select' : ''
              "
              v-model="item.attributes.charidy_field"
              :options="donorFieldsName"
            ></b-form-select>

            <b-form-input
              v-if="item.attributes.charidy_field && !item.new"
              disabled
              v-model="item.attributes.charidy_field"
            >
            </b-form-input>
            <b-form-input
              v-if="
                (item.attributes.static_value && item.attributes.charidy_field != 'static') ||
                (item.new && item.attributes.charidy_field === 'static')
              "
              :disabled="!item.new"
              v-model="item.attributes.static_value"
            >
            </b-form-input>
          </div>
        </div>

        <div class="col-12 col-md-5">
          <div class="form-group">
            <p
              :class="
                item.new && item.attributes.charidy_field === 'static' ? 'margin-static-field' : ''
              "
            >
              {{ $t("integration.comeunity-field") }}
            </p>
            <b-form-input v-model="item.attributes.comeunity_field"></b-form-input>
          </div>
        </div>
        <div class="col-12 col-md-2 btn-box">
          <a
            class="import-manual__delete"
            role="button"
            @click.prevent="deleteDonorFields(item.id)"
          >
            {{ $t("dashboard.add_donate_delete_row") }}
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 btn-row">
          <span class="import-manual__action">
            <button class="import-manual__add-row" @click.prevent="addDonorRow">
              {{ $t("dashboard.add_donate_add_row") }}
            </button>
          </span>
        </div>
      </div>
    </div>

    <div class="wrapper-options">
      <button
        type="button"
        @click="showOptionalyDonation = !showOptionalyDonation"
        class="show-hide-bttn"
        name="button"
      >
        {{
          !showOptionalyDonation
            ? $t("integration.map_receipt_property_show")
            : $t("integration.map_receipt_property_hide")
        }}
        <i
          :class="!showOptionalyDonation ? 'fa fa-angle-down' : 'fa fa-angle-up'"
          aria-hidden="true"
        ></i>
      </button>
    </div>
    <div class="optionally-params" v-if="showOptionalyDonation">
      <div class="data-view multi-row">
        <div class="data-view-row row">
          <div class="col-12 col-md-5">
            <div class="data-view__title">
              {{ $t("integration.donation_receipt_creator") }}
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="data-view__data">
              <b-form-select
                id="field-select-type"
                v-model="receiptCreator"
                :options="receipt_creator_list"
              ></b-form-select>
            </div>
          </div>
        </div>
      </div>

      <div class="row import-manual__row" v-for="item in donorFieldsOptional" :key="item.id">
        <div class="col-12 col-md-5">
          <div class="form-group">
            <p>
              {{
                item.attributes.charidy_field && item.attributes.charidy_field != "static"
                  ? $t("integration.charidy-field")
                  : $t("integration.static_value")
              }}
            </p>

            <b-form-select
              v-if="item.new"
              id="field-select"
              :class="
                item.new && item.attributes.charidy_field === 'static' ? 'margin-static-select' : ''
              "
              v-model="item.attributes.charidy_field"
              :options="donationFieldsName"
            ></b-form-select>

            <b-form-input
              v-if="item.attributes.charidy_field && !item.new"
              disabled
              v-model="item.attributes.charidy_field"
            >
            </b-form-input>
            <b-form-input
              v-if="
                (item.attributes.static_value && item.attributes.charidy_field != 'static') ||
                (item.new && item.attributes.charidy_field === 'static')
              "
              :disabled="!item.new"
              v-model="item.attributes.static_value"
            >
            </b-form-input>
          </div>
        </div>

        <div class="col-12 col-md-5">
          <div class="form-group">
            <p
              :class="
                item.new && item.attributes.charidy_field === 'static' ? 'margin-static-field' : ''
              "
            >
              {{ $t("integration.comeunity-field") }}
            </p>
            <b-form-input v-model="item.attributes.comeunity_field"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-md-2 btn-box">
          <a
            class="import-manual__delete"
            role="button"
            @click.prevent="deleteDonationFields(item.id)"
          >
            {{ $t("dashboard.add_donate_delete_row") }}
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 btn-row">
          <span class="import-manual__action">
            <button class="import-manual__add-row" @click.prevent="addDonationRow">
              {{ $t("dashboard.add_donate_add_row") }}
            </button>
          </span>
        </div>
      </div>
    </div>

    <div class="footer">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="updateStep">
        {{ $t("gateways.close_view") }}
      </button>
      <button
        class="bttn bttn--lg bttn--orange el-right"
        @click.prevent="updateSealsForceIntegrationDataMap"
      >
        {{ $t("gateways.update_view") }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import axios from "axios";

import urls from "@/constants/urls.js";

import moment from "moment";
import uniqWith from "lodash/uniqWith";
import isEqual from "lodash/isEqual";
import unionBy from "lodash/unionBy";

export default {
  props: ["service"],

  data: function () {
    return {
      editIntegration: {
        data: {
          attributes: {
            donation_sobject_type: "",
          },
        },
      },
      showOptionaly: false,
      showOptionalyDonation: false,
      donorApiData: null,
      isLoad: null,
      donorFieldsCount: 0,
      donorFields: [],
      donationFields: [],
      receipt_creator_list: ["Green Invoice", "Rivhit", "iCount", "Zoho Books"],
      receiptCreator: null,
      // objectTypeDonorValue: null,
      // objectTypeDonationValue: null,
      donorFieldsName: [],
      donationFieldsName: [],
      errors: [],
    };
  },

  computed: {
    ...mapState({
      campaignId: (state) => {
        return state.campaign.campaign.data.id;
      },
    }),

    donorFieldsOptional() {
      return this.donorFields.filter((item) => {
        return (
          (item.attributes.charidy_field && item.attributes.comeunity_field) ||
          (item.attributes.static_value && item.attributes.comeunity_field) ||
          item.new
        );
      });
    },
  },

  methods: {
    fieldList: function (section) {
      let fieldsArr = this[section];

      let resultArr = [];

      for (var i = 0; i < fieldsArr.length; i++) {
        if (fieldsArr[i].attributes.charidy_field) {
          let obj = {
            text: fieldsArr[i].attributes.charidy_field,
            value: fieldsArr[i].attributes.charidy_field,
          };
          resultArr.push(obj);
        }
      }
      resultArr = uniqWith(resultArr, isEqual); //remove duplicate key
      resultArr.push({ value: "static", text: "Static field" });

      return resultArr;
    },

    addDonorRow: function () {
      let fieldObj = {
        id: this.donorFieldsCount.toString(),
        new: true,
        type: "comeunity_configuration_map_item",
        attributes: {
          charidy_field: "static",
          comeunity_field: "",
          static_value: "",
        },
      };

      this.donorFields.push(fieldObj);
      this.comeunityApiData.data.relationships.donor_data_mapping.data.push({
        type: "comeunity_configuration_map_item",
        id: this.donorFieldsCount.toString(),
      });
      this.donorFieldsCount++;
    },

    addDonationRow: function () {
      let fieldObj = {
        id: this.donorFieldsCount.toString(),
        new: true,
        type: "comeunity_configuration_map_item",
        attributes: {
          charidy_field: "static",
          comeunity_field: "",
          static_value: "",
        },
      };
      this.donationFields.push(fieldObj);
      this.comeunityApiData.data.relationships.receipt_data_mapping.data.push({
        type: "comeunity_configuration_map_item",
        id: this.donorFieldsCount.toString(),
      });
      this.donorFieldsCount++;
    },

    deleteDonorFields: function (id) {
      for (var i = 0; i < this.donorFields.length; i++) {
        if (this.donorFields[i].id == id) {
          this.donorFields.splice(i, 1);
        }
      }

      for (
        var j = 0;
        j < this.comeunityApiData.data.relationships.donor_data_mapping.data.length;
        j++
      ) {
        if (this.comeunityApiData.data.relationships.donor_data_mapping.data[j].id == id) {
          this.comeunityApiData.data.relationships.donor_data_mapping.data.splice(j, 1);
        }
      }
    },

    deleteDonationFields: function (id) {
      for (var i = 0; i < this.donorFields.length; i++) {
        if (this.donorFields[i].id == id) {
          this.donorFields.splice(i, 1);
        }
      }

      for (
        var j = 0;
        j < this.comeunityApiData.data.relationships.receipt_data_mapping.data.length;
        j++
      ) {
        if (this.comeunityApiData.data.relationships.receipt_data_mapping.data[j].id == id) {
          this.comeunityApiData.data.relationships.receipt_data_mapping.data.splice(j, 1);
        }
      }
    },

    getComeunityIntegrationDataMap() {
      let orgId = localStorage.getItem("orgId");
      axios
        .get(
          urls.getComeunityIntegrationDataMap
            .replace(":orgId", orgId)
            .replace(":id", this.service.id)
        )
        .then((response) => {
          this.comeunityApiData = response.data;
          this.isLoad = true;
          this.getDonorDataMap();
          this.getDonationDataMap();
        });
    },

    getSealsForceIntegrationDefaultDataMap() {
      let orgId = localStorage.getItem("orgId");
      axios
        .get(urls.getComeunityIntegrationDefaultDataMap.replace(":orgId", orgId))
        .then((response) => {
          this.comeunityApiData = response.data;
          this.isLoad = true;
          this.getDonorDataMap();
          this.getDonationDataMap();
        });
    },

    updateSealsForceIntegrationDataMap() {
      let orgId = localStorage.getItem("orgId");

      this.donorFields.forEach((item) => {
        if (item.new) {
          delete item.new;
        }
        if (item.attributes.charidy_field === "static") {
          item.attributes.charidy_field = "";
        }
        this.comeunityApiData.included = unionBy([item], this.comeunityApiData.included, "id");
      });

      this.donationFields.forEach((item) => {
        if (item.new) {
          delete item.new;
        }
        if (item.attributes.charidy_field === "static") {
          item.attributes.charidy_field = "";
        }
        this.comeunityApiData.included = unionBy([item], this.comeunityApiData.included, "id");
      });

      this.comeunityApiData.data.attributes.receipt_creator = this.receiptCreator;

      axios
        .post(
          urls.getComeunityIntegrationDataMap
            .replace(":orgId", orgId)
            .replace(":id", this.service.id),
          this.comeunityApiData
        )
        .then(() => {
          this.$emit("update-step");
        });
    },

    getDonorDataMap: function () {
      let included = this.comeunityApiData.included;
      included.sort((a, b) => a.id - b.id);
      this.donorFieldsCount = +included[included.length - 1].id + 1;
      let relationships = this.comeunityApiData.data.relationships.donor_data_mapping.data;

      for (let i = 0; i < relationships.length; i++) {
        let el = included.filter((el) => {
          return el.id == relationships[i].id && el.type == relationships[i].type;
        });
        this.donorFields.push(el[0]);
      }
      this.donorFieldsName = this.fieldList("donorFields");

      this.receiptCreator = this.comeunityApiData.data.attributes.receipt_creator;
    },

    checkType: function (type) {
      return type !== "Account" && type !== "Contact" && type !== "Lead" ? "Custom" : type;
    },

    getDonationDataMap: function () {
      let included = this.comeunityApiData.included;

      let relationships = this.comeunityApiData.data.relationships.receipt_data_mapping.data;

      for (let i = 0; i < relationships.length; i++) {
        let el = included.filter((el) => {
          return el.id == relationships[i].id && el.type == relationships[i].type;
        });
        this.donationFields.push(el[0]);
      }
      this.donationFieldsName = this.fieldList("donationFields");
    },

    updateStep() {
      this.$emit("update-step");
    },

    getDateFormat: function (date) {
      return moment.unix(date).format("MMMM D, YYYY h:mm a");
    },

    clearDataModa: function () {
      this.gatewayInfo = null;
      this.errors = [];
    },
  },
  created: function () {
    this.getComeunityIntegrationDataMap();
  },
};
</script>

<style lang="scss" scoped>
.el-right {
  margin-left: auto;
}
.card {
  width: 100%;
}
.select {
  width: 50%;
}
.card-body {
  display: flex;
  align-items: center;
}
.type-input {
  height: 50px;
  width: 100%;
  margin: 0;
}

.donation-show-hide-options {
  border: 0;
  background: transparent;
  width: 100%;
  text-align: center;
  outline: none;
  margin: 30px 0;
}
.margin-static-field {
  margin-bottom: 80px;
}
.margin-static-select {
  margin-bottom: 15px;
}

.form-group {
  input {
    width: 100%;
  }
}
.import-manual {
  &__row {
    &:hover {
      .import-manual__delete {
        opacity: 1;
      }
    }
  }
  &__add-row {
    cursor: pointer;
    margin: 0;
    padding: 0 13px;
    height: 26px;
    font-size: 12px;
    line-height: 26px;
    text-transform: uppercase;
    background-color: #f2f4f5;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 13px;
    &:hover,
    &:focus,
    &:active {
      background-color: #f2f4f5;
      outline: 0;
      box-shadow: none;
    }
  }
  &__delete {
    opacity: 0;
    font-size: 14px;
    font-family: "ProximaNova-Regular", sans-serif;
    color: #cc6d4c;
    text-transform: capitalize;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover,
    &:focus {
      color: darken(#cc6d4c, 5%);
      text-decoration: none;
    }
  }
}
.footer {
  display: flex;
}
.optionally-params {
  margin-bottom: 15px;
}
.custom-select {
  height: 50px;
  border-radius: 4px;
}
.btn-box {
  align-items: center;
  display: flex;
  .import-manual__delete {
    margin-top: 20px;
    color: #cc6d4c;
  }
}

.btn-row {
  text-align: center;
  padding: 15px 0;
}
.wrapper-options {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #999999;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    z-index: 0;
  }
  button {
    width: auto;
    position: relative;
    z-index: 2;
    &:focus {
      outline: 0;
    }
  }
}

.multi-row {
  flex-wrap: wrap;
  .data-view__title {
    min-width: 50%;
    max-width: 50%;
  }
  input {
    width: 100%;
  }
}

.data-view-row {
  width: 100%;
  display: flex;
  align-items: center;
}
.data-view__data {
  max-width: 100%;
  width: 100%;
  margin-left: 15px;
}
</style>
