<template>
  <div class="button-item">
    <div class="button-image">
      <img :src="image" :alt="title" />
    </div>
    <div class="button-body">
      <h5>{{ title }}</h5>

      <span v-if="subtitle">
        {{ subtitle }}
      </span>

      <span v-if="subLearner" class="text-muted mt-1">
        {{ $t("teams.team_page_taken_by", "taken by") }}
        <a :href="subLearner.link">{{ subLearner.name }}</a>
      </span>
    </div>
    <div class="text-center mt-2">
      <b-button v-if="!subLearner" class="bttn bttn--sm bttn--orange" @click="remove">
        <b-icon-trash-fill font-scale="1.2" />
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
    team: {
      type: Object,
      default: null,
    },
  },

  computed: {
    attrs() {
      return this.data?.attributes;
    },

    extend() {
      return this.attrs?.extend;
    },

    book() {
      return this.extend?.grandparent;
    },

    chapter() {
      return this.extend?.parent;
    },

    image() {
      return (
        this.attrs?.media ||
        "https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1607380962396280810.png"
      );
    },

    title() {
      return `${this.book?.name} - ${this.chapter?.name} - ${this.attrs?.title}`;
    },

    subtitle() {
      return this.attrs?.description;
    },

    subLearner() {
      return (this?.extend?.learners || []).filter((el) => {
        const tptid = this.team?.["parent_team_id"];
        const tid = this.team?.id;

        if (tptid) {
          return el.id !== tptid && tid !== el.id;
        }

        return tid !== el.id;
      })[0];
    },
  },

  methods: {
    remove() {
      const msg = this.$t(
        "teams.team_page_delete_confirm_msg",
        `Please confirm that you want to delete ${this.title}`
      );

      this.$bvModal
        .msgBoxConfirm(msg, {
          title: "Please Confirm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("dashboard.donor_settings_confirm_yes_btn", "Yes"),
          cancelTitle: this.$t("dashboard.donor_settings_confirm_no_btn", "No"),
          footerClass: "p-2",
          hideHeaderClose: true,
        })
        .then((value) => {
          if (value) {
            this.$emit("remove", { ...this.data });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.button-item {
  position: relative;
  width: 200px;
  min-width: 150px;
  padding: 5px;
  transition: all 0.5s;
  &.disabled {
    cursor: not-allowed;
  }
  .button-image {
    width: 65px;
    height: 65px;
    overflow: hidden;
    margin: 0 auto;
    img {
      height: 100%;
    }
  }
  .button-body {
    padding-top: 10px;
    text-align: center;
    h5 {
      text-transform: uppercase;
      font-size: 12px;
      padding-bottom: 3px;
      margin: 0 0 3px 0;
      border-bottom: solid 1px black;
    }
    p {
      font-weight: bold;
      margin: 0;
      padding-bottom: 1px;
      sup {
        font-size: 75%;
      }
    }
    sup {
      vertical-align: sub;
    }
    span {
      font-size: 11px;
      font-weight: 100;
      display: block;
    }
  }
}
</style>
