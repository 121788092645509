<template>
  <b-modal
    id="export-reports"
    size="lg"
    centered
    no-close-on-backdrop
    @hidden="resetModal"
    @cancel="cancelModal"
    @close="cancelModal"
    @ok="submitReport"
  >
    <template v-slot:modal-header>
      <h2>{{ $t("dashboard.export_report_modal_title") }}</h2>
      <div class="modal-header__hide" @click.prevent="cancelModal">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
          <path d="M0 0h24v24H0z" fill="none"></path>
        </svg>
      </div>
    </template>

    <div slot="modal-footer">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="cancelModal">
        {{ $t("dashboard.donation_add_cancel") }}
      </button>
      <button @click.prevent="submitReport" class="bttn bttn--lg bttn--orange">
        {{ $t("dashboard.export_report_export") }}
      </button>
    </div>

    <b-row class="mb-4">
      <b-col cols="12">
        <h3 class="section-title">Date range</h3>
      </b-col>
      <b-col cols="12" class="mb-4">
        <!-- time intervals select -->
        <div class="action-row">
          <div class="form-radio">
            <input
              v-model="selectedDate"
              id="today"
              type="radio"
              class="form-radio__input"
              value="today"
            />
            <label for="today" class="form-radio__label"> Today </label>
          </div>
          <div class="legend">
            {{ showDate("today") }}
          </div>
        </div>
        <div class="action-row">
          <div class="form-radio">
            <input
              v-model="selectedDate"
              id="c-month"
              type="radio"
              class="form-radio__input"
              value="month"
            />
            <label for="c-month" class="form-radio__label"> Current month </label>
          </div>
          <div class="legend">
            {{ showDate("month") }}
          </div>
        </div>

        <div class="action-row">
          <div class="form-radio">
            <input
              v-model="selectedDate"
              id="-week"
              type="radio"
              class="form-radio__input"
              value="-week"
            />
            <label for="-week" class="form-radio__label"> Previous 7 days </label>
          </div>
          <div class="legend">
            {{ showDate("-week") }}
          </div>
        </div>

        <div class="action-row">
          <div class="form-radio">
            <input
              v-model="selectedDate"
              id="-month"
              type="radio"
              class="form-radio__input"
              value="-month"
            />
            <label for="-month" class="form-radio__label"> Previous month </label>
          </div>
          <div class="legend">
            {{ showDate("-month") }}
          </div>
        </div>

        <div class="action-row">
          <div class="form-radio">
            <input
              v-model="selectedDate"
              id="all"
              type="radio"
              class="form-radio__input"
              value="all"
            />
            <label for="all" class="form-radio__label"> All </label>
          </div>
        </div>

        <div class="action-row-custom">
          <div class="form-radio">
            <input
              v-model="selectedDate"
              id="custom"
              type="radio"
              class="form-radio__input"
              value="custom"
            />
            <label for="custom" class="form-radio__label"> Custom </label>
          </div>
          <div class="from">
            <datetime
              type="date"
              input-class="mb-2 mb-sm-0 form-control"
              v-model="custom.from"
              :disabled="selectedDate !== 'custom'"
              auto
            />
          </div>
          <div class="to">
            <datetime
              type="date"
              input-class="mb-2 mb-sm-0 form-control"
              v-model="custom.to"
              :disabled="selectedDate !== 'custom'"
              auto
            />
          </div>
        </div>
      </b-col>
      <!--/ end of intervals -->
      <b-col>
        <div class="report-type">
          <div class="report-type__legend">Select report type:</div>
          <b-form-select
            v-model="reportTypeSelected"
            :options="reportTypeOptions"
            value-field="value"
            text-field="text"
          ></b-form-select>
        </div>
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col cols="12" class="mb-3">
        <h3 class="section-title">Basic Report options</h3>
      </b-col>
      <b-col cols="12" md="4" v-for="(item, index) in basicReportData" :key="index">
        <div class="form-checkbox form-checkbox--orange">
          <input
            :id="`br-${index}`"
            v-model="basicReport[item[0]]"
            class="form-checkbox__input"
            type="checkbox"
          />
          <label class="form-checkbox__label" :for="`br-${index}`">
            {{ item[2] }}
          </label>
        </div>
      </b-col>
    </b-row>

    <b-row class="mb-4" v-if="reportTypeSelected === 'advanced'">
      <b-col cols="12" class="d-flex mb-3">
        <h3 class="section-title">Advanced Report options</h3>
        <button class="select-deselect" @click="selectDeselect">
          {{ selectAll ? "Select All" : "Deselect All" }}
        </button>
      </b-col>
      <b-col cols="12" md="4" v-for="(item, index) in advancedReportData" :key="index">
        <div class="form-checkbox form-checkbox--orange">
          <input
            :id="`ar-${index}`"
            v-model="advancedReport[item[0]]"
            class="form-checkbox__input"
            type="checkbox"
          />
          <label class="form-checkbox__label" :for="`ar-${index}`">
            {{ item[2] }}
          </label>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { mapGetters } from "vuex";
import urls from "@/constants/urls";

export default {
  name: "reportsExport",
  data() {
    return {
      cid: "",
      selectedDate: "today",
      custom: {
        from: new Date().toISOString(),
        to: new Date().toISOString(),
      },
      reportTypeSelected: "basic",
      reportTypeOptions: [
        { value: "basic", text: "Basic report" },
        { value: "advanced", text: "Advanced report" },
      ],
      basicReportData: [
        ["donation_id", true, "Donation ID"],
        ["donation_date_and_time", true, "Donation Date and Time"],
        ["billing_first_name", true, "Billing First Name"],
        ["billing_last_name", true, "Billing Last Name"],
        ["email", true, "Email"],
        ["phone", true, "Phone"],
        ["charge_amount", true, "Charge Amount"],
        ["matched_total_amount", true, "Matched/Total Amount"],
        ["currency", true, "Currency"],
        ["bank_name", true, "Bank Name"],
        ["invoice_no", true, "Invoice No"],
        ["dedication", true, "Dedication"],
        ["billing_address_line_1", true, "Billing Address Line 1"],
        ["billing_address_line_2", true, "Billing Address Line 2"],
        ["billing_address_city", true, "Billing Address City"],
        ["billing_address_zip_postal_code", true, "Billing Address Zip / Postal Code"],
        ["billing_address_state_area", true, "Billing Address State / Area"],
        ["billing_address_country", true, "Billing Address Country"],
      ],
      advancedReportData: [
        ["campaign", false, "Campaign"],
        ["display_name", false, "Display Name"],
        ["receipt_name", false, "Receipt Name"],
        ["original_charge_amount", false, "Original Charge Amount"],
        ["original_charge_currency", false, "Original Charge Currency"],
        ["status", false, "Status"],
        ["transactional_id", false, "Transaction ID"],
        ["account_number", false, "Account Number"],
        ["referred_source_id", false, "Referrer / Source ID"],
        ["donation_earmark_group", false, "Donation Earmark / Group"],
        ["team_id", false, "Team ID"],
        ["team_name", false, "Team Name"],
        ["donation_stream", false, "Donation Stream"],
        ["donation_preset_level", false, "Donation Preset Level"],
        ["gift_aid", false, "GiftAid"],
        ["offline_donation_source", false, "Offline Donation Source"],
        ["offline_donation_note", false, "Offline Donation Note"],
        ["offline_donation_added_by", false, "Offline Donation Added By (account email)"],
        ["note", false, "Note"],
        ["custom_fields", false, "Custom Fields"],
        //---
        ["recurring", false, "Recurring"],
        ["recurring_period", false, "Recurring period"],
        ["installments", false, "Installments"],
        ["charge_amount_total", false, "Charge Amount Total"],
        ["original_charge_amount_total", false, "Original Charge Amount Total"],
        ["card_holder_id", false, "Card Holder ID Number"],
        ["credit_card_exp_date", false, "Credit Card Exp Date"],
        ["card_token", false, "Credit Card Token"],
      ],

      basicReport: {},
      advancedReport: {},
      selectAll: true,
    };
  },
  computed: {
    ...mapGetters(["getOrgTimezone"]),
    accTimezone() {
      return this.getOrgTimezone;
    },
  },
  created() {
    this.basicReport = Object.fromEntries(this.basicReportData);
    this.advancedReport = Object.fromEntries(this.advancedReportData);
  },
  watch: {
    reportTypeSelected(newValue, oldValue) {
      if (newValue !== oldValue && newValue === "basic") {
        this.advancedReport = Object.fromEntries(this.advancedReportData);
        this.selectAll = true;
      }
    },
  },
  methods: {
    showDate(value) {
      if (this.accTimezone) {
        if (value === "today") {
          return moment().tz(this.accTimezone).format("MMM D");
        } else if (value === "month") {
          return `${moment().tz(this.accTimezone).startOf("month").format("MMM D")} - ${moment()
            .tz(this.accTimezone)
            .format("MMM D")}`;
        } else if (value === "-week") {
          return `${moment().tz(this.accTimezone).subtract(1, "week").format("MMM D")} - ${moment()
            .tz(this.accTimezone)
            .subtract(1, "day")
            .format("MMM D")}`;
        } else if (value === "-month") {
          return `${moment()
            .tz(this.accTimezone)
            .subtract(1, "month")
            .startOf("month")
            .format("MMM D")} - ${moment()
            .tz(this.accTimezone)
            .subtract(1, "month")
            .endOf("month")
            .format("MMM D")}`;
        }
      }
    },

    selectDeselect() {
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        this.advancedReport = Object.fromEntries(this.advancedReportData);
      } else {
        let that = this;
        Object.keys(this.advancedReport).forEach(function (key) {
          that.advancedReport[key] = true;
        });
      }
    },

    openModal(cid) {
      this.cid = cid;
      this.$bvModal.show("export-reports");
    },

    resetModal() {
      this.reportTypeSelected = "basic";
      this.basicReport = Object.fromEntries(this.basicReportData);
    },

    cancelModal() {
      this.resetModal();
      this.$bvModal.hide("export-reports");
    },

    submitReport() {
      const orgId = localStorage.getItem("orgId");
      const columns = [];
      const reports = { ...this.basicReport, ...this.advancedReport };

      for (let [key, value] of Object.entries(reports)) {
        if (value) {
          columns.push(key);
        }
      }

      const payload = {
        unix_date_from: "",
        unix_date_to: "",
        columns: columns.join(),
      };

      if (this.selectedDate === "today") {
        payload.unix_date_from = (moment().tz(this.accTimezone).format("x") / 1000) | 0;
        payload.unix_date_to = (moment().tz(this.accTimezone).format("x") / 1000) | 0;
      } else if (this.selectedDate === "month") {
        payload.unix_date_from =
          (moment().tz(this.accTimezone).startOf("month").format("x") / 1000) | 0;
        payload.unix_date_to = (moment().tz(this.accTimezone).format("x") / 1000) | 0;
      } else if (this.selectedDate === "-week") {
        payload.unix_date_from =
          (moment().tz(this.accTimezone).subtract(1, "week").format("x") / 1000) | 0;
        payload.unix_date_to =
          (moment().tz(this.accTimezone).subtract(1, "day").format("x") / 1000) | 0;
      } else if (this.selectedDate === "-month") {
        payload.unix_date_from =
          (moment().tz(this.accTimezone).subtract(1, "month").startOf("month").format("x") / 1000) |
          0;
        payload.unix_date_to =
          (moment().tz(this.accTimezone).subtract(1, "month").endOf("month").format("x") / 1000) |
          0;
      } else if (this.selectedDate === "custom") {
        payload.unix_date_from =
          (moment(this.custom.from).tz(this.accTimezone).format("x") / 1000) | 0;
        payload.unix_date_to = (moment(this.custom.to).tz(this.accTimezone).format("x") / 1000) | 0;
      }
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };
      axios
        .post(urls.reportExport.replace(":orgId", orgId).replace(":cid", this.cid), payload, {
          headers: headers,
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
          }
        })
        .catch((err) => {
          if (err) {
            //
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section-title {
  font-size: 18px;
  font-family: "VitoExtended-Bold";
  margin-bottom: 0;
  align-self: center;
}
.select-deselect {
  align-self: center;
  margin-left: auto;
  background: #ffffff;
  font-family: "VitoExtended-Light";
  font-size: 14px;
  color: #107598;
  text-decoration: underline;
  border: none;
  &:focus,
  &:active {
    outline: 0;
  }
}
.action-row {
  display: flex;
  align-items: center;
  width: 100%;
  // max-width: 568px;
  .form-radio {
    width: 150px;
    padding: 4px 0;
    @media only screen and (min-width: 992px) {
      width: 200px;
    }
    &__label {
      font-family: "VitoExtended-Light";
      font-size: 14px;
      @media only screen and (min-width: 992px) {
        font-size: 16px;
      }
    }
  }
  .legend {
    flex-grow: 1;
    font-family: "VitoExtended-Light";
    font-size: 14px;
    @media only screen and (min-width: 992px) {
      font-size: 16px;
    }
  }
}
.action-row-custom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (min-width: 992px) {
    flex-wrap: nowrap;
  }
  .form-radio {
    padding: 4px 0;
    width: 100%;
    @media only screen and (min-width: 992px) {
      max-width: 200px;
    }
    &__label {
      font-family: "VitoExtended-Light";
      font-size: 14px;
      @media only screen and (min-width: 992px) {
        font-size: 16px;
      }
    }
  }
  .from,
  .to {
    width: calc(50% - 5px);
    @media only screen and (min-width: 992px) {
      width: 100%;
      max-width: 200px;
    }
  }
  .from {
    margin-right: 5px;
  }
  .to {
    margin-left: 5px;
  }
}
.form-checkbox {
  margin: 0;
  height: 40px;
  &__label {
    font-family: "VitoExtended-Light";
  }
}
.report-type {
  display: flex;
  align-items: center;
  &__legend {
    margin-right: 15px;
    flex-shrink: 0;
    font-family: "VitoExtended-Light";
    font-size: 14px;
    @media only screen and (min-width: 992px) {
      font-size: 16px;
    }
  }
  .custom-select {
    width: 100%;
    max-width: 180px;
  }
}
</style>
