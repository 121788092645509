<template>
  <div>
    <!-- Modal Component -->
    <b-modal id="team-form" size="xl" @hidden="clearData">
      <div slot="modal-header">
        <h2>{{ formTitle }}</h2>
        <div class="modal-header__hide" @click="modalClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button type="button" class="bttn bttn--lg bttn--blue" @click="modalClose" name="button">
          {{ $t("teams.close") }}
        </button>
        <button
          form="team-add"
          type="submit"
          :disabled="loading"
          :class="loading ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
        >
          {{ $t("teams.save") }}
        </button>
      </div>

      <form id="team-add" action class @submit.prevent="saveForm" @click="hidePickerColor">
        <div class="form-block">
          <b-tabs content-class="mt-3">
            <b-tab title="Team details">
              <div class="row">
                <div class="col-12 col-lg-3">
                  <div class="matcher-card__img">
                    <div class="matcher-img">
                      <label
                        v-if="!teamData.image"
                        @click="permissions.can_edit_team_image && changeImage()"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                          />
                        </svg>
                        {{ $t("teams.upload_image") }}
                      </label>

                      <div v-if="teamData.image" class="matcher-img__loaded">
                        <div class="image-wrapper">
                          <img :src="teamData.image.model" alt />
                        </div>
                        <div
                          class="matcher-img__action matcher-img__delete"
                          @click="permissions.can_edit_team_image && deleteImage()"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                            />
                            <path d="M0 0h24v24H0z" fill="none" />
                          </svg>
                        </div>
                        <label
                          class="matcher-img__action matcher-img__change"
                          @click="permissions.can_edit_team_image && changeImage()"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path
                              d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
                            />
                          </svg>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-9">
                  <div class="row">
                    <div class="col-12 col-lg-5">
                      <div class="form-group form-unit">
                        <label class="form-unit__label form-unit__label--required" for>{{
                          $t("teams.name")
                        }}</label>
                        <input
                          :disabled="!permissions.can_edit_team_name"
                          type="text"
                          class="form-control form-unit__input"
                          v-model="teamData.name.model"
                          :required="permissions.can_edit_team_name"
                        />
                      </div>
                    </div>
                    <div class="p-0 col-12 col-lg-2">
                      <div class="form-group form-unit">
                        <label class="form-unit__label">{{ $t("teams.order") }}</label>
                        <input
                          type="text"
                          inputmode="numeric"
                          pattern="[0-9]*"
                          class="form-control form-unit__input"
                          v-model.number="teamData.sort.model"
                          :disabled="!permissions[teamData.sort.permission]"
                        />
                      </div>
                    </div>
                    <div class="col-12 col-lg-5">
                      <div class="form-group form-unit form-unit--addon">
                        <label class="form-unit__label">{{ $t("teams.goal") }}</label>
                        <div class="form-unit__addon-wrap">
                          <div class="form-unit__addon-icon no-pointer">{{ campaignCurrency }}</div>
                          <input
                            :disabled="!permissions.can_edit_team_goal"
                            type="text"
                            inputmode="numeric"
                            pattern="[0-9]*"
                            class="form-control form-unit__input"
                            :placeholder="$t('teams.goal')"
                            v-model.number="teamData.goal.model"
                            :required="permissions.can_edit_team_goal"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <AppSwitchToggle
                        :label="$t('teams.percentage_view_switch_label')"
                        :disabled="!permissions[teamData.percentage_view.permission]"
                        v-model="teamData.percentage_view.model"
                      />
                    </div>
                    <div class="col-12">
                      <div class="form-group form-unit form-unit--addon">
                        <label class="form-unit__label form-unit__label--required">{{
                          $t("teams.slug")
                        }}</label>
                        <div class="form-unit__addon-wrap">
                          <div class="form-unit__addon-icon form-unit__addon-wide no-pointer">
                            .../{{ campaignShortLink }}/
                          </div>
                          <input
                            :disabled="!permissions.can_edit_team_shortlink"
                            type="text"
                            class="form-control form-unit__input"
                            v-model="teamData.slug.model"
                            :required="permissions.can_edit_team_shortlink"
                          />
                        </div>
                        <a class="form-unit__copy-link" @click="copySlug">
                          {{ `https://${domain}/${campaignShortLink}/${teamData.slug.model}` }}
                        </a>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group form-unit form-unit--multiselect">
                            <label class="form-unit__label">{{
                              $t("dashboard.parent_team")
                            }}</label>
                            <multiselect
                              :disabled="!permissions.can_edit_team_parent_team"
                              v-model="parentTeamId"
                              :options="teamList"
                              label="text"
                              track-by="text"
                              :multiple="false"
                              :placeholder="$t('dashboard.select_parent_team')"
                              :searchable="true"
                              :internal-search="false"
                              aria-describedby="parent-team-help"
                            ></multiselect>
                            <b-form-text class="font-weight-bold" id="parent-team-help">
                              <b-icon-question-circle-fill></b-icon-question-circle-fill>
                              {{ $t("dashboard.parent_team.help") }}
                            </b-form-text>
                          </div>
                        </div>
                      </div>

                      <div class="row align-items-end">
                        <div class="col-4">
                          <div class="color-section form-group form-unit">
                            <h2 class="form-unit__label">{{ $t("edit_campaign.color_title") }}</h2>
                            <div class="color-section__inner">
                              <div class="color-section__unit">
                                <div
                                  class="picker-block"
                                  @click="permissions.can_edit_team_color && pickerToggle()"
                                  :style="{ backgroundColor: teamData.color.model }"
                                >
                                  <chrome-picker
                                    v-show="opened"
                                    v-model="teamData.color.model"
                                    @input="updateTeamColor"
                                    class="picker"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-8">
                          <AppSwitchToggle
                            :label="$t('teams.show_switch_label')"
                            :disabled="!permissions.can_edit_team_hidden"
                            v-model="teamData.hidden.model"
                            :true-value="false"
                            :false-value="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Team Leader">
              <div class="col-12">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group form-unit">
                      <label class="form-unit__label">{{ $t("teams.leader_name") }}</label>
                      <div
                        class="float-label"
                        :class="{ 'is-active': teamData.leader_name.model.length }"
                      >
                        <input
                          type="text"
                          class="form-control form-unit__input"
                          v-model="teamData.leader_name.model"
                          :disabled="!permissions.can_edit_team_leader_name"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-lg-6">
                    <div class="form-group form-unit">
                      <label class="form-unit__label">{{ $t("teams.leader_email") }}</label>
                      <input
                        type="text"
                        class="form-control form-unit__input"
                        v-model="teamData.leader_email.model"
                        :disabled="!permissions.can_edit_team_leader_email"
                      />
                    </div>
                  </div>

                  <div class="col-12 col-lg-6">
                    <div class="form-group form-unit">
                      <label class="form-unit__label">{{ $t("teams.phone") }}</label>
                      <input
                        type="text"
                        class="form-control form-unit__input"
                        v-model="teamData.phone.model"
                        :disabled="!permissions.can_edit_team_phone"
                      />
                    </div>
                  </div>

                  <b-col cols="12" lg="6">
                    <team-extra-data
                      type="email"
                      v-model="teamData.extra_emails.model"
                      :disabled="!permissions.can_edit_team_extra_emails"
                    />
                  </b-col>
                </div>
              </div>
            </b-tab>
            <b-tab title="Team description">
              <div class="row">
                <div class="col-12">
                  <div class="form-group form-unit">
                    <div class="form-unit__label">{{ $t("teams.description") }}</div>

                    <quill-editor
                      :disabled="!permissions.can_edit_team_description"
                      v-model="teamData.description.model"
                      ref="myQuillEditor"
                      :options="editorOption"
                    ></quill-editor>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab title="Email">
              <div class="row">
                <div class="col-12">
                  <div class="form-group form-unit">
                    <label class="form-unit__label">{{ $t("teams.paragraph_email") }}</label>
                    <textarea
                      class="form-unit__textarea"
                      :disabled="!permissions.can_edit_team_paragraph_email"
                      v-model="teamData.paragraph_email.model"
                    ></textarea>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab v-if="showPagesTab" title="Pages" lazy>
              <div class="row">
                <div class="col-12">
                  <team-page-list :team="team" />
                </div>
              </div>
            </b-tab>
            <b-tab v-if="showPinnedDonations" :title="$t('teams.pinned_donations', 'Pinned Donations')" lazy>
              <div class="row">
                <div class="col-12">
                  <TeamPinnedDonations
                    ref="TeamPinnedDonationsRef"
                    :team="team"
                    :campaignId="campaignId"
                    :orgId="orgId"
                  />
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import Chrome from "vue-color/src/components/Chrome.vue";
import ClickOutside from "vue-click-outside";
import Multiselect from "vue-multiselect";
import { BIconQuestionCircleFill } from "bootstrap-vue";
import urls from "@/constants/urls.js";
import { notificationsMixin } from "@/mixins";
import TeamPageList from "../sefer-torah/team-page-list.vue";
import TeamExtraData from "@/views/campaigns/campaign-edit-vue/subcomponents/team-extra-data.vue";
import AppSwitchToggle from "@/common-components/ui-elements/app-switch-toggle.vue";
import { unwrapContentValue } from "@/components/vue-quill-editor/utils";
import TeamPinnedDonations from "@/views/campaigns/campaign-edit-vue/subcomponents/team-pinned-donations.vue";
import CustomVideo from "@/components/vue-quill-editor/formats/custom-video";

export default {
  components: {
    chromePicker: Chrome,
    Multiselect,
    BIconQuestionCircleFill,
    TeamPageList,
    TeamExtraData,
    AppSwitchToggle,
    TeamPinnedDonations,
  },
  mixins: [notificationsMixin],
  props: {},

  data: function () {
    return {
      team: null,
      campaignId: null,
      opened: false,
      teamId: 0,
      parentTeamId: null,
      permissions: {},
      formTitle: this.$t("teams.add_team_modal"),
      teamData: {
        campaign_id: { model: 0, permission: "" },
        parent_team_id: { model: 0, permission: "can_edit_team_parent_team" },
        name: { model: "", permission: "can_edit_team_name" },
        slug: { model: "", permission: "can_edit_team_shortlink" },
        description: { model: "", permission: "can_edit_team_description" },
        goal: { model: 0, permission: "can_edit_team_goal" },
        color: { model: "", permission: "can_edit_team_color" },
        image: { model: "", permission: "can_edit_team_image" },
        sort: { model: 1, permission: "" },
        paragraph_email: {
          model: "",
          permission: "can_edit_team_paragraph_email",
        },
        leader_name: { model: "", permission: "can_edit_team_leader_name" },
        leader_email: { model: "", permission: "can_edit_team_leader_email" },
        extra_emails: { model: [], permission: "can_edit_team_extra_emails" },
        phone: { model: "", permission: "can_edit_team_phone" },
        hidden: { model: false, permission: "can_edit_team_hidden" },
        percentage_view: { model: false, permission: "" },
      },
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ["bold", "underline", "strike", "italic"],
              ["blockquote"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ align: [] }],
              [{ color: [] }, { background: [] }],
              ["image"],
              [CustomVideo.blotName],
              ["link"],
              ["clean"],
            ],
          },
        },
      },
    };
  },

  directives: {
    ClickOutside,
  },

  computed: {
    ...mapState({
      hasAccessEdit: (state) => {
        return state.account.access.CanEditCampaignTeams;
      },

      teamList: (state) => {
        let arrTeams = state.donorTeams.collection;

        let resultArr = [];

        for (var i = 0; i < arrTeams.length; i++) {
          let objTeam = {
            value: arrTeams[i].team.id,
            text: `[${arrTeams[i].team.id}] ${arrTeams[i].team.name}`,
          };

          resultArr.push(objTeam);
        }

        return resultArr;
      },

      loading: (state) => state.donorTeams.loading.editDonorTeams,
      showPagesTab: (s) => s.seferTorahPages.active,
      showPinnedDonations: (s) => s.featureHide.showPinnedDonations,
    }),
    campaignShortLink() {
      return this.team?.campaign?.short_link || "";
    },
    campaignCurrency() {
      return this.team?.campaign?.currency || "usd";
    },
    orgId() {
      return this.team?.organization?.id;
    },
    shortUrl() {
      return this.team?.campaign?.short_url;
    },
    domain() {
      return this.shortUrl || "www.charidy.com";
    },
  },

  watch: {
    parentTeamId: function () {
      if (this.parentTeamId) {
        this.teamData.parent_team_id.model = parseInt(this.parentTeamId.value);
      } else {
        this.teamData.parent_team_id.model = 0;
      }
    },
  },

  methods: {
    ...mapActions({
      getDonorTeams: "donorTeams/getDonorTeams",
      editDonorTeams: "donorTeams/editDonorTeams",
    }),

    copySlug() {
      const link = `https://${this.domain}/${this.campaignShortLink}/${this.teamData.slug.model}`;
      navigator.clipboard.writeText(link).then(() => {
        this.$bvToast.toast(this.$t("teams.copy_slug_content"), {
          title: this.$t("teams.copy_slug_title"),
          variant: "success",
        });
      });
    },

    pickerToggle() {
      this.opened = true;
    },

    hidePickerColor(e) {
      if (!e.target.closest(".picker-block")) {
        this.opened = false;
      }
    },

    updateTeamColor: function (color) {
      this.teamData.color.model = color.hex8;
    },

    openModal: function () {
      this.$root.$emit("bv::show::modal", "team-form");
    },

    openEditModal: function (team, permissions) {
      this.team = team;
      this.permissions = permissions;
      this.campaignId = team.campaign_id;

      Object.entries(team).forEach(([k, v]) => {
        this.$set(this.teamData, k, {
          model: v,
          permission: this.teamData[k] ? this.teamData[k].permission : "",
        });
      });

      this.teamId = team.id;
      this.formTitle = this.$t("teams.edit_team");

      this.openModal();
      this.setActiveTeam();
    },

    setActiveTeam: function () {
      let selectedTeam = this.teamList.filter((el) => {
        return el.value == this.teamData.parent_team_id.model;
      });

      if (selectedTeam.length > 0) {
        this.parentTeamId = selectedTeam[0];
      } else {
        this.parentTeamId = null;
      }
    },

    clearData: function () {
      Object.entries(this.teamData).forEach(([k, v]) => {
        if (typeof v.model == "number") {
          this.teamData[k].model = 0;
        }
        if (typeof v.model == "string") {
          this.teamData[k].model = "";
        }
        if (typeof v.model?.value == "string") {
          this.teamData[k].model.value = "";
        }
        if (typeof v.model == "boolean") {
          this.teamData[k].model = false;
        }
      });
      this.teamId = 0;
      this.parentTeamId = null;
    },

    modalClose: function () {
      this.$root.$emit("bv::hide::modal", "team-form");
    },

    saveForm: function () {
      this.editTeam();
    },

    editTeam: function () {
      this.teamData.campaign_id.model = Number(this.campaignId);
      this.teamData.description.model = unwrapContentValue(this.teamData.description.model);

      const data = {};
      Object.entries(this.teamData).forEach(([k, v]) => {
        if (this.permissions[v.permission]) {
          data[k] = v.model;
        }
      });

      this.editDonorTeams({ id: this.teamData.id.model, data })
        .then(this.$refs?.TeamPinnedDonationsRef?.$saveSelectedPinnedDonations)
        .then(() => {
          this.$_notificationsMixin_makeToast(
            "Success!",
            this.$t("edit_campaign.success_edit"),
            "success"
          );
          this.getDonorTeams({
            extend: ["stats", "campaign", "organization", "permissions"],
          });
          this.modalClose();
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    deleteImage: function () {
      this.teamData.image.model = "";
    },

    changeImage: function () {
      this.load = true;

      let fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file");
      fileInput.setAttribute("accept", "image/png, image/gif, image/jpeg, image/bmp, image/x-icon");
      fileInput.classList.add("load-image-input");

      fileInput.addEventListener("change", () => {
        if (fileInput.files != null && fileInput.files[0] != null) {
          let form_data = new FormData();

          form_data.append("file", fileInput.files[0]);

          axios
            .post(urls.loadImageQuill.replace(":OrgId", this.orgId), form_data)
            .then((response) => {
              this.load = false;

              this.teamData.image.model = response.data.src;

              fileInput.remove();
            })
            .catch(this.$_notificationsMixin_handleCatch);
        }
      });

      document.querySelector("body").appendChild(fileInput);

      fileInput.click();
    },

    teamGoal: function () {
      return this.teamData.goal.model > 0 ? true : false;
    },
  },
};
</script>

<style lang="scss">
.quill-editor .ql-container {
  height: 200px;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  background: #fff;
}

.ql-toolbar.ql-snow {
  background: #fff;
}

#team-form {
  .form-unit__addon-wide {
    width: 170px;
    overflow-x: auto;
    @media only screen and (min-width: 768px) {
      width: auto;
      overflow-x: initial;
    }
  }
  .ql-editor {
    font-family: "ProximaNova-Regular";
    font-size: 16px;
    strong {
      font-family: "ProximaNova-Bold";
    }
  }
  .form-unit__textarea {
    font-family: "ProximaNova-Regular";
    font-size: 16px;
  }
  .no-pointer {
    cursor: default !important;
  }
}

.form-unit__label {
  display: inline-block;
}

.form-unit__copy-link:not([href]) {
  cursor: pointer;
  color: $bb-70;
  &:hover {
    text-decoration: underline;
    color: $bb-70;
  }
}

.color-section {
  margin-bottom: 40px;
  &__inner {
    display: flex;
  }
  &__unit {
    width: 300px;
  }
}
.picker-block {
  height: 50px;
  width: 100%;
  margin: 0;
  padding: 0 20px;
  display: block;
  color: #20434e;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid rgba(32, 67, 78, 0.15);
  box-shadow: none;
  border-radius: 4px;
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
  .picker {
    display: block;
  }
}

.picker {
  display: none;
  position: absolute;
  top: 102%;
  left: 0;
  z-index: 2;
}
</style>
