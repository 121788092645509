<template>
  <div>
    <div class="dashboard-content-wrapper">
      <div class="dashboard-block dashboard-donations">
        <div class="dashboard-donations__header">
          <h2 class="dashboard-block__title">{{ $t("dashboard.title_teams", "Teams") }}</h2>
          <div class="btn-wrapper">
            <button :disabled="!hasAccessAdd" class="bttn bttn--sm bttn--orange" @click="addTeam">
              {{ $t("teams.add_new_team", "Add new team") }}
            </button>

            <email-message ref="emailMessage" />
            <sms-message ref="smsMessage" />
            <whatsapp-message ref="whatsappMessage" />

            <div class="btn-group delete-all-teams">
              <b-dropdown
                :disabled="!hasAccessEdit"
                variant="-sm bttn--bordered-blue"
                right
                :text="$t('edit_campaign.teams_options', 'Options')"
              >
                <b-dropdown-item @click="openModalImportTeams" v-if="hasAccessAdd">{{
                  $t("edit_campaign.import_teams", "Import")
                }}</b-dropdown-item>
                <b-dropdown-item @click="openModalExportTeams">{{
                  $t("edit_campaign.export_button_teams_title", "Export")
                }}</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>

        <div class="dashboard-donations__filters">
          <!-- search -->
          <div class="filter-block dashboard-donation__search" v-if="canSeeInputSearch">
            <label class="pb-2 form-unit__label">{{
              $t("dashboard.filter_search", "Search")
            }}</label>
            <div class="search">
              <svg viewBox="0 0 17 17">
                <g fill="#107598" fill-rule="nonzero">
                  <path
                    d="M11.22 3.29c.2.2.38.41.55.64l-.12-.16c.33.44.61.93.83 1.44l-.08-.19a6.76 6.76 0 0 1 .42 1.48 6.92 6.92 0 0 1 .03 1.52 6.76 6.76 0 0 1-.45 1.69l.08-.2c-.22.52-.5 1-.83 1.45l.12-.16c-.34.45-.74.85-1.18 1.2l.16-.12c-.43.34-.91.62-1.42.84l.2-.08a6.41 6.41 0 0 1-1.45.43 6.52 6.52 0 0 1-1.5.03 6.41 6.41 0 0 1-1.65-.46l.19.08c-.5-.22-.98-.5-1.42-.84l.16.12a6.67 6.67 0 0 1-1.18-1.2l.12.16a6.78 6.78 0 0 1-.83-1.45l.08.2a6.76 6.76 0 0 1-.42-1.48 6.92 6.92 0 0 1-.03-1.52c.08-.58.23-1.15.45-1.69l-.08.2c.22-.52.5-1 .83-1.45l-.12.16c.34-.45.74-.86 1.18-1.2l-.16.12c.44-.34.91-.62 1.42-.84l-.2.08a6.41 6.41 0 0 1 1.45-.43 6.52 6.52 0 0 1 1.5-.03c.57.08 1.12.23 1.65.46L9.33 2a6.43 6.43 0 0 1 1.89 1.28c.3.3.83.32 1.11 0a.83.83 0 0 0 0-1.14A7.13 7.13 0 0 0 .92 3.77a7.46 7.46 0 0 0 .85 8.43 7.16 7.16 0 0 0 9.02 1.58 7.53 7.53 0 0 0 2.48-2.38 7.46 7.46 0 0 0-.94-9.25c-.29-.3-.82-.32-1.11 0a.83.83 0 0 0 0 1.14z"
                  />
                  <path
                    d="M11.28 12.42l1.47 1.47 2.34 2.34.54.54c.3.3.85.32 1.14 0a.82.82 0 0 0 0-1.14l-1.47-1.47-2.34-2.34-.54-.54c-.3-.3-.85-.32-1.14 0a.82.82 0 0 0 0 1.14z"
                  />
                </g>
              </svg>
              <input
                v-model="searchQ"
                class="search__input"
                type="text"
                :placeholder="
                  $t(
                    'dashboard.select_filter_placeholder_input',
                    'Search by team name or leader name'
                  )
                "
                value
              />
            </div>
          </div>

          <!-- statuses -->
          <div class="filter-block dashboard-donation__type">
            <label class="pb-2 form-unit__label">{{
              $t("dashboard.filter_statuses", "Sort By")
            }}</label>
            <div class="select">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>
              <select class="select__select" v-model="sortBy">
                <option value>{{ $t("dashboard.teams_filter_sort_by", "None") }}</option>
                <option value="goal">{{ $t("dashboard.teams_filter_goal", "Goal ASC") }}</option>
                <option value="-goal">
                  {{ $t("dashboard.teams_filter_goal_desc", "Goal DESC") }}
                </option>
                <option value="raised">
                  {{ $t("dashboard.teams_filter_raised", "Raised ASC") }}
                </option>
                <option value="-raised">
                  {{ $t("dashboard.teams_filter_raised_desc", "Raised DESC") }}
                </option>
                <option value="percent">
                  {{ $t("dashboard.teams_filter_percent", "Percent ASC") }}
                </option>
                <option value="-percent">
                  {{ $t("dashboard.teams_filter_percent_desc", "Percent DESC") }}
                </option>
              </select>
            </div>
          </div>

          <div class="filter-block dashboard-donation__type">
            <label class="pb-2 form-unit__label">{{
              $t("dashboard.filter_show_all", "Visibility")
            }}</label>
            <div class="select">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>
              <select class="select__select" v-model="sortByShown">
                <option value>{{ $t("dashboard.teams_filter_all", "Any") }}</option>
                <option value="true">{{ $t("dashboard.teams_filter_hidden", "Hidden") }}</option>
                <option value="false">{{ $t("dashboard.teams_filter_shown", "Shown") }}</option>
              </select>
            </div>
          </div>

          <div class="filter-block dashboard-donation__type">
            <label class="pb-2 form-unit__label">{{
              $t("dashboard.parent_team", "Parent team")
            }}</label>
            <multiselect
              class="pb-2"
              v-model="parentTeamId"
              :options="teamList"
              label="text"
              track-by="text"
              :multiple="false"
              :placeholder="$t('dashboard.select_parent_team', 'Select parent team')"
              :searchable="true"
              :internal-search="false"
              aria-describedby="parent-team-help"
            ></multiselect>
            <!--  //The including_grand_children checkbox requires an endpoint on the server
             <b-form-checkbox
              v-model="including_grand_children"
              name="including grand children"
              :value="1"
              :unchecked-value="0"
              >{{ $t("dashboard.filters_including_grand_children") }}</b-form-checkbox
            > -->
          </div>

          <div class="filter-block dashboard-donation__type">
            <label class="pb-2 form-unit__label">
              {{ $t("dashboard.team_subaccount_filter", "Filter by sub-account") }}
            </label>
            <div class="select">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>
              <select class="select__select" v-model="login">
                <option v-for="(l, i) in loginsOptions" :key="i" :value="l.value">
                  {{ l.text }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="dashboard-donations__th">
          <div class="th-item">ID</div>
          <div class="th-item clickable th-item--name" @click="setSort('name')">
            {{ $t("dashboard.team_name", "Name") }}
            <i :class="classSortBy('name')"></i>
            <tooltip :title="$t('dashboard.team_name_tooltip', 'Team name')" class="mx-1" />
          </div>
          <div class="th-item th-item--name">
            {{ $t("dashboard.team_leader", "Leader name") }}
            <i :class="classSortBy('team_leader')"></i>
            <tooltip
              :title="$t('dashboard.team_leader_tooltip', 'Team leader name')"
              class="mx-1"
            />
          </div>
          <div class="th-item clickable" @click="setSort('goal')">
            {{ `${$t("dashboard.team_goal", "Goal")} (${selectedCampaignCurrency})` }}
            <i :class="classSortBy('goal')" />
            <tooltip :title="$t('dashboard.team_goal_tooltip', 'Team goal')" class="mx-1" />
          </div>
          <div class="th-item clickable" @click="setSort('raised')">
            {{ $t("dashboard.team_raised", "Raised") }}
            <i :class="classSortBy('raised')"></i>
            <tooltip
              :title="$t('dashboard.team_raised_tooltip', 'Team total amount')"
              class="mx-1"
            />
          </div>
          <div class="th-item">
            {{ $t("dashboard.team_number_of_donors", "Number of donors") }}
            <tooltip
              :title="$t('dashboard.team_number_of_donors_tooltip', 'Number of donors')"
              class="mx-1"
            />
          </div>
          <div class="th-item clickable" @click="setSort('percent')">
            {{ $t("dashboard.team_percent", "Progress") }}
            <i :class="classSortBy('percent')"></i>
            <tooltip :title="$t('dashboard.team_percent_tooltip', 'Team progress')" class="mx-1" />
          </div>
          <div class="th-item th-item--name">
            {{ $t("dashboard.team_page_show", "Visibility") }}
            <tooltip
              :title="$t('dashboard.team_page_show_tooltip', 'Team visibility')"
              class="mx-1"
            />
          </div>
          <div class="th-item">
            {{ $t("dashboard.diff_goal_raised", "Diff (goal-raised)") }}
            <tooltip
              :title="
                $t(
                  'dashboard.diff_goal_raised_tooltip',
                  'Difference between goal and raised amount'
                )
              "
              class="mx-1"
            />
          </div>
        </div>
      </div>

      <div v-if="!loading">
        <div>
          <dashboard-teams-unit
            v-for="team in teams"
            :key="team.id"
            :team="team"
            @edit-team="editTeam"
          />

          <team-form
            ref="teamform"
            :dashboard="true"
            :currentpage="page"
            :searchvalue="searchQ"
            :shortlink="selectedCampaign.attributes.short_link"
            :ccy="selectedCampaign.attributes.currency"
          />

          <div v-if="teamsCount === 0" class="empty">
            <p>{{ $t("dashboard.teams_empty") }}</p>
          </div>
          <div v-if="teamsCount > perPage" class="pagination">
            <b-pagination
              v-model="page"
              :total-rows="teamsCount"
              :per-page="perPage"
              size="md"
            ></b-pagination>
          </div>
        </div>
      </div>

      <div v-if="loading">
        <div class="loadings">
          <LdsSpinner />
        </div>
      </div>
    </div>

    <import-teams
      ref="ImportTeamsModal"
      :campaignId="campaignId"
      @send-email="sendEmail"
      @send-sms="sendSMS"
    />
    <export-teams ref="ExportTeamsModal" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import debounce from "lodash/debounce";
import Multiselect from "vue-multiselect";

import teamForm from "@/views/campaigns/campaign-edit-vue/subcomponents/team-form.vue";
import importTeams from "@/views/campaigns/campaign-edit-vue/subcomponents/campaign-import-teams.vue";
import exportTeams from "@/views/dashboard/subcomponents/export-teams-modal.vue";

import dashboardTeamsUnit from "./dashboardTeamsUnit.vue";
import smsMessage from "./messaging/smsMessage.vue";
import emailMessage from "./messaging/emailMessage.vue";
import whatsappMessage from "./messaging/whatsappMessage.vue";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";
import { publicApiHost } from "@/constants/urls.js";
import Tooltip from "@/common-components/tooltip.vue";

export default {
  components: {
    teamForm,
    dashboardTeamsUnit,
    Multiselect,
    smsMessage,
    emailMessage,
    whatsappMessage,
    importTeams,
    exportTeams,
    Tooltip,
    LdsSpinner,
  },

  data() {
    return {
      apiUrl: publicApiHost,
      page: 1,
      perPage: 50,
      searchQ: "",
      sortBy: "",
      sortByShown: "",
      parentTeamId: null,
      parent_team_id: null,
      including_grand_children: false,

      login: null,
      logins: [],
    };
  },

  directives: {
    debounce,
  },

  computed: {
    ...mapState({
      campaignId: (state) => state.dashboard.selectedCampaign,

      canSeeInputSearch: (state) => state.account.access.CanPerformDonationSearch,

      selectedCampaign(state) {
        return state.campaigns.campaigns.find((cmp) => cmp.id === this.campaignId);
      },

      selectedCampaignCurrency: (state) =>
        state.dashboard?.campaignDashboard?.data?.attributes?.currency_code,

      teams: (state) => state.teams.teams,
      loading: (state) => state.teams.loadingTeam,
      teamsCount: (state) => state.teams.teamsCount,

      hasAccessAdd: (state) => state.account.access.CanAddCampaignTeams,
      hasAccessEdit: (state) => state.account.access.CanEditCampaignTeams,

      teamList: (state) => {
        let arrTeams = state.teams.allTeams;

        let resultArr = [];

        for (var i = 0; i < arrTeams.length; i++) {
          let objTeam = {
            value: arrTeams[i].id,
            text: `[${arrTeams[i].id}] ${arrTeams[i].attributes.name}`,
          };

          resultArr.push(objTeam);
        }

        return resultArr;
      },

      orgId: () => localStorage.getItem("orgId"),
    }),

    loginsOptions() {
      const list = this.logins;
      return [
        {
          value: null,
          text: this.$t("teams.subaccount_select_existing_user", "Please select user"),
        },
        ...list.map((l) => ({
          value: l,
          text: `${l.firstname} ${l.lastname} <${l.email}>`,
        })),
      ];
    },
  },

  watch: {
    campaignId() {
      this.page = 1;
      this.getTeams();
    },
    page() {
      this.getTeams();
    },
    sortBy() {
      this.page = 1;
      this.getTeams();
    },
    sortByShown() {
      this.page = 1;
      this.getTeams();
    },
    login() {
      this.page = 1;
      this.getTeams();
    },
    searchQ: debounce(function () {
      this.page = 1;
      this.getTeams();
    }, 500),
    parentTeamId: debounce(function () {
      if (this.parentTeamId) {
        this.parent_team_id = Number(this.parentTeamId.value);
      } else {
        this.parent_team_id = 0;
      }
      this.page = 1;
      this.getTeams();
    }, 500),
    including_grand_children() {
      this.getTeams();
    },
  },

  methods: {
    ...mapActions({
      getLoginsToTeamsUsers: "subAccounts/getLoginsToTeamsUsers",
    }),

    setSort(sortBy) {
      if (this.sortBy != sortBy && this.sortBy != `-${sortBy}`) {
        this.sortBy = sortBy;
      } else if (this.sortBy == sortBy) {
        this.sortBy = `-${sortBy}`;
      } else {
        this.sortBy = "";
      }
    },

    classSortBy(sortBy) {
      if (this.sortBy == sortBy) {
        return "fa fa-angle-up";
      } else if (this.sortBy == "-" + sortBy) {
        return "fa fa-angle-down";
      }
    },

    getTeams() {
      const {
        page,
        perPage,
        sortBy,
        sortByShown,
        searchQ,
        parent_team_id,
        including_grand_children,
      } = this;
      const params = {
        page,
        limit: perPage,
        q: searchQ ? searchQ : undefined,
        sort: sortBy ? sortBy : undefined,
        hidden: sortByShown,
        extend: ["stats"],
        parent_team_id,
        including_grand_children,
        login_user_id: this.login?.user_id,
      };
      this.$store.dispatch("getTeamsByCampaignId", [this.campaignId, params]);
    },

    addTeam() {
      this.$refs.teamform.openModal();
    },

    editTeam(team) {
      this.$refs.teamform.openEditModal(team);
    },

    openModalImportTeams() {
      this.$refs.ImportTeamsModal.openModal();
    },

    openModalExportTeams() {
      const params = {
        parent_team_id: this.parent_team_id,
      };
      this.$refs.ExportTeamsModal.openModal(this.campaignId, { params });
    },

    loadNewPageTeams() {
      this.getTeams();
    },

    sendEmail({ team, importGroup } = {}) {
      this.$refs.emailMessage.open({ team, importGroup });
    },

    sendSMS({ team, importGroup } = {}) {
      this.$refs.smsMessage.open({ team, importGroup });
    },

    sendWhatsapp({ team, importGroup } = {}) {
      this.$refs.whatsappMessage.open({ team, importGroup });
    },
  },

  created() {
    this.getTeams();

    this.getLoginsToTeamsUsers({
      oid: this.orgId,
      cid: this.campaignId,
    }).then(({ data }) => {
      this.logins = data;
    });
  },
};
</script>

<style lang="scss" scoped>
#import {
  @media only screen and (max-width: 1169px) {
    display: none;
  }
  ::v-deep .btn.disabled {
    opacity: initial;
  }
}
::v-deep .multiselect__tags {
  height: 48px;
  width: 100%;
  padding-left: 45px;
  border: 1px solid #88bacc;
  border-radius: 24px;
  padding: 0;
  padding-left: 30px;
}
::v-deep .multiselect__single,
::v-deep .multiselect__placeholder {
  margin: 0;
  line-height: 48px;
  background: #0000;
}
::v-deep .multiselect__select {
  height: 48px;
}
::v-deep .multiselect__input,
::v-deep .multiselect__single,
::v-deep .multiselect__placeholder {
  height: 48px;
  margin: 0;
  line-height: 48px;
  background: transparent;
  color: initial;
  padding: 0;
  font-family: inherit;
  font-size: 16px;
}
.donation-loading {
  display: flex;
  min-height: 100px;
  align-items: center;
  justify-content: center;
}
/*.btn-item {*/
/*margin-left: 10px;*/
/*}*/
.pagination {
  margin-top: 20px;
}

.clickable {
  cursor: pointer;
  i {
    padding-left: 3px;
  }
}

.pdf-button-donations {
  background-color: #fff;
  padding-bottom: 20px;
  a {
    display: block;
    width: 100%;
    padding: 10px 25px;
    background-color: #b4c5cd52;
    color: #127598;
    font-family: "VitoExtended-Bold";
  }
}

.dashboard-donation {
  &__type {
    flex-direction: column;
  }
  &__search {
    flex-direction: column;
  }
}
</style>
