<template lang="html">
  <b-modal id="viewDataModal" size="lg">
    <div slot="modal-header">
      <h2>{{ $t(`template.preview_${name}`) }}</h2>
    </div>

    <div slot="modal-footer" class="justify-end">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="closeModal">Cancel</button>
    </div>

    <b-alert show variant="warning" class="w-100">
      {{ $t(`template.preview_donations_text`) }}
      <router-link to="/dashboard">{{ $t(`template.dashboard`) }}</router-link>
    </b-alert>

    <div class="data-view" v-for="[key, value] in dataViewEntries" :key="key">
      <div class="data-view__title">
        {{ $t("template." + key, key) }}
      </div>
      <div class="data-view__data">
        {{ value ? setValueFormat(value, key) : $t("template.empty_key", "-") }}
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from "moment-timezone";

import { mapState } from "vuex";

export default {
  props: {
    name: {
      type: String,
      defaults: "",
    },
  },

  data() {
    return {
      dataView: null,
    };
  },

  computed: {
    ...mapState({
      timeZone: (state) => {
        return state.account.account.data.attributes.timezone;
      },
    }),

    dataViewEntries() {
      return Object.entries(this.dataView || {}).filter(([k]) => this.checkAvailable(k));
    },
  },

  methods: {
    setValueFormat(data, key) {
      if (key === "date") {
        return moment.tz(data * 1000, this.timeZone).format("YYYY-MM-DD HH:mm");
      } else if (
        key === "charged_amount" ||
        key === "effective_amount" ||
        key === "processing_charged_amount"
      ) {
        return data.toLocaleString();
      } else if (key === "currency_code" || key === "processing_charged_currency") {
        return data.toUpperCase();
      } else {
        return data;
      }
    },

    openModal(data) {
      this.dataView = data;
      this.$store.dispatch("getTeamsByCampaignId", [data.campaign_id]);
      this.$root.$emit("bv::show::modal", "viewDataModal");
    },

    closeModal() {
      this.dataView = null;
      this.$root.$emit("bv::hide::modal", "viewDataModal");
    },

    checkAvailable(key) {
      const disabledKeys = [
        "campaign_id",
        "donation_receipt_id",
        "moderation_status",
        "donation_type",
        "currency_sign",
      ];

      return !disabledKeys.includes(key);
    },
  },
};
</script>

<style lang="scss">
.data-view {
  margin-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  display: flex;
  flex-wrap: wrap;
  &:last-child {
    border-bottom: 0;
  }
  @media only screen and (min-width: 992px) {
    flex-wrap: nowrap;
  }
  &__title {
    min-width: 100%;
    padding: 10px 0;
    font-family: "ProximaNova-Bold", sans-serif;
    font-size: 16px;
    @media only screen and (min-width: 992px) {
      min-width: 30%;
      max-width: 30%;
      margin-right: 20px;
    }
  }
  &__data {
    flex-grow: 1;
    padding: 10px 0;
    //font-family: 'ProximaNova', sans-serif;
    font-size: 16px;
    word-wrap: break-word;
    max-width: 65%;
  }
}

#viewDataModal {
  .justify-end {
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .data-view__data {
    max-width: 100%;
  }
}
</style>
