const errorsMixin = {
  methods: {
    $_errorsMixin_handleCatch(e) {
      const errors = [];
      if (e.response.data) {
        if (e.response.data.error) {
          errors.push(e.response.data.error);
        } else {
          errors.push(this.$t("template.error_default"));
        }
      } else {
        errors.push(this.$t("template.error_default"));
      }
      return errors;
    },
  },
};

export default errorsMixin;
