<template>
  <div>
    <div class="disabled-area">
      <div class="disabled-overlay"></div>

      <ValidationObserver ref="observer">
        <b-table
          class="p-0"
          :items="teamDonationsLocal"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          stacked="lg"
        >
          <template #head(team)>
            <div>{{ $t("dashboard.table_team_name") }}</div>
          </template>
          <template #head(dedication)>
            <div>{{ $t("dashboard.table_dedication") }}</div>
          </template>
          <template #head(total)>
            <div>{{ $t("dashboard.table_total") }}</div>
          </template>
          <template #head(actions)>
            <div>{{ $t("dashboard.table_actions") }}</div>
          </template>
          <!-- ROWS -->
          <template #cell(team)="data">
            <ValidationProvider name="team" rules="required" v-slot="{ errors }">
              <multiselect-search
                v-model="teamIDToTeam[data.item.team_id]"
                :open-prefetch="true"
                :search-url="getSearchUrl"
                :search-options="searchOptions"
                :allowEmpty="false"
                closeOnSelect
                @input="setTeam(data, $event)"
              >
                <template #no-options>
                  {{ $t("dashboard.team_search_no_options", "Please enter 1 or more characters") }}
                </template>
                <template #no-more-infinite>
                  {{ $t("dashboard.team_search_no_more", "No more teams") }}
                </template>
              </multiselect-search>
              <div
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                class="invalid-feedback d-block is-danger"
              >
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </template>
          <template #cell(dedication)="{ item }">
            <ValidationProvider name="dedication" v-slot="{ errors }">
              <b-form-input v-model="item.dedication" type="text"></b-form-input>

              <div
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                class="invalid-feedback d-block is-danger"
              >
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </template>
          <template #cell(total)="{ item }">
            <ValidationProvider
              name="total"
              :rules="getAmountValidationRules(item)"
              v-slot="{ errors, flags }"
            >
              <b-form-input
                v-model.number="item.total"
                type="text"
                class="mw-100px"
                :state="!errors[0]"
                v-b-tooltip.hover
                :title="
                  flags['max_value']
                    ? $t(
                        'dashboard.table_team_amount_error',
                        'Total amount should not exceed the donation amount'
                      )
                    : ''
                "
              ></b-form-input>
              <div
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                class="invalid-feedback d-block is-danger"
              >
                {{ !flags["max_value"] && errors[0] }}
              </div>
            </ValidationProvider>
          </template>
          <template #cell(actions)="{ index }">
            <div>
              <button
                type="button"
                class="bttn bttn--sm bttn--bordered-red"
                @click="deleteTeam(index)"
              >
                {{ $t("dashboard.table_action_delete") }}
              </button>
            </div>
          </template>
        </b-table>
      </ValidationObserver>

      <b-pagination
        v-if="teamDonationsLocal.length > perPage"
        v-model="currentPage"
        :total-rows="teamDonationsLocal.length"
        :per-page="perPage"
      />

      <div class="d-flex justify-content-center my-2">
        <button
          type="button"
          class="bttn bttn--sm bttn--orange"
          :disabled="!valid"
          @click="addTeam"
        >
          {{ $t("dashboard.action_add_team") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import urls from "@/constants/urls";
import MultiselectSearch from "@/components/multiselect-search.vue";
import { notificationsMixin } from "@/mixins";
import axios from "axios";
import { deepCopy } from "@/helpers/calendarModule";

export default {
  mixins: [notificationsMixin],

  props: {
    donation: {
      type: Object,
      required: true,
      default: () => null,
    },
  },

  components: {
    ValidationObserver,
    ValidationProvider,
    MultiselectSearch,
  },

  data() {
    return {
      fields: ["team", "dedication", "total", "actions"],
      teamDonationsLocal: [],
      teamIDToTeam: {},
      perPage: 5,
      currentPage: 1,
      valid: true,
    };
  },

  computed: {
    donationID() {
      return Number(this.donation.id);
    },

    campaignID() {
      return this.donation.campaign_id;
    },

    teamDataMap() {
      return this.donation.teamData.reduce((res, t) => {
        res[t.id] = {
          id: t.id,
          name: t.attributes.name,
        };
        return res;
      }, {});
    },

    teamDonationsMaped() {
      return this.donation.teamDonations.map((t) => ({
        id: Number(t.id),
        team_id: t.attributes.team_id,
        total: t.attributes.total / 100,
        dedication: t.attributes.dedication,
      }));
    },

    teamDonationsChanged() {
      return !(JSON.stringify(this.teamDonationsMaped) === JSON.stringify(this.teamDonationsLocal));
    },

    getSearchUrl() {
      return urls.publicTeams.replace(":cid", this.campaignID);
    },

    searchOptions() {
      return {
        placeholder: this.$t("dashboard.select_team", "Select teams"),
        changeParams: ({ query, page, per_page: pp }) => {
          return {
            q: query,
            offset: (page - 1) * pp,
            limit: pp,
          };
        },
        customLabel: (e) => `[${e.id}] ${e.name}`,
        processResults: (data) =>
          data.map((el) => ({
            id: el.id,
            name: el.attributes.name,
            $isDisabled: Boolean(this.teamIDToTeam[el.id]),
          })),
      };
    },

    allowedMinAmount() {
      return 1;
    },

    totalAmount() {
      return this.teamDonationsLocal.reduce((res, el) => {
        res += el.total;
        return res;
      }, 0);
    },
  },

  watch: {
    teamDonationsLocal: {
      handler() {
        this.onValidate();
      },
      deep: true,
    },
  },

  mounted() {
    this.teamDonationsLocal = deepCopy(this.teamDonationsMaped);
    this.teamIDToTeam = deepCopy(this.teamDataMap);
  },

  methods: {
    async validate() {
      const validateItem = async (item) => {
        if (!item.team_id) {
          return false;
        }
        return true;
      };

      const valRes = [];

      this.teamDonationsLocal.forEach((el) => {
        valRes.push(validateItem(el));
      });

      return new Promise((resolve) => {
        this.$nextTick(() => {
          if (this.$refs.observer) {
            valRes.push(this.$refs.observer.validate());
          }
          Promise.all(valRes).then((v) => resolve(!v.includes(false)));
        });
      });
    },

    onValidate() {
      this.validate().then((valid) => {
        this.valid = valid;
        this.$emit("onValidate", valid);
      });
    },

    getMaxAmount(total) {
      const freeAmt = this.donation.amountEffective - this.totalAmount;
      return total + freeAmt;
    },

    getAmountValidationRules(item) {
      const rules = {
        required: true,
        min_value: this.allowedMinAmount,
      };

      const maxValue = this.getMaxAmount(item.total);
      if (maxValue) {
        rules.max_value = maxValue;
      }

      return rules;
    },

    reCalculateAmount() {
      const tl = this.teamDonationsLocal.length;
      let amt = Math.floor(this.donation.amountEffective / (tl || 1));

      this.teamDonationsLocal = this.teamDonationsLocal.map((el) => {
        return {
          ...el,
          total: amt || this.allowedMinAmount,
        };
      });
    },

    setTeam({ index: rowIndex, item }, val) {
      const index = (this.currentPage - 1) * this.perPage + rowIndex;

      this.$delete(this.teamIDToTeam, item.team_id);

      if (val) {
        item.team_id = Number(val.id);

        this.$set(this.teamDonationsLocal, index, item);
        this.$set(this.teamIDToTeam, val.id, val);
      }
    },

    deleteTeam(rowIndex) {
      const index = (this.currentPage - 1) * this.perPage + rowIndex;
      this.$delete(this.teamIDToTeam, this.teamDonationsLocal[index]?.team_id);
      this.$delete(this.teamDonationsLocal, index);
      this.reCalculateAmount();
    },

    addTeam() {
      const defaultItem = {
        id: 0,
        team_id: 0,
        total: 0,
        dedication: "",
      };
      this.teamDonationsLocal.push(defaultItem);
      this.reCalculateAmount();
    },

    save() {
      if (!this.valid) {
        this.$_notificationsMixin_makeToast(
          "Donations to the teams error",
          "invalid form data",
          "danger"
        );
        return Promise.resolve(false);
      }

      if (this.teamDonationsChanged) {
        return axios
          .post(
            urls.donorDonationsTeamList.replace(":did", this.donationID),
            this.teamDonationsLocal
          )
          .then(({ data }) => {
            return data;
          })
          .catch(this.$_notificationsMixin_handleCatch);
      }

      return Promise.resolve(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  $this: &;
  &-area {
    position: relative;
  }
  &#{$this}-area {
    #{$this}-overlay {
      display: unset;
    }
  }
  &-overlay {
    display: none;
    position: absolute;
    cursor: not-allowed;
    z-index: 5;
    background: rgba(0, 0, 0, 0.15);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.mw-100px {
  min-width: 100px;
}
</style>
