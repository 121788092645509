<template>
  <div>
    <div v-if="!create && !update" class="text-center p-3">
      <button class="bttn bttn--lg bttn--orange" @click="create = true">
        {{ $t("dashboard_calendar_module.add_to_calendar", "+ Add to calendar") }}
      </button>
    </div>

    <b-tabs v-if="showEditForm" content-class="p-0 mt-3" lazy justified>
      <b-tab
        :title="$t('dashboard_calendar_module.one_day_tab', 'One day')"
        :active="item.dates.length === 1"
      >
        <calendar-date-form v-model="item" />
      </b-tab>

      <b-tab :title="$t('dashboard_calendar_module.few_days_tab', 'Several days')">
        <calendar-date-form v-model="item" range />
      </b-tab>

      <b-tab :title="$t('dashboard_calendar_module.day_for_period_tab', 'A day for a period')">
        <calendar-date-form v-model="item" forPeriod />
      </b-tab>

      <b-tab
        :active="item.dates.length > 1"
        :title="$t('dashboard_calendar_module.mult_days_tab', 'Multiple Dates')"
      >
        <calendar-date-form v-model="item" multiple />
      </b-tab>
    </b-tabs>

    <b-alert :show="showEditForm" variant="danger" class="text-center mt-3">
      <div v-if="remove">
        {{
          $t(
            "dashboard_calendar_module.remove_from_calendar_details",
            "Calendar entry will be removed after saving"
          )
        }}
      </div>

      <hr v-if="remove" />

      <button v-if="!remove" class="bttn bttn--lg bttn--orange" @click="triggerRemoveState(true)">
        {{ $t("dashboard_calendar_module.remove_btn", "Remove from calendar") }}
      </button>

      <button v-if="remove" class="bttn bttn--lg bttn--blue" @click="triggerRemoveState(false)">
        {{ $t("dashboard.donation_edit_cancel", "Cancel") }}
      </button>
    </b-alert>
  </div>
</template>

<script>
import { deepCopy } from "@/helpers/calendarModule";
import { mapActions, mapState } from "vuex";

import CalendarDateForm, { defaultFormData } from "./CalendarDateForm.vue";

export default {
  components: { CalendarDateForm },

  props: {
    moduleId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      create: false,
      update: false,
      remove: false,

      item: {
        dates: deepCopy(defaultFormData.dates),
        period: "",
        interval: 0,
        amount: 0,
        months: [],
        years: [],
      },
    };
  },

  computed: {
    ...mapState({
      cID: (state) => state.dashboard.selectedCampaign,
    }),

    showEditForm() {
      const { create, update } = this;

      return create || update;
    },
  },

  mounted() {
    if (this.moduleId) {
      this.fetchItem([this.cID, this.moduleId, {}]).then(({ attributes }) => {
        if (attributes.data) {
          this.item = attributes.data;

          this.create = false;
          this.remove = false;
          this.update = true;
        }
      });
    }
  },

  beforeDestroy() {
    this.create = false;
    this.update = false;
    this.remove = false;

    this.item = {
      dates: deepCopy(defaultFormData.dates),
      period: "",
      interval: 0,
      amount: 0,
      months: [],
      years: [],
    };
  },

  methods: {
    ...mapActions({
      fetchItem: "calendar/fetchItem",
      fetchCreate: "calendar/fetchCreate",
      fetchUpdate: "calendar/fetchUpdate",
      fetchDelete: "calendar/fetchDelete",
    }),

    triggerRemoveState(state) {
      this.remove = state;

      if (!this.update && state) {
        this.create = false;
        this.remove = false;
      }
    },

    submit() {
      if (!this.moduleId && this.create) {
        return this.fetchCreate([this.cID, this.item]).then(({ data }) => data.data.attributes.id);
      }

      if (this.moduleId) {
        if (this.update) {
          return this.fetchUpdate([this.cID, this.moduleId, this.item]).then(
            ({ data }) => data.data.attributes.id
          );
        }
        if (this.remove) {
          return this.fetchDelete([this.cID, this.moduleId]).then(() => 0);
        }
      }

      return Promise.resolve(this.moduleId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
