<script>
import MainHeader from "./common-components/main-header.vue";
import CharidyV2Footer from "@/common-components/cdn-v2/cdn-footer.vue";
import { getCookie } from "@/helpers/cookie";
import { notificationsMixin } from "@/mixins";
import QuillEditorVariables from "@/common-components/ui-elements/quill-editor-variables.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [notificationsMixin],
  components: {
    MainHeader,
    CharidyV2Footer,
    QuillEditorVariables,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      loading: false,
      success: false,
      error: false,
      forbidden: false,
      comment: "",
    };
  },
  created() {
    let lang = getCookie("language");
    this.$store.dispatch("setTranslationPermanent", lang);
    this.$store.dispatch("getTranslation");
  },
  watch: {
    jwtAuthCode: {
      handler(code) {
        if (!code) {
          this.$router.push({ path: "/donor-dashboard" });
        }
      },
      immediate: true,
    },
  },
  computed: {
    jwtAuthCode() {
      return this.$route.params?.jwtAuthCode;
    },
    maxLength() {
      return 120;
    },
    rules() {
      return `required|max:${this.maxLength}`;
    },
    msgLength() {
      return new Blob([this.comment]).size;
    },
    charactersLeft() {
      return this.maxLength - this.msgLength;
    },
    charactersLeftText() {
      const l = this.msgLength;
      return `(${l} / ${this.maxLength}) ${this.charactersLeft}`;
    },
  },
  methods: {
    validate() {
      return new Promise((resolve) => {
        this.$refs.observer.validate().then((valid) => {
          resolve(valid);
        });
      });
    },
    async submit() {
      const valid = await this.validate();
      if (!valid) {
        return;
      }

      this.loading = true;
      try {
        await this.$store.dispatch("postDonorTeamsPublicReply", {
          jwtAuthCode: this.jwtAuthCode,
          comment: this.comment,
        });
        const timeout = setTimeout(() => {
          clearTimeout(timeout);
          this.$router.push({ path: "/donor-dashboard" });
        }, 5000);
        this.success = true;
      } catch (e) {
        const { status } = e.response || {};
        if (status === 400) {
          this.forbidden = true;
          return;
        }
        this.error = true;
        this.$_notificationsMixin_handleCatch(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<template>
  <div class="donor-component">
    <div class="donor-wrapper d-flex flex-column align-items-start justify-content-between">
      <main-header></main-header>
      <div
        class="container-fluid d-flex flex-column align-items-start justify-content-between main"
      >
        <b-card footer-class="text-right" class="w-100" v-if="!success && !error && !forbidden">
          <template #header>
            <h3 class="h3-like">
              {{ $t("donor_dashboard.public_reply_title", "Public reply") }}
            </h3>
          </template>
          <b-card-body>
            <form @submit.prevent="submit" id="public-reply-form">
              <ValidationObserver ref="observer">
                <ValidationProvider name="message" :rules="rules" v-slot="{ errors, valid }">
                  <b-form-group>
                    <template #label>
                      <label class="form-group__label form-group__label--required">
                        {{ $t("donor_dashboard.public_reply_comment", "Comment") }}
                      </label>
                    </template>
                    <QuillEditorVariables simple v-model="comment" />
                    <template #description>
                      <span :class="{ 'text-danger': charactersLeft < 0 }">
                        {{ `${charactersLeftText} characters left` }}
                      </span>
                    </template>
                    <b-form-invalid-feedback :state="valid">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </ValidationObserver>
            </form>
          </b-card-body>
          <template #footer>
            <b-button
              :disabled="loading"
              class="bttn bttn--lg bttn--blue public-reply__btn"
              type="submit"
              form="public-reply-form"
            >
              <b-spinner v-if="loading" variant="white" small></b-spinner>
              <span>{{ $t("donor_dashboard.public_reply_action", "Send") }}</span>
            </b-button>
          </template>
        </b-card>
        <div class="donation-statistic panel public-reply__panel" v-else-if="success && !error">
          <div
            class="public-reply__text"
            v-html="
              $t(
                'donor_dashboard.cancel_public_reply_notice_success',
                `<p>Your public reply has been successfully posted.</p>`
              )
            "
          />
        </div>
        <div class="donation-statistic panel public-reply__panel" v-else-if="!success && error">
          <div
            class="public-reply__text"
            v-html="
              $t(
                'donor_dashboard.cancel_public_reply_notice_error',
                `<p>There was an error posting your public reply. Please contact support.</p>`
              )
            "
          />
        </div>
        <div class="donation-statistic panel public-reply__panel" v-else-if="!success && forbidden">
          <div
            class="public-reply__text"
            v-html="
              $t(
                'donor_dashboard.cancel_public_reply_notice_forbidden',
                `<p>Token not valid or expired.</p>`
              )
            "
          />
        </div>
      </div>
      <CharidyV2Footer />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.public-reply {
  &__panel {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 50vh;
    flex-direction: column;
    justify-content: right;
  }
  &__btn {
    gap: 1rem;
    width: 16rem;
  }
  &__text {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    letter-spacing: normal;
    color: #212529;
  }
}
</style>
