<template>
  <div>
    <div>
      <form method="POST" class="campaign-edit" @submit.prevent="submitGeneralInfo">
        <div class="error-wrapp">
          <error :errors="errors"></error>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group form-unit">
              <label class="form-unit__label form-unit__label--upper-sm">Package Name</label>
              <input
                type="text"
                required
                :disabled="!hasAccess"
                class="form-control form-unit__input"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="time-zone-info col-12 col-sm-12">Availability</div>
          <div class="col-12 col-sm-6">
            <!-- Start Date -->
            <div class="form-group form-unit form-unit--addon date-time-select">
              <label class="form-unit__label form-unit__label--upper-sm date">{{
                $t("edit_campaign.generalinfo_start_date")
              }}</label>

              <div class="form-unit__addon-wrap">
                <div
                  :class="!hasAccess ? 'form-unit__addon-icon disabled' : 'form-unit__addon-icon'"
                >
                  <svg viewBox="0 0 24 22">
                    <g fill="none" fill-rule="evenodd" opacity="1">
                      <path
                        stroke="#107598"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4.64 1v3.64M19.18 1v3.64M1 2.82V21h14.55l7.27-7.27V2.82zM21.91 15.09v5h-5"
                      />
                      <path
                        fill="#107598"
                        fill-rule="nonzero"
                        d="M1 2.82V21h14.55l7.27-7.27V2.82H1zm6.36 14.54H5.55v-1.81h1.81v1.81zm0-3.63H5.55V11.9h1.81v1.82zm0-3.64H5.55V8.27h1.81v1.82zM11 17.36H9.18v-1.81H11v1.81zm0-3.63H9.18V11.9H11v1.82zm0-3.64H9.18V8.27H11v1.82zm3.64 7.27h-1.82v-1.81h1.82v1.81zm0-3.63h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82zm3.63 3.64h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82z"
                      />
                    </g>
                  </svg>
                </div>

                <datetime
                  input-id="start-date"
                  type="datetime"
                  value-zone="UTC"
                  input-class="form-control form-unit__input"
                  :disabled="!hasAccess"
                ></datetime>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-6">
            <!-- Finish Date -->
            <div class="form-group form-unit form-unit--addon date-time-select">
              <label class="form-unit__label form-unit__label--upper-sm date">{{
                $t("edit_campaign.generalinfo_finish_date")
              }}</label>

              <div class="form-unit__addon-wrap">
                <div
                  :class="!hasAccess ? 'form-unit__addon-icon disabled' : 'form-unit__addon-icon'"
                >
                  <svg viewBox="0 0 24 22">
                    <g fill="none" fill-rule="evenodd" opacity="1">
                      <path
                        stroke="#107598"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4.64 1v3.64M19.18 1v3.64M1 2.82V21h14.55l7.27-7.27V2.82zM21.91 15.09v5h-5"
                      />
                      <path
                        fill="#107598"
                        fill-rule="nonzero"
                        d="M1 2.82V21h14.55l7.27-7.27V2.82H1zm6.36 14.54H5.55v-1.81h1.81v1.81zm0-3.63H5.55V11.9h1.81v1.82zm0-3.64H5.55V8.27h1.81v1.82zM11 17.36H9.18v-1.81H11v1.81zm0-3.63H9.18V11.9H11v1.82zm0-3.64H9.18V8.27H11v1.82zm3.64 7.27h-1.82v-1.81h1.82v1.81zm0-3.63h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82zm3.63 3.64h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82z"
                      />
                    </g>
                  </svg>
                </div>

                <datetime
                  input-id="end-date"
                  type="datetime"
                  value-zone="UTC"
                  input-class="form-control form-unit__input"
                  :disabled="!hasAccess"
                ></datetime>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-8">
            <!-- Bonus goal -->
            <div class="form-group form-unit form-unit--addon">
              <label class="form-unit__label form-unit__label--upper-sm">Pricing</label>

              <div class="form-unit__addon-wrap">
                <div
                  :class="!hasAccess ? 'form-unit__addon-icon disabled' : 'form-unit__addon-icon'"
                >
                  1000000
                </div>

                <input
                  class="form-control form-unit__input"
                  type="number"
                  :disabled="!hasAccess"
                  :placeholder="$t('edit_campaign.generalinfo_bonus_goal')"
                />
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-12">
            <div class="form-group form-unit">
              <label class="form-unit__label form-unit__label--upper-sm">Discount Code</label>
              <input type="text" :disabled="!hasAccess" class="form-control form-unit__input" />
            </div>
          </div>
        </div>
      </form>
    </div>

    <change-timezone ref="changeTimeZone"></change-timezone>

    <!-- <div class="load">
      <img src="https://editionsdelarose.com/wp-content/themes/edr/img/loading.gif">
    </div> -->
  </div>
</template>

<script>
import debounce from "lodash/debounce";

import urls, { appHost } from "@/constants/urls.js";

import error from "@/components/errors.vue";

import { mapState, mapActions } from "vuex";

import axios from "axios";

import changeTimeZone from "./changeTimeZone.vue";

import tooltip from "@/components/tooltip.vue";

export default {
  data() {
    return {
      appHost,
      generalInfo: {
        data: {
          attributes: null,
        },
      },
      errors: [],
      load: false,
      validationShortLink: "",
      shortLink: "",
      gdSelectedId: 0,
      gdArray: [],
      selectedGd: [],
    };
  },

  components: {
    error: error,
    "change-timezone": changeTimeZone,
    tooltip: tooltip,
  },

  computed: {
    ...mapState({}),
  },

  watch: {},

  methods: {
    ...mapActions({
      getEntities: "getEntities",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../styles/_vars.scss";
.goal-input {
  .dropdown {
    display: flex;
    &.open {
      .btn {
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
      }
    }
    .btn {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top: 1px solid #e6eaed !important;
      border-bottom: 1px solid #e6eaed !important;
      border-left: 1px solid #e6eaed !important;
      background-color: #ffffff;
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      position: relative;
      color: #20434e;
      &:active,
      &:focus,
      &:hover {
        color: #20434e;
        outline: 0;
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
        box-shadow: none;
      }
      span {
        position: absolute;
        top: 22px;
        right: 5px;
      }
    }
    .dropdown-toggle {
      min-width: 65px;
      font-size: 15px;
      position: relative;
      &:after {
        position: absolute;
        right: 6px;
        top: calc(50% - 1px);
      }
    }
    &-menu {
      min-width: initial;
      padding: 0;
      width: 100%;
      border-radius: 0;
    }
    &-item {
      height: 45px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: gray;
      text-transform: uppercase;
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(32, 67, 78, 0.15);
      }
    }
  }
}

.date-time-select {
  .form-unit__addon-icon {
    display: none;
    @media only screen and (min-width: 992px) {
      display: flex;
    }
  }
  input.form-unit__input {
    @media only screen and (max-width: 991px) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}
.form-unit--addon {
  .help-tooltip {
    margin-bottom: -3px;
  }
}
.form-unit__addon-wrap {
  .link-error,
  .link-success {
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    right: 0;
  }
  .link-error {
    color: #f26e66;
  }
  .link-success {
    color: #3cc7a0;
  }
  .link-validate {
    color: $fd;
    display: inline-flex;
    align-items: center;
    height: 44px;
    position: absolute;
    top: 3px;
    right: 5px;
    > img {
      display: block;
      width: auto;
      height: 100%;
    }
  }
}

.time-zone-info {
  width: 100%;
  margin-bottom: 15px;
  display: inline-block;
  font-size: 16px;
  color: $fd;
  .change-time-zone-button {
    color: $bb-100;
    text-decoration: underline;
    &:hover,
    &:focus {
      color: $bb-80;
      //text-decoration: none;
    }
  }
}
.load {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  background-color: white;
  img {
    width: 200px;
    margin: 0 auto;
  }
}

.submit-button {
  padding-top: 20px;
}

.switch-public {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //padding: 0;
  &--switch {
    input {
      display: block;
      opacity: 0;
    }
    label {
      width: 60px;
      height: 30px;
      cursor: pointer;
      display: inline-block;
      position: relative;
      background: rgb(189, 189, 189);
      border-radius: 30px;
      transition: background-color 0.4s;
      -moz-transition: background-color 0.4s;
      -webkit-transition: background-color 0.4s;
    }
    label:after {
      left: 0;
      width: 20px;
      height: 20px;
      margin: 5px;
      content: "";
      position: absolute;
      background: #fff;
      border-radius: 10px;
    }
    input:checked + label {
      background: #3cc7a0;
    }
    input:checked + label:after {
      left: auto;
      right: 0;
    }
  }
}

.form-unit__label--upper-sm {
  text-transform: none;
}

.date {
  text-transform: uppercase;
}
</style>
