<template>
  <div>
    <div class="content">
      <div class="logo">
        <img src="../../../assets/img/overpass.svg" alt="" />
      </div>
      <div class="description">
        <p>
          {{ $t("integration.overpass-description") }}
        </p>

        <p v-if="step === 'first'">{{ $t("integration.overpass_launched_five_minutes") }}</p>
      </div>
    </div>
    <div class="wizard">
      <div class="footer">
        <div class="login-mode" v-if="step === 'second'">
          <button class="bttn bttn--lg bttn--orange" @click.prevent="toLoginOverpass()">
            {{ $t("integration.login") }}
          </button>
          <span
            >{{ $t("integration.login_at_overpass") }}
            <a href="https://app.overpass.com/auth/login">overpass.com </a>
          </span>
        </div>

        <button
          class="bttn bttn--lg bttn--blue"
          v-if="step === 'first'"
          @click.prevent="closeModal"
        >
          {{ $t("integration.close") }}
        </button>

        <button
          class="bttn bttn--lg bttn--orange el-right"
          v-if="step === 'first'"
          @click.prevent="nextStep('first')"
        >
          {{ $t("integration.next") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import urls from "@/constants/urls.js";

// import store from "@/store/store.js";

import { mapState } from "vuex";

export default {
  data() {
    return {
      redirectUri: "",
      client_id: "",
      step: "first",
      integrationStatus: false,
      currentIntegrationListCache: [],
      unsubscribe: "",
    };
  },

  computed: mapState({
    currentIntegrationList: (state) => {
      return state.integrations.integrations;
    },
  }),

  methods: {
    toLoginOverpass() {
      window.open(`https://app.overpass.com/auth/login`, "_blank");
    },
    getOverpassIntegrationKey() {
      let orgId = localStorage.getItem("orgId");
      axios.post(urls.getOverpassIntegrationKey.replace(":orgId", orgId)).then((response) => {
        this.redirectUri = response.data.url;
        this.client_id = response.data.client_id;
        document.cookie =
          "overpass_integration_key=" +
          response.data.mailchimp_integration_key +
          ";domain=.charidy.com;path=/";
      });
    },

    nextStep(step) {
      if (step === "first") {
        // this.step = "second";
        window.open(this.redirectUri, "_blank");
        this.$store.dispatch("getIntegrationsList");
        this.$emit("close-modal");
      }
    },

    closeModal() {
      this.$store.dispatch("getIntegrationsList");
      this.$emit("close-modal");
    },
  },
  created: function () {
    this.getOverpassIntegrationKey();
  },
};
</script>

<style lang="scss" scoped>
.content {
  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
  .description {
    margin-bottom: 50px;
    p {
      text-align: center;
    }
  }
}
.footer {
  width: 100%;
  display: flex;
  .login-mode {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .bttn {
      margin-bottom: 10px;
    }
  }
}

.el-right {
  margin-left: auto;
}

.account-link {
  font-size: 16px;
  color: #cc6d4c;
}
</style>
