import urls from "../../constants/urls.js";
import axios from "axios";

const module_name = "richcontent";

const getBody = (cId, data, id = "") => ({
  type: "module_data",
  id: String(id),
  attributes: {
    module: module_name,
    campaign_id: Number(cId),
    data: JSON.stringify(data),
  },
});

const state = {
  activated: false,
  list: [],
  count: 0,
  loading: false,
  error: null,
};

const mutations = {
  setActivated(state, value) {
    state.activated = value;
  },

  setList(state, data) {
    state.list = data;
  },

  setCount(state, val) {
    state.count = val || 0;
  },

  setLoading(state, value) {
    state.loading = value;
  },

  setError(state, val) {
    state.error = val;
  },
};

const actions = {
  getJournalActivated({ commit }, cmpId) {
    const orgId = localStorage.getItem("orgId");
    const params = {
      module_name: "richcontent",
    };
    axios
      .get(urls.getModule.replace(":oid", orgId).replace(":cid", cmpId), {
        params,
      })
      .then(({ data }) => {
        const { active } = data.data.attributes;
        commit("setActivated", active);
      })
      .catch(() => {
        commit("setActivated", false);
      });
  },

  fetchJournalAds({ commit }, [cId, getParams]) {
    commit("setLoading", true);

    const orgId = localStorage.getItem("orgId");

    const params = {
      module_name: "richcontent",
      campaign_id: cId,
      ...getParams,
    };

    axios
      .get(urls.moduleData.replace(":oid", orgId).replace(":cmpid", cId), {
        params,
      })
      .then(({ data, headers }) => {
        commit("setCount", headers["x-total-records"]);
        commit("setList", data.data);
        commit("setLoading", false);
      })
      .catch((e) => {
        commit("setError", e.response);
        commit("setLoading", false);
      });
  },

  fetchCreateRichcontent(_, [cId, form, params] = []) {
    const orgId = localStorage.getItem("orgId");
    const data = getBody(cId, form);

    return axios.post(
      urls.moduleData.replace(":oid", orgId).replace(":cmpid", cId),
      { data },
      { params }
    );
  },

  fetchUpdateRichcontent(_, [cId, id, form, params] = []) {
    const orgId = localStorage.getItem("orgId");
    const data = getBody(cId, form, id);

    return axios.put(
      urls.moduleData.replace(":oid", orgId).replace(":cmpid", cId),
      { data },
      { params }
    );
  },
};

export default {
  state,
  actions,
  mutations,
};
