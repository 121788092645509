import { render, staticRenderFns } from "./packagesDetails.vue?vue&type=template&id=5aa51ec8&scoped=true&"
import script from "./packagesDetails.vue?vue&type=script&lang=js&"
export * from "./packagesDetails.vue?vue&type=script&lang=js&"
import style0 from "./packagesDetails.vue?vue&type=style&index=0&id=5aa51ec8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5aa51ec8",
  null
  
)

export default component.exports