<template>
  <div class="donor-sign-up-page">
    <div class="donor-wrapper">
      <main-header>
        <template #auth-actions>
          <div class="sign__mode w-100 h-100">
            <p class="sign__helper">{{ $t("template.already_have_account") }}</p>
            <a href="/donor-log-in" class="bttn bttn--sm bttn--orange">{{
              $t("template.sign_in")
            }}</a>
          </div>
        </template>
      </main-header>
      <div class="container-fluid d-flex align-items-center justify-content-between main">
        <div class="col">
          <form action>
            <div class="donor-sign-up">
              <div class="form-header">
                <h2>{{ $t("template.get_started", "Get Started") }}</h2>
                <p>{{ $t("template.enter_your_details_below", "Enter your details below") }}</p>
              </div>

              <div v-if="signupSucceed" class="signup-message">
                <p>{{ $t("template.check_your_email") }}</p>
              </div>

              <donor-sign-up-form
                @register-success="changeRegisterStatus"
                v-if="!signupSucceed"
                ref="signupDonor"
              ></donor-sign-up-form>

              <div class="text-center form-unit" v-if="!signupSucceed">
                <button
                  class="bttn bttn--lg bttn--orange bttn--sign"
                  @click.prevent="proceedSignup"
                  :disabled="!loading"
                >
                  {{ $t("template.sign_up") }}
                </button>
                <p>
                  {{
                    $t(
                      "template.already_have_an_account_Log_in",
                      " Already have an account? Log in "
                    )
                  }}
                  <a href="/donor-log-in">{{ $t("template.here", " here ") }}</a>
                </p>
                <p>
                  {{ $t("template.are_you_a_nonprofit_Sign_Up", " Are you a nonprofit? Sign Up ") }}
                  <a href="/register">{{ $t("template.here", " here ") }}</a>
                </p>
              </div>
            </div>
          </form>
        </div>
        <div class="col text-center">
          <img
            src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1699652522_6163303936383766656237626336653731373031363839333334366130343135_31363939363532353232.svg"
          />
        </div>
      </div>
      <CharidyV2Footer />
    </div>
  </div>
</template>

<script>
import mainHeader from "@/views/donor-dashboard/common-components/main-header.vue";
import CharidyV2Footer from "@/common-components/cdn-v2/cdn-footer.vue";
import donorSignUpForm from "./donor-sign-up-form.vue";

export default {
  data() {
    return {
      loading: true,
      signupSucceed: false,
    };
  },
  components: {
    "main-header": mainHeader,
    CharidyV2Footer,
    "donor-sign-up-form": donorSignUpForm,
  },
  methods: {
    changeRegisterStatus: function (result) {
      this.loading = true;
      if (result) {
        this.signupSucceed = true;
      }
    },

    proceedSignup() {
      this.loading = false;
      this.$refs.signupDonor.registerUser();
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_vars.scss";
.signup-message {
  width: 100%;
  height: 80px;
  padding: 0 15px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #414359;
  text-align: center;
  border: 1px solid #88bacc;
  border-radius: 4px;
  background-color: #eaf4ff;
  > p {
    margin: 0;
  }
}
.donor-sign-up-page {
  line-height: 1.2;
  min-height: 100vh;
  overflow: auto;
  background: #fff
    url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/main-bg.png")
    top center no-repeat;
  background-size: 100% auto;
  color: $fd;
  .bttn--orange {
    background-color: #ff5555;
    &:hover {
      opacity: 0.85;
    }
  }
  .donor-wrapper {
    padding-top: 0;
    min-width: 320px;
  }
  .col {
    width: 50%;
    img {
      max-width: 100%;
    }
  }
}
.container-fluid {
  max-width: 1150px;
  margin: 0 auto;
}
.donor-sign-up {
  .form-header {
    text-align: center;
    margin-bottom: 45px;
    h3 {
      font-size: 35px;
    }
  }
  label {
    text-transform: uppercase;
    font-size: 14px;
  }
  .text-center {
    padding-top: 25px;
    .bttn {
      min-width: 255px;
      margin-bottom: 50px;
    }
    p {
      margin-bottom: 15px;
      &.small {
        font-size: 12px;
      }
    }
  }
  a {
    color: #107598;
    text-decoration: underline;
  }
}

.main {
  margin-bottom: 70px;
}
@media all and (max-width: 767px) {
  .main {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .donor-sign-up-page {
    .col {
      width: 100%;
      flex-basis: auto;
      padding: 0;
      &:first-child {
        margin-bottom: 50px;
      }
    }
    .donor-sign-up {
      .text-center {
        padding-top: 20px;
        .bttn {
          margin-bottom: 30px;
        }
        p {
          font-size: 15px;
          &.small {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
