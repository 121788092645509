<template>
  <component :is="containerTag" :class="containerClass">
    <div class="col-12 col-sm-6">
      <div class="form-group form-unit">
        <label class="form-unit__label form-unit__label--upper-sm">{{
          $t("edit_campaign.generalinfo_google_analitics")
        }}</label>
        <input
          type="text"
          :disabled="disabled"
          pattern="^(UA-\d{4,10}-\d{1,4}|G-[A-Z0-9]+)$"
          :value="googleAnalyticsNumber"
          @input="$emit('update:googleAnalyticsNumber', $event.target.value)"
          class="form-control form-unit__input"
          :placeholder="$t('edit_campaign.generalinfo_google_analitics')"
          :title="
            $t(
              'edit_campaign.generalinfo_google_analitics_error',
              'Please enter a valid GA4 Measurement ID (e.g., G-XXXXXXX) or UA Property ID (e.g., UA-12345678-9).'
            )
          "
        />
      </div>
    </div>
    <div class="col-12 col-sm-6">
      <div class="form-group form-unit">
        <label class="form-unit__label form-unit__label--upper-sm">{{
          $t("edit_campaign.generalinfo_facebook_pixel")
        }}</label>
        <input
          type="text"
          :disabled="disabled"
          :value="facebookPixelId"
          @input="$emit('update:facebookPixelId', $event.target.value)"
          class="form-control form-unit__input"
          :placeholder="$t('edit_campaign.generalinfo_facebook_pixel')"
          pattern="^\d{10,20}$"
        />
      </div>
    </div>
    <div class="col-12 col-sm-6">
      <div class="form-group form-unit form-unit--addon">
        <label class="form-unit__label form-unit__label--upper-sm">{{
          $t("edit_campaign.generalinfo_google_conversion_id")
        }}</label>
        <div class="form-unit__addon-wrap">
          <div class="form-unit__addon-icon form-unit__addon-icon--wide">AW-</div>
          <input
            type="text"
            :disabled="disabled"
            :value="googleConversionId"
            @input="$emit('update:googleConversionId', $event.target.value)"
            class="form-control form-unit__input"
            :placeholder="$t('edit_campaign.generalinfo_google_conversion_id')"
            pattern="^\d*$"
          />
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6">
      <div class="form-group form-unit">
        <label class="form-unit__label form-unit__label--upper-sm">{{
          $t("edit_campaign.generalinfo_google_conversion_label")
        }}</label>
        <div class="form-unit__addon-wrap">
          <input
            type="text"
            :disabled="disabled"
            :value="googleConversionLabel"
            @input="$emit('update:googleConversionLabel', $event.target.value)"
            class="form-control form-unit__input"
            :placeholder="$t('edit_campaign.generalinfo_google_conversion_label')"
            pattern="^[a-zA-Z0-9]*$"
          />
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6">
      <div class="form-group form-unit">
        <label class="form-unit__label form-unit__label--upper-sm">
          {{ $t("edit_campaign.generalinfo_google_tag_manager", "Google Tag Manager") }}
        </label>
        <div class="form-unit__addon-wrap">
          <input
            type="text"
            :disabled="disabled"
            :value="googleTagManager.id"
            @input="
              $emit('update:googleTagManager', {
                id: $event.target.value,
                inject_as_text: googleTagManager.inject_as_text,
                __data: googleTagManager.__data,
              })
            "
            class="form-control form-unit__input"
          />
        </div>
      </div>
    </div>
    <div class="col-12 mt-4">
      <slot name="footer" />
    </div>
  </component>
</template>

<script>
export default {
  props: {
    containerTag: {
      type: [String, Function],
      default: "div",
    },
    containerClass: {
      type: [String, Array, Object],
      default: "m-0 row w-100",
    },

    disabled: Boolean,

    googleAnalyticsNumber: {
      type: String,
      default: "",
    },
    facebookPixelId: {
      type: String,
      default: "",
    },
    googleConversionId: {
      type: String,
      default: "",
    },
    googleConversionLabel: {
      type: String,
      default: "",
    },
    googleTagManager: {
      type: Object,
      default: () => ({
        id: "",
        inject_as_text: false,
        __data: null,
      }),
    },
  },
};
</script>

<style lang="scss" scoped></style>
