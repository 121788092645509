<template>
  <div>
    <div class="campaign-edit-title">
      <h2 class="h2-like">{{ $t("edit_campaign.donation_levels_title") }}</h2>
      <div class="btn-wrap">
        <level-modal-form ref="modalLevel" @save-level="onSaveLevel" />
      </div>
    </div>

    <section class="matchers_page">
      <div class="row flex-row-reverse" v-if="levelsList.length > 0">
        <div
          class="col-12"
          :style="{ order: orderStyle(level) }"
          v-for="level in levelsList"
          :key="level.id"
        >
          <level-plate
            :level="level"
            :loading="load"
            @edit-level="editLevel"
            @delete-level="deleteLevel"
            @change-level-order="changeLevelOrder"
          ></level-plate>
        </div>
      </div>
      <div v-if="levelsList.length == 0" class="empty-result-message">
        {{ $t("edit_campaign.donation_level_empty") }}
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import campaignLevelPlate from "./campaignLevelPlate.vue";
import levelModalForm from "./level-modal-form.vue";
import { notificationsMixin } from "@/mixins";

const extend = `?${[
  "extend=campaign_stats",
  "extend=content",
  "extend=media",
  "extend=matchers",
  "extend=donation_levels",
  "extend=donation_streams",
].join("&")}`;

export default {
  components: {
    "level-plate": campaignLevelPlate,
    levelModalForm,
  },
  mixins: [notificationsMixin],
  data() {
    return {
      levelsList: [],
      load: false,
      errors: [],
    };
  },

  computed: {
    ...mapState({
      levels: (state) => {
        let included = state.campaign.campaign.included;

        let relationships = state.campaign.campaign.data.relationships.donation_level.data;

        let arrLevels = [];

        relationships.forEach((relationship) => {
          let level = included.find(
            (el) => el.id == relationship.id && el.type == relationship.type
          );

          arrLevels.push(level);
        });

        return arrLevels;
      },

      campaignId: (state) => state.campaign.campaign.data.id,
      orgId: () => localStorage.getItem("orgId"),
    }),
  },

  methods: {
    ...mapActions({
      getJournalActivated: "getJournalActivated",
    }),

    editLevel(level) {
      this.$refs.modalLevel.openModalEdit(level);
    },

    deleteLevel(level) {
      this.errors = [];

      let message = this.$t("edit_campaign.confirm_delete_donation_level");

      let options = {
        okText: this.$t("edit_campaign.confirm_delete"),
        cancelText: this.$t("edit_campaign.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.load = true;

        axios
          .delete(
            urls.apiPutDonationLevel
              .replace(":orgId", this.orgId)
              .replace(":campaignId", this.campaignId)
              .replace(":donationLevelid", level.id)
          )
          .then(() => {
            this.load = false;

            this.$store.dispatch("updateCampaign", extend);

            this.$_notificationsMixin_makeToast(
              "Success",
              this.$t("edit_campaign.success_delete_donation_levels")
            );
          })
          .catch((e) => {
            this.load = false;
            this.renderError(e);
          });
      });
    },

    async updateLevel(level) {
      try {
        await axios.put(
          urls.apiPutDonationLevel
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignId)
            .replace(":donationLevelid", level.id),
          { data: { attributes: level.attributes } }
        );
      } catch (e) {
        this.renderError(e);
      }
    },

    async changeLevelOrder(level, direction) {
      this.load = true;

      const items = structuredClone(this.levelsList);

      // Find the index of the item to adjust
      const index = items.findIndex((item) => Number(item.id) === Number(level.id));
      if (index === -1) {
        throw new Error("Item with the specified ID not found.");
      }

      // Swap the order with the next or previous item based on the direction
      if (direction === "increase" && index < items.length - 1) {
        // Swap with the next item
        let temp = items[index].attributes.order;
        items[index].attributes.order = items[index + 1].attributes.order;
        items[index + 1].attributes.order = temp;
      } else if (direction === "decrease" && index > 0) {
        // Swap with the previous item
        let temp = items[index].attributes.order;
        items[index].attributes.order = items[index - 1].attributes.order;
        items[index - 1].attributes.order = temp;
      }

      try {
        await Promise.allSettled(items.map((level) => this.updateLevel(level)));
        await this.$store.dispatch("updateCampaign", extend);
      } finally {
        this.load = false;
      }
    },

    async normalizeLevelOrder() {
      this.load = true;
      const items = structuredClone(this.levelsList);
      let modifiedObjects = [];

      items.forEach((item, index) => {
        // Calculate the new order value
        let newOrder = items.length - index;

        // Check if the order value is actually going to be modified
        if (item.attributes.order !== newOrder) {
          // Update the order
          item.attributes.order = newOrder;
          // Add the modified object to the modifiedObjects array
          modifiedObjects.push(item);
        }
      });

      try {
        await Promise.allSettled(items.map((level) => this.updateLevel(level)));
        await this.$store.dispatch("updateCampaign", extend);
      } finally {
        this.load = false;
      }
    },

    checkForRepetitiveOrder(arr) {
      const orderValues = new Set();

      for (const item of arr) {
        if (orderValues.has(item.attributes.order)) {
          return true; // Found a repetitive order value
        }
        orderValues.add(item.attributes.order);
      }

      return false; // No repetitive order values found
    },

    renderError(e) {
      if (e.response.data) {
        if (e.response.data.error) {
          this.errors.push(e.response.data.error);
        } else {
          this.errors.push(this.$t("template.error_default"));
        }
      } else {
        this.errors.push(this.$t("template.error_default"));
      }
    },

    orderStyle(level) {
      let order = level.attributes.order;

      if (order === 0) {
        return 0;
      }

      return -order;
    },

    ensureUniqueLevelsOrder() {
      if (this.checkForRepetitiveOrder(this.levelsList)) {
        this.normalizeLevelOrder();
      }
    },

    onSaveLevel() {
      this.ensureUniqueLevelsOrder();
    },
  },

  watch: {
    levels() {
      this.levelsList = this.levels;
    },
  },

  mounted() {
    this.levelsList = this.levels;

    this.getJournalActivated(this.campaignId);
    this.ensureUniqueLevelsOrder();
  },
};
</script>

<style lang="scss" scoped>
.matchers_page {
  padding: 0px;
  &_search {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    position: relative;
    > svg {
      fill: gray;
      position: absolute;
      left: 0;
      top: calc(50% - 12px);
    }
    > input {
      width: 100%;
      border-left: none;
      border-right: none;
      border-top: none;
      border-radius: 0;
      padding-left: 30px;
    }
  }
}
</style>
