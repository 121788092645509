import { campaignSetting } from "@/resources";

export async function getSetting(
  { cid, oid = localStorage.getItem("orgId"), extraParams = {} } = {},
  name
) {
  const params = {
    meta_name: name,
    ...extraParams,
  };

  const { data } = await campaignSetting.get({
    cid: cid,
    oid: oid,
    params,
  });
  const [meta] = data.data;
  return meta;
}

export async function createSetting({ cid, oid = localStorage.getItem("orgId") } = {}, name, data) {
  const { data: data_1 } = await campaignSetting.post({
    cid: cid,
    oid: oid,
    name: name,
    data,
  });
  return data_1.data;
}

export async function updateSetting(
  { cid, oid = localStorage.getItem("orgId") } = {},
  name,
  id,
  data
) {
  const { data: data_1 } = await campaignSetting.put({
    cid: cid,
    oid: oid,
    id: id,
    name: name,
    data,
  });
  return data_1.data;
}
