<template>
  <div class="sign-wrapper">
    <div class="container-fluid">
      <div class="sign">
        <div class="sign__image">
          <a href="/" class="sign__logo">
            <cdn-logo-by-lang class="logo-main" />
          </a>
        </div>

        <div class="sign__mode">
          <p class="sign__helper">
            {{ $t("dashboard.already_have_account", "Already have an account?") }}
          </p>
          <a href="/login" class="bttn bttn--sm bttn--orange">
            {{ $t("template.sign_in", "Sign in") }}
          </a>
        </div>

        <div class="sign__deco">
          <img
            src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/other/signup.jpeg"
          />
        </div>

        <div class="sign-content">
          <h1 v-if="userType != 'D'" class="sign-content__title">
            {{ $t("dashboard.org_admin_dashboard", "organization administration dashboard") }}
          </h1>
          <!--<p class="sign-content__description">-->
          <!--Take full control of your campaigns with charidy's organization dashboard. You can create and edit-->
          <!--your campaign. Follow in real time all the campaign data and traffic. Integrate with companies like-->
          <!--Salesforce. Lorem ipsum ipsum ipsum ipsum ipsum.-->
          <!--</p>-->

          <form class="sign-form">
            <h2 class="sign-form__title">
              {{ $t("dashboard.reset_your_password", "Reset Your Password") }}
            </h2>
            <!--<p class="sign-form__description">Enter your details below</p>-->

            <div
              v-if="resetPasswordSuccess"
              class="signup-message"
              v-html="resetPasswordSuccessMsg()"
            ></div>

            <div class="sign-form__data" v-if="!resetPasswordSuccess">
              <div class="form-group form-unit">
                <label class="form-unit__label form-unit__label--upper-sm">
                  {{ $t("dashboard.new_password", "New password") }}
                </label>

                <password-hint
                  ref="passwordHint"
                  :target="'tooltip-pass'"
                  :password="newPasswordReset"
                ></password-hint>

                <input
                  id="tooltip-pass"
                  @focus="openHint"
                  @blur="closeHint"
                  type="password"
                  min="6"
                  v-model="newPasswordReset"
                  class="form-control form-unit__input"
                />
              </div>

              <div class="form-group form-unit">
                <label class="form-unit__label form-unit__label--upper-sm">
                  {{ $t("dashboard.confirm_new_password", "Confirm new password") }}
                </label>
                <input
                  v-model="newPasswordConfirm"
                  min="6"
                  type="password"
                  class="form-control form-unit__input"
                />
              </div>

              <error :errors="errors"></error>
            </div>

            <div class="sign-form__action" v-if="!resetPasswordSuccess">
              <button
                class="bttn bttn--lg bttn--orange bttn--sign"
                @click.prevent="resetPassword"
                :disabled="loading"
              >
                {{ $t("dashboard.reset_password", "Reset password") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls.js";
import ErrorDisplay from "@/views/Error.vue";
import passwordHint from "../components/password-hint-tooltip";
import { defineComponent } from "vue";
import CdnLogoByLang from "@/common-components/cdn-v2/cdn-logo-by-lang.vue";

export default defineComponent({
  data() {
    return {
      newPasswordReset: "",
      newPasswordConfirm: "",
      resetPasswordSuccess: false,
      errors: [],
      loading: false,
      userType: "",
    };
  },

  components: {
    error: ErrorDisplay,
    passwordHint,
    CdnLogoByLang,
  },

  created() {
    this.userType = this.$route.query.type;
  },

  methods: {
    resetPasswordSuccessMsg() {
      return this.$t(
        "dashboard.reset_password_success_msg",
        `<p>You password was reset successfully. Click <a href="/">here</a> to login <br>or <a href="charidy://charidy.app.open">Open APP</a>.</p>`
      );
    },

    resetPassword: function () {
      this.errors = [];

      if (this.newPasswordReset != this.newPasswordConfirm) {
        this.errors.push(this.$t("dasboard.reset_password_error_invalid", "Passwords don’t match"));
        return false;
      }

      if (this.newPasswordReset.length < 6 || this.newPasswordConfirm.length < 6) {
        this.errors.push(
          this.$t(
            "dashboard.reset_password_error_minlength",
            "Password requires at least 6 symbols"
          )
        );
        return false;
      }

      axios
        .post(urls.resetPasswordToken + this.$router.currentRoute.query.token, {
          password: this.newPasswordReset,
        })
        .then((response) => {
          if (response.data.Result == "ok") {
            this.resetPasswordSuccess = true;
          }
        })
        .catch((e) => {
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },
    openHint: function () {
      this.$refs.passwordHint.openHint();
    },

    closeHint: function () {
      this.$refs.passwordHint.closeHint();
    },
  },
});
</script>

<style lang="scss" scoped>
.el-left {
  margin-right: auto;
}
.el-right {
  margin-left: auto;
}
.sign-content__title {
  margin-bottom: 60px;
}
.signup-message {
  width: 100%;
  height: 80px;
  padding: 0 15px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #414359;
  text-align: center;
  border: 1px solid #88bacc;
  border-radius: 4px;
  background-color: #eaf4ff;
  > p {
    margin: 0;
  }
}
</style>
