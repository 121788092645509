<template>
  <div class="action-block">
    <button
      v-if="!createYearRoundCampaign"
      class="bttn bttn--sm bttn--orange"
      :disabled="!canCreateCampaign"
      @click="openModal"
    >
      {{ $t("add_campaign.create_new_campaign_button") }}
    </button>
    <b-dropdown
      v-if="createYearRoundCampaign"
      variant="-sm bttn--orange"
      :disabled="!canCreateCampaign"
      :text="$t('add_campaign.create_new_campaign_button')"
    >
      <b-dropdown-item @click="openModal">
        {{ $t("add_campaign.create_new_standard_campaign") }}
      </b-dropdown-item>
      <b-dropdown-item @click="openModal('year_round')">
        {{ $t("add_campaign.create_new_year_round_campaign") }}
      </b-dropdown-item>
    </b-dropdown>

    <ValidationObserver ref="observer" v-slot="{ invalid, validate, errors: observerErrors }" slim>
      <b-modal id="modalPrevent" ref="modal" size="xl" centered>
        <div slot="modal-header">
          <div class="modal-header__hide" @click="hideModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
              <path d="M0 0h24v24H0z" fill="none" />
            </svg>
          </div>
          <h2 class="h1-like">
            {{ $t("add_campaign.add_new_campaign") }}
          </h2>
          <div class="modal-header__description">
            {{ $t("add_campaign.change_anytime") }}
          </div>
        </div>
        <div slot="modal-footer">
          <a href="#" class="bttn bttn--lg bttn--blue" @click="hideModal">{{
            $t("add_campaign.close")
          }}</a>
          <button
            form="campaign-add"
            :disabled="invalid || sending"
            class="bttn bttn--lg bttn--orange"
            type="submit"
          >
            {{ $t("add_campaign.create_new_campaign") }}
          </button>
        </div>
        <form id="campaign-add" @submit.stop.prevent="validate().then(() => handleSubmit())">
          <error
            v-if="$options.filterErrors(errors, observerErrors)"
            :errors="$options.filterErrors(errors, observerErrors)"
          />
          <div class="row align-items-end">
            <div class="col-12 col-md-6">
              <div class="form-group form-unit form-unit--addon goal-input">
                <label class="form-unit__label">
                  {{ $t("add_campaign.goal_in_mind") }}
                </label>
                <div class="form-unit__addon-wrap">
                  <b-dropdown id="ddown1" :text="newCampaign.campaignCurrency" class="dropdown">
                    <b-dropdown-item
                      v-for="(ccy, i) in currencies"
                      :key="`ccy${i}`"
                      :data-ccy="ccy"
                      @click="updateCurrency"
                      >{{ ccy }}</b-dropdown-item
                    >
                  </b-dropdown>
                  <input
                    type="text"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    min="0"
                    required
                    class="form-control form-unit__input"
                    v-model.number="newCampaign.goalAmount"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <ValidationProvider rules="no_whitespace" v-slot="{}">
                <div class="form-group form-unit">
                  <label class="form-unit__label">
                    {{ $t("add_campaign.title") }}
                  </label>
                  <input
                    type="text"
                    class="form-control form-unit__input"
                    v-model="newCampaign.campaignTitle"
                    required
                  />
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row align-items-end">
            <div class="col-12 col-md-6">
              <div class="form-group form-unit form-unit--addon date-time-select">
                <label class="form-unit__label">{{ $t("add_campaign.date_in_mind") }}</label>
                <div class="form-unit__addon-wrap">
                  <div class="form-unit__addon-icon">
                    <svg viewBox="0 0 24 22">
                      <g fill="none" fill-rule="evenodd" opacity="1">
                        <path
                          stroke="#107598"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4.64 1v3.64M19.18 1v3.64M1 2.82V21h14.55l7.27-7.27V2.82zM21.91 15.09v5h-5"
                        />
                        <path
                          fill="#107598"
                          fill-rule="nonzero"
                          d="M1 2.82V21h14.55l7.27-7.27V2.82H1zm6.36 14.54H5.55v-1.81h1.81v1.81zm0-3.63H5.55V11.9h1.81v1.82zm0-3.64H5.55V8.27h1.81v1.82zM11 17.36H9.18v-1.81H11v1.81zm0-3.63H9.18V11.9H11v1.82zm0-3.64H9.18V8.27H11v1.82zm3.64 7.27h-1.82v-1.81h1.82v1.81zm0-3.63h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82zm3.63 3.64h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82z"
                        />
                      </g>
                    </svg>
                  </div>
                  <datetime
                    input-id="start-date"
                    type="datetime"
                    value-zone="UTC"
                    :zone="timeZone"
                    v-model="newCampaign.startDate"
                    input-class="form-control form-unit__input"
                    title="Select start date"
                  ></datetime>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group form-unit form-unit--addon date-time-select">
                <label class="form-unit__label">{{ $t("add_campaign.end_date") }}</label>
                <div class="form-unit__addon-wrap">
                  <div class="form-unit__addon-icon">
                    <svg viewBox="0 0 24 22">
                      <g fill="none" fill-rule="evenodd" opacity="1">
                        <path
                          stroke="#107598"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M4.64 1v3.64M19.18 1v3.64M1 2.82V21h14.55l7.27-7.27V2.82zM21.91 15.09v5h-5"
                        />
                        <path
                          fill="#107598"
                          fill-rule="nonzero"
                          d="M1 2.82V21h14.55l7.27-7.27V2.82H1zm6.36 14.54H5.55v-1.81h1.81v1.81zm0-3.63H5.55V11.9h1.81v1.82zm0-3.64H5.55V8.27h1.81v1.82zM11 17.36H9.18v-1.81H11v1.81zm0-3.63H9.18V11.9H11v1.82zm0-3.64H9.18V8.27H11v1.82zm3.64 7.27h-1.82v-1.81h1.82v1.81zm0-3.63h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82zm3.63 3.64h-1.82V11.9h1.82v1.82zm0-3.64h-1.82V8.27h1.82v1.82z"
                        />
                      </g>
                    </svg>
                  </div>
                  <datetime
                    input-id="end-date"
                    type="datetime"
                    v-model="newCampaign.endDate"
                    value-zone="UTC"
                    :zone="timeZone"
                    input-class="form-control form-unit__input"
                    :placeholder="$t('edit_campaign.generalinfo_end_date')"
                  ></datetime>
                </div>
              </div>
            </div>
          </div>
        </form>
      </b-modal>
    </ValidationObserver>
    <campaign-express-add-modal ref="expressAddModal" />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import axios from "axios";
import urls from "@/constants/urls.js";
import campaignExpressAddModal from "./campaign-express-add.vue";
import error from "@/components/errors.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { filterErrors } from "@/helpers/filters";

export default {
  filterErrors,

  data() {
    return {
      newCampaign: {
        campaignCurrency: "USD",
        goalAmount: "",
        startDate: "",
        endDate: "",
        campaignTitle: "",
      },
      errors: [],
      validation: [],
      sending: false,
    };
  },

  components: {
    error: error,
    campaignExpressAddModal,
    ValidationObserver,
    ValidationProvider,
  },

  created() {
    this.newCampaign.startDate = moment()
      .tz(this.timeZone)
      .startOf("hour")
      .add(1, "d")
      .toISOString();
    this.newCampaign.endDate = moment().tz(this.timeZone).startOf("hour").add(2, "d").toISOString();
  },

  computed: {
    ...mapState({
      orgId: () => localStorage.getItem("orgId"),
      canCreateCampaign: (state) => state.account.access.CanCreateCampaign,
      createYearRoundCampaign: (state) => state.account.createYearRoundCampaign,
      timeZone: (state) => state.account.account.data.attributes.timezone,
      currencies: (state) =>
        state.account.currencyList?.map((el) => el.attributes.code.toLowerCase()) || [],
    }),

    ...mapGetters(["getOrgTimezone"]),

    accTimezone() {
      return this.getOrgTimezone;
    },
  },
  methods: {
    updateCurrency(e) {
      this.newCampaign.campaignCurrency = e.target.dataset.ccy;
    },

    handleSubmit() {
      this.errors = [];
      let dates = {};

      let startDate = new Date(this.newCampaign.startDate);
      let thisDate = new Date();

      if (startDate < thisDate) {
        this.errors.push(`add_campaign.start_date_fail`);
        return false;
      }

      if (this.newCampaign.endDate) {
        let endaDate = new Date(this.newCampaign.endDate);
        if (startDate > endaDate) {
          this.errors.push(`add_campaign.end_date_fail`);
          return false;
        }
      }

      dates = {
        start_date: new Date(this.newCampaign.startDate).getTime() / 1000,
        end_date: new Date(this.newCampaign.endDate).getTime() / 1000,
      };

      this.sending = true;

      let campaignData = {
        data: {
          attributes: {
            currency: this.newCampaign.campaignCurrency,
            ...dates,
            title: this.newCampaign.campaignTitle,
            short_link: this.newCampaign.short_link,
            primary_goal: this.newCampaign.goalAmount,
            bonus_goal: 0,
          },
        },
      };
      axios
        .post(urls.getCampaignList.replace(":orgId", this.orgId), campaignData)
        .then(({ data }) => {
          this.sending = false;
          const cId = data?.data?.id;
          if (cId) {
            this.$router.push({ path: `/campaign/${cId}` });
          } else {
            const extend = [
              "campaign_stats",
              "media",
              "meta",
              "donation_levels",
              "donation_streams",
            ];
            const params = {
              page: 1,
              perPage: 10,
              mode: [0, 1, 2, 5],
              extend,
            };

            const payload = {
              type: "current",
              params,
            };

            this.$store.dispatch("getCampaigns", payload);
            this.hideModal();
          }
        })
        .catch((e) => {
          this.sending = false;
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },

    openModal(type) {
      if (type === "year_round") {
        this.$store.dispatch("getGateways");
        this.$refs.expressAddModal.openModal();
      } else {
        this.$root.$emit("bv::show::modal", "modalPrevent");
      }
    },

    hideModal() {
      this.$refs.modal.hide();
    },
  },
};
</script>

<style lang="scss">
.goal-input {
  .dropdown {
    display: flex;
    &.open {
      .btn {
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
      }
    }
    .btn {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top: 1px solid #e6eaed !important;
      border-bottom: 1px solid #e6eaed !important;
      border-left: 1px solid #e6eaed !important;
      background-color: #ffffff;
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      position: relative;
      color: #20434e;
      &:active,
      &:focus,
      &:hover {
        color: #20434e;
        outline: 0;
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
        box-shadow: none;
      }
      span {
        position: absolute;
        top: 22px;
        right: 5px;
      }
    }
    .dropdown-toggle {
      min-width: 65px;
      font-size: 15px;
      position: relative;
      &:after {
        position: absolute;
        right: 6px;
        top: calc(50% - 1px);
      }
    }
    &-menu {
      min-width: initial;
      padding: 0;
      width: 100%;
      border-radius: 0;
    }
    &-item {
      height: 45px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: gray;
      text-transform: uppercase;
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(32, 67, 78, 0.15);
      }
    }
  }
}

.description-set {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
  h3 {
    margin: 0 0 20px;
    padding: 0;
    font-family: "ProximaNova-Bold", sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #20434e;
  }
  &__unit {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (min-width: 992px) {
      width: 46%;
    }
    textarea {
      width: 100%;
      resize: none;
    }
  }
  &__unit:not(:last-of-type) {
    margin-bottom: 20px;
    @media only screen and (min-width: 992px) {
      margin-bottom: 0;
    }
  }
}

#campaign-add {
  .dropdown-toggle {
    text-transform: uppercase; // !important;
  }
  .dropdown-item {
    text-transform: uppercase;
  }

  .date-time-select {
    .form-unit__addon-icon {
      display: none;
      @media only screen and (min-width: 992px) {
        display: flex;
      }
    }
    .form-unit__input {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      @media only screen and (min-width: 992px) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.alert-danger li {
  width: 100%;
  margin-left: 0;
}

.vdatetime {
  width: 100%;
}

.vdatetime-popup {
  backface-visibility: hidden;
  transform: translate3d(-50%, calc(-50% - 0.5px), 0px);
  &__header {
    background-color: $bb-100;
  }
}

.vdatetime-calendar {
  &__month__day {
    &--selected {
      &:hover {
        > span > span {
          background-color: $bb-80;
        }
      }
      > span > span {
        background-color: $bb-100;
      }
    }
  }
}

.vdatetime-year-picker__item {
  &--selected {
    color: $bb-100;
  }
}

.vdatetime-time-picker__item {
  &--selected {
    color: $bb-100;
  }
}

.language-selected__unit {
  &.add-modal {
    padding: 0px 10px 0 20px;
  }
}
</style>
