<template>
  <div class="donation-unit donation-unit--moderation">
    <div class="donation-unit__cell donation-unit__id">{{ donation.id }}</div>

    <div class="donation-unit__cell donation-unit__dname">
      <div class="donation-unit__label">{{ $t("dashboard.donation_display_name") }}</div>
      {{ donation.attributes.display_name }}
    </div>

    <div class="donation-unit__cell donation-unit__dedication">
      <div class="donation-unit__label">{{ $t("dashboard.donation_dedication") }}</div>
      {{ donation.attributes.dedication }}
    </div>

    <div class="donation-unit__action">
      <button
        class="bttn-round bttn-round--auto"
        type="button"
        @click="inlineUpdateStatusDonate"
        :class="{ disabled: statusChanged }"
        :disabled="statusChanged"
        v-if="!statusChanged"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
        </svg>
        {{ $t("donors.donation_approve") }}
      </button>

      <p class="approved-text" v-else>{{ $t("donors.donation_approved") }}</p>

      <button class="bttn-round" type="button" @click="editDonate">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
// import { mapState } from "vuex";

import axios from "axios";

import urls from "@/constants/urls.js";
import { deepCopy } from "@/helpers/calendarModule";
import { notificationsMixin } from "@/mixins";

export default {
  props: ["donation", "premoderation", "campaignid"],

  mixins: [notificationsMixin],

  data: function () {
    return {
      statusChanged: false,
    };
  },

  methods: {
    editDonate: function () {
      this.$emit("edit-donate", this.donation, this.premoderation);
    },

    inlineUpdateStatusDonate() {
      const message = this.$t("donors.donation_approved_confirm_msg");

      const options = {
        okText: this.$t("donors.donation_approved"),
        cancelText: this.$t("donors.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.sending = true;
        this.errors = [];

        const data = deepCopy(this.donation.attributes);
        data.moderation_status = 1;
        const model = { data };
        let orgId = localStorage.getItem("orgId");
        axios
          .patch(
            urls.putDonation.replace(":orgId", orgId).replace(":campaignId", this.campaignid) +
              "/" +
              this.donation.id,
            model
          )
          .then(() => {
            this.statusChanged = true;

            // let params = {
            //   id: this.campaignid,
            //   setting: `?page=${this.currentpage}&limit=50`
            // };
            //
            // this.$store.dispatch("getCampaignDonation", params);
          })
          .catch((e) => {
            this.sending = false;
            if (e.response.data) {
              if (e.response.data.error) {
                this.errors.push(e.response.data.error);
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
            this.$_notificationsMixin_handleCatch(e);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.approved-text {
  margin: 0;
  margin-right: 10px;
  font-weight: bold;
}
.donation-unit--moderation {
  .donation-unit__id {
    width: 100%;
    @media only screen and (min-width: 1024px) {
      width: 10%;
    }
  }

  .donation-unit__dname {
    display: flex;
    width: 100%;
    order: 2;
    position: relative;
    font-size: 16px;
    @media only screen and (min-width: 568px) {
      width: 50%;
    }
    @media only screen and (min-width: 768px) {
      width: 40%;
    }
    @media only screen and (min-width: 1024px) {
      width: 20%;
      min-width: 20%;
      order: initial;
      font-size: 14px;
    }
    .donation-unit__label {
      font-size: 14px;
      position: absolute;
      top: 2px;
      left: 5px;
    }
  }

  .donation-unit__dedication {
    width: 100%;
    order: 3;
    font-size: 16px;
    @media only screen and (min-width: 568px) {
      width: 50%;
    }
    @media only screen and (min-width: 768px) {
      width: 60%;
    }
    @media only screen and (min-width: 1024px) {
      width: 40%;
      order: initial;
      font-size: 14px;
    }
    .donation-unit__label {
      font-size: 14px;
      position: absolute;
      top: 2px;
      left: 5px;
    }
  }

  .donation-unit__action {
    @media only screen and (max-width: 1023px) {
      padding-top: 10px;
      width: 100%;
      border-left: none;
    }
  }

  .bttn-round.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  .bttn-round:not(:last-of-type) {
    margin-right: 10px;
    @media only screen and (min-width: 1024px) {
      margin-bottom: 0;
    }
  }
}
</style>
