<template>
  <b-tooltip ref="tooltip" :target="target" triggers="focus">
    <div class="hint-box">
      <span class="pass-hint">
        (<i v-if="lettersCounter">+</i><i v-else>&nbsp;&nbsp;</i>)
        {{ $t("template.8_symbols", "(+) minimum length 8 characters") }}</span
      ><br />
      <span class="pass-hint">
        (<i v-if="specialSymbol">+</i><i v-else>&nbsp;&nbsp;</i>)
        {{ $t("template.special_symbols", "(+) at least 1 special symbol") }}</span
      ><br />
      <span class="pass-hint">
        (<i v-if="oneNumber">+</i><i v-else>&nbsp;&nbsp;</i>)
        {{ $t("template.one_number", "(+) at least 1 number") }}</span
      ><br />
    </div>
  </b-tooltip>
</template>

<script>
export default {
  props: ["target", "password"],
  data() {
    return {
      lettersCounter: false,
      lettersCapital: false,
      specialSymbol: false,
      oneNumber: false,
    };
  },

  watch: {
    password: function () {
      this.checkPassord();
    },
  },

  methods: {
    openHint() {
      this.checkPassord();
      this.$refs.tooltip.$emit("open");
    },

    closeHint: function () {
      this.$refs.tooltip.$emit("close");
    },

    checkPassord: function () {
      if (this.password || this.password === "") {
        //this.lettersCounter = this.registerData.password.match(/(.*[A-Za-z]{1}){8,}.*/);
        this.lettersCounter = this.password.match(/.{8,}/);
        this.lettersCapital = this.password.match(/(.*[A-Z]{1}).*/);
        this.specialSymbol = this.password.match(/(.*[@!#$%^&+=]{1}).*/);
        this.oneNumber = this.password.match(/(.*[0-9]{1}).*/);
      }
    },
  },
};
</script>

<style scoped>
.hint-box {
  width: 100%;
  text-align: left;
}

.pass-hint {
  width: 100%;
}
</style>
