<template>
  <div class="matcher-card__img">
    <div class="matcher-img" :class="{ campaign: cmp }">
      <label v-if="!value" @click="changeImage()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
          />
        </svg>
        {{ label || $t("dashboard.media_storage_upload_img", "Upload image") }}
      </label>

      <div v-if="value" class="matcher-img__loaded">
        <div
          :class="{
            'image-wrapper-cmp': cmp,
            'image-wrapper': !cmp,
          }"
        >
          <img :src="filePreviewUrl" alt />
        </div>
        <div class="matcher-img__action matcher-img__delete" @click="deleteImage()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
        <label class="matcher-img__action matcher-img__change" @click="changeImage()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"
            />
          </svg>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: {
      default: "",
    },
    type: {
      default: "",
    },
  },

  data() {
    return {
      file: "",
    };
  },

  computed: {
    filePreviewUrl() {
      if (typeof this.value === "object" && this.file) {
        return URL.createObjectURL(this.file);
      }
      return this.value;
    },
    cmp() {
      return this.type === "campaign";
    },
  },

  watch: {
    file(val) {
      this.$emit("input", val);
    },
  },

  methods: {
    changeImage() {
      let fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file");
      fileInput.setAttribute("accept", "image/png, image/gif, image/jpeg, image/bmp, image/x-icon");
      fileInput.classList.add("sending-image-input");

      fileInput.addEventListener("change", () => {
        if (fileInput.files && fileInput.files[0]) {
          this.file = fileInput.files[0];
        } else {
          this.file = "";
        }
      });

      document.querySelector("body").appendChild(fileInput);

      fileInput.click();
      fileInput.remove();
    },

    deleteImage() {
      URL.revokeObjectURL(this.filePreviewUrl);
      this.file = "";
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.campaign {
  border-radius: 0;
  width: 426px;
  height: 240px;
}

.image-wrapper-cmp {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
</style>
