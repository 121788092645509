<template>
  <b-card-group class="shadow-sm">
    <b-card>
      <b-card-text>
        <div class="text-center">
          <p class="m-1 c-text-secondary text-size-highlited text-uppercase font-weight-bold">
            {{ slotStats.donationAmount }} {{ currency }}
          </p>
          <p
            class="p-0 m-0 text-completed text-uppercase font-weight-bold text-size-hint text-black-50"
          >
            {{
              `${$t("donor_dashboard.of", "of")} ${Math.ceil(goal)} ${currency} ${$t(
                "donor_dashboard.raised",
                "raised"
              )}`
            }}
          </p>
        </div>
      </b-card-text>
      <template #footer>
        <div class="text-center">
          <small>
            {{
              $t("donor_dashboard.amount_rank", "Rank: {rank}", {
                rank: slotStats.donationAmountRank,
              })
            }}
          </small>
        </div>
      </template>
    </b-card>
    <b-card>
      <b-card-text>
        <div class="text-center">
          <p class="m-1 c-text-secondary text-size-highlited text-uppercase font-weight-bold">
            {{ Math.round(slotStats.progress) }}%
          </p>
          <p
            class="p-0 m-0 text-completed text-uppercase font-weight-bold text-size-hint text-black-50"
          >
            Completed
          </p>
        </div>
      </b-card-text>
      <template #footer>
        <div class="text-center">
          <small>
            {{
              $t("donor_dashboard.amount_rank", "Rank: {rank}", {
                rank: slotStats.donationAmountRank,
              })
            }}
          </small>
        </div>
      </template>
    </b-card>
    <b-card>
      <b-card-text>
        <div class="text-center">
          <p class="m-1 c-text-secondary text-size-highlited text-uppercase font-weight-bold">
            {{ slotStats.donorsCount }}
          </p>
          <p class="p-0 m-0 text-completed font-weight-bold text-size-hint text-black-50">
            {{ $t("donor_dashboard.count", "Number of Donors") }}
          </p>
        </div>
      </b-card-text>
      <template #footer>
        <div class="text-center">
          <small>
            {{
              $t("donor_dashboard.donors_rank", "Rank: {rank}", { rank: slotStats.donorsCountRank })
            }}
          </small>
        </div>
      </template>
    </b-card>
  </b-card-group>
</template>

<script>
import {
  teamStatsNotEmpty,
  donationAmount,
  donationAmountRank,
  donorsCount,
  donorsCountRank,
  progress,
} from "@/helpers/team-stats";

export default {
  props: {
    goal: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    teamStats: {
      type: Object,
      required: true,
    },
  },
  computed: {
    slotStats() {
      return {
        teamStatsNotEmpty: teamStatsNotEmpty(this.teamStats),
        donationAmount: donationAmount(this.teamStats),
        donationAmountRank: donationAmountRank(this.teamStats),
        donorsCount: donorsCount(this.teamStats),
        donorsCountRank: donorsCountRank(this.teamStats),
        progress: progress(this.teamStats, this.goal),
      };
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.text-size-highlited {
  font-size: 22px;
}
.text-size-hint {
  font-size: 14px;
}
</style>
