<template>
  <b-container class="m-0 p-0 mb-2" fluid>
    <b-row class="align-items-center mb-3">
      <b-col cols="8">
        {{ $t(`teams.extra_${type}_label`, `Additional ${type}s`) }}
      </b-col>
      <b-col class="d-flex justify-content-end" cols="4">
        <button type="button" class="bttn bttn--sm bttn--orange" @click="add" :disabled="disabled">
          {{ $t("teams.add_extra_data", "Add") }}
        </button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="inputs">
        <b-input-group v-for="(v, i) in value" :key="i">
          <b-form-input
            :value="v"
            @input="raw($event, i)"
            :type="type === 'phone' ? 'tel' : type"
            form="team-add"
            :disabled="disabled"
          />
          <template #append>
            <b-button @click="remove(i)" variant="outlined-secondary" class="bttn--inverted-blue">
              <b-icon icon="trash-fill" />
            </b-button>
          </template>
        </b-input-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { cloneDeep } from "@/helpers/cloneDeep";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
    disabled: Boolean,
  },
  methods: {
    raw(e, i) {
      this.$set(this.value, i, e);
      this.$emit("input", this.value);
    },
    add() {
      const copy = cloneDeep(this.value || []);
      copy.push("");
      this.$emit("input", copy);
    },
    remove(i) {
      const copy = cloneDeep(this.value);
      copy.splice(i, 1);
      this.$emit("input", copy);
    },
  },
};
</script>

<style lang="scss" scoped>
.inputs {
  display: flex;
  flex-flow: column;
  gap: 10px;
}
</style>
