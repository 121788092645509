<template>
  <div>
    <slot :meta="metaByProp" />
    <slot v-if="Boolean(metaByProp.version)" v-bind="metaByProp" :name="metaByProp.version" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    orgSetting: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    metaByProp() {
      return this.getOrgSetting(this.account, this.orgSetting);
    },
  },
  methods: {
    getOrgSetting(account, name) {
      const setting = account[name] || {};
      if (typeof setting === "boolean") {
        return {
          value: setting,
        };
      }

      return {
        ...setting,
        value: setting?.value || false,
        version: setting?.value_int ? `v${setting.value_int}` : `v${1}`,
        meta: setting.value_extend,
      };
    },
  },
};
</script>
