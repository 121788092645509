<template>
  <div>
    <div class="dashboard-content-wrapper">
      <div class="dashboard-block dashboard-donations">
        <div class="dashboard-donations__header">
          <h2 class="dashboard-block__title">
            {{ $t("dashboard.title_leads") }}
          </h2>
          <div class="btn-wrapper">
            <b-dropdown
              variant="-sm bttn--orange"
              :disabled="
                category === 'givingday' || (mode !== 1 && mode !== 2) || !hasAccessImportDonation
              "
              :text="$t('dashboard.lead_import_donation_button_title')"
              right
            >
              <b-dropdown-item @click="openModalImport">
                {{ $t("dashboard.import_button_title") }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <button
            class="bttn bttn--sm bttn--orange ml-2"
            :disabled="exportInProgress"
            @click="exportLeads"
          >
            {{ exportInProgress ? `${progress}%` : $t("dashboard.lead_export") }}
          </button>
        </div>

        <dashboard-import-donors
          ref="importList"
          type="pledge"
          :campaignid="campaignId"
          @get-donors-list="getLeads"
        />

        <div class="dashboard-donations__filters">
          <!-- search -->
          <div class="filter-block dashboard-donation__search" v-if="canSeeInputSearch">
            <div class="search">
              <svg viewBox="0 0 17 17">
                <g fill="#107598" fill-rule="nonzero">
                  <path
                    d="M11.22 3.29c.2.2.38.41.55.64l-.12-.16c.33.44.61.93.83 1.44l-.08-.19a6.76 6.76 0 0 1 .42 1.48 6.92 6.92 0 0 1 .03 1.52 6.76 6.76 0 0 1-.45 1.69l.08-.2c-.22.52-.5 1-.83 1.45l.12-.16c-.34.45-.74.85-1.18 1.2l.16-.12c-.43.34-.91.62-1.42.84l.2-.08a6.41 6.41 0 0 1-1.45.43 6.52 6.52 0 0 1-1.5.03 6.41 6.41 0 0 1-1.65-.46l.19.08c-.5-.22-.98-.5-1.42-.84l.16.12a6.67 6.67 0 0 1-1.18-1.2l.12.16a6.78 6.78 0 0 1-.83-1.45l.08.2a6.76 6.76 0 0 1-.42-1.48 6.92 6.92 0 0 1-.03-1.52c.08-.58.23-1.15.45-1.69l-.08.2c.22-.52.5-1 .83-1.45l-.12.16c.34-.45.74-.86 1.18-1.2l-.16.12c.44-.34.91-.62 1.42-.84l-.2.08a6.41 6.41 0 0 1 1.45-.43 6.52 6.52 0 0 1 1.5-.03c.57.08 1.12.23 1.65.46L9.33 2a6.43 6.43 0 0 1 1.89 1.28c.3.3.83.32 1.11 0a.83.83 0 0 0 0-1.14A7.13 7.13 0 0 0 .92 3.77a7.46 7.46 0 0 0 .85 8.43 7.16 7.16 0 0 0 9.02 1.58 7.53 7.53 0 0 0 2.48-2.38 7.46 7.46 0 0 0-.94-9.25c-.29-.3-.82-.32-1.11 0a.83.83 0 0 0 0 1.14z"
                  />
                  <path
                    d="M11.28 12.42l1.47 1.47 2.34 2.34.54.54c.3.3.85.32 1.14 0a.82.82 0 0 0 0-1.14l-1.47-1.47-2.34-2.34-.54-.54c-.3-.3-.85-.32-1.14 0a.82.82 0 0 0 0 1.14z"
                  />
                </g>
              </svg>
              <input
                v-model="searchQ"
                class="search__input"
                type="text"
                :placeholder="$t('dashboard.select_filter_placeholder_input')"
                value=""
              />
            </div>
          </div>

          <!-- statuses -->
          <div class="filter-block dashboard-donation__type">
            <div class="select">
              <svg viewBox="0 0 12 6">
                <path
                  fill="#A0A1AC"
                  fill-rule="nonzero"
                  d="M.28.28a.6.6 0 0 0 .02.86l5.25 4.6c.26.22.64.22.88 0l5.25-4.6c.26-.22.28-.6.02-.84a.65.65 0 0 0-.9-.02L6 4.48 1.18.26a.67.67 0 0 0-.9.02z"
                />
              </svg>
              <select class="select__select" v-model="status">
                <option value="">
                  {{ $t("dashboard.lead_status_filter_all") }}
                </option>
                <option value="pending">
                  {{ $t("dashboard.lead_status_filter_pending") }}
                </option>
                <option value="processed_donated">
                  {{ $t("dashboard.lead_status_filter_processed_donated") }}
                </option>
                <option value="processed_not_available">
                  {{ $t("dashboard.lead_status_filter_processed_not_available") }}
                </option>
                <option value="processed_refused">
                  {{ $t("dashboard.lead_status_filter_processed_refused") }}
                </option>
                <option value="callback">
                  {{ $t("dashboard.lead_status_filter_callback") }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="dashboard-donations__th">
          <div class="th-item">ID</div>
          <div class="th-item th-item--name">
            {{ $t("dashboard.lead_name") }}
          </div>
          <!-- <div class="th-item th-item--name">
            {{ $t('dashboard.lead_last_name') }}
          </div> -->
          <div class="th-item">
            {{ $t("dashboard.lead_amount") }}
          </div>
          <div class="th-item th-item--15">
            {{ $t("dashboard.lead_email") }}
          </div>
          <div class="th-item th-item--15">
            {{ $t("dashboard.lead_phone") }}
          </div>
          <div class="th-item th-item--name">
            {{ $t("dashboard.lead_status") }}
          </div>
        </div>
      </div>

      <div v-if="!loading">
        <div>
          <OrgDonorMessagingModal #default="{ open: openEmailMsgModal }">
            <lead-unit
              v-for="lead in leads"
              :key="lead.id"
              :lead="lead"
              @update-list="getLeads"
              @open-modal-checkout="openModalCheckout"
              @send-email="({ lead }) => openEmailMsgModal({ donor: lead, delivery: 'email' })"
              @send-sms="({ lead }) => openEmailMsgModal({ donor: lead, delivery: 'sms' })"
            />
          </OrgDonorMessagingModal>

          <div v-if="leadsCount === 0" class="empty">
            <p>
              {{ $t("dashboard.leads_empty") }}
            </p>
          </div>
          <div v-if="leadsCount >= perPage" class="pagination">
            <b-pagination v-model="page" :total-rows="leadsCount" :per-page="perPage" size="md">
            </b-pagination>
          </div>
        </div>
      </div>

      <dashboard-lead-checkout-modal ref="leadCheckoutModal" />

      <div v-if="loading">
        <div class="loadings">
          <LdsSpinner />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import debounce from "lodash/debounce";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";
import leadUnit from "./dashboardLeadsUnit.vue";
import dashboardImportDonors from "./dashboardImportDonors.vue";
import dashboardLeadCheckoutModal from "./dashboardLeadCheckoutModal.vue";
import OrgDonorMessagingModal from "./org-donor-messaging/MsgModal.vue";

export default {
  components: {
    leadUnit,
    dashboardImportDonors,
    dashboardLeadCheckoutModal,
    LdsSpinner,
    OrgDonorMessagingModal,
  },

  data() {
    return {
      page: 1,
      perPage: 50,
      searchQ: "",
      status: "",
      exportInProgress: false,
    };
  },

  directives: {
    debounce,
  },

  computed: {
    ...mapState({
      campaignId: (state) => state.dashboard.selectedCampaign,
      selectedCmp: (state) => state.dashboard.campaignDashboard,
      canSeeInputSearch: (state) => state.account.access.CanPerformDonationSearch,
      leads: (state) => state.leads.list,
      loading: (state) => state.leads.loading,
      leadsCount: (state) => state.leads.count,
      progress: (state) => state.leads.progress,
      hasAccessImportDonation: (state) => {
        return state.account.access.CanImportOfflineCampaignDonation;
      },
    }),

    mode() {
      return this.selectedCmp.data.attributes.mode;
    },
    category() {
      return this.selectedCmp.data.attributes.category;
    },
  },

  watch: {
    campaignId() {
      this.page = 1;
      this.getLeads();
    },
    page() {
      this.getLeads();
    },
    status() {
      this.page = 1;
      this.getLeads();
    },
    searchQ: debounce(function () {
      this.page = 1;
      this.getLeads();
    }, 500),
  },

  methods: {
    ...mapActions({
      fetchExportLeads: "fetchExportLeads",
    }),

    exportLeads() {
      this.exportInProgress = true;
      this.fetchExportLeads([this.campaignId]).then(({ file }) => {
        this.exportInProgress = false;

        const a = document.createElement("a");
        a.href = file;
        a.download = file.substr(file.lastIndexOf("/") + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },

    getLeads() {
      const { page, perPage, status, searchQ } = this;
      const params = {
        page,
        limit: perPage,
        q: searchQ ? searchQ : undefined,
        lead_status: status ? status : undefined,
      };
      this.$store.dispatch("fetchLeads", [this.campaignId, params]);
    },

    openModalImport() {
      this.$refs.importList.openModal();
    },

    openModalCheckout(data) {
      this.$refs.leadCheckoutModal.open(data);
    },
  },

  created() {
    this.getLeads();
  },
};
</script>

<style lang="scss" scoped>
#import {
  @media only screen and (max-width: 1169px) {
    display: none;
  }
  ::v-deep .btn.disabled {
    opacity: initial;
  }
}
::v-deep .multiselect__tags {
  height: 48px;
  width: 100%;
  padding-left: 45px;
  border: 1px solid #88bacc;
  border-radius: 24px;
  padding: 0;
  padding-left: 30px;
}
::v-deep .multiselect__single,
::v-deep .multiselect__placeholder {
  margin: 0;
  line-height: 48px;
  background: #0000;
}
::v-deep .multiselect__select {
  height: 48px;
}
::v-deep .multiselect__input,
::v-deep .multiselect__single,
::v-deep .multiselect__placeholder {
  height: 48px;
  margin: 0;
  line-height: 48px;
  background: transparent;
  color: initial;
  padding: 0;
  font-family: inherit;
  font-size: 16px;
}
.donation-loading {
  display: flex;
  min-height: 100px;
  align-items: center;
  justify-content: center;
}
/*.btn-item {*/
/*margin-left: 10px;*/
/*}*/
.pagination {
  margin-top: 20px;
}

.clickable {
  cursor: pointer;
  i {
    padding-left: 3px;
  }
}

.pdf-button-donations {
  background-color: #fff;
  padding-bottom: 20px;
  a {
    display: block;
    width: 100%;
    padding: 10px 25px;
    background-color: #b4c5cd52;
    color: #127598;
    font-family: "VitoExtended-Bold";
  }
}
</style>
