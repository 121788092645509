<template>
  <div>
    <div class="dashboard-content-wrapper">
      <div class="dashboard-block dashboard-donations">
        <div class="dashboard-donations__header">
          <h2 class="dashboard-block__title">
            {{ $t("dashboard.tickets_pages_tab_title") }}
          </h2>
          <div class="btn-wrapper">
            <button class="bttn bttn--sm bttn--orange" @click="openAddModal">
              {{ $t("dashboard.tickets_add_btn") }}
            </button>
          </div>
        </div>

        <div class="dashboard-donations__filters">
          <b-form-checkbox
            id="tickets-pending-checkbox"
            name="tickets-pending-checkbox"
            :value="1"
            :unchecked-value="0"
            v-model="includeNotLinkedToDonationId"
            @change="getTickets"
            :disabled="loading"
          >
            {{ $t("dashboard.tickets_filter_not_linked_to_donation", "Show pending tickets") }}
          </b-form-checkbox>
        </div>

        <div class="dashboard-donations__th d-flex">
          <div class="th-item">ID</div>
          <div class="th-item">Donation ID</div>
          <div class="th-item">
            {{ $t("dashboard.tickets_promo_code", "Promo code") }}
          </div>
          <div class="th-item w-100">
            {{ $t("dashboard.tickets_title_quantity") }}
          </div>
          <div v-if="showFor(['ticket_free_price'])" class="th-item w-100">
            {{ $t("dashboard.tickets_items", "Items") }}
          </div>
          <div class="th-item w-100">
            {{ $t("dashboard.tickets_total_amount") }}
          </div>
          <div class="th-item w-25"></div>
        </div>
      </div>

      <div v-if="!loading">
        <div>
          <ticket-unit
            v-for="ticket in tickets"
            :key="ticket.id"
            :ticket="ticket"
            @view-donation="viewDonation"
            @edit="openEditModal"
            @remove="removeUnit"
          />

          <ticket-modal ref="ticketModal" :update-list="getTickets" />

          <div v-if="ticketsCount === 0" class="empty">
            <p>
              {{ $t("dashboard.tickets_empty") }}
            </p>
          </div>
          <div v-if="ticketsCount > perPage" class="pagination">
            <b-pagination v-model="page" :total-rows="ticketsCount" :per-page="perPage" size="md">
            </b-pagination>
          </div>
        </div>
      </div>

      <div v-if="loading">
        <div class="loadings">
          <LdsSpinner />
        </div>
      </div>

      <donation-unit-modal
        :campaignid="campaignId"
        :currentpage="1"
        ref="editDonateModal"
      ></donation-unit-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import debounce from "lodash/debounce";

import urls from "@/constants/urls";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";
import ticketModal from "./ticketModal.vue";
import ticketUnit from "./dashboardTicketsUnit.vue";
import DonationUnitModal from "./donationUnitModal.vue";

export default {
  components: {
    ticketModal,
    ticketUnit,
    DonationUnitModal,
    LdsSpinner,
  },

  data() {
    return {
      page: 1,
      perPage: 50,
      searchQ: "",
      includeNotLinkedToDonationId: 0,
    };
  },

  directives: {
    debounce,
  },

  computed: {
    ...mapState({
      campaignId: (state) => state.dashboard.selectedCampaign,
      selectedCmp: (state) => state.dashboard.campaignDashboard,
      canSeeInputSearch: (state) => state.account.access.CanPerformDonationSearch,
      tickets: (state) => state.ticket.list,
      loading: (state) => state.ticket.loading,
      ticketsCount: (state) => state.ticket.count,
      config: (state) => state.ticket.config,
    }),

    usecase() {
      return this.config.usecase;
    },

    donationsPremoderation() {
      return this.$store.getters.getDonationsPremoderationCampaigns(this.campaignId);
    },
  },

  watch: {
    campaignId() {
      this.page = 1;
      this.getTickets();
    },
    page() {
      this.getTickets();
    },
    searchQ: debounce(function () {
      this.page = 1;
      this.getTickets();
    }, 500),
  },

  methods: {
    ...mapActions({
      fetchDeleteTicket: "ticket/fetchDeleteTicket",
    }),

    showFor(list = [""]) {
      return list.includes(this.usecase);
    },

    getTickets() {
      const { page, perPage, searchQ } = this;
      const params = {
        page,
        per_page: perPage,
        q: searchQ ? searchQ : undefined,
        include_not_linked_to_donation_id: this.includeNotLinkedToDonationId,
      };
      this.$store.dispatch("ticket/fetchTickets", [this.campaignId, params]);
    },

    viewDonation(id) {
      const oid = localStorage.getItem("orgId");

      axios
        .get(
          urls.loadDonation.replace(":oid", oid).replace(":cid", this.campaignId).replace(":id", id)
        )
        .then(({ data }) => {
          this.$refs.editDonateModal.openEditModal(data.data, this.donationsPremoderation);
        });
    },

    openAddModal() {
      this.$refs.ticketModal.open();
    },

    openEditModal(id) {
      this.$refs.ticketModal.open("edit", id);
    },

    removeUnit(id) {
      let message = this.$t("dashboard.tickets_confirm_delete");

      let options = {
        okText: this.$t("teams.confirm_delete"),
        cancelText: this.$t("teams.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.fetchDeleteTicket([this.campaignId, id]).then(() => {
          this.getTickets();
        });
      });
    },
  },

  created() {
    this.getTickets();
  },
};
</script>

<style lang="scss" scoped>
#import {
  @media only screen and (max-width: 1169px) {
    display: none;
  }
  ::v-deep .btn.disabled {
    opacity: initial;
  }
}
::v-deep .multiselect__tags {
  height: 48px;
  width: 100%;
  padding-left: 45px;
  border: 1px solid #88bacc;
  border-radius: 24px;
  padding: 0;
  padding-left: 30px;
}
::v-deep .multiselect__single,
::v-deep .multiselect__placeholder {
  margin: 0;
  line-height: 48px;
  background: #0000;
}
::v-deep .multiselect__select {
  height: 48px;
}
::v-deep .multiselect__input,
::v-deep .multiselect__single,
::v-deep .multiselect__placeholder {
  height: 48px;
  margin: 0;
  line-height: 48px;
  background: transparent;
  color: initial;
  padding: 0;
  font-family: inherit;
  font-size: 16px;
}
.donation-loading {
  display: flex;
  min-height: 100px;
  align-items: center;
  justify-content: center;
}
/*.btn-item {*/
/*margin-left: 10px;*/
/*}*/
.pagination {
  margin-top: 20px;
}

.clickable {
  cursor: pointer;
  i {
    padding-left: 3px;
  }
}
</style>
