<template>
  <div>
    <!-- Modal Component -->
    <b-modal id="loginModal" size="lg" no-close-on-backdrop no-close-on-esc>
      <div slot="modal-header">
        <h2>Login</h2>
      </div>

      <div slot="modal-footer">
        <button v-if="mode === 'login'" class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
          Cancel
        </button>

        <button
          v-if="mode === 'login'"
          class="bttn bttn--lg bttn--orange"
          @click.prevent="proceedLogin"
        >
          Login
        </button>

        <button
          v-if="mode === 'resetPassword' || mode === 'resetPasswordPhone'"
          class="bttn bttn--lg bttn--blue"
          @click.prevent="stepBack('login')"
        >
          Back
        </button>

        <button
          v-if="mode === 'resetPassword' || mode === 'resetPasswordPhone'"
          class="bttn bttn--lg bttn--orange"
          @click.prevent="resetPassword"
        >
          Reset password
        </button>

        <button
          v-if="mode === 'backToLogin'"
          class="bttn bttn--lg bttn--blue"
          @click.prevent="stepBack('login')"
        >
          Back To Login
        </button>
      </div>

      <form id="login" v-on:keyup.enter="proceedLogin" @submit.prevent="proceedLogin">
        <login-form ref="loginmodal" @update-step="updateMode">
          <login-form-org
            slot="item"
            slot-scope="props"
            :login="props.login"
            :steps="props.steps"
            :prevstep="props.prevstep"
            :errors="props.errors"
            :successresetpassword="props.successResetPassword"
            @steps-set="props.stepsset"
            @step-to="props.stepto"
            :proceedresetpassword="props.proceedResetPassword"
            @login-user="props.loginuser"
            :loginLocalErrors="props.loginLocalErrors"
          >
          </login-form-org>
        </login-form>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import loginForm from "../components/loginForm.vue";
import loginFormOrg from "../components/loginFormOrg.vue";

export default defineComponent({
  data() {
    return {
      mode: "login", //'login' - login; 'resetPassword' - forgot password, 'backToLogin' - password send return to login
    };
  },

  components: {
    loginForm,
    loginFormOrg,
  },

  methods: {
    hideModal() {
      this.$root.$emit("bv::hide::modal", "loginModal");
    },

    openModal() {
      this.$root.$emit("bv::show::modal", "loginModal");
    },

    proceedLogin() {
      return this.$refs.loginmodal.loginUser({ noRouting: true }).then((data) => {
        this.$root.$emit("charidy::legal-agreement-document::open", data);
      });
    },

    updateMode(n) {
      this.mode = n;
      this.$refs.loginmodal.stepTo(n);
    },

    stepBack(n) {
      this.mode = n;
      this.$refs.loginmodal.stepTo(n);
    },

    resetPassword() {
      this.$refs.loginmodal.proceedResetPassword();
    },
  },
});
</script>

<style scoped></style>
