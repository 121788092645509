<script>
import { mapState } from "vuex";
import CdnSvgFacebook from "./cdn-svg-facebook.vue";
import CdnSvgInstagram from "./cdn-svg-instagram.vue";
import CdnSvgTwitter from "./cdn-svg-twitter.vue";

export default {
  props: {
    variant: { type: String, default: "floating" },
  },
  components: {
    CdnSvgFacebook,
    CdnSvgInstagram,
    CdnSvgTwitter,
  },
  computed: {
    ...mapState({
      lang: (state) => state?.i18n?.locale,
    }),
    socials() {
      return [
        {
          href: this.chooseBasedOnLang({
            en: "https://www.facebook.com/wearecharidy/",
            he: "https://www.facebook.com/CharidyIsrael/",
          }),
          ariaText: "Link To Facebook",
          iconComponent: CdnSvgFacebook,
          type: "facebook",
        },
        {
          href: this.chooseBasedOnLang({
            en: "https://twitter.com/wearecharidy?lang=en",
            he: "https://twitter.com/charidyisrael?lang=en",
          }),
          ariaText: "Link To Twitter",
          iconComponent: CdnSvgTwitter,
          type: "twitter",
        },
        {
          href: "https://www.instagram.com/wearecharidy/",
          ariaText: "Link To Instagram",
          iconComponent: CdnSvgInstagram,
          type: "instagram",
        },
      ];
    },
  },
  methods: {
    chooseBasedOnLang({ en, he } = {}) {
      switch (this.lang) {
        case "en":
          return en;
        case "he":
          return he;
        default:
          return en;
      }
    },
  },
};
</script>

<template>
  <div
    class="cdn-social-bar"
    :class="{
      'cdn-social-bar__variant-floating': variant === 'floating',
      'cdn-social-bar__variant-inline': variant === 'inline',
    }"
  >
    <li>
      <ul
        v-for="({ href, ariaText, iconComponent, type }, i) in socials"
        :key="i"
        class="cdn-social-bar__social-list-item"
        :class="[`cdn-social-bar__${type}`]"
      >
        <a :href="href" :aria-label="ariaText" target="_blank" class="cdn-social-bar__social-link">
          <component :is="iconComponent" />
        </a>
      </ul>
    </li>
  </div>
</template>

<style lang="scss" scoped>
.cdn-social-bar {
  &__variant-floating {
    position: fixed;
    top: 25%;
    right: 0;
    background: #ffffff;
    opacity: 0.64;
    padding: 31px 0px;
    padding-right: 10px;
    padding-left: 15px;
    z-index: 1;
  }

  &__variant-inline {
    li {
      display: grid;
      grid-template-columns: repeat(4, 44px);
      gap: 21px;
      place-items: center;
      place-content: center;
      @media only screen and (min-width: 320px) and (max-width: 479px) {
        gap: 1px;
      }
    }
  }

  &__social-list-item {
    margin: 0;
    padding: 0;
    width: 44px;
    height: 44px;
  }

  &__social-link {
    text-decoration: none;
    width: 44px;
    height: 44px;
  }

  li {
    list-style: none;
  }

  &__twitter {
    background-color: black;
    border-radius: 9999px;
    width: 32px;
    height: 32px;
    margin-bottom: 4px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
    }
    svg {
      fill: white;
    }
  }
}
</style>
