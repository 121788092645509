<template>
  <div>
    <!--<b-btn v-b-modal.exportDonation>{{ $t('dashboard.export_button_title') }}</b-btn>-->
    <!--
    <button class="bttn bttn--sm bttn--orange" :disabled='!hasAccess' @click="openModal">{{ $t('dashboard.export_button_title') }}</button>
    -->
    <!-- Modal Component -->
    <b-modal id="exportDonation" size="lg" @hidden="clearData">
      <div slot="modal-header">
        <h2>{{ $t("dashboard.export_modal_title") }}</h2>
        <div class="modal-header__hide" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button
          :class="
            !exportLink.file && success ? 'bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--blue'
          "
          @click.prevent="hideModal"
        >
          {{ $t("dashboard.hide_modal") }}
        </button>
        <button
          @click="exportList"
          :class="isLoading ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
          type="button"
          v-if="!success"
          :disabled="isLoading"
        >
          {{ $t("dashboard.export_export_in_modal") }}
        </button>
        <a
          :href="exportLink.file"
          v-if="exportLink.file && success"
          class="bttn bttn--lg bttn--orange"
        >
          {{ $t("dashboard.export_dowload_export_file") }}
        </a>
      </div>
      <div class="filter-block-wrapper" v-if="showFilters">
        <h4 class="modal-subtitle">Filter export:</h4>
        <div class="filter-block dashboard-donation__export">
          <div class="filter-block-item">
            <h6 class="block-item-label">Recurring Donations:</h6>
            <div class="select">
              <div class="multiselect__select"></div>
              <select class="select__select" v-model="filters.recurringFilter">
                <option value="-1">
                  {{ $t("dashboard.recurring_filter_recurring_and_onetime") }}
                </option>
                <option value="1">{{ $t("dashboard.recurring_filter_recurring_only") }}</option>
                <option value="0">{{ $t("dashboard.recurring_filter_onetime_only") }}</option>
              </select>
            </div>
          </div>

          <div class="filter-block-item">
            <h6 class="block-item-label">Gateway:</h6>
            <div class="select">
              <div class="multiselect__select"></div>
              <select class="select__select" v-model="filters.gatewayFilter">
                <option value>{{ $t("dashboard.select_filter_please_select_gateway") }}</option>
                <option
                  v-for="gateway in gateways"
                  :key="gateway.id"
                  :value="gateway.attributes.value"
                >
                  {{ $t("dashboard.select_filter_" + gateway.attributes.text) }}
                </option>
              </select>
            </div>
          </div>

          <div class="filter-block-item">
            <h6 class="block-item-label">Team:</h6>
            <div class="search">
              <multiselect
                v-model="multiselectTeam"
                :options="teamList"
                :custom-label="customLabel"
                track-by="text"
                :placeholder="$t('dashboard.select_teams')"
                :multiple="false"
                :show-labels="true"
              ></multiselect>
            </div>
          </div>
        </div>
      </div>

      <form id="export-donation" class="entities-data">
        <div class="info-content success-desc" v-if="exportLink.file && success">
          <p>{{ $t("dashboard.export_success_have_button") }}</p>
        </div>

        <div class="error info-content" v-if="!exportLink.file && success">
          <p>{{ $t("dashboard.export_check_email") }}</p>
        </div>

        <div class="loading-link-wrap" v-if="!success">
          <div v-if="!isLoading">
            <div class="info-content">
              <p>{{ $t("dashboard.export_export_description") }}</p>
            </div>

            <div class="error info-content">
              <error-message :response.sync="errorResponse"></error-message>
            </div>

            <div class="form-group">
              <label>{{ $t("dashboard.export_email") }}</label>
              <input
                @keydown.enter.prevent="exportList"
                type="email"
                name="email"
                value
                v-model="email"
                class="form-control"
              />
            </div>
          </div>

          <div class="info-content loading-link" v-if="isLoading">
            <p>{{ $t("dashboard.export_loading_link") }}...{{ progress }}%</p>
          </div>
          <error-message ref="error" :response.sync="errorResponse"></error-message>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

import axios from "axios";

import urls from "@/constants/urls.js";

import errorList from "@/components/error-message.vue";
import Multiselect from "vue-multiselect";

export default {
  props: [
    "campaignid",
    "filters",
    "teamList",
    "multiselectTeam",
    "gateways",
    "customLabel",
    "canSeeInputSearch",
  ],

  data() {
    return {
      email: "",
      exportLink: "",
      isLoading: false,
      needEmail: false,
      success: false,
      errorResponse: null,
      procLoading: false, // the process of file uploading
      uploadResult: null, // json with result report
      successUpload: false, // if the import is comlpeted
      progress: 0,
      procID: 0,
      interval: null,
      showFilters: true,
    };
  },

  components: {
    errorMessage: errorList,
    Multiselect,
  },

  computed: mapState({
    orgId: function () {
      return localStorage.getItem("orgId");
    },

    hasAccess: (state) => {
      return state.account.access.CanExportCampaignDonationList;
    },
  }),

  watch: {
    procID: function () {
      if (this.procID > 0 && this.interval == null) {
        this.initProcTracking();
      }
    },
  },

  methods: {
    clearData: function () {
      this.email = "";
      this.exportLink = "";
      this.isLoading = false;
      this.needEmail = false;
      this.success = false;
      this.progress = 0;
      this.procID = 0;
      this.interval = null;
      this.procLoading = false; // the process of file uploading
      this.uploadResult = null; // json with result report
      this.successUpload = false; // if the import is comlpeted
      this.showFilters = true;
    },

    initProcTracking: function () {
      this.interval = setInterval(this.checkProcProgress, 2000);
    },

    checkProcProgress: function () {
      if (this.procID == 0) {
        return;
      }
      let orgId = localStorage.getItem("orgId");
      axios
        .get(urls.procProgressTracker.replace(":oid", orgId).replace(":id", this.procID))
        .then((response) => {
          this.progress = response.data.data.attributes.progress;
          if (response.data.data.attributes.finished) {
            clearInterval(this.interval);

            this.success = true;
            this.isLoading = false;
            if (response.data.data.attributes.meta != null) {
              this.exportLink = JSON.parse(response.data.data.attributes.meta);
            }
          } else {
            this.isLoading = true;
          }
        })
        .catch((e) => {
          this.isLoading = false;
          this.errors.push(e.response);
        });
    },

    openModal() {
      this.$root.$emit("bv::show::modal", "exportDonation");
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "exportDonation");
    },

    exportList() {
      this.$refs.error.clearErrors();
      let setting = `${
        this.filters.gatewayFilter.length > 0 ? "&bank_name=" + this.filters.gatewayFilter : ""
      }${"&recurring=" + this.filters.recurringFilter}${
        this.filters.filterStatus.length > 0 ? "&status=" + this.filters.filterStatus : ""
      }${this.filters.searchQ.length > 0 ? "&q=" + this.filters.searchQ : ""}${
        this.multiselectTeam ? "&team_id=" + this.multiselectTeam.value : ""
      }`;
      this.isLoading = true;
      this.showFilters = false;
      axios
        .post(
          urls.exportCsvList
            .replace(":orgId", this.orgId)
            .replace(":campaignId", this.campaignid)
            .replace(":email", this.email) + setting
        )
        .then((response) => {
          this.procID = parseInt(response.data.proc_session_id); // set process id to track progress
        })
        .catch((e) => {
          console.log(e.response);
          this.isLoading = false;
          this.needEmail = true;
          this.errorResponse = e.response;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#export-donation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.loading-link-wrap {
  width: 100%;
  .form-group {
    @media only screen and (min-width: 768px) {
      width: 50%;
    }
  }
}

.loading-link,
.success-desc {
  text-align: center;
  width: 100%;
  p {
    width: 100%;
    text-align: center;
  }
}

.export-list {
  margin-top: 20px;
}

.filter-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 20px;
  &-item {
    flex-basis: 47%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &:nth-child(2) {
      .select {
        flex-basis: 75%;
      }

      .block-item-label {
        flex-basis: 25%;
      }
    }

    &:last-child {
      flex-basis: 100%;

      .search {
        flex-basis: 78%;
      }

      .block-item-label {
        flex-basis: 22%;
      }
    }

    .block-item-label,
    .select,
    .search {
      font: 400 0.9rem Arial;
    }
    .select {
      flex-basis: 53%;
    }

    .block-item-label {
      flex-basis: 45%;
    }
  }
}
.modal-subtitle {
  margin-bottom: 15px;
  font: 400 0.9rem Arial;
}

::v-deep .multiselect__tags {
  height: 48px;
  width: 100%;
  border: 1px solid #88bacc;
  border-radius: 24px;
  padding: 0;
  padding-left: 20px;
  font: 400 0.9rem Arial;
  color: black;
  cursor: pointer;
}
::v-deep .multiselect__single,
::v-deep .multiselect__placeholder {
  margin-top: 3.1%;
  line-height: 48px;
  background: #0000;
  font: 400 0.9rem Arial;
  color: black;
}
::v-deep .multiselect__select {
  height: 48px;
  font: 400 0.9rem Arial;
  color: black;
}
::v-deep .multiselect__input,
::v-deep .multiselect__single,
::v-deep .multiselect__placeholder {
  height: 48px;
  margin: 0;
  line-height: 48px;
  background: transparent;
  font: 400 0.9rem Arial;
  color: black;
}
::v-deep .multiselect__select:before {
  top: 58%;
  color: black;
}
::v-deep .multiselect__element {
  font: 400 0.9rem Arial;
  color: black;
}
</style>
