<template>
  <b-modal ref="modal" centered hide-header ok-only>
    <div
      v-for="(msg, i) in completeMsg"
      :key="i"
      class="text-center"
      :class="{
        'text-danger': !msg.success,
        'text-success': msg.success,
      }"
    >
      <b-icon
        :icon="msg.success ? 'check-circle' : 'x-circle'"
        :variant="msg.success ? 'success' : 'danger'"
        style="width: 80px; height: 80px"
      ></b-icon>
      <div class="mt-2">
        {{ msg.detail }}
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  computed: {
    completeMsg() {
      const res = [];
      const q = new URLSearchParams(location.search);
      const [success, msg] = [q.get("success"), q.get("message")];

      if (success && msg) {
        res.push({
          success: success === "true" ? true : false,
          detail: msg,
        });
      }

      return res;
    },
  },

  mounted() {
    if (this.completeMsg.length) {
      this.$refs.modal.show();
    }
  },
};
</script>

<style></style>
