<template>
  <div class="campaigns-list">
    <ShortlinkValidationProvider>
      <CampaignAddByMeta v-slot="{ searchQuery }">
        <div class="my-4 w-100 text-right">
          <GridSwitch v-model="layout" />
        </div>
        <CampaignListSearch :layout="layout" :searchQuery="searchQuery" />
        <div class="loading" v-if="loading">
          <app-spinner />
        </div>
      </CampaignAddByMeta>
    </ShortlinkValidationProvider>
  </div>
</template>

<script>
import { mapState } from "vuex";
import appSpinner from "@/common-components/ui-elements/app-spinner.vue";
import CampaignAddByMeta from "./campaign-wizards/campaignAddByMeta.vue";
import CampaignListSearch from "./campaign-list-search.vue";
import ShortlinkValidationProvider from "@/views/campaigns/campaign-edit-vue/subcomponents/shortlink-validation-provider.vue";
import GridSwitch from "./grid-switch.vue";
import { getCampaignListLayout, setCampaignListLayout } from "@/helpers/storage";

export default {
  components: {
    appSpinner,
    CampaignAddByMeta,
    CampaignListSearch,
    ShortlinkValidationProvider,
    GridSwitch,
  },

  data() {
    return {
      setIntervalData: null,
      layout: null,
    };
  },

  mounted() {
    this.layout = getCampaignListLayout();
  },

  watch: {
    layout(v) {
      setCampaignListLayout(v);
    },
  },

  computed: {
    ...mapState({
      loading: (state) => state.campaigns.loadingCampaigns,
    }),
  },
};
</script>

<style lang="scss" scoped>
.campaigns-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.current-campaigns {
  margin-bottom: 20px;
  .h2-subcat {
    margin-bottom: 20px;
  }
}
</style>
