<template>
  <div class="grid-switch">
    <div class="svg-wrap" :class="value === 'list' ? 'active' : ''" @click="onInput('list')">
      <b-icon icon="list" />
    </div>

    <div class="svg-wrap" :class="value === 'grid' ? 'active' : ''" @click="onInput('grid')">
      <b-icon icon="grid-fill" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "grid",
    },
  },
  methods: {
    onInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-switch {
  display: inline-flex;
  .svg-wrap {
    display: flex;
    cursor: pointer;
    background-color: #0f7497;
    width: 54px;
    height: 54px;
    opacity: 0.5;
    padding: 0.5rem;
    border-radius: 15%;
    transition: all 0.2s ease-in-out;
    &:not(:last-of-type) {
      margin-right: 15px;
    }
    &.active {
      opacity: 1;
      box-shadow: 0 14px 26px 0 rgba(34, 37, 42, 0.14);
    }
    svg {
      display: block;
      width: 100%;
      height: auto;
      fill: white;
    }
  }
}
.he,
.ar {
  .grid-switch {
    flex-direction: row-reverse;
  }
}
</style>
