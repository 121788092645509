<template>
  <div class="batch-actions" v-if="items.length > 1">
    <b-overlay :show="loading" rounded opacity="0.6" spinner-small class="d-inline-block">
      <b-button variant="danger" size="sm" class="bttn bttn--lg bttn--blue" @click="deleteLocalAll">
        Delete
      </b-button>
    </b-overlay>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { notificationsMixin, confirmMixin } from "@/mixins";

export default {
  mixins: [confirmMixin, notificationsMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      loading: (state) => state.mediaStorage.loading,
    }),
    selectedCmp: {
      get() {
        return this.campaign || this.$store.state.mediaStorage.campaign;
      },
      set(val) {
        this.form.campaign_id = val.id;
        this.campaign = val;
      },
    },
  },
  methods: {
    ...mapActions({
      batchDeleteMedia: "mediaStorage/batchDeleteMedia",
    }),
    async deleteLocalAll() {
      const yes = await this.$_confirmMixin_deleteConfirm();
      if (yes) {
        this.batchDeleteMedia({
          cID: this.selectedCmp.id,
          items: this.items,
        }).catch(this.$_notificationsMixin_handleCatch);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.batch-actions {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(1, 1fr);
  justify-items: end;
  align-items: center;
  gap: 5px;
}
</style>
