<template>
  <multiselect
    :value="multiselectData"
    @input="emitInput"
    :options="teamListWithDisabled"
    label="text"
    track-by="value"
    :placeholder="$t('dashboard.multiselect_choose_team', 'Choose team')"
    :selectLabel="$t('dashboard.multiselect_press_enter_to_select', 'Press enter to select')"
    :deselectLabel="$t('dashboard.multiselect_press_enter_to_remove', 'Press enter to remove')"
    :multiple="false"
  >
  </multiselect>
</template>

<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      required: true,
    },
    data: {
      required: true,
    },
  },
  data() {
    return {
      multiselectData: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.multiselectData = this.teamListWithDisabled.find((v) => v.value === val.toString());
        } else {
          this.multiselectData = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      teamList: (state) => state.teams.allTeams,
    }),
    teamListWithDisabled() {
      let resultArr = [];

      for (const v of Object.values(this.teamList)) {
        const teamDonation = this.data.find((t) => t.team_id === Number(v.id));
        let objTeam = {
          value: v.id,
          text: `[${v.id}] ${v.attributes.name}`,
          $isDisabled: Boolean(teamDonation),
        };
        resultArr.push(objTeam);
      }

      return resultArr;
    },
    disableMultiselect() {
      if (this.multiselectData) {
        const teamDonation = this.data.find(
          (t) => t.team_id === Number(this.multiselectData.value)
        );
        if (teamDonation.id) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    emitInput(event) {
      if (event) {
        this.$emit("input", Number(event.value));
      } else {
        this.$emit("input", null);
      }
    },
  },
};
</script>
