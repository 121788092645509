export default {
  title: "AddSetCampaignModeSetting",
  type: "object",
  expanded: true,
  properties: {
    campaign_mode: {
      title: "Campaign Mode",
      type: "enum",
      expanded: true,
      items: {
        enum: [
          {
            text: "Campaign is LIVE",
            value: 2,
          },
          {
            text: "Campaign is in COUNTDOWN mode",
            value: 1,
          },
          {
            text: "Campaign is over",
            value: 3,
          },
          {
            text: "Campaign is in DEMO mode",
            value: 5,
          },
          {
            text: "Campaign is in PENDING mode",
            value: 0,
            disabled: true,
          },
          {
            text: "Campaign is in FAILED mode",
            value: 4,
            disabled: true,
          },
        ],
      },
    },
  },
};
