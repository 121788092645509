<template>
  <div class="cu-page__outer-block">
    <category-title :title="$t('dashboard.updates')" />
    <dashboard-updates></dashboard-updates>
  </div>
</template>

<script>
import categoryTitle from "@/common-components/ui-elements/category-title.vue";
import dashboardUpdates from "@/views/dashboard/subcomponents/dashboardUpdatesWithQuill.vue";
import { mapState } from "vuex";

export default {
  components: {
    categoryTitle,
    dashboardUpdates,
  },
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState({}),
  },
};
</script>

<style lang="scss" scoped>
.cu-page__outer-block {
  --border: 1px solid #e6eaed;
  --border-darker: 1px solid #bcc0c2;
}

::v-deep .dashboard-block {
  padding-bottom: 0px;
  @media only screen and (max-width: 414px) {
    width: min-content;
  }
}
.cu-page {
  display: grid;
  grid-auto-flow: row;
  gap: 20px;
  border-bottom: 1px solid #e6eaed;
  &__block {
    display: flex;
    flex-wrap: wrap;
  }
  &__outer-block {
    display: grid;
    grid-auto-flow: row;
    ::v-deep .scroll {
      max-height: 100%;
    }
  }
  &:last-child {
    padding-bottom: 20px;
  }
}
</style>
