<template>
  <div class="w-100 mb-4">
    <div class="d-flex justify-content-between mb-2">
      <span>
        {{ field.title }}
      </span>
    </div>
    <b-form-select
      v-if="!loadingGateways && gateways.length"
      v-model="valueModel"
      :required="field.required"
      :options="optionsWithDisabledState"
    />
    <div v-else class="col-12 d-flex">
      <b-spinner v-if="loadingGateways" small class="p-2"></b-spinner>
      <b-alert v-else show variant="warning" class="w-100 d-flex">
        {{ $t("add_campaign.not_gateways", "No active gateways") }}
      </b-alert>
    </div>
    <form class="mt-3" v-if="field.items" :key="componentKey">
      <FormGeneretor :scheme="field.items" :value="localFormValue" />
    </form>
  </div>
</template>

<script>
import FormGeneretor from "@/common-components/form-generetor/form-generetor";
import { isPlainObject } from "@/helpers/inspect";
import { mapState } from "vuex";

export default {
  components: { FormGeneretor },

  props: {
    form: {
      type: Object,
      default: () => null,
    },
    field: {
      type: Object,
      default: () => null,
    },
    fieldKey: {
      type: String,
      default: "",
    },
    parentValue: {
      type: Object,
      default: () => ({}),
    },
    parentFormKey: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      localValue: "",
      localFormValue: {},
      componentKey: 0,
    };
  },

  watch: {
    value(val) {
      if (isPlainObject(val) && Object.keys(val).length === 0) {
        this.localValue = "";
        this.localFormValue = {};
        this.forceRerender();
      }
    },
  },

  mounted() {
    this.$store.dispatch("getGateways");
  },

  computed: {
    ...mapState({
      cID: (state) => state.campaign.campaign.data.id,
      loadingGateways: (state) => state.gateways.loadingGateways,
      gateways: (state) => state.gateways.gateways.filter((v) => v.attributes.active),
    }),

    componentAttrs() {
      return this.field?.componentAttrs || {};
    },

    value() {
      return this.form[this.fieldKey];
    },

    optionsWithDisabledState() {
      let resultArr = [];

      for (var i = 0; i < this.gateways.length; i++) {
        const value = this.gateways[i].attributes.gateway;
        const text = this.gateways[i].attributes.gateway;
        const disabled = !!this.parentValue[value];

        let obj = {
          text,
          value,
          disabled,
        };

        resultArr.push(obj);
      }

      return resultArr;
    },

    valueModel: {
      get() {
        return this.localValue;
      },
      set(val) {
        this.localValue = val;
        console.log({ val });
        this.$emit("input", { [val]: this.localFormValue });
      },
    },
  },

  methods: {
    // TODO: form generator does not pick up changes from the localFormValue
    forceRerender() {
      this.componentKey += 1;
      const timeout = setTimeout(() => {
        this.componentKey += 1;
        clearTimeout(timeout);
      }, 500);
    },
  },
};
</script>
