<template>
  <div class="recent-activity">
    <h2 class="dashboard-block__title">Recent Activity</h2>

    <ul class="recent-list">
      <li>
        <div class="recent-list-card">
          <div class="recent-list-card__left">
            <div class="recent-list-card__date">August 13, 2019</div>
            <div class="recent-list-card__info">7 days ago</div>
          </div>

          <div class="recent-list-card__content">
            <div class="recent-list-card__message">
              <span>Visted your page:<br />EMail Address</span>
            </div>
            <!--
            <div class="recent-list-card__message">
              {{ item.details.dedication }}
            </div>
            -->
          </div>
        </div>

        <div class="recent-list-card">
          <div class="recent-list-card__left">
            <div class="recent-list-card__date">August 13, 2019</div>
            <div class="recent-list-card__info">7 days ago</div>
          </div>

          <div class="recent-list-card__content">
            <div class="recent-list-card__message">
              <span>Visted your page:<br />EMail Address</span>
            </div>
            <!--
            <div class="recent-list-card__message">
              {{ item.details.dedication }}
            </div>
            -->
          </div>
        </div>

        <div class="recent-list-card">
          <div class="recent-list-card__left">
            <div class="recent-list-card__date">August 13, 2019</div>
            <div class="recent-list-card__info">7 days ago</div>
          </div>

          <div class="recent-list-card__content">
            <div class="recent-list-card__message">
              <span>Visted your page:<br />EMail Address</span>
            </div>
            <!--
            <div class="recent-list-card__message">
              {{ item.details.dedication }}
            </div>
            -->
          </div>
        </div>

        <div class="recent-list-card">
          <div class="recent-list-card__left">
            <div class="recent-list-card__date">August 13, 2019</div>
            <div class="recent-list-card__info">7 days ago</div>
          </div>

          <div class="recent-list-card__content">
            <div class="recent-list-card__message">
              <span>Visted your page:EMail Address</span>
            </div>
            <!--
            <div class="recent-list-card__message">
              {{ item.details.dedication }}
            </div>
            -->
          </div>
        </div>
      </li>
    </ul>

    <!-- <div class="empty">
      <p>

      </p>
    </div> -->
    <!--
    <div class="recent-total">
      {{ $t('dashboard.activity_total', { count: recentActivity.length }, recentActivity.length) }}
    </div>
    -->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState({}),

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/_vars.scss";

.empty {
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.recent-activity {
  width: 100%;
  max-height: 600px;
}

.recent-list {
  max-height: 320px;
  overflow-y: auto;
  list-style: none;
  padding: 2px 5px 2px 0;
  margin: 0;
  li:not(:first-of-type) {
    margin-top: -1px;
  }
}

.recent-list-card {
  width: 100%;
  min-height: 90px;
  display: flex;
  border: 1px solid #e6eaed;
  border-radius: 4px;
  overflow: hidden;
  &__left {
    padding-left: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    width: 40%;
    background-color: $bg-50;
  }
  &__date {
    width: 100%;
    font-size: 14px;
    color: $fd;
  }
  &__info {
    color: $bb-100;
    font-size: 14px;
    text-align: left;
  }
  &__content {
    width: 40%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 5px 0 15px;
  }
  &__message {
    font-size: 14px;
    color: #000000;
    min-width: 116px;
    text-align: center;
    span {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.recent-total {
  text-align: center;
  color: #888888;
}
</style>
