<template>
  <b-overlay :show="loading" rounded="sm">
    <div class="organization-role-container panel">
      <div class="organization-role-desk col-lg-4 col-md-6" v-for="role in roles" :key="role.id">
        <div class="role-info">
          <div class="role-wrap">
            <div class="img-container">
              <div class="inner">
                <img v-if="avatar(role)" :src="avatar(role)" />
                <icon-generic-user v-else />
              </div>
            </div>
            <div class="role-text">
              <div class="img-wrap" v-if="role.organization">
                <img :src="role.organization" width="64" height="64" />
              </div>
              <h5>{{ userRole(role) }}</h5>
            </div>
          </div>
          <router-link
            to="/donor-log-in"
            class="btn bttn bttn--sm bttn--bordered-red"
            target="_self"
          >
            Log In
          </router-link>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import iconGenericUser from "@/components/icon-genericUser.vue";
import { mapActions, mapState } from "vuex";
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],
  components: {
    iconGenericUser,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      roles: (state) => state.organizationRoles.collection,
      loading: (state) => state.organizationRoles.loading.fetchRoles,
    }),
  },
  mounted() {
    this.fetchRoles().catch(this.$_notificationsMixin_handleCatch);
  },
  methods: {
    ...mapActions({
      fetchRoles: "organizationRoles/fetchRoles",
    }),
    avatar(role) {
      return role.attributes.avatar;
    },
    userRole(role) {
      return `${role.attributes.first_name} ${role.attributes.last_name}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/_vars.scss";
.organization-role-container {
  padding: 40px 28px 28px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  img,
  ::v-deep svg {
    max-width: 100%;
  }
  .img-wrap {
    max-width: 112px;
    margin-bottom: 12px;
    img,
    ::v-deep svg {
      max-height: 35px;
    }
  }
  .role-text {
    padding: 20px 0 20px 18px;
  }
}
.organization-role-desk {
  padding: 0 12px;
  margin-bottom: 20px;
  .role-info {
    background: #f7fbff;
    border-radius: 6px;
    border: 1px solid #e6eaed;
    padding: 25px 18px 70px;
    letter-spacing: -0.5px;
    position: relative;
    height: 100%;
  }
  h5 {
    font-family: "VitoExtended-Bold";
    font-size: calc(14px + 0.64vmin);
  }
  .btn {
    position: absolute;
    bottom: 25px;
    left: 50%;
    margin-left: -70px;
    width: 140px;
  }
}
.role-wrap {
  display: flex;
  align-items: flex-start;
}
.img-container {
  overflow: hidden;
  width: 33%;
  min-width: 33%;
  height: 0;
  padding-top: 33%;
  border-radius: 50px;
  position: relative;
  .inner {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
