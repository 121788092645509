<template>
  <div>
    <div>
      <div class="campaign-edit-title">
        <h2 class="h2-like">{{ $t("edit_campaign.generalinfo_title") }}</h2>
      </div>

      <!-- <intity-info-missing v-if="!noEntitiesWarning"/>
      <contact-info-missing /> -->

      <form method="POST" class="campaign-edit">
        <!-- <div class="error-wrapp">
            <error v-if='errors' :errors='errors'></error>
        </div> -->

        <div class="row">
          <div class="col-12">
            <div class="form-group form-unit">
              <label class="form-unit__label form-unit__label--upper-sm">Organization Name</label>
              <input
                type="text"
                required
                :disabled="!hasAccess"
                class="form-control form-unit__input"
              />
            </div>

            <div class="form-group form-unit">
              <label class="form-unit__label form-unit__label--upper-sm">Email</label>
              <input
                type="text"
                required
                :disabled="!hasAccess"
                class="form-control form-unit__input"
              />
            </div>

            <div class="form-group form-unit">
              <label class="form-unit__label form-unit__label--upper-sm">Phone Number</label>
              <input
                type="text"
                required
                :disabled="!hasAccess"
                class="form-control form-unit__input"
              />
            </div>

            <div class="form-group form-unit">
              <label class="form-unit__label form-unit__label--upper-sm"
                >Organization Website</label
              >
              <input
                type="text"
                required
                :disabled="!hasAccess"
                class="form-control form-unit__input"
              />
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- <div class="load">
      <img src="https://editionsdelarose.com/wp-content/themes/edr/img/loading.gif">
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import contactInfoMissing from "@/components/contact-info-missing";
import intityInfoMissing from "@/components/intity-info-missing";

export default {
  data() {
    return {};
  },

  components: {
    contactInfoMissing,
    intityInfoMissing,
  },

  computed: {
    ...mapState({
      hasAccess: (state) => {
        return state.account.access.CanEditCampaignContent;
      },
    }),
  },

  watch: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/_vars.scss";
.goal-input {
  .dropdown {
    display: flex;
    &.open {
      .btn {
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
      }
    }
    .btn {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top: 1px solid #e6eaed !important;
      border-bottom: 1px solid #e6eaed !important;
      border-left: 1px solid #e6eaed !important;
      background-color: #ffffff;
      display: inline-flex;
      align-items: center;
      text-transform: uppercase;
      position: relative;
      color: #20434e;
      &:active,
      &:focus,
      &:hover {
        color: #20434e;
        outline: 0;
        background-color: #ffffff;
        border-color: rgba(32, 67, 78, 0.15);
        box-shadow: none;
      }
      span {
        position: absolute;
        top: 22px;
        right: 5px;
      }
    }
    .dropdown-toggle {
      min-width: 65px;
      font-size: 15px;
      position: relative;
      &:after {
        position: absolute;
        right: 6px;
        top: calc(50% - 1px);
      }
    }
    &-menu {
      min-width: initial;
      padding: 0;
      width: 100%;
      border-radius: 0;
    }
    &-item {
      height: 45px;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: gray;
      text-transform: uppercase;
      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(32, 67, 78, 0.15);
      }
    }
  }
}

.date-time-select {
  .form-unit__addon-icon {
    display: none;
    @media only screen and (min-width: 992px) {
      display: flex;
    }
  }
  input.form-unit__input {
    @media only screen and (max-width: 991px) {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}
.form-unit--addon {
  .help-tooltip {
    margin-bottom: -3px;
  }
}
.form-unit__addon-wrap {
  .link-error,
  .link-success {
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    right: 0;
  }
  .link-error {
    color: #f26e66;
  }
  .link-success {
    color: #3cc7a0;
  }
  .link-validate {
    color: $fd;
    display: inline-flex;
    align-items: center;
    height: 44px;
    position: absolute;
    top: 3px;
    right: 5px;
    > img {
      display: block;
      width: auto;
      height: 100%;
    }
  }
}

.time-zone-info {
  width: 100%;
  margin-bottom: 15px;
  display: inline-block;
  font-size: 16px;
  color: $fd;
  .change-time-zone-button {
    color: $bb-100;
    text-decoration: underline;
    &:hover,
    &:focus {
      color: $bb-80;
      //text-decoration: none;
    }
  }
}
.load {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  background-color: white;
  img {
    width: 200px;
    margin: 0 auto;
  }
}

.submit-button {
  padding-top: 20px;
}

.switch-public {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //padding: 0;
  &--switch {
    input {
      display: block;
      opacity: 0;
    }
    label {
      width: 60px;
      height: 30px;
      cursor: pointer;
      display: inline-block;
      position: relative;
      background: rgb(189, 189, 189);
      border-radius: 30px;
      transition: background-color 0.4s;
      -moz-transition: background-color 0.4s;
      -webkit-transition: background-color 0.4s;
    }
    label:after {
      left: 0;
      width: 20px;
      height: 20px;
      margin: 5px;
      content: "";
      position: absolute;
      background: #fff;
      border-radius: 10px;
    }
    input:checked + label {
      background: #3cc7a0;
    }
    input:checked + label:after {
      left: auto;
      right: 0;
    }
  }
}

.form-unit__label--upper-sm {
  text-transform: none;
}
</style>
