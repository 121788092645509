import { render, staticRenderFns } from "./packagesReviews.vue?vue&type=template&id=57a2022d&scoped=true&lang=html&"
import script from "./packagesReviews.vue?vue&type=script&lang=js&"
export * from "./packagesReviews.vue?vue&type=script&lang=js&"
import style0 from "./packagesReviews.vue?vue&type=style&index=0&id=57a2022d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57a2022d",
  null
  
)

export default component.exports