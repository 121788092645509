export default {
  title: "MinDonationAmount",
  type: "object",
  properties: {
    value: {
      title: "Active",
      type: "boolean",
    },
    amount: {
      title: "Amount",
      type: "number",
    },
  },
};
