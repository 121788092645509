<template>
  <div>
    <team-page-form :team="team" class="mb-3" ref="teamPageForm" @submit="addPage" />

    <div v-if="loading" class="text-center">
      <b-spinner />
    </div>

    <div v-else-if="rows == 0 || pages.length == 0" class="text-center">
      {{ $t("teams.team_pages_no_records", "No records") }}
    </div>

    <div v-else>
      <div class="d-flex flex-wrap justify-content-center">
        <team-page-item v-for="p in pages" :key="p.id" :data="p" :team="team" @remove="remove" />
      </div>
      <div>
        <b-pagination
          v-model="page"
          :total-rows="rows"
          :per-page="perPage"
          size="sm"
          class="mb-0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { notificationsMixin } from "@/mixins";
import { mapActions, mapState } from "vuex";
import TeamPageForm from "./team-page-form.vue";
import teamPageItem from "./team-page-item.vue";

export default {
  components: { teamPageItem, TeamPageForm },

  mixins: [notificationsMixin],

  props: {
    orgarea: Boolean,
    team: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      perPage: 9,
      page: 1,
    };
  },

  computed: {
    ...mapState({
      pages: (s) => s.seferTorahPages.list,
      loading: (s) => s.seferTorahPages.loading,
      rows: (s) => s.seferTorahPages.count,
    }),

    cId() {
      return this.team.campaign_id;
    },

    tId() {
      return this.team.id;
    },
  },

  mounted() {
    this.getPages();
  },

  watch: {
    page() {
      this.getPages();
    },
  },

  methods: {
    ...mapActions({
      fetchPages: "seferTorahPages/fetchPages",
      fetchCreatePage: "seferTorahPages/fetchCreatePage",
      fetchOrgCreatePage: "seferTorahPages/fetchOrgCreatePage",
      fetchDeletePage: "seferTorahPages/fetchDeletePage",
      fetchOrgDeletePage: "seferTorahPages/fetchOrgDeletePage",
    }),

    getPages() {
      const params = {
        per_page: this.perPage,
        page: this.page,
        extend: ["parent", "grandparent", "learner"],
      };

      const payload = {
        cId: this.cId,
        tId: this.tId,
        params,
      };

      this.fetchPages(payload);
    },

    addPage({ page }) {
      const payload = {
        tId: this.tId,
        id: page.id,
        data: null,
      };

      if (this.orgarea) {
        payload.cId = this.cId;
        this.addOrg(payload);
      } else {
        this.add(payload);
      }
    },

    add(payload) {
      this.fetchCreatePage(payload)
        .then(() => {
          this.$refs?.teamPageForm?.clear();
          this.page = 1;
          this.getPages();
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    addOrg(payload) {
      this.fetchOrgCreatePage(payload)
        .then(() => {
          this.$refs?.teamPageForm?.clear();
          this.page = 1;
          this.getPages();
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    remove({ id }) {
      const payload = {
        tId: this.tId,
        id,
      };

      if (this.orgarea) {
        payload.cId = this.cId;
        this.delOrg(payload);
      } else {
        this.del(payload);
      }
    },

    del(payload) {
      this.fetchDeletePage(payload)
        .then(() => {
          this.getPages();
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    delOrg(payload) {
      this.fetchOrgDeletePage(payload)
        .then(() => {
          this.getPages();
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
  },
};
</script>

<style lang="scss" scoped></style>
