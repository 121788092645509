export default {
  title: "AddTeamFromCampaign",
  type: "object",
  noValidateChild: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: false,
    },
    // hide_parent_team_field: {
    //   title: "Hide parent team field",
    //   type: "boolean",
    //   default: false
    // },
    use_phone_as_shortlink: {
      title: "Use phone as shortlink",
      type: "boolean",
      default: false,
    },
    // send_sms: {
    //   title: "Send sms",
    //   type: "boolean",
    //   default: true
    // },
    // parent_team_list: {
    //   title: "Parent team list",
    //   type: "enum",
    //   default: [],
    //   items: {
    //     title: "Items",
    //     type: "enum",
    //     default: "",
    //     enum: [
    //       {
    //         text: "All teams",
    //         value: ""
    //       },
    //       {
    //         text: "Parent only",
    //         value: "parent_only"
    //       },
    //       {
    //         text: "Grandparent only",
    //         value: "grandparent_only"
    //       }
    //     ]
    //   }
    // },
    // theme: {
    //   title: "Theme",
    //   type: "string",
    //   default: "",
    //   pattern: "^.*$"
    // },
    // "image": {
    //   "title": "Image",
    //   "type": "object",
    //   "properties": {
    //     "src": {
    //       "title": "Src",
    //       "type": "string",
    //       "default": "",
    //       "pattern": "^.*$"
    //     },
    //     "disable_change": {
    //       "title": "Disable change",
    //       "type": "boolean",
    //       "default": true
    //     },
    //     "hide": {
    //       "title": "Hide",
    //       "type": "boolean",
    //       "default": true
    //     }
    //   }
    // },
    form: {
      title: "Form",
      type: "object",
      properties: {
        leader_name: {
          title: "Leader name",
          type: "object",
          properties: {
            hide: {
              title: "Hide",
              type: "boolean",
              default: false,
            },
            required: {
              title: "Required",
              type: "boolean",
              default: true,
            },
          },
        },
        phone: {
          title: "Phone",
          type: "object",
          properties: {
            hide: {
              title: "Hide",
              type: "boolean",
              default: false,
            },
            required: {
              title: "Required",
              type: "boolean",
              default: true,
            },
          },
        },
        email: {
          title: "Email",
          type: "object",
          properties: {
            hide: {
              title: "Hide",
              type: "boolean",
              default: false,
            },
            required: {
              title: "Required",
              type: "boolean",
              default: true,
            },
          },
        },
        parent_team: {
          title: "Parent team",
          type: "object",
          properties: {
            hide: {
              title: "Hide",
              type: "boolean",
              default: true,
            },
            required: {
              title: "Required",
              type: "boolean",
              default: false,
            },
          },
        },
        description: {
          title: "Description",
          type: "object",
          properties: {
            hide: {
              title: "Hide",
              type: "boolean",
              default: false,
            },
            required: {
              title: "Required",
              type: "boolean",
              default: false,
            },
          },
        },
        goal: {
          title: "Goal",
          type: "object",
          properties: {
            allow_edit: {
              title: "Allow Edit",
              type: "boolean",
              default: false,
            },
            min: {
              title: "Min Goal",
              type: "number",
              default: 0,
            },
            max: {
              title: "Max Goal",
              type: "number",
              default: 100000,
            },
          },
        },
        donor_goal: {
          title: "Donor goal",
          type: "object",
          properties: {
            allow_edit: {
              title: "Allow Edit",
              type: "boolean",
              default: false,
            },
            hide: {
              title: "Hide",
              type: "boolean",
              default: true,
            },
            required: {
              title: "Required",
              type: "boolean",
              default: false,
            },
          },
        },
      },
    },
    colors: {
      title: "Form colors",
      type: "object",
      properties: {
        value: {
          title: "Active",
          type: "boolean",
          default: false,
        },
        background: {
          title: "Background",
          type: "color",
          default: "#ffffff",
        },
        background_secondary: {
          title: "Background secondary",
          type: "color",
          default: "#fbfcff",
        },
        text: {
          title: "Text",
          type: "color",
          default: "#212529",
        },
        text_secondary: {
          title: "Text secondary",
          type: "color",
          default: "#20434e",
        },
      },
    },
    banner: {
      title: "Banner",
      type: "object",
      expanded: true,
      properties: {
        value: {
          title: "Active",
          type: "boolean",
          default: false,
        },
        options: {
          title: "Images",
          type: "list",
          default: [],
          items: {
            type: "object",
            properties: {
              src: {
                title: "Src",
                type: "image",
                default: "",
              },
            },
          },
        },
      },
    },
    goal_categories: {
      title: "Goal categories",
      type: "object",
      component: "GoalCategoriesSection",
      expanded: true,
      properties: {
        value: {
          title: "Active",
          type: "boolean",
          default: false,
        },
        view: {
          title: "View Mode",
          type: "enum",
          default: "card-list",
          items: {
            enum: [
              {
                text: "Card list",
                value: "card-list",
              },
              {
                text: "Dropdown list",
                value: "standard",
              },
            ],
          },
        },
        options: {
          title: "Items",
          type: "list",
          default: [],
          items: {
            type: "object",
            properties: {
              price: {
                title: "Price",
                type: "number",
                default: 0,
              },
              title: {
                title: "Title",
                type: "string",
                default: "",
              },
              subtitle: {
                title: "Subtitle",
                type: "string",
                default: "",
              },
              image: {
                title: "Image",
                type: "string",
                default: "",
              },
              level_ids: {
                title: "Levels",
                type: "array",
                default: [],
                items: {
                  title: "Items",
                  type: "number",
                  default: 0,
                },
              },
            },
          },
        },
      },
    },
  },
};
