<template>
  <div class="drop-down">
    <b-dropdown id="ddown" :text="dropdownTitle">
      <b-dropdown-item
        v-for="(lang, index) in dropdownOptions"
        :key="`video_tutorials-${index}-${lang.short}`"
      >
        <a class="link" @click="onLangClick(lang.short)">{{ lang.long }}</a>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "LanguageDropdown",
  props: {
    dropdownTitle: {
      type: String,
      default: "",
    },
    dropdownOptions: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    onLangClick(lang) {
      this.$emit("onClick", lang);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep #ddown {
  .btn {
    max-width: 290px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 30px 0 0;
    line-height: 1;
    @media only screen and (min-width: 468px) {
      max-width: initial;
      overflow: initial;
      text-overflow: initial;
    }
  }
  .dropdown-toggle {
    color: #107598;
    font-family: $vito-light;
    font-size: 14px;
    background-color: transparent;
    border: none;
    &:focus,
    &:active,
    &:hover {
      color: #333333;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
    &:after {
      color: #107598;
      border-top: 5px solid;
      border-right: 5px solid transparent;
      border-bottom: 0;
      border-left: 5px solid transparent;
      top: 25%;
    }
    > span {
      font-size: 18px;
      color: #107598;
    }
  }
  .dropdown-toggle[area-expanded="true"] {
    color: #107598;
    background-color: transparent;
    font-size: 14px;
  }
  .dropdown-item {
    display: block;
    height: auto;
    &:focus,
    &:active,
    &:hover {
      outline: 0;
    }
  }
  .link {
    display: block;
    width: 100%;
    padding: 15px 0;
    text-decoration: none;
  }
}
</style>
