<template>
  <div v-if="!loading || !access" class="training">
    <div v-if="!access" class="access-error">
      <h2>{{ $t("training.dont_have_access") }}</h2>
    </div>

    <div v-else>
      <div class="mobile-control">
        <b-dropdown :text="selectedCategoryTitle" class="dropdown-button dropdown-button--bordered">
          <b-dropdown-item
            v-for="(category, key) in videosCategory"
            :key="key"
            :data-value="category.id"
            @click="bttnUpdateVideoList"
            >{{ category.attributes.title }}</b-dropdown-item
          >
        </b-dropdown>
      </div>

      <div class="filter-block">
        <div class="bttn-radio" v-for="(category, key) in videosCategory" :key="key">
          <input
            :id="'v-filter-' + key"
            type="radio"
            class="bttn-radio__input"
            :value="category.id"
            v-model="selectedCategory"
            @change="updateVideoList"
          />

          <label :for="'v-filter-' + key" class="bttn-radio__label">{{
            category.attributes.title
          }}</label>
        </div>
      </div>

      <div class="video-section" v-if="isMobile">
        <div class="video-show">
          <h2 class="video-show__title">{{ selectedVideo.title }}</h2>

          <div class="video-show__origin">from <a href="#">Charidy Inc</a></div>

          <div class="video-show__unit">
            <iframe
              :src="selectedVideo.src"
              frameborder="0"
              webkitallowfullscreen=""
              mozallowfullscreen=""
              allowfullscreen=""
            ></iframe>
          </div>
        </div>

        <div class="video-list">
          <div
            class="video-thumbnail"
            v-for="video in filteredVideo"
            :data-id="video.id"
            :key="video.id"
            @click.prevent="updateSelectedVideo"
          >
            <div
              class="video-thumbnail__preview"
              :style="{ backgroundImage: 'url(' + video.attributes.thumbnail + ')' }"
            >
              <div v-if="video.attributes.src == ''" class="video-thumbnail__restricted">
                <svg viewBox="0 0 576 512">
                  <path
                    fill="currentColor"
                    d="M272.702 359.139c-80.483-9.011-136.212-86.886-116.93-167.042l116.93 167.042zM288 392c-102.556 0-192.092-54.701-240-136 21.755-36.917 52.1-68.342 88.344-91.658l-27.541-39.343C67.001 152.234 31.921 188.741 6.646 231.631a47.999 47.999 0 0 0 0 48.739C63.004 376.006 168.14 440 288 440a332.89 332.89 0 0 0 39.648-2.367l-32.021-45.744A284.16 284.16 0 0 1 288 392zm281.354-111.631c-33.232 56.394-83.421 101.742-143.554 129.492l48.116 68.74c3.801 5.429 2.48 12.912-2.949 16.712L450.23 509.83c-5.429 3.801-12.912 2.48-16.712-2.949L102.084 33.399c-3.801-5.429-2.48-12.912 2.949-16.712L125.77 2.17c5.429-3.801 12.912-2.48 16.712 2.949l55.526 79.325C226.612 76.343 256.808 72 288 72c119.86 0 224.996 63.994 281.354 159.631a48.002 48.002 0 0 1 0 48.738zM528 256c-44.157-74.933-123.677-127.27-216.162-135.007C302.042 131.078 296 144.83 296 160c0 30.928 25.072 56 56 56s56-25.072 56-56l-.001-.042c30.632 57.277 16.739 130.26-36.928 171.719l26.695 38.135C452.626 346.551 498.308 306.386 528 256z"
                  ></path>
                </svg>
              </div>
            </div>

            <div class="video-thumbnail__inner">
              <!--<div class="video-thumbnail__origin">from <span>Charidy Inc</span></div>-->
              <div class="video-thumbnail__title">{{ video.attributes.title }}</div>
            </div>
          </div>
          <video-modal ref="restrictedVideo"></video-modal>
        </div>
      </div>

      <div class="video-section" v-else>
        <div class="video-list">
          <div
            class="video-thumbnail"
            v-for="video in filteredVideo"
            :data-id="video.id"
            :key="video.id"
            @click.prevent="updateSelectedVideo"
          >
            <div
              class="video-thumbnail__preview"
              :style="{ backgroundImage: 'url(' + video.attributes.thumbnail + ')' }"
            >
              <div v-if="video.attributes.src == ''" class="video-thumbnail__restricted">
                <svg viewBox="0 0 576 512">
                  <path
                    fill="currentColor"
                    d="M272.702 359.139c-80.483-9.011-136.212-86.886-116.93-167.042l116.93 167.042zM288 392c-102.556 0-192.092-54.701-240-136 21.755-36.917 52.1-68.342 88.344-91.658l-27.541-39.343C67.001 152.234 31.921 188.741 6.646 231.631a47.999 47.999 0 0 0 0 48.739C63.004 376.006 168.14 440 288 440a332.89 332.89 0 0 0 39.648-2.367l-32.021-45.744A284.16 284.16 0 0 1 288 392zm281.354-111.631c-33.232 56.394-83.421 101.742-143.554 129.492l48.116 68.74c3.801 5.429 2.48 12.912-2.949 16.712L450.23 509.83c-5.429 3.801-12.912 2.48-16.712-2.949L102.084 33.399c-3.801-5.429-2.48-12.912 2.949-16.712L125.77 2.17c5.429-3.801 12.912-2.48 16.712 2.949l55.526 79.325C226.612 76.343 256.808 72 288 72c119.86 0 224.996 63.994 281.354 159.631a48.002 48.002 0 0 1 0 48.738zM528 256c-44.157-74.933-123.677-127.27-216.162-135.007C302.042 131.078 296 144.83 296 160c0 30.928 25.072 56 56 56s56-25.072 56-56l-.001-.042c30.632 57.277 16.739 130.26-36.928 171.719l26.695 38.135C452.626 346.551 498.308 306.386 528 256z"
                  />
                </svg>
              </div>
            </div>

            <div class="video-thumbnail__inner">
              <div class="video-thumbnail__title">{{ video.attributes.title }}</div>
            </div>
          </div>
          <video-modal ref="restrictedVideo" />
        </div>

        <div class="video-show">
          <div class="video-show__unit">
            <iframe
              :src="selectedVideo.src"
              frameborder="0"
              webkitallowfullscreen=""
              mozallowfullscreen=""
              allowfullscreen=""
            />
          </div>

          <div class="video-show__origin">from <a href="#">Charidy Inc</a></div>

          <h2 class="video-show__title">{{ selectedVideo.title }}</h2>
        </div>
      </div>

      <div class="static-block">
        <h2 class="h1-like">{{ $t("training.material_download_title") }}</h2>

        <div v-if="materialsDownload.length">
          <div class="row">
            <div
              class="col-12 col-sm-6 col-md-4"
              v-for="(material, index) in materialsDownload"
              :key="index"
            >
              <div class="static-plate">
                <div
                  class="static-plate__header"
                  :style="'background-image: url(' + material.attributes.thumbnail + ')'"
                />
                <div class="static-plate__body">
                  <h3 class="static-plate__title">
                    {{ material.attributes.title | shorten }}
                  </h3>
                  <div class="static-plate__action">
                    <a
                      v-if="material.attributes.src"
                      :href="material.attributes.src"
                      class="bttn bttn--xs bttn--orange"
                      download
                      >Download</a
                    >
                    <a
                      v-else
                      href=""
                      @click.prevent="videoRestricted"
                      class="bttn bttn--xs bttn--orange"
                      >Download</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-12 text-center">
              {{ $t("training.material_download_not_awailable") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading" v-else>
    <app-spinner />
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "../../store/store.js";
import tooltip from "../../components/tooltip.vue";
import videoModal from "./videoModal.vue";
import appSpinner from "@/common-components/ui-elements/app-spinner.vue";

export default {
  data() {
    return {
      selectedCategory: 1,
      selectedCategoryTitle: "",
      filteredVideo: null,
      selectedVideo: {},
      materialsDownload: {},
      isMobile: false,
    };
  },

  components: {
    tooltip,
    videoModal,
    appSpinner,
  },

  created() {
    if (this.access) {
      store.dispatch("getVideo");
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    this.$nextTick(() => {
      if (!this.loading) {
        this.updateVideoList();
        this.updateMaterialDownload();
        this.getTitle();
      }
    });
  },

  computed: mapState({
    videosCategory: (state) => {
      return state.video.video.data;
    },

    loading: (state) => {
      return state.video.loadingVideo;
    },

    access: (state) => {
      return state.account.access.CanSeeTrainingVideos;
    },

    included: (state) => state.video.video.included,
  }),

  watch: {
    loading: function () {
      if (this.loading === false) {
        this.updateVideoList();
        this.updateMaterialDownload();
        this.getTitle();
      }
    },

    included: {
      handler() {
        this.updateVideoList();
      },
      deep: true,
    },
  },

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },

  methods: {
    getVideoById: function (id) {
      let video = this.included.filter((el) => {
        return el.id == id;
      });

      return video[0].attributes;
    },

    getTitle() {
      let selectedTitle = this.videosCategory.filter((el) => {
        return el.id == this.selectedCategory;
      });
      return (this.selectedCategoryTitle = selectedTitle[0].attributes.title);
    },

    bttnUpdateVideoList(e) {
      this.selectedCategory = e.currentTarget.dataset.value;
      this.getTitle();
      this.updateVideoList();
    },

    updateVideoList() {
      let video = [];

      if (this.included) {
        video = this.included
          .filter((el) => {
            if (el.type === "video") {
              return el.attributes.video_category_id == this.selectedCategory;
            }
          })
          .sort(function (a, b) {
            return a.id - b.id;
          });
      }

      this.filteredVideo = video;

      if (video.length > 0) {
        this.selectedVideo = video[0].attributes;
      }

      // set initial video after filter list
      this.updateMaterialDownload();
    },

    updateSelectedVideo(e) {
      let video = this.included.filter((el) => {
        if (el.type == "video") {
          return el.id == e.currentTarget.dataset.id;
        }
      });

      if (video[0].attributes.src != "") {
        this.selectedVideo = video[0].attributes;
      } else {
        this.videoRestricted();
      }
    },

    updateMaterialDownload() {
      let materials = this.included
        .filter((el) => {
          if (el.type == "media_download") {
            return el.attributes.video_category_id == this.selectedCategory;
          }
        })
        .sort((a, b) => {
          return a.id - b.id;
        });

      this.materialsDownload = materials;
    },

    videoRestricted() {
      this.$refs.restrictedVideo.showModal();
    },

    onResize() {
      this.isMobile = window.innerWidth < 768;
    },
  },

  filters: {
    shorten(value) {
      return value.length > 30 ? value.slice(0, 30) + " ..." : value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_vars.scss";

.training {
  padding-left: 0;
}

.access-error h2 {
  text-align: center;
  width: 100%;
}

.mobile-control {
  display: flex;
  margin-bottom: 25px;
  @media only screen and (min-width: 768px) {
    display: none;
  }
  ::v-deep .b-dropdown {
    .dropdown-item {
      display: block;
      width: 290px;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 39px;
    }
  }
}

.filter-block {
  display: none;
  @media only screen and (min-width: 768px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    display: flex;
    margin-right: -5px;
    margin-left: -5px;
    overflow-x: auto;
    .bttn-radio {
      margin: 5px;
      flex-shrink: 0;
    }
  }
}

.video-section {
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 768px) {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  @media only screen and (min-width: 1600px) {
    justify-content: flex-start;
  }
}

.video-list {
  margin-bottom: 20px;
  padding-right: 20px;
  overflow: hidden;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
    flex-direction: column;
    max-height: 370px;
    overflow-x: visible;
    overflow-y: auto;
    min-width: 310px;
    max-width: 310px;
  }
  @media only screen and (min-width: 1600px) {
    min-width: 390px;
    max-width: 390px;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #107598;
  }
}

.video-thumbnail {
  display: flex;
  min-width: 290px;
  height: 101px;
  min-height: 101px;
  cursor: pointer;
  &:not(:last-of-type) {
    margin-bottom: 10px;
    @media only screen and (max-width: 767px) {
      margin-right: 25px;
    }
  }
  &__preview {
    width: 152px;
    min-width: 152px;
    //height: 101px;
    margin-right: 10px;
    background-color: $bg-20;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }
  &__restricted {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    svg {
      display: block;
      width: 58px;
      height: auto;
      opacity: 0.8;
    }
  }
  &__title {
    font-family: $vito-bold;
    font-size: 18px;
    color: $fd;
    line-height: 1.125;
  }
  &__origin {
    font-size: 16px;
    > span {
      color: $bb-100;
    }
  }
}

.video-show {
  width: 100%;
  //max-width: 826px;
  @media only screen and (min-width: 768px) {
    padding-left: 20px;
  }
  &__unit {
    width: 100%;
    margin-bottom: 40px;
    //max-width: 806px;
    background-color: gray;
    padding-bottom: 56.4%;
    position: relative;
    iframe {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  &__origin {
    margin-bottom: 20px;
    color: $fd;
    > a {
      color: $bb-100;
    }
  }
  &__title {
    margin: 0;
    font-family: $vito-bold;
    font-size: 40px;
    color: $fd;
    text-transform: capitalize;
  }
}

.video-block {
  margin-bottom: 20px;
  color: $fd;
  .h2-like {
    margin-bottom: 20px;
  }
}

.static-block {
  .h1-like {
    text-align: center;
    margin-bottom: 45px;
  }
}
.static-plate {
  width: 100%;
  box-shadow: $shadow-th;
  background-color: #ffffff;
  margin-bottom: 30px;
  &__header {
    width: 100%;
    height: 199px;
    background-color: $bg-20;
    background-position: center center;
    background-size: cover;
  }
  &__body {
    padding: 45px 30px;
  }
  &__title {
    width: 100%;
    margin-bottom: 16px;
    font-size: 26px;
    text-align: center;
    min-height: 62px;
    max-height: 62px;
    overflow: hidden;
  }
  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    .bttn:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}

.video-wrapper {
  margin-right: -15px;
  margin-left: -15px;
  display: flex;
  flex-wrap: wrap;
}

.video-item {
  margin: 10px 15px 15px 15px;
  width: 100%;
  max-width: 365px;

  &__title {
    font-size: 18px;
    margin: 0 0 5px 0;
  }

  &__description {
    font-size: 14px;
    line-height: 1;
    color: $fd;
    margin: 0;
  }
}

::v-deep .dropdown-button {
  .btn {
    background-color: transparent;
    color: #414359;
    font-family: "VitoExtended-Light";
    height: 48px;
    padding: 0 24px;
    border-radius: 24px;
    border-color: #107598;
    &.dropdown-toggle:after {
      vertical-align: 2px;
    }
  }
  &.show {
    .btn {
      background-color: #107598;
      border-color: #107598;
      color: #ffffff;
    }
  }
}
</style>
