const confirmMixin = {
  methods: {
    $_confirmMixin_deleteConfirm(options = {}) {
      const warnItem = options.warnItem || "all selected items";
      const message = options.message || `Do you want to delete ${warnItem}?`;
      const title = options.title || "This action cannot be undone!";
      return this.$parent.$bvModal.msgBoxConfirm(message, {
        title,
        okVariant: "danger",
        cancelVariant: "success",
        centered: true,
      });
    },
    $_confirmMixin_genericConfirm(options = {}) {
      const warnItem = options.warnItem || "all selected items";
      const action = options.action || "perform this action on";
      const message = options.message || `Do you want to ${action} ${warnItem}?`;
      const title = options.title || "This action cannot be undone!";
      return this.$parent.$bvModal.msgBoxConfirm(message, {
        title,
        okVariant: "danger",
        cancelVariant: "success",
        centered: true,
      });
    },
  },
};

export default confirmMixin;
