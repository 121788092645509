<template>
  <div class="rds-page__outer-block">
    <category-title
      :title="$t('dashboard.title_recurring_donations_summary', 'Recurring donations summary')"
    >
    </category-title>
    <div class="dashboard-block">
      <div class="rds-page">
        <div class="rds-page__block">
          <rds-filters />
          <rds-status />
        </div>
        <rds-total-stats />
        <div class="rds-page__block">
          <rds-period />
          <rds-charges-stats />
        </div>
      </div>
    </div>
    <div class="rds-page__table-block">
      <rds-table />
    </div>
  </div>
</template>

<script>
import rdsFilters from "./rds-filters.vue";
import rdsChargesStats from "./rds-charges-stats.vue";
import rdsPeriod from "./rds-period.vue";
import rdsTable from "./rds-table.vue";
import rdsTotalStats from "./rds-total-stats.vue";
import rdsStatus from "./rds-status.vue";
import categoryTitle from "@/common-components/ui-elements/category-title.vue";

export default {
  components: {
    rdsFilters,
    rdsChargesStats,
    rdsPeriod,
    rdsTable,
    rdsTotalStats,
    rdsStatus,
    categoryTitle,
  },
};
</script>

<style lang="scss" scoped>
.rds-page__outer-block {
  --border: 1px solid #e6eaed;
  --border-darker: 1px solid #bcc0c2;
}

::v-deep .dashboard-block {
  padding-bottom: 0px;
  @media only screen and (max-width: 414px) {
    width: min-content;
  }
}
.rds-page {
  display: grid;
  grid-auto-flow: row;
  gap: 20px;
  border-bottom: 1px solid #e6eaed;
  &__block {
    display: flex;
    flex-wrap: wrap;
  }
  &__outer-block {
    display: grid;
    grid-auto-flow: row;
  }
  &__table-block {
    background: #fff;
  }
  &:last-child {
    padding-bottom: 20px;
  }
}
</style>
