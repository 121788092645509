<template>
  <div class="email-confirm-wrapper">
    <div class="email-confirm" v-if="success">
      <div class="email-confirm__image">
        <img
          width="120"
          height="120"
          src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/public_upload/1709060043_3738653632323166363339336431333536363831646233393866313463653664_31373039303630303433.png"
        />
      </div>

      <div class="email-confirm__result">
        {{ $t("email_confirm.email_verified") }}
      </div>

      <div class="email-confirm__action">
        <a href="https://charidy.com" class="bttn bttn--lg bttn--blue"> Home </a>

        <a href="/" class="bttn bttn--lg bttn--orange"> Log in </a>
      </div>
    </div>
    <div class="email-confirm-error" v-else>
      <a href="https://www.charidy.com" class="charidy-brand">
        <CdnLogoByLang />
      </a>
      <div class="email-confirm__result">
        <error-message ref="error"></error-message>
      </div>

      <div class="email-confirm__action">
        <a href="/" class="bttn bttn--lg bttn--orange"> Proceed to Log in </a>
      </div>
    </div>
  </div>
</template>

<script>
import urls from "../constants/urls.js";
import axios from "axios";
import errorList from "@/components/error-message.vue";
import CdnLogoByLang from "@/common-components/cdn-v2/cdn-logo-by-lang.vue";

export default {
  name: "email-confirm",

  data: function () {
    return {
      success: false,
    };
  },
  components: {
    errorMessage: errorList,
    CdnLogoByLang,
  },

  computed: {
    tokenVerify: function () {
      return this.$router.currentRoute.params.tokenVerify;
    },
  },

  methods: {
    checkToken: function () {
      axios
        .post(urls.checkToken.replace(":token", this.tokenVerify))
        .then((response) => {
          if (response.data) {
            this.success = true;
          }
        })
        .catch((e) => {
          this.$refs.error.setErrorResponse(e.response);
        });
    },
  },

  created: function () {
    this.checkToken();
  },
};
</script>

<style lang="scss" scoped>
.email-confirm-wrapper {
  width: 100%;
  min-height: 100vh;
  padding: 20px 15px;
  background-color: #fbfdff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-confirm {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    width: 120px;
    height: 120px;
    margin-bottom: 40px;
  }

  &__result {
    margin-bottom: 20px;
    font-family: "VitoExtended-Bold";
    font-size: 24px;
    text-align: center;
    color: #3cc7a0;
  }

  &__message {
    margin-bottom: 40px;
    font-family: "VitoExtended-Light";
    font-size: 18px;
    text-align: center;
  }

  &__action {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .bttn {
      margin: 10px;
    }
    .bttn--app {
      background-color: #ffffff;
      color: #f26e66;
      border: 1px solid #f26e66;
    }
  }
}

.email-confirm-error {
  width: 100%;
  max-width: 568px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #eaf4ff;
  padding: 20px;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1);
}
.charidy-brand {
  display: inline-block;
  width: 100%;
  max-width: 220px;
  svg {
    display: block;
    width: 100%;
    height: 76px;
  }
}
</style>
