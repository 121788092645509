<script>
import { mapState, mapGetters } from "vuex";
import CustomVideo from "@/components/vue-quill-editor/formats/custom-video";
import { unwrapContentValue } from "@/components/vue-quill-editor/utils";
import { createSetting, getSetting, updateSetting } from "@/helpers/campaign-settings";
import { notificationsMixin } from "@/mixins";
import { getDirection } from "@/store/modules/translation";

export default {
  mixins: [notificationsMixin],
  props: {
    hasAccess: {
      type: Boolean,
      default: false,
    },
    orgId: {
      type: String,
      required: true,
    },
    campaignId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      content: "",
      contentOriginal: "",
      contentId: null,
      unidyClassicProgressAndCountdownBarVue: null,
      languageSet: [],
      languageSelected: "",
      editorDirection: "ltr",
    };
  },
  computed: {
    ...mapState({
      locale: (state) => state.i18n.locale,
    }),
    ...mapGetters({
      getOldLangsVariant: "getOldLangsVariant",
    }),
    editorOptions() {
      return {
        modules: {
          toolbar: {
            container: [
              ["bold", "underline", "strike", "italic"],
              ["blockquote"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ align: [] }],
              [{ color: [] }, { background: [] }],
              ["clean"],
              ["image"],
              [CustomVideo.blotName],
              ["link"],
            ],
          },
          imageResize: {
            modules: ["Resize", "DisplaySize"],
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
          },
        },
      };
    },
    safeLocale() {
      if (this.getOldLangsVariant.map((lv) => lv.short).includes(this.locale)) {
        return this.locale;
      }
      return "en";
    },
    langCustomHtml() {
      return this.unidyClassicProgressAndCountdownBarVue?.lang_custom_html || {};
    },
    languagesAdded() {
      const langs = Object.keys(this.langCustomHtml);
      if (!langs.length) {
        return [this.safeLocale];
      }
      return langs;
    },
  },
  methods: {
    getProgressBarMessage() {
      return getSetting(
        {
          cid: this.campaignId,
          oid: this.orgId,
        },
        "unidy_classic_progress_and_countdown_bar_vue"
      )
        .then((data) => {
          this.contentId = data.id;
          this.unidyClassicProgressAndCountdownBarVue = data?.attributes?.meta_data || {};
          this.content =
            this.unidyClassicProgressAndCountdownBarVue?.lang_custom_html[this.languageSelected] ||
            "";
          this.contentOriginal = this.unidyClassicProgressAndCountdownBarVue?.custom_html || "";
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
    updateProgressBarMessage({ extraData = {} } = {}) {
      const data = {
        ...this.unidyClassicProgressAndCountdownBarVue,
        value: true,
        custom_html: unwrapContentValue(this.contentOriginal),
        ...extraData,
      };
      const success = () => {
        this.$_notificationsMixin_makeToast("Success", this.$t("edit_campaign.success_edit"));
        return this.getProgressBarMessage();
      };

      if (!this.contentId) {
        return createSetting(
          {
            cid: this.campaignId,
            oid: this.orgId,
          },
          "unidy_classic_progress_and_countdown_bar_vue",
          data
        )
          .then(success)
          .catch(this.$_notificationsMixin_handleCatch);
      }

      return updateSetting(
        {
          cid: this.campaignId,
          oid: this.orgId,
        },
        "unidy_classic_progress_and_countdown_bar_vue",
        this.contentId,
        data
      )
        .then(success)
        .catch(this.$_notificationsMixin_handleCatch);
    },
    unique(arr, arr2) {
      let result = [];
      nextInput: for (let i = 0; i < arr.length; i++) {
        let str = arr[i];
        for (let j = 0; j < arr2.length; j++) {
          if (arr2[j] == str.short) continue nextInput;
        }
        result.push(str.short);
      }
      return result;
    },
    getLangFullName(lang) {
      return this.getOldLangsVariant.find((item) => item.short === lang)?.long || lang;
    },
    setLangForAdd() {
      let allLang = this.getOldLangsVariant;
      let addedLang = this.languagesAdded;
      let languageAvailable = this.unique(allLang, addedLang);
      this.languageSet = languageAvailable;
    },
    async addLanguage(val) {
      this.languageSelected = val;

      await this.updateProgressBarMessage({
        extraData: {
          lang_custom_html: {
            ...this.langCustomHtml,
            [val]: "",
          },
        },
      });
      this.content = "";

      this.editorDirection = getDirection(val);
      this.setLangForAdd();
    },
    isSelected(lang) {
      this.languageSelected = lang;
      this.editorDirection = getDirection(lang);
      this.content = this.langCustomHtml[lang] || "";
    },
    async save() {
      await this.updateProgressBarMessage({
        extraData: {
          lang_custom_html: {
            ...this.langCustomHtml,
            [this.languageSelected]: unwrapContentValue(this.content),
          },
        },
      });
    },
    async deleteLang(lang) {
      const customLangsFiltered = Object.keys(this.langCustomHtml).reduce((acc, key) => {
        if (key !== lang) {
          acc[key] = this.langCustomHtml[key];
        }
        return acc;
      }, {});
      await this.updateProgressBarMessage({
        extraData: {
          lang_custom_html: customLangsFiltered,
        },
      });
      this.isSelected(this.safeLocale);
    },
  },
  mounted() {
    this.getProgressBarMessage();
    this.setLangForAdd();

    if (this.languagesAdded.length > 0) {
      this.languageSelected = this.languagesAdded[0];
      this.editorDirection = getDirection(this.languageSelected);
    }
  },
};
</script>

<template>
  <div class="custom-message">
    <div class="custom-message__label">
      {{ $t("edit_campaign.custom_note_under_progress_bar") }}
    </div>
    <ul class="language-selected">
      <li
        v-for="lang in languagesAdded"
        :key="`custom-progress-bar-select-lang-${lang}`"
        @click="isSelected(lang)"
        class="language-selected__unit"
        :class="{ active: languageSelected === lang }"
        v-text="getLangFullName(lang)"
      />
      <b-dropdown
        id="ddown1"
        text="+ Add language"
        class="dropdown"
        variant="-sm bttn--bordered-blue"
        :disabled="!hasAccess"
      >
        <b-dropdown-item
          v-for="lang in languageSet"
          :key="`custom-progress-bar-add-lang-${lang}`"
          :disabled="!hasAccess"
          @click="addLanguage(lang)"
        >
          {{ getLangFullName(lang) }}
        </b-dropdown-item>
      </b-dropdown>
    </ul>
    <quill-editor
      emitWithChangeSource
      v-model="content"
      :enable="hasAccess"
      :options="editorOptions"
      :editor-direction="editorDirection"
    />
    <button class="mx-1 bttn bttn--sm bttn--blue" @click.prevent="save">
      {{ $t("edit_campaign.custom_note_upd_text_btn") }}
    </button>
    <button
      v-if="languagesAdded.length > 1"
      type="button"
      class="mx-1 bttn bttn--lg bttn--orange bttn--inverted-orange"
      @click="deleteLang(languageSelected)"
      name="button"
    >
      {{
        $t("edit_campaign.delete", {
          langName: getLangFullName(languageSelected),
        })
      }}
    </button>
  </div>
</template>

<style lang="scss" scoped>
.custom-message {
  margin-bottom: 30px;
  &__label {
    margin-bottom: 5px;
    font-size: 14px;
    color: $fd;
    text-transform: uppercase;
  }
  .quill-editor {
    margin-bottom: 15px;
  }
  .ql-container {
    height: 100px;
  }
  .language-selected {
    $border-radius: 19px;
    $height: 38px;
    $skeleton-width: 120px;

    display: inline-flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 20px;
    list-style: none;
    &__unit {
      padding: 0 20px;
      height: $height;
      margin: 0 5px 5px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $bb-100;
      color: $fd;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      border-radius: $border-radius;
      cursor: pointer;
      &.b-skeleton-text {
        border-radius: $border-radius;
        width: $skeleton-width;
        height: $height;
      }
      &.dirty {
        border-width: 2px;
        border-color: $danger !important;
      }
      &.active {
        color: #ffffff;
        background-color: $bb-100;
      }
      .btn-group {
        display: flex;
        width: 100%;
        height: 100%;
      }
    }
    &__tab {
      &--active {
        color: #ffffff !important;
        background-color: $bb-100 !important;
      }
      &--load {
        width: 100px;
      }
    }
    &__add {
      display: inline-flex;
      height: 40px;
      border: 1px solid rgba(32, 67, 78, 0.15);
    }
    .language-add {
      width: 100%;
      padding: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      text-transform: uppercase;
      cursor: pointer;
    }
    ::v-deep .btn-group {
      display: flex;
      &.open {
        .dropdown-toggle {
          box-shadow: none;
        }
        .dropdown-menu {
          width: 100%;
          min-width: initial;
          padding: 0;
          li {
            padding: 10px;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            &:not(:last-of-type) {
              border-bottom: 1px solid lightgray;
            }
          }
        }
      }
    }
    ::v-deep .btn.btn-secondary.dropdown-toggle {
      background: transparent;
      border: 1px solid rgba(32, 67, 78, 0.15);
      color: #20434e;
      font-size: 16px;
      font-weight: 400;
      text-transform: uppercase;
      cursor: pointer;
      height: 42px;
      border-radius: 0;
    }
    ::v-deep .dropdown-menu {
      color: #20434e;
    }
  }
}
</style>
