<template>
  <div>
    <div class="data-view" v-for="(value, name) in editMetaFields" :key="name">
      <div class="data-view__title">
        {{ name }}
      </div>
      <div v-if="name === 'charidy_legal_entity_id'" class="data-view__data">
        {{ value }}
      </div>
      <div v-else class="data-view__data">
        <b-form-input
          :value="value"
          v-on:input="updateData(name, $event)"
          type="text"
          required
        ></b-form-input>
      </div>
    </div>
    <button class="bttn bttn--lg bttn--orange el-right" @click.prevent="updateMeta">
      {{ $t("integration.update_taxgift", "Update") }}
    </button>
  </div>
</template>

<script>
export default {
  props: ["meta"],

  data: function () {
    return {
      editMetaFields: null,
    };
  },

  created() {
    this.editMetaFields = this.meta;
  },

  methods: {
    updateMeta() {
      this.$emit("update-meta", this.editMetaFields, "taxgift");
    },
    updateData(name, value) {
      this.editMetaFields[name] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-right {
  float: right;
}
.card {
  width: 100%;
}
.select {
  width: 50%;
}
.card-body {
  display: flex;
  align-items: center;
}
.type-input {
  height: 50px;
  width: 100%;
  margin: 0;
}
</style>
