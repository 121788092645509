<template>
  <div>
    <slot
      :perPage="perPage"
      :page="page"
      :updatePage="(e) => (page = e)"
      :rows="rows"
      :list="list"
      :loading="loading"
      :loadingSort="loadingSort"
      :remove="onRemove"
      :changeItemSort="onChangeItemSort"
      :sortChange="onSortChange"
      :searchChange="onSearchChange"
      :onRemoveMultiple="onRemoveMultiple"
      :onAfterModalSubmit="onAfterModalSubmit"
      :currentFetchParams="currentFetchParams"
      :getPages="getPages"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      loading: false,
      loadingSort: false,
      perPage: 19,
      page: 1,
      list: [],
      rows: 0,
      status: null,
      query: null,
    };
  },
  computed: {
    ...mapState({
      cmpId: (state) => state.campaign.campaign.data.id,
    }),
    currentFetchParams() {
      return {
        page: this.page,
        per_page: this.perPage,
        sort_by: "-sort",
        extend: ["parent", "learner"],
        status: this.status,
        query: this.query,
      };
    },
  },
  methods: {
    ...mapActions({
      fetchPagesPublic: "seferTorahPages/fetchPagesPublic",
      fetchOrgDeletePageNoTeam: "seferTorahPages/fetchOrgDeletePageNoTeam",
      fetchOrgEditPageNoTeam: "seferTorahPages/fetchOrgEditPageNoTeam",
    }),
    modifyListAndCount(list, count) {
      this.list = list;
      this.rows = count;
    },
    async onAfterModalSubmit(list, count) {
      console.log("SeferTorahPage.vue onAfterModalSubmit");
      this.loading = true;
      try {
        this.modifyListAndCount(list, count);
        await this.ensureUniqueItemSort();
      } finally {
        this.loading = false;
      }
    },
    async getPages() {
      this.loading = true;
      try {
        const { data, count } = await this.fetchPagesPublic({
          cId: this.cmpId,
          params: {
            per_page: this.perPage,
            page: this.page,
            extend: ["parent", "learner"],
            sort_by: "-sort",
          },
        });
        this.modifyListAndCount(data, count);
      } finally {
        this.loading = false;
      }
    },
    async getPagesSort({ ...params } = {}) {
      console.log("SeferTorahPage.vue getPagesSort");
      const { data, count } = await this.fetchPagesPublic({
        cId: this.cmpId,
        params: {
          per_page: this.perPage,
          page: this.page,
          extend: ["parent", "learner"],
          sort_by: "-sort",
          ...params,
        },
      });
      this.modifyListAndCount(data, count);
    },
    onSortChange(ctx, { status }) {
      this.loadingSort = true;
      this.status = status;
      this.query = ctx.query;
      this.getPagesSort({ status, query: ctx.query }).finally(() => {
        this.loadingSort = false;
      });
    },
    onSearchChange(ctx, value) {
      this.loadingSort = true;
      this.query = value;
      this.status = ctx.status;
      this.getPagesSort({ query: value, status: ctx.status }).finally(() => {
        this.loadingSort = false;
      });
    },
    onRemove(id) {
      this.remove(id);
    },
    onRemoveMultiple(items) {
      if (items.length === 0) {
        return;
      }

      this.removeMultiple(items);
    },
    async updateItem(item) {
      const { extend, ...attributes } = item.attributes;
      const payload = {
        cId: this.cmpId,
        data: { attributes: { ...attributes } },
        params: {
          ...this.currentFetchParams,
          team_id: extend.learner?.id,
        },
      };
      const { data, count } = await this.fetchOrgEditPageNoTeam({
        ...payload,
        id: item.id,
      });
      this.modifyListAndCount(data, count);
    },
    async deleteItem(itemId) {
      const { data, count } = await this.fetchOrgDeletePageNoTeam({
        cId: this.cmpId,
        id: itemId,
        params: {
          ...this.currentFetchParams,
        },
      });
      this.modifyListAndCount(data, count);
    },
    onChangeItemSort(item_1, direction) {
      this.changeItemSort(item_1, direction);
    },
    async changeItemSort(item_1, direction) {
      this.loadingSort = true;

      const items = structuredClone(this.list);

      // Find the index of the item to adjust
      const index = items.findIndex((item) => Number(item.id) === Number(item_1.id));
      if (index === -1) {
        throw new Error("Item with the specified ID not found.");
      }

      // Swap the order with the next or previous item based on the direction
      if (direction === "increase" && index > 0) {
        // Swap with the next item
        let temp = items[index].attributes.sort;
        items[index].attributes.sort = items[index - 1].attributes.sort;
        items[index - 1].attributes.sort = temp;
      } else if (direction === "decrease" && index < items.length - 1) {
        // Swap with the previous item
        let temp = items[index].attributes.sort;
        items[index].attributes.sort = items[index + 1].attributes.sort;
        items[index + 1].attributes.sort = temp;
      }

      try {
        await Promise.allSettled(items.map((item) => this.updateItem(item)));
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        return;
      } finally {
        this.loadingSort = false;
      }
    },
    async normalizeItemSort() {
      console.log("SeferTorahPage.vue normalizeItemSort");
      this.loadingSort = true;
      const list = structuredClone(this.list);
      let modifiedObjects = [];

      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        // Calculate the new order value
        const newOrder = list.length - i;
        // Check if the order value is actually going to be modified
        if (item.attributes.sort !== newOrder) {
          // Update the order
          item.attributes.sort = newOrder;
          // Add the modified object to the modifiedObjects array
          modifiedObjects.push(item);
        }
      }

      try {
        await Promise.allSettled(list.map((item) => this.updateItem(item)));
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        return;
      } finally {
        this.loadingSort = false;
      }
    },
    checkForRepetitiveSort(arr) {
      console.log("SeferTorahPage.vue checkForRepetitiveSort");
      const values = new Set();

      for (const item of arr) {
        if (values.has(item.attributes.sort)) {
          return true; // Found a repetitive sort value
        }
        values.add(item.attributes.sort);
      }

      return false; // No repetitive order values found
    },
    async ensureUniqueItemSort() {
      console.log("SeferTorahPage.vue ensureUniqueItemSort");
      if (this.checkForRepetitiveSort(this.list)) {
        await this.normalizeItemSort();
      }
    },
    async remove(id) {
      const message = this.$t("dashboard.sefer_torah_confirm_delete", "Please confirm page delete");

      const options = {
        okText: this.$t("account.confirm_delete", "Delete"),
        cancelText: this.$t("account.confirm_cancel", "Cancel"),
      };

      await this.$dialog.confirm(message, options);

      this.loading = true;
      try {
        await this.deleteItem(id);

        await this.normalizeItemSort();
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        return;
      } finally {
        this.loading = false;
      }
    },
    async removeMultiple(items) {
      const formatter = new Intl.ListFormat("en", {
        style: "long",
        type: "conjunction",
      });

      const names = items.map((item) => item.attributes.title);
      const message = this.$t(
        "dashboard.sefer_torah_confirm_delete_multiple",
        `Please confirm delete {names}`,
        { names: formatter.format(names) },
        names.length
      );
      const options = {
        okText: this.$t("account.confirm_delete", "Delete"),
        cancelText: this.$t("account.confirm_cancel", "Cancel"),
      };

      await this.$dialog.confirm(message, options);

      this.loading = true;
      try {
        await Promise.allSettled(items.map((item) => this.deleteItem(item.id)));

        await this.normalizeItemSort();
      } catch (e) {
        this.$_notificationsMixin_handleCatch(e);
        return;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getPages().then(() => {
      return this.ensureUniqueItemSort();
    });
  },
  watch: {
    page() {
      this.getPages().then(() => {
        return this.ensureUniqueItemSort();
      });
    },
  },
};
</script>
