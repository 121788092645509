<template>
  <div v-if="CreateOfflineCampaignDonation && !CanSeeCampaignDashboard">
    <button class="add-cerfa-btn" @click="open">
      {{ $t("dashboard.add_button_title", "Add offline donation") }}
    </button>

    <DonationAddModal ref="DonationAddModal" hideAddBtn inlineSelectCampaign />
  </div>
</template>

<script>
import { mapState } from "vuex";
import DonationAddModal from "@/views/dashboard/subcomponents/donationAddModal.vue";

export default {
  components: { DonationAddModal },

  data() {
    return {
      cmp: null,
    };
  },

  computed: {
    ...mapState({
      CreateOfflineCampaignDonation: (state) => state.account.access.CreateOfflineCampaignDonation,
      CanSeeCampaignDashboard: (state) => state.account.access.CanSeeCampaignDashboard,
    }),
  },

  methods: {
    open() {
      this.$nextTick(() => {
        this.$refs.DonationAddModal.openModal();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-cerfa-btn {
  width: 100%;
  padding: 15px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  background-color: $bc-90;
  border-color: $bc-100;
  color: #ffffff;
  outline: none;
  font-size: 16px;
  &:hover {
    color: #fff;
    font-family: $vito-bold;
    text-decoration: none;
  }
}
</style>
