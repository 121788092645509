<template>
  <b-card class="account-card">
    <h2 slot="header" class="account-card__title">
      {{ $t("account.org_notifications_from_email", "Notification custom from email") }}
      <tooltip
        :title="$t('account.org_notifications_from_email_input_description',
          'These are the name and email to be used as the \'reply-to\' for the donation confirmation email, receipt email, and any other outgoing email notifications. All emails will still come from support@charidy.com, but replies will be redirected to this email address. If you want all emails to be sent from your own email address, please contact your Campaign Manager/Tech Support for assistance.'
        )" class="mx-1" />
    </h2>

    <div class="email-list">
      <form id="email-list-add-form" class="email-list-add-form" @submit.prevent="save">
        <b-form-group
          class="w-100"
          :label="''"
        >
          <b-form-input
            v-model="name"
            name="displayName"
            pattern="^[a-zA-Z]+(?:\s[a-zA-Z]+){0,29}$"
            title="Display name must be 1 to 30 words, letters only, separated by spaces."
            placeholder="Enter name"
            class="mb-2"
            type="text"
          />
          <b-form-input
            v-model="email"
            name="email"
            placeholder="Enter email"
            class="mb-2"
            type="email"
          />
        </b-form-group>
      </form>

      <div class="email-list-actions">
        <button class="bttn bttn--sm bttn--orange" form="email-list-add-form" type="submit">
          {{ $t("account.save_change") }}
        </button>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Tooltip from "@/common-components/tooltip.vue";
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],

  components: {
    Tooltip,
  },

  data() {
    return {
      name: "",
      email: "",
    };
  },

  computed: {
    ...mapState({
      notificationsFromEmailName: (s) =>
        s.account.account.data.attributes.notifications_from_email_name,
      notificationsFromEmail: (s) => s.account.account.data.attributes.notifications_from_email,
    }),
  },

  watch: {
    notificationsFromEmailName: {
      handler(val) {
        this.name = val;
      },
      immediate: true,
    },
    notificationsFromEmail: {
      handler(val) {
        this.email = val;
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      saveNotificationsFromEmail: "saveNotificationsFromEmail",
    }),

    save() {
      const payload = {
        name: this.name,
        email: this.email,
      };

      this.saveNotificationsFromEmail({ payload })
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch("updateAccountOrg");
          }, 300);

          this.$_notificationsMixin_makeToast(
            "Success!",
            this.$t("account.org_notify_emails_save", "Saved successfully"),
            "success"
          );
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
  },
};
</script>

<style lang="scss" scoped>
.email-list {
  &-item {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
    align-items: center;
  }
  &-add-form {
    display: flex;
    margin-bottom: 15px;
  }
  &-actions {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
