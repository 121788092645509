import { singleLineString } from "@/helpers/filters";
import _Quill from "quill";

const Quill = window.Quill || _Quill;
const BlockEmbed = Quill.import("blots/block/embed");
const Link = Quill.import("formats/link");

const ATTRIBUTES = ["height", "width"];

class CustomVideo extends BlockEmbed {
  static create(value) {
    const node = super.create(value);
    const dataSrc = this.sanitize(value?.src || value || "");
    const dataSrcType = this.sanitize(value?.srcType || "");
    node.setAttribute("data-src", dataSrc);
    node.setAttribute("data-src-type", dataSrcType);
    node.appendChild(this.chooseElementBasedOnUrl(dataSrc, "dom"));

    return node;
  }

  static formats(domNode) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  static sanitize(url) {
    return Link.sanitize(url);
  }

  static getSrcType(url) {
    const isUrlContainsExt = (url, ext) => {
      if (!url) {
        return false;
      }

      return url.toLowerCase().indexOf(ext) !== -1;
    };

    if (isUrlContainsExt(url, ".mp4")) {
      return "video/mp4";
    } else if (isUrlContainsExt(url, ".mov")) {
      return "video/quicktime";
    } else {
      return null;
    }
  }

  static value(domNode) {
    const src = domNode.dataset?.src ?? "";
    const srcType = domNode.dataset?.srcType ?? this.getSrcType(src);

    return {
      srcType,
      src,
    };
  }

  static chooseElementBasedOnUrl(url, type) {
    const srcType = this.getSrcType(url);

    if (!srcType) {
      return this.createIframeElement(url, type);
    }

    return this.createVideoElement(url, srcType, type);
  }
  static createVideoElement(src, srcType, nodeType = "dom") {
    const style = "display:inline-block;max-width:400px;";
    if (nodeType === "dom") {
      const video = document.createElement("video");
      video.muted = true;
      video.controls = true;
      video.classList = ["c-custom-video"];
      video.style = style;

      const source = document.createElement("source");
      source.src = src;
      source.type = srcType;

      video.appendChild(source);
      return video;
    } else if (nodeType === "html") {
      return singleLineString`
        <div class="c-custom-video" data-src="${src}" data-src-type="${srcType}">
          <video
            controls
            muted
            style="${style}">
            <source>
              src="${src}"
              type="${srcType}"
            </source>
          </video>
        </div>
      `;
    }
  }
  static createIframeElement(src, type = "dom") {
    const style = "display:block;max-width:100%;";
    const sandbox = "allow-scripts allow-same-origin allow-presentation";
    if (type === "dom") {
      const iframe = document.createElement("iframe");
      iframe.classList = ["c-custom-video"];
      iframe.style = style;
      iframe.setAttribute("frameborder", "0");
      iframe.setAttribute("allowfullscreen", true);
      iframe.setAttribute("sandbox", sandbox);
      iframe.setAttribute("src", src);
      return iframe;
    } else if (type === "html") {
      return singleLineString`
        <div class="c-custom-video" data-src="${src}">
          <iframe
            frameborder="0"
            allowfullscreen
            sandbox="${sandbox}"
            src="${src}"
            style="${style}">
          </iframe>
        </div>
      `;
    }
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }

  html() {
    const { video } = this.value();
    return this.chooseElementBasedOnUrl(video, "html");
  }
}

CustomVideo.blotName = "custom-video";
CustomVideo.className = "c-custom-video";
CustomVideo.tagName = "div";

export default CustomVideo;
