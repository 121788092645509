<template>
  <svg viewBox="0 0 22 21">
    <path
      fill="#107598"
      fill-rule="nonzero"
      d="M2.32 7.64A2.48 2.48 0 0 1 4.8 5.16c0 1.65.65 3.16 1.7 4.27-.44.42-1.04.69-1.7.69a2.48 2.48 0 0 1-2.48-2.48zM11 10.12a4.96 4.96 0 1 0-.01-9.93 4.96 4.96 0 0 0 .01 9.93zm6.2 0a2.48 2.48 0 0 0 0-4.96c0 1.66-.65 3.16-1.7 4.27.44.43 1.04.69 1.7.69zm0 2.48c-.34 0-.68.04-1 .1a9.46 9.46 0 0 1 3.48 3.62H22a4.97 4.97 0 0 0-4.8-3.72zm-11.22.15A4.97 4.97 0 0 0 0 16.32h2.4a9.9 9.9 0 0 1 3.58-3.57zm5.2-.15a8.7 8.7 0 0 0-8.77 7.44H19.6a8.34 8.34 0 0 0-8.42-7.44z"
    />
  </svg>
</template>

<script>
export default {};
</script>
