<script>
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],
  props: {
    state: { type: Object, default: () => ({}) },
    setState: { type: Function, default: () => {} },
    submitByFormId: { type: String, default: "" },
    options: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      visible: true,
    };
  },
  computed: {},
  methods: {
    handleSubmit(event) {
      const { submitter } = event;
      const context = {
        continue: submitter.dataset.context === "continue",
      };

      this.$emit("can-continue", context);
    },
    setStateSigningDocumentFields(key, value) {
      const signingDocumentFields = {
        ...this.state.signingDocumentFields,
        [key]: value,
      };
      this.setState({
        ...this.state,
        signingDocumentFields: signingDocumentFields,
      });
    },
    setStateSigningDocumentFieldsClient(key, value) {
      const signingDocumentFieldsClient = {
        ...this.state.signingDocumentFields.client,
        [key]: value,
      };
      this.setState({
        ...this.state,
        signingDocumentFields: {
          ...this.state.signingDocumentFields,
          client: signingDocumentFieldsClient,
        },
      });
    },
  },
  mounted() {},
};
</script>

<template>
  <div v-bind="{}">
    <div class="accordion" role="tablist">
      <b-card no-body>
        <b-card-header
          header-tag="header"
          class="p-2 d-flex justify-content-between align-items-center"
          role="tab"
        >
          <b-button
            :class="visible ? null : 'collapsed'"
            :aria-expanded="visible ? 'true' : 'false'"
            aria-controls="document-fields-collapse"
            @click="visible = !visible"
            variant="link"
          >
            <b-icon class="mx-1" icon="arrow-down-short" />
            {{ $t("account.request_foundation_modal_step_document_fields_title", "Fields") }}
          </b-button>
        </b-card-header>
        <b-collapse
          id="document-fields-collapse"
          accordion="document-fields-accordion"
          role="tabpanel"
          visible
          v-model="visible"
        >
          <b-card-body>
            <form :id="submitByFormId" @submit.prevent="handleSubmit($event)">
              <b-form-group
                :label="
                  $t('account.request_foundation_modal_step_document_fields_email_label', 'Email')
                "
              >
                <b-form-input
                  required
                  type="email"
                  :value="state.signingDocumentFields.email"
                  @input="setStateSigningDocumentFields('email', $event)"
                />
              </b-form-group>
              <b-form-row>
                <b-col cols="6">
                  <b-form-group
                    :label="
                      $t(
                        'account.request_foundation_modal_step_document_fields_first_name_label',
                        'First Name'
                      )
                    "
                  >
                    <b-form-input
                      required
                      :value="state.signingDocumentFields.fname"
                      @input="setStateSigningDocumentFields('fname', $event)"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="
                      $t(
                        'account.request_foundation_modal_step_document_fields_last_name_label',
                        'Last Name'
                      )
                    "
                  >
                    <b-form-input
                      required
                      :value="state.signingDocumentFields.lname"
                      @input="setStateSigningDocumentFields('lname', $event)"
                    />
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-group
                :label="
                  $t(
                    'account.request_foundation_modal_step_document_fields_client_company_label',
                    'Company'
                  )
                "
              >
                <b-form-input
                  required
                  :value="state.signingDocumentFields.client.company"
                  @input="setStateSigningDocumentFieldsClient('company', $event)"
                />
              </b-form-group>
              <b-form-group
                :label="
                  $t(
                    'account.request_foundation_modal_step_document_fields_client_non_profit_type_label',
                    'Non-profit type'
                  )
                "
              >
                <b-form-input
                  required
                  :value="state.signingDocumentFields.client.non_profit_type"
                  @input="setStateSigningDocumentFieldsClient('non_profit_type', $event)"
                />
              </b-form-group>
              <b-form-group
                :label="
                  $t(
                    'account.request_foundation_modal_step_document_fields_client_providing_label',
                    'Providing'
                  )
                "
              >
                <b-form-input
                  required
                  :value="state.signingDocumentFields.client.providing"
                  @input="setStateSigningDocumentFieldsClient('providing', $event)"
                />
              </b-form-group>
              <b-form-group
                :label="
                  $t(
                    'account.request_foundation_modal_step_document_fields_client_campaign_shortlink_label',
                    'Campaign shortlink'
                  )
                "
              >
                <b-form-input
                  required
                  :value="state.signingDocumentFields.client.campaign_shortlink"
                  @input="setStateSigningDocumentFieldsClient('campaign_shortlink', $event)"
                />
              </b-form-group>
              <b-form-group
                :label="
                  $t(
                    'account.request_foundation_modal_step_document_fields_client_position_label',
                    'Position'
                  )
                "
              >
                <b-form-input
                  required
                  :value="state.signingDocumentFields.client.position"
                  @input="setStateSigningDocumentFieldsClient('position', $event)"
                />
              </b-form-group>
            </form>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
    <b-card class="mt-3" no-body ref="embed-document-card">
      <b-card-header header-tag="header" class="p-0 no-bottom-border" role="tab">
        <b-alert
          v-if="!state.embedDocumentId"
          class="mb-0 no-bottom-border no-border-radius"
          :variant="state.alertVariant"
          show
        >
          {{ state.alertMessage }}
        </b-alert>
        <b-alert v-else class="mb-0 no-bottom-border no-border-radius" variant="info" show>
          {{
            $t(
              "account.request_foundation_modal_step_document_fields_alert_preview_edit",
              "Change fields here to recreate document"
            )
          }}
        </b-alert>
      </b-card-header>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
.no-bottom-border {
  border-bottom: none;
}
.no-border-radius {
  border-radius: 0;
}
</style>
