<template>
  <b-modal :id="id" size="xl" centered @hide="onModalHide" :no-close-on-backdrop="showHidePopover">
    <div :id="`modal-header-${id}`" slot="modal-header">
      <button class="modal-header__hide" :id="`modal-header-hide-${id}`" @click="onHideModal">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </button>
      <h2 class="h1-like">{{ $t("add_campaign.add_new_campaign") }}</h2>
      <div class="modal-header__description">
        {{ $t("add_campaign.change_anytime") }}
      </div>
      <b-popover
        v-if="showHidePopover"
        :target="`modal-header-hide-${id}`"
        triggers="click"
        :show.sync="popoverShow"
        placement="auto"
        :container="`modal-header-${id}`"
        ref="popover"
        @show="onPopoverShow"
        @shown="onPopoverShown"
        @hide="onPopoverHide"
        @hidden="onPopoverHidden"
        custom-class="modal-header__popover"
        variant="danger"
      >
        <template #title>
          {{ $t("add_campaign.delete_anyway_noticetitle", "Dangerous action") }}
        </template>
        <slot name="hide-popover-content">
          <div>
            <p>
              {{
                $t(
                  "add_campaign.delete_anyway_notice",
                  "Do you want to delete all your saved data?"
                )
              }}
            </p>
            <input
              type="submit"
              class="mx-2 bttn bttn--sm bttn--orange"
              :disabled="loading"
              v-bind="saveDraftBtnAttrs"
              v-on="saveDraftBtnListeners"
              :value="$t('add_campaign.save_draft', 'Save Draft')"
            />
            <button
              :disabled="loading"
              class="bttn bttn--sm bttn--bordered-red"
              @click="hideModalAndDeleteState"
            >
              {{ $t("add_campaign.delete_anyway", "Delete") }}
            </button>
          </div>
        </slot>
      </b-popover>
    </div>

    <div slot="modal-footer" class="d-flex flex-column">
      <div
        class="w-100 d-flex align-items-center"
        :class="{ 'justify-content-end': step === 0 || prevStepHidden }"
      >
        <button
          class="mr-auto bttn--lg bttn--blue"
          v-if="step > 0 && !prevStepHidden"
          :disabled="loading"
          @click="backStep"
        >
          {{ $t("add_campaign.back", "Back") }}
        </button>

        <input
          type="submit"
          class="mx-2 bttn bttn--lg bttn--orange"
          v-if="step > 0"
          :disabled="loading"
          v-bind="saveDraftBtnAttrs"
          v-on="saveDraftBtnListeners"
          :value="$t('add_campaign.save_draft', 'Save Draft')"
        />

        <input
          type="submit"
          class="mx-2 bttn bttn--lg bttn--orange"
          v-if="step < steps.length - 1 && !nextStepGet.hidden"
          :disabled="loading || error"
          v-bind="continueBtnAttrs"
          v-on="continueBtnListeners"
          :value="$t('add_campaign.continue_to_next_step', 'Continue')"
        />
        <button
          class="mx-2 bttn bttn--lg bttn--orange"
          v-else
          @click="done"
          :disabled="loading || error"
        >
          {{ $t("add_campaign.done", "Done") }}
        </button>
      </div>
    </div>

    <div class="d-stepper">
      <div class="d-stepper-header d-flex justify-content-around">
        <div
          class="step-number-content text-center"
          :class="{ active: step == i }"
          v-for="(stepItem, i) in steps"
          v-show="!stepItem.hidden"
          :key="i"
        >
          <div
            class="step-number align-items-center justify-content-center mx-auto"
            :class="stepNumberClasses(i)"
          >
            <b-icon-check v-if="step > i" />
            <b-icon-exclamation v-else-if="step === i && fatalError" />
            <span v-else>{{ i + 1 }}</span>
          </div>
        </div>
      </div>

      <div class="mt-4" v-if="!stepHidden">
        <transition :name="effect" mode="out-in">
          <keep-alive>
            <component
              ref="step"
              :is="stepComponent"
              :state="store.state"
              :setState="setState"
              :options="options"
              :submitByFormId="getActionHandler(activeStep.action).submit"
              @loading="loadingAction"
              @error="errorHandler"
              @fatal-error="blockStepper"
              @can-continue="nextStepAction"
              @set-step="setStep"
            />
          </keep-alive>
        </transition>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { notificationsMixin } from "@/mixins";
import { isFunction, isPlainObject } from "@/helpers/inspect";

export default {
  mixins: [notificationsMixin],
  props: {
    id: { type: String, required: true },
    steps: { type: Array, default: () => [] },
    initialState: { type: Object, default: () => ({}) },
    options: { type: Object, default: () => ({}) },
    ssrStateSaved: {
      type: [String, Boolean],
      default: false,
    },
    showHidePopover: Boolean,
  },
  data() {
    return {
      store: {
        state: this.getInitialState(),
        setState: this.setState,
        resetState: this.resetState,
      },
      step: 0,
      loading: false,
      error: false,
      fatalError: false,
      fatalErrorMsg: "",
      effect: "in-out-translate-fade",
      shake: false,
      popoverShow: false,
    };
  },
  computed: {
    activeStep() {
      return this.steps[this.step];
    },
    prevStep() {
      const prev = this.steps[this.step - 1];
      if (!prev) {
        return this.activeStep;
      }
      return prev;
    },
    nextStepGet() {
      const next = this.steps[this.step + 1];
      if (!next) {
        return this.activeStep;
      }
      return next;
    },
    stepComponent() {
      return this.steps[this.step].component;
    },
    stepHidden() {
      return this.steps[this.step].hidden;
    },
    prevStepHidden() {
      return this.prevStep.hidden;
    },
    continueBtnAttrs() {
      const { submit } = this.getActionHandler(this.activeStep.action);

      return {
        ...(submit.length > 0 && {
          type: "submit",
          form: submit,
          "data-context": "continue",
        }),
      };
    },
    continueBtnListeners() {
      const { submit } = this.getActionHandler(this.activeStep.action);

      return {
        ...(submit.length === 0 && {
          click: this.nextStep.bind(this, { continue: true }),
        }),
      };
    },
    saveDraftBtnAttrs() {
      const { submit } = this.getActionHandler(this.activeStep.action);

      return {
        ...(submit.length > 0 && {
          type: "submit",
          form: submit,
          "data-context": "saveDraft",
        }),
      };
    },
    saveDraftBtnListeners() {
      const { submit } = this.getActionHandler(this.activeStep.action);

      return {
        ...(submit.length === 0 && { click: this.saveDraft }),
      };
    },
  },
  mounted() {
    this.$root.$on("bv::modal::show", this.onShowModal);
  },
  beforeDestroy() {
    this.$root.$off("bv::modal::show", this.onShowModal);
  },
  watch: {
    activeStep: {
      handler(v) {
        if (v.hidden) {
          this.nextStep({
            continue: true,
          });
        }
      },
    },
  },
  methods: {
    setStep(step) {
      if (step >= 1 && step <= this.steps.length) this.step = step - 1;
    },
    resetState() {
      this.store.state = {
        ...this.getInitialState(),
      };
    },
    setState(key, value) {
      if (isPlainObject(key)) {
        this.store.state = {
          ...this.store.state,
          ...key,
        };

        return this.store.state;
      }

      this.store.state = {
        ...this.store.state,
        [key]: value,
      };

      return this.store.state;
    },
    errorHandler(payload) {
      this.error = payload;
      this.shake = payload;
      setTimeout(() => {
        this.shake = !payload;
      }, 750);
    },
    blockStepper(msg) {
      this.resetParams();
      this.fatalErrorMsg = msg;
      this.fatalError = true;
    },
    resetParams() {
      this.error = false;
      this.loading = false;
      this.fatalErrorMsg = "";
      this.fatalError = false;
    },
    stepNumberClasses(i) {
      return {
        "c-bg-secondary text-white": this.step === i && !this.fatalError,
        "bg-success text-white": this.step > i && !this.fatalerror,
        "bg-danger text-white": this.fatalError && this.step === i,
        "c-text-secondary": this.step < i,
      };
    },
    nextStep(context) {
      if (!this.$refs.step?.nextStep) return this.nextStepAction(context);

      if (this.$refs.step.nextStep()) {
        if (!this.loading) {
          this.nextStepAction(context);
        }
      }
    },
    nextStepAction(context) {
      this.effect = "in-out-translate-fade";
      this.resetParams();
      const { required } = this.getActionHandler(this.activeStep.action);
      if (this.step < this.steps.length - 1) {
        if (required && context?.continue) {
          this.executeAction()
            .then(() => {
              this.step++;
            })
            .catch(this.$_notificationsMixin_handleCatch);
        } else if (context?.saveDraft) {
          this.saveDraft();
        } else {
          this.step++;
        }
      }
    },
    backStep() {
      this.effect = "out-in-translate-fade";
      this.resetParams();
      if (this.step > 0) this.step--;
    },
    loadingAction(status) {
      this.loading = status;
    },
    onShowModal(bvEvent, modalId) {
      if (modalId === this.id) {
        this.resetState();
        this.restoreCachedState();
        if (this.activeStep.name === this.prevStep.name && this.prevStep.hidden) {
          this.nextStep({
            continue: true,
          });
        }
        this.$emit(
          "open",
          this.store.state,
          this.store.setState,
          this.loadingAction,
          this.errorHandler,
          this.setStep,
          this.blockStepper
        );
      }
    },
    onHideModal() {
      if (this.showHidePopover) {
        return;
      }

      this.hideModal();
    },
    resetAll() {
      this.popoverShow = false;
      this.loading = false;
      this.error = false;
      this.fatalError = false;
      this.shake = false;
      this.popoverShow = false;
      this.step = 0;
      localStorage.removeItem("current-wizard-draft");
      this.resetState();
    },
    hideModal() {
      this.popoverShow = false;
      this.$emit("hide", { resetAll: this.resetAll });
      this.$bvModal.hide(this.id);
    },
    hideModalAndDeleteState() {
      if (!this.ssrStateSaved) {
        this.resetAll();
        this.hideModal();
        return;
      }

      return this.$emit("hide", {
        action: "delete-state",
        loadingAction: this.loadingAction,
        resetAll: this.resetAll,
        errorHandler: this.errorHandler,
      });
    },
    getActionHandler(action) {
      const delegatedAction =
        this.steps.find((s) => {
          const name = s?.name ? s.name : "";
          return name === action?.delegate;
        })?.action || null;
      const actual = !delegatedAction ? action : delegatedAction;

      if (isFunction(actual)) {
        return {
          handler: actual,
          required: true, // shorthand for always execute actions, but not validate output
          saveDraftBypassSubmit: false,
          submit: "",
          delegated: !!delegatedAction,
        };
      }

      return {
        handler: actual.handler,
        required: actual.required || false,
        saveDraftBypassSubmit: actual.saveDraftBypassSubmit || false,
        submit: actual.submit || "",
        delegated: !!delegatedAction,
      };
    },
    getComponentActions() {
      const { handler } = this.getActionHandler(this.activeStep.action);
      const { handler: prevHandler } = this.getActionHandler(this.prevStep.action);

      if (!handler) {
        throw new Error("Not existent action called! Check action handlers");
      }

      if (this.activeStep.name !== this.prevStep.name && this.prevStep.hidden) {
        return prevHandler({
          state: this.store.state,
          setState: this.store.setState,
        }).then(() =>
          handler({
            state: this.store.state,
            setState: this.store.setState,
          })
        );
      }

      return handler({
        state: this.store.state,
        setState: this.store.setState,
      });
    },
    restoreCachedState() {
      try {
        const cache = JSON.parse(localStorage.getItem("current-wizard-draft"));
        const { storeState, step, loading, error, ssrStateSaved } = cache;
        this.$emit("update:ssrStateSaved", ssrStateSaved);

        if (cache) {
          this.setState(storeState);
          // instead of this.setStep we directly write to this.step
          // because step 0 doesn't support to have save draft
          // and setStep made for one usecase - user click navigation
          this.step = step;
          this.loadingAction(loading);
          this.errorHandler(error);
          return true;
        }

        return false;
      } catch (e) {
        if (e instanceof SyntaxError) {
          this.$_notificationsMixin_makeErrorToast(
            this.$t("edit_campaign.wizzard_cache_error", "Cache Error"),
            this.$t(
              "edit_campaign.wizzard_cache_error_details",
              "Saved draft cannot be restored due to damaged data"
            )
          );
          return false;
        }
        return false;
      }
    },
    saveDraft() {
      this.$emit("save-draft", this.store.state, this.store.setState, this.executeAction);

      localStorage.setItem(
        "current-wizard-draft",
        JSON.stringify({
          storeState: this.store.state,
          step: this.step,
          loading: this.loading,
          error: this.error,
          ssrStateSaved: this.ssrStateSaved,
        })
      );

      this.$_notificationsMixin_makeToast("Success", this.$t("edit_campaign.success_edit"));

      this.hideModal();
    },
    executeAction() {
      this.loadingAction(true);
      return this.getComponentActions()?.finally(() => this.loadingAction(false));
    },
    done() {
      this.executeAction()
        .then((produced) => {
          this.hideModal();

          this.$emit("done", { ...produced, resetAll: this.resetAll });
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
    getInitialState() {
      const current = localStorage.getItem("current-wizard-draft");

      if (!current) {
        return this.initialState;
      }

      return JSON.parse(current);
    },
    onPopoverShow() {
      this.$emit("hide-popover-show");
    },
    onPopoverShown() {
      this.$emit("hide-popover-shown");
    },
    onPopoverHide(e) {
      e.preventDefault();
      this.$emit("hide-popover-hide");
    },
    onPopoverHidden() {
      this.$emit("hide-popover-hidden");
    },
    onModalHide(e) {
      if (this.popoverShow) {
        e.preventDefault();
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.d-stepper .d-stepper-header {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}

.d-stepper .d-stepper-header::before {
  position: absolute;
  width: 100%;
  height: 1px;
  background: #ddd;
  top: 20px;
  left: 0;
  content: " ";
}

.d-stepper .step-number {
  background: #e9e9e9;
  border-radius: 50%;
  text-align: center;
  height: 40px;
  width: 40px;
  display: flex;
}
.d-stepper .step-number-content {
  transition: transform 0.2s;
  z-index: 2;
  width: 68px;
}

.d-stepper .step-number-content div {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.d-stepper .step-number-content.active {
  transform: scale(1.25);
}

.in-out-translate-fade-enter-active,
.in-out-translate-fade-leave-active {
  transition: all 0.15s;
}
.in-out-translate-fade-enter,
.in-out-translate-fade-leave-active {
  opacity: 0;
}
.in-out-translate-fade-enter {
  transform: translateX(100px);
}
.in-out-translate-fade-leave-active {
  transform: translateX(-100px);
}

.out-in-translate-fade-enter-active,
.out-in-translate-fade-leave-active {
  transition: all 0.15s;
}
.out-in-translate-fade-enter,
.out-in-translate-fade-leave-active {
  opacity: 0;
}
.out-in-translate-fade-enter {
  transform: translateX(-100px);
}
.out-in-translate-fade-leave-active {
  transform: translateX(100px);
}
</style>
