export const cdnBaseInputProps = {
  form: Boolean,
  hideTitle: { type: Boolean, default: false },
  variant: { type: String, default: "light" },
  type: { type: String, default: "text" },
  placeholder: { type: String, default: "" },
  name: { type: String, default: "" },
  id: { type: String, default: "" },
  size: { type: String, default: "md" },
  error: Boolean,
};
