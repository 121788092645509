<template>
  <div class="app-search">
    <svg viewBox="0 0 17 17">
      <g fill="#107598" fill-rule="nonzero">
        <path
          d="M11.22 3.29c.2.2.38.41.55.64l-.12-.16c.33.44.61.93.83 1.44l-.08-.19a6.76 6.76 0 0 1 .42 1.48 6.92 6.92 0 0 1 .03 1.52 6.76 6.76 0 0 1-.45 1.69l.08-.2c-.22.52-.5 1-.83 1.45l.12-.16c-.34.45-.74.85-1.18 1.2l.16-.12c-.43.34-.91.62-1.42.84l.2-.08a6.41 6.41 0 0 1-1.45.43 6.52 6.52 0 0 1-1.5.03 6.41 6.41 0 0 1-1.65-.46l.19.08c-.5-.22-.98-.5-1.42-.84l.16.12a6.67 6.67 0 0 1-1.18-1.2l.12.16a6.78 6.78 0 0 1-.83-1.45l.08.2a6.76 6.76 0 0 1-.42-1.48 6.92 6.92 0 0 1-.03-1.52c.08-.58.23-1.15.45-1.69l-.08.2c.22-.52.5-1 .83-1.45l-.12.16c.34-.45.74-.86 1.18-1.2l-.16.12c.44-.34.91-.62 1.42-.84l-.2.08a6.41 6.41 0 0 1 1.45-.43 6.52 6.52 0 0 1 1.5-.03c.57.08 1.12.23 1.65.46L9.33 2a6.43 6.43 0 0 1 1.89 1.28c.3.3.83.32 1.11 0a.83.83 0 0 0 0-1.14A7.13 7.13 0 0 0 .92 3.77a7.46 7.46 0 0 0 .85 8.43 7.16 7.16 0 0 0 9.02 1.58 7.53 7.53 0 0 0 2.48-2.38 7.46 7.46 0 0 0-.94-9.25c-.29-.3-.82-.32-1.11 0a.83.83 0 0 0 0 1.14z"
        />
        <path
          d="M11.28 12.42l1.47 1.47 2.34 2.34.54.54c.3.3.85.32 1.14 0a.82.82 0 0 0 0-1.14l-1.47-1.47-2.34-2.34-.54-.54c-.3-.3-.85-.32-1.14 0a.82.82 0 0 0 0 1.14z"
        />
      </g>
    </svg>
    <input
      class="app-search__input"
      type="text"
      :value="value"
      v-bind="inputAttrs"
      v-on="inputListeners"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
function omitObjectKey(object, keys) {
  return Object.entries(object).reduce((acc, curr) => {
    const [k, v] = curr;
    if (keys.some((v) => k !== v)) {
      acc[k] = v;
    }
    return acc;
  }, {});
}

export default {
  name: "AppSearchInput",
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    inputAttrs() {
      return omitObjectKey(this.$attrs, ["type", "value"]);
    },
    inputListeners() {
      return omitObjectKey(this.$listeners, ["input"]);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-search {
  width: 100%;
  display: inline-block;
  position: relative;

  svg {
    display: block;
    width: 18px;
    height: auto;
    position: absolute;
    top: calc(50% - 9px);
    left: 18px;
  }

  &__input {
    height: 48px;
    width: 100%;
    padding-left: 45px;
    border: 1px solid $bb-50;
    border-radius: 24px;
    cursor: pointer;
    &:focus,
    &:active,
    &:hover {
      outline: 0;
      border-color: $bb-70;
    }
  }
}
#app.he {
  .app-search {
    > svg {
      right: 18px;
      left: initial;
    }
    &__input {
      padding-right: 45px;
      padding-left: 25px;
    }
  }
}
</style>
