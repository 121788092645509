import urls from "../../constants/urls.js";
import axios from "axios";
import { computeDatesForPeriodItem, getMonthYearLists } from "@/helpers/calendarModule.js";

const module_name = "calendar";

const getBody = (cId, data, id = "") => {
  const { months, years } = (data.dates || []).reduce(
    (res, el) => {
      res = getMonthYearLists(el.start, el.end);
      return res;
    },
    { months: [], years: [] }
  );

  data.months = months;
  data.years = years;

  return {
    type: "module_data",
    id: String(id),
    attributes: {
      module: module_name,
      campaign_id: Number(cId),
      data: JSON.stringify(data),
    },
  };
};

const state = {
  activated: false,
  config: null,
  reservedDates: [],
};

const mutations = {
  setActivated(state, value) {
    state.activated = value;
  },

  setConfig(state, value) {
    state.config = value;
  },
};

const getters = {
  getDisabledDates(s) {
    return (s.reservedDates || []).reduce((res, el) => {
      const item = computeDatesForPeriodItem(el.data);

      res.push(...item.dates);

      return res;
    }, []);
  },
};

const actions = {
  fetchIsActivated({ commit, dispatch }, cmpId) {
    const orgId = localStorage.getItem("orgId");
    const params = {
      module_name,
    };
    axios
      .get(urls.getModule.replace(":oid", orgId).replace(":cid", cmpId), {
        params,
      })
      .then(({ data }) => {
        const { active } = data.data.attributes;
        commit("setActivated", active);
        if (active) {
          dispatch("fetchConfig", { campaign_id: cmpId });
        }
      })
      .catch((e) => {
        console.log(e);
        commit("setActivated", false);
      });
  },

  fetchConfig({ commit }, params) {
    const url = urls.module.replace(":name", module_name);
    return axios.get(url, { params }).then(({ data }) => {
      commit("setConfig", data);
      return data;
    });
  },

  fetchItem(_, [cId, id, getParams] = []) {
    const orgId = localStorage.getItem("orgId");
    const sufix = String(id).trim() && `/${id}`;
    const params = { ...getParams };

    return axios
      .get(urls.moduleData.replace(":oid", orgId).replace(":cmpid", cId) + sufix, { params })
      .then(({ data }) => {
        return {
          ...data.data,
          attributes: {
            ...data.data.attributes,
            data: JSON.parse(data.data.attributes.data),
          },
        };
      });
  },

  fetchCreate(_, [cId, form, params] = []) {
    const orgId = localStorage.getItem("orgId");
    const data = getBody(cId, form);

    return axios.post(
      urls.moduleData.replace(":oid", orgId).replace(":cmpid", cId),
      { data },
      { params }
    );
  },

  fetchUpdate(_, [cId, id, form, params] = []) {
    const orgId = localStorage.getItem("orgId");
    const data = getBody(cId, form, id);

    return axios.put(
      urls.moduleData.replace(":oid", orgId).replace(":cmpid", cId),
      { data },
      { params }
    );
  },

  fetchDelete(_, [cId, id] = []) {
    const orgId = localStorage.getItem("orgId");
    const sufix = String(id).trim() && `/${id}`;

    return axios.delete(urls.moduleData.replace(":oid", orgId).replace(":cmpid", cId) + sufix);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
