<template>
  <div class="donor-sign-in">
    <div class="form-header">
      <h3>{{ $t("template.sign_in_to_charidy", "Sign in to Charidy") }}</h3>
      <p>{{ $t("template.log_in_enter_your_details", "Enter your details below") }}</p>
    </div>
    <div class="form-group" v-if="steps === 0">
      <label for="email">{{ $t("template.log_in_email_address", "Email address") }}</label>
      <b-form-input
        type="email"
        v-model="login.email"
        class="form-control"
        tabindex="1"
        id="email"
        :placeholder="$t('template.log_in_enter_email_placeholder', 'Enter email')"
      ></b-form-input>
    </div>

    <div class="form-group" v-if="steps === 1 || steps === 4">
      <div class="action-title">
        <p v-if="steps === 4" class="sign-form__description">
          {{ $t("template.reset_by_email") }}
        </p>
        <p v-else-if="steps === 1" class="sign-form__description">
          {{ $t("template.reset_by_sms") }}
        </p>
      </div>
      <label class="form-unit__label form-unit__label--upper-sm form-unit__label--w-link"
        >{{ $t("template.email_address") }}
        <a v-if="steps === 1" href="#" @click.prevent="stepsSet(4)" tabindex="3">{{
          $t("template.send_sms")
        }}</a>
        <a v-else-if="steps === 4" href="#" @click.prevent="stepsSet(1)" tabindex="3">{{
          $t("template.send_email")
        }}</a>
      </label>
      <b-form-input
        type="email"
        v-model="login.email"
        class="form-control"
        tabindex="1"
        id="email"
        :placeholder="$t('template.log_in_enter_email_placeholder', 'Enter email')"
        @input="emailChange"
      ></b-form-input>
      <span v-show="this.loginLocalErrors.email" class="v-help is-danger">{{
        $t("template.valid_email_is_required")
      }}</span>
    </div>

    <div class="form-group form-unit" v-if="steps === 4">
      <label class="form-unit__label form-unit__label--upper-sm form-unit__label--w-link"
        >{{ $t("template.phone_number") }}
      </label>
      <VueTelInput
        name="phone"
        v-model="login.phone"
        v-validate="{
          required: true,
          twilio_lookup: twilioLookup,
        }"
        :inputOptions="{
          styleClasses: 'form-control form-unit__input',
        }"
        mode="international"
        data-vv-validate-on="blur"
        valid-characters-only
        tabindex="4"
        @validate="validate"
        :defaultCountry="defaultCountry"
        :autoDefaultCountry="autoDefaultCountry"
      />
      <span
        v-if="vErrorsHas('phone', 'required') || vErrorsHas('phone', 'decimal')"
        class="v-help is-danger"
      >
        {{ $t("template.field_is_required") }}
      </span>
      <span v-if="twilioLookupErrorMsg" class="v-help is-danger">
        {{ twilioLookupErrorMsg }}
      </span>
    </div>

    <div class="form-group" v-if="steps === 0">
      <div class="row">
        <div class="col-6">
          <label for="password">{{ $t("template.log_in_password", "Password") }}</label>
        </div>
        <div class="col-6 text-right">
          <a href="#" @click.prevent="stepsSet(1)" tabindex="3">{{
            $t("template.log_in_forgot_password", "Forgot password?")
          }}</a>
        </div>
      </div>
      <b-form-input
        type="password"
        v-on:keyup.enter="loginUser"
        v-model="login.password"
        class="form-control"
        tabindex="2"
        id="password"
        :placeholder="$t('template.log_in_password_placeholder', 'Password')"
      ></b-form-input>
    </div>

    <div class="form-group form-unit" v-if="steps === 3">
      <label class="form-unit__label form-unit__label--upper-sm">
        {{ $t("template.two_fa_vode", "Code from your Googe Authenticator") }}
      </label>
      <input
        v-on:keyup.enter="$emit('login-user')"
        v-model="twoFACode"
        class="form-control form-unit__input"
        tabindex="1"
      />
    </div>

    <div class="text-center">
      <slot name="action_buttons"></slot>
      <p v-if="steps === 0">
        {{
          $t(
            "template.log_in_dont_have_donor_account_yet",
            `Don't have a donor account yet? Sign Up`
          )
        }}
        <router-link to="/donor-sign-up">{{ $t("template.log_in_here", "here") }}</router-link>
      </p>
    </div>

    <div v-if="steps === 2 && prevStep === 1" class="login-message">
      {{ $t("template.password_reset_please_check_email") }}
    </div>
    <div v-if="steps === 2 && prevStep === 4" class="login-message">
      {{ $t("template.password_reset_please_check_phone") }}
    </div>

    <error :errors="errors"></error>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ErrorDisplay from "@/views/Error.vue";
import { VueTelInput } from "vue-tel-input";

export default {
  data: function () {
    return {
      login: {
        phone: "",
        email: "",
        password: "",
      },
      loginLocalErrors: {
        phone: false,
        email: false,
      },
      isDonor: true,
      steps: 0, //0 - login; 1 - forgot password, 2 - password send return to login, 3 - 2fa, 4 - reset by sms
      prevStep: null,
      phoneObject: null,
    };
  },

  created() {
    this.$store.dispatch("checkToken");
  },

  components: {
    error: ErrorDisplay,
    VueTelInput,
  },

  computed: {
    ...mapState({
      errors: (state) => {
        return state.user.errorLogin;
      },

      successResetPassword: (state) => {
        return state.user.resetPasswordSuccess;
      },

      defaultCountry: (s) => s.account.userCountry,
    }),
    ...mapGetters({
      require2FACode: "require2FACode",
    }),

    twoFACode: {
      get() {
        return this.login["2fa_code"] || "";
      },
      set(val) {
        this.$set(this.login, "2fa_code", val);
      },
    },
    autoDefaultCountry() {
      return this.defaultCountry === "N/A" || this.defaultCountry.length === 0;
    },
    twilioLookupErrorMsg() {
      const exist = this.vErrors.items.find(
        (el) => el.field === "phone" && el.rule === "twilio_lookup"
      );

      if (exist) {
        return this.$t("template.field_error_twilio_lookup", "Invalid or incorrect phone number");
      }

      return "";
    },
    twilioLookup() {
      return {
        valid: this.phoneObject?.valid || false,
      };
    },
  },
  watch: {
    successResetPassword: function () {
      if (this.successResetPassword) {
        this.stepsSet(2);
      }
    },
    steps: function (oldVal) {
      if ([1, 4].includes(oldVal)) {
        this.prevStep = oldVal;
      }
    },
  },

  methods: {
    stepsSet(step) {
      this.steps = step;
      this.$emit("update-step", step);
    },

    stepTo(n) {
      this.steps = n;
    },

    proceedResetPassword() {
      if (
        (this.steps == "resetPassword" &&
          (this.loginLocalErrors.email || this.login.email == "")) ||
        (this.steps == "resetPasswordPhone" &&
          (this.loginLocalErrors.phone ||
            this.login.phone == "" ||
            this.loginLocalErrors.email ||
            this.login.email == ""))
      ) {
        return;
      }

      let payload = {};

      if (this.steps === 1) {
        payload = { email: this.login.email };
      }

      if (this.steps === 4) {
        payload = { phone: this.login.phone, email: this.login.email };
      }

      this.$store.dispatch("resetPassword", payload);
    },

    loginUser() {
      this.$store
        .dispatch("loginUser", [{ password: this.login.password, email: this.login.email }])
        .then(() => {
          if (this.require2FACode) {
            this.steps = 3;
          }
        });
    },
    validate(phoneObject) {
      this.phoneObject = phoneObject;

      if (!this.phoneObject?.valid) {
        this.loginLocalErrors.phone = true;
        return;
      }

      this.loginLocalErrors.phone = false;
    },
    vErrorsHas(field, rule) {
      return this.vErrors.items.find((el) => el.field === field && el.rule === rule);
    },
    emailChange() {
      if (this.login.email === "" || !this.validEmail(this.login.email)) {
        this.loginLocalErrors.email = true;
        return;
      }

      this.loginLocalErrors.email = false;
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style lang="scss" scoped>
.donor-sign-in {
  .form-header {
    text-align: center;
    margin-bottom: 45px;
    h3 {
      font-size: 35px;
    }
  }
  .form-group {
    a {
      font-size: 12px;
    }
  }
  label {
    text-transform: uppercase;
    font-size: 14px;
  }
  .text-center {
    padding-top: 25px;
    .bttn {
      min-width: 255px;
      margin-bottom: 50px;
    }
    p {
      margin-bottom: 15px;
      &.small {
        font-size: 12px;
      }
    }
  }
  a {
    color: #107598;
    text-decoration: underline;
  }
}

.login-message {
  width: 100%;
  height: 80px;
  padding: 0 15px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #414359;
  text-align: center;
  border: 1px solid #88bacc;
  border-radius: 4px;
  background-color: #eaf4ff;
}

@media all and (max-width: 767px) {
  .main-header-component {
    margin-bottom: 60px;
  }
  .donor-sign-in {
    .text-center {
      .bttn {
        margin-bottom: 30px;
      }
    }
    .form-header h3 {
      font-size: 33px;
    }
  }
}

.is-danger {
  color: #f26e66;
}
</style>
