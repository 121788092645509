<template>
  <div class="w-100 mb-4">
    <div class="d-flex justify-content-between mb-3">
      <span class="font-weight-bold">
        {{ field.title }}
      </span>
    </div>

    <multiselect-search
      v-model="valueModel"
      :open-prefetch="true"
      :search-url="getSearchUrl"
      :search-options="searchOptions"
      v-bind="componentAttrs"
    >
      <template #no-options>
        {{ $t("dashboard.team_search_no_options", "Please enter 1 or more characters") }}
      </template>
      <template #no-more-infinite>
        {{ $t("dashboard.team_search_no_more", "No more teams") }}
      </template>
    </multiselect-search>
  </div>
</template>

<script>
import urls from "@/constants/urls.js";

import MultiselectSearch from "@/components/multiselect-search.vue";
import { mapState } from "vuex";
import axios from "axios";

export default {
  components: {
    MultiselectSearch,
  },

  props: {
    form: {
      type: Object,
      default: () => null,
    },
    field: {
      type: Object,
      default: () => null,
    },
    fieldKey: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      localValue: [],
    };
  },

  watch: {
    value(val, old) {
      const same = JSON.stringify(old) === JSON.stringify(val);
      if (Array.isArray(val) && val.length && !same) {
        axios
          .get(this.getSearchUrl, {
            params: {
              team_id: this.value,
            },
          })
          .then(({ data }) => {
            this.localValue = this.searchOptions.processResults(data.data);
          });
      }
    },
  },

  computed: {
    ...mapState({
      cID: (state) => state.campaign.campaign.data.id,
    }),

    componentAttrs() {
      return this.field?.componentAttrs || {};
    },

    value() {
      return this.form[this.fieldKey];
    },

    valueModel: {
      get() {
        return this.localValue;
      },
      set(val) {
        this.localValue = val;

        if (Array.isArray(val)) {
          const ids = val.map((e) => Number(e.id));
          this.$emit("input", ids);
        } else {
          this.$emit("input", val);
        }
      },
    },

    getSearchUrl() {
      const cID = this.cID;
      const orgID = localStorage.getItem("orgId");

      if (Number(cID) === 0) {
        throw new Error("getSearchUrl campaign id is 0");
      }

      return urls.apiTeams.replace(":orgId", orgID).replace(":campaignId", cID);
    },

    searchOptions() {
      return {
        placeholder: this.$t("dashboard.select_team", "Select teams"),
        changeParams: ({ query, page, per_page: pp }) => {
          return {
            q: query,
            page: page,
            limit: pp,
          };
        },
        customLabel: (e) => `[${e.id}] ${e.name}`,
        processResults: (data) =>
          data.map((el) => ({
            id: el.id,
            ...el.attributes,
          })),
      };
    },
  },
};
</script>
