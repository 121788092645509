<template>
  <div>
    <b-modal id="userModal" size="lg" @hidden="clearData">
      <div slot="modal-header">
        <h2>{{ $t("users.user_account_modal_header") }}</h2>
      </div>

      <div slot="modal-footer">
        <!-- Cancel modal for both modes-->
        <button class="bttn bttn--lg bttn--blue" @click.prevent="">
          {{ $t("account.cancel") }}
        </button>

        <!-- Save data :: Add news user mode -->
        <button form="subAccountAdd" :disabled="isLoading" class="bttn bttn--lg bttn--orange">
          {{ $t("account.send_invitation") }}
        </button>

        <!-- Save data :: Edit mode -->
        <button @click="updateSubAccount()" class="bttn bttn--lg bttn--orange">
          {{ $t("account.save") }}
        </button>
      </div>

      <form id="subAccountAdd" @submit.prevent="addSubAccount">
        <error :errors="errors"></error>

        <div class="row">
          <div class="col-12">
            <p class="modal-body__description">{{ $t("account.sub_account_modal_description") }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12"></div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <!-- upload avatar -->
            <div class="avatar-upload">
              <label class="avatar-upload__label" :class="{ disabled: !hasAccess || editMode }">
                {{ $t("user.avatar") }}

                <div class="avatar-upload__file fileUpload" style="display: none">
                  <input
                    type="file"
                    :disabled="!hasAccess || editMode"
                    @change="uploadAvatar"
                    id="userAvatar"
                  />
                </div>

                <div class="avatar-upload__thumbnail">
                  <div class="loading-image">
                    <img :src="subaccount.data.attributes.avatar" />
                  </div>

                  <img
                    :src="subaccount.data.attributes.logo"
                    :alt="subaccount.data.attributes.name"
                  />
                </div>
              </label>
            </div>
            <!--/ end of upload avatar -->
          </div>
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-12">
                <div class="form-group form-unit">
                  <label class="form-unit__label">First Name</label>
                  <input
                    type="text"
                    :disabled="editMode"
                    v-model="subaccount.data.attributes.first_name"
                    class="form-control form-unit__input"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="form-group form-unit">
                  <label class="form-unit__label">Last Name</label>
                  <input
                    type="text"
                    :disabled="editMode"
                    v-model="subaccount.data.attributes.last_name"
                    class="form-control form-unit__input"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col-12 col-md-6">
            <div class="form-group form-unit">
              <label class="form-unit__label form-group__label--required">Email</label>
              <input
                type="email"
                required
                :disabled="editMode"
                v-model="subaccount.data.attributes.email"
                class="form-control form-unit__input"
              />
            </div>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group>
              <label class="form-group__label form-group__label--required">{{
                $t("account.sub_account_role")
              }}</label>
              <b-form-select
                id="subaccount-role"
                required
                v-model="subaccount.data.attributes.access"
                :options="roleTypes"
              />
            </b-form-group>
          </div>
        </div>
        <div class="row align-items-center">
          <error-message ref="error"></error-message>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";

import error from "@/components/errors.vue";
import errorList from "@/components/error-message.vue";

export default {
  components: {
    error,
    errorMessage: errorList,
  },

  data() {
    return {
      subAccId: "",
      roleTypes: [
        { value: null, text: this.$t("account.select_role"), disabled: true },
        { value: "full", text: this.$t("account.role_full") },
        { value: "operator", text: this.$t("account.role_operator") },
      ],
      subaccount: {
        data: {
          attributes: {
            access: null,
            email: "",
            first_name: "",
            last_name: "",
          },
        },
      },
      isLoading: false,
      errors: [],
      editMode: false,
      loadImage: false,
    };
  },

  computed: mapState({}),

  methods: {},
};
</script>

<style lang="scss"></style>
