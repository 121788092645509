<template>
  <div class="sign-form__data">
    <error :errors="errors"></error>
    <slot
      name="item"
      :login="login"
      :steps="steps"
      :prevstep="prevStep"
      :successresetpassword="successResetPassword"
      :stepsset="stepsSet"
      :stepto="stepTo"
      :proceedresetpassword="proceedResetPassword"
      :loginuser="loginUser"
      :loginLocalErrors="loginLocalErrors"
    >
    </slot>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ErrorDisplay from "@/views/Error.vue";

export default {
  data: function () {
    return {
      login: {
        phone: "",
        email: "",
        password: "",
      },
      loginLocalErrors: {
        phone: false,
        email: false,
      },
      steps: "login", //login - login; forgot password - resetPassword, password send return to login - backToLogin
      prevStep: "",
    };
  },

  components: {
    error: ErrorDisplay,
  },

  mounted() {
    this.clearError();
  },

  computed: {
    ...mapState({
      errors: (state) => {
        return state.user.errorLogin;
      },

      successResetPassword: (state) => {
        return state.user.resetPasswordSuccess;
      },
    }),
    ...mapGetters({
      require2FACode: "require2FACode",
    }),
  },

  watch: {
    successResetPassword: function () {
      if (this.successResetPassword) {
        this.stepsSet("backToLogin");
      }
    },
    steps: function (oldVal) {
      if (["resetPassword", "resetPasswordPhone"].includes(oldVal)) {
        this.prevStep = oldVal;
      }
    },
  },

  methods: {
    ...mapActions(["clearError"]),
    stepsSet(step) {
      this.steps = step;
      this.$emit("update-step", step);
    },

    stepTo(n) {
      this.steps = n;
    },

    proceedResetPassword() {
      if (
        (this.steps == "resetPassword" &&
          (this.loginLocalErrors.email || this.login.email == "")) ||
        (this.steps == "resetPasswordPhone" &&
          (this.loginLocalErrors.phone ||
            this.login.phone == "" ||
            this.loginLocalErrors.email ||
            this.login.email == ""))
      ) {
        return;
      }

      let payload = {};

      if (this.steps === "resetPassword") {
        payload = { email: this.login.email };
      }

      if (this.steps === "resetPasswordPhone") {
        payload = { phone: this.login.phone, email: this.login.email };
      }

      this.$store.dispatch("resetPassword", payload);

      this.steps = "backToLogin";
    },

    loginUser(params) {
      return this.$store.dispatch("loginUser", [this.login, params]).then((data) => {
        if (this.require2FACode) {
          this.steps = "2facode";
          return null;
        }
        return data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-message {
  width: 100%;
  height: 80px;
  padding: 0 15px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #414359;
  text-align: center;
  border: 1px solid #88bacc;
  border-radius: 4px;
  background-color: #eaf4ff;
}
</style>
