<template>
  <div>
    <div v-for="rt in requiredTypes" :key="rt" class="contact-missing">
      <div class="notice">
        <img
          class="notice__warning"
          src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1561469376862501135.svg"
          alt=""
          role="presentation"
        />
        <div class="notice__content">
          <p class="notice__title">
            {{ getTitle(rt) }}
          </p>
          <p class="notice__text">
            {{ getNote(rt) }}
          </p>
        </div>
      </div>

      <form class="notice-form" @submit.prevent="saveContact(rt)">
        <div class="notice-form__inner">
          <div class="form-unit">
            <label class="form-unit__label"> {{ $t("account.contacts_email") }}: </label>
            <input
              class="form-unit__input"
              type="email"
              v-model="contact[rt].data.attributes.email"
            />
          </div>
          <div class="form-unit">
            <label class="form-unit__label">{{ $t("account.contacts_phone") }}:</label>
            <input
              class="form-unit__input"
              type="text"
              v-model="contact[rt].data.attributes.phone"
            />
          </div>
        </div>
        <button class="bttn bttn--sm bttn--orange" :disabled="disableSend(rt)">Save</button>
      </form>

      <error v-if="errors" :errors="errors"></error>

      <div class="loading" v-if="loading">
        <LdsSpinner />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import error from "@/components/errors.vue";
import { notificationsMixin } from "@/mixins";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";

function getModelSchema(type) {
  return {
    [type]: {
      data: {
        attributes: {
          first_name: "",
          last_name: "",
          type,
          phone: "",
          email: "",
          note: "",
        },
      },
    },
  };
}

export default {
  CONTACT_TYPE_DONOR_SUPPORT: "donor_support",
  CONTACT_TYPE_DISPUTES_AND_FINANCIAL_SUPPORT: "disputes_and_financial_support",

  components: {
    LdsSpinner,
    error: error,
  },
  mixins: [notificationsMixin],
  data() {
    return {
      loading: false,
      errors: [],
      contact: {
        ...getModelSchema(this.$options.CONTACT_TYPE_DONOR_SUPPORT),
        ...getModelSchema(this.$options.CONTACT_TYPE_DISPUTES_AND_FINANCIAL_SUPPORT),
      },
    };
  },
  created() {
    this.$store.dispatch("getContacts");
  },
  computed: {
    ...mapState({
      contacts: (state) => state.account.contacts,
    }),

    contactsByType() {
      return this.contacts.reduce((res, c) => {
        res[c.attributes.type] = c;
        return res;
      }, {});
    },

    requiredTypes() {
      return [
        this.$options.CONTACT_TYPE_DONOR_SUPPORT,
        this.$options.CONTACT_TYPE_DISPUTES_AND_FINANCIAL_SUPPORT,
      ].filter((t) => !this.contactsByType[t]);
    },
  },
  methods: {
    getTitle(type) {
      switch (type) {
        case "disputes_and_financial_support":
          return this.$t(`account.${type}_contact_missing_title`, `${type} is missing`);
      }
      return this.$t(`account.public_contact_missing_title`);
    },
    getNote(type) {
      switch (type) {
        case "disputes_and_financial_support":
          return this.$t(
            `account.${type}_contact_missing_note`,
            `Please add your organization ${type} phone number and email address`
          );
      }
      return this.$t(`account.public_contact_missing_note`);
    },
    disableSend(type) {
      const attrs = this.contact[type].data.attributes;
      return attrs.email === "" && attrs.phone === "";
    },
    saveContact(type = this.$options.CONTACT_TYPE_DONOR_SUPPORT) {
      this.loading = true;

      let orgId = localStorage.getItem("orgId");

      this.contact[type].data.attributes.type = type;

      axios
        .post(urls.postGetContacts.replace(":orgId", orgId), this.contact[type])
        .then((response) => {
          if (response.status === 200) {
            this.$store.dispatch("getContacts");
            this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
          }
        })
        .catch(this.$_notificationsMixin_handleCatch)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
.contact-missing {
  margin-bottom: 40px;
  padding: 15px;
  background-color: #fef5f5;
  border: 1px solid #e4e8ec;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  .form-unit {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    &__label {
      min-width: 50px;
      margin-right: 15px;
      margin-bottom: 0;
      color: $fd;
      font-size: 14px;
      line-height: 1;
    }
    &__input {
      height: 34px;
      width: 100%;
      max-width: 360px;
      margin: 0;
      padding: 0 20px;
      display: block;
      color: $fd;
      font-family: $vito-light;
      font-size: 15px;
      box-shadow: none;
      border-radius: 8px;
      transition: all 0.2s;
      border: 1px solid #e6eaed;
      &:hover {
        border: 1px solid $bb-90;
      }
      &:focus {
        border: 1px solid $bb-90;
        outline: 0;
      }
      // &:disabled {
      //   background-color: $bg-50;
      //   &:hover {
      //     border: 1px solid #E6EAED;
      //   }
      // }
    }
  }
}
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.notice {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 468px) {
    flex-direction: row;
    align-items: center;
  }
  &__warning {
    margin-bottom: 15px;
    width: 63px;
    min-width: 63px;
    height: auto;
    flex-shrink: 0;
    align-self: center;
    @media only screen and (min-width: 468px) {
      margin-right: 30px;
      margin-bottom: initial;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    justify-content: center;
  }
  &__title {
    margin-bottom: 5px;
    font-family: $vito-bold;
    font-size: 16px;
    color: $fd;
    line-height: 1.125;
  }
  &__text {
    margin-bottom: 0;
    font-family: $vito-light;
    font-size: 14px;
    line-height: 1.125;
    color: $fd;
  }
}

.notice-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &__inner {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    @media only screen and (min-width: 568px) {
      flex-direction: row;
    }
    @media only screen and (min-width: 992px) {
      width: initial;
      margin-bottom: initial;
    }
  }
  .form-unit {
    margin-bottom: 20px;
    @media only screen and (min-width: 568px) {
      margin-bottom: initial;
      &:not(:last-of-type) {
        margin-right: 40px;
      }
    }
  }
  .bttn--sm {
    min-width: 180px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 992px) {
      margin-right: initial;
    }
  }
}
</style>
