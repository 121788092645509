<script>
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],
  props: {
    state: { type: Object, default: () => ({}) },
    setState: { type: Function, default: () => {} },
    submitByFormId: { type: String, default: "" },
    options: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false },
    error: { type: [Error, Object, Boolean], default: () => ({}) },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleSubmit() {
      this.$emit("can-continue", {
        continue: true,
      });
    },
    goBack() {
      this.$emit("set-step", 2);
      this.$emit("loading", false);
    },
  },
  activated() {
    if (!this.error) {
      this.$emit("loading", true);
    }
  },
  mounted() {
    this.$emit("loading", true);

    window.addEventListener("message", (event) => {
      const type = event.data && event.data.type;
      const payload = event.data && event.data.payload;

      switch (type) {
        case "session_view.document.loaded":
          console.log("Session view is loaded");
          break;
        case "session_view.document.completed":
          console.log("Document is completed");
          console.log(payload);
          this.setState({
            ...this.state,
            documentRef: payload.uuid,
          });
          this.$emit("loading", false);
          this.handleSubmit();
          break;
        case "session_view.document.exception":
          console.log("Exception during document finalization");
          console.log(payload);
          this.$emit("error", payload);
      }
    });
  },
};
</script>

<template>
  <div v-bind="{}">
    <div class="accordion" role="tablist">
      <b-card no-body>
        <b-card-header
          header-tag="header"
          class="p-2 d-flex justify-content-between align-items-center"
          role="tab"
        >
          <b-button @click="goBack" variant="link">
            <b-icon class="mx-1" icon="arrow-down-short" />
            {{ $t("account.request_foundation_modal_step_document_fields_title", "Fields") }}
          </b-button>
        </b-card-header>
        <b-collapse
          id="document-fields-collapse"
          accordion="document-fields-accordion"
          role="tabpanel"
          :visible="false"
        >
        </b-collapse>
      </b-card>
    </div>
    <b-card class="mt-3" no-body ref="embed-document-card">
      <b-card-header
        header-tag="header"
        class="p-0"
        :class="{ 'no-bottom-border': !state.embedDocumentId }"
        role="tab"
      >
        <b-alert class="mb-0 no-bottom-border no-border-radius" :variant="state.alertVariant" show>
          {{ state.alertMessage }}
        </b-alert>
      </b-card-header>
      <div class="w-100 h-auto" v-if="state.embedDocumentId">
        <iframe
          :src="`https://app.pandadoc.com/s/${state.embedDocumentId}/`"
          width="100%"
          height="800"
          ref="pandadoc"
          style="border: none"
        ></iframe>
      </div>
    </b-card>
  </div>
</template>

<style lang="scss" scoped>
.no-bottom-border {
  border-bottom: none;
}
.no-border-radius {
  border-radius: 0;
}
</style>
