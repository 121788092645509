<template>
  <div class="billings-inner">
    <div class="why-charidy__control-bar">
      <div class="control-bar">
        <input id="t0" type="radio" value="0" class="control-bar__radio" />
        <input id="t1" type="radio" value="1" class="control-bar__radio" />
        <input id="t2" type="radio" value="2" class="control-bar__radio" />
        <div
          class="control-bar__slider"
          :class="{ tab0: activetab == 1, tab1: activetab == 2, tab2: activetab == 3 }"
        ></div>
        <label
          for="t0"
          class="control-bar__tab"
          :class="{ 'tab0, active': activetab == 1 }"
          v-on:click="activetab = 1"
        >
          Daily Activity
        </label>
        <label
          for="t1"
          class="control-bar__tab"
          :class="{ 'tab1, active': activetab == 2 }"
          v-on:click="activetab = 2"
        >
          Campaign Reports
        </label>
        <label
          for="t2"
          class="control-bar__tab"
          :class="{ 'tab2, active': activetab == 3 }"
          v-on:click="activetab = 3"
        >
          Payouts
        </label>
      </div>
    </div>

    <daily-activity :activetab="activetab" />
    <campaign-reports :activetab="activetab" />
    <payouts-tab :activetab="activetab" />
  </div>
</template>

<script>
import dailyActivity from "./tabs/dailyActivity";
import campaignReports from "./tabs/campaignReports";
import payoutsTab from "./tabs/payoutsTab";

export default {
  components: {
    dailyActivity,
    campaignReports,
    payoutsTab,
  },
  data: function () {
    return {
      activetab: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.billings-inner {
  width: 100%;
}

.why-charidy__control-bar {
  margin-bottom: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.control-bar {
  display: inline-flex;
  position: relative;
  align-items: center;
  padding: 0 0 8px;
  background-color: #fff;
  box-shadow: 0px 10px 32px rgba(0, 18, 77, 0.0491112);
  border-radius: 26px;
}

.control-bar__radio {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.control-bar__slider {
  height: 54px;
  width: 33%;
  border-radius: 27px;
  transition: all 0.4s ease-in-out;
  background-color: #00d39c;
  position: absolute;
}

.tab0 {
  left: 0%;
}

.tab1 {
  left: 33.5%;
}

.tab2 {
  left: 67%;
}

.active {
  color: #fff;
}

.control-bar__tab {
  margin: 0;
  width: 172.5px;
  height: 54px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-size: 15px;
  color: #00d39c;
  z-index: 1;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.control-bar__tab.active {
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .control-bar__slider {
    display: none;
  }

  .control-bar {
    background: transparent;
    flex-direction: column;
    width: 100%;
    padding: 0;
    display: inline-flex;
    position: relative;
    align-items: center;
    box-shadow: none;
  }

  .control-bar__tab.active {
    color: #fff;
    background-color: #00d39c;
  }

  .control-bar__tab {
    border-radius: 27px;
  }
}

@media only screen and (max-width: 992px) {
  .control-bar {
    padding: 0;
  }
}

@media only screen and (min-width: 992px) {
  .control-bar {
    padding-bottom: 0;
    width: 549px;
    justify-content: center;
    flex-direction: row;
    box-shadow: 0 10px 32px 0 rgba(0, 18, 77, 0.05);
    border-radius: 27px;
    overflow: initial;
    overflow-x: initial;
  }

  .control-bar__tab {
    width: 183px;
    font-size: 17px;
  }
}
</style>
