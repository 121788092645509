export default {
  title: "DonationFormCustomFields",
  type: "object",
  expanded: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: true,
    },
    fields: {
      title: "Fields",
      type: "list",
      default: [],
      expanded: true,
      layout: "expanded",
      items: {
        type: "object",
        properties: {
          key: {
            title: "Key",
            type: "string",
            expanded: true,
          },
          order: {
            title: "Order",
            type: "number",
            expanded: true,
          },
          available_min_amount: {
            title: "Available min amount",
            type: "number",
            expanded: true,
          },
          required: {
            title: "Required",
            type: "boolean",
            expanded: true,
          },
          type: {
            title: "Type",
            expanded: true,
            type: "enum",
            default: "text",
            items: {
              enum: [
                {
                  text: "text",
                  value: "text",
                },
                {
                  text: "ddl",
                  value: "ddl",
                },
                {
                  text: "number",
                  value: "number",
                },
                {
                  text: "multiselect",
                  value: "multiselect",
                },
                {
                  text: "ddl_search",
                  value: "ddl_search",
                },
                {
                  text: "checkbox",
                  value: "checkbox",
                },
              ],
            },
          },
          price_of_option: {
            title: "Price of option",
            type: "number",
            expanded: true,
          },
          price_of_option_by_total_amount: {
            title: "Price of option by total amount",
            type: "boolean",
            expanded: true,
          },
          label: {
            title: "Label",
            type: "string",
            expanded: true,
          },
          placeholder: {
            title: "Placeholder",
            type: "string",
            expanded: true,
          },
          custom_view: {
            title: "Custom view",
            type: "number",
            expanded: true,
          },
          gateways: {
            title: "For gateways",
            type: "array",
            expanded: true,
          },
          public: {
            title: "Public",
            type: "boolean",
            expanded: true,
          },
          checked: {
            title: "Checked",
            type: "boolean",
            expanded: true,
          },
        },
      },
    },
  },
};
