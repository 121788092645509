<template>
  <b-modal
    class="export-donor-donations-popup"
    id="export-donor-donations"
    @shown="clearData"
    title
    centered
    hide-footer
  >
    <b-form id="export-donor-donations-form">
      <div class="form-group">
        <label class="form-group__label">
          {{
            $t(
              "donor_dashboard.export_sent_to_email",
              "Requested receipts will be generated and sent to email:"
            )
          }}
        </label>
      </div>

      <b-form-group :label="$t('dashboard.export_email', 'Email')">
        <b-form-input
          v-model="email"
          type="email"
          required
          :placeholder="
            $t('donor_dashboard.export_enter_your_email_placeholder', 'Enter your email')
          "
        ></b-form-input>
      </b-form-group>

      <error-message ref="error" :response.sync="errorResponse"></error-message>

      <div class="btn-wrap">
        <button
          class="add bttn bttn--lg mr-2"
          @click.prevent="
            clearData();
            hideModal();
          "
        >
          {{ $t("donor_dashboard.export_cancel", "Cancel") }}
        </button>

        <button class="add bttn bttn--lg bttn--orange float-right" @click.prevent="downloadRecipts">
          {{ $t("donor_dashboard.sent_to_email", "Sent to email") }}
        </button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";

import axios from "axios";
import urls from "@/constants/urls.js";

import errorList from "@/components/error-message.vue";

export default {
  props: ["years"],
  data() {
    return {
      email: "",
      dateFrom: null,
      dateTo: null,
      errorResponse: null,
    };
  },

  components: {
    errorMessage: errorList,
  },

  computed: mapState({
    currentDonorEmail: (state) => {
      return state.donorAccount.account.attributes.email;
    },
  }),

  methods: {
    clearData: function () {
      this.email = this.currentDonorEmail;
    },
    openModal() {
      this.$root.$emit("bv::show::modal", "export-donor-donations");
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "export-donor-donations");
    },
    getFullPeriodDateSetting(years) {
      years.sort();

      if (years.length) {
        return `&year=${years.join("&year=")}`;
      }

      return "";
    },
    downloadRecipts() {
      let params = this.getFullPeriodDateSetting(this.years);
      this.$refs.error.clearErrors();

      this.isLoading = true;
      axios
        .post(urls.donorDonationReceiptDownload.replace(":email", this.email) + params)
        .then(() => {
          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e.response);
          this.isLoading = false;
          this.errorResponse = e.response;
        });
      this.hideModal();
    },
  },
};
</script>
<style lang="scss" scoped>
.bttn--green {
  background: #00d39a;
  width: 180px;
  color: #fff;
}
.bttn--orange {
  background-color: #ff5555;
  &:hover {
    opacity: 0.85;
  }
}
.export-donor-donations-popup {
  background: #1c273999;
  color: #414359;
  .close {
    font-size: 2.5rem;
    line-height: 0.5;
    color: #107598;
    opacity: 1;
    width: 36px;
    height: 36px;
    padding: 5px;
    border-radius: 18px;
    margin-top: -5px;
    outline: none;
    &:hover {
      background: #10759824;
    }
  }
  .modal-dialog {
    max-width: 800px;
  }
  .modal-body {
    p {
      font: 30px/1.4 "VitoExtended-Bold";
      margin-bottom: 60px;
      text-align: center;
    }
  }
  .modal-content {
    background: url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco1.svg")
        top -40px left 5% no-repeat,
      url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco2.svg")
        top -50px right 20% no-repeat,
      #fff;
    .modal-header {
      background: none;
    }
    .modal-body {
      background: none;
    }
  }
  .input-wrap {
    flex-grow: 2;
  }
  .btn-wrap {
    text-align: center;
    .bttn {
      margin: 0 10px;
    }
  }
  .form-group {
    margin-bottom: 40px;
    label {
      display: block;
      color: #414359;
      font-size: 20px;
    }
  }
}
</style>
