<template>
  <div class="intity-missing">
    <div class="notice">
      <img
        class="notice__warning"
        src="https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/1561469376862501135.svg"
        alt=""
        role="presentation"
      />
      <div class="notice__content">
        <p class="notice__title">
          {{ $t("account.entity_missing_title") }}
        </p>
        <p class="notice__text">
          {{ $t("account.entity_contact_missing_note") }}
        </p>
      </div>
      <!--      <button-->
      <!--        class="bttn bttn&#45;&#45;sm bttn&#45;&#45;orange"-->
      <!--      >-->
      <!--        Add Entity-->
      <!--      </button>-->
      <entities-modal />
    </div>
  </div>
</template>

<script>
import entitiesModal from "@/views/account/subcomponents/entitiesModal";

export default {
  components: {
    entitiesModal,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
.intity-missing {
  margin-bottom: 40px;
  padding: 15px;
  background-color: #fef5f5;
  border: 1px solid #e4e8ec;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.notice {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 468px) {
    flex-direction: row;
    align-items: center;
  }
  &__warning {
    margin-bottom: 15px;
    width: 63px;
    min-width: 63px;
    height: auto;
    flex-shrink: 0;
    align-self: center;
    @media only screen and (min-width: 468px) {
      margin-right: 30px;
      margin-bottom: initial;
    }
  }
  &__content {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    justify-content: center;
  }
  &__title {
    margin-bottom: 5px;
    font-family: $vito-bold;
    font-size: 16px;
    color: $fd;
    line-height: 1.125;
  }
  &__text {
    margin-bottom: 0;
    font-family: $vito-light;
    font-size: 14px;
    line-height: 1.125;
    color: $fd;
  }
  .entity-modal {
    margin-left: auto;
  }

  ::v-deep .bttn-bordered {
    border-color: $lr-100;
    color: #ffffff;
    background-color: $lr-100;
    font-family: $vito-bold;
    &--sm {
      min-width: 180px;
    }
  }
}
</style>
