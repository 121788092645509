import { rdsStatuses } from "@/constants/rds-statuses";

const rdsStatusMixin = {
  computed: {
    $_rdsStatusMixin_rdsStatuses() {
      return rdsStatuses;
    },
  },
  methods: {
    $_rdsStatusMixin_getTranslationStatus(number) {
      const s = Object.values(this.$_rdsStatusMixin_rdsStatuses).find((v) => v.number === number);
      if (!s) {
        return this.$t("dashboard.rds_status_undefined", "undefined");
      }
      return this.$t(...s.translation);
    },
    $_rdsStatusMixin_getStatusNumber(key) {
      const s = this.$_rdsStatusMixin_rdsStatuses[key];
      if (!s) {
        return undefined;
      }
      return s.number;
    },
    $_rdsStatusMixin_getStatusCode(key) {
      return this.$_rdsStatusMixin_rdsStatuses[key]?.code;
    },
  },
};

export default rdsStatusMixin;
