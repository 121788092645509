<template lang="html">
  <div class="updates__item">
    <div class="updates__item--top">
      <h4>
        {{ update.attributes.title }}
      </h4>
      <span>{{ ago }}</span>
    </div>
    <pre v-html="message" />
    <div class="updates__item--bottom" v-if="update">
      <button v-if="update" class="bttn bttn--sm bttn--blue" @click="patch" :disabled="loading">
        {{ $t("dashboard.updates_patch", "Edit") }}
      </button>
      <button v-if="update" class="bttn bttn--sm bttn--orange" @click="remove" :disabled="loading">
        {{ $t("dashboard.updates_remove", "Remove") }}
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import { confirmMixin } from "@/mixins";
import { edjsHtmlWithTransformers } from "@/helpers/edjsHTML";

export default {
  mixins: [confirmMixin],

  props: {
    update: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapState({
      loading: (state) => state.dashboard.updates.loading,
    }),
    ago() {
      return moment(this.update.attributes.created_at * 1000).fromNow();
    },
    message() {
      try {
        const data = JSON.parse(this.update.attributes.message);
        return edjsHtmlWithTransformers().parse({ blocks: data.blocks }).join("\n");
      } catch {
        return this.update.attributes.message;
      }
    },
  },

  methods: {
    ...mapActions({
      removeUpdate: "updates/removeUpdate",
    }),
    patch() {
      this.$emit("edit", {
        update: {
          title: this.update.attributes.title,
          message: this.message,
        },
        uid: this.update.id,
      });
    },
    remove() {
      this.$_confirmMixin_deleteConfirm({
        message: this.$t("dashboard.updates_remove_confirm", "Do you want to remove this update?"),
      }).then((ok) => {
        ok && this.removeUpdate({ uid: this.update.id });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.updates {
  &__item {
    width: 100%;
    padding: 20px 30px;
    background: rgba(0, 211, 156, 0.07);
    border: 0;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 21px;
      height: 1px;
      top: 50%;
      left: -21px;
      background: $bb-100;
    }
    &:after {
      position: absolute;
      content: "";
      width: 17px;
      height: 17px;
      background: $bb-100;
      top: 50%;
      margin-top: -8px;
      left: -30px;
      border-radius: 50%;
    }
    &--top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 15px;
    }
    &--bottom {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.5em;
      @media screen and (max-width: 414px) {
        flex-flow: column;
        align-items: flex-end;
      }
    }
    h4,
    div,
    span {
      font-size: 20px;
      margin: 0;
    }
    span {
      color: #a0a1ac;
      font-size: 16px;
    }
    pre {
      font-size: 16px;
      color: #a0a1ac;
      font-family: inherit;
      overflow-wrap: break-word;
      display: flex;
      min-width: 0;
      width: 100%;
      ::v-deep * {
        word-break: normal;
        white-space: normal;
      }
      ::v-deep img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
