<template>
  <form id="gateway-form" class="gateway-form" @submit.prevent="saveForm" v-if="!loadingEntity">
    <info-alert :tKey="'gateways.info_html_stripe'" />

    <error v-if="errors" :errors="errors"></error>

    <div v-if="!required" class="form-block">
      <div class="row" v-if="!loadingLegalEntiti && entities.length > 0">
        <div class="col-12">
          <h3>{{ $t("gateways.stripe_acc_detalis") }}</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-12" v-if="!loadingLegalEntiti && entities.length > 0">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.legal_entity")
            }}</label>
            <b-form-select v-model.number="legal_entity_id" required>
              <option v-for="entity in entities" :key="entity.id" :value="entity.id">
                {{ entity.attributes.name }}
              </option>
            </b-form-select>
          </div>
        </div>

        <div class="crate-legal-entity col-12" v-if="entities.length == 0">
          <p>
            {{ $t("gateways.create_entity_text") }}
          </p>
          <button
            type="button"
            class="bttn bttn--sm bttn--orange"
            @click="createLegalEntity"
            name="button"
          >
            {{ $t("gateways.create_entity_button") }}
          </button>
          <StripeSupportedTransferCountries />
        </div>

        <div class="loadings" v-if="loadingLegalEntiti || loadingStripeData">
          <LdsSpinner />
        </div>

        <div class="col-12 col-lg-12" v-if="stripeApiData && !loadingStripeData">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.org_type")
            }}</label>
            <b-form-select v-model="selectOrganizationType" required :options="organizationType" />
          </div>
        </div>
      </div>
    </div>

    <div class="form-block" v-if="stripeApiData && !loadingStripeData && !required">
      <div class="row">
        <div class="col-12">
          <h3>{{ $t("gateways.bank_detalis") }}</h3>
        </div>
      </div>
      <div class="row align-items-end">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">
              {{ $t("gateways.charge_currency", "Charge Currency") }}
            </label>
            <multiselect
              v-model="stripeData.data.attributes.charge_currency_list"
              :options="stripeApiData.data.attributes.available_charge_currency_list"
              :multiple="true"
            />
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">
              {{ $t("gateways.bank_account_currency") }}
            </label>
            <b-form-select
              v-model="stripeData.data.attributes.bank_account_currency"
              required
              :options="stripeApiData.data.attributes.available_currency_list"
            />
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group" :class="{ 'has-error': vErrors.has('businessName') }">
            <label class="form-group__label form-group__label--required">
              {{ $t("gateways.business_name") }}
            </label>
            <b-form-input
              v-model="stripeData.data.attributes.business_name"
              v-validate="'required'"
              name="businessName"
              type="text"
            />
            <span v-show="vErrors.has('businessName')" class="v-help is-danger">
              {{ $t("template.field_is_required") }}
            </span>
          </div>
        </div>

        <div class="col-12 col-lg-6" v-if="entityCountry == 'gb'">
          <div class="form-group">
            <label class="form-group__label form-group__label--required">
              {{ $t("gateways.iban_bank_account_number") }}
            </label>
            <b-form-input
              v-model="stripeData.data.attributes.bank_account_number"
              v-validate="'required'"
              name="account_number"
              ref="account_number"
              type="text"
            />
            <span v-show="vErrors.has('account_number')" class="v-help is-danger">
              {{ $t("template.field_is_required") }}
            </span>
          </div>

          <div class="form-group">
            <label class="form-group__label form-group__label--required">
              {{ $t("gateways.confirm_iban_number") }}
            </label>
            <b-form-input
              v-model="stripeData.data.attributes.confirm_bank_account_number"
              type="text"
              v-validate="'required|confirmed:account_number'"
              name="account_number_confirmed"
              @paste.prevent
            />
            <span v-show="vErrors.has('account_number_confirmed')" class="v-help is-danger">
              {{ $t("template.iban_number_not_match") }}
            </span>
          </div>
        </div>

        <div class="col-12 col-lg-6" v-if="entityCountry == 'gb'">
          <div class="form-group">
            <label for="">{{ $t("gateways.sort_code") }}</label>
            <b-form-input
              v-model="stripeData.data.attributes.bank_account_routing_number"
              type="text"
            ></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6" v-if="entityCountry == 'au'">
          <div class="form-group">
            <label class="form-group__label form-group__label--required">{{
              $t("gateways.bsb")
            }}</label>
            <b-form-input
              required
              v-model="stripeData.data.attributes.bank_account_routing_number"
              type="text"
            >
            </b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6" v-if="showAccountNumber">
          <div class="form-group" :class="{ 'has-error': vErrors.has('account_number') }">
            <label class="form-group__label form-group__label--required">
              {{ $t("gateways.iban") }}
            </label>
            <b-form-input
              v-model="stripeData.data.attributes.bank_account_number"
              v-validate="'required'"
              name="account_number"
              ref="account_number"
              type="text"
            />
            <span v-show="vErrors.has('account_number')" class="v-help is-danger">
              {{ $t("template.field_is_required") }}
            </span>
          </div>
          <div class="form-group" :class="{ 'has-error': vErrors.has('account_number_confirmed') }">
            <label class="form-group__label form-group__label--required">
              {{ $t("gateways.confirm_iban_number") }}
            </label>
            <b-form-input
              v-model="stripeData.data.attributes.confirm_bank_account_number"
              type="text"
              v-validate="'required|confirmed:account_number'"
              name="account_number_confirmed"
              @paste.prevent
            />
            <span v-show="vErrors.has('account_number_confirmed')" class="v-help is-danger">
              {{ $t("template.iban_number_not_match") }}
            </span>
          </div>
        </div>

        <div
          class="col-12 col-lg-6"
          v-if="
            entityCountry == 'us' ||
            entityCountry == 'au' ||
            entityCountry == 'br' ||
            entityCountry == 'ca' ||
            entityCountry == 'hk' ||
            entityCountry == 'sg' ||
            entityCountry == 'nz'
          "
        >
          <div class="form-group" :class="{ 'has-error': vErrors.has('account_number') }">
            <label class="form-group__label form-group__label--required">
              {{ $t("gateways.bank_account_number") }}
            </label>
            <b-form-input
              v-model="stripeData.data.attributes.bank_account_number"
              v-validate="'required'"
              name="account_number"
              ref="account_number"
              type="text"
            />
            <span v-show="vErrors.has('account_number')" class="v-help is-danger">
              {{ $t("template.field_is_required") }}
            </span>
          </div>
          <div class="form-group" :class="{ 'has-error': vErrors.has('account_number_confirmed') }">
            <label class="form-group__label form-group__label--required">
              {{ $t("gateways.confirm_bank_account_number") }}
            </label>
            <b-form-input
              v-model="stripeData.data.attributes.confirm_bank_account_number"
              type="text"
              v-validate="'required|confirmed:account_number'"
              name="account_number_confirmed"
              @paste.prevent
            />
            <span v-show="vErrors.has('account_number_confirmed')" class="v-help is-danger">
              {{ $t("template.account_number_not_match") }}
            </span>
          </div>
        </div>

        <div class="col-12 col-lg-6" v-if="entityCountry == 'us'">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.bank_account_routing_number")
            }}</label>
            <b-form-input
              v-model="stripeData.data.attributes.bank_account_routing_number"
              required
              type="text"
            >
            </b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6" v-if="entityCountry == 'br' || entityCountry == 'sg'">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.bank_code")
            }}</label>
            <b-form-input v-model="bankCode" required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6" v-if="entityCountry == 'hk'">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.clearing_code")
            }}</label>
            <b-form-input v-model="clearingCode" required type="text"></b-form-input>
          </div>
        </div>

        <div
          class="col-12 col-lg-6"
          v-if="entityCountry == 'br' || entityCountry == 'hk' || entityCountry == 'sg'"
        >
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.branch_code")
            }}</label>
            <b-form-input v-model="branchCode" required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6" v-if="entityCountry == 'ca'">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.transit_number")
            }}</label>
            <b-form-input v-model="bankCode" required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6" v-if="entityCountry == 'ca'">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.institution_number")
            }}</label>
            <b-form-input v-model="branchCode" required type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <!--
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{ $t('gateways.statement') }}</label>
            <b-form-input v-model="stripeData.data.attributes.statement" required type="text"></b-form-input>
          </div>-->

          <div class="form-group form-unit form-unit--addon">
            <label class="form-unit__label form-unit__label--upper-sm form-unit__label--required">
              {{ $t("gateways.statement") }}
              <tooltip icon="info">
                <div class="help-tooltip__message">{{ $t("gateways.info_message") }}</div>
              </tooltip>
            </label>

            <div class="form-unit__addon-wrap">
              <div class="form-unit__addon-icon form-unit__addon-icon--wide">CHARIDY</div>
              <input
                type="text"
                required
                class="form-control form-unit__input"
                v-model="statementShortened"
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">{{
              $t("gateways.legal_entity_dob")
            }}</label>
            <b-form-input
              v-model="legal_entity_dob"
              v-on:input="selectedEntityDate"
              required
              type="date"
            >
            </b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">
              {{ $t("gateways.bank_account_country_full", "Bank account country") }}
            </label>
            <multiselect
              :allow-empty="false"
              v-model="multiselectCountry"
              :options="countryList"
              label="text"
              track-by="text"
              :multiple="false"
            />
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">
              {{ $t("gateways.entity_country_full") }}
            </label>
            <b-form-input v-model="entityCountryModel" required readonly type="text"></b-form-input>
          </div>
        </div>

        <div class="col-12 col-lg-6" v-for="input in stripeInputsApiFiltered" :key="input.id">
          <div class="form-group">
            <label class="form-group__label form-group__label--required" for="">
              <span>
                {{
                  entityCountryModel.toLowerCase() === "ca" &&
                  input.attributes.key === "legal_entity.personal_id_number"
                    ? $t(`gateways.${input.attributes.key}_canada`)
                    : $t(
                        `gateways.${input.attributes.key}`,
                        input.attributes.key.replaceAll(".", " ").replaceAll("_", " ")
                      )
                }}
              </span>
            </label>
            <b-form-input
              v-model="input.attributes.value"
              :readonly="input.attributes.readonly"
              required
              type="text"
            >
            </b-form-input>
          </div>
        </div>
      </div>
    </div>

    <div v-if="required" class="gateway-form__notice">
      {{ $t("gateways.stripe_acc_additional_verification") }}
      <a :href="link" ref="onboardingLinkEl">Click here</a>
    </div>

    <div
      class="gateway-form__notice"
      v-if="!loadingLegalEntiti && entities.length > 0 && !required"
    >
      By registering your account, you agree to our
      <a target="_blank" :href="`${appHost}/termsconditions`">Services Agreement</a> and the
      <a target="_blank" href="https://stripe.com/connect-account/legal"
        >Stripe Connected Account Agreement</a
      >.
    </div>

    <error v-if="errors" :errors="errors"></error>

    <portal to="gateway-add-footer">
      <div class="gateway-form__action">
        <button
          type="button"
          :class="
            entities.length > 0 ? 'bttn bttn--lg bttn--blue' : 'bttn bttn--lg bttn--blue el-right'
          "
          @click="$emit('close-modal')"
          name="button"
          v-if="!externalClose"
        >
          {{ $t("gateways.close") }}
        </button>
        <button
          type="submit"
          :disabled="sending || loadingLegalEntiti || loadingStripeData"
          v-if="entities.length > 0 && !required"
          :class="sending ? 'disabled bttn bttn--lg bttn--orange' : 'bttn bttn--lg bttn--orange'"
          form="gateway-form"
        >
          {{ $t("gateways.save") }}
        </button>
      </div>
    </portal>
  </form>

  <div class="loadings" v-else>
    <LdsSpinner />
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls, { appHost } from "@/constants/urls.js";
import error from "@/components/errors.vue";
import tooltip from "@/components/tooltip";
import infoAlert from "./infoAlert.vue";
import moment from "moment-timezone";
import StripeSupportedTransferCountries from "@/views/gateways/subcomponents/stripeSupportedTransferCountries.vue";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";
import Multiselect from "vue-multiselect";

export default {
  props: {
    externalClose: Boolean,
  },

  data() {
    return {
      appHost,
      stripeData: {
        data: {
          attributes: {
            type: null,
            charge_currency_list: [],
            bank_account_currency: "",
            bank_account_number: "",
            confirm_bank_account_number: "",
            bank_account_routing_number: "",
            business_name: "",
            country_code: "",
            legal_entity_id: 0,
            // currency_list: [],
            // default_currency: '',
            statement: "",
          },
        },
      },
      statementShortened: "",
      entities: [],
      legal_entity_id: 0,
      legal_entity_dob: null,
      selectOrganizationType: "company",
      listInputsStripe: null,
      loadingStripeData: false,
      loadingLegalEntiti: true,
      stripeApiData: null,
      stripeInputsApi: null,
      countryList: [],
      errors: [],
      sending: false,
      loadingEntity: true,
      bankCode: "",
      branchCode: "",
      clearingCode: "",
      entityCountryModel: "",
      multiselectCountry: null,
      organizationType: [
        { value: null, text: "Select type" },
        { value: "individual", text: this.$t("gateways.org_type_individual") },
        { value: "company", text: this.$t("gateways.org_type_company") },
      ],
      required: false,
      link: "",
    };
  },

  components: {
    error: error,
    tooltip,
    infoAlert,
    StripeSupportedTransferCountries,
    LdsSpinner,
    Multiselect,
  },

  computed: mapState({
    orgId() {
      return localStorage.getItem("orgId");
    },

    entityCountry() {
      let selectedEntity = this.entities.filter((el) => {
        return el.id == this.legal_entity_id;
      })[0];

      this.entityCountryModel = selectedEntity.attributes.country.toUpperCase();

      return selectedEntity.attributes.country.toLowerCase();
    },

    showAccountNumber() {
      return [
        "at",
        "dk",
        "fi",
        "fr",
        "de",
        "gi",
        "ie",
        "it",
        "lu",
        "nl",
        "no",
        "pt",
        "es",
        "se",
        "ch",
        "lt",
        "bg",
        "ro",
        "gr",
        "mx",
        "be",
        "mt",
        "pl",
      ].includes(this.entityCountry);
    },

    accountEntities: (state) => state.account.entities,

    stripeInputsApiFiltered() {
      return this.stripeInputsApi.filter((el) => el.attributes.visible);
    },
  }),

  watch: {
    legal_entity_id: function () {
      this.getStripeData();
    },

    selectOrganizationType: function () {
      this.setStripeInputs();
    },

    accountEntities() {
      this.getentitiesList();
    },

    multiselectCountry(val, oldVal) {
      if (oldVal) {
        this.stripeData.data.attributes.country_code = val.value.toUpperCase();
      }
    },
  },

  methods: {
    selectedEntityDate: function (date) {
      let year = moment(new Date(date)).format("YYYY");
      let month = moment(new Date(date)).format("MM");
      let day = moment(new Date(date)).format("DD");

      const { included } = this.stripeApiData;

      for (var i = 0; i < included.length; i++) {
        const { key } = included[i].attributes;

        if (key && key.includes(".dob.year")) {
          this.stripeApiData.included[i].attributes.value = String(year);
        }

        if (key && key.includes(".dob.month")) {
          this.stripeApiData.included[i].attributes.value = String(month);
        }

        if (key && key.includes(".dob.day")) {
          this.stripeApiData.included[i].attributes.value = String(day);
        }
      }
    },

    saveForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          // submit logic
          this.errors = [];
          this.sending = true;

          this.stripeData.data.attributes.type = this.selectOrganizationType;
          this.stripeData.data.attributes.statement = "CHARIDY " + this.statementShortened;

          this.stripeData.data.attributes.legal_entity_id = parseInt(this.legal_entity_id);

          if (
            this.bankCode &&
            this.branchCode &&
            this.entityCountry != "sg" &&
            this.entityCountry != "hk"
          ) {
            this.stripeData.data.attributes.bank_account_routing_number =
              String(this.bankCode) + String(this.branchCode);
          } else if (this.entityCountry == "sg") {
            this.stripeData.data.attributes.bank_account_routing_number =
              String(this.bankCode) + "-" + String(this.branchCode);
          } else if (this.entityCountry == "hk") {
            this.stripeData.data.attributes.bank_account_routing_number =
              String(this.clearingCode) + "-" + String(this.branchCode);
          }

          let stripeDataForSend;

          if (this.selectOrganizationType == "individual") {
            stripeDataForSend = {
              data: {
                type: "stripe_custom_account_request",
                attributes: this.stripeData.data.attributes,
                relationships: {
                  stripe_min_params_individual:
                    this.stripeApiData.data.relationships.stripe_min_params_individual,
                },
              },
              included: this.stripeInputsApi,
            };
          } else if (this.selectOrganizationType == "company") {
            stripeDataForSend = {
              data: {
                type: "stripe_custom_account_request",
                attributes: this.stripeData.data.attributes,
                relationships: {
                  stripe_min_params_company:
                    this.stripeApiData.data.relationships.stripe_min_params_company,
                },
              },
              included: this.stripeInputsApi,
            };
          }

          axios

            .post(
              urls.stripeApi.replace(":orgId", this.orgId).replace(":id", this.legal_entity_id),
              stripeDataForSend
            )
            .then(({ data: { data: createdGateway } }) => {
              this.$emit("submit", createdGateway);
              this.getStripeOnBoarding(createdGateway.id).then(({ data }) => {
                this.sending = false;

                const { required, link } = data.data.attributes;

                this.$store.dispatch("getGateways");

                this.$_notificationsMixin_makeToastCompat({
                  group: "Notification",
                  title: this.$t("gateways.add_stripe"),
                });

                if (required) {
                  this.required = required;
                  this.link = link;

                  setTimeout(() => {
                    this.$refs.onboardingLinkEl?.click();
                  }, 300);
                } else {
                  this.$emit("close-modal");
                }
              });
            })
            .catch((e) => {
              console.log("error", e);
              this.sending = false;

              if (e.response?.data?.error) {
                this.errors.push(e.response.data.error);
              } else if (e.response?.data?.errors) {
                this.errors = e.response.data.errors.map((err) => err.detail);
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            });
          // end of submit logic
        }
      });
    },

    getStripeOnBoarding(id) {
      return axios.get(urls.stripeOnBoarding.replace(":oid", this.orgId).replace(":idGateway", id));
    },

    createLegalEntity: function () {
      this.$emit("create-entity");
    },

    getStripeData() {
      this.errors = [];

      this.loadingStripeData = true;

      axios
        .get(urls.stripeApi.replace(":orgId", this.orgId).replace(":id", this.legal_entity_id))

        .then((response) => {
          this.loadingStripeData = false;

          this.stripeApiData = response.data;
          this.stripeData.data.attributes.statement = this.stripeApiData.data.attributes.statement;
          this.statementShortened = this.stripeData.data.attributes.statement.replace(
            "CHARIDY ",
            ""
          );

          if (this.selectOrganizationType) {
            this.setStripeInputs();
          }
          this.getCoutryList();
        })

        .catch((e) => {
          this.loadingStripeData = false;

          if (e.response?.data?.error) {
            this.errors.push(e.response.data.error);
          } else if (e.response?.data?.errors) {
            this.errors = e.response.data.errors.map((err) => err.detail);
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },

    getentitiesList: function () {
      this.loadingEntity = true;

      axios
        .get(urls.getStripeEntiti.replace(":orgId", this.orgId))

        .then((response) => {
          this.entities = response.data.data;
          if (this.entities.length == 1) {
            this.legal_entity_id = this.entities[0].id;
          }

          this.loadingEntity = false;

          this.loadingLegalEntiti = false;
        })

        .catch((e) => {
          if (e.response?.data?.error) {
            this.errors.push(e.response.data.error);
          } else if (e.response?.data?.errors) {
            this.errors = e.response.data.errors.map((err) => err.detail);
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
          this.loadingEntity = false;
        });
    },

    setStripeInputs() {
      let typeData = this.selectOrganizationType;

      if (!this.stripeApiData) {
        return false;
      }

      let includedArr = this.stripeApiData.included;

      let relationships;

      if (typeData == "individual") {
        relationships = this.stripeApiData.data.relationships.stripe_min_params_individual.data;
      } else if (typeData == "company") {
        relationships = this.stripeApiData.data.relationships.stripe_min_params_company.data;
      }

      let resultArr = [];

      for (var i = 0; i < relationships.length; i++) {
        let includedItem = includedArr.filter((el) => {
          return el.type == relationships[i].type && el.id == relationships[i].id;
        });

        if (includedItem.length > 0) {
          resultArr.push(includedItem[0]);
        }
      }

      this.stripeInputsApi = resultArr;
    },

    getCoutryList() {
      axios
        .get(urls.getEntitiesCountry.replace(":orgId", this.orgId))

        .then((response) => {
          let apiResult = response.data.data;
          let resultArr = apiResult.map((el) => el.attributes);
          let us = resultArr.filter((el) => el.value === "US")[0];
          let filtered = resultArr.filter((el) => el.value !== "US");
          this.countryList = [us, ...filtered];
          const leCountry = this.countryList.find((el) => el.value === this.entityCountryModel);
          if (leCountry) {
            this.multiselectCountry = leCountry;
          }
        })

        .catch((e) => {
          this.catchError(e);
        });
    },
  },

  mounted() {
    this.getentitiesList();
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;

  &.has-error {
    .form-control {
      border-color: #f26e66;
    }
  }
}

.v-help {
  font-size: 14px;
  position: absolute;
  bottom: -18px;
  right: 0;
}

.is-danger {
  color: #f26e66;
}

.loadings {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.alert-danger {
  width: auto;
  margin-left: 0;

  li {
    width: auto;
    margin-left: 0;
  }
}

.crate-legal-entity {
  button {
    display: block;
    margin: 20px auto;
  }
}
</style>
