<template>
  <div class="table-wrapper">
    <b-form-group
      v-if="selectedItems.length > 0"
      id="fieldset-table-actions"
      :label="$t('dashboard.sefer_torah_public_actions', 'Actions')"
    >
      <b-button class="mb-3 bttn bttn--lg bttn--orange mx-1" @click="onRemoveMultiple">
        {{ $t("dashboard.sefer_torah_public_remove_multiple", "Remove multiple") }}
      </b-button>
    </b-form-group>
    <b-form-group
      id="fieldset-table-search-by-title"
      :label="$t('dashboard.sefer_torah_public_search_by_title', 'Search by title')"
      label-for="table-search-by-title"
    >
      <b-form-input
        id="table-search-by-title"
        key="table-search-by-title"
        :value="search"
        @input="onSearch"
        :disabled="loadingSort"
      ></b-form-input>
    </b-form-group>
    <b-table
      ref="SeferTorahPublicTable"
      id="SeferTorahPublicTable"
      head-variant="light"
      :busy="loading"
      :items="list"
      :fields="fields"
      :no-local-sorting="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      selectable
      select-mode="multi"
      @row-selected="onRowSelected"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>

      <!-- Select One -->
      <template v-slot:cell(selected)="{ rowSelected }">
        <template>
          <b-form-checkbox :checked="rowSelected" :disabled="true"></b-form-checkbox>
          <span class="sr-only" v-if="rowSelected">Selected</span>
          <span class="sr-only" v-else>Not selected</span>
        </template>
      </template>
      <!-- Select All -->
      <template v-slot:head(selected)>
        <b-form-checkbox v-model="selectAll"></b-form-checkbox>
      </template>

      <template v-slot:[`cell(attributes.sort)`]="{ item }">
        <div class="d-flex flex-column align-items-center mr-auto p-2">
          <button
            :disabled="loadingSort || !hasUpperBoundOrder(item)"
            class="bttn bttn-round"
            @click="changeOrder(item, 'increase')"
          >
            <b-icon-arrow-up-short />
          </button>
          <span>{{ item.attributes.sort }}</span>
          <button
            :disabled="loadingSort || item.attributes.sort === 0 || !hasLowerBoundOrder(item)"
            class="bttn bttn-round"
            @click="changeOrder(item, 'decrease')"
          >
            <b-icon-arrow-down-short />
          </button>
        </div>
      </template>

      <template
        v-slot:[`cell(attributes.extend.parent)`]="{
          item: {
            attributes: {
              extend: { parent },
            },
          },
        }"
      >
        <b-list-group v-if="parent">
          <p>
            {{ parent.name }}
          </p>
        </b-list-group>
        <b-alert v-else show variant="warning">
          {{ $t("dashboard.sefer_torah_public_th_parentitem_notfound", "No parent item found") }}
        </b-alert>
      </template>

      <template v-slot:[`head(attributes.status)`]="{}">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex flex-column align-items-center">
            <span>
              {{ $t("dashboard.sefer_torah_public_th_active", "Active") }}
            </span>
            <b-badge v-if="sortBy === '' && !sortDesc" pill variant="secondary">
              {{ $t("dashboard.sefer_torah_public_th_active_all", "All") }}
            </b-badge>
            <b-badge v-else-if="sortBy === 'attributes.status' && !sortDesc" pill variant="success">
              {{ $t("dashboard.sefer_torah_public_th_active_available", "Available") }}
            </b-badge>
            <b-badge v-else-if="sortBy === 'attributes.status' && sortDesc" pill variant="danger">
              {{ $t("dashboard.sefer_torah_public_th_active_taken", "Taken") }}
            </b-badge>
          </div>
          <b-button v-if="sortBy !== ''" variant="link" @click="clearSort">
            <b-icon-x />
          </b-button>
        </div>
      </template>

      <template
        v-slot:[`cell(attributes.status)`]="{
          item: {
            attributes: {
              status,
              extend: { learner, learners },
            },
          },
        }"
      >
        <b-badge v-if="status === 0" pill variant="secondary">{{
          $t("dashboard.sefer_torah_public_th_active_nodefined", "No defined")
        }}</b-badge>
        <b-badge v-else-if="isAvailable({ learner, learners, status })" pill variant="success">{{
          $t("dashboard.sefer_torah_public_th_active_available", "Available")
        }}</b-badge>
        <b-badge v-else-if="isTaken({ learner, learners, status })" pill variant="danger">{{
          $t("dashboard.sefer_torah_public_th_active_taken", "Taken")
        }}</b-badge>
      </template>

      <template
        v-slot:[`cell(attributes.extend.learners)`]="{
          item: {
            attributes: {
              extend: { learners },
            },
          },
        }"
      >
        <b-list-group v-if="learners && learners.length > 0">
          <b-list-group-item v-for="learner in learners" :key="learner.id">
            <a :href="learner.link" target="_blank">
              {{ learner.name }}
            </a>
          </b-list-group-item>
        </b-list-group>
        <b-alert v-else show variant="warning">
          {{ $t("dashboard.sefer_torah_public_th_takenby_notfound", "No learners found") }}
        </b-alert>
      </template>

      <template v-slot:cell(actions)="{ item }">
        <TableOptionsDropdown
          :options="[
            {
              text: $t('dashboard.utm_actions_edit'),
              action: () => openAddModal({ originalData: item }),
            },
            {
              text: $t('dashboard.utm_actions_delete'),
              action: () => remove(item.id),
              variant: 'danger',
            },
          ]"
        />
      </template>
    </b-table>
    <b-pagination
      :value="page"
      @input="$emit('update:page', $event)"
      :total-rows="rows"
      :per-page="perPage"
      size="sm"
      class="mb-0"
    />
  </div>
</template>

<script>
import TableOptionsDropdown from "@/common-components/ui-elements/TableOptionsDropdown.vue";
import debounce from "lodash/debounce";

export default {
  components: {
    TableOptionsDropdown,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    remove: {
      type: Function,
      default: () => {},
    },
    openAddModal: {
      type: Function,
      default: () => {},
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 9,
    },
    rows: {
      type: Number,
      default: 0,
    },
    loading: Boolean,
    loadingSort: Boolean,
  },
  data() {
    return {
      fields: [
        "selected",
        {
          key: "attributes.sort",
          label: this.$t("dashboard.sefer_torah_public_th_sort", "Sort"),
        },
        {
          key: "attributes.title",
          label: this.$t("dashboard.sefer_torah_public_th_title", "Title"),
        },
        {
          key: "attributes.subtitle",
          label: this.$t("dashboard.sefer_torah_public_th_subtitle", "Subtitle"),
        },
        {
          key: "attributes.extend.parent",
          label: this.$t("dashboard.sefer_torah_public_th_parentitem", "Parent Item"),
        },
        {
          key: "attributes.description",
          label: this.$t("dashboard.sefer_torah_public_th_description", "Description"),
        },
        {
          key: "attributes.status",
          label: this.$t("dashboard.sefer_torah_public_th_active", "Active"),
          sortable: true,
        },
        {
          key: "attributes.amount",
          label: this.$t("dashboard.sefer_torah_public_th_amount", "Amount"),
        },
        {
          key: "attributes.extend.learners",
          label: this.$t("dashboard.sefer_torah_public_th_takenby", "Taken by"),
        },
        {
          key: "actions",
          label: "",
        },
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      search: "",
      selectedItems: [],
      selectAll: false,
    };
  },
  watch: {
    sortBy() {
      this.sortChange({ sortBy: this.sortBy, sortDesc: this.sortDesc });
    },
    sortDesc() {
      this.sortChange({ sortBy: this.sortBy, sortDesc: this.sortDesc });
    },
    selectAll(val) {
      if (val) {
        this.selectAllRows();
      } else {
        this.clearSelected();
      }
    },
  },
  methods: {
    isTaken({ learner, learners, status }) {
      return status === 2 || learner || (learners && learners.length > 0);
    },
    isAvailable({ learner, learners, status }) {
      return status === 1 && (!learner || !(learners && learners.length > 0));
    },
    changeOrder: debounce(function (item, direction) {
      this.$emit("change-order", item, direction);
    }, 300),
    hasUpperBoundOrder(level) {
      if (this.search !== "") {
        return false;
      }

      if (this.sortBy !== "") {
        return false;
      }

      // Determine the maximum order value in the list
      const maxSort = Math.max(...this.list.map((item) => item.attributes.sort));

      // Check if the item's order is less than the maximum order
      return level.attributes.sort < maxSort;
    },
    hasLowerBoundOrder(level) {
      if (this.search !== "") {
        return false;
      }

      if (this.sortBy !== "") {
        return false;
      }

      // Determine the minimum order value in the list
      const minSort = Math.min(...this.list.map((item) => item.attributes.sort));

      // Check if the item's order is greater than the minimum order
      return level.attributes.sort > minSort;
    },
    resolveSortByToParams(sortBy, sortDesc) {
      let status = null;
      if (sortBy === "attributes.status" && !sortDesc) {
        status = 1;
      } else if (sortBy === "attributes.status" && sortDesc) {
        status = 2;
      }

      return { status };
    },
    sortChange({ sortBy, sortDesc }) {
      const params = this.resolveSortByToParams(sortBy, sortDesc);

      this.$emit("sort-change", { query: this.search }, params);
    },
    clearSort() {
      this.sortBy = "";
      this.sortDesc = false;
      this.sortDirection = "asc";
    },
    onSearch(value) {
      this.search = value;
      this.emitSearch();
    },
    emitSearch: debounce(function () {
      const params = this.resolveSortByToParams(this.sortBy, this.sortDesc);
      this.$emit("search-change", params, this.search);
    }, 1000),
    onRowSelected(items) {
      this.selectedItems = items;
    },
    onRemoveMultiple() {
      this.$emit("remove-multiple", this.selectedItems);
    },
    selectAllRows() {
      this.$refs.SeferTorahPublicTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.SeferTorahPublicTable.clearSelected();
      this.selectedItems = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  ::v-deep {
    .table.b-table > thead > tr > th,
    .table.b-table > tfoot > tr > td {
      background-image: none !important;
    }
  }
}
</style>
