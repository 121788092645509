<template>
  <div>
    <b-modal id="editReceipt" size="lg">
      <div slot="modal-header">
        <div class="modal-header__close" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
        <h2 class="h1-like">{{ $t("dashboard.receipt_receipt", "Receipt") }}</h2>
      </div>
      <div slot="modal-footer">
        <button form="editReceipt" class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
          {{ $t("dashboard.receipt_cancel", "Cancel") }}
        </button>
      </div>

      <form
        id="receipt-edit"
        v-if="receiptExist"
        @submit.prevent="formSend"
        class="receipt-data entities-data"
      >
        <div class="row receipt-data__update">
          <div class="col-12">
            <div class="form-group">
              <label for="receipt-name" class="entities-data__label entities-data">
                {{ $t("dashboard.receipt_name", "Name") }}
              </label>

              <b-form-input id="receipt-name" v-model="newName"></b-form-input>
            </div>
          </div>
          <div v-if="false" class="col-6">
            <div class="form-group">
              <label for="receipt-address" class="entities-data__label entities-data">
                {{ $t("dashboard.receipt_address") }}
              </label>

              <b-form-input id="receipt-address" v-model="newAddress"></b-form-input>
            </div>
          </div>
        </div>

        <div v-if="!hideRegenerate" class="regenerate-block">
          <!--
          <a
            v-if="!regenerateStatus.loading && !regenerateStatus.success"
            @click.prevent="regenerate"
            href="#"
            v-b-tooltip.hover
            :title="$t('dashboard.regenerate_receipt_tooltip')"
            :disabled="newName.length === 0"
            class="generate-link"
          >
            {{ $t('dashboard.regenerate_receipt') }}
          </a>

          <a
            v-else-if='regenerateStatus.loading'
            href="#"
          >
            {{ $t('dashboard.regenerate_receipt_loading') }}
          </a>
          -->

          <a
            v-if="!regenerating"
            @click.prevent="regenerate"
            href="#"
            v-b-tooltip.hover
            :title="$t('dashboard.regenerate_receipt_tooltip')"
            :disabled="newName.length === 0"
            class="btn bttn bttn--sm bttn--orange"
          >
            {{ $t("dashboard.regenerate_receipt", "Regenerate") }}
          </a>

          <a v-if="regenerating" href="#">
            {{ $t("dashboard.regenerate_receipt_loading") }}
          </a>
          <!--
          <a
            v-else-if='regenerateStatus.success'
            @click.prevent="loadReceipt(donation.id)"
            href="#"
          >
            {{ $t('dashboard.regenerate_receipt_success') }}
          </a>
          -->
        </div>

        <div class="row align-items-start">
          <div class="col-12 receipt-data__receipt">
            <div v-if="loaderFlag" class="spinner-wrapper">
              <LdsSpinner />
            </div>

            <div v-else v-html="receiptHtml" class="receipt-data__receipt-content"></div>

            <a :href="receiptDownload">
              <svg xmlns="http://www.w3.org/2000/svg" width="144" height="144" viewBox="0 0 24 24">
                <path
                  d="M18 17H6v-2h12v2zm0-4H6v-2h12v2zm0-4H6V7h12v2zM3 22l1.5-1.5L6 22l1.5-1.5L9 22l1.5-1.5L12 22l1.5-1.5L15 22l1.5-1.5L18 22l1.5-1.5L21 22V2l-1.5 1.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2 4.5 3.5 3 2v20z"
                ></path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
              Download receipt
            </a>
          </div>

          <div v-if="false" class="col-12">
            <div class="receipt-data__send">
              <div class="form-group">
                <label for="receipt-email" class="entities-data__label entities-data">
                  {{ $t("dashboard.receipt_email") }}
                </label>

                <b-form-input id="receipt-email" v-model="initialEmail"></b-form-input>
              </div>

              <button
                v-if="!sendFlag"
                form="receiptEdit"
                class="bttn bttn--lg bttn--orange"
                :disabled="sendFlag === 2"
              >
                <span>{{ $t("dashboard.receipt_resend") }}</span>
              </button>
              <span class="receipt-send-message" v-if="sendFlag === 1">{{
                $t("dashboard.receipt_sending")
              }}</span>
              <span class="receipt-send-message" v-if="sendFlag === 2">{{
                $t("dashboard.receipt_sent")
              }}</span>
            </div>
          </div>
        </div>
      </form>

      <div v-if="!receiptExist && donation">
        <div
          v-html="
            $t(
              `donor_dashboard.donor_external_receipt_${donation.bank_name}`,
              'Charidy is not able to provide you with the receipt due to the processor\'s privacy policy. Please check your email inbox and spam folder for your receipt. If you cannot locate it, please reach out to the organization directly using the contact details provided below.'
            )
          "
        ></div>
        <OrgContacts v-if="donation" :orgAttrs="donation.organizationAttr" />
      </div>

      <div v-if="regenerating" class="process-overlay">
        <LdsSpinner />
        <span>{{ progress }}</span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import urls from "@/constants/urls.js";
import axios from "axios";
import LdsSpinner from "@/common-components/ui-elements/lds-spinner.vue";
import OrgContacts from "../donor-donations/org-contacts.vue";

export default {
  components: {
    LdsSpinner,
    OrgContacts,
  },

  data() {
    return {
      receiptExist: false,
      donation: null,
      initialEmail: "",
      newName: "",
      newAddress: "",
      loaderFlag: true,
      sendFlag: false,
      receipt: null,
      regenerating: false,
      procId: null,
      progress: 0,
      //
      regenerateStatus: {
        loading: false,
        success: false,
      },
      hideRegenerate: false,
    };
  },

  computed: {
    ...mapState({
      donorAccountData: (state) => {
        return state.donorAccount.account.attributes;
      },
    }),
    receiptHtml() {
      return this.receipt !== null ? this.receipt.attributes.content : "";
    },
    receiptDownload() {
      return this.receipt !== null ? this.receipt.attributes.download_link : "#";
    },
  },

  methods: {
    openModal(donate) {
      this.donation = donate;

      this.receiptExist = donate.donation_receipt_id > 0;

      if (this.receiptExist) {
        let receiptId = donate.id;
        this.loadReceipt(receiptId);
        this.initialName();
      }

      this.$root.$emit("bv::show::modal", "editReceipt");
    },

    hideModal() {
      this.loaderFlag = true;
      this.donation = null;
      this.regenerating = false;
      this.regenerateStatus = {
        loading: false,
        success: false,
      };
      this.hideRegenerate = false;
      // this.newName = '';
      this.progress = 0;
      this.$root.$emit("bv::hide::modal", "editReceipt");
    },

    initialName() {
      this.newName = `${this.donorAccountData.first_name} ${this.donorAccountData.last_name}`;
    },

    loadReceipt(receipt) {
      this.loaderFlag = true;
      const authToken = `Bearer ${localStorage.getItem("jwt_token")}`;
      const URL = urls.donorDonationReceipt.replace(":donationId", receipt);

      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        })
        .get(URL)
        .then((res) => {
          if (res.status === 200) {
            this.receipt = res.data.data;
            this.loaderFlag = false;
          }
        })
        .catch((err) => console.log(err));
    },

    regenerate() {
      this.regenerating = true;
      const receiptId = this.donation.id;
      const authToken = `Bearer ${localStorage.getItem("jwt_token")}`;
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        })
        .post(urls.donorReceiptRegenerate.replace(":donationId", receiptId), {
          force: true,
          generateReceipt: true,
          generateEmail: true,
          sendEmail: true,
          billing_name: this.newName,
        })
        .then((res) => {
          if (res.status === 200) {
            this.procId = res.data.proc;
            this.procCheck();
          }
        })
        .catch((e) => {
          console.log(e);
          this.hideRegenerate = true;
        });
    },

    procCheck() {
      let token = localStorage.getItem("jwt_token");

      let interval = setInterval(() => {
        axios
          .create({
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .get(urls.oldProcProgressTracker.replace(":id", this.procId))
          .then((res) => {
            let data = res.data.data;
            this.progress = data.attributes.progress;
            if (data.attributes.finished) {
              clearInterval(interval);
              // this.newName = '';
              this.progress = 0;
              this.regenerating = false;
              this.loadReceipt(this.donation.id);
            }
          })
          .catch((err) => console.log(err));
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
#editReceipt {
  .modal-header {
    position: relative;

    &__close {
      display: inline-flex;
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .modal-content {
    overflow: hidden;
  }

  .spinner-wrapper {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed rgba(0, 0, 0, 0.1);
  }
}

.receipt-data {
  &__receipt {
    //text-align: center;

    &-content {
      width: 100%;
      max-height: 400px;
      margin-bottom: 5px;
      overflow-x: auto;
      overflow-y: auto;
      table {
        margin: 0 auto !important;
      }
    }

    a {
      margin: 0 auto 20px;
      display: inline-flex;
      width: 100%;
      //flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      text-transform: uppercase;
      color: #20434e;
      transition: all 0.2s ease-in-out;
      svg {
        display: block;
        margin-right: 10px;
        width: 24px;
        height: auto;
        fill: #c1c1c1;
      }
      &:hover,
      &:focus,
      &:active {
        color: #cc6d4c;
      }
    }
  }
  &__update {
    .form-group {
      margin-bottom: 10px;
      max-width: 310px;
      width: 100%;
      @media only screen and (min-width: 768px) {
        max-width: initial;
      }
    }
  }

  .form-group {
    .form-control {
      height: 45px;
      width: 100%;
    }
  }

  .receipt-send {
    span {
      font-family: inherit;
      font-size: inherit;
      color: inherit;
    }
    &--success {
      color: #666666;
      background-color: transparent;
    }
  }

  &__send {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    @media only screen and (min-width: 768px) {
      flex-wrap: nowrap;
    }
    button {
      margin: 0;
      align-self: flex-end;
    }
    .form-group {
      margin-bottom: 10px;
      max-width: 310px;
      width: 100%;
      @media only screen and (min-width: 768px) {
        max-width: 290px;
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
  }
}

.modal-footer {
  .bttn--blue {
    margin-left: auto;
  }
}

.receipt-send-message {
  height: 46px;
  display: flex;
  align-items: center;
  align-self: flex-end;
}

.regenerate-block {
  padding-top: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: flex-end;
  width: 100%;
}

.generate-link {
  &[disabled] {
    pointer-events: none;
    color: gray;
  }
}

.process-overlay {
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -72px;
  left: 0;
  bottom: -87px;
  > span {
    padding-top: 20px;
  }
}
</style>
