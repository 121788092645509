<template>
  <b-row>
    <b-col v-for="item in list" :key="item.id" cols="12" md="3" class="mb-4">
      <b-card class="bg-white shadow rounded position-relative" no-body>
        <TableOptionsDropdown
          :options="[
            { text: $t('dashboard.utm_actions_edit'), action: () => openAddModal(item) },
            {
              text: $t('dashboard.utm_actions_delete'),
              action: () => remove(item),
              variant: 'danger',
            },
          ]"
          toggle-class="position-absolute bg-light close-btn rounded-circle"
        />
        <b-embed type="video" class="svw-unidy-embed" controls>
          <source :src="item.video" type="video/mp4" />
        </b-embed>
        <template #footer>
          <b-card-text>
            {{ item.name }}
          </b-card-text>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import TableOptionsDropdown from "@/common-components/ui-elements/TableOptionsDropdown.vue";
export default {
  components: { TableOptionsDropdown },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    openAddModal: {
      type: Function,
      default: () => {},
    },
    remove: {
      type: Function,
      default: () => {},
    },
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep .close-btn {
  z-index: 5;
  top: -20px;
  right: -20px;
  width: 48px;
  height: 48px;
  svg {
    width: 24px;
    height: 24px;
  }
}
</style>
