<template>
  <div>
    <b-modal id="TeamsDonationModal" size="xl" ref="TeamsDonationModal">
      <div slot="modal-header">
        <h2>{{ $t("dashboard.campaign_team_donation", "Team Donation") }}</h2>
        <div class="modal-header__hide" @click="hide">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        </div>
      </div>

      <b-overlay :show="loading" rounded="sm">
        <b-row class="row flex-column col-12 mb-3">
          <b-col @click="donationChartCollapse = !donationChartCollapse">
            <div class="">
              <b-icon
                :class="donationChartCollapse ? 'rotate-180' : ''"
                id="collapse-icon"
                v-b-tooltip.hover.top="
                  this.$t('dashboard.teams_tooltip_donation_chart', 'View donation graph')
                "
                icon="chevron-down"
              ></b-icon>
              {{ $t("dashboard.team_donation_chart", "View donation graph") }}
            </div>
          </b-col>

          <b-col>
            <b-collapse v-model="donationChartCollapse" class="mt-2">
              <b-card>
                <teamDonationChart :donation="teamDonationsList" />
              </b-card>
            </b-collapse>
          </b-col>
        </b-row>

        <b-table
          :fields="fields"
          :items="teamDonationsList"
          caption-top
          stacked="lg"
          hover
          small
          head-variant="light"
          responsive="sm"
          class="text-center team-table"
        >
          <template v-slot:head(id)>
            <div>{{ $t("dashboard.donation_id", "DID") }}</div>
          </template>
          <template v-slot:head(date)>
            <div>{{ $t("dashboard.donation_date", "Date") }}</div>
          </template>
          <template v-slot:head(name)>
            <div>{{ $t("dashboard.donation_name", "Name") }}</div>
          </template>
          <template v-slot:head(display_name)>
            <div>{{ $t("dashboard.donation_display_name", "Display Name") }}</div>
          </template>
          <template v-slot:head(charged_amount)>
            <div>{{ $t("dashboard.donation_amount_charged", "Real Amount") }}</div>
          </template>
          <template v-slot:head(effective_amount)>
            <div>{{ $t("dashboard.donation_amount_effective", "Total Amount") }}</div>
          </template>
          <template v-slot:head(email)>
            <div>{{ $t("dashboard.donation_email", "Email") }}</div>
          </template>
          <template v-slot:head(phone)>
            <div>{{ $t("dashboard.donation_phone", "Phone") }}</div>
          </template>

          <template v-slot:cell(id)="{ item }">
            <div>{{ item.id }}</div>
          </template>
          <template v-slot:cell(date)="{ item }">
            <div>{{ getDateFormat(item.attributes.date) }}</div>
          </template>
          <template v-slot:cell(name)="{ item }">
            <div>{{ item.attributes.billing_name }} {{ item.attributes.billing_last_name }}</div>
          </template>
          <template v-slot:cell(display_name)="{ item }">
            <div>{{ item.attributes.display_name }}</div>
          </template>
          <template v-slot:cell(charged_amount)="{ item }">
            <div>{{ campaignCurrencySign }} {{ item.attributes.charged_amount }}</div>
          </template>
          <template v-slot:cell(effective_amount)="{ item }">
            <div>{{ campaignCurrencySign }} {{ item.attributes.effective_amount }}</div>
          </template>
          <template v-slot:cell(email)="{ item }">
            <div>{{ item.attributes.email }}</div>
          </template>
          <template v-slot:cell(phone)="{ item }">
            <div>{{ item.attributes.phone }}</div>
          </template>
        </b-table>

        <div v-if="teamDonationsList.length === 0" class="no-data">
          {{
            $t(
              "dashboard.campaign_donation_no_data_available",
              "No Data available on your request."
            )
          }}
        </div>
      </b-overlay>
      <div slot="modal-footer">
        <button type="button" class="bttn bttn--lg bttn--blue" @click="hide" name="button">
          {{ $t("teams.close") }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

import teamDonationChart from "./teamDonationChart.vue";

export default {
  components: {
    teamDonationChart,
  },
  name: "TeamsDonationModal",
  data() {
    return {
      id: null,
      fields: [
        "id",
        "date",
        "name",
        "display_name",
        "charged_amount",
        "effective_amount",
        "email",
        "phone",
      ],
      donationChartCollapse: false,
    };
  },
  computed: {
    ...mapState({
      campaignId: (state) => state.dashboard.selectedCampaign,
      campaignCurrencySign: (state) => state.campaign.campaign.data.attributes.currency_sign,
      teamDonationsList: (state) => state.donation.donations,
      loading: (state) => state.donation.loadingDonation,
    }),
  },
  methods: {
    show(id) {
      this.$refs.TeamsDonationModal.show(id);
      this.id = id;
      this.getCampaignDonation();
    },
    getCampaignDonation() {
      const params = {
        id: this.campaignId,
        setting: `?team_id=${this.id}&status=processed`,
      };
      this.$store.dispatch("getCampaignDonation", params);
    },
    hide() {
      this.$refs.TeamsDonationModal.hide();
      this.$store.dispatch("getCampaignDonation", {
        id: this.campaignId,
        setting: `?`,
      });
    },
    getDateFormat: function (date) {
      let donateDate = moment.unix(date).format("MMMM D, YYYY");

      let thisDate = moment(new Date()).format("MMMM D, YYYY");

      if (donateDate == thisDate) {
        return moment.unix(date).format("h:mm a");
      } else {
        return moment.unix(date).format("MMMM D, YYYY h:mm a");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  min-height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#collapse-icon {
  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
.rotate-180 {
  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  stroke: #107598;
  transform: rotate(180deg);
}
</style>
