<template>
  <div class="email-tempaltes">
    <div class="inner-tabs justify-content-around">
      <div class="inner-tabs__unit" :class="{ active: currentEmailType == 'confirmation_email' }">
        {{ $t("edit_campaign.email_template_confirmation") }}
      </div>
      <div class="inner-tabs__unit" :class="{ active: currentEmailType == 'receipt_email' }">
        {{ $t("edit_campaign.email_template_receipt") }}
      </div>
    </div>

    <div class="campaign-edit-title">
      <h2 class="h2-like">{{ $t("edit_campaign.email_template_title") }}</h2>
    </div>

    <div class="content">
      <h2 class="h2-like">Insert Email Template for Organizations</h2>
    </div>
  </div>
</template>

<script>
// import { mapState, mapGetters } from "vuex";
// import axios from "axios";

// import router from "@/router";
// import urls from "@/constants/urls.js";
// import error from "@/components/errors.vue";

export default {
  data() {
    return {};
  },

  components: {},

  methods: {},

  computed: {},

  watch: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.inner-tabs {
  display: flex;
  margin-bottom: 40px;
  flex-direction: column;
  align-items: center;
  @media only screen and (min-width: 768px) {
    padding-left: 25px;
    padding-right: 25px;
    align-items: initial;
    flex-direction: row;
  }
  &__unit {
    width: 100%;
    @media only screen and (min-width: 768px) {
      max-width: 40%;
    }
    padding: 0 25px;
    height: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 19px;
    border: 1px solid $bb-100;
    cursor: pointer;
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
    &:hover {
      background-color: #eaf4ff;
    }
    &.active {
      color: #ffffff;
      background-color: $bb-100;
    }
  }
}

.content {
  border: 1px solid #979797;
  width: 480px;
  height: 239px;
  margin: 0 auto;
  h2 {
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #000000;
    line-height: 239px;
  }
}
</style>
