<template>
  <div class="mb-3">
    <div v-for="(item, i) in items" :key="'itm' + i" class="row mb-1">
      <div class="col-4 text-break">
        {{ `${item.name1} ${item.name2}` }}
      </div>

      <div class="col-4 text-break">
        {{ item.gender && $t(`dashboard.tickets_form_${getFullGender(item.gender)}_label`) }}
      </div>

      <div class="col-2">{{ $t("template.ticket", { count: 1 }, 1) }}</div>

      <div class="col-2 d-flex">
        <button class="bttn bttn-round m-auto my-auto" type="button" @click="removeIteam(i)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    removeIteam(index) {
      this.items.splice(index, 1);
    },

    getFullGender(v) {
      return {
        m: "male",
        f: "female",
      }[v];
    },
  },
};
</script>

<style lang="scss" scoped></style>
