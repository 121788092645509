<template>
  <div>
    <CrowdfundingFundraising>
      <CharidyToGo>
        <slot />
      </CharidyToGo>
    </CrowdfundingFundraising>
  </div>
</template>

<script>
import CrowdfundingFundraising from "@/views/campaigns/campaign-wizards/v2/crowdfundingFundraising.vue";
import CharidyToGo from "@/views/campaigns/campaign-wizards/v2/charidyToGo.vue";

export default {
  components: {
    CrowdfundingFundraising,
    CharidyToGo,
  },
};
</script>
