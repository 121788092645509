<template>
  <div>
    <ChromePicker :value="color" @input="onInput" />
  </div>
</template>

<script>
import ChromePicker from "vue-color/src/components/Chrome.vue";
export default {
  components: {
    ChromePicker,
  },
  props: {
    color: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    onInput(data) {
      this.$emit("input", data.hex);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
::v-deep {
  .vc-chrome {
    box-shadow: none;
  }
  .vc-input__input {
    font-family: $vito-light;
    font-size: inherit;
  }
  .vc-input__label {
    font-family: $vito-light;
    font-size: inherit;
  }
}
</style>
