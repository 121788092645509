<template lang="html">
  <div class="billings-wrapper">
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.billings-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
}
</style>
