<template>
  <div v-if="!loading">
    <category-title :title="$t('users.section_title')" withActions>
      <button class="bttn bttn--sm bttn--orange" @click="addUser">
        {{ $t("users.create_new_user_button") }}
      </button>
    </category-title>

    <div class="user-search">
      <app-search-input placeholder="search" v-model="searchValue" />
    </div>

    <div class="users-section" v-if="subAccList.length > 0">
      <user-card
        v-for="(subaccount, index) in subAccList"
        :key="index"
        :userAcc="subaccount"
        @updateUser="updateUserData(subaccount)"
        @deleteUser="deleteCurrentUser(subaccount)"
      />
    </div>

    <div
      v-else-if="subAccList.length === 0 && searchValue.length === 0"
      class="users-section-empty"
    >
      {{ $t("account.sub_account_no_subaccounts") }}
    </div>

    <div v-else class="users-section-empty">
      {{ $t("account.sub_account_no_subaccounts_by_search") }}
    </div>

    <user-details-modal ref="userDataModal" />
  </div>
  <div class="loading" v-else>
    <app-spinner />
  </div>
</template>

<script>
import { mapState } from "vuex";
import categoryTitle from "@/common-components/ui-elements/category-title.vue";
import appSearchInput from "@/common-components/ui-elements/app-search-input.vue";
import appSpinner from "@/common-components/ui-elements/app-spinner.vue";
import userCard from "./userCard.vue";
import userDetailsModal from "./userDetailsModal.vue";
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],

  components: {
    categoryTitle,
    userCard,
    userDetailsModal,
    appSearchInput,
    appSpinner,
  },

  data() {
    return {
      subAccList: [],
      patchModel: {
        data: {
          attributes: {
            active: "",
          },
        },
      },
      searchValue: "",
    };
  },

  created() {
    if (this.hasAccess) {
      this.$store.dispatch("getSubaccountList");
    }
  },

  computed: mapState({
    loading: (state) => {
      return state.account.loadingAccount;
    },
    subAcc: (state) => {
      return state.account.subAccountList;
    },
    hasAccess: (state) => {
      return state.account.access.CanManageOtherAccountAccess;
    },
  }),

  watch: {
    // Watch sub accounts changes & update sub accounts array
    subAcc: function () {
      this.setSubAccounts();
    },

    searchValue: function () {
      this.setSubAccounts();
    },
  },

  methods: {
    // Set array of sub accounts
    setSubAccounts: function () {
      if (this.searchValue.length > 0) {
        this.subAccList = this.subAcc.filter((el) => {
          return (
            el.attributes.email.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1 ||
            el.attributes.first_name.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1 ||
            el.attributes.last_name.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
          );
        });
      } else {
        this.subAccList = this.subAcc;
      }
    },

    // Add New User
    addUser() {
      this.$refs.userDataModal.addUser();
    },

    // Update existing user
    updateUserData(item) {
      this.$refs.userDataModal.editUser(item);
    },

    // Delete user
    deleteCurrentUser(item) {
      let message = this.$t("account.confirm_delete_sub_account", "User delete confirmation");

      let options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel"),
      };
      this.$dialog.confirm(message, options).then(() => {
        this.$store
          .dispatch("deleteSubAccount", item)
          .then(() => {
            this.$_notificationsMixin_makeToastCompat({
              group: "Notification",
              title: this.$t("users.success_edit"),
            });
          })
          .catch(this.$_notificationsMixin_handleCatch);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-search {
  display: flex;
  justify-content: flex-end;
  ::v-deep .app-search {
    max-width: 290px;
  }
}
</style>
