<template>
  <div v-if="unpublishedActivated && unpublishedCount && show" class="unpublished-error">
    <div class="notice">
      <div class="notice__content">
        <p class="notice__text">
          {{
            $t("dashboard.donation_unpublished_error_msg", {
              count: unpublishedCount,
            })
          }}
        </p>
      </div>
      <button class="ml-auto bttn-bordered bttn-bordered--sm" @click="publishAll">
        {{ $t("dashboard.donation_publish_all") }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      interval: 0,
      show: false,
    };
  },

  computed: {
    ...mapState({
      unpublishedActivated: (state) => state.donation.unpublishedActivated,

      unpublishedCount: (state) => {
        return parseInt(state.donation.unpublishedCount);
      },

      selectedCampaign: (state) => {
        const { campaigns } = state.campaigns;
        const campaign = campaigns.find((el) => el.id === state.dashboard.selectedCampaign);
        return campaign;
      },
    }),
  },

  watch: {
    unpublishedActivated: {
      handler(val) {
        if (val) {
          this.runCheckTime();
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["publishDonations", "getUnpublishedDonations"]),

    runCheckTime() {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        if (!this.unpublishedActivated) {
          clearInterval(this.interval);
          return;
        }
        const end = this.selectedCampaign.attributes.end_date;

        const startTime = new Date();
        const endTime = new Date(end * 1000);
        const difference = endTime - startTime.setHours(startTime.getHours() + 1);
        const resultInSeconds = Math.round(difference / 1000);

        this.show = resultInSeconds <= 3600;
      }, 10000);
    },

    publishAll() {
      this.publishDonations([this.selectedCampaign.id, [], true]).then(() => {
        this.getUnpublishedDonations([this.selectedCampaign.id]);
        this.show = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";
.unpublished-error {
  margin-bottom: 40px;
  padding: 15px;
  background-color: #f6e3e2;
  border: 2px solid darken($color: #f6e3e2, $amount: 10%);
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  a {
    margin-left: auto;
  }
}
.notice {
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 468px) {
    flex-direction: row;
    align-items: center;
  }
  &__warning {
    margin-bottom: 15px;
    width: 63px;
    min-width: 63px;
    height: auto;
    flex-shrink: 0;
    align-self: center;
    @media only screen and (min-width: 468px) {
      margin-right: 30px;
      margin-bottom: initial;
    }
  }
  &__content {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    justify-content: center;
  }
  &__title {
    margin-bottom: 5px;
    font-family: $vito-bold;
    font-size: 16px;
    color: $fd;
    line-height: 1.125;
  }
  &__text {
    margin-bottom: 0;
    font-family: $vito-bold;
    font-size: 14px;
    line-height: 1.125;
    color: $fd;
  }
  .entity-modal {
    margin-left: auto;
  }

  ::v-deep .bttn-bordered {
    border-color: $lr-100;
    color: #ffffff;
    background-color: $lr-100;
    font-family: $vito-bold;
    &--sm {
      min-width: 180px;
    }
  }
}
</style>
