/* eslint-disable no-unused-vars */
import urls from "@/constants/urls";
import axios from "axios";

export const REQUEST_UNDER_REVIEW = "new";
export const REQUEST_DECLINED = "declined";
export const REQUEST_ACCEPTED = "approved";

async function pollUrl(url, desiredStatus, params = {}) {
  let attempts = 0;

  const interval = params.interval || 1000;
  const maxAttempts = params.maxAttempts || 50;
  const waitTimeOn429 = params.waitTimeOn429 || 5000;

  return new Promise((resolve, reject) => {
    const poll = async () => {
      attempts++;
      try {
        const { status, data } = await axios.get(url);
        if (data?.status === desiredStatus) {
          resolve(data);
        } else if (status === 429) {
          if (attempts < maxAttempts) {
            setTimeout(poll, waitTimeOn429);
          } else {
            reject(new Error("Max attempts reached due to 429 status"));
          }
        } else if (maxAttempts === undefined || attempts < maxAttempts) {
          setTimeout(poll, interval);
        } else {
          reject(new Error("Max attempts reached without desired status"));
        }
      } catch (error) {
        console.log(error);
        if (error.response?.status === 429) {
          if (attempts < maxAttempts) {
            setTimeout(poll, waitTimeOn429);
          } else {
            reject(new Error("Max attempts reached due to 429 status"));
          }
          return;
        }

        reject(error);
      }
    };
    poll();
  });
}

const actions = {
  async getStatus() {
    const orgId = localStorage.getItem("orgId");
    const url = urls.foundationRequestStatus(orgId);

    const { data } = await axios.get(url);
    return data;
  },
  async create(_, { data: newRequestData, params } = {}) {
    const orgId = localStorage.getItem("orgId");
    const url = urls.foundationRequest(orgId);

    const newRequest = params?.newRequest || false;
    newRequestData.organization_id = Number(orgId);

    const { data } = await axios.post(url, newRequestData, { params: { newRequest } });
    return data;
  },
  async pandadocCreateDocument(_, documentFields) {
    const orgId = localStorage.getItem("orgId");
    const url = urls.createPandadocDocument(orgId);

    const { data } = await axios.post(url, documentFields);
    return data.document_id;
  },
  async pandadocPollDocumentStatus(_, params = {}) {
    const documentId = params.documentId;
    if (!documentId) {
      throw new Error("Document ID is required");
    }

    const desiredStatus = params.status;
    const orgId = localStorage.getItem("orgId");
    const url = urls.getStatusPandadocDocument(orgId, documentId);

    const data = await pollUrl(url, desiredStatus, { interval: 10_000, waitTimeOn429: 60_000 });
    return data;
  },
  async pandadocSendDocument(_, params = {}) {
    const documentId = params.documentId;
    if (!documentId) {
      throw new Error("Document ID is required");
    }

    const subject = params.subject;
    const message = params.message;
    const orgId = localStorage.getItem("orgId");
    const url = urls.sendPandadocDocument(orgId, documentId);

    await axios.post(url, { subject, message });
  },
  async pandadocCreateSigningSession(_, params = {}) {
    const documentId = params.documentId;
    if (!documentId) {
      throw new Error("Document ID is required");
    }

    const recipient = params.recipient;
    const orgId = localStorage.getItem("orgId");
    const url = urls.createPandadocSigningSession(orgId, documentId);

    const { data } = await axios.post(url, { recipient });
    return data.id;
  },
  async pandadocDownloadDocument({ commit, dispatch }, params) {
    const documentId = params.documentId;
    if (!documentId) {
      throw new Error("Document ID is required");
    }

    const orgId = localStorage.getItem("orgId");
    const url = urls.downloadPandadocDocument(orgId, documentId);

    const { data } = await axios.get(url);
    return data.link;
  },
};

export default {
  namespaced: true,
  actions,
};
