<template>
  <div class="user-card">
    <button class="user-card__delete" @click="deleteUser"></button>

    <div class="user-card__avatar" v-if="userAcc.attributes.avatar" :style="avatar"></div>
    <div class="user-card__avatar" v-else>
      <icon-generic-user></icon-generic-user>
    </div>

    <h3 class="user-card__name">{{ userAcc.attributes.email }}</h3>

    <div class="user-card__role">{{ userRoleSet }}</div>

    <div class="user-card__action">
      <div class="subaccount-row__status">
        <div>
          <input
            type="checkbox"
            v-model="userAcc.attributes.active"
            @change="updateUserAccess($event, userAcc.id)"
            :id="'checkbox' + userAcc.id"
          />
          <label :for="'checkbox' + userAcc.id"></label>
        </div>
      </div>

      <button class="user-card__edit" @click="editUserProfile">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
        <span>
          {{ this.$t("dashboard.edit_btn") }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import urls from "@/constants/urls.js";
import iconGenericUser from "@/components/icon-genericUser.vue";
import axios from "axios";

export default {
  props: ["userAcc"],

  components: {
    iconGenericUser,
  },

  data() {
    return {
      patchModel: {
        data: {
          attributes: {
            active: "",
          },
        },
      },
    };
  },

  computed: {
    // temporary value
    userRoleSet() {
      let role = this.userAcc.attributes.access;
      switch (role) {
        case "full":
          return this.$t("users.role_name_full");
        case "operator":
          return this.$t("users.role_name_operator");
        case "team_agent":
          return this.$t("users.role_name_team_agent", "Access: Team agent");
        case "restricted":
          return this.$t("users.role_name_restricted", "Access: Restricted");
        case "":
          return this.$t("users.role_name_na");
        default:
          return this.$t("users.role_name_na");
      }
    },

    avatar() {
      return `background-image: url(${this.userAcc.attributes.avatar})`;
    },
  },

  methods: {
    editUserProfile() {
      this.$emit("updateUser");
    },

    deleteUser() {
      this.$emit("deleteUser");
    },

    updateUserAccess(event, id) {
      let orgId = localStorage.getItem("orgId");
      this.patchModel.data.attributes = {
        active: event.target.checked,
      };
      axios
        .patch(
          urls.patchSubAccount.replace(":orgId", orgId).replace(":sucAccId", id),
          this.patchModel
        )
        .then((res) => {
          if (res.status === 200) {
            this.$_notificationsMixin_makeToastCompat({
              group: "Notification",
              title: this.$t("users.success_edit"),
            });
          }
          this.$store.dispatch("getSubaccountList");
        })
        .catch((e) => {
          console.log(e);
          this.$refs.error.setErrorResponse(e.response);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-card__avatar {
  align-items: center;
  justify-content: center;
  display: flex;
  ::v-deep svg {
    width: 128px;
    height: 128px;
  }
}

input {
  display: block;
  opacity: 0;
}
label {
  width: 60px;
  height: 30px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  background: rgb(189, 189, 189);
  border-radius: 30px;

  transition: background-color 0.4s;
  -moz-transition: background-color 0.4s;
  -webkit-transition: background-color 0.4s;
}
label:after {
  left: 0;
  width: 20px;
  height: 20px;
  margin: 5px;
  content: "";
  position: absolute;
  background: #fff;
  border-radius: 10px;
}
input:checked + label {
  background: #3cc7a0;
}
input:checked + label:after {
  left: auto;
  right: 0;
}
</style>
