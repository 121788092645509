<template>
  <div class="wizard">
    <div class="main">
      <div class="taxgift-intro">
        <p>
          <b>{{ $t("integration.donorfuse_title", "DonorFuse") }}</b>
        </p>
      </div>
      <b-form-group
        id="reports-fieldset-select-entity"
        label="API Key:"
        label-for="reports-input-select-entity"
      >
        <b-form-input
          id="reports-input-select-entity"
          v-model="key"
          type="text"
          placeholder="Please enter your API Key"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="reports-fieldset-select-entity"
        label="DonorFuse Campaign Name:"
        label-for="reports-input-select-entity"
      >
        <b-form-input
          id="reports-input-select-entity"
          v-model="campaignName"
          type="text"
          placeholder="Please enter DonorFuse Campaign Name"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group id="reports-fieldset-select-entity">
        <b-form-checkbox v-model="sandbox" name="check-button" switch> Sandbox </b-form-checkbox>
      </b-form-group>
    </div>
    <div class="footer">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="closeModal">
        {{ $t("integration.close") }}
      </button>
      <button
        class="bttn bttn--lg bttn--orange el-right"
        :disabled="!key"
        @click.prevent="addIntegration"
      >
        {{ $t("integration.add_taxgift", "Add Integration") }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls.js";
import { mapState } from "vuex";

export default {
  data() {
    return {
      key: "",
      campaignName: "",
      sandbox: false,
    };
  },

  computed: {
    ...mapState({
      orgId: () => {
        return localStorage.getItem("orgId");
      },
    }),
  },

  methods: {
    closeModal() {
      this.$store.dispatch("getIntegrationsList");
      this.$emit("close-modal");
    },
    addIntegration() {
      const payload = {
        key: this.key,
        status: true,
        sandbox: this.sandbox,
        campaign_name: this.campaignName,
      };
      // Please fill in API Call here
      axios
        .post(urls.addIntegration(this.orgId, "donorfuse"), payload)
        .then(() => {
          this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
          this.$emit("close-modal");
          this.$store.dispatch("getIntegrationsList");
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: space-between;
}
.el-right {
  margin-left: auto;
}
</style>
