<template>
  <div>
    <!--<b-btn-->
    <!--class="entities-add" v-b-modal.cprofileModal>{{ $t('account.contacts_button') }}</b-btn>-->
    <button
      type="button"
      :disabled="!hasAccess"
      class="bttn-bordered bttn-bordered--sm"
      v-b-modal.cprofileModal
    >
      + {{ $t("account.contacts_button") }}
    </button>

    <!-- Modal Component -->
    <b-modal id="cprofileModal" size="lg" @hidden="clearData">
      <div slot="modal-header">
        <h2>{{ $t("account.contacts_modal_header") }}</h2>
        <!-- <div class="modal-header__subtitle">(as it will appear on the donors receipt)</div> -->
      </div>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
          {{ $t("account.cancel") }}
        </button>
        <button form="cprofile-add" class="bttn bttn--lg bttn--orange">
          {{ $t("account.save") }}
        </button>
        <!--@click.prevent="addContact" -->
      </div>

      <form id="cprofile-add" @submit.prevent="addContact" class="entities-data">
        <error v-if="errors" :errors="errors"></error>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label
                for="cprofile-name"
                class="entities-data__label entities-data__label--required"
              >
                {{ $t("account.contacts_first_name") }}
              </label>
              <b-form-input
                id="cprofile-name"
                required
                v-model="cprofile.data.attributes.first_name"
              ></b-form-input>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group">
              <label
                for="cprofile-last_name"
                class="entities-data__label entities-data__label--required"
              >
                {{ $t("account.contacts_last_name") }}
              </label>
              <b-form-input
                id="cprofile-last_name"
                required
                v-model="cprofile.data.attributes.last_name"
              ></b-form-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label
                for="cprofile-type"
                class="entities-data__label entities-data__label--required"
              >
                {{ $t("account.contacts_type") }}
              </label>
              <b-form-select id="cprofile-type" required v-model="cprofile.data.attributes.type">
                <option
                  v-for="type in cprofileTypes"
                  :key="type.value"
                  required
                  :value="type.value"
                >
                  {{ $t("account.contacts_type_" + type.value, type.type) }}
                </option>
              </b-form-select>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group">
              <label
                for="cprofile-phone"
                class="entities-data__label entities-data__label--required"
              >
                {{ $t("account.contacts_phone") }}
              </label>
              <b-form-input
                id="cprofile-phone"
                :required="cprofile.data.attributes.email.length == 0"
                v-model="cprofile.data.attributes.phone"
              ></b-form-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group" :class="{ 'has-error': vErrors.has('contactEmail') }">
              <label
                for="cprofile-email"
                class="entities-data__label entities-data__label--required"
              >
                {{ $t("account.contacts_email") }}
              </label>
              <!-- :required='cprofile.data.attributes.phone.length == 0'-->
              <b-form-input
                id="cprofile-email"
                v-model="cprofile.data.attributes.email"
                name="contactEmail"
                v-validate="'required|email'"
              />
              <span v-show="vErrors.has('contactEmail')" class="v-help is-danger">{{
                $t("template.field_is_required")
              }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="cprofile-note" class="entities-data__label">
                {{ $t("account.contacts_note") }}
              </label>
              <b-form-textarea
                id="cprofile-note"
                v-model="cprofile.data.attributes.note"
                :placeholder="$t('account.contacts_note_placeholder')"
                :rows="3"
              ></b-form-textarea>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import error from "@/components/errors.vue";
import { notificationsMixin } from "@/mixins";

export default {
  data() {
    return {
      cprofile: {
        data: {
          attributes: {
            first_name: "",
            last_name: "",
            type: "main",
            phone: "",
            email: "",
            note: "",
          },
        },
      },

      cprofileTypes: [],

      loadind: false,
      errors: [],
      edit: false,
    };
  },

  mixins: [notificationsMixin],

  components: {
    error: error,
  },

  computed: mapState({
    hasAccess: (state) => {
      return state.account.access.CanEditOrganizationDetail;
    },
  }),

  methods: {
    getContactsType: function () {
      let orgId = localStorage.getItem("orgId");
      axios
        .get(urls.contactsGetTypeList.replace(":orgId", orgId))

        .then((response) => {
          let apiResult = response.data.data;
          let resultArr = [];

          for (var i = 0; i < apiResult.length; i++) {
            resultArr.push(apiResult[i].attributes);
          }
          this.cprofileTypes = resultArr;
        })

        .catch((e) => {
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },

    editContacts(item) {
      this.edit = true;

      this.cprofile.data = item;

      this.$root.$emit("bv::show::modal", "cprofileModal");
    },

    clearData: function () {
      this.edit = false;

      this.errors = [];

      this.cprofile = {
        data: {
          attributes: {
            name: "",
            type: "main",
            phone: "",
            email: "",
            note: "",
          },
        },
      };
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "cprofileModal");
    },

    addContact() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.edit) {
            this.editContactApi();
          } else {
            this.addContactApi();
          }
        }
      });
    },

    editContactApi() {
      this.errors = [];
      let orgId = localStorage.getItem("orgId");
      this.loadind = true;
      axios
        .put(`${urls.putContact.replace(":orgId", orgId)}/${this.cprofile.data.id}`, this.cprofile)
        .then(() => {
          this.cprofile = {
            data: {
              attributes: {
                name: "",
                type: "main",
                phone: "",
                email: "",
                note: "",
              },
            },
          };

          this.loadind = false;

          this.$store.dispatch("getContacts");

          this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));

          this.edit = false;

          this.$root.$emit("bv::hide::modal", "cprofileModal");
        })
        .catch((e) => {
          this.loadind = false;
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },

    addContactApi: function () {
      let orgId = localStorage.getItem("orgId");
      axios
        .post(urls.postGetContacts.replace(":orgId", orgId), this.cprofile)
        .then(() => {
          this.cprofile = {
            data: {
              attributes: {
                name: "",
                type: "main",
                phone: "",
                email: "",
                note: "",
              },
            },
          };

          this.loadind = false;

          this.$store.dispatch("getContacts");

          this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));

          this.edit = false;

          this.$root.$emit("bv::hide::modal", "cprofileModal");
        })
        .catch((e) => {
          this.loadind = false;
          if (e.response.data) {
            if (e.response.data.error) {
              this.errors.push(e.response.data.error);
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },
  },

  created: function () {
    if (this.hasAccess) {
      this.getContactsType();
    }
  },
};
</script>

<style lang="scss" scoped>
.entities-data {
  &__labels {
    color: gray;
    text-transform: capitalize;
  }
  &__label {
    color: gray;
    text-transform: capitalize;
    line-height: 1;
    &--required {
      position: relative;
      &:after {
        content: "*";
        font-size: 13px;
        line-height: 1;
        color: red;
        display: inline;
        position: relative;
        top: -2px;
      }
    }
  }
}
.entities-add {
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0 15px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  color: #20434e;
  background-color: #ffffff;
}
.btn-oval {
  width: auto;
  height: 45px;
  padding: 0 23px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 23px;
  border: none;
  font-family: "ProximaNova-Bold", sans-serif;
  font-size: 16px;
  color: #ffffff;
  background-color: #cc6d4c;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover,
  &:active {
    background-color: darken(#cc6d4c, 5%);
    box-shadow: none;
    outline: 0;
  }
  &:focus {
    background-color: #cc6d4c;
    box-shadow: none;
    outline: 0;
  }
  &--cancel {
    background-color: gray;
    &:hover,
    &:active {
      background-color: darken(gray, 5%);
    }
    &:focus {
      background-color: gray;
    }
  }
}
::v-deep.form-group {
  position: relative;
}
::v-deep .v-help {
  position: absolute;
  top: 5px;
  right: 0;
}

.is-danger {
  color: #f26e66;
}
.has-error {
  .form-control {
    border-color: #f26e66;
  }
}
</style>
