<template>
  <ValidationObserver ref="observer" tag="div" mode="passive" class="row mt-2">
    <div class="col-12 col-sm-6">
      <div class="form-group">
        <label class="entities-data__label">
          {{ $t("dashboard.tickets_form_full_name_label") }}
        </label>
        <ValidationProvider name=" " rules="required" v-slot="{ errors }">
          <b-form-input v-model="form.name1" :class="{ 'has-error': errors.length }">
          </b-form-input>
          <div
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            class="invalid-feedback d-block is-danger"
          >
            {{ errors[0] }}
          </div>
        </ValidationProvider>
      </div>
    </div>

    <div class="col-12 col-sm-6">
      <div class="form-group">
        <label class="entities-data__label">
          {{ $t("dashboard.tickets_form_surname_label") }}
        </label>
        <ValidationProvider name=" " rules="required" v-slot="{ errors }">
          <b-form-input v-model="form.name2" :class="{ 'has-error': errors.length }">
          </b-form-input>
          <div
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            class="invalid-feedback d-block is-danger"
          >
            {{ errors[0] }}
          </div>
        </ValidationProvider>
      </div>
    </div>

    <div class="col-12 text-center">
      <div class="form-group">
        <ValidationProvider name=" " rules="required" v-slot="{ errors }">
          <b-form-radio-group>
            <b-form-radio v-model="form.gender" value="m">
              {{ $t("dashboard.tickets_form_male_label", "Male") }}
            </b-form-radio>
            <b-form-radio v-model="form.gender" value="f">
              {{ $t("dashboard.tickets_form_female_label", "Female") }}
            </b-form-radio>
          </b-form-radio-group>
          <div
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            class="invalid-feedback d-block is-danger"
          >
            {{ errors[0] }}
          </div>
        </ValidationProvider>
      </div>
    </div>

    <div class="col-12 text-center">
      <button class="bttn bttn--lg bttn--blue" @click.prevent="addItem">
        {{ $t("dashboard.tickets_form_add_tiket") }}
      </button>
    </div>

    <div v-if="showAmountField" class="w-100 mt-3">
      <div class="m-auto col-12 col-sm-6">
        <div class="form-group">
          <label class="entities-data__label">
            {{ $t("dashboard.tickets_form_amount_label") }}
          </label>
          <ValidationProvider name=" " rules="required" v-slot="{ errors }">
            <b-form-input v-model="form.amount" type="tel" :class="{ 'has-error': errors.length }">
            </b-form-input>
            <div
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
              class="invalid-feedback d-block is-danger"
            >
              {{ errors[0] }}
            </div>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      form: {
        ticket_type: "ticket_free_price",
        name1: "",
        name2: "",
        amount: "",
        gender: "",
      },
    };
  },

  computed: {
    ...mapState({
      config: (state) => state.ticket.config,
    }),

    showAmountField() {
      return this.config.list?.ticket_free_price?.amount <= 0;
    },
  },

  watch: {
    showAmountField: {
      handler(val) {
        if (!val) {
          this.form.amount = this.config.list?.ticket_free_price?.amount || 0;
        }
      },
      immediate: true,
    },
  },

  methods: {
    addItem() {
      this.$refs.observer.validate().then((isValid) => {
        if (isValid) {
          this.form.amount = Number(this.form.amount || 0);
          this.$emit("add", JSON.parse(JSON.stringify(this.form)));
          this.form.name1 = "";
          this.form.name2 = "";
          this.$refs.observer.reset();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
