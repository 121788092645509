<template>
  <div class="country-list-block">
    <p>
      {{ $t("gateways.stripe_available_in") }}
    </p>
    <ul class="country-list">
      <li class="open" v-for="code in $options.stripeSupportedTransferCountries" :key="code">
        <span class="shadow-sm" :class="flagIconClass(getCountry(code))"></span>
        <span>{{ getCountry(code).name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { stripeSupportedTransferCountries, countryList } from "@/constants/countries";

export default {
  stripeSupportedTransferCountries,

  methods: {
    getCountry(isoCode) {
      return countryList.find(({ iso_code }) => iso_code === isoCode) || {};
    },
    flagIconClass(lang) {
      if (lang.icon_name) {
        return ["flag-icon", `${lang.icon_name}`];
      }
      return ["flag-icon", `flag-icon-${lang.iso_code?.toLowerCase()}`];
    },
  },
};
</script>

<style lang="scss" scoped>
.country-list {
  display: flex;
  flex-wrap: wrap;
}
.open {
  flex: 1 0 25%;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-flow: row;
  place-items: center;
  gap: 1rem;
}
</style>
