<template>
  <div class="import-manual__row">
    <a class="import-manual__delete" role="button" @click.prevent="removeRow">
      {{ $t("dashboard.add_donate_delete_row") }}
    </a>

    <div class="import-manual__mandatory">
      <div class="import-manual-top-line">
        <div class="form-group required">
          <label class="import-manual__label">
            {{ $t("dashboard.add_donate_display_name") }}
          </label>
          <b-form-input
            :class="
              item.validation && item.data.display_name.length == 0
                ? 'import-manual__input invalid'
                : 'import-manual__input'
            "
            :readonly="issaving || item.success"
            v-model="item.data.display_name"
          ></b-form-input>
        </div>

        <div class="form-group form-group--charged required">
          <label class="import-manual__label">
            {{ $t("dashboard.add_donate_charged_amount") }}
          </label>
          <b-form-input
            :class="
              item.validation && item.data.charged_amount.length == 0
                ? 'import-manual__input invalid'
                : 'import-manual__input'
            "
            :readonly="issaving || item.success"
            type="number"
            v-model.number="item.data.charged_amount"
          ></b-form-input>
        </div>

        <div class="form-group required">
          <label class="import-manual__label">
            {{ $t("dashboard.add_donate_email") }}
          </label>
          <b-form-input
            :class="
              item.validation && item.data.email.length == 0
                ? 'import-manual__input invalid'
                : 'import-manual__input'
            "
            :readonly="issaving || item.success"
            v-model="item.data.email"
          ></b-form-input>
        </div>

        <div class="form-group">
          <label class="import-manual__label">
            {{ $t("dashboard.add_donate_dedication") }}
          </label>
          <b-form-input
            class="import-manual__input"
            :readonly="issaving || item.success"
            v-model="item.data.dedication"
          ></b-form-input>
        </div>

        <div class="form-group form-group--select" v-if="teamList.length > 0">
          <label class="import-manual__label">
            {{ $t("dashboard.add_donate_team") }}
          </label>
          <multiselect
            :disabled="issaving || item.success"
            v-model="multiselectTeam"
            :options="teamList"
            label="text"
            track-by="text"
            :multiple="false"
          >
          </multiselect>
        </div>
      </div>

      <div v-if="issaving" class="import-manual__loader">
        <img src="https://editionsdelarose.com/wp-content/themes/edr/img/loading.gif" />
      </div>
      <div v-if="item.success">
        <span>{{ $t("dashboard.add_donate_success") }}</span>
      </div>

      <button
        v-if="!item.success && !issaving"
        type="button"
        class="btn btn-secondary"
        @click="addOneDonation"
        name="button"
      >
        {{ $t("dashboard.add_donate_mass_one") }}
      </button>
    </div>

    <div class="form-group import-manual__checkbox">
      <input type="checkbox" :id="'advanced-constants' + item.id" v-model="showAdvanced" />
      <label :for="'advanced-constants' + item.id">
        {{ $t("dashboard.add_donate_advanced") }}
      </label>
    </div>

    <div v-if="showAdvanced" class="import-manual__subrow">
      <div class="form-group">
        <label class="import-manual__label">
          {{ $t("donors.offline_donation_source") }}
        </label>
        <b-form-select
          class="import-manual__input"
          :readonly="issaving || item.success"
          v-model="item.data.offline_donation_source"
        >
          <option value="cash">{{ $t("donors.cash") }}</option>
          <option value="check">{{ $t("donors.check") }}</option>
          <option value="bank_transfer">{{ $t("donors.bank_transfer") }}</option>
          <option value="recurring_credit_card">{{ $t("donors.recurring_credit_card") }}</option>
          <option value="recurring_bank_transfer">
            {{ $t("donors.recurring_bank_transfer") }}
          </option>
        </b-form-select>
      </div>

      <div class="form-group">
        <label class="import-manual__label">
          {{ $t("donors.offline_donation_note") }}
        </label>
        <b-form-input
          class="import-manual__input"
          :readonly="issaving || item.success"
          v-model="item.data.offline_donation_note"
        >
        </b-form-input>
      </div>

      <div class="form-group">
        <label class="import-manual__label">
          {{ $t("dashboard.add_donate_billing_name") }}
        </label>
        <b-form-input
          class="import-manual__input"
          :readonly="issaving || item.success"
          v-model="item.data.billing_name"
        ></b-form-input>
      </div>

      <div class="form-group">
        <label class="import-manual__label">
          {{ $t("dashboard.add_donate_billing_last_name") }}
        </label>
        <b-form-input
          class="import-manual__input"
          :readonly="issaving || item.success"
          v-model="item.data.billing_last_name"
        ></b-form-input>
      </div>

      <div class="form-group">
        <label class="import-manual__label">
          {{ $t("dashboard.add_donate_phone") }}
        </label>
        <b-form-input
          class="import-manual__input"
          :readonly="issaving || item.success"
          v-model="item.data.phone"
        ></b-form-input>
      </div>

      <!--<div class="form-group">-->
      <!--<label-->
      <!--class="import-manual__label">-->
      <!--{{ $t('dashboard.add_donate_country') }}-->
      <!--</label>-->
      <!--<b-form-input-->
      <!--class="import-manual__input" :readonly='issaving || item.success' v-model='item.data.payment_country'></b-form-input>-->
      <!--</div>-->

      <div class="form-group">
        <label class="import-manual__label">
          {{ $t("dashboard.add_donate_country") }}
        </label>
        <b-form-select :readonly="issaving || item.success" v-model="item.data.payment_country">
          <option v-for="country in countryList" :value="country.attributes.name">
            {{ country.attributes.name }}
          </option>
        </b-form-select>
      </div>

      <div class="form-group">
        <label class="import-manual__label">
          {{ $t("dashboard.edit_donate_category") }}
        </label>

        <!--<b-form-input-->
        <!--id="add-donation-category"-->
        <!--v-model="editDonation.data.attributes.category"></b-form-input>-->

        <b-form-input
          class="import-manual__input"
          :readonly="issaving || item.success"
          v-model="item.data.category"
        ></b-form-input>
      </div>

      <div class="form-group">
        <label class="import-manual__label">
          {{ $t("dashboard.add_donate_city") }}
        </label>
        <b-form-input
          class="import-manual__input"
          :readonly="issaving || item.success"
          v-model="item.data.payment_city"
        ></b-form-input>
      </div>

      <div class="form-group">
        <label class="import-manual__label">
          {{ $t("dashboard.add_donate_address") }}
        </label>
        <b-form-input
          class="import-manual__input"
          :readonly="issaving || item.success"
          v-model="item.data.payment_address"
        ></b-form-input>
      </div>

      <div class="form-group">
        <label class="import-manual__label">
          {{ $t("dashboard.add_donate_address_2") }}
        </label>
        <b-form-input
          class="import-manual__input"
          :readonly="issaving || item.success"
          v-model="item.data.payment_address_2"
        ></b-form-input>
      </div>

      <div class="form-group">
        <label class="import-manual__label">
          {{ $t("dashboard.add_donate_postcode") }}
        </label>
        <b-form-input
          class="import-manual__input"
          :readonly="issaving || item.success"
          v-model="item.data.payment_postcode"
        ></b-form-input>
      </div>

      <div class="form-group">
        <label class="import-manual__label">
          {{ $t("dashboard.add_donate_state_area") }}
        </label>
        <b-form-input
          class="import-manual__input"
          :readonly="issaving || item.success"
          v-model="item.data.payment_state"
        ></b-form-input>
      </div>

      <div class="form-group">
        <label class="import-manual__label">
          {{ $t("dashboard.add_donate_referer") }}
        </label>
        <b-form-input
          class="import-manual__input"
          :readonly="issaving || item.success"
          v-model="item.data.referrer"
        ></b-form-input>
      </div>

      <div class="form-group form-checkbox form-checkbox--orange align-self-end">
        <input
          class="form-checkbox__input"
          type="checkbox"
          :id="item.id"
          v-model="item.data.send_receipt"
        />
        <label class="form-checkbox__label" :for="item.id">
          {{ $t("dashboard.send_receipt") }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Multiselect from "vue-multiselect";
export default {
  props: ["item", "issaving"],

  data() {
    return {
      showAdvanced: false,
      selected: null,
      multiselectTeam: null,
    };
  },
  components: {
    Multiselect,
  },

  watch: {
    multiselectTeam: function () {
      if (this.multiselectTeam) {
        this.item.data.team_id = parseInt(this.multiselectTeam.value);
      } else {
        this.item.data.team_id = 0;
      }
    },
  },

  computed: mapState({
    teamList: (state) => {
      let teamsList = state.teams.teams;

      let teamForMultiselect = [];

      for (var i = 0; i < teamsList.length; i++) {
        let obj = {
          value: teamsList[i].id,
          text: teamsList[i].attributes.name,
        };

        teamForMultiselect.push(obj);
      }

      return teamForMultiselect;
    },

    countryList: (state) => {
      return state.account.countries;
    },
  }),

  methods: {
    removeRow() {
      this.$emit("remove-row", this.item.id);
    },

    addOneDonation: function () {
      this.$emit("add-row", this.item.id);
    },
  },
};
</script>

<style lang="scss">
#importManual .import-manual-top-line {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  .required {
    label {
      position: relative;
      &:after {
        content: "*";
        position: absolute;
        top: -2px;
        right: -5px;
        color: red;
        font-size: 12px;
      }
    }
  }
}

#importManual {
  .multiselect {
    min-height: 50px;

    &__tags {
      padding: 7px 40px 0px 8px;
      min-height: 50px;
      max-height: 50px;
      border: 1px solid rgba(32, 67, 78, 0.15);
      transition: all 0.5s;
      &:hover,
      &:focus {
        border-color: #0084af;
      }
    }
    &__select {
      top: 0;
      padding: 0;
      height: 50px;
      &:before {
        top: 61%;
      }
    }
    &__single {
      margin-bottom: 0;
      height: 34px;
      line-height: 34px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__input {
      height: 34px;
    }
  }
}

.form-group_country {
  select {
    width: 100%;
  }
}

.import-manual__mandatory {
  input.invalid {
    border-color: red;
  }
}
</style>
