<template>
  <div>
    <b-table :items="fundTransferStatus" :fields="fields" :busy="loading">
      <template v-slot:head(account)>
        <span>{{ $t("dashboard.fts_account", "Account") }}</span>
      </template>
      <template v-slot:cell(account)="{ item }">
        <span>{{ item.account }}</span>
        <span v-if="item.skipped" style="color: grey; font-size: 10px">
          ({{ item.skipped }} skipped, since happend out of the campaign Date,
          <a href="#" @click="showAll">show all</a>)
        </span>
      </template>
      <template v-slot:head(created)>
        <span>{{ $t("dashboard.fts_created", "Date") }}</span>
      </template>
      <template v-slot:cell(created)="{ item: row }">
        <span v-if="row.created"
          >{{ new Date(row.created * 1000).getUTCMonth() + 1 }}/{{
            new Date(row.created * 1000).getUTCDate()
          }}/{{ new Date(row.created * 1000).getUTCFullYear() }}</span
        >
      </template>
      <template v-slot:head(bank_account)>
        <span>{{ $t("dashboard.fts_bank_account", "Account (last 4)") }}</span>
      </template>
      <template v-slot:cell(bank_account)="{ item: row }">
        <span v-if="row.bank_account">{{ row.bank_account.last4 }}</span>
      </template>
      <template v-slot:head(amount)>
        <span>{{ $t("dashboard.fts_amount", "Amount") }}</span>
      </template>
      <template v-slot:cell(amount)="{ item: row }">
        <span v-if="row.amount">{{ row.amount }}</span>
      </template>
      <template v-slot:head(status)>
        <span>{{ $t("dashboard.fts_status", "Status") }}</span>
      </template>
      <template v-slot:head(automatic)>
        <span>{{ $t("dashboard.fts_automatic", "Automatic") }}</span>
      </template>
      <template v-slot:cell(automatic)="{ item: row }">
        <span v-if="row.automatic">yes</span> <span v-else>manual</span>
      </template>
      <template v-slot:head(arrival_date)>
        <span>{{ $t("dashboard.fts_arrival_date", "Arrival date") }}</span>
      </template>
      <template v-slot:cell(arrival_date)="{ item: row }">
        <span v-if="row.arrival_date">{{ getArrivalDate(row.arrival_date) }}</span>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { notificationsMixin } from "@/mixins";

export default {
  mixins: [notificationsMixin],
  data() {
    return {
      fields: [
        "account",
        "created",
        "bank_account",
        "amount",
        "status",
        "automatic",
        "arrival_date",
      ],
    };
  },
  computed: {
    ...mapState({
      fundTransferStatus: (state) => state.fundTransferStatus.fundTransferStatus,
      loading: (state) => state.fundTransferStatus.loading.fundTransferStatus,
      cidInDashboard: (state) => state.dashboard.selectedCampaign,
    }),
    cid() {
      return this.$route.params.id ? this.$route.params.id : this.cidInDashboard;
    },
  },
  methods: {
    ...mapActions({
      fetchFundTransferStatus: "fundTransferStatus/fetchFundTransferStatus",
    }),
    showAll() {
      this.fetchFundTransferStatus([this.cid, 1]).catch(this.$_notificationsMixin_handleCatch);
    },
    getArrivalDate(arrivalDate) {
      const month = new Date(arrivalDate * 1000).getUTCMonth() + 1;
      const date = new Date(arrivalDate * 1000).getUTCDate();
      const year = new Date(arrivalDate * 1000).getUTCFullYear();
      return `${month}/${date}/${year}`;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .table {
  margin-bottom: 0px;
}
::v-deep .table thead,
::v-deep .table thead tr,
::v-deep .table thead th,
::v-deep .table tbody[role="rowgroup"] td {
  padding: 20px 40px;
}
::v-deep .table thead th {
  border-top: none;
  border-bottom: none;
  span {
    font-weight: 400;
  }
}
::v-deep .table thead[role="rowgroup"] {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
::v-deep .table tbody[role="rowgroup"] td {
  border-top: 1px solid #e6eaed;
  border-bottom: 1px solid #e6eaed;
  border-radius: 4px;
  background-color: #ffffff;
  &:first-of-type {
    border-left: 1px solid #e6eaed;
  }
  &:last-of-type {
    border-right: 1px solid #e6eaed;
  }
}
</style>
