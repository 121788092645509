<template>
  <span v-b-tooltip.hover.html="tipData">
    <slot></slot>
    <slot name="icon">
      <component :is="`b-icon-${icon}`" :variant="variant" />
    </slot>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "question-circle-fill",
    },
    variant: {
      type: String,
      default: "info",
    },
    opacity: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    tipData() {
      return { title: this.title, html: true, customClass: this.customClass };
    },
    customClass() {
      const cls = ["custom-b-tooltip"];
      if (this.opacity) {
        cls.push("custom-b-tooltip--default-opacity");
      } else {
        cls.push("custom-b-tooltip--no-opacity");
      }
      return cls;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-b-tooltip {
  opacity: var(--bs-tooltip-opacity) !important;

  &--default-opacity {
    --bs-tooltip-opacity: 0.9;
  }
  &--no-opacity {
    --bs-tooltip-opacity: 1;
  }
}
</style>
