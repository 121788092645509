<template>
  <div>
    <slot
      name="table"
      :loading="loading"
      :donors="donors"
      :internalCheckedDonors="internalCheckedDonors"
      :fields="fields"
    ></slot>
    <slot
      v-if="donors.length"
      name="pagination"
      :page="page"
      :updatePage="(v) => (page = v)"
      :total="total"
      :perPage="perPage"
    ></slot>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import { mapActions } from "vuex";
import { getFormatedAmountString } from "@/constants/currencies";

export default {
  props: {
    teamId: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      donors: [],
      checkedDonors: [],
      errors: [],
      loading: false,
      page: 1,
      perPage: 10,
      total: 0,
      fields: [
        { key: "id", name: "ID", thClass: "border-right border-left-0" },
        {
          key: "billing_name",
          label: "Name",
          formatter(value, key, item) {
            return `${value} ${item.billing_last_name}`;
          },
          thClass: "border-right",
        },
        {
          key: "display_name",
          label: "Display Name",
          thClass: "border-right",
        },
        {
          key: "dedication",
          label: "Dedication",
          thClass: "border-right",
        },
        {
          key: "effective_amount",
          label: "Amount",
          formatter(value, key, item) {
            return getFormatedAmountString(value, item.__campaignCurrency);
          },
          thClass: "border-right",
        },
        {
          key: "date",
          label: "Donation Date",
          formatter(value) {
            if (!value) {
              return "None";
            }
            return DateTime.fromISO(value).toLocaleString();
          },
          thClass: "border-right",
        },
        {
          key: "actions",
          label: "",
          thClass: "border-right-0",
        },
      ],
    };
  },
  mounted() {
    this.getDonors();
  },
  watch: {
    page() {
      this.getDonors();
    },
  },
  computed: {
    internalCheckedDonors() {
      const self = this;
      return {
        get() {
          return self.checkedDonors;
        },
        update(v) {
          self.checkedDonors = v;
          self.$emit("update:checkedDonors", v);
        },
      };
    },
  },
  methods: {
    ...mapActions({
      getDonorTeamDonors: "donorTeams/getDonorTeamDonors",
    }),
    getDonors() {
      this.loading = true;
      const id = this.teamId;
      this.getDonorTeamDonors({
        id,
        params: {
          page: this.page,
          perPage: this.perPage,
        },
      })
        .then(({ data, total }) => {
          this.total = total;
          this.donors = data.map((d) => ({
            ...d,
            __campaignCurrency: this.currency,
          }));
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
