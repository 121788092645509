<template>
  <div class="vendor-dashboard">
    <vendorHeader />
    <vendorSidebar />

    <div class="wrapper-content">
      <!-- v-if='!loadingAccess' -->

      <transition name="fade">
        <router-view></router-view>
      </transition>
    </div>
    <!-- <main-footer></main-footer> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vendorHeader from "./header/mobile-header";
import vendorSidebar from "./sidebar/sidebar";

export default {
  components: {
    vendorHeader,
    vendorSidebar,
  },
  data() {
    return {
      windowWidth: null,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  watch: {
    windowWidth(newVal) {
      if (newVal > 1360) {
        this.closeSidebar();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleMenu: "vendorMain/vendorSidebarToggle",
    }),

    closeSidebar() {
      this.toggleMenu(false);
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-content {
  padding: 120px 15px;
  min-height: 100vh;
  background: url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco1.svg")
      top center no-repeat,
    url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco2.svg")
      top 0px right -10px no-repeat,
    url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco3.svg")
      bottom -100px right 0 no-repeat,
    url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco4.svg")
      bottom 100px left 0 no-repeat;

  @media only screen and (min-width: 1360px) {
    padding: 60px 70px 60px 70px;
    margin-left: 300px;
  }
}
</style>
