<template lang="html">
  <div class="item">
    <div
      :style="article.image ? 'background-image: url(' + article.image + ')' : ''"
      class="item-image"
    ></div>
    <div class="item-body">
      <h2>{{ article.title }}</h2>
      <div class="time-creator">
        <p>by {{ article.creator }}</p>
        <p>
          {{ article.date }}
        </p>
      </div>
      <p class="lead">
        {{ article.lead }}
      </p>
      <a :href="article.link" target="_blank" class="button">Read more</a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["article"],
};
</script>

<style lang="scss" scoped>
@import "../../styles/_vars.scss";

.item {
  width: 100%;
  margin: 0 15px 20px 15px;
  overflow: hidden;
  color: $fd;
  border-radius: 0 0 4px 4px;
  box-shadow: $shadow-main;
  background-color: #ffffff;
  @media only screen and (min-width: 624px) {
    width: calc(50% - 30px);
  }
  @media only screen and (min-width: 1024px) {
    width: calc(33% - 30px);
  }
  @media only screen and (min-width: 1360px) {
    width: calc(50% - 30px);
  }
  @media only screen and (min-width: 1500px) {
    width: calc(33% - 30px);
  }
  .item-image {
    display: block;
    width: 100%;
    height: 300px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-color: $fl;
    img {
      width: 100%;
    }
  }
  .item-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 300px);
    h2 {
      font-family: $vito-bold;
    }
  }
  .time-creator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 0;
    p {
      font-family: $vito-light;
      font-size: 14px;
      line-height: 1;
      margin: 0;
      &:last-child {
        color: #107598;
      }
    }
  }
  .lead {
    font-family: $vito-light;
    font-size: 16px;
  }
  .button {
    display: block;
    width: 100%;
    color: #107598;
    border: 1px solid #107598;
    text-align: center;
    padding: 10px 0px;
    border-radius: 30px;
    font-family: $vito-bold;
  }
}
</style>
