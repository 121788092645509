<template>
  <div class="sign-wrapper">
    <div class="container-fluid">
      <div class="sign">
        <div class="sign__image">
          <a href="https://charidy.com" class="sign__logo">
            <cdn-logo-by-lang class="logo-main" />
          </a>

          <a
            href="https://charidy.com/all-campaigns"
            class="bttn bttn--orange bttn--sm live-campaigns"
          >
            Live campaigns
          </a>
        </div>

        <div class="sign__mode">
          <p class="sign__helper">
            {{ $t("template.already_have_account", "Already have an account?") }}
          </p>
          <a href="/login" class="bttn bttn--sm bttn--orange">{{
            $t("template.sign_in", "Sign In")
          }}</a>
        </div>

        <div class="sign__deco">
          <sign-in-svg-deco />
        </div>

        <div class="sign-content">
          <h1 class="sign-content__title">
            {{ $t("template.org_admin_dashboard", "Organization Administration Dashboard") }}
          </h1>
          <p class="sign-content__description">
            {{
              $t(
                "template.sign_up_text",
                "Take full control of your campaigns with charidy's organization dashboard. You can create and edit your campaign. Follow in real time all the campaign data and traffic. Integrate with companies like Salesforce."
              )
            }}
          </p>

          <form action="" class="sign-form">
            <h2 class="sign-form__title">{{ $t("template.get_started", "Get Started") }}</h2>
            <p class="sign-form__description">
              {{ $t("template.enter_your_detalis", "Enter your details below") }}
            </p>

            <sign-up-form
              @register-success="changeRegisterStatus"
              v-if="!signupSucceed"
              ref="signup"
            ></sign-up-form>

            <div v-if="signupSucceed" class="signup-message">
              <p>
                {{
                  $t(
                    "template.check_your_email",
                    "Please check your email box to verify your account!"
                  )
                }}
              </p>
            </div>

            <div class="sign-form__action" v-if="!signupSucceed">
              <button
                class="bttn bttn--lg bttn--orange bttn--sign"
                @click.prevent="proceedSignup"
                :disabled="!loading"
              >
                {{ $t("template.sign_up", "Signup") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import signUpForm from "../components/signupForm.vue";
import CdnLogoByLang from "@/common-components/cdn-v2/cdn-logo-by-lang.vue";
import SignInSvgDeco from "@/svg-components/SignInSvgDeco.vue";

export default defineComponent({
  data() {
    return {
      mode: 0,
      loading: true,
      signupSucceed: false,
    };
  },
  components: {
    signUpForm,
    CdnLogoByLang,
    SignInSvgDeco,
  },
  methods: {
    changeRegisterStatus: function (result) {
      this.loading = true;
      if (result) {
        this.signupSucceed = true;
      }
    },

    proceedSignup() {
      this.loading = false;
      this.$refs.signup.registerUser();
    },
  },
});
</script>

<style lang="scss" scoped>
.container-fluid {
  max-width: 1500px;
}
.el-left {
  margin-right: auto;
}
.el-right {
  margin-left: auto;
}
.sign__deco {
  img {
    @media only screen and (min-width: 1520px) {
      height: auto;
      width: auto;
    }
  }
}
.live-campaigns {
  margin-left: 10px;
}
.signup-message {
  width: 100%;
  height: 80px;
  padding: 0 15px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #414359;
  text-align: center;
  border: 1px solid #88bacc;
  border-radius: 4px;
  background-color: #eaf4ff;
  > p {
    margin: 0;
  }
}
::v-deep .sign-form__row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  @media only screen and (min-width: 400px) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .form-unit {
    width: 100%;
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    @media only screen and (min-width: 400px) {
      width: 49%;
      margin-bottom: 0;
      &:not(:last-of-type) {
        margin-bottom: 0;
      }
    }
  }
}
</style>
