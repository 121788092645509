<template>
  <div id="disabled_add_button">
    <!--<b-btn v-b-modal.addDonation :disabled='disabledButton'>{{ $t('dashboard.add_button_title') }}</b-btn>-->
    <button class="bttn bttn--sm bttn--orange" @click="openModal" :disabled="disabledButton">
      {{ $t("dashboard.add_button_title") }}
    </button>
    <b-tooltip
      target="disabled_add_button"
      v-if="disabledButton"
      :title="$t('dashboard.disabled_add_button')"
    ></b-tooltip>

    <!-- Modal Component -->
    <b-modal
      id="addDonation"
      size="lg"
      content-class="modal-content-dashboard"
      @hidden="clearData"
      no-close-on-backdrop
    >
      <div slot="modal-header">
        <h2>{{ $t("dashboard.add_modal_title") }}</h2>
        <div class="modal-header__hide" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
          {{ $t("dashboard.donation_add_cancel") }}
        </button>
        <button @click.prevent="submitForm" class="bttn bttn--lg bttn--orange" :disabled="sending">
          {{ $t("dashboard.add_donors_button_form") }}
        </button>
      </div>

      <form id="add-donation" ref="addDonate" class="entities-data">
        <!-- @submit.prevent="submitForm"-->
        <error v-if="errors" :errors="errors"></error>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group" :class="{ 'has-error': vErrors.has('fullName') }">
              <label class="entities-data__label entities-data__label--required">
                {{ $t("dashboard.add_donate_display_name") }}
              </label>
              <b-form-input
                name="fullName"
                v-validate="'required|min:3'"
                v-model="addDonation.data.attributes.display_name"
              >
              </b-form-input>
              <span v-show="vErrors.has('fullName')" class="v-help is-danger"
                >{{ $t("template.first_name_is_required") }}
              </span>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div
              class="form-group form-unit form-unit--addon"
              :class="{ 'has-error': vErrors.has('amount') }"
            >
              <label class="entities-data__label entities-data__label--required">
                {{ $t("dashboard.add_donate_charged_amount") }}
              </label>
              <div class="form-unit__addon-wrap">
                <div class="form-unit__addon-icon">
                  <b-dropdown
                    id="ddown1"
                    :text="addDonation.data.attributes.processing_charged_currency"
                    class="dropdown"
                  >
                    <b-dropdown-item @click="updateCurrency('usd')">USD</b-dropdown-item>
                    <b-dropdown-item @click="updateCurrency('eur')">EUR</b-dropdown-item>
                    <b-dropdown-item @click="updateCurrency('gbp')">gbp</b-dropdown-item>
                    <b-dropdown-item @click="updateCurrency('cad')">cad</b-dropdown-item>
                    <b-dropdown-item @click="updateCurrency('ils')">ils</b-dropdown-item>
                    <b-dropdown-item @click="updateCurrency('brl')">brl</b-dropdown-item>
                    <b-dropdown-item @click="updateCurrency('aud')">aud</b-dropdown-item>
                    <b-dropdown-item @click="updateCurrency('zar')">zar</b-dropdown-item>
                    <b-dropdown-item @click="updateCurrency('mxn')">mxn</b-dropdown-item>
                    <b-dropdown-item @click="updateCurrency('chf')">chf</b-dropdown-item>
                  </b-dropdown>
                </div>

                <b-form-input
                  type="number"
                  class="form-unit__input"
                  name="amount"
                  autocomplete="off"
                  v-model.number="chargedAmountUser"
                  v-validate="'required|min_value:1'"
                >
                </b-form-input>
                <div
                  class="convert-amount"
                  v-if="
                    addDonation.data.attributes.processing_charged_currency.toLowerCase() !=
                    currency.toLowerCase()
                  "
                >
                  <label class="entities-data__label entities-data">
                    {{ currency }} {{ addDonation.data.attributes.charged_amount }}
                  </label>
                </div>
              </div>
              <span v-show="vErrors.has('amount')" class="v-help is-danger">
                {{ $t("template.amount_is_required") }}
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label
                class="entities-data__label entities-data"
                :class="requiredType ? 'entities-data__label--required' : ''"
              >
                {{ $t("donors.offline_donation_source") }}
              </label>
              <b-form-select
                v-model="addDonation.data.attributes.offline_donation_source"
                :required="requiredType"
              >
                <option value="cash">{{ $t("donors.cash") }}</option>
                <option value="credit_card">{{ $t("donors.credit_card") }}</option>
                <option value="check">{{ $t("donors.check") }}</option>
                <option value="bank_transfer">{{ $t("donors.bank_transfer") }}</option>
                <option value="recurring_credit_card">
                  {{ $t("donors.recurring_credit_card") }}
                </option>
                <option value="recurring_bank_transfer">
                  {{ $t("donors.recurring_bank_transfer") }}
                </option>
                <option value="charity_voucher">{{ $t("donors.charity_voucher") }}</option>
              </b-form-select>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group">
              <label class="entities-data__label entities-data">
                {{ $t("donors.offline_donation_note") }}
              </label>
              <b-form-input v-model="addDonation.data.attributes.offline_donation_note">
              </b-form-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group" :class="{ 'has-error': vErrors.has('fullName') }">
              <label class="entities-data__label entities-data__label--required">
                {{ $t("dashboard.add_donate_email") }}
              </label>
              <b-form-input
                name="email"
                v-validate="'required|email'"
                v-model="addDonation.data.attributes.email"
              >
              </b-form-input>
              <span v-show="vErrors.has('email')" class="v-help is-danger"
                >{{ $t("template.email_is_required") }}
              </span>
            </div>
          </div>
          <div class="col-12 col-md-6" v-if="teamList.length > 0">
            <div class="form-group form-unit--multiselect">
              <label class="entities-data__label entities-data">
                {{ $t("dashboard.edit_donate_team") }}
              </label>
              <multiselect
                v-model="multiselectTeam"
                :options="teamList"
                :custom-label="customLabel"
                track-by="text"
                :multiple="false"
              >
              </multiselect>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="entities-data__label">
                {{ $t("dashboard.add_donate_dedication") }}
              </label>
              <b-form-input
                v-model="addDonation.data.attributes.dedication"
                :rows="2"
              ></b-form-input>
            </div>
          </div>
        </div>

        <div class="wrapper-options">
          <button
            type="button"
            @click="showOptionaly = !showOptionaly"
            class="show-hide-bttn"
            name="button"
          >
            {{
              !showOptionaly
                ? $t("dashboard.add_donation_show_optionaly")
                : $t("dashboard.add_donation_hide_optionaly")
            }}
            <i
              :class="!showOptionaly ? 'fa fa-angle-down' : 'fa fa-angle-up'"
              aria-hidden="true"
            ></i>
          </button>
        </div>

        <div class="optionally-params" v-if="showOptionaly">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="entities-data__label entities-data">
                  {{ $t("dashboard.add_donate_billing_name") }}
                </label>
                <b-form-input v-model="addDonation.data.attributes.billing_name"></b-form-input>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="entities-data__label entities-data">
                  {{ $t("dashboard.add_donate_billing_last_name") }}
                </label>
                <b-form-input
                  v-model="addDonation.data.attributes.billing_last_name"
                ></b-form-input>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="entities-data__label entities-data">
                  {{ $t("dashboard.add_donate_phone") }}
                </label>
                <b-form-input v-model="addDonation.data.attributes.phone"></b-form-input>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="entities-data__label entities-data">
                  {{ $t("dashboard.add_donate_country") }}
                </label>
                <b-form-select v-model="addDonation.data.attributes.payment_country">
                  <option
                    v-for="country in countryList"
                    :key="country.id"
                    :value="country.attributes.name"
                  >
                    {{ country.attributes.name }}
                  </option>
                </b-form-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="entities-data__label entities-data">
                  {{ $t("dashboard.add_donate_city") }}
                </label>
                <b-form-input v-model="addDonation.data.attributes.payment_city"></b-form-input>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="entities-data__label entities-data">
                  {{ $t("dashboard.add_donate_address") }}
                </label>
                <b-form-input v-model="addDonation.data.attributes.payment_address"></b-form-input>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="entities-data__label entities-data">
                  {{ $t("dashboard.add_donate_payment_address_2") }}
                </label>
                <b-form-input
                  v-model="addDonation.data.attributes.payment_address_2"
                ></b-form-input>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="entities-data__label entities-data">
                  {{ $t("dashboard.add_donate_referrer") }}
                </label>
                <b-form-input v-model="addDonation.data.attributes.referrer"></b-form-input>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="entities-data__label entities-data">
                  {{ $t("dashboard.add_donate_payment_state") }}
                </label>
                <b-form-input v-model="addDonation.data.attributes.payment_state"></b-form-input>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="entities-data__label entities-data">
                  {{ $t("dashboard.add_donate_category") }}
                </label>
                <b-form-input v-model="addDonation.data.attributes.category"></b-form-input>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label class="entities-data__label entities-data">
                  {{ $t("dashboard.edit_donate_postcode") }}
                </label>
                <b-form-input v-model="addDonation.data.attributes.payment_postcode"></b-form-input>
              </div>
            </div>
            <div class="col-12 col-md-6 align-self-center checkbox-block">
              <div class="form-checkbox form-checkbox--orange no-height">
                <input
                  class="form-checkbox__input"
                  type="checkbox"
                  id="send_confirmation_email"
                  v-model="addDonation.data.attributes.send_confirmation_email"
                />
                <label class="form-checkbox__label" for="send_confirmation_email">
                  {{ $t("dashboard.send_confirmation_email") }}
                </label>
              </div>

              <div class="form-checkbox form-checkbox--orange no-height">
                <input
                  class="form-checkbox__input"
                  type="checkbox"
                  id="send-email"
                  v-model="addDonation.data.attributes.send_receipt"
                />
                <label class="form-checkbox__label" for="send-email">
                  {{ $t("dashboard.send_receipt") }}
                </label>
              </div>
            </div>
          </div>
        </div>

        <error v-if="errors" :errors="errors"></error>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

import axios from "axios";

import urls from "@/constants/urls.js";

import error from "@/components/errors.vue";

import Multiselect from "vue-multiselect";

import debounce from "lodash/debounce";

export default {
  props: ["campaignid", "ccy"],

  data() {
    return {
      addDonation: {
        data: {
          attributes: {
            campaign_id: 0,
            display_name: "",
            billing_name: "",
            billing_last_name: "",
            currency_code: "",
            processing_charged_currency: "",
            phone: "",
            email: "",
            payment_country: "",
            payment_city: "",
            payment_address: "",
            payment_postcode: "",
            dedication: "",
            category: "",
            charged_amount: "",
            team_id: 0,
            offline_donation_source: "",
            offline_donation_note: "",
            change_request_type: "",
            change_request_note: "",
            send_receipt: false,
            send_confirmation_email: false,
          },
        },
      },
      errors: [],
      multiselectTeam: null,
      showOptionaly: false,
      sending: false,
      chargedAmountUser: null,
      chargeRequestType: [
        {
          text: this.$t("dashboard.none"),
          value: "",
        },
        {
          text: this.$t("dashboard.cancel_donation"),
          value: "cancel",
        },
        {
          text: this.$t("dashboard.cancel_refund"),
          value: "cancel_refund",
        },
      ],
    };
  },

  components: {
    error,
    Multiselect,
  },

  watch: {
    multiselectTeam: function () {
      if (this.multiselectTeam) {
        this.addDonation.data.attributes.team_id = parseInt(this.multiselectTeam.value);
      } else {
        this.addDonation.data.attributes.team_id = 0;
      }
    },

    chargedAmountUser: debounce(function () {
      this.convertCurrency();
    }, 500),
  },

  computed: mapState({
    selectedCampaignId: (state) => {
      return state.dashboard.selectedCampaign;
    },

    addDonationTeam: (state) => {
      return state.teams.teams;
    },

    teamList: (state) => {
      let arrTeams = state.teams.teams;

      let resultArr = [];

      for (var i = 0; i < arrTeams.length; i++) {
        let objTeam = {
          value: arrTeams[i].id,
          text: arrTeams[i].attributes.name,
        };

        resultArr.push(objTeam);
      }
      return resultArr;
    },

    currency: (state) => {
      return state.dashboard.campaignDashboard.data.attributes.currency_code;
    },

    countryList: (state) => {
      return state.account.countries;
    },

    requiredType(state) {
      const { campaigns, includedMedia } = state.campaigns;

      const campaign = campaigns.filter((el) => el.id === this.campaignid)[0];
      const { data } = campaign.relationships.campaign_meta;

      const metas = {};

      data.forEach((el) => {
        const meta = includedMedia.find((metaEl) => el.id === metaEl.id && el.type === metaEl.type);

        if (meta) metas[meta.attributes.name] = JSON.parse(meta.attributes.data);
      });

      if (metas.offline_donation_type_mandatory) return metas.offline_donation_type_mandatory.value;

      return false;
    },

    disabledButton: (state) => {
      return (
        state.dashboard.campaignDashboard.data.attributes.mode != 1 &&
        state.dashboard.campaignDashboard.data.attributes.mode != 2
      );
    },

    orgId: function () {
      return localStorage.getItem("orgId");
    },
  }),

  methods: {
    convertCurrency: function () {
      if (
        this.chargedAmountUser !== "" &&
        this.chargedAmountUser !== null &&
        this.addDonation.data.attributes.processing_charged_currency.toLowerCase() !=
          this.currency.toLowerCase()
      ) {
        axios
          .get(
            urls.getConversionAmount
              .replace(":amount", this.chargedAmountUser)
              .replace(":from", this.addDonation.data.attributes.processing_charged_currency)
              .replace(":to", this.currency.toLowerCase())
          )
          .then((response) => {
            this.addDonation.data.attributes.charged_amount = Math.round(
              response.data.data.attributes.amount_out
            );
          });
      } else {
        this.addDonation.data.attributes.charged_amount = 0;
      }
    },

    clearData: function () {
      this.errors = [];

      this.addDonation.data.attributes = {
        campaign_id: 0,
        display_name: "",
        billing_name: "",
        billing_last_name: "",
        currency_code: "",
        processing_charged_currency: this.currency,
        phone: "",
        email: "",
        payment_country: "",
        payment_city: "",
        payment_address: "",
        payment_postcode: "",
        dedication: "",
        category: "",
        charged_amount: "",
        team_id: 0,
        send_receipt: false,
        send_confirmation_email: false,
      };

      this.chargedAmountUser = null;

      this.showOptionaly = false;

      this.multiselectTeam = null;
    },

    openModal() {
      this.$root.$emit("bv::show::modal", "addDonation");
    },

    setActiveTeam: function () {
      let selectedTeam = this.teamList.filter((el) => {
        return el.value == this.addDonation.data.attributes.team_id;
      });

      if (selectedTeam.length > 0) {
        this.multiselectTeam = selectedTeam[0];
      } else {
        this.multiselectTeam = null;
      }
    },

    hideModal() {
      this.$root.$emit("bv::hide::modal", "addDonation");
    },

    updateCurrency(val) {
      this.addDonation.data.attributes.processing_charged_currency = val;
      this.convertCurrency();
    },

    submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.addDonation.data.attributes.charged_amount = this.chargedAmountUser;

          if (this.addDonation.data.attributes.processing_charged_currency != this.currency) {
            axios
              .get(
                urls.getConversionAmount
                  .replace(":amount", this.chargedAmountUser)
                  .replace(":from", this.addDonation.data.attributes.processing_charged_currency)
                  .replace(":to", this.currency.toLowerCase())
              )
              .then((response) => {
                this.addDonation.data.attributes.charged_amount = Math.round(
                  response.data.data.attributes.amount_out
                );
                this.addDonate();
              });
          } else {
            this.addDonate();
          }
        }
      });
    },

    addDonate() {
      if (this.sending) {
        return;
      }
      this.sending = true;
      this.errors = [];

      this.addDonation.data.attributes.campaign_id = parseInt(this.campaignid);
      this.addDonation.data.attributes.currency_code = this.currency.toLowerCase();
      this.addDonation.data.attributes.processing_charged_amount = this.chargedAmountUser;

      axios
        .post(
          urls.donationNewApi.replace(":orgId", this.orgId).replace(":campaignId", this.campaignid),
          this.addDonation
        )
        .then(() => {
          this.sending = false;

          this.$_notificationsMixin_makeToastCompat({
            group: "Notification",
            title: this.$t("dashboard.add_success_add"),
          });

          let params = {
            id: this.campaignid,
            setting: `?page=1&limit=50`,
          };

          this.$store.dispatch("getCampaignDonation", params);

          this.hideModal();
        })
        .catch((e) => {
          this.sending = false;
          if (e.response) {
            if (e.response.data) {
              if (e.response.data.error) {
                this.errors.push(e.response.data.error);
              } else {
                this.errors.push(this.$t("template.error_default"));
              }
            } else {
              this.errors.push(this.$t("template.error_default"));
            }
          } else {
            this.errors.push(this.$t("template.error_default"));
          }
        });
    },

    customLabel({ value, text }) {
      return `[${value}] ${text}`;
    },
  },

  created() {
    this.addDonation.data.attributes.processing_charged_currency = this.currency;
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_vars.scss";

.entities-data {
  &__label {
    font-family: $vito-light;
    text-transform: uppercase;
    font-size: 14px;
    color: #414359;
    line-height: 1;
    &--required {
      &:after {
        content: "*";
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        color: $lr-100;
      }
    }
  }
}
.v-help {
  font-size: 12px;
  position: absolute;
  right: 0;
  bottom: -18px;
  &.is-danger {
    color: $lr-100;
  }
}
.form-group {
  position: relative;
  &.has-error {
    .form-control {
      border-color: $lr-100;
    }
  }
}
::v-deep .dropdown-toggle {
  background: transparent !important;
  border: 0;
  text-transform: uppercase;
  font-family: $vito-bold;
  font-size: 14px;
  color: #414359 !important;
  &:focus,
  &:active,
  &:hover {
    background: transparent !important;
    color: #414359 !important;
  }
  + .dropdown-menu {
    .dropdown-item {
      font-family: $vito-light;
    }
  }
}

.form-unit__addon-icon {
  padding: 0;
}

.wrapper-options {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #eaf4ff;
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
  }
  .show-hide-bttn {
    width: auto;
    position: relative;
    z-index: 2;
    &:focus {
      outline: 0;
    }
  }
}

.convert-amount {
  padding: 0 15px;
  display: flex;
  align-items: center;
  label {
    white-space: nowrap;
    margin: 0;
  }
}
.checkbox-block {
  padding-top: 12px;
  .form-checkbox {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
}
.form-checkbox {
  margin: 0;
  &.no-height {
    height: initial;
    > label {
      margin-bottom: 0;
    }
  }
}
</style>
