<template>
  <b-modal class="edit-photo-popup" id="editPhoto" title centered hide-footer>
    <div class="d-flex flex-column justify-content-between align-items-center">
      <div class="my-3">
        <slot name="avatar"></slot>
      </div>
      <div class="drop-browse-wrap my-3">
        <div class="custom-file-wrap">
          <b-form-file
            ref="newAvatarFile"
            @change.prevent="uploadFileLocal($event)"
            accept="image/*"
            :disabled="loading"
          ></b-form-file>
          <div class="form-file-label">
            <span>{{ $t("donor_dashboard.add_photo_drop_files", "Drop files to upload ") }}</span>
            <span class="sm">{{ $t("donor_dashboard.add_photo_or", "or ") }}</span>
            <span>{{ $t("donor_dashboard.add_photo_browse", "browse ") }}</span>
          </div>
        </div>
      </div>
      <b-button @click="deleteFileLocal" :disabled="loading" class="my-3" block variant="danger">
        {{ $t("donor_dashboard.delete_photo_drop_file", "Delete") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "edit-photo",
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      uploadDonorAccountAvatarFile: "uploadDonorAccountAvatarFile",
      deleteDonorAccountAvatarFile: "deleteDonorAccountAvatarFile",
      updateUserWithAvatar: "updateUserWithAvatar",
    }),
    openModal() {
      this.$root.$emit("bv::show::modal", "editPhoto");
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "editPhoto");
    },
    deleteFileLocal() {
      this.loading = true;
      this.deleteDonorAccountAvatarFile()
        .then(this.updateUserWithAvatar)
        .then(this.hideModal)
        .finally(() => {
          this.loading = false;
        });
    },
    uploadFileLocal(fileInputEvent) {
      this.loading = true;
      this.uploadDonorAccountAvatarFile(fileInputEvent)
        .then(this.updateUserWithAvatar)
        .then(this.hideModal)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.edit-photo-popup {
  background: #1c273999;
  .avatar {
    width: 240px;
    height: 240px;
    .inner {
      width: 240px;
      height: 240px;
      img {
        max-width: 240px;
        max-height: 240px;
      }
    }
  }
  .d-flex {
    max-width: 640px;
    margin: 0 auto;
  }
  .avatar-wrap {
    width: 240px;
    height: 240px;
    overflow: hidden;
    border-radius: 120px;
    margin: 0;
  }
  .drop-browse-wrap {
    width: 55%;
  }
  .custom-file-wrap {
    position: relative;
    .custom-file {
      height: 160px;
    }
    .custom-file-input {
      height: 160px;
      cursor: pointer;
      &:focus ~ .custom-file-label {
        box-shadow: none;
        &:after {
          border-color: transparent;
        }
      }
    }
    .form-file-label {
      height: 160px;
      cursor: pointer;
      border: 1px dashed #c5ccd4;
      border-radius: 4px;
      text-align: center;
      padding-top: 65px;
      background: rgba(247, 251, 255, 0.8)
        url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/drop-icon.png")
        center top 15px no-repeat;
      background-size: 45px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      span {
        display: block;
        font-size: 16px;
        cursor: pointer;
        &.sm {
          font-size: 14px;
        }
      }
    }

    .custom-file-label {
      display: none;
      // height: 60px;
      // cursor: pointer;
      // border: 1px dashed #dee6ef;
      // background: rgba(247, 251, 255, 0.8);
      // &:before{
      //    content:"";
      //    display: block;
      //    top: 0;
      //    left: 0;
      //    bottom: 0;
      //    width: 60px;
      //    position: absolute;
      //    background:  url('../../../assets/img/drop-icon.png') center right no-repeat;
      // }
      // &:after{
      //     content: "Drop files to upload or browse";
      //     background: none;
      //     border: none;
      //     text-align:left;
      //     height: 60px;
      //     padding: 18px 10px 18px 10px;
      //     cursor: pointer;
      //     z-index: 1;
      //     left: 65px;
      // }
    }
  }
  a {
    color: #107598;
    text-decoration: none;
  }
  .close {
    font-size: 2.5rem;
    line-height: 0.5;
    color: #107598;
    opacity: 1;
    width: 36px;
    height: 36px;
    padding: 5px;
    border-radius: 18px;
    margin-top: -5px;
    outline: none;
    &:hover {
      background: #10759824;
    }
  }
  .modal-dialog {
    max-width: 800px;
  }
  .modal-body {
    padding-top: 0;
    padding-bottom: 60px;
    img {
      margin-bottom: 30px;
    }
    p {
      font: 30px/1.4 "VitoExtended-Bold";
      margin-bottom: 50px;
    }
  }
  .modal-content {
    background: url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco1.svg")
        top -40px left 5% no-repeat,
      url("https://b313e8803f7a4150a884-6e0b076a1e92e31c40be44f466689c50.ssl.cf5.rackcdn.com/charidy_images/donor_dashboard/deco2.svg")
        top -50px right 20% no-repeat,
      #fff;
    .modal-header {
      background: none;
    }
    .modal-body {
      background: none;
    }
  }
  .default-photo {
    display: none;
  }
  .default-photo:only-child {
    background: #fbfdff;
    border: 1px dashed #c5ccd4;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 40px 20px;
    border-radius: 50%;
    font-family: "VitoExtended-Light";
    align-items: center;
    justify-content: center;
    i {
      display: inline-block;
      width: 45px;
      height: 45px;
      // border: 1px solid #70acc1;
      //margin-bottom: 23px;
      border-radius: 25px;
      position: relative;
      background: #10759833;
      margin-top: -40px;
      &:before {
        //content: "\002B";
        content: "";
        display: block;
        width: 90px;
        height: 40px;
        font-style: normal;
        font-size: 26px;
        color: #107598;
        position: absolute;
        background: #10759833;
        line-height: 1.3;
        border-radius: 55px 55px 0 0;
        left: 50%;
        margin-left: -45px;
        bottom: -50px;
      }
    }
    span {
      display: block;
      text-decoration: underline;
      color: #107598;
    }
    &:focus {
      outline: none;
      background: #fbfdff !important;
      border: 1px dashed #dee6ef !important;
    }
  }
}
</style>
