<template>
  <div class="profile-container">
    <profile-edit></profile-edit>
  </div>
</template>

<script>
import profileEdit from "./subcomponents/profile-edit.vue";

export default {
  components: {
    "profile-edit": profileEdit,
  },
  destroyed() {
    console.log("destroyed");
  },
  created() {
    this.$store.dispatch("getDonorAccountInfo");
    console.log("created");
  },
};
</script>

<style lang="scss">
@import "@/styles/_vars.scss";
.profile-container {
  padding: 45px 35px;
  box-shadow: 0 8px 32px 0 rgba(35, 40, 71, 0.08);
  background-color: #ffffff;
  .bttn--orange {
    background-color: #ff5555;
    &:hover {
      opacity: 0.85;
    }
  }
  .account-card {
    box-shadow: none;
    border-bottom: 1px solid #e6eaed;
    .card-footer {
      padding-top: 0;
      padding-bottom: 30px;
    }
    .card-body {
      padding-bottom: 0;
    }
  }
}
</style>
