<template>
  <div class="video-list-item" @click.prevent="selectVideo">
    <div
      class="video-list-item__image"
      :style="{
        backgroundImage: `url(https://img.youtube.com/vi/${videoItem.youtubeId}/sddefault.jpg)`,
      }"
    />
    <div class="video-list-item__content">
      <div class="video-list-item__title">
        {{ videoItem.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoListItem",
  props: {
    videoItem: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    selectVideo() {
      this.$emit("setVideo", this.videoItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.video-list-item {
  width: 100%;
  min-width: 290px;
  min-height: 100px;
  display: flex;
  cursor: pointer;
  &:not(:last-of-type) {
    margin-right: 10px;
    @media only screen and (min-width: 768px) {
      margin-right: initial;
      margin-bottom: 10px;
    }
  }
  &__image {
    width: 152px;
    flex-shrink: 0;
    background-size: cover;
    background-position: center center;
  }
  &__content {
    padding-left: 10px;
    font-family: $vito-bold;
    font-size: 15px;
  }
}
</style>
