import urls from "@/constants/urls";
import axios from "axios";

const state = {
  team_sms_messaging: null,
  team_email_messaging: null,
  loading: {
    checkSmsSettingInOrg: false,
    checkEmailSettingInOrg: false,
    saveMessaging_sms: false,
    saveMessaging_email: false,
  },
};

const getters = {};

const mutations = {
  SET_TEAM_SMS_MESSAGING(state, data) {
    state.team_sms_messaging = data;
  },
  SET_TEAM_EMAIL_MESSAGING(state, data) {
    state.team_email_messaging = data;
  },
  SET_SMS_ALLOWED_COUNTRIES(state, data) {
    state.sms_allowed_countries = data;
  },
  SET_LOADING(state, [k, v]) {
    state.loading[k] = v;
  },
};

const actions = {
  checkSmsSettingInOrg({ commit }, { oId }) {
    commit("SET_LOADING", ["checkSmsSettingInOrg", true]);
    const url = urls["organizationSettings"].replace(":orgId", oId);
    return axios
      .get(url)
      .then(({ data }) => {
        const found = data.find((v) => v.setting === "team_sms_messaging");
        const resolve = (found && found.value) || false;
        commit("SET_TEAM_SMS_MESSAGING", resolve);

        const countriesSMSSettings = data.find((v) => v.setting === "sms_allowed_countries");
        const active = (countriesSMSSettings && countriesSMSSettings.value) || false;

        if (active) {
          commit("SET_SMS_ALLOWED_COUNTRIES", countriesSMSSettings.value_extend);
        }
        return data;
      })
      .finally(() => commit("SET_LOADING", ["checkSmsSettingInOrg", false]));
  },
  checkEmailSettingInOrg({ commit }, { oId }) {
    commit("SET_LOADING", ["checkEmailSettingInOrg", true]);
    const url = urls["organizationSettings"].replace(":orgId", oId);
    return axios
      .get(url)
      .then(({ data }) => {
        const found = data.find((v) => v.setting === "team_email_messaging");
        const resolve = (found && found.value) || false;
        commit("SET_TEAM_EMAIL_MESSAGING", resolve);
        return data;
      })
      .finally(() => commit("SET_LOADING", ["checkEmailSettingInOrg", false]));
  },
  save({ commit }, { oId, cId, delivery, audience, data }) {
    commit("SET_LOADING", [`saveMessaging_${delivery}`, true]);
    const url = urls["messaging"]
      .replace(":oid", oId)
      .replace(":cid", cId)
      .replace(":delivery", delivery)
      .replace(":audience", audience);
    return axios
      .post(url, data)
      .then(({ data }) => data)
      .finally(() => commit("SET_LOADING", [`saveMessaging_${delivery}`, false]));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
