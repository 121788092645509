<template>
  <div>
    <button class="bttn-bordered bttn-bordered--sm" :disabled="!hasAccess" v-b-modal.subAccount>
      {{ $t("account.sub_account_add_button") }}
    </button>

    <b-modal id="subAccount" size="lg" @hidden="clearData">
      <div slot="modal-header">
        <h2>{{ $t("account.sub_account_modal_header") }}</h2>
      </div>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click.prevent="hideModal">
          {{ $t("account.cancel") }}
        </button>

        <button
          v-if="!edit"
          form="subAccountAdd"
          :disabled="isLoading"
          class="bttn bttn--lg bttn--orange"
        >
          {{ $t("account.send_invitation") }}
        </button>

        <button v-if="edit" @click="updateSubAccount()" class="bttn bttn--lg bttn--orange">
          {{ $t("account.save") }}
        </button>
      </div>

      <form id="subAccountAdd" @submit.prevent="addSubAccount">
        <error v-if="errors" :errors="errors"></error>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group form-unit">
              <label class="form-unit__label">
                {{ $t("account.sub_account_modal_fname_label") }}
              </label>
              <input
                type="text"
                :disabled="edit"
                v-model="subaccount.data.attributes.first_name"
                class="form-control form-unit__input"
                name="firstName"
              />
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group form-unit">
              <label class="form-unit__label">
                {{ $t("account.sub_account_modal_lname_label") }}
              </label>
              <input
                type="text"
                :disabled="edit"
                v-model="subaccount.data.attributes.last_name"
                class="form-control form-unit__input"
                name="lastName"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group form-unit" :class="{ 'has-error': vErrors.has('contactEmail') }">
              <label class="form-unit__label form-group__label--required">
                {{ $t("account.sub_account_modal_email_label") }}
              </label>
              <input
                v-model="subaccount.data.attributes.email"
                v-validate="'required|email'"
                name="contactEmail"
                class="form-control form-unit__input"
                type="text"
                :disabled="edit"
              />
              <span v-show="vErrors.has('contactEmail')" class="v-help is-danger">{{
                $t("template.field_is_required")
              }}</span>
            </div>
          </div>

          <RoleSelect
            id="subaccount-role"
            required
            v-model="subaccount.data.attributes.access"
            :label="$t('account.sub_account_role')"
          />

          <RestrictedAclList
            v-if="subaccount.data.attributes.access === 'restricted'"
            v-model="restrictedACL"
          />
        </div>
        <div class="row align-items-center">
          <error-message ref="error"></error-message>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import urls from "@/constants/urls.js";
import {
  restrictedACLitemsToModelArr,
  restrictedACLModelArrToJSONApiRel,
} from "@/helpers/subAccounts";
import { notificationsMixin } from "@/mixins";

import error from "@/components/errors.vue";
import errorList from "@/components/error-message.vue";
import RoleSelect from "@/components/role-select.vue";
import RestrictedAclList from "@/components/restricted-acl-list.vue";

export default {
  data() {
    return {
      subAccId: "",
      subaccount: {
        data: {
          attributes: {
            active: "",
            access: null,
            email: "",
            first_name: "",
            last_name: "",
            login_id: "",
          },
        },
      },

      patchModel: {
        data: {
          attributes: {
            access: "",
            active: "",
          },
        },
      },

      restrictedACL: [],

      isLoading: false,
      errors: [],
      edit: false,
    };
  },
  mixins: [notificationsMixin],
  components: {
    error,
    errorMessage: errorList,
    RoleSelect,
    RestrictedAclList,
  },

  computed: mapState({
    hasAccess: (state) => {
      return state.account.access.CanManageOtherAccountAccess;
    },
  }),

  methods: {
    editMode(item) {
      this.edit = true;
      this.subAccId = item.id;
      this.subaccount.data = JSON.parse(JSON.stringify(item));

      this.restrictedACL = restrictedACLitemsToModelArr(
        item.relationships.restricted_acl_items.data
      );
      this.$root.$emit("bv::show::modal", "subAccount");
    },

    clearData() {
      this.edit = false;
      this.subaccount.data.attributes = {
        access: null,
        email: "",
        first_name: "",
        last_name: "",
      };
    },

    hideModal() {
      this.clearData();
      this.$root.$emit("bv::hide::modal", "subAccount");
    },

    addSubAccount() {
      const { relationships, included } = restrictedACLModelArrToJSONApiRel(this.restrictedACL);
      this.subaccount.data.relationships = relationships;
      this.subaccount.included = included;

      this.$validator.validateAll().then((result) => {
        if (result) {
          let orgId = localStorage.getItem("orgId");
          this.isLoading = true;
          axios
            .post(urls.subAccount.replace(":orgId", orgId), this.subaccount)
            .then(() => {
              this.isLoading = false;
              this.$store.dispatch("getSubaccountList");
              this.$root.$emit("bv::hide::modal", "subAccount");
            })
            .catch((e) => {
              console.log(e);
              this.isLoading = false;
              this.$refs.error.setErrorResponse(e.response);
            });
        }
      });
    },

    updateSubAccount() {
      let orgId = localStorage.getItem("orgId");
      this.patchModel.data.attributes = {
        access: this.subaccount.data.attributes.access,
        active: this.subaccount.data.attributes.active,
      };

      const { relationships, included } = restrictedACLModelArrToJSONApiRel(this.restrictedACL);
      this.patchModel.data.relationships = relationships;
      this.patchModel.included = included;

      axios
        .patch(
          urls.patchSubAccount.replace(":orgId", orgId).replace(":sucAccId", this.subAccId),
          this.patchModel
        )
        .then(() => {
          this.$store.dispatch("getSubaccountList");
          this.$root.$emit("bv::hide::modal", "subAccount");
          this.$_notificationsMixin_makeToast("Success", this.$t("account.success_edit"));
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-checkbox {
  margin: 0;
}
</style>
