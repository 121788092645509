<template>
  <div>
    <div class="d-flex align-items-center justify-content-center mb-2">
      <image-upload v-model="form.image" type="campaign" label="Upload campaign image" />
    </div>

    <b-row>
      <b-btn
        class="bttn bttn--lg bttn--orange bttn--mw255 ml-auto"
        :disabled="sending"
        @click="onSave"
      >
        {{ $t("edit_campaign.generalinfo_save") }}
      </b-btn>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";

import urls from "@/constants/urls.js";

import imageUpload from "@/components/image-upload.vue";

export default {
  components: {
    imageUpload,
  },

  data() {
    return {
      form: {
        image: "",
      },
      sending: false,
    };
  },

  computed: {
    ...mapState({
      cId: (state) => state.campaign.campaign.data.id,
      attrs: (state) => state.campaign.campaign.data.attributes,
      included: (state) => state.campaign.campaign.included || [],
    }),

    data() {
      const res = {};
      ["title", "short_link", "currency"].forEach((key) => {
        res[key] = this.attrs[key];
      });
      return res;
    },
  },

  created() {
    this.presetForm();
  },

  methods: {
    ...mapActions({
      fetchUpdateExpressCampaign: "expressCampaign/fetchUpdateExpressCampaign",
    }),

    presetForm() {
      let content = this.included.filter(
        (el) => el.type === "media" && el.attributes.tag === "slider"
      );
      this.form.image = (content && content[0]?.attributes?.src) || "";
    },

    updateCampaign() {
      let extend = `?${[
        "extend=campaign_stats",
        "extend=content",
        "extend=media",
        "extend=matchers",
        "extend=donation_levels",
        "extend=donation_streams",
      ].join("&")}`;

      this.$store.dispatch("updateCampaign", extend);
    },

    getMediaUrl() {
      const { orgId } = this;
      const mediaUrl = this.form.image;

      return new Promise(function (resolve) {
        if (mediaUrl instanceof File) {
          const formData = new FormData();

          formData.append("file", mediaUrl);

          axios.post(urls.loadImageQuill.replace(":OrgId", orgId), formData).then((response) => {
            resolve(response.data.src);
          });
        } else {
          resolve(mediaUrl);
        }
      });
    },

    async onSave() {
      this.sending = true;
      const image = await this.getMediaUrl();

      this.fetchUpdateExpressCampaign([
        this.cId,
        {
          ...this.data,
          image,
        },
      ])
        .then(() => {
          this.sending = false;
          this.$_notificationsMixin_makeToastCompat({
            group: "express-campaign-edit",
            title: this.$t("edit_campaign.success_edit"),
          });

          this.updateCampaign();
        })
        .catch(() => {
          this.sending = false;
        });
    },
  },
};
</script>
