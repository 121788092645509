<template>
  <b-card class="account-card">
    <h2 slot="header" class="account-card__title">
      {{ $t("account.2fa_title", "Two factor authorization") }}
    </h2>

    <b-alert v-if="twoFAActive" show variant="success">
      <b>
        {{ $t("account.2fa_enabled", "2FA enabled") }}
      </b>
    </b-alert>

    <section v-if="!twoFAActive">
      <b-alert v-for="(err, i) in respErrors" :key="i" show variant="danger">
        {{ err.detail }}
      </b-alert>

      <div class="mb-3">
        {{
          $t(
            "account.2fa_description",
            "For better security, please enable two factor authorization, which will add one more step on login - entering one time password generated on your phone."
          )
        }}
      </div>

      <div class="mb-3 text-center">
        <p class="mb-2">
          {{ $t("account.2fa_step1_description", "Download Google Authenticator:") }}
        </p>
        <div>
          <a
            href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
            target="_blank"
          >
            <img
              src="https://developer.apple.com//assets/elements/icons/download-on-the-app-store/download-on-the-app-store.svg"
              style="width: 160px"
            />
            <br />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
            target="_blank"
          >
            <img
              src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
              style="width: 190px"
            />
          </a>
        </div>
      </div>

      <b-overlay :show="loading" rounded="sm">
        <div class="mb-3 text-center">
          <p class="mb-2">
            {{
              $t(
                "account.2fa_step2_description",
                'To enable two-factor authentication, please scan this QR code with "Goole Authenticator" and input 6 digits code.'
              )
            }}
          </p>
          <div>
            <img :src="'data:image/png;base64,' + twofaqr" style="width: 300px" class="mb-2" />

            <div class="form-group form-unit">
              <label class="form-unit__label form-unit__label--upper-sm">
                {{
                  $t(
                    "account.2fa_code_label",
                    "Input code from your Googe Authenticator application"
                  )
                }}
              </label>
              <input v-model="code" class="form-control form-unit__input" />
            </div>
          </div>
        </div>
      </b-overlay>
    </section>

    <div slot="footer" class="account-card__footer text-right">
      <button
        v-if="!twoFAActive"
        class="bttn bttn--sm bttn--orange"
        :disabled="!code"
        @click="enable2FA"
      >
        {{ $t("account.enable", "Enable") }}
      </button>
    </div>
  </b-card>
</template>

<script>
import urls from "@/constants/urls";
import axios from "axios";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      // -- state --
      twofaqr: "",
      loading: false,
      respErrors: [],
      // -- form --
      code: "",
    };
  },

  computed: {
    ...mapState({
      twoFAActive: (s) => s.account.twoFAActive,
    }),
  },

  mounted() {
    this.getQRCode();
  },

  methods: {
    ...mapActions({
      updateUserAccessSettings: "updateUserAccessSettings",
    }),

    getQRCode() {
      this.loading = true;
      axios
        .get(urls.account2FA)
        .then(({ data }) => {
          this.twofaqr = data.qr_code;
        })
        .catch((err) => {
          const resp = err?.response;
          this.respErrors = resp?.data?.errors || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },

    enable2FA() {
      const payload = {
        code: this.code,
      };

      axios
        .post(urls.account2FA, payload)
        .then(({ data }) => {
          this.twofaqr = data.qr_code;
          this.updateUserAccessSettings();
        })
        .catch((err) => {
          const resp = err?.response;
          this.respErrors = resp?.data?.errors || [];
        });
    },
  },
};
</script>

<style></style>
