import urls from "../../constants/urls.js";
import axios from "axios";

const state = {
  reminder: [],
  loadingReminder: true,
  reminderCount: 0,
};

const mutations = {
  setReminder(state, data) {
    state.reminder = data;
  },

  setLoadingReminder(state, value) {
    state.loadingReminder = value;
  },

  setReminderCount(state, value) {
    state.reminderCount = value;
  },
};

const getters = {};

const actions = {
  getReminder({ commit }, props) {
    let orgId = localStorage.getItem("orgId");
    axios
      .get(urls.reminder.replace(":orgId", orgId) + props)
      .then((response) => {
        let reminder = response.data.data;
        commit("setReminderCount", parseInt(response.headers["x-search-remindme"]));
        commit("setReminder", reminder);
        commit("setLoadingReminder", false);
      })
      .catch((e) => {
        console.log(e);
      });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
