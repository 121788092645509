import urls from "../../constants/urls.js";
import axios from "axios";

const state = {
  campaigns: [],
  current: [],
  past: [],
  total: {
    value: 0,
    current: 0,
    past: 0,
  },
  includedMedia: [],
  loadingCampaigns: true,
};

const mutations = {
  setCampaigns(state, data) {
    state.campaigns = data;
  },

  setCampaignsByType(state, { type, list }) {
    state[type] = list;
  },

  setTotal(state, { type, total }) {
    if (type) {
      state.total[type] = Number(total);
    } else {
      state.total.value = Number(total);
    }
  },

  setIncludedMedia(state, data) {
    state.includedMedia = data;
  },

  setLoadingCampaigns(state, value) {
    state.loadingCampaigns = value;
  },
};

const getters = {
  getCurrentCampaigns: (state) => {
    let currentCampaigns = [];
    let campaigns = state.campaigns;

    for (let i = 0; i < campaigns.length; i++) {
      if (
        campaigns[i].attributes.mode == 0 ||
        campaigns[i].attributes.mode == 1 ||
        campaigns[i].attributes.mode == 2 ||
        campaigns[i].attributes.mode == 5
      ) {
        currentCampaigns.push(campaigns[i]);
      }
    }

    return currentCampaigns;
  },

  getDonationsPremoderationCampaigns: (state) => (camapignId) => {
    let included = state.includedMedia;

    let campaignMeta = included.filter((el) => {
      return el.attributes.campaign_id == camapignId;
    });

    let campaignMetaDonationsPremoderation = campaignMeta.filter((el) => {
      return el.attributes.name == "donations_premoderation";
    });
    return campaignMetaDonationsPremoderation.length > 0
      ? JSON.parse(campaignMetaDonationsPremoderation[0].attributes.data)
      : "";
  },

  getPastCampaigns: (state) => {
    let pastCampaigns = [];
    let campaigns = state.campaigns;

    for (let i = 0; i < campaigns.length; i++) {
      if (campaigns[i].attributes.mode == 3 || campaigns[i].attributes.mode == 4) {
        pastCampaigns.push(campaigns[i]);
      }
    }

    return pastCampaigns;
  },

  plateImage: (state) => (camapignId) => {
    let included = state.includedMedia;

    let campaignImages = included.filter((el) => {
      return (
        el.attributes.campaign_id == camapignId &&
        el.attributes.team_id === 0 &&
        el.type === "media"
      );
    });

    const findCampaignImage = (images) => {
      return images.reduce((acc, curr) => {
        const res = campaignImages.filter((el) => {
          return el.attributes.tag == curr;
        });

        if (res.length > 0) {
          acc = res[0].attributes.src;
          return acc;
        }

        return acc;
      }, "");
    };

    let resultImage = findCampaignImage([
      "slider",
      "campaign_hero_mobile",
      "countdown_hero_mobile",
      "campaign_hero",
      "countdown_hero",
      "shared_image",
    ]);

    return resultImage;
  },

  getCampaignOptionsDashboard(state) {
    if (state.campaigns.length === 0) return [];

    return state.campaigns.map((v) => ({
      id: v.id,
      title: v.attributes.title,
    }));
  },
};

const actions = {
  async getCampaigns({ commit, state }, payload) {
    let orgId = localStorage.getItem("orgId");

    let { params, type, action } = payload || {};

    if (!params) {
      const extend = ["campaign_stats", "media", "meta", "donation_levels", "donation_streams"];
      params = { extend };
    }

    !(action === "see-more") && commit("setLoadingCampaigns", true);

    const response = await axios.get(urls.getCampaignList.replace(":orgId", orgId), { params });

    let campaignsList = response.data.data ? response.data.data : [];
    let included = response.data.included ? response.data.included : [];

    commit("setIncludedMedia", [...state.includedMedia, ...included]);
    commit("setTotal", {
      type,
      total: response.headers["x-total-records"] || 0,
    });

    if (action && action === "see-more") {
      campaignsList = [...state[type ? type : "campaigns"], ...campaignsList];
    }

    if (type) {
      commit("setCampaignsByType", {
        type,
        list: campaignsList,
      });
    } else {
      commit("setCampaigns", campaignsList);
    }

    !(action === "see-more") && commit("setLoadingCampaigns", false);

    return response;
  },

  updateCampaigns({ commit }) {
    let orgId = localStorage.getItem("orgId");
    const extendParams = ["campaign_stats", "media", "meta", "donation_levels", "donation_streams"];
    axios
      .get(urls.getCampaignList.replace(":orgId", orgId), { params: { extend: extendParams } })
      .then((response) => {
        let campaignsList = response.data.data ? response.data.data : [];
        let included = response.data.included ? response.data.included : [];
        commit("setIncludedMedia", included);
        commit("setCampaigns", campaignsList);
      });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
