<template>
  <div
    v-if="layout === 'grid'"
    class="plate-campaign"
    :class="{ 'border-warning': $options.getFavoriteCampaign() === campaigndata.id }"
  >
    <div
      class="plate-campaign__top"
      :style="imageForPlate ? 'background-image: url(' + imageForPlate + ')' : ''"
    >
      <CampaignActionsButton
        :campaignId="campaigndata.id"
        :campaignAttrs="campaigndata.attributes"
        :isWizardMode="isWizardMode"
        class="plate-campaign__top-actions-btn"
        @edit="editCampaign"
        @clone="cloneCampaign"
        @delete="deleteCampaign"
        @favorite="() => $options.setFavoriteCampaign(campaigndata.id)"
        @unfavorite="() => $options.deleteFavoriteCampaign()"
      />

      <CampaignPlateWizardBtn
        :id="`id-${campaigndata.id}-bttn-edit`"
        v-if="isWizardMode"
        @click="onEditClick"
        buttonClass="plate-campaign__top-edit-btn plate-campaign__top-edit-btn--absolute"
      />
      <router-link
        v-else
        class="plate-campaign__top-edit-btn plate-campaign__top-edit-btn--absolute"
        :to="`/campaign/${campaigndata.id}`"
      >
        <EditIcon />
      </router-link>
    </div>

    <div class="plate-campaign__body">
      <CampaignPlateTitle
        :campaign="campaigndata"
        :campaignTitle="campaigndata.attributes.title"
        :campaignShortlink="campaigndata.attributes.short_link"
        :isFavoriteCampaign="$options.getFavoriteCampaign() === campaigndata.id"
      />
      <CampaignProgress :campaignAttrs="campaigndata.attributes" />
      <CampaignStatus
        :campaignId="campaigndata.id"
        :campaignAttrs="campaigndata.attributes"
        :isWizardMode="isWizardMode"
      />
    </div>
  </div>
  <div
    class="d-flex flex-row w-100 justify-content-between align-items-center gap-5"
    v-else-if="layout === 'list'"
  >
    <CampaignPlateTitle
      class="w-25"
      :campaignTitle="campaigndata.attributes.title"
      :campaignShortlink="campaigndata.attributes.short_link"
      :isFavoriteCampaign="$options.getFavoriteCampaign() === campaigndata.id"
      v-slot="{ campaignTitle }"
    >
      <router-link :to="`/campaign/${campaigndata.id}`">
        {{ campaignTitle }}
      </router-link>
    </CampaignPlateTitle>
    <CampaignProgress class="w-50" :campaignAttrs="campaigndata.attributes" />
    <CampaignStatus
      class="w-25"
      :campaignId="campaigndata.id"
      :campaignAttrs="campaigndata.attributes"
      :isWizardMode="isWizardMode"
    />
    <div class="w-auto d-flex gap-3">
      <CampaignPlateWizardBtn
        :id="`id-${campaigndata.id}-bttn-edit`"
        v-if="isWizardMode"
        @click="onEditClick"
        buttonClass="plate-campaign__top-edit-btn"
      />
      <CampaignActionsButton
        :campaignId="campaigndata.id"
        :campaignAttrs="campaigndata.attributes"
        :isWizardMode="isWizardMode"
        styledDropdownBtn
        @edit="editCampaign"
        @clone="cloneCampaign"
        @delete="deleteCampaign"
        @favorite="() => $options.setFavoriteCampaign(campaigndata.id)"
        @unfavorite="() => $options.deleteFavoriteCampaign()"
      >
        <template #button-content>
          <b-icon icon="menu-down" :style="{ fill: 'white' }" />
        </template>
      </CampaignActionsButton>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters, mapState } from "vuex";
import urls from "@/constants/urls.js";
import { notificationsMixin } from "@/mixins";
import EditIcon from "./edit-icon.vue";
import { OpenCrowdfundingFundraisingModal } from "@/constants/providers";
import {
  setFavoriteCampaign,
  getFavoriteCampaign,
  deleteFavoriteCampaign,
} from "@/helpers/storage";
import CampaignProgress from "./campaignProgress.vue";
import CampaignStatus from "./campaignStatus.vue";
import CampaignActionsButton from "./campaignActionsButton.vue";
import CampaignPlateTitle from "./campaignPlateTitle.vue";
import CampaignPlateWizardBtn from "./campaignPlateWizardBtn.vue";

export default {
  setFavoriteCampaign,
  getFavoriteCampaign,
  deleteFavoriteCampaign,

  props: {
    campaigndata: {
      type: Object,
      required: true,
    },
    crowdfundingFundraisingOptions: {
      type: Array,
      default: () => [],
    },
    layout: {
      type: String,
      default: "grid",
    },
  },

  mixins: [notificationsMixin],
  inject: { OpenCrowdfundingFundraisingModal },
  components: {
    EditIcon,
    CampaignProgress,
    CampaignStatus,
    CampaignActionsButton,
    CampaignPlateTitle,
    CampaignPlateWizardBtn,
  },

  data: function () {
    return {
      errors: [],
    };
  },

  computed: {
    ...mapState({
      imageForPlate: function () {
        return this.$store.getters.plateImage(this.campaigndata.id);
      },
      loadingCampaign: (state) => state.campaign.loadingCampaign,
    }),
    ...mapGetters({
      getCurrentlyEditedInWizard: "getCurrentlyEditedInWizard",
    }),
    crowdfundingFundraisingOptionsAsKeys() {
      return this.crowdfundingFundraisingOptions.reduce((acc, curr) => {
        acc[curr.key] = curr.disabled;
        return acc;
      }, {});
    },
    isWizardMode() {
      return this.getCurrentlyEditedInWizard === this.campaigndata.id;
    },
  },

  methods: {
    ...mapActions({
      fetchDeleteCampaign: "fetchDeleteCampaign",
    }),

    deleteCampaign: function () {
      let message = this.$t("campaigns.confirm_delete_campaign");

      let options = {
        okText: this.$t("account.confirm_delete"),
        cancelText: this.$t("account.confirm_cancel"),
      };

      this.$dialog.confirm(message, options).then(() => {
        this.fetchDeleteCampaign({ cId: this.campaigndata.id })
          .then(() => {
            this.$emit("update-list");

            this.$_notificationsMixin_makeToastCompat({
              group: "Notification",
              title: this.$t("campaigns.success_delete"),
            });
          })
          .catch(this.$_notificationsMixin_handleCatch);
      });
    },

    editCampaign(campaignState) {
      if (campaignState === "wizard") {
        this.onEditClick();
        return;
      }

      this.$router.push({ path: `/campaign/${this.campaigndata.id}` });
      return;
    },

    cloneCampaign: function () {
      let orgId = localStorage.getItem("orgId");
      const campaigndata = {
        attributes: {
          currency: this.campaigndata.attributes.currency,
          start_date: this.campaigndata.attributes.start_date,
          end_date: this.campaigndata.attributes.end_date,
          title: this.campaigndata.attributes.title,
          primary_goal: this.campaigndata.attributes.primary_goal,
          bonus_goal: this.campaigndata.attributes.bonus_goal,
        },
      };
      axios
        .post(
          urls.cloneCampaign.replace(":orgId", orgId).replace(":campaignId", this.campaigndata.id),
          campaigndata
        )
        .then((respone) => {
          this.$router.push({ path: `/campaign/${respone.data.data.id}` });
          this.$_notificationsMixin_makeToast(
            "Success",
            this.$t("campaigns.success_clone", "Successfully cloned")
          );
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    onEditClick(tooltipId = null) {
      this.$root.$emit("bv::hide::tooltip", tooltipId);
      this.OpenCrowdfundingFundraisingModal({
        hiddenSteps: { ...this.crowdfundingFundraisingOptionsAsKeys },
      });
    },
  },
};
</script>

<style lang="scss">
.plate-campaign {
  width: 100%;
  margin: 0 15px 20px 15px;
  overflow: hidden;
  color: $fd;
  border-radius: 4px;
  box-shadow: $shadow-main;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  @media only screen and (min-width: 624px) {
    width: calc(50% - 30px);
  }
  @media only screen and (min-width: 1024px) {
    width: calc(33% - 30px);
  }
  @media only screen and (min-width: 1360px) {
    width: calc(50% - 30px);
  }
  @media only screen and (min-width: 1500px) {
    width: calc(33% - 30px);
  }

  &__top {
    display: block;
    width: 100%;
    height: 160px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-color: $fl;
    &-actions-btn {
      ::v-deep .dropdown-item {
        text-transform: initial !important;
      }
    }
    &-edit-btn {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: $bb-100;
      border: none;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      transition: background-color 0.2s ease-in-out;
      &:hover,
      &:focus {
        background-color: $bb-90;
      }
      &--absolute {
        position: absolute;
        right: 30px;
        bottom: -28px;
      }
    }
  }

  &__body {
    padding: 40px 30px 40px;
  }

  &__header {
    display: flex;
    width: 100%;
    align-items: center;
  }
}
</style>
