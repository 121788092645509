<template>
  <div class="rds-period">
    <div class="rds-period__bottom">
      <b-form-group
        id="period-fieldset-1"
        :label="$t('dashboard.rds_period_label', 'Period')"
        label-for="period-date"
        label-class="font-weight-bold"
      >
        <input type="month" input-id="period-date" v-model="period" />
      </b-form-group>
      <text-3-rows class="mx-4" btn :active="status === ''" @click.native="setStatus('')">
        <template #top>
          {{ stats.charge_count }}
        </template>
        <template #middle>{{ $t("dashboard.rds_charges_stats_total", "Total") }}</template>
        <template #bottom>
          <span>{{ currencySign }}</span>
          <span>{{ stats.charge_total.toLocaleString() }}</span>
        </template>
      </text-3-rows>
      <span class="vertical-devider" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import text3Rows from "./text-3-rows.vue";

export default {
  components: {
    text3Rows,
  },
  props: {
    value: {
      type: String,
    },
  },

  computed: {
    ...mapState({
      filters: (s) => s.rds.ui.filters,
      stats: (s) => s.rds.recordsStats,
      campaigns: (s) => s.campaigns.campaigns,
    }),

    campaign() {
      return this.campaigns.find((v) => v.id === this.filters.cid);
    },

    currencySign() {
      return this.campaign?.attributes.currency_sign;
    },

    period: {
      get() {
        const date = new Date(this.filters.year, this.filters.month).getTime();
        const fdate = moment(date).tz("America/New_York").format("YYYY-MM");

        return date >= 0 && fdate;
      },
      set(val) {
        const date = new Date(val);

        this.setFilter({ key: "year", value: date.getFullYear() });
        this.setFilter({ key: "month", value: date.getMonth() + 1 });
      },
    },

    status: {
      get() {
        return this.filters.status;
      },
      set(status) {
        this.setFilter({ key: "status", value: status });
      },
    },
  },

  mounted() {
    const ddate = moment().tz("America/New_York").startOf("hour").format("YYYY-MM");

    this.period = ddate;
  },

  methods: {
    ...mapActions({
      setFilter: "rds/ui/setFilter",
    }),

    setStatus(status) {
      this.status = status;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .form-group input {
  height: 40px;
  padding: 0px 13px;
}
.rds-period {
  flex: 0.7;
  align-self: center;
  @media screen and (max-width: 768px) {
    flex: 1;
  }
  &__bottom {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 2fr 1fr auto;
    align-self: end;
  }
}

.vertical-devider {
  width: 1px;
  justify-items: center;
  border-right: var(--border-darker);
}
</style>
