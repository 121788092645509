<template>
  <div>
    <b-modal ref="modal" size="lg" @hidden="clearData">
      <div slot="modal-header">
        <h2>{{ $t("leads.import_modal_title", "Import leads from the file") }}</h2>
        <div class="modal-header__hide" @click.prevent="hideModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button
          :class="
            successUpload || procLoading
              ? 'bttn bttn--lg bttn--orange el-right'
              : 'bttn bttn--lg bttn--blue'
          "
          @click.prevent="hideModal"
        >
          {{ $t("leads.hide_modal", "Close") }}
        </button>

        <button
          v-if="!successUpload && !procLoading"
          @click.prevent="uploadFile"
          :disabled="loading || procLoading || !this.file"
          class="bttn bttn--lg bttn--orange"
        >
          {{ $t("leads.import", "Import") }}
        </button>
      </div>

      <form class="import-donation entities-data">
        <div v-if="!procLoading && !successUpload">
          <response-error-alert ref="responseErrorAlert" />

          <p>
            {{
              $t(
                "leads.import_description",
                "To upload more than one item at a time, you can upload a XLSX file"
              )
            }}
            <a href="#" @click="dowloadSample">
              {{ $t("leads.import_dowload_template", "download template") }}
            </a>
            <!-- {{ $t('leads.import_mandatory') }} -->
          </p>

          <label class="upload-file">
            {{ $t("leads.import_upload_file", "Upload file") }}
            <br />
            {{ fileName }}
            <input
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @change="fileLoad"
            />
          </label>
        </div>

        <div v-if="procLoading">
          {{ $t("leads.import_uploading", "Uploading") }}... {{ progress }}%
        </div>

        <div v-if="successUpload && uploadResult">
          <p class="success">
            {{ $t("leads.successful_import") }}
          </p>
          <p>
            {{
              $t("leads.imported_number", "Imported {number} rows", {
                number: uploadResult.imported_number,
              })
            }}
          </p>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import urls from "@/constants/urls.js";

import { notificationsMixin } from "@/mixins";
import responseErrorAlert from "@/components/response-error-alert.vue";

export default {
  components: { responseErrorAlert },

  mixins: [notificationsMixin],

  data() {
    return {
      file: undefined,
      fileName: "",
      // import data
      loading: false, // loading the API call
      procLoading: false, // the process of file uploading
      uploadResult: null, // json with result report
      successUpload: false, // if the import is comlpeted
      progress: 0,
      procID: 0,
      interval: null,
    };
  },

  watch: {
    procID() {
      if (this.procID > 0 && this.interval == null) {
        this.initProcTracking();
      }
    },
  },

  methods: {
    openModal() {
      this.$refs.modal.show();
    },

    hideModal() {
      this.$refs.modal.hide();
    },

    updateReminderList() {
      let props = `?limit=50&page=1`;
      this.$store.dispatch("getReminder", props);
    },

    initProcTracking() {
      this.interval = setInterval(this.checkProcProgress, 2000);
    },

    checkProcProgress() {
      let orgId = localStorage.getItem("orgId");

      axios
        .get(urls.procProgressTracker.replace(":oid", orgId).replace(":id", this.procID))
        .then((response) => {
          this.progress = response.data.data.attributes.progress;

          if (response.data.data.attributes.finished) {
            clearInterval(this.interval);

            this.successUpload = true;
            this.procLoading = false;

            if (response.data.data.attributes.meta != null) {
              this.uploadResult = JSON.parse(response.data.data.attributes.meta);
            }
          } else {
            this.procLoading = true;
          }
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    dowloadSample() {
      let orgId = localStorage.getItem("orgId");

      axios
        .get(urls.remindMeImportXls.replace(":oid", orgId))
        .then((response) => {
          let linkButton = document.createElement("a");
          linkButton.setAttribute("target", "_parent");
          linkButton.setAttribute("href", response.data.link);
          linkButton.classList.add("link");
          document.querySelector("body").appendChild(linkButton);
          linkButton.click();
          linkButton.remove();
        })
        .catch(this.$_notificationsMixin_handleCatch);
    },

    uploadFile() {
      this.$refs?.responseErrorAlert?.clearErrors();

      let orgId = localStorage.getItem("orgId");

      this.loading = true;

      let data = new FormData();
      data.append("file", this.file);

      axios
        .post(urls.remindMeImportXls.replace(":oid", orgId), data)
        .then((response) => {
          const attrs = response?.data?.data?.attributes;

          this.file = undefined;
          this.fileName = "";

          this.loading = false;

          if (attrs) {
            this.procID = attrs.proc_session.ID;
            this.procLoading = true;
          } else {
            this.updateReminderList();
            this.hideModal();
          }
        })
        .catch((e) => {
          this.$refs?.responseErrorAlert?.handleCatch(e);
          this.$_notificationsMixin_handleCatch(e);
          this.loading = false;
        });
    },

    fileLoad(e) {
      if (e.target.files.length > 0) {
        this.fileName = e.target.files[0].name;
        this.file = e.target.files[0];
      } else {
        this.fileName = "";
        this.file = null;
      }
    },

    clearData() {
      this.file = undefined;
      this.fileName = "";
      this.loading = false; // loading the API call
      this.procLoading = false; // the process of file uploading
      this.uploadResult = null; // json with result report
      this.successUpload = false; // if the import is comlpeted
      this.progress = 0;
      this.procID = 0;
      this.interval = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.import-donation {
  padding-top: 20px;
  padding-bottom: 20px;
}

.upload-file {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  cursor: pointer;
  border: 1px dashed #cecece;
  text-align: center;
  input {
    display: none;
  }
}

#massUpload {
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  position: absolute;
}

.export-list {
  margin-top: 20px;
}
</style>
