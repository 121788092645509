<template>
  <div>
    <b-form-group v-if="orgAttrs">
      <b-input-group v-if="orgAttrs.public_email" prepend="Email" class="mt-3">
        <b-form-input :value="orgAttrs.public_email" readonly></b-form-input>
        <b-input-group-append>
          <b-button
            class="bttn--orange h-100"
            variant="link"
            :href="`mailto:${orgAttrs.public_email}`"
            >Send email</b-button
          >
        </b-input-group-append>
      </b-input-group>
      <b-input-group v-if="orgAttrs.public_phone" prepend="Phone" class="mt-3">
        <b-form-input :value="orgAttrs.public_phone" readonly></b-form-input>
        <b-input-group-append>
          <b-button class="bttn--orange h-100" variant="link" :href="`tel:${orgAttrs.public_phone}`"
            >Make a call</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    orgAttrs: {
      default: () => null,
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped></style>
