<template>
  <b-col cols="12" md="6">
    <b-form-group :description="roleDescription(value)">
      <label class="form-group__label form-group__label--required">
        {{ label }}
      </label>
      <b-form-select
        :value="value"
        @input="$emit('input', $event)"
        :options="roleTypes"
        v-bind="$attrs"
      />
    </b-form-group>
  </b-col>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      roleTypes: [
        { value: null, text: this.$t("account.select_role"), disabled: true },
        {
          value: "full",
          text: this.$t("account.role_full"),
          desc: this.$t(
            "account.role_full_desc",
            "Full access account will be able to make any changes on your organization and all campaigns belonging to it"
          ),
        },
        {
          value: "operator",
          text: this.$t("account.role_operator"),
          desc: this.$t(
            "account.role_operator_desc",
            "Operation Room Agent will have an access to edit donations (name, dedication, payment details), add new offline donations and edit campaign teams."
          ),
        },
        {
          value: "team_agent",
          text: this.$t("account.role_team_agent", "Team Agent"),
          desc: this.$t("account.role_team_agent_desc", "Team Agent does something."),
        },
        {
          value: "restricted",
          text: this.$t("account.role_restricted", "Restricted"),
          desc: this.$t("account.role_restricted_desc", "Restricted"),
        },
      ],
    };
  },
  methods: {
    roleDescription(roleType) {
      return this.roleTypes.find((rt) => rt.value === roleType)?.desc || "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
