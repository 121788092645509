<template lang="html">
  <div>
    <category-title :title="$t('training.training_videos')" withActions>
      <div class="drop-down">
        <b-dropdown
          id="ddown"
          :text="$t('training.training_' + this.$route.name + '_link')"
          class="h1-like"
        >
          <b-dropdown-item>
            <router-link to="/training/video" class="link">
              {{ $t("training.training_video_link") }}
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link to="/training/video-fr" class="link">
              {{ $t("training.training_video_link_fr") }}
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item>
            <router-link to="/training/article" class="link">
              {{ $t("training.training_article_link") }}
            </router-link>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </category-title>
    <!--
    <div class="category-title">
      <h1 class="h1-like">{{ $t('training.training_videos') }}</h1>
      <div class="drop-down">
        <b-dropdown
          id="ddown"
          :text="$t('training.training_' + this.$route.name + '_link')"
          class="h1-like"
        >
          <b-dropdown-item>
            <router-link to="/training/video" class="link">
              {{ $t('training.training_video_link') }}
            </router-link>
            <router-link to="/training/article" class="link">
              {{ $t('training.training_article_link') }}
            </router-link>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    -->
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import categoryTitle from "@/common-components/ui-elements/category-title";

export default {
  components: {
    categoryTitle,
  },
  computed: {
    ...mapState({
      loading: (state) => {
        return state.video.loadingVideo;
      },
    }),
  },
  created() {
    console.log(this.$route.name);
  },
};
</script>

<style lang="scss" scoped>
::v-deep .category-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .action-block {
    margin: 0 auto 10px 0;
  }
}
::v-deep #ddown {
  .dropdown-toggle {
    color: #107598;
    font-size: 14px;
    background-color: transparent;
    border: none;
    &:focus,
    &:active,
    &:hover {
      color: #333333;
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
    &:after {
      color: #107598;
      border-top: 6px solid;
      border-right: 6px solid transparent;
      border-bottom: 0;
      border-left: 6px solid transparent;
      top: 25%;
    }
    > span {
      font-size: 18px;
      color: #107598;
    }
  }
  .dropdown-toggle[area-expanded="true"] {
    color: #107598;
    background-color: transparent;
    font-size: 14px;
  }
  .dropdown-item {
    display: block;
    height: auto;
    &:focus,
    &:active,
    &:hover {
      outline: 0;
    }
  }
  .link {
    display: block;
    width: 100%;
    padding: 15px 0;
    text-decoration: none;
  }
}
</style>
