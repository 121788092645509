export default {
  title: "DonationSuccessPageConfig",
  type: "object",
  expanded: true,
  noValidateChild: true,
  properties: {
    value: {
      title: "Active",
      type: "boolean",
      default: true,
    },
    banner_image: {
      title: `Banner Image`,
      type: "image",
      fileName: true,
      preview: true,
    },
    text: {
      title: "Text",
      type: "string",
      default: "",
      maxLength: 256,
    },
    custom_message: {
      title: "Custom Message (HTML)",
      type: "string",
      default: "",
      maxLength: 256,
    },
  },
};
