export function match(node, prefix) {
  let className = node.getAttribute("class") || "";
  return className.split(/\s+/).filter(function (name) {
    return name.indexOf(`${prefix}-`) === 0;
  });
}

export function camelize(name) {
  let parts = name.split("-");
  let rest = parts
    .slice(1)
    .map(function (part) {
      return part[0].toUpperCase() + part.slice(1);
    })
    .join("");
  return parts[0] + rest;
}

function unwrapContent(content, key) {
  if (typeof content === "string") {
    return content;
  }
  const keyContent = content?.[key];
  if (typeof keyContent === "string") {
    return keyContent;
  }
  return typeof content === "string" ? content : "";
}

export function unwrapContentValue(content) {
  return unwrapContent(content, "value");
}

export function unwrapContentInline(content) {
  return unwrapContent(content, "inlineStylesHtml");
}
