<template>
  <div class="app-select">
    <MultiselectSearch
      v-model="selected"
      :open-prefetch="true"
      :close-on-select="true"
      :search-url="searchUrl"
      :search-options="searchOptions"
    >
      <slot name="singleLabel" slot="singleLabel"></slot>
    </MultiselectSearch>
  </div>
</template>

<script>
import MultiselectSearch from "@/components/multiselect-search.vue";

export default {
  name: "AppSelect",
  props: {
    value: {
      required: true,
    },
    searchUrl: {
      type: String,
      required: true,
      default: "",
    },
    searchOptions: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  components: {
    MultiselectSearch,
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.app-select {
  width: 100%;
  display: inline-block;
  position: relative;
}

::v-deep .multiselect {
  min-height: 48px;
  &__select {
    height: 46px;
  }
  &__tags {
    min-height: 48px;
    border-radius: 24px;
    border-color: $bb-50;
    padding-left: 20px;
  }
  &__single {
    height: 30px;
    line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__input {
    min-height: 30px;
    line-height: 30px;
  }
  &__option {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

#app.he {
  .app-select {
    ::v-deep .multiselect {
      text-align: right;
      &__single {
        text-align: right;
      }
    }
  }
}
</style>
