import urls from "../../constants/urls.js";
import axios from "axios";

const state = {
  integrations: [],
  mailChimpIntegrations: [],
  loadingIntegrations: true,
  loadingMailChimpList: false,
};

const mutations = {
  setIntegrations(state, data) {
    state.integrations = data;
  },

  setLoadingIntegrations(state, value) {
    state.loadingIntegrations = value;
  },

  setLoadingMailChimpList(state, value) {
    state.loadingMailChimpList = value;
  },

  setMailchimpIntegrations(state, data) {
    state.mailChimpIntegrations = data;
  },
};

const getters = {
  getintegratuimByType: (state) => (type) => {
    return state.integrations.filter(function (i) {
      return i.attributes.service === type;
    });
  },
};

const actions = {
  getIntegrationsList({ commit }) {
    let orgId = localStorage.getItem("orgId");
    axios.get(urls.getIntegrationsList.replace(":orgId", orgId)).then((response) => {
      let integrations = response.data.data;
      commit("setIntegrations", integrations);
      commit("setLoadingIntegrations", false);
    });
  },

  getMailChimpIntegrationList({ commit }, id) {
    let orgId = localStorage.getItem("orgId");
    commit("setLoadingMailChimpList", true);
    axios
      .get(urls.getMailChimpIntegrationList.replace(":orgId", orgId).replace(":id", id))
      .then((response) => {
        let list = response.data.data;
        commit("setMailchimpIntegrations", list);
        commit("setLoadingMailChimpList", false);
      });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
