<template>
  <div>
    <slot :open="open" :close="close" />
    <b-modal
      id="SeferTorahAddModal"
      size="lg"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @hide="clearData"
    >
      <div slot="modal-header">
        <h2 class="text-capitalize">
          {{ titleTk }}
        </h2>
      </div>

      <form id="SeferTorahAddModalForm" @submit.prevent="submit">
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <template #label>
                {{ $t("dashboard.sefer_torah_public_th_title", "Title") }}
              </template>
              <b-form-input required v-model="formData.attributes.title"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <template #label>
                {{ $t("dashboard.sefer_torah_public_th_subtitle", "Subtitle") }}
              </template>
              <b-form-input v-model="formData.attributes.subtitle"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <template #label>
                {{ $t("dashboard.sefer_torah_public_th_parentitem", "Parent Item") }}
              </template>
              <multiselect-search
                v-model="formData.attributes.extend.parent"
                @input="onExtendParentInput"
                :open-prefetch="true"
                :search-url="getSearchUrl"
                :search-options="getSearchOptions()"
                skipExternalSerching
              >
                <template #no-options>
                  {{ $t("teams.team_pages_no_options", "Please enter 1 or more characters") }}
                </template>
                <template #no-more-infinite>
                  {{ $t("teams.team_pages_book_no_more", "No more books") }}
                </template>
              </multiselect-search>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <template #label>
                {{ $t("dashboard.sefer_torah_public_th_description", "Description") }}
              </template>
              <b-form-input v-model="formData.attributes.description"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <template #label>
                {{ $t("dashboard.sefer_torah_public_th_bgimg", "Background Image") }}
              </template>
              <b-form-input v-model="formData.attributes.meta.bgImg"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <template #label>
                {{ $t("dashboard.sefer_torah_public_th_amount", "Amount") }}
              </template>
              <b-form-input
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                v-model.number="formData.attributes.amount"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <template #label>
                {{ $t("dashboard.sefer_torah_public_th_takenby", "Taken By") }}
              </template>
              <multiselect-search
                v-model="formData.attributes.extend.learner"
                @input="onExtendTeamInput"
                :open-prefetch="true"
                :search-url="getTeamSearchUrl"
                :search-options="getTeamSearchOptions()"
                skipExternalSerching
                closeOnSelect
              >
                <template #no-options>
                  {{
                    $t(
                      "dashboard.sefer_torah_public_nooptions_takenby",
                      "Please enter 1 or more characters"
                    )
                  }}
                </template>
                <template #no-more-infinite>
                  {{ $t("dashboard.sefer_torah_public_nomoreinfinite_takenby", "No more teams") }}
                </template>
              </multiselect-search>
            </b-form-group>
          </b-col>
        </b-row>
      </form>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click="close">
          {{ $t(`dashboard.cancel_btn`) }}
        </button>
        <button
          class="bttn bttn--lg bttn--orange text-capitalize"
          :disabled="disabledButton"
          type="submit"
          form="SeferTorahAddModalForm"
        >
          <b-spinner v-if="loading" small class="mr-1"></b-spinner>
          {{ buttonTk }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MultiselectSearch from "@/components/multiselect-search.vue";
import urls from "@/constants/urls";
import { notificationsMixin } from "@/mixins";
import { mapActions, mapState } from "vuex";

const FORM_DATA_DEFAULT_META = {
  bgImg: "",
};

export default {
  mixins: [notificationsMixin],
  components: {
    MultiselectSearch,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    currentFetchParams: {
      type: Object,
      default: () => ({}),
    },
  },

  data: function () {
    return {
      formData: {
        attributes: {
          title: "",
          subtitle: "",
          description: "",
          amount: 0,
          meta: { ...FORM_DATA_DEFAULT_META },
          extend: {
            parent: null,
            learner: null,
          },
        },
      },
      multiselectSearch: {
        learner: 0,
      },
      originalData: null,
      loading: false,
    };
  },

  computed: {
    ...mapState({
      cId: (state) => state.campaign.campaign.data.id,
    }),
    titleTk() {
      return this.hasEditState({
        forEdit: this.$t("dashboard.sefer_torah_update_modal_title", `Update Sefer Torah`),
        forSave: this.$t("dashboard.sefer_torah_addnew_modal_title", `Add Sefer Torah`),
      });
    },
    buttonTk() {
      return this.hasEditState({
        forEdit: this.$t("dashboard.sefer_torah_update_modal_btn", `Update`),
        forSave: this.$t("dashboard.sefer_torah_addnew_modal_btn", `Add`),
      });
    },
    maxListSort() {
      return Math.max(...this.list.map((item) => item.attributes.sort));
    },
    disabledButton() {
      return this.loading;
    },
    getSearchUrl() {
      return urls.getSeferTorahTeamPagesPublic.replace(":cid", this.cId);
    },
    getTeamSearchUrl() {
      let orgId = localStorage.getItem("orgId");
      if (Number(this.cId) === 0) {
        throw new Error("getSearchUrl sefer torah campaign id is 0");
      }
      return urls.apiTeams.replace(":orgId", orgId).replace(":campaignId", this.cId);
    },
  },

  methods: {
    ...mapActions({
      fetchOrgCreatePageNoTeam: "seferTorahPages/fetchOrgCreatePageNoTeam",
      fetchOrgEditPageNoTeam: "seferTorahPages/fetchOrgEditPageNoTeam",
    }),

    getSearchOptions() {
      return {
        placeholder: this.$t(
          "dashboard.sefer_torah_public_select_parentitem",
          "Select Parent Item"
        ),
        changeParams: ({ page, per_page }) => {
          return {
            page,
            per_page,
          };
        },
        customLabel: (e) => `[${e.id}] ${e.name}`,
        processResults: (data) =>
          data.map((el) => ({
            id: Number(el.id),
            name: el.attributes.title,
          })),
      };
    },

    getTeamSearchOptions() {
      return {
        placeholder: this.$t("dashboard.sefer_torah_public_select_takenby", "Select Team"),
        changeParams: ({ page, per_page, query }) => {
          return {
            page,
            limit: per_page,
            q: query,
          };
        },
        customLabel: (e) => `[${e.id}] ${e.name}`,
        processResults: (data) =>
          data.map((el) => ({
            id: Number(el.id),
            name: el.attributes.name,
            link: "",
          })),
      };
    },

    onExtendParentInput(e) {
      if (e) {
        this.formData.attributes.parent_item_id = Number(e?.id);
      }
    },

    onExtendTeamInput(e) {
      if (e) {
        this.multiselectSearch.learner = Number(e.id);
      } else {
        this.multiselectSearch.learner = 0;
      }
    },

    hasEditState({ forEdit, forSave } = {}) {
      if (this.originalData) {
        return forEdit;
      }
      return forSave;
    },

    open({ originalData } = {}) {
      if (originalData) {
        this.originalData = originalData;
        this.formData = JSON.parse(JSON.stringify(originalData));
        this.formData.attributes.meta = originalData.attributes.meta
          ? JSON.parse(originalData.attributes.meta)
          : { ...FORM_DATA_DEFAULT_META };
      }
      this.$bvModal.show("SeferTorahAddModal");
    },

    close() {
      this.$bvModal.hide("SeferTorahAddModal");
    },

    clearData() {
      this.formData = {
        attributes: {
          title: "",
          subtitle: "",
          description: "",
          amount: 0,
          meta: { ...FORM_DATA_DEFAULT_META },
          extend: {
            parent: null,
            learner: null,
          },
        },
      };
      this.originalData = null;
      this.$emit("hide");
    },

    submit() {
      this.loading = true;

      // eslint-disable-next-line no-unused-vars
      const { extend, ...attributes } = this.formData.attributes;
      const payload = {
        cId: this.cId,
        data: {
          attributes: {
            ...attributes,
            meta: JSON.stringify(attributes.meta),
            sort: this.maxListSort + 1,
          },
        },
        params: {
          ...this.currentFetchParams,
          team_id: this.multiselectSearch.learner,
        },
      };

      if (this.originalData) {
        this.fetchOrgEditPageNoTeam({
          ...payload,
          id: this.formData.id,
        })
          .then(({ data, count }) => {
            this.$emit("submit", { data, count });
            this.loading = false;
            this.close();
          })
          .catch((e) => {
            this.$_notificationsMixin_handleCatch(e);
            this.loading = false;
          });

        return;
      }

      console.log("SeferTorahAddModal.vue submit");
      this.fetchOrgCreatePageNoTeam(payload)
        .then(({ data, count }) => {
          this.$emit("submit", { data, count });
          this.loading = false;
          this.close();
        })
        .catch((e) => {
          this.$_notificationsMixin_handleCatch(e);
          this.loading = false;
        });

      return;
    },
  },
};
</script>

<style lang="scss" scoped></style>
