import urls from "@/constants/urls";
import axios from "axios";

const state = {
  campaignTeamDonations: [],
  loading: {
    campaignTeamDonation: false,
  },
};

const getters = {};

const mutations = {
  setCampaignTeamDonation(state, campaignTeamDonation) {
    state.campaignTeamDonations = campaignTeamDonation;
  },
  setLoading(state, [k, v]) {
    state.loading[k] = v;
  },
};

const actions = {
  fetchCampaignTeamDonations({ commit }, [cId, dId, getParams = {}]) {
    const orgId = localStorage.getItem("orgId");
    const params = {
      ...getParams,
    };
    return axios
      .get(
        urls.campaignTeamDonation
          .replace(":oid", orgId)
          .replace(":cid", cId)
          .replace(":donation_id", dId),
        { params }
      )
      .then(({ data }) => {
        commit("setCampaignTeamDonation", data);
        return data;
      });
  },
  createCampaignTeamDonations({ commit }, [cId, dId, data, qsParams = {}]) {
    const orgId = localStorage.getItem("orgId");
    commit("setLoading", ["campaignTeamDonation", true]);
    const params = {
      ...qsParams,
    };
    return axios
      .post(
        urls.campaignTeamDonation
          .replace(":oid", orgId)
          .replace(":cid", cId)
          .replace(":donation_id", dId),
        data,
        { params }
      )
      .then(({ data }) => {
        return data;
      })
      .finally(() => commit("setLoading", ["campaignTeamDonation", false]));
  },
};

export default { namespaced: true, state, getters, mutations, actions };
