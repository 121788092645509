<template>
  <div class="plate plate--wm">
    <div class="d-flex w-100 flex-column">
      <div class="d-flex flex-wrap justify-content-between mb-2">
        <div class="d-flex payouts-amount-block">
          <!-- <span class="payouts-currency-sign">$</span> -->
          <span class="payouts-amount">{{ amount }} {{ currency }}</span>
        </div>
        <div class="d-flex payouts-status-block">
          <span class="payouts-status">{{ payout.status }}:</span>
          <span class="payouts-date">Arrival {{ arrivalDate }}</span>
        </div>
      </div>

      <div class="d-flex">
        <div class="d-flex payouts-gateway-block">
          <img
            src="https://png2.kisspng.com/sh/4d62425e15ba539c36e663412eed2015/L0KzQYm3VcE2N5hriZH0aYP2gLBuTfNwdaF6jNd7LXnmf7B6TgBigZ5qhuY2Y4LodLr7TfNiepUyfNdraYSwc7L5hL02aZdnTaY9M0PoQ4WCUL4yOWg6SqoDOUG4Qoe7UsA2PGg3TaYDLoDxd1==/kisspng-computer-icons-payment-credit-card-debit-card-5afb54433e3490.1175288915264205472548.png"
            alt=""
            class="payouts-gateway-img"
          />
          <span class="payouts-gateway-name align-self-center">{{ payout.gateway }}</span>
          <!-- <span class="payouts-gateway-amount align-self-center">1079 USD</span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    payout: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  computed: {
    amount() {
      return this.payout.amount / 100;
    },
    currency() {
      return this.payout.currency.toLocaleUpperCase();
    },
    arrivalDate() {
      return moment(this.payout.arrival_date * 1000).format("M.d.YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.payouts-amount-block {
  color: rgb(9, 172, 104);
  .payouts-amount {
    font-size: 28px;
  }
  .payouts-currency-sign {
    padding-top: 5px;
    padding-right: 6px;
  }
}

.payouts-status-block {
  @media (min-width: 768px) {
    width: 180px;
  }
  @media (max-width: 768px) {
    margin-bottom: 6px;
  }
  .payouts-status {
    white-space: nowrap;
    text-transform: capitalize;
    padding-right: 6px;
  }
}

.payouts-gateway-block {
  .payouts-gateway-img {
    @media (min-width: 768px) {
      width: 10%;
    }
    @media (max-width: 768px) {
      width: 15%;
    }
    padding-right: 6px;
  }
  .payouts-gateway-name {
    padding-right: 6px;
    text-transform: capitalize;
    height: min-content;
  }
  .payouts-gateway-amount {
    height: min-content;
  }
}
</style>
