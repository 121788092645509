<template>
  <div>
    <!-- Modal Component -->
    <b-modal
      id="journalAdModal"
      size="lg"
      content-class="modal-content-dashboard"
      @hide="clearData"
      no-close-on-backdrop
    >
      <div slot="modal-header">
        <h2 v-if="type === 'add'">{{ $t("dashboard.journal_ad_add_modal_title") }}</h2>
        <h2 v-else>{{ $t("dashboard.journal_ad_edit_modal_title") }}</h2>
        <div class="modal-header__hide" @click.prevent="hide">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </div>
      </div>

      <div slot="modal-footer">
        <button class="bttn bttn--lg bttn--blue" @click.prevent="hide">
          {{ $t("dashboard.donation_add_cancel") }}
        </button>
        <button
          @click.prevent="submitForm"
          class="bttn bttn--lg bttn--orange"
          v-show="!sending"
          :disabled="sending"
        >
          {{ $t("dashboard.journal_ad_save_btn") }}
        </button>
      </div>

      <form id="journalAdModalForm" class="entities-data">
        <error v-if="errors" :errors="errors"></error>

        <journal-ad-tab-form
          ref="journalAdForm"
          :edit="type === 'edit'"
          :module-id="Number(moduleId)"
        />

        <error v-if="errors" :errors="errors"></error>
      </form>
    </b-modal>
  </div>
</template>

<script>
import error from "@/components/errors.vue";
import journalAdTabForm from "./journalAdTabForm.vue";

export default {
  data() {
    return {
      type: "add",
      moduleId: 0,
      errors: [],
      sending: false,
    };
  },

  components: {
    error,
    journalAdTabForm,
  },

  methods: {
    clearData() {
      this.sending = false;
      this.errors = [];
    },

    open(type = "add", mID) {
      this.type = type;
      if (type === "edit" && mID) {
        this.moduleId = mID;
      }
      this.$root.$emit("bv::show::modal", "journalAdModal");
    },

    hide() {
      this.$refs.journalAdForm.resetForm();
      this.$root.$emit("bv::hide::modal", "journalAdModal");
    },

    submitForm() {
      if (this.sending) return;
      this.sending = true;

      this.$refs.journalAdForm
        .submitForm()
        .then(({ data }) => {
          this.sending = false;
          this.$emit("update-list");
          this.hide();

          let message = {
            title: this.$t("dashboard.journal_ad_id_title"),
            body: `${data.data.id || data.data.attributes.id}`,
          };

          let options = {
            title: this.$t("dashboard.journal_ad_id_title"),
            okText: this.$t("dashboard.journal_ad_ok_btn"),
          };

          this.$dialog.alert(message, options);
        })
        .catch((e) => {
          this.sending = false;
          if (e && e.response && e.response.data.error) {
            this.errors.push(e.response.data.error);
          }
        });
    },
  },
};
</script>
