<script>
import IconDesktop from "./iconDesktop.vue";
import IconMobile from "./iconMobile.vue";

export default {
  props: {
    hasAccess: {
      type: Boolean,
      default: true,
    },
    mobile: Boolean,
    shared: Boolean,
    id: {
      type: String,
      required: true,
    },
  },
  components: { IconDesktop, IconMobile },
};
</script>

<template>
  <div class="upload-bar">
    <input
      type="file"
      :disabled="!hasAccess"
      :name="id"
      :id="id"
      accept="image/*"
      @change="$emit('change', $event)"
    />
    <label :for="id" class="upload-bar__label" v-if="!mobile">
      <IconDesktop />
      <IconMobile v-if="shared" />
      <span v-if="!shared">
        <slot name="label">
          {{ $t("edit_campaign.image_for_desktop", "Upload desktop image") }}
        </slot>
      </span>
      <span v-else>
        <slot name="label">
          {{ $t("edit_campaign.upload_image_shared", "Upload images for mobile/desktop") }}
        </slot>
      </span>
    </label>
    <label :for="id" class="upload-bar__label" v-else>
      <IconMobile />
      <span>{{ $t("edit_campaign.image_for_mobile", "Upload mobile image") }}</span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.upload-bar {
  width: 100%;
  margin-bottom: 10px;
  max-width: 280px;
  height: 60px;
  display: flex;
  justify-content: center;
  border: 1px solid #bbbcc4;
  border-radius: 5px;
  background-color: #f5faff;
  position: relative;
  > input,
  > button {
    width: 1px;
    height: 1px;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__label {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    svg {
      display: block;
      height: 24px;
      width: auto;
      margin-right: 10px;
    }
    span:not(.spinner-border) {
      width: 60%;
      text-transform: uppercase;
      line-height: 1.125;
    }
  }
}
</style>
