import urls from "@/constants/urls";
import axios from "axios";
import $ from "jquery";
import Quill from "quill";

export default function imageHandler(quillRef) {
  const maxImageSize = 3000000;
  let fileInput = this.container.querySelector("input.ql-image[type=file]");
  let parrentContainer = this.container.parentNode;

  if (fileInput == null) {
    fileInput = document.createElement("input");
    fileInput.setAttribute("type", "file");
    fileInput.setAttribute("accept", "image/png, image/gif, image/jpeg, image/bmp, image/x-icon");
    fileInput.classList.add("ql-image");

    fileInput.addEventListener("change", () => {
      if (fileInput.files != null && fileInput.files[0] != null) {
        if (fileInput.files[0].size > maxImageSize) {
          if (parrentContainer.querySelector(".alert-danger-editor")) {
            parrentContainer.querySelector(".alert-danger-editor").remove();
          }
          const error = document.createElement("ul");
          error.classList.add("alert-danger-editor");
          error.innerHTML = `<li>File '${
            fileInput.files[0].name
          }' is too big to load. Attachment size exceeds the allowable limit ${(
            maxImageSize / 1000000
          ).toFixed(1)}Mb</li>`;
          parrentContainer.appendChild(error);
        } else {
          let form_data = new FormData();

          form_data.append("file", fileInput.files[0]);

          let orgId = localStorage.getItem("orgId");

          $(".quill-editor .ql-container").prepend(`
                <div class="loadin-image" v-else>
                  <img src="https://editionsdelarose.com/wp-content/themes/edr/img/loading.gif">
                </div>
              `);

          axios.post(urls.loadImageQuill.replace(":OrgId", orgId), form_data).then((response) => {
            if (parrentContainer.querySelector(".alert-danger-editor")) {
              parrentContainer.querySelector(".alert-danger-editor").remove();
            }

            let reader = new FileReader();
            reader.onload = () => {
              let range = quillRef.getSelection(true);
              quillRef.insertEmbed(range.index, "image", response.data.src, Quill.sources.USER);
              fileInput.value = "";
            };
            reader.readAsDataURL(fileInput.files[0]);
            $(".loadin-image").remove();
          });
        }
      }
    });

    this.container.appendChild(fileInput);
  }
  fileInput.click();
}
