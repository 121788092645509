<template>
  <b-dropdown variant="link" no-caret :toggle-class="toggleClass">
    <template v-slot:button-content>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 18.5C14 17.3954 13.1046 16.5 12 16.5C10.8954 16.5 10 17.3954 10 18.5C10 19.6046 10.8954 20.5 12 20.5C13.1046 20.5 14 19.6046 14 18.5Z"
          fill="#107598"
        />
        <path
          d="M14 12.5C14 11.3954 13.1046 10.5 12 10.5C10.8954 10.5 10 11.3954 10 12.5C10 13.6046 10.8954 14.5 12 14.5C13.1046 14.5 14 13.6046 14 12.5Z"
          fill="#107598"
        />
        <path
          d="M14 6.5C14 5.39543 13.1046 4.5 12 4.5C10.8954 4.5 10 5.39543 10 6.5C10 7.60457 10.8954 8.5 12 8.5C13.1046 8.5 14 7.60457 14 6.5Z"
          fill="#107598"
        />
      </svg>
    </template>
    <b-dropdown-item-button v-for="(o, i) in options" :key="i" @click="o.action">
      <span :class="{ [`text-${o.variant}`]: o.variant }">
        {{ o.text }}
      </span>
    </b-dropdown-item-button>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    toggleClass: {
      type: [Array, String, Object],
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
